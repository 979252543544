import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormBuilder } from '../../components/organisms/FormBuilder';
import Loader from '../../components/Loader';
import formSchema from './emailCampaignAudienceSchema';
import Modal from 'react-modal';
import { isEmpty } from 'lodash';
import { getEmailType } from 'actions/emailbriefing/emailType';
import ProgressBar from 'components/molecules/ProgressBar';
import { getBrandDetails } from 'actions/emailbriefing/emailBrandDetails';
import { getEmailCampaignDetails } from 'actions/emailbriefing/emailCampaignDetails';
import constants from 'constants/config';
import EmailCustomAudienceGuildline from 'components/EmailCustomAudienceGuildLine';
import classNames from 'classnames';
import { LinkButton } from 'components/atoms/LinkButton';

export const CustomButtons = ({
  isSubmitting,
  onPrevious,
  previousBtnLabel,
  submitBtnLabel,
  onSecondaryClick,
  onSubmitSecondary,
  secondaryBtnLabel,
  isEdit
}) => {
  return isEdit ? (
    <div className="mt-30 mb-10">
      <div>
        <button
          className={classNames('btn btn btn-outline-secondary mr-3 ')}
          type="button"
          onClick={onPrevious}
          disabled={isSubmitting}
        >
          {previousBtnLabel || 'Cancel'}
        </button>
        {onSecondaryClick && (
          <button
            className="btn btn-primary mr-3"
            type="button"
            onClick={() => {
              onSubmitSecondary();
            }}
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Loading....' : secondaryBtnLabel || 'Secondary'}
          </button>
        )}
      </div>
      <div className="mt-20">
        <LinkButton
          className="cw-formdraft-link"
          type="submit"
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Loading....' : submitBtnLabel || 'Submit'}
        </LinkButton>
      </div>
    </div>
  ) : (
    <div className="mt-30 mb-10">
      <button
        className={classNames('btn btn btn-outline-secondary mr-3')}
        type="button"
        onClick={onPrevious}
        disabled={isSubmitting}
      >
        {previousBtnLabel || 'Cancel'}
      </button>
      <button className="btn btn-primary" type="submit" disabled={isSubmitting}>
        {isSubmitting ? 'Loading....' : submitBtnLabel || 'Submit'}
      </button>
    </div>
  );
};

const EmailCampaignAudience = props => {
  const { isLoading, audienceDefinition } = useSelector(
    state => state.emailCampaignDetails
  );
  const { isCrossBrand, isLoading: isBrandDetailsLoading } = useSelector(
    state => state.emailBrandDetails
  );
  const emailDesign = useSelector(state => state.emailDesign);
  const campaignConfig = useSelector(state => state.app.campaignConfig);
  const { authorizedUserData } = useSelector(state => state.authorized);

  const [isCustomAudienceModel, setIsCustomAudienceModel] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const pathUid = props.match.params.id;
    console.log('pathUid: ', pathUid);
    if (pathUid) {
      // fetch from email details from the server
      // dispatch(getEmailType(pathUid))
      dispatch(getEmailCampaignDetails(pathUid));
      dispatch(getBrandDetails(pathUid));
    }
  }, []);

  const compProps = {
    history: props.history,
    match: props.match,
    defaultValues:
      (isCrossBrand &&
      audienceDefinition?.sendEmailTo?.type?.value ===
        'allConsentedContactableConsumers'
        ? {
            ...audienceDefinition,
            sendEmailTo: {
              type: {
                label:
                  'All consumers who signed up to atleast one of the selected brands in the market',
                value: 'allConsentedContactableConsumers'
              }
            }
          }
        : audienceDefinition) || {},
    emailDesign,
    openCustomAudienceModal: () => setIsCustomAudienceModel(true),
    campaignConfig: campaignConfig || {},
    isCrossBrand
  };

  const isEmailDataEmpty = isEmpty(emailDesign.emailBriefing);

  return (
    <div className="col-sm-12 skypink">
      <div className="cw-section">
        {/* <BreadCrumb match={props.match} emailBriefing /> */}
        <ProgressBar customClass={'cw-email-progress-custom'} pageNo={constants.EMAIL_JOURNEY_PAGE_NUMBERS.AUDIENCE} />
        <div className="cw-section--title mb-30">
          <h2 className="cw-heading--secondary cw-email-heading">
            Email audience
          </h2>
        </div>
        <div className="row">
          {isLoading || isBrandDetailsLoading ? (
            <Loader />
          ) : (
            <div className="col-md-8">
              {!isEmailDataEmpty ? (
                <FormBuilder
                  schema={formSchema(dispatch, compProps)}
                  user={authorizedUserData}
                  customButtonsComp={CustomButtons}
                />
              ) : null}
            </div>
          )}
        </div>
      </div>

      <Modal
        scrollable={true}
        isOpen={isCustomAudienceModel}
        onRequestClose={() => setIsCustomAudienceModel(false)}
        className="cw-modal cw-modal--audience-guildline"
        contentLabel="Campaign Wizard 2.0"
        style={{
          overlay: {
            backgroundColor: constants.EMAIL_OVERLAY_BACKGROUND
          }
        }}
      >
        <EmailCustomAudienceGuildline
          closeIsEditModal={() => setIsCustomAudienceModel(false)}
          data={constants.EMAIL_CUSTOM_AUDIENCE_GUIDELINE}
          isEmailCampaign={false}
          title={'Steps to create custom audience'}
        />
      </Modal>
    </div>
  );
};

export default EmailCampaignAudience;
