import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
import moment from 'moment';
import DateTimeField from 'react-datetime';
import {
  retrieveSmsCampaignInformation,
  updateSmsCampaignDetails,
  submitSmsCampaignDetails,
  getSavedSmsCampaignDetails,
  validateSmsCampignName
} from '../../actions/communications/communicationCampaignDetails.js';
import Loader from 'components/Loader';
import Alert from '../../components/Alert';
import MobileDevicePreview from 'components/organisms/MobileDevicePreview/index.jsx';
import constant from '../../constants';
import { getSMSPreviewData } from 'selectors/index.js';
import axios from 'axios';
import ProgressBar from 'components/molecules/ProgressBar/index.js';
import constants from '../../constants';

class CommunicationCampaignDetails extends Component {
  state = {
    hideDPC: true,
    uid: this.props.match.params.id,
    smsDetails: [
      {
        smsContent: '',
        optOutText: this.props.optOutText,
        broadcastDate: '',
        broadcastTime: ''
      }
    ],
    smsContent1: '',
    smsContent2: '',
    broadcastDate1: '',
    broadcastDate2: '',
    broadcastTime1: '',
    broadcastTime2: '',
    smsOverview: '',
    name: '',
    targetAudience: '',
    expectedVolume: '',
    additionalSMSRequired: false,
    dpcFlag: true
  };

  componentDidMount() {
    this.props.retrieveSmsCampaignInformation(this.state.uid);
    this.props.getSavedSmsCampaignDetails(this.state.uid);
    this.setValuesToFieldOnPageLoad(this.state.uid);
  }

  setValuesToFieldOnPageLoad = uid => {
    let smsDetailURL = constant.communications.GET_SAVED_SMS_DETAIL;
    axios.get(smsDetailURL + '/' + uid).then(res => {
      let response = res && res.data;
      this.setState({
        additionalSMSRequired:
          response &&
          response.communicationDetail &&
          response.communicationDetail.additionalSMSRequired,
        smsOverview:
          response &&
          response.communicationDetail &&
          response.communicationDetail.smsOverview,
        targetAudience:
          response &&
          response.communicationDetail &&
          response.communicationDetail.targetAudience,
        dpcFlag:
          response &&
          response.communicationDetail &&
          response.communicationDetail.dpcFlag,
        name: response && response.name,
        expectedVolume:
          response &&
          response.communicationDetail &&
          response.communicationDetail.expectedVolume,
        dpcPraReferenceNo:
          response &&
          response.communicationDetail &&
          response.communicationDetail.dpcPraReferenceNo,
        smsContent1:
          response &&
          response.communicationDetail &&
          response.communicationDetail.smsDetails &&
          response.communicationDetail.smsDetails[0].smsContent,
        broadcastDate1:
          response &&
          response.communicationDetail &&
          response.communicationDetail.smsDetails &&
          response.communicationDetail.smsDetails[0].broadcastDate,
        broadcastTime1:
          response &&
          response.communicationDetail &&
          response.communicationDetail.smsDetails &&
          response.communicationDetail.smsDetails[0].broadcastTime
      });

      if (
        response &&
        response.communicationDetail &&
        response.communicationDetail.additionalSMSRequired
      ) {
        let additionalSmsRequestData = {
          smsContent: '',
          optOutText: '',
          broadcastDate: ''
        };
        this.props.smsDetails.push(additionalSmsRequestData);
        this.setState({
          smsContent2:
            response &&
            response.communicationDetail &&
            response.communicationDetail.smsDetails &&
            response.communicationDetail.smsDetails[1] &&
            response.communicationDetail.smsDetails[1].smsContent,
          broadcastDate2:
            response &&
            response.communicationDetail &&
            response.communicationDetail.smsDetails &&
            response.communicationDetail.smsDetails[1] &&
            response.communicationDetail.smsDetails[1].broadcastDate,
          broadcastTime2:
            response &&
            response.communicationDetail &&
            response.communicationDetail.smsDetails &&
            response.communicationDetail.smsDetails[1] &&
            response.communicationDetail.smsDetails[1].broadcastTime
        });
      }
    });
  };

  _scrollToTop() {
    setTimeout(() => {
      document.getElementById('root').scrollIntoView({ behavior: 'smooth' });
    }, 10);
  }

  _isValidBroadcastDate(current) {
    let campaignStartDays = 3;
    let yesterday = DateTimeField.moment().add(campaignStartDays, 'day');
    return current.isAfter(yesterday);
  }

  _showSMSLeadTimeMessage() {
    let smsLeadTimeText =
      'Please note, SMS campaigns require a lead time of 72 hours';
    return (
      <div className={`alert alert-primary`} role="alert">
        <em>{smsLeadTimeText}</em>
      </div>
    );
  }

  _validateSmsCampaignName = async smsCampaignName => {
    if (smsCampaignName) {
      let isNameValid = await this.props.validateSmsCampignName(
        smsCampaignName,
        this.state.uid
      );
      if (!isNameValid) {
        if (this.refs.nameAlreadyUsedError.classList.contains('cw-hidden')) {
          this.refs.nameAlreadyUsedError.classList.remove('cw-hidden');
        }
        return false;
      } else {
        if (!this.refs.nameAlreadyUsedError.classList.contains('cw-hidden')) {
          this.refs.nameAlreadyUsedError.classList.add('cw-hidden');
        }
        return true;
      }
    }
  };

  renderInput(props, openCalendar) {
    props.value = props.value;
    return (
      <div className="input-group date" id="datePickerStart">
        <input
          {...props}
          readOnly={'readonly'}
          style={{ backgroundColor: 'white' }}
        />
        <div className="input-group-append">
          <span
            className="input-group-text cw-icon cw-icon--cal"
            id="cw-icon--cal"
            onClick={openCalendar}
          >
            <i className="fas fa-calendar-alt"></i>
          </span>
        </div>
      </div>
    );
  }

  _handlebackButton = event => {
    event.preventDefault();
    let { id } = this.props.match.params;
    this.props.history.push(
      '/campaign-wizard/communications/' + id + '/communicationbrandpage'
    );
  };

  handleDpcFlagChange = event => {
    if (event.target.checked) {
      this.setState({
        dpcFlag: true
      });
      this.props.updateSmsCampaignDetails({
        dpcFlag: true
      });
      if (!this.refs.dpcFlagError.classList.contains('cw-hidden')) {
        this.refs.dpcFlagError.classList.add('cw-hidden');
      }
    } else {
      this.setState({
        dpcFlag: false
      });
      this.props.updateSmsCampaignDetails({
        dpcFlag: false
      });
    }
  };

  handleSMSName = event => {
    let enteredValue = event.target.value.trim();
    this.setState({
      name: enteredValue
    });
    this.props.updateSmsCampaignDetails({
      name: enteredValue
    });
    if (!this.refs.nameError.classList.contains('cw-hidden')) {
      this.refs.nameError.classList.add('cw-hidden');
    }
    this._validateSmsCampaignName(enteredValue);
  };
  handleSMSOverview = event => {
    let enteredValue = event.target.value;
    this.setState({
      smsOverview: enteredValue
    });
    this.props.updateSmsCampaignDetails({
      smsOverview: enteredValue
    });
    if (!this.refs.smsOverviewError.classList.contains('cw-hidden')) {
      this.refs.smsOverviewError.classList.add('cw-hidden');
    }
  };
  handleSMSTargetAudience = event => {
    let enteredValue = event.target.value;
    this.setState({
      targetAudience: enteredValue
    });
    this.props.updateSmsCampaignDetails({
      targetAudience: enteredValue
    });
    if (!this.refs.targetAudienceError.classList.contains('cw-hidden')) {
      this.refs.targetAudienceError.classList.add('cw-hidden');
    }
  };
  handleSMSExpectedVolume = event => {
    let enteredValue = event.target.value.trim();
    this.setState({
      expectedVolume: enteredValue
    });
    this.props.updateSmsCampaignDetails({
      expectedVolume: enteredValue
    });
    if (!this.refs.expectedVolumeError.classList.contains('cw-hidden')) {
      this.refs.expectedVolumeError.classList.add('cw-hidden');
    }
  };

  handleSMSDpcReference = event => {
    let enteredValue = event.target.value.trim();
    const re = /^[0-9\b]+$/;
    this.setState({
      dpcPraReferenceNo: enteredValue
    });
    this.props.updateSmsCampaignDetails({
      dpcPraReferenceNo: enteredValue
    });
    if (re.test(event.target.value)) {
      if (!this.refs.dpcPraError.classList.contains('cw-hidden')) {
        this.refs.dpcPraError.classList.add('cw-hidden');
      }
    } else {
      if (this.refs.dpcPraError.classList.contains('cw-hidden')) {
        this.refs.dpcPraError.classList.remove('cw-hidden');
      }
    } 
  };
  

  onSmsContentChange = (event, index) => {
    let enteredValue = event.target.value;
    this.setState({
      smsContent1: enteredValue
    });
    this.props.updateSmsCampaignDetails({
      smsContent: enteredValue
    });
    this.props.smsDetails[index]['smsContent'] = this.state.smsContent1;
    if (!this.refs.smsContentError0.classList.contains('cw-hidden')) {
      this.refs.smsContentError0.classList.add('cw-hidden');
    }

    this.props.updateSmsCampaignDetails({
      smsDetails: this.props.smsDetails
    });
  };

  onBroadcastDateChange = (moment, index) => {
    let event = { target: { name: 'broadcastDate', value: '' } };
    this._handleDateTimeValdation(event, moment);
    this.props.smsDetails[index]['broadcastDate'] = moment._isValid
      ? moment.utc().format('DD/MM/YY')
      : '';
    this.setState({
      broadcastDate1: moment._isValid ? moment.utc().format('DD/MM/YY') : ''
    });
    if (!this.refs.broadcastDateError0.classList.contains('cw-hidden')) {
      this.refs.broadcastDateError0.classList.add('cw-hidden');
    }

    this.props.updateSmsCampaignDetails({
      smsDetails: this.props.smsDetails
    });
  };

  onBroadcastTimeChange = (moment, index) => {
    let event = { target: { name: 'broadcastTime', value: '' } };
    this._handleDateTimeValdation(event, moment);
    this.props.smsDetails[index]['broadcastTime'] = moment._isValid
      ? moment.utc().format('LT')
      : '';
    this.setState({
      broadcastTime1: moment._isValid ? moment.utc().format('LT') : ''
    });
    if (!this.refs.broadcastDateError0.classList.contains('cw-hidden')) {
      this.refs.broadcastDateError0.classList.add('cw-hidden');
    }

    this.props.updateSmsCampaignDetails({
      smsDetails: this.props.smsDetails
    });
  };

  onSmsContentChange1 = (event, index) => {
    let enteredValue = event.target.value;
    this.setState({
      smsContent2: enteredValue
    });
    this.props.updateSmsCampaignDetails({
      smsContent: enteredValue
    });
    if (!this.refs.smsContentError1.classList.contains('cw-hidden')) {
      this.refs.smsContentError1.classList.add('cw-hidden');
    }
    this.props.updateSmsCampaignDetails({
      smsDetails: this.props.smsDetails
    });
  };

  onBroadcastDateChange1 = (moment, index) => {
    let event = { target: { name: 'broadcastDate', value: '' } };
    this._handleDateTimeValdation(event, moment);
    this.props.smsDetails[index]['broadcastDate'] = moment._isValid
      ? moment.utc().format('DD/MM/YY')
      : '';

    this.setState({
      broadcastDate2: moment._isValid ? moment.utc().format('DD/MM/YY') : ''
    });
    if (!this.refs.broadcastDateError1.classList.contains('cw-hidden')) {
      this.refs.broadcastDateError1.classList.add('cw-hidden');
    }
    this.props.updateSmsCampaignDetails({
      smsDetails: this.props.smsDetails
    });
  };

  onBroadcastTimeChange1 = (moment, index) => {
    let event = { target: { name: 'broadcastTime', value: '' } };
    this._handleDateTimeValdation(event, moment);

    this.props.smsDetails[index]['broadcastTime'] = moment._isValid
      ? moment.utc().format('LT')
      : '';
    this.setState({
      broadcastTime2: moment._isValid ? moment.utc().format('LT') : ''
    });
    if (!this.refs.broadcastDateError1.classList.contains('cw-hidden')) {
      this.refs.broadcastDateError1.classList.add('cw-hidden');
    }
    this.props.updateSmsCampaignDetails({
      smsDetails: this.props.smsDetails
    });
  };

  _handleDateTimeValdation(event, moment) {
    if (moment._isValid) {
      event.target.value = moment._d;
    }
    return event.target.value;
  }

  onAdditionRequestChangeYes = () => {
    this.props.updateSmsCampaignDetails({
      additionalSMSRequired: true
    });
    this.setState({
      additionalSMSRequired: true
    });
    if (this.props.smsDetails.length < 2) {
      let additionalSmsRequestData = {
        smsContent: '',
        optOutText: '',
        broadcastDate: ''
      };
      this.props.smsDetails.push(additionalSmsRequestData);
      this.props.smsDetails[1]['optOutText'] = this.props.optOutText;
      this.props.updateSmsCampaignDetails({
        smsDetails: this.props.smsDetails
      });
    }
  };

  onAdditionRequestChangeNo = () => {
    this.props.updateSmsCampaignDetails({
      additionalSMSRequired: false
    });
    this.setState({
      additionalSMSRequired: false
    });
    this.setState({
      smsContent2: '',
      broadcastDate2: '',
      broadcastTime2: ''
    });
    if (this.props.smsDetails.length > 1 || !this.state.additionalSMSRequired) {
      this.props.smsDetails.pop();
      this.props.updateSmsCampaignDetails({
        smsDetails: this.props.smsDetails
      });
    }
  };

  _validateForm = () => {
    let isValid = true;
    const re = /^[0-9\b]+$/;
    if (
      this.state.name === null ||
      this.state.name === '' ||
      this.state.name === undefined
    ) {
      if (this.refs.nameError.classList.contains('cw-hidden')) {
        this.refs.nameError.classList.remove('cw-hidden');
      }
      isValid = false;
    }
    if (
      this.state.smsOverview === null ||
      this.state.smsOverview === '' ||
      this.state.smsOverview === undefined ||
      document.getElementById('smsOverview').value.trim() === ''
    ) {
      if (this.refs.smsOverviewError.classList.contains('cw-hidden')) {
        this.refs.smsOverviewError.classList.remove('cw-hidden');
      }
      isValid = false;
    }
    if (
      this.state.targetAudience === null ||
      this.state.targetAudience === '' ||
      this.state.targetAudience === undefined ||
      document.getElementById('targetAudience').value.trim() === ''
    ) {
      if (this.refs.targetAudienceError.classList.contains('cw-hidden')) {
        this.refs.targetAudienceError.classList.remove('cw-hidden');
      }
      isValid = false;
    }
    if (
      this.state.expectedVolume === null ||
      this.state.expectedVolume === '' ||
      this.state.expectedVolume === undefined
    ) {
      if (this.refs.expectedVolumeError.classList.contains('cw-hidden')) {
        this.refs.expectedVolumeError.classList.remove('cw-hidden');
      }
      isValid = false;
    }
    if (this.state.additionalSMSRequired) {
      if (
        this.state.smsContent2 === null ||
        this.state.smsContent2 === '' ||
        this.state.smsContent2 === undefined ||
        document.getElementById('smsContent1').value.trim() === ''
      ) {
        if (this.refs.smsContentError1.classList.contains('cw-hidden')) {
          this.refs.smsContentError1.classList.remove('cw-hidden');
        }
        isValid = false;
      }
      if (this.refs.optOutText_1.value === null) {
        if (this.refs.optOutTextError1.classList.contains('cw-hidden')) {
          this.refs.optOutTextError1.classList.remove('cw-hidden');
        }
        isValid = false;
      }
      if (
        this.state.broadcastDate2 === null ||
        this.state.broadcastDate2 === '' ||
        this.state.broadcastDate2 === undefined
      ) {
        if (this.refs.broadcastDateError1.classList.contains('cw-hidden')) {
          this.refs.broadcastDateError1.classList.remove('cw-hidden');
        }
        isValid = false;
      }
      if (
        this.state.broadcastTime2 === null ||
        this.state.broadcastTime2 === '' ||
        this.state.broadcastTime2 === undefined
      ) {
        if (this.refs.broadcastDateError1.classList.contains('cw-hidden')) {
          this.refs.broadcastDateError1.classList.remove('cw-hidden');
        }
        isValid = false;
      }
    }
    if (
      this.state.smsContent1 === null ||
      this.state.smsContent1 === '' ||
      this.state.smsContent1 === undefined ||
      document.getElementById('smsContent0').value.trim() === ''
    ) {
      if (this.refs.smsContentError0.classList.contains('cw-hidden')) {
        this.refs.smsContentError0.classList.remove('cw-hidden');
      }
      isValid = false;
    }
    if (this.refs.optOutText_0.value === null) {
      if (this.refs.optOutTextError0.classList.contains('cw-hidden')) {
        this.refs.optOutTextError0.classList.remove('cw-hidden');
      }
      isValid = false;
    }
    if (
      this.state.broadcastDate1 === null ||
      this.state.broadcastDate1 === '' ||
      this.state.broadcastDate1 === undefined
    ) {
      if (this.refs.broadcastDateError0.classList.contains('cw-hidden')) {
        this.refs.broadcastDateError0.classList.remove('cw-hidden');
      }
      isValid = false;
    }

    if (
      this.state.broadcastTime1 === null ||
      this.state.broadcastTime1 === '' ||
      this.state.broadcastTime1 === undefined
    ) {
      if (this.refs.broadcastDateError0.classList.contains('cw-hidden')) {
        this.refs.broadcastDateError0.classList.remove('cw-hidden');
      }
      isValid = false;
    }

    // if (!this.state.dpcFlag) {
    //   if (this.refs.dpcFlagError.classList.contains('cw-hidden')) {
    //     this.refs.dpcFlagError.classList.remove('cw-hidden');
    //   }
    //   isValid = false;
    // }
    if (
      this.state.dpcPraReferenceNo === null ||
      this.state.dpcPraReferenceNo === '' ||
      this.state.dpcPraReferenceNo === undefined ||
      !re.test(this.state.dpcPraReferenceNo)
    ) {
      if (this.refs.dpcPraError.classList.contains('cw-hidden')) {
        this.refs.dpcPraError.classList.remove('cw-hidden');
      }
      isValid = false;
    }

    return isValid;
  };

  _handleSubmit = async event => {
    event.preventDefault();
    let saveAsDraft = false;
    let { smsDetails } = this.props;

    this.props.smsDetails[0]['optOutText'] = this.props.optOutText;
    this.props.smsDetails[0]['smsContent'] = this.state.smsContent1;
    this.props.smsDetails[0]['broadcastDate'] = this.state.broadcastDate1;
    this.props.smsDetails[0]['broadcastTime'] = this.state.broadcastTime1;
    this.props.updateSmsCampaignDetails({
      smsDetails: this.props.smsDetails
    });

    if (
      !this.state.additionalSMSRequired &&
      this.props.smsDetails &&
      this.props.smsDetails.length >= 2
    ) {
      this.props.smsDetails.splice(1);
      this.props.updateSmsCampaignDetails({
        smsDetails: this.props.smsDetails
      });
    }

    if (this.state.additionalSMSRequired) {
      if (this.props.smsDetails && this.props.smsDetails[1]) {
        this.props.smsDetails[1]['optOutText'] = this.props.optOutText;
        this.props.smsDetails[1]['smsContent'] = this.state.smsContent2;
        this.props.smsDetails[1]['broadcastDate'] = this.state.broadcastDate2;
        this.props.smsDetails[1]['broadcastTime'] = this.state.broadcastTime2;
      }

      if (
        this.state.additionalSMSRequired &&
        this.props.smsDetails &&
        this.props.smsDetails.length > 2
      ) {
        this.props.smsDetails.splice(2);
        this.props.updateSmsCampaignDetails({
          smsDetails: this.props.smsDetails
        });
      }

      this.props.updateSmsCampaignDetails({
        smsDetails: this.props.smsDetails
      });
    }
    let campaignName = document.getElementById('name').value;

    let isNameValid = await this._validateSmsCampaignName(campaignName);
    if (this._validateForm() && isNameValid) {
      await this.props.submitSmsCampaignDetails(
        this.state.uid,
        this.state.name,
        this.state.smsOverview,
        this.state.targetAudience,
        this.state.expectedVolume,
        smsDetails,
        this.state.dpcFlag,
        this.state.dpcPraReferenceNo,
        this.state.additionalSMSRequired,
        saveAsDraft
      );
      this._scrollToTop();

      setTimeout(() => {
        if (this.props.response && this.props.response.status === 201) {
          this.props.history.push(
            '/campaign-wizard/communications/' +
              this.state.uid +
              '/communicationreview'
          );
        } else {
          this._scrollToTop();
        }
      }, 1000);
    } else {
      this._scrollToTop();
    }
  };

  render() {
    const {
      smsBrandCampaignDetails,
      smsBrandDetails,
      optOutText,
      smsCampaignDetails
    } = this.props;
    let smsDetails =
      smsCampaignDetails &&
      smsCampaignDetails.communicationDetail &&
      smsCampaignDetails.communicationDetail.smsDetails;
    let dpcClass = classNames({
      'ml-4': true,
      'cw-subCategory': true,
      'cw-hidden': !this.state.dpcFlag
    });
    let mobilePreviewData = getSMSPreviewData({
      smsContent1: this.state.smsContent1,
      optOutText: optOutText,
      broadcastDate1: this.state.broadcastDate1,
      broadcastTime1: this.state.broadcastTime1,
      smsContent2: this.state.smsContent2,
      broadcastDate2: this.state.broadcastDate2,
      broadcastTime2: this.state.broadcastTime2
    }); 
    return (
      <>
        {this.props.isLoading ? <Loader /> : null}
        <div className="col-sm-12 skypink email-campaign-brand-selection">
          <ReactTooltip
            place="right"
            type="info"
            multiline={true}
            className="cw-tooltip-sms"
          />
          <div className="cw-section">
            <strong>
              <Alert
                alertType={'nonEditableBrandDetails'}
                type={'danger'}
                message={constant.SYSTEM_ERROR_MESSAGE}
                showComponent={
                  (this.props.systemError &&
                    this.props.systemError.response === undefined) ||
                  (this.props.systemError &&
                    this.props.systemError.response === 400)
                }
              />
            </strong>
            <ProgressBar customClass={'cw-email-progress-custom'} pageNo={2} totalPage={constants.SMS_PROGRESS_BAR_TOT_PAGE} />
            <div className="cw-section--title mb-20">
              <h2 className="cw-heading--secondary mb-40 cw-email-heading">
                SMS Campaign Details
              </h2>
            </div>
            <div className="row">
              <div className="col-sm-7">
                <div className="cw-section--content">
                  <form className="cw-form" onSubmit={this._handleSubmit}>
                    {/* SMS Campaign name */}
                    <div className="form-group">
                      <label htmlFor="name">
                        <span>Campaign name</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        id="name"
                        maxLength="50"
                        ref="name"
                        defaultValue={this.state.name || ''}
                        onChange={e => this.handleSMSName(e)}
                      />
                      <span className="cw-error cw-hidden" ref="nameError">
                        <i className="fas fa-exclamation-triangle mr-10"></i>
                        Please enter the campaign name
                      </span>
                      <span
                        className="cw-error cw-hidden"
                        ref="nameAlreadyUsedError"
                      >
                        <i className="fas fa-exclamation-triangle mr-10"></i>
                        Sorry this campaign name is already taken, please try
                        again.
                      </span>
                    </div>
                    {/* SMS Campaign Overview */}
                    <div className="form-group">
                      <label htmlFor="smsOverview">
                        <span>Campaign description</span>
                        <span
                          className="float-right  cw-icon cw-icon--help"
                          data-tip="Provide a narrative overview of the campaign activity including the brand's 
                        JTBD and how this activity supports these objectives including a brief consumer journey"
                        >
                          <i className="fas fa-question-circle"></i>
                        </span>
                      </label>
                      <textarea
                        className="form-control"
                        name="smsOverview"
                        id="smsOverview"
                        ref="smsOverview"
                        aria-describedby="smsOverview"
                        value={
                          this.props.smsOverview || this.state.smsOverview || ''
                        }
                        onChange={e => this.handleSMSOverview(e)}
                      ></textarea>
                      <span
                        className="cw-error cw-hidden"
                        ref="smsOverviewError"
                      >
                        <i className="fas fa-exclamation-triangle mr-10"></i>
                        Please enter the Campaign description
                      </span>
                    </div>
                    {/* Target Audience */}
                    <div className="form-group">
                      <label htmlFor="targetAudience">
                        <span>Target Audience</span>
                        <span
                          className="float-right  cw-icon cw-icon--help"
                          data-tip="Specify the details of the target audience (consumer segments) for this campaign"
                        >
                          <i className="fas fa-question-circle"></i>
                        </span>
                      </label>
                      <textarea
                        className="form-control"
                        name="targetAudience"
                        id="targetAudience"
                        ref="targetAudience"
                        data-required="true"
                        placeholder={
                          'Give a brief description of the target audience.'
                        }
                        value={
                          this.props.targetAudience ||
                          this.state.targetAudience ||
                          ''
                        }
                        onChange={e => this.handleSMSTargetAudience(e)}
                      ></textarea>
                      <span
                        className="cw-error cw-hidden"
                        ref="targetAudienceError"
                      >
                        <i className="fas fa-exclamation-triangle mr-10"></i>
                        Please enter the target audience
                      </span>
                    </div>
                    {/* Expected volume */}

                    <div className="form-group">
                      <label htmlFor="expectedVolume">
                        <span>Expected Volume</span>
                        <span
                          className="float-right  cw-icon cw-icon--help"
                          data-tip="Specify the number of SMS expected to be sent"
                        >
                          <i className="fas fa-question-circle"></i>
                        </span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="expectedVolume"
                        id="expectedVolume"
                        ref="expectedVolume"
                        aria-describedby="expectedVolume"
                        defaultValue={
                          (smsCampaignDetails &&
                            smsCampaignDetails.communicationDetail &&
                            smsCampaignDetails.communicationDetail
                              .expectedVolume) ||
                          this.state.expectedVolume ||
                          ''
                        }
                        onChange={e => this.handleSMSExpectedVolume(e)}
                      />
                      <span
                        className="cw-error cw-hidden"
                        ref="expectedVolumeError"
                      >
                        <i className="fas fa-exclamation-triangle mr-10"></i>
                        Please enter the expected volume
                      </span>
                    </div>

                    {/* DPC/PRA ID confirmation */}

                    <div className="cw-form--legend mb-40">
                    <p className="cw-heading--saascampaign mb-3">
                      Privacy Risk Assessment Confirmation
                    </p>
                    <div className="mb-10">
                      <span>
                        Confirm you have completed privacy risk assessment.
                      </span>
                      <ReactTooltip
                        id={'dpc_pra_tooltip'}
                        place="right"
                        type="info"
                        multiline={true}
                        className="cw-tooltip custom-cw-toolip"
                      />
                      <span
                        className="float-right  cw-icon cw-icon--help"
                        data-for="dpc_pra_tooltip"
                        data-tip="You need to have completed the privacy risk assessment in Scout before you begin collecting consumer data. Enter your PRA ID from Scout here to confirm. Please ensure you enter an authentic ID as your campaign requests may be subject to audit by Unilever's privacy teams."
                      >
                        <i className="fas fa-question-circle"></i>
                      </span>
                    </div>
                    <div>
                      <div className="form-group">
                        <label htmlFor="dpiaReferenceNo">
                          <span>PRA ID</span>
                          <span className="float-right ">
                            <a
                              className="cw-qna-faq-link"
                              href={constant.PRA_LEARN_MORE_LINK}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {'Learn more on PRA'} &nbsp;
                              <i className="fas fa-external-link-alt"></i>
                            </a>
                          </span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="dpiaReferenceNo"
                          id="dpiaReferenceNo"
                          ref="dpiaReferenceNo"
                          // disabled={editCampaign}
                          aria-describedby="dpiaReferenceNo"
                          data-required="true"
                          //onChange={this._handlePraIdChange}
                          //  onBlur={this._handleInputOnBlur}
                          value={this.state.dpcPraReferenceNo || ''}
                          maxLength={'5'}
                          defaultValue={this.state.dpcPraReferenceNo || ''}
                          onChange={e => this.handleSMSDpcReference(e)}
                        />
                        <span
                          className="cw-error cw-hidden"
                          data-control="dpiaReferenceNo"
                          id="dpcPraError"
                          ref="dpcPraError"
                        >
                          <i className="fas fa-exclamation-triangle mr-10"></i>
                          Please enter a valid PRA ID from your Scout
                          assessment.
                        </span>
                      </div>
                    </div>
                  </div>

                    {
                      <div className="cw-form--legend mb-30">
                        <>
                          <div key={'0-key'} className="cw-form--legend mb-30">
                            <ReactTooltip
                              place="right"
                              type="info"
                              multiline={true}
                              className="cw-tooltip-sms"
                            />
                            <div key={'0'} className="form-group">
                              <p
                                className="cw-heading--tertiary mb-3"
                                style={{ fontWeight: '600' }}
                              >
                                SMS 1 - Body and Target Date{' '}
                              </p>
                              <label htmlFor="smsBody"></label>
                              <div key={'smsContent0'} className="form-group">
                                <label htmlFor="smsContent0">
                                  <span>SMS Body Content</span>
                                  <span
                                    className="float-right  cw-icon cw-icon--help"
                                    data-tip="Enter the content of the SMS which will be sent to the target audience."
                                  >
                                    <i className="fa fa-question-circle"></i>
                                  </span>
                                </label>
                                <textarea
                                  className="form-control"
                                  name={'smsContent0'}
                                  id={'smsContent0'}
                                  ref={'smsContent0'}
                                  value={this.state.smsContent1 || ''}
                                  onChange={e => this.onSmsContentChange(e, 0)}
                                ></textarea>
                                <span
                                  className="cw-error cw-hidden"
                                  ref={'smsContentError0'}
                                >
                                  <i className="fas fa-exclamation-triangle mr-10"></i>
                                  Please enter the SMS body content.
                                </span>
                              </div>
                            </div>
                            <div key={'optOutText-0'} className="form-group">
                              <label htmlFor="optOutText">
                                <span>Opt out Text</span>
                                <span
                                  className="float-right  cw-icon cw-icon--help"
                                  data-tip="Opt-out text content is according to the country and brand selected.
                              It is mandatory to provide Opt Out text in SMS and goes at end of the SMS body"
                                >
                                  <i className="fa fa-question-circle"></i>
                                </span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name={'optOutText-0'}
                                id={'optOutText-0'}
                                ref={'optOutText_0'}
                                aria-describedby={'optOutText-0'}
                                value={optOutText || ''}
                                disabled={true}
                              />
                              <span
                                className="cw-error cw-hidden"
                                ref={'optOutTextError0'}
                              >
                                <i className="fas fa-exclamation-triangle mr-10"></i>
                                Opt-out text not populated
                              </span>
                            </div>
                            <div key={'broadcastDate0'} className="form-group">
                              <label htmlFor="targetDate">
                                <span>
                                  Target Broadcast Date &amp; Time (UTC)
                                </span>
                                <span
                                  className="float-right cw-icon cw-icon--help"
                                  data-tip="Please specify the time (in UTC) at which you want to broadcast the message"
                                >
                                  <i className="fas fa-question-circle"></i>
                                </span>
                              </label>
                              {this._showSMSLeadTimeMessage()}
                              <span className="row">
                                <span className="col-sm-8">
                                  <DateTimeField
                                    inputProps={{
                                      name: 'broadcastDate',
                                      id: 'broadcastDate',
                                      className: 'form-control',
                                      'aria-describedby': 'broadcastDate',
                                      placeholder:
                                        this.state.broadcastDate1 &&
                                        this.state.broadcastDate1.length > 0
                                          ? this.state.broadcastDate1
                                          : 'Select a target broadcast date'
                                    }}
                                    dateFormat="DD/MM/YY"
                                    timeFormat={false}
                                    isValidDate={this._isValidBroadcastDate}
                                    utc={true}
                                    onkeyDown="return false"
                                    renderInput={this.renderInput}
                                    onChange={moment =>
                                      this.onBroadcastDateChange(moment, 0)
                                    }
                                  />
                                </span>
                                <span className="col-sm-4">
                                  <DateTimeField
                                    inputProps={{
                                      name: 'broadcastTime',
                                      id: 'broadcastTime',
                                      className: 'form-control',
                                      'aria-describedby': 'broadcastTime',
                                      placeholder:
                                        this.state.broadcastTime1 &&
                                        this.state.broadcastTime1.length > 0
                                          ? this.state.broadcastTime1
                                          : 'Broadcast time (UTC)'
                                    }}
                                    dateFormat={false}
                                    timeFormat="HH:mm A"
                                    utc={true}
                                    onkeyDown="return false"
                                    renderInput={this.renderInput}
                                    onChange={moment =>
                                      this.onBroadcastTimeChange(moment, 0)
                                    }
                                  />
                                </span>
                              </span>
                              <span
                                className="cw-error cw-hidden"
                                ref={'broadcastDateError0'}
                              >
                                <i className="fas fa-exclamation-triangle mr-10"></i>
                                Please enter the target broadcast date and time
                                (UTC)
                              </span>
                              <span
                                className="cw-error cw-hidden"
                                data-custom-msg="broadcastDate"
                              >
                                <i className="fas fa-exclamation-triangle mr-10"></i>
                                Broadcast date should be greater than current
                                datetime
                              </span>
                            </div>
                          </div>

                          <div className="cw-form--legend mb-40">
                            <p className="mb-15">
                              Would you like to add an additional SMS in the
                              same request?
                            </p>
                            <div className="form-check">
                              <input
                                type="radio"
                                className="form-check-input"
                                id="additionalSMS_yes"
                                name="additionalSMSRequired_Yes"
                                ref="additionalSMSRequired_Yes"
                                value={
                                  this.state.additionalSMSRequired
                                    ? true
                                    : false
                                }
                                checked={
                                  this.state.additionalSMSRequired
                                    ? true
                                    : false
                                }
                              />

                              <label
                                className="form-check-label"
                                htmlFor="additionalSMSRequired_Yes"
                                onClick={this.onAdditionRequestChangeYes}
                              >
                                <p className="mb-5">Yes</p>
                              </label>

                              <input
                                type="radio"
                                className="form-check-input"
                                id="additionalSMSRequired_no"
                                name="additionalSMSRequired_No"
                                ref="additionalSMSRequired_No"
                                value={
                                  !this.state.additionalSMSRequired
                                    ? true
                                    : false
                                }
                                checked={
                                  !this.state.additionalSMSRequired
                                    ? true
                                    : false
                                }
                              />
                              <label
                                className="form-check-label ml-2"
                                htmlFor="additionalSMSRequired_No"
                                onClick={this.onAdditionRequestChangeNo}
                              >
                                <p className="mb-5">No</p>
                              </label>
                            </div>
                          </div>
                        </>
                      </div>
                    }

                    {this.state.additionalSMSRequired ? (
                      <div className="cw-form--legend mb-30">
                        <>
                          <div key={'1-key'} className="cw-form--legend mb-30">
                            <ReactTooltip
                              place="right"
                              type="info"
                              multiline={true}
                              className="cw-tooltip-sms"
                            />
                            <div key={'1'} className="form-group">
                              <p
                                className="cw-heading--tertiary mb-3"
                                style={{ fontWeight: '600' }}
                              >
                                SMS 2 - Body and Target Date{' '}
                              </p>
                              <label htmlFor="smsBody"></label>
                              <div key={'smsContent1'} className="form-group">
                                <label htmlFor="smsContent1">
                                  <span>SMS Body Content</span>
                                  <span
                                    className="float-right  cw-icon cw-icon--help"
                                    data-tip="Enter the content of the SMS which will be sent to the target audience."
                                  >
                                    <i className="fa fa-question-circle"></i>
                                  </span>
                                </label>
                                <textarea
                                  className="form-control"
                                  name={'smsContent1'}
                                  id={'smsContent1'}
                                  ref={'smsContent1'}
                                  value={this.state.smsContent2 || ''}
                                  onChange={e => this.onSmsContentChange1(e, 1)}
                                ></textarea>
                                <span
                                  className="cw-error cw-hidden"
                                  ref={'smsContentError1'}
                                >
                                  <i className="fas fa-exclamation-triangle mr-10"></i>
                                  Please enter the SMS body content.
                                </span>
                              </div>
                            </div>
                            <div key={'optOutText-1'} className="form-group">
                              <label htmlFor="optOutText">
                                <span>Opt out Text</span>
                                <span
                                  className="float-right  cw-icon cw-icon--help"
                                  data-tip="Opt-out text content is according to the country and brand selected.
                              It is mandatory to provide Opt Out text in SMS and goes at end of the SMS body"
                                >
                                  <i className="fa fa-question-circle"></i>
                                </span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name={'optOutText-1'}
                                id={'optOutText-1'}
                                ref={'optOutText_1'}
                                aria-describedby={'optOutText-1'}
                                value={optOutText || ''}
                                disabled={true}
                              />
                              <span
                                className="cw-error cw-hidden"
                                ref={'optOutTextError1'}
                              >
                                <i className="fas fa-exclamation-triangle mr-10"></i>
                                Opt-out text not populated
                              </span>
                            </div>
                            <div key={'broadcastDate2'} className="form-group">
                              <label htmlFor="targetDate">
                                <span>
                                  Target Broadcast Date &amp; Time (UTC)
                                </span>
                                <span
                                  className="float-right cw-icon cw-icon--help"
                                  data-tip="Please specify the time (in UTC) at which you want to broadcast the message"
                                >
                                  <i className="fas fa-question-circle"></i>
                                </span>
                              </label>
                              {this._showSMSLeadTimeMessage()}
                              <span className="row">
                                <span className="col-sm-8">
                                  <DateTimeField
                                    inputProps={{
                                      name: 'broadcastDate',
                                      id: 'broadcastDate',
                                      className: 'form-control',
                                      'aria-describedby': 'broadcastDate',
                                      placeholder:
                                        this.state.broadcastDate2 &&
                                        this.state.broadcastDate2.length > 0
                                          ? this.state.broadcastDate2
                                          : 'Select a target broadcast date'
                                    }}
                                    dateFormat="DD/MM/YY"
                                    timeFormat={false}
                                    isValidDate={this._isValidBroadcastDate}
                                    utc={true}
                                    onkeyDown="return false"
                                    renderInput={this.renderInput}
                                    onChange={moment =>
                                      this.onBroadcastDateChange1(moment, 1)
                                    }
                                  />
                                </span>
                                <span className="col-sm-4">
                                  <DateTimeField
                                    inputProps={{
                                      name: 'broadcastTime',
                                      id: 'broadcastTime',
                                      className: 'form-control',
                                      'aria-describedby': 'broadcastTime',
                                      placeholder:
                                        this.state.broadcastTime2 &&
                                        this.state.broadcastTime2.length > 0
                                          ? this.state.broadcastTime2
                                          : 'Broadcast time (UTC)'
                                    }}
                                    dateFormat={false}
                                    timeFormat="HH:mm A"
                                    utc={true}
                                    onkeyDown="return false"
                                    renderInput={this.renderInput}
                                    onChange={moment =>
                                      this.onBroadcastTimeChange1(moment, 1)
                                    }
                                  />
                                </span>
                              </span>
                              <span
                                className="cw-error cw-hidden"
                                ref={'broadcastDateError1'}
                              >
                                <i className="fas fa-exclamation-triangle mr-10"></i>
                                Please enter the target broadcast date and time
                                (UTC)
                              </span>
                              <span
                                className="cw-error cw-hidden"
                                data-custom-msg="broadcastDate"
                              >
                                <i className="fas fa-exclamation-triangle mr-10"></i>
                                Broadcast date should be greater than current
                                datetime
                              </span>
                            </div>
                          </div>
                        </>
                      </div>
                    ) : null}

                    <div className="cw-form--action-cta">
                      <>
                        <button
                          type="button"
                          className="btn btn btn-outline-secondary mr-3"
                          onClick={this._handlebackButton}
                        >
                          Back
                        </button>
                        <button
                          type="submit"
                          className="btn btn-primary"
                          onClick={this._handleSubmit}
                        >
                          Next
                        </button>
                        <a
                          href="javascript:void(0)"
                          className="cw-save-to-draft mt-40"
                          style={{ display: 'none' }}
                        >
                          Save and exit
                        </a>
                      </>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-sm-5">
                  <MobileDevicePreview data={mobilePreviewData}/>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  smsBrandDetails: state.communicationCampaignDetails.smsBrandDetails,
  name: state.communicationCampaignDetails.name,
  smsOverview: state.communicationCampaignDetails.smsOverview,
  targetAudience: state.communicationCampaignDetails.targetAudience,
  expectedVolume: state.communicationCampaignDetails.expectedVolume,
  smsDetails: state.communicationCampaignDetails.smsDetails,
  dpcFlag: state.communicationCampaignDetails.dpcFlag,
  dpcPraReferenceNo: state.communicationCampaignDetails.dpcPraReferenceNo,
  additionalSMSRequired:
    state.communicationCampaignDetails.additionalSMSRequired,
  smsContent: state.communicationCampaignDetails.smsContent,
  smsBrandCampaignDetails:
    state.communicationCampaignDetails.smsBrandCampaignDetails,
  optOutText: state.communicationCampaignDetails.optOutText,
  isLoading: state.communicationCampaignDetails.isLoading,
  response: state.communicationCampaignDetails.response,
  systemError: state.communicationCampaignDetails.systemError,
  smsCampaignDetails: state.communicationCampaignDetails.smsCampaignDetails,
  isSMSNameValid: state.communicationCampaignDetails.isSMSNameValid
});

export default connect(
  mapStateToProps,
  {
    retrieveSmsCampaignInformation,
    updateSmsCampaignDetails,
    submitSmsCampaignDetails,
    getSavedSmsCampaignDetails,
    validateSmsCampignName
  }
)(CommunicationCampaignDetails);
