import React from 'react';

import { ReactComponent as Icon } from '../../images/toggle-icon.svg';

export default function ToggleButton({ visible, setIsVisible }) {
  const onClick = () => {
    setIsVisible(!visible);
  };

  return (
    <button className="toggle-button" onClick={onClick}>
      {visible ? <Icon className="rotated" /> : <Icon />}
    </button>
  );
}
