import React, { useState, useRef } from 'react';
import { Popover } from 'react-tiny-popover';
import { SketchPicker } from 'react-color';
import 'components/molecules/ColourPicker/ColourPicker.css';
import PlusButton from 'components/atoms/PlusButton/PlusButton';
import Color from 'components/atoms/Color/Color';
import { LinkButton } from 'components/atoms/LinkButton';
import { useOnClickOutside } from 'hooks/useOnClickOutside';

export default function SingleColorPicker({
  className = '',
  label = 'Color',
  sampleText,
  property,
  value,
  onChange
}) {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [selectedColour, setSelectedColour] = useState('#ddd');
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [triggerY, setTriggerY] = useState();

  const triggerRef = React.useRef();
  const pickerRef = useRef();
  useOnClickOutside(pickerRef, () => setIsPopoverOpen(false));

  React.useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    const handleScroll = () => {
      setTriggerY(triggerRef?.current?.getBoundingClientRect()?.y);
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const onRemove = () => {
    onChange(property, '');
  };

  const getColourPickerView = () => {
    return (
      <div
        style={{
          borderRadius: 4,
          boxShadow: '0 16px 24px 0 rgba(48,49,51,.1)',
          backgroundColor: 'white',
          position: 'relative'
        }}
        ref={pickerRef}
      >
        <button
          className='color-picker-close'
          onClick={() => {
            setIsPopoverOpen(false);
          }}
        >
          <i className="fa fa-times-circle" aria-hidden="true"></i>
        </button>
        <SketchPicker
          color={selectedColour}
          onChangeComplete={c => setSelectedColour(c.hex)}
          className="sketch-picker-new"
        />
        <div
          className="d-flex flex-column"
          style={{ justifyContent: 'space-around', padding: '0px 10px 10px' }}
        >
          <button
            className="btn btn-primary color-picker-add-button"
            onClick={() => {
              onChange(property, selectedColour);
              setIsPopoverOpen(false);
            }}
          >
            Select colour
          </button>
          <LinkButton
            className="color-picker-close-button"
            onClick={() => {
              setIsPopoverOpen(false);
            }}
          >
            Close
          </LinkButton>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`${className}`}
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'top'
      }}
    >
      <div
        className="d-flex flex-column justify-content-start brand-color-container"
        ref={triggerRef}
      >
        <Popover
          isOpen={isPopoverOpen}
          positions={['right', 'top', 'bottom', 'left']} // preferred positions by priority
          content={getColourPickerView()}
          reposition={false}
          align={
            370 > windowHeight - triggerY && windowHeight - triggerY > 230
              ? 'center'
              : windowHeight - triggerY > 370
              ? 'start'
              : 'end'
          }
        >
          {value ? (
            <div className="d-flex flex-column">
              <Color
                color={value}
                onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                onRemove={onRemove}
              />
            </div>
          ) : (
            <PlusButton
              large
              onClick={() => setIsPopoverOpen(!isPopoverOpen)}
            />
          )}
        </Popover>
        <span className="brand-color-title mt-1">{label}</span>
      </div>
    </div>
  );
}
