import React, { useEffect } from 'react';
import { Input } from '../../atoms/Input';
import { FieldError } from '../../atoms/FieldError';
import { get } from 'lodash';

export const FormRadioInput = ({
  name,
  type,
  rules,
  register,
  label,
  errors,
  options,
  defaultValue,
  ...props
}) => {
  const errorMessages = get(errors, name);
  const hasError = !!(errors && errorMessages);

  useEffect(() => {
    if (props.setValue && defaultValue) {
      props.setValue(name, defaultValue, { shouldTouch: true });
    }
  }, [defaultValue]);

  return (
    <div className="form-group">
      {options.map(({ labelClass, helpText, defaultChecked, ...option }) => (
        <div className="form-check mb-10" key={option.id} aria-live="polite">
          <Input
            name={name}
            {...option}
            {...(register && register(name, rules))}
            type={type}
            defaultChecked={defaultValue === option.value}
          />
          <label htmlFor={option.id} className="form-check-label">
            <p className={labelClass}>{option.label}</p>
            {helpText && <p className="">{helpText}</p>}
          </label>
        </div>
      ))}
      {!props.disableError && <FieldError errors={errors} name={name} />}
    </div>
  );
};
