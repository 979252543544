import React, { useEffect } from 'react';
import { useWatch } from 'react-hook-form';

import { FormTextArea } from 'components/molecules/FormTextArea';
import { FormInput } from 'components/molecules/FormInput';
import { getQueryObject } from './';

import { getSMSBrandMarketMappings } from 'services/smsBrandMapping';

export const OptOutTextInput = ({
  control,
  watchInput,
  errors,
  name,
  register,
  defaultValue,
  setValue
}) => {
  const [country, brand, language] = useWatch({
    control,
    name: watchInput
  });

  const existingValues = async query => {
    const data = await getSMSBrandMarketMappings(getQueryObject(query));
    if (data.length > 0) {
      const dataForCurrentLanguage = data.find(
        item =>
          item.language.languageCode.toLowerCase() ===
          language?.value?.toLowerCase()
      );
      setValue(
        `${name}.keyword`,
        dataForCurrentLanguage.language.optOutKeyword
      );
      setValue(`${name}.text`, dataForCurrentLanguage.language.optOutText);
    } else {
      setValue(`${name}.keyword`, '');
      setValue(`${name}.text`, '');
    }
  };

  useEffect(() => {
    if (country?.value && brand?.value && language?.value) {
      existingValues({
        brandCountry: [{ value: country.id }],
        brands: [{ value: brand.id, label: brand.label }],
        languageCodes: [{ value: language.value }]
      });
    }
  }, [country?.value, brand?.value, language?.value]);

  return (
    <div>
      <FormInput
        id="keyword"
        type="text"
        name={`${name}.keyword`}
        label="Opt-out keyword"
        key="text"
        maxlength={60}
        register={register}
        errors={errors}
        setValue={setValue}
      />

      <FormTextArea
        id="text"
        name={`${name}.text`}
        label="Opt-out text"
        maxlength={100}
        register={register}
        errors={errors}
        setValue={setValue}
      />
    </div>
  );
};
