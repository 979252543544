import React from 'react';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import EmailBriefingMasonryCard from './EmailBriefingMasonryCard/EmailBriefingMasonryCard';

function EmailBriefingsListMasonry({ isCleanTemplates, list, role }) {
  return (
    <div className="mt-20">
      <ResponsiveMasonry columnsCountBreakPoints={{ 991: 3, 1023: 4, 1200: 5 }}>
        <Masonry gutter={'15px'}>
          {list.map((elem = {}, index) => {
            return (
              <EmailBriefingMasonryCard
                isCleanTemplates={isCleanTemplates}
                key={elem._id}
                index={index}
                elem={elem}
                role={role}
              />
            );
          })}
        </Masonry>
      </ResponsiveMasonry>
    </div>
  );
}

export default EmailBriefingsListMasonry;
