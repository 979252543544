import React from 'react';

function LineBreak(props) {
  return props.paragraphs.split('\n').map((paragraph, key) => {
    return (
      <p key={key}>
        {paragraph}
        <br />
      </p>
    );
  });
}

export default LineBreak;
