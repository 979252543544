import React from 'react';
const QnANonSaasSubheadingText = props => {
  return (
    <>
      Q&amp;As enable you to gain insight on your consumer preferences and
      needs. Please read the guidelines before selecting or requesting questions.
      <br />
    </>
  );
};

export default QnANonSaasSubheadingText;
