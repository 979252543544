import React, { useState } from 'react';
import CampaignRejectionListModal from './components/CampaignRejectionListModal';

const CampaignRejectionList = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => setIsOpen(!isOpen);

  return (
    <div>
      <button
        className="inline-button"
        onClick={toggleModal}
        style={{ fontWeight: 'bold' }}
      >
        view rejection comments
      </button>
      <CampaignRejectionListModal isOpen={isOpen} onClose={toggleModal} />
    </div>
  );
};

export default CampaignRejectionList;
