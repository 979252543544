import axios from "axios";
import constant from "../constants";

import {
  GET_CAMPAIGN_DETAILS_CONSENTS,
  GET_ALL_CONSENTS_FOR_LOCALE,
  CONSENTS_ASYNC_IN_PROGRESS,
  GET_CONSENTS_FOR_SELECTED_VERSION,
  GET_SAAS_CONSENT_METADATA,
  ASYNC_IN_PROGRESS
} from "../actionTypes";

export function getCampaignDetails(campaignId) {
  return function(dispatch) {
    dispatch({ type: CONSENTS_ASYNC_IN_PROGRESS, isLoading: true });
    axios
      .get(constant.serviceUrls.CAMPAIGN_DETAILS_SERVICE_URL + "/" + campaignId)
      .then((response) => {
        let campaign = response.data[0];

        let countryCode = campaign.country.code;
        let brandCode = campaign.brand.code;
        let language = campaign.language.code;
        //let selectedVersion = campaign.getCampaignDetails.consentsVersion;
        dispatch(getAllConsentsForLocale(brandCode, countryCode, language));
        dispatch(getConsentFieldData(language));
        dispatch({ type: GET_CAMPAIGN_DETAILS_CONSENTS, campaign });
        dispatch({ type: CONSENTS_ASYNC_IN_PROGRESS, isLoading: false });
      });
  };
}

export function getConsentFieldData(language) {
  return function(dispatch) {
    dispatch({ type: ASYNC_IN_PROGRESS, isLoading: true });
    axios.get(constant.saasServiceUrls.FORM_FIELDS_METADATA+'?language='+language+'&campaignType=Direct').then(res => {
      const response = res.data;
      if(response && response.length > 0){
        const formConsentData =  response;  
        dispatch({ type: GET_SAAS_CONSENT_METADATA, formConsentData });
        dispatch({ type: ASYNC_IN_PROGRESS, isLoading: false });
      }
    });
  };
}

export function getAllConsentsForLocale(brand, country, language) {
  return function(dispatch) {
    axios
      .get(
        constant.consentsApi.HOST +
          constant.consentsApi.URL +
          "?country=" +
          country +
          "&language=" +
          language +
          "&brand=" +
          brand
      )
      .then((response) => {
        let allConsents =response.data;
        dispatch({ type: GET_ALL_CONSENTS_FOR_LOCALE, allConsents });
      });
  };
}

export function getConsentsForSelectedVersion(campaign, allConsents, version) {
  return function(dispatch) {
    dispatch({ type: CONSENTS_ASYNC_IN_PROGRESS, isLoading: true });
    let brandCode = campaign && campaign.brand && campaign.brand.code;
    let countryCode = campaign && campaign.country && campaign.country.code;

    let ver = version.label;
    let consentsForSelectedVersion = allConsents[ver];
    let defaultList = constant.consents;
    let selectedConsents = [];
    if (consentsForSelectedVersion && consentsForSelectedVersion.length > 0) {
      let consentObj = consentsForSelectedVersion[0];
      defaultList.map((elem) => {
        let key = elem.tsKey;
        if (consentObj[key]) {
          elem.defaultCopy = consentObj[key] ? consentObj[key] : "";
          if (elem.optInType === "brandEmailConsent") {
            elem.serviceId = "BRAND_" + countryCode + "_" + brandCode + "_EML";
          } else if (elem.optInType === "brandSMSConsent") {
            elem.serviceId = "BRAND_" + countryCode + "_" + brandCode + "_SMS";
          } else if (elem.optInType === "unileverEmailConsent") {
            elem.serviceId = "UNILEVER_" + countryCode + "_EML";
          } else if (elem.optInType === "unileverSMSConsent") {
            elem.serviceId = "UNILEVER_" + countryCode + "_SMS";
          }
          selectedConsents.push(elem);
        }
        return elem;
      });

      dispatch({ type: GET_CONSENTS_FOR_SELECTED_VERSION, selectedConsents });
      dispatch({ type: CONSENTS_ASYNC_IN_PROGRESS, isLoading: false });
    } else {
      dispatch({ type: GET_CONSENTS_FOR_SELECTED_VERSION, defaultList });
      dispatch({ type: CONSENTS_ASYNC_IN_PROGRESS, isLoading: false });
    }
  };
}
