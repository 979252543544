import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEmailCoreTemplates, getLiveEmailTemplates } from 'actions/emailbriefing/emailCoreTemplates';
import constants from 'constants/index';
import { getBrandCountries, getBrands } from 'actions/dashboard';
import EmailMultiSelectFilter from 'components/organisms/EmailMultiSelectFilter';
import SearchIcon from 'images/find.png';
import { getEmailRowBlock } from 'actions/emailbriefing/emailCoreRaws';
import ToggleButton from 'pages/EmailCampaignTemplate/ToggleButton';
import { get } from 'lodash';

const getSortedFiltersData = (filterList, item, p1 = []) => {
  const list = [...filterList]
  const priority = {}
  // create priority list
  for(let i = 0; i < p1.length; i++){
    priority[p1[i]] = i
  }
  if(item){
    priority[item.code] = p1.length
  }
  list.sort((a,b) => priority.hasOwnProperty(b.code) - priority.hasOwnProperty(a.code)
  || priority[a.code] - priority[b.code]
  )
  return list
}

const EmailTemplateSearch = ({ id, ...props }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [resetId, setResetId] = useState(0);
  const {
    visible = true,
    collapsible,
    setIsVisible,
    enteredText,
    setEnteredText,
    userinfo,
    setUserInfo,
    handleChange,
    isRowSearch,
    emailBriefing,
    isEmailCollection,
    isEmailTemplate,
    resetFilterPills = () => {}
  } = props;
  const dispatch = useDispatch();
  /**
   * Trigger search
   * @param {*} query
   */
  const handleSearch = () => {
    if (props.isRawShow) {
      dispatch(getEmailRowBlock({ filter: userinfo, text: enteredText }));
    } else {
      if(isEmailTemplate) {
        const {uid} = emailBriefing || {}
        dispatch(getLiveEmailTemplates({ filter: userinfo, text: enteredText },'',uid))
      } else {
        dispatch(getEmailCoreTemplates({ filter: userinfo, text: enteredText }));
      }
    }
  };
  /**
   * Reset query
   * @param {*} evt
   */
  const clearFilter = evt => {
    console.log('clear')
    let resetObject = {
      emailType: [],
      countries: [],
      brands: [],
      templateContext: [],
      templateAttributes: [],
      rawCategories: [],
      isEmailCollection: isEmailCollection ? true : false
    };
    setUserInfo(resetObject);
    resetFilterPills();
    dispatch(getEmailCoreTemplates({ filter: resetObject, text: '' }));
    dispatch(getEmailRowBlock({ filter: resetObject, text: '' }));
    setEnteredText('');
    // to reset the text in multi select filters
    setResetId(Math.floor((Math.random() * 100) + 1))
  };

  /**
   * on load get brand and countries data required for filter
   */
  useEffect(() => {
    dispatch(getBrandCountries());
    dispatch(getBrands());
  }, []);

  useEffect(() => {
    if (!userinfo?.isFirstLoad) {
      handleSearch();
      setEnteredText(enteredText);
    }
  }, [userinfo]);

  useEffect(() => {
    if (props.isReset) {
      clearFilter();
      props.resetTrigger();
    }
  }, [props.isReset]);

  const { emailCoreTemplates, countries, brands, emailCoreRaws } = useSelector(
    state => ({
      emailCoreTemplates: state.emailCoreTemplates,
      countries: state.dashboard.countries,
      brands: state.dashboard.brands,
      emailCoreRaws: state.emailCoreRaws
    })
  );

  const sortedFilters = useMemo(() => {
    if(emailBriefing && !isEmailCollection){ 
      const emailCountry = get(emailBriefing, "country");
      const emailBrand = get(emailBriefing, 'brand');
      return {
        countries: getSortedFiltersData(countries, emailCountry, userinfo?.countries),
        brands: getSortedFiltersData(brands, emailBrand, userinfo?.brands)
      }
    }
    return {
      countries: getSortedFiltersData(countries, null, userinfo?.countries),
      brands:  getSortedFiltersData(brands, null, userinfo?.brands)
    }
  }, [emailBriefing, countries, brands, userinfo])

  if (!visible) {
    return (
      <div className="email-template-search container" style={{ padding: 0 }}>
        <ToggleButton visible={visible} setIsVisible={setIsVisible} />
      </div>
    );
  }

  return (
    <>
      {/* search textbox */}
      <div className="email-template-search container">
        {/* search filter - Email Type */}
        <div className="email-template-search-box">
          <div className="cw-listing d-flex flex-grow-1 align-items-center">
            <div className="d-flex flex-grow-1 align-items-center">
              <div className="input-clear">
                <input
                  className="search-input cross"
                  onChange={e => setEnteredText(e.target.value)}
                  placeholder={
                    isRowSearch ? 'Search for tiles' : 'Search for templates'
                  }
                  autoComplete="off"
                  value={enteredText}
                  onKeyPress={e =>
                    e.key === 'Enter' && enteredText ? handleSearch() : null
                  }
                />
                {enteredText.trim().length > 0 && (
                  <span
                    className="input-clear__cross"
                    onClick={() => setEnteredText('')}
                  >
                    <i className="fas fa-times"></i>
                  </span>
                )}
              </div>
              <button className="search-button" onClick={handleSearch}>
                <img src={SearchIcon} alt="Search" />
              </button>
            </div>
          </div>
        </div>
        {collapsible ? (
          <ToggleButton visible={visible} setIsVisible={setIsVisible} />
        ) : null}
        <div className="row email-template-search__info">
          <div className="col-md-9">
            {emailCoreTemplates.data.type === 'fallback_blank' ? (
              <span className="email-template-search__info--text">
                0 {isRowSearch ? 'tiles' : 'templates'}
              </span>
            ) : null}
            {emailCoreTemplates.data.type === 'api_success' ? (
              <span className="email-template-search__info--text">
                {isRowSearch
                  ? emailCoreRaws.data &&
                    emailCoreRaws.data.pagination &&
                    emailCoreRaws.data.pagination.total
                  : emailCoreTemplates.data.data.pagination.total}{' '}
                {isRowSearch ? 'tiles' : 'templates'}
              </span>
            ) : null}
          </div>
          <div className="col-md-3">
            {emailCoreTemplates?.data?.isFilter 
              || (isEmailTemplate && emailCoreTemplates?.data?.data?.isFilter) 
              || emailCoreRaws?.data?.isFilter 
              ? (
                  <button
                    className="cw-link-btn cw-formdraft-link"
                    onClick={clearFilter}
                  >
                    Clear all
                  </button>
                ) 
              : null}
          </div>
        </div>
        {/* search filter - Email Type */}
        {props.isRawShow ? null : (
          <div className="email-template-filter">
            {/* {Object.keys(constants.emailType).map(item => {return {[item]:constants.emailType[item]}})} */}
            <EmailMultiSelectFilter
              filterListData={constants.EMAIL_TYPE_OPTIONS}
              onChangeHandler={handleChange}
              fieldname="emailType"
              heading="Email Type"
              hideSearch={true}
              count={userinfo?.emailType?.length || 0}
              filters={userinfo?.emailType}
              collapse={false}
              isActive={activeIndex === 0}
              onShow={() => setActiveIndex(activeIndex === 0 ? -1 : 0)}
              resetText={resetId}
            />
          </div>
        )}
        
        {/* search filter - Template context */}
        {!isEmailTemplate && <div className="email-template-filter">
          <EmailMultiSelectFilter
            filterListData={constants.EMAIL_TEMPLATE_CONTEXT}
            onChangeHandler={handleChange}
            fieldname="templateContext"
            heading={isRowSearch ? 'Tile type' : 'Template context'}
            hideSearch={true}
            count={userinfo?.templateContext?.length || 0}
            filters={userinfo?.templateContext}
            collapse={true}
            isActive={activeIndex === (isRowSearch ? 0 : 1)}
            onShow={() =>
              setActiveIndex(
                activeIndex === (isRowSearch ? 0 : 1) ? -1 : isRowSearch ? 0 : 1
              )
            }
            resetText={resetId}
          />
        </div>}

        {props.isRawShow ? (
          <div className="email-template-filter">
            <EmailMultiSelectFilter
              filterListData={constants.EMAIL_SAVE_ROW_CATEGORIES}
              onChangeHandler={handleChange}
              fieldname="rawCategories"
              heading="Tile category"
              hideSearch={true}
              count={userinfo?.rawCategories?.length || 0}
              filters={userinfo?.rawCategories}
              collapse={true}
              isActive={activeIndex === 1}
              onShow={() => setActiveIndex(activeIndex === 1 ? -1 : 1)}
              resetText={resetId}
            />
          </div>
        ) : null}

        {/* search filter - Country */}
        <div className="email-template-filter">
          <EmailMultiSelectFilter
            filterListData={sortedFilters.countries}
            onChangeHandler={handleChange}
            fieldname="countries"
            heading="Countries"
            placeholder="Search for country.."
            count={userinfo?.countries?.length || 0}
            filters={userinfo?.countries}
            collapse={true}
            isActive={activeIndex === 2}
            onShow={() => setActiveIndex(activeIndex === 2 ? -1 : 2)}
            resetText={resetId}
          />
        </div>
        {/* search filter - Brand */}
        <div className="email-template-filter">
          <EmailMultiSelectFilter
            filterListData={sortedFilters.brands}
            onChangeHandler={handleChange}
            fieldname="brands"
            heading="Brands"
            placeholder="Search for brands.."
            count={userinfo?.brands?.length || 0}
            filters={userinfo?.brands}
            collapse={true}
            isActive={activeIndex === 3}
            onShow={() => setActiveIndex(activeIndex === 3 ? -1 : 3)}
            resetText={resetId}
          />
        </div>

        {/* search filter - Attributes */}
        {!isRowSearch && <div className="email-template-filter">
          <EmailMultiSelectFilter
            filterListData={constants.EMAIL_ATTRIBUTES}
            onChangeHandler={handleChange}
            fieldname="templateAttributes"
            heading="Email attributes"
            hideSearch={true}
            count={userinfo?.templateAttributes?.length || 0}
            filters={userinfo?.templateAttributes}
            collapse={false}
            isActive={activeIndex === 4}
            onShow={() => setActiveIndex(activeIndex === 4 ? -1 : 4)}
            resetText={resetId}
          />
        </div>}
      </div>
    </>
  );
};

export default EmailTemplateSearch;
