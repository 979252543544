import { SET_SELECTED_TEMPLATE } from '../../actionTypes';
import { createEmailTemplateEdit } from '../../services/emailTemplateEdit';

export function setSelectedTemplate({
  coreTemplateId,
  uid,
  userEmail,
  userName,
  status,
  isEmailTemplate
}) {
  return async dispatch => {
    const templateEdit = await createEmailTemplateEdit({
      coreTemplateId,
      uid,
      userEmail,
      userName,
      status,
      isEmailTemplate
    });
    dispatch({
      type: SET_SELECTED_TEMPLATE,
      payload: templateEdit
    });
  };
}

export function flushSelectedTemplate() {
  return {
    type: SET_SELECTED_TEMPLATE,
    payload: {}
  };
}
