import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import { getGSMandUnicodeStats } from 'utilities/util';

const SMSContentAlert = ({ smsCampaign }) => {
  const [mesg, setMesg] = useState(null);
  const { communicationDetail = {}, optOutText } = smsCampaign;

  useEffect(() => {
    const content = get(communicationDetail, 'smsDetails.[0].smsContent');
    const stats = getGSMandUnicodeStats(
      optOutText ? content + ` ${optOutText}` : content
    );
    if (stats) {
      if (stats.hasUnicode) {
        if (stats.segments > 1) {
          setMesg(
            `Your SMS has atleast one Unicode character and has exceeded max length per Unicode message. Your message now has '${stats.segments}' parts with a reduced limit of ${stats.segmentLimit} characters per part. You will be charged for ${stats.segments} messages per consumer`
          );
        } else {
          setMesg(null);
        }
      } else {
        if (stats.segments > 1) {
          setMesg(
            `Your SMS has exceeded max length per message. Your message now has '${stats.segments}' parts with a reduced limit of ${stats.segmentLimit} characters per part. You will be charged for ${stats.segments} messages per consumer`
          );
        } else {
          setMesg(null);
        }
      }
    } else {
      setMesg(null);
    }
  }, [smsCampaign]);

  return mesg ? (
    <div className={`alert alert-warning d-flex`} role="alert">
      <i className="fas fa-exclamation-circle pt-5 mr-10"></i>
      <span>{mesg}</span>
    </div>
  ) : null;
};

export default SMSContentAlert;
