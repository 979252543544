/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, Fragment } from 'react';
class Pagination extends Component {
  getPageNumbers = () => {
    const { totalRecords, limit } = this.props;
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(totalRecords / limit); i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  };

  _handleDisableClick = event => {
    event.preventDefault();
    return false;
  };
  _handleClick = event => {
    event.preventDefault();

    const { onPageChange } = this.props;

    const pageNumber = parseInt(event.target.dataset.value);
    const pageNumbers = this.getPageNumbers();
    if (pageNumbers.length <= 1) {
      return false;
    }
    onPageChange(pageNumber);
  };

  render() {
    const {
      itemLabel = 'Campaigns',
      pageNumber,
      totalRecords,
      limit,
      recordShowStart,
      recordShowto
    } = this.props;

    const pageNumbers = this.getPageNumbers();
    const numberOfPages = pageNumbers.length;
    const renderPageNumbers = pageNumbers.map(number => {
      return (
        <Fragment key={number}>
          {numberOfPages > 5 &&
          number > pageNumber - 3 &&
          number < pageNumber + 3 ? (
            <li
              className={`page-item ${
                pageNumber === number ? 'active ' : 'cursor-pointer'
              }`}
              key={number}
            >
              <a
                className="page-link"
                href={number}
                tabIndex="-1"
                data-value={number}
                onClick={this._handleClick}
              >
                {number}
              </a>
            </li>
          ) : number <= 5 && numberOfPages <= 5 ? (
            <li
              className={`page-item ${pageNumber < 6 ? '' : 'd-none'} ${
                pageNumber === number ? 'active' : 'cursor-pointer'
              }`}
              key={number}
            >
              <a
                className="page-link"
                href={number}
                tabIndex="-1"
                data-value={number}
                onClick={this._handleClick}
              >
                {number}
              </a>
            </li>
          ) : null}
        </Fragment>
      );
    });
    const showItemPerPage = () => {
      let stringForPagination =
        'Showing ' +
        recordShowStart +
        ' to ' +
        recordShowto +
        ' of ' +
        totalRecords +
        ` ${itemLabel}`;
      return stringForPagination;
    };
    const lastPageNumber = Math.ceil(totalRecords / limit);
    return (
      <div className="cw-pagination mt-3 d-flex align-items-center justify-content-between">
        <div className="cw-pagination--status">
          {showItemPerPage(totalRecords)}
        </div>
        <nav
          aria-label="Pagination"
          className="justify-content-center d-flex"
          style={{ zIndex: 0, flex: 1 }}
        >
          <ul className="pagination pagination-sm">
            <li
              className={`page-item ${pageNumber <= 1 ? 'disabled' : ''}`}
              value="1"
              key="First"
              name="First"
            >
              <a
                className="page-link"
                href="#"
                tabIndex="-1"
                data-value="1"
                onClick={this._handleClick}
              >
                First
              </a>
            </li>
            <li
              className={`page-item ${pageNumber <= 1 ? 'disabled' : ''}`}
              name="Previous"
              value={`${pageNumber - 1}`}
            >
              <a
                className="page-link"
                href="#"
                tabIndex="-1"
                data-value={`${pageNumber - 1}`}
                onClick={this._handleClick}
              >
                Previous
              </a>
            </li>
            <li
              className={`page-item ${
                numberOfPages > 5
                  ? pageNumber <= 3
                    ? 'd-none disabled'
                    : ''
                  : 'd-none disabled'
              }`}
            >
              <p className="page-link">...</p>
            </li>
            {renderPageNumbers}
            <li
              className={`page-item ${
                pageNumber >= numberOfPages - 2 || numberOfPages <= 5
                  ? 'd-none disabled'
                  : ''
              }`}
            >
              <p className="page-link">...</p>
            </li>
            <li
              className={`page-item ${
                pageNumber >= lastPageNumber ? 'disabled' : ''
              }`}
              name="Next"
              value={pageNumber + 1}
            >
              <a
                className="page-link"
                href="#"
                tabIndex="-1"
                data-value={pageNumber + 1}
                onClick={this._handleClick}
              >
                Next
              </a>
            </li>
            <li
              className={`page-item ${
                pageNumber >= lastPageNumber ? 'disabled' : ''
              }`}
              name="Last"
              value={lastPageNumber}
            >
              <a
                className="page-link"
                href="#"
                tabIndex="-1"
                data-value={lastPageNumber}
                onClick={this._handleClick}
              >
                Last
              </a>
            </li>
          </ul>
        </nav>
        <div style={{ flex: 1 }}></div>
      </div>
    );
  }
}

export default Pagination;
