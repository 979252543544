import React, { forwardRef } from 'react';

export const Image = forwardRef(
  ({ src, className, alt, label, ...props }, ref) => {
    return (
      <img
        src={
          src ||
          'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII='
        }
        className={className || ''}
        alt={alt || 'Image'}
        aria-label={label || ''}
        {...props}
      />
    );
  }
);
