import React from 'react';
import constant from '../../../constants';
import constants from 'constants/config';
import ReactTooltip from 'react-tooltip';

const IConfirm = ({
  iConfirmQnA,
  _handleCheckboxConfirm,
  isOpenGuidelineModel
}) => (
  <div
    className={`iconfirm form-check ${
      constant.featureFlags.ENABLE_QNA_GUIDELINES_CHECKBOX ? '' : 'cw-hidden'
    }`}
  >
    <input
      className="form-check-input"
      type="checkbox"
      value={iConfirmQnA}
      id="iconfirm"
      name="iconfirm"
      checked={iConfirmQnA}
      onChange={e => _handleCheckboxConfirm(e)}
    />
    <label className="form-check-label" htmlFor="iconfirm">
      I confirm I have not requested a&nbsp;
      <ReactTooltip
        place="right"
        id="PIIquestion"
        type="info"
        multiline={true}
        className="cw-tooltip"
      />
      <a
        href="#"
        data-for="PIIquestion"
        data-tip={constants.PII_FIELDS_DESCRIPTION}
      >
        PII question
      </a>
      &nbsp;or a duplicate question worded differently, as indicated in the{' '}
      <a href="#" onClick={isOpenGuidelineModel}>
        guidelines
      </a>
      .
    </label>
  </div>
);

export default IConfirm;
