import axios from 'axios';
import constant from '../constants';

function paramsSerializer(params) {
  return Object.entries(Object.assign({}, params))
    .map(([key, value]) => `${key}=${value}`)
    .join('&');
}

export async function getEmailRowBlockService(query, pageNumber) {
  let filter =
    typeof query === 'object'
      ? '?search=' + query.text + '&' + paramsSerializer(query.filter)
      : query
      ? '?search=' + query
      : '';
  filter += `${filter ? '&' : '?'}page=${pageNumber}`;
  const response = await axios.get(
    `${constant.serviceUrls.EMAIL_BEE_GET_ROWS_URL}/raw/list` + filter
  );
  return response.data;
}

export async function deleteEmailRowService(id) {
  try {
  const response = await axios.get(
    `${constant.serviceUrls.EMAIL_BEE_GET_ROWS_URL}/${id}/delete`
  );
  return response.data; 
  } catch (error) {
    throw error
  }
}

export async function regenerateRowThumbnailService(id) {
  const response = await axios.get(
    `${constant.serviceUrls.EMAIL_BEE_GET_ROWS_URL}/${id}/regenerate-thumbnail`
  );
  return response.data;
}
