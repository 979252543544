import React, { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { ReactComponent as MobileArrow } from '../../../images/mobile-left-arrow.svg';
import { ReactComponent as MobileUser } from '../../../images/mobile-user-icon.svg';
import classNames from 'classnames';
import SmsActionBar from '../SmsActionBar/SmsActionBar';
import constants from 'constants/config';
import { get } from 'lodash';
import { getGSMandUnicodeStats } from 'utilities/util';
import { stat } from 'fs-extra';

const SpanComp = ({text, bgcolor}) => {
  return (<span style={{backgroundColor: bgcolor}}>{ReactHtmlParser(text)}</span>)
}

const MobileDevicePreview = ({
  data,
  className,
  campaign,
  isPreviewOverlay,
  showActions = false,
  onClickEdit,
  isDesignPage,
  alignHeight
}) => {
  const [showSMSParts, setShowSMSparts] = useState(false);

  const processText = (text,shortURL) => {
    return text
      .replace(/\[FName\]/g, 'Emily')
      .replace(/\[Coupon\]/g, 'A10OFF')
      .replace(
        `[${shortURL}]`,
        () => `<a href="">${shortURL ? shortURL :constants.SAMPLE_BITLY_URL}</a>`
      ).replace(
        `[${constants.SAMPLE_BITLY_URL}]`,
        () => `<a href="">${constants.SAMPLE_BITLY_URL}</a>`
      )
      .replace(/(?:[-+()]*\d){4,13}/g, number => `<a href="">${number}</a>`)
      .replace(/\n/g, `<br/>`)
  };

  const cleanText = (text, shortURL) => {
    return text
      .replace(/\[FName\]/g, 'Emily')
      .replace(/\[Coupon\]/g, 'A10OFF')
      .replace(
        `[${constants.SAMPLE_BITLY_URL}]`,
        () => `${constants.SAMPLE_BITLY_URL}`
      )
      .replace(
        `[${shortURL}]`,
      () => `${shortURL ? shortURL : constants.SAMPLE_BITLY_URL}`)
  };

  const optOutText = optOutTextFromCampaign => {
    const { status, optOutText } = campaign || {};
    if (status == 1) {
      return optOutText ? optOutText : '';
    } else {
      return optOutTextFromCampaign;
    }
  };

  let schduleDate = ''
  if(get(campaign, 'deliverySchedule.smsTrigger.details.triggerCondition.value') === 'fixedDateInBirthdayMonth') {
   schduleDate = 'Trigger on fixed date'
  }

  const renderlogic = (text, optOutText, gstats, givenShortURL) => {
    const fulText = cleanText(optOutText? text + " " + optOutText :  text || '', givenShortURL);
    const list = [];
    const colors = ['#E0FFFF', '#FBCEB1','#D0F0C0', '#E6E6FA'];
    if (gstats.numCharacters > gstats.limit) {
      let count = 0;
      let colorIndex = 0;
      for(let i = 0; i < gstats.segments; i++){
        const endPos = count + gstats.segmentLimit;
        const subStrSections = fulText.substring(count, endPos)
        list.push(subStrSections)
        count = count + gstats.segmentLimit
      }
      const contentList = list.map((elem, index) => {
        const v = <SpanComp text={elem} bgcolor={colors[colorIndex]} key={`${elem.length}-${index}`}/>
        if(colorIndex === colors.length - 1){
          colorIndex = 0;
        } else {
          colorIndex = colorIndex + 1;
        }
        return v;
      })
      return contentList;
    } else {
      return ReactHtmlParser(processText(fulText))
    }
  }

  const getStats = (list) => {
    if(list && list.length){
      let statList = [];

      list.forEach(item => {
        const statsItem = getGSMandUnicodeStats(optOutText(item.optOutText)? item.text + " " + optOutText(item.optOutText) : item.text);
        statList.push(statsItem)
      })
      return statList; 
    } else {
      return [{
        numCharacters: 0,
        limit : 160
      }]
    }
  }

  const stats = getStats(data);

  const showToggle = stats && stats.length && (stats[0].numCharacters > stats[0].limit);

  return (
    <div className={classNames('mobile-device-prev', className)} style={{ marginTop: showActions ? -53 : (showToggle && isDesignPage)? -32: (showToggle && alignHeight)? -53: 0 }}>
      <SmsActionBar showActions={showActions || alignHeight} showToggle={showToggle} onClickEdit={onClickEdit} showSMSParts={showSMSParts} setShowSMSparts={setShowSMSparts} />
      <div className="device-frame">
        <div className="mob-header">
          <div className="arrow-box">
            <MobileArrow className="arrow-icon" />
          </div>
          <div className="user-box">
            <MobileUser />
            <span className="mob-number">&#43;91 1234567890</span>
          </div>
          <div className="mob-space-box"></div>
        </div>
        <div className="mob-content-area">
          <div className="samp-text">Text Message</div>
          {data && data.length ? (
            data.map((item, index) => {
              return (
                <div className="sms-box" key={index}>
                  <div className="sms-date">{schduleDate ? schduleDate : item.date}</div>
                  <div className="sms-content">
                  {showSMSParts? renderlogic(item.text, optOutText(item.optOutText), stats[index], item.shortURL) :
                    <>
                    {ReactHtmlParser(optOutText(item.optOutText)? processText(item.text, item.shortURL) + " " + processText(optOutText(item.optOutText)): processText(item.text, item.shortURL))}
                    </>}
                  </div>
                </div>
              );
            })
          ) : (
            <div className="sms-box">
              <div className="sms-content"></div>
            </div>
          )}
        </div>
      </div>
      <div className="mobile-preview-note">
        <p>
          <strong>Note :</strong> This is an illustrative preview. Actual
          renditions on device may vary. Any dynamic placeholders have been
          replaced with sample values to show a more realistic preview.
        </p>
      </div>
    </div>
  );
};

export default MobileDevicePreview;
