import {
    EMAIL_DESIGN_ASYNC_INPROGRESS,
    SET_EMAIL_BREIFINGS,
    UPDATE_EMAIL_BREIFINGS_STORE,
    RESET_EMAIL_BREIFINGS
  } from '../../actionTypes';
  import { updateEmailBriefing, getEmailBriefingbyUid } from 'services/emailBriefings';
  // import { transformEmailBriefingData } from 'selectors';
  
  
  export const setEmailBriefings = emailBriefing => ({
    type: SET_EMAIL_BREIFINGS,
    emailBriefing
  });
  
  export const setEmailDesignLoader = payload => ({
    type: EMAIL_DESIGN_ASYNC_INPROGRESS,
    isLoading: payload
  });

  export const updateEmailBriefingsStore = payload => ({
    type: UPDATE_EMAIL_BREIFINGS_STORE,
    payload
  });
  
//   export const updateEmailCampaignDetails = (uid, data, callback) => {
//     return function(dispatch) {
//         let dataToSend = {
//           ...data,
//         }
//         console.log('dataToSend: ', dataToSend);
//         dispatch(setEmailCampaignDetailsLoader(true))
//         updateEmailBriefing(uid, dataToSend).then(result => {
//           dispatch(setEmailCampaignDetailsLoader(false))
//           callback(null,result)
//           // dispatch(setEmailCampaignDetails({...data, uid}));
//         }).catch(error => {
//           dispatch(setEmailCampaignDetailsLoader(false))
//           callback(error, null)
//         })
//     }
//   }
  
  export const getEmailBriefing = (uid,{disableLoader} = {}) => {
    return function (dispatch) {
        if(!disableLoader){
          dispatch(setEmailDesignLoader(true))
        }
        getEmailBriefingbyUid(uid).then(result => {
          if(!disableLoader){
            dispatch(setEmailDesignLoader(false))
          }
          dispatch(setEmailBriefings(result.data));
        }).catch(error => {
          if(!disableLoader){
            dispatch(setEmailDesignLoader(false))
          }
          console.log("getEmailCampaignDetails error :", error)
        })
    }
  }

  export const resetEmailBriefingsDesign = () => ({
    type: RESET_EMAIL_BREIFINGS,
  });
  