import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import Loader from './Loader';
import { fetchCampaignData, saveEditorPermissions } from '../actions/overview';
import { getCampaignDetails } from '../actions/questionsAnswers';
import constant from '../constants';
const EditorManagement = props => {
  let emailAddressList = [{ email: '', id: 0, isNewUser: false }];
  const dispatch = useDispatch();
  const [users, setUsers] = useState();
  const [addedEmail, setAddedEmail] = useState('');
  const [finalEmailList, setFinalEmailList] = useState([]);
  const [matchProps, setMatchProps] = useState(props.match);
  const [showLoader, setShowLoader] = useState(false);
  const [validDomainList, setValidDomainList] = useState([]);
  useEffect(() => {
    dispatch(getCampaignDetails(props.match.params.id));
    dispatch(fetchCampaignData(props.match.params.id, false, false));
    setUpdatedValue(props.match.params.id);
    getValidDomainList();
  }, []);

  let getValidDomainList = async () => {
    await axios
      .get(constant.serviceUrls.REQUESTER_DOMAIN_URL)
      .then(response => {
        let responseData = response.data;
        let domainList = responseData.map(elem => elem.domain);
        setValidDomainList(domainList);
      });
  };

  let setUpdatedValue = async Id => {
    setShowLoader(true);
    await axios
      .get(constant.serviceUrls.CAMPAIGN_MASTER_SERVICE_URL + '/' + Id)
      .then(response => {
        let campaign = response.data[0];
        let { campaignDetail } = campaign;
        let users = campaignDetail && campaignDetail.users;
        let isEveryoneEditor =
          campaignDetail && campaignDetail.isEveryoneEditor;
        if (users && users.length > 0 && !isEveryoneEditor) {
          setUsers(constant.editorPermissions.SELECTED_USERS);
          setFinalEmailList(users);
        }
        if (isEveryoneEditor) {
          setUsers(constant.editorPermissions.EVERYONE);
        }
        if (!isEveryoneEditor && users && users.length === 0) {
          setUsers(constant.editorPermissions.ONLY_REQUESTOR);
        }
      });
    setShowLoader(false);
  };

  let onChangeRadio = (event, authorizedUsers) => {
    setUsers(authorizedUsers);
    if (authorizedUsers !== constant.editorPermissions.SELECTED_USERS) {
      setFinalEmailList([]);
      document.getElementById(`email_address`).value = '';
    }
    if (
      document.getElementById(`email_text`) &&
      !document.getElementById(`email_text`).classList.contains('cw-hidden')
    ) {
      document.getElementById(`email_text`) &&
        document.getElementById(`email_text`).classList.add('cw-hidden');
    }
    if (
      document.getElementById(`email_address`) &&
      document
        .getElementById(`email_address`)
        .classList.contains('cw-error-focus')
    ) {
      document.getElementById(`email_address`) &&
        document
          .getElementById(`email_address`)
          .classList.remove('cw-error-focus');
    }
    if (
      document.getElementById(`email_duplicate`) &&
      !document
        .getElementById(`email_duplicate`)
        .classList.contains('cw-hidden')
    ) {
      document.getElementById(`email_duplicate`) &&
        document.getElementById(`email_duplicate`).classList.add('cw-hidden');
    }
    if (
      document.getElementById(`email_invalid`) &&
      !document.getElementById(`email_invalid`).classList.contains('cw-hidden')
    ) {
      document.getElementById(`email_invalid`) &&
        document.getElementById(`email_invalid`).classList.add('cw-hidden');
    }
  };

  let _deleteAnswer = (event, index) => {
    let currentEmailList = [...finalEmailList];
    let i = currentEmailList.findIndex(elem => elem.id === index);
    currentEmailList.splice(i, 1);
    setFinalEmailList([...currentEmailList]);
    document.getElementById('add-email').style.pointerEvents = 'auto';
  };

  let addEmailAddress = (event, index) => {
    let regexPattern = constant.regex['email'];
    if (
      document.getElementById(`email_duplicate`) &&
      !document
        .getElementById(`email_duplicate`)
        .classList.contains('cw-hidden')
    ) {
      document.getElementById(`email_duplicate`) &&
        document.getElementById(`email_duplicate`).classList.add('cw-hidden');
    }
    if (
      document.getElementById(`email_address`) &&
      document.getElementById(`email_address`).value.trim().length > 0 &&
      regexPattern.test(document.getElementById(`email_address`).value)
    ) {
      //  if(document.getElementById(`email_address`).value.includes('@unilever.com')){
      let emailList = {
        email: addedEmail,
        id: parseInt(Date.now() * Math.random()),
        isNewUser: false
      };

      if (
        document.getElementById(`email_text`) &&
        !document.getElementById(`email_text`).classList.contains('cw-hidden')
      ) {
        document.getElementById(`email_text`) &&
          document.getElementById(`email_text`).classList.add('cw-hidden');
      }
      if (
        document.getElementById(`email_invalid`) &&
        !document
          .getElementById(`email_invalid`)
          .classList.contains('cw-hidden')
      ) {
        document.getElementById(`email_invalid`) &&
          document.getElementById(`email_invalid`).classList.add('cw-hidden');
      }
      if (
        document.getElementById(`email_address`) &&
        document
          .getElementById(`email_address`)
          .classList.contains('cw-error-focus')
      ) {
        document.getElementById(`email_address`) &&
          document
            .getElementById(`email_address`)
            .classList.remove('cw-error-focus');
      }
      if (finalEmailList.find(o => o.email === emailList.email) !== undefined) {
        document.getElementById(`email_duplicate`) &&
          document
            .getElementById(`email_duplicate`)
            .classList.remove('cw-hidden');
        document.getElementById(`email_address`) &&
          document
            .getElementById(`email_address`)
            .classList.add('cw-error-focus');
      } else if (emailList.email.split('@')[1] === 'unilever.com') {
        addEmail(emailList);
      } else if (validDomainList.includes(emailList.email.split('@')[1])) {
        addEmail(emailList);
      } else {
        IsEmailValid(emailList);
      }
      //  }
    } else {
      if (
        document.getElementById(`email_invalid`) &&
        document.getElementById(`email_invalid`).classList.contains('cw-hidden')
      ) {
        document.getElementById(`email_invalid`) &&
          document
            .getElementById(`email_invalid`)
            .classList.remove('cw-hidden');
      }
      if (
        document.getElementById(`email_address`) &&
        !document
          .getElementById(`email_address`)
          .classList.contains('cw-error-focus')
      ) {
        document.getElementById(`email_address`) &&
          document
            .getElementById(`email_address`)
            .classList.add('cw-error-focus');
      }
    }
  };
  let addEmail = emailList => {
    finalEmailList.push(emailList);
    setFinalEmailList([...finalEmailList]);
    document.getElementById(`email_address`).value = '';
  };
  let IsEmailValid = emailList => {
    let datatoSubmit = {
      emailId: emailList.email,
      lastUpdatedScreen: 'manage-editors'
    };
    document.getElementById(`email-loader`).classList.remove('cw-hidden');
    document.getElementById('add-email').style.pointerEvents = 'none';
    axios
      .post(constant.serviceUrls.CAMPAIGN_GET_USER_URL, datatoSubmit)
      .then(response => {
        if (response.data.data.emailId === emailList.email) {
          addEmail(emailList);
          document.getElementById(`email-loader`).classList.add('cw-hidden');
          document.getElementById('add-email').style.pointerEvents =
            finalEmailList.length >= 10 ? 'none' : 'auto';
        } else {
          displayInvalidEmailError();
        }
      })
      .catch(err => {
        displayInvalidEmailError();
      });
  };
  let displayInvalidEmailError = () => {
    document.getElementById(`email-loader`).classList.add('cw-hidden');
    document.getElementById('add-email').style.pointerEvents = 'auto';
    document.getElementById(`email_invalid`) &&
      document.getElementById(`email_invalid`).classList.remove('cw-hidden');
    document.getElementById(`email_address`) &&
      document.getElementById(`email_address`).classList.add('cw-error-focus');
  };
  let handleEmailAddress = event => {
    let enteredAddress = event.target.value;
    document.getElementById(`email_text`) &&
      document.getElementById(`email_text`).classList.add('cw-hidden');
    document.getElementById(`email_address`) &&
      document
        .getElementById(`email_address`)
        .classList.remove('cw-error-focus');
    setAddedEmail(enteredAddress.toLowerCase());
  };

  let _handleSubmit = () => {
    let isEveryOneEditor =
      users === constant.editorPermissions.EVERYONE ? true : false;
    let onlyRequestor =
      users === constant.editorPermissions.ONLY_REQUESTOR ? true : false;
    let editorList = [];
    if (onlyRequestor || isEveryOneEditor) {
      dispatch(
        saveEditorPermissions(
          props.match.params.id,
          isEveryOneEditor,
          editorList,
          loggedInUser ? loggedInUser : '',
          loggedInUserName ? loggedInUserName : ''
        )
      );
      props.history.push('/campaign-wizard/campaign/' + props.match.params.id);
    }
    if (!isEveryOneEditor && !onlyRequestor) {
      editorList = finalEmailList;
      if (finalEmailList.length > 0) {
        dispatch(
          saveEditorPermissions(
            props.match.params.id,
            isEveryOneEditor,
            editorList,
            loggedInUser ? loggedInUser : '',
            loggedInUserName ? loggedInUserName : ''
          )
        );
        props.history.push(
          '/campaign-wizard/campaign/' + props.match.params.id
        );
      } else {
        document.getElementById(`email_text`) &&
          document.getElementById(`email_text`).classList.remove('cw-hidden');
        document.getElementById(`email_address`) &&
          document
            .getElementById(`email_address`)
            .classList.add('cw-error-focus');
      }
    }
  };
  let _handleCancel = event => {
    props.history.push('/campaign-wizard/campaign/' + props.match.params.id);
  };
  const campaignData = useSelector(state => ({
    campaign: state.overview.campaign,
    campaignDetails: state.overview.campaignDetails
  }));

  let { campaignDetails } = campaignData;
  let { authorizedUserData } = props;
  let requestorMail = campaignDetails && campaignDetails.requestersEmail;
  let loggedInUser = authorizedUserData && authorizedUserData.emailId;
  let loggedInUserName = authorizedUserData && authorizedUserData.name;

  let isEveryoneEditor = campaignDetails && campaignDetails.isEveryoneEditor;
  //let isCampaignOwner = requestorMail.toLowerCase() === loggedInUser.toLowerCase() ? true: false;
  return (
    <>
      {showLoader ? <Loader /> : null}
      <div className="col-sm-8">
        <div className="cw-section ml-20">
          <div className="row mb-30">
            <div className="col-sm-6">
              <NavLink
                className="cw-color--primary cw-breadcrum"
                to={constant.paths.HOMEPAGE}
              >
                <i className="fas fa-angle-left"></i> Campaigns
              </NavLink>
            </div>
          </div>
          <div className="cw-section--title mb-20">
            <h2 className="cw-heading--secondary mb-20">Manage Editors</h2>
            <p>
              <strong>
                Please choose who should be allowed to edit your form, should
                there be a need to, even after the CW processing is complete.
              </strong>
            </p>
          </div>
          <div className="row">
            <ReactTooltip
              place="right"
              type="info"
              multiline={true}
              className="cw-tooltip"
            />
            <div className="col-sm-8">
              <div className="cw-section--content">
                <div className="mr-10">
                  <div className="form-check mb-10">
                    {/* <p className="mb-10"></p> */}
                    <div className="form-check mb-10">
                      <input
                        type="radio"
                        className="form-check-input"
                        id="Everyone"
                        name="Everyone"
                        value={users}
                        checked={users === constant.editorPermissions.EVERYONE}
                        onClick={e =>
                          onChangeRadio(e, constant.editorPermissions.EVERYONE)
                        }
                      />
                      <label className="form-check-label" htmlFor="Everyone">
                        <span>{constant.editorPermissionsLabel.EVERYONE}</span>
                      </label>
                    </div>
                    <div className="form-check mb-10">
                      <input
                        type="radio"
                        className="form-check-input"
                        id="onlyMe"
                        name="onlyMe"
                        value={users}
                        checked={
                          users === constant.editorPermissions.ONLY_REQUESTOR
                        }
                        onClick={e =>
                          onChangeRadio(
                            e,
                            constant.editorPermissions.ONLY_REQUESTOR
                          )
                        }
                      />
                      <label className="form-check-label" htmlFor="onlyMe">
                        <span>
                          {constant.editorPermissionsLabel.ONLY_REQUESTOR}
                        </span>
                      </label>
                    </div>
                    <div className="form-check mb-10">
                      <input
                        type="radio"
                        className="form-check-input"
                        id="selectedUsers"
                        name="selectedUsers"
                        value={users}
                        checked={
                          users === constant.editorPermissions.SELECTED_USERS
                        }
                        onClick={e =>
                          onChangeRadio(
                            e,
                            constant.editorPermissions.SELECTED_USERS
                          )
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="selectedUsers"
                      >
                        <span>
                          {constant.editorPermissionsLabel.REQUESTED_USERS}
                        </span>
                      </label>
                    </div>
                    <div
                      className={`form-check mb-10 ${
                        users === 'selectedUsers' ? '' : 'd-none'
                      }`}
                    >
                      <label
                        className="form-check-label ml-1"
                        htmlFor="emailList"
                      >
                        <span>Enter email address</span>
                        <span
                          className="cw-icon cw-icon--help ml-2"
                          data-tip="Only emails on unilever.com  domain are allowed along with any other emails/domains whitelisted in Campaign Wizard."
                        >
                          <i className="fas fa-question-circle"></i>
                        </span>
                      </label>

                      <div className="form-group form-group-field-width">
                        <input
                          type="text"
                          className="form-control mb-10"
                          name={`email_address`}
                          id={'email_address'}
                          aria-describedby={`email_address`}
                          data-required="true"
                          disabled={finalEmailList.length >= 10}
                          defaultValue={addedEmail}
                          onChange={e => handleEmailAddress(e, e.target.value)}
                        />

                        <span
                          id="add-email"
                          style={{
                            pointerEvents:
                              finalEmailList.length >= 10 ? 'none' : 'auto'
                          }}
                          className={'manage-editors'}
                          onClick={e => addEmailAddress()}
                        >
                          {' '}
                        </span>
                        <span
                          id={`email_text`}
                          className="cw-error cw-hidden"
                          data-control="name"
                        >
                          <i className="fas fa-exclamation-triangle mr-10"></i>
                          Please add at least one email
                        </span>
                        <span
                          id={`email_invalid`}
                          className="cw-error cw-hidden"
                          data-control="name"
                        >
                          <i className="fas fa-exclamation-triangle mr-10"></i>
                          Please enter a valid email/domain. Only emails on
                          domain unilever.com are allowed along with any other
                          emails/domains whitelisted in Campaign Wizard
                        </span>
                        <span
                          id={`email_duplicate`}
                          className="cw-error cw-hidden"
                          data-control="name"
                        >
                          <i className="fas fa-exclamation-triangle mr-10"></i>
                          This email id is already been added
                        </span>
                        <div id="email-loader" className="cw-hidden">
                          <span>
                            <div
                              className="cw-loader"
                              style={{
                                'justify-content': 'right',
                                'padding-right': '4%'
                              }}
                            >
                              <div className="d-flex justify-content-left">
                                <div
                                  className="spinner-border cw-color--primary"
                                  role="status"
                                  style={{ width: '1.5rem', height: '1.5rem' }}
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              </div>
                            </div>
                          </span>
                        </div>
                      </div>
                      <label>Added email address</label>
                      {finalEmailList.map((elem, index) => {
                        // if(elem.email && elem.length>0){
                        return (
                          <div
                            className="form-group form-group-field-width"
                            key={index}
                          >
                            <ul style={{ listStyleType: 'none' }}>
                              <li>
                                {elem.email} &nbsp; &nbsp;
                                <button
                                  className="cw-modal--close_manage_editors"
                                  onClick={e => _deleteAnswer(e, elem.id)}
                                >
                                  X
                                </button>
                              </li>
                            </ul>
                          </div>
                        );
                        //  }
                      })}
                    </div>
                    <div className="cw-form--action-cta mt-4 ml-1">
                      <button
                        type="button"
                        className="btn btn btn-outline-secondary mr-3 pl-3 pr-3"
                        onClick={_handleCancel}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary pl-4 pr-4"
                        onClick={_handleSubmit}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default EditorManagement;
