import * as yup from 'yup';
import constants from '../../constants';
//import { ABTestResultInput } from 'components/molecules/ABTestResultInput';
import { RichTextFormWrapper } from 'components/richTextEditor';
import moment from 'moment';
import { get } from 'lodash';

export default (handleFormClose, handleSubmit, defaultValues) => {
  const isEdit = get(defaultValues, '_id') ? true : false;
  return {
    formType: 'custombuttons',
    submitBtnLabel: isEdit ? 'Update' : 'Submit',
    previousBtnLabel: 'Cancel',
    secondaryBtnLabel: 'Update existing',
    disableFormError: true,
    fields: [
      {
        type: 'datepicker',
        name: 'releasedateText',
        id: 'releasedateText',
        label: 'Release date',
        dateFormat: 'DD/MM/YY',
        timeFormat: false,
        defaultValue: defaultValues.releasedateText,
        placeholder: 'DD/MM/YY',
        rules: yup
          .string()
          .test(
            'isDateStringParsable',
            'Please enter a valid release date',
            dateString => {
              return (
                moment(dateString, 'DD/MM/YY', true).isValid() ||
                moment(
                  String(dateString).slice(0, 33),
                  'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ',
                  true
                ).isValid()
              );
            }
          )
          .required('Please enter release date.')
      },
      {
        type: 'reactselect',
        name: 'status',
        id: 'status',
        label: 'Status',
        rules: yup
          .object()
          .shape({
            label: yup.string().required(),
            value: yup.string().required()
          })
          .required('Please select status')
          .default(undefined),
        options: [
          {
            label: 'Live',
            value: 'live'
          },
          {
            label: 'Coming soon',
            value: 'comingSoon'
          }
        ],
        defaultValue: defaultValues.status
      },
      {
        type: 'reactselect',
        name: 'featureCategory',
        id: 'featureCategory',
        label: 'Feature category',
        rules: yup
          .object()
          .shape({
            label: yup.string().required(),
            value: yup.string().required()
          })
          .required('Please select feature category')
          .default(undefined),
        options: [
          {
            label: 'Data acquisition',
            value: 'dataAcquisition'
          },
          {
            label: 'Email campaigns',
            value: 'emailCampaigns'
          },
          {
            label: 'SMS campaigns',
            value: 'smsCampaigns'
          },
          {
            label: 'General',
            value: 'general'
          }
        ],
        defaultValue: defaultValues.featureCategory
      },
      {
        type: 'text',
        name: 'featureSummaryText',
        id: 'featureSummaryText',
        label: 'Feature Summary',
        placeholder: 'Enter text here',
        defaultValue: defaultValues.featureSummaryText,
        rules: yup
          .string()
          .trim()
          .required('Please enter feature summary')
      },
      {
        type: 'custom',
        name: 'descriptionText',
        component: RichTextFormWrapper,
        id: 'descriptionText',
        label: 'Description',
        placeholder: 'Enter text here',
        toolbar: `redo undo |
                  bold italic underline strikethrough forecolor fontsizeselect | 
                  alignleft aligncenter alignright | 
                  subscript superscript |
                  bullist numlist emoticons link
                `,
        defaultValue: defaultValues.descriptionText,
        rules: yup
          .string()
          .trim()
          .required('Please enter release description')
      }
    ],
    onSubmit: (data, { user, setFormErrorMessage }) => {
      handleSubmit(data, { user, setFormErrorMessage });
    },
    onPrevious: () => {
      handleFormClose();
    },
    onSecondaryClick: isEdit
      ? (data, { user, setFormErrorMessage }) => {
          console.log('data: ', data);
        }
      : null,
    isEdit: isEdit
  };
};
