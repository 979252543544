import React from 'react';
import { get } from 'lodash';

import { FieldError } from 'components/atoms/FieldError';
import { FormInput } from 'components/molecules/FormInput';
import { FormReactSelect } from 'components/molecules/FormReactSelect';
import constants from '../../../constants';

export const EmailImageSelector = ({
  name,
  rules,
  register,
  label,
  errors,
  setValue,
  control,
  defaultValue,
  savedRowData,
  ...props
}) => {
  const errorMessages = get(errors, name);
  const hasError = !!(errors && errorMessages);

  return (
    <div className="image-select-container">
      <div className="row">
        <div className="col-md-12">
          <label className="font-weight-bold">Select image</label>
        </div>

        <div className="col-md-8">
          <FormInput
            id="assetID"
            type="text"
            name={`${name}.assetID`}
            label="Asset ID from TAB"
            placeholder="Asset ID from TAB"
            register={register}
            errors={errors}
            key="assetID"
            defaultValue={defaultValue.id}
            setValue={setValue}
          />
          <label className="mt-n2 email-type-desc-font">
            Ensure that the image is public in TAB (Share to website attribute
            should be set to “yes“)
          </label>
        </div>
        <div className="col-md-4">
          <FormReactSelect
            id="fileType"
            type="reactselect"
            name={`${name}.fileType`}
            control={control}
            label="File type"
            placeholder="File type"
            options={constants.IMAGE_TYPES}
            register={register}
            errors={errors}
            key="fileType"
            defaultValue={defaultValue.extn}
            setValue={setValue}
            isInModal={true}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <label className="font-weight-bold mt-3">Transform image</label>
        </div>

        <div className="col-md-6">
          <FormInput
            id="resizeWidth"
            type="text"
            name={`${name}.resizeWidth`}
            label="Width (px)"
            placeholder="Width (px)"
            register={register}
            errors={errors}
            key="resizeWidth"
            defaultValue={defaultValue.query?.width}
            setValue={setValue}
            customComponent={() => (<span className="ml-1 mb-2 email-type-desc-font" style={{alignSelf: 'flex-end'}}>(Optional)</span>)}
          />
          <label className="mt-n2 email-type-desc-font">
            Width is mandatory when height is entered
          </label>
        </div>
        <div className="col-md-6">
          <FormInput
            id="resizeHeight"
            type="text"
            name={`${name}.resizeHeight`}
            label="Height (px)"
            placeholder="Height (px)"
            register={register}
            errors={errors}
            key="resizeHeight"
            defaultValue={defaultValue.query?.height}
            setValue={setValue}
            customComponent={() => (<span className="ml-1 mb-2 email-type-desc-font" style={{alignSelf: 'flex-end'}}>(Optional)</span>)}
          />
        </div>
      </div>
    </div>
  );
};
