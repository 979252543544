// Overview Reducer
import {
	GET_CAMPAIGN_DETAILS_OVERVIEW,
	ASYNC_IN_PROGRESS,
	DELETE_CAMPAIGN,
	SERVICE_ERROR_OVERVIEW,
	CANCEL_CAMPAIGN,
	SET_CAMPAIGN_TO_EDITED,
	RESET_CAMPAIGN_TO_EDITED,
	CLONE_CAMPAIGN_DATA,
	PUSH_CLONED_DATA,
	POST_SUBMIT_REVIEW
} from '../actionTypes';

const initialState = {
	asyncInProgress: false,
	campaign: {},
	campaignDetails: {},
	questionAnswerList: [],
	campaignStatus: '',
	campaignId: '',
	apiConnectionDetails: {},
	deleted: null,
	redirect: null,
	deletedCampaign: null,
	serviceError: false,
	serviceErrorStatus: null,
	optins: [],
	cancelled: false,
	editCampaign: false,
	campaignAccessEmails: [],
	isClone: false
};

export default function(state = initialState, action) {
	switch (action.type) {
		case GET_CAMPAIGN_DETAILS_OVERVIEW: {
			let campaign = {
				campaign: action.campaign || {},
				campaignDetails: action.campaign.campaignDetail || {},
				optins: (action.campaign.campaignDetail && action.campaign.campaignDetail.optins) || [],
				questionAnswerList:
					(action.campaign.campaignDetail && action.campaign.campaignDetail.questionAnswerList) || [],
					campaignAccessEmails: action.campaign.users || state.campaignAccessEmails
			};
			return { ...state, ...campaign };
		}
		case ASYNC_IN_PROGRESS: {
			let asyncInProgress = { asyncInProgress: action.isLoading };
			return { ...state, ...asyncInProgress };
		}
		case POST_SUBMIT_REVIEW: {
			return { ...state, ...{ isEdited: action.isEdited } };
		  }
		case DELETE_CAMPAIGN: {
			let deleted = {
				asyncInProgress: action.approved,
				deleted: action.approved,
			};
			return { ...state, ...deleted };
		}
		case CANCEL_CAMPAIGN: {
			let cancelled = { cancelled: action.cancelled };
			return { ...state, ...cancelled };
		}
		case SERVICE_ERROR_OVERVIEW: {
			let serviceError = {
				serviceError: true,
				serviceErrorStatus: (action.error.response && action.error.response.status) || 401,
			};
			return { ...state, ...serviceError };
		}
		case RESET_CAMPAIGN_TO_EDITED:
		case SET_CAMPAIGN_TO_EDITED: {
			return { ...state, editCampaign: action.editCampaign };
		}
		case CLONE_CAMPAIGN_DATA: 
		case PUSH_CLONED_DATA:{
			let campaign = { campaign: action.campaign};
			return { ...state,...campaign };
		}
		default:
			return state;
	}
}
