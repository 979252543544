import RadioDropDown from '../../../../components/RadioDropDown';

import React from 'react';

export default function LastUpdatedFilter({
  asyncInProgress,
  value,
  onChange,
  options,
  isEmailCampaign,
  classNamePrefix
}) {
  return (
    <div className="filter-item">
      <div className="form-group mb-0">
        <RadioDropDown
          className={isEmailCampaign? 'react-multi-select-container' : 'cw-dashboard-filter last-updated-filter'}
          classNamePrefix={classNamePrefix}
          isEmailCampaign={isEmailCampaign}
          name={'lastUpdated'}
          label={'Last updated'}
          hideSelectedOptions={false}
          placeholder={'Last Updated'}
          isDisabled={asyncInProgress}
          isLoading={asyncInProgress}
          value={value}
          onChange={onChange}
          options={options}
          isClearable={false}
        />
      </div>
    </div>
  );
}
