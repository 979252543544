import {
  FETCH_BRAND_DEFAULTS,
  FETCH_BRAND_DEFAULTS_SUCCESS,
  FETCH_BRAND_DEFAULTS_FAILURE
} from '../actionTypes';

const initialState = {
  isLoading: false,
  data: null,
  error: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_BRAND_DEFAULTS: {
      return { ...state, isLoading: true };
    }
    case FETCH_BRAND_DEFAULTS_SUCCESS:
      return { ...state, isLoading: false, data: action.payload };

    case FETCH_BRAND_DEFAULTS_FAILURE:
      return { ...state, isLoading: false, error: action.payload };

    default:
      return state;
  }
}
