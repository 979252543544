import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const FormError = ({ message, className, isNotBold }) => {
  return (
    <div className={classNames('alert alert-danger', className)} role="alert">
      {isNotBold? message : <strong>{message}</strong>}
    </div>
  );
};

FormError.propTypes = {
  message: PropTypes.string
};

FormError.defaultProps = {
  message: 'There was a problem with the page. Errors are listed below'
};

export { FormError };
