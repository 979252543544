import axios from "axios";
import constant from "../constants";
import {
  FIELD_SORT_ASYNC_IN_PROGRESS,
  FIELD_SORT_GET_CAMPAIGN,
  UDPATE_PROFILE_FIELDS,
  UPDATE_GENDER_VALUES,
  UPDATE_PROVINCE_VALUES
} from "../actionTypes";

export function fetchCampaignData(Id) {
  return function(dispatch) {
    dispatch({
      type: FIELD_SORT_ASYNC_IN_PROGRESS,
      isLoading: true,
    });
    axios
      .get(constant.serviceUrls.CAMPAIGN_DETAILS_SERVICE_URL + "/" + Id)
      .then((response) => {
        let campaign = response.data[0];
        dispatch({ type: FIELD_SORT_ASYNC_IN_PROGRESS, isLoading: false });
        dispatch({ type: FIELD_SORT_GET_CAMPAIGN, campaign });
      });
  };
}

export const updateProfileFields = (fields) => ({
  type: UDPATE_PROFILE_FIELDS,
  fields,
});

export const updateGenderValues = (values) => ({
  type: UPDATE_GENDER_VALUES,
  values,
});
export const updateProvinceValues = (values) => ({
  type: UPDATE_PROVINCE_VALUES,
  values,
});