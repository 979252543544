import CheckboxDropDown from '../../../CheckboxDropDown';
import React from 'react';
import FilterWrapper from '../FilterWrapper/FilterWrapper';

export default function EmailTypeFilter({
  asyncInProgress,
  value,
  onChange,
  options,
  isEmailCampaign,
  classNamePrefix
}) {
  return (
    <FilterWrapper>
      <CheckboxDropDown
        isMulti={true}
        className={isEmailCampaign? 'react-multi-select-container' : 'cw-dashboard-filter'}
        classNamePrefix={classNamePrefix}
        isEmailCampaign={isEmailCampaign}
        menuIsOpen={false}
        name={'emailType'}
        label={'Email type'}
        hideSelectedOptions={false}
        placeholder={'Email type'}
        isDisabled={asyncInProgress}
        isLoading={asyncInProgress}
        value={value}
        onChange={onChange}
        options={options}
        isClearable={false}
      />
    </FilterWrapper>
  );
}
