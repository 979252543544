import React from 'react';
import { NavLink } from 'react-router-dom';
import tick from '../images/icons8-check-mark-button-48.png';
import NewStarIcon from '../images/NewStar.svg';
const RejectComments = props => {
  return (
    <div className={`row mb-20 ${props.showComponent ? '' : 'd-none'}`}>
      <div className="col-sm-12">
        <div
          className={`alert alert-${props.type}`}
          role="alert"
          style={{ whiteSpace: 'pre-line' }}
        >
          <strong>Campaign was rejected : </strong>
          {' ' + props.message}
        </div>
      </div>
    </div>
  );
};

const CancelCampaign = props => {
  return (
    <div className={`row mb-20 ${props.showComponent ? '' : 'd-none'}`}>
      <div className="col-sm-12">
        <div className={`alert alert-${props.type}`} role="alert">
          <strong>{props.campaignName} </strong>
          {' ' + props.message}
        </div>
      </div>
    </div>
  );
};
const UserManagement = props => {
  return (
    <div className={`row mb-20 ${props.showComponent ? '' : 'd-none'}`}>
      <div className="col-sm-12">
        <div className={`alert alert-${props.type}`} role="alert">
          <strong>User {props.actionType ? props.actionType : ''} : </strong>-{' '}
          {props.message}
        </div>
      </div>
    </div>
  );
};

const ValidationError = props => {
  return (
    <div className={`row mb-20 ${props.showComponent ? '' : 'd-none'}`}>
      <div className="col-sm-12">
        <div className={`alert alert-danger`} role="alert">
          There was a problem with the page. Errors are listed below{' '}
        </div>
      </div>
    </div>
  );
};

const NonEnglishTranslation = props => {
  return (
    <div className={`row mb-20 ${props.showComponent ? '' : 'd-none'}`}>
      <div className="col-sm-12">
        <div className={`alert alert-warning`} role="alert">
          <strong>
            {props.cammpaignLanguage} to English translation email required{' '}
          </strong>{' '}
          -{' ' + props.message}
        </div>
      </div>
    </div>
  );
};

const NonEditableBrandDetails = props => {
  return (
    <div className={`row mb-20 ${props.showComponent ? '' : 'd-none'}`}>
      <div className="col-sm-12">
        <div className={`alert alert-${props.type}`} role="alert">
          {props.message}
        </div>
      </div>
    </div>
  );
};

const CampaignEditedMessage = props => {
  return (
    <div className={`row mb-20 ${props.showComponent ? '' : 'd-none'}`}>
      <div className="col-sm-12">
        <div className={`alert alert-${props.type}`} role="alert">
          {props.message}
        </div>
      </div>
    </div>
  );
};

const NonPublished = props => {
  return (
    <div className={`row mb-20 ${props.showComponent ? '' : 'd-none'}`}>
      <div className="col-sm-12">
        <div className={`alert alert-warning`} role="alert">
          <strong>Not published</strong> - this campaign form has not been
          published due to an automated publishing failure. We will attempt to
          re-publish. If this status has not changed within the next 5 days
          please raise a Kana ticket to the campaign wizard team
        </div>
      </div>
    </div>
  );
};

const Error404NonPublished = props => {
  return (
    <div className={`row mb-20 ${props.showComponent ? '' : 'd-none'}`}>
      <div className="col-sm-12">
        <div className={`alert alert-warning`} role="alert">
          <strong>Not published</strong> - This campaign form has not been
          published as the site (with alias or brand name) does not exist in the
          platform selected as the website type,which caused an automated
          publishing failure. Please create a new campaign and select an
          appropriate website type before submitting the campaign.
        </div>
      </div>
    </div>
  );
};

const MobileSelected = props => {
  return (
    <div className={`row mb-20 ${props.showComponent ? '' : 'd-none'}`}>
      <div className="col-sm-12">
        <div className={`alert alert-warning`} role="alert">
          <strong>
            Are you also looking for SMS communication. If yes, please select
            SMS as the consent channel on the campaign details screen and remove
            the profile question from here
          </strong>
        </div>
      </div>
    </div>
  );
};
const ErrorPromotigoAC = props => {
  return (
    <div className={`row mb-20 ${props.showComponent ? '' : 'd-none'}`}>
      <div className="col-sm-12">
        <div className={`alert alert-warning`} role="alert">
          <strong>
            Promotigo forms service with multiple choice questions is currently
            unavailable for the brands hosted on Adobe Classic platform
          </strong>
        </div>
      </div>
    </div>
  );
};
const FormWidgetError = props => {
  return (
    <div className={`row mb-20 ${props.showComponent ? '' : 'd-none'}`}>
      <div className="col-sm-12">
        <div className={`alert alert-warning`} role="alert">
          <strong>{props.errorMessage}</strong>
        </div>
      </div>
    </div>
  );
};

const SaasUpdateMessage = props => {
  return (
    <div
      className={`alert alert-update-message mt-15`}
      style={{ width: '100%', padding: '1% 2%', borderRadius: '10px' }}
      role="alert"
    >
      <div className={'saas-update-icon'}>
        <span className={'icon-span'}></span>
        <span>New Update</span>
      </div>
      <div className={'saas-update-content'}>
        <p>
          {' '}
          {props.text.map((text, index) => {
            return <em key={index}>{text}</em>;
          })}
          {props.isKnowMore ? (
            <em>
              <a
                href="#modal"
                onClick={props.openModal}
                title={props.modelText}
                className={'alert-update-message'}
                style={{ textDecoration: 'underline', color: '#007dbb' }}
              >
                {props.modelText}
              </a>
              &nbsp; {props.optionalText}
            </em>
          ) : (
            ''
          )}
        </p>
        {props.isButton ? (
          <a
            className="btn mb-2 btn-primary mt-20"
            href={props.previewUrl}
            target={'_blank'}
          >
            Preview Form
          </a>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

const SassUpdateKnownmore = props => {
  return (
    <>
      {props.className ? (
        <div
          className={`alert alert-qna-message`}
          style={{
            borderRadius: '10px',
            marginTop: '70px',
            maxWidth: '85%',
            marginLeft: '0'
          }}
          role="alert"
        >
          <div className={'saas-update-icon'}>
            <span className={'icon-span'}></span>
            <span style={{ textAlign: 'left' }}>New</span>
          </div>
          <div className={'saas-update-content'}>
            <p>
              {' '}
              {props.text.map((text, index) => {
                return (
                  <>
                    <p
                      key={index}
                      style={{
                        margin: '10px 0',
                        fontSize: '14px',
                        color: 'black',
                        textAlign: 'left'
                      }}
                    >
                      {text}
                    </p>
                  </>
                );
              })}
            </p>
          </div>
        </div>
      ) : (
        <div
          className={`alert alert-update-message mt-15`}
          style={{ padding: '0 25px', borderRadius: '10px' }}
          role="alert"
        >
          <div className={'saas-update-icon'}>
            <span className={'icon-span'}></span>
            <span>New Update</span>
          </div>
          <div className={'saas-update-content'}>
            <p>
              {' '}
              {props.text.map((text, index) => {
                return (
                  <>
                    {props.className ? (
                      <p
                        key={index}
                        style={{ margin: '10px 0', fontSize: '14px' }}
                      >
                        {text}
                      </p>
                    ) : (
                      <em key={index} style={{ margin: '10px 0' }}>
                        {text}
                      </em>
                    )}
                  </>
                );
              })}
              {props.isKnowMore ? (
                <em>
                  <a
                    style={{ color: '#007dbb', textDecoration: 'underline' }}
                    className={'alert-update-message'}
                    href="#modal"
                    onClick={props.openModal}
                    title={props.modelText}
                  >
                    {props.modelText}
                  </a>
                  &nbsp; {props.optionalText}
                </em>
              ) : (
                ''
              )}
            </p>
          </div>
        </div>
      )}
    </>
  );
};
const SaasInfoMessage = props => {
  const { withIcon } = props;
  return (
    <div
      className="alert alert-primary mt-15 cw-color--saasheading d-flex"
      style={{ padding: '10px 20px', borderRadius: '10px' }}
      role="alert"
    >
      {withIcon && (
        <i class="fas fa-info-circle align-self-start pt-1 mr-2"></i>
      )}
      {props.text.map(text => {
        return <p key={text}>{text}</p>;
      })}
    </div>
  );
};

const WarningAlertMessage = props => {
  return (
    <div className={`alert alert-warning`} role="alert">
      <strong>{props.text}</strong>
    </div>
  );
};
const WarningAlertMessageWithIcon = props => {
  return (
    // <div className={`alert alert-warning`} role="alert">
    //   <strong>{props.text}</strong>
    // </div>
    <div
      className={`alert alert-warning ${
        props.showComponent ? 'd-flex' : 'd-none'
      }`}
      role="alert"
    >
      <i className="fas fa-exclamation-circle pt-5 mr-10"></i>
      <span className={props.textClass ? props.textClass : ''}>
        {props.text}
      </span>
    </div>
  );
};

const SaaSWarningAlertMessageWithIcon = props => {
  const { closable, onClose } = props;
  return (
    <div
      className={`saas-warning justify-content-between mb-20 ${
        props.showComponent ? 'd-flex' : 'd-none'
      }`}
      role="alert"
    >
      <div>
        <i className="fas fa-exclamation-triangle mr-10"></i>
        {props.text} &nbsp;
        <u>
          {props.isLink ? (
            <a href="#modal" onClick={props.openModal} title={props.modelText}>
              {props.modelText}
            </a>
          ) : null}
        </u>
      </div>
      {closable && <i className="fas fa-times clickable" onClick={onClose}></i>}
    </div>
  );
};

const SaaSWarningAlertMessage = props => {
  return (
    <div
      className={`saas-warning mb-20 ${props.showComponent ? '' : 'd-none'}`}
      role="alert"
    >
      <em>
        {props.text} &nbsp;
        <u>
          {props.isLink ? (
            <a href="#modal" onClick={props.openModal} title={props.modelText}>
              {props.modelText}
            </a>
          ) : null}
        </u>
      </em>
    </div>
  );
};

const AutopopulateWebsiteTypeMessage = props => {
  return (
    <div className={`row mb-20 ${props.showComponent ? '' : 'd-none'}`}>
      <div className="col-sm-12">
        <div className={`alert alert-warning`} role="alert">
          <strong> The brand site exists in Adobe Classic platform</strong>
        </div>
      </div>
    </div>
  );
};

const AutopopulateATWebsiteTypeMessage = props => {
  return (
    <div className={`row mb-20 ${props.showComponent ? '' : 'd-none'}`}>
      <div className="col-sm-12">
        <div className={`alert alert-warning`} role="alert">
          <strong> The brand site exists in Adobe Templated platform</strong>
        </div>
      </div>
    </div>
  );
};

const ACATWebsiteTypeExists = props => {
  return (
    <div className={`row mb-20 ${props.showComponent ? '' : 'd-none'}`}>
      <div className="col-sm-12">
        <div className={`alert alert-warning`} role="alert">
          <strong>
            {' '}
            The brand site exists in Adobe Classic and Adobe Templated platform
          </strong>
        </div>
      </div>
    </div>
  );
};

const PromotigoInterimSolutionMessage = props => {
  return (
    <div className={`row mb-20 ${props.showComponent ? '' : 'd-none'}`}>
      <div className="col-sm-12">
        <div className={`alert alert-warning`} role="alert">
          <strong>
            The incentive campaign workflow in CW is currently enabled only for
            Promotigo forms. If your competitions/samples partners are others
            (eg. Cheetah, SoPost, Zappit or any Third party data collection)
            then please choose ‘direct messaging campaign' and proceed with your
            request. This is an interim solution while we work towards resolving
            this issue.
          </strong>
        </div>
      </div>
    </div>
  );
};

const ProfilePageOverlay = props => {
  return (
    <>
      <button
        className="cw-modal--close"
        title="Close"
        onClick={props.closeModal}
      >
        <i className="fas fa-times"></i>
      </button>
      <p className="mb-10">
        <strong>
          Field properties are a set of instructions that guide how fields
          appear and behave on your sign-up forms. Field properties we collect
          can include{' '}
        </strong>
      </p>
      <ul className="ml-20" style={{ listStyleType: 'square' }}>
        <li className="mb-10">
          {' '}
          <strong>Field labels</strong> - Determines the label of the field to
          be displayed on the form. E.g. First name, What is your first name,
          etc
        </li>
        <li className="mb-10">
          <strong>Help text/Tool Tip</strong> – Any additional information that
          needs to be displayed next to a field on the form that can guide
          consumers on how to fill information correctly. E.g. A help text
          configured for ‘mobile number’ field can be “Please ensure that the
          mobile number is entered in format +449010101010 (country code and
          number)"
        </li>
        <li className="mb-10">
          <strong>Field mandatory</strong> – helps you choose whether you want
          you consumers to mandatorily enter information in a field before they
          submit
        </li>
        <li className="mb-10">
          <strong>Mandatory field error messages</strong> – The error you want
          your consumers to see if they try to submit the form without filling
          in mandatory fields
        </li>
        <li className="mb-10">
          <strong>Validation error messages</strong> – The error you want
          consumers to see if they try to submit the form with invalid
          information in a field. This may not be applicable to all form fields.
          We will prompt you to specify this information, wherever applicable
        </li>
      </ul>
    </>
  );
};

const SaasCompletedUpdateMessage = props => {
  return (
    <div
      className={`alert alert-update-message alert-completed-message mt-15`}
      role="alert"
    >
      <div className={'saas-update-icon'}>
        <span className={'icon-span'}></span>
        <span>New Update</span>
      </div>
      <div className={'saas-update-content'}>
        <p>
          {' '}
          {props.text.map((text, index) => {
            return <em key={index}>{text}</em>;
          })}
        </p>
        {props.isButton ? (
          <a
            className="btn mb-2 btn-primary mt-20"
            href={props.previewUrl}
            target={'_blank'}
          >
            Preview Form
          </a>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

const SaasProfileFieldsMessage = props => {
  return (
    <div>
      <p>
        {props.text}{' '}
        <a href="#modal" onClick={props.openModal} title={props.modelText}>
          <u>{props.modelText}</u>
        </a>
        {props.isKnowMore ? <div>{props.optionalText}</div> : ''}
      </p>
    </div>
  );
};

const SassCampaignTypeKnownmore = props => {
  return (
    <p>
      {props.text}
      <a
        style={{
          color: '#007dbb',
          background: '#ffffff',
          textDecoration: 'underline'
        }}
        className={'alert-update-message'}
        href="#modal"
        onClick={props.openModal}
        title={props.modelText}
      >
        {props.modelText}
      </a>
    </p>
  );
};

const NewSaasPreviewButton = props => {
  return (
    <div className="" style={{ float: 'right' }}>
      <a
        className="btn mb-2 btn-primary mt-20"
        href={props.previewUrl}
        target={'_blank'}
      >
        Preview Form
      </a>
    </div>
  );
};

const SaasEditedMessageInfo = props => {
  return (
    <div className={`row mb-20 ${props.showComponent ? '' : 'd-none'}`}>
      <div className="col-sm-12">
        <div className={`alert alert-primary`} role="alert">
          <i className="fa fa-info-circle"></i>
          &nbsp; This campaign was edited on {props.lastUpdatedDate}
        </div>
      </div>
    </div>
  );
};
// const ClonedInfo = (props) => {
//   return (
//     <div className={`row mb-20 ${props.showComponent ? "" : "d-none"}`}>
//       <div className="col-sm-12">
//         <div className={`alert alert-warning`} role="alert">
//           <strong>
//           {props.message}
//           </strong>
//         </div>
//       </div>
//     </div>
//   );
// };

const CiaNewUpdateInfo = props => {
  return (
    <div
      className={`alert alert-cia-message`}
      style={{
        borderRadius: '10px',
        maxWidth: '85%',
        marginLeft: 'auto'
      }}
      role="alert"
    >
      <div className={'saas-update-icon'}>
        <span className="icon-span">
          <img src={NewStarIcon} alt="new star icon" height={25} width={25} />
        </span>
        <span>New</span>
      </div>
      <div className={'saas-update-content'}>
        <p
          style={{
            margin: '10px 0',
            fontSize: '14px',
            color: 'black',
            textAlign: 'left'
          }}
        >
          Introducing “Content Interest Areas”! You can now add this field on
          your sign-up forms to capture the kind of content that interests our
          consumers so that you can tailor your marketing communications to
          consumer needs.
        </p>
      </div>
    </div>
  );
};

const SaasInfoMessageWithIcon = props => {
  return (
    <div
      className={`alert alert-primary mt-15 cw-color--saasheading`}
      style={{ padding: '0 25px', borderRadius: '10px' }}
      role="alert"
    >
      <div className="d-flex justify-content-between align-items-start">
        <div className="mr-2 cia-info-box">
          <span>
            <i className="fa fa-info-circle"></i>
          </span>
        </div>
        <div className="">
          {props.text.map((text, index) => {
            return (
              <p key={index} style={{ margin: '10px 0' }}>
                {text}
              </p>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const FeedbackFormMesg = props => {
  return (
    <div className={`alert alert-new-feature-mesg`} role="alert">
      <div className={'saas-update-icon'}>
        <span className="icon-span">
          <img src={NewStarIcon} alt="new star icon" height={25} width={25} />
        </span>
        <span>Rate us</span>
      </div>
      <div className={'saas-update-content'}>
        <p>
          We would love to hear about your experience using Campaign Wizard!
          Please{' '}
          <a
            href="#model"
            onClick={props.onFeedbackClick}
            className="alert-mesg-link"
          >
            share your feedback
          </a>
        </p>
      </div>
    </div>
  );
};

const ReviewNewUpdateMessage = props => {
  return (
    <div className={`alert alert-new-feature-mesg`} role="alert">
      <div className={'saas-update-icon'}>
        <span className="icon-span">
          <img src={NewStarIcon} alt="new star icon" height={25} width={25} />
        </span>
        <span>{props.heading}</span>
      </div>
      <div className={'saas-update-content'}>
        <p>{props.textMessage}</p>
      </div>
    </div>
  );
};

const RequestMoreInfo = props => {
  return (
    <div
      className={`row email-summary-font ${
        props && props.className ? props.className : 'mb-20'
      } ${props.showComponent ? '' : 'd-none'}`}
    >
      <div className="col-sm-12">
        <div
          className={`alert alert-${props.type}`}
          role="alert"
          style={{ whiteSpace: 'pre-line' }}
        >
          {props.title ? (
            <>
              <strong>{props.title}</strong> <br />
            </>
          ) : null}
          {' ' + props.message}
        </div>
      </div>
    </div>
  );
};

const Alert = props => {
  const alertType = props.alertType;
  switch (alertType) {
    case 'rejectcomment': {
      return (
        <RejectComments
          type={props.type}
          showComponent={props.showComponent}
          message={props.message}
        />
      );
    }
    case 'requestMoreInfo': {
      return (
        <RequestMoreInfo
          type={props.type}
          showComponent={props.showComponent}
          message={props.message}
          title={props.title}
          className={props.className}
        />
      );
    }
    case 'cancelcampaign': {
      return (
        <CancelCampaign
          type={props.type}
          showComponent={props.showComponent}
          campaignName={props.campaignName}
          message={props.message}
        />
      );
    }
    case 'usermanagement': {
      return (
        <UserManagement
          type={props.type}
          actionType={props.actionType}
          showComponent={props.showComponent}
          message={props.message}
        />
      );
    }
    case 'validationError': {
      return (
        <ValidationError
          campaignName={props.campaignName}
          type={props.type}
          showComponent={props.showComponent}
          message={props.message}
        />
      );
    }
    case 'nonEnglishTranslation': {
      return (
        <NonEnglishTranslation
          cammpaignLanguage={props.cammpaignLanguage}
          type={props.type}
          showComponent={props.showComponent}
          message={props.message}
        />
      );
    }
    case 'nonPublished': {
      return <NonPublished showComponent={props.showComponent} />;
    }
    case 'error404NonPublished': {
      return <Error404NonPublished showComponent={props.showComponent} />;
    }
    case 'nonEditableBrandDetails': {
      return (
        <NonEditableBrandDetails
          type={props.type}
          message={props.message}
          showComponent={props.showComponent}
        />
      );
    }
    case 'campaignEdited': {
      return (
        <CampaignEditedMessage
          type={props.type}
          message={props.message}
          showComponent={props.showComponent}
        />
      );
    }

    case 'isMobileSelected': {
      return (
        <MobileSelected
          type={props.type}
          message={props.message}
          showComponent={props.showComponent}
        />
      );
    }
    case 'isPromotigoAC': {
      return <ErrorPromotigoAC showComponent={props.showComponent} />;
    }
    case 'isWebsiteTypeAutopoulated': {
      return (
        <AutopopulateWebsiteTypeMessage showComponent={props.showComponent} />
      );
    }
    case 'isACATWebsiteTypeExists': {
      return <ACATWebsiteTypeExists showComponent={props.showComponent} />;
    }
    case 'isWebsiteTypeATAutopoulated': {
      return (
        <AutopopulateATWebsiteTypeMessage showComponent={props.showComponent} />
      );
    }
    case 'isPromotigoInterimMessageRequired': {
      return (
        <PromotigoInterimSolutionMessage showComponent={props.showComponent} />
      );
    }
    case 'warningMessage': {
      return <WarningAlertMessage text={props.textMessage} />;
    }

    case 'warningMessageWithIcon': {
      return (
        <WarningAlertMessageWithIcon
          text={props.textMessage}
          showComponent={props.showComponent}
          textClass={props.textClass}
        />
      );
    }
    case 'saasInfoMessage': {
      return <SaasInfoMessage {...props} text={props.textMessage} />;
    }
    case 'saasUpdateMessage': {
      return (
        <SaasUpdateMessage
          text={props.textMessage}
          isButton={props.isButton}
          isKnowMore={props.isKnowMore}
          modelText={props.modelText}
          previewUrl={props.previewUrl}
          optionalText={props.optionalText}
          openModal={props.openModal}
        />
      );
    }

    case 'saasProfileFieldsMessage': {
      return (
        <SaasProfileFieldsMessage
          text={props.textMessage}
          //isButton={props.isButton}
          isKnowMore={props.isKnowMore}
          modelText={props.modelText}
          previewUrl={props.previewUrl}
          optionalText={props.optionalText}
          openModal={props.openModal}
        />
      );
    }

    case 'saasUpdateKnownmore': {
      return (
        <SassUpdateKnownmore
          text={props.textMessage}
          openModal={props.openModal}
          isKnowMore={props.isKnowMore}
          modelText={props.modelText}
          className={props.className}
        />
      );
    }
    case 'saasWarningAlertMessageWithIcon':
      return (
        <SaaSWarningAlertMessageWithIcon {...props} text={props.textMessage} />
      );
    case 'saasWarningAlertMessage': {
      return (
        <SaaSWarningAlertMessage
          text={props.textMessage}
          showComponent={props.showComponent}
          isLink={props.isLink}
          modelText={props.modelText}
          openModal={props.openModal}
        />
      );
    }
    case 'formWidgetErrorMessgae': {
      return (
        <FormWidgetError
          showComponent={props.showComponent}
          errorMessage={props.errorMessage}
        />
      );
    }

    case 'profilePageOverlay': {
      return <ProfilePageOverlay closeModal={props.closeModal} />;
    }

    case 'completedUpdateMessage': {
      return (
        <SaasCompletedUpdateMessage
          text={props.textMessage}
          isButton={props.isButton}
          previewUrl={props.previewUrl}
        />
      );
    }

    case 'campaignTypeKnownmore': {
      return (
        <SassCampaignTypeKnownmore
          text={props.textMessage}
          openModal={props.openModal}
          isKnowMore={props.isKnowMore}
          modelText={props.modelText}
        />
      );
    }

    case 'saasEditedMessageInfo': {
      return (
        <SaasEditedMessageInfo
          showComponent={props.showComponent}
          lastUpdatedDate={props.lastUpdatedDate}
        />
      );
    }

    case 'ciaNewUpdateInfo': {
      return <CiaNewUpdateInfo />;
    }

    case 'saasInfoMessageWithIcon': {
      return <SaasInfoMessageWithIcon text={props.textMessage} />;
    }

    case 'newSaasPreviewFormButton': {
      return <NewSaasPreviewButton previewUrl={props.previewUrl} />;
    }

    case 'feedbackFormMesg': {
      return <FeedbackFormMesg onFeedbackClick={props.onFeedbackClick} />;
    }

    case 'ReviewNewUpdateMessage': {
      return (
        <ReviewNewUpdateMessage
          heading={props.heading}
          textMessage={props.textMessage}
        />
      );
    }

    default:
      return null;
  }
};

export default Alert;
