import moment from 'moment';
import React from 'react';
import { getCampaignTypeText } from 'selectors';
import Status from './Status';

export default function CampaignRejectionListRow({ log, authorizedUserData }) {
  const {
    uid,
    event,
    notes,
    comments,
    eventDate,
    userEmail,
    campaign: { name, type, campaignType, incentiveType, rejectComments },
    websiteType: { name: websiteName },
    brand: { name: brandName },
    country: { name: countryName }
    // rejectedBy,
    // rejectionDate,
    // rejectionComment,
    // rejectorEmail,
    // status,
    // country: { name: countryName },
    // brand: { name: brandName },
    // campaignType,
    // type
  } = log;
  const { campaign } = log;

  return (
    <tr className="rejection-list--row">
      <td className="rejection-list--column">
        <a
          href={'/campaign-wizard/campaign/' + uid}
          title={name}
          className="one-line dark-text"
          style={{ fontWeight: 'bold', whiteSpace: 'break-spaces' }}
        >
          {name || 'Untitled'}
        </a>
        <p className="dark-text mt-15">{brandName}</p>
        <p className="light-text" style={{ color: 'var(--color-text-light)' }}>
          {countryName}
        </p>
        <p className="dark-text mt-15">
          {getCampaignTypeText({ campaignType, incentiveType })}
        </p>
        <p className="light-text" style={{ color: 'var(--color-text-light)' }}>
          {type}
        </p>
        <p className="mt-4">
          <Status authorizedUserData={authorizedUserData} campaign={campaign} />
        </p>
      </td>
      <td className="rejection-list--column">
        <p>{userEmail}</p>
        <p className="light-text" style={{ color: 'var(--color-text-light)' }}>
          {moment(eventDate).format('DD MMM YYYY')}
        </p>
      </td>
      <td className="dark-text rejection-list--column">
        {campaign.rejectComments || notes}
      </td>
      {/* <td className="rejection-list--column">
        <Status authorizedUserData={authorizedUserData} campaign={campaign} />
      </td> */}
    </tr>
  );
}
