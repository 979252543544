import React from 'react';

export const FieldDataRow = ({ label, value }) => (
  <div className="field-row">
    <div className="row">
      <div className="col-sm-4">
        <strong>{label}</strong>
      </div>
      <div className="col-sm-8 cw-text--ternary">{value}</div>
    </div>
  </div>
);
