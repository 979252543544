import React from 'react';
import Modal from 'react-modal';
import ABTestingGuidelines from './ABTestingGuidelines';
import constants from 'constants/config';
import { ReactComponent as ABTestGuidelineIcon } from 'images/abTestGuidelineIcon.svg';

const EmailABTestingGuidelines = ({
  isOpen,
  onCloseModal,
  handleCloseForm
}) => {
  Modal.setAppElement('#root');
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCloseModal}
      className="cw-modal cw-modal--abtest-guidelines"
      contentLabel="Save row form"
      style={{
        overlay: {
          backgroundColor: constants.EMAIL_OVERLAY_BACKGROUND
        }
      }}
    >
      <div className="email-overlay-wrapper">
        <span
          className="qna-guideline-close email-overlay-close-modal"
          onClick={handleCloseForm}
        ></span>
        <div className="email-overlay-scroll">
          <div className="d-flex mb-20">
            <div className="email-overlay-header-box">
              <ABTestGuidelineIcon />
            </div>
            <div className="ml-10">
              <div
                className={'cw-heading--saascampaign send-test-email__title'}
              >
                {'AB test guidelines'}
              </div>
              <p className="mt-10">
                You have chosen to AB test your email content. Please follow the
                instructions below to create and preview email test content
                variants
              </p>
            </div>
          </div>

          <ABTestingGuidelines onClose={handleCloseForm} />
        </div>
      </div>
    </Modal>
  );
};

export default EmailABTestingGuidelines;
