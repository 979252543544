import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormBuilder } from '../../components/organisms/FormBuilder';
import Loader from '../../components/Loader';
import CampaignSheduleSchema from './emailCampaignScheduleSchema';

const EmailCampaignScheduleDetails = ({
  pathUid,
  closeModal,
  emailBriefing,
  setSuccess
}) => {
  const {
    isLoading,
    uid,
    deliverySchedule,
    abTesting,
    status,
    emailType,
    isCrossBrand,
    nmiAssignedToRequestor
  } = emailBriefing;
  const { authorizedUserData } = useSelector(state => state.authorized);
  const dispatch = useDispatch();
  const compProps = {
    pathUid,
    closeModal,
    defaultValues: deliverySchedule || {},
    abTesting,
    emailType,
    setSuccess,
    status,
    isCrossBrand,
    nmiAssignedToRequestor
  };
  return (
    <div>
      {isLoading ? <Loader /> : null}
      <FormBuilder
        schema={CampaignSheduleSchema(dispatch, compProps)}
        user={authorizedUserData}
      />
    </div>
  );
};

export default EmailCampaignScheduleDetails;
