import React from 'react';
import { get } from 'lodash';

import { FormInput } from 'components/molecules/FormInput';

import constants from 'constants/config';

export const DeliveryLabelInputs = ({
  control,
  watchInput,
  errors,
  name,
  register,
  setValue,
  labelClass,
  defaultValue,
  trigger,
  customComponent,
  variant,
  variantFollowUp,
  isSms,
  ...props
}) => {
  const { performABTests = false, noOfABVariants = 0 } = props.abTesting || {};
  const isFollowUp = get(defaultValue, 'sendAfter.value') !== 'DontSend';

  const getDefaultValue = type => {
    if (typeof defaultValue.deliveryLabel === 'string') {
      return type === 'mainLabel' ? defaultValue.deliveryLabel : '';
    } else {
      return (
        defaultValue?.deliveryLabel?.find(val => val.type === type)?.value || ''
      );
    }
  };

  if (variant) {
    return (
      <FormInput
        id={`AB${variant}`}
        type="text"
        name={`${name}.AB${variant}`}
        label={constants.deliveryLablesMap[`AB${variant}`]}
        placeholder=""
        register={register}
        errors={errors}
        key={`AB${variant}`}
        defaultValue={getDefaultValue(`AB${variant}`)}
        maxLength={100}
        setValue={setValue}
        customComponent={customComponent}
      />
    );
  }

  if (variantFollowUp && isFollowUp) {
    return (
      <FormInput
        id="followUpLabel"
        type="text"
        name={`${name}.followUpLabel`}
        label={isSms ? constants.deliveryLablesMap.smsFollowUpLabel : constants.deliveryLablesMap.followUpLabel}
        placeholder=""
        register={register}
        errors={errors}
        key="followUpLabel"
        defaultValue={getDefaultValue('followUpLabel')}
        maxLength={100}
        setValue={setValue}
        customComponent={customComponent}
      />
    );
  }


  return (
    <>
      {!performABTests && (
        <FormInput
          id="mainLabel"
          type="text"
          name={`${name}.mainLabel`}
          label={isSms ? 'Delivery label' : constants.deliveryLablesMap.mainLabel}
          placeholder=""
          register={register}
          errors={errors}
          key="mainLabel"
          defaultValue={getDefaultValue('mainLabel')}
          maxLength={100}
          setValue={setValue}
          customComponent={customComponent}
        />
      )}

      {performABTests && (
        <FormInput
          id="ABVariantA"
          type="text"
          name={`${name}.ABVariantA`}
          label={constants.deliveryLablesMap.ABVariantA}
          placeholder=""
          register={register}
          errors={errors}
          key="ABVariantA"
          defaultValue={getDefaultValue('ABVariantA')}
          maxLength={100}
          setValue={setValue}
          customComponent={customComponent}
        />
      )}

      {performABTests && noOfABVariants >= 2 && (
        <FormInput
          id="ABVariantB"
          type="text"
          name={`${name}.ABVariantB`}
          label={constants.deliveryLablesMap.ABVariantB}
          placeholder=""
          register={register}
          errors={errors}
          key="ABVariantB"
          defaultValue={getDefaultValue('ABVariantB')}
          maxLength={100}
          setValue={setValue}
          customComponent={customComponent}
        />
      )}

      {performABTests && noOfABVariants == 3 && (
        <FormInput
          id="ABVariantC"
          type="text"
          name={`${name}.ABVariantC`}
          label={constants.deliveryLablesMap.ABVariantC}
          placeholder=""
          register={register}
          errors={errors}
          key="ABVariantC"
          defaultValue={getDefaultValue('ABVariantC')}
          maxLength={100}
          setValue={setValue}
          customComponent={customComponent}
        />
      )}

      {isFollowUp && (
        <FormInput
          id="followUpLabel"
          type="text"
          name={`${name}.followUpLabel`}
          label={isSms ? constants.deliveryLablesMap.smsFollowUpLabel : constants.deliveryLablesMap.followUpLabel}
          placeholder=""
          register={register}
          errors={errors}
          key="followUpLabel"
          defaultValue={getDefaultValue('followUpLabel')}
          maxLength={100}
          setValue={setValue}
          customComponent={customComponent}
        />
      )}
    </>
  );
};
