import React, { useState, useRef } from 'react';
import Modal from 'react-modal';
import { useSelector, useDispatch } from 'react-redux';
import { FormBuilder } from '../FormBuilder';
import saveTemplateSchema from './saveTemplateSchema';
import { closeSaveEmailTemplate } from 'actions/emailbriefing/saveTemplate';
import SendTestEmailIcon from '../../../images/send-test-email.png';
import ActionSaveAsTemplateIcon from '../../../images/email-save-template-icon';
import ExternalLinkIcon from 'images/external-link.png';
import constants from 'constants/config';
import { fetchHistoryLogs } from 'actions/history';
import Loader from 'components/Loader';

const SaveTemplateOverlay = () => {
  Modal.setAppElement('#root');
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [savedTemplate, setSavedTemplate] = useState('');
  const { isOpen, templateInfo, templateHtml } = useSelector(
    state => state.saveTemplateForm
  );
  const { authorizedUserData } = useSelector(state => state.authorized);
  const { emailBriefing } = useSelector(state => state.emailDesign);
  const modalRef = useRef();

  const onCloseModal = () => {
    dispatch(closeSaveEmailTemplate());
  };
  const handleCloseForm = () => {
    dispatch(closeSaveEmailTemplate());
    const { uid } = emailBriefing;
    dispatch(fetchHistoryLogs(uid));
  };

  const scrollToTop = () => {
    if (modalRef.current) {
      modalRef.current.scroll({ top: 0, behavior: 'smooth' });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCloseModal}
      className="cw-modal cw-modal--save-template skypink"
      contentLabel="Save row form"
      style={{
        overlay: {
          backgroundColor: constants.EMAIL_OVERLAY_BACKGROUND
        }
      }}
    >
      <div className="email-overlay-wrapper">
        <span
          className="qna-guideline-close email-overlay-close-modal"
          onClick={handleCloseForm}
        ></span>
        <div className="email-overlay-scroll" ref={modalRef}>
          <div className="d-flex mb-20">
            <div className="email-overlay-header-box">
              <ActionSaveAsTemplateIcon />
            </div>
            <div className="ml-10">
              <div
                className={'cw-heading--saascampaign send-test-email__title'}
              >
                {'Save template'}
              </div>
              <p className="mt-10">
                Save an email as template to add it to the templates library.
                This enables you to reuse it to build your email campaigns
                rather than starting from scratch.
              </p>
            </div>
          </div>
          {/* <button
            className="cw-modal--close"
            title="Close"
            onClick={handleCloseForm}
          >
            <i className="fas fa-times"></i>
          </button> */}
          {/* <h2 className="cw-heading--primary mb-20">
            <strong>Save template</strong>
          </h2> */}
          {isLoading ? <Loader /> : null}

          {savedTemplate ? (
            <>
              <p className="mb-20">
                Your template <strong>{savedTemplate}</strong> has been saved to
                the templates library. It will be available for reuse when you
                create a campaign. You can also review this on our&nbsp;
                <a
                  href={constants.EMAIL_COLLECTION_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  templates collection
                  <img
                    src={ExternalLinkIcon}
                    alt="External Link"
                    class="external-link-icon ml-1"
                  />
                </a>
                .
              </p>
              <button
                className="btn btn-primary"
                onClick={() => {
                  handleCloseForm();
                  setSavedTemplate('');
                }}
              >
                Close
              </button>
            </>
          ) : (
            <FormBuilder
              schema={saveTemplateSchema(
                {
                  setIsLoading,
                  setError,
                  handleCloseForm,
                  setSavedTemplate,
                  userInfo: authorizedUserData
                },
                templateInfo,
                emailBriefing,
                templateHtml
              )}
              user={authorizedUserData}
              isInModal
              scrollToTop={scrollToTop}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default SaveTemplateOverlay;
