import React from 'react';
import OverflowMenu from '../OverflowMenu/OverflowMenu';

export default function Card({ onError, thumbnail, title, alt = '',overflowMenuOptions }) {
  return (
    <div className="email-collection-row-card">
      <div>
      {/* {overflowMenuOptions && overflowMenuOptions.length ? <OverflowMenu options={overflowMenuOptions} /> :''} */}
      <img
        onError={onError}
        className="email-card-raw-image"
        src={thumbnail}
        alt={alt || title}
      />
      </div>
      <div className="email-card__overflow-menu">
          {overflowMenuOptions.map(option => (
            <OverflowMenuItem key={option.label} option={option} />
          ))}
        </div>
    </div>
  );
}

function OverflowMenuItem({ option }) {
  const { icon, label, onClick } = option;
  return (
    <button
      className="email-card__overflow-menu-item inline-button"
      onClick={onClick}
    >
      <i className={`${icon} mr-10`} />
      {/* {label} */}
    </button>
  );
}
