import React from 'react';
import NewStarIcon from '../../../images/NewStar.svg';

const NewUpdateAlert = ({
  title = 'New',
  content = 'New feature.',
  className = ''
}) => {
  return (
    <div className={`alert alert-new-message ${className}`} role="alert">
      <div className={'saas-update-icon'}>
        <span className="icon-span">
          <img src={NewStarIcon} alt="new star icon" height={25} width={25} />
        </span>
        <span className="saas-update-title">{title}</span>
      </div>
      <div className={'new-update-content'}>{content}</div>
    </div>
  );
};

export default NewUpdateAlert;
