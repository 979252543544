const DOMAIN_API_LOCAL = '//localhost:3001/api';
const constants = {
  HOMEPAGE: 'https://campaignwizard-uat.unileversolutions.com/campaign-wizard',
  REPORTS: '/campaign-wizard/report-mgmt-v2',
  KANA_PAGE:
    'https://unilever.service-now.com/cex?id=csm_get_help&sys_id=990686831b3cc0d076bc6428bd4bcba2&issue=8b9f991b1bbe8010cd9b1028bd4bcb01',
  KANA_URL:
    'https://unilever.service-now.com/cex?id=csm_get_help&sys_id=fb1e37031ba13890d26d8732f54bcb98',
  serviceUrls: {
    CAMPAIGN_LIST_SERVICE_URL: DOMAIN_API_LOCAL + '/v1/campaigns',
    CAMPAIGN_DETAILS_SERVICE_URL: DOMAIN_API_LOCAL + '/v1/campaigns',
    CAMPAIGN_DATA_SERVICE_URL: DOMAIN_API_LOCAL + '/v1/campaigndetails',
    CAMPAIGN_STATUS_UPDATE_SERVICE_URL:
      DOMAIN_API_LOCAL + '/v1/campaigns/updateStatus',
    CAMPAIGN_MASTER_UPDATE_SERVICE_URL:
      'http://localhost:3001/api/v1/campaigns',
    CAMPAIGN_MASTER_DATA_UPDATE_SERVICE_URL:
      'http://localhost:3001/api/v1/campaigns/masterdata',
    CAMPAIGN_MASTER_SERVICE_URL: 'http://localhost:3001/api/v1/campaigns',
    CAMPAIGN_INFRA_API_CALL:
      'http://localhost:3001/api/v1/campaigns/infraApiCall',
    SERVICE_URL: {
      BRAND_DETAILS: DOMAIN_API_LOCAL + '/v1/branddetails',
      PROFILE_STORE: DOMAIN_API_LOCAL + '/v1/profileStore',
      QUESTIONS: DOMAIN_API_LOCAL + '/v1/questions',
      ANSWERS: DOMAIN_API_LOCAL + '/v1/answers',
      COUNTRIES: 'http://localhost:3001/api/v1/mapBrandCountry',
      BRANDS_LIST: 'http://localhost:3001/api/v1/mapBrandCountry/brand',
      PROMOTIGO_COST_CALCULATION: 'http://localhost:3001/api/v1/calculateCost',
      COOKIE_PRIVACY_URL: 'http://localhost:3001/api/v1/campaigndetails/urls',
      CAMPAIGN_ID_NAME_URL: 'http://localhost:3001/api/v1/campaigns/searchData',
      CAMPAIGN_FIND_URL: 'http://localhost:3001/api/v1/campaigns/suggestions',
      BRAND_NAME_URL: 'http://localhost:3001/api/v1/mapBrandCountry/brand',
      CAMPAIGN_EDIT_LOGS_URL: 'http://localhost:3001/api/v1/campaignLog',
      GET_CAMPAIGN_ID: 'http://localhost:3001/api/v1/campaigns/getCampaignId',
      GET_TRANSLATED_QUESTIONS: 'http://localhost:3001/api/v1/questions',
      GET_TRANSLATED_ANSWERS: 'http://localhost:3001/api/v1/answers',
      GET_AUTOPOPULATED_WEBSITETYPE:
        'http://localhost:3001/api/v1/websitetypes',
      GET_AUTOPOPULATED_BUSINESS_GROUP:
        'http://localhost:3001/api/v1/businessGroup'
    },
    CAMPAIGN_GET_USER_URL: 'http://localhost:3001/api/v1/user',
    CAMPAIGN_ADD_EDIT_USER_URL: 'http://localhost:3001/api/v1/user/add',
    CAMPAIGN_DELETE_USER_URL: 'http://localhost:3001/api/v1/user/delete',
    CONNECTED_WORLD_USER_URL: '/bin/ts/public/idm/user',
    CAMPAIGN_HISTORY_LOGS_URL: 'http://localhost:3001/api/v1/logs',
    REQUESTER_DOMAIN_URL: 'http://localhost:3001/api/v1/domains',
    REQUESTER_DELETE_DOMAIN_URL: 'http://localhost:3001/api/v1/domains/delete',
    VALIDATE_CAMPAIGN_NAME_URL:
      'http://localhost:3001/api/v1/campaigns/validate',
    CAMPAIGN_AUTHOR_ACCESS: 'http://localhost:3001/api/v1/authAccess',
    VALIDATE_CAMPAIGN_AUTHOR_ACCESS:
      'http://localhost:3001/api/v1/authAccess/validate',
    CAMPAIGN_PREFERENCE_CENTRE_UPLOAD_URL:
      'http://localhost:3001/api/v1/admin/prefcentre',
    BRAND_CREATION_UPLOAD_URL:
      'http://localhost:3001/api/v1/admin/createBrands',
    CAMPAIGN_DETAILS_UPLOAD_URL:
      'http://localhost:3001/api/v1/campaigndetails/upload',
    CONFLUENCE_FORMAT_URL:
      'https://ce-platform.atlassian.net/wiki/spaces/IWG/pages/148114044/Ciab+BAU+to+Production+Non+D2',
    CONFLUENCE_ADOBE_CLASSIC_IMPLEMENTATION:
      'https://ce-platform.atlassian.net/wiki/spaces/IWG/pages/360416294/Campaign+Form+Auto-Generation+-+AEM+Implementation+And+Approach',
    CONFLUENCE_ADOBE_TRANSLATED_IMPLEMENTATION:
      'https://ce-platform.atlassian.net/wiki/spaces/UNI/pages/603751240/Auto-generated+Sign+UP+form+from+Campaign+Wizard+-+Adobe+Templated',
    CONFLUENCE_OTHER_PLATFORM_IMPLEMENTATION:
      'https://ce-platform.atlassian.net/wiki/spaces/IWG/pages/9576481/SignUp+Forms',
    CAMPAIGN_JSON_OUTPUT_URL: 'http://localhost:3001/api/v1/campaigns',
    CAMPAIGN_JSON_URL:
      'https://campaignwizard-uat.unileversolutions.com/campaign-wizard/campaign',
    CAMPAIGN_CLONE_URL: 'http://localhost:3001/api/v1/campaigns/clone',
    FETCH_ALL_QUESTIONS: 'http://localhost:3001/api/v1/questions/getAll',
    FETCH_ALL_ANSWERS: 'http://localhost:3001/api/v1/answers/getAll',
    RELEASE_NOTE_URL: 'http://localhost:3001/api/v1/releasenote',
    QNA_CAMPAIGN_DETAILS: 'http://localhost:3001/api/v1/reports/qna',
    FEEDBACKS_URL: 'http://localhost:3001/api/v1/feedbacks',
    EMAIL_BRIEFINGS: 'http://localhost:3001/api/v1/emailbriefings',
    EMAIL_CORE_TEMPLATES_URL:
      'http://localhost:3001/api/v1/email-core-templates',
    BRAND_DEFAULTS_URL: 'http://localhost:3001/api/v1/brandDefaults',
    BRAND_DEFAULTS_HISTORY_URL:
      'http://localhost:3001/api/v1/brand-defaults-history',
    EMAIL_TEMPLATE_EDIT_URL: 'http://localhost:3001/api/v1/email-template-edit',
    CAMPAIGN_CONFIG_URL: 'http://localhost:3001/api/v1/config/campaign',
    FETCH_DASHBOARD_MATRIX: 'http://localhost:3001/api/v1/metrics/get',
    EMAIL_BEE_GET_ROWS_URL: 'http://localhost:3001/api/v1/emailRowBlocks',
    SEND_TEST_EMAIL_URL:
      'http://localhost:3001/api/v1/emailbriefings/send/testEmail',
    EMAIL_TEMPLATE_EXPORT: 'http://localhost:3001/api/v1/emailCSApi',
    APPROVER_BULK_UPLOAD:
      'http://localhost:3001/api/v1/user/approver/excel/upload',
    MAJOR_ENRICHMENT_REPORT:
      'http://localhost:3001/api/v1/reports/new-major-enrichment-questions',
    CAMPAIGN_ROUTINE_URL: 'http://localhost:3001/api/v1/user/routineTask',
    CAMPAIGN_LOGOUT_TRACKING_URL: DOMAIN_API_LOCAL + '/v1/campaignLog/logout',
    EMAIL_TEMPLATE_VALIDATION: `http://localhost:3001/api/v1/emailbriefings`,
    LIVE_EMAIL_TEMPLATES:
      'http://localhost:3001/api/v1/emailbriefings/liveEmailTemplates',
    OPT_OUT_BULK_UPLOAD:
      'http://localhost:3001/api/v1/smsBrandDetails/excel/upload',
    ADMIN_SETTINGS_HISTORY_LOGS:
      'http://localhost:3001/api/v1/adminSettingsHistory'
  },
  saasServiceUrls: {
    FORM_FIELDS_METADATA: 'http://localhost:3001/api/v1/authoring',
    FORM_PREVIEW_ENDPOINT: 'http://localhost:3001/api/v1/form/saveConfig/',
    FORM_PREVIEW_URL: 'https://uat-forms-widget.unileversolutions.com/',
    SAAS_EDIT_CAMPAIGN_ENDPOINT: 'http://localhost:3001/api/v1/editCampaign/'
  },
  communications: {
    FETCH_SMS_BRAND_DETAIL: 'http://localhost:3001/api/v1/smsBrandDetails',
    POST_SMS_BRAND_DETAIL: 'http://localhost:3001/api/v1/communications',
    POST_SMS_CAMPAIGN_DETAILS:
      'http://localhost:3001/api/v1/communicationdetails',
    POST_SMS_CAMPAIGN_ADDITIONAL_INFO_DETAILS:
      'http://localhost:3001/api/v1/communicationdetails',
    UPDATE_SMS_CAMPAIGN_STATUS:
      'http://localhost:3001/api/v1/communications/updateStatus',
    GET_SAVED_SMS_DETAIL: 'http://localhost:3001/api/v1/communications',
    GET_SMS_REVIEW_DETAIL: 'http://localhost:3001/api/v1/communications',
    VALIDATE_SMS_NAME: 'http://localhost:3001/api/v1/communications/validate',
    SMS_CAMPAIGN_LIST_SERVICE_URL:
      'http://localhost:3001/api/v1/communications/',
    UPDATE_FILE_UPLOAD_AND_SMS_CAMPAIGN_STATUS:
      'http://localhost:3001/api/v1/communications/updateFileUploadAndStatus',
    DOWNLOAD_SMS_COST_ESTIMATE:
      'http://localhost:3001/api/v1/communications/download/costEstimate',
    DOWNLOAD_SMS_PURCHASE_ORDER:
      'http://localhost:3001/api/v1/communications/download/purchaseOrder'
  },

  consentsApi: {
    HOST: 'http://localhost:3001',
    URL: '/api/v1/consents'
  },
  featureFlags: {
    EDIT_FOR_SAAS: true,
    ENABLE_EPSILON_JOURNEY: true,
    ENABLE_GUIDELINES: true,
    ENABLE_QNA_GUIDELINES_CHECKBOX: true,
    ENABLE_NEW_QNA_FOR_SAAS: true,
    ENABLE_NEW_QNA_FOR_NONSAAS: true,
    ENABLE_MASTER_DATA_ONLY_CAMPAIGN: true,
    ENABLE_SINGLE_PAGE_FIELD_SELECTION: true,
    ENABLE_ENTERPRISE_CONSENT: true,
    ENABLE_CONTENT_INTEREST_AREAS: true,
    ENABLE_CAMPAIGN_STATUS_TIMELINE: true,
    EDITABLE_CONSENTS: true,
    ENABLE_FEEDBACK_FORM: true,
    ENABLE_FEEDBACK_EXPORT_LINK: true,
    ENABLE_CAMPAIGN_EMAIL: true,
    ENABLE_BRAND_DEFAULTS: true,
    ENABLE_EMAIL_PRIVILEGED_ACCESS: false,
    ENABLE_AEM_FORM_OPTION: false,
    EMAIL_APPROVAL_REQUIRED: true,
    EMAIL_EXECUTION_REQUIRED: true,
    EMAIL_UPLOAD_IMAGE_ENABLED: false,
    ENABLE_EMAIL_COLLECTION_BUTTON: true,
    ENABLE_HIDE_EMAIL_EDITOR_DEFAULT_ROWS: false,
    ENABLE_CAMPAIGN_ID_GOVERNANCE: true,
    ENABLE_SMS_APPROVAL_JOURNEY: true,
    ENABLE_SMS_UNICODE_DETECTION: true
  },
  RELEASENOTE_PAGE: '/campaign-wizard/releasenotes',
  CAMPAIGN_URL: 'http://localhost:3000/campaign-wizard/campaign/',
  BEE_PLUGIN_CLIENT_ID: '4e5ddd49-b372-4ce7-b387-3d2ae6fa3f6f',
  BEE_PLUGIN_CLIENT_SECRET:
    'M928Ghs0IDqMaF1JSU3zU5Tf4ETHlGBtiEiND0duvaI0XaIcHtZX',
  TEMPLATE_IMAGE_BASE_URL: 'http://localhost:3001',
  CONSUMERDATA_ENRICHMENT_PAGE: '/campaign-wizard/consumerdataenrichment',
  APPROVER_LIST_PAGE: '/campaign-wizard/approvers',
  APPROVER_LIST_ADMIN_PAGE: '/campaign-wizard/user',
  TAB_BASE_URL: 'https://assets.unileversolutions.com/v1/',
  EMAIL_COLLECTION_URL:
    'http://localhost:3000/campaign-wizard/emailbriefing/email-collection',
  TinyMCEapiKey: 'qu7qvd479o6awpunip87ztwactvdeqzjka9bs0xo1u19rfpo', // Jevin's keys
};

export default constants;
