import React, { useEffect, useState } from 'react';
import { FieldError } from '../../atoms/FieldError';
import { get } from 'lodash';
import { useWatch } from 'react-hook-form';
import SingleColorPicker from '../SingleColorPicker/SingleColorPicker';
import Size from 'components/atoms/Size/Size';
import FontSelector from 'pages/BrandEmailDefaults/FontSelector/FontSelector';
import Select, { components } from 'react-select';

const fontWeightOptions = [
  {
    label: 'Regular',
    value: '400'
  },
  {
    label: 'Bold',
    value: '700'
  }
];

const Option = props => {
  return (
    <div style={{ fontFamily: props.data.fontFamily }}>
      <components.Option {...props} />
    </div>
  );
};

export const ButtonsDefaultInput = ({
  name,
  description,
  type,
  rules,
  control,
  register,
  label,
  errors,
  options,
  watchInput,
  defaultValue,
  defaultWatchValue,
  getValues,
  setValue,
  ...props
}) => {
  const errorMessages = get(errors, name);
  const hasError = !!(errors && errorMessages);

  const [values, setValues] = useState(
    defaultValue || {
      color: '',
      backgroundColor: '',
      fontSize: 14,
      borderRadius: 4
    }
  );

  const brand = useWatch({
    control,
    name: watchInput,
    defaultValue: defaultWatchValue
  });

  useEffect(() => {
    if (props.onWatch) {
      props.onWatch(brand, onChange);
    }
  }, [brand]);

  const onChange = (property, value) => {
    setValues(values => {
      const updatedValue = { ...values, [property]: value };
      setValue(name, updatedValue);
      return updatedValue;
    });
  };

  if (!brand) {
    return null;
  }

  return (
    <div className="form-group mt-30">
      <div className="d-flex flex-column">
        <strong className="mb-10">{label}</strong>
        <span className="description mb-10">{description}</span>
      </div>
      <FontSelector
        sampleText="I am your button"
        label="Button font"
        property="fontFamily"
        placeholder="Select a button text font"
        onChange={onChange}
        type="button"
        value={values.fontFamily}
        values={values}
      />
      <div className="d-flex" style={{ alignItems: 'flex-start' }}>
        <SingleColorPicker
          label="Background colour"
          property="backgroundColor"
          value={values.backgroundColor}
          onChange={onChange}
        />
        <SingleColorPicker
          className="ml-30"
          label="Text colour"
          property="color"
          value={values.color}
          onChange={onChange}
        />
      </div>
      <div className="d-flex mt-20">
        <Size
          value={values.fontSize}
          label="Text size"
          onChange={size => onChange('fontSize', size)}
        />
        <Size
          className="ml-30"
          value={values.borderRadius}
          label="Border radius"
          onChange={size => onChange('borderRadius', size)}
        />
      </div>
      <div className="mt-2" style={{ width: 300 }}>
        <label>Button font weight</label>
        <Select
          className="react-select-container"
          classNamePrefix="react-select"
          components={{ Option }}
          options={fontWeightOptions}
          name={'qtype'}
          label="Button font weight"
          onChange={selected => onChange('fontWeight', selected.value)}
          value={fontWeightOptions.find(
            option => option.value === values.fontWeight
          )}
        />
      </div>
      {!props.disableError && <FieldError errors={errors} name={name} />}
    </div>
  );
};
