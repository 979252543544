import React, { forwardRef, useEffect, useState } from 'react';
import { Input } from '../../atoms/Input';
import { FieldError } from '../../atoms/FieldError';
import { get } from 'lodash';
import { useWatch, Controller } from 'react-hook-form';
import { FormDatePicker } from '../FormDatePicker';
import { FormReactSelect } from '../FormReactSelect';
import moment from 'moment';
import classNames from 'classnames';
import fixedDateAndTimeImg from '../../../images/fixedDateAndTime.svg';
import dynamicTriggerImg from '../../../images/dynamicTrigger.svg';
import SmsTriggerTypeInfoModal from './SmsTriggerTypeInfoModal';
import { DateTimeInput } from './DateTimeInput';
import { DONT_SEND } from '../EmailScheduleInput';
import WinningVariantIndicator from 'components/atoms/WinningVariantIndicator';
import { getSMSScheduleTriggerOptions, getSubmittedSMSCampaignScheduleDates } from '../../../selectors';
import { FormInput } from '../FormInput';

const formatDateTime = (dateTime, format) => {
  if (dateTime) {
    const momentParsedDate = moment.utc(dateTime);
    return momentParsedDate.isValid() ? momentParsedDate.format(format) : '';
  }
  return '';
};

const FixedDataAndTimefields = ({
  control,
  watchInput,
  errors,
  name,
  register,
  dateFormat,
  defaultValue,
  setValue,
  abTesting
}) => {
  const defaultType = get(defaultValue, 'type');
  const triggerType = useWatch({
    control,
    name: watchInput,
    defaultValue: defaultType
  });
  const currentDate = moment();

  const { performABTests, noOfABVariants, abTestElements } = abTesting || {};
  const isABTestOnSendTime = performABTests && abTestElements === 'sendTime';

  return triggerType === 'fixedDateAndtime' ? (
    <div className="pt-0">
      {isABTestOnSendTime ? (
        <>
          <p className="pt-2 pb-1">
            <strong>A/B test - Variant A</strong>
          </p>
          <DateTimeInput
            name={name}
            variant="VariantA"
            currentDate={currentDate}
            control={control}
            errors={errors}
            defaultValue={get(defaultValue, 'details')}
            setValue={setValue}
            placeholder="DD/MM/YYYY"
          />

          <p className="pt-2 pb-1">
            <strong>A/B test - Variant B</strong>
          </p>
          <DateTimeInput
            name={name}
            variant="VariantB"
            currentDate={currentDate}
            control={control}
            errors={errors}
            defaultValue={get(defaultValue, 'details')}
            setValue={setValue}
            placeholder="DD/MM/YYYY"
          />
        </>
      ) : (
        <div className="pt-2">
          <DateTimeInput
            name={name}
            variant=""
            currentDate={currentDate}
            control={control}
            errors={errors}
            defaultValue={get(defaultValue, 'details')}
            setValue={setValue}
            placeholder="DD/MM/YYYY"
          />
        </div>
      )}

    </div>
  ) : null;
};

const TriggerConditionField = ({
  control,
  watchInput,
  errors,
  name,
  dateFormat,
  register,
  setValue,
  labelClass,
  defaultValue,
  trigger,
  smsType
}) => {
  const defaultType = get(defaultValue, 'type');
  const errorMessages = get(errors, name);
  const hasError = !!(errors && errorMessages);
  const currentDate = moment();

  const { options, defaultOption } = getSMSScheduleTriggerOptions(
    smsType,
    get(defaultValue, 'details.triggerCondition.value')
  );

  const [triggerType, triggerCondition] = useWatch({
    control,
    name: watchInput,
    defaultValue: [defaultType, defaultOption]
  });

  const getfixedDateInBirthdayMonthOptins = ()=>{
    const optionsArr = []
    for(let i=1;i<=28;i++){
      optionsArr.push({
        label : i.toString(),
        value : i
      })
    }
    return optionsArr
  }

  return triggerType === 'dynamicTrigger' ? (
    <div className="pt-2">
      <FormReactSelect
        id="triggerCondition"
        name={`${name}.details.triggerCondition`}
        label={'Trigger condition'}
        placeholder={'Please select trigger condition'}
        control={control}
        errors={errors}
        defaultValue={defaultOption}
        options={options}
        setValue={setValue}
      />

      {triggerCondition?.value === 'other' && (
        <FormInput
          id="otherTriggerDetails"
          type="text"
          name={`${name}.details.otherTriggerDetails`}
          label="Please specify trigger condition"
          key="otherTriggerDetails"
          register={register}
          errors={errors}
          defaultValue={get(defaultValue, 'details.otherTriggerDetails')}
          setValue={setValue}
        />
      )}
      {triggerCondition?.value === 'fixedDateInBirthdayMonth' && (
        <div className="pt-0 mt-10">
          <FormReactSelect
            id="triggerConditionBirthdayDate"
            name={`${name}.details.triggerConditionBirthdayDate`}
            label={`Select date`}
            placeholder={'Please select date'}
            control={control}
            errors={errors}
            defaultValue={get(
              defaultValue,
              'details.triggerConditionBirthdayDate'
            )}
            options={getfixedDateInBirthdayMonthOptins()}
            setValue={setValue}
          />
           <FieldError errors={errors}  name={`${name}.type`} />
        </div>
      )}

      {triggerCondition?.value != 'fixedDateInBirthdayMonth' &&
      (<div className="form-row row-col-padding">
        <FormDatePicker
          name={`${name}.details.triggerConditionStartDate`}
          label="Start date"
          control={control}
          errors={errors}
          dateFormat={'DD/MM/YYYY'}
          timeFormat={false}
          defaultDisabled={false}
          defaultValue={get(defaultValue, 'details.triggerConditionStartDate')}
          isValidDate={givenDate => {
            return givenDate.isSameOrAfter(currentDate, 'day');
          }}
          setValue={setValue}
          placeholder="DD/MM/YYYY"
          inputGroupClassName="datetime-width"
        />

        <div className="ml-3">
          <FormDatePicker
            name={`${name}.details.triggerConditionEndDate`}
            label="End date"
            control={control}
            errors={errors}
            dateFormat={'DD/MM/YYYY'}
            timeFormat={false}
            defaultDisabled={false}
            defaultValue={tempfunction(defaultValue)}
            isValidDate={givenDate => {
              return givenDate.isSameOrAfter(currentDate, 'day');
            }}
            setValue={setValue}
            placeholder="DD/MM/YYYY"
            onWatch={(results, properties, setProperties) => {
              if (results) {
                setValue(`${name}.details.triggerConditionEndDate`, '');
                if (trigger) {
                  trigger(`${name}.details.triggerConditionEndDate`);
                }
              }
              setProperties({
                disabled: results
              });
            }}
            defaultWatchValue={get(
              defaultValue,
              'details.triggerConditionAlwaysOn'
            )}
            watchInput={`${name}.details.triggerConditionAlwaysOn`}
            inputGroupClassName="datetime-width"
          />
          <div className="form-check mb-10" aria-live="polite">
            <Input
              name={`${name}.details.triggerConditionAlwaysOn`}
              {...(register &&
                register(`${name}.details.triggerConditionAlwaysOn`))}
              id="alwaysOn"
              // value="alwaysOn"
              type="checkbox"
              defaultChecked={get(
                defaultValue,
                'details.triggerConditionAlwaysOn'
              )}
            />
            <label htmlFor="alwaysOn" className="form-check-label">
              <p className={labelClass}>No end date, always on</p>
            </label>
          </div>
        </div>
      </div>)}
    </div>
  ) : null;
};

const SmsTriggerRadioInput = forwardRef(
  ({ name, value, smsType, onChange }, ref) => {
    const handleOnChange = e => {
      onChange(e.target.value);
    };
    return (
      <div className="themes-container d-flex flex-wrap">
        {smsType !== 'birthdaySMS' && smsType !== 'welcomeSMS' && (
          <label
            htmlFor="fixedDateAndtime"
            className={classNames('theme-item theme-item-wide d-flex align-items-center', {
              selected: value === 'fixedDateAndtime'
            })}
          >
            <div className="mb-0">
              <Input
                name={`${name}`}
                type="radio"
                id="fixedDateAndtime"
                value="fixedDateAndtime"
                checked={value === 'fixedDateAndtime'}
                onChange={handleOnChange}
                disableStyleClass={true}
              />
              <div className="label-text d-flex justify-content-center align-items-center">
                <img
                  src={fixedDateAndTimeImg}
                  alt={'Fixed date & time'}
                  className="content-theme-icon"
                />
                <p className="theme-label text-size-small">{'Fixed date & time'}</p>
              </div>
            </div>
          </label>
        )}

        <label
          htmlFor="dynamicTrigger"
          className={classNames('theme-item theme-item-wide d-flex align-items-center', {
            selected: value === 'dynamicTrigger'
          })}
        >
          <div className="mb-0">
            <Input
              name={`${name}`}
              id="dynamicTrigger"
              value="dynamicTrigger"
              type="radio"
              checked={value === 'dynamicTrigger'}
              onChange={handleOnChange}
              disableStyleClass={true}
            />
            <div className="label-text d-flex justify-content-center align-items-center">
              <img
                src={dynamicTriggerImg}
                alt={'Dynamic trigger'}
                className="content-theme-icon-alt"
              />
              <p className="theme-label text-size-small">{'Dynamic trigger'}</p>
            </div>
          </div>
        </label>
      </div>
    );
  }
);

export const SmsTriggerInput = ({
  name,
  rules,
  register,
  label,
  errors,
  setValue,
  control,
  defaultValue,
  smsType,
  abTesting,
  ...props
}) => {
  const errorMessages = get(errors, name);
  const hasError = !!(errors && errorMessages);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const getSelectedOption = value => {
    if (value === 'newsletter' || value === 'coreinfo') {
      return 'fixedDateAndtime';
    } else if (value === 'welcomeSMS' || value === 'birthdaySMS') {
      return 'dynamicTrigger';
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (defaultValue) {
      if (
        get(defaultValue, 'type') === 'fixedDateAndtime' &&
        (smsType === 'welcomeSMS' || smsType === 'birthdaySMS')
      ) {
        setValue(`${name}.type`, 'dynamicTrigger');
      } else {
        setValue(`${name}.type`, get(defaultValue, 'type'));
      }
    } else {
      if (smsType) {
        const selectedOption = getSelectedOption(smsType);
        if (selectedOption) {
          setValue(`${name}.type`, selectedOption);
        }
      }
    }
  }, [defaultValue, smsType]);

  const checkFixedDateAndTime = (smsTypeValue, givenValue) => {
    if (smsTypeValue) {
      const testResult =
        smsTypeValue === 'newsletter' || smsTypeValue === 'coreinfo';
      return testResult;
    }
    return get(givenValue, 'type') === 'fixedDateAndtime';
  };

  const checkDynamicTigger = (smsTypeValue, givenValue) => {
    if (smsTypeValue) {
      const testResult = smsTypeValue === 'welcomeEmail';
      return smsTypeValue === 'welcomeEmail';
    }
    return get(givenValue, 'type') === 'dynamicTrigger';
  };

  return (
    <div className="form-group email-secondary-theme" aria-live="polite">
      <label htmlFor={props.id}>
        <strong>{label}</strong>
        <span className="cw-icon cw-icon--help ml-10">
          <i
            className="fa fa-info-circle"
            style={{ color: 'var(--color-base)' }}
            onClick={() => setIsModalOpen(true)}
          ></i>
        </span>
      </label>
      <SmsTriggerTypeInfoModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
      <div>
        <Controller
          name={`${name}.type`}
          control={control}
          defaultValue={get(defaultValue, `${name}.type`)}
          render={({ field }) => (
            <SmsTriggerRadioInput {...field} smsType={smsType} />
          )}
        />
        <FixedDataAndTimefields
          control={control}
          watchInput={`${name}.type`}
          errors={errors}
          register={register}
          name={name}
          dateFormat={props.dateFormat}
          defaultValue={
            defaultValue ||
            (smsType === 'newsletter' || smsType === 'coreinfo'
              ? { type: 'fixedDateAndtime' }
              : '')
          }
          setValue={setValue}
          abTesting={abTesting}
        />
        <TriggerConditionField
          control={control}
          watchInput={[`${name}.type`, `${name}.details.triggerCondition`]}
          errors={errors}
          name={name}
          dateFormat={props.dateFormat}
          setValue={setValue}
          register={register}
          labelClass={props.labelClass}
          defaultValue={
            defaultValue ||
            (smsType === 'welcomeSMS' || smsType === 'birthdaySMS'
              ? { type: 'dynamicTrigger' }
              : '')
          }
          smsType={smsType}
          trigger={props.trigger}
        />
      </div>

      <FieldError errors={errors} name={`${name}.type`} />
    </div>
  );
};

export const sentAfterSms = data => {
  if (data && data.sendAfter) {
    const isDontSend = data.sendAfter.value !== DONT_SEND;
    return [
      {
        label: 'Send follow up',
        value: isDontSend
          ? `Yes, after ${get(data, 'sendAfter.label')}`
          : `No, don't send`
      }
    ];
  } else {
    return [];
  }
};

const deliveryDateTime = (dateTime, abTesting, winningVariant) => {
  const smsDeliveryDate = get(dateTime, 'details.smsDeliveryDate');
  const smsDeliveryTime = get(dateTime, 'details.smsDeliveryTime');

  const formattedValue = (date, time) =>
    `${formatDateTime(date, 'DD MMM YYYY')}${
      time ? `, ${formatDateTime(time, 'hh:mm a')} UTC` : ''
    }`;

  if (abTesting.performABTests && abTesting.abTestElements === 'sendTime') {
    const smsDeliveryDateVariantA = get(
      dateTime,
      'details.smsDeliveryDateVariantA',
      ''
    );
    const smsDeliveryTimeVariantA = get(
      dateTime,
      'details.smsDeliveryTimeVariantA',
      ''
    );
    const smsDeliveryDateVariantB = get(
      dateTime,
      'details.smsDeliveryDateVariantB',
      ''
    );
    const smsDeliveryTimeVariantB = get(
      dateTime,
      'details.smsDeliveryTimeVariantB',
      ''
    );

    return (
      <>
        <p>{`Variant A | ${formattedValue(
          smsDeliveryDateVariantA,
          smsDeliveryTimeVariantA
        )}`}</p>
        {winningVariant === 'variantA' && (
          <WinningVariantIndicator variant={winningVariant} />
        )}
        <p>{`Variant B | ${formattedValue(
          smsDeliveryDateVariantB,
          smsDeliveryTimeVariantB
        )}`}</p>
        {winningVariant === 'variantB' && (
          <WinningVariantIndicator variant={winningVariant} />
        )}
      </>
    );
  } else {
    return formattedValue(smsDeliveryDate, smsDeliveryTime);
  }
};
export const DisplaySubmittedSmsTriggerInput = (data) =>{
  const dateObj = getSubmittedSMSCampaignScheduleDates(data);
  const list = [
    {
      label: 'SMS trigger',
      value: 'Fixed date & time'
    }
  ];

  list.push({
    label: 'Delivery date and time',
    value:  <>
        <p>{dateObj.date1}</p>
        <p>{dateObj.date2}</p>
      </>,
    mustShow: true
  });
  return list
}

export const DisplaySmsTriggerInput = (
  data,
  abTesting = {},
  winningVariant
) => {
  if (data && data.type) {
    if (data.type === 'fixedDateAndtime') {
      const list = [
        {
          label: 'SMS trigger',
          value: 'Fixed date & time'
        }
      ];

      list.push({
        label: 'Delivery date and time',
        value: deliveryDateTime(data, abTesting, winningVariant),
        mustShow: true
      });

      return list;
    }
    if (data.type === 'dynamicTrigger') {
      const result = []

      result.push({
        label: 'SMS trigger',
        value: 'Dynamic trigger'
      })

      if(get(data, 'details.triggerCondition.value') === 'fixedDateInBirthdayMonth') {
        const birthdate = get(data, 'details.triggerConditionBirthdayDate.value')
        const value = birthdate 
          ? `Fixed date - Day ${get(data, 'details.triggerConditionBirthdayDate.value')} of the birthday month`
          : `${get(data, 'details.triggerCondition.label', '')}`
        result.push({
          label: 'Trigger condition',
          value
        })
      } else {
        result.push(
          {
            label: 'Start date',
            value: formatDateTime(
              data.details.triggerConditionStartDate,
              'DD MMM YYYY'
            )
          },
          {
            label: 'End date',
            value: get(data, 'details.triggerCondition.alwaysOn')
              ? ''
              : get(data, 'details.triggerConditionAlwaysOn')
              ? 'Always on'
              : formatDateTime(
                  data.details.triggerConditionEndDate,
                  'DD MMM YYYY'
                )
          },
          {
            label: 'Trigger condition',
            value: `${get(data, 'details.triggerCondition.label')} ${
              get(data, 'details.otherTriggerDetails')
                ? ` - ${get(data, 'details.otherTriggerDetails')}`
                : ''
            }`
          }

        )
      }
      return result
    }
  }
  return [
    {
      label: 'SMS trigger',
      value: ''
    }
  ];
};

const tempfunction = data => {
  const t = get(data, 'details.triggerConditionEndDate');
  if (t === null) {
    return 'DD/MM/YYYY';
  } else if (t === undefined) {
    return undefined;
  } else {
    const formatedDate = moment.utc(t);
    return formatedDate;
  }
};
