import * as yup from 'yup';
import constants from '../../constants';
import { ABTestResultInput } from 'components/molecules/ABTestResultInput';
import {get} from 'lodash';

const hasVariantC = (emailBrief) => {
  const noOfVariants = get(emailBrief, 'abTesting.noOfABVariants', null);
  return (noOfVariants && noOfVariants === "3") ? true : false
}

export default (handleFormClose, handleSubmit, emailBriefing) => {
  const isEdit = get({}, '_id')? true : false;
  return {
    formType: 'custombuttons',
    submitBtnLabel: isEdit? 'Save as new':'Save and close',
    previousBtnLabel: 'Cancel',
    secondaryBtnLabel: 'Update existing',
    disableFormError: true,
    fields: [
      {
        type: 'custom',
        component: ABTestResultInput,
        name: 'winningVariant',
        id: 'winningVariant',
        label: 'Select winning variant',
        showVariantC: hasVariantC(emailBriefing),
        defaultValue: emailBriefing,
        rules: yup
          .string()
          .required('Please select variant')
          .nullable()
      }
    ],
    onSubmit: (data, { user, setFormErrorMessage }) => {
        handleSubmit(data, { user, setFormErrorMessage })
    },
    onPrevious: () => {
      handleFormClose();
    },
    onSecondaryClick: isEdit? (data, {user, setFormErrorMessage}) => {
        console.log('data: ', data);
    } : null,
    isEdit: isEdit
  };
};
