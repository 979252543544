import React, { useState } from 'react';
import Modal from 'react-modal';
import { FormBuilder } from '../../components/organisms/FormBuilder';
import constants from 'constants/config';
import customBrandRowSchema from './customBrandRowSchema';

const CustomBrandRowsModel = ({
  isOpen,
  onCloseModal,
  handleCloseForm,
  handleSubmit,
  user,
  emailBriefing,
  dispatch,
  countries,
  brands,
  customFilters
}) => {
  Modal.setAppElement('#root');
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCloseModal}
      className="cw-modal cw-modal--beeSaveRow"
      contentLabel="Save row form"
      style={{
        overlay: {
          backgroundColor: constants.EMAIL_OVERLAY_BACKGROUND
        }
      }}
    >
      <div>
        <button
          className="cw-modal--close"
          title="Close"
          onClick={handleCloseForm}
        >
          <i className="fas fa-times"></i>
        </button>
        <h2 className="cw-heading--primary mb-20">
          <strong>Find other brand tiles</strong>
        </h2>
        {emailBriefing && (
          <FormBuilder
            schema={customBrandRowSchema(
              dispatch,
              handleCloseForm,
              handleSubmit,
              emailBriefing,
              {
                countries,
                brands,
                customFilters
              }
            )}
            user={user}
            isInModal
          />
        )}
      </div>
    </Modal>
  );
};

export default CustomBrandRowsModel;
