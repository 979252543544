import React from 'react';

const MarkScheduledIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.998 4V6.002C13.2831 6.03416 12.6062 6.33317 12.101 6.84L7.271 11.669C6.862 12.079 6.571 12.592 6.431 13.154L6.22 13.999H2.5C1.83696 13.999 1.20107 13.7356 0.732233 13.2668C0.263392 12.7979 0 12.162 0 11.499V3.999L13.998 4ZM11.498 0C12.161 0 12.7969 0.263392 13.2658 0.732233C13.7346 1.20107 13.998 1.83696 13.998 2.5V3H0V2.5C0 1.83696 0.263392 1.20107 0.732233 0.732233C1.20107 0.263392 1.83696 0 2.5 0H11.498ZM7.978 12.376L12.808 7.546C13.1589 7.19538 13.6347 6.99852 14.1307 6.9987C14.6267 6.99889 15.1024 7.19612 15.453 7.547C15.8036 7.89788 16.0005 8.37367 16.0003 8.86971C16.0001 9.36574 15.8029 9.84138 15.452 10.192L10.623 15.02C10.3416 15.3015 9.98908 15.5013 9.603 15.598L8.105 15.973C7.95601 16.01 7.79999 16.0078 7.6521 15.9667C7.50421 15.9255 7.36947 15.8468 7.26097 15.7382C7.15247 15.6297 7.07391 15.4948 7.03291 15.3469C6.99192 15.199 6.98988 15.043 7.027 14.894L7.401 13.396C7.498 13.01 7.697 12.657 7.979 12.376H7.978Z"
        fill="#007DBB"
      />
    </svg>
  );
};

export default MarkScheduledIcon;
