import * as yup from 'yup';
import { BeeRowTypeInput } from 'components/molecules/BeeRowTypeInput';
import FormWarningsInput from 'components/molecules/FormWarningsInput';
import constants from '../../constants';
import { get } from 'lodash';

export default (handleFormClose, handleSubmit, emailBriefing, savedRowData,isUserRequestorOrApprover, userInfo) => {
  const isEdit = get(savedRowData, '_id') ? true : false;
  return {
    formType: 'custombuttons',
    submitBtnLabel: isEdit ? 'Save as new' : 'Save tile',
    previousBtnLabel: 'Cancel',
    secondaryBtnLabel: 'Update existing',
    disableFormError: true,
    fields: [
      {
        type: 'text',
        name: 'rowName',
        id: 'rowName',
        label: 'Tile name',
        maxLength: '50',
        defaultValue: get(savedRowData, 'rowName'),
        rules: yup
          .string()
          .trim()
          .max(50, 'Max 50 characters are allowed')
          .required('Please enter tile name')
      },
      {
        type: 'custom',
        name: 'rowType',
        component: BeeRowTypeInput,
        id: 'rowType',
        label: 'Tile context',
        rules: yup
          .object({
            type: yup
              .string()
              .required('Please select tile type')
              .typeError('Please select tile type'),
            country: yup.object(),
            brand: yup.object().when('type', {
              is: 'brandSpecific',
              then: yup
                .object()
                .shape({ value: yup.string(), label: yup.string() })
            }),
            crossBrand: yup.array().when('type', {
              is: 'crossBrand',
              then: yup
                .array()
                .of(
                  yup
                    .object()
                    .shape({ value: yup.string(), label: yup.string() })
                )
                .min(1, 'Please select at least one brand')
            })
          })
          .required('Please select tile type')
          .typeError('Please select tile type'),
        emailBriefing,
        savedRowData,
        isUserRequestorOrApprover,
        userInfo
      },
      {
        type: 'reactselect',
        name: 'rowCategory',
        id: 'rowCategory',
        label: 'Tile category',
        placeholder: 'Please select',
        rules: yup
          .object()
          .when('rowType.type', {
            is: data => data === 'crossBrand',
            then: yup.object({
              label: yup.string().required(),
              value: yup
                .string()
                .matches(
                  /(headers|footers)/,
                  'Cross brand rows can only be saved as headers or footers'
                )
                .required()
            }),
            otherwise: yup.object().shape({
              label: yup.string().required(),
              value: yup.string().required()
            })
          })
          .required('Please select tile category')
          .default(undefined),
        hasNestedError: true,
        options: constants.EMAIL_SAVE_ROW_CATEGORIES,
        defaultValue: get(savedRowData, 'rowCategory'),
        watchInput: 'rowType.type',
        onWatch: (value, { options, disabled }, setData) => {
          let m_disabled = disabled;
          let m_options = options;
          if (value === 'crossBrand') {
            m_options = options.filter(item => {
              return !constants.EMAIL_SAVE_ROW_CATEGORIES_BRAND_EXCLUDE.includes(
                item.value
              );
            });
            setData({ options: m_options, disabled: m_disabled });
          } else {
            setData({
              options: constants.EMAIL_SAVE_ROW_CATEGORIES,
              disabled: m_disabled
            });
          }
        }
      },
      {
        type: 'custom',
        component: FormWarningsInput,
        watchInput: [
          'rowCategory',
          'rowType.brand',
          'rowType.country',
          'rowType.type'
        ]
      }
    ],
    onSubmit: (data, { user, setFormErrorMessage }) => {
      const payload = {
        rowName: data.rowName,
        rowType: data.rowType.type,
        rowCategory: data.rowCategory,
        brand:
          data.rowType.type === 'generic'
            ? null
            : data.rowType.type === 'brandSpecific'
            ? data.rowType.brand
            : data.rowType.crossBrand,
        country: data.rowType.country
      };

      handleSubmit(
        {
          ...payload,
          name: data.rowName,
          userName: user.name,
          userEmail: user.emailId
        },
        setFormErrorMessage
      );
    },
    onPrevious: () => {
      handleFormClose();
    },
    onSecondaryClick: isEdit
      ? (data, { user, setFormErrorMessage }) => {
          const payload = {
            rowName: data.rowName,
            rowType: data.rowType.type,
            rowCategory: data.rowCategory,
            brand:
              data.rowType.type === 'generic'
                ? null
                : data.rowType.type === 'brandSpecific'
                ? data.rowType.brand
                : data.rowType.crossBrand,
            country: data.rowType.country
          };

          handleSubmit(
            {
              ...payload,
              name: data.rowName,
              userName: user.name,
              userEmail: user.emailId,
              _id: get(savedRowData, '_id')
            },
            setFormErrorMessage,
            'update'
          );
        }
      : null,
    isEdit: isEdit
  };
};
