// Feedback Form Reducer
import {
  OPEN_FEEDBACK_FORM,
  CLOSE_FEEDBACK_FORM,
  TOGGLE_FEEDBACK_FORM_SUBMISSION,
  SET_FEEDBACKS
} from '../actionTypes';

const initialState = {
  isOpen: false,
  isSubmitting: false,
  feedbacks: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case OPEN_FEEDBACK_FORM: {
      return {
        ...state,
        isOpen: true,
        campaignUid: action.payload.campaignUid,
        userId: action.payload.userId,
        defaultCampaignRequest: action.payload.defaultCampaignRequest,
        showCampaignRequest: action.payload.showCampaignRequest
      };
    }
    case CLOSE_FEEDBACK_FORM: {
      return {
        ...state,
        isOpen: false,
        campaignUid: undefined,
        userId: undefined,
        defaultCampaignRequest: undefined,
        showCampaignRequest: true
      };
    }
    case TOGGLE_FEEDBACK_FORM_SUBMISSION: {
      return { ...state, isSubmitting: action.payload };
    }
    case SET_FEEDBACKS: {
      return { ...state, feedbacks: action.payload };
    }
    default:
      return state;
  }
}
