import React from 'react';
import Modal from 'react-modal';
import constant from '../../constants';

const OnboardingGuidanceSmsCamp = ({ isModalOpen, closeModal }) => {
  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      className="cw-modal cw-modal--branddetail"
      contentLabel="Can't find your brand?"
      style={{
        overlay: {
          backgroundColor: constant.EMAIL_OVERLAY_BACKGROUND
        }
      }}
    >
      <button className="cw-modal--close" title="Close" onClick={closeModal}>
        <i className="fas fa-times"></i>
      </button>
      <h3 className="cw-heading--primary mb-20">
        Onboarding guidance for SMS campaigns
      </h3>
      <p className="mb-10">
      If you are an active brand and market combination, you should find yourself listed in our tool.
        <br /> If you can&#39;t find your brand or market, raise a
        <a href={constant.KANA_URL} target="_blank" rel="noopener noreferrer">
          {` Kana ticket `}
          <i className="fas fa-external-link-alt"></i>
        </a>.
      </p>
      <p className="mb-10">
      Additionally, you have to enable yourself with the SMS service provider. Please follow the <a href={constant.SMS_CAMPAIGNS_ONBOARDING_GUIDANCE_LINK} target="_blank" rel="noopener noreferrer">
          {` onboarding guidance `}
          <i className="fas fa-external-link-alt"></i>
        </a> to get yourself enabled with the provider. After you are onboarded, as a final step, please ensure the global ACS team update your opt-out text in Campaign Wizard, failing which, you will not be able to submit your campaign in Campaign Wizard.
      </p>
      <p>If you have any difficulty in onboarding with the SMS provider, please reach out to Astha Garodia and Marica DellAngello.</p>
    </Modal>
  );
};

export default OnboardingGuidanceSmsCamp;
