import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Row,
  Column,
  FooterLink
} from '../components/common/FooterStyles';
import { openFeedbackForm } from 'actions/feedbackForm';
import footerImage from '../images/Footer_logo2.png';
import unileverLogo from '../images/Unilever_logo.png';
import constant from '../constants';
import { getFeedbackDownloadLink } from '../selectors';
import { withRouter } from 'react-router-dom';

const Footer = ({ ...props }) => {
  const dispatch = useDispatch();
  const { authorizedUserData } = useSelector(state => state.authorized);
  const { group } = authorizedUserData
 
  const getDefaultCampaignRequest = () => {
    const pathList = window.location.pathname.split('/');
    if (
      pathList.includes('smsdashboard') ||
      pathList.includes('communications')
    ) {
      return 'SMS';
    }
    return 'form';
  };

  const isNoFooter = props.noFooterRoutes.some(route =>
    window.location.pathname.includes(route)
  );
  return (
    <div
      className={
        isNoFooter ? 'cw-print-hide bee-editor-footer' : 'cw-print-hide'
      }
    >
      <Box>
        <Row className='footer-row'>
          <Column className="footer-column--logo">
            <img
              className="footer_img_logo"
              src={unileverLogo}
              alt={'Unilever'}
            />
          </Column>
          <Column className="footer-column--menu">
            <FooterLink href={constant.HOMEPAGE} className="footer_link">
              Home
            </FooterLink>
            <FooterLink
              className="footer_link"
              href={constant.CONSUMERDATA_ENRICHMENT_PAGE}
            >
              Enrichment Q&As
            </FooterLink>
            <FooterLink href={group === 'admin' ? constant.APPROVER_LIST_ADMIN_PAGE :  constant.APPROVER_LIST_PAGE  } className="footer_link">
            List of approvers
            </FooterLink> 
          </Column>
          <Column className="footer-column--menu">
          <FooterLink href={constant.REPORTS} className="footer_link">
              Reports
            </FooterLink>
            <FooterLink
              href={constant.RELEASENOTE_PAGE}
              className="footer_link"
            >
              Release updates
            </FooterLink>
            {constant.featureFlags.ENABLE_FEEDBACK_FORM && (
              <FooterLink
                href={'#'}
                className="footer_link"
                onClick={event => {
                  event.preventDefault();
                  dispatch(
                    openFeedbackForm({
                      userId: authorizedUserData._id,
                      defaultCampaignRequest: getDefaultCampaignRequest()
                    })
                  );
                }}
              >
                Feedback
              </FooterLink>
            )}
           
          </Column>
          <Column className="footer-column--menu">
          <FooterLink
              href={constant.KANA_PAGE}
              target="_blank"
              className="footer_link"
            >
              Need Help? Raise a Kana ticket&nbsp;
              <i className="fas fa-external-link-alt"></i>
            </FooterLink>
          </Column>

          <Column className="footer-column--feedback">
            {/* {constant.featureFlags.ENABLE_FEEDBACK_EXPORT_LINK && (
                <FooterLink
                href={getFeedbackDownloadLink()}
                className="footer_link"
                download="feedbacksForm"
                >
                  Download Feedbacks
                </FooterLink>
            )} */}
          </Column>
          <Column>
            <img
              className="footer_img"
              src={footerImage}
              alt={'Lady looking into a mirror'}
            />
          </Column>
        </Row>
      </Box>
    </div>
  );
};
//export default Footer;
export default withRouter(Footer);
