import React, { forwardRef } from 'react';
import classNames from 'classnames';

export const TextArea = forwardRef(
  ({ id, name, type, label, placeholder, rows, hasError, ...props }, ref) => {
    return (
      <textarea
        className={classNames('form-control', { 'is-invalid': hasError })}
        id={id}
        ref={ref}
        name={name}
        aria-label={label}
        placeholder={placeholder}
        rows={rows || '3'}
        {...props}
      ></textarea>
    );
  }
);
