import React, { Component } from 'react';
import { connect } from 'react-redux';
import constant from '../../constants';

class EmailAddressField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      existingEmailList: [],
      email: props.email || '',
      showError: false,
      isNewEmailAddress: (props.email && props.isNewUser) || false
    };
  }
  render() {
    const addOrDeleteIcon =
      this.props.count === 1 ? 'fa-plus-circle' : 'fa-trash-alt';
    const emailSuggestionBoxClassName =
      this.state.existingEmailList.length !== 0 ? 'email-suggestions-line' : '';
    const errorText = this.getErrorMessage();
    const isNewEmail = this.state.isNewEmailAddress;
    return (
      <div className="email-address-field" onMouseLeave={this.onMouseLeave}>
        <div className="email-address-label">{`Email address ${this.props.count}`}</div>
        <div className="email-input-line">
          <div className="email-input-with-new">
            <input
              type="input"
              value={this.state.email}
              className="email-input"
              name={`userEmailAddress_${this.props.count}`}
              onChange={this.handleInputChange}
              onBlur={this.onBlur}
            ></input>
            {isNewEmail && <span className="new-box">New</span>}
          </div>
          <div className="add-or-delete" onClick={this.addOrDelete}>
            <i className={`fas ${addOrDeleteIcon}`}></i>
          </div>
        </div>
        <div
          className="cw-error cw-hidden"
          data-custom-msg={`userEmailAddress_${this.props.count}`}
        >
          <span className="error-icon">
            <i className={`fas fa-exclamation-triangle`} aria-hidden="true"></i>
          </span>
          <span
            className="error-text"
            data-custom-txt={`userEmailAddress_${this.props.count}`}
          ></span>
        </div>

        {errorText ? (
          <div
            className="email-error"
            data-msg={`userEmailAddress_${this.props.count}`}
          >
            <span className="error-icon">
              <i
                className={`fas fa-exclamation-triangle`}
                aria-hidden="true"
              ></i>
            </span>
            <span
              className="error-text"
              data-msg-txt={`userEmailAddress_${this.props.count}`}
            >
              {errorText}
            </span>
          </div>
        ) : (
          <div className={emailSuggestionBoxClassName}>
            {this.state.existingEmailList.map(item => (
              <div
                className="suggested-email-id"
                key={item}
                data-value={item}
                onClick={this.onEmailClick}
              >
                {item}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }

  handleInputChange = event => {
    const emailID = String(event.target.value).trim();
    const targetName = event.target.name;
    if (document.querySelector('[data-custom-msg=' + targetName + ']')) {
      document.querySelector('[data-custom-txt=' + targetName + ']').innerHTML =
        '';
      document
        .querySelector('[data-custom-msg=' + targetName + ']')
        .classList.add('cw-hidden');
    }
    this.setState({
      existingEmailList:
        emailID === ''
          ? []
          : this.props.existingEmailList.filter(item =>
              item.toLowerCase().includes(emailID.toLowerCase())
            ),
      email: emailID,
      showError: false,
      isNewEmailAddress: false
    });
    this.props.saveValidEmail(this.props.count, emailID);
  };

  onEmailClick = event => {
    const emailID = event.currentTarget.dataset.value;
    this.setState({
      email: emailID,
      existingEmailList: [],
      showError: false
    });
    this.props.saveValidEmail(this.props.count, emailID);
  };

  addOrDelete = () => {
    this.props.addOrDelete(this.props.count);
  };

  onBlur = () => {
    this.state.existingEmailList.length === 0 && this.authorizeEmail();
  };

  authorizeEmail = () => {
    const emailID = this.state.email;
    const regex = constant.regex['email'];
    if (this.props.existingEmailList.includes(emailID)) {
      this.props.saveValidEmail(this.props.count, emailID);
      this.setState({ isNewEmailAddress: false, showError: false });
    } else if (regex.test(emailID)) {
      this.props.isLDAPUser(emailID).then(isLDAPUser => {
        if (this.props.isMounted) {
          if (isLDAPUser) {
            this.props.saveValidEmail(this.props.count, emailID);
            this.setState({ isNewEmailAddress: true, showError: false });
          } else {
            this.props.saveValidEmail(this.props.count, emailID);
            this.setState({ isNewEmailAddress: true, showError: true });
          }
        }
      });
    } else {
      this.props.saveValidEmail(this.props.count, emailID);
      this.setState({ showError: true, isNewEmailAddress: false });
    }
  };

  onMouseLeave = () => {
    if (this.state.email && this.state.existingEmailList.length !== 0)
      this.setState({ existingEmailList: [] });
  };

  getErrorMessage = () => {
    if (this.state.showError) {
      if (this.state.isNewEmailAddress) {
        return constant.USER_NOT_IN_GROUP_ERROR;
      } else {
        return this.props.count === 1
          ? constant.MANDATORY_AUTHORING_ERROR_MSG
          : constant.EMPTY_AUTHORING_EMAIL_ERROR;
      }
    } else {
      return undefined;
    }
  };
}
export default EmailAddressField;
