import React, { useState } from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as yup from 'yup';
import { FormBuilder } from '../FormBuilder';
import constants from 'constants/config';
import Loader from '../../Loader';

function HTMLChangesDescription({ history, match, isOpen, onClose, onSubmit }) {
  const [isLoading, setIsLoading] = useState(false);
  const { authorizedUserData } = useSelector(state => state.authorized);

  const dispatch = useDispatch();

  const handleSubmit = (...args) => {
    setIsLoading(true);
    onSubmit(...args);
  };

  return (
    <Modal
      isOpen={isOpen}
      shouldCloseOnOverlayClick={false}
      onRequestClose={onClose}
      className="cw-modal cw-modal--questions cw-modal--changes-description"
      contentLabel="HTML chagnes description"
      style={{
        overlay: {
          backgroundColor: constants.EMAIL_OVERLAY_BACKGROUND
        }
      }}
    >
      {isLoading ? <Loader /> : null}
      <h3 className="cw-heading--primary mb-10">Describe HTML changes</h3>
      <FormBuilder
        schema={aceUserSubmitModalSchema(dispatch, {
          onSubmit: handleSubmit
        })}
        user={authorizedUserData}
      />
    </Modal>
  );
}

const aceUserSubmitModalSchema = (dispatch, { onSubmit, onPrevious }) => ({
  formType: 'simple',
  submitBtnLabel: 'Save and proceed',
  onSubmit: data => {
    onSubmit(data);
  },
  fields: [
    {
      type: 'textarea',
      name: 'changesDescription',
      id: 'changesDescription',
      label: 'Please describe what changed',
      isOptional: true,
      defaultValue: '',
      rules: yup
        .string()
        .trim()
        .max(300, 'Max 300 characters are allowed'),
      maxLength: '300'
    }
  ]
});

export default withRouter(HTMLChangesDescription);
