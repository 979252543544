import React from 'react';
import Modal from 'react-modal';

import constants from 'constants/config';

export const DeleteConfirmationModal = ({ isOpen, onClose, deleteItem }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="cw-modal cw-modal--newuser"
      style={{
        overlay: {
          backgroundColor: constants.EMAIL_OVERLAY_BACKGROUND
        }
      }}
    >
      <button className="cw-modal--close" title="Close" onClick={onClose}>
        <i className="fas fa-times"></i>
      </button>

      <h3 className="cw-heading--primary mb-10">Confirm delete</h3>

      <p className="mb-20">Please confirm if you want to delete this record</p>

      <div className="d-flex justify-content-end">
        <button
          type="submit"
          onClick={onClose}
          className="btn btn-outline-primary mr-10"
        >
          Cancel
        </button>
        <button className="btn btn-primary" onClick={deleteItem}>
          Confirm
        </button>
      </div>
    </Modal>
  );
};
