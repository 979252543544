import {
  SET_SELECTED_TEMPLATE,
  UPDATE_TEMPLATE_OBJECT
} from '../../actionTypes';

const initialState = {};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_SELECTED_TEMPLATE:
      return action.payload;
    case UPDATE_TEMPLATE_OBJECT:
      return { ...state, templateObject: action.payload };
    default:
      return state;
  }
}
