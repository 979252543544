import React from 'react';

const ServiceError = props => {
	return (
		<div className='col-sm-12'>
			<div className='cw-section'>
				<h2 className='cw-heading--secondary mb-30'>500</h2>
				<p>Try refreshing this page or try again later.</p>
				<p>
					If the problem persists please contact{' '}
					<a href='mailto:helpdesk@unilever.com'>helpdesk@unilever.com</a>
				</p>
			</div>
		</div>
	);
};

export default ServiceError;
