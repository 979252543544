import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormBuilder } from '../../components/organisms/FormBuilder';
import SmsEditInfoSchema from './SmsEditInfoSchema';

const SmsEditInfo = ({ pathUid, closeModal }) => {
  const { authorizedUserData } = useSelector(state => state.authorized);
  const dispatch = useDispatch();
  const compProps = {
    pathUid,
    closeModal
  };
  return (
    <div>
      <FormBuilder
        schema={SmsEditInfoSchema(dispatch, compProps)}
        user={authorizedUserData}
      />
    </div>
  );
};

export default SmsEditInfo;
