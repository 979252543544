import axios from 'axios';
import constant from '../constants';
import { checkCampaignLanguage } from '../selectors';
import {
  ADD_QUESTION,
  DELETE_QUESTION,
  ADD_ANSWER,
  DELETE_ANSWER,
  ADD_QUESTION_TYPE,
  FETCH_QUESTIONS,
  FETCH_ANSWERS,
  FETCH_CAMPAIGN_DETAILS,
  QUESTIONS_FETCH_SUCCESS,
  ANSWERS_FETCH_SUCCESS,
  CAMPAIGN_DETAILS_FETCH_SUCCESS,
  SUBMITTING_QUESTIONSANSWERS,
  SUBMIT_QUESTIONSANSWERS_SUCCESS,
  RESET_QNA_SUBMISSION,
  EDIT_QUESTION,
  SAVE_QUESTIONSANSWERS_DATA
} from '../actionTypes';

export const addQuestion = question => ({ type: ADD_QUESTION, question });
export const removeQuestion = (question, questionIndex) => ({
  type: EDIT_QUESTION,
  question,
  questionIndex
});
export const addAnswer = answer => ({ type: ADD_ANSWER, answer });
export const deleteAnswer = index => ({ type: DELETE_ANSWER, index });
export const addQuestionType = questionType => ({
  type: ADD_QUESTION_TYPE,
  questionType
});
export const deleteQuestion = index => ({ type: DELETE_QUESTION, index });
export const resetDataSubmittionState = reset => ({
  type: RESET_QNA_SUBMISSION,
  reset
});
export function getQuestions(input) {
  return function(dispatch) {
    dispatch({
      type: FETCH_QUESTIONS,
      isLoading: true
    });
    axios.get(constant.serviceUrls.SERVICE_URL.QUESTIONS).then(response => {
      let questions = response.data;

      dispatch({ type: FETCH_QUESTIONS, isLoading: false });

      dispatch({
        type: QUESTIONS_FETCH_SUCCESS,
        questions,
        questionsLoaded: true
      });
    });
  };
}
export function getAnswers() {
  return function(dispatch) {
    dispatch({
      type: FETCH_ANSWERS,
      isLoading: true
    });

    // Fetch Search Filters
    axios.get(constant.serviceUrls.SERVICE_URL.ANSWERS).then(response => {
      let answers = response.data;

      dispatch({ type: FETCH_ANSWERS, isLoading: false });

      dispatch({
        type: ANSWERS_FETCH_SUCCESS,
        answers,
        answersLoaded: true
      });
    });
  };
}
export function getCampaignDetails(campaignId) {
  return function(dispatch) {
    dispatch({
      type: FETCH_CAMPAIGN_DETAILS,
      isLoading: true
    });
    axios
      .get(constant.serviceUrls.CAMPAIGN_MASTER_SERVICE_URL + '/' + campaignId)
      .then(response => {
        let campaign = response.data[0];
        let campaignLanguage =
          campaign && campaign.language && campaign.language.name;
        campaign['isLanguageEnglish'] = checkCampaignLanguage(campaignLanguage);
        dispatch({ type: FETCH_CAMPAIGN_DETAILS, isLoading: false });
        dispatch({ type: CAMPAIGN_DETAILS_FETCH_SUCCESS, campaign });
      });
  };
}

export function submitQuestionsAnswers(campaignId, payload, userName, userEmail, saveAsDraft,iConfirmQnA, isClone)
// iConfirmQnA, isClone  - to be used for confirm checkbox
{
	return function(dispatch) {
		dispatch({
			type: SUBMITTING_QUESTIONSANSWERS,
			isLoading: true,
		});

		axios
			.post(constant.serviceUrls.CAMPAIGN_DATA_SERVICE_URL, {
				uid: campaignId,
				lastUpdatedScreen: 'questionsanswers',
				questionAnswerList: payload,
				userName: userName,
				userEmail: userEmail,
				saveAsDraft: saveAsDraft ? true : false,
				iConfirmQnA: iConfirmQnA,
				isClone: isClone
			})
			.then((response) => {
				dispatch({ type: SUBMITTING_QUESTIONSANSWERS, isLoading: false });
				if (saveAsDraft) {
					dispatch({
						type: SAVE_QUESTIONSANSWERS_DATA,
						dataSubmission: true,
					});
				} else {
					dispatch({
						type: SUBMIT_QUESTIONSANSWERS_SUCCESS,
						dataSubmission: true,
					});
				}
			});
	};
}
