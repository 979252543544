import axios from 'axios';
import constant from '../constants';
import {
  ACTIVE_STEP,
  ASYNC_IN_PROGRESS,
  GET_BRAND_COUNTRIES,
  BRAND_COUNTRY_LOADED_SUCCESS,
  BRAND_COUNTRY_LOADED,
  GET_BRAND_DETAILS,
  PROFILE_STORE_FOUND,
  RESET_PROFILE_STORE,
  UPDATE_BRAND_DETAILS,
  GET_CAMPAIGN_DETAILS_BRANDDETAILS,
  BRAND_DETAIL_SUCCESS,
  GET_COUNTRY_BRAND_LIST,
  SET_PROFILE_STORE_TYPE,
  SET_PROFILE_STORE_ERROR,
  PROFILE_STORE_IN_PROGRESS,
  RESET_PROFILE_STORE_IN_PROGRESS,
  AUTOPOPULATE_WEBSITETYPE,
  RESET_BRAND_DETAILS
} from '../actionTypes';
import _ from 'lodash';

export const setActiveStep = activeStep => ({
  type: ACTIVE_STEP,
  activeStep
});
export const resetProfileStore = reset => ({
  type: RESET_PROFILE_STORE,
  reset
});
export const updateBrandDetails = brandDetails => ({
  type: UPDATE_BRAND_DETAILS,
  brandDetails
});

export function getBrandCountries(campaignUid) {
  return function(dispatch) {
    dispatch({
      type: ASYNC_IN_PROGRESS,
      isLoading: true
    });

    axios
      .get(constant.serviceUrls.SERVICE_URL.COUNTRIES)
      .then(response => {
        let countryDetails = response.data;
        let countryDetailsSorted = _.orderBy(
          countryDetails.countries,
          ['name'],
          ['asc']
        );

        dispatch({
          type: GET_BRAND_COUNTRIES,
          countryDetailsSorted
        });
        dispatch({
          type: BRAND_COUNTRY_LOADED_SUCCESS,
          isLoading: false,
          payload: true
        });
      })
      .then(response => {
        if (campaignUid) {
          dispatch({
            type: ASYNC_IN_PROGRESS,
            isLoading: true
          });
          axios
            .get(
              constant.serviceUrls.CAMPAIGN_MASTER_SERVICE_URL +
                '/' +
                campaignUid
            )
            .then(response => {
              let campaign = response.data[0];
              let countryId = campaign.country._id || '';

              dispatch({
                type: BRAND_COUNTRY_LOADED,
                countryDataLoaded: true
              });
              dispatch({ type: ASYNC_IN_PROGRESS, isLoading: false });
              dispatch({
                type: GET_CAMPAIGN_DETAILS_BRANDDETAILS,
                campaign,
                countryId
              });
            });
        } else {
          dispatch({
            type: BRAND_COUNTRY_LOADED,
            countryDataLoaded: false
          });
          dispatch({ type: ASYNC_IN_PROGRESS, isLoading: false });
        }
      });
  };
}

export function getBrandByCountry(countryId, campaignUid = '') {
  return function(dispatch) {
    dispatch({
      type: ASYNC_IN_PROGRESS,
      isLoading: true
    });

    let country = countryId.split('-')[1];
    axios
      .get(constant.serviceUrls.SERVICE_URL.COUNTRIES + '?country=' + country)
      .then(response => {
        let countryBrand = response.data;
        let brands = countryBrand.map(data => data.brand);
        brands = _.orderBy(brands, ['name'], ['asc']);
        let languageExists =
          countryBrand[0].language !== undefined ? true : false;
        let countrylanguage;
        if (languageExists) {
          countrylanguage = {
            label: countryBrand[0]['language']['name'],
            value:
              countryBrand[0]['language']['code'] +
              '-' +
              countryBrand[0]['language']['_id']
          };
        }
        dispatch({
          type: GET_COUNTRY_BRAND_LIST,
          brands,
          countrylanguage
        });
        dispatch({
          type: BRAND_COUNTRY_LOADED,
          countryDataLoaded: false
        });
        dispatch({ type: ASYNC_IN_PROGRESS, isLoading: false });
      })
      .then(response => {
        if (campaignUid) {
          dispatch({
            type: ASYNC_IN_PROGRESS,
            isLoading: true
          });
          axios
            .get(
              constant.serviceUrls.CAMPAIGN_MASTER_SERVICE_URL +
                '/' +
                campaignUid
            )
            .then(response => {
              let campaign = response.data[0];
              dispatch({ type: ASYNC_IN_PROGRESS, isLoading: false });
              dispatch({ type: GET_CAMPAIGN_DETAILS_BRANDDETAILS, campaign });
            });
        } else {
          dispatch({ type: ASYNC_IN_PROGRESS, isLoading: false });
        }
      });
  };
}

export function getBrandDetails(campaignUid) {
  return function(dispatch) {
    dispatch({
      type: ASYNC_IN_PROGRESS,
      isLoading: true
    });
    // Fetch Search Filters
    axios.get(constant.serviceUrls.SERVICE_URL.BRAND_DETAILS).then(response => {
      let brandDetails = response.data[0];
      dispatch({ type: ASYNC_IN_PROGRESS, isLoading: false });

      dispatch({
        type: GET_BRAND_DETAILS,
        brandDetails
      });
      dispatch({
        type: BRAND_COUNTRY_LOADED,
        countryDataLoaded: false
      });

      dispatch({
        type: BRAND_DETAIL_SUCCESS,
        isLoading: false,
        payload: true
      });
    });
  };
}

export function getProfileStore(
  brand,
  country,
  language,
  websiteType,
  websiteExtension,
  uid
) {
  return function(dispatch) {
    if (brand !== '' && country !== '') {
      dispatch({
        type: PROFILE_STORE_IN_PROGRESS,
        isLoading: true
      });

      axios
        .get(constant.serviceUrls.SERVICE_URL.PROFILE_STORE, {
          params: {
            brand:
              brand && brand.value.indexOf('-') > -1
                ? brand.value.split('-')[0]
                : brand.value,
            country:
              country && country.value.indexOf('-') > -1
                ? country.value.split('-')[0]
                : country.value
          }
        })
        .then(response => {
          let profileStore = response.data;

          let profileStoreExist =
            profileStore.length > 0 &&
            profileStore[0].profileStoreType &&
            profileStore[0].profileStoreType !== ''
              ? {
                  profileStoreType: profileStore[0].profileStoreType,
                  profileStoreExist: true
                }
              : { profileStoreType: null, profileStoreExist: false };

          if (
            profileStore.length &&
            profileStore[0].profileStoreType &&
            profileStore[0].profileStoreType !== '' &&
            uid
          ) {
            let dateToSend = {
              uid,
              brand: brand.value.split('-')[1],
              country: country.value.split('-')[1],
              language: language.value.split('-')[1],
              websiteType: websiteType.value.split('-')[1]
            };
            if (profileStore[0].hasOwnProperty('isWhitelistedForGigya')) {
              dateToSend['isWhitelistedForGigya'] =
                profileStore[0]['isWhitelistedForGigya'];
            }
            dispatch({
              type: PROFILE_STORE_IN_PROGRESS,
              isLoading: true
            });
            dispatch({
              type: SET_PROFILE_STORE_TYPE,
              profileStore
            });
            axios
              .post(
                constant.serviceUrls.CAMPAIGN_MASTER_SERVICE_URL,
                dateToSend
              )
              .then(response => {
                dispatch({ type: PROFILE_STORE_IN_PROGRESS, isLoading: false });
                dispatch({
                  type: PROFILE_STORE_FOUND,
                  profileStoreExist
                });
              });
          } else {
            dispatch({ type: PROFILE_STORE_IN_PROGRESS, isLoading: false });
            dispatch({
              type: PROFILE_STORE_FOUND,
              profileStoreExist
            });
          }
        })
        .catch(err => {
          let error;
          if (err.response.data.Message) {
            error = err.response.data.Message || '';
          } else {
            error =
              err.response.data.body[0].number +
                ' ' +
                (err.response.data.body[0].code
                  ? err.response.data.body[0].code.replace(/_/g, ' ')
                  : err.response.data.message) ||
              'There was a problem with the page.';
          }
          dispatch({ type: PROFILE_STORE_IN_PROGRESS, isLoading: false });
          dispatch({
            type: SET_PROFILE_STORE_ERROR,
            error
          });
        });
    }
  };
}

export function setProfileStoreType(profileStoreType, uid,userEmail) {
  return function(dispatch) {
    if (profileStoreType !== '' && uid !== '') {
      let data = {};
      data['uid'] = uid;
      data['lastUpdatedScreen'] = 'branddetails';
      data['profileStore'] = {};
      data['profileStore']['profileStoretype'] = profileStoreType;
      data['profileStore']['url'] = '';
      data['profileStore']['secretkey'] = '';
      data['profileStore']['accesskey'] = '';
      data['createdBy'] = userEmail;
      data['updatedBy'] = userEmail;

      axios({
        method: 'POST',
        url: constant.serviceUrls.CAMPAIGN_DATA_SERVICE_URL,
        data: data
      }).then(response => {
        dispatch({ type: ASYNC_IN_PROGRESS, isLoading: false });
      });
    }
  };
}

export const resetProfileStoreInProgress = () => ({
  type: RESET_PROFILE_STORE_IN_PROGRESS
});

export function autoPopulateWebsiteType(country, brand, language) {
  return function(dispatch) {
    return axios
      .get(
        constant.serviceUrls.SERVICE_URL.GET_AUTOPOPULATED_WEBSITETYPE +
          '?brand=' +
          brand +
          '&market=' +
          country +
          '&language=' +
          language
      )
      .then(response => {
        let responseData = response.data;
        let value = response.data[0] && response.data[0]._id;
        let label = response.data[0] && response.data[0].name;

        let autopopulateWebsiteType = {
          value: 'undefined-' + value,
          label: label
        };
        if (responseData.length > 1 || responseData.length === undefined) {
          dispatch({
            type: AUTOPOPULATE_WEBSITETYPE,
            autopopulateWebsiteType: null,
            websiteCount: response.data.length
          });
        } else if (responseData.length === 1) {
          dispatch({
            type: AUTOPOPULATE_WEBSITETYPE,
            autopopulateWebsiteType: autopopulateWebsiteType,
            websiteCount: response.data.length
          });
        }
      });
  };
}

export function resetFieldsOnLanguageChange(uid) {
  return function(dispatch) {
    let data = {
      uid: uid,
      fields: [],
      consents: [],
      messages: {},
      consentsVersion: ''
    };

    axios({
      method: 'POST',
      url: constant.serviceUrls.CAMPAIGN_DATA_SERVICE_URL,
      data: data
    }).then(response => {
      dispatch({ type: ASYNC_IN_PROGRESS, isLoading: false });
    });
  };
}

export function resetFieldsOnCountryChange(uid) {
  return function(dispatch) {
    let data = {
      uid: uid,
      fields: [],
      consents: [],
      messages: {},
      consentsVersion: '',
      epsilon: {}
    };

    axios({
      method: 'POST',
      url: constant.serviceUrls.CAMPAIGN_DATA_SERVICE_URL,
      data: data
    }).then(response => {
      dispatch({ type: ASYNC_IN_PROGRESS, isLoading: false });
    });
  };
}

export function resetQnAConfirm(uid) {
  return function(dispatch) {
    let data = {
      uid: uid,
      iConfirmQnA: false
    };
    axios({
      method: 'POST',
      url: constant.serviceUrls.CAMPAIGN_DATA_SERVICE_URL,
      data: data
    }).then(response => {
      dispatch({ type: ASYNC_IN_PROGRESS, isLoading: false });
    });
  };
}

export const resetFormBrandDetails = () => ({
  type: RESET_BRAND_DETAILS
})
