import React from 'react';
import ExternalLinkIcon from 'images/external-link.png';

const ExternalLinkTag = ({ href, iconSrc, children, className }) => {
  return (
    <a href={href} target="_blank" rel="noopener noreferrer" className={className}>
      {children}{' '}
      <img
        src={iconSrc ? iconSrc : ExternalLinkIcon}
        alt="External Link"
        className="external-link-icon"
      />
    </a>
  );
};

export default ExternalLinkTag;
