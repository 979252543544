import React from 'react';

export default function GuidelineWrapper({
  className = '',
  title = 'Guidelines',
  titleClassName = '',
  onClose,
  children,
  hideCloseButton,
  closeIconClass = '',
  isEmailCampaign
}) {
  return (
    <div className={`guideline-wrapper ${className}`}>
      {!isEmailCampaign ? (
        <>
          <div className="guideline-wrapper--header">
            <p className={`title ${titleClassName}`}>{title}</p>
            <i className="guideline-wrapper-close-icon" onClick={onClose}></i>
          </div>
          <div className="guideline-wrapper--body">{children}</div>
        </>
      ) : (
        <>
          {onClose ? (
            <span
              className={`qna-guideline-close qna-guideline-close-modal ${closeIconClass}`}
              onClick={onClose}
            ></span>
          ) : null}
          {children}
        </>
      )}
      {!hideCloseButton ? (
        <div className="mt-3">
          <button
            type="submit"
            onClick={onClose}
            className="btn btn-primary mb-10 cw-print-hide text-nowrap  guideline-close-button"
          >
            Close
          </button>
        </div>
      ) : null}
    </div>
  );
}
