import React from 'react';
const QnASubheadingText = props => {
  return (
    <>
      Q&amp;As enable you to gain insight on your consumer preferences and
      needs. Please read the guidelines before selecting or requesting
      questions.
      <br />
    </>
  );
};

export default QnASubheadingText;
