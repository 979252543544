import React from 'react';
import WinningVariantIndicator from 'components/atoms/WinningVariantIndicator';
import { get } from 'lodash';
import constants from 'constants/config';
const WinningVariantInfo = ({ className, emailBriefing }) => {
  const performABTests = get(emailBriefing, 'abTesting.performABTests');
  const abTestElement = get(emailBriefing, 'abTesting.abTestElements');
  const winningVariant = get(emailBriefing, 'winningVariant');
  return performABTests && abTestElement === 'emailContent' ? (
    <div className={className}>
      {winningVariant ? (
        <>
          <WinningVariantIndicator variant={winningVariant} />
          <p className="winning-variant-note">
            Winning {constants.EMAIL_ABTEST_VARIANT_MAP[winningVariant]} is
            displayed by default.
          </p>
        </>
      ) : (
        <p className="winning-variant-note">
          AB test variant A is displayed by default.
        </p>
      )}
      <p className="winning-variant-note">
        Click preview button to view all AB test variants.
      </p>
    </div>
  ) : null;
};

export default WinningVariantInfo;
