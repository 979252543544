import React, { useEffect, useState } from 'react';
import { Input } from '../../atoms/Input';
import { FieldError } from '../../atoms/FieldError';
import { get } from 'lodash';
import { useWatch, Controller } from 'react-hook-form';
import { FormReactSelect } from '../FormReactSelect';
import moment from 'moment';
import classNames from 'classnames';
import constants from 'constants/config';
import ABTestEdgeCaseValidation from './abTestEdgeCaseValidations';
import ReactTooltip from 'react-tooltip';

const ABSpecificFields = ({
  control,
  watchInput,
  errors,
  name,
  dateFormat,
  register,
  setValue,
  labelClass,
  defaultValue,
  trigger
}) => {
  const defaultPerformABTests = get(defaultValue, `${name}.performABTests`);
  const defaultAbTestElements = get(defaultValue, `${name}.abTestElements`);
  const defaultAbTestSplits = get(defaultValue, `${name}.abTestSplits`);
  const [performAbTests, abTestElements, abTestSplits] = useWatch({
    control,
    name: watchInput,
    defaultValue: [
      defaultPerformABTests,
      defaultAbTestElements,
      defaultAbTestSplits
    ]
  });

  useEffect(() => {
    if (
      abTestElements === 'sendTime' &&
      defaultValue.emailType === 'welcomeEmail'
    ) {
      setValue(`${name}.abTestElements`, '');
      setValue(`${name}.abTestSplits`, '');
    }
  }, [defaultValue?.emailType, abTestElements, abTestSplits]);

  useEffect(() => {
    if (
      abTestElements === 'sendTime' &&
      abTestSplits &&
      abTestSplits !== '2-variants-50-50'
    ) {
      setValue(`${name}.abTestSplits`, '2-variants-50-50');
    }
  }, [abTestElements]);

  const errorMessages = get(errors, name);
  const hasError = !!(errors && errorMessages);
  const currentDate = moment();

  return performAbTests ? (
    <div className="ml-33 pt-0 cw-form-nested mb-20">
      <div className="form-group">
        <label htmlFor="abTestElement">
          A/B test elements{' '}
          <span className="email-label-subtext d-block">
            Only one variable should be tested at a time to attribute
            performance to the selected variable
          </span>
        </label>

        <div>
          <div className="form-check mb-10" aria-live="polite">
            <Input
              name={`${name}.abTestElements`}
              type="radio"
              {...(register && register(`${name}.abTestElements`))}
              id="subjectLine"
              value="subjectLine"
            />
            <label htmlFor="subjectLine" className="form-check-label">
              <p>Subject line</p>
              {abTestElements === 'subjectLine' && (
                <span className="email-label-subtext d-block">
                  You will be prompted to enter subject line variations in the
                  later steps of this setup brief
                </span>
              )}
            </label>
          </div>
        </div>

        <div>
          <div className="form-check mb-10" aria-live="polite">
            <Input
              name={`${name}.abTestElements`}
              {...(register && register(`${name}.abTestElements`))}
              id="emailContent"
              value="emailContent"
              type="radio"
            />
            <label htmlFor="emailContent" className="form-check-label">
              <p>Email content</p>
              {abTestElements === 'emailContent' && (
                <span className="email-label-subtext d-block">
                  You will need to setup content variations while designing your
                  email
                </span>
              )}
            </label>
          </div>
        </div>

        <div>
          <div
            className="form-check check-with-tooltip mb-10"
            aria-live="polite"
          >
            <Input
              name={`${name}.abTestElements`}
              {...(register && register(`${name}.abTestElements`))}
              id="sendTime"
              value="sendTime"
              type="radio"
              disabled={defaultValue.emailType === 'welcomeEmail'}
            />

            <label
              htmlFor="sendTime"
              className={classNames('form-check-label d-flex', {
                'radio-option-disabled':
                  defaultValue.emailType === 'welcomeEmail'
              })}
            >
              <div className="d-flex align-items-center">
                <p className="label-text">Send time</p>
                <div className="tooltip-container ml-1">
                  <span
                    className="icon"
                    data-tip="A/B tests on send time are available for fixed date and time emails and are not applicable to welcome emails that are sent upon sign up"
                    data-for="sendTime"
                  >
                    &nbsp;<i className="fas fa-question-circle"></i>
                  </span>
                  <ReactTooltip
                    id="sendTime"
                    place="right"
                    type="info"
                    multiline={true}
                    className="cw-tooltip cw-email-tooltip"
                  />
                </div>
              </div>
              {abTestElements === 'sendTime' && (
                <span className="email-label-subtext d-block">
                  You will be prompted to specify send time variations in email schedule
                </span>
              )}
            </label>
          </div>
        </div>

        <FieldError errors={errors} name={`${name}.abTestElements`} />
      </div>

      <div className="form-group">
        <label htmlFor="abTestSplits">A/B test split</label>
        <div>
          <div className="form-check mb-10" aria-live="polite">
            <Input
              name={`${name}.abTestSplits`}
              type="radio"
              {...(register && register(`${name}.abTestSplits`))}
              id="2-variants-50-50"
              value="2-variants-50-50"
            />
            <label htmlFor="2-variants-50-50" className="form-check-label">
              2 variants | 50:50 split
              <span className="email-label-subtext d-block">
                Your audience will be randomly split into 2 halves and will
                receive one of the 2 email variants. You will be able to apply
                your learnings to the next campaign
              </span>
            </label>
          </div>
        </div>

        <div>
          <div
            className="form-check check-with-tooltip mb-10"
            aria-live="polite"
          >
            <Input
              name={`${name}.abTestSplits`}
              {...(register && register(`${name}.abTestSplits`))}
              id="2-variants-10-10-80"
              value="2-variants-10-10-80"
              type="radio"
              disabled={abTestElements === 'sendTime'}
            />
            <label
              htmlFor="2-variants-10-10-80"
              className={classNames('form-check-label d-flex', {
                'radio-option-disabled': abTestElements === 'sendTime'
              })}
            >
              <div className="d-flex align-items-center">
                <p>2 variants | 10:10:80 split</p>
                {abTestElements === 'sendTime' && (
                  <div className="tooltip-container ml-1">
                    <span
                      className="icon"
                      data-tip="This option is unavailable for A/B tests on send time"
                      data-for="2-variants-10-10-80"
                    >
                      &nbsp;<i className="fas fa-question-circle"></i>
                    </span>
                    <ReactTooltip
                      id="2-variants-10-10-80"
                      place="right"
                      type="info"
                      multiline={true}
                      className="cw-tooltip cw-email-tooltip w-auto"
                    />
                  </div>
                )}
              </div>
              <span className="email-label-subtext d-block">
                Email variants will be sent to 2 randomly selected sample splits
                that are 10% each of your audience size. The test will run for a
                given test duration and the winning variant will be sent to the
                remaining 80% audience
              </span>
            </label>
          </div>
        </div>

        <div>
          <div
            className="form-check check-with-tooltip mb-10"
            aria-live="polite"
          >
            <Input
              name={`${name}.abTestSplits`}
              {...(register && register(`${name}.abTestSplits`))}
              id="3-variants-5-5-5-85"
              value="3-variants-5-5-5-85"
              type="radio"
              disabled={abTestElements === 'sendTime'}
            />
            <label
              htmlFor="3-variants-5-5-5-85"
              className={classNames('form-check-label d-flex', {
                'radio-option-disabled': abTestElements === 'sendTime'
              })}
            >
              <div className="d-flex align-items-center">
                <p>3 variants | 5:5:5:85 split</p>
                {abTestElements === 'sendTime' && (
                  <div className="tooltip-container ml-1">
                    <span
                      className="icon"
                      data-tip="This option is unavailable for A/B tests on send time"
                      data-for="3-variants-5-5-5-85"
                    >
                      &nbsp;<i className="fas fa-question-circle"></i>
                    </span>
                    <ReactTooltip
                      id="3-variants-5-5-5-85"
                      place="right"
                      type="info"
                      multiline={true}
                      className="cw-tooltip cw-email-tooltip w-auto"
                    />
                  </div>
                )}
              </div>
              <span className="email-label-subtext d-block">
                Email variants will be sent to 3 randomly selected sample splits
                that are 5% each of your audience size. The test will run for a
                given test duration and the winning variant will be sent to the
                remaining 85% audience
              </span>
            </label>
          </div>
        </div>
        <FieldError errors={errors} name={`${name}.abTestSplits`} />
      </div>

      {constants.EMAIL_AB_TEST_DURATION_CHECK_LIST.includes(abTestSplits) && (
        <FormReactSelect
          id={'testDuration'}
          name={`${name}.testDuration`}
          label={'Test duration'}
          placeholder={'Select test duration'}
          control={control}
          errors={errors}
          defaultValue={get(defaultValue, `${name}.testDuration`, {
            label: '2 hours',
            value: '2-hrs'
          })}
          options={constants.EMAIL_ABTEST_DURATION_OPTIONS}
          setValue={setValue}
        />
      )}

      <div className="form-group">
        <label htmlFor="winCriteria">
          Win criteria{' '}
          <span className="email-label-subtext d-block">
            Which metric will determine the winning recipe?
          </span>
        </label>
        <div>
          <div className="form-check mb-10" aria-live="polite">
            <Input
              name={`${name}.winCriteria`}
              type="radio"
              {...(register && register(`${name}.winCriteria`))}
              id="openRate"
              value="openRate"
            />
            <label htmlFor="openRate" className="form-check-label">
              <p>
                Open rate
                <span
                  className="ml-1 icon"
                  data-tip="Email open rate is a metric that measures the percentage of recipients who opened an email. It is calculated by dividing the number of unique opens by the number of emails delivered, expressed as a percentage."
                  data-for="open-rate"
                >
                  &nbsp;<i className="fas fa-question-circle"></i>
                </span>
              </p>
              <ReactTooltip
                id="open-rate"
                place="right"
                type="info"
                multiline={true}
                className="cw-tooltip cw-email-tooltip"
              />
            </label>
          </div>
        </div>
        <div>
          <div className="form-check mb-10" aria-live="polite">
            <Input
              name={`${name}.winCriteria`}
              type="radio"
              {...(register && register(`${name}.winCriteria`))}
              id="clickThroughRate"
              value="clickThroughRate"
            />
            <label htmlFor="clickThroughRate" className="form-check-label">
              <p>
                Click through rate
                <span
                  className="ml-1 icon"
                  data-tip="Email click-through rate (CTR) is a metric that measures the percentage of recipients who clicked on at least one link within an email. It is calculated by dividing the number of unique recipients who clicked on atleast 1 link by the number of emails delivered, expressed as a percentage."
                  data-for="clickthrough-rate"
                >
                  &nbsp;<i className="fas fa-question-circle"></i>
                </span>
              </p>
              <ReactTooltip
                id="clickthrough-rate"
                place="right"
                type="info"
                multiline={true}
                className="cw-tooltip cw-email-tooltip"
              />
            </label>
          </div>
        </div>
        <FieldError errors={errors} name={`${name}.winCriteria`} />
      </div>
    </div>
  ) : null;
};

export const EmailABTestingInput = ({
  name,
  rules,
  register,
  label,
  errors,
  setValue,
  control,
  defaultValue,
  emailType,
  ...props
}) => {
  const [isABTestEdgeCase, setIsABTestEdgeCase] = useState();
  const errorMessages = get(errors, name);
  const hasError = !!(errors && errorMessages);
  const isAbTestConfigured = get(defaultValue, `isAbTestConfigured`, undefined);
  useEffect(() => {
    if (defaultValue) {
      setValue(
        `${name}.performABTests`,
        get(defaultValue, `${name}.performABTests`)
      );
      setValue(
        `${name}.abTestElements`,
        get(defaultValue, `${name}.abTestElements`)
      );
      setValue(
        `${name}.abTestSplits`,
        get(defaultValue, `${name}.abTestSplits`)
      );
      setValue(`${name}.winCriteria`, get(defaultValue, `${name}.winCriteria`));
      setValue(
        `${name}.testDuration`,
        get(defaultValue, `${name}.testDuration`)
      );
    }
  }, [defaultValue]);

  return (
    <div className="form-group mt-5" aria-live="polite">
      <label htmlFor={props.id}>
        <strong>{label}</strong>
      </label>
      <div>
        <p>
          AB tests are experiments that let you send 2 or 3 variations of the
          same email to different audience segments and measure the impact of
          variables on you campaign performance
        </p>
        <div className="form-group d-flex mt-20">
          <label className="switch-saas">
            <Controller
              control={control}
              name={`${name}.performABTests`}
              defaultValue={get(defaultValue, `${name}.performABTests`)}
              render={({ field: { onChange, onBlur, value, ref, name } }) => (
                <Input
                  ref={ref}
                  name={name}
                  checked={value}
                  type="checkbox"
                  id="performABTests"
                  onChange={e => {
                    if (isAbTestConfigured && e.target.checked === false) {
                      e.preventDefault();
                      setIsABTestEdgeCase(true);
                    } else {
                      onChange(e);
                    }
                  }}
                />
              )}
            />
            <div className="slider-saas round"></div>
          </label>
          <label className="ml-20" htmlFor={`performABTests`}>
            Do you want to perform A/B tests on your email
          </label>
        </div>
        <ABSpecificFields
          control={control}
          errors={errors}
          defaultValue={defaultValue}
          rules={rules}
          trigger={props.trigger}
          setValue={setValue}
          name={name}
          register={register}
          watchInput={[
            `${name}.performABTests`,
            `${name}.abTestElements`,
            `${name}.abTestSplits`
          ]}
        />
      </div>

      <FieldError errors={errors} name={`${name}.type`} />
      <ABTestEdgeCaseValidation
        isOpen={isABTestEdgeCase}
        handleCloseModal={() => {
          setIsABTestEdgeCase(false);
        }}
        onHandleRemoveABTest={() => {
          setValue(`${name}.performABTests`, false);
          setIsABTestEdgeCase(false);
        }}
      />
    </div>
  );
};
