import React, { useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { getEmailCoreTemplates, getLiveEmailTemplates } from 'actions/emailbriefing/emailCoreTemplates';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedTemplate } from '../../actions/emailbriefing/selectedTemplate';
import TemplatePreviewOverlay from './templatePreviewOverlay';
import _ from 'lodash';
import { getEmailBriefing } from 'actions/emailbriefing/emailDesign';
import ConfirmChangeTemplateModal from 'components/molecules/EmailTemplateThumbnail/ConfirmChangeTemplateModal';
import AppliedFilters from 'components/organisms/Filters/AppliedFilters/AppliedFilters';
import Loader from 'components/Loader';
import { TemplatesPanel } from './TemplatesPanel'
import logo from 'images/CW_logo.svg';
import constants from 'constants/config';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const EmailCampaignTemplate = ({ match, history, userEmail, userName }) => {
  const uid = match.params.id;
  const [visible, setIsVisible] = useState(true);

  const { emailCoreTemplates: templates, emailBriefing } = useSelector(state => ({
    emailCoreTemplates: state.emailCoreTemplates,
    emailBriefing: state.emailDesign.emailBriefing
  }));

  const [isTemplatePreviewOpen, setIsTemplatePreviewOpen] = useState(false);
  const [selectedTemplateForPreview, setSelectedTemplateForPreview] = useState(
    {}
  );
  // defined isReset as communication between filter and list component to reset filters
  const [isReset, setIsReset] = useState(false);
  const [enteredText, setEnteredText] = useState('');
  const [userinfo, setUserInfo] = useState({
    emailType: [],
    countries: [],
    brands: [],
    templateContext: [],
    templateAttributes: [],
    isFirstLoad: true
  });
  const [activeTab, setAvtiveTab] = React.useState(0);

  const [filterPills, setFilterPills] = useState({
    emailType: [],
    countries: [],
    brands: [],
    templateContext: [],
    templateAttributes: [],
    rawCategories: [],
  });

  // useEffect(() => {
  //   if(activeTab === 0) {
  //     dispatch(getEmailCoreTemplates());
  //   } else {
  //     dispatch(getLiveEmailTemplates())
  //   }
  // }, [activeTab])
  

  /**
   * Filter checkbox change handler
   * @param {*} e
   */
  const handleFilterChange = (e, data) => {
    const { value, checked, name } = e.target;
    const { emailType, countries, brands, templateContext, templateAttributes } = userinfo;
    if (checked) {
      setUserInfo({
        emailType: name === 'emailType' ? [...emailType, value] : emailType,
        countries: name === 'countries' ? [...countries, value] : countries,
        brands: name === 'brands' ? [...brands, value] : brands,
        templateContext:
          name === 'templateContext'
            ? [...templateContext, value]
            : templateContext,
        templateAttributes:
          name === 'templateAttributes'
            ? [...templateAttributes, value]
            : templateAttributes
      });
      setFilterPills({
        emailType: name === 'emailType' ? [...filterPills.emailType, {value: value, label: data.label}] : filterPills.emailType,
        brands: name === 'brands' ? [...filterPills.brands, {value: value, label: data.name}] : filterPills.brands,
        countries: name === 'countries' ? [...filterPills.countries, {value: value, label: data.name}] : filterPills.countries,
        templateContext: name === 'templateContext'
          ? [...filterPills.templateContext, {value: value, label: data.label}]
          : filterPills.templateContext,
      templateAttributes: name === 'templateAttributes'
          ? [...filterPills.templateAttributes, {value: value, label: data.label}]
          : filterPills.templateAttributes,
      rawCategories:
        name === 'rawCategories' ? [...filterPills.rawCategories, {value: value, label: data.label}] : filterPills.rawCategories,
      })
    } else {
      setUserInfo({
        emailType: emailType.filter(e => e !== value),
        countries: countries.filter(e => e !== value),
        brands: brands.filter(e => e !== value),
        templateContext: templateContext.filter(e => e !== value),
        templateAttributes: templateAttributes.filter(e => e !== value)
      });
      setFilterPills({
        emailType: filterPills.emailType.filter(e => e.value !== value),
        countries: filterPills.countries.filter(e => e.value !== value),
        brands: filterPills.brands.filter(e => e.value !== value),
        templateContext: filterPills.templateContext.filter(e => e.value !== value),
        templateAttributes: filterPills.templateAttributes.filter(e => e.value !== value),
        rawCategories: filterPills.rawCategories.filter(e => e.value !== value),
      });
    }
  };

  const emailType = emailBriefing && emailBriefing.emailType;

  /**
   * Setting default email type conditionally
   */
  useEffect(() => {
    let userInfo;
    if (emailType && emailType !== 'otherMarketingEmails') {
      userInfo = {
        emailType: [emailType],
        countries: [],
        brands: [],
        templateContext: [],
        templateAttributes: [],
        isFirstLoad: false
      };
      setUserInfo(userInfo);
      setFilterPills({
        emailType: [{value: emailType, label: constants.emailType[emailType]}],
        countries: [],
        brands: [],
        templateContext: [],
        templateAttributes: [],
        rawCategories: [],
      });
    } else if (emailBriefing) {
      userInfo = {
        emailType: [],
        countries: [],
        brands: [],
        templateContext: [],
        templateAttributes: [],
        isFirstLoad: false
      };
      setUserInfo(userInfo);
    }
  }, [emailBriefing]);

  const dispatch = useDispatch();

  const onPageChange = pageNumber => {
    if(activeTab === 0) {
      dispatch(
        getEmailCoreTemplates({ filter: userinfo, text: enteredText }, pageNumber)
      );
    } else {
      dispatch(
        getLiveEmailTemplates({ filter: userinfo, text: enteredText }, pageNumber,match.params.id)
      );
    }
  };

  useEffect(() => {
    //dispatch(getEmailCoreTemplates());
    dispatch(getEmailBriefing(match.params.id));
  }, []);

  const onReset = () => {
    dispatch(getEmailCoreTemplates());
    setIsReset(true);
  };

  const handleTemplatePreview = template => {
    setIsTemplatePreviewOpen(true);
    setSelectedTemplateForPreview(template);
  };

  const handleCloseTemplatePreview = () => {
    setIsTemplatePreviewOpen(false);
    setSelectedTemplateForPreview({});
  };

  const handleConfirmTemplateSelect = () => {
    dispatch(
      setSelectedTemplate({
        coreTemplateId: selectedTemplateForPreview?._id,
        uid,
        userEmail,
        userName,
        isEmailTemplate: activeTab === 1
      })
    ).then(() => {
      closeModal();
      history.push(`/campaign-wizard/emailbriefing/${uid}/email-editor`);
    });
  };

  const { limit, page, total } =
    (templates &&
      templates.data &&
      templates.data.data &&
      templates.data.data.pagination) ||
    {};

  const recordShowStart = (page - 1) * limit + 1;
  const recordShowto = _.min([recordShowStart + limit - 1, total]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const givenTemplate = useSelector(state => state.selectedTemplate);
  const doesGivenTemplateExists = !_.isEmpty(givenTemplate);

  const handlePreviewSelect = template => {
    if (!doesGivenTemplateExists) {
      handleConfirmTemplateSelect(template);
    } else {
      setIsTemplatePreviewOpen(false);
      setIsModalOpen(true);
    }
  };

  const handleResume = () => {
    closeModal();
    history.push(`/campaign-wizard/emailbriefing/${uid}/email-editor`);
  };

  const tabStyle = { fontFamily: 'Open Sans', textTransform: 'none' };
  const resetFilterPills = () => {
    setFilterPills({
      emailType: [],
      countries: [],
      brands: [],
      templateContext: [],
      templateAttributes: [],
      rawCategories: [],
    })
  }
  const removeFilterPill = (value, filterKey) => {
    setFilterPills({
      ...filterPills,
      [filterKey]: filterPills[filterKey].filter(e => e.value !== value)
    })
    setUserInfo({
      ...userinfo,
      [filterKey]: userinfo[filterKey].filter(e => e !== value)
    })
  };

  const getFiltersCount = (givenInfo) => {
    const filters = ['countries', 'brands', 'emailType', 'templateContext', 'templateAttributes'];

    let filterCount = filters.reduce(
      (acc, filterKey) => acc + _.get(givenInfo,`${filterKey}.length`, 0),
      0
    );

    // if (userinfo.lastUpdated !== '') {
    //   filterCount += 1;
    // }
    return filterCount;
  }

  const handleTabChange = (e, newValue) => {
    setAvtiveTab(newValue);
    let emailTypeUser = [], emailTypePill = [];
    if (emailType && emailType !== 'otherMarketingEmails') { 
      emailTypeUser = [emailType];
      emailTypePill = [{value: emailType, label: constants.emailType[emailType]}]
    }

    setUserInfo({
      emailType: emailTypeUser,
      countries: [],
      brands: [],
      templateContext: [],
      templateAttributes: [],
      isFirstLoad: false,
      isEmailCollection: true
    });
    setEnteredText('');
    setFilterPills({
      emailType: emailTypePill,
      countries: [],
      brands: [],
      templateContext: [],
      templateAttributes: [],
      rawCategories: [],
    });
    //dispatch(getEmailBriefing(match.params.id));
  };

  return (
    <div className="col-sm-12 skypink">
      <div className="toolbar">
        <div className="toolbar__left">
          <a
            href="/campaign-wizard"
            className={'logo-link'}
            title="Campaign Wizard"
          >
            <img src={logo} alt="Campaign Wizard logo" className={'logo'} />
          </a>

          <button
            className="btn btn btn-outline-secondary mr-3"
            type="button"
            onClick={() => {
              const uid = match.params.id;
              history.push(
                `/campaign-wizard/emailbriefing/${uid}/design-your-email`
              );
            }}
          >
            Back
          </button>
        </div>
        <div className="toolbar__center">
          <div className="col"></div>
        </div>
        <div className="toolbar__right">
          <div className="d-flex"></div>
        </div>
      </div>
      <div className="cw-section email-template-page-padding">
        <div className="cw-section--title mb-15">
          <h2 className="cw-heading--secondary cw-email-heading">
            Select email template
          </h2>
        </div>
        {/* {userinfo?.isFirstLoad ? (
          <Loader
            style={{
              position: 'fixed',
              height: '100vh',
              left: 0
            }}
          />
        ) : null} */}

        <div className="email-campaigns-navigation-wrapper">
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            disableRipple
          >
            <Tab disableRipple style={tabStyle} label="Templates" />
            <Tab disableRipple style={tabStyle} label="Existing emails" />
          </Tabs>
        </div>

        <TabPanel value={activeTab} index={0}>
          <p className="email-summary-font pb-20 email-tab-text">
            Choose from a range of reusable templates
          </p>
          <AppliedFilters
              removeFilter={removeFilterPill}
              filters={filterPills}
              filtersCnt={getFiltersCount(userinfo)}
              _clearAllFilters={() => {
                onReset();
                resetFilterPills();
                setEnteredText('')
              }}
              className="mb-10"
          />
          <div className="select-template-page">
            <TemplatesPanel
              templates={templates}
              onReset={onReset}
              userEmail={userEmail}
              userName={userName}
              total={total}
              recordShowStart={recordShowStart}
              recordShowto={recordShowto}
              handleTemplatePreview={handleTemplatePreview}
              page={page}
              limit={limit}
              onPageChange={onPageChange}
              visible={visible}
              setIsVisible={setIsVisible}
              enteredText={enteredText}
              setEnteredText={setEnteredText}
              userinfo={userinfo}
              setUserInfo={setUserInfo}
              handleFilterChange={handleFilterChange}
              setIsReset={setIsReset}
              isReset={isReset}
              emailBriefing={emailBriefing}
              resetFilterPills={resetFilterPills}
            />
          </div>
        </TabPanel>
        
        <TabPanel value={activeTab} index={1}>
          <p className="email-summary-font pb-20 email-tab-text">
            Choose from a range of live emails created in CW
          </p>
          <AppliedFilters
              removeFilter={removeFilterPill}
              filters={filterPills}
              filtersCnt={getFiltersCount(userinfo)}
              _clearAllFilters={() => {
                onReset();
                resetFilterPills();
                setEnteredText('')
              }}
              className="mb-10"
          />
          <div className="select-template-page">
            <TemplatesPanel 
              templates={templates}
              onReset={onReset}
              userEmail={userEmail}
              userName={userName}
              total={total}
              recordShowStart={recordShowStart}
              recordShowto={recordShowto}
              handleTemplatePreview={handleTemplatePreview}
              page={page}
              limit={limit}
              onPageChange={onPageChange}
              visible={visible}
              setIsVisible={setIsVisible}
              enteredText={enteredText}
              setEnteredText={setEnteredText}
              userinfo={userinfo}
              setUserInfo={setUserInfo}
              handleFilterChange={handleFilterChange}
              setIsReset={setIsReset}
              isReset={isReset}
              emailBriefing={emailBriefing}
              isEmailTemplate={true}
              resetFilterPills={resetFilterPills}
            />
          </div>
        </TabPanel>
        
      </div>
      <ConfirmChangeTemplateModal
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        onSelect={handleConfirmTemplateSelect}
        handleResume={handleResume}
      />
      <TemplatePreviewOverlay
        isOpen={isTemplatePreviewOpen}
        selectedTemplateForPreview={selectedTemplateForPreview}
        handleCloseForm={handleCloseTemplatePreview}
        onPreviewSelect={handlePreviewSelect}
      />
    </div>
  );
};

export default EmailCampaignTemplate;
