import React, { useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { getEmailRowCount } from 'services/emailBriefings';
import { get } from 'lodash';
import constants from 'constants/config';
const FormWarningsInput = ({
  name,
  control,
  watchInput,
  errors,
  register,
  defaultValue,
  rules,
  trigger,
  setValue
}) => {
  const [message, setMessage] = useState('');
  const values = useWatch({
    control,
    name: watchInput
  });
  console.log('values: ', values);
  const rowType = get(values, '[0].value');
  const brand = get(values, '[1].value');
  const country = get(values, '[2].value');
  const type = get(values, '[3]');

  useEffect(() => {
    if (type !== 'brandSpecific') {
      setMessage('');
    }
  }, [type]);

  if (rowType && brand && country && type === 'brandSpecific') {
    getEmailRowCount(rowType, brand, country)
      .then(result => {
        if (result.count > 1 || result.count === 1) { 
          const categoryString =
            result.count === 1
              ? constants.EMAIL_SAVE_ROW_CATEGORY_MAP_SINGULAR[result.category]
              : constants.EMAIL_SAVE_ROW_CATEGORY_MAP[result.category];
          const messageString = `${result.count} ${categoryString} ${result.count === 1? 'is' : 'are'} already saved for your brand market combination, Max ${result.max} are allowed`;
          setMessage(messageString);
        } else {
          const categoryString = constants.EMAIL_SAVE_ROW_CATEGORY_MAP_SINGULAR[result.category]
          const messageString = `You have 0 ${categoryString} saved  for your brand market combination, Max ${result.max} are allowed`;
          setMessage(messageString);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  return (
    <div>
      {message && (
        <div className={`alert alert-warning`} role="alert">
          <strong>{message}</strong>
        </div>
      )}
    </div>
  );
};

export default FormWarningsInput;
