import React, { useState } from 'react';
import Modal from 'react-modal';
import constants from 'constants/config';
import RequestMoreInfoIconTransparent from 'images/requestMoreInfoIconTransparent';
import SmsCampaignRequestInfo from 'pages/SmsCampaignRequestInfo';
import SmsCostAndMoreRequestInfo from 'pages/SmsCostAndMoreRequestInfo';

const SmsCostAndRequestInfoModel = ({
  modalIsOpen,
  closeModal,
  smsDetails,
  pathUid
}) => {
  const [isActive, setIsActive] = useState(false);

  const dismissModal = () => {
    setIsActive(false)
    closeModal(false)
  }

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={dismissModal}
        className="cw-modal cw-modal--schedule-email-model"
        contentLabel="Schedule Modal"
        style={{
          overlay: {
            backgroundColor: constants.EMAIL_OVERLAY_BACKGROUND
          }
        }}
      >
        <button className="cw-modal--close" title="Close" onClick={dismissModal}>
          <i className="fas fa-times"></i>
        </button>

        <div className='d-flex email-request-more-info-modal-wrapper'>
          <div className="email-request-more-info-icon send-test-email__icon">
            <RequestMoreInfoIconTransparent />
          </div>
          <div className="w-100">
            <h3 className="cw-heading--senary">Share costs or request more information</h3>
            <SmsCostAndMoreRequestInfo
             pathUid={pathUid}
             closeModal={closeModal}
             smsDetails={smsDetails}
             toggle={isActive}
             setToggle={setIsActive}
             />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SmsCostAndRequestInfoModel;
