import {
  EMAIL_TYPE_ASYNC_IN_PROGRESS,
  UPDATE_EMAIL_TYPE,
  SET_EMAIL_EMAIL_TYPE,
  RESET_EMAIL_TYPE_PAGE
} from '../../actionTypes';
import { updateEmailBriefing, getEmailBriefingbyUid } from 'services/emailBriefings';
import { transformEmailBriefingData } from 'selectors';


export const setEmailType = emailType => ({
  type: SET_EMAIL_EMAIL_TYPE,
  emailType
});

export const setEmailTypeLoader = payload => ({
  type: EMAIL_TYPE_ASYNC_IN_PROGRESS,
  isLoading: payload
});

export const resetEmailType = () => ({
  type: RESET_EMAIL_TYPE_PAGE
});

export const updateEmailType = (uid, data, callback) => {
  return async function(dispatch) {
    try {
      dispatch(setEmailTypeLoader(true));
      if (uid) {
        await updateEmailBriefing(uid, data);
      }
      dispatch(setEmailType({ ...data, uid }));
      callback(null, true);
    } catch (error) {
      callback(error, null);
    } finally {
      dispatch(setEmailTypeLoader(false));
    }
  };
};

export const getEmailType = (uid) => {
  return function (dispatch) {
      dispatch(setEmailTypeLoader(true))
      getEmailBriefingbyUid(uid).then(result => {
        let transformedData = transformEmailBriefingData(result.data, "emailType")
        dispatch(setEmailTypeLoader(false))
        dispatch(setEmailType(transformedData));
      }).catch(error => {
        dispatch(setEmailTypeLoader(false))
        console.log("getEmailType error :", error)
      })
  }
}
