import React from 'react';
import classNames from 'classnames';
import { Title } from 'components/atoms/Title';
import { Input } from 'components/atoms/Input';
import ReportSectionHeading from 'components/atoms/ReportSectionHeading';

const ChartSummary = ({ data, className, titleClass }) => {
  return (
    <>
      {data &&
        data.length > 0 &&
        data.map((ele, index) => {
          return (
            <div
              className={classNames(
                'chart-summary-wrapper',
                className,
                ele.className
              )}
              key={index}
            >
              <div
                className={classNames(
                  'row ml-0 mr-0 justify-content-between align-items-center',
                  titleClass
                )}
              >
                <Title title={ele.title} />

                {ele.checkBoxConfig ? (
                  <>
                    <span onClick={ele.checkBoxConfig.onChange}>
                      <Input
                        type="checkbox"
                        name={ele.checkBoxConfig.title}
                        id={index}
                        checked={ele.checkBoxConfig.value}
                      />
                      <label className="checkbox-label-small">
                        {ele.checkBoxConfig.title}
                      </label>
                    </span>
                  </>
                ) : (
                  ''
                )}
              </div>

              <ReportSectionHeading className="chart-summary-separator" />
              {ele.data &&
                ele.data.length > 0 &&
                ele.data.map((row, key) => {
                  var accordianOpened =
                    ele.checkBoxConfig && ele.checkBoxConfig.value;
                  return (
                    <>
                      <div className="row" key={key}>
                        <div
                          className={
                            'col-sm-8 cell-value ' +
                            (accordianOpened ? 'highlight' : '')
                          }
                        >
                          {row.title}
                        </div>
                        <div
                          className={
                            'col-sm-2 text-right cell-value ' +
                            (accordianOpened ? 'highlight' : '')
                          }
                        >
                          {row.value}
                        </div>
                        {!accordianOpened ? (
                          <>
                            <div className="col-sm-2 text-right cell-value">
                              {row.percentage}
                            </div>
                          </>
                        ) : null}
                      </div>
                      {accordianOpened && row.data
                        ? row.data.map((innerRow, key2) => {
                            return (
                              <div className="row" key={key2}>
                                <div className="col-sm-8 cell-value ">
                                  {innerRow.title}
                                </div>
                                <div className="col-sm-2 text-right cell-value">
                                  {innerRow.value}
                                </div>
                                <div className="col-sm-2 text-right cell-value">
                                  {innerRow.percentage}
                                </div>
                              </div>
                            );
                          })
                        : null}
                    </>
                  );
                })}

              <ReportSectionHeading className="chart-summary-separator" />
              {ele.buttonConfig ? (
                <>
                  <div
                    className={classNames(
                      'row ml-0 mr-0 justify-content-end ',
                      titleClass
                    )}
                  >
                    {' '}
                    <Title
                      title={ele.buttonConfig.title}
                      className="cw-chart-table-right-button"
                      onClick={ele.buttonConfig.buttonClicked}
                    />
                  </div>
                </>
              ) : null}
              {ele.total && (
                <>
                  <div className="row ">
                    <div className="col-sm-8 cell-value highlight">
                      {ele.total.title}
                    </div>
                    <div className="col-sm-2 text-right cell-value highlight">
                      {ele.total.value}
                    </div>
                    <div className="col-sm-2 text-right cell-value highlight">
                      {ele.total.percentage}
                    </div>
                  </div>
                </>
              )}
            </div>
          );
        })}
    </>
  );
};

export default ChartSummary;
