import React, { useEffect, useState, useRef, forwardRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import { FormBuilder } from 'components/organisms/FormBuilder';
import Loader from 'components/Loader';
import formSchema from './designSmsSchema';
import MobileDevicePreview from 'components/organisms/MobileDevicePreview/index.jsx';
import ProgressBar from 'components/molecules/ProgressBar';
import OptOutTextMissingMessage from 'components/communications/OptOutTextMissingMessage';
import { CustomButtons } from 'pages/EmailCampaignAudience';

import { retrieveSmsCampaignInformation } from 'actions/communications/communicationCampaignDetails';

import { getMobilePreviewDates } from 'utilities/util';
import constants from '../../constants/config';
import { get } from 'lodash';

const PreWrap = forwardRef((props, ref) => {
  const [previewObj, setPreviewObject] = useState([]);
  useEffect(() => {
    ref.current = setPreviewObject
  },[])
  return (<MobileDevicePreview data={previewObj} campaign={props.campaign} isDesignPage/>)
})

const DesignSMS = props => {
  const [isEdit, setEdit] = useState(false);
  const [insertingURLMain, setInsertingURLMain] = useState(false)
  const [insertingURLFollowup, setInsertingURLFollowup] = useState(false)
  const communicationCampaignDetails = useSelector(
    state => state.communicationCampaignDetails
    );
  const { authorizedUserData } = useSelector(state => state.authorized);
  const dispatch = useDispatch();
  const pathUid = props.match.params.id;
  const previewRef = useRef();
  useEffect(() => {
    if (pathUid) {
      setEdit(true);
      // fetch from email details from the server
      dispatch(retrieveSmsCampaignInformation(pathUid));
    }
  }, []);

  const { location } = props.history || {}

  const hideProgress = location?.state?.isEdited && location?.state?.executionRedesign;

  const smsBrandDetails = communicationCampaignDetails &&  communicationCampaignDetails.smsBrandDetails

  useEffect(() => {
    const smsDetails = smsBrandDetails?.communicationDetail?.smsDetails
    const mainContent = smsDetails?.length ? smsDetails[0]?.smsContent : ''
    if(mainContent && (mainContent?.includes(constants.SAMPLE_BITLY_URL) || mainContent?.includes(get(smsDetails,'[0].URL.[0].shortURL')))) {
      setInsertingURLMain(true)
    } else {
      setInsertingURLMain(false)
    }
  }, [smsBrandDetails])

  //if(!smsBrandDetails) return null
  const { isLoading, optOutText  } = communicationCampaignDetails
  console.log('optOutText: ', optOutText, typeof optOutText);
  const { uid } = smsBrandDetails
  const previewDates = useMemo(() => getMobilePreviewDates(smsBrandDetails),[smsBrandDetails])
  const handleFormStateChange = (givenValues, defaultValues) => {
    let values = givenValues;
    let flag = true;
    for(let i=0; i< givenValues.length; i++){
      if(typeof givenValues[i] !== 'undefined') {
        flag = false
      }
    }
    if(flag){
      values = defaultValues
    }
    const data = [];
    if(values[0]){
      data.push(
        {
          date: moment.utc(`${previewDates.broadcastDate1} ${previewDates.broadcastTime1}`, 'DD/MM/YYYY h:mm A').format('ddd, D MMM [at] h:mm a'),
          text: values[0],
          optOutText: values[1],
          shortURL : get(values,'[2].shortURL'),
          fullURL :  get(values,'[2].fullURL'),
        }
      )
    }
   /* if(values[2]){
      data.push(
        {
          date: moment.utc(`${previewDates.broadcastDate2} ${previewDates.broadcastTime2}`, 'DD/MM/YYYY h:mm A').format('ddd, D MMM [at] h:mm a'),
          text: values[2],
          optOutText: values[3]
        }
      )
    }*/
    if(previewRef.current){
      previewRef.current(data)
    }
  }

  const compProps = {
    history: props.history,
    match: props.match,
    defaultValues: smsBrandDetails,
    optOutText: optOutText,
    onFormValueChange: handleFormStateChange,
    authorizedUserData : authorizedUserData,
    insertingURLMain,
    insertingURLFollowup,
    setInsertingURLMain,
    setInsertingURLFollowup
  };

  return (
    <div className="col-sm-12 skypink">
      <div className="cw-section">
        {!hideProgress && <ProgressBar
          customClass={'cw-email-progress-custom'}
          totalPage={constants.SMS_PROGRESS_BAR_TOT_PAGE}
          pageNo={constants.SMS_JOURNEY_PAGE_NUMBERS.DESIGN_SMS}
        />}
        <div className={`cw-section--title mb-30 ${hideProgress ? 'mt-30' : ''}`}>
          <h2 className="cw-heading--secondary cw-email-heading">
            Design SMS
          </h2>
        </div>
        <div className="row">
          {isLoading ? <Loader /> : null}
          {!isLoading? <><div className="col-md-8">
            {!optOutText && typeof optOutText === 'string' && <OptOutTextMissingMessage isError/>}
            <FormBuilder
              schema={formSchema(dispatch, compProps)}
              customButtonsComp={CustomButtons}
              user={authorizedUserData}
              isEdit={false}
            />
          </div>
          <div className="col-md-4">
            <PreWrap ref={previewRef} campaign={communicationCampaignDetails?.smsBrandDetails || {}}/>
          </div></> :null}
        </div>
      </div>
    </div>
  );
};

export default DesignSMS;
