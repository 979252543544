import React, { useEffect, useState, useMemo } from 'react';
import allCampaign from '../../images/allCampaign.png';
import approverImage from '../../images/approver.png';
import masterDataImage from '../../images/master-data.png';
import { DashboardCard } from '../organisms/DashboardCard/DashboardCard';

var userSummaryCards = [
  {
    title: 'Requestors',
    value: 0,
    className: 'cw-dashboard-summary-card-requestors',
    secondaryCard: (
      <>
        <strong>Requestors</strong> are users who create campaign requests and
        can be brand marketers, data acquisition leads or agency partners
      </>
    )
  },
  {
    title: 'Approvers',
    value: 0,
    className: 'cw-dashboard-summary-card-approvers',
    secondaryCard: (
      <>
        <strong>Approvers</strong> are users who approve campaign requests,
        usually data acquisition leads, and are identified per market
      </>
    )
  },
  {
    title: 'Consumer Data Bureau',
    value: 0,
    className: 'cw-dashboard-summary-card-data-bureau',
    secondaryCard: (
      <>
        <strong>Consumer data bureau</strong> are users who assign master data
        IDs for consumer data enrichment question and answers
      </>
    )
  },
  {
    title: 'Admins',
    value: 0,
    className: 'cw-dashboard-summary-card-admin',
    secondaryCard: (
      <>
        <strong>Admins</strong> are users who have more control on managing
        other users, permissions and configurations of Campaign Wizard
      </>
    )
  }
];

const UserSummary = props => {
  let campaignMatrix = props.campaignMatrix;
  // User Summary
  if (campaignMatrix && campaignMatrix.userSummary) {
    //Requestors
    userSummaryCards[0].value = campaignMatrix.userSummary.requestors
      ? campaignMatrix.userSummary.requestors.value
      : 0;

    //approvers
    userSummaryCards[1].value = campaignMatrix.userSummary.approvers
      ? campaignMatrix.userSummary.approvers.value
      : 0;

    // Mater data assigners
    userSummaryCards[2].value = campaignMatrix.userSummary.masterDataAppovers
      ? campaignMatrix.userSummary.masterDataAppovers.value
      : 0;

    // Admins
    userSummaryCards[3].value = campaignMatrix.userSummary.admins
      ? campaignMatrix.userSummary.admins.value
      : 0;
  }

  return (
    <>
      <div className="mt-40" style={{ marginBottom: '1rem' }}>
        <div className="row">
          <DashboardCard
            data={userSummaryCards}
            colClass={`col-sm-3 pl-0 pr-0`}
          />
        </div>
      </div>
      <div style={{ minHeight: '15vh' }}></div>
    </>
  );
};

export default UserSummary;
