import React, { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { useHistory } from 'react-router-dom';
import SSOLogo from 'images/login-logo.png';
import logo from 'images/CW_logo.svg';
import SSOLoginProgress from 'images/login-progress.gif';
import { getLoggedInUser } from 'utilities/util';

const LoginRedirect = props => {
  const { instance } = useMsal();
  const history = useHistory();
  let activeAccount = getLoggedInUser(instance);

  useEffect(() => {
    const mainElement = document.querySelector('main');
    mainElement.classList.add('pb-0');
    return () => {
      mainElement.classList.remove('pb-0');
    };
  }, []);

  useEffect(() => {
    const redirectAction = setTimeout(() => {
      if (activeAccount) {
        history.push('/campaign-wizard');
      } else {
        history.push('/login');
      }
    }, 3000);
    return () => {
      clearInterval(redirectAction);
    };
  }, [activeAccount]);

  return (
    <div className="cw--login pinkpurple loginpage min-vh-100 d-flex justify-content-center align-items-center">
      <div className="login-progress-box">
        <div className="login-progress-box_header">
          <img
            className="login-progress-box_logo"
            src={logo}
            alt="Campaign wizard logo"
          />
          <h3>Campaign Wizard</h3>
        </div>
        <div className="login-progress-box_loader">
          <img
            className="loader-img"
            src={SSOLoginProgress}
            alt="Campaign wizard login progress img"
          />
          <h3>Please wait login in progress</h3>
        </div>
      </div>
    </div>
  );
};

export default LoginRedirect;
