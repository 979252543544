import React from 'react';
export const EmailBrandDisplay = ({
  brand,
  isCrossBrand,
  country,
  showAllBrands
}) => {
  if (Array.isArray(brand)) {
    if (!isCrossBrand) {
      return (
        <>
          {brand[0]} {country && '|'} {country}
        </>
      );
    } else {
      return (
        <>
          <span>
            Cross brand email {country && '|'} {country}
          </span>
          <br />
          <span>
            {showAllBrands
              ? brand.join(', ')
              : brand.length === 1
              ? brand[0]
              : brand.length === 2
              ? `${brand[0]} | ${brand[1]}`
              : `${brand[0]} | ${brand[1]} | + ${brand.length - 2} others`}
          </span>
        </>
      );
    }
  }
  return (
    <>
      {brand} {country && '|'} {country}
    </>
  );
};
