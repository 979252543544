import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import axios from 'axios';
import constants from 'constants/config';
import {
  debounceFunction,
  getDistinctBusinessFilters
} from 'selectors/index.js';
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';
import groupIcon from '../../images/groupIcon.png';
import Alert from 'components/Alert';

const QuestionAnswerNewOverlay = props => {
  const [searchedQ, setSearchedQuestion] = useState('');
  const [isOpenModel, setIsOpenModel] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [tempQuestions, setTempQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [tempAnswers, setTempAnswers] = useState([]);

  const [showQuestions, setQuestionsOption] = useState(true);
  const [showAnswers, setAnswersOption] = useState(false);
  let [selectQuestionOption, setSelectQuestion] = useState(false);
  const [addNewQuestions, setNewQuestions] = useState(false);
  const [addNewAnswer, setNewAnswer] = useState(false);
  let [selectedQuestionArray, setSelectedQuestionList] = useState([]);
  let [selectedFilter, setSelectedFilter] = useState('');
  const [masterDataQue, setMasterDataQue] = useState({
    isNew: true,
    istranslatedNew: false,
    translatedLabel: '',
    englishLabel: '',
    dataType: 'questionAnswerPair',
    id: '',
    reasonForCreation: ''
  });

  const [masterDataAns, setMasterDataAns] = useState({
    isNew: true,
    istranslatedNew: false,
    translatedLabelAns: '',
    englishLabelAns: '',
    dataType: 'questionAnswerPair',
    id: ''
  });
  const [isTranslationAdded, setTranslationAdded] = useState(true);
  const [showQuestionError, setQuestionListError] = useState(false);
  const [showAnswerError, setShowAnswerError] = useState(false);
  const [selectAnsList, setSelectAnsList] = useState([]);
  const [businessFilters, setBusinessFilter] = useState([]);
  const [showAnsSuccessMsg, setShowAnsSuccessMsg] = useState(false);
  const [freeTextWarning, setFreeTextWarning] = useState(false);

  const handleChangeInput = e => {
    document.getElementById(`${e.target.name}`).classList.add('d-none');
    document
      .getElementById(`${e.target.id}`)
      .classList.contains('cw-error-focus') &&
      document
        .getElementById(`${e.target.id}`)
        .classList.remove('cw-error-focus');
    setMasterDataQue({ ...masterDataQue, [e.target.name]: e.target.value });
  };

  const handleChangeInputEngAns = e => {
    document.getElementById(`${e.target.name}_err`).classList.add('d-none');
    document
      .getElementById(`${e.target.id}`)
      .classList.contains('cw-error-focus') &&
      document
        .getElementById(`${e.target.id}`)
        .classList.remove('cw-error-focus');
    setMasterDataAns({ ...masterDataAns, [e.target.name]: e.target.value });
  };

  let fetchQuestionList = async () => {
    let queUrl = constants.serviceUrls.FETCH_ALL_QUESTIONS;
    if (props.language) {
      queUrl = queUrl + '?language=' + props.language;
    }
    await axios.get(queUrl).then(response => {
      let data = response && response.data;
      setQuestions(data);
      if (data && data.length > 0) {
        setTempQuestions(JSON.parse(JSON.stringify(data)));
      }
      setBusinessFilter(getDistinctBusinessFilters(data));
    });
  };
  let fetchAnswersList = async () => {
    let ansUrl = constants.serviceUrls.FETCH_ALL_ANSWERS;
    if (props.language) {
      ansUrl = ansUrl + '?language=' + props.language;
    }
    await axios.get(ansUrl).then(response => {
      let data = response && response.data;
      setAnswers(data);
      if (data && data.length) {
        setTempAnswers(JSON.parse(JSON.stringify(data)));
      }
    });
  };

  let searchAnswers = text => {
    // setSelectAnsList([])
    axios
      .get(
        constants.serviceUrls.FETCH_ALL_ANSWERS +
          '?language=' +
          props.language +
          '&search=' +
          text
      )
      .then(response => {
        let data = response && response.data;
        if (selectAnsList && selectAnsList.length > 0 && data.length > 0) {
          for (let ans of selectAnsList) {
            if (ans && ans.id) {
              let findIndex = data.findIndex(e => e.id == ans.id);
              if (findIndex > -1) {
                data[findIndex]['isSelected'] = true;
                data[findIndex].translatedText =
                  ans && ans.translatedText ? ans.translatedText : '';
                data[findIndex].istranslatedNew =
                  ans && ans.translatedText ? true : false;
              }
            }
          }
        }
        setAnswers(data);
        if (data && data.length) {
          setTempAnswers(JSON.parse(JSON.stringify(data)));
        }
      });
  };

  let searchQuestionsBox = e => {
    let data = e.target.value;
    setSearchedQuestion(data);
    debounceFunction(searchQuestions(data, selectedFilter), 1000);
  };

  let searchAnswerBox = e => {
    let data = e.target.value;
    setSearchedQuestion(data);
    debounceFunction(searchAnswers(data), 600);
  };

  let backBtnFromAddQue = e => {
    setSelectedFilter('');
    setNewQuestions(false);
    setSearchedQuestion('');
    debounceFunction(searchQuestions(''), 1000);
  };

  let backBtnFromAddAnswer = e => {
    setNewAnswer(false);
    setSearchedQuestion('');
    // setSelectAnsList([])
    debounceFunction(searchAnswers(''), 500);
  };

  let setSelectedFilterText = group => {
    if (group && group !== '') {
      if (group === selectedFilter) {
        setSelectedFilter('');
        group = '';
      } else {
        setSelectedFilter(group);
      }
    }
    searchQuestions(searchedQ, group);
  };

  let searchQuestions = async (text, group) => {
    await axios
      .get(
        constants.serviceUrls.FETCH_ALL_QUESTIONS +
          '?language=' +
          props.language +
          '&search=' +
          text +
          '&group=' +
          (group !== undefined && group != null
            ? encodeURIComponent(group)
            : '')
      )
      .then(response => {
        let data = response && response.data;
        setQuestions(data);
        if (selectQuestionOption && selectQuestionOption.isSelected) {
          if (selectQuestionOption && selectQuestionOption.id) {
            let findIndex = data.findIndex(
              e => e.id == selectQuestionOption.id
            );
            if (findIndex > -1) {
              data[findIndex]['isSelected'] = true;
              data[findIndex][
                'translatedText'
              ] = selectQuestionOption.translatedText
                ? selectQuestionOption.translatedText
                : '';
              data[
                findIndex
              ].istranslatedNew = selectQuestionOption.translatedText
                ? true
                : false;
            }
          }
        }
        if (data && data.length > 0) {
          setTempQuestions(JSON.parse(JSON.stringify(data)));
        }
      });
  };
  const openNewAnswerMaster = e => {
    setNewAnswer(true);
    setShowAnswerError(false);
  };

  useEffect(() => {
    setSearchedQuestion('');
    fetchQuestionList();
    fetchAnswersList();
    // return () => {
    //   second
    // }
  }, []);

  const closeOverlayModal = event => {
    setSelectAnsList([]);
    event.preventDefault();
    fetchQuestionList();
    fetchAnswersList();
    setSearchedQuestion('');
    setTranslationAdded(true);
    setIsOpenModel(false);
    document.body.style.overflow = 'visible';
    // document.querySelector('html').style.overflowY = 'visible';
    setNewQuestions(false);
    setNewAnswer(false);
    setShowAnswerError(false);
    setSelectedFilter('');
    setMasterDataAns({ englishLabelAns: '', translatedLabelAns: '' });
    setMasterDataQue({
      isNew: true,
      istranslatedNew: true,
      translatedLabel: '',
      englishLabel: '',
      dataType: 'questionAnswerPair',
      id: '',
      reasonForCreation: ''
    });
    setQuestionListError(false);
    setFreeTextWarning(false);
  };
  const openQnAOverlayModal = event => {
    //  document.body.scrollTop = 300
    document.body.style.overflow = 'hidden';
    // document.querySelector('html').style.overflowY = 'hidden';
    setQuestionListError(false);
    setSelectQuestion(false);
    event.preventDefault();
    setIsOpenModel(true);
    // document.body.scrollTop = 300
  };

  let _handleInputChange = (e, index, selectedQuestion) => {
    setQuestionListError(false);
    let filteredList = questions.filter(
      (elem, index) => elem.isSelected !== undefined && elem.isSelected
    );
    if (filteredList && filteredList.length > 0) {
      filteredList[0].isSelected = false;
      if (!filteredList[0].isTranslationExist) {
        filteredList[0].translatedText = '';
      }
      setQuestions([...questions, filteredList]);
    }
    setTranslationAdded(true);
    let questionList = [...questions];
    questionList[index].isSelected = true;
    setSelectQuestion(selectedQuestion);
    setQuestions([...questionList]);
  };

  let _handleMultiSelectAnswers = (isChecked, index, obj) => {
    answers[index]['isSelected'] = isChecked;
    if (props.browseType === 'Answers') {
      setShowAnswerError(false);
      if (isChecked) {
        obj.rootIndex = index;
        setSelectAnsList([...selectAnsList, obj]);
      } else {
        let selectAns = [...selectAnsList];
        let spliceIndex = selectAns.findIndex(e => e.id == obj.id);
        if (spliceIndex > -1) {
          selectAns.splice(spliceIndex, 1);
        }
        // const removedUnselectedAns = answers.filter(el => el.isSelected && el.isSelected == true)
        setSelectAnsList([...selectAns]);
      }
    }
  };

  let handleTranslation = (e, index, selectedQuestion) => {
    let enteredText = e.target.value;
    let questionList = [...questions];
    setTranslationAdded(true);
    questionList[index].translatedText = enteredText;
    questionList[index].istranslatedNew = true;
    setSelectQuestion({
      ...selectedQuestion,
      translatedText: enteredText,
      istranslatedNew: true
    });
    setQuestions([...questionList]);
  };

  let handleAnswerTranslation = (e, index, value) => {
    let enteredText = e.target.value;
    let answerList = [...answers];
    answerList[index].translatedText = enteredText;
    document
      .getElementById(`translatedAnwText_${value.id}`)
      .classList.contains('cw-error-focus') &&
      document
        .getElementById(`translatedAnwText_${value.id}`)
        .classList.remove('cw-error-focus');

    document
      .getElementById(`errorMessage_translatedAnwText__${value.id}`)
      .classList.add('d-none');
    answerList[index].istranslatedNew = true;
    let selectedAns = [...selectAnsList];
    let updateIndex = selectedAns.findIndex(e => e.id === value.id);
    if (updateIndex > -1) {
      selectedAns[updateIndex].translatedText = enteredText;
      selectedAns[updateIndex].istranslatedNew = true;
    }
    setSelectAnsList([...selectedAns]);
    setAnswers([...answerList]);
  };

  let addQuestionToList = e => {
    if (props.browseType === 'Answers') {
      let isValid = true;
      if (selectAnsList && selectAnsList.length != 0) {
        setShowAnswerError(false);
        for (let ele of selectAnsList) {
          if (
            ele.isSelected &&
            props.campaignLanguage !== 'English' &&
            (ele.translatedText === '' ||
              ele.translatedText === undefined ||
              (ele.translatedText && ele.translatedText.trim().length === 0))
          ) {
            isValid = false;
          }
        }
        if (isValid) {
          setShowAnsSuccessMsg(true);
          props.getNewAnsCallback(selectAnsList, props.rootIndex);
          // closeOverlayModal(e);
          setTimeout(() => {
            setShowAnsSuccessMsg(false);
          }, 2000);
        } else {
          let errorArray = [];
          let enteredTransArr = [];
          let answerList = [...answers];
          let newIndex = 0;
          answerList = answerList.filter(
            e => e.isSelected === undefined || e.isSelected === false
          );
          for (let ele of selectAnsList) {
            if (
              ele.isSelected &&
              props.campaignLanguage !== 'English' &&
              (ele.translatedText === '' ||
                ele.translatedText === undefined ||
                (ele.translatedText && ele.translatedText.trim().length === 0))
            ) {
              let newEle = JSON.parse(JSON.stringify(ele));
              newEle.rootIndex = newIndex;
              newEle.isSelected = true;
              newIndex++;
              errorArray.push(newEle);
            }
          }
          for (let ele of selectAnsList) {
            if (
              ele.isSelected &&
              props.campaignLanguage !== 'English' &&
              ele.translatedText &&
              ele.translatedText.trim().length != 0
            ) {
              let newEle = JSON.parse(JSON.stringify(ele));
              newEle.rootIndex = newIndex;
              newEle.isSelected = true;
              newIndex++;
              enteredTransArr.push(newEle);
            }
          }
          let newAnswer = errorArray.concat(enteredTransArr.concat(answerList));
          let newArray = errorArray.concat(enteredTransArr);
          setSelectAnsList([...newArray]);
          setAnswers([...newAnswer]);
          setTimeout(() => {
            for (let ele of newArray) {
              if (
                ele.isSelected &&
                props.campaignLanguage !== 'English' &&
                (ele.translatedText === '' ||
                  ele.translatedText === undefined ||
                  (ele.translatedText &&
                    ele.translatedText.trim().length === 0))
              ) {
                document
                  .getElementById(`translatedAnwText_${ele.id}`)
                  .classList.contains('cw-error-focus') ||
                  document
                    .getElementById(`translatedAnwText_${ele.id}`)
                    .classList.add('cw-error-focus');
                document
                  .getElementById(`errorMessage_translatedAnwText__${ele.id}`)
                  .classList.remove('d-none');

                document.getElementById(
                  `translatedAnwText_${ele.id}`
                ).value = null;
              } else {
                if (
                  document.getElementById(`translatedAnwText_${ele.id}`) &&
                  document.getElementById(`translatedAnwText_${ele.id}`)
                    .classList
                ) {
                  document
                    .getElementById(`translatedAnwText_${ele.id}`)
                    .classList.contains('cw-error-focus') &&
                    document
                      .getElementById(`translatedAnwText_${ele.id}`)
                      .classList.remove('cw-error-focus');
                  document
                    .getElementById(`errorMessage_translatedAnwText__${ele.id}`)
                    .classList.add('d-none');
                }
              }
            }
            document.getElementById('question-overlay') &&
              document.getElementById('question-overlay').scrollTo({ top: 0 });
          }, 500);
          return;
        }
      } else {
        setShowAnswerError(true);
      }
    } else {
      if (!selectQuestionOption) {
        setQuestionListError(true);
      } else {
        let filteredList = questions.filter(
          (elem, index) => elem.isSelected !== undefined && elem.isSelected
        );
        if (filteredList && filteredList.length === 0) {
          setQuestionListError(true);
        } else {
          setSelectedQuestionList(selectQuestionOption);
          if (
            props.campaignLanguage !== 'English' &&
            filteredList[0] &&
            filteredList[0].translatedText !== undefined &&
            filteredList[0].translatedText.trim().length > 0
          ) {
            filteredList[0].isSelected = false;
            setQuestions([...questions, filteredList]);
            props.getNewQueCallback(selectQuestionOption, props.rootIndex);
            setTranslationAdded(true);
            closeOverlayModal(e);
          } else if (props.campaignLanguage === 'English') {
            if (filteredList && filteredList.length > 0) {
              filteredList[0].isSelected = false;
            }
            setQuestions([...questions, filteredList]);
            props.getNewQueCallback(selectQuestionOption, props.rootIndex);
            setTranslationAdded(true);
            closeOverlayModal(e);
          } else {
            setTranslationAdded(false);
            setQuestionListError(false);
            return;
          }
          if (selectedQuestionArray && selectedQuestionArray.length < 1) {
            setQuestionListError(true);
          } else {
            setQuestionListError(false);
          }
        }
      }
    }
  };

  const optimizedAnswerVersion = debounceFunction(searchAnswers, 600);
  const optimizedQuestionVersion = debounceFunction(searchQuestions, 600);
  const questionTypes = [
    {
      label: 'Data enrichment',
      value: 'Data enrichment',
      id: 1
    },
    {
      label: 'Operational',
      value: 'Operational',
      id: 2
    }
  ];
  const handleSelectData = selectedOption => {
    document.getElementById('ReasonForMasterdata').classList.add('d-none');
    setMasterDataQue({
      ...masterDataQue,
      reasonForCreation: selectedOption.value
    });
    setFreeTextWarning(false)
    if((props.questionType === 'freeText' || props.questionType ==='freeTextArea') && selectedOption.value === 'Data enrichment'){
      setFreeTextWarning(true)
    }
  };
  const handleSubmit = e => {
    let isValid = true;
    if (masterDataQue && masterDataQue.reasonForCreation.trim().length === 0) {
      document.getElementById('ReasonForMasterdata').classList.remove('d-none');
      document.getElementById('reason').classList.add('cw-error-focus');
      isValid = false;
    } else {
      document.getElementById('reason').classList.contains('cw-error-focus') &&
        document.getElementById('reason').classList.remove('cw-error-focus');
      document.getElementById('ReasonForMasterdata').classList.add('d-none');
    }
    if (masterDataQue && masterDataQue.englishLabel.trim().length === 0) {
      document.getElementById('englishLabel').classList.remove('d-none');

      document.getElementById('english').classList.add('cw-error-focus');
      isValid = false;
    } else {
      document.getElementById('english').classList.contains('cw-error-focus') &&
        document.getElementById('english').classList.remove('cw-error-focus');
      document.getElementById('englishLabel').classList.add('d-none');
    }
    if (props.campaignLanguage !== 'English') {
      if (masterDataQue && masterDataQue.translatedLabel.trim().length === 0) {
        document.getElementById('translatedLabel').classList.remove('d-none');
        document.getElementById('translated').classList.add('cw-error-focus');
        isValid = false;
      } else {
        document
          .getElementById('translated')
          .classList.contains('cw-error-focus') &&
          document
            .getElementById('translated')
            .classList.remove('cw-error-focus');
        document.getElementById('translatedLabel').classList.add('d-none');
        masterDataQue.istranslatedNew = true;
      }
    }
    if (isValid) {
      props.getNewQueCallback(masterDataQue, props.rootIndex, true);
      closeOverlayModal(e);
      setNewQuestions(false);
      setMasterDataQue({
        isNew: true,
        istranslatedNew: props.campaignLanguage === 'English' ? false : true,
        translatedLabel: '',
        englishLabel: '',
        dataType: 'questionAnswerPair',
        id: '',
        reasonForCreation: ''
      });
    }
  };

  const handleSubmitAnswer = e => {
    let isValid = true;
    if (masterDataAns && masterDataAns.englishLabelAns.trim().length === 0) {
      document.getElementById('englishLabelAns_err').classList.remove('d-none');

      document
        .getElementById('englishLabelAns')
        .classList.add('cw-error-focus');
      isValid = false;
    } else {
      document
        .getElementById('englishLabelAns')
        .classList.contains('cw-error-focus') &&
        document
          .getElementById('englishLabelAns')
          .classList.remove('cw-error-focus');
      document.getElementById('englishLabelAns_err').classList.add('d-none');
    }
    if (props.campaignLanguage !== 'English') {
      if (
        masterDataAns &&
        masterDataAns.translatedLabelAns.trim().length === 0
      ) {
        document
          .getElementById('translatedLabelAns_err')
          .classList.remove('d-none');
        document
          .getElementById('translatedLabelAns')
          .classList.add('cw-error-focus');
        isValid = false;
      } else {
        document
          .getElementById('translatedLabelAns')
          .classList.contains('cw-error-focus') &&
          document
            .getElementById('translatedLabelAns')
            .classList.remove('cw-error-focus');
        document
          .getElementById('translatedLabelAns_err')
          .classList.add('d-none');
      }
    }

    if (isValid) {
      setShowAnsSuccessMsg(true);
      props.getNewAnsCallback(masterDataAns, props.rootIndex, true);
      setNewQuestions(false);
      setMasterDataAns({
        isNew: true,
        istranslatedNew: true,
        translatedLabelAns: '',
        englishLabelAns: '',
        dataType: 'questionAnswerPair',
        id: ''
      });
      setTimeout(() => {
        setShowAnsSuccessMsg(false);
      }, 2000);
    }
  };

  return (
    <>
      <div
        className={
          props.isWebsiteTypeNonSaaS
            ? 'col-sm-6 col-xl-5 p-0'
            : 'col-sm-6 col-xl-5'
        }
      >
        <button
          type="submit"
          className="btn btn-primary mt-1 rounded "
          style={
            props.isWebsiteTypeNonSaaS
              ? { width: '90%' }
              : { width: '85%', marginLeft: '16px' }
          }
          onClick={openQnAOverlayModal}
        >
          Browse {props.browseType}
        </button>
      </div>
      <Modal
        scrollable={true}
        isOpen={isOpenModel}
        onRequestClose={closeOverlayModal}
        className={
          'cw-modal cw-modal--qnaoverlay ' +
          (addNewAnswer ? ' answer-model ' : '')
        }
        contentLabel="Campaign Wizard 2.0"
        style={{
          overlay: {
            backgroundColor: constants.EMAIL_OVERLAY_BACKGROUND
          }
        }}
      >
        {!addNewQuestions && !addNewAnswer ? (
          <>
            <div style={{ marginLeft: '1%', marginBottom: '9px' }}>
              <span>
                <strong>
                  Select and add from master list of{' '}
                  {props.browseType.toLocaleLowerCase()}
                </strong>
              </span>
              &nbsp;{' '}
              <span style={{ color: '#007dbb' }}>{props.campaignLanguage}</span>
              <span
                className={'qna-guideline-close'}
                onClick={closeOverlayModal}
                style={{ right: '-4%' }}
              ></span>
            </div>
            <div>
              {/* {
                props.browseType === 'Questions' ?
                  <>
                    <h4 className="cw-heading--qnafilters mt-3 mb-3 mr-2 ml-2"> Filter by business group</h4>
                  </>
                  : null} */}
              {props.browseType === 'Questions' ? (
                <div style={{ marginLeft: '1%' }}>
                  {businessFilters &&
                    businessFilters.length > 0 &&
                    businessFilters.map((elem, index) => {
                      {
                        /* {constants.BUSINESS_FILTERS.map((elem, index) => { */
                      }
                      return (
                        <span>
                          <button
                            type="submit"
                            onClick={() =>
                              setSelectedFilterText(elem !== 'All' ? elem : '')
                            }
                            className={
                              'btn filter-buttons mb-10 cw-print-hide text-nowrap  mr-1 ' +
                              (selectedFilter && selectedFilter === elem
                                ? 'active'
                                : '')
                            }
                          >
                            {elem}
                          </button>
                        </span>
                      );
                    })}
                </div>
              ) : null}
            </div>

            <div style={{ margin: '0' }}>
              <div className="row">
                <div className="col-sm-8">
                  <input
                    type="search"
                    className="form-control"
                    name={'searchQ'}
                    id={'searchQ'}
                    placeholder="Search using keywords or master IDs"
                    style={{
                      width: '90%',
                      height: '2em',
                      marginLeft: '8px',
                      marginTop: '1%'
                    }}
                    value={searchedQ}
                    onChange={
                      props.browseType === 'Answers'
                        ? searchAnswerBox
                        : searchQuestionsBox
                    }
                  />
                </div>
                <div
                  className={`col-sm-4 text-right`}
                  style={{ marginTop: '1%' }}
                >
                  <a
                    className="new-question-answer-btn"
                    onClick={() =>
                      props.browseType == 'Questions'
                        ? setNewQuestions(true)
                        : openNewAnswerMaster()
                    }
                  >
                    <u>
                      Request new{' '}
                      {props.browseType == 'Questions' ? 'question' : 'answer'}
                    </u>
                  </a>
                </div>
              </div>
              <div
                className={
                  'qna-guidline-btn-wrap ' +
                  (props.browseType === 'Questions' ? 'redice-height' : '')
                }
              >
                <div
                  id="question-overlay"
                  className={
                    'qna-guideline-scroll ' +
                    (props.browseType === 'Answers'
                      ? 'add-height'
                      : 'add-height-test')
                  }
                >
                  <div className="mb-10 cw-color--saasheading d-none">
                    <strong>Available master list</strong>
                  </div>
                  <ul>
                    {props.browseType === 'Answers' ? (
                      <>
                        {answers.length > 0 &&
                          answers.map((elem, index) => {
                            return (
                              <>
                                <div className="form-check mb-1" key={elem.id}>
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id={`answer_${index}`}
                                    name={`answer_${index}`}
                                    value={elem.id}
                                    checked={
                                      elem.isSelected ? elem.isSelected : false
                                    }
                                    onChange={e =>
                                      _handleMultiSelectAnswers(
                                        e.target.checked,
                                        index,
                                        answers[index]
                                      )
                                    }
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={`answer_${index}`}
                                  >
                                    {elem.text}
                                  </label>
                                  {props.campaignLanguage !== 'English' ? (
                                    <>
                                      <p
                                        style={{
                                          fontSize: '12px',
                                          marginLeft: '31px'
                                        }}
                                      >
                                        <span style={{ color: '#007dbb' }}>
                                          {props.campaignLanguage}:
                                        </span>{' '}
                                        <span>
                                          {elem &&
                                          elem.translatedText &&
                                          elem.translatedText !== undefined &&
                                          elem.translatedText !== ''
                                            ? document.getElementById(
                                                `translatedAnwText_${elem.id}`
                                              ) &&
                                              document
                                                .getElementById(
                                                  `translatedAnwText_${elem.id}`
                                                )
                                                .value.trim().length > 0
                                              ? elem.translatedText
                                              : elem.translatedText
                                              ? elem.translatedText
                                              : 'No translation'
                                            : 'No translation'}
                                        </span>
                                      </p>
                                    </>
                                  ) : null}
                                  {elem.isSelected &&
                                  props.campaignLanguage !== 'English' &&
                                  !elem.isTranslationExist ? (
                                    <>
                                      <input
                                        type="text"
                                        className={`form-control`}
                                        name={`translatedAnwText_${elem.id}`}
                                        id={`translatedAnwText_${elem.id}`}
                                        aria-describedby={`translatedAnwText_${elem.id}`}
                                        placeholder={`Enter new answer (Translated)`}
                                        value={
                                          answers[index] &&
                                          answers[index].translatedText
                                            ? answers[index].translatedText
                                            : ''
                                        }
                                        onChange={e =>
                                          handleAnswerTranslation(
                                            e,
                                            index,
                                            elem
                                          )
                                        }
                                        style={{
                                          width: '94%',
                                          marginLeft: '4.5%',
                                          marginTop: '1%'
                                        }}
                                      />
                                      <span
                                        className={`cw-error d-none`}
                                        id={`errorMessage_translatedAnwText__${elem.id}`}
                                        style={{ marginLeft: '4.5%' }}
                                      >
                                        <i className="fas fa-exclamation-triangle mr-10"></i>
                                        Please enter translated answer master
                                        data
                                      </span>
                                    </>
                                  ) : null}
                                </div>
                                <div className="horizontal-line horizontal-line--li mb-10"></div>
                              </>
                            );
                          })}
                      </>
                    ) : (
                      <>
                        {questions.length > 0 &&
                          tempQuestions.length > 0 &&
                          questions.map((elem, index) => {
                            return (
                              <>
                                <div className="form-check mb-10" key={elem.id}>
                                  <input
                                    type="radio"
                                    className="form-check-input"
                                    id={'question' + elem.id}
                                    name={'question' + elem.id}
                                    value={elem.id}
                                    checked={
                                      questions && questions[index].isSelected
                                    }
                                    onChange={e =>
                                      _handleInputChange(
                                        e,
                                        index,
                                        questions[index]
                                      )
                                    }
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={'question' + elem.id}
                                  >
                                    {elem.text}
                                  </label>
                                  {props.campaignLanguage !== 'English' ? (
                                    <>
                                      <p
                                        style={{
                                          fontSize: '12px',
                                          marginLeft: '31px'
                                        }}
                                      >
                                        <span style={{ color: '#007dbb' }}>
                                          {props.campaignLanguage}:
                                        </span>{' '}
                                        <span>
                                          {elem &&
                                          elem.translatedText &&
                                          elem.translatedText !== undefined &&
                                          elem.translatedText !== ''
                                            ? document.getElementById(
                                                `translatedNewText_${index}`
                                              ) &&
                                              document
                                                .getElementById(
                                                  `translatedNewText_${index}`
                                                )
                                                .value.trim().length > 0
                                              ? elem.translatedText
                                              : elem.translatedText
                                              ? elem.translatedText
                                              : 'No translation'
                                            : 'No translation'}
                                        </span>
                                      </p>
                                      {questions &&
                                      questions[index].isSelected &&
                                      !questions[index].isTranslationExist ? (
                                        <>
                                          <input
                                            type="text"
                                            className={`form-control ${
                                              isTranslationAdded
                                                ? ''
                                                : 'cw-error-focus'
                                            }`}
                                            name={`translatedNewText_${index}`}
                                            id={`translatedNewText_${index}`}
                                            aria-describedby={`translatedNewText_${index}`}
                                            placeholder={`Enter new question (Translated)`}
                                            value={
                                              questions[index] &&
                                              questions[index].translatedText
                                            }
                                            onChange={e =>
                                              handleTranslation(
                                                e,
                                                index,
                                                questions[index]
                                              )
                                            }
                                            style={{
                                              width: '94%',
                                              marginLeft: '4.5%',
                                              marginTop: '1%'
                                            }}
                                          />
                                          <span
                                            className={`cw-error ${
                                              isTranslationAdded
                                                ? 'cw-hidden'
                                                : ''
                                            }`}
                                            id={`errorMessage_${elem.value}_${index}`}
                                            style={{ marginLeft: '4.5%' }}
                                          >
                                            <i className="fas fa-exclamation-triangle mr-10"></i>
                                            Please enter translated question
                                            master data
                                          </span>
                                        </>
                                      ) : null}
                                    </>
                                  ) : null}
                                </div>
                                <div className="horizontal-line horizontal-line--li mb-10"></div>
                              </>
                            );
                          })}
                      </>
                    )}
                  </ul>
                  {questions.length === 0 && (
                    <div className="custom-free-text">
                      <div className="form-group mt-10 mb-5 generic-error-message">
                        <span
                          id="note"
                          className="cw-error"
                          data-control="name"
                        >
                          No results found matching your search criteria
                        </span>
                      </div>
                      <p className="mb-15">
                        You can search using other related keywords or look for
                        an alternate question that fulfills your purpose from
                        our curated master list with readily available master
                        data IDs
                      </p>
                      <h6>
                        <b>
                          If you still can’t find the question you are looking
                          for,
                        </b>{' '}
                        you can request new{' '}
                        <a
                          className="new-question-answer-btn"
                          onClick={() => setNewQuestions(true)}
                        >
                          <u>here</u>
                        </a>{' '}
                        but there will be longer than usual turnaround time due
                        to tighter data governance rules. Ensure you have read
                        our guidelines and are not requesting master data that
                        is duplicate or relates to a PII field.
                      </h6>
                    </div>
                  )}
                  {answers.length === 0 && (
                    <div class="custom-free-text">
                      <div class="form-group mt-10 mb-5 generic-error-message">
                        <span id="note" class="cw-error" data-control="name">
                          No results found matching your search criteria
                        </span>
                      </div>
                      <p class="mb-15">
                        You can search using other related keywords or look for
                        an alternate answer that fulfills your purpose from our
                        curated master list with readily available master data
                        IDs
                      </p>
                      <h6>
                        <b>
                          If you still can’t find the answer you are looking
                          for,
                        </b>{' '}
                        you can request new{' '}
                        <a
                          className="new-question-answer-btn"
                          onClick={() => setNewAnswer(true)}
                        >
                          <u>here</u>
                        </a>{' '}
                        but there will be longer than usual turnaround time due
                        to tighter data governance rules. Ensure you have read
                        our guidelines and are not requesting master data that
                        is duplicate or relates to a PII field.
                      </h6>
                    </div>
                  )}
                </div>
                <div className="qnaNewOverlay">
                  {/* <div className="mt-10 horizontal-line mb-20"></div> */}

                  <div className="devider-top-answer-list">
                    <div>
                      {showQuestionError ? (
                        <span
                          id="selectMasterData"
                          class="cw-error"
                          data-control="name"
                          style={{ display: 'initial' }}
                        >
                          <i class="fas fa-exclamation-triangle mr-10"></i>
                          Please select a question from the master list
                        </span>
                      ) : null}
                      {showAnswerError ? (
                        <span
                          id="selectMasterData"
                          class="cw-error mt-0 mr-4"
                          data-control="name"
                          style={{ display: 'initial' }}
                        >
                          <i class="fas fa-exclamation-triangle mr-10"></i>
                          Please select an answer
                        </span>
                      ) : null}
                    </div>

                    <span className="button-message">
                      {showAnsSuccessMsg && (
                        <p>
                          <i class="fas fa-check-circle"></i> Answer added
                          successfully
                        </p>
                      )}
                      <a
                        className="cw-qna-faq-link"
                        href={constants.QNA_FAQ_LINK}
                        target="_blank"
                      >
                        <span>
                          Q&A FAQs <i className="fas fa-external-link-alt"></i>
                        </span>{' '}
                      </a>
                      <button
                        style={{
                          float: 'right',
                          borderRadius: '8px',
                          marginLeft: '16px'
                        }}
                        type="submit"
                        onClick={addQuestionToList}
                        disabled={
                          (questions.length == 0 &&
                            props.browseType == 'Questions') ||
                          (answers.length == 0 && props.browseType == 'Answers')
                        }
                        className="btn btn-primary mb-0 cw-print-hide text-nowrap  mr-0"
                      >
                        Add
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            {addNewQuestions ? (
              <>
                <div className="add-space">
                  <ReactTooltip
                    place="left"
                    type="info"
                    multiline={true}
                    className="cw-toolip custom-cw-toolip"
                    style={{ width: '70% !important' }}
                  />
                  <div className="request-new" style={{ margin: '2% 2% 0px' }}>
                    <div className="request-new-modal-header-warp">
                      <span
                        className="modal-header-back"
                        onClick={e => backBtnFromAddQue(e)}
                      >
                        <i class="fas fa-arrow-left"></i>
                        <span>Back to questions master list</span>
                      </span>
                      <span
                        className={'qna-guideline-close'}
                        onClick={closeOverlayModal}
                        style={{ right: '-4%' }}
                      ></span>
                    </div>

                    <div className="add-new-question-body">
                      <h4 onClick={() => setNewQuestions(true)}>
                        Request new question
                      </h4>
                      {/* <div className="note-question">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="25"
                          height="25"
                          viewBox="0 0 30 30"
                          fill="none"
                        >
                          <path
                            d="M27 0H3C1.35 0 0.015 1.35 0.015 3L0 30L6 24H27C28.65 24 30 22.65 30 21V3C30 1.35 28.65 0 27 0ZM16.5 13.5H13.5V4.5H16.5V13.5ZM16.5 19.5H13.5V16.5H16.5V19.5Z"
                            fill="#C71F1F"
                            fill-opacity="0.9"
                          />
                        </svg>
                        &nbsp; Important Note:
                      </div> */}
                      <div className="addnew-question-text">
                        <h6>Can’t find the question you are looking for?</h6>
                        <ReactTooltip
                          place="right"
                          id="duplicateText"
                          type="info"
                          multiline={true}
                          className="cw-tooltip custom-cw-toolip"
                        />
                        <p>
                          You can request new but there will be longer than
                          usual turnaround time due to tighter data governance
                          rules. Ensure you have read our guidelines and are not
                          requesting master data that is&nbsp;
                          <a
                            className="new-question-answer-btn"
                            data-for="duplicateText"
                            data-tip={constants.NEW_QNA_DUPLICATE_TEXT_TOOLTIP}
                          >
                            duplicate
                          </a>{' '}
                          or relates to a{' '}
                          <ReactTooltip
                            place="right"
                            id="PIIquestion"
                            type="info"
                            multiline={true}
                            className="cw-tooltip"
                          />
                          <a
                            className="new-question-answer-btn"
                            data-for="PIIquestion"
                            data-tip={constants.PII_FIELDS_DESCRIPTION}
                          >
                            PII field
                          </a>
                          .
                        </p>
                      </div>
                    </div>
                    <div className="form-controler mb-20">
                      <div className="form-group">
                        <input
                          value={masterDataQue.englishLabel}
                          type="text"
                          name="englishLabel"
                          id="english"
                          className="form-control"
                          data-required="true"
                          placeholder="Enter new question (English)"
                          onChange={handleChangeInput}
                        />
                        <div className="sufix">
                          <img
                            src={groupIcon}
                            alt={groupIcon}
                            width="25"
                            height="25"
                          />{' '}
                          New Master
                        </div>
                      </div>

                      <span
                        id="englishLabel"
                        class="cw-error d-none"
                        data-control="name"
                      >
                        <i class="fas fa-exclamation-triangle mr-10"></i>
                        Please enter a short question name
                      </span>
                      {props.campaignLanguage !== 'English' ? (
                        <>
                          <div className="form-group">
                            <input
                              value={masterDataQue.translatedLabel}
                              type="text"
                              name="translatedLabel"
                              id="translated"
                              className="form-control"
                              data-required="true"
                              placeholder="Enter new question (Translated)"
                              onChange={handleChangeInput}
                            />
                            <div className="sufix">
                              <img
                                src={groupIcon}
                                alt={groupIcon}
                                width="25"
                                height="25"
                              />{' '}
                              New Master
                            </div>
                          </div>
                          <span
                            id="translatedLabel"
                            class="cw-error d-none"
                            data-control="name"
                          >
                            <i class="fas fa-exclamation-triangle mr-10"></i>
                            Please enter a short translated question name
                          </span>
                        </>
                      ) : null}

                      <div className="mb-20">
                        <div
                          className="form-group mt-10 mr-3 saas-profile-select saas-profile-select--qna-new-design mb-20"
                          id="add-questionField"
                        >
                          <Select
                            id="reason"
                            className={
                              'cw-select--custom cw-color--primary ' +
                              (masterDataQue.reasonForCreation ? 'addded' : '')
                            }
                            placeholder={'Reason for new request'}
                            options={questionTypes}
                            onChange={handleSelectData}
                            value={questionTypes.find(
                              e => e.value == masterDataQue.reasonForCreation
                            )}
                          />
                          <span
                            className="float-right  cw-icon cw-icon--help"
                            data-tip="Mark your question request as “enrichment” if this data will help Unilever improve its understanding of a consumer to enrich or segment audience such as consumer interests, needs, preferences, usage of products, their benefits, etc. <br/> Else, mark your question as “operational” if it intends to help ensure the campaign works or to fulfill consumer requests, such as receipts, purchase date, SKUs, etc."
                            currentitem="false"
                          >
                            <i className="fas fa-question-circle"></i>
                          </span>
                        </div>
                        <span
                          id="ReasonForMasterdata"
                          class="cw-error d-none"
                          data-control="name"
                        >
                          <i class="fas fa-exclamation-triangle mr-10"></i>
                          Please select reason for new master data request
                        </span>
                        <div className="custon-alert-msg mt-10 mb-10">
                          <Alert
                            alertType="warningMessageWithIcon"
                            showComponent={freeTextWarning}
                            textClass={`cw-qna-faq-link`}
                            textMessage={constants.FREE_TEXT_ENRICH_WARNING}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="devider-top">
                    <span>
                      <button
                        style={{ borderRadius: '8px' }}
                        type="submit"
                        onClick={handleSubmit}
                        className="btn btn-primary mb-10 cw-print-hide text-nowrap  mr-1"
                      >
                        Add
                      </button>
                    </span>
                  </div>
                </div>
              </>
            ) : addNewAnswer ? (
              <>
                <div className="qna-guidline-btn-wrap-2">
                  <div
                    className="answer-section"
                    style={{ margin: '2%', marginBottom: '0' }}
                  >
                    <ReactTooltip
                      place="left"
                      type="info"
                      multiline={true}
                      className="cw-toolip custom-cw-toolip"
                      style={{ width: '70% !important' }}
                    />
                    <div className="request-new-modal-header">
                      <span
                        className="modal-header-back"
                        onClick={e => backBtnFromAddAnswer(e)}
                      >
                        <i class="fas fa-arrow-left"></i>
                        <span>Back to answers master list</span>
                      </span>
                      <span
                        className={'qna-guideline-close'}
                        onClick={closeOverlayModal}
                        style={{ right: '-4%' }}
                      ></span>
                    </div>
                    <div className="add-new-question-body">
                      <h4 onClick={() => setNewAnswer(true)}>
                        Request new answer
                      </h4>
                      {/* <div className="note-question">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="25"
                          height="25"
                          viewBox="0 0 30 30"
                          fill="none"
                        >
                          <path
                            d="M27 0H3C1.35 0 0.015 1.35 0.015 3L0 30L6 24H27C28.65 24 30 22.65 30 21V3C30 1.35 28.65 0 27 0ZM16.5 13.5H13.5V4.5H16.5V13.5ZM16.5 19.5H13.5V16.5H16.5V19.5Z"
                            fill="#C71F1F"
                            fill-opacity="0.9"
                          />
                        </svg>
                        &nbsp; Important Note:
                      </div> */}
                      <div className="addnew-Answer-text">
                        <h6>
                          <b>Can’t find the answer you are looking for?</b>
                        </h6>
                        <p>
                          You can request new but there will be a longer than
                          usual turnaround time due to tighter data governance
                          rules. Ensure you have read our guidelines and are not
                          requesting master data that is&nbsp;
                          <ReactTooltip
                            place="right"
                            id="duplicateTextAns"
                            type="info"
                            multiline={true}
                            className="cw-tooltip custom-cw-toolip"
                          />
                          <a
                            className="new-question-answer-btn"
                            data-for="duplicateTextAns"
                            data-tip={constants.NEW_QNA_DUPLICATE_TEXT_TOOLTIP}
                          >
                            duplicate
                          </a>{' '}
                          or relates to a{' '}
                          <ReactTooltip
                            place="right"
                            id="PIIanswer"
                            type="info"
                            multiline={true}
                            className="cw-tooltip"
                          />
                          <a
                            className="new-question-answer-btn"
                            data-for="PIIanswer"
                            data-tip={constants.PII_FIELDS_DESCRIPTION}
                          >
                            PII field
                          </a>
                          .
                        </p>
                      </div>
                    </div>
                    <div className="form-controler mb-30">
                      <div className="form-group">
                        <input
                          value={masterDataAns.englishLabelAns}
                          type="text"
                          name="englishLabelAns"
                          id="englishLabelAns"
                          className="form-control"
                          data-required="true"
                          placeholder="Enter new answer (English)"
                          onChange={handleChangeInputEngAns}
                        />
                        <div className="sufix">
                          <img
                            src={groupIcon}
                            alt={groupIcon}
                            width="25"
                            height="25"
                          />{' '}
                          New Master
                        </div>
                      </div>

                      <span
                        id="englishLabelAns_err"
                        class="cw-error d-none"
                        data-control="name"
                      >
                        <i class="fas fa-exclamation-triangle mr-10"></i>
                        Please enter the new answer
                      </span>
                      {props.campaignLanguage !== 'English' ? (
                        <>
                          <div className="form-group">
                            <input
                              value={masterDataAns.translatedLabelAns}
                              type="text"
                              name="translatedLabelAns"
                              id="translatedLabelAns"
                              className="form-control"
                              data-required="true"
                              placeholder="Enter new answer (Translated)"
                              onChange={handleChangeInputEngAns}
                            />
                            <div className="sufix">
                              <img
                                src={groupIcon}
                                alt={groupIcon}
                                width="25"
                                height="25"
                              />{' '}
                              New Master
                            </div>
                          </div>
                          <span
                            id="translatedLabelAns_err"
                            class="cw-error d-none"
                            data-control="name"
                          >
                            <i class="fas fa-exclamation-triangle mr-10"></i>
                            Please enter the translated answer master data
                          </span>
                        </>
                      ) : null}
                    </div>
                  </div>
                  <div className="devider-top-answer">
                    {showAnsSuccessMsg && (
                      <p>
                        <i class="fas fa-check-circle"></i> Answer added
                        successfully
                      </p>
                    )}
                    <span>
                      <button
                        style={{ borderRadius: '8px' }}
                        type="submit"
                        onClick={handleSubmitAnswer}
                        className="btn btn-primary cw-print-hide text-nowrap  mr-1"
                      >
                        Add
                      </button>
                    </span>
                  </div>
                </div>
              </>
            ) : (
              ''
            )}
          </>
        )}
      </Modal>
    </>
  );
};

export default QuestionAnswerNewOverlay;
