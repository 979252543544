import React, { forwardRef } from 'react';
import classNames from 'classnames';

export const Input = forwardRef(
  ({ id, name, type, label, placeholder, hasError, disableStyleClass, ...props }, ref) => {
    return (
      <input
        {...props}
        className={disableStyleClass? "": classNames('form-control', { 'is-invalid': hasError })}
        id={id}
        ref={ref}
        name={name}
        type={type}
        aria-label={label}
        placeholder={placeholder}
        maxLength={props.maxLength || null}
        readOnly={props.readOnly? true : false}
      />
    );
  }
);
