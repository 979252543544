import * as yup from 'yup';
import constants from 'constants/config';
import {
  EmailTriggerInput,
  DisplayEmailTriggerInput
} from 'components/molecules/EmailTriggerInput';
import {
  SendEmailToInput,
  DisplaySendEmailToInput
} from 'components/molecules/SendEmailToInput';
import { getYupSchema } from 'selectors';
import moment from 'moment';

const SummarySchema = (onSubmit, formData, onFormStateChange, onDraft) => {
  console.log('formData====>', formData);
  return {
    formType: 'simple',
    submitBtnLabel: 'Save',
    fields: [
      {
        type: 'text',
        name: 'campaignName',
        id: 'campaignName',
        label: 'Campaign name',
        defaultValue: formData.campaignName,
        rules: yup
          .string()
          .trim()
          .required('Please enter campaign name')
      },
      {
        type: 'textarea',
        name: 'campaignDescription',
        id: 'campaignDescription',
        label: 'Campaign description',
        defaultValue: formData.campaignDescription,
        rules: yup
          .string()
          .trim()
          .required('Please enter campaign description'),
        maxLength: '100'
      },
      {
        type: 'reactselect',
        name: 'emailContentTheme',
        id: 'emailContentTheme',
        label: 'Email content theme',
        placeholder: 'Please select',
        rules: yup
          .object()
          .shape({
            label: yup.string().required(),
            value: yup.string().required()
          })
          .required('Please select email content theme')
          .default(undefined),
        defaultValue: formData.emailContentTheme,
        options: [
          {
            label: 'Parenting & Family',
            value: 'Parenting & Family'
          },
          {
            label: 'Planet, Nature and Society',
            value: 'Planet, Nature and Society'
          },
          {
            label: 'Home & Hygiene',
            value: 'Home & Hygiene'
          },
          {
            label: 'Food & Recipes',
            value: 'Food & Recipes'
          },
          {
            label: 'Beauty & Styling',
            value: 'Beauty & Styling'
          },
          {
            label: 'Wellness, Nutrition and Active Lifestyle',
            value: 'Wellness, Nutrition and Active Lifestyle'
          }
        ]
      },
      {
        type: 'textarea',
        name: 'additionalInformation',
        id: 'additionalInformation',
        label: 'Additional information',
        placeholder: 'Optional',
        isOptional: true,
        defaultValue: formData.additionalInformation,
        rules: yup
          .string()
          .trim()
          .max(100, 'Max 100 characters are allowed'),
        maxLength: '100'
      }
    ],
    onSubmit: onSubmit,
    onDraft: onDraft,
    draftText: 'Save draft and complete later',
    onFormStateChange: onFormStateChange,
    disableFormError: true,
    getSchema() {
      if (this.fields) {
        return getYupSchema(this.fields);
      }
      return null;
    }
  };
};

const deliveryScheduleSchema = (
  onSubmit,
  formData,
  onFormStateChange,
  onDraft,
  emailType
) => {
  return {
    formType: 'simple',
    submitBtnLabel: 'Save',
    fields: [
      {
        type: 'custom',
        name: 'emailTrigger',
        component: EmailTriggerInput,
        displayComponent: DisplayEmailTriggerInput,
        id: 'emailTrigger',
        label: 'Email trigger',
        dateFormat: constants.FORMAT_DATE_DD_MM_YY,
        timeFormat: true,
        defaultValue: formData.emailTrigger,
        emailType: emailType,
        rules: yup
          .object({
            type: yup
              .string()
              .required('Please select email trigger')
              .nullable(),
            details: yup
              .object()
              .when('type', {
                is: 'fixedDateAndtime',
                then: yup.object({
                  emailDeliveryDate: yup
                    .string()
                    .test(
                      'isNotPastDate',
                      'Please select a future date',
                      dateString => {
                        return moment(dateString).isSameOrAfter(
                          moment(),
                          'day'
                        );
                      }
                    )
                    .required('Please enter email delivery date'),
                  emailDeliveryTime: yup
                    .string()
                    .trim()
                    .test(
                      'isDateStringParsable',
                      'Please enter a valid time',
                      dateString => {
                        return moment(dateString).isValid();
                      }
                    )
                    .required('Please enter email delivery time')
                })
              })
              .when('type', {
                is: 'dynamicTrigger',
                then: yup.object({
                  triggerCondition: yup
                    .object()
                    .shape({
                      label: yup.string().required(),
                      value: yup.string().required()
                    })
                    .required('Please select trigger condition')
                    .default(undefined)
                })
              })
          })
          .required('Please select email trigger')
          .default({ type: undefined, details: undefined })
      },
      {
        type: 'textarea',
        name: 'additionalInformation',
        id: 'additionalInformation',
        label: 'Additional information',
        placeholder: 'Optional',
        isOptional: true,
        defaultValue: formData.additionalInformation,
        rules: yup
          .string()
          .trim()
          .max(100, 'Max 100 characters are allowed'),
        maxLength: '100'
      }
    ],
    onDraft: onDraft,
    draftText: 'Save draft and complete later',
    onSubmit: onSubmit,
    onFormStateChange: onFormStateChange,
    disableFormError: true,
    getSchema() {
      if (this.fields) {
        return getYupSchema(this.fields);
      }
      return null;
    }
  };
};

const audienceDefinitionSchema = (
  onSubmit,
  formData,
  onFormStateChange,
  onDraft
) => {
  return {
    formType: 'simple',
    submitBtnLabel: 'Save',
    fields: [
      {
        type: 'custom',
        name: 'sendEmailTo',
        component: SendEmailToInput,
        displayComponent: DisplaySendEmailToInput,
        id: 'sendEmailTo',
        label: 'Send email to',
        defaultValue: formData.sendEmailTo,
        options: [
          {
            label: 'All consented contactable consumers',
            value: 'allConsentedContactableConsumers'
          },
          {
            label: 'Consumers linked to Campaign ID(s)',
            value: 'consumersLinkedToCampaignIds'
          },
          {
            label: 'Custom criteria',
            value: 'customCriteria'
          }
        ],
        placeholder: 'Please select',
        rules: yup
          .object({
            type: yup
              .object({
                label: yup.string(),
                value: yup.string()
              })
              .required('Please select send email to')
              .default(undefined),
            details: yup
              .object()
              .when('type', {
                is: data =>
                  data && data.value === 'consumersLinkedToCampaignIds',
                then: yup.object({
                  campaignIds: yup
                    .array()
                    .of(
                      yup.object({
                        value: yup
                          .string()
                          .required('Please enter campaign ID')
                          .matches(
                            constants.regex.campaignId,
                            'Please enter a valid Campaign ID (example CN123456 or PN123456)'
                          )
                      })
                    )
                    .min(1, 'Please enter at least one campaign ID')
                })
              })
              .when('type', {
                is: data => data && data.value === 'customCriteria',
                then: yup.object({
                  customCriteriaDetails: yup
                    .string()
                    .trim()
                    .max(100, 'Max 100 characters are allowed')
                    .required('Please enter custom criteria')
                })
              })
          })
          .required('Please select send email to')
          .default({ type: undefined, details: undefined })
      },
      {
        type: 'textarea',
        name: 'additionalInformation',
        id: 'additionalInformation',
        label: 'Additional information',
        placeholder: 'Optional',
        isOptional: true,
        defaultValue: formData.additionalInformation,
        rules: yup
          .string()
          .trim()
          .max(100, 'Max 100 characters are allowed'),
        maxLength: '100'
      }
    ],
    onDraft: onDraft,
    draftText: 'Save draft and complete later',
    onSubmit: onSubmit,
    onFormStateChange: onFormStateChange,
    disableFormError: true,
    getSchema() {
      if (this.fields) {
        return getYupSchema(this.fields);
      }
      return null;
    }
  };
};

export { SummarySchema, deliveryScheduleSchema, audienceDefinitionSchema };
