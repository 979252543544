import constants from 'constants/config';
import React from 'react';
import GuidelineWrapper from './atoms/GuidelineWrapper/GuidelineWrapper';
import GuideLines from './GuideLines';

const QnaGuideline = props => {
  return (
    <GuidelineWrapper onClose={props.closeIsEditModal}>
      <div className="qna-guideline-scroll">
        <GuideLines
          data={constants.QNA_GUIDE_LINES}
          qnaIndex={true}
          noLineBreak={true}
        />
        <div className="horizontal-line"></div>

        <GuideLines data={constants.QNA_DEFINITIONS} qnaIndex={true} />
        <div className="horizontal-line"></div>
      </div>
    </GuidelineWrapper>
  );
};

export default QnaGuideline;
