import React from 'react';
import { getEmailStatusClassName } from 'components/atoms/EmailStatus/EmailStatus';
import constants from '../../../constants/config';

export default function PageHeader({
  heading = '',
  subHeading = '',
  status,
  form,
  review
}) {
  const statusClassName = getEmailStatusClassName(status, {
    inPageHeader: true
  });

  return (
    <div
      className={`cw-section--title ${
        review ? 'mb-10' : form ? 'mb-30' : 'mb-40'
      }`}
    >
      <h2
        className={`cw-heading--secondary ${review ? '' : 'cw-email-heading'}`}
      >
        <span className="cw-print-hide campaign-break-word">{heading}</span>
        {status ? (
          <span
            className={`${statusClassName} cw-print-hide status_badge_reposition_overview`}
            style={{ maxWidth: 200 }}
          >
            {constants.emailStatus[status]}
          </span>
        ) : null}
      </h2>
      <span>{subHeading ? <p>{subHeading}</p> : null}</span>
    </div>
  );
}
