import * as yup from 'yup';
import constants from 'constants/config';
import {
  getEmailCampaignDetails,
  updateEmailCampaignDetails
} from 'actions/emailbriefing/emailCampaignDetails';
import { getYupSchema, transformEmailBriefingPayload } from 'selectors';
import moment from 'moment';
import {
  EmailTriggerInput,
  DisplayEmailTriggerInput
} from 'components/molecules/EmailTriggerInput';
import { SendFollowUpEmailInput } from 'components/molecules/SendFollowUpEmailInput';

export const dateTimeSchema = isABTestOnSendTime => {
  if (isABTestOnSendTime) {
    return {
      emailDeliveryDateVariantA: yup
        .string()
        .test(
          'isDateStringParsable',
          'Please enter a valid date',
          dateString => {
            return (
              moment(dateString, 'DD/MM/YYYY', true).isValid() ||
              moment(
                String(dateString).slice(0, 33),
                'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ',
                true
              ).isValid()
            );
          }
        )
        .test('isNotPastDate', 'Please select a future date', dateString => {
          return moment(dateString).isSameOrAfter(moment(), 'day');
        })
        .required('Please enter email delivery date for variant A'),
      emailDeliveryDateVariantB: yup
        .string()
        .test(
          'isDateStringParsable',
          'Please enter a valid date',
          dateString => {
            return (
              moment(dateString, 'DD/MM/YYYY', true).isValid() ||
              moment(
                String(dateString).slice(0, 33),
                'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ',
                true
              ).isValid()
            );
          }
        )
        .test('isNotPastDate', 'Please select a future date', dateString => {
          return moment(dateString).isSameOrAfter(moment(), 'day');
        })
        .required('Please enter email delivery date for variant B'),
      emailDeliveryTimeVariantA: yup
        .string()
        .trim()
        .test(
          'isDateStringParsable',
          'Please enter a valid time',
          dateString => {
            return moment(dateString).isValid();
          }
        )
        .required('Please enter email delivery time for variant A'),
      emailDeliveryTimeVariantB: yup
        .string()
        .trim()
        .test(
          'isDateStringParsable',
          'Please enter a valid time',
          dateString => {
            return moment(dateString).isValid();
          }
        )
        .required('Please enter email delivery time for variant B')
    };
  } else {
    return {
      emailDeliveryDate: yup
        .string()
        .test(
          'isDateStringParsable',
          'Please enter a valid date',
          dateString => {
            return (
              moment(dateString, 'DD/MM/YYYY', true).isValid() ||
              moment(
                String(dateString).slice(0, 33),
                'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ',
                true
              ).isValid()
            );
          }
        )
        .test('isNotPastDate', 'Please select a future date', dateString => {
          return moment(dateString).isSameOrAfter(moment(), 'day');
        })
        .required('Please enter email delivery date'),
      emailDeliveryTime: yup
        .string()
        .trim()
        .test(
          'isDateStringParsable',
          'Please enter a valid time',
          dateString => {
            return moment(dateString).isValid();
          }
        )
        .required('Please enter email delivery time')
    };
  }
};

const ScheduledSchema = (
  dispatch,
  { defaultValues = {}, match, history, emailDesign, emailType, abTesting }
) => {
  const { performABTests, noOfABVariants, abTestElements } = abTesting || {};
  const isABTestOnSendTime = performABTests && abTestElements === 'sendTime';

  const isEdited =
    history &&
    history.location &&
    history.location.state &&
    history.location.state.isEdited;

  const onSubmit = (data, { user }, { backToReview } = {}) => {
    // Remove below code if optimizeSendTime is made a checkbox again
    if (
      data?.emailTrigger?.type === 'fixedDateAndtime' &&
      abTesting.abTestElements !== 'sendTime'
    ) {
      data.emailTrigger.details.optimizeSendTime = true;
    }

    if (data?.emailTrigger?.details?.triggerCondition?.value !== 'other') {
      delete data.emailTrigger.details.otherTriggerDetails;
    }

    const paramUid = match.params.id;
    const dataToSend = {
      userEmail: user.emailId,
      userName: user.name,
      deliverySchedule: data
    };

    const transformedData = transformEmailBriefingPayload(
      dataToSend,
      'emailSchedule'
    );

    dispatch(
      updateEmailCampaignDetails(paramUid, transformedData, (error, result) => {
        if (result) {
          const uid = match.params.id;
          if (isEdited && backToReview) {
            const status = emailDesign?.emailBriefing?.status;
            if (status === constants.EMAIL_STATUS.AWAITING_FOR_APPROVAL) {
              history.push(`/campaign-wizard/emailbriefing/${uid}/approval`);
            } else if (
              status === constants.EMAIL_STATUS.DRAFT ||
              status === constants.EMAIL_STATUS.REJECTED
            ) {
              history.push(`/campaign-wizard/emailbriefing/${uid}/review`);
            }
          } else {
            history.push(
              `/campaign-wizard/emailbriefing/${uid}/email-audience`
            );
          }
        }
        if (error) {
          console.log('OnSubmit error: ', error);
        }
      })
    );
  };

  return {
    formType: 'custombuttons',
    submitBtnLabel: isEdited ? 'Save and back to review' : 'Next',
    onSecondaryClick: onSubmit,
    onSubmitSecondary: () => {},
    isEdit: isEdited,
    secondaryBtnLabel: 'Next',
    fields: [
      {
        type: 'custom',
        name: 'emailTrigger',
        component: EmailTriggerInput,
        displayComponent: DisplayEmailTriggerInput,
        id: 'emailTrigger',
        label: 'Email trigger',
        dateFormat: constants.FORMAT_DATE_DD_MM_YY,
        timeFormat: true,
        defaultValue: defaultValues.emailTrigger,
        emailType: emailType,
        abTesting,
        rules: yup
          .object({
            type: yup
              .string()
              .required('Please select email trigger')
              .nullable(),
            details: yup
              .object()
              .when('type', {
                is: 'fixedDateAndtime',
                then: yup.object(dateTimeSchema(isABTestOnSendTime))
              })
              .when('type', {
                is: 'dynamicTrigger',
                then: yup.object({
                  triggerCondition: yup
                    .object()
                    .shape({
                      label: yup.string().required(),
                      value: yup.string().required()
                    })
                    .required('Please select trigger condition')
                    .default(undefined),
                  triggerConditionStartDate: yup
                    .string()
                    .when('triggerCondition', {
                      is: triggerCondition =>
                        triggerCondition &&
                        triggerCondition.value != 'fixedDateInBirthdayMonth',
                      then: schema =>
                        schema
                          .test(
                            'isDateStringParsable',
                            'Please enter a valid date',
                            dateString => {
                              return (
                                moment(
                                  dateString,
                                  'DD/MM/YYYY',
                                  true
                                ).isValid() ||
                                moment(
                                  dateString,
                                  'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ',
                                  true
                                ).isValid()
                              );
                            }
                          )
                          .test(
                            'isNotPastDate',
                            'Please select a future date',
                            dateString => {
                              return moment(dateString, [
                                'DD/MM/YYYY',
                                moment.ISO_8601,
                                'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ'
                              ]).isSameOrAfter(moment(), 'day');
                            }
                          )
                          .required('Please enter start date')
                    }),
                  triggerConditionEndDate: yup
                    .string()
                    .when(['triggerConditionAlwaysOn', 'triggerCondition'], {
                      is: (triggerConditionAlwaysOn, triggerCondition) => {
                        return triggerCondition &&
                          triggerCondition.value === 'fixedDateInBirthdayMonth'
                          ? false
                          : triggerConditionAlwaysOn
                          ? false
                          : true;
                      },
                      then: schema =>
                        schema
                          .test(
                            'isDateStringParsable',
                            'Please enter a valid date',
                            dateString => {
                              return (
                                moment(
                                  dateString,
                                  'DD/MM/YYYY',
                                  true
                                ).isValid() ||
                                moment(
                                  dateString,
                                  'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ',
                                  true
                                ).isValid()
                              );
                            }
                          )
                          .test(
                            'isNotPastDate',
                            'Please select a future date',
                            dateString => {
                              return moment(dateString, [
                                'DD/MM/YYYY',
                                moment.ISO_8601,
                                'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ'
                              ]).isSameOrAfter(moment(), 'day');
                            }
                          )
                          .required('Please enter end date'),
                      otherwise: schema => schema.notRequired()
                    }),
                  otherTriggerDetails: yup
                    .string()
                    .trim()
                    .nullable()
                    .when('triggerCondition', {
                      is: triggerCondition =>
                        triggerCondition && triggerCondition.value === 'other',
                      then: yup
                        .string()
                        .required('Please specify trigger condition'),
                      otherwise: yup.string().nullable()
                    }),
                  triggerConditionBirthdayDate: yup
                    .object()
                    .shape({
                      label: yup.string().required(),
                      value: yup.string().required()
                    })
                    .default(undefined)
                    .when('triggerCondition', {
                      is: triggerCondition =>
                        triggerCondition &&
                        triggerCondition.value === 'fixedDateInBirthdayMonth',
                      then: yup.object().required('Please select date'),
                      otherwise: yup.object().nullable()
                    })
                })
              })
          })
          .required('Please select email trigger')
          .default({ type: undefined, details: undefined })
      },
      {
        type: 'custom',
        name: 'sendFollowUpEmail',
        component: SendFollowUpEmailInput,
        id: 'sendFollowUpEmail',
        label: 'Send follow up email',
        dateFormat: constants.FORMAT_DATE_DD_MM_YY,
        timeFormat: true,
        defaultValue: defaultValues
      },
      {
        type: 'textarea',
        name: 'additionalInformation',
        id: 'additionalInformation',
        label: 'Additional information',
        placeholder: 'Optional',
        isOptional: true,
        defaultValue: defaultValues.additionalInformation,
        rules: yup
          .string()
          .trim()
          .max(300, 'Max 300 characters are allowed'),
        maxLength: '300'
      }
    ],
    onSubmit: (data, { user }) =>
      onSubmit(data, { user }, { backToReview: isEdited }),

    onDraft: (data, validationSchema, { user }) => {
      const paramUid = match.params.id;

      if (
        data?.emailTrigger?.details?.triggerCondition?.value !== 'other' &&
        data?.emailTrigger?.details?.otherTriggerDetails
      ) {
        delete data.emailTrigger.details.otherTriggerDetails;
      }

      const dataToSend = {
        userEmail: user.emailId,
        userName: user.name,
        deliverySchedule: data,
        status: emailDesign?.emailBriefing?.status === '8' ? '8' : '1'
      };

      const transformedData = transformEmailBriefingPayload(
        dataToSend,
        'emailSchedule'
      );

      dispatch(
        updateEmailCampaignDetails(
          paramUid,
          transformedData,
          (error, result) => {
            if (result) {
              const uid = match.params.id;
              history.push(`/campaign-wizard/email-dashboard`);
            }
            if (error) {
              console.log('OnSubmit error: ', error);
            }
          },
          { isSaveAsDraft: true }
        )
      );
    },
    onPrevious: () => {
      const uid = match.params.id;
      history.push(`/campaign-wizard/emailbriefing/${uid}/email-attributes`);
    },
    getSchema() {
      if (this.fields) {
        return getYupSchema(this.fields);
      }
      return null;
    },
    previousBtnLabel: 'Back',
    draftText: 'Save and exit',
    formErrorMessage:
      'There was a problem with the form. Errors are listed below'
  };
};

export default ScheduledSchema;
