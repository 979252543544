import React, { useState } from 'react';
import Modal from 'react-modal';
import { FormBuilder } from '../../components/organisms/FormBuilder';
import { useDispatch } from 'react-redux';
import releaseUpdateFormSchema from './releaseUpdateFormSchema';
import {
    addReleaseNoteData,
    editReleaseNoteData
  } from '../../actions/user-management';
import { isEmpty } from 'lodash';

const CustomButtons = ({
    isSubmitting,
    onPrevious,
    previousBtnLabel,
    submitBtnLabel,
    onSecondaryClick,
    onSubmitSecondary,
    secondaryBtnLabel,
    isEdit
  }) => {
    return isEdit ? (
      <div className="btn-wrapper">
      <button
        type="button"
        className="btn btn-outline-primary mr-10"
        onClick={onPrevious}
        disabled={isSubmitting}
      >
        {previousBtnLabel || 'Cancel'}
      </button>
      <button type="submit" className="btn btn-primary"  disabled={isSubmitting}>
      {isSubmitting ? 'Loading....' : submitBtnLabel || 'Submit'}
      </button>
    </div>
    ) : (
        <div className="btn-wrapper">
            <button
              type="button"
              className="btn btn-outline-primary mr-10"
              onClick={onPrevious}
              disabled={isSubmitting}
            >
              {previousBtnLabel || 'Cancel'}
            </button>
            <button type="submit" className="btn btn-primary"  disabled={isSubmitting}>
            {isSubmitting ? 'Loading....' : submitBtnLabel || 'Submit'}
            </button>
          </div>
    );
  };

const ReleaseUpdateOverlay = ({
  isOpen,
  onCloseModal,
  selectedReleaseNote = {},
  user,
  handleEditClose
}) => {
 const dispatch = useDispatch()
 const isEdit = !isEmpty(selectedReleaseNote);
  Modal.setAppElement('#root');
  
  const handleSubmit = (values) => {
    const formElements = {
        ...values,
        group: 'admin',
        published: true,
    }
    if(isEdit){
        dispatch(editReleaseNoteData(selectedReleaseNote._id, formElements)).then(response => {
            if (response.status === 200) {
                handleEditClose(true)
            } else {
    
            }
          });
    } else {
        dispatch(addReleaseNoteData(formElements)).then(response => {
            if (response.status === 200) {
                onCloseModal(true)
            } else {
    
            }
          });
    }
    
  }
  return (
    <Modal
          isOpen={isOpen}
          onRequestClose={isEdit? () => handleEditClose(false) : () => onCloseModal(false)}
          className="cw-modal cw-modal--release-note"
          contentLabel="addReleaseNote"
        >
          <button
            className="cw-modal--close"
            title="Close"
            onClick={isEdit? () => handleEditClose(false) : () => onCloseModal(false)}
          >
            <i className="fas fa-times"></i>
          </button>
          <h3 className="cw-heading--primary mb-10">{isEdit? 'Edit' : 'Add'} release update</h3>
          <p className="mb-20 cw-modal--release-note__sub-heading">
            Please enter any release updates or announcements. These will be
            published on the CW{' '}
            <a href="/campaign-wizard/releasenotes">release updates</a> page.
          </p>
          <div className="cw-release-note-content">
            <FormBuilder
              schema={releaseUpdateFormSchema(
                  isEdit? handleEditClose : onCloseModal,
                handleSubmit,
                selectedReleaseNote
              )}
              user={user}
              isInModal
              isEdit
              customButtonsComp={CustomButtons}
            />
          </div>
        </Modal>
  );
};

export default ReleaseUpdateOverlay;
