import React from 'react';
import Modal from 'react-modal';
import constants from 'constants/config';

import EmailDesignChecklist from '../EmailDesignChecklist/EmailDesignChecklist';

export default function EmailDesignChecklistModal({ isOpen, onClose }) {
  return (
    <Modal
      scrollable={true}
      isOpen={isOpen}
      onRequestClose={onClose}
      className="cw-modal cw-modal--email-checklist-modal"
      contentLabel="Campaign Wizard 2.0"
      style={{
        overlay: {
          backgroundColor: constants.EMAIL_OVERLAY_BACKGROUND
        }
      }}
    >
      <EmailDesignChecklist onClose={onClose} />
    </Modal>
  );
}
