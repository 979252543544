import React, { useState } from 'react';
import EmailTemplateThumbnail from 'components/molecules/EmailTemplateThumbnail';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import Pagination from 'components/Pagination';
import EmailTemplateRow from 'components/molecules/EmailTemplateRow';
import { useDispatch, useSelector } from 'react-redux';
import { deleteEmailRow, regenerateRowThumbnail } from 'actions/emailbriefing/emailCoreRaws';
import Loader from 'components/Loader';
import { checkIfUserIsRequestorOrApprover } from 'selectors';
import DeleteRowModal from 'pages/EmailCollection/deleteRowModal';
const EmailTemplateRawList = ({
  emailCoreRaws,
  userEmail,
  userName,
  onReset,
  total,
  recordShowStart,
  recordShowto,
  showLoader,
  page,
  limit,
  onPageChange,
  handleTemplatePreview,
}) => {
  const [isDeleteRowOpen, setIsDeleteRowOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const { authorizedUserData } = useSelector(state => state.authorized);
  const isUserRequestorOrApprover = checkIfUserIsRequestorOrApprover(authorizedUserData);

  const dispatch = useDispatch();
  const handleDeleteRow = (rowId) => {
    setSelectedRow(rowId);
    setIsDeleteRowOpen(true);
  }
  const closeDeleteRow = () => {
    setSelectedRow(null);
    setIsDeleteRowOpen(false);
  }

  const handleDeleteRowConfirm = () => {
    if (selectedRow) {
      dispatch(deleteEmailRow(selectedRow, () => {
        setSelectedRow(null);
        setIsDeleteRowOpen(false);
      }));
    }
  }
  return (
    <div className="email-template-list">
      <p className="mb-3">
        {/* {emailCoreRaws.data &&
        emailCoreRaws.data.data &&
        emailCoreRaws.data.data.length === 0 &&
        isNaN(recordShowStart) ? (
          <span className="no-record-title">No matching components found</span>
        ) : (
          `Showing ${recordShowto - recordShowStart + 1} of ${total} components`
        )} */}
      </p>
      {emailCoreRaws.data &&
        emailCoreRaws.data.data &&
        emailCoreRaws.data.data.length === 0 ? '': (
          <>
            <ResponsiveMasonry
              columnsCountBreakPoints={{ 991: 2, 1023: 2, 1200: 2 }}
            >
              <Masonry gutter={'15px'}>
                {emailCoreRaws.data &&
                  emailCoreRaws.data.data.map(template => {
                    return (
                      <EmailTemplateRow
                        key={template._id}
                        type="tile"
                        template={template}
                        userEmail={userEmail}
                        userName={userName}
                        handleTemplatePreview={handleTemplatePreview}
                        handleDeleteRow={handleDeleteRow}
                      />
                    );
                  })}
              </Masonry>
            </ResponsiveMasonry>
            {showLoader || isNaN(recordShowStart) ? (
              <span>&nbsp;</span>
            ) : (
              <Pagination
                itemLabel="tiles"
                pageNumber={Number(page)}
                totalRecords={total}
                limit={limit}
                recordShowStart={recordShowStart}
                recordShowto={recordShowto}
                onPageChange={onPageChange}
              />
            )}
          </>
        )}
        <DeleteRowModal 
          isOpen={isDeleteRowOpen}
          handleCloseModal={closeDeleteRow}
          handleConfirmation={handleDeleteRowConfirm}
          isUserRequestorOrApprover={isUserRequestorOrApprover}
        />
    </div>
  );
};

export default EmailTemplateRawList;
