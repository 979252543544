import axios from 'axios';
import constant from '../constants';

import {
  UPDATE_CAMPAIGN_TYPE,
  ASYNC_IN_PROGRESS,
  GET_CAMPAIGN,
  UDPATE_CAMPAIGN_TYPE_DETAILS
} from '../actionTypes';
export function getCampaignDetails(campaignId, isEdit) {
  return function(dispatch) {
    dispatch({
      type: ASYNC_IN_PROGRESS,
      isLoading: true
    });
    // Fetch Search Filters
    axios
      .get(constant.serviceUrls.CAMPAIGN_MASTER_SERVICE_URL + '/' + campaignId)
      .then(response => {
        let campaign = response.data[0];
        dispatch({ type: ASYNC_IN_PROGRESS, isLoading: false });
        dispatch({ type: GET_CAMPAIGN, campaign, isEdit });
      });
  };
}

export function updateCampaignType(uid, campaignTypeData, userName, userEmail) {
  return function(dispatch) {
    dispatch({
      type: ASYNC_IN_PROGRESS,
      isLoading: true
    });
    // Fetch Search Filters
    campaignTypeData['uid'] = uid;
    campaignTypeData['type'] = campaignTypeData['type']
      ? campaignTypeData['type']
      : campaignTypeData['formType'];
    campaignTypeData['incentiveType'] =
      campaignTypeData['campaignType'].toLowerCase() === 'incentive'
        ? campaignTypeData['incentiveType']
        : null;
    campaignTypeData['userName'] = userName;
    campaignTypeData['userEmail'] = userEmail;
    campaignTypeData['updatedBy'] = userEmail;
    axios({
      method: 'POST',
      url: constant.serviceUrls.CAMPAIGN_MASTER_SERVICE_URL,
      data: campaignTypeData
    }).then(response => {
      dispatch({ type: ASYNC_IN_PROGRESS, isLoading: false });
      dispatch({
        type: UPDATE_CAMPAIGN_TYPE,
        campaignTypeData
      });
    });
  };
}

export const updateCampaignTypeDetails = campaignDetails => ({
  type: UDPATE_CAMPAIGN_TYPE_DETAILS,
  campaignDetails
});
