import React, { useState } from 'react';
import Modal from 'react-modal';
import constants from 'constants/config';
import RequestMoreInfoIconTransparent from 'images/requestMoreInfoIconTransparent';
import SmsEditInfo from 'pages/SmsEditInfo';

const SmsEditModel = ({ modalIsOpen, closeModal, smsDetails, pathUid }) => {
  const dismissModal = () => {
    closeModal(false);
  };

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={dismissModal}
        className="cw-modal cw-modal--schedule-email-model"
        contentLabel="Schedule Modal"
        style={{
          overlay: {
            backgroundColor: constants.EMAIL_OVERLAY_BACKGROUND
          }
        }}
      >
        <button
          className="cw-modal--close"
          title="Close"
          onClick={dismissModal}
        >
          <i className="fas fa-times"></i>
        </button>

        <div className="d-flex email-request-more-info-modal-wrapper">
          <div className="email-request-more-info-icon send-test-email__icon">
            <RequestMoreInfoIconTransparent />
          </div>
          <div class="w-100">
            <h3 className="cw-heading--senary">Edit and resubmit campaign</h3>
            <br />
            <SmsEditInfo
              pathUid={pathUid}
              closeModal={closeModal}
              smsDetails={smsDetails}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SmsEditModel;
