import React from 'react'
import { Input } from 'components/atoms/Input'
import classNames from 'classnames'

const CheckBoxInput = ({id, name, value, onChange, labelClass, label, className}) => {
  return (
    <div className={classNames("form-check", className)} aria-live="polite">
        <Input
          name={name}
          id={id}
          value={value}
          type="checkbox"
          checked={value}
          onChange={onChange}
        />
        <label htmlFor={id} className={classNames("form-check-label", labelClass)}>
          {label}
        </label>
    </div>
  )
}

export default CheckBoxInput