import React from 'react';
import DesignEmailIcon from '../../../images/design-email.png';

export default function DesignEmail({ onClick, buttonLabel = '' }) {
  return (
    <div className="template-review design-email">
      {buttonLabel ? (
        <button className="btn btn-primary" onClick={onClick}>
          {buttonLabel}
        </button>
      ) : null}
      <img src={DesignEmailIcon} alt="Design email icon" />
      <p>
        A preview thumbnail of your email will be available here after you
        design it
      </p>
    </div>
  );
}
