import {
    EMAIL_CAMPAIGN_DETAILS_ASYNC_IN_PROGRESS,
    SET_EMAIL_CAMPAIGN_DETAILS
  } from '../../actionTypes';
  
  const initialState = {
    isLoading: false,
    summary: null,
    deliverySchedule: null,
    audienceDefinition: null,
    uid: null
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
      case EMAIL_CAMPAIGN_DETAILS_ASYNC_IN_PROGRESS: {
        //let asyncInProgress = { asyncInProgress: action.isLoading };
        return { ...state, isLoading: action.isLoading };
      }
      case SET_EMAIL_CAMPAIGN_DETAILS: {
        let emailCampaignDetails = action.emailCampaignDetails;
        return { ...state, ...emailCampaignDetails };
      }
  
      default:
        return state;
    }
  }
  