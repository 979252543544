import React from 'react';

import { FormInput } from 'components/molecules/FormInput';

import constants from 'constants/config';

export const SMSURLInput = ({
  name,
  register,
  setValue,
  defaultValue,
  errors,
  status,
  insertingURLMain,
  insertingURLFollowup,
  setInsertingURLMain,
  setInsertingURLFollowup,
  ...props
}) => {
  return (
    <>
      <div className="row">
        <div className="col-md-8">
          <FormInput
            id="fullURL"
            type="text"
            name={`${name}.fullURL`}
            label="Enter URL"
            placeholder="Enter URL"
            register={register}
            errors={errors}
            key="fullURL"
            defaultValue={defaultValue.fullURL}
            maxLength={200}
            setValue={setValue}
          />
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center w-100">
            <div className="flex-grow-1 mr-2">
              <FormInput
                id="shortURL"
                type="text"
                name={`${name}.shortURL`}
                label="Sample short URL"
                placeholder="Sample short URL"
                className="flex-grow-1"
                register={register}
                errors={errors}
                key="shortURL"
                defaultValue={defaultValue.shortURL || constants.SAMPLE_BITLY_URL}
                maxLength={50}
                setValue={setValue}
                readOnly={status !== constants.SMS_STATUS.AWAITING_SETUP}
              />
            </div>
            <span
              className={'saas-accordion-close mt-3'}
              style={{ position: 'static' }}
              onClick={() => {
                name === 'URLMain'
                  ? setInsertingURLMain(false)
                  : setInsertingURLFollowup(false);
              }}
            ></span>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 email-type-desc-font mb-4 mt-n2">
          ACS team will replace any URLs in your SMS body with shortened Bitly
          URLs that can range anywhere between 14-20 characters. We add a sample
          URL in your SMS body to give you a more realistic preview of your SMS
          and its character length.
        </div>
      </div>
    </>
  );
};
