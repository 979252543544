import axios from "axios";
import constant from "../constants";

import {
  GET_PREVIEW_URL,
  GET_PREVIEW_ERROR_PAGE,
  PREVIEW_ASYNC_IN_PROGRESS,
} from "../actionTypes";

import { isLanguageRTL } from "../selectors/index.js";

/**
 * Function will call the api to save form configs and will return the preview url
 * @param {*} campaignId - campaign uid
 * @returns Preview url
 */
export function getFormPreviewUrl(campaignId, language, overviewFlag) {
  return function(dispatch) {
    dispatch({
      type: PREVIEW_ASYNC_IN_PROGRESS,
      isLoading: true,
    });
    let endpoint =
      constant.saasServiceUrls.FORM_PREVIEW_ENDPOINT +
      campaignId +
      "?preview=true";
    if (overviewFlag) {
      endpoint = endpoint + "&overview=" + overviewFlag;
    }
    axios
      .post(endpoint)
      .then((response) => {
        if (response.status === 200) {
          let previewURL =
            constant.saasServiceUrls.FORM_PREVIEW_URL + "?id=" + campaignId;
          previewURL = isLanguageRTL(language)
            ? previewURL + "&rtl=true"
            : previewURL;
          dispatch({
            type: GET_PREVIEW_URL,
            previewUrl: previewURL,
          });
          setTimeout(
            () =>
              dispatch({ type: PREVIEW_ASYNC_IN_PROGRESS, isLoading: false }),
            2000
          );
        }
      })
      .catch((error) => {
        if (error.response && error.response.data.status >= 400) {
          dispatch({
            type: GET_PREVIEW_ERROR_PAGE,
            errorPageUrl:
              "/campaign-wizard/error/" + error.response.data.status,
          });
          dispatch({ type: PREVIEW_ASYNC_IN_PROGRESS, isLoading: false });
        } else {
          dispatch({
            type: GET_PREVIEW_ERROR_PAGE,
            errorPageUrl: "/campaign-wizard/error/" + 500,
          });
          dispatch({ type: PREVIEW_ASYNC_IN_PROGRESS, isLoading: false });
        }
      });
  };
}
