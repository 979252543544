import summarySchema from 'pages/SmsCampaignSummary/smsCampaignSummarySchema';
import campaignAttributes from 'pages/CommunicationAttributes/smsAttributesSchema';
import deliveryScheduleSchema from 'pages/CommunicationSchedule/smsScheduleSchema';
import audienceDefinitionSchema from 'pages/CommunicationAudience/smsAudienceSchema';
import designSmsSchema from 'pages/DesignSMS/designSmsSchema';
// import emailCollaboratorsSchema from 'pages/EmailCollaborators/emailCollaboratorsSchema';

import { transformSMSDataForValidation } from 'selectors';

export const schemas = {
  summary: summarySchema(null, {}).getSchema(),
  campaignAttributes: campaignAttributes(null, {}).getSchema(),
  deliverySchedule: campaign =>
    deliveryScheduleSchema(null, {
      emailType: campaign.emailType
    }).getSchema(),
  audienceDefinition: audienceDefinitionSchema(null, {}).getSchema(),
  designSms: campaign =>
    designSmsSchema(null, {
      defaultValues: campaign
    }).getSchema()
  // emailCollaborators: emailCollaboratorsSchema(null, {}).getSchema(),
};

export const validateSMSCampaign = async campaign => {
  let transformedData = transformSMSDataForValidation(campaign, true);

  const summary = await schemas.summary.isValid(transformedData.summary);

  const campaignAttributes = await schemas.campaignAttributes.isValid(
    transformedData.campaignAttributes
  );

  const deliverySchedule = await schemas
    .deliverySchedule(transformedData)
    .isValid(transformedData.deliverySchedule);

  let deliveryScheduleError = null;
  try {
    await schemas
      .deliverySchedule(transformedData)
      .validate(transformedData.deliverySchedule);
  } catch (err) {
    deliveryScheduleError = err;
  }

  const audienceDefinition = await schemas.audienceDefinition.isValid(
    transformedData.audienceDefinition
  );

  const designSMS = await schemas
    .designSms(campaign)
    .isValid({...transformedData.designSMS, optOutText: campaign.optOutText, optOutTextFollowUp: campaign.optOutText });

  // const emailCollaborators = await schemas.emailCollaborators.isValid(
  //   campaign
  // );

  const dynamicCouponError = validateDynamicCoupon(campaign);

  return {
    summary: !summary,
    campaignAttributes: !campaignAttributes,
    deliverySchedule: !deliverySchedule,
    deliveryScheduleError: deliveryScheduleError,
    audienceDefinition: !audienceDefinition,
    designSMS: !designSMS,
    dynamicCouponError: !dynamicCouponError
    // emailCollaborators: !emailCollaborators,
  };
};

export const validateDynamicCoupon = campaign => {
  const { smsAttributes } = campaign;

  if (smsAttributes) {
    if (smsAttributes?.smsCoupon?.type === 'dynamicCoupon') {
      const couponFileUpload = smsAttributes?.smsCoupon?.couponFileUpload;
      
      if (couponFileUpload === 'failed') {
        if (smsAttributes?.smsCoupon?.kanaTicketNumber) {
          return true;
        } else {
          return false;
        }
      }
      if (couponFileUpload === 'success') {
        return true;
      }

      if (couponFileUpload === 'pending' || couponFileUpload === '') {
        return false;
      }
    }
    return true;
  }
  return true;
};
