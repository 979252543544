import React, { useEffect, useState, useMemo } from 'react';
import allCampaign from '../../images/allCampaign.png';
import requesterImage from '../../images/requester.png';
import approverImage from '../../images/approver.png';
import masterDataImage from '../../images/master-data.png';
import brandMarketImage from '../../images/brand-market.png';
import marketImage from '../../images/market.png';
import brandImage from '../../images/brand.png';
import { DashboardCard } from '../organisms/DashboardCard/DashboardCard';
import { CampaignCharts } from '../organisms/CampaignCharts/CampaignCharts';
import { getDashboardMatrix } from '../../actions/dashboard';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import constant from '../../constants';
import { getAuthoringFieldData } from 'actions/saasFormAuthoring';
import ReportSectionHeading from '../atoms/ReportSectionHeading';
import FaqStats from '../molecules/FaqStats';
import FieldStatsAccordion from '../organisms/FieldStatsAccordion';
import DashStatsBox from '../organisms/DashStatsBox';
import StatsBoxTitle from '../molecules/StatsBoxTitle';
import { getChartData, getDateRange } from '../../selectors/index';
import RadioDropDown from '../RadioDropDown';
import constants from '../../constants';
import { get } from 'lodash';
import moment from 'moment';
import ChartSummary from '../organisms/ChartSummary';
import CampaignRejectionList from 'components/organisms/CampaignRejectionList/CampaignRejectionList';
import CampaignRejectionListModal from 'components/organisms/CampaignRejectionList/components/CampaignRejectionListModal';
const dropDownOptions = [
  { label: 'Monthly', value: 'monthly' },
  { label: 'Weekly', value: 'weekly' },
  { label: 'Quarterly', value: 'quarter' },
  { label: 'Yearly', value: 'annual' }
];

var _startDate = null;

const PerformanceAndHealth = props => {
  const [infraStatusOption, setInfraStatusOption] = useState(
    dropDownOptions[0]
  );
  const handleInfraStatusDropDown = selectedOption => {
    setInfraStatusOption(selectedOption);
  };
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => setIsOpen(!isOpen);

  const [rejectionOption, setRejectionOption] = useState(dropDownOptions[0]);

  const handleRejectionDropDown = selectedOption => {
    setRejectionOption(selectedOption);
  };

  const infraStatusDropDownConfig = {
    className: 'cw-report-dashboard-dropdown-compact',
    name: 'campaignTrendsDropDown',
    label: 'campaignTrendsDropDown',
    id: 'campaignTrendsDropDown',
    options: dropDownOptions,
    onChange: handleInfraStatusDropDown,
    value: infraStatusOption
  };

  const rejectionsDropDownConfig = {
    className: 'cw-report-dashboard-dropdown-compact',
    name: 'rejectionsDropDown',
    label: 'rejectionsDropDown',
    id: 'rejectionsDropDown',
    options: dropDownOptions,
    onChange: handleRejectionDropDown,
    value: rejectionOption
  };

  let campaignMatrix = props.campaignMatrix;

  const infraStatusMap = [
    {
      title: <strong>Infra and security setup</strong>,
      data: []
    }
  ];
  const infraStatusTrend = {
    data: {
      labels: [],
      datasets: []
    }
  };

  const rejectionsMap = [
    {
      title: <strong>Campaign rejections</strong>,
      data: [],
      buttonConfig: {
        title: 'View rejection comments',
        buttonClicked: toggleModal
      }
    }
  ];

  const rejectionsTrend = {
    data: {
      labels: [],
      datasets: []
    }
  };

  // INFRA DATA SETUP STARTS
  if (campaignMatrix && campaignMatrix.infraStatus) {
    infraStatusMap[0].data = [
      {
        title: 'Success',
        value: campaignMatrix.infraStatus.success.value || 0,
        percentage:
          Math.ceil(campaignMatrix.infraStatus.success.percentage || 0) + '%'
      },
      {
        title: 'Failure',
        value: campaignMatrix.infraStatus.failure.value || 0,
        percentage:
          Math.ceil(campaignMatrix.infraStatus.failure.percentage || 0) + '%'
      }
    ];
    infraStatusMap[0].total = {
      title: 'Total',
      value:
        (campaignMatrix.infraStatus.success.value || 0) +
        (campaignMatrix.infraStatus.failure.value || 0),
      percentage: ''
    };

    // Chart Data setup
    var infraStatusSuccessChartdata = getChartData(
      campaignMatrix.infraStatus.success.trend,
      infraStatusOption.value
    );
    var infraStatusFailureChartdata = getChartData(
      campaignMatrix.infraStatus.failure.trend,
      infraStatusOption.value
    );

    infraStatusTrend.data = {
      labels: infraStatusSuccessChartdata.labels,
      datasets: [
        {
          label: 'Infra setup success',
          backgroundColor: '#40B47C',
          data: infraStatusSuccessChartdata.dataSet,
          barThickness: 13,
          borderRadius: 5
        },
        {
          label: 'Infra setup failure',
          backgroundColor: '#F34F4F',
          data: infraStatusFailureChartdata.dataSet,
          barThickness: 13,
          borderRadius: 5
        }
      ]
    };

    infraStatusTrend.options = {
      indexAxis: 'x',
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          //   offset: 20,
          stacked: true,
          display: true,
          grid: {
            display: false
          }
          //   position: "right",
        },
        x: {
          //   offset: 20,
          stacked: true,
          display: true,
          grid: {
            display: false
          },
          beginAtZero: true
        }
      },
      plugins: {
        legend: {
          display: true,
          position: 'right',
          maxWidth: 200,
          labels: {
            boxWidth: 10
          }
        },
        tooltip: {
          intersect: false,
          callbacks: {
            title: function(context) {
              if (infraStatusOption.value == 'weekly') {
                let m = moment(context[0].label, 'DD MMM YYYY');
                if (m) {
                  if (_startDate && _startDate > m) {
                    return (
                      _startDate.format('DD MMM YYYY') +
                      ' - ' +
                      m.day(6).format('DD MMM YYYY')
                    );
                  }
                  return (
                    context[0].label + ' - ' + m.day(6).format('DD MMM YYYY')
                  );
                }
              }
              return context[0].label;
            }
          }
        }
      }
    };
  }
  // INFRA DATA SETUP ENDS

  // REJECTION DATA SETUP STARTS
  if (campaignMatrix && campaignMatrix.rejections) {
    rejectionsMap[0].data = [
      {
        title: 'Rejected atleast once',
        value: campaignMatrix.rejections.atleastOneRejection.value || 0,
        percentage:
          Math.ceil(
            campaignMatrix.rejections.atleastOneRejection.percentage || 0
          ) + '%'
      },
      {
        title: 'Never rejected',
        value: campaignMatrix.rejections.noRejection.value || 0,
        percentage:
          Math.ceil(campaignMatrix.rejections.noRejection.percentage || 0) + '%'
      }
    ];

    // Chart Data setup
    // var rejectionsNoRejectionsChartdata = getChartData(
    //   campaignMatrix.rejections.noRejection.trend,
    //   rejectionOption.value
    // );
    var rejectionsAtleastOneRejectionChartdata = getChartData(
      campaignMatrix.rejections.atleastOneRejection.trend,
      rejectionOption.value
    );

    rejectionsTrend.data = {
      labels: rejectionsAtleastOneRejectionChartdata.labels,
      datasets: [
        {
          label: 'Rejected atleast once',
          backgroundColor: '#464444',
          data: rejectionsAtleastOneRejectionChartdata.dataSet,
          barThickness: 13,
          borderRadius: 5
        }
        // {
        //   label: 'Never rejected',
        //   backgroundColor: '#A9A0A0',
        //   data: rejectionsNoRejectionsChartdata.dataSet,
        //   barThickness: 13,
        //   borderRadius: 5
        // }
      ]
    };

    rejectionsTrend.options = {
      indexAxis: 'x',
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          //   offset: 20,
          stacked: true,
          display: true,
          grid: {
            display: false
          },
          //   position: "right",
          ticks: {
            beginAtZero: true,
            callback: function(label, index, labels) {
              // when the floored value is the same as the value we have a whole number
              if (Math.floor(label) === label) {
                return label;
              }
            }
          }
        },
        x: {
          //   offset: 20,
          stacked: true,
          display: true,
          grid: {
            display: false
          },
          beginAtZero: true
        }
      },
      plugins: {
        legend: {
          display: true,
          position: 'right',
          maxWidth: 200,
          labels: {
            boxWidth: 10
          }
        },
        tooltip: {
          intersect: false,
          callbacks: {
            title: function(context) {
              if (rejectionOption.value == 'weekly') {
                let m = moment(context[0].label, 'DD MMM YYYY');
                if (m) {
                  if (_startDate && _startDate > m) {
                    return (
                      _startDate.format('DD MMM YYYY') +
                      ' - ' +
                      m.day(6).format('DD MMM YYYY')
                    );
                  }
                  return (
                    context[0].label + ' - ' + m.day(6).format('DD MMM YYYY')
                  );
                }
              }
              return context[0].label;
            }
          }
        }
      }
    };
  }
  // REJECTION DATA SETUP ENDS

  return (
    <>
      {/* REJECTIONS STARTS HERE*/}
      <div className="mt-40">
        <div className="row justify-content-betweenss">
          <div
            className="col-sm-4 dashboard-info-security"
            style={{ paddingTop: '28px' }}
          >
            <ChartSummary
              data={rejectionsMap}
              titleClass={'mb-0'}
              className={'chart-summary-wrapper-no-padding'}
            />
          </div>
          <div className="col-sm-8 mt-20 pl-20" style={{ paddingLeft: '7%' }}>
            <div className="campaign-trends" style={{ padding: '20px 10px' }}>
              <CampaignCharts
                title={<strong>Campaign rejections over time</strong>}
                className={'campaign-trends-chart '}
                chartType={'Bar'}
                chartConfig={rejectionsTrend}
                dropDownConfig={rejectionsDropDownConfig}
              />
            </div>
          </div>
        </div>
      </div>
      {/* REJECTIONS ENDS HERE*/}

      {/* INFRA STATUS STARTS HERE*/}
      <div className="mt-40">
        <div className="row justify-content-betweenss">
          <div
            className="col-sm-4 dashboard-info-security"
            style={{ paddingTop: '12px' }}
          >
            <ChartSummary
              data={infraStatusMap}
              titleClass={'mb-0'}
              className={'chart-summary-wrapper-no-padding'}
            />
          </div>
          <div className="col-sm-8 mt-20 pl-20" style={{ paddingLeft: '7%' }}>
            <div className="campaign-trends" style={{ padding: '20px 10px' }}>
              <CampaignCharts
                title={<strong>Infra setup over time</strong>}
                className={'campaign-trends-chart ml-50'}
                chartType={'Bar'}
                chartConfig={infraStatusTrend}
                dropDownConfig={infraStatusDropDownConfig}
              />
            </div>
          </div>
        </div>
      </div>
      {/* INFRA STATUS ENDS HERE*/}

      <CampaignRejectionListModal isOpen={isOpen} onClose={toggleModal} />
      {/* <div className="row">
        <CampaignRejectionList />
      </div> */}
    </>
  );
};

export default PerformanceAndHealth;
