import * as yup from 'yup';
import { updateEmailType } from '../../actions/emailbriefing/emailType';
import { toggleForceEmailType } from 'actions/emailbriefing/emailBrandDetails';
import constants from 'constants/config';
import { FormEmailRadioInput } from 'components/molecules/FormEmailRadioInput';
import { getIsEdited } from 'pages/NewEmailCampaign/brandDetailsformSchema';

const formSchema = (dispatch, componentProps) => {
  const { history, match, defaultValues, emailDesign } = componentProps;
  const isEdited = getIsEdited({ history });

  const onSubmit = (data, {}, { backToReview } = {}) => {
    // window.alert(JSON.stringify(data, null, 2));
    const isEdit = !!match.params.id;
    const uid = isEdit ? match.params.id : false;
    const emailType = {
      ...data
    };

    dispatch(toggleForceEmailType(false));

    dispatch(
      updateEmailType(uid, emailType, (error, result) => {
        if (result) {
          if (backToReview && isEdited) {
            const status = emailDesign?.emailBriefing?.status;
            if (status === constants.EMAIL_STATUS.AWAITING_FOR_APPROVAL) {
              history.push(`/campaign-wizard/emailbriefing/${uid}/approval`);
            } else if (
              status === constants.EMAIL_STATUS.DRAFT ||
              status === constants.EMAIL_STATUS.REJECTED
            ) {
              history.push(`/campaign-wizard/emailbriefing/${uid}/review`);
            }
          } else {
            isEdit
              ? history.push(
                  `/campaign-wizard/emailbriefing/${uid}/email-summary`
                )
              : history.push(
                  '/campaign-wizard/new-email-campaign-email-summary'
                );
          }
        }
        if (error) {
          console.log('submit error: ', error);
        }
      })
    );
  };

  return {
    formType: 'custombuttons',
    submitBtnLabel: isEdited ? 'Save and back to review' : 'Next',
    onSecondaryClick: onSubmit,
    onSubmitSecondary: () => {},
    isEdit: isEdited,
    secondaryBtnLabel: 'Next',
    fields: [
      {
        type: 'custom',
        name: 'emailType',
        id: 'emailType',
        disableError: true,
        component: FormEmailRadioInput,
        defaultValue: defaultValues.emailType,
        options: constants.EMAIL_TYPE_OPTIONS,
        rules: yup.string().required('This field Is required!!!')
      }
    ],
    onSubmit: (data, { user }) =>
      onSubmit(data, { user }, { backToReview: isEdited }),
    formErrorMessage: 'Please select an email category',
    onPrevious: () => {
      const uid = match.params.id;
      history.push(
        '/campaign-wizard' +
          (uid ? `/emailbriefing/${uid}/branddetails` : '/new-email-campaign')
      );
    },
    previousBtnLabel: 'Back'
  };
};

export default formSchema;
