import axios from 'axios';
import {
  GET_CAMPAIGN,
  ASYNC_IN_PROGRESS,
  APPROVE_CAMPAIGN,
  REJECT_CAMPAIGN,
  REDIRECT,
  SET_APPROVAL_ERROR,
  FETCH_CAMPIGNEDITLOGS_IN_PROGRESS,
  GET_CAMPAIGN_EDITLOGS
} from '../actionTypes';
import constant from '../constants';
import { getFormPreviewUrl } from './preview';

export function fetchCampaignData(Id) {
  return function(dispatch) {
    dispatch({
      type: ASYNC_IN_PROGRESS,
      isLoading: true
    });
    // Fetch Campaign Data
    axios
      .get(constant.serviceUrls.CAMPAIGN_DETAILS_SERVICE_URL + '/' + Id)
      .then(response => {
        let campaign = response.data[0];
        let language = campaign.language && campaign.language.code;
        if (
          ((campaign.websiteType &&
            campaign.websiteType.name === 'Adobe SaaS') ||
            campaign.isPlatformAgnostic) &&
          campaign && !campaign.isMasterData
        ) {
          dispatch(getFormPreviewUrl(Id, language));
        }
        dispatch({ type: ASYNC_IN_PROGRESS, isLoading: false });
        dispatch({ type: GET_CAMPAIGN, campaign, dataUpdated: false });
      })
      .catch(error => {
        dispatch({ type: REDIRECT, redirect: true });
      });
  };
}

export function approveCampaign(data) {
  return function(dispatch) {
    dispatch({
      type: ASYNC_IN_PROGRESS,
      isLoading: true
    });
    let approvalMsg = null;
    // Update Campaign Status
    return axios({
      method: 'POST',
      url: constant.serviceUrls.CAMPAIGN_STATUS_UPDATE_SERVICE_URL,
      data: data
    })
      .then(response => {
        approvalMsg = '';
        dispatch({ type: ASYNC_IN_PROGRESS, isLoading: false });
        dispatch({
          type: APPROVE_CAMPAIGN,
          approved: 'approved',
          approvalMsg,
          dataUpdated: true
        });
      })
      .catch(err => {
        let error =
          err.response.data.body[0].number +
            ' ' +
            (err.response.data.body[0].code
              ? err.response.data.body[0].code.replace(/_/g, ' ')
              : err.response.data.message) ||
          'There was a problem with the page.';
        dispatch({ type: ASYNC_IN_PROGRESS, isLoading: false });
        dispatch({ type: SET_APPROVAL_ERROR, error });
      });
  };
}
export function rejectCampaign(data) {
  return function(dispatch) {
    dispatch({
      type: ASYNC_IN_PROGRESS,
      isLoading: true
    });
    // Update Campaign Status
    return axios({
      method: 'POST',
      url: constant.serviceUrls.CAMPAIGN_STATUS_UPDATE_SERVICE_URL,
      data: data
    }).then(response => {
      dispatch({ type: ASYNC_IN_PROGRESS, isLoading: false });
      dispatch({
        type: REJECT_CAMPAIGN,
        approved: 'rejected',
        dataUpdated: true
      });
    });
  };
}

export function fetchCampaignEditLogs(Id) {
  return function(dispatch) {
    dispatch({
      type: FETCH_CAMPIGNEDITLOGS_IN_PROGRESS,
      isLoading: true
    });
    // Fetch Campaign Data
    axios
      .get(constant.serviceUrls.SERVICE_URL.CAMPAIGN_EDIT_LOGS_URL + '/' + Id)
      .then(response => {
        let campaignEditLogs =
          response.data && response.data.length
            ? response.data[0]['updatedFields']
            : '';
        dispatch({ type: FETCH_CAMPIGNEDITLOGS_IN_PROGRESS, isLoading: false });
        dispatch({ type: GET_CAMPAIGN_EDITLOGS, campaignEditLogs });
      })
      .catch(error => {
        //dispatch({ type: REDIRECT, redirect: true });
      });
  };
}
