import React, { useState, forwardRef, useEffect } from 'react';
import classNames from 'classnames';

const FlyoutModal = forwardRef(
  (
    { isOpen, onOpen, onClose, btnClass, btnStyle = {}, children, buttonContent, modelTitle, externalSubmit },
    ref
  ) => {
    useEffect(() => {
      /**
       * Invoke Function onClick outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          onClose();
        }
      }
      // Bind
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // dispose
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref, onClose]);

    return (
      <div className="flyoutmodal__container" ref={ref}>
        <button className={classNames(btnClass)} style={btnStyle} type="button" onClick={onOpen}>
          {buttonContent}
        </button>
        {isOpen && (
          <>
            <span className="flyoutmodal__up-arrow"></span>
            <div className="flyoutmodal__box">
            <div className="email-save-model-wrapper">
            <span
                  className="qna-guideline-close email-title-close"
                  onClick={onClose}
                ></span>
              <div className="email-overlay-scroll">
                <div className="mb-10 cw-color--saasheading">
                  <strong>{modelTitle}</strong>
                </div>
              </div>
              {children}
              {externalSubmit? externalSubmit(): null}
              </div>
            </div>
            
          </>
        )}
      </div>
    );
  }
);

export default FlyoutModal;
