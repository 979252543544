import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TimlineItem from 'components/organisms/TimelineItem/TimelineItem';
import { Accordion, Menu, Icon, Label } from 'semantic-ui-react';
import classNames from 'classnames';
import constant from '../../../constants';
import 'semantic-ui-css/components/accordion.min.css';
import menuHonorificPrefix from '../../../images/menu-honorificPrefix.png';
import menuUser from '../../../images/menu-user.png';
import menuGender from '../../../images/menu-gender.png';
import menuDate from '../../../images/menu-date.png';
import menuEmail from '../../../images/menu-mail.png';
import menuMobileNumber from '../../../images/menu-mobileNumber.png';
import menuHouseNameOrNumber from '../../../images/menu-houseNameOrNumber.png';
import menuAddressLine1 from '../../../images/menu-addressLine1.png';
import menuCityName from '../../../images/menu-cityName.png';
import menuPostalCode from '../../../images/menu-postalCode.png';
import menuStateOrProvince from '../../../images/menu-stateOrProvince.png';
import menuAddressCountry from '../../../images/menu-stateOrProvince.png';
import menuHeading from '../../../images/menu-heading.png';
import menuParagraph from '../../../images/menu-heading.png';
import menuEntryCode from '../../../images/menu-entryCode.png';
import menuUpload from '../../../images/menu-upload.png';
import menuSubmit from '../../../images/menu-submit.png';
import menuArchive from '../../../images/menu-archive.png';
import menuNumber from '../../../images/menu-number.png';
import legalAgeConsent from '../../../images/menu-user-check.png';
import legalDisclaimer from '../../../images/menu-alert-circle.png';
import termsAndConditions from '../../../images/menu-clipboard.png';
import additionalSubscription from '../../../images/menu-plus-circle.png';
import additionalSubscription1 from '../../../images/menu-plus-circle.png';
import privacyNotice from '../../../images/menu-shield.png';
import contentInterestAreas from '../../../images/menu-list.png';
import brandSMSConsent from '../../../images/menu-message-circle.png';
import unileverSMSConsent from '../../../images/menu-message-circle.png';
import brandEmailConsent from '../../../images/menu-inbox.png';
import unileverEmailConsent from '../../../images/menu-inbox.png';
import unileverOnlineOnlyConsent from '../../../images/online-advertising.png';
const consentTypes = constant.consentTypes;

function MenuAccordion({
  menuItems,
  clickHandler,
  closeHandler,
  editCampaign,
  className,
  statsMode,
  showStats,
  startingIndex,
  isMasterDataOther
}) {
  const [state, setState] = useState({
    activeIndex: startingIndex != null ? startingIndex : -1
  });
  const handleClick = (e, titleProps) => {
    e.stopPropagation();
    const { index } = titleProps;
    const { activeIndex } = state;
    const newIndex = activeIndex === index ? -1 : index;
    setState({ activeIndex: newIndex });
  };
  let menuIcon = {
    honorificPrefix: menuHonorificPrefix,
    honorificSuffix: menuHonorificPrefix,
    firstName: menuUser,
    middleName: menuUser,
    lastName: menuUser,
    gender: menuGender,
    dateOfBirth: menuDate,
    email: menuEmail,
    mobileNumber: menuMobileNumber,
    houseNameOrNumber: menuHouseNameOrNumber,
    addressLine1: menuAddressLine1,
    addressLine2: menuAddressLine1,
    cityName: menuCityName,
    postalCode: menuPostalCode,
    stateOrProvince: menuStateOrProvince,
    addressCountry: menuAddressCountry,
    heading: menuHeading,
    paragraph: menuParagraph,
    entryCode: menuNumber,
    questionAnswerPair: menuEntryCode,
    samplePrizeSelection: menuArchive,
    fileUpload: menuUpload,
    UGCTextarea: menuEntryCode,
    legalAgeConsent,
    privacyNotice,
    brandEmailConsent,
    brandSMSConsent,
    unileverEmailConsent,
    unileverSMSConsent,
    unileverEmailOnlyConsent: unileverEmailConsent,
    unileverSMSOnlyConsent: unileverSMSConsent,
    unileverOnlineOnlyConsent,
    contentInterestAreas,
    additionalSubscription,
    additionalSubscription1,
    termsAndConditions,
    legalDisclaimer,
    submit: menuSubmit,
    combinedUnileverAndBrandEmailConsent: unileverEmailConsent,
    unileverWhatsAppConsent : unileverSMSConsent,
    brandWhatsAppConsent : unileverSMSConsent
  };
  return (
    <div className={classNames('menuaccordion', className)}>
      {!statsMode ? (
        <div className="menuaccordion__heading">
          <div className="menuaccordion__heading--title">Form fields</div>{' '}
          <div className="menuaccordion__heading--icon">
            <Icon name="remove circle" onClick={closeHandler} size="large" />
          </div>
        </div>
      ) : null}
      <Accordion vertical="true" fluid>
        {menuItems.map((menuItem, i) => {
          return menuItem.listItems.length ? (
            <Menu.Item
              key={'menuItem-' + menuItem.listId}
              className="menuaccordion__items"
            >
              <Accordion.Title
                active={state.activeIndex === i}
                content={menuItem.listLabel}
                index={i}
                onClick={handleClick}
                className="menuaccordion__items--title"
                icon={state.activeIndex === i ? 'minus' : 'plus'}
                key={'title-' + menuItem.listId + '-' + i}
              />
              <MenuAccordionItem
                data={menuItems[i].listItems}
                activeIndex={state.activeIndex === i}
                className={
                  'menuaccordion__items--item' +
                  ' list-' +
                  menuItems[i].listId +
                  '-' +
                  i +
                  ' list-of-' +
                  menuItems.length
                }
                editCampaign={editCampaign}
                menuIcon={menuIcon}
                index={menuItems[i].listId}
                clickHandler={clickHandler}
                key={'list-' + menuItem.listId + '-' + i}
                showStats={showStats}
                isMasterDataOther={isMasterDataOther}
              />
            </Menu.Item>
          ) : null;
        })}
      </Accordion>
    </div>
  );
}
function MenuAccordionItem({
  data,
  activeIndex,
  className,
  menuIcon,
  editCampaign,
  index,
  clickHandler,
  showStats,
  isMasterDataOther
}) {
  const releaseItems = (
    <div className="menuaccordion__items--wrapper" key={'item-wrap' + index}>
      {data.map(item => {
        //Check for heading and paragraph is added to maintain backward compatibility with earlier implementation
        return item.type === 'field' &&
          (item.dataType === 'heading' ||
            item.dataType === 'paragraph') ? null : (
          <>
            <button
              onClick={() => clickHandler(item)}
              className="menuaccordion__item"
              key={'item-' + item.dataType + '-' + activeIndex}
            >
              <div className="d-flex">
                <div className="menu-item-img-container">
                  <img
                    src={
                      menuIcon[item.dataType] ||
                      menuIcon[item.optInType] ||
                      'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII='
                    }
                    alt={item.label}
                    className="menu-item-img"
                  />
                </div>
                <span
                  className={classNames('menu-item-text', {
                    'menu-item-text-keep-case':
                      item.optInType === consentTypes.brandSMSConsent ||
                      item.optInType === consentTypes.unileverSMSConsent ||
                      item.optInType === consentTypes.unileverSMSOnlyConsent ||
                      item.optInType === consentTypes.combinedUnileverAndBrandEmailConsent ||
                      item.optInType === consentTypes.unileverWhatsAppConsent ||
                      item.optInType === consentTypes.brandWhatsAppConsent
                  })}
                >
                  {item.label}
                </span>
                <span className="menu-item-tag"></span>
              </div>
              <div className="text-nowrap">
                {showStats ? (
                  <span className="menu-item-text-right">
                    {item.count || '0'} &#40;{item.percentage || '0'}&#37;&#41;
                  </span>
                ) : null}
              </div>
            </button>
          </>
        );
      })}
    </div>
  );

  const disbledMessage = (
    <p className="disabled">
      These fields are not available for selection during edit
    </p>
  );

  const disbledIncentiveFieldMessage = (
    <p className="disabled">
      The incentive fields are specific to Promotigo vendor driven incentive
      sign up forms and are not available for others.
    </p>
  );
  return (
    <Accordion.Content
      active={activeIndex}
      content={
        editCampaign && (index === '1' || index === '3' || index === '4')
          ? disbledMessage
          : isMasterDataOther && index === '4'
          ? disbledIncentiveFieldMessage
          : releaseItems
      }
      className={className}
    />
  );
}
MenuAccordion.propTypes = {
  menuItems: PropTypes.array.isRequired
};
export default MenuAccordion;
