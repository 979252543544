import React from 'react';
import { get } from 'lodash';

import { FormDatePicker } from '../FormDatePicker';

export const DateTimeInput = ({
  name,
  variant = '',
  currentDate,
  control,
  errors,
  defaultValue,
  setValue
}) => {
  return (
    <div className="form-row row-col-padding">
      <FormDatePicker
        name={`${name}.details.smsDeliveryDate${variant}`}
        label="SMS delivery date"
        control={control}
        errors={errors}
        dateFormat={'DD/MM/YYYY'}
        timeFormat={false}
        defaultDisabled={false}
        defaultValue={get(defaultValue, `smsDeliveryDate${variant}`)}
        isValidDate={givenDate => {
          return givenDate.isSameOrAfter(currentDate, 'day');
        }}
        setValue={setValue}
        placeholder="DD/MM/YYYY"
        inputGroupClassName="datetime-width"
      />

      <FormDatePicker
        name={`${name}.details.smsDeliveryTime${variant}`}
        label="SMS delivery time (UTC)"
        control={control}
        errors={errors}
        dateFormat={false}
        defaultDisabled={false}
        timeFormat={'hh:mm a'}
        defaultValue={get(defaultValue, `smsDeliveryTime${variant}`)}
        setValue={setValue}
        placeholder=""
        wrapperClassName="ml-3"
        inputGroupClassName="datetime-width"
      />
    </div>
  );
};
