import React from 'react';
import Loader from '../../Loader';
import Pagination from '../../Pagination';
import CampaignListRow from '../CampaignListRow/CampaignListRow';

class CampaignList extends React.Component {
  renderDashboard() {
    let {
      asyncInProgress,
      list,
      role,
      authorizedUserData,
      defaultFiltersEnabled
    } = this.props;
    if (asyncInProgress) {
      return (
        <tr>
          <td>
            <Loader />
          </td>
        </tr>
      );
    } else {
      if (!this.props.checkCampaignsExist()) {
        return (
          <tr>
            <td>
              <span>{'0 campaigns match your search'}</span>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        );
      } else {
        return list.map((campaign, index) => {
          return (
            <CampaignListRow
              key={campaign.uid}
              campaign={campaign}
              index={index}
              role={role}
              defaultFiltersEnabled={defaultFiltersEnabled}
              authorizedUserData={authorizedUserData}
            />
          );
        });
      }
    }
  }
  render() {
    const {
      showLoader,
      sortDashboard,
      recordShowStart,
      recordShowto,
      pageNumber,
      totalCount,
      limit,
      onPageChange,
      sortClass,
      list
    } = this.props;
    return (
      <div className="cw-listing--content">
        {showLoader ? (
          <Loader />
        ) : (
          <table className="table table-striped-dashboard mb-40">
            <caption>List of campaigns</caption>
            <thead>
              <tr>
                <th scope="col">
                  <span className="">Type</span>
                </th>
                <th scope="col" data-type="name" onClick={sortDashboard}>
                  <span className="">Campaign Name</span>
                </th>
                <th scope="col">
                  <span className="">Brand</span>
                </th>
                <th scope="col">
                  <span
                    className={sortClass}
                    data-type="lastUpdatedDate"
                    onClick={sortDashboard}
                  >
                    Updated
                  </span>
                </th>
                <th scope="col">
                  <span className="">Status</span>
                </th>
              </tr>
            </thead>
            <tbody>{this.renderDashboard()}</tbody>
          </table>
        )}
        {showLoader || !list.length ? (
          <span>&nbsp;</span>
        ) : (
          <Pagination
            recordShowStart={recordShowStart}
            recordShowto={recordShowto}
            pageNumber={pageNumber}
            totalRecords={totalCount}
            limit={limit}
            onPageChange={onPageChange}
          />
        )}
      </div>
    );
  }
}

export default CampaignList;
