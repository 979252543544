import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateQuestionIds, updateAnswersIds } from '../actions/masterData';
import {
  showCampaignLanguage,
  isQuestionMandatory,
  isQuesQualifying,
  isRestrictionAllowed
} from '../selectors';
import MasterDataAnswers from './MasterDataAnswer';
import groupIcon from '../images/groupIcon.png';
class MasterDataQuestion extends Component {
  constructor(props) {
    super(props);
    this._handleChange = this._handleChange.bind(this);
    this._validateQuesAns = this._validateQuesAns.bind(this);
  }

  _handleChange(event) {
    this._validateQuesAns(event);
    this.props.updateQuestionIds({
      id: event.target.value,
      qno: event.currentTarget.dataset.qno,
      qtype: event.currentTarget.dataset.qtype
    });
  }
  _handleAnswer(event) {
    this._validateQuesAns(event);
    this.props.updateAnswersIds({
      id: event.target.value,
      qno: event.target.dataset.qno,
      ano: event.target.dataset.ano,
      anstype: event.currentTarget.dataset.anstype
    });
  }
  _validateQuesAns(event) {
    this.props.fielddValidation(event);
  }
  render() {
    let {
      questionAnswerList,
      disabled,
      isCampaignNonEnglish,
      campaignLanguage,
      isPromotigoType
    } = this.props;
    let newMasterData = isNew => {
      return isNew ? (
        <>
          <div
            style={{ display: 'block', color: '#E9A40A', whiteSpace: 'pre' }}
            className="col-sm-3"
          >
            <img src={groupIcon} alt={groupIcon} width="25" height="25"></img>
            &nbsp; New Master
          </div>
        </>
      ) : null;
    };
    var question = questionAnswerList.map((question, index) => {
      return (
        <div className="cw-separator mb-20" key={index}>
          <div className="cw-question-answer-dotted-line">
            <div className="cw-heading-qna">
              <span
                className="float-left  fa fa-circle mt-10"
                style={{ marginTop: '5px' }}
              ></span>
              <strong style={{ marginLeft: '15px' }}>Question</strong>
            </div>
            <div className="cw-striped-qna-row">
              <div className="row">
                <div className="col-sm-3">
                  <div className="cw-qna-lable">Question</div>
                  <div
                    className={`${
                      isCampaignNonEnglish || question.type === 'Profile'
                        ? 'd-none'
                        : ''
                    }`}
                  >
                    <p className="xsmall-label">English</p>
                  </div>
                </div>
                <div className="col-sm-3">
                  <p>{question.text}</p>
                </div>
                {question.type !== 'Profile' &&
                question.type !== 'File upload' &&
                question.type !== 'Unique entry code' ? (
                  <div className="col-sm-3">
                    <input
                      type="text"
                      className="form-control"
                      name={'question' + index}
                      id={'question' + index}
                      placeholder="Enter ID"
                      data-qno={index + 1 || ''}
                      disabled={disabled}
                      value={question.id || ''}
                      onChange={this._handleChange}
                    />
                    <span
                      className="cw-error cw-hidden"
                      data-control={'question' + index}
                    >
                      <i className="fas fa-exclamation-triangle mr-10"></i>
                      Please enter Question Id.
                    </span>
                  </div>
                ) : (
                  <div className="col-sm-3"></div>
                )}
                {newMasterData(question.new)}
              </div>
            </div>
            {!isCampaignNonEnglish &&
            (question.type !== 'Profile' &&
              question.type !== 'File upload' &&
              question.type !== 'Unique entry code') ? (
              <div className="cw-striped-qna-row">
                <div className="row">
                  <div className="col-sm-3">
                    <div className="cw-qna-lable">Question</div>
                    <div>
                      <p className="xsmall-label">{campaignLanguage}</p>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <p>{question.translatedquestion}</p>
                  </div>
                  {question.type !== 'Profile' &&
                  question.type !== 'File upload' &&
                  question.type !== 'Unique entry code' ? (
                    <div className="col-sm-3">
                      <input
                        type="text"
                        className="form-control"
                        name={'translatedquestion' + index}
                        id={'translatedquestion' + index}
                        placeholder="Enter ID"
                        data-qno={index + 1 || ''}
                        disabled={disabled}
                        value={question.translatedId || ''}
                        onChange={this._handleChange}
                        data-qtype={'translated'}
                      />
                      <span
                        className="cw-error cw-hidden"
                        data-control={'translatedquestion' + index}
                      >
                        <i className="fas fa-exclamation-triangle mr-10"></i>
                        Please enter Question Id.
                      </span>
                    </div>
                  ) : (
                    <div className="col-sm-3"></div>
                  )}
                  {newMasterData(question.translatednew)}
                </div>
              </div>
            ) : null}
            <div className="cw-striped-qna-row">
              <div className="row">
                <div className="col-sm-3 cw-qna-lable">Type</div>
                <div className="col-sm-3">
                  <p>{question.type}</p>
                </div>
                <div className="col-sm-3"></div>
              </div>
            </div>
          </div>
          <br />
          {question.type !== 'Profile' &&
          question.type !== 'File upload' &&
          question.type !== 'Unique entry code' &&
          question.answers.length ? (
            <div className="cw-question-answer-dotted-line">
              <div className="cw-heading-qna">
                <span
                  className="float-left  fa fa-circle mt-10"
                  style={{ marginTop: '5px' }}
                ></span>
                <strong style={{ marginLeft: '15px' }}>Answer</strong>
              </div>
              <MasterDataAnswers
                onChange={this._handleAnswer.bind(this)}
                campaignLanguage={this.props.campaignLanguage}
                answerList={question.answers}
                disabled={disabled}
                showInput={
                  question.type !== 'Profile' &&
                  question.type !== 'File upload' &&
                  question.type !== 'Unique entry code'
                    ? true
                    : false
                }
                qno={index}
                isCampaignNonEnglish={isCampaignNonEnglish}
              />
            </div>
          ) : null}
          <br />
          <div className="cw-question-answer-dotted-line">
            <div className="cw-heading-qna">
              <span
                className="float-left  fa fa-circle mt-10"
                style={{ marginTop: '5px' }}
              ></span>
              <strong style={{ marginLeft: '15px' }}>
                Other Configurations
              </strong>
            </div>

            <div className="cw-striped-qna-row">
              <div className="row">
                <div className="col-sm-3 cw-qna-lable">
                  <strong>Mandatory</strong>
                </div>
                <div className="col-sm-3">
                  <p>{isQuestionMandatory(question.mandatory)}</p>
                </div>
                <div className="col-sm-3"></div>
              </div>
            </div>
            {isPromotigoType &&
            (question.type === 'Single choice' ||
              question.type === 'Free text') ? (
              <div className="cw-striped-qna-row">
                <div className="row">
                  <div className="col-sm-3 cw-qna-lable">Qualifying</div>
                  <div className="col-sm-3">
                    <p>{isQuesQualifying(question.isQualifying)}</p>
                  </div>
                  <div className="col-sm-3"></div>
                </div>
              </div>
            ) : null}
            {question.type === 'File upload' ? (
              <>
                <div className="cw-striped-qna-row">
                  <div className="row">
                    <div className="col-sm-3 cw-qna-lable">
                      <strong>Upload restrictions</strong>
                    </div>
                    <div className="col-sm-3">
                      <p>
                        {isRestrictionAllowed(question.isRestrictionAllowed)}
                      </p>
                    </div>
                    <div className="col-sm-3"></div>
                  </div>
                </div>
                <div className="cw-striped-qna-row">
                  <div className="row">
                    <div className="col-sm-3 cw-qna-lable">
                      <strong>File size</strong>
                    </div>
                    <div className="col-sm-3">
                      <p>{question.maxFileSize} KB</p>
                    </div>
                    <div className="col-sm-3"></div>
                  </div>
                </div>
                <div className="cw-striped-qna-row">
                  <div className="row">
                    <div className="col-sm-3 cw-qna-lable">
                      <strong>Default display text</strong>
                    </div>
                    <div className="col-sm-3">
                      <p>{question.defaultDisplayText}</p>
                    </div>
                    <div className="col-sm-3"></div>
                  </div>
                </div>
                <div className="cw-striped-qna-row">
                  <div className="row">
                    <div className="col-sm-3 cw-qna-lable">
                      <strong>Placeholder text</strong>
                    </div>
                    <div className="col-sm-3">
                      <p>{question.placeholderText}</p>
                    </div>
                    <div className="col-sm-3"></div>
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>
      );
    });
    return (
      <div className="col-sm-12">
        <div className="cw-campaign--review-section mb-30">
          <div className="mb-15">
            <h3>Questions &amp; answers ID's</h3>
          </div>
          <div className="cw-campaign--review-section-content">{question}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  questionAnswerList: state.masterData.questionAnswerList
});

export default connect(
  mapStateToProps,
  { updateQuestionIds, updateAnswersIds }
)(MasterDataQuestion);
