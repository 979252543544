import React from 'react';
import classNames from 'classnames';

const StatsBoxTitle = ({ title, className }) => {
  return (
    <div className={classNames(className)}>
      <span className="stats-box-title">{title}</span>
    </div>
  );
};

export default StatsBoxTitle;
