import axios from 'axios';
import constants from '../constants';

export async function fetchBrandDefaults(brand) {
  const response = await axios.get(
    `${constants.serviceUrls.BRAND_DEFAULTS_URL}/${brand}`
  );
  return response.data;
}

export async function saveBrandDefaultsService(data) {
  const response = await axios.post(
    `${constants.serviceUrls.BRAND_DEFAULTS_URL}/save`,
    data
  );
  return response.data;
}
