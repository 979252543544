import constants from 'constants/config';
import React from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import NewStarIcon from '../../images/NewStar.svg';

function CrossLink({ history, isTabView, emailCampaignCount = 'X' }) {
  const onClick = e => {
    history.push('/campaign-wizard/emailbriefing/email-collection');
  };
  return (
    <div
      className={
        constants.featureFlags.ENABLE_EMAIL_COLLECTION_BUTTON
          ? 'cross-link d-flex'
          : 'invisible'
      }
    >
      <img
        src={NewStarIcon}
        alt="new star icon"
        height={25}
        width={25}
        className="mr-2"
      />

      <p>
        <strong>
          {emailCampaignCount} email campaigns created in Campaign Wizard are already live!
        </strong>
        <br />
        <span>
          Draw inspiration from campaigns below, or expore our&nbsp;
          <Link
            to="/campaign-wizard/emailbriefing/email-collection"
            className="inline-button template-btn"
          >
            template collection.
          </Link>
        </span>
      </p>
    </div>
  );
}

export default withRouter(CrossLink);
