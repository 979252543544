import React, { useState } from 'react';
import Modal from 'react-modal';
import { FormBuilder } from '../../components/organisms/FormBuilder';
import { LinkButton } from '../../components/atoms/LinkButton';
import saveRowFormSchema from './saveRowFormSchema';
import EmailSaveRowIcon from '../../images/email-row-save-icon.jsx';
import constants from 'constants/config';
import classNames from 'classnames';

const CustomButtons = ({
  isSubmitting,
  onPrevious,
  previousBtnLabel,
  submitBtnLabel,
  onSecondaryClick,
  onSubmitSecondary,
  secondaryBtnLabel,
  isEdit
}) => {
  return isEdit ? (
    <div className="mt-30 mb-20">
      <div>
        {onSecondaryClick && (
          <button
            className="btn btn-primary mr-3"
            type="button"
            onClick={() => {
              onSubmitSecondary();
            }}
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Loading....' : secondaryBtnLabel || 'Secondary'}
          </button>
        )}
        <button
          className={classNames('btn btn btn-outline-secondary')}
          type="button"
          onClick={onPrevious}
          disabled={isSubmitting}
        >
          {previousBtnLabel || 'Cancel'}
        </button>
      </div>
      <div className="mt-10">
        <LinkButton
          className="cw-formdraft-link"
          type="submit"
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Loading....' : submitBtnLabel || 'Submit'}
        </LinkButton>
      </div>
    </div>
  ) : (
    <div className="mt-30 mb-20">
      <button
        className="btn btn-primary mr-3"
        type="submit"
        disabled={isSubmitting}
      >
        {isSubmitting ? 'Loading....' : submitBtnLabel || 'Submit'}
      </button>
      <button
        className={classNames('btn btn btn-outline-secondary')}
        type="button"
        onClick={onPrevious}
        disabled={isSubmitting}
      >
        {previousBtnLabel || 'Cancel'}
      </button>
    </div>
  );
};

const SaveRowModel = ({
  isOpen,
  onCloseModal,
  handleCloseForm,
  handleSubmit,
  user,
  emailBriefing,
  savedRowData,
  isUserRequestorOrApprover
}) => {
  Modal.setAppElement('#root');
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCloseModal}
      className="cw-modal cw-modal--beeSaveRow"
      contentLabel="Save row form"
      style={{
        overlay: {
          backgroundColor: constants.EMAIL_OVERLAY_BACKGROUND
        }
      }}
    >
      <div className="email-overlay-wrapper">
        <span
          className="qna-guideline-close email-overlay-close-modal"
          onClick={handleCloseForm}
        ></span>
        <div className="email-overlay-scroll">
          <div className="d-flex mb-20">
            <div className="email-overlay-header-box">
              <EmailSaveRowIcon />
            </div>
            <div className="ml-10">
              <div
                className={'cw-heading--saascampaign send-test-email__title'}
              >
                {'Save tile'}
              </div>
              <p className="mt-10">
                Save a section of email as a reusable tile
              </p>
            </div>
          </div>
          <FormBuilder
            schema={saveRowFormSchema(
              handleCloseForm,
              handleSubmit,
              emailBriefing,
              savedRowData,
              isUserRequestorOrApprover,
              user
            )}
            user={user}
            isInModal
            customButtonsComp={CustomButtons}
          />
        </div>
      </div>
    </Modal>
  );
};

export default SaveRowModel;
