import React from 'react';
import { getCampaignStatus, getCampaignStatusText, getFormatedDateNew, getNewCampaignTypeName } from '../selectors/index';
import constant from '../constants';
import ReactTooltip from 'react-tooltip';
import BrandCampSummary from '../images/BrandCampSummary.png';
import QnASummary from '../images/Data_Summary.png';
import Summary from '../images/Summary.png';
import constants from '../constants/config.js';
import refresh from '../images/refresh.png';
import { epsilonMarket } from '../selectors/index';

const SummaryComponent = props => {
  const {
    brand,
    country,
    language,
    websiteType,
    submissionDate,
    campaignDetail,
    incentiveType,
    lastEditDate,
    uid,
    status
  } = props.data;

  let { campaignType, campaignId, type, isMasterData } = props.data;

  const { isCommunication, campaignConfig } = props;
  let campaignTypeName = campaignType;
  if (!isCommunication) {
    campaignTypeName = getNewCampaignTypeName(campaignType, incentiveType);
    if (campaignTypeName === 'Master data request') {
      type = 'Not applicable';
    }
  }

  let requestorEmail = campaignDetail && campaignDetail.requestersEmail;
  let isCampaignInfraEnabled =
    campaignDetail &&
    campaignDetail.campaignProductionUrl &&
    campaignDetail.campaignInfraDetails &&
    campaignDetail.campaignInfraDetails.isCampaignInfraSetupDone !== undefined
      ? campaignDetail.campaignInfraDetails.isCampaignInfraSetupDone
      : '';
  
  let isCampaignTypeSASS =
      (props.data &&
        props.data.websiteType &&
        props.data.websiteType.name === 'Adobe SaaS') ||
      (props.data && props.data.isPlatformAgnostic) || (props.data.isMasterData);

  let statusText = getCampaignStatusText(props.data); 
  
  let cwStatus = getCampaignStatus(status);
  return (
    <>
      <img
        src={
          props.isBrandSummary
            ? BrandCampSummary
            : props.isQnASummary
            ? QnASummary
            : Summary
        }
        alt={
          props.isBrandSummary
            ? BrandCampSummary
            : props.isQnASummary
            ? QnASummary
            : Summary
        }
        className="summary-component-img"
        height="60px"
        width="60px"
      />
      <div
        className={`summary-component ${
          incentiveType ? 'summary-component--summary-component-height' : ''
        } ${
          props.isQnASummary
            ? 'summary-component-qna'
            : !props.isBrandSummary && !props.isQnASummary
            ? 'summary-component--summary-component-form-section ml-4'
            : 'ml-4'
        } 
         mb-20`}
      >
        {props.isBrandSummary ? (
          <>
            <div className={`summary-component-message mt-4`}>
              <div className={'summary-component-icon'}>
                <div className="cw-heading--saascampaign mt-3 mb-3">
                  Brand Summary
                </div>
                <div className="row mb-1">
                  <div className="col-sm-4">
                    <div className="">
                      <strong>Brand</strong>
                    </div>
                  </div>
                  <div className="col-sm-8">
                    <div className="">{`${brand && brand.name} (${brand &&
                      brand.code})`}</div>
                  </div>
                </div>
                <div className="row mb-1">
                  <div className="col-sm-4">
                    <div className="">
                      <strong>Country</strong>
                    </div>
                  </div>
                  <div className="col-sm-8">
                    <div className="" style={{ lineBreak: 'anywhere' }}>
                      {country && country.name}
                    </div>
                  </div>
                </div>
                <div className="row mb-1">
                  <div className="col-sm-4">
                    <div className="">
                      <strong>Language</strong>
                    </div>
                  </div>
                  <div className="col-sm-8">
                    <div className="" style={{ lineBreak: 'anywhere' }}>
                      {language && language.name}
                    </div>
                  </div>
                </div>
                {!isCommunication ? (
                  <div className="row mb-1">
                    <div className="col-sm-4">
                      <div className="">
                        <strong>Website</strong>
                      </div>
                    </div>
                    <div className="col-sm-8">
                      <div className="">{websiteType && websiteType.name}</div>
                    </div>
                  </div>
                ) : null}
              </div>
              <div
                className={`vr ${incentiveType ? 'vr--vr-height' : ''}`}
              ></div>
              <div className={'summary-component-icon'}>
                <div className="cw-heading--saascampaign ml-4 mt-2 mb-3">
                  Campaign Summary
                </div>
                <div className="row ml-2 mb-1">
                  <div className="col-sm-4">
                    <div className="">
                      <strong>Campaign</strong>
                    </div>
                  </div>
                  <div className="col-sm-8">
                    <div className="">{campaignTypeName}</div>
                  </div>
                </div>
                {isCommunication ? (
                  <div className="row ml-2 mb-1">
                    <div className="col-sm-4">
                      <div className="">
                        <strong>Request date</strong>
                      </div>
                    </div>
                    <div className="col-sm-8">
                      <div className="">{submissionDate}</div>
                    </div>
                  </div>
                ) : (
                  <div className="row ml-2 mb-1">
                    <div className="col-sm-4">
                      <div className="">
                        {isMasterData ? (
                          <strong>Request</strong>
                        ) : (
                          <strong>Form</strong>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-8">
                      <div className="">
                        {isMasterData ? (
                          <>
                            <strong>Master data only</strong>&nbsp;
                            <ReactTooltip
                              id="masterToolTip"
                              place="top"
                              type="info"
                              multiline={true}
                              className="cw-toolip custom-cw-toolip"
                            />
                            <span
                              className="cw-icon cw-icon--help"
                              data-tip={`This request is for master data only, required for offline ingestion or to build a custom form not available in Campaign Wizard.<br/>Master data includes Sign up IDs and Q&A IDs (if applicable). Campaign Wizard does not generate a sign-up form for such requests.`}
                              currentitem="false"
                              data-for="masterToolTip"
                            >
                              <i className="fas fa-question-circle"></i>
                            </span>{' '}
                          </>
                        ) : (
                          `${type} form`
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {incentiveType ? (
                  <>
                    <div className="row ml-2 mb-1">
                      <div className="col-sm-4">
                        <div className="">
                          <strong>Incentive</strong>
                        </div>
                      </div>
                      <div className="col-sm-8">
                        <div className="" style={{ lineBreak: 'anywhere' }}>
                          {campaignDetail.instantWinLose &&
                          incentiveType === 'Competitions'
                            ? `${incentiveType} - Instant win`
                            : incentiveType}
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
                <div className="row ml-2 mb-1">
                  <div className="col-sm-4">
                    <div className="">
                      <strong>Requestor</strong>
                    </div>
                  </div>
                  <div className="col-sm-8">
                    <div className="" style={{ lineBreak: 'anywhere' }}>
                      {requestorEmail}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : props.isQnASummary ? (
          <>
            <div
              className="row"
              style={{ marginTop: '4%', marginLeft: '1.5%' }}
            >
              <div className="col-sm-8">
                <div className="cw-heading--qnatile">
                  New master data requested
                </div>
              </div>
            </div>
            {props.isQnaPage ? (
              <div
                className={`summary-component-message mt-2 col-sm-12 flex-column`}
                style={{ marginLeft: '1%' }}
              >
                <p>{constants.QNA_MASTER_DATA_TILE_MSG}</p>
                <p>{constants.QNA_MASTER_DATA_TILE_SUB_MSG}</p>
              </div>
            ) : (
              <div className={`summary-component-message mt-2 col-sm-12`}>
                <div className={'summary-component-icon'}>
                  <div className="row mb-1">
                    <div className="col-sm-12">
                      <div className="">
                        <strong>Questions</strong>
                      </div>
                    </div>
                  </div>
                  {props.qnaTileData && props.qnaTileData.showQuesCategory ? (
                    <>
                      <div className="row mb-1">
                        <div className="col-sm-12">
                          <div className="">
                            New enrichment questions:{' '}
                            {props.qnaTileData
                              ? props.qnaTileData.enrichmentQues
                              : 0}
                          </div>
                        </div>
                      </div>
                      <div className="row mb-1">
                        <div className="col-sm-12">
                          <div className="">
                            New operational questions:{' '}
                            {props.qnaTileData
                              ? props.qnaTileData.operationQues
                              : 0}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="row mb-1">
                      <div className="col-sm-12">
                        <div className="">
                          New questions:{' '}
                          {props.qnaTileData
                            ? props.qnaTileData.newQuestion
                            : 0}
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="row mb-1">
                    <div className="col-sm-12">
                      <div className="">
                        New translations:{' '}
                        {props.qnaTileData
                          ? props.qnaTileData.newTranslatedQues
                          : 0}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`vr ${
                    props.isQnASummary ? 'vr--vr-qnaheight' : ''
                  }`}
                ></div>
                <div className={'summary-component-icon'}>
                  <div className="row ml-2 mb-1">
                    <div className="col-sm-12">
                      <div className="">
                        <strong>Answers</strong>
                      </div>
                    </div>
                  </div>
                  <div className="row ml-2 mb-1">
                    <div className="col-sm-12">
                      <div className="">
                        New answers:{' '}
                        {props.qnaTileData ? props.qnaTileData.newAnswer : 0}
                      </div>
                    </div>
                  </div>
                  <div className="row ml-2 mb-1">
                    <div className="col-sm-12">
                      <div className="">
                        New translations:{' '}
                        {props.qnaTileData
                          ? props.qnaTileData.newTranslatedAns
                          : 0}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            <div
              className={`summary-component-message mt-4`}
              style={{
                padding: lastEditDate !== undefined ? '0% 3%' : '1% 3%'
              }}
            >
              <div className={'summary-component-icon'}>
                <div className="row">
                  <div className="col-sm-9">
                    <div className="cw-heading--saascampaign mt-3 mb-3">
                      Form Summary
                    </div>
                  </div>
                  <div className="col-sm-3 btn-summary-preview cw-print-hide" style={{marginBottom : '5px'}}>
                    <a
                      className="btn btn-primary"
                      href={props.previewUrl}
                      target={'_blank'}
                    >
                      Preview Form
                    </a>
                  </div>
                </div>
                {cwStatus == 'Completed' && (
                  <div className="mt-1">
                    <div className="alert alert-success w-100">
                      <p>Your form widget is now generated</p>
                    </div>
                  </div>
                )}
                <div className="row mb-1">
                  <div className="col-sm-4">
                    <div className="">
                      <strong>Form ID</strong>
                    </div>
                  </div>
                  <div className="col-sm-8">
                    <div className="">{uid}</div>
                  </div>
                </div>
                {constant.featureFlags.ENABLE_CAMPAIGN_ID_GOVERNANCE && (!epsilonMarket(country && country.code)) ? (
                  <div className="row mb-1">
                    <div className="col-sm-4">
                    
                      <div className="">
                        <strong>{`${campaignConfig.LABEL_SIGN_UP_ID}`}</strong>
                      </div>
                    </div>
                    <div className="col-sm-8">
                      <div>
                        <span>{campaignId}</span>
                        &nbsp;
                        <ReactTooltip
                          id="acquisitionIdToolTip"
                          place="right"
                          type="info"
                          multiline={true}
                          className="cw-toolip custom-cw-toolip"
                        />
                        <span
                          className="cw-icon cw-icon--help"
                          data-tip={`Campaign IDs are now known as ${campaignConfig.LABEL_SIGN_UP_ID}s to better explain the purpose of the field to uniquely identify the  initiative through which a consumer signed up`}
                          currentitem="false"
                          data-for="acquisitionIdToolTip"
                        >
                          <i className="fas fa-question-circle"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                ) : null}

                <div className="row mb-1">
                  <div className="col-sm-4">
                    <div className="">
                      <strong>Preview URL</strong>
                    </div>
                  </div>
                  <div className="col-sm-8">
                    <div className="">
                      <a
                        href={props.previewUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View Preview URL here &nbsp;
                        <i className="fas fa-external-link-alt"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="row mb-1">
                  <div className="col-sm-4">
                    <div className="">
                      <strong>Integration Guide</strong>
                    </div>
                  </div>
                  <div className="col-sm-8">
                    <div className="">
                      <a
                        href={constant.FORM_WIDGET_INTEGRATION_GUIDE}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View form integration guide &nbsp;
                        <i className="fas fa-external-link-alt"></i>
                      </a>
                    </div>
                  </div>
                </div>
                {isCampaignInfraEnabled !== '' ? (
                  <div className="row mb-1">
                    <div className="col-sm-4 mt-1">
                      <div className="">
                        <strong>Infra Status </strong>
                      </div>
                    </div>
                    <div className="col-sm-8">
                      {!isCampaignInfraEnabled ? (
                        <span>
                          <span style={{ color: 'red' }}> Disabled</span>
                          <a
                            href={constants.KANA_URL}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            &nbsp; Raise Kana &nbsp;
                            <i className="fas fa-external-link-alt"></i>
                          </a>
                        </span>
                      ) : (
                        <span> Enabled </span>
                      )}
                      <ReactTooltip
                        place="right"
                        id="infraStatusValue"
                        type="info"
                        multiline={true}
                        className="cw-tooltip custom-cw-toolip"
                      />
                      <span
                        className="cw-icon cw-icon--help"
                        data-for="infraStatusValue"
                        data-tip={
                          isCampaignInfraEnabled
                            ? websiteType &&
                              websiteType.name === constants.WEBSITETYPE_SAAS
                              ? constants.SAAS_ENABLED_INFRA_STATUS_TOOLTIP_TEXT
                              : constants.NONSAAS_ENABLED_INFRA_STATUS_TOOLTIP_TEXT
                            : constants.DISABLED_INFRA_STATUS_TOOLTIP_TEXT
                        }
                      >
                        {'          '}
                        <i className="fas fa-question-circle"></i>
                      </span>
                      <ReactTooltip
                        id={'refresh-status'}
                        place="right"
                        type="info"
                        multiline={true}
                        className="cw-tooltip"
                      />
                      <img
                        data-tip={'Refresh status'}
                        style={{ cursor: 'pointer' }}
                        data-for="refresh-status"
                        src={refresh}
                        onClick={props.handleRefresh}
                        className="refresh-icon"
                        alt="refresh"
                        width={20}
                      />
                    </div>
                  </div>
                ) : null}
                 {isCampaignInfraEnabled !== '' &&  isCampaignInfraEnabled? (
                  <div className="row mb-1">
                    <div className="col-sm-4 mt-1">
                      <div className="">
                        <strong>V3 Data collection API endpoint</strong>
                      </div>
                    </div>
                    <div className="col-sm-8">
                      <span>
                      {isCampaignTypeSASS &&
                        (statusText === 'Completed' || statusText === 'Processing') &&
                        isCampaignInfraEnabled
                          ? campaignDetail &&
                          campaignDetail.campaignInfraDetails &&
                          campaignDetail.campaignInfraDetails.v3ApiEndpoint
                          : ''}
                      </span>
                      <ReactTooltip
                        place="right"
                        id={'V3 Data collection API endpoint'}
                        type="info"
                        multiline={true}
                        className="cw-tooltip custom-cw-toolip"
                      />
                      <span
                        className="cw-icon cw-icon--help"
                        data-for="V3 Data collection API endpoint"
                        data-tip={
                          isCampaignTypeSASS
                            ?constants.SAAS_V3_ENDPOINT_TOOLTIP_TEXT
                            : constants.NONSAAS_V3_ENDPOINT_TOOLTIP_TEXT
                        }
                      >
                        {'          '}
                        <i className="fas fa-question-circle"></i>
                      </span>
                    </div>
                  </div>
                ) : null}
                 {isCampaignInfraEnabled !== '' && isCampaignInfraEnabled? (
                  <div className="row mb-1">
                    <div className="col-sm-4 mt-1">
                      <div className="">
                        <strong>V3 Data collection API key</strong>
                      </div>
                    </div>
                    <div className="col-sm-8">
                      <span>
                      {isCampaignTypeSASS &&
                        (statusText === 'Completed' || statusText === 'Processing') &&
                        isCampaignInfraEnabled
                          ? campaignDetail &&
                          campaignDetail.campaignInfraDetails &&
                          campaignDetail.campaignInfraDetails.xApiKey
                          : ''}
                      </span>
                      <ReactTooltip
                        place="right"
                        id={'V3 Data collection API key'}
                        type="info"
                        multiline={true}
                        className="cw-tooltip custom-cw-toolip"
                      />
                      <span
                        className="cw-icon cw-icon--help"
                        data-for="V3 Data collection API key"
                        data-tip={
                          isCampaignTypeSASS
                            ? constants.SAAS_V3_API_KEY_TOOLTIP_TEXT
                            : constants.NONSAAS_V3_API_KEY_TOOLTIP_TEXT
                        }
                      >
                        {'          '}
                        <i className="fas fa-question-circle"></i>
                      </span>
                    </div>
                  </div>
                ) : null}

                {lastEditDate !== undefined ? (
                  <div className="row mb-1">
                    <div className="col-sm-4">
                      <div className="">
                        <strong>Last Edited On</strong>
                      </div>
                    </div>
                    <div className="col-sm-8">
                      <div className="">{getFormatedDateNew(lastEditDate)}</div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default SummaryComponent;
