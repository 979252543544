import React from 'react';
import {
  getFormatedDate,
  generateRoleStatusBasedUrl
} from '../../../selectors';
import constant from '../../../constants';
import moment from 'moment';
import IncetiveCompetitions from '../../../images/incentive-competitions.png';
import IncentiveSamples from '../../../images/incentive-samples.png';
import SimpleSignup from '../../../images/simple-sign-up.png';
import Status from '../Status/Status';
import TwoValueColumn from 'components/atoms/TwoValueColumn/TwoValueColumn';
import Column from 'components/atoms/Column/Column';

const iconsForCampaignType = {
  'Incentive competitions': IncetiveCompetitions,
  'Incentive samples': IncentiveSamples,
  'Simple sign up': SimpleSignup
};

const roleForFilter = constant.dashboardRoleFilter;

const CampaignListRow = ({
  campaign,
  index,
  defaultFiltersEnabled,
  authorizedUserData,
  role
}) => {
  campaign.brand = campaign.brand || {};
  campaign.country = campaign.country || {};
  let {
    name,
    uid,
    type,
    campaignType,
    lastUpdatedDate,
    status,
    campaignDetail,
    brand: { name: brandname },
    country: { name: countryname }
  } = campaign;
  // TODO: Check if there are libaries to convert UTC to local
  //lastUpdatedDate = lastUpdatedDate.split('T')[0].replace(/-/g, '/');
  let lastUpdateDate = '';
  lastUpdateDate = getFormatedDate(lastUpdatedDate, false, false);
  lastUpdateDate = lastUpdateDate.split('-')[0];

  const { startDate, endDate } = campaign.campaignDetail || {};

  return (
    <tr key={index}>
      <TwoValueColumn
        icon={{
          src:
            iconsForCampaignType[campaignType] ||
            'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII=',
          alt: `${campaignType} icon`
        }}
        value1={campaignType}
        value2={
          type && type === 'Sign-up with Questions & Answers'
            ? 'Sign up with Q&A'
            : type && type === 'Simple sign-up'
            ? 'Simple sign up'
            : ''
        }
      />
      <td className="max-width-column">
        <div className="d-flex flex-column">
          {defaultFiltersEnabled ? (
            <a
              href={
                '/campaign-wizard/campaign/' +
                uid +
                (constant.roleBasedPages[role]
                  ? '/' + constant.roleBasedPages[role]
                  : '')
              }
              title={name}
              className="one-line dark-text"
            >
              {name || 'Untitled'}
            </a>
          ) : (
            <a
              href={generateRoleStatusBasedUrl(
                uid,
                role,
                status,
                campaignType,
                type
              )}
              title={name}
              className="one-line dark-text"
            >
              {name || 'Untitled'}
            </a>
          )}
          <div className="light-text">
            {startDate ? `${moment(startDate).format('DD MMM YYYY')} - ` : ''}
            {campaignDetail && campaignDetail.isAlwaysOn
              ? 'Always on'
              : endDate
              ? moment(endDate).format('DD MMM YYYY')
              : ''}
          </div>
        </div>
      </td>
      <TwoValueColumn value1={brandname} value2={countryname} />
      <Column value={lastUpdateDate} />
      <td className="center">
        <Status
          authorizedUserData={authorizedUserData}
          roleForFilter={roleForFilter}
          campaign={campaign}
          status={status}
        />
      </td>
    </tr>
  );
};

export default CampaignListRow;
