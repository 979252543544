import React, { useEffect } from 'react';
import { Input } from '../../atoms/Input';
import { FieldError } from '../../atoms/FieldError';
import { get } from 'lodash';
import { useWatch } from 'react-hook-form';
import { FormDatePicker } from '../FormDatePicker';
import { FormReactSelect } from '../FormReactSelect';
import moment from 'moment';
import { DateTimeInput } from 'components/molecules/EmailTriggerInput/DateTimeInput';

const formatDateTime = (dateTime, format) => {
  if (dateTime) {
    const momentParsedDate = moment.utc(dateTime);
    return momentParsedDate.isValid() ? momentParsedDate.format(format) : '';
  }
  return '';
};

const FixedDataAndTimefields = ({
  control,
  watchInput,
  errors,
  name,
  register,
  dateFormat,
  defaultValue,
  setValue,
  variant,
  isSms,
  markAsSchedule
}) => {
  const defaultType = get(defaultValue, 'type');
  const triggerType = useWatch({
    control,
    name: watchInput,
    defaultValue: defaultType
  });
  const currentDate = moment();

  return triggerType === 'fixedDateAndtime' ? (
    <div className="pt-0">
      <DateTimeInput
        name={name}
        variant={variant}
        currentDate={currentDate}
        control={control}
        errors={errors}
        defaultValue={get(defaultValue, 'details')}
        setValue={setValue}
        placeholder="DD/MM/YYYY"
        isSms={isSms}
        disabledValidDateCheck={markAsSchedule}
      />
    </div>
  ) : null;
};

const TriggerConditionField = ({
  control,
  watchInput,
  errors,
  name,
  dateFormat,
  register,
  setValue,
  labelClass,
  defaultValue,
  trigger
}) => {
  const defaultType = get(defaultValue, 'type');
  const triggerType = useWatch({
    control,
    name: watchInput,
    defaultValue: defaultType
  });

  const errorMessages = get(errors, name);
  const hasError = !!(errors && errorMessages);
  const currentDate = moment();
  return defaultType === 'dynamicTrigger' ? (
    <div className="pt-0">
      <FormDatePicker
        name={`${name}.details.triggerConditionStartDate`}
        label="Start date"
        control={control}
        errors={errors}
        dateFormat={'DD/MM/YYYY'}
        timeFormat={false}
        defaultDisabled={false}
        defaultValue={get(defaultValue, 'details.triggerConditionStartDate')}
        setValue={setValue}
        placeholder="DD/MM/YYYY"
      />

      <FormDatePicker
        name={`${name}.details.triggerConditionEndDate`}
        label="End date"
        control={control}
        errors={errors}
        dateFormat={'DD/MM/YYYY'}
        timeFormat={false}
        defaultDisabled={false}
        defaultValue={tempfunction(defaultValue)}
        setValue={setValue}
        placeholder="DD/MM/YYYY"
        onWatch={(results, properties, setProperties) => {
          if (results) {
            setValue(`${name}.details.triggerConditionEndDate`, 'DD/MM/YYYY');
            if (trigger) {
              trigger(`${name}.details.triggerConditionEndDate`);
            }
          } else {
            const dateValue = tempfunction(defaultValue);
            if (dateValue) {
              setValue(
                `${name}.details.triggerConditionEndDate`,
                moment.utc(tempfunction(defaultValue))
              );
            }
          }

          setProperties({
            disabled: results
          });
        }}
        defaultWatchValue={get(
          defaultValue,
          'details.triggerConditionAlwaysOn'
        )}
        watchInput={`${name}.details.triggerConditionAlwaysOn`}
      />

      <div className="form-check mb-10" aria-live="polite">
        <Input
          name={`${name}.details.triggerConditionAlwaysOn`}
          {...(register &&
            register(`${name}.details.triggerConditionAlwaysOn`))}
          id="alwaysOn"
          type="checkbox"
          defaultChecked={get(defaultValue, 'details.triggerConditionAlwaysOn')}
        />
        <label htmlFor="alwaysOn" className="form-check-label">
          <p className={labelClass}>Always on</p>
        </label>
      </div>
    </div>
  ) : null;
};

export const EmailSchduleInput = ({
  name,
  rules,
  register,
  label,
  errors,
  setValue,
  control,
  defaultValue,
  emailType,
  variant,
  isSms,
  markAsSchedule,
  ...props
}) => {
  return (
    <div className="form-group " aria-live="polite">
      <div>
        <input
          type="hidden"
          {...(register && register(`${name}.type`))}
          defaultValue={get(defaultValue, `type`)}
        />
        <FixedDataAndTimefields
          control={control}
          watchInput={`${name}.type`}
          errors={errors}
          register={register}
          name={name}
          dateFormat={props.dateFormat}
          defaultValue={defaultValue}
          setValue={setValue}
          variant={variant}
          isSms={isSms}
          markAsSchedule={markAsSchedule}
        />
        <TriggerConditionField
          control={control}
          watchInput={`${name}.type`}
          errors={errors}
          name={name}
          dateFormat={props.dateFormat}
          setValue={setValue}
          register={register}
          labelClass={props.labelClass}
          defaultValue={defaultValue}
          trigger={props.trigger}
        />
      </div>
      <FieldError errors={errors} name={`${name}.type`} />
    </div>
  );
};

export const DONT_SEND = 'DontSend';

export const sentAfterEmail = data => {
  if (data && data.sendAfter) {
    const isDontSend = data.sendAfter.value !== DONT_SEND;
    return [
      {
        label: 'Send follow up',
        value: isDontSend
          ? `Yes, after ${get(data, 'sendAfter.label')}`
          : `No, don't send`
      }
    ];
  } else {
    return [];
  }
};

export const DisplayEmailTriggerInput = data => {
  if (data && data.type) {
    if (data.type === 'fixedDateAndtime') {
      const emailDeliveryDate = get(data, 'details.emailDeliveryDate');
      const emailDeliveryTime = get(data, 'details.emailDeliveryTime');
      const list = [
        {
          label: 'Email trigger',
          value: 'Fixed date & time'
        }
      ];

      if (emailDeliveryDate) {
        list.push({
          label: 'Email delivery date',
          value: formatDateTime(emailDeliveryDate, 'DD/MM/YYYY')
        });
      }

      if (emailDeliveryTime) {
        list.push({
          label: 'Email delivery time',
          value: `${formatDateTime(emailDeliveryTime, 'hh:mm a')} UTC`
        });
      }

      list.push({
        label: 'Optimize send time',
        value: get(data, 'details.optimizeSendTime') ? 'Yes' : 'No'
      });

      return list;
    }
    if (data.type === 'dynamicTrigger') {
      return [
        {
          label: 'Email trigger',
          value: 'Dynamic trigger'
        },
        {
          label: 'Start date',
          value: formatDateTime(
            data.details.triggerConditionStartDate,
            'DD/MM/YYYY'
          )
        },
        {
          label: 'End date',
          value: get(data, 'details.triggerCondition.alwaysOn')
            ? ''
            : get(data, 'details.triggerConditionAlwaysOn')
            ? 'Always on'
            : formatDateTime(data.details.triggerConditionEndDate, 'DD/MM/YYYY')
        },
        {
          label: 'Trigger condition',
          value: get(data, 'details.triggerCondition.label')
        }
      ];
    }
  }
  return [
    {
      label: 'Email trigger',
      value: ''
    }
  ];
};

const tempfunction = data => {
  const t = get(data, 'details.triggerConditionEndDate');
  console.log('t: ', t, typeof t);
  if (t === null) {
    return 'DD/MM/YYYY';
  } else if (t === undefined) {
    return undefined;
  } else {
    const formatedDate = t;
    return formatedDate;
  }
};
