import React from 'react';
import { Modal } from 'semantic-ui-react';

const GenericModal = ({ open, setOpen, size, header, children, actions, classes, closeModal }) => {
  return (
    <Modal
      open={open}
      size={size}
      closeIcon
      className={`ui-modal-container ${classes}`}
      onClose={() => closeModal()}
      onOpen={() => setOpen(true)}
    >
      <Modal.Header>{header}</Modal.Header>
      <Modal.Content>
        <Modal.Description>{children}</Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        {actions}
      </Modal.Actions>
    </Modal>
  );
};

export default GenericModal;
