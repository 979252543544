import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ReportTabPanelContent from '../ReportTabPanelContent';
import { NavLink } from 'react-router-dom';
import constant from '../../constants';
import { fetchUserData } from '../../actions/user-management';
class ReportManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabIndex: 0
    };
  }
  render() {
    let { asyncInProgress } = this.props;
    return (
      <>
        <div className="cw-section cw-overview ml-20">
          <div className="col-sm-12 mb-20">
            <div className="row mb-10">
              <div className="col-sm-6">
                <NavLink
                  className="cw-color--primary cw-breadcrum"
                  to={'/campaign-wizard'}
                >
                  <i className="fas fa-angle-left"></i> Campaigns
                </NavLink>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6">
                <h2 className="cw-heading--secondary d-flex align-items-center mb-10">
                  <span className="mr-3">Reports</span>
                </h2>
              </div>
            </div>
          </div>
          <div className="col-sm-12">
            <Tabs
              selectedIndex={this.state.tabIndex}
              onSelect={tabIndex => {
                this.setState({ tabIndex });
                constant.userTabIndex[tabIndex] === 'requester'
                  ? this.props.fetchDomainData()
                  : this.props.fetchUserData(
                      constant.userTabIndex[tabIndex],
                      '',
                      '',
                      'tabchange'
                    );
              }}
            >
              <TabList>
                <Tab>Dashboard</Tab>
                <Tab>Downloadable reports</Tab>
              </TabList>

              <TabPanel>
                <ReportTabPanelContent
                  panelType="dashboard"
                  asyncInProgress={asyncInProgress}
                />
              </TabPanel>
              <TabPanel>
                <ReportTabPanelContent
                  panelType="reports"
                  asyncInProgress={asyncInProgress}
                />
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  asyncInProgress: state.user.asyncInProgress
});

export default connect(mapStateToProps, {
  fetchUserData
})(ReportManagement);
