import React from 'react';

const EditPermissionsSection = props => {
  let selectedUsers =
    props.data &&
    props.data.users &&
    props.data.users.length > 0 &&
    !props.data.isEveryoneEditor
      ? true
      : false;

  let isOnlyRequestor =
    props.data &&
    !props.data.isEveryoneEditor &&
    props.data.users &&
    props.data.users.length === 0
      ? true
      : false;

  let isEveryOneEditor =
    props.data && props.data.isEveryoneEditor ? true : false;
  return (
    <div className="cw-campaign--review-section mb-40">
      <div className="mb-20">
        <h3 className="cw-heading--primary">Edit Permissions</h3>
      </div>
      <div className="cw-campaign--review-section-content">
        <div className="cw-striped-profile-row">
          <div className="row">
            <div className="col-sm-4">
              <strong> Form Edit Permissions</strong>
            </div>
            {props.data.isEveryoneEditor !== undefined ? (
              <div className="col-sm-8">
                {selectedUsers ? 'Requestor and selected users' : null}
                {isEveryOneEditor ? 'Everyone' : null}
                {isOnlyRequestor ? 'Only Requestor' : null}
              </div>
            ) : (
              <div className="col-sm-8">Everyone</div>
            )}
          </div>
        </div>
        <div
          className={`cw-striped-profile-row ${
            selectedUsers ? '' : 'cw-hidden'
          }`}
        >
          <div className="row">
            <div className="col-sm-4">
              <strong>Added Users</strong>
            </div>
            <div className="col-sm-8">
              {selectedUsers
                ? props.data &&
                  props.data.users.map(elem => elem.email).join(', ')
                : ''}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditPermissionsSection;
