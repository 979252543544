import React from 'react';

export default function TwoValueColumn({ value1, value2, icon }) {
  return (
    <td className="max-width-column center">
      <div className="d-flex">
        {icon ? (
          <div
            className="d-flex align-items-center"
            style={{ paddingRight: 13 }}
          >
            <img
              src={icon.src}
              alt={icon.alt}
              style={{ height: 20, width: 20 }}
            />
          </div>
        ) : null}
        <div className="d-flex flex-column">
          <span className="dark-text">{value1}</span>
          {value2 ? <span className="light-text">{value2}</span> : null}
        </div>
      </div>
    </td>
  );
}
