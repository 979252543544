import React, { useState, forwardRef } from 'react';
import MultiRangeSlider from 'multi-range-slider-react';

const RangeSlider = forwardRef(({ min, max, onChange, value, label, id, defaultValue}, ref) => {
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(0);
  const [minValue2, setMinValue2] = useState(0);
  const [maxValue2, setMaxValue2] = useState(0);

  return (
    <div className="form-group">
        <label htmlFor={id}>{label}</label>
      <div className="d-flex justify-content-between">
        <span>{min}</span>
        <span>{max}</span>
      </div>
      <MultiRangeSlider
        min={min}
        max={max}
        minValue={defaultValue[0]}
        maxValue={defaultValue[1]}
        onInput={e => {
          setMinValue(e.minValue);
          setMaxValue(e.maxValue);
        }}
        onChange={e => {
          setMinValue2(e.minValue);
          setMaxValue2(e.maxValue);
          onChange([e.minValue, e.maxValue]);
        }}
        ruler={false}
        label={false}
        baseClassName="cw-multi-range-slider"
      ></MultiRangeSlider>
    </div>
  );
});

export default RangeSlider;
