import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEmailBriefing } from 'actions/emailbriefing/emailDesign';
import PageHeader from 'components/organisms/PageHeader/PageHeader';
import TemplatePreview from 'components/organisms/TemplatePreview/TemplatePreview';
import BackAndNext from 'components/organisms/BackAndNext/BackAndNext';
import {
  deleteEmailCampaignDetails,
  updateEmailCampaignDetails
} from 'actions/emailbriefing/emailCampaignDetails';
import EmailTest from 'components/EmailTest';
import Modal from 'react-modal';
import { exportEmailHTML } from 'services/emailTemplateEdit';
import EmailSummaryCards from 'components/organisms/EmailSummaryCards/EmailSummaryCards';
import EmailOverviewTables from 'components/organisms/EmailOverviewTables/EmailOverviewTables';
import EmailApproverInfo from 'components/organisms/EmailApproverInfo';
import EmailRejectModel from 'components/organisms/EmailRejectModel';
import TemplatePreviewOverlay from 'pages/EmailCampaignTemplate/templatePreviewOverlay';
import constants from 'constants/config';
import EmailGuideline from 'components/EmailGuideline';
import EmailGuidelineText from 'components/organisms/EmailGuidelineText/EmailApproverGuideline';
import WinningVariantInfo from 'components/organisms/WinningVariantInfo';
import { fetchHistoryLogs } from 'actions/history';
import HistoryComponent from 'components/history';
import Toolbar from 'components/common/toolbar';
import { setNotificationMessage } from 'actions/dashboard';
import { openFeedbackForm } from 'actions/feedbackForm';
import Layout from 'components/organisms/Layout/Layout';
import Loader from 'components/Loader';

export default function EmailApproval(props) {
  const { match, history } = props;
  const dispatch = useDispatch();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [RejectModelOpen, setRejectModelOpen] = useState(false);
  const [isTemplatePreviewOpen, setIsTemplatePreviewOpen] = useState(false);
  const [selectedTemplateForPreview, setSelectedTemplateForPreview] = useState(
    {}
  );
  const [aprGuideModel, setAprGuideModel] = useState(false);
  const uid = props.match.params.id;

  useEffect(() => {
    dispatch(fetchHistoryLogs(uid));
    dispatch(getEmailBriefing(uid));
  }, []);

  const emailProps = useSelector(state => ({
    historyLogs: state.historyLogs,
    emailBriefing: state.emailDesign.emailBriefing,
    isLoading: state.emailDesign.isLoading,
    campaignConfig: state.app.campaignConfig,
    authorizedUserData: state.authorized.authorizedUserData
  }));

  if (!emailProps.emailBriefing) return null;
  const { emailTemplateEditId, summary, status } =
    emailProps && emailProps.emailBriefing;
  const campaignName = (summary && summary.campaignName) || 'Untitled';
  const { isLoading, authorizedUserData } = emailProps;

  const handleApprovalClick = () => {
    const paramUid = props.match.params.id;
    const dataToSend = {
      status: constants.featureFlags.EMAIL_EXECUTION_REQUIRED
        ? constants.EMAIL_STATUS.AWAITING_SETUP
        : constants.EMAIL_STATUS.SUBMITTED, // Awaiting setup
      userEmail: props.userEmail,
      userName: props.userName,
      isEmailApprovalRequired: constants.featureFlags.EMAIL_APPROVAL_REQUIRED
    };

    if (dataToSend.status === constants.EMAIL_STATUS.SUBMITTED) {
      dataToSend.completionDate = new Date();
    }

    dispatch(
      updateEmailCampaignDetails(paramUid, dataToSend, (error, result) => {
        if (result) {
          const uid = props.match.params.id;
          dispatch(
            setNotificationMessage(
              constants.EMAIL_CAMPAIGN_APPROVAL_MESG.marketApproved.message,
              undefined,
              constants.EMAIL_CAMPAIGN_APPROVAL_MESG.marketApproved.type
            )
          );
          props.history.push(`/campaign-wizard/emailbriefing/${uid}`);
        }
      })
    );
  };
  const handleRejectClick = () => {
    setRejectModelOpen(true);
  };

  const handleOnSubmit = rejectedComment => {
    const paramUid = props.match.params.id;
    const dataToSend = {
      status: '5', // Rejected
      userEmail: props.userEmail,
      userName: props.userName,
      comment: rejectedComment
    };
    dispatch(
      updateEmailCampaignDetails(paramUid, dataToSend, (error, result) => {
        if (result) {
          const uid = props.match.params.id;
          props.history.push(`/campaign-wizard/email-dashboard`);
        }
      })
    );
  };

  const onClickEdit = () => {
    const uid = match.params.id;
    history.push(`/campaign-wizard/emailbriefing/${uid}/email-editor`);
  };

  const openTestEmailModel = () => {
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };

  const closeRejectModelClick = () => {
    setRejectModelOpen(false);
  };

  /**
   * Export button click handler
   */
  const onExportHandler = () => {
    const res = exportEmailHTML(
      props.match.params.id,
      props.userEmail,
      props.userName,
      { campaignName }
    );
  };

  const handleTemplatePreview = template => {
    setIsTemplatePreviewOpen(true);
    setSelectedTemplateForPreview(template);
  };

  const handleCloseTemplatePreview = () => {
    setIsTemplatePreviewOpen(false);
    setSelectedTemplateForPreview({});
  };

  const isOpenAprGuidelineModel = () => {
    setAprGuideModel(true);
  };

  const isCloseAprGuidelineModel = () => {
    setAprGuideModel(false);
  };

  const deleteEmailBriefing = () => {
    dispatch(
      deleteEmailCampaignDetails(uid, () => {
        dispatch(
          setNotificationMessage({
            message: `${summary && summary.campaignName} has been deleted.`,
            type: 'warning'
          })
        );
        history.push(`/campaign-wizard/email-dashboard`);
      })
    );
  };

  const handleOnOpenFeedback = () => {
    dispatch(
      openFeedbackForm({
        campaignUid: props.match.params.id,
        userId: props.authorizedUserData._id,
        defaultCampaignRequest: 'email',
        showCampaignRequest: false
      })
    );
  };

  const refetchEmailBriefing = () => {
    const uid = props.match.params.id;
    // if (!emailBriefing || emailBriefing.uid !== uid) {
    dispatch(getEmailBriefing(uid, { disableLoader: true }));
  };

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="cw-modal cw-modal--test-email-model"
        contentLabel="Field Types"
        style={{
          overlay: {
            backgroundColor: constants.EMAIL_OVERLAY_BACKGROUND
          }
        }}
      >
        <EmailTest
          onClose={closeModal}
          userEmail={props.userEmail}
          uid={props.match.params.id}
          userName={props.userName}
          userGroup={props.authorizedUserData.group}
          emailBriefing={emailProps.emailBriefing}
          refetchEmailBriefing={refetchEmailBriefing}
        />
      </Modal>
      <EmailRejectModel
        modalIsOpen={RejectModelOpen}
        closeModal={closeRejectModelClick}
        handleOnSubmit={handleOnSubmit}
      />

      <Modal
        scrollable={true}
        isOpen={aprGuideModel}
        onRequestClose={isCloseAprGuidelineModel}
        className="cw-modal cw-modal--qnadetail"
        contentLabel="Campaign Wizard 2.0"
        style={{
          overlay: {
            backgroundColor: constants.EMAIL_OVERLAY_BACKGROUND
          }
        }}
      >
        <EmailGuideline
          closeIsEditModal={isCloseAprGuidelineModel}
          data={constants.EMAIL_APPROVER_GUIDE_LINES}
          isEmailCampaign={true}
        />
      </Modal>
      <div className="skypink ml-5 mr-5">
        <div className="cw-section cw-overview">
          <div className="draft_button_section_main">
            <PageHeader
              heading={(summary && summary.campaignName) || 'Untitled'}
              status={status}
              review={true}
            />
            <div className="row">
              <div className={`col-12 cw-print-hide`}>
                <div className="toolbar-scroll-fix">
                  <Toolbar
                    showPrint={true}
                    showFeedbackLink={
                      constants.featureFlags.ENABLE_FEEDBACK_FORM
                    }
                    onOpenFeedback={handleOnOpenFeedback}
                  />
                </div>
              </div>
            </div>
            {status === constants.EMAIL_STATUS.AWAITING_FOR_APPROVAL ? (
              <div className="mb-10">
                <EmailApproverInfo
                  emailBriefing={emailProps && emailProps.emailBriefing}
                  userData={authorizedUserData}
                />
              </div>
            ) : (
              <br />
            )}
            <div className="mb-50">
              <EmailGuidelineText
                isOpenGuidelineModel={isOpenAprGuidelineModel}
                titleText={`Please review and approve the email campaign using these `}
                guideLineName={`approval guidelines`}
              />
            </div>
            <Layout
              sidebar={
                <div className="winning-variant-wrapper">
                  <TemplatePreview
                    template={
                      emailTemplateEditId && emailTemplateEditId.templateObject
                    }
                    thumbnail={
                      emailTemplateEditId && emailTemplateEditId.thumbnail
                    }
                    emailBriefing={emailProps.emailBriefing}
                    title={emailTemplateEditId && emailTemplateEditId.title}
                    sentTestEmailOnClick={openTestEmailModel}
                    onExport={onExportHandler}
                    isExportButton={true}
                  />
                  <WinningVariantInfo
                    className="mt-10"
                    emailBriefing={emailProps.emailBriefing}
                  />
                </div>
              }
            >
              <EmailSummaryCards emailProps={emailProps} />
              <EmailOverviewTables
                emailProps={emailProps}
                review
                // editable
                handleTemplatePreview={handleTemplatePreview}
              />
              <div className="mb-20">
                <BackAndNext
                  backTitle="Reject"
                  onClickBack={handleRejectClick}
                  onClickNext={handleApprovalClick}
                  nextTitle="Approve"
                />
              </div>
            </Layout>
            <div className="overview-page-history mt-20">
              {emailProps.historyLogs && emailProps.historyLogs.length ? (
                <HistoryComponent
                  historyLogs={emailProps.historyLogs}
                  isEmailBrief={true}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <TemplatePreviewOverlay
        isOpen={isTemplatePreviewOpen}
        selectedTemplateForPreview={selectedTemplateForPreview}
        handleCloseForm={handleCloseTemplatePreview}
        hideSelectBtn
      />
    </>
  );
}
