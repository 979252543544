import React from 'react';
import constant from '../../../constants';

const SinglePageDescription = ({ isQnaCampaign, isOpenGuidelineModel }) => (
  <>
    {isQnaCampaign ? (
      <div>
        {constant.SAAS_MESSAGES.configureFormFields[0]} and use our&nbsp;
        <a
          href="#"
          onClick={isOpenGuidelineModel}
          style={{ fontWeight: 'bold' }}
        >
          Q&A guidelines
        </a>{' '}
        to setup your custom questions correctly.
      </div>
    ) : (
      <p>{constant.SAAS_MESSAGES.configureFormFields}.</p>
    )}
  </>
);

export default SinglePageDescription;
