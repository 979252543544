import React, { useState, useEffect } from 'react';

const ProgressiveImage = ({ placeholderSrc, src, ...props }) => {
  const [imgSrc, setImgSrc] = useState();

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      setImgSrc(src);
    };
  }, [src]);

  return imgSrc ? (
    <img {...{ src: imgSrc, ...props }} alt={props.alt || ''} />
  ) : (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{
        width: props.width || '20px',
        height: props.height || '20px'
      }}
    >
      <span>
        <i className="fa fa-spinner fa-spin fa-2x" />
      </span>
    </div>
  );
};
export default ProgressiveImage;
