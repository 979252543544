import React, { Fragment, useRef, createRef } from 'react';
import {
  isQuestionMandatory,
  isQuesQualifying,
  isRestrictionAllowed
} from '../selectors';
import ReactTooltip from 'react-tooltip';
import groupIcon from '../images/groupIcon.png';

const QuestionsGrid = props => {
  let {
    addedQuestions,
    isIconDisabled,
    editQuestionIndex,
    onClickEditQuestions,
    onClickDeleteQuestions,
    isLanguageEnglish,
    campaignLanguage,
    isPromotigoType
  } = props;
  const iconsRef = useRef(addedQuestions.map(() => createRef()));
  const panelRef = useRef(addedQuestions.map(() => createRef()));

  const _handleCollapse = (event, index) => {
    event.preventDefault();
    if (
      panelRef.current[index] &&
      panelRef.current[index].classList.contains('show')
    ) {
      panelRef.current[index].classList.remove('show');
      iconsRef.current[index].classList.remove('fa-chevron-up');
      iconsRef.current[index].classList.add('fa-chevron-down');
    } else {
      panelRef.current[index].classList.add('show');
      iconsRef.current[index].classList.remove('fa-chevron-down');
      iconsRef.current[index].classList.add('fa-chevron-up');
    }
  };

  return addedQuestions.map((question, index) => {
    let answerTranslatedIsNew =
      question.answers &&
      question.answers.map(
        (elem, indexValue) => elem.new || elem.translatednew
      );
    let answerHasNewTranslatedFlag =
      answerTranslatedIsNew &&
      answerTranslatedIsNew.filter(elem => elem).length > 0;
    return (
      <div
        className="cw-questions--listitem mb-30"
        data-item={index}
        key={index}
      >
        <div className="cw-striped-row">
          <div className="row">
            <div className="col-sm-4">
              <div>
                <strong>
                  Question{' '}
                  <a
                    className="pt-2"
                    href="#questionAnswersCollapsed"
                    role="button"
                    aria-expanded="false"
                    aria-controls="questionAnswersCollapsed"
                    onClick={event => _handleCollapse(event, index)}
                  >
                    <i
                      className={`ml-1 fas ${
                        index ? 'fa-chevron-down' : 'fa-chevron-up'
                      }`}
                      ref={node => {
                        iconsRef.current[index] = node;
                      }}
                    ></i>
                  </a>
                </strong>
              </div>
              <div
                className={`${
                  isLanguageEnglish || question.type === 'Profile'
                    ? 'd-none'
                    : ''
                }`}
              >
                <p className="xsmall-label">
                  {!isLanguageEnglish && question.type === 'Unique entry code'
                    ? campaignLanguage
                    : `English`}
                </p>
              </div>
            </div>
            <div className="d-flex col-sm-8 justify-content-between align-items-left">
              <div className="cw-question-text">{question.text}</div>
              <div className="cw-question-icon">
                <span className="cw-icon cw-icon--delete" data-index="0">
                  {isIconDisabled && editQuestionIndex === index ? (
                    <>
                      <i className="fas fa-disabled fa-edit mr-10"></i>
                      <i className="fas fa-disabled fa-trash-alt"></i>
                      {question.new ||
                      question.translatednew ||
                      answerHasNewTranslatedFlag ? (
                        <>
                          <ReactTooltip
                            id="saas_new_master_tooltip"
                            place="right"
                            type="info"
                            multiline={true}
                            className="cw-tooltip saas_new_master_tooltip"
                          />
                          <span
                            className={'saas_new_master_logo_questionsanswers'}
                            style={{
                              display: 'block',
                              color: '#E9A40A',
                              whiteSpace: 'pre'
                            }}
                            data-tip="New master data request"
                            data-for="saas_new_master_tooltip"
                          >
                            <img
                              src={groupIcon}
                              alt={groupIcon}
                              width="25"
                              height="25"
                            />
                          </span>
                        </>
                      ) : null}
                    </>
                  ) : (
                    <>
                      <i
                        className="fas fa-edit mr-10"
                        onClick={event =>
                          onClickEditQuestions(event, index, question)
                        }
                      ></i>
                      <i
                        className="fas fa-trash-alt"
                        onClick={event => onClickDeleteQuestions(event, index)}
                      ></i>
                      {question.new ||
                      question.translatednew ||
                      answerHasNewTranslatedFlag ? (
                        <>
                          <ReactTooltip
                            id="saas_new_master_tooltip"
                            place="right"
                            type="info"
                            multiline={true}
                            className="cw-tooltip saas_new_master_tooltip"
                          />
                          <span
                            className={'saas_new_master_logo_questionsanswers'}
                            style={{
                              display: 'block',
                              color: '#E9A40A',
                              whiteSpace: 'pre'
                            }}
                            data-tip="New master data request"
                            data-for="saas_new_master_tooltip"
                          >
                            <img
                              src={groupIcon}
                              alt={groupIcon}
                              width="25"
                              height="25"
                            />
                          </span>
                        </>
                      ) : null}
                    </>
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`collapse ${!index ? 'show' : ''}`}
          id="questionAnswersCollapsed"
          ref={node => {
            panelRef.current[index] = node;
          }}
        >
          {!isLanguageEnglish &&
          question.type !== 'Profile' &&
          question.type !== 'Unique entry code' ? (
            <div className="cw-striped-row collpased-striped-row">
              <div className="row">
                <div className="col-sm-4">
                  <div>
                    <strong>Question</strong>
                  </div>
                  <div className={`${isLanguageEnglish ? 'd-none' : ''}`}>
                    <p className="xsmall-label">{campaignLanguage}</p>
                  </div>
                </div>
                <div className="col-sm-8">
                  <p>{question.translatedquestion}</p>
                </div>
              </div>
            </div>
          ) : null}
          {question.answers &&
          question.answers.length &&
          question.answers[0].label !== ''
            ? question.answers.map((answer, ansindex) => {
                return (
                  <>
                    <div
                      className="cw-striped-row collpased-striped-row"
                      key={ansindex}
                    >
                      <div className="row">
                        <div className="col-sm-4">
                          <div>
                            <strong>
                              Answer{' '}
                              {!isLanguageEnglish && question.type !== 'Profile'
                                ? ansindex + 1
                                : ''}
                            </strong>
                          </div>
                          <div
                            className={`${
                              isLanguageEnglish || question.type === 'Profile'
                                ? 'd-none'
                                : ''
                            }`}
                          >
                            <p className="xsmall-label">English</p>
                          </div>
                        </div>
                        <div className="col-sm-8 justify-content-between align-items-left">
                          {answer.label}
                        </div>
                      </div>
                    </div>
                    {!isLanguageEnglish && question.type !== 'Profile' ? (
                      <div className="cw-striped-row collpased-striped-row">
                        <div className="row">
                          <div className="col-sm-4">
                            <div>
                              <strong>
                                Answer {!isLanguageEnglish ? ansindex + 1 : ''}
                              </strong>
                            </div>
                            <div
                              className={`${isLanguageEnglish ? 'd-none' : ''}`}
                            >
                              <p className="xsmall-label">{campaignLanguage}</p>
                            </div>
                          </div>
                          <div className="col-sm-8">
                            <p>{answer.translatedanswer}</p>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </>
                );
              })
            : null}
          {question.maxAnswers ? (
            <div className="cw-striped-row collpased-striped-row">
              <div className="row">
                <div className="col-sm-4">
                  <strong>Allowed answers</strong>
                </div>
                <div className="col-sm-8 justify-content-between align-items-left">
                  {question.maxAnswers}
                </div>
              </div>
            </div>
          ) : null}
          {question.type === 'File upload' ? (
            <>
              <div className="cw-striped-row collpased-striped-row">
                <div className="row">
                  <div className="col-sm-4">
                    <strong>Upload restrictions</strong>
                  </div>
                  <div className="col-sm-8 justify-content-between align-items-left">
                    {isRestrictionAllowed(question.isRestrictionAllowed)}
                  </div>
                </div>
              </div>
              {question.isRestrictionAllowed ? (
                <div className="cw-striped-row collpased-striped-row">
                  <div className="row">
                    <div className="col-sm-4">
                      <strong>File size</strong>
                    </div>
                    <div className="col-sm-8 justify-content-between align-items-left">
                      {question.maxFileSize} KB
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="cw-striped-row collpased-striped-row">
                <div className="row">
                  <div className="col-sm-4">
                    <strong>Default display text</strong>
                  </div>
                  <div className="col-sm-8 justify-content-between align-items-left">
                    {question.defaultDisplayText}
                  </div>
                </div>
              </div>
              <div className="cw-striped-row collpased-striped-row">
                <div className="row">
                  <div className="col-sm-4">
                    <strong>Placeholder text</strong>
                  </div>
                  <div className="col-sm-8 justify-content-between align-items-left">
                    {question.placeholderText}
                  </div>
                </div>
              </div>
            </>
          ) : null}

          <div className="cw-striped-row collpased-striped-row">
            <div className="row">
              <div className="col-sm-4">
                <strong>Type</strong>
              </div>
              <div className="col-sm-8 justify-content-between align-items-left">
                {question.type}
              </div>
            </div>
          </div>
          <div className="cw-striped-row collpased-striped-row">
            <div className="row">
              <div className="col-sm-4">
                <strong>Mandatory</strong>
              </div>
              <div className="col-sm-8 justify-content-between align-items-left">
                {isQuestionMandatory(question.mandatory)}
              </div>
            </div>
          </div>
          {isPromotigoType &&
          (question.type === 'Single choice' ||
            question.type === 'Free text') ? (
            <div className="cw-striped-row collpased-striped-row">
              <div className="row">
                <div className="col-sm-4">
                  <strong>Qualifying</strong>
                </div>
                <div className="col-sm-8 justify-content-between align-items-left">
                  {isQuesQualifying(question.isQualifying)}
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  });
};

const AddedQuestionsList = props => {
  let {
    addedQuestions,
    isIconDisabled,
    editQuestionIndex,
    isLanguageEnglish,
    campaignLanguage,
    isPromotigoType
  } = props;
  return addedQuestions && addedQuestions.length === 0 ? (
    <div className="cw-questions--list">
      <p className="mb-4">You have not added any questions.</p>
    </div>
  ) : (
    <div>
      <QuestionsGrid
        isLanguageEnglish={isLanguageEnglish}
        campaignLanguage={campaignLanguage}
        addedQuestions={addedQuestions}
        isIconDisabled={isIconDisabled}
        editQuestionIndex={editQuestionIndex}
        onClickEditQuestions={props.onClickEditQuestions}
        onClickDeleteQuestions={props.onClickDeleteQuestions}
        isPromotigoType={isPromotigoType}
      />
    </div>
  );
};

export default AddedQuestionsList;
