import React from 'react';

const DashStats = ({ title, count, percent }) => {
  return (
    <div className="dash-stats d-flex flex-column align-items-center">
      <span className="dash-stats-title">{title}</span>
      <div className="dash-stats-line"></div>
      <span className="dash-stats-count">{count}</span>
      <span className="dash-stats-percent">{percent}</span>
    </div>
  );
};

export default DashStats;
