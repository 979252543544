import CheckboxDropDown from '../../../../components/CheckboxDropDown';
import React from 'react';
import FilterWrapper from '../FilterWrapper/FilterWrapper';

export default function StatusFilter({
  isDisabled,
  asyncInProgress,
  value,
  onChange,
  options,
  isEmailCampaign,
  classNamePrefix
}) {
  return (
    <FilterWrapper>
      <CheckboxDropDown
        isMulti={true}
        className={isEmailCampaign? 'react-multi-select-container' : 'cw-dashboard-filter'}
        classNamePrefix={classNamePrefix}
        isEmailCampaign={isEmailCampaign}
        name={'status'}
        label={'Status'}
        hideSelectedOptions={false}
        placeholder={'Status'}
        isDisabled={isDisabled || asyncInProgress}
        isLoading={asyncInProgress}
        value={value}
        onChange={onChange}
        options={options}
        isClearable={false}
      />
    </FilterWrapper>
  );
}
