import * as yup from 'yup';

import {
  getCountries,
  getBrandByCountry,
  getBrandDetails
} from 'services/emailBriefings';

import { OptOutTextInput } from './OptOutTextInput';

export const formSchema = (dispatch, componentProps, defaultValues) => {
  const { addItem, onClose } = componentProps;

  return {
    formType: 'modal',
    modelBtnSwap: true,
    submitBtnLabel: 'Save',
    fields: [
      {
        type: 'reactselect',
        name: 'country',
        id: 'country',
        label: 'Country',
        rules: yup
          .object()
          .shape({
            label: yup.string().required(),
            value: yup.string().required(),
            id: yup.string().required()
          })
          .required('Please select country')
          .default(undefined),
        options: [],
        defaultDisabled: true,
        onLoad: (properties, setProperties) => {
          getCountries()
            .then(response => {
              let countries = response.map(country => {
                return {
                  value: country.code,
                  label: country.name,
                  id: country._id
                };
              });
              setProperties({
                ...properties,
                options: countries,
                disabled: false
              });
            })
            .catch(e => {});
        }
      },
      {
        type: 'reactselect',
        name: 'brand',
        id: 'brand',
        label: 'Brand',
        rules: yup
          .object()
          .shape({
            label: yup.string().required(),
            value: yup.string().required(),
            id: yup.string().required()
          })
          .required('Please select brand')
          .default(undefined),
        options: [],
        defaultDisabled: true,
        watchInput: 'country',
        defaultWatchValue: defaultValues.country,
        onWatch: (value, { options, disabled }, setData) => {
          let m_disabled = disabled;
          let m_options = options;
          if (value) {
            m_disabled = false;
            getBrandByCountry(value.id).then(res => {
              if (res.brands) {
                let brands = res.brands.map(country => {
                  return {
                    value: country.code,
                    label: country.name,
                    id: country._id
                  };
                });
                setData({ options: brands, disabled: m_disabled });
              }
            });
          }
        }
      },
      {
        type: 'reactselect',
        name: 'language',
        id: 'language',
        label: 'Language',
        rules: yup
          .object()
          .shape({
            label: yup.string().required(),
            value: yup.string().required(),
            id: yup.string().required()
          })
          .required('Please select language')
          .default(undefined),
        options: [],
        defaultDisabled: true,
        watchInput: 'country',
        defaultWatchValue: defaultValues.country,
        onWatch: (value, { options, disabled }, setData) => {
          let m_disabled = disabled;
          let m_options = options;
          if (value) {
            m_disabled = false;
          }
          getBrandDetails().then(res => {
            if (res.languages) {
              let languages = res.languages.map(language => {
                return {
                  value: language.code,
                  label: language.name,
                  id: language._id
                };
              });
              setData({ options: languages, disabled: m_disabled });
            }
          });
        }
      },
      {
        type: 'custom',
        name: 'optOutTextInput',
        id: 'optOutTextInput',
        component: OptOutTextInput,
        watchInput: ['country', 'brand', 'language'],
        rules: yup.object({
          text: yup
            .string()
            .required('Please enter opt-out text')
            .max(100, 'Opt-out text cannot be longer than 100 characters'),
          keyword: yup
            .string()
            .required('Please enter opt-out keyword')
            .max(60, 'Opt-out text cannot be longer than 60 characters')
        })
      }
    ],

    onSubmit: data => {
      addItem(data);
    },
    onPrevious: () => onClose(),
    formErrorMessage:
      'There was a problem with the form. Errors are listed below'
  };
};
