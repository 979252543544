import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import Modal from 'react-modal';
import { setModalStatus } from '../actions/app';
import { rejectCampaign } from '../actions/approval';
import {
  fetchCampaignData,
  updateDetails,
  saveGigyaDetails,
  downloadGigyaCssFile
} from '../actions/gigya';
import {
  getFormatedDate,
  textCapitalization,
  scrollToTop,
  showGigyaIdScreen,
  getCampaignStatusText,
  statusBadge,
  checkCampaignLanguage
} from '../selectors';
import axios from 'axios';
import CampaignDetailsInfo from './CampaignDetailsInfo';
import MasterDataQuestion from './MasterDataQuestion';
import constant from '../constants';
import { fetchHistoryLogs } from '../actions/history';
import { setNotificationMessage } from '../actions/dashboard';
import ErrorNotification from './errorNotification';
import Alert from './Alert';
import HistoryComponent from '../components/history';
import RegularCommunications from './RegularCommunications';
import NotFound from './NotFound';
class GigyaConnectionDetails extends Component {
  constructor(props) {
    super(props);
    // TODO: Move this state into redux
    this.state = {
      dataSubmitted: false,
      showMessageAtTop: false,
      showRejectionError: false
    };
    this.formElements = {
      gigyaFormName: this.props.gigyaFormName,
      gigyaStartScreenUrl: this.props.gigyaStartScreenUrl,
      gigyaCssFileUrl: this.props.gigyaCssFileUrl,
      gigyaQAProfileStoreKey: this.props.gigyaQAProfileStoreKey,
      gigyaProductionProfileStoreKey: this.props.gigyaProductionProfileStoreKey,
      gigyaScreenSetName: this.props.gigyaScreenSetName,
      gigyaPreferenceCentreScreenSetName: this.props
        .gigyaPreferenceCentreScreenSetName
    };
    this.notificationData = {
      message: '',
      link: '',
      type: '',
      campaign: ''
    };
    this.errorFields = [];
    this.isValid = false;
    this.campaignId = '';
    this._handleRejectButton = this._handleRejectButton.bind(this);
    this._handleApproveButton = this._handleApproveButton.bind(this);
    this._handleCollapse = this._handleCollapse.bind(this);
    this._handleSubmit = this._handleSubmit.bind(this);
    this._handleChange = this._handleChange.bind(this);
    this._saveAsDraft = this._saveAsDraft.bind(this);
    this._validateField = this._validateField.bind(this);
    this._addErrorFields = this._addErrorFields.bind(this);
    this._filterErrorFields = this._filterErrorFields.bind(this);
    this._downloadCssFile = this._downloadCssFile.bind(this);
    //Reject campaign
    this._handleReject = this._handleReject.bind(this);
    this._closeModal = this._closeModal.bind(this);
    this._handleOnSubmit = this._handleOnSubmit.bind(this);
    this._handleRejectChange = this._handleRejectChange.bind(this);
    // Campaign Details Collapsible Panel
    this.collapsePanel = React.createRef();
    this.collapseHandlerIcon = React.createRef();
    this.collapseHandlerText = React.createRef();
    // PRM Collapsible Panel
    this.prmCollapsePanel = React.createRef();
    this.collapseHandlerIconPrm = React.createRef();
    this.collapseHandlerTextPrm = React.createRef();

    //MasdterData Collapsible Panel
    this.collapseMasterDataPanel = React.createRef();
    this.collapseMasterDataHandlerIcon = React.createRef();
    this.collapseMasterDataHandlerText = React.createRef();
  }

  _handleChange(event) {
    if (event.target.type === 'file') {
      this.formElements[event.target.name] = event.target.files[0];
    } else {
      this.formElements[event.target.name] = event.target.value;
    }

    if (event.target.dataset.customError === 'true') {
      if (event.target.dataset.customErrorType === 'size') {
        let file = event.target.files;
        this.formElements[event.target.name] = event.target.files[0];
        if (file && file[0].size > constant.maxAllowedGigyaCssFileSize) {
          document
            .querySelector('[data-custom-msg=' + event.target.name + ']')
            .classList.remove('cw-hidden');
          this.formElements[event.target.name] = '';
        } else {
          document
            .querySelector('[data-custom-msg=' + event.target.name + ']')
            .classList.add('cw-hidden');
        }
      } else {
        // Email Format Validtion
        let regexPattern = constant.regex[event.target.dataset.customErrorType];

        if (
          regexPattern.test(event.target.value) ||
          event.target.value === ''
        ) {
          document
            .querySelector('[data-custom-msg=' + event.target.name + ']')
            .classList.add('cw-hidden');
        } else {
          document
            .querySelector('[data-custom-msg=' + event.target.name + ']')
            .classList.remove('cw-hidden');
        }
      }
    }

    if (event.target.dataset && event.target.dataset.required === 'true') {
      this._validateField(event);
    }
    //file-input--path
    if (event.target.type === 'file') {
      this.props.updateDetails({ [event.target.name]: event.target.files[0] });
    } else {
      this.props.updateDetails({ [event.target.name]: event.target.value });
    }
  }

  _validateField(event) {
    if (event.target) {
      if (
        document
          .querySelector("[name='" + event.target.name + "']")
          .parentElement.classList.contains('error')
      ) {
        document
          .querySelector("[name='" + event.target.name + "']")
          .parentElement.classList.remove('error');
      }
      if (
        !document
          .querySelector("[data-control='" + event.target.name + "']")
          .classList.contains('cw-hidden')
      ) {
        document
          .querySelector("[data-control='" + event.target.name + "']")
          .classList.add('cw-hidden');
      }
    }
  }
  _validateForm(event) {
    let formElements = this.formElements;
    for (var key in formElements) {
      if (formElements.hasOwnProperty(key)) {
        let selector = document.querySelector("[name='" + key + "']");
        if (selector) {
          if (formElements[key] !== '') {
            if (selector.parentElement.classList.contains('error')) {
              selector.parentElement.classList.remove('error');
            }
            if (document.querySelector("[data-control='" + key + "']")) {
              if (
                !document
                  .querySelector("[data-control='" + key + "']")
                  .classList.contains('cw-hidden')
              ) {
                document
                  .querySelector("[data-control='" + key + "']")
                  .classList.add('cw-hidden');
              }
            }
            this.errorFields = this._filterErrorFields(this.errorFields, key);
          } else {
            if (!selector.parentElement.classList.contains('error')) {
              selector.parentElement.classList.add('error');
            }
            if (document.querySelector("[data-control='" + key + "']")) {
              if (
                document
                  .querySelector("[data-control='" + key + "']")
                  .classList.contains('cw-hidden')
              ) {
                document
                  .querySelector("[data-control='" + key + "']")
                  .classList.remove('cw-hidden');
              }
            }
            this.errorFields = this._addErrorFields(this.errorFields, key);
          }
        }
      }
    }
    if (this.errorFields.length === 0) {
      this.isValid = true;
      this.setState({ showMessageAtTop: false });
    } else {
      this.setState({ showMessageAtTop: true });
      scrollToTop();
    }
  }

  _downloadCssFile(event) {
    this.props.downloadGigyaCssFile(
      this.campaignId,
      this.props.gigyaCssFileUrl.name
    );
  }

  _handleSubmit(event) {
    this._validateForm(event);

    if (this.isValid) {
      let gigyaDetails = {};

      gigyaDetails = {
        ...gigyaDetails,
        ...this.props.profileStore,
        ...this.formElements
      };

      this.props
        .saveGigyaDetails(
          this.campaignId,
          gigyaDetails,
          this.props.userName,
          this.props.userEmail
        )
        .then(response => {
          this.notificationData.message = 'has been assigned Gigya keys.';
          this.notificationData.campaign = this.props.campaign.name;
          this.notificationData.type = 'success';
          this.props.setNotificationMessage(
            this.notificationData.message,
            this.notificationData.link,
            this.notificationData.type,
            this.notificationData.campaign
          );

          this.props.history.push('/campaign-wizard/dashboard');
        });
    }
  }
  _saveAsDraft() {
    let gigyaDetails = {};
    gigyaDetails = {
      ...gigyaDetails,
      ...this.props.profileStore,
      ...this.formElements
    };

    this.props
      .saveGigyaDetails(
        this.campaignId,
        gigyaDetails,
        this.props.userName,
        this.props.userEmail,
        true
      )
      .then(response => {
        this.notificationData.message = 'has been saved as draft.';
        this.notificationData.campaign = this.props.campaign.name;
        this.notificationData.type = 'warning';
        this.props.setNotificationMessage(
          this.notificationData.message,
          this.notificationData.link,
          this.notificationData.type,
          this.notificationData.campaign
        );
        this.props.history.push('/campaign-wizard/dashboard');
      });
  }

  _handleCollapse(event, type) {
    event.preventDefault();
    let collapisblePanel,
      collapseHandlerIcon,
      collapseHandlerText,
      collapseHandlerTextLess,
      collapseHandlerTextMore;

    if (type === 'prm') {
      collapisblePanel = this.prmCollapsePanel;
      collapseHandlerIcon = this.collapseHandlerIconPrm;
      collapseHandlerText = this.collapseHandlerText;
      collapseHandlerTextLess = 'View less prm connection details';
      collapseHandlerTextMore = 'View more prm connection details';
    } else if (type === 'masterdata') {
      collapisblePanel = this.collapseMasterDataPanel;
      collapseHandlerIcon = this.collapseMasterDataHandlerIcon;
      collapseHandlerText = this.collapseMasterDataHandlerText;
      collapseHandlerTextLess = 'View less Master Data IDs';
      collapseHandlerTextMore = 'View more Master Data IDs';
    }

    if (collapisblePanel.current.classList.contains('show')) {
      collapisblePanel.current.classList.remove('show');
      collapseHandlerIcon.current.classList.remove('fa-chevron-up');
      collapseHandlerIcon.current.classList.add('fa-chevron-down');
      collapseHandlerText.current.innerHTML = collapseHandlerTextMore;
    } else {
      collapisblePanel.current.classList.add('show');
      collapseHandlerText.current.innerHTML = collapseHandlerTextLess;
      collapseHandlerIcon.current.classList.remove('fa-chevron-down');
      collapseHandlerIcon.current.classList.add('fa-chevron-up');
    }
  }
  _addErrorFields(array, itemToAdd) {
    return array.filter(field => field !== itemToAdd).concat([itemToAdd]);
  }
  _filterErrorFields(array, itemToRemove) {
    return array.filter(item => item !== itemToRemove);
  }

  _handleRejectButton() {
    return true;
  }

  _handleApproveButton() {
    return true;
  }

  _handleRejectChange(e) {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.value.length > 0) {
      this.setState({ showRejectionError: false });
    } else {
      this.setState({ showRejectionError: true });
    }
  }

  _handleOnSubmit(e) {
    e.preventDefault();

    if (this.state.description && this.state.description.length > 0) {
      this.setState({ showRejectionError: false });
      let data = {
        uid: this.campaignId,
        status: constant.campaignStatus.REJECTED,
        rejectComments: this.state.description,
        userName: this.props.userName,
        userEmail: this.props.userEmail
      };

      this._closeModal();

      this.props.rejectCampaign(data).then(response => {
        this.notificationData.message = 'has been rejected.';
        this.notificationData.link =
          '/campaign-wizard/campaign/' + this.props.campaign.uid;
        this.notificationData.type = 'warning';
        this.notificationData.campaign = this.props.campaign.name;
        this.props.setNotificationMessage(
          this.notificationData.message,
          this.notificationData.link,
          this.notificationData.type,
          this.notificationData.campaign
        );
        this.props.history.push('/campaign-wizard/dashboard');
      });
    } else {
      this.setState({ showRejectionError: true });
    }
  }

  _handleReject(e) {
    e.preventDefault();
    let actionType = e.currentTarget.getAttribute('data-action');
    this.props.setModalStatus(true, actionType);
  }

  _closeModal() {
    this.props.setModalStatus(false);
  }

  componentDidMount() {
    this.campaignId = this.props.match.params.id;
    this.notificationData.link = '/campaign-wizard/campaign/' + this.campaignId;
    this.props.fetchCampaignData(this.campaignId);
    this.props.fetchHistoryLogs(this.campaignId);
  }

  componentWillReceiveProps(nextProps, nextState) {
    if (nextProps.datauploaded) {
      let showGigyaScreen = showGigyaIdScreen(nextProps.campaign);
      if (!showGigyaScreen) {
        this.props.history.push('/campaign-wizard/dashboard');
      }
    }
    if (nextProps.url || nextProps.accesskey || nextProps.secretkey) {
      this.formElements = {
        gigyaFormName: this.props.gigyaFormName,
        gigyaStartScreenUrl: this.props.gigyaStartScreenUrl,
        gigyaCssFileUrl: this.props.gigyaCssFileUrl,
        gigyaQAProfileStoreKey: this.props.gigyaQAProfileStoreKey,
        gigyaProductionProfileStoreKey: this.props
          .gigyaProductionProfileStoreKey,
        gigyaScreenSetName: this.props.gigyaScreenSetName,
        gigyaPreferenceCentreScreenSetName: this.props
          .gigyaPreferenceCentreScreenSetName
      };
    }
  }

  _handleRefresh = () => {
    let { campaign } = this.props;
    axios
      .post(constant.serviceUrls.CAMPAIGN_INFRA_API_CALL, campaign)
      .then(response => {
        this.campaignId = this.props.match.params.id;
        this.props.fetchCampaignData(this.campaignId);
      });
  };

  render() {
    Modal.setAppElement('#root');

    let statusClassName = classNames({
      'form-control mb-40': !this.isValid,
      'form-control mb-30': this.isError
    });

    let {
      campaign,
      campaignDetails,
      dataSubmitted,
      additionalOptins,
      questionAnswerList,
      modalIsOpen,
      activeModal
    } = this.props;
    let isAEM =
      campaign &&
      campaign.websiteType &&
      (campaign.websiteType.name.toLowerCase() === 'adobe classic' ||
        campaign.websiteType.name.toLowerCase() === 'adobe templated')
        ? true
        : false;

    let statusClass = classNames(statusBadge(this.props.campaign));
    let getCampaignStatus = getCampaignStatusText(this.props.campaign);
    let campaignLanguage =
      campaign && campaign.language && campaign.language.name;
    let isCampaignNonEnglish = checkCampaignLanguage(campaignLanguage);
    let isCampaignIncentive =
      campaign && campaign.campaignType === 'Incentive' ? true : false;

    this.formElements = {
      gigyaFormName: this.props.gigyaFormName,
      gigyaStartScreenUrl: this.props.gigyaStartScreenUrl,
      gigyaCssFileUrl: this.props.gigyaCssFileUrl,
      gigyaQAProfileStoreKey: this.props.gigyaQAProfileStoreKey,
      gigyaProductionProfileStoreKey: this.props.gigyaProductionProfileStoreKey,
      gigyaScreenSetName: this.props.gigyaScreenSetName,
      gigyaPreferenceCentreScreenSetName: this.props
        .gigyaPreferenceCentreScreenSetName
    };

    if (campaign.status !== constant.campaignStatus.WAITING_API_DETAILS) {
      return <NotFound isGigyaDecommissioned={true} />;
    } else {
      return (
        <>
          <div className="cw-section cw-overview mb-30">
            <div className="colsm-12 mb-30">
              <div className="col-sm-6">
                <NavLink
                  className="cw-color--primary cw-breadcrum"
                  to={'/campaign-wizard'}
                >
                  <i className="fas fa-angle-left"></i> Campaigns
                </NavLink>
              </div>
            </div>
            <>
              <Modal
                isOpen={modalIsOpen && activeModal === 'reject'}
                onRequestClose={this._closeModal}
                className="cw-modal cw-modal--reject"
                contentLabel="Rejection Modal"
                style={{
                  overlay: {
                    backgroundColor: constant.EMAIL_OVERLAY_BACKGROUND
                  }
                }}
              >
                <button
                  className="cw-modal--close"
                  title="Close"
                  onClick={this._closeModal}
                >
                  <i className="fas fa-times"></i>
                </button>
                <h3 className="cw-heading--primary mb-10">Confirm rejection</h3>
                <p className="mb-20">
                  Please confirm and provide a reason why you have rejected this
                  campaign.
                </p>

                {/* rejection textarea */}
                <div>
                  <label htmlFor="description">
                    <span>Reason for rejection</span>
                  </label>
                  <textarea
                    className={statusClassName}
                    name="description"
                    id="description"
                    onChange={this._handleRejectChange}
                    rows="3"
                  ></textarea>
                  {this.state.showRejectionError ? (
                    <span className="cw-error mb-10" data-control="description">
                      <i className="fas fa-exclamation-triangle mr-10"></i>
                      Please enter rejection reason.
                    </span>
                  ) : null}
                </div>

                <div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-outline-secondary"
                      data-dismiss="modal"
                      onClick={this._closeModal}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={this._handleOnSubmit}
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </Modal>
            </>
            {dataSubmitted && this.props.isDraftState === false ? (
              <div className="col-sm-12">
                <p className="alert alert-success">
                  <strong>Gigya connection details submitted.</strong> (
                  {campaign
                    ? getFormatedDate(campaign.GigyaSubmissionDate, true)
                    : ''}{' '}
                  UTC )
                </p>
              </div>
            ) : null}
            {dataSubmitted && this.props.isDraftState === true ? (
              <div className="col-sm-12">
                <p className="alert alert-warning">
                  <strong>Gigya connection details saved as draft</strong>
                </p>
              </div>
            ) : null}

            {/* Alert for failure of campaign page generation*/}
            <Alert
              alertType={'nonPublished'}
              showComponent={
                campaign.status === constant.campaignStatus.PROCESSING &&
                campaign &&
                campaign.aemError &&
                campaign.aemError.code !== '404' &&
                campaign.aemError.code !== '412'
              }
            />
            <Alert
              alertType={'error404NonPublished'}
              showComponent={
                campaign.status === constant.campaignStatus.PROCESSING &&
                campaign &&
                campaign.aemError &&
                campaign.aemError.code === '404'
              }
            />
            <Alert
              alertType={'isPromotigoAC'}
              showComponent={
                campaign.status === constant.campaignStatus.PROCESSING &&
                campaign &&
                campaign.aemError &&
                campaign.aemError.code === '412'
              }
            />

            {this.props.profileStore &&
            this.props.profileStore.gigyaErrorCode &&
            this.props.profileStore.gigyaErrorCode !== null ? (
              <div className="alert alert-danger" role="alert">
                <strong>Please resolve the following issue:</strong>
                <p>
                  Error code: {this.props.profileStore.gigyaErrorCode}
                  {this.props.profileStore.gigyaErrorMessage
                    ? ' : ' +
                      this.props.profileStore.gigyaErrorMessage.replace(
                        /_/g,
                        ' '
                      )
                    : ''}{' '}
                </p>
              </div>
            ) : null}
            {!this.isValid && this.state.showMessageAtTop ? (
              <ErrorNotification />
            ) : null}
            <Alert
              type={'warning'}
              alertType={'cancelcampaign'}
              campaignName={this.props.campaign && this.props.campaign.name}
              message={'has been cancelled. No action required.'}
              showComponent={
                this.props.campaign &&
                this.props.campaign.status &
                  (this.props.campaign.status ===
                    constant.campaignStatus.CANCELLED)
              }
            />
            <div class="cw-campaign--review-section mb-40">
              <h2 className="cw-heading--secondary d-flex align-items-center mb-10">
                <span className="mr-3">Submit Gigya connection details</span>
                <span className={statusClass}>{getCampaignStatus}</span>
              </h2>
              <p>Enter and submit the Gigya connection details.</p>
            </div>

            {/* Campaign Details */}
            <CampaignDetailsInfo
              campaign={campaign}
              campaignDetails={campaignDetails}
              panelref={this.collapsePanel}
              collapseHandlerIconref={this.collapseHandlerIcon}
              collapseHandlerTextref={this.collapseHandlerText}
              isConsentAndCompliance={true}
              isCampaignType={true}
              handleRefresh={this._handleRefresh}
            />

            {!isCampaignIncentive ? (
              <RegularCommunications
                campaign={campaign}
                campaignDetails={campaignDetails}
                colLabelClass="col-sm-4"
                colValClass="col-sm-8"
                disabledFlag={false}
                draftFlag={false}
                optins={this.props.optins}
                optinsDescription={this.state.optinsDescription}
              ></RegularCommunications>
            ) : null}

            <div>
              <div class="cw-campaign--review-section mb-40">
                <div className="mb-20">
                  <h3 className="cw-heading--primary">Master Data IDs</h3>
                </div>
                <div className="cw-campaign--review-section-content">
                  <div className="cw-striped-row">
                    <div className="row">
                      <div className="col-sm-4">
                        <strong>Campaign ID</strong>
                      </div>
                      <div className="col-sm-4"></div>
                      <div className="col-sm-4">
                        <input
                          type="text"
                          className="form-control"
                          name="campaignId"
                          id="campaignId"
                          disabled="disabled"
                          data-required="true"
                          value={campaign.campaignId || ''}
                          onChange={this._handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="collapse"
              id="MasterIDCollapsed"
              ref={this.collapseMasterDataPanel}
            >
              <div className="col-sm-12">
                <div className="cw-campaign--review-section mb-30">
                  <div className="mb-20">
                    <h3>Consent IDs</h3>
                  </div>
                  <div className="cw-campaign--review-section-content">
                    <div className="cw-striped-row">
                      <div className="row">
                        <div className="col-sm-3">
                          <strong>
                            {!campaignDetails.emailChannel &&
                            campaignDetails.smsChannel
                              ? 'Brand (SMS)'
                              : 'Brand (email)'}
                          </strong>
                        </div>
                        <div className="col-sm-5">
                          {campaignDetails.emailChannel &&
                          campaignDetails.smsChannel ? (
                            <p>
                              Please tick the box to receive offers and
                              competitions about (brand name ) by email.
                            </p>
                          ) : campaignDetails.emailChannel &&
                            !campaignDetails.smsChannel ? (
                            <p>
                              Please tick the box to receive offers and
                              competitions about (brand name ) by email.
                            </p>
                          ) : !campaignDetails.emailChannel &&
                            campaignDetails.smsChannel ? (
                            <p>
                              Please tick the box to receive offers and
                              competitions about (brand name ) by SMS.
                            </p>
                          ) : (
                            <p>
                              Please tick the box to receive offers and
                              competitions about (brand name ) by email.
                            </p>
                          )}
                        </div>
                        <div className="col-sm-4">
                          <input
                            type="text"
                            className="form-control"
                            name={this.props.brandoptin.text}
                            id={this.props.brandoptin.text}
                            value={this.props.brandoptin.id || ''}
                            disabled={true}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="cw-striped-row">
                      <div className="row">
                        <div className="col-sm-3">
                          <strong>
                            {campaignDetails.emailChannel &&
                            campaignDetails.smsChannel
                              ? 'Brand (SMS)'
                              : this.props.unileveroptin.text}
                          </strong>
                        </div>
                        <div className="col-sm-5">
                          {campaignDetails.emailChannel &&
                          campaignDetails.smsChannel ? (
                            <p>
                              Please tick the box to receive offers and
                              competitions about (brand name ) by SMS.
                            </p>
                          ) : campaignDetails.emailChannel &&
                            !campaignDetails.smsChannel ? (
                            <p>
                              Please tick the box to receive exciting news,
                              offers and competitions from other Unilever brands
                              by email.
                            </p>
                          ) : !campaignDetails.emailChannel &&
                            campaignDetails.smsChannel ? (
                            <p>
                              Please tick the box to receive exciting news,
                              offers and competitions from other Unilever brands
                              by email.
                            </p>
                          ) : (
                            <p>
                              Please tick the box to receive offers and
                              competitions about (brand name ) by SMS.
                            </p>
                          )}
                        </div>
                        <div className="col-sm-4">
                          <input
                            type="text"
                            className="form-control"
                            name={this.props.unileveroptin.text}
                            id={this.props.unileveroptin.text}
                            value={this.props.unileveroptin.id || ''}
                            disabled={true}
                          />
                        </div>
                      </div>
                    </div>

                    {campaignDetails.emailChannel &&
                    campaignDetails.smsChannel ? (
                      <div className="cw-striped-row">
                        <div className="row">
                          <div className="col-sm-3">
                            <strong>
                              {this.props.unileverid.text !== undefined
                                ? this.props.unileverid.text === 'Brand_SMS'
                                  ? 'Unilever'
                                  : ''
                                : ''}
                            </strong>
                          </div>
                          <div className="col-sm-5">
                            <p>
                              Please tick the box to receive exciting news,
                              offers and competitions from other Unilever brands
                              by email.
                            </p>
                          </div>
                          <div className="col-sm-4">
                            <input
                              type="text"
                              className="form-control"
                              name={this.props.unileverid.text}
                              id={this.props.unileverid.text}
                              value={'UNILEVER'}
                              disabled={true}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {!isCampaignIncentive && additionalOptins.length
                      ? additionalOptins.map((optin, index) => {
                          return (
                            <>
                              <div key={index} className="cw-striped-row">
                                <div className="row">
                                  <div className="col-sm-3">
                                    <strong>
                                      {'Regular communication (' +
                                        (index + 1) +
                                        ')'}
                                    </strong>
                                  </div>
                                  <div className="col-sm-5">
                                    <p>{optin.text}</p>
                                  </div>
                                  <div className="col-sm-4">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter ID"
                                      name={'additionalOptinList-' + index}
                                      id={'additionalOptinList-' + index}
                                      value={optin.id || ''}
                                      disabled={true}
                                    />
                                  </div>
                                </div>
                              </div>
                              {/* <div key={index} className="cw-striped-row">
                            <div className="row">
                              <div className="col-sm-3">
                                <strong>
                                  {'Frequency of regular communication (' +
                                    (index + 1) +
                                    ')'}
                                </strong>
                              </div>
                              <div className="col-sm-5">
                                <p>
                                  {optin.frequency
                                    ? textCapitalization(optin.frequency)
                                    : ''}
                                </p>
                              </div>
                              <div className="col-sm-4"></div>
                            </div>
                          </div> */}
                            </>
                          );
                        })
                      : null}
                  </div>
                </div>
              </div>
              {questionAnswerList.length &&
              campaign.type === 'Sign-up with Questions & Answers' ? (
                <MasterDataQuestion
                  questionAnswerList={questionAnswerList}
                  campaignLanguage={campaignLanguage || ''}
                  disabled={true}
                  isCampaignNonEnglish={isCampaignNonEnglish}
                />
              ) : null}
            </div>
            <div className="col-sm-12">
              <div className="cw-campaign--review-section mb-30">
                <a
                  className="collapse-h pt-2"
                  href="#MasterIDCollapsed"
                  role="button"
                  aria-expanded="false"
                  aria-controls="MasterIDCollapsed"
                  onClick={event => this._handleCollapse(event, 'masterdata')}
                >
                  <span ref={this.collapseMasterDataHandlerText}>
                    View more Master Data IDs
                  </span>{' '}
                  <i
                    className="fas fa-chevron-down ml-1"
                    ref={this.collapseMasterDataHandlerIcon}
                  ></i>
                </a>
              </div>
            </div>

            {/* TODO: PRM details will be shown if campaign type "Simple Signup" and "Profile Store" is "PRM" */}
            {campaign.type === 'Sign-up with Questions & Answers' ? (
              <div className="col-sm-12">
                <div className="cw-campaign--review-section mb-30">
                  <div className="mb-20">
                    <h3 className="cw-heading--primary">
                      PRM connection details
                    </h3>
                  </div>
                  <div className="cw-campaign--review-section-content mb-40">
                    <div className="cw-striped-row">
                      <div className="row">
                        <div className="col-sm-4">
                          <strong>Campaign URL</strong>
                        </div>
                        <div className="col-sm-4"></div>
                        <div className="col-sm-4">
                          <input
                            type="text"
                            placeholder="Awaiting URL"
                            className="form-control"
                            name="url"
                            id="url"
                            value={this.props.profileStore.url || ''}
                            readOnly={true}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="collapse"
                      id="prmDetailsCollapsed"
                      ref={this.prmCollapsePanel}
                    >
                      <div className="cw-striped-row">
                        <div className="row">
                          <div className="col-sm-4">
                            <strong>Secret Key</strong>
                          </div>
                          <div className="col-sm-4"></div>
                          <div className="col-sm-4">
                            <input
                              type="text"
                              placeholder="Awaiting Key"
                              className="form-control"
                              name="secretkey"
                              id="secretkey"
                              value={this.props.profileStore.secretkey || ''}
                              readOnly={true}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="cw-striped-row">
                        <div className="row">
                          <div className="col-sm-4">
                            <strong>Access Key</strong>
                          </div>
                          <div className="col-sm-4"></div>
                          <div className="col-sm-4">
                            <input
                              type="text"
                              placeholder="Awaiting Key"
                              className="form-control"
                              name="accesskey"
                              id="accesskey"
                              value={this.props.profileStore.accesskey || ''}
                              readOnly={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <a
                      className="collapse-h pt-2"
                      href="#prmDetailsCollapsed"
                      role="button"
                      aria-expanded="false"
                      aria-controls="prmDetailsCollapsed"
                      onClick={event => this._handleCollapse(event, 'prm')}
                    >
                      <span ref={this.collapseHandlerTextPrm}>
                        View more prm connection details
                      </span>
                      <i
                        className="fas fa-chevron-down ml-1"
                        ref={this.collapseHandlerIconPrm}
                      ></i>
                    </a>
                  </div>
                </div>
              </div>
            ) : null}

            {/* Gigya Details will be shown if campaign type is "Simple Signup" and "Profile Store" is "Gigya" */}
            <div>
              <div className="cw-campaign--review-section mb-40">
                <div className="mb-20">
                  <h3 className="cw-heading--primary">
                    Gigya connection details
                  </h3>
                </div>
                <div className="cw-campaign--review-section-content mb-40">
                  {this.props.campaign.type !== 'Simple sign-up' ? (
                    <div className="cw-striped-row">
                      <div className="row">
                        <div className="col-sm-4">
                          <label htmlFor="gigyaQAProfileStoreKey">
                            <strong>QA API key</strong>
                          </label>
                        </div>
                        <div className="col-sm-4"></div>
                        <div className="col-sm-4">
                          <input
                            type="text"
                            placeholder="Enter Key"
                            className="form-control"
                            name="gigyaQAProfileStoreKey"
                            id="gigyaQAProfileStoreKey"
                            data-required="true"
                            disabled={
                              this.props.campaign.status ===
                              constant.campaignStatus.CANCELLED
                            }
                            value={this.props.gigyaQAProfileStoreKey}
                            onChange={this._handleChange}
                          />
                          <span
                            className="cw-error cw-hidden"
                            data-control="gigyaQAProfileStoreKey"
                          >
                            <i className="fas fa-exclamation-triangle mr-10"></i>
                            Please enter QA API key.
                          </span>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div className="cw-striped-row">
                    <div className="row">
                      <div className="col-sm-4">
                        <label htmlFor="gigyaProductionProfileStoreKey">
                          <strong>Production API key</strong>
                        </label>
                      </div>
                      <div className="col-sm-4"></div>
                      <div className="col-sm-4">
                        <input
                          type="text"
                          placeholder="Enter Key"
                          className="form-control"
                          data-required="true"
                          name="gigyaProductionProfileStoreKey"
                          id="gigyaProductionProfileStoreKey"
                          data-required="true"
                          disabled={
                            this.props.campaign.status ===
                            constant.campaignStatus.CANCELLED
                          }
                          value={this.props.gigyaProductionProfileStoreKey}
                          onChange={this._handleChange}
                        />
                        <span
                          className="cw-error cw-hidden"
                          data-control="gigyaProductionProfileStoreKey"
                        >
                          <i className="fas fa-exclamation-triangle mr-10"></i>
                          Please enter production api key.
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="cw-striped-row">
                    <div className="row">
                      <div className="col-sm-4">
                        <label htmlFor="gigyaScreenSetName">
                          <strong>Screenset name</strong>
                        </label>
                      </div>
                      <div className="col-sm-4"></div>
                      <div className="col-sm-4">
                        <input
                          type="text"
                          placeholder="Enter name"
                          className="form-control"
                          name="gigyaScreenSetName"
                          id="gigyaScreenSetName"
                          data-required="true"
                          disabled={
                            this.props.campaign.status ===
                            constant.campaignStatus.CANCELLED
                          }
                          value={this.props.gigyaScreenSetName}
                          onChange={this._handleChange}
                        />
                        <span
                          className="cw-error cw-hidden"
                          data-control="gigyaScreenSetName"
                        >
                          <i className="fas fa-exclamation-triangle mr-10"></i>
                          Please enter a screenset name.
                        </span>
                      </div>
                    </div>
                  </div>
                  {isAEM ? (
                    <div className="cw-striped-row">
                      <div className="row">
                        <div className="col-sm-4">
                          <label htmlFor="gigyaStartScreenUrl">
                            <strong>Start screen name</strong>
                          </label>
                        </div>
                        <div className="col-sm-4"></div>
                        <div className="col-sm-4">
                          <input
                            type="text"
                            placeholder="Enter name"
                            className="form-control"
                            name="gigyaStartScreenUrl"
                            id="gigyaStartScreenUrl"
                            disabled={
                              this.props.campaign.status ===
                              constant.campaignStatus.CANCELLED
                            }
                            data-required="true"
                            value={this.props.gigyaStartScreenUrl}
                            onChange={this._handleChange}
                          />
                          <span
                            className="cw-error cw-hidden"
                            data-control="gigyaStartScreenUrl"
                          >
                            <i className="fas fa-exclamation-triangle mr-10"></i>
                            Please enter a start screen name.
                          </span>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div className="cw-striped-row">
                    <div className="row">
                      <div className="col-sm-4">
                        <label htmlFor="gigyaPreferenceCentreScreenSetName">
                          <strong>Preference centre screenset name</strong>
                        </label>
                      </div>
                      <div className="col-sm-4"></div>
                      <div className="col-sm-4">
                        <input
                          type="text"
                          placeholder="Enter name"
                          className="form-control"
                          name="gigyaPreferenceCentreScreenSetName"
                          id="gigyaPreferenceCentreScreenSetName"
                          data-required="true"
                          disabled={
                            this.props.campaign.status ===
                            constant.campaignStatus.CANCELLED
                          }
                          value={this.props.gigyaPreferenceCentreScreenSetName}
                          onChange={this._handleChange}
                        />
                        <span
                          className="cw-error cw-hidden"
                          data-control="gigyaPreferenceCentreScreenSetName"
                        >
                          <i className="fas fa-exclamation-triangle mr-10"></i>
                          Please enter a preference centre screenset name.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`col-sm-12 ${
                this.props.campaign.status === constant.campaignStatus.CANCELLED
                  ? 'd-none'
                  : ''
              }`}
            >
              <div className="cw-form--action-cta">
                <button
                  type="button"
                  className="btn btn btn-outline-secondary mr-3"
                  data-action="reject"
                  onClick={this._handleReject}
                >
                  Reject
                </button>

                <button
                  type="button"
                  className="btn btn-primary mr-3"
                  onClick={this._handleSubmit}
                >
                  Submit
                </button>
                <a
                  href="#saveDraft"
                  className="cw-save-to-draft mt-25"
                  onClick={this._saveAsDraft}
                >
                  Save as draft
                </a>
              </div>
            </div>
          </div>
          {this.props.historyLogs.length ? (
            <HistoryComponent historyLogs={this.props.historyLogs} />
          ) : null}
        </>
      );
    }
  }
}

const mapStateToProps = state => ({
  campaign: state.prmDetails.campaign,
  campaignDetails: state.prmDetails.campaignDetails || {},
  profileStore: state.prmDetails.profileStore,
  questionAnswerList: state.masterData.questionAnswerList || [],
  additionalOptins: state.masterData.additionalOptins,
  asyncInProgress: state.prmDetails.asyncInProgress,
  dataSubmitted: state.gigyaDetails.dataSubmitted,
  dataUpdated: state.gigyaDetails.dataUpdated,
  brandoptin: state.masterData.brandoptin,
  unileveroptin: state.masterData.unileveroptin,
  unileverid: state.masterData.unileveroptin,
  gigyaFormName: state.gigyaDetails.gigyaFormName,
  gigyaStartScreenUrl: state.gigyaDetails.gigyaStartScreenUrl,
  gigyaQAProfileStoreKey: state.gigyaDetails.gigyaQAProfileStoreKey,
  gigyaProductionProfileStoreKey:
    state.gigyaDetails.gigyaProductionProfileStoreKey,
  gigyaScreenSetName: state.gigyaDetails.gigyaScreenSetName,
  gigyaPreferenceCentreScreenSetName:
    state.gigyaDetails.gigyaPreferenceCentreScreenSetName,
  isDraftState: state.gigyaDetails.isDraftState,
  datauploaded: state.gigyaDetails.datauploaded,
  historyLogs: state.historyLogs,
  modalIsOpen: state.app.modalIsOpen,
  activeModal: state.app.activeModal
});

export default connect(mapStateToProps, {
  rejectCampaign,
  setModalStatus,
  fetchCampaignData,
  updateDetails,
  saveGigyaDetails,
  downloadGigyaCssFile,
  setNotificationMessage,
  fetchHistoryLogs
})(GigyaConnectionDetails);
