import React from 'react';

const EmailSaveTemplateIcon = () => {
  return (
    <svg
      width="21"
      height="23"
      viewBox="0 0 21 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.3507 4.33037C19.8087 4.50421 20.2054 4.82737 20.486 5.25521C20.7667 5.68304 20.9174 6.19444 20.9173 6.71875V17.9688C20.9173 19.2368 20.4509 20.4529 19.6207 21.3496C18.7904 22.2463 17.6644 22.75 16.4902 22.75H6.07357C5.58809 22.7501 5.11458 22.5873 4.71843 22.2842C4.32229 21.9811 4.02307 21.5527 3.86211 21.058L6.04648 21.0625H16.4902C17.25 21.0625 17.9786 20.7366 18.5158 20.1564C19.053 19.5762 19.3548 18.7893 19.3548 17.9688V6.71875L19.3507 6.66138V4.33037ZM15.9652 0.25C16.5868 0.25 17.183 0.516684 17.6225 0.991386C18.0621 1.46609 18.309 2.10992 18.309 2.78125V17.4029C18.309 18.0742 18.0621 18.718 17.6225 19.1927C17.183 19.6674 16.5868 19.9341 15.9652 19.9341H2.42773C1.80613 19.9341 1.20999 19.6674 0.770453 19.1927C0.330914 18.718 0.0839844 18.0742 0.0839844 17.4029V2.78125C0.0839844 2.10992 0.330914 1.46609 0.770453 0.991386C1.20999 0.516684 1.80613 0.25 2.42773 0.25H15.9652ZM9.19857 5.59375C9.00978 5.59376 8.82738 5.6676 8.6851 5.80161C8.54282 5.93563 8.45028 6.12075 8.42461 6.32275L8.41732 6.4375V9.25H5.81315C5.61521 9.25007 5.42467 9.33127 5.28004 9.47722C5.1354 9.62316 5.04745 9.82296 5.03396 10.0362C5.02047 10.2495 5.08244 10.4604 5.20735 10.6262C5.33227 10.792 5.51081 10.9005 5.7069 10.9296L5.81315 10.9375H8.41732V13.75C8.41738 13.9638 8.49257 14.1696 8.62771 14.3258C8.76284 14.482 8.94784 14.577 9.14532 14.5915C9.3428 14.6061 9.53804 14.5392 9.69159 14.4043C9.84514 14.2694 9.94555 14.0765 9.97253 13.8647L9.97982 13.75V10.9375H12.584C12.7819 10.9374 12.9725 10.8562 13.1171 10.7103C13.2617 10.5643 13.3497 10.3645 13.3632 10.1513C13.3767 9.93798 13.3147 9.72712 13.1898 9.56129C13.0649 9.39546 12.8863 9.28701 12.6902 9.25788L12.584 9.25H9.97982V6.4375C9.97982 6.21372 9.89751 5.99911 9.751 5.84088C9.60448 5.68264 9.40577 5.59375 9.19857 5.59375Z"
        fill="#007DBB"
      />
    </svg>
  );
};

export default EmailSaveTemplateIcon;
