import React from 'react';
import { Image } from '../../atoms/image';
import { Title } from '../../atoms/Title';
export const DashboardCard = ({ data, colClass }) => {
  return (
    data &&
    data.map(ele => {
      return (
        <>
          <div className={colClass}>
            <div className={ele.className}>
              <div className="ml-10 mb-2 mt-1">
                {ele.imageIcon ? (
                  <Image
                    className="mr-10 dashboard-title-icon-image"
                    src={ele.imageIcon}
                  />
                ) : null}
                <Title className="title" title={ele.title} />
              </div>
              <div className="total-count">
                <Title title={ele.value} />
                {ele.percentage ? (
                  <Title className="percentage" title={ele.percentage} />
                ) : (
                  ''
                )}
              </div>
            </div>
            {ele.secondaryCard ? (
              <div className="mb-2 mt-10 campaign-trends dashboard-secondary-card">
                <span>{ele.secondaryCard}</span>
              </div>
            ) : null}
          </div>
        </>
      );
    })
  );
};
