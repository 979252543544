import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import { resetProfileStore } from '../actions/brandDetails';
import ErrorNotification from './errorNotification';
import { scrollToTop, isUnbundledConsentAndMarketCheck } from '../selectors';
import { getBrandDetails } from '../actions/brandDetails';
import {
  updateCampaignType,
  getCampaignDetails,
  updateCampaignTypeDetails
} from '../actions/campaignType';
import {
  editedCampaignDetails,
  fetchEditedCampaignLogs,
  saveEditedCampaignLogs
} from '../actions/app';
import { resetFieldsOnLanguageChange } from '../actions/brandDetails';
import BreadCrumb from './BreadCrumb';
import Alert from './Alert';
import constant from '../constants';
import recommended from '../images/recommended.png';
import { epsilonMarket } from '../selectors';
import BrandDetailsKnowMore from './BrandDetailsKnowMore';
import ReactTooltip from 'react-tooltip';
import MasterDataGuideline from './MasterDataGuideline';
import Select from 'react-select';
import classNames from 'classnames';
class CampaignType extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false,
      activeModal: null,
      showMessageAtTop: false,
      matchProps: props.match,
      isIncludeQnA: false,
      isNewModalOpen: false,
      isPlatformAgnosticFlag: this.props.isPlatformAgnostic,
      isAutogenerateFlag: this.props.autogenerate,
      isMasterDataFlag: this.props.isMasterData,
      requestNewModelOpen: false,
      masterDataModelOpen: false,
      promotigoVendorOptions: [
        {
          label: 'Promotigo (Global promotions partner)',
          value: 'promotigo'
        }
      ],
      promotionsVendorPartnerOptions: [
        {
          label: 'Promotigo (Global promotions partner)',
          value: 'promotigo'
        },
        {
          label: 'Cheetah',
          value: 'cheetah'
        },
        {
          label: 'Qualifio',
          value: 'qualifio'
        },
        {
          label: 'Other',
          value: 'other'
        }
      ],
      promotigoVendor: null,
      promotigoVendorError: false,
      promotigoVendorPartnerError: false
    };

    this.formElements = {
      campaignType: this.props.campaignType || ''
    };

    if (this.props.incentiveType) {
      this.formElements = {
        incentiveType: this.props.incentiveType || ''
      };
    }
    if (this.props.formType) {
      this.formElements = {
        formType: this.props.formType || ''
      };
    }
    if (this.props.type) {
      this.formElements = {
        type: this.props.type || ''
      };
    }

    if (this.props.isPlatformAgnostic) {
      this.formElements = {
        isPlatformAgnostic: this.props.isPlatformAgnostic || ''
      };
    } else {
      this.formElements = {
        isPlatformAgnostic: false
      };
    }
    if (this.props.autogenerate) {
      this.formElements = {
        autogenerate: this.props.autogenerate || ''
      };
    } else {
      this.formElements = {
        autogenerate: false
      };
    }
   
    if (this.props.isMasterData) {
      this.formElements = {
        isMasterData: this.props.isMasterData || ''
      };
    } else {
      this.formElements = {
        isMasterData: false
      };
    }

    if (this.props.incentivePromotionalPartner) {
      this.formElements = {
        incentivePromotionalPartner: this.props.incentivePromotionalPartner || ''
      };
    } else {
      this.formElements = {
        incentivePromotionalPartner: ''
      };
    }

    if (this.props.incentivePromotionalOtherPartner) {
      this.formElements = {
        incentivePromotionalOtherPartner: this.props.incentivePromotionalOtherPartner || ''
      };
    } else {
      this.formElements = {
        incentivePromotionalOtherPartner: ''
      };
    }

    this.formTypeOptionsForSaas = [
      { value: 'Simple sign-up', label: 'Simple signup' }
    ];
    this.formTypeOptions = [
      { value: 'Simple sign-up', label: 'Simple signup' },
      {
        value: 'Sign-up with Questions & Answers',
        label: 'Sign-up with Questions and Answers'
      }
    ];
    this.incentiveTypeOptions = [
      { value: 'Competitions', label: 'Competitions' },
      { value: 'Product samples', label: 'Product samples' }
    ];
    this.isValid = false;
    this.errorFields = [];
    //this._handleSubmit = this._handleSubmit.bind(this);
    this._handleOnSelectChange = this._handleOnSelectChange.bind(this);
    this._handleBackButton = this._handleBackButton.bind(this);
    this._handleNextButton = this._handleNextButton.bind(this);
    this._handleOnChange = this._handleOnChange.bind(this);
    this._handlePlatrformAgnosticChange = this._handlePlatrformAgnosticChange.bind(
      this
    );
    this._handleAemFormChange = this._handleAemFormChange.bind(this);
    this._openModal = this._openModal.bind(this);
    this._closeModal = this._closeModal.bind(this);
    this._showContent = this._showContent.bind(this);
    this._closeNewModal = this._closeNewModal.bind(this);
    this._openNewModal = this._openNewModal.bind(this);
    this._handleMasterDataFormChange = this._handleMasterDataFormChange.bind(
      this
    );
    this._openRequestNewModelOpen = this._openRequestNewModelOpen.bind(this);
    this._openRequestNewModelClose = this._openRequestNewModelClose.bind(this);
    this._openMasterDataModel = this._openMasterDataModel.bind(this);
    this._closeMasterDataModel = this._closeMasterDataModel.bind(this);
    this._handlePromotigoVendor = this._handlePromotigoVendor.bind(this);
    this._handleIncentiveVendorPartner = this._handleIncentiveVendorPartner.bind(this);
    this._handleMasterPromotigoVendor = this._handleMasterPromotigoVendor.bind(this)
    this.vendorPartner = React.createRef(null);
  }
  campaignTypeChanged = false;
  websiteTypeChanged = false;
  oldIncentiveType = '';
  oldCampaignTypeVal = '';
  oldFormTypeVal = '';
  componentDidMount() {
    this.UUID = this.props.match.params.id;
    this.props.getCampaignDetails(this.UUID, this.props.editCampaign);
    this.formElements['campaignType'] = this.props.campaignType;
    let isQnAFlag =
      this.props.type === 'Sign-up with Questions & Answers' ||
      this.props.formType === 'Sign-up with Questions & Answers';
    this.setState({
      isIncludeQnA: isQnAFlag
    });
    // if (!this.props.autogenerate && !this.props.isPlatformAgnostic) {
    //   this.formElements['isPlatformAgnostic'] = true
    //   this.props.updateCampaignTypeDetails(this.formElements);
    // }
    // this.isCampaignTypeUpdated(this.UUID);
    this.isEpsiloneDefault();
   
  }

  isEpsiloneDefault() {
    let countryCode =
      this.props.campaign &&
      this.props.campaign.country &&
      this.props.campaign.country.code;
    if (epsilonMarket(countryCode)) {
      this.formElements['type'] = null;
      this.formElements['campaignType'] = 'Direct messaging';
      if (
        this.props.type === 'Sign-up with Questions & Answers' ||
        this.props.formType === 'Sign-up with Questions & Answers'
      ) {
        this.formElements['type'] = 'Sign-up with Questions & Answers';
      } else {
        this.formElements['type'] = 'Simple sign-up';
      }
      this.formElements['isPlatformAgnostic'] = true;
      this.formElements['isMasterData'] = false;
      this.formElements['autogenerate'] = false;
      this.props.updateCampaignTypeDetails(this.formElements);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.isCampaignEdited &&
      this.props.isCampaignEdited !== prevProps.isCampaignEdited
    ) {
      this.props.fetchEditedCampaignLogs(this.UUID);
    }
  }

  _validateForm() {
    let formElements = this.formElements;
    this.errorFields = [];
    for (let key in formElements) {
      if(key == 'isMasterData' && formElements.hasOwnProperty(key) &&  formElements[key]){
        if(!this.props.incentivePromotionalPartner){
          this.setState({
            promotigoVendorError : true
          })
          if(this.props.campaignType === 'Incentive'){
            this.errorFields = this._addErrorFields(this.errorFields, key);
          }
        } else if(this.props.incentivePromotionalPartner === 'other'){
          if(!this.props.incentivePromotionalOtherPartner){
            this.setState({
              promotigoVendorPartnerError : true
            })
          }
        }
      } else {
        if (formElements.hasOwnProperty(key)) {
          let selector = document.querySelector("[name='" + key + "']");
          if (selector) {
            if (formElements[key] && formElements[key] !== false) {
              if (selector.parentElement.classList.contains('error')) {
                selector.parentElement.classList.remove('error');
              }
              if (document.querySelector("[data-control='" + key + "']")) {
                if (
                  !document
                    .querySelector("[data-control='" + key + "']")
                    .classList.contains('cw-hidden')
                ) {
                  document
                    .querySelector("[data-control='" + key + "']")
                    .classList.add('cw-hidden');
                }
              }
              this.errorFields = this._filterErrorFields(this.errorFields, key);
            } else {
              if (!selector.parentElement.classList.contains('error')) {
                selector.parentElement.classList.add('error');
              }
              if (document.querySelector("[data-control='" + key + "']")) {
                if (
                  document
                    .querySelector("[data-control='" + key + "']")
                    .classList.contains('cw-hidden')
                ) {
                  document
                    .querySelector("[data-control='" + key + "']")
                    .classList.remove('cw-hidden');
                }
              }
              this.errorFields = this._addErrorFields(this.errorFields, key);
            }
          }
        }
      }
     
    }
    const { campaign } = this.props;
    const countryCode = campaign.country && campaign.country.code;
    const isUnbundledMarket = isUnbundledConsentAndMarketCheck(
      this.props.campaignConfig,
      countryCode
    );
    if (
      (constant.featureFlags.ENABLE_MASTER_DATA_ONLY_CAMPAIGN === false ||
        !constant.featureFlags.ENABLE_MASTER_DATA_ONLY_CAMPAIGN) &&
      isUnbundledMarket &&
      this.props.campaignType === 'Incentive'
    ) {
      if (formElements['isMasterData'] === false) {
        this.setState({ showMessageAtTop: true });
        scrollToTop();
      }
    } else if (this.errorFields.length === 0) {
      this.isValid = true;
      this.setState({ showMessageAtTop: false });
    } else {
      this.setState({ showMessageAtTop: true });
      scrollToTop();
    }
  }
  _addErrorFields(array, itemToAdd) {
    return array.filter(field => field !== itemToAdd).concat([itemToAdd]);
  }

  _filterErrorFields(array, itemToRemove) {
    return array.filter(item => item !== itemToRemove);
  }

  _closeNewModal() {
    this.setState({
      isNewModalOpen: false
    });
  }

  _openNewModal(event) {
    event.preventDefault();
    this.setState({
      isNewModalOpen: true
    });
  }

  _openRequestNewModelOpen(event) {
    event.preventDefault();
    this.setState({
      requestNewModelOpen: true
    });
  }

  _openRequestNewModelClose(event) {
    this.setState({
      requestNewModelOpen: false
    });
  }

  _openMasterDataModel(event) {
    event.preventDefault();
    this.setState({
      masterDataModelOpen: true
    });
  }

  _closeMasterDataModel(event) {
    this.setState({
      masterDataModelOpen: false
    });
  }

  componentWillReceiveProps(nextProps, nextState) {
    if (nextProps.campaignTypeloaded) {
      this.formElements['campaignType'] = nextProps.campaignType || '';
      if (
        nextProps.incentiveType &&
        nextProps.campaignType.toLowerCase() === 'incentive'
      ) {
        this.formElements['incentiveType'] = nextProps.incentiveType;
      }
      if (
        nextProps.type &&
        nextProps.campaignType.toLowerCase() === 'direct messaging'
      ) {
        this.formElements['type'] = nextProps.type;
      }
      if (
        nextProps.type &&
        nextProps.campaignType.toLowerCase() === 'masterdata'
      ) {
        this.formElements['type'] = nextProps.type;
      }
      if (
        nextProps.formType &&
        nextProps.campaignType.toLowerCase() === 'incentive'
      ) {
        this.formElements['formType'] = nextProps.formType;
      }
    }

    if (nextProps.campaignTypeUpdated) {
      this.props.history.push(
        '/campaign-wizard/campaign/' + this.UUID + '/details'
      );
    }

    if(nextProps && nextProps.campaign && nextProps.campaign.uid){
      const { campaign } = nextProps;
      const countryCode = campaign.country && campaign.country.code;
      const isUnbundledMarket = isUnbundledConsentAndMarketCheck(
        this.props.campaignConfig,
        countryCode
      );
      if (isUnbundledMarket && this.props.campaignType === 'Incentive') {
        if(!this.props.isMasterData){
          this.resetFieldOnChangeRadio(countryCode);
        }
      }
    }

    return null;
  }

  _showContent(event, type) {
    event.preventDefault();
    this.setState(state => {
      return {
        ...state,
        ...{
          modalIsOpen: true,
          activeModal: type
        }
      };
    });
  }

  _validateField(event) {
    if (event.target) {
      if (
        document.querySelector("[name='" + event.target.name + "']") &&
        document
          .querySelector("[name='" + event.target.name + "']")
          .parentElement.classList.contains('error')
      ) {
        document
          .querySelector("[name='" + event.target.name + "']")
          .parentElement.classList.remove('error');
      }

      if (
        document.querySelector("[data-control='" + event.target.name + "']") &&
        !document
          .querySelector("[data-control='" + event.target.name + "']")
          .classList.contains('cw-hidden')
      ) {
        document
          .querySelector("[data-control='" + event.target.name + "']")
          .classList.add('cw-hidden');
      }
    }
  }
  _handleBackButton(event) {
    event.preventDefault();
    this.props.resetProfileStore(null);
    this.props.history.push(
      '/campaign-wizard/campaign/' + this.UUID + '/branddetails'
    );
  }

  _handleNextButton() {
    if (this.props.incentivePromotionalOtherPartner) {
      this.formElements['incentivePromotionalOtherPartner'] =
        this.props.incentivePromotionalOtherPartner || '';
    }
    
    if (this.props.isMasterData) {
      this.formElements['isMasterData'] =
        this.props.isMasterData || false;
    }
    this._validateForm();

    let isSaasType =
      this.props.websiteType && this.props.websiteType.label === 'Adobe SaaS';

    if (this.isValid) {
      let isDirect = this.formElements['campaignType'] === 'Direct messaging';
      let isSampleType = this.props.incentiveType === 'Product samples';
      let isSimpleType = this.formElements['type'] === 'Simple sign-up';

      if (
        (this.campaignTypeChanged &&
          (isSaasType ||
            this.props.isPlatformAgnostic ||
            this.props.isMasterData)) ||
        this.websiteTypeChanged
      ) {
        this.props.resetFieldsOnLanguageChange(this.UUID, false);
      }
      // if(isSampleType){
      //   this.props.resetFieldsOnLanguageChange(this.UUID,isSampleType);
      // }
      this.formElements['nonGigyaSignupForm'] =
        isDirect && isSimpleType ? true : false;
      this.formElements['isEdited'] =
        this.props.editCampaign || this.props.isCampaignEdited ? true : false;
      if (this.props.editCampaign) {
        this.formElements['status'] = 1;
      }
      if (this.props.isPlatformAgnostic) {
        this.formElements['isPlatformAgnostic'] =
          this.props.isPlatformAgnostic || '';
      }
      if (this.props.autogenerate) {
        this.formElements['autogenerate'] = this.props.autogenerate || '';
      }

      if (this.props.isMasterData) {
        this.formElements['isMasterData'] = this.props.isMasterData || '';
      }
      if (this.props.incentivePromotionalPartner) {
        this.formElements['incentivePromotionalPartner'] =
          this.props.incentivePromotionalPartner || '';
      }
     
      
      this.setState({ showMessageAtTop: false });
      this.props.updateCampaignType(
        this.UUID,
        this.formElements,
        this.props.userName,
        this.props.userEmail
      );
      if (this.props.isCampaignEdited || this.props.editCampaign) {
        this.props.saveEditedCampaignLogs(
          this.UUID,
          this.props.editCampaignDetails
        );
      }
      // if(isSaasType && isIncentiveType){
      //   scrollToTop();
      // } else {
    }
  }

  _handleOnSelectChange(selectedOption, event) {
    this.formElements[event.name] = selectedOption.value;
    this._validateDropDownField(event.name);
    if (event.name === 'formType') {
      this.formElements['incentiveType'] = this.props.incentiveType;
    }
    this.props.updateCampaignTypeDetails(this.formElements);
    this.props.editedCampaignDetails({ [event.name]: selectedOption.value });
  }

  _includeQnAtoggle(event, formName, qnAFlag) {
    const formValue = qnAFlag
      ? 'Sign-up with Questions & Answers'
      : 'Simple sign-up';
    this.formElements[formName] = formValue;
    if (formName === 'formType') {
      this.formElements['incentiveType'] = this.props.incentiveType;
    }
    if (this.props.isPlatformAgnostic) {
      this.formElements['isPlatformAgnostic'] = this.props.isPlatformAgnostic;
    }
    if (this.props.autogenerate) {
      this.formElements['autogenerate'] = this.props.autogenerate;
    }
    if (this.props.isMasterData) {
      this.formElements['isMasterData'] = this.props.isMasterData;
    }
    this.props.updateCampaignTypeDetails(this.formElements);
    this.props.editedCampaignDetails({ [formName]: formValue });
  }

  isCampaignTypeUpdated = () => {
    this.campaignTypeChanged = false;
    if (
      this.props.campaignType !==
      (this.props.campaign && this.props.campaign.campaignType)
    ) {
      this.campaignTypeChanged = true;
    }
    if (this.props.campaignType === 'Direct messaging') {
      if (
        this.props.type !== (this.props.campaign && this.props.campaign.type)
      ) {
        this.campaignTypeChanged = true;
      }
    }
    if (this.props.campaignType === 'Incentive') {
      if (
        this.props.incentiveType !==
          (this.props.campaign && this.props.campaign.incentiveType) ||
        this.props.formType !==
          (this.props.campaign && this.props.campaign.type)
      ) {
        this.campaignTypeChanged = true;
      }
    }
    return this.campaignTypeChanged;
  };

  _validateDropDownField(field) {
    if (
      document
        .querySelector("[name='" + field + "']")
        .parentElement.classList.contains('error')
    ) {
      document
        .querySelector("[name='" + field + "']")
        .parentElement.classList.remove('error');
    }
    if (
      !document
        .querySelector("[data-control='" + field + "']")
        .classList.contains('cw-hidden')
    ) {
      document
        .querySelector("[data-control='" + field + "']")
        .classList.add('cw-hidden');
    }
  }

  _handlePlatrformAgnosticChange(event) {
    this.formElements['isPlatformAgnostic'] = true;
    this.formElements['autogenerate'] = false;
    this.formElements['isMasterData'] = false;
    this.websiteTypeChanged = false;
    if (
      this.state.isPlatformAgnosticFlag !=
      this.formElements['isPlatformAgnostic']
    ) {
      this.websiteTypeChanged = true;
    }
    if (this.state.isAutogenerateFlag != this.formElements['autogenerate']) {
      this.websiteTypeChanged = true;
    }
    if (this.props.campaignType === 'Incentive') {
      this.formElements['incentivePromotionalPartner'] = 'promotigo';
    } else {
      this.formElements['incentivePromotionalPartner'] = null;
    }
    this.props.updateCampaignTypeDetails(this.formElements);
    this.isCampaignTypeUpdated(this.UUID);
    this.setState({
      promotigoVendorError : false
    })
  }

  updatePlateFormAgnostic() {
    const { campaign } = this.props;
    const countryCode = campaign.country && campaign.country.code;
    const isUnbundledMarket = isUnbundledConsentAndMarketCheck(
      this.props.campaignConfig,
      countryCode
    );
    if (isUnbundledMarket && this.props.campaignType === 'Incentive') {
      this.formElements['isPlatformAgnostic'] = false;
    } else {
      this.formElements['isPlatformAgnostic'] = true;
    }
    this.formElements['autogenerate'] = false;
    this.props.updateCampaignTypeDetails(this.formElements);
    this.isCampaignTypeUpdated(this.UUID);
  }

  _handleAemFormChange(event) {
    this.formElements['isPlatformAgnostic'] = false;
    this.formElements['autogenerate'] = true;
    this.websiteTypeChanged = false;
    if (
      this.state.isPlatformAgnosticFlag !=
      this.formElements['isPlatformAgnostic']
    ) {
      this.websiteTypeChanged = true;
    }
    if (this.state.isAutogenerateFlag != this.formElements['autogenerate']) {
      this.websiteTypeChanged = true;
    }
    this.props.updateCampaignTypeDetails(this.formElements);
  }

  _handleMasterDataFormChange(event) {
    this.formElements['isPlatformAgnostic'] = false;
    this.formElements['isMasterData'] = true;
    this.formElements['incentivePromotionalPartner'] = null;
    this.formElements['incentivePromotionalOtherPartner'] = null;
    this.websiteTypeChanged = false;
    if (
      this.state.isPlatformAgnosticFlag !=
      this.formElements['isPlatformAgnostic']
    ) {
      this.websiteTypeChanged = true;
    }
    if (this.state.isMasterDataFlag != this.formElements['isMasterData']) {
      this.websiteTypeChanged = true;
    }
    this.props.updateCampaignTypeDetails(this.formElements);
    this.isCampaignTypeUpdated(this.UUID);
  }
  resetFieldOnChangeRadio(countryCode) {
    let isUnbundledMarket  = false
    if(countryCode){
       isUnbundledMarket = isUnbundledConsentAndMarketCheck(
        this.props.campaignConfig,
        countryCode
      );
    }else {
      const { campaign } = this.props;
      const countryCode = campaign.country && campaign.country.code;
       isUnbundledMarket = isUnbundledConsentAndMarketCheck(
        this.props.campaignConfig,
        countryCode
      );
    }

    if (isUnbundledMarket && this.props.campaignType === 'Incentive') {
      this.formElements['isPlatformAgnostic'] = false;
      this.formElements['autogenerate'] = false;
      this.formElements['isMasterData'] = constant.featureFlags.ENABLE_MASTER_DATA_ONLY_CAMPAIGN
        ? true
        : false;
      if(this.props.incentivePromotionalPartner){
        this.formElements['incentivePromotionalPartner'] = this.props.incentivePromotionalPartner
      }
      if(this.props.incentivePromotionalOtherPartner){
        this.formElements['incentivePromotionalOtherPartner'] = this.props.incentivePromotionalOtherPartner
      }
    } else {
      this.formElements['isPlatformAgnostic'] = true;
      this.formElements['autogenerate'] = false;
      this.formElements['isMasterData'] = false;
      this.formElements['incentivePromotionalPartner'] = 'promotigo';
      this.formElements['incentivePromotionalOtherPartner'] = null;
    }

    if (!isUnbundledMarket) {
      this.setState({
        isIncludeQnA: false
      });
    }

    if (!isUnbundledMarket) {
      if (this.props.campaignType === 'Incentive') {
        this.formElements['formType'] = 'Simple sign-up';
        this.formElements['type'] = '';
      } else {
        this.formElements['type'] = 'Simple sign-up';
        this.formElements['formType'] = '';
      }
    } 
    this.props.updateCampaignTypeDetails(this.formElements);
  }

  _handleOnChange(event, incentiveName) {
    if (event.target) {
      if (
        event.target.type === 'radio' &&
        event.target.value.toLowerCase() === 'direct messaging'
      ) {
        this.formElements['type'] = null;
        this.formElements['incentivePromotionalPartner'] = null;
        delete this.formElements['formType'];
        delete this.formElements['incentiveType'];
        if (this.state.isIncludeQnA) {
          this.formElements['type'] = 'Sign-up with Questions & Answers';
        } else {
          this.formElements['type'] = 'Simple sign-up';
        }
      } else if (
        event.target.type === 'radio' &&
        incentiveName.toLowerCase() === 'competitions'
      ) {
        this.formElements['formType'] = null;
        this.formElements['incentiveType'] = incentiveName;
        delete this.formElements['type'];
        if (this.state.isIncludeQnA) {
          this.formElements['formType'] = 'Sign-up with Questions & Answers';
        } else {
          this.formElements['formType'] = 'Simple sign-up';
        }
      } else if (
        event.target.type === 'radio' &&
        incentiveName.toLowerCase() === 'product samples'
      ) {
        this.formElements['formType'] = null;
        this.formElements['incentiveType'] = incentiveName;
        delete this.formElements['type'];
        if (this.state.isIncludeQnA) {
          this.formElements['formType'] = 'Sign-up with Questions & Answers';
        } else {
          this.formElements['formType'] = 'Simple sign-up';
        }
      } else if (
        event.target.type === 'radio' &&
        incentiveName.toLowerCase() === 'masterdata'
      ) {
        this.formElements['type'] = null;
        delete this.formElements['formType'];
        delete this.formElements['incentiveType'];
        if (this.state.isIncludeQnA) {
          this.formElements['type'] = 'masterdata';
        } else {
          this.formElements['type'] = 'masterdata';
        }
      }

      this.formElements[event.target.name] =
        event.target.type === 'checkbox'
          ? event.target.checked
          : event.target.value;
      this.selectedCampaignType = event.target.value;
      this._validateField(event);
      this.props.updateCampaignTypeDetails(this.formElements);
      this.props.editedCampaignDetails(this.formElements);
      this.isCampaignTypeUpdated(this.UUID);
      setTimeout(() => {
        this.resetFieldOnChangeRadio();
      }, 300);
    }
  }

  _openModal(event) {
    event.preventDefault();
    this.setState(state => {
      return { ...state, ...{ modalIsOpen: true } };
    });
  }

  _closeModal() {
    this.setState(state => {
      return { ...state, ...{ modalIsOpen: false } };
    });
  }

  _handlePromotigoVendor(selectedOption) {
    this.setState({
      promotigoVendor: selectedOption,
      promotigoVendorError : false
    });
    let detailsToUpdate = {
      incentivePromotionalPartner: selectedOption.value
    };
    detailsToUpdate = { ...this.formElements, ...detailsToUpdate };
    this.formElements['incentivePromotionalPartner'] = selectedOption.value
    this.props.updateCampaignTypeDetails(this.formElements);
    this.props.editedCampaignDetails({ ['incentivePromotionalPartner']: selectedOption.value });
  }

  _handleMasterPromotigoVendor(selectedOption) {
    this.setState({
      promotigoVendor: selectedOption,
      promotigoVendorError : false
    });
    let detailsToUpdate = {
      incentivePromotionalPartner: selectedOption.value
    };
    this.formElements['isMasterData']  = true
    this.formElements['isPlatformAgnostic']  = false
    this.formElements['autogenerate']  = false
    detailsToUpdate = { ...this.formElements, ...detailsToUpdate };
    this.formElements['incentivePromotionalPartner'] = selectedOption.value
    this.formElements['incentivePromotionalOtherPartner'] = null
    this.props.updateCampaignTypeDetails(this.formElements);
    this.props.editedCampaignDetails({ ['incentivePromotionalPartner']: selectedOption.value });
  }

  _handleIncentiveVendorPartner(event) {
    this.vendorPartner.current = event.target.value;
    this.formElements['incentivePromotionalPartner'] = this.props.incentivePromotionalPartner;
    this.formElements['isMasterData']  = true
    this.formElements['isPlatformAgnostic']  = false
    this.formElements['autogenerate']  = false
    this.formElements['incentivePromotionalOtherPartner'] = event.target.value;
    this.props.updateCampaignTypeDetails(this.formElements);
    this.setState({
      promotigoVendorPartnerError : false
    })
  }

  render() {
  
    this.isCampaignTypeUpdated();
    Modal.setAppElement('#root');
    let { websiteType, campaignType, type, campaignConfig } = this.props;

    let isIncentiveType = campaignType === 'Incentive';
    let isQnAFlag =
      this.props.type === 'Sign-up with Questions & Answers' ||
      this.props.formType === 'Sign-up with Questions & Answers';

    let isAEMType =
      websiteType &&
      (websiteType.label === 'Adobe Classic' ||
        websiteType.label === 'Adobe Templated' ||
        websiteType.label === 'Standalone');

    let isSaasType = websiteType && websiteType.label === 'Adobe SaaS';
    if (isAEMType === false) {
      if (this.props.isMasterData === false) {
        this.updatePlateFormAgnostic();
      }
    }

    let countryCode =
      this.props.campaign &&
      this.props.campaign.country &&
      this.props.campaign.country.code;

    let isdirectSimpleSignup =
      campaignType &&
      campaignType === 'Direct messaging' &&
      type &&
      type === 'Simple sign-up';
    let isEpsilonMarket = epsilonMarket(countryCode);

    const qnaToggle = formType => {
      return (
        <div className="form-group form-group-field-width">
          <div className="form-group d-flex ">
            <label className="switch-saas">
              <input
                type="checkbox"
                name={`includeQnAtoggle`}
                checked={this.state.isIncludeQnA || isQnAFlag}
                onChange={() => {
                  this.setState(
                    prevState => ({
                      isIncludeQnA: !this.state.isIncludeQnA
                    }),
                    e => {
                      this._includeQnAtoggle(
                        e,
                        formType,
                        this.state.isIncludeQnA
                      );
                    }
                  );
                }}
              />
              <div className="slider-saas round"></div>
            </label>
            <label
              className="ml-10"
              htmlFor={`includeQnAtoggle`}
              style={{ marginTop: '-6px' }}
            >
              <ReactTooltip
                id="includeQna"
                place="right"
                type="info"
                multiline={true}
                className="cw-tooltip"
              />
              <span>Include Q&As</span>{' '}
              <span
                className="cw-icon cw-icon--help"
                data-tip="Custom Q&As help collect additional information about consumer needs, preferences, and pain points, leading to richer consumer insight and better segmentation"
                data-for="includeQna"
              >
                <i className="fas fa-info-circle"></i>
              </span>
            </label>
          </div>
          {!isEpsilonMarket && (
            <>
              <span>{`What are you looking for? `}</span>{' '}
              <span className="cw-icon cw-icon--help">
                <div
                  className="fas fa-info-circle"
                  onClick={this._openRequestNewModelOpen}
                ></div>
              </span>
            </>
          )}
        </div>
      );
    };

    const platFormAgnosticComponent = htmlForId => {
      const { campaign } = this.props;
      const countryCode = campaign.country && campaign.country.code;
      const isUnbundledMarket = isUnbundledConsentAndMarketCheck(
        this.props.campaignConfig,
        countryCode
      );

      return (
        <div className="form-check">
          <input
            type="radio"
            className="form-check-input"
            id={htmlForId}
            name={htmlForId}
            value={'Platform agnostic forms'}
            checked={this.props.isPlatformAgnostic}
            disabled={
              (htmlForId === 'platformAgnosticFormsCompitition' ||
                htmlForId === 'platformAgnosticFormsSample') &&
              isUnbundledMarket
                ? true
                : false
            }
            onChange={this._handlePlatrformAgnosticChange}
          />
          <label className="form-check-label" htmlFor={htmlForId}>
            <p className="d-flex">
              <p className="cw-heading--tenary title">Form widgets</p>{' '}
              <p
                className="cw-heading--tenary"
                style={{ marginLeft: '-8px', marginTop: '-3px' }}
              >
                <img
                  src={recommended}
                  alt={recommended}
                  width="130"
                  height="30"
                  style={{ objectFit: 'contain' }}
                ></img>
              </p>
            </p>
            <Alert
              alertType="campaignTypeKnownmore"
              textMessage={constant.CAMPAIGN_MESSAGE.platformAgnosticMessage}
              openModal={this._openNewModal}
              isKnowMore={true}
              modelText={'Know more'}
            />
            {(htmlForId === 'platformAgnosticFormsCompitition' ||
              htmlForId === 'platformAgnosticFormsSample') &&
            isUnbundledMarket ? (
              <div className="email-sendafter-font">
                This option is unavailable
                <ReactTooltip
                  id={'IncentiveTooltip'}
                  place="right"
                  type="info"
                  multiline={true}
                  className="cw-tooltip custom-cw-toolip"
                />
                <span
                  className="ml-10 cw-icon cw-icon--help"
                  data-for="IncentiveTooltip"
                  data-tip={`This option is unavailable as Promotigo is currently not set to receive data per your country’s consent model`}
                >
                  <i className="fas fa-question-circle"></i>
                </span>
              </div>
            ) : null}
          </label>
          {this.props.isPlatformAgnostic &&
          (htmlForId === 'platformAgnosticFormsCompitition' ||
            htmlForId === 'platformAgnosticFormsSample') ? (
            <div className="form-group mt-10 vendor-dropdown">
              <label htmlFor="promotigoVendor">
                <span>Powered by</span>
                <ReactTooltip
                  id={'promotigoVendorToolTip'}
                  place="right"
                  type="info"
                  multiline={true}
                  className="cw-tooltip custom-cw-toolip"
                />
                <span
                  className="float-right  cw-icon cw-icon--help"
                  data-for="promotigoVendorToolTip"
                  data-tip="Incentivized sign up forms are integrated with Promotigo, the global approved partner for promotions. Once your request is submitted and approved, you will receive an email notification from us and will be required to setup your campaign in Promotigo as well to make this form fully functional."
                >
                  <i className="fas fa-question-circle"></i>
                </span>
              </label>
              <div id={'promotigoVendorRequestDiv'}>
                <Select
                  className={'cw-select--custom'}
                  name={'promotigoVendor'}
                  label={'promotigoVendor'}
                  placeholder={'Please select'}
                  options={this.state.promotigoVendorOptions}
                  onChange={this._handlePromotigoVendor}
                  value={
                    this.props &&
                    this.props.incentivePromotionalPartner != null &&
                    this.state.promotigoVendorOptions.find(
                      e => e.value == 'promotigo'
                    )
                  }
                />
              </div>
            </div>
          ) : null}
        </div>
      );
    };

    const aemComponent = htmlForId => {
      const showAEMComponent = isAEMType
        ? this.props.editCampaign
          ? true
          : constant.featureFlags.ENABLE_AEM_FORM_OPTION
        : false;
      return showAEMComponent ? (
        <div className="form-check mb-20">
          <input
            type="radio"
            className="form-check-input"
            id={htmlForId}
            name={htmlForId}
            value={'AEM forms'}
            checked={this.props.autogenerate}
            onChange={this._handleAemFormChange}
          />
          <label className="form-check-label" htmlFor={htmlForId}>
            <p className="cw-heading--tenary">
              AEM forms{' '}
              <span className="cw-heading--tenary-sub-text">{` (To be discontinued effective 1 Jan 2023) `}</span>
            </p>
            <p>{constant.CAMPAIGN_MESSAGE.aemMessage}</p>
            <div className="mt-3">
              <Alert
                alertType={'warningMessageWithIcon'}
                textMessage={constant.SAAS_MESSAGES.aemDiscontinuationMessage}
                showComponent
              />
            </div>
          </label>
        </div>
      ) : null;
    };

    const masterDataComponent = htmlForId => {
      return constant.featureFlags.ENABLE_MASTER_DATA_ONLY_CAMPAIGN ? (
        <div className="form-check mb-20">
          <input
            type="radio"
            className="form-check-input"
            id={htmlForId}
            name={htmlForId}
            value={'masterdata'}
            checked={this.props.isMasterData}
            onChange={this._handleMasterDataFormChange}
          />
          <label className="form-check-label" htmlFor={htmlForId}>
            <p>
              <span className="cw-heading--tenary">Master data </span>
            </p>
            <p>
              {`Get master data IDs for offline ingestion, custom forms and integrations. `}
              <span className="cw-icon cw-icon--help">
                <div
                  className="fas fa-info-circle"
                  onClick={this._openMasterDataModel}
                ></div>
              </span>
            </p>
          </label>
          {this.props.isMasterData &&
          (htmlForId === 'isMasterDataCompitition' ||
            htmlForId === 'isMasterDataSample') ? (
            <div className="form-group mt-10 vendor-dropdown">
              <label htmlFor="masterVendor">
                <span>Promotions vendor partner</span>
                <ReactTooltip
                  id={'masterVendorToolTip'}
                  place="right"
                  type="info"
                  multiline={true}
                  className="cw-tooltip custom-cw-toolip"
                />
                <span
                  className="float-right  cw-icon cw-icon--help"
                  data-for="masterVendorToolTip"
                  data-tip="We capture this only for informational purposes. Please note Campaign Wizard does not integrate with these platforms. We will provide you master data but you will have to create your forms with the help of your agencies partner or your promotions vendor."
                >
                  <i className="fas fa-question-circle"></i>
                </span>
              </label>
              <Select
                className={classNames('cw-select--custom', {
                  "cw-error-focus" :this.state.promotigoVendorError
                })}
                name={'masterVendor'}
                label={'masterVendor'}
                placeholder={'Please select'}
                options={this.state.promotionsVendorPartnerOptions}
                onChange={this._handleMasterPromotigoVendor}
                value={
                  this.props &&
                  this.props.incentivePromotionalPartner != null &&
                  this.state.promotionsVendorPartnerOptions.find(
                    e => e.value == this.props.incentivePromotionalPartner 
                  )
                }
              />
              <span className={classNames('cw-error',{
                'cw-hidden' : !this.state.promotigoVendorError
              })}>
                <i className="fas fa-exclamation-triangle mr-10"></i>
                Please specify your vendor partner.
              </span>
            </div>
          ) : null}
          {this.props.incentivePromotionalPartner &&
          this.props.incentivePromotionalPartner === 'other' ? (
            <div className="form-group vendor-dropdown">
              <label htmlFor="incentivePromotionalOtherPartner">
                <span>Please specify</span>
              </label>
              <div className={classNames('cw-select--custom', {
                  "cw-error-focus" :this.state.promotigoVendorPartnerError
                })}>
              <input
                type="text"
                className="form-control"
                name="incentivePromotionalOtherPartner"
                id="incentivePromotionalOtherPartner"
                maxLength="50"
                ref="vendorPartner"
                aria-describedby="campaignnamehelp"
                data-required="true"
                value={this.props.incentivePromotionalOtherPartner || ''}
                onChange={this._handleIncentiveVendorPartner}
              />
              </div>
              <span className={classNames('cw-error',{
                'cw-hidden' : !this.state.promotigoVendorPartnerError
              })}>
                <i className="fas fa-exclamation-triangle mr-10"></i>
                Please specify your vendor partner.
              </span>
            </div>
          ) : null}
        </div>
      ) : null;
    };

    //let isSaasType = websiteType && (websiteType.label === 'Adobe SaaS');
    return (
      <>
        <div className="row">
          <div className="col-sm-8">
            <Modal
              isOpen={
                this.state.modalIsOpen && this.state.activeModal === 'formtypes'
              }
              onRequestClose={this._closeModal}
              className="cw-modal cw-modal--formtype"
              contentLabel="info"
              style={{
                overlay: {
                  backgroundColor: constant.EMAIL_OVERLAY_BACKGROUND
                }
              }}
            >
              <button
                className="cw-modal--close"
                title="Close"
                onClick={this._closeModal}
              >
                <i className="fas fa-times"></i>
              </button>
              <h3 className="cw-heading--primary mb-10">Form types</h3>
              <p>Simple sign-up</p>
              <p className="mb-20">
                Capture consumers' name, email and consent details only.
              </p>
              <p>Sign-up with Questions &amp; Answers</p>
              <p className="mb-20">
                As simple sign-up, plus add additional questions and answers to
                profile and segment your consumers.
              </p>
            </Modal>
            <div className="cw-section ml-20">
              <BreadCrumb
                match={this.state.matchProps}
                editCampaign={
                  this.props.editCampaign || this.props.isCampaignEdited
                }
              />
              {!this.isValid && this.state.showMessageAtTop ? (
                <ErrorNotification />
              ) : null}

              {/* <Alert
                type={"warning"}
                alertType={"isPromotigoInterimMessageRequired"}
                showComponent={isIncentiveType}
              /> */}

              <div className="cw-section--title mb-30">
                <h2 className="cw-heading--secondary mb-15">
                  Choose request type
                </h2>
              </div>
              <Alert
                alertType={'saasWarningAlertMessage'}
                textMessage={constant.SAAS_MESSAGES.saasCampaignTypeMessage}
                // showComponent={isSaasType}
              />
              {/* <Alert
                alertType={"saasWarningAlertMessage"}
                textMessage={
                  isAEMType
                    ? constant.SAAS_MESSAGES.nonSaasCampaignTypeMessage
                    : constant.SAAS_MESSAGES.otherCampaignTypeMessage
                }
                showComponent={!isSaasType && isdirectSimpleSignup}
              /> */}

              <div className="cw-section--content">
                <div className=" cw-form--legend mb-15 ">
                  <div className="form-check">
                    <input
                      type="radio"
                      className="form-check-input"
                      id="directmessagaing"
                      name="campaignType"
                      value={'Direct messaging'}
                      checked={
                        this.props.campaignType.toLowerCase() ===
                        'direct messaging'
                      }
                      onChange={this._handleOnChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="directmessagaing"
                    >
                      <p className="cw-heading--tenary">Simple sign up</p>
                      {this.props.campaignType.toLowerCase() !==
                      'direct messaging' ? (
                        <p className="">
                          {constant.CAMPAIGN_OPTION_MESSAGE.simpleSignUp}
                        </p>
                      ) : null}
                    </label>
                  </div>
                  <div
                    className={`ml-33 pt-0 ${
                      !(isSaasType && isEpsilonMarket)
                        ? 'cw-promotigosubCategory'
                        : ''
                    } ${
                      this.props.campaignType.toLowerCase() ===
                      'direct messaging'
                        ? ''
                        : 'cw-hidden'
                    }`}
                  >
                    <p className="mb-10">
                      {constant.CAMPAIGN_OPTION_MESSAGE.simpleSignUp}
                    </p>
                    {qnaToggle('type')}

                    {platFormAgnosticComponent('platformAgnosticFormsDirect')}
                    <br />
                    {aemComponent('isAEMFormDirect')}

                    {!isEpsilonMarket
                      ? masterDataComponent('isMasterDataDirect')
                      : null}
                  </div>
                </div>
                {!(isSaasType && isEpsilonMarket) ? (
                  <>
                    <div className="cw-form--legend mb-15">
                      <div className="form-check">
                        <input
                          type="radio"
                          className="form-check-input"
                          id="incentivecampaign"
                          name="campaignType"
                          value={'Incentive'}
                          checked={
                            this.props.incentiveType &&
                            this.props.incentiveType.toLowerCase() ===
                              'competitions'
                          }
                          onChange={e => {
                            this._handleOnChange(e, 'Competitions');
                          }}
                          // disabled={isSaasType}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="incentivecampaign"
                        >
                          <p className="cw-heading--tenary">
                            {' '}
                            Incentivized sign up | Competitions
                          </p>
                          {(this.props.incentiveType &&
                            this.props.incentiveType.toLowerCase() !=
                              'competitions') ||
                          this.props.incentiveType === '' ? (
                            <p className="">
                              {constant.CAMPAIGN_OPTION_MESSAGE.competitionsNew}
                            </p>
                          ) : null}
                        </label>
                      </div>
                      <div
                        className={`ml-33 pt-0 cw-promotigosubCategory ${
                          this.props.incentiveType &&
                          this.props.incentiveType.toLowerCase() ===
                            'competitions'
                            ? ''
                            : 'cw-hidden'
                        }`}
                      >
                        <p className="">
                          {constant.CAMPAIGN_OPTION_MESSAGE.competitionsNew}
                        </p>
                        <p className="">
                          {constant.CAMPAIGN_OPTION_MESSAGE.competitions}
                        </p>
                        {qnaToggle('formType')}
                        {platFormAgnosticComponent(
                          'platformAgnosticFormsCompitition'
                        )}
                        <br />
                        {aemComponent('isAEMFormCompitition')}
                        {!isEpsilonMarket
                          ? masterDataComponent('isMasterDataCompitition')
                          : null}
                      </div>
                    </div>

                    <div className="cw-form--legend mb-15">
                      <div className="form-check">
                        <input
                          type="radio"
                          className="form-check-input"
                          id="incentivecampaignSample"
                          name="campaignType"
                          value={'Incentive'}
                          checked={
                            this.props.incentiveType &&
                            this.props.incentiveType.toLowerCase() ===
                              'product samples'
                          }
                          onChange={e => {
                            this._handleOnChange(e, 'Product samples');
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="incentivecampaignSample"
                        >
                          <p className="cw-heading--tenary">
                            Incentivized sign up | Samples
                          </p>
                          {(this.props.incentiveType &&
                            this.props.incentiveType.toLowerCase() !=
                              'product samples') ||
                          this.props.incentiveType === '' ? (
                            <p className="">
                              {constant.CAMPAIGN_OPTION_MESSAGE.sampleNew}
                            </p>
                          ) : null}
                        </label>
                      </div>
                      <div
                        className={`ml-33 pt-0 cw-promotigosubCategory ${
                          this.props.incentiveType &&
                          this.props.incentiveType.toLowerCase() ===
                            'product samples'
                            ? ''
                            : 'cw-hidden'
                        }`}
                      >
                        <p className="">
                          {constant.CAMPAIGN_OPTION_MESSAGE.sampleNew}
                        </p>
                        <p className="">
                          {constant.CAMPAIGN_OPTION_MESSAGE.samples}
                        </p>
                        {qnaToggle('formType')}
                        {platFormAgnosticComponent(
                          'platformAgnosticFormsSample'
                        )}
                        <br />
                        {aemComponent('isAEMFormSample')}
                        {!isEpsilonMarket
                          ? masterDataComponent('isMasterDataSample')
                          : null}
                      </div>
                    </div>
                  </>
                ) : null}

                <span
                  className="mb-10 cw-error cw-hidden"
                  data-control="campaignType"
                >
                  <i className="fas fa-exclamation-triangle mr-10"></i>
                  Please select a campaign type.
                </span>
                <Modal
                  scrollable={true}
                  isOpen={this.state.isNewModalOpen}
                  onRequestClose={this._closeNewModal}
                  className="cw-modal cw-modal--branddetail_new_overlay cw-modal--scrollbar_know_more_message "
                  contentLabel="Campaign Wizard 2.0"
                  style={{
                    overlay: {
                      backgroundColor: constant.EMAIL_OVERLAY_BACKGROUND
                    }
                  }}
                >
                  {/* <BrandDetailsMessage closeNewModal={this._closeNewModal} /> */}
                  <BrandDetailsKnowMore
                    closeIsEditModal={this._closeNewModal}
                  />
                </Modal>
                <Modal
                  scrollable={true}
                  isOpen={this.state.requestNewModelOpen}
                  onRequestClose={this._openRequestNewModelClose}
                  className="cw-modal cw-modal--branddetail_new_overlay master-dialog"
                  contentLabel="Campaign Wizard 2.0"
                  style={{
                    overlay: {
                      backgroundColor: constant.EMAIL_OVERLAY_BACKGROUND
                    }
                  }}
                >
                  <MasterDataGuideline
                    title={'Understanding your data collection options'}
                    data={constant.CAMPAIGN_TYPE_GUIDE_LINES}
                    qnaIndex={true}
                    closeIsEditModal={this._openRequestNewModelClose}
                    noLineBreak={true}
                  />
                </Modal>
                <Modal
                  scrollable={true}
                  isOpen={this.state.masterDataModelOpen}
                  onRequestClose={this._closeMasterDataModel}
                  className="cw-modal cw-modal--branddetail_new_overlay master-dialog"
                  contentLabel="Campaign Wizard 2.0"
                  style={{
                    overlay: {
                      backgroundColor: constant.EMAIL_OVERLAY_BACKGROUND
                    }
                  }}
                >
                  <MasterDataGuideline
                    title={'Requesting master data'}
                    data={constant.MASTER_DATA_GUIDE_LINES}
                    qnaIndex={true}
                    closeIsEditModal={this._closeMasterDataModel}
                    noLineBreak={true}
                    campaignConfig={campaignConfig}
                  />
                </Modal>
              </div>

              <div className="">
                <button
                  type="button"
                  className="btn btn btn-outline-secondary mr-3"
                  onClick={this._handleBackButton}
                >
                  Back
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={this._handleNextButton}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
          <div className="col-sm-4 choose-request-type-new">
            <Alert
              alertType="saasUpdateKnownmore"
              textMessage={constant.SAAS_MESSAGES.newFormtypeMessage}
              className={true}
              // openModal={this._openNewModal}
              isKnowMore={true}
              modelText={'Know more'}
            />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  type: state.campaignType.type,
  formType: state.campaignType.formType,
  campaignType: state.campaignType.campaignType,
  incentiveType: state.campaignType.incentiveType,
  isPlatformAgnostic: state.campaignType.isPlatformAgnostic,
  autogenerate: state.campaignType.autogenerate,
  campaignTypeUpdated: state.campaignType.campaignTypeUpdated,
  campaignTypeloaded: state.campaignType.campaignTypeloaded,
  simplesignup: state.campaignType.simplesignup,
  signupqna: state.campaignType.signupqna,
  brand: state.campaignType.brand,
  editCampaign: state.overview.editCampaign,
  editCampaignDetails: state.app.editCampaignDetails,
  isCampaignEdited: state.campaignType.isCampaignEdited,
  websiteType: state.campaignType.websiteType,
  campaign: state.campaignType.campaign,
  isMasterData: state.campaignType.isMasterData,
  campaignConfig: state.app.campaignConfig,
  incentivePromotionalPartner: state.campaignType.incentivePromotionalPartner,
  incentivePromotionalOtherPartner: state.campaignType.incentivePromotionalOtherPartner
});


export default connect(mapStateToProps, {
  resetProfileStore,
  updateCampaignType,
  getCampaignDetails,
  updateCampaignTypeDetails,
  editedCampaignDetails,
  fetchEditedCampaignLogs,
  saveEditedCampaignLogs,
  getBrandDetails,
  resetFieldsOnLanguageChange
})(CampaignType);
