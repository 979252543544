import {
  EMAIL_TYPE_ASYNC_IN_PROGRESS,
  UPDATE_EMAIL_TYPE,
  RESET_EMAIL_TYPE_PAGE,
  SET_EMAIL_EMAIL_TYPE
} from '../../actionTypes';

const initialState = {
  isLoading: false,
  emailType: null,
  uid: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case EMAIL_TYPE_ASYNC_IN_PROGRESS: {
      //let asyncInProgress = { asyncInProgress: action.isLoading };
      return { ...state, isLoading: action.isLoading };
    }
    case SET_EMAIL_EMAIL_TYPE: {
      let emailType = action.emailType;
      return { ...state, ...emailType };
    }

    case RESET_EMAIL_TYPE_PAGE:
      return initialState;

    default:
      return state;
  }
}
