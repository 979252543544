import React, { useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Controller } from 'react-hook-form';

import { FieldError } from '../components/atoms/FieldError';

import constant from 'constants/config';

import './custom-css/richTextEditor.css';

function RichTextEditor(props) {
  const editorRef = useRef(null);
  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };
  const defaultData =
    props && props.defaultValue ? props.defaultValue : '<p></p>';
  const placeholder = props && props.placeholder ? props.placeholder : null;
  const height = props && props.height ? props.height : 100;

  const { value, disabled } = props;
  return (
    <>
      <Editor
        className="tox tox-statusbar"
        onChange={(evt, editor) => props.parentCallback(editor)}
        onEditorChange={(evt, editor) => props.parentCallback(editor)}
        onBeforeAddUndo={(evt, editor) =>
          props && props.handleBeforeAddUndo
            ? props.handleBeforeAddUndo(evt, editor)
            : null
        }
        value={value}
        disabled={disabled}
        apiKey={constant.TinyMCEapiKey}
        initialValue={defaultData}
        init={{
          placeholder: placeholder,
          height: height,
          menubar: false,
          plugins: ['link  anchor'],
          toolbar: false,
          branding: false,
          content_style:
            `body { font-family:Helvetica,Arial,sans-serif; font-size:14px; background: ${disabled ? '#e9ecef' : 'transparent'} }`
        }}
      />
    </>
  );
}

const RichTextFormWrapper = ({
  name,
  control,
  defaultValue,
  placeholder,
  id,
  label,
  errors,
  ...props
}) => {
  const handleChange = (richTextData, onChange) => {
    let defaultCopy = richTextData.getContent();
    onChange(defaultCopy);
  };

  return (
    <div className="form-group">
      <label htmlFor={id}>{label}</label>
      <Controller
        name={`${name}`}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <EmailRichTextEditor
            height="200"
            placeholder={placeholder}
            name={field.name}
            value={field.value}
            id={id}
            parentCallback={textData => {
              handleChange(textData, field.onChange);
            }}
            defaultValue={defaultValue}
            plugins={props.plugins}
            toolbar={props.toolbar}
            
          />
        )}
      />
      <FieldError errors={errors} name={`${name}`} />
    </div>
  );
};

const EmailRichTextEditor = ({
  defaultValue,
  placeholder,
  height,
  toolbar, 
  plugins,
  disabled,
  parentCallback
}) => {
  const defaultData = defaultValue ? defaultValue : '<p></p>';
  const defaultPlugins = 'link anchor lists emoticons paste'

  const pluginsList = plugins ? defaultPlugins.concat(plugins) : defaultPlugins;
  const toolbarItems = toolbar || false;

  return (
    <Editor
      className="tox tox-statusbar"
      onChange={(evt, editor) => parentCallback(editor)}
      onEditorChange={(evt, editor) => parentCallback(editor)}
      disabled={disabled}
      apiKey={constant.TinyMCEapiKey}
      initialValue={defaultData}
      init={{
        placeholder: placeholder,
        height: height || 100,
        menubar: false,
        plugins: pluginsList,
        toolbar: toolbarItems,
        contextmenu: 'link undo redo',
        branding: false,
        paste_as_text: true,
        content_style:
          'body { font-family: "Open Sans", sans-serif; font-size: 14px }',
        fontsize_formats: "8px 10px 12px 14px 16px 18px 20px 24px 36px"
      }}
    />
  );
};

export default RichTextEditor;
export { RichTextFormWrapper };
