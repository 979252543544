import React, { useState, useRef } from 'react';
import { useOnClickOutside } from '../../../hooks/useOnClickOutside';
import OverflowMenuIcon from 'images/overflow-menu-icon.png';

export default function OverflowMenu({ options }) {
  const ref = useRef();
  const [isOpen, setIsOpen] = useState(false);

  useOnClickOutside(ref, () => setIsOpen(false));

  return (
    <div ref={ref} className="overflow-menu">
      <button className="inline-button" onClick={() => setIsOpen(true)}>
        <img src={OverflowMenuIcon} alt="Open Menu" />
      </button>

      {isOpen ? (
        <div className="overflow-menu__list">
          {options.map(option => (
            <OverflowMenuItem key={option.label} option={option} />
          ))}
        </div>
      ) : null}
    </div>
  );
}

function OverflowMenuItem({ option }) {
  const { icon, label, onClick } = option;
  return (
    <button
      className="overflow-menu__list__item inline-button"
      onClick={onClick}
    >
      <i className={`${icon} mr-10`} />
      {label}
    </button>
  );
}
