import React from 'react';

export default function EmailBriefingListHeader({ sortDashboard, sortClass }) {
  return (
    <thead>
      <tr>
        <th scope="col">
          <span className="">Type</span>
        </th>
        <th scope="col" data-type="name" onClick={sortDashboard}>
          <span className="">Campaign Name</span>
        </th>
        <th scope="col">
          <span className="">Brand</span>
        </th>
        <th scope="col">
          <span
            className={sortClass}
            data-type="lastUpdatedDate"
            onClick={sortDashboard}
          >
            Updated
          </span>
        </th>
        <th scope="col">
          <span className="">Status</span>
        </th>
        <th scope="col">
          <span className="">Preview</span>
        </th>
      </tr>
    </thead>
  );
}
