import React from 'react';
import { checkForNullOrUndefined } from '../../../selectors';

const FaqStats = ({ questions }) => {
  let sortedQuestions = questions.sort(
    (a, b) => (b.count || 0) - (a.count || 0)
  );
  return (
    <div className="faq-stats-list">
      <ul>
        {sortedQuestions.map((elem, index) => {
          return (
            <div key={index} className="qna-list">
              <li
                className="mb-1 d-flex align-items-center justify-content-between"
                style={{ listStyle: 'none', fontSize: 14 }}
              >
                <div>{elem.text}</div>{' '}
                <div className="pr-30 text-nowrap">
                  {checkForNullOrUndefined(elem.count, 50)} &#40;
                  {checkForNullOrUndefined(elem.percentage, 20)}&#37;&#41;
                </div>
              </li>
              <div className="faq-stats-line faq-stats-line--li mb-1"></div>
            </div>
          );
        })}
      </ul>
    </div>
  );
};

export default FaqStats;
