import React from 'react';
import EmailTabular from '../EmailTabular/EmailTabular';
import { getTabularData } from 'pages/EmailReview/data';
import { withRouter } from 'react-router';
import { getEmailBriefingbyUid } from 'services/emailBriefings'
import EmailRequestInfoSubmitComp from '../EmailRequestInfoSubmit/EmailRequestInfoSubmit';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

function EmailOverviewTables({
  emailProps,
  editable,
  review,
  errors = {},
  match,
  history,
  handleTemplatePreview,
  isDesignPage,
  handleRequestInfoAnswer,
  requestInfoAnswerFlag
}) {
  const [parentCampaign, setParentCampaign] = React.useState()

  const parentCampaignUid = emailProps?.emailBriefing?.emailTemplateEditId?.parentCampaignUid

  React.useEffect(() => {
    const getParentCampaign = async() => {
      const res = await getEmailBriefingbyUid(parentCampaignUid)
      setParentCampaign(res.data)
    }

    if(parentCampaignUid) {
      getParentCampaign()
    }
  }, [parentCampaignUid]);
  
  const parentData = {
    name: parentCampaign?.summary?.campaignName,
    brand: Array.isArray(parentCampaign?.brand)
      ? parentCampaign?.isCrossBrand
        ? `cross brand email for ${parentCampaign?.brand.map(b => (b ? b.name : '')).join(', ')}`
        : parentCampaign?.brand[0]?.name
      : parentCampaign?.brand?.name,
    country: parentCampaign?.country?.name
  }
  
  const { authorizedUserData } = useSelector(state => state.authorized);

  const currentUser = {
    email: get(authorizedUserData, 'emailId', ''),
    group: get(authorizedUserData, 'group', '')
  }

  if (!emailProps.emailBriefing) return null;

  const {
    brandSummary,
    campaignType,
    campaignSummary,
    campaignAttributes,
    deliveryScheduleData,
    audienceData,
    emailProperties,
    emailPropertiesSections,
    emailDesignReferences,
    emailABTesting,
    emailCollaboratorsData
  } = getTabularData(emailProps, review, currentUser, parentData);

  const onClickEdit = page => {
    const uid = match.params.id;
    history.push({
      pathname: `/campaign-wizard/emailbriefing/${uid}/${page}`,
      state: {
        flyoutOpen: true,
        isEdited: review
      }
    });
  };

  const editProps = redirectTo =>
    editable ? { onClickEdit: () => onClickEdit(redirectTo) } : {};

  return (
    <div>
      {review ? (
        <>
          <EmailTabular
            data={brandSummary}
            title="Brand summary"
            {...editProps('branddetails')}
          />
          <EmailTabular
            data={campaignType}
            title="Campaign type"
            {...editProps('emailtype')}
          />
        </>
      ) : null}
      <EmailTabular
        data={campaignSummary}
        title={'Campaign summary'}
        isError={errors.summary}
        {...editProps('email-summary')}
      />
      <EmailTabular
        data={campaignAttributes}
        title={'Campaign attributes'}
        isError={errors.campaignAttributes}
        errorDetails={errors.dynamicCouponError}
        {...editProps('email-attributes')}
      />
      <EmailTabular
        data={deliveryScheduleData}
        isError={errors.deliverySchedule}
        title={'Delivery schedule'}
        errorDetails={errors.deliveryScheduleError}
        {...editProps('email-schedule')}
      />
      <EmailTabular
        data={audienceData}
        isError={errors.audienceDefinition}
        title={'Audience segment'}
        {...editProps('email-audience')}
      />
      <EmailTabular
        data={emailProperties}
        isError={errors.emailProperties}
        title={'Email Properties'}
        {...editProps('email-properties')}
        sections={emailPropertiesSections}
        isDesignPage={isDesignPage}
      />
      {emailProps && emailProps.emailBriefing && emailProps.emailBriefing.requestInfoText  && requestInfoAnswerFlag? (
        <EmailRequestInfoSubmitComp
          emailBriefing={emailProps && emailProps.emailBriefing}
          title={'Response'}
          isError={errors.requestAnswererror}
          handleRequestInfoAnswer={handleRequestInfoAnswer}
        />
      ) : null}

      {emailProps.emailBriefing?.emailTemplateEditId ? (
        <EmailTabular
          data={emailDesignReferences(handleTemplatePreview)}
          title={'Email design references'}
        />
      ) : null}
    </div>
  );
}

export default withRouter(EmailOverviewTables);
