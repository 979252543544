import React from 'react';
import Select, { components } from 'react-select';
import beeDefaultFonts from './beeDefaultFonts';
import './FontSelector.scss';

const defaultFonts = beeDefaultFonts.map(font => ({
  ...font,
  label: font.name
}));

const Option = props => {
  return (
    <div style={{ fontFamily: props.data.fontFamily }}>
      <components.Option {...props} />
    </div>
  );
};

export default function FontSelector({
  name = 'Title',
  sampleText = 'Sample text',
  values,
  property,
  value,
  type,
  onChange,
  description,
  placeholder = '',
  label
}) {
  const handleChange = value => {
    onChange(property, value);
  };

  return (
    <div className="font-selector">
      {/* <div className="name">{name}</div> */}
      {label ? <div className="label">{label}</div> : null}
      {description ? (
        <div className="description mb-20">{description}</div>
      ) : null}
      <div className="d-flex align-items-center">
        <div style={{ width: 300 }}>
          <Select
            className="react-select-container"
            classNamePrefix="react-select"
            components={{ Option }}
            options={defaultFonts}
            name={'qtype'}
            label={label}
            placeholder={placeholder}
            onChange={handleChange}
            value={value}
            getOptionValue={(item) => item.name}
          />
        </div>
        {values ? (
          type === 'button' ? (
            <button
              className="ml-20"
              disabled
              style={{
                boxShadow: 'none',
                border: 'none',
                backgroundColor:
                  values.backgroundColor || 'var(--color-primary)',
                color: values.color || 'white',
                fontFamily: value && value.fontFamily,
                fontSize: values.fontSize,
                fontWeight: values.fontWeight,
                borderRadius: values.borderRadius,
                padding: '5px 20px',
                minHeight: 38
              }}
            >
              {sampleText}
            </button>
          ) : (
            <div
              className="ml-20"
              style={{
                fontFamily: value && value.fontFamily,
                color: values.color,
                fontSize: values.fontSize || 14,
                fontWeight: values.fontWeight || 'normal'
              }}
            >
              {sampleText}
            </div>
          )
        ) : null}
      </div>
    </div>
  );
}
