import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormBuilder } from '../../components/organisms/FormBuilder';
import Loader from '../../components/Loader';
import BreadCrumb from '../../components/BreadCrumb';
import formSchema from './emailCampaignSummarySchema';
import { getEmailType } from 'actions/emailbriefing/emailType';
import ProgressBar from 'components/molecules/ProgressBar';
import {
  getEmailCampaignDetails,
  updateEmailCampaignDetails
} from 'actions/emailbriefing/emailCampaignDetails';
import { Redirect } from 'react-router-dom';
import { CustomButtons } from 'pages/EmailCampaignAudience';
import constants from 'constants/config';

const EmailCampaignSummary = props => {
  const [isEdit, setEdit] = useState(false);
  const { isLoading, uid, summary = {}, emailCollaborators } = useSelector(
    state => state.emailCampaignDetails
  );
  const { authorizedUserData } = useSelector(state => state.authorized);
  const { emailType } = useSelector(state => state.emailType);
  const { country, brand, language, isCrossBrand } = useSelector(
    state => state.emailBrandDetails
  );
  const emailDesign = useSelector(state => state.emailDesign);

  const dispatch = useDispatch();
  const pathUid = props.match.params.id;

  useEffect(() => {
    if (pathUid) {
      setEdit(true);
      // fetch from email details from the server
      // dispatch(getEmailType(pathUid))
      dispatch(getEmailCampaignDetails(pathUid));
    }
  }, []);

  if (!pathUid && (!country || !brand || !language)) {
    return <Redirect to="new-email-campaign" />;
  }
  const compProps = {
    emailType,
    country,
    brand,
    language,
    emailDesign,
    history: props.history,
    match: props.match,
    defaultValues: uid !== pathUid ? {} : {...summary, emailCollaborators} || {},
    isCrossBrand
  };
  return (
    <div className="col-sm-12 skypink">
      <div className="cw-section">
        {/* <BreadCrumb match={props.match} emailBriefing /> */}
        <ProgressBar customClass={'cw-email-progress-custom'} pageNo={constants.EMAIL_JOURNEY_PAGE_NUMBERS.CAMPAIGN_SUMMARY} />
        <div className="cw-section--title mb-30">
          <h2 className="cw-heading--secondary cw-email-heading">
            Campaign summary
          </h2>
        </div>
        <div className="row">
          {isLoading ? <Loader /> : null}
          <div className="col-md-8">
            <FormBuilder
              schema={formSchema(dispatch, compProps)}
              user={authorizedUserData}
              isEdit={isEdit}
              customButtonsComp={CustomButtons}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailCampaignSummary;
