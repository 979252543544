import React, { Component, Fragment } from 'react';
import _ from 'lodash';

import { textCapitalization, isCampaignEdited } from '../selectors';

export default class Optins extends Component {
  render() {
    let {
      optins,
      draftFlag,
      index,
      disabledFlag,
      isRegular,
      isEdited,
      showFrequency
    } = this.props;
    let OptinTextUpdated = false;
    OptinTextUpdated =
      isEdited &&
      isCampaignEdited(
        'additionalOptinList',
        this.props.campaignEditLog,
        index,
        'text',
        optins.text
      );
    let optinTextClass = this.props.changeAllOptinsColor
      ? 'campaign-edited'
      : OptinTextUpdated
      ? 'campaign-edited'
      : 'striped-row';
    let OptinFrequencyUpdated = false;
    OptinFrequencyUpdated =
      isEdited &&
      isCampaignEdited(
        'additionalOptinList',
        this.props.campaignEditLog,
        index,
        'frequency',
        optins.frequency
      );
    let optinFrequencyClass = this.props.changeAllOptinsColor
      ? 'campaign-edited'
      : OptinFrequencyUpdated
      ? 'campaign-edited'
      : 'striped-row';
    return (
      <>
        <div
          className={`cw-striped-profile-row ${
            optins.text && _.isEmpty(optins.text) && draftFlag ? 'd-none' : ''
          }`}
        >
          <div className="row">
            <div
              className={
                this.props.colLabelClass ? this.props.colLabelClass : 'col-sm-3'
              }
            >
              <strong>
                {(this.props.regularCommunicationLabel
                  ? this.props.regularCommunicationLabel
                  : 'Name of regular communication') +
                  ' (' +
                  (index + 1) +
                  ')'}
              </strong>
            </div>
            <div
              className={
                this.props.colValClass ? this.props.colValClass : 'col-sm-5'
              }
            >
              <p>{optins.text}</p>
            </div>

            {!isRegular ? (
              <div className="col-sm-4">
                {this.props.hideInputFields === 'true' ? (
                  optins.id ? (
                    optins.id
                  ) : (
                    'Awaiting'
                  )
                ) : (
                  <input
                    type="text"
                    className="form-control"
                    disabled={disabledFlag}
                    name="brandoptin"
                    id="brandoptin"
                    value={optins.id ? optins.id : ''}
                    readOnly={true}
                  />
                )}
              </div>
            ) : null}
          </div>
        </div>
        {showFrequency ? (
          <div
            className={`cw-striped-profile-row ${
              optins.frequency && _.isEmpty(optins.frequency) && draftFlag
                ? 'd-none'
                : ''
            }`}
          >
            <div className="row">
              <div
                className={
                  this.props.colLabelClass
                    ? this.props.colLabelClass
                    : 'col-sm-3'
                }
              >
                <strong>
                  {'Frequency of regular communication (' + (index + 1) + ')'}
                </strong>
              </div>
              <div
                className={`text-capitalize ${
                  this.props.colValClass ? this.props.colValClass : 'col-sm-5'
                }`}
              >
                <p>
                  {optins.frequency ? textCapitalization(optins.frequency) : ''}
                </p>
              </div>
              <div className="col-sm-4"></div>
            </div>
          </div>
        ) : null}
      </>
    );
  }
}
