import React, { useState, useEffect } from 'react';
import 'semantic-ui-css/components/accordion.min.css';
import Timeline from 'components/organisms/Timeline/Timeline';
import BreadCrumb from '../../components/BreadCrumb';
import ReleaseUpdateOverlay from './ReleaseUpdateOverlay';
import { useDispatch, useSelector } from 'react-redux';
import { deleteReleaseNoteData } from 'actions/user-management';
import axios from 'axios';
import constant from '../../constants';
import moment from 'moment';
import { get } from 'lodash';

const ReleaseNoteCommunication = props => {
  const [state, setState] = useState({ release: [] });
  const [selectedReleaseNote, setSelectedReleaseNote] = useState({});
  const [isReleaseUpdateOverlayOpen, setIsReleaseUpdateOverlayOpen] = useState(false);
  const [rerender, setRerender] = useState(true)
  const { authorizedUserData } = useSelector(state => state.authorized);
  const dispatch = useDispatch();
  useEffect(() => {
    async function fetchData() {
      const response = await axios
        .get(constant.serviceUrls.RELEASE_NOTE_URL)
        .then(function(response) {
          let retVal = reOrderResponse(response.data);
          setState({ release: retVal });
          return response;
        })
        .catch(function(error, req) {
          console.log(error);
        });
    }
    fetchData();
  }, []);

  useEffect(() => {
    if(isReleaseUpdateOverlayOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.removeProperty('overflow')
    }
  }, [isReleaseUpdateOverlayOpen])

const refetchData = async () => {
    const result = await axios
      .get(constant.serviceUrls.RELEASE_NOTE_URL)
      .then(function(response) {
        let retVal = reOrderResponse(response.data);
        setState({ release: retVal });
        return response;
      })
      .catch(function(error, req) {
        console.log(error);
      });
    return result
}
  /**
   *
   * @param {*} releaseData
   * @returns Array of data rearranged in desc order with an empty placeholder for quarter
   */
  function reOrderResponse(releaseData) {
    releaseData.forEach((years, i) => {
      let quarters = [
        [{ noData: true, quarter: '4' }],
        [{ noData: true, quarter: '3' }],
        [{ noData: true, quarter: '2' }],
        [{ noData: true, quarter: '1' }]
      ];
      years.quarters.forEach((quarter, indx) => {
        let sortedQuarter = quarter.sort(
          (a, b) =>
            new moment(b.releaseDate).format('YYYYMMDD') -
            new moment(a.releaseDate).format('YYYYMMDD')
        );
        quarters[4 - quarter[0].quarter] = sortedQuarter;
      });
      years.quarters = quarters;
    });

    const filteredData = releaseData.map(yearData => {
      const { quarters, year } = yearData

      if(year >= moment().year()) {
        return {year, quarters: quarters.filter(quarter => {
          return !quarter[0].noData
        })}
      } else {
        return yearData
      }
    })
    return filteredData;
  }

  const handleEdit = (givenNote) => {
    if(givenNote){
      setIsReleaseUpdateOverlayOpen(true)
      setSelectedReleaseNote(givenNote)
    }
  }
  const handleOnClose = (refetch) => {
    setIsReleaseUpdateOverlayOpen(false)
    if(refetch){
      refetchData()
    }
  }

  const handleEditClose = (refetch) => {
      setIsReleaseUpdateOverlayOpen(false)
      setSelectedReleaseNote({})
      setRerender(false)
      if(refetch){
        refetchData()
      }
  }

  const handleDelete = (givenNote) => {
    if(givenNote){
      dispatch(deleteReleaseNoteData(givenNote._id)).then(response => {
        if (response.status === 200) {
          refetchData()
        } else {

        }
      })
    }
  }

  const isAdminUser = get(authorizedUserData, 'group') === 'admin'
  return (
    <div className="cw-section cw-overview cw-releasenote-wrapper ml-20">
      <div className="col-sm-12">
        <BreadCrumb match={props.match} hideBreadCrumb={false} />
      </div>
      <div className="col-sm-12">
        <div className="d-flex justify-content-end">
        {isAdminUser && 
          <button type="button" className='btn btn-primary' onClick={() => setIsReleaseUpdateOverlayOpen(true)}>Add release updates</button>
        }
        </div>
      </div>
      <div className="col-sm-12">
        <div>
          <h2 className="cw-heading--secondary d-flex align-items-center mb-10">
            <span className="mr-3">Campaign Wizard Updates</span>
          </h2>
          <p>
            Please visit this page to remain informed about all key updates, new
            features, announcements, and changes to Campaign Wizard.
          </p>
        </div>
        <div className="mt-20">
          <Timeline releaseData={state.release} handleEdit={handleEdit} handleDelete={handleDelete} isAdminUser={isAdminUser} rerender={rerender} />
        </div>
      </div>
      <ReleaseUpdateOverlay
        isOpen={isReleaseUpdateOverlayOpen}
        onCloseModal={handleOnClose}
        selectedReleaseNote={selectedReleaseNote}
        handleEditClose={handleEditClose}
      />
    </div>
  );
};
export default ReleaseNoteCommunication;
