import * as yup from 'yup';
import constants from 'constants/config';
import { getYupSchema, SMSSubmitRedirectURL } from 'selectors';
import { SmsAttributes } from 'components/organisms/SmsAttributes';
import { submitSmsCampaignDetailsV2 } from 'actions/communications/communicationCampaignDetails.js';

const smsAttributesSchema = (
  dispatch,
  { defaultValues, match, history, emailType, toggleShowDynamicCouponAlert }
) => {
  const isEdited =
    history &&
    history.location &&
    history.location.state &&
    history.location.state.isEdited;

  const onSubmit = (data, { user }, { backToReview } = {}) => {
    const paramUid = match.params.id;
    const {
      couponCode,
      kanaTicketNumber,
      smsCoupon,
      smsCouponType,
      knowsAudienceSize,
      couponFileUpload,
      shareCouponFileViaKana
    } = data.smsAttributes;

    const dataToSend = {
      ...data,
      userEmail: user.emailId,
      userName: user.name,
      uid: paramUid
    };

    const isDynamicCoupon = smsCoupon && smsCouponType === 'dynamicCoupon';
    const smsDetails = defaultValues?.communicationDetail?.smsDetails

    // if dynamic coupon placeholder is added in design and then dynamic coupon is removed, remove the placeholder from content
    if(smsDetails) {
      const content = smsDetails.map(item => ({
        ...item,
        smsContent: !isDynamicCoupon ? item?.smsContent?.replace(/\[Coupon\]/g, '').trim() : item.smsContent
      }))
      dataToSend.smsDetails = content
    }

    dataToSend.smsAttributes = smsCoupon
    ? {
        smsCoupon: {
            couponApplied: smsCoupon,
            type: smsCouponType,
            code: smsCouponType === 'staticCoupon' ? couponCode : '',
            kanaTicketNumber:
            smsCouponType === 'dynamicCoupon' ? kanaTicketNumber : '',
            knowsAudienceSize: smsCouponType === 'dynamicCoupon' ? knowsAudienceSize : '',
            couponFileUpload: smsCouponType === 'dynamicCoupon' ? couponFileUpload : '',
            shareCouponFileViaKana: smsCouponType === 'dynamicCoupon' ? shareCouponFileViaKana : false,
        }
    }
    : {};
    console.log('dataToSend.smsAttributes: ', dataToSend.smsAttributes);
    
    dispatch(submitSmsCampaignDetailsV2(dataToSend, 
        (error, result) => {
            if (error) {
            console.log('OnSubmit error: ', error);
            } else {
              history.push(
                SMSSubmitRedirectURL({
                  backToReview: isEdited && backToReview,
                  status: defaultValues.status,
                  uid: paramUid,
                  nextPage: `${constants.SMS_ROUTES.SCHEDULE}`
                })
              );
            }
    }))
  };

  return {
    formType: 'custombuttons',
    submitBtnLabel: isEdited ? 'Save and back to review' : 'Next',
    onSecondaryClick: onSubmit,
    onSubmitSecondary: () => {},
    isEdit: isEdited,
    secondaryBtnLabel: 'Next',
    fields: [
      {
        type: 'custom',
        name: 'smsAttributes',
        id: 'smsAttributes',
        label: 'Coupons',
        component: SmsAttributes,
        defaultValue: defaultValues,
        rules: yup.object({
          smsCoupon: yup.bool(),
          smsCouponType: yup
            .string()
            .when('smsCoupon', {
              is: data => !!data,
              then: schema => schema.required('Please select coupon type')
            })
            .nullable(),
          couponCode: yup
            .string()
            .when(
              ['smsCouponType', 'smsCoupon'],
              (type, applied, schema) => {
                if (applied && type === 'staticCoupon') {
                  return schema
                    .required('Please provide a coupon code')
                    .max(50, 'Coupon code cannot be longer than 50 characters');
                }
                return schema;
              }
            )
            .nullable(),
          kanaTicketNumber: yup
            .string()
            .when(
              ['smsCouponType', 'smsCoupon', 'shareCouponFileViaKana'],
              (type, applied, isShareViaKana, schema) => {
                if (applied && type === 'dynamicCoupon' && isShareViaKana) {
                  return schema
                    .matches(
                      /^$|^CS\d{7}$/,
                      "Please enter a valid Kana ticket in format 'CS1234567'"
                    )
                    .nullable()
                    .max(
                      9,
                      "Please enter a valid Kana ticket in format 'CS1234567'"
                    );
                }
                return schema;
              }
            )
            .nullable()
        }),
        toggleShowDynamicCouponAlert
      }
    ],
    onSubmit: (data, { user }) =>
      onSubmit(data, { user }, { backToReview: isEdited }),
    onDraft: (data, validationSchema, { user }) => {
      const paramUid = match.params.id;
      const {
        couponCode,
        kanaTicketNumber,
        smsCoupon,
        smsCouponType,
        knowsAudienceSize,
        couponFileUpload,
        shareCouponFileViaKana
      } = data.smsAttributes;

      const dataToSend = {
        userEmail: user.emailId,
        userName: user.name,
        ...data,
        status: '1',
        uid: paramUid
      };

      const isDynamicCoupon = smsCoupon && smsCouponType === 'dynamicCoupon';
      const smsDetails = defaultValues?.communicationDetail?.smsDetails

      // if dynamic coupon placeholder is added in design and then dynamic coupon is removed, remove the placeholder from content
      if(smsDetails) {
        const content = smsDetails.map(item => ({
          ...item,
          smsContent: !isDynamicCoupon ? item?.smsContent?.replace(/\[Coupon\]/g, '').trim() : item.smsContent
        }))
        dataToSend.smsDetails = content
      }

      dataToSend.smsAttributes = smsCoupon
      ? {
          smsCoupon: {
              couponApplied: smsCoupon,
              type: smsCouponType,
              code: smsCouponType === 'staticCoupon' ? couponCode : '',
              kanaTicketNumber:
              smsCouponType === 'dynamicCoupon' ? kanaTicketNumber : '',
              knowsAudienceSize: smsCouponType === 'dynamicCoupon' ? knowsAudienceSize : '',
              couponFileUpload: smsCouponType === 'dynamicCoupon' ? couponFileUpload : '',
              shareCouponFileViaKana: smsCouponType === 'dynamicCoupon' ? shareCouponFileViaKana : false,
            }
        }
        : {};
    console.log('dataToSend: ', dataToSend);

    dispatch(submitSmsCampaignDetailsV2(dataToSend, 
        (error, result) => {
            if (error) {
            console.log('OnSubmit error: ', error);
            } else {
                history.push(`/campaign-wizard/smsdashboard`);
            }
    }, { isSaveAsDraft: true }))
      
    },
    getSchema() {
      if (this.fields) {
        return getYupSchema(this.fields);
      }
      return null;
    },
    onPrevious: () => {
      const uid = match.params.id;
      history.push(`${constants.SMS_BASE_ROUTE}/${uid}/${constants.SMS_ROUTES.CAMPAIGN_SUMMARY}`);
    },
    previousBtnLabel: 'Back',
    draftText: 'Save and exit',
    formErrorMessage:
      'There was a problem with the form. Errors are listed below'
  };
};

export default smsAttributesSchema