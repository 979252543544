import React from 'react';

const EmailGuidelineText = ({ isOpenGuidelineModel,titleText,guideLineName }) => (
  <>
        {titleText}
        <a
          href="#"
          onClick={isOpenGuidelineModel}
          style={{ fontWeight: 'bold' }}
        >
          {guideLineName}
        </a>{' '}
  </>
);

export default EmailGuidelineText;
