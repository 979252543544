import React from 'react';
import ProgressiveImage from '../ProgressiveImage';
const ratingIconSize = 80;
const SatisfactoryRatingsInput = ({
  name,
  onChange,
  value,
  onBlur,
  idPrefix = ''
}) => {
  return (
    <div className="satisfactory_rating_wrapper">
      <div className="d-flex">
        <div className="rating-input">
          <label htmlFor="1">
            <input
              type="radio"
              name={name}
              id="1"
              value="1"
              onChange={onChange}
              checked={value === '1'}
              onBlur={onBlur}
            />
            <ProgressiveImage
              src="/campaign-wizard/frown_emoji.svg"
              alt="frown_emoji"
              height={ratingIconSize}
              width={ratingIconSize}
            />
          </label>
        </div>

        <div className="rating-input">
          <label htmlFor="2">
            <input
              type="radio"
              name={name}
              id="2"
              value="2"
              onChange={onChange}
              checked={value === '2'}
              onBlur={onBlur}
            />
            <ProgressiveImage
              src="/campaign-wizard/slight_frown_emoji.svg"
              alt="slight_frown_emoji"
              height={ratingIconSize}
              width={ratingIconSize}
            />
          </label>
        </div>

        <div className="rating-input">
          <label htmlFor="3">
            <input
              type="radio"
              name={name}
              id="3"
              value="3"
              onChange={onChange}
              checked={value === '3'}
              onBlur={onBlur}
            />
            <ProgressiveImage
              src="/campaign-wizard/neutral_face_emoji.svg"
              alt="neutral_face_emoji"
              height={ratingIconSize}
              width={ratingIconSize}
            />
          </label>
        </div>

        <div className="rating-input">
          <label htmlFor="4">
            <input
              type="radio"
              name={name}
              id="4"
              value="4"
              onChange={onChange}
              checked={value === '4'}
              onBlur={onBlur}
            />
            <ProgressiveImage
              src="/campaign-wizard/slight_smiling_emoji.svg"
              alt="slight_smiling_emoji"
              height={ratingIconSize}
              width={ratingIconSize}
            />
          </label>
        </div>

        <div className="rating-input">
          <label htmlFor="5">
            <input
              type="radio"
              name={name}
              id="5"
              value="5"
              onChange={onChange}
              checked={value === '5'}
              onBlur={onBlur}
            />
            <ProgressiveImage
              src="/campaign-wizard/smiling_face_emoji.svg"
              alt="smiling_face_emoji"
              height={ratingIconSize}
              width={ratingIconSize}
            />
          </label>
        </div>
      </div>
    </div>
  );
};

export default SatisfactoryRatingsInput;
