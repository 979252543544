import React from 'react';
import IncrementIcon from 'images/increment.svg';
import DecrementIcon from 'images/decrement.svg';

export default function Size({
  label = 'Text size',
  value = 14,
  onChange,
  className = ''
}) {
  const onIncrement = () => {
    onChange(value + 1);
  };
  const onDecrement = () => {
    onChange(value - 1);
  };

  return (
    <div className={`d-flex ${className}`}>
      <div className="d-flex  flex-column">
        <div className="size-buttons">
          <Button onClick={onDecrement} icon={DecrementIcon} alt="Decrement" />
          <div className="value">{value}</div>
          <Button onClick={onIncrement} icon={IncrementIcon} alt="Increment" />
        </div>
        <div className="brand-color-title mt-1">{label}</div>
      </div>
    </div>
  );
}

const Button = ({ icon, alt, onClick }) => {
  return (
    <div className="counter-button" onClick={onClick}>
      <img className="counter-button__image" src={icon} alt={alt} />
    </div>
  );
};
