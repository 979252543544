import React from 'react';
import Alert from './Alert';

const CampaignMasterDataEditModel = props => {
  const {campaign } = props
  return (
    <div>
      <button
        className="cw-modal--close"
        title="Close"
        onClick={props.closeIsEditModal}
      >
        <i className="fas fa-times"></i>
      </button>
      <h2 className="cw-heading--primary mb-10">
        <strong>Editing a master data request</strong>
      </h2>
      <div style={{ 'overflow-y': 'auto' }}>
        {props.data.map(ele => {
          return (
            <>
              <p className="mb-10">
                <div>
                  {ele.values.map(valEle => {
                    return <p>{valEle}</p>;
                  })}
                </div>
              </p>
            </>
          );
        })}
      </div>
      <br />
      <div className="mr-20">
        <button
          style={{ float: 'right' }}
          type="submit"
          onClick={props.closeIsEditModal}
          className="btn btn-outline-primary btn-custom-width mb-10 cw-print-hide mr-1"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default CampaignMasterDataEditModel;
