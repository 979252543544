import React from 'react';

import EmailTemplateList from 'components/organisms/EmailTemplateList';
import EmailTemplateSearch from 'components/organisms/EmailTemplateSearch';

export const TemplatesPanel = ({
  emailBriefing,
  templates,
  userinfo,
  userEmail,
  userName,
  visible,
  enteredText,
  isReset,
  page,
  total,
  limit,
  recordShowStart,
  recordShowto,
  onReset,
  handleTemplatePreview,
  onPageChange,
  setIsVisible,
  setEnteredText,
  setUserInfo,
  handleFilterChange,
  setIsReset,
  isEmailTemplate,
  resetFilterPills
}) => (
  <>
    <div style={{ flex: 3 }}>
      <EmailTemplateList
        emailCoreTemplates={templates}
        onReset={onReset}
        userEmail={userEmail}
        userName={userName}
        total={total}
        recordShowStart={recordShowStart}
        recordShowto={recordShowto}
        handleTemplatePreview={handleTemplatePreview}
        page={page}
        limit={limit}
        onPageChange={onPageChange}
        isEmailTemplate={isEmailTemplate}
        resetFilterPills={resetFilterPills}
      />
    </div>
    <div
      className="email-template-search-wrapper"
      style={
        visible
          ? { flex: 1, marginLeft: 20, position: 'relative' }
          : { flex: 0, position: 'relative' }
      }
    >
      <EmailTemplateSearch
        visible={visible}
        collapsible
        setIsVisible={setIsVisible}
        enteredText={enteredText}
        setEnteredText={setEnteredText}
        userinfo={userinfo}
        setUserInfo={setUserInfo}
        handleChange={handleFilterChange}
        emailCoreTemplates={templates}
        isReset={isReset}
        resetTrigger={() => {
          setIsReset(false);
        }}
        emailBriefing={emailBriefing}
        isEmailTemplate={isEmailTemplate}
        resetFilterPills={resetFilterPills}
      />
    </div>
  </>
);
