// Dashboard Reducer
import {
  GET_REVIEW_CAMPAIGN,
  POST_SUBMIT_REVIEW,
  ASYNC_IN_PROGRESS,
  SERVICE_ERROR_REVIEW,
  UDPATE_CAMPAIGN_DETAILS_REVIEW
} from '../actionTypes';

const initialState = {
  asyncInProgress: false,
  additionalConsentFlag: null,
  campaign: {},
  campaignDetails: {},
  questionAnswerList: [],
  serviceError: false,
  serviceErrorStatus: null,
  isSubmitted: false,
  isEdited: false,
  additionalInfo: null,
  editComments: null,
  campaignAccessEmails: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_REVIEW_CAMPAIGN: {
      let campaign = {
        campaign: action.campaign || {},
        campaignDetails: action.campaign.campaignDetail || {},
        additionalConsentFlag:
          action.campaign.campaignDetail &&
          action.campaign.campaignDetail.additionalConsentFlag,
        questionAnswerList:
          (action.campaign.campaignDetail &&
            action.campaign.campaignDetail.questionAnswerList) ||
          [],
        additionalInfo:
          (action.campaign.campaignDetail &&
            action.campaign.campaignDetail.additionalInfo) ||
          '',
        editComments:
          (action.campaign.campaignDetail &&
            action.campaign.campaignDetail.editComments) ||
          '',
        campaignAccessEmails:
          action.campaign.users || state.campaignAccessEmails,
        isMigrationRequest:
          (action.campaign &&
            action.campaign.campaignDetail &&
            (action.campaign.campaignDetail.isMigrationRequest == true ||
            action.campaign.campaignDetail.isMigrationRequest == false
              ? action.campaign.campaignDetail.isMigrationRequest
              : null)) ||
          null
      };
      return { ...state, ...campaign };
    }
    case ASYNC_IN_PROGRESS: {
      let asyncInProgress = { asyncInProgress: action.isLoading };
      return { ...state, ...asyncInProgress };
    }
    case SERVICE_ERROR_REVIEW: {
      let serviceError = {
        serviceError: true,
        serviceErrorStatus:
          (action.error.response && action.error.response.status) || 401
      };
      return { ...state, ...serviceError };
    }
    case UDPATE_CAMPAIGN_DETAILS_REVIEW: {
      let campaignDetails = action.campaignDetails;
      return { ...state, ...campaignDetails };
    }
    case POST_SUBMIT_REVIEW: {
      return {
        ...state,
        ...{ isSubmitted: action.isSubmitted, isEdited: action.isEdited }
      };
    }
    default:
      return state;
  }
}
