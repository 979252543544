import React, { useEffect, useState, forwardRef } from 'react';
import { useWatch, Controller } from 'react-hook-form';
import CharCountTextarea from '.';

const CharCountTextareaWrapper = ({
  name,
  id,
  defaultValue,
  control,
  label,
  errors,
  maxLength,
  helpText,
  rows,
  labelTooltip
}) => {
  // const charCount = value.length
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <CharCountTextarea
          {...field}
          label={label}
          id={id}
          errors={errors}
          maxLength={maxLength}
          helpText={helpText}
          rows={rows}
          labelTooltip={labelTooltip}
        />
      )}
    />
  );
};
export default CharCountTextareaWrapper;
