import React, { Component } from 'react';
import { connect } from 'react-redux';
import ModalWindow from '../ModalWindow';
import TabPanelContent from '../TabPanelContent';
import {
    addNewDomain, deleteDomain, editDomain
} from '../../actions/user-management/requesterTab';
import constant from '../../constants';
import ApproverContentTable from 'components/ApproverContentTable';

class RequesterTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            requesterDomain: {
                id: '',
                name: '',
                domain: ''
            },
            openNewDomainModal: false,
            openDeleteDomainModal: false,
            isUpdateDomain: false,
            showNameError: false,
            showDomainError: false,
            domainExistsError: false
        };
        this.requesterDomain = {
            id: '',
            name: '',
            domain: ''
        };
    }
    render() {
        const modalHeading = this.state.isUpdateDomain ? 'Update domain' : 'Add new domain';
        const newDomainDescription = 'User type: Requester';
        return <>
            <ApproverContentTable {...this.props}
                userData={this.props.domainData}
                columnHeaders={this.props.columnHeaders}
                asyncInProgress={this.props.asyncInProgress}
                modalClick={this.toggleModalWindow}
                editUser={this.editDomain}
                deleteUser={this.toggleDeleteDomainModal} />
            <ModalWindow className="w-32" isOpen={this.state.openNewDomainModal} onRequestClose={this.toggleModalWindow}
                heading={modalHeading} description={newDomainDescription} >
                <div className='form-group'>
                    <label htmlFor='description'>
                        <span>Name</span>
                        <small> (required)</small>
                    </label>
                    <input
                        className='form-control'
                        type='text'
                        name='name'
                        id='name'
                        data-required='true'
                        value={this.state.requesterDomain['name'] || ''}
                        onChange={this.handleInputChange}
                        rows='3'
                    />
                    <span className={`cw-error ${this.state.showNameError ? '' : 'cw-hidden'} mb-10`}
                        data-control='name'>
                        <i className='fas fa-exclamation-triangle mr-10'></i>Please enter name
					</span>
                </div>
                <div className='form-group'>
                    <label htmlFor='description'>
                        <span>Email Domain</span>
                        <small> (required)</small>
                    </label>
                    <input
                        className='form-control'
                        type='text'
                        name='domain'
                        id='domain'
                        onChange={this.handleInputChange}
                        data-required='true'
                        data-custom-error='true'
                        value={this.state.requesterDomain['domain']}
                        data-custom-error-type='domain'
                        rows='3'
                    />
                    <span className={`cw-error ${this.state.showDomainError ? '' : 'cw-hidden'} mb-10`} data-control='domain'>
                        <i className='fas fa-exclamation-triangle mr-10'></i>Please enter valid domain
						</span>
                    <span
                        className={`cw-error ${this.state.domainExistsError ? '' : 'cw-hidden'}`}
                        data-type='domainValidationMessage'>
                        <i className='fas fa-exclamation-triangle mr-10'></i>
							Domain already in use
						</span>
                </div>
                <div>
                    <button type='submit' onClick={this.toggleModalWindow} className='btn btn-outline-primary mr-10'>
                        Cancel
						</button>
                    <button className='btn btn-primary' onClick={this.addDomain}>
                        Confirm
							</button>
                </div>
            </ModalWindow>
            <ModalWindow className="w-32" isOpen={this.state.openDeleteDomainModal} onRequestClose={this.toggleDeleteDomainModal}
                heading={'Confirm delete'} description={`Confirm you want to delete ${this.state.requesterDomain.domain} ?`}>
                <div>
                    <button type='submit' onClick={this.toggleDeleteDomainModal} className='btn btn-outline-primary mr-10'>
                        Cancel
						</button>
                    <button
                        className='btn btn-primary'
                        onClick={this.deleteDomain}>
                        Confirm
						</button>
                </div>
            </ModalWindow>
        </>
    }

    componentDidUpdate(prevProps) {
        if (this.props.domainExists !== prevProps.domainExists) {
            this.setState({ domainExistsError: this.props.domainExists })
        }
    }

    handleInputChange = (event) => {
        const requesterDomain = {
            ...this.state.requesterDomain,
            [event.target.name]: event.target.value
        };
        this.setState({
            requesterDomain,
            domainExistsError: event.target.name === 'domain'
                && this.props.domainExists
                && this.state.domainExistsError ? false : this.state.domainExistsError
        });
        if (event.target.dataset.customError === 'true') {
            if (this.isValidEmailDomain(event.target.value)) {
                this.setState({ showDomainError: false });
            } else {
                this.setState({ showDomainError: true })
            }
        }
        //Note: Ideally a function should not have multiple calls to setState
        // below will only be called
        // if form was submitted with empty fields
        this.state.showNameError && requesterDomain.name !== '' && this.setState({ showNameError: false });
    }

    addDomain = (event) => {
        event.preventDefault();
        if (this.state.requesterDomain.name.trim() === '') {
            this.setState({ showNameError: true })
        } else if (this.state.requesterDomain.domain.trim() === '') {
            this.setState({ showDomainError: true })
        } else if (!this.state.showDomainError && !this.state.domainExistsError) {
            const result = this.state.isUpdateDomain
                ? this.props.editDomain(this.state.requesterDomain)
                : this.props.addNewDomain(this.state.requesterDomain, this.state.isUpdateDomain);
            result.then(response => {
                response === constant.apiResponseText.DOMAIN_ALREADY_EXISTS && this.setState({ domainExistsError: true });
                (this.props.isNewDomainAdded || this.props.isDomainUpdated) && !this.props.domainExists && this.toggleModalWindow();
            });
        }
    }
    toggleModalWindow = () => {
        this.setState({
            openNewDomainModal: !this.state.openNewDomainModal,
            requesterDomain: this.requesterDomain,
            isUpdateDomain: false,
            domainExistsError: false
        })
    }

    editDomain = (panelType, task, domain) => {
        this.setState({
            openNewDomainModal: !this.state.openNewDomainModal,
            requesterDomain: { id: domain._id, name: domain.name, domain: domain.domain },
            isUpdateDomain: true
        })
    }

    toggleDeleteDomainModal = (panelType, domainName) => {
        this.setState({
            openDeleteDomainModal: !this.state.openDeleteDomainModal,
            requesterDomain: {
                ...this.state.requesterDomain,
                domain: domainName
            }
        })
    }

    deleteDomain = () => {
        this.setState({
            openDeleteDomainModal: !this.state.openDeleteDomainModal,
            requesterDomain: this.requesterDomain
        })
        this.props.deleteDomain(this.state.requesterDomain.domain);
    }

    isValidEmailDomain = (domainName) => {
        const regex = constant.regex['emailDomain'];
        return domainName.match(regex)
            && domainName.match(regex).pop() === domainName
            && domainName !== '';
    }
}

const mapStateToProps = state => ({
    domainData: state.requester.domainData || {},
    asyncInProgress: state.requester.asyncInProgress,
    domainExists: state.requester.domainExists,
    isNewDomainAdded: state.requester.isNewDomainAdded,
    isDomainUpdated: state.requester.isDomainUpdated
});

export default connect(
    mapStateToProps,
    { addNewDomain, deleteDomain, editDomain }
)(RequesterTab);