import * as yup from 'yup';
import constants from 'constants/config';
import {
  getEmailCampaignDetails,
  updateEmailCampaignDetails
} from 'actions/emailbriefing/emailCampaignDetails';
import { getYupSchema, transformEmailBriefingPayload } from 'selectors';
import moment from 'moment';
import {get} from 'lodash';
import { EmailABTestingInput } from 'components/molecules/EmailABTestingInput';
const checkIfCleanUpRequired = (mainData, defaultData) => {
  const result = {cleanABTest: false, isVariantChange: false}
  const isAbTestConfigured = get(defaultData, 'isAbTestConfigured');
  const oldValue = get(defaultData, 'abTesting.performABTests');
  const newValue = get(mainData, 'abTesting.performABTests');
  const oldSplit = get(defaultData, 'abTesting.abTestSplits');
  const newSplit = get(mainData, 'abTesting.abTestSplits');
  const oldElements = get(defaultData, 'abTesting.abTestElements');
  const newElements = get(mainData, 'abTesting.abTestElements');
  if(isAbTestConfigured && oldValue === true && newValue === false){
    result.cleanABTest = true
  }
  if(isAbTestConfigured && oldElements !== newElements){
    result.cleanABTest = true
  }
  if(oldSplit === '3-variants-5-5-5-85' && newSplit !== '3-variants-5-5-5-85'){
    result.isVariantChange = true
  }
  return result
}
const emailABTestsSchema = (
  dispatch,
  { defaultValues, match, history, emailType }
  ) => {
    const isEdited =
    history &&
    history.location &&
    history.location.state &&
    history.location.state.isEdited;

    const onSubmit = (data, { user }, { backToReview } = {}) => {
      const paramUid = match.params.id;
      const dataToSend = {
          ...data,
          userEmail: user.emailId,
          userName: user.name,
        };
        
      const transformedData = transformEmailBriefingPayload(
        dataToSend,
        'abTesting'
      );
      const checks = checkIfCleanUpRequired(transformedData, defaultValues)
      if(checks.cleanABTest){
        transformedData.cleanABTest = true
      }
      if(checks.isVariantChange){
        transformedData.isVariantChange = true
      }

      dispatch(
        updateEmailCampaignDetails(
          paramUid,
          transformedData,
          (error, result) => {
            if (result) {
              const uid = match.params.id;
              const status = defaultValues?.status;
              if (isEdited && backToReview) {
                if (status === constants.EMAIL_STATUS.AWAITING_FOR_APPROVAL) {
                  history.push(`/campaign-wizard/emailbriefing/${uid}/approval`);
                } else if (
                  status === constants.EMAIL_STATUS.DRAFT ||
                  status === constants.EMAIL_STATUS.REJECTED
                ) {
                  history.push(`/campaign-wizard/emailbriefing/${uid}/review`);
                }
              } else {
                history.push(
                  `/campaign-wizard/emailbriefing/${uid}/email-properties`
                );
              }
            }
            if (error) {
              console.log('OnSubmit error: ', error);
            }
          }
        )
      );
    };
  return {
    formType: 'custombuttons',
    submitBtnLabel: isEdited ? 'Save and back to review' : 'Next',
    onSecondaryClick: onSubmit,
    onSubmitSecondary: () => {},
    isEdit: isEdited,
    secondaryBtnLabel: 'Next',
    fields: [
      {
        type: 'custom',
        name: 'abTesting',
        label:"A/B tests",
        component: EmailABTestingInput,
        id: 'abTesting',
        defaultValue: defaultValues,
        rules: yup
          .object({
            performABTests: yup.bool(),
        abTestElements:  yup.string().when('performABTests', {
                is: data => !!data,
                then: (schema) => schema.required("Please specify A/B test elements")}).nullable(),
        abTestSplits: yup.string().when('performABTests', {
            is: data => !!data,
            then: (schema) => schema.required("Please specify AB test split")}).nullable(),
        winCriteria: yup.string().when('performABTests', {
            is: data => !!data,
            then: (schema) => schema.required("Please specify win criteria")}).nullable(),
        testDuration: yup.object().shape({
            label: yup.string().required(),
            value: yup.string().required()
        }).when('abTestSplits', {
            is: data => constants.EMAIL_AB_TEST_DURATION_CHECK_LIST.includes(data),
            then: (schema) => schema.required("Please select test duration")}).default(undefined)
          })
      },
    ],
    onSubmit: (data, { user }) =>
    onSubmit(data, { user }, { backToReview: isEdited }),
    onDraft: (data, validationSchema, { user }) => {
      const paramUid = match.params.id;
      const dataToSend = {
        userEmail: user.emailId,
        userName: user.name,
        ...data,
        status: defaultValues?.status === '8' ? '8' : '1',
      };

      const transformedData = transformEmailBriefingPayload(
        dataToSend,
        'abTesting'
      );
      const checks = checkIfCleanUpRequired(transformedData, defaultValues)
      if(checks.cleanABTest){
        transformedData.cleanABTest = true
      }
      if(checks.isVariantChange){
        transformedData.isVariantChange = true
      }
      dispatch(
        updateEmailCampaignDetails(
          paramUid,
          transformedData,
          (error, result) => {
            if (result) {
              const uid = match.params.id;
              history.push(`/campaign-wizard/email-dashboard`);
            }
            if (error) {
              console.log('OnSubmit error: ', error);
            }
          },
          { isSaveAsDraft: true }
        )
      );
    },
    getSchema() {
      if (this.fields) {
        return getYupSchema(this.fields);
      }
      return null;
    },
    onPrevious: () => {
      const uid = match.params.id;
      history.push(`/campaign-wizard/emailbriefing/${uid}/email-audience`);
    },
    previousBtnLabel: 'Back',
    draftText: 'Save and exit',
    formErrorMessage:
      'There was a problem with the form. Errors are listed below'
  };
};

export default emailABTestsSchema;
