import React, { useState } from 'react';
import Modal from 'react-modal';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import EmailGuideline from 'components/EmailGuideline';
import { TabPanel } from 'pages/ReportingDashboard/ReportingDashboard';
import EmailCouponIcon from '../../../images/email-coupon.svg';

import constants from 'constants/config';

const EmailCouponGuidelines = ({ isOpen, defaultTab, handleCloseForm }) => {
  Modal.setAppElement('#root');
  const [activeTab, setActiveTab] = useState(defaultTab || 0);

  const tabStyle = { fontFamily: 'Open Sans', textTransform: 'none' };

  const handleChange = (e, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => {
        setActiveTab(defaultTab || 0);
        handleCloseForm();
      }}
      className="cw-modal cw-modal--coupon-guidelines"
      contentLabel="Save row form"
      style={{
        overlay: {
          backgroundColor: constants.EMAIL_OVERLAY_BACKGROUND
        }
      }}
    >
      <div className="email-overlay-wrapper">
        <span
          className="qna-guideline-close email-overlay-close-modal"
          onClick={() => {
            setActiveTab(defaultTab || 0);
            handleCloseForm();
          }}
        ></span>

        <div className="d-flex mb-20">
          <div className="email-overlay-header-box">
            <img
              src={EmailCouponIcon}
              alt="send test email icon"
              className="send-test-email__icon"
            />
          </div>
          <div className="ml-10 pr-3">
            <div className={'cw-heading--saascampaign send-test-email__title'}>
              Coupon guidelines
            </div>
            <p className="mt-10">
              You have chosen to send coupons in your email. Please follow the
              guidelines below to setup coupons correctly
            </p>
          </div>
        </div>

        <div className="overlay-content-wrapper">
          <div className="overlay-content">
            <Tabs
              value={activeTab}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              disableRipple
              style={{ borderBottom: '1px solid #aaa', marginBottom: '1rem' }}
            >
              <Tab disableRipple style={tabStyle} label="Static coupons" />
              <Tab disableRipple style={tabStyle} label="Dynamic coupons" />
            </Tabs>
            <TabPanel value={activeTab} index={0}>
              <EmailGuideline
                listStyleType="disc"
                listStyleTypeColor="black"
                liClassName="li-item-color"
                noMargin
                isEmailCampaign
                hideCloseButton
                hideHorizontalLine
                data={constants.EMAIL_STATIC_COUPON_GUIDELINES}
                noUlLineBreak
                noSubTitleLineBreak
              />
            </TabPanel>
            <TabPanel value={activeTab} index={1}>
              <EmailGuideline
                listStyleType="disc"
                listStyleTypeColor="black"
                liClassName="li-item-color"
                noMargin
                isEmailCampaign
                hideCloseButton
                hideHorizontalLine
                data={constants.EMAIL_DYNAMIC_COUPON_GUIDELINES}
                noUlLineBreak
                noSubTitleLineBreak
              />
            </TabPanel>
          </div>

          <div className="bottom-cta">
            <div className="horizontal-line"></div>

            <div className="mt-3">
              <button
                type="submit"
                onClick={() => {
                  setActiveTab(defaultTab || 0);
                  handleCloseForm();
                }}
                className="btn btn-block btn-outline-secondary mb-10 cw-print-hide text-nowrap"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EmailCouponGuidelines;
