import React from 'react';

const EmailSaveRowIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 22 22" fill="none">
      <path d="M15 1H7V7H15V1Z" fill="black"></path>
      <path
        d="M18.1 1H17V9.00001H4.99998V1L3.50195 1C2.12016 1 1 2.12016 1 3.50195L1 18.498C1 19.8798 2.12016 21 3.50195 21H18.498C19.8798 21 21 19.8798 21 18.498V4.93636C21 4.2728 20.7364 3.63642 20.2672 3.16721L18.1 1ZM11 19C8.79999 19 7 17.2 7 15C7 12.8 8.79999 11 11 11C13.2 11 15 12.8 15 15C15 17.2 13.2 19 11 19Z"
        fill="black"
      ></path>
      <path
        d="M11 17C12.1046 17 13 16.1046 13 15C13 13.8954 12.1046 13 11 13C9.89541 13 8.99997 13.8954 8.99997 15C8.99997 16.1046 9.89541 17 11 17Z"
        fill="black"
      ></path>
    </svg>
  );
};

export default EmailSaveRowIcon;
