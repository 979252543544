import EditButton from 'components/atoms/EditButton/EditButton';
import React from 'react';
import _ from 'lodash';
import ReactTooltip from 'react-tooltip';

export default function EmailTabular(props) {
  const { sections = [], isDesignPage } = props;
  return (
    <SectionContainer {...props}>
      <div className="cw-campaign--review-section-content">
        {props.data.map((campaignData, index) => {
          return (campaignData.value && _.trim(campaignData.value) !== '') ||
            (!isDesignPage && campaignData.mustShow) ? (
            <div
              className="email-tabular__row row"
              key={'campaigndetails_' + index}
            >
              <div className="col-sm-3 table-lable">
                <strong
                  className="email-tabular__label cw-icon cw-icon--help"
                  data-for="label-tooltip"
                  data-tip={campaignData.tooltip}
                >
                  {campaignData.label}
                </strong>

                {campaignData.subLabel ? (
                  <span className="email-tabular__subLabel">
                    {campaignData.subLabel}
                  </span>
                ) : (
                  ''
                )}
              </div>
              <div className={`col-sm-9 email-tabular__smsvalue`}>
                {campaignData.value ? (
                  <span
                    className={`${campaignData.preWrap ? 'pre-wrap' : ''} ${
                      campaignData.typeOfDownload && campaignData.isDownloadable
                        ? ' cw-icon'
                        : ''
                    }`}
                    filename={campaignData.value}
                    onClick={() =>
                      campaignData.typeOfDownload &&
                      campaignData.typeOfDownload === 'ce' &&
                      campaignData.isDownloadable
                        ? props.handleDownloadCostEstimateClick(
                            campaignData.value
                          )
                        : campaignData.typeOfDownload &&
                          campaignData.typeOfDownload === 'po' &&
                          campaignData.isDownloadable
                        ? props.handleDownloadPOClick(campaignData.value)
                        : null
                    }
                  >
                    {campaignData.value}
                  </span>
                ) : (
                  <span className="email-tabular__subLabel">
                    {campaignData.altText}
                  </span>
                )}
                {campaignData.optOutTextValue && (
                  <>
                    <br />
                    <br /> <span>{campaignData.optOutTextValue}</span>
                  </>
                )}
                {campaignData.tooltip ? (
                  <i
                    className="fas fa-info-circle ml-1"
                    data-for="value-tooltip"
                    data-tip={campaignData.tooltip}
                  ></i>
                ) : null}
                {campaignData.tooltip ? (
                  <ReactTooltip
                    id="value-tooltip"
                    place="right"
                    type="info"
                    multiline={true}
                    className="cw-tooltip cw-email-tooltip saas_new_master_tooltip"
                  />
                ) : null}
              </div>
            </div>
          ) : null;
        })}
        {sections.map(section => (
          <Section section={section} />
        ))}
      </div>
    </SectionContainer>
  );
}

export function SectionContainer({
  title,
  isError,
  onClickEdit,
  children,
  errorDetails
}) {
  let isDateError = false;
  let dateFieldLabel = '';
  let generalErrorMesg = 'Information is missing in this section';

  if (errorDetails && typeof errorDetails === 'object') {
    if (
      [
        'emailTrigger.details.emailDeliveryDate',
        'smsTrigger.details.smsDeliveryDate'
      ].includes(errorDetails.path)
    ) {
      dateFieldLabel = 'email delivery date';
    }
    if (
      [
        'emailTrigger.details.triggerConditionStartDate',
        'smsTrigger.details.triggerConditionStartDate'
      ].includes(errorDetails.path)
    ) {
      dateFieldLabel = 'start date';
    }
    if (
      [
        'emailTrigger.details.triggerConditionEndDate',
        'smsTrigger.details.triggerConditionEndDate'
      ]
    ) {
      dateFieldLabel = 'end date';
    }
    if (errorDetails.type === 'isNotPastDate') {
      isDateError = true;
    }
  }

  if (errorDetails && typeof errorDetails === 'boolean') {
    generalErrorMesg = 'Please upload your coupon file';
  }

  return (
    <div className="email-tabular">
      <div className="cw-campaign--review-section-header d-flex align-items-center">
        <h3 className="email-tabular__heading">{title}</h3>
        {onClickEdit ? <EditButton onClick={onClickEdit} /> : null}
      </div>
      {isError ? (
        isDateError ? (
          <p className="error-text">
            <span>
              <i className="fas fa-exclamation-triangle mr-10"></i>
            </span>
            {`Please select a future ${dateFieldLabel}`}
          </p>
        ) : (
          <p className="error-text">
            <span>
              <i className="fas fa-exclamation-triangle mr-10"></i>
            </span>
            {generalErrorMesg}
          </p>
        )
      ) : null}
      {children}
    </div>
  );
}

export const Section = ({ section = {} }) => {
  const { title, properties } = section;
  return (
    <div className="mb-2">
      {/* <div className="cw-heading-qna mb-2">
        <strong className="email-tabular__label">{title}</strong>
      </div> */}
      {properties.map(property => (
        <div
          className="email-tabular__row row"
          key={'campaigndetails_' + property.label}
        >
          <div className="col-sm-3">
            <strong className="email-tabular__label">{property.label}</strong>
            <span className="email-tabular__subLabel">{property.subLabel}</span>
          </div>
          <div className={`col-sm-9 email-tabular__value`}>
            {property.value}
          </div>
        </div>
      ))}
    </div>
  );
};
