import React, { forwardRef, useEffect, useState } from 'react';
import { Input } from 'components/atoms/Input';
import { FieldError } from 'components/atoms/FieldError';
import { get } from 'lodash';

const getLabel = (option, abTestElement) => {
    if(abTestElement === "subjectLine") {
        return `Subject line ${option}`
    } else if(abTestElement === "emailContent") {
      return `Content ${option}`
    } else {
      return `Send time ${option}`
    }
    
}

export const ABTestResultInput = ({
  name,
  rules,
  register,
  label,
  errors,
  setValue,
  control,
  defaultValue,
  ...props
}) => {
  const errorMessages = get(errors, name);
  const hasError = !!(errors && errorMessages);

  const abTestElement = get(defaultValue, 'abTesting.abTestElements')
  const winningVariant = get(defaultValue, 'winningVariant', '')

  return (
    <div className="form-group " aria-live="polite">
        <label htmlFor={name}>{label}</label>
      <div>
          <div className="form-check mb-10" aria-live="polite">
            <Input
              name={`${name}`}
              type="radio"
              {...(register && register(`${name}`))}
              id="variantA"
              value="variantA"
              defaultChecked={winningVariant === 'variantA'}
            />
            <label htmlFor="variantA" className="form-check-label">
              <p>{getLabel("variant A", abTestElement)}</p>
            </label>
          </div>
      </div>
      <div>
        <div className="form-check mb-10" aria-live="polite">
            <Input
              name={`${name}`}
              type="radio"
              {...(register && register(`${name}`))}
              id="variantB"
              value="variantB"
              defaultChecked={winningVariant === 'variantB'}
            />
            <label htmlFor="variantB" className="form-check-label">
              <p>{getLabel("variant B", abTestElement)}</p>
            </label>
        </div>
      </div>
      {props.showVariantC && (
        <div>
        <div className="form-check mb-10" aria-live="polite">
            <Input
              name={`${name}`}
              type="radio"
              {...(register && register(`${name}`))}
              id="variantC"
              value="variantC"
              defaultChecked={winningVariant === 'variantC'}
            />
            <label htmlFor="variantC" className="form-check-label">
              <p>{getLabel("variant C", abTestElement)}</p>
            </label>
        </div>
      </div>
      )}

      <FieldError errors={errors} name={`${name}`} />
    </div>
  );
};
