import React, { forwardRef } from 'react';
import { FieldError } from '../../atoms/FieldError';
import { get } from 'lodash';
import classNames from 'classnames';

const CharCountInput = forwardRef(
  ({ onChange, name, value, maxLength, label, helpText, errors }, ref) => {
    console.log('maxLength: ', maxLength);
    // const charCount = value.length
    const errorMessages = get(errors, name);
    const hasError = !!(errors && errorMessages);
    return (
      <div className="form-group charcountinput">
        <label htmlFor={name}>{label}</label>
        <div className="position-relative">
          <input
            className={classNames('form-control pr-5', {
              'is-invalid': hasError
            })}
            type="text"
            name={name}
            id={name}
            onChange={event => onChange(event.target.value)}
            value={value}
            ref={ref}
            maxLength={maxLength}
          />
          <span className="position-absolute text-secondary charcountinput__count">
            {value.length}/{maxLength}
          </span>
        </div>
        <div className="charcountinput__helptext">{helpText}</div>
        <FieldError errors={errors} name={name} />
      </div>
    );
  }
);
export default CharCountInput;
