import Modal from 'react-modal';
import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';
import groupIcon from '../images/groupIcon.png';
import QuestionAnswerNewList from './SAAS/QuestionAnswerNewList';
import constants from 'constants/config';
const GuideLinesAccordian = props => {
  const [expand, setExpand] = useState(false);
  const [addNewQuestionModal, setAddNewQuestionModal] = useState(false);

  let CloseAddNewQuestionModal = () => {
    setAddNewQuestionModal(false);
  };

  let handleAccordionExpandCollapse = () => {
    setExpand(!expand);
  };
  return (
    <>
      <ReactTooltip
        place="right"
        type="info"
        multiline={true}
        className="cw-tooltip"
      />
      {props.showMasterIcon ? (
        <>
          <div style={{ color: '#E8A100' }}>
            <img src={groupIcon} alt={groupIcon} width="25" height="25"></img>
            {` This campaign includes new master data requests.`}
          </div>
          <br />
        </>
      ) : null}
      <div className="accordion saas-form-field-accordion mb-15">
        <div className="card" style={{ border: 0 }}>
          <div className="guideline-accordion-title">
            <div>
              <span className="cw-heading--senary">
                {props.data && props.data.title ? props.data.title : ''}
              </span>
              <span
                type="button"
                className={`guideline-accordion-arrow ${expand}`}
                onClick={() => handleAccordionExpandCollapse()}
              >
                <i
                  className={
                    expand === false
                      ? 'fas fa-chevron-down transformIcon-180'
                      : 'fas fa-chevron-down'
                  }
                ></i>
              </span>
            </div>
            {props.showPreviewFormCta && (
              <div className="preview-btn-padding">
                <a
                  className="btn btn-primary preview-form-btn"
                  href={props.previewUrl}
                  target={'_blank'}
                >
                  Preview Form
                </a>
              </div>
            )}
          </div>
          <div className={expand === false ? 'collapse show' : 'collapse'}>
            <div>
              <p className="my-2">{props.data.subTitle}</p>
              <ul
                className={`ml-20 ${
                  props.qnaIndex ? 'qna_guide_ul' : 'item_guide_ul'
                }`}
              >
                {props.data.guideLine.map((elem, index) => {
                  return (
                    <>
                      <li
                        className={`${
                          props.qnaIndex ? 'qna_guide_list' : 'items_guide_list'
                        }`}
                        style={{ fontWeight: '' }}
                      >
                        {elem.text}
                        {elem.subText ? (
                          <>
                            <ul className="ml-20">
                              {elem.subText.map(e => {
                                return (
                                  <li
                                    className="item_guide_li"
                                    style={{ color: '#000000 !important' }}
                                  >
                                    {e.text}
                                    {e.isKnowMore ? (
                                      e.isModal ? (
                                        <a
                                          className={'alert-knowmore-message'}
                                          href="#"
                                          onClick={() =>
                                            setAddNewQuestionModal(true)
                                          }
                                        >
                                          {e.knowMoreText}
                                        </a>
                                      ) : (
                                        <>
                                          {' '}
                                          <span data-tip={e.toolTipText}>
                                            <a
                                              className={
                                                'alert-knowmore-message'
                                              }
                                            >
                                              {e.knowMoreText}
                                            </a>
                                          </span>
                                        </>
                                      )
                                    ) : null}
                                  </li>
                                );
                              })}
                            </ul>
                          </>
                        ) : null}
                        {elem.isKnowMore ? (
                          elem.isModal ? (
                            <a
                              className={'alert-knowmore-message'}
                              href="#"
                              onClick={() => setAddNewQuestionModal(true)}
                            >
                              {elem.knowMoreText}
                            </a>
                          ) : (
                            <>
                              <span data-tip={elem.toolTipText}>
                                <a className={'alert-knowmore-message'}>
                                  {' '}
                                  {elem.knowMoreText}
                                </a>{' '}
                              </span>
                            </>
                          )
                        ) : null}
                      </li>
                    </>
                  );
                })}
              </ul>
              {/* <p>
                <strong>
                    1.
                </strong>
                &nbsp;
                An approved PRA reference should be available and should match the context of this campaign
            </p>
            <p>
                <strong>
                    2.
                </strong>
                &nbsp;
                Questions and answers
            </p>
            <ul className="ml-20">
                <li>PII information should not be requested as questions and answers. <a className={"alert-knowmore-message"} >Know more</a> </li>
                <li>Any sensitive data should only be collected for valid reasons approved by Data Privacy/ legal. <a className={"alert-knowmore-message"}>What’s sensitive data?</a></li>
                <li>Questions and answers should be built using the master Q&A list. In case a new question and/or answer is requested, please ensure it is a valid request, not a duplicate or a rephrased version of an already available master Q&A. You can check the <a className={"alert-knowmore-message"}>existing master list of Q&As here</a></li>
            </ul>
            <p>
                <strong>
                    3.
                </strong>
                &nbsp;
                Any additional market level considerations should also inform your decision to approve or reject
            </p>
            <p>
                <strong>
                    4.
                </strong>
                &nbsp;
                Ensure that any rejections are accompanied by comments that explain the corrections that need to be made
            </p> */}
              {props.noLineBreak ? '' : <br />}
            </div>
          </div>
        </div>
        <Modal
          scrollable={true}
          isOpen={addNewQuestionModal}
          onRequestClose={CloseAddNewQuestionModal}
          className="cw-modal cw-modal--qnaoverlay"
          contentLabel="Campaign Wizard 2.0"
          style={{
            overlay: {
              backgroundColor: constants.EMAIL_OVERLAY_BACKGROUND
            }
          }}
        >
          <QuestionAnswerNewList
            language={props.campaignLanguageCode}
            campaignLanguage={props.campaignLanguage}
            browseType="Questions"
            isOpenModal={addNewQuestionModal}
            closeModal={CloseAddNewQuestionModal}
          />
        </Modal>
      </div>
    </>
  );
};

export default GuideLinesAccordian;
