import React, { Component, Fragment, useCallback } from 'react';
import { connect } from 'react-redux';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';
import constant from '../constants';

import {
  fetchCampaignData,
  updateQuestionDetails,
  submitQuestionsAnswers,
  resetDataSubmittionState
} from '../actions/questionAnswerSorting';
import Loader from './Loader';
import BreadCrumb from './BreadCrumb';
import {
  setApproverEditCampaignStatus,
  setApproverEditCampaignStatusMessage,
  editedCampaignDetails,
  fetchEditedCampaignLogs,
  saveEditedCampaignLogs
} from '../actions/app';
import { setNotificationMessage } from '../actions/dashboard';
import {
  isQuestionMandatory,
  checkCampaignLanguage,
  isQuesQualifying,
  isRestrictionAllowed
} from '../selectors';

const SortableItem = SortableElement(
  ({
    question,
    sortIndex,
    handleCollapse,
    collapsableIcon,
    collapsablePanel,
    isCampaignNonEnglish,
    campaignLanguage,
    isPromotigoType
  }) => {
    const collpaseEv = useCallback(() => {
      handleCollapse(sortIndex);
    }, [handleCollapse, sortIndex]);
    return (
      <li className="list-group-item p-0 sortable-item">
        <div
          className="cw-questions--listitem "
          data-item={sortIndex}
          key={sortIndex}
        >
          <div className="cw-striped-row">
            <div className="row">
              <div className="col-sm-4">
                <div className="row">
                  <span className="text-secondary pl-13 mr-20">
                    <i className="fas fa-align-justify"></i>
                  </span>
                  <span>
                    <strong>
                      <a
                        className="pt-2"
                        href="#questionAnswersCollapsed"
                        role="button"
                        aria-expanded="false"
                        aria-controls="questionAnswersCollapsed"
                        onClick={collpaseEv}
                      >
                        Question {' ' + (sortIndex + 1)}{' '}
                        <i
                          className={`ml-1 fas fa-chevron-down`}
                          ref={node => {
                            collapsableIcon[sortIndex] = node;
                          }}
                        ></i>
                      </a>
                    </strong>
                    <br />
                    {!isCampaignNonEnglish && question.type !== 'Profile' ? (
                      <p className="xsmall-label">English</p>
                    ) : null}
                  </span>
                </div>
              </div>
              <div className="d-flex col-sm-8 justify-content-between align-items-center">
                {question.text}
              </div>
            </div>
          </div>
          <div
            className={`collapse `}
            id="questionAnswersCollapsed"
            ref={node => {
              collapsablePanel[sortIndex] = node;
            }}
          >
            {!isCampaignNonEnglish && question.type !== 'Profile' ? (
              <div className="cw-striped-row collpased-striped-row">
                <div className="row">
                  <div className="col-sm-4">
                    <div>
                      <strong>Question</strong>
                    </div>
                    <div className={`${isCampaignNonEnglish ? 'd-none' : ''}`}>
                      <p className="xsmall-label">{campaignLanguage}</p>
                    </div>
                  </div>
                  <div className="col-sm-8">
                    <p>{question.translatedquestion}</p>
                  </div>
                </div>
              </div>
            ) : null}
            {question.answers.length && question.answers[0].label !== ''
              ? question.answers.map((answer, ansindex) => {
                  return (
                    <>
                      <div
                        className="cw-striped-row collpased-striped-row"
                        key={ansindex}
                      >
                        <div className="row">
                          <div className="col-sm-4">
                            <div>
                              <strong>
                                Answer{' '}
                                {isCampaignNonEnglish &&
                                question.type !== 'Profile'
                                  ? ansindex + 1
                                  : ''}
                              </strong>
                            </div>
                            <div
                              className={`${
                                isCampaignNonEnglish ||
                                question.type === 'Profile'
                                  ? 'd-none'
                                  : ''
                              }`}
                            >
                              <p className="xsmall-label">English</p>
                            </div>
                          </div>
                          <div className="col-sm-8 justify-content-between align-items-left">
                            {answer.label}
                          </div>
                        </div>
                      </div>
                      {!isCampaignNonEnglish && question.type !== 'Profile' ? (
                        <div className="cw-striped-row collpased-striped-row">
                          <div className="row">
                            <div className="col-sm-4">
                              <div>
                                <strong>
                                  Answer{' '}
                                  {!isCampaignNonEnglish ? ansindex + 1 : ''}
                                </strong>
                              </div>
                              <div
                                className={`${
                                  isCampaignNonEnglish ? 'd-none' : ''
                                }`}
                              >
                                <p className="xsmall-label">
                                  {campaignLanguage}
                                </p>
                              </div>
                            </div>
                            <div className="col-sm-8">
                              <p>{answer.translatedanswer}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </>
                  );
                })
              : null}
            {question.maxAnswers ? (
              <div className="cw-striped-row collpased-striped-row">
                <div className="row">
                  <div className="col-sm-4">
                    <strong>Allowed answers</strong>
                  </div>
                  <div className="col-sm-8 justify-content-between align-items-left">
                    {question.maxAnswers}
                  </div>
                </div>
              </div>
            ) : null}
            <div className="cw-striped-row collpased-striped-row">
              <div className="row">
                <div className="col-sm-4">
                  <strong>Type</strong>
                </div>
                <div className="col-sm-8 justify-content-between align-items-left">
                  {question.type}
                </div>
              </div>
            </div>
            <div className="cw-striped-row collpased-striped-row">
              <div className="row">
                <div className="col-sm-4">
                  <strong>Mandatory</strong>
                </div>
                <div className="col-sm-8 justify-content-between align-items-left">
                  {isQuestionMandatory(question.mandatory)}
                </div>
              </div>
            </div>
            {isPromotigoType &&
            (question.type === 'Free text' ||
              question.type === 'Single choice') ? (
              <div className="cw-striped-row collpased-striped-row">
                <div className="row">
                  <div className="col-sm-4">
                    <strong>Qualifying</strong>
                  </div>
                  <div className="col-sm-8 justify-content-between align-items-left">
                    {isQuesQualifying(question.isQualifying)}
                  </div>
                </div>
              </div>
            ) : null}

            {question.type === 'File upload' ? (
              <>
                <div className="cw-striped-row collpased-striped-row">
                  <div className="row">
                    <div className="col-sm-4">
                      <strong>Upload restrictions</strong>
                    </div>
                    <div className="col-sm-8 justify-content-between align-items-left">
                      {isRestrictionAllowed(question.isRestrictionAllowed)}
                    </div>
                  </div>
                </div>
                {question.isRestrictionAllowed ? (
                  <div className="cw-striped-row collpased-striped-row">
                    <div className="row">
                      <div className="col-sm-4">
                        <strong>File size</strong>
                      </div>
                      <div className="col-sm-8 justify-content-between align-items-left">
                        {question.maxFileSize} KB
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className="cw-striped-row collpased-striped-row">
                  <div className="row">
                    <div className="col-sm-4">
                      <strong>Default display text</strong>
                    </div>
                    <div className="col-sm-8 justify-content-between align-items-left">
                      {question.defaultDisplayText}
                    </div>
                  </div>
                </div>
                <div className="cw-striped-row collpased-striped-row">
                  <div className="row">
                    <div className="col-sm-4">
                      <strong>Placeholder text</strong>
                    </div>
                    <div className="col-sm-8 justify-content-between align-items-left">
                      {question.placeholderText}
                    </div>
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </li>
    );
  }
);

const SortableList = SortableContainer(
  ({
    items,
    handleCollapse,
    collapsableIcon,
    collapsablePanel,
    isCampaignNonEnglish,
    campaignLanguage,
    isPromotigoType
  }) => {
    return (
      <ul className="list-group">
        {items.map((value, index) => (
          <SortableItem
            key={`item-${index}`}
            index={index}
            question={value}
            sortIndex={index}
            handleCollapse={handleCollapse}
            collapsableIcon={collapsableIcon}
            collapsablePanel={collapsablePanel}
            isCampaignNonEnglish={isCampaignNonEnglish}
            campaignLanguage={campaignLanguage}
            isPromotigoType={isPromotigoType}
          />
        ))}
      </ul>
    );
  }
);

class QuestionAnswerSorting extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [
        'Question 1',
        'Question 2',
        'Question 3',
        'Question 4',
        'Question 5',
        'Question 6'
      ],
      questions: [],
      matchProps: props.match
    };
    this.pageDisplayOptions = [
      { value: 'single', label: 'Single' },
      { value: 'multiple', label: 'Multiple' }
    ];
    this.onSortEnd = this.onSortEnd.bind(this);
    this.handleCollapse = this.handleCollapse.bind(this);
    this._handleOnSelectChange = this._handleOnSelectChange.bind(this);
    this._handleBackButton = this._handleBackButton.bind(this);
    this._handleSubmit = this._handleSubmit.bind(this);
    this._cancelApproverEdit = this._cancelApproverEdit.bind(this);
    this.collapsableIcon = [];
    this.collapsablePanel = [];
  }

  handleCollapse(index) {
    if (this.collapsableIcon[index] && this.collapsablePanel[index]) {
      if (this.collapsablePanel[index].classList.contains('show')) {
        this.collapsablePanel[index].classList.remove('show');
        this.collapsableIcon[index].classList.remove('fa-chevron-up');
        this.collapsableIcon[index].classList.add('fa-chevron-down');
      } else {
        this.collapsablePanel[index].classList.add('show');
        this.collapsableIcon[index].classList.remove('fa-chevron-down');
        this.collapsableIcon[index].classList.add('fa-chevron-up');
      }
    }
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(
      ({ questions }) => ({
        questions: arrayMove(questions, oldIndex, newIndex)
      }),
      () => {
        this.props.updateQuestionDetails({
          questionAnswerList: this.state.questions
        });
      }
    );
  };

  componentDidMount() {
    this.campaignId = this.props.match.params.id;
    this.props.fetchCampaignData(this.campaignId);
  }
  componentDidUpdate(prevProps) {
    if (
      !this.props.approverEditCampaign &&
      this.props.isCampaignEdited &&
      this.props.isCampaignEdited !== prevProps.isCampaignEdited
    ) {
      this.props.fetchEditedCampaignLogs(this.campaignId);
      this.props.editedCampaignDetails({
        questionAnswerList: this.props.questionAnswerList
      });
    }

    if (
      !this.props.approverEditCampaign &&
      this.props.questionAnswerList !== prevProps.questionAnswerList
    ) {
      this.props.editedCampaignDetails({
        questionAnswerList: this.props.questionAnswerList
      });
    }
  }
  componentWillReceiveProps(nextProps, nextState) {
    if (nextProps.questionLoaded) {
      this.setState({
        questions: nextProps.questionAnswerList
      });
    }
    if (nextProps.sortingdataSubmittedToApi) {
      nextProps.resetDataSubmittionState(false);
      if (nextProps.approverEditCampaign) {
        nextProps.setApproverEditCampaignStatusMessage('success');
        this.props.history.push(
          '/campaign-wizard/campaign/' + this.campaignId + '/approval'
        );
      } else {
        this.props.history.push(
          '/campaign-wizard/campaign/' + this.campaignId + '/review'
        );
      }
    }

    if (nextProps.questionanswerssaved) {
      let notificationData = {};
      notificationData.message = constant.CAMPAIGN_SAVED_AS_DRAFT_TEXT;
      notificationData.link = '';
      notificationData.type = 'warning';
      notificationData.campaign = '';

      this.props.setNotificationMessage(
        notificationData.message,
        notificationData.link,
        notificationData.type,
        notificationData.campaign
      );
      this.props.history.push('/campaign-wizard/dashboard');
    }
  }

  _handleOnSelectChange(selectedOption, event) {
    this.props.updateQuestionDetails({ [event.name]: selectedOption.value });
  }

  _handleBackButton(event) {
    event.preventDefault();
    this.props.history.push(
      '/campaign-wizard/campaign/' + this.campaignId + '/questionsanswers'
    );
  }
  _handleSubmit(event, saveAsDraft) {
    event.preventDefault();
    let formElements = {};
    formElements['questionAnswerList'] = this.props.questionAnswerList;
    formElements['questionDisplay'] = this.props.questionDisplay;
    this.props.submitQuestionsAnswers(
      this.campaignId,
      formElements,
      saveAsDraft
    );
    if (!this.props.approverEditCampaign && this.props.isCampaignEdited) {
      this.props.saveEditedCampaignLogs(
        this.campaignId,
        this.props.editCampaignDetails
      );
    }
  }
  _cancelApproverEdit() {
    this.props.setApproverEditCampaignStatus(false);
    this.props.setApproverEditCampaignStatusMessage('');
    this.props.history.push(
      '/campaign-wizard/campaign/' + this.campaignId + '/approval'
    );
  }

  render() {
    let { campaign, campaignDetails, websiteType } = this.props;
    let campaignLanguage =
      campaign && campaign.language && campaign.language.name;
    let isCampaignNonEnglish = checkCampaignLanguage(campaignLanguage);
    let isCampaignAEMType =
      websiteType === 'Adobe Classic' ||
      websiteType === 'Adobe Templated' ||
      websiteType === 'Standalone';
    let isIncentive = campaign.campaignType === 'Incentive';
    let isPromotigoType = isCampaignAEMType && isIncentive ? true : false;

    return (
      <>
        <ReactTooltip
          place="right"
          type="info"
          multiline={true}
          className="cw-toolip"
        />
        {!this.props.questionLoaded ? <Loader /> : null}
        <div className="col-sm-12">
          <div className="cw-section">
            <BreadCrumb
              match={this.state.matchProps}
              editCampaign={
                this.props.editCampaign || this.props.isCampaignEdited
              }
              websiteType={this.props.websiteType}
            />
            <div className="row">
              <div className="col-sm-8 cw-section--title mb-30">
                <h2 className="cw-heading--secondary mb-10">Question layout</h2>
              </div>
            </div>
            <div className="row mb-20">
              <div className="col-sm-8">
                <p className="cw-heading--tertiary mb-20">Sort questions</p>
                <p className="m-0">
                  Sort the questions into the order they should appear on the
                  campaign form.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-8">
                <div className="cw-section--content">
                  <div className="form-group">
                    <SortableList
                      distance={1}
                      items={this.state.questions}
                      onSortEnd={this.onSortEnd}
                      handleCollapse={this.handleCollapse}
                      collapsableIcon={this.collapsableIcon}
                      collapsablePanel={this.collapsablePanel}
                      isCampaignNonEnglish={isCampaignNonEnglish}
                      campaignLanguage={campaignLanguage}
                      isPromotigoType={isPromotigoType}
                    />
                  </div>
                  <div className="cw-form--action-cta">
                    {this.props.approverEditCampaign ? (
                      <>
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={this._handleSubmit}
                        >
                          Save
                        </button>
                        <a
                          href="#"
                          className="cw-save-to-draft mt-25"
                          onClick={this._cancelApproverEdit}
                        >
                          Cancel
                        </a>
                      </>
                    ) : (
                      <>
                        <button
                          type="button"
                          className="btn btn btn-outline-secondary mr-3"
                          onClick={this._handleBackButton}
                        >
                          Back
                        </button>
                        <button
                          type="submit"
                          className="btn btn-primary"
                          onClick={this._handleSubmit}
                        >
                          Next
                        </button>
                        <a
                          href="javascript:void(0)"
                          className="cw-save-to-draft mt-40"
                          onClick={event => this._handleSubmit(event, true)}
                        >
                          Save and exit
                        </a>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  campaign: state.questionsorting.campaign,
  campaignDetails: state.questionsorting.campaignDetail || {},
  questionAnswerList: state.questionsorting.questionAnswerList || [],
  questionDisplay: state.questionsorting.questionDisplay,
  asyncInProgress: state.questionsorting.asyncInProgress,
  questionLoaded: state.questionsorting.questionLoaded,
  sortingdataSubmittedToApi: state.questionsorting.sortingdataSubmittedToApi,
  approverEditCampaign: state.app.approverEditCampaign,
  questionanswerssaved: state.questionsorting.questionanswerssaved,
  editCampaign: state.overview.editCampaign,
  editCampaignDetails: state.app.editCampaignDetails,
  isCampaignEdited: state.questionsorting.isCampaignEdited,
  websiteType: state.questionsorting.websiteType
});

export default connect(mapStateToProps, {
  fetchCampaignData,
  updateQuestionDetails,
  submitQuestionsAnswers,
  resetDataSubmittionState,
  setApproverEditCampaignStatus,
  setApproverEditCampaignStatusMessage,
  setNotificationMessage,
  editedCampaignDetails,
  fetchEditedCampaignLogs,
  saveEditedCampaignLogs
})(QuestionAnswerSorting);
