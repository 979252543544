import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Modal from 'react-modal';

import {
  fetchCampaignData,
  approveCampaign,
  rejectCampaign,
  fetchCampaignEditLogs
} from '../actions/approval';
import { fetchHistoryLogs } from '../actions/history';
import { setModalStatus, setApproverEditCampaignStatus } from '../actions/app';
import { setNotificationMessage } from '../actions/dashboard';
import NotificationMessage from './Messages';
import {
  getFormatedDate,
  getCampaignStatusText,
  statusBadge,
  isQuestionMandatory,
  isQuesQualifying,
  isRestrictionAllowed,
  isCampaignEdited,
  checkCampaignLanguage,
  checkMarketSupportedForDoubleOptin,
  isAEMTypeCampaign,
  showAutoGenerateFlag,
  epsilonMarket,
  showMasterDataIcon,
  getCampaignStatus,
  getNewFormatedDate,
  isMDRequiredForQnA
} from '../selectors/index';
import constant from '../constants';
import Loader from './Loader';
import RegularCommunications from './RegularCommunications';
import classNames from 'classnames';
import Alert from './Alert';
import HistoryComponent from '../components/history';
import { getBrandCountries } from '../actions/brandDetails';
import FormAuthoring from './Review/FormAuthoring';
import FormAuthoringGrouped from './Review/FormAuthoringGrouped';
import GuideLinesAccordian from './GuideLinesAccordian';
import groupIcon from '../images/groupIcon.png';
import SummaryComponent from './SummaryComponent';
import CampaignDetailsInfo from './CampaignDetailsInfo';
import QnAList from './Review/QnAList';
import ApproverAssignmentInfo from './organisms/ApproverAssignmentInfo';
import axios from 'axios';
import { get } from 'lodash';
class Approval extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.formElements = {
      description: ''
    };
    this.errorFields = [];
    this.isValid = false;
    this.isError = '';
    this.showLoder = false;
    this.notificationData = {
      message: '',
      link: '',
      type: ''
    };

    this._handleApproval = this._handleApproval.bind(this);
    this._handleReject = this._handleReject.bind(this);
    this._closeModal = this._closeModal.bind(this);
    this._approveConfirm = this._approveConfirm.bind(this);
    this._handleOnSubmit = this._handleOnSubmit.bind(this);
    this._handleChange = this._handleChange.bind(this);
    this._scrollToTop = this._scrollToTop.bind(this);
    this._handleCampaignDetail = this._handleCampaignDetail.bind(this);
    this._handleQuestionsAnswers = this._handleQuestionsAnswers.bind(this);
    this._handleAdditionalConsent = this._handleAdditionalConsent.bind(this);
  }

  _approveConfirm() {
    const { campaignDetails, campaign } = this.props;
    let isCampaignIncentive =
      campaign && campaign.campaignType === 'Incentive' ? true : false;
    const isMDActionRequired = isMDRequiredForQnA(campaign);
    let data = {
      uid: this.campaignId,
      status:
        isCampaignIncentive &&
        (campaignDetails.incentiveItPartner === 'other' ||
          campaign.incentivePromotionalPartner === 'other') &&
        !isMDActionRequired
          ? constant.campaignStatus.COMPLETED
          : constant.campaignStatus.WAITING_ID,
      userName: this.props.userName,
      userEmail: this.props.userEmail,
      requestIncentiveOther:
        isCampaignIncentive &&
        (campaignDetails.incentiveItPartner === 'other' ||
          campaign.incentivePromotionalPartner === 'other')
          ? true
          : false
    };
    this._closeModal();

    this.props.approveCampaign(data).then(response => {
      this.notificationData.message = 'has been approved.';
      this.notificationData.link =
        '/campaign-wizard/campaign/' + this.props.campaign.uid;
      this.notificationData.type = 'success';
      this.notificationData.campaign = this.props.campaign.name;
      this.props.setNotificationMessage(
        this.notificationData.message,
        this.notificationData.link,
        this.notificationData.type,
        this.notificationData.campaign
      );
      this.props.history.push('/campaign-wizard/dashboard');
    });
  }

  _handleChange(e) {
    this.formElements[e.target.name] = e.target.value;
    this._validateField(e.target.name);
    this.setState({ [e.target.name]: e.target.value });
  }

  _handleCampaignDetail() {
    this.props.setApproverEditCampaignStatus(true);
    this.props.history.push({
      pathname: '/campaign-wizard/campaign/' + this.campaignId + '/details',
      state: { isEdited: true }
    });
  }

  _handleQuestionsAnswers() {
    this.props.setApproverEditCampaignStatus(true);
    this.props.history.push({
      pathname:
        '/campaign-wizard/campaign/' + this.campaignId + '/questionsanswers',
      state: { isEdited: true }
    });
  }
  _handleAdditionalConsent() {
    this.props.setApproverEditCampaignStatus(true);
    this.props.history.push({
      pathname:
        '/campaign-wizard/campaign/' + this.campaignId + '/consentpreferences',
      state: { isEdited: true }
    });
  }

  _validateField(field) {
    if (
      document
        .querySelector("[name='" + field + "']")
        .parentElement.classList.contains('error')
    ) {
      document
        .querySelector("[name='" + field + "']")
        .parentElement.classList.remove('error');
    }
    if (
      !document
        .querySelector("[data-control='" + field + "']")
        .classList.contains('cw-hidden')
    ) {
      document
        .querySelector("[data-control='" + field + "']")
        .classList.add('cw-hidden');
    }
  }
  _validateForm(event) {
    let formElements = this.formElements;
    for (var key in formElements) {
      if (formElements.hasOwnProperty(key)) {
        let selector = document.querySelector("[name='" + key + "']");
        if (selector) {
          if (formElements[key] !== '') {
            if (selector.parentElement.classList.contains('error')) {
              selector.parentElement.classList.remove('error');
            }
            if (document.querySelector("[data-control='" + key + "']")) {
              if (
                !document
                  .querySelector("[data-control='" + key + "']")
                  .classList.contains('cw-hidden')
              ) {
                document
                  .querySelector("[data-control='" + key + "']")
                  .classList.add('cw-hidden');
              }
            }
            this.errorFields = this._filterErrorFields(this.errorFields, key);
          } else {
            if (!selector.parentElement.classList.contains('error')) {
              selector.parentElement.classList.add('error');
            }
            if (document.querySelector("[data-control='" + key + "']")) {
              if (
                document
                  .querySelector("[data-control='" + key + "']")
                  .classList.contains('cw-hidden')
              ) {
                document
                  .querySelector("[data-control='" + key + "']")
                  .classList.remove('cw-hidden');
              }
            }
            this.errorFields = this._addErrorFields(this.errorFields, key);
          }
        }
      }
    }
    if (this.errorFields.length === 0) {
      this.isValid = true;
    }
  }

  _addErrorFields(array, itemToAdd) {
    return array.filter(field => field !== itemToAdd).concat([itemToAdd]);
  }
  _filterErrorFields(array, itemToRemove) {
    return array.filter(item => item !== itemToRemove);
  }

  _closeModal() {
    this.props.setModalStatus(false);
  }
  //TODO: FAILURES MESSAGES TO Be SHOWN IN NOFTIFICATION.
  _handleOnSubmit(e) {
    this._validateForm(e);
    e.preventDefault();

    if (this.isValid) {
      this.isError = false;
      let data = {
        uid: this.campaignId,
        status: constant.campaignStatus.REJECTED,
        rejectComments: this.state.description,
        userName: this.props.userName,
        userEmail: this.props.userEmail
      };

      this._closeModal();

      this.props.rejectCampaign(data).then(response => {
        this.notificationData.message = 'has been rejected.';
        this.notificationData.link =
          '/campaign-wizard/campaign/' + this.props.campaign.uid;
        this.notificationData.type = 'warning';
        this.notificationData.campaign = this.props.campaign.name;
        this.props.setNotificationMessage(
          this.notificationData.message,
          this.notificationData.link,
          this.notificationData.type,
          this.notificationData.campaign
        );
        this.props.history.push('/campaign-wizard/dashboard');
      });
    } else {
      this.isError = true;
    }
  }
  _handleApproval(e) {
    e.preventDefault();
    let actionType = e.currentTarget.getAttribute('data-action');
    this.props.setModalStatus(true, actionType);
  }
  _handleReject(e) {
    e.preventDefault();
    let actionType = e.currentTarget.getAttribute('data-action');
    this.props.setModalStatus(true, actionType);
  }
  componentDidMount() {
    this.campaignId = this.props.match.params.id;
    this.props.fetchCampaignData(this.campaignId);
    this.props.fetchCampaignEditLogs(this.campaignId);
    this.props.fetchHistoryLogs(this.campaignId);
    this.props.getBrandCountries(this.campaignId);
  }
  static getDerivedStateFromProps(props, state) {
    if (props.redirect) {
      props.history.push('/campaign-wizard/404');
    }

    if (props.dataUpdated) {
      //props.history.push('/campaign-wizard/dashboard');
    }

    return null;
  }

  _scrollToTop() {
    //this.screenTop.current.scrollIntoView({ behavior: 'smooth' });
    window.scroll({
      top: -100,
      left: 0,
      behavior: 'smooth'
    });
  }

  _getApproverName(campaign, campaignHistory = []) {
    let approverEmail = get(campaign, 'approversEmail');
    let mailList = get(campaign, 'approversMailingList.mailingList', []);
    let approverMail = mailList.find(item => item.email === approverEmail);
    if (approverMail) {
      return approverMail.name;
    } else {
      // extract from the campaign
      let historyLog = Array.isArray(campaignHistory)
        ? campaignHistory.find(elem => elem.event === 'Campaign approved')
        : {};
      return get(historyLog, 'userName', 'Unknown');
    }
  }

  renderCta() {
    return (
      <div className="cw-form--action-cta mt-40  mb-40">
        <button
          type="button"
          className="btn btn btn-outline-secondary mr-3"
          data-action="reject"
          onClick={this._handleReject}
        >
          Reject
        </button>
        <button
          type="button"
          className="btn btn-primary"
          data-action="approved"
          onClick={this._handleApproval}
        >
          Approve
        </button>
      </div>
    );
  }

  renderNotification() {
    if (this.props.approverCampaignUpdated) {
      let campaignUpdationMessage = Object.freeze({
        success: 'updated',
        warning: 'cancelled'
      });
      let message = campaignUpdationMessage[this.props.approverCampaignUpdated];
      return (
        <div
          className={`alert ${
            this.props.approverCampaignUpdated
              ? 'alert-' + this.props.approverCampaignUpdated
              : 'd-none'
          }`}
          role="alert"
        >
          <strong>{this.props.campaign.name}</strong>
          <span className="ml-1">has been {message}.</span>
        </div>
      );
    }
  }

  _handleRefresh = () => {
    let { campaign } = this.props;
    axios
      .post(constant.serviceUrls.CAMPAIGN_INFRA_API_CALL, campaign)
      .then(response => {
        this.campaignId = this.props.match.params.id;
        this.props.fetchCampaignData(this.campaignId);
      });
  };

  render() {
    Modal.setAppElement('#root');

    let {
      campaign,
      campaignDetails,
      questionAnswerList,
      asyncInProgress,
      modalIsOpen,
      activeModal,
      approved,
      approvalErrorMessage,
      campaignEditLog,
      country
    } = this.props;

    let autogenerateSignupInAEM = campaign.websiteType
      ? campaign.websiteType.name === 'Standalone' ||
        campaign.websiteType.name === 'Adobe Classic' ||
        campaign.websiteType.name === 'Adobe Templated'
      : false;

    let isCampaignTypeSASS =
      (campaign && campaign.isPlatformAgnostic) ||
      (campaign &&
        campaign.websiteType &&
        campaign.websiteType.name === 'Adobe SaaS') ||
      (campaign && campaign.isMasterData);

    let isEdited = campaign && campaign.isEdited;
    let campaignLanguage =
      campaign && campaign.language && campaign.language.name;
    let campaignLanguageCode =
      campaign && campaign.language && campaign.language.code;
    let isCampaignNonEnglish = checkCampaignLanguage(campaignLanguage);
    let isCampaignIncentive =
      campaign && campaign.campaignType === 'Incentive' ? true : false;
    let showApprovalButton =
      this.approved === '' &&
      this.status &&
      this.status === constant.campaignStatus.WAITING_APPROVAL.toString();
    let approvalDate = this.props.campaign.approvalDate;
    let statusClassName = classNames({
      'form-control mb-40': !this.isValid,
      'form-control mb-30': this.isError
    });
    let countrySelected = campaign && campaign.country && campaign.country.code;
    let isEpsilonMarket =
      countrySelected && epsilonMarket(countrySelected) ? true : false;
    let statusClass = classNames(statusBadge(this.props.campaign));
    let getCampStatus = getCampaignStatusText(this.props.campaign);
    let isCampaignAEMType = isAEMTypeCampaign(campaign);
    let isPromotigoType =
      isCampaignIncentive && isCampaignAEMType ? true : false;
    let cwStatus = getCampaignStatus(campaign.status);
    let additionalConsentList = additionalConsent => {
      let consentItems = [];

      if (additionalConsent && additionalConsent.length) {
        additionalConsent.forEach((additionaConsent, index) => {
          if (additionaConsent.text) {
            consentItems.push(
              <div key={index} className="cw-striped-row">
                <div className="row">
                  <div className="col-sm-4">
                    <strong>{'Additional Consent ' + (index + 1)}</strong>
                  </div>
                  <div className="col-sm-8">
                    <p>{additionaConsent.text}</p>
                  </div>
                </div>
              </div>
            );
          }
        });
      }
      return consentItems;
    };

    //redirect url
    let marketCode = country && country.value.split('-')[0];
    let allowedMarketsForDoubleOptin = constant.allowedMarketsForDoubleOptin;
    let isMarketSupported = checkMarketSupportedForDoubleOptin(
      marketCode,
      allowedMarketsForDoubleOptin
    );
    let newMasterData = isNew => {
      return isNew ? (
        <>
          <div
            style={{ display: 'block', color: '#E9A40A', whiteSpace: 'pre' }}
            className="col-sm-4"
          >
            <img src={groupIcon} alt={groupIcon} width="25" height="25"></img>
            &nbsp; New Master
          </div>
        </>
      ) : null;
    };

    Modal.setAppElement('#root');
    this.status = campaign && campaign.status ? campaign.status : '';

    this.approved = approved;
    let questionsAnswerListHtml;

    if (questionAnswerList.length > 0) {
      questionsAnswerListHtml = questionAnswerList.map((data, index) => {
        let isquestiontextUpdated =
          isEdited &&
          isCampaignEdited(
            'questionAnswerList',
            this.props.campaignEditLog,
            index,
            'text',
            data.text
          );
        return (
          <div className="col-sm-12">
            <div
              className="cw-campaign--review-section-content mb-4"
              key={index}
            >
              <div className="cw-question-answer-dotted-line">
                <div className="cw-heading-qna">
                  <span
                    className="float-left  fa fa-circle mt-10"
                    style={{ marginTop: '5px' }}
                  ></span>
                  <strong style={{ marginLeft: '15px' }}>Question</strong>
                </div>
                <div className="cw-striped-qna-row">
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="cw-qna-lable">
                        <strong>Question</strong>
                      </div>
                      <div
                        className={`${
                          isCampaignNonEnglish || data.type === 'Profile'
                            ? 'd-none'
                            : ''
                        }`}
                      >
                        <p className="xsmall-label">English</p>
                      </div>
                    </div>
                    <div className="col-sm-4">{data.text}</div>
                    {newMasterData(data.new)}
                  </div>
                </div>

                {!isCampaignNonEnglish &&
                data.type !== 'Profile' &&
                data.type !== 'File upload' &&
                data.type !== 'Unique entry code' ? (
                  <div className={`cw-striped-qna-row`}>
                    <div className="row">
                      <div className="col-sm-4">
                        <div className="cw-qna-lable">
                          <strong>Question</strong>
                        </div>
                        <div
                          className={`${isCampaignNonEnglish ? 'd-none' : ''}`}
                        >
                          <p className="xsmall-label">{campaignLanguage}</p>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <p>{data.translatedquestion}</p>
                      </div>
                      {newMasterData(data.translatednew)}
                    </div>
                  </div>
                ) : null}
                <div className="cw-striped-qna-row">
                  <div className="row">
                    <div className="col-sm-4 cw-qna-lable">Question Type</div>
                    <div className="col-sm-4">{data.type}</div>
                  </div>
                </div>
              </div>
              <br />
              {data.type !== 'Profile' &&
              data.type !== 'File upload' &&
              data.type !== 'Unique entry code' ? (
                <>
                  {data.answers && data.answers.length > 0 ? (
                    <div className="cw-question-answer-dotted-line">
                      <div className="cw-heading-qna">
                        <span
                          className="float-left  fa fa-circle mt-10"
                          style={{ marginTop: '5px' }}
                        ></span>
                        <strong style={{ marginLeft: '15px' }}>Answer</strong>
                      </div>

                      {data.answers.length &&
                        data.answers.map((answersdata, answerIndex) => {
                          //if (answersdata.label) {
                          return (
                            <Fragment key={'approval_answer_' + answerIndex}>
                              <div className="cw-striped-qna-row">
                                <div className="row">
                                  <div className="col-sm-4">
                                    <div className="cw-qna-lable">
                                      <strong>
                                        Answer{' '}
                                        {!isCampaignNonEnglish
                                          ? answerIndex + 1
                                          : ''}
                                      </strong>
                                    </div>
                                    <div
                                      className={`${
                                        isCampaignNonEnglish ? 'd-none' : ''
                                      }`}
                                    >
                                      <p className="xsmall-label">English</p>
                                    </div>
                                  </div>
                                  <div className="col-sm-4">
                                    {answersdata.label}
                                  </div>
                                  {newMasterData(
                                    answersdata.new || answersdata.translatednew
                                  )}
                                </div>
                              </div>
                              {!isCampaignNonEnglish ? (
                                <div className="cw-striped-qna-row">
                                  <div className="row">
                                    <div className="col-sm-4">
                                      <div className="cw-qna-lable">
                                        <strong>
                                          Answer{' '}
                                          {!isCampaignNonEnglish
                                            ? answerIndex + 1
                                            : ''}
                                        </strong>
                                      </div>
                                      <div
                                        className={`${
                                          isCampaignNonEnglish ? 'd-none' : ''
                                        }`}
                                      >
                                        <p className="xsmall-label">
                                          {campaignLanguage}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="col-sm-4">
                                      <p>{answersdata.translatedanswer}</p>
                                    </div>
                                    {newMasterData(answersdata.translatednew)}
                                  </div>
                                </div>
                              ) : null}
                            </Fragment>
                          );
                          // } else {
                          //   return <span key={"approval_answer_"+answerIndex}></span>;
                          // }
                        })}
                    </div>
                  ) : (
                    ''
                  )}
                </>
              ) : (
                ''
              )}
              <br />
              <div className="cw-question-answer-dotted-line">
                <div className="cw-heading-qna">
                  <span
                    className="float-left  fa fa-circle mt-10"
                    style={{ marginTop: '5px' }}
                  ></span>
                  <strong style={{ marginLeft: '15px' }}>
                    Other Configurations
                  </strong>
                </div>

                {data.type === 'File upload' ? (
                  <>
                    <div className="cw-striped-qna-row">
                      <div className="row">
                        <div className="col-sm-4 cw-qna-lable">
                          Placeholder text
                        </div>
                        <div className="col-sm-8">{data.placeholderText}</div>
                      </div>
                    </div>
                    <div className="cw-striped-qna-row">
                      <div className="row">
                        <div className="col-sm-4 cw-qna-lable">
                          Default display text
                        </div>
                        <div className="col-sm-8">
                          {data.defaultDisplayText}
                        </div>
                      </div>
                    </div>
                    <div className="cw-striped-qna-row">
                      <div className="row">
                        <div className="col-sm-4 cw-qna-lable">
                          Upload restrictions
                        </div>
                        <div className="col-sm-8">
                          {isRestrictionAllowed(data.isRestrictionAllowed)}
                        </div>
                      </div>
                    </div>
                    {data.isRestrictionAllowed ? (
                      <div className="cw-striped-qna-row">
                        <div className="row">
                          <div className="col-sm-4 cw-qna-lable">File size</div>
                          <div className="col-sm-8">{data.maxFileSize} KB</div>
                        </div>
                      </div>
                    ) : null}
                  </>
                ) : null}
                <div className="cw-striped-qna-row">
                  <div className="row">
                    <div className="col-sm-4 cw-qna-lable">Mandatory</div>
                    <div className="col-sm-8">
                      {isQuestionMandatory(data.mandatory)}
                    </div>
                  </div>
                </div>
                {isPromotigoType &&
                (data.type === 'Single choice' || data.type === 'Free text') ? (
                  <div className="cw-striped-qna-row">
                    <div className="row">
                      <div className="col-sm-4 cw-qna-lable">Qualifying</div>
                      <div className="col-sm-8">
                        {isQuesQualifying(data.isQualifying)}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        );
      });
    }

    let approverName = this._getApproverName(campaign, this.props.historyLogs);
    return (
      <>
        {asyncInProgress ? <Loader /> : null}
        <div className="col-sm-12">
          {/* Campaign Approval Page */}
          <div className="cw-section">
            {/* Placeholder - Campaign Approval Message */}
            <div className="row mb-30">
              <div className="col-sm-6">
                <NavLink
                  className="cw-color--primary cw-breadcrum"
                  to={'/campaign-wizard/dashboard'}
                >
                  <i className="fas fa-angle-left"></i> Campaigns
                </NavLink>
              </div>
            </div>
            <Modal
              isOpen={modalIsOpen && activeModal === 'approved'}
              onRequestClose={this._closeModal}
              className="cw-modal cw-modal--approve"
              contentLabel="Approval"
              style={{
                overlay: {
                  backgroundColor: constant.EMAIL_OVERLAY_BACKGROUND
                }
              }}
            >
              <button
                className="cw-modal--close"
                title="Close"
                onClick={this._closeModal}
              >
                <i className="fas fa-times"></i>
              </button>
              <h3 className="cw-heading--primary mb-10">Confirm approval</h3>
              <p className="mb-20">
                Please confirm you want to approve this campaign.
              </p>
              <div>
                <button
                  type="submit"
                  onClick={this._closeModal}
                  className="btn btn-outline-primary mr-10"
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary"
                  onClick={this._approveConfirm}
                >
                  Confirm
                </button>
              </div>
            </Modal>
            <Modal
              isOpen={modalIsOpen && activeModal === 'reject'}
              onRequestClose={this._closeModal}
              className="cw-modal cw-modal--reject"
              contentLabel="Rejection Modal"
              style={{
                overlay: {
                  backgroundColor: constant.EMAIL_OVERLAY_BACKGROUND
                }
              }}
            >
              <button
                className="cw-modal--close"
                title="Close"
                onClick={this._closeModal}
              >
                <i className="fas fa-times"></i>
              </button>
              <h3 className="cw-heading--primary mb-10">Confirm rejection</h3>
              <p className="mb-20">
                Please confirm and provide a reason why you have rejected this
                campaign.
              </p>

              {/* rejection textarea */}
              <div>
                <label htmlFor="description">
                  <span>Reason for rejection</span>
                </label>
                <textarea
                  className={statusClassName}
                  name="description"
                  id="description"
                  onChange={this._handleChange}
                  rows="3"
                ></textarea>
                <span
                  className="cw-error cw-hidden mb-10"
                  data-control="description"
                >
                  <i className="fas fa-exclamation-triangle mr-10"></i>Please
                  enter rejection reason.
                </span>
              </div>

              <div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-outline-secondary"
                    data-dismiss="modal"
                    onClick={this._closeModal}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={this._handleOnSubmit}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </Modal>

            <div className="row">
              <div className="col-sm-10 offset-md-1 cw-section--title">
                {/* Campaign Information */}

                {this.renderNotification()}

                {/* */}
                <Alert
                  type={'warning'}
                  alertType={'cancelcampaign'}
                  campaignName={this.props.campaign && this.props.campaign.name}
                  message={'has been cancelled. No action required.'}
                  showComponent={
                    this.props.campaign &&
                    this.props.campaign.status &
                      (this.props.campaign.status ===
                        constant.campaignStatus.CANCELLED)
                  }
                />
                <Alert
                  type={'warning'}
                  alertType={'campaignEdited'}
                  message={
                    'This campaign has been modified by the requester, see changes highlighted in yellow.'
                  }
                  showComponent={false}
                />
                {/* Alert for failure of campaign page generation*/}
                <Alert
                  alertType={'nonPublished'}
                  showComponent={
                    campaign.status === constant.campaignStatus.PROCESSING &&
                    campaign &&
                    campaign.aemError &&
                    campaign.aemError.code !== '404' &&
                    campaign.aemError.code !== '412'
                  }
                />
                <Alert
                  alertType={'error404NonPublished'}
                  showComponent={
                    campaign.status === constant.campaignStatus.PROCESSING &&
                    campaign &&
                    campaign.aemError &&
                    campaign.aemError.code === '404'
                  }
                />
                <Alert
                  alertType={'isPromotigoAC'}
                  showComponent={
                    campaign.status === constant.campaignStatus.PROCESSING &&
                    campaign &&
                    campaign.aemError &&
                    campaign.aemError.code === '412'
                  }
                />
                {approvalDate &&
                this.status !=
                  constant.campaignStatus.WAITING_APPROVAL.toString() ? (
                  <div class="row">
                    <div class="col-sm-8 ">
                      <NotificationMessage
                        showcomponent={true}
                        message={`This campaign was approved by ${approverName} on ${getNewFormatedDate(
                          approvalDate,
                          'DD-MMM-YYYY  - HH:mm'
                        )} UTC`}
                        type="success"
                      />
                    </div>
                  </div>
                ) : null}
                <h2 className="cw-heading--secondary align-items-center mb-10">
                  <span className="mr-3 campaign-break-word">
                    {campaign ? campaign.name : ''}
                  </span>
                  <span
                    className={`${statusClass} status_badge_reposition_approval`}
                  >
                    {getCampStatus}
                  </span>
                </h2>
                <ApproverAssignmentInfo
                  campaign={campaign}
                  userData={this.props.authorizedUserData}
                />
                {this.status !== constant.campaignStatus.WAITING_ID &&
                this.status !== constant.campaignStatus.REJECTED ? (
                  <p>
                    Review the campaign and either approve or reject the
                    request.{' '}
                  </p>
                ) : null}
              </div>
            </div>
            <div className="row">
              <div class="col-10 offset-md-1">
                <div className="cw-section--content">
                  <div className="cw-campaign--review mt-20">
                    {!constant.featureFlags.ENABLE_GUIDELINES &&
                      isCampaignTypeSASS &&
                      campaign &&
                      !campaign.isMasterData && (
                        <div className="d-flex justify-content-end mb-15">
                          <div>
                            <a
                              className="btn btn-primary preview-form-btn"
                              href={this.props.previewUrl}
                              target={'_blank'}
                            >
                              Preview Form
                            </a>
                          </div>
                        </div>
                      )}
                    {constant.featureFlags.ENABLE_GUIDELINES ? (
                      <GuideLinesAccordian
                        data={constant.APPROVAL_GUIDE_LINES}
                        showMasterIcon={showMasterDataIcon(campaign)}
                        campaignLanguageCode={campaignLanguageCode}
                        campaignLanguage={campaignLanguage}
                        previewUrl={this.props.previewUrl}
                        showPreviewFormCta={
                          isCampaignTypeSASS &&
                          campaign &&
                          !campaign.isMasterData
                        }
                      />
                    ) : null}
                    {/* Brand Details */}
                    <div className="cw-campaign--review-section cw-hidden">
                      {isCampaignTypeSASS ? (
                        <Alert
                          alertType="newSaasPreviewFormButton"
                          previewUrl={this.props.previewUrl}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                    <div className="cw-campaign--review-section">
                      <div className="mb-3">
                        {isCampaignTypeSASS &&
                        getCampaignStatus === 'Completed' ? (
                          <SummaryComponent
                            data={this.props.campaign}
                            isBrandSummary={false}
                            cwStatus={this.props.cwStatus}
                            previewUrl={this.props.previewUrl}
                            isCampaignTypeSASS={this.props.isCampaignTypeSASS}
                            handleRefresh={this._handleRefresh}
                            campaignConfig={this.props.campaignConfig}
                          />
                        ) : null}

                        <SummaryComponent
                          data={this.props.campaign}
                          isBrandSummary={true}
                          cwStatus={this.props.cwStatus}
                          isCampaignTypeSASS={this.props.isCampaignTypeSASS}
                          handleRefresh={this._handleRefresh}
                          campaignConfig={this.props.campaignConfig}
                        />
                      </div>
                    </div>

                    {/* Campaign Details */}
                    <CampaignDetailsInfo
                      isCampaignTypeSASS={isCampaignTypeSASS}
                      previewUrl={this.props.previewUrl}
                      cwStatus={cwStatus}
                      campaign={campaign}
                      campaignDetails={campaignDetails}
                      panelref={this.collapsePanel}
                      collapseHandlerIconref={this.collapseHandlerIcon}
                      collapseHandlerTextref={this.collapseHandlerText}
                      handleCampaignDetail={this._handleCampaignDetail}
                      isEditButton={true}
                      isConsentAndCompliance={true}
                      isCampaignType={false}
                      isCampaignAuthoring={false}
                      handleRefresh={this._handleRefresh}
                      campaignConfig={this.props.campaignConfig}
                    />

                    {isCampaignTypeSASS &&
                    constant.featureFlags.ENABLE_SINGLE_PAGE_FIELD_SELECTION ? (
                      <FormAuthoringGrouped
                        ishideQnA={
                          campaign.type !== 'Sign-up with Questions & Answers'
                        }
                        isEditButton={showApprovalButton}
                        campaignId={this.props.match.params.id}
                        consentPage={'/saas-consent-compliance'}
                        qnaPage={'/saasqna'}
                        profilePage={'/saas-profile-details'}
                        genericPage={'/saas-generic-details'}
                        showMasterIcon={true}
                        colWidth={`col-sm-4`}
                        accordianClass={`col-sm-8`}
                        newIconClass={`col-sm-3`}
                        isMasterData={campaign && campaign.isMasterData}
                        campaignConfig={this.props.campaignConfig}
                      />
                    ) : (
                      ''
                    )}
                    {isCampaignTypeSASS &&
                    !constant.featureFlags
                      .ENABLE_SINGLE_PAGE_FIELD_SELECTION ? (
                      <FormAuthoring
                        ishideQnA={
                          campaign.type !== 'Sign-up with Questions & Answers'
                        }
                        isEditButton={showApprovalButton}
                        campaignId={this.props.match.params.id}
                        consentPage={'/saas-consent-compliance'}
                        qnaPage={'/saasqna'}
                        profilePage={'/saas-profile-details'}
                        genericPage={'/saas-generic-details'}
                        showMasterIcon={true}
                        colWidth={`col-sm-4`}
                        accordianClass={`col-sm-8`}
                        newIconClass={`col-sm-3`}
                      />
                    ) : (
                      ''
                    )}

                    {!isCampaignIncentive && !isCampaignTypeSASS ? (
                      <RegularCommunications
                        campaign={campaign}
                        isEdited={isEdited}
                        campaignEditLog={campaignEditLog}
                        campaignDetails={campaignDetails}
                        colLabelClass="col-sm-4"
                        colValClass="col-sm-8"
                        disabledFlag={false}
                        draftFlag={false}
                        optins={this.props.optins}
                        optinsDescription={this.state.optinsDescription}
                        showEditButton={
                          this.status &&
                          this.status ===
                            constant.campaignStatus.WAITING_APPROVAL.toString()
                            ? true
                            : false
                        }
                        buttonClickEvent={this._handleAdditionalConsent}
                      ></RegularCommunications>
                    ) : null}
                    {/* Addtional Consent and Channel*/}
                    {/* Questions & answers */}
                    {questionAnswerList.length &&
                    !isCampaignTypeSASS &&
                    campaign.type === 'Sign-up with Questions & Answers' ? (
                      <div className="cw-campaign--review-section">
                        {/* <div className="cw-campaign--review-section-header mb-3 d-flex align-items-center clearfix">
                          <h3 className="cw-heading--senary">
                            Questions &amp; Answers
                          </h3>
                          <button
                            className={`btn btn-outline-secondary ml-auto ${this.status &&
                              this.status ===
                              constant.campaignStatus.WAITING_APPROVAL.toString()
                              ? ""
                              : "d-none"
                              }`}
                            onClick={this._handleQuestionsAnswers}
                          >
                            Edit
                          </button>
                        </div>
                        {questionsAnswerListHtml} */}
                        <QnAList
                          campaignLanguage={campaignLanguage}
                          handleQuestionsAnswers={this._handleQuestionsAnswers}
                          isCampaignNonEnglish={isCampaignNonEnglish}
                          campaignId={this.campaignId}
                          qnaList={questionAnswerList}
                          showQnaList={
                            campaign.type ===
                              'Sign-up with Questions & Answers' &&
                            !isCampaignTypeSASS
                          }
                          isPromotigoType={isPromotigoType}
                          colWidth={`col-sm-4`}
                          showAwaitingQid={false}
                          isEditButtonShow={
                            this.status &&
                            this.status ===
                              constant.campaignStatus.WAITING_APPROVAL.toString()
                          }
                          showTextBox={false}
                          accordianClass={`col-sm-8`}
                          newIconClass={`col-sm-3`}
                          campaign={campaign}
                          campaignDetails={campaignDetails}
                        />
                      </div>
                    ) : null}
                    {/* Additional Information */}

                    {/* CTA */}

                    {showApprovalButton ? this.renderCta() : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-10 offset-md-1">
          <div className="overview-page-history">
            {this.props.historyLogs.length ? (
              <HistoryComponent historyLogs={this.props.historyLogs} />
            ) : null}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  campaign: state.approval.campaign,
  campaignDetails: state.approval.campaignDetails,
  questionAnswerList: state.approval.questionAnswerList,
  asyncInProgress: state.approval.asyncInProgress
    ? state.approval.asyncInProgress
    : '',
  modalIsOpen: state.app.modalIsOpen,
  activeModal: state.app.activeModal,
  approved: state.approval.approved,
  redirect: state.approval.redirect,
  approvalErrorMessage: state.approval.approvalErrorMessage,
  approvalMsg: state.approval.approvalMsg,
  approverCampaignUpdated: state.app.approverCampaignUpdated,
  historyLogs: state.historyLogs,
  campaignEditLog: state.approval.campaignEditLog,
  country: state.brandDetails.country,
  previewUrl: state.preview.previewUrl,
  isLoading: state.preview.isLoading,
  notificationMessage: state.dashboard.notificationMessage,
  notificationLink: state.dashboard.notificationLink,
  notificationType: state.dashboard.notificationType,
  notificationCampaign: state.dashboard.notificationCampaign,
  campaignConfig: state.app.campaignConfig
});

export default connect(mapStateToProps, {
  fetchCampaignData,
  approveCampaign,
  rejectCampaign,
  setModalStatus,
  setNotificationMessage,
  setApproverEditCampaignStatus,
  fetchHistoryLogs,
  fetchCampaignEditLogs,
  getBrandCountries
})(Approval);
