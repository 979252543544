import * as yup from 'yup';
import { BeeRowTypeInput } from 'components/molecules/BeeRowTypeInput';
import { TemplateAttributes } from 'components/molecules/TemplateAttributes';
import slugify from 'slugify';
import { saveEmailCoreTemplate } from 'services/emailCoreTemplates';
import constant from '../../../constants';
import FormWarningsTemplateInput from 'components/molecules/FormWarningsInput/FormWarningsTemplate';
export default (
  { setIsLoading, handleCloseForm, userInfo, setSavedTemplate },
  templateInfo,
  emailBriefing,
  templateHtml
) => {
  return {
    formType: 'modal',
    submitBtnLabel: 'Save template',
    previousBtnLabel: 'Cancel',
    btnOrderReverse: true,
    disableFormError: true,
    fields: [
      {
        type: 'text',
        name: 'templateName',
        id: 'templateName',
        label: 'Template name',
        maxLength: '50',
        rules: yup
          .string()
          .trim()
          .max(50, 'Max 50 characters are allowed')
          .required('Please enter template name')
      },
      // {
      //   type: 'textarea',
      //   rows: 3,
      //   name: 'templateDescription',
      //   id: 'templateDescription',
      //   label: 'Template description',
      //   maxLength: '200',
      //   rules: yup
      //     .string()
      //     .trim()
      //     .max(200, 'Max 200 characters are allowed')
      // },
      {
        type: 'reactselect',
        name: 'templateType',
        id: 'templateType',
        label: 'Template type',
        placeholder: 'Please select',
        rules: yup
          .object()
          .shape({
            label: yup.string().required(),
            value: yup.string().required()
          })
          .required('Please select row category')
          .default(undefined),
        options: constant.EMAIL_TYPE_OPTIONS
      },
      {
        type: 'custom',
        name: 'templateUsage',
        component: BeeRowTypeInput,
        id: 'templateUsage',
        label: 'Template context',
        rules: yup.object({
          type: yup
            .string()
            .required('Please select template usage')
            .typeError('Please select template usage'),
          country: yup.object(),
          brand: yup.object().when('type', {
            is: 'brandSpecific',
            then: yup
              .object()
              .shape({ value: yup.string(), label: yup.string() })
          }),
          crossBrand: yup.array().when('type', {
            is: 'crossBrand',
            then: yup
              .array()
              .of(
                yup.object().shape({ value: yup.string(), label: yup.string() })
              )
              .min(1, 'Please select at least one brand')
          })
        }),
        emailBriefing,
        userInfo
      },
      {
        type: 'custom',
        component: FormWarningsTemplateInput,
        watchInput: [
          'templateUsage.type',
          'templateUsage.brand',
          'templateUsage.country',
        ]
      },
      {
        type: 'custom',
        name: 'templateAttributes',
        id: 'templateAttributes',
        label: 'Select template attributes',
        component: TemplateAttributes,
        watchInput: 'templateAttributes.emailCoupon',
        rules: yup.object({
          emailCoupon: yup.bool(),
          emailCouponType: yup
            .string()
            .when("emailCoupon", {
              is: (data) => !!data,
              then: (schema) => schema.required("Please select coupon type"),
            })
          .nullable()
        }),
        emailBriefing
      },
    ],
    onSubmit: (data, { user, handleSetFormErrorMessage }) => {
      let templatePayload = {
        templateType: 'custom',
        name: slugify(data.templateName, { lower: true }),
        title: data.templateName,
        category: data.templateType.value,
        createdBy: {
          emailId: user.emailId,
          name: user.name
        },
        templateObject: templateInfo,
        description: data.templateDescription,
        templateUsage: data.templateUsage.type,
        brand:
          data.templateUsage.type === 'generic'
            ? null
            : data.templateUsage.type === 'brandSpecific'
            ? data.templateUsage.brand
            : data.templateUsage.crossBrand,
        country:
          data.templateUsage.type === 'generic'
            ? null
            : data.templateUsage.country,
        templateHtml: templateHtml,
        templateAttributes: data.templateAttributes,
        uid: emailBriefing.uid
      };
      setIsLoading(true);
      saveEmailCoreTemplate(templatePayload)
        .then(result => {
          if (data) {
            // handleCloseForm();
            setSavedTemplate(data.templateName)
          }
        })
        .catch(err => {
          if (err.response && err.response.data) {
            handleSetFormErrorMessage(err.response.data.message, {
              scrollToTop: true
            });
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    onPrevious: () => {
      handleCloseForm();
    }
  };
};
