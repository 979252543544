import axios from 'axios';
import constant from '../../constants';
import { GET_SMS_REVIEW_DETAIL } from '../../actionTypes';
import _ from 'lodash';

export const getSmsCampaignDetails = uid => {
  return async dispatch => {
    const response = await axios.get(
      constant.communications.GET_SMS_REVIEW_DETAIL + '/' + uid
    );
    dispatch({
      type: GET_SMS_REVIEW_DETAIL,
      payload: response.data
    });
  };
};
