import {
  SET_CAMPAIGN_RECORD_ID,
  SET_MODAL_STATUS,
  SET_DELETED_CAMPAIGN,
  APPROVAL_EDIT_CAMPAIGN,
  SET_CAMPAIGN_UPDATION_SUCCESS,
  RESET_APP_PROPS,
  SET_EDITED_CAMPAIGNS_DATA,
  SET_APPROVAL_LAST_UPDATED_SCREEN,
  GET_CAMPAIGN_CONFIG,
  GET_ROUTINE_CONFIG,
  TOGGLE_SIDEBAR,
  CLOSE_SIDEBAR
} from '../actionTypes';

const initialState = {
  activeStep: 0,
  modalIsOpen: false,
  activeModal: '',
  campaignRecordId: '',
  deletedCampaign: null,
  approverEditCampaign: false,
  approverCampaignUpdated: '',
  lastUpdatedScreen: '',
  editCampaignDetails: {},
  campaignConfig: {
    LABEL_SIGN_UP_ID: 'Sign up ID'
  },
  isSideBarOpen: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_CAMPAIGN_RECORD_ID: {
      let campaignRecordId = { campaignRecordId: action.id };
      return { ...state, ...campaignRecordId };
    }
    case SET_MODAL_STATUS: {
      let modalData = {
        modalIsOpen: action.modalIsOpen,
        activeModal: action.activeModal
      };
      return { ...state, ...modalData };
    }
    case SET_DELETED_CAMPAIGN: {
      let deletedCampaign = { deletedCampaign: action.deletedCampaign };
      return { ...state, ...deletedCampaign };
    }
    case 'APPROVAL_EDIT_CAMPAIGN': {
      let approverEditCampaign = {
        approverEditCampaign: action.approverEditCampaign
      };
      return { ...state, ...approverEditCampaign };
    }
    case 'SET_APPROVAL_CAMPAIGN_UPDATION_SUCCESS': {
      let approverCampaignUpdated = {
        approverCampaignUpdated: action.approverCampaignUpdated
      };
      return { ...state, ...approverCampaignUpdated };
    }
    case SET_APPROVAL_LAST_UPDATED_SCREEN: {
      let lastUpdatedScreen = {
        lastUpdatedScreen: action.lastUpdatedScreen
      };
      return { ...state, ...lastUpdatedScreen };
    }
    case 'RESET_APP_PROPS': {
      return {
        ...state,
        ...{ deletedCampaign: null },
        ...{ approverEditCampaign: null },
        ...{ approverCampaignUpdated: '' }
      };
    }
    case 'SET_EDITED_CAMPAIGNS_DATA': {
      let editCampaignDetails = {
        ...state.editCampaignDetails,
        ...action.campaignDetails
      };
      return { ...state, ...{ editCampaignDetails: editCampaignDetails } };
    }
    case GET_CAMPAIGN_CONFIG: {
      return { ...state, campaignConfig: action.campaignConfig };
    }
    case GET_ROUTINE_CONFIG: {
      return { ...state, cwApiKey: action.routineResponse };
    }
    case TOGGLE_SIDEBAR: {
      return { ...state, isSideBarOpen: !state.isSideBarOpen };
    }
    case CLOSE_SIDEBAR: {
      return { ...state, isSideBarOpen: false };
    }
    default:
      return state;
  }
}
