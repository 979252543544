import React, { useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { getTemplateCount } from 'services/emailBriefings';
import { get } from 'lodash';

const FormWarningsTemplateInput = ({
  name,
  control,
  watchInput,
  errors,
  register,
  defaultValue,
  rules,
  trigger,
  setValue,
  formState
}) => {
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('warning');
  const values = useWatch({
      control,
      name: watchInput
    });
  const type = get(values, '[0]');
  const brand = get(values, '[1].value');
  const country = get(values, '[2].value');

  useEffect(() => {
    if (type !== 'brandSpecific') {
      setMessage('');
    }
  }, [type]);

  if (brand && country && type === 'brandSpecific' && !formState.isSubmitting) {
    getTemplateCount(type, brand, country)
      .then(result => {
        if (result.count > 1 || result.count === 1) {
          const categoryString =
            result.count === 1
              ? 'template'
              : 'templates';
          const secondaryText = result.count > 1? 'are' : 'is';
        const additionalText = result.count === result.max || result.count > result.max? `. Please delete an existing template to save a new one`: '';
          const messageString = `${result.count} ${categoryString} ${secondaryText} already saved for this brand-country combination. Max ${result.max} are allowed${additionalText}`;
          setMessage(messageString);
          if(result.count === result.max || result.count > result.max){
            setMessageType('danger');
          } else {
            setMessageType('warning');
          }
        } else {
          setMessage(`You have 0 templates saved for your brand market combination, Max ${result.max} are allowed`);
          setMessageType('warning');
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  return (
    <div>
      {message && (
        <div className={`alert alert-${messageType}`} role="alert">
          <strong>{message}</strong>
        </div>
      )}
    </div>
  );
};

export default FormWarningsTemplateInput;
