import React from 'react';
import constants from 'constants/config';
import GuidelineWrapper from './atoms/GuidelineWrapper/GuidelineWrapper';
import GuideLines from './GuideLines';

const ConsentGuidelines = ({ onClose }) => {
  return (
    <GuidelineWrapper onClose={onClose} isEmailCampaign={true}>
      <div className="qna-guideline-scroll free-height">
        <GuideLines
          data={constants.CONSENT_GUIDELINES}
          qnaIndex={true}
          noLineBreak={true}
        />
        <div className="horizontal-line"></div>
      </div>
    </GuidelineWrapper>
  );
};

export default ConsentGuidelines;
