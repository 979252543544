import React, { Component } from 'react';
import RequestComponent from './CWRequestComponent';
import Loader from './Loader';
import constant from '../constants';
const Homepage = props => {
  let smsOverviewStyle = {
    fontSize: '15px',
    width: '90%'
  };
  let formOverviewStyle = {
    fontSize: '15px'
  };
  return (
    <>
      <div className="col-sm-12">
        <div className="cw-section">
          <div className="cw-section--title mt-50">
            <h2 className="cw-heading--primary--homepage-heading mb-60 ">
              {constant.CW_HOMEPAGE_HEADING}
            </h2>
            <div className="cw-section--homepage-body">
              <div className="row">
                <div className="col-sm-6">
                  <RequestComponent
                    userRoles={props.userrole}
                    requestType={'Form Request'}
                    textStyle={formOverviewStyle}
                    overview={constant.CW_HOMEPAGE_FORM_OVERVIEW_TEXT}
                    destinationURL1={'/campaign-wizard/newcampaign'}
                    destinationURL2={'/campaign-wizard/dashboard'}
                    buttonTitle1={'Create form'}
                    buttonTitle2={'View Form Dashboard'}
                    showComponent={true}
                    showPrimaryButton={
                      props.userrole !== constant.roles.MASTERDATA &&
                      props.userrole !== constant.roles.GIGYA
                    }
                    showSecondaryButton={true}
                  />
                </div>
                {/* this section to be visible only to non-MD and non-Gigya */}
                <div className={`col-sm-6`}>
                  <RequestComponent
                    userRoles={props.userrole}
                    requestType={'SMS Campaign'}
                    textStyle={smsOverviewStyle}
                    overview={constant.CW_HOMEPAGE_SMS_OVERVIEW_TEXT}
                    destinationURL1={'/campaign-wizard/newcommunications'}
                    destinationURL2={'/campaign-wizard/smsdashboard'}
                    buttonTitle1={'Create SMS request'}
                    buttonTitle2={'View SMS Dashboard'}
                    showComponent={
                      props.userrole !== constant.roles.MASTERDATA &&
                      props.userrole !== constant.roles.GIGYA
                    }
                    showPrimaryButton={
                      props.userrole !== constant.roles.MASTERDATA &&
                      props.userrole !== constant.roles.GIGYA
                    }
                    showSecondaryButton={
                      props.userrole !== constant.roles.MASTERDATA &&
                      props.userrole !== constant.roles.GIGYA
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Homepage;
