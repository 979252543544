import React from 'react';

export default function AdditionalCommentsField({
  value = '',
  onChange,
  index
}) {
  return (
    <div className="form-group ml-3 cw-qna-lable-width">
      <label htmlFor={`additionalComments_${index}`}>
        <span>Detailed justification for new request</span>
      </label>
      <label className='mt-n2 email-type-desc-font'>
        Please provide detailed explanation for requesting a new master question, how will you use this data and why any of the existing master questions do not serve the purpose
      </label>
      <textarea
        className="form-control"
        name={`additionalComments_${index}`}
        id={`additionalComments_${index}`}
        aria-describedby={`additionalComments_${index}`}
        data-required="true"
        rows="3"
        maxLength="500"
        value={value}
       // placeholder="Please use this optionally to enter any comments about your new master data request"
        onChange={onChange}
      ></textarea>
      <span className="cw-error d-none"  id={`additionalComments_description_${index}`}>
        <i className="fas fa-exclamation-triangle mr-10"></i>
        Please enter the details.
      </span>
    </div>
  );
}
