import React, { useEffect } from 'react';
import { useWatch, Controller } from 'react-hook-form';
import ReactTooltip from 'react-tooltip';
import { Input } from '../../atoms/Input';
import { FieldError } from '../../atoms/FieldError';
import { CustomFormFieldArrayV3 } from '../FormFieldArray/custom-v3';
import { get, isEmpty } from 'lodash';

const getDefaultCollaborators = (defaultValue, user) => {
  const collaborators = get(defaultValue, `collaborators`, [
    {
      permissionGivenBy: user.emailId,
      value: '',
      permissionGivenAt: new Date()
    }
  ]);
  return !isEmpty(collaborators)
    ? collaborators
    : [
        {
          permissionGivenBy: user.emailId,
          value: '',
          permissionGivenAt: new Date()
        }
      ];
};

const CollaboratorsList = ({
  control,
  defaultValue,
  defaultValueToWatch,
  watchInput,
  errors,
  trigger,
  rules,
  name,
  user
}) => {
  const rowType = useWatch({
    control,
    name: watchInput,
    defaultValue: defaultValueToWatch
  });

  return rowType === 'requestorAndSelectedUsers' ? (
    <div className="ml-33 pt-0 cw-form-nested mb-20">
      <Controller
        name={`${name}.collaborators`}
        defaultValue={getDefaultCollaborators(defaultValue, user)}
        control={control}
        render={({ field }) => (
          <CustomFormFieldArrayV3
            {...field}
            label="Email address"
            type="text"
            errors={errors}
            //defaultValue={get(defaultValue, `${name}.collaborators`)}
            rules={rules}
            trigger={trigger}
            user={user}
          />
        )}
      />
    </div>
  ) : null;
};

const EmailCollaboratorsInput = ({
  name,
  register,
  label,
  errors,
  control,
  defaultValue,
  setValue,
  subLabel,
  ...props
}) => {
  useEffect(() => {
    const type = get(defaultValue, 'type', null);
    if (type) {
      setValue(`${name}.type`, type);
    } else {
      setValue(`${name}.type`, 'everyone');
    }
  }, [defaultValue]);
  return (
    <div className="form-group" aria-live="polite">
      <label htmlFor={props.id}>
        {label}
        <div className="description mb-20">
          {subLabel ? subLabel : `Before you proceed, please specify who should be allowed to
          collaborate on your campaign. Any other users will have view access
          only and will not be able to edit or delete your drafts.`}
        </div>
      </label>

      <div>
        <div className="form-check mb-10" aria-live="polite">
          <Input
            name={`${name}.type`}
            type="radio"
            {...(register && register(`${name}.type`))}
            id="everyone"
            value="everyone"
          />
          <label htmlFor="everyone" className="form-check-label">
            <p className={props.labelClass}>Everyone</p>
          </label>
        </div>
      </div>
      <div>
        <div className="form-check mb-10" aria-live="polite">
          <Input
            name={`${name}.type`}
            {...(register && register(`${name}.type`))}
            id="requestor"
            value="requestor"
            type="radio"
          />
          <label htmlFor="requestor" className="form-check-label">
            <p className={props.labelClass}>Requestor</p>
          </label>
        </div>
      </div>
      <div>
        <div className="form-check mb-10" aria-live="polite">
          <ReactTooltip
            place="right"
            id="emailcollaborartorTooltip"
            type="info"
            multiline={true}
            className="cw-tooltip cw-email-tooltip"
          />
          <Input
            name={`${name}.type`}
            {...(register && register(`${name}.type`))}
            id="requestorAndSelectedUsers"
            value="requestorAndSelectedUsers"
            type="radio"
          />
          <label
            htmlFor="requestorAndSelectedUsers"
            className="form-check-label"
          >
            <p className={props.labelClass}>
              Requestor and 5 additional users{' '}
              <span
                className="cw-icon--help"
                style={{cursor: 'pointer'}}
                data-for="emailcollaborartorTooltip"
                data-tip={
                  'Specified users will be collaborate on your campaign provided they have access to Campaign Wizard.'
                }
              >
                <i className="fas fa-question-circle"></i>
              </span>
            </p>

            <p className="email-text-font">
              Only unilever.com emails are allowed
            </p>
          </label>
        </div>
      </div>
      <CollaboratorsList
        control={control}
        watchInput={`${name}.type`}
        errors={errors}
        name={name}
        defaultValue={defaultValue}
        setValue={setValue}
        emailBriefing={props.emailBriefing}
        isInModal={props.isInModal}
        trigger={props.trigger}
        user={props.user}
      />
      <FieldError errors={errors} name={`${name}.type`} />
    </div>
  );
};

export default EmailCollaboratorsInput;
