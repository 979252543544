import {
  EMAIL_CAMPAIGN_DETAILS_ASYNC_IN_PROGRESS,
  SET_EMAIL_CAMPAIGN_DETAILS,
  DELETE_EMAIL_BRIEFING,
  DELETE_EMAIL_BRIEFING_SUCCESS,
  DELETE_EMAIL_BRIEFING_FAILURE,
  GET_EMAIL_CAMPAIGN,
  RESET_EMAIL_BRAND_DETAILS_PAGE,
  RESET_EMAIL_TYPE_PAGE
} from '../../actionTypes';
import {
  updateEmailBriefing,
  getEmailBriefingbyUid,
  deleteEmailBriefingbyUid,
  createEmailBriefing
} from 'services/emailBriefings';
import { transformEmailBriefingData, _generateUUID } from 'selectors';
import { setEmailBriefings, setEmailDesignLoader } from './emailDesign';
import { fetchHistoryLogs } from 'actions/history';
import { setNotificationMessage } from 'actions/dashboard';
import {get} from 'lodash';
import constants from '../../constants';

export const setEmailCampaignDetails = emailCampaignDetails => ({
  type: SET_EMAIL_CAMPAIGN_DETAILS,
  emailCampaignDetails
});

export const setEmailCampaignDetailsLoader = payload => ({
  type: EMAIL_CAMPAIGN_DETAILS_ASYNC_IN_PROGRESS,
  isLoading: payload
});

export const updateEmailCampaignDetails = (
  uid,
  data,
  callback,
  { isSaveAsDraft } = {}
) => {
  return async function(dispatch) {
    try {
      dispatch(setEmailCampaignDetailsLoader(true));
      let result;
      if (uid) {
        result = await updateEmailBriefing(uid, data);
      } else {
        uid = _generateUUID();
        result = await createEmailBriefing({ ...data, uid, requestersEmail:data.userEmail });
      }
      const transformedData = transformEmailBriefingData(
        result.data,
        'emailDetails'
      );
      dispatch(setEmailCampaignDetails({ ...transformedData, uid }));
      if (isSaveAsDraft) {
        let campaignName = get(result,'data.summary.campaignName');
        dispatch(
          setNotificationMessage(
            constants.CAMPAIGN_SAVED_AS_DRAFT_TEXT,
            `/campaign-wizard/emailbriefing/${uid}`,
            'success',
            campaignName,
            'emailCampaignDraft'
          )
        );
      }
      dispatch(setEmailBriefings(result.data));
      dispatch(fetchHistoryLogs(uid));
      dispatch({
        type: GET_EMAIL_CAMPAIGN,
        campaign: result.data,
        campaignLoaded: true
      });
      callback(null, result.data);
    } catch (error) {
      callback(error, null);
    } finally {
      dispatch({ type: RESET_EMAIL_BRAND_DETAILS_PAGE });
      dispatch({ type: RESET_EMAIL_TYPE_PAGE });
      dispatch(setEmailCampaignDetailsLoader(false));
    }
  };
};

export const getEmailCampaignDetails = (uid, options) => {
  return function(dispatch) {
    dispatch(setEmailCampaignDetailsLoader(true));
    dispatch(setEmailDesignLoader(true));
    getEmailBriefingbyUid(uid, options)
      .then(result => {
        let transformedData = transformEmailBriefingData(
          result.data,
          'emailDetails'
        );
        dispatch(setEmailDesignLoader(false));
        dispatch(setEmailBriefings(result.data));
        dispatch(setEmailCampaignDetailsLoader(false));
        dispatch(setEmailCampaignDetails(transformedData));
      })
      .catch(error => {
        dispatch(setEmailDesignLoader(false));
        dispatch(setEmailCampaignDetailsLoader(false));
        console.log('getEmailCampaignDetails error :', error);
      });
  };
};

export const deleteEmailCampaignDetails = (uid, callback) => {
  return async function(dispatch) {
    try {
      dispatch({ type: DELETE_EMAIL_BRIEFING });
      await deleteEmailBriefingbyUid(uid);
      callback();
      dispatch({
        type: DELETE_EMAIL_BRIEFING_SUCCESS,
        payload: uid
      });
    } catch (error) {
      dispatch({
        type: DELETE_EMAIL_BRIEFING_FAILURE,
        payload: uid
      });
    }
  };
};
