import React, { forwardRef } from 'react';
import classNames from 'classnames';

export default forwardRef(function PlusButton(
  { large, disabled, onClick },
  ref
) {
  return (
    <div
      ref={ref}
      className={classNames({
        'brand-color-add-button-large': large,
        'brand-color-add-button-disabled': disabled,
        'brand-color-add-button': !disabled
      })}
    >
      <button
        type="button"
        className={disabled ? 'square-button-disabled' : 'square-button'}
        onClick={onClick}
      >
        <i className="fas fa-plus"></i>
      </button>
    </div>
  );
});
