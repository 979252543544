import React from 'react';
import classNames from 'classnames';

const UnstyledButton = ({ className, onClick, children, hasCustomPadding }) => {
  return (
    <button
      className={classNames('cw-unstyle-btn',{'cw-unstyle-padding': !hasCustomPadding}, className)}
      type="button"
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export { UnstyledButton };
