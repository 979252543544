import React, { Component } from 'react';
import { connect } from 'react-redux';
import BreadCrumb from '../BreadCrumb';
import ReactTooltip from 'react-tooltip';
import Alert from '../Alert';
import Select from 'react-select';
import { scrollToTop } from 'selectors';
import axios from 'axios';
import constant from '../../constants';
import RichTextEditor from '../richTextEditor';
import { setNotificationMessage } from '../../actions/dashboard';
import Modal from 'react-modal';
import {
  epsilonMarket,
  checkIsCIAEnabled,
  isBrandEmailSmsConsentVisible
} from '../../selectors';
import {
  setApproverEditCampaignStatus,
  setApproverEditCampaignStatusMessage
} from '../../actions/app';
import { getCampaignDetails } from '../../actions/consentAndCompliance';
import Loader from 'components/Loader';
import CampaignCancelModel from '../CampaignCancelModel';
import { cancelCampaignChanges } from '../../actions/overview';
import NewStarIcon from '../../images/NewStar.svg';
import _ from 'lodash';

const consentTypes = constant.consentTypes;

const isSomeConsentAvailable = consents => {
  return Object.entries(consents).some(([key, value]) => {
    return value && constant.consents.some(consent => consent.tsKey === key);
  });
};

class SaasConsentCompliance extends Component {
  constructor(props) {
    super(props);

    this._openIsCancelModal = this._openIsCancelModal.bind(this);
    this._closeIsCancelModal = this._closeIsCancelModal.bind(this);
    this._cancelSaasCampaign = this._cancelSaasCampaign.bind(this);
    this._openNewModal = this._openNewModal.bind(this);
    this._closeNewModal = this._closeNewModal.bind(this);
  }
  state = {
    matchProps: this.props.match,
    campaignId: this.props.match.params.id,
    consents: [],
    expandAccordion: false,
    expand: 0,
    showErrorMessage: false,
    showAllPurposeError: false,
    consentsVersion: '',
    consentVersionKey: '',
    areConsentFieldsPopulated: false,
    optInType: [],
    consentValidationFail: false,
    consentUnavailableValidation: false,
    isCancelModalOpen: false,
    isNewModalOpen: false,
    isLegalAge: epsilonMarket(
      this.props.campaign &&
        this.props.campaign.country &&
        this.props.campaign.country.code
    )
      ? '13'
      : '16',
    fields: []
  };
  statementRef = React.createRef();

  frequency = [
    { value: 'daily', label: 'Daily' },
    { value: 'weekly', label: 'Weekly' },
    { value: 'monthly', label: 'Monthly' },
    { value: 'annually', label: 'Annually' }
  ];

  componentDidMount() {
    let fields;
    this.campaignId = this.props.match.params.id;
    this.props.getCampaignDetails(this.campaignId);
    axios
      .get(
        constant.serviceUrls.CAMPAIGN_DETAILS_SERVICE_URL +
          '/' +
          this.state.campaignId
      )
      .then(response => {
        let campaign = response.data[0];
        this.status = campaign && campaign.status;
        let countryCode = campaign.country.code;
        let brandCode = campaign.brand.code;
        fields =
          campaign && campaign.campaignDetail && campaign.campaignDetail.fields;
        if (fields.length > 0) {
          this.handleAccordionExpandCollapse(-1);
          this.setState({
            isLegalAge:
              campaign &&
              campaign.campaignDetail &&
              campaign.campaignDetail.ageOfConsent,
            fields: fields
          });
        } else {
          this.handleAccordionExpandCollapse(-1);
        }

        let countrySelected = campaign.country && campaign.country.code;
        let isEpsilonMarket =
          countrySelected && epsilonMarket(countrySelected) ? true : false;

        let brandEmailSmsConsent = [];
        const brandEmailSmsObject = [
          {
            optInType: 'brandEmailConsent',
            label: 'Brand Email',
            serviceId: !isEpsilonMarket
              ? 'BRAND_' + countryCode + '_' + brandCode + '_EML'
              : ''
          },
          {
            optInType: 'brandSMSConsent',
            label: 'Brand SMS',
            serviceId: !isEpsilonMarket
              ? 'BRAND_' + countryCode + '_' + brandCode + '_SMS'
              : ''
          }
        ];

        if (
          isBrandEmailSmsConsentVisible(
            this.props.campaignConfig,
            countrySelected,
            brandCode
          )
        ) {
          brandEmailSmsConsent = brandEmailSmsObject;
        }

        this.optInType = [
          {
            optInType: 'legalAgeConsent',
            label: 'Age Consent',
            ageOfConsent: epsilonMarket(
              this.props.campaign &&
                this.props.campaign.country &&
                this.props.campaign.country.code
            )
              ? '13'
              : '16'
          },
          {
            optInType: 'privacyNotice',
            label: 'Privacy Policy'
          },
          ...brandEmailSmsConsent,
          {
            optInType: 'unileverEmailConsent',
            label: 'Unilever Email',
            serviceId: !isEpsilonMarket
              ? 'UNILEVER_' + countryCode + '_EML'
              : ''
          },
          {
            optInType: 'unileverSMSConsent',
            label: 'Unilever SMS',
            serviceId: !isEpsilonMarket
              ? 'UNILEVER_' + countryCode + '_SMS'
              : ''
          },
          {
            optInType: consentTypes.additionalSubscription,
            label: 'Additional Opt-ins (Regular Comms.) 1'
          },
          {
            optInType: consentTypes.additionalSubscription1,
            label: 'Additional Opt-ins (Regular Comms.) 2'
          },
          { optInType: 'termsAndConditions', label: 'Terms and Conditions' },
          { optInType: 'legalDisclaimer', label: 'Disclaimer' }
        ];
        if (epsilonMarket(countryCode)) {
          this.optInType = this.optInType.filter(
            elem =>
              elem.optInType !== consentTypes.unileverSMSConsent &&
              elem.optInType !== consentTypes.brandSMSConsent &&
              elem.optInType !== consentTypes.additionalSubscription &&
              elem.optInType !== consentTypes.additionalSubscription1
          );
          if (!campaign.campaignDetail.consentsVersion) {
            this._handleConsentVersion({ value: 'enterpriseConsentsVersion3' });
          }
        }

        if (checkIsCIAEnabled(campaign)) {
          this.optInType.splice(6, 0, {
            optInType: 'contentInterestAreas',
            label: 'Content Interest Areas'
          });
        }

        this.setState({
          consentsTest: campaign.campaignDetail.consents,
          emailSenderName: campaign.brand.name,
          consentsVersion:
            campaign.campaignDetail.consentsVersion ||
            (isEpsilonMarket ? 'enterpriseConsentsVersion3' : '')
        });
        if (!campaign.campaignDetail.consentsVersion && isEpsilonMarket) {
          this._handleConsentVersion({ value: 'enterpriseConsentsVersion3' });
        }
        if (
          campaign.campaignDetail.consents &&
          campaign.campaignDetail.consents.length > 0
        ) {
          let legalAge = epsilonMarket(
            this.props.campaign &&
              this.props.campaign.country &&
              this.props.campaign.country.code
          )
            ? '13'
            : '16';
          const ageConsentIndex = campaign.campaignDetail.consents.findIndex(
            ele => ele.optInType === 'legalAgeConsent'
          );
          let ageConsentData =
            campaign.campaignDetail.consents[ageConsentIndex];
          if (
            campaign &&
            campaign.campaignDetail &&
            campaign.campaignDetail.ageOfConsent
          ) {
            ageConsentData['ageOfConsent'] =
              campaign &&
              campaign.campaignDetail &&
              campaign.campaignDetail.ageOfConsent;
            legalAge =
              campaign &&
              campaign.campaignDetail &&
              campaign.campaignDetail.ageOfConsent;
          } else if (
            ageConsentData &&
            ageConsentData.hasOwnProperty('ageOfConsent')
          ) {
            ageConsentData['ageOfConsent'] = ageConsentData['ageOfConsent'];
            legalAge = ageConsentData['ageOfConsent'];
          }
          if (
            ageConsentData['ageOfConsent'] === undefined ||
            ageConsentData['ageOfConsent'] === '0'
          ) {
            ageConsentData['ageOfConsent'] = epsilonMarket(
              this.props.campaign &&
                this.props.campaign.country &&
                this.props.campaign.country.code
            )
              ? '13'
              : '16';
            legalAge = epsilonMarket(
              this.props.campaign &&
                this.props.campaign.country &&
                this.props.campaign.country.code
            )
              ? '13'
              : '16';
          }
          campaign.campaignDetail.consents[ageConsentIndex] = ageConsentData;
          this.setState({
            isLegalAge: legalAge,
            consents: campaign.campaignDetail.consents
          });
        }
      });
  }

  componentDidUpdate(prevProps) {
    const { campaign, allConsents } = this.props;
    if (!_.isEqual(prevProps.allConsents, allConsents) && campaign) {
      const countrySelected = campaign.country && campaign.country.code;
      const isEpsilonMarket =
        countrySelected && epsilonMarket(countrySelected) ? true : false;
      if (isEpsilonMarket && !campaign.campaignDetail.consentsVersion) {
        this._handleConsentVersion({ value: 'enterpriseConsentsVersion3' });
      }
    }
  }

  _openModal = event => {
    event.preventDefault();
    this.setState(state => {
      return { ...state, ...{ modalIsOpen: true } };
    });
  };
  _closeModal = () => {
    this.setState(state => {
      return { ...state, ...{ modalIsOpen: false } };
    });
  };

  _openIsCancelModal(event) {
    event.preventDefault();
    this.setState({
      isCancelModalOpen: true
    });
  }

  _closeIsCancelModal() {
    this.setState({
      isCancelModalOpen: false
    });
  }

  _cancelSaasCampaign(event) {
    event.preventDefault();
    this.setState({
      isCancelModalOpen: false
    });
    this.props.cancelCampaignChanges(this.state.campaignId);
    this.props.history.push(
      '/campaign-wizard/campaign/' + this.state.campaignId
    );
  }

  _openNewModal(event) {
    event.preventDefault();
    this.setState({
      isNewModalOpen: true
    });
  }

  _closeNewModal() {
    this.setState({
      isNewModalOpen: false
    });
  }

  _handlebackButton = () => {
    let { campaign } = this.props;
    let isDirectType = campaign && campaign.campaignType === 'Direct messaging';
    let isQnA =
      campaign && campaign.type === 'Sign-up with Questions & Answers';
    let isDirectQnA = isDirectType && isQnA ? true : false;
    let campaignTypeIncentive =
      campaign && campaign.campaignType === 'Incentive';
    let isSimpleSignUporQna =
      (campaign && campaign.type === 'Simple sign-up') ||
      (campaign && campaign.type === 'Sign-up with Questions & Answers');
    let countrySelected =
      this.props.campaign &&
      this.props.campaign.country &&
      this.props.campaign.country.code;
    if (constant.featureFlags.ENABLE_SINGLE_PAGE_FIELD_SELECTION) {
      this.props.history.push(
        '/campaign-wizard/campaign/' +
          this.state.campaignId +
          '/configure-form-fields'
      );
    } else if (isDirectQnA) {
      this.props.history.push(
        '/campaign-wizard/campaign/' +
          this.state.campaignId +
          `${epsilonMarket(countrySelected) ? '/saas-epsilon-qna' : '/saasqna'}`
      );
    } else if (campaignTypeIncentive && isSimpleSignUporQna) {
      this.props.history.push(
        '/campaign-wizard/campaign/' +
          this.state.campaignId +
          '/saas-incentive-fields'
      );
    } else {
      this.props.history.push(
        '/campaign-wizard/campaign/' +
          this.state.campaignId +
          '/saas-profile-details'
      );
    }
  };

  _handleValidationMessageDisplay = selectedOption => {
    if (selectedOption) {
      document.getElementById('consentVersionErrorMessage') &&
        document
          .getElementById('consentVersionErrorMessage')
          .classList.add('cw-hidden');

      document
        .getElementById(`consentsVersion`)
        .classList.contains('cw-error-focus') &&
        document
          .getElementById(`consentsVersion`)
          .classList.remove('cw-error-focus');
    }
    this.setState({
      showAllPurposeError: false
    });
    this.state.consents.forEach((elem, index) => {
      if (
        document.getElementById('consent_error_' + elem.optInType) &&
        !document
          .getElementById('consent_error_' + elem.optInType)
          .classList.contains('cw-hidden')
      ) {
        document.getElementById('consent_error_' + elem.optInType) &&
          document
            .getElementById('consent_error_' + elem.optInType)
            .classList.add('cw-hidden');

        document
          .getElementById(`consent_${elem.optInType}`)
          .classList.contains('cw-error-focus') &&
          document
            .getElementById(`consent_${elem.optInType}`)
            .classList.remove('cw-error-focus');
      }

      if (
        document.getElementById('consent_mandatoryMessage_' + elem.optInType) &&
        !document
          .getElementById('consent_mandatoryMessage_' + elem.optInType)
          .classList.contains('cw-hidden')
      ) {
        document.getElementById('consent_mandatoryMessage_' + elem.optInType) &&
          document
            .getElementById('consent_mandatoryMessage_' + elem.optInType)
            .classList.add('cw-hidden');

        document
          .getElementById(`errorMessage_${elem.optInType}`)
          .classList.contains('cw-error-focus') &&
          document
            .getElementById(`errorMessage_${elem.optInType}`)
            .classList.remove('cw-error-focus');
      }

      if (
        document.getElementById('frequency_error_' + elem.optInType) &&
        !document
          .getElementById('frequency_error_' + elem.optInType)
          .classList.contains('cw-hidden')
      ) {
        document.getElementById('frequency_error_' + elem.optInType) &&
          document
            .getElementById('frequency_error_' + elem.optInType)
            .classList.add('cw-hidden');

        document
          .getElementById(`frequency_${elem.optInType}`)
          .classList.contains('cw-error-focus') &&
          document
            .getElementById(`frequency_${elem.optInType}`)
            .classList.remove('cw-error-focus');

        document
          .getElementById(`errorMessage_${elem.optInType}`)
          .classList.contains('cw-error-focus') &&
          document
            .getElementById(`errorMessage_${elem.optInType}`)
            .classList.remove('cw-error-focus');
      }

      if (
        document.getElementById('ageErrorMessage') &&
        !document
          .getElementById('ageErrorMessage')
          .classList.contains('cw-hidden')
      ) {
        document.getElementById('ageErrorMessage') &&
          document.getElementById('ageErrorMessage').classList.add('cw-hidden');

        document
          .getElementById(`ageOfConsent`)
          .classList.contains('cw-error-focus') &&
          document
            .getElementById(`ageOfConsent`)
            .classList.remove('cw-error-focus');
      }

      if (
        document.querySelector("[data-custom-msg='emailSenderName']") &&
        !document
          .querySelector("[data-custom-msg='emailSenderName']")
          .classList.contains('cw-hidden')
      ) {
        document
          .querySelector('[data-custom-msg=' + 'emailSenderName' + ']')
          .classList.add('cw-hidden');

        document
          .getElementById(`emailSenderName`)
          .classList.contains('cw-error-focus') &&
          document
            .getElementById(`emailSenderName`)
            .classList.remove('cw-error-focus');
      }

      if (
        document.querySelector("[data-custom-msg='replyToEmailAdderess']") &&
        !document
          .querySelector("[data-custom-msg='replyToEmailAdderess']")
          .classList.contains('cw-hidden')
      ) {
        document
          .querySelector('[data-custom-msg=' + 'replyToEmailAdderess' + ']')
          .classList.add('cw-hidden');

        document
          .getElementById(`replyToEmailAdderess`)
          .classList.contains('cw-error-focus') &&
          document
            .getElementById(`replyToEmailAdderess`)
            .classList.remove('cw-error-focus');
      }
    });
  };

  _handleConsentVersion = selectedOption => {
    let consents = this.props.allConsents;
    let campaign = this.props.campaign;
    let consentMessage = this.props.formConsentData;
    let countrySelected = campaign.country && campaign.country.code;
    let isEpsilonMarket =
      countrySelected && epsilonMarket(countrySelected) ? true : false;

    this._handleValidationMessageDisplay(selectedOption);
    let ver = constant.consentLabels[selectedOption.value];
    let consentsForSelectedVersion = consents && consents[ver];
    let areConsentsAvailable = false;
    if (
      consentsForSelectedVersion &&
      (consentsForSelectedVersion.length === 0 ||
        !isSomeConsentAvailable(consentsForSelectedVersion[0]))
    ) {
      this.handleAccordionExpandCollapse(-1);
      scrollToTop();
      areConsentsAvailable = false;
      this.setState({ consentUnavailableValidation: true });
      const defaultConsents = { ...constant.defaultConsents };
      if (selectedOption.value === 'brandUnileverVersion2') {
        delete defaultConsents.age_consent;
      }
      consentsForSelectedVersion = [defaultConsents];
    } else {
      areConsentsAvailable = true;
      this.handleAccordionExpandCollapse(-1);
      this.setState({ consentUnavailableValidation: false });
    }

    let defaultList = constant.consents;
    let selectedConsents = [];

    let ageConsentData = consentMessage
      ? consentMessage.find(elem => elem.dataType === 'legalAgeConsent')
      : {};
    let brandEmailConsentData = consentMessage
      ? consentMessage.find(elem => elem.dataType === 'brandEmailConsent')
      : {};
    let brandSMSConsentData = consentMessage
      ? consentMessage.find(elem => elem.dataType === 'brandSMSConsent')
      : {};
    let unileverEmailConsentData = consentMessage
      ? consentMessage.find(elem => elem.dataType === 'unileverEmailConsent')
      : {};
    let unileverSMSConsentData = consentMessage
      ? consentMessage.find(elem => elem.dataType === 'unileverSMSConsent')
      : {};
    let termsAndConditionsData = consentMessage
      ? consentMessage.find(elem => elem.dataType === 'termsAndConditions')
      : {};

    const existAgeOfConsent =
      campaign &&
      campaign.campaignDetail &&
      campaign.campaignDetail.ageOfConsent;

    let areConsentFieldsPopulated = false;

    if (consentsForSelectedVersion && consentsForSelectedVersion.length > 0) {
      let consentObj = consentsForSelectedVersion[0];

      if (selectedOption.value === 'brandUnileverVersion2') {
        let ageOfConsent = {
          consentType: 'implicit',
          label: 'Age Consent',
          optInType: 'legalAgeConsent',
          mandatoryMessage:
            ageConsentData && ageConsentData.translatedLabel
              ? ageConsentData.translatedLabel
              : '',
          defaultCopy: constant.defaultConsents.age_consent,
          ageOfConsent: existAgeOfConsent
        };
        selectedConsents.push(ageOfConsent);
      }

      defaultList.map(elem => {
        let key = elem.tsKey;
        if (consentObj[key]) {
          areConsentFieldsPopulated = true;
          elem.defaultCopy = consentObj[key] ? consentObj[key] : '';
          elem.tsCopy = consentObj[key] ? consentObj[key] : '';
          elem.consentType = 'mandatory';

          if (elem.optInType === 'brandEmailConsent') {
            elem.serviceId = !isEpsilonMarket
              ? 'BRAND_' +
                campaign.country.code +
                '_' +
                campaign.brand.code +
                '_EML'
              : '';
            elem.emailSenderName = campaign.brand.name;
            elem.mandatoryMessage =
              brandEmailConsentData && brandEmailConsentData.translatedLabel
                ? brandEmailConsentData.translatedLabel
                : '';
          } else if (elem.optInType === 'brandSMSConsent') {
            elem.serviceId = !isEpsilonMarket
              ? 'BRAND_' +
                campaign.country.code +
                '_' +
                campaign.brand.code +
                '_SMS'
              : '';
            elem.mandatoryMessage =
              brandSMSConsentData && brandSMSConsentData.translatedLabel
                ? brandSMSConsentData.translatedLabel
                : '';
          } else if (elem.optInType === 'unileverEmailConsent') {
            elem.serviceId = !isEpsilonMarket
              ? 'UNILEVER_' + campaign.country.code + '_EML'
              : '';
            elem.mandatoryMessage =
              unileverEmailConsentData &&
              unileverEmailConsentData.translatedLabel
                ? unileverEmailConsentData.translatedLabel
                : '';
            if (
              !areConsentsAvailable &&
              (isEpsilonMarket ||
                selectedOption.value === 'brandUnileverVersion2')
            ) {
              elem.consentType = 'implicit';
            }
          } else if (elem.optInType === 'unileverSMSConsent') {
            elem.serviceId = !isEpsilonMarket
              ? 'UNILEVER_' + campaign.country.code + '_SMS'
              : '';
            elem.mandatoryMessage =
              unileverSMSConsentData && unileverSMSConsentData.translatedLabel
                ? unileverSMSConsentData.translatedLabel
                : '';
          } else if (elem.optInType === 'legalAgeConsent') {
            elem.ageOfConsent = existAgeOfConsent;

            if (isEpsilonMarket) {
              elem.consentType = 'implicit';
            }
            elem.mandatoryMessage =
              ageConsentData && ageConsentData.translatedLabel
                ? ageConsentData.translatedLabel
                : '';
          } else if (elem.optInType === 'termsAndConditions') {
            elem.mandatoryMessage =
              termsAndConditionsData && termsAndConditionsData.translatedLabel
                ? termsAndConditionsData.translatedLabel
                : '';
          }
          selectedConsents.push(elem);
        }
        return elem;
      });
      this.setState({
        consents: selectedConsents,
        consentsTest: selectedConsents,
        consentsVersion: selectedOption.value,
        consentVersionKey: ver,
        areConsentFieldsPopulated
      });
    } else {
      this.setState({
        consents: selectedConsents,
        consentsTest: selectedConsents,
        consentsVersion: selectedOption.value,
        consentVersionKey: ver,
        areConsentFieldsPopulated
      });
    }
  };

  _validConsents = () => {
    let isValid = true;
    let regexPattern;
    this.state.consents.forEach((elem, index) => {
      if (elem.optInType !== 'contentInterestAreas') {
        if (
          elem.consentType === 'mandatory' ||
          elem.consentType === 'optional'
        ) {
          const isConsentTextEmpty =
            elem.defaultCopy === undefined ||
            elem.defaultCopy
              .replace(/\r?\n|\r/gm, '')
              .replace(/<[^>]+>/gm, '')
              .replace(/&nbsp;/gm, '')
              .trim().length === 0;
          if (isConsentTextEmpty) {
            document.getElementById('consent_error_' + elem.optInType) &&
              document
                .getElementById('consent_error_' + elem.optInType)
                .classList.remove('cw-hidden');

            document
              .getElementById(`consent_${elem.optInType}`)
              .classList.contains('cw-error-focus') ||
              document
                .getElementById(`consent_${elem.optInType}`)
                .classList.add('cw-error-focus');

            document
              .getElementById(`consent_${elem.optInType}`)
              .classList.contains('cw-error-focus') ||
              document
                .getElementById(`consent_${elem.optInType}`)
                .classList.add('cw-error-focus');

            this.setState({ expand: index });
            isValid = false;
          } else {
            this.removeConsentValidationUI(elem);
          }
        } else {
          this.removeConsentValidationUI(elem);
        }
      }

      if (
        elem.optInType !== 'privacyNotice' &&
        elem.optInType !== 'legalDisclaimer' &&
        elem.consentType &&
        elem.consentType === 'mandatory' &&
        (elem.mandatoryMessage === undefined ||
          Object.values(elem.mandatoryMessage.trim()).length === 0)
      ) {
        document.getElementById('consent_mandatoryMessage_' + elem.optInType) &&
          document
            .getElementById('consent_mandatoryMessage_' + elem.optInType)
            .classList.remove('cw-hidden');

        document
          .getElementById(`errorMessage_${elem.optInType}`)
          .classList.contains('cw-error-focus') ||
          document
            .getElementById(`errorMessage_${elem.optInType}`)
            .classList.add('cw-error-focus');

        this.setState({ expand: index });
        isValid = false;
      }
      if (
        elem.optInType === 'additionalSubscription' ||
        elem.optInType === 'additionalSubscription1'
      ) {
        regexPattern = constant.regex['serviceId'];
        if (
          elem.serviceId &&
          elem.serviceId.trim() !== '' &&
          !regexPattern.test(elem.serviceId.trim())
        ) {
          document.querySelector(
            '[data-custom-msg=' + 'serviceId_' + elem.optInType + ']'
          ) &&
            document
              .querySelector(
                '[data-custom-msg=' + 'serviceId_' + elem.optInType + ']'
              )
              .classList.remove('cw-hidden');
          isValid = false;
        }
        if (
          elem.frequency === undefined ||
          Object.values(elem.frequency).length === 0
        ) {
          document.getElementById('frequency_error_' + elem.optInType) &&
            document
              .getElementById('frequency_error_' + elem.optInType)
              .classList.remove('cw-hidden');

          document
            .getElementById(`frequency_${elem.optInType}`)
            .classList.contains('cw-error-focus') ||
            document
              .getElementById(`frequency_${elem.optInType}`)
              .classList.add('cw-error-focus');

          isValid = false;
        }
        if (
          elem.frequency === undefined ||
          Object.values(elem.frequency).length === 0
        ) {
          document.getElementById('frequency_error_' + elem.optInType) &&
            document
              .getElementById('frequency_error_' + elem.optInType)
              .classList.remove('cw-hidden');

          document
            .getElementById(`frequency_${elem.optInType}`)
            .classList.contains('cw-error-focus') ||
            document
              .getElementById(`frequency_${elem.optInType}`)
              .classList.add('cw-error-focus');
          this.setState({ expand: index });
          isValid = false;
        }
      }
      if (elem.optInType === 'brandEmailConsent') {
        regexPattern = constant.regex['email'];
        if (
          elem.replyToEmailAdderess &&
          elem.replyToEmailAdderess.trim() !== '' &&
          !regexPattern.test(elem.replyToEmailAdderess)
        ) {
          document
            .querySelector('[data-custom-msg=' + 'replyToEmailAdderess' + ']')
            .classList.remove('cw-hidden');

          document
            .getElementById(`replyToEmailAdderess`)
            .classList.contains('cw-error-focus') ||
            document
              .getElementById(`replyToEmailAdderess`)
              .classList.add('cw-error-focus');

          isValid = false;
        }
        if (
          (elem.emailSenderName === undefined) |
          (Object.values(elem.emailSenderName.trim()).length === 0)
        ) {
          document
            .querySelector('[data-custom-msg=' + 'emailSenderName' + ']')
            .classList.remove('cw-hidden');

          document
            .getElementById(`emailSenderName`)
            .classList.contains('cw-error-focus') ||
            document
              .getElementById(`emailSenderName`)
              .classList.add('cw-error-focus');

          this.setState({ expand: index });
          isValid = false;
        }
      }
    });
    if (!isValid) {
      scrollToTop();
    }
    return isValid;
  };

  removeConsentValidationUI(elem) {
    document.getElementById('consent_error_' + elem.optInType) &&
      document
        .getElementById('consent_error_' + elem.optInType)
        .classList.add('cw-hidden');

    document
      .getElementById(`consent_${elem.optInType}`)
      .classList.contains('cw-error-focus') &&
      document
        .getElementById(`consent_${elem.optInType}`)
        .classList.remove('cw-error-focus');
  }

  _handleConsentField = selectedOption => {
    let consents = this.props.allConsents;
    let consentMessage = this.props.formConsentData;
    let consentVersionKey = this.state.consentVersionKey;
    let hasNoDuplicate = this.state.consents.some(elem => {
      return elem.optInType === selectedOption.optInType;
    });

    let ageConsentMessage = consentMessage
      ? consentMessage.find(elem => elem.dataType === 'legalAgeConsent')
      : {};
    let brandEmailConsentMessage = consentMessage
      ? consentMessage.find(elem => elem.dataType === 'brandEmailConsent')
      : {};
    let brandSMSConsentMessage = consentMessage
      ? consentMessage.find(elem => elem.dataType === 'brandSMSConsent')
      : {};
    let unileverEmailConsentMessage = consentMessage
      ? consentMessage.find(elem => elem.dataType === 'unileverEmailConsent')
      : {};
    let unileverSMSConsentMessage = consentMessage
      ? consentMessage.find(elem => elem.dataType === 'unileverSMSConsent')
      : {};
    let additionalSubscriptionMessage = consentMessage
      ? consentMessage.find(elem => elem.dataType === 'additionalSubscription')
      : {};
    let termsAndConditionsMessage = consentMessage
      ? consentMessage.find(elem => elem.dataType === 'termsAndConditions')
      : {};

    if (!hasNoDuplicate) {
      this.setState({ showErrorMessage: false });
      if (this._validConsents()) {
        this.setState({ consentValidationFail: false });
        selectedOption.consentType = 'mandatory';
        if (
          selectedOption &&
          selectedOption.optInType === 'brandEmailConsent'
        ) {
          selectedOption['emailSenderName'] = this.state.emailSenderName;
          selectedOption['mandatoryMessage'] =
            brandEmailConsentMessage && brandEmailConsentMessage.translatedLabel
              ? brandEmailConsentMessage.translatedLabel
              : '';
          selectedOption['defaultCopy'] =
            consents[consentVersionKey] &&
            consents[consentVersionKey][0] &&
            consents[consentVersionKey][0].brand_email;
        }
        if (selectedOption && selectedOption.optInType === 'legalAgeConsent') {
          selectedOption['mandatoryMessage'] =
            ageConsentMessage && ageConsentMessage.translatedLabel
              ? ageConsentMessage.translatedLabel
              : '';
          this.setState({ consentValidationFail: false });
          let countrySelected =
            this.props.campaign &&
            this.props.campaign.country &&
            this.props.campaign.country.code;

          if (epsilonMarket(countrySelected)) {
            selectedOption['consentType'] = 'implicit';
          }
          selectedOption['defaultCopy'] =
            consents[consentVersionKey] &&
            consents[consentVersionKey][0] &&
            consents[consentVersionKey][0].age_consent;
        }

        if (selectedOption && selectedOption.optInType === 'privacyNotice') {
          selectedOption['mandatoryMessage'] =
            brandSMSConsentMessage && brandSMSConsentMessage.translatedLabel
              ? brandSMSConsentMessage.translatedLabel
              : '';
          this.setState({ consentValidationFail: false });
          selectedOption['defaultCopy'] =
            consents[consentVersionKey] &&
            consents[consentVersionKey][0] &&
            consents[consentVersionKey][0].privacy_policy;
        }

        if (selectedOption && selectedOption.optInType === 'brandSMSConsent') {
          selectedOption['mandatoryMessage'] =
            brandSMSConsentMessage && brandSMSConsentMessage.translatedLabel
              ? brandSMSConsentMessage.translatedLabel
              : '';
          selectedOption['defaultCopy'] =
            consents[consentVersionKey] &&
            consents[consentVersionKey][0] &&
            consents[consentVersionKey][0].brand_sms;
        }
        if (
          selectedOption &&
          selectedOption.optInType === 'unileverEmailConsent'
        ) {
          selectedOption['mandatoryMessage'] =
            unileverEmailConsentMessage &&
            unileverEmailConsentMessage.translatedLabel
              ? unileverEmailConsentMessage.translatedLabel
              : '';
          selectedOption['defaultCopy'] =
            consents[consentVersionKey] &&
            consents[consentVersionKey][0] &&
            consents[consentVersionKey][0].unilever_email;
        }
        if (
          selectedOption &&
          selectedOption.optInType === 'unileverSMSConsent'
        ) {
          selectedOption['mandatoryMessage'] =
            unileverSMSConsentMessage &&
            unileverSMSConsentMessage.translatedLabel
              ? unileverSMSConsentMessage.translatedLabel
              : '';
          selectedOption['defaultCopy'] =
            consents[consentVersionKey] &&
            consents[consentVersionKey][0] &&
            consents[consentVersionKey][0].unilever_sms;
        }
        if (
          selectedOption &&
          selectedOption.optInType === 'additionalSubscription'
        ) {
          selectedOption['mandatoryMessage'] =
            additionalSubscriptionMessage &&
            additionalSubscriptionMessage.translatedLabel
              ? additionalSubscriptionMessage.translatedLabel
              : '';
        }
        if (
          selectedOption &&
          selectedOption.optInType === 'additionalSubscription1'
        ) {
          selectedOption['mandatoryMessage'] =
            additionalSubscriptionMessage &&
            additionalSubscriptionMessage.translatedLabel
              ? additionalSubscriptionMessage.translatedLabel
              : '';
        }
        if (
          selectedOption &&
          selectedOption.optInType === 'termsAndConditions'
        ) {
          selectedOption['mandatoryMessage'] =
            termsAndConditionsMessage &&
            termsAndConditionsMessage.translatedLabel
              ? termsAndConditionsMessage.translatedLabel
              : '';
        }

        if (
          selectedOption &&
          selectedOption.optInType === 'contentInterestAreas'
        ) {
          selectedOption['consentType'] = 'implicit';
        }

        this.state.consents.push(selectedOption);
        this.setState({
          consents: [...this.state.consents],
          consentsTest: [...this.state.consents]
        });
        this.setState({ expand: this.state.consents.indexOf(selectedOption) });
        this.setState({
          showAllPurposeError: false
        });
      } else {
        this.setState({
          showAllPurposeError: true
        });
      }
    } else {
      this.setState({ showErrorMessage: true });
      scrollToTop();
    }
  };

  removeAccordion = index => {
    let updatedConsentArray = [...this.state.consents];
    updatedConsentArray.splice(index, 1);
    this.setState({
      consents: [...updatedConsentArray],
      consentsTest: [...updatedConsentArray]
    });
  };

  handleAccordionExpandCollapse = index => {
    this.setState(prevState => ({
      expand: prevState.expand === index ? false : index
    }));
  };

  _handleMandatoryError = (e, index, optInType) => {
    let errorMessage = e.target.value;
    let updatedConsentArray = [...this.state.consents];
    updatedConsentArray[index] = {
      ...updatedConsentArray[index],
      mandatoryMessage: errorMessage
    };
    document.getElementById(`consent_mandatoryMessage_${optInType}`) &&
      document
        .getElementById(`consent_mandatoryMessage_${optInType}`)
        .classList.add('cw-hidden');

    document
      .getElementById(`errorMessage_${optInType}`)
      .classList.contains('cw-error-focus') &&
      document
        .getElementById(`errorMessage_${optInType}`)
        .classList.remove('cw-error-focus');

    this.setState({
      consents: [...updatedConsentArray]
    });
  };

  _handleInputChange = (e, index, elem) => {
    let fieldData = {
      [e.target.name]: e.target.value
    };
    if (e.target.name === 'emailSenderName') {
      this.setState({
        emailSenderName: e.target.value
      });
    }

    let updatedConsentArray = [...this.state.consents];
    updatedConsentArray[index] = {
      ...updatedConsentArray[index],
      ...fieldData
    };

    /*if (
      updatedConsentArray[index] &&
      updatedConsentArray[index].hasOwnProperty("ageOfConsent") &&
      updatedConsentArray[index].ageOfConsent.trim().length !== 0
    ) {
      document.getElementById("ageErrorMessage") &&
        document.getElementById("ageErrorMessage").classList.add("cw-hidden");

      document
        .getElementById(`ageOfConsent`)
        .classList.contains("cw-error-focus") &&
        document
          .getElementById(`ageOfConsent`)
          .classList.remove("cw-error-focus");
    }*/
    if (
      updatedConsentArray[index] &&
      updatedConsentArray[index].hasOwnProperty('emailSenderName') &&
      updatedConsentArray[index].emailSenderName.trim().length !== 0
    ) {
      document
        .querySelector('[data-custom-msg=' + 'emailSenderName' + ']')
        .classList.add('cw-hidden');

      document
        .getElementById(`emailSenderName`)
        .classList.contains('cw-error-focus') &&
        document
          .getElementById(`emailSenderName`)
          .classList.remove('cw-error-focus');
    }
    let regexPattern;
    regexPattern = regexPattern = constant.regex['email'];
    if (
      updatedConsentArray[index].replyToEmailAdderess &&
      updatedConsentArray[index].replyToEmailAdderess.trim() !== '' &&
      !regexPattern.test(updatedConsentArray[index].replyToEmailAdderess)
    ) {
      document
        .getElementById(`replyToEmailAdderess`)
        .classList.contains('cw-error-focus') &&
        document
          .getElementById(`replyToEmailAdderess`)
          .classList.remove('cw-error-focus');
    }

    this.setState({
      consents: [...updatedConsentArray],
      consentsTest: [...updatedConsentArray]
    });
  };

  _handleLegalAgeConsentInputChange = (e, index, elem) => {
    let fieldData = {
      ageOfConsent: e.value
    };
    let fieldsArr = [...this.state.fields];
    const existingAge = this.state.isLegalAge;
    if (fieldsArr && fieldsArr.length > 0) {
      fieldsArr.forEach(elem => {
        if (elem.dataType === 'dateOfBirth' && elem.validationMessage != '') {
          elem.validationMessage = elem.validationMessage
            ? elem.validationMessage.replace(existingAge, e.value.toString())
            : null;
        }
      });
    }

    /*let countrySelected =
      this.props.campaign &&
      this.props.campaign.country &&
      this.props.campaign.country.code;
    let minAge = epsilonMarket(countrySelected) ? 13 : 16;
    if (e.value < minAge) {
      document.getElementById(`error_legalAgeValidation_${elem.dataType}`) &&
        document
          .getElementById(`error_legalAgeValidation_${elem.dataType}`)
          .classList.remove("cw-hidden");

      document
        .getElementById(`ageOfConsent`)
        .classList.contains("cw-error-focus") ||
        document
          .getElementById(`ageOfConsent`)
          .classList.add("cw-error-focus");
    } else {
      document.getElementById(`error_legalAgeValidation_${elem.dataType}`) &&
        document
          .getElementById(`error_legalAgeValidation_${elem.dataType}`)
          .classList.add("cw-hidden");

      document
        .getElementById(`ageOfConsent`)
        .classList.contains("cw-error-focus") &&
        document
          .getElementById(`ageOfConsent`)
          .classList.remove("cw-error-focus");
    }*/

    let updatedConsentArray = [...this.state.consents];
    updatedConsentArray[index] = {
      ...updatedConsentArray[index],
      ...fieldData
    };
    this.setState({
      isLegalAge: e.value,
      fields: [...fieldsArr],
      consents: [...updatedConsentArray],
      consentsTest: [...updatedConsentArray]
    });
  };

  handleMandatoryRadio = (e, index) => {
    let mandatoryChecked = e.target.checked;
    let updatedConsentArray = [...this.state.consents];
    if (mandatoryChecked) {
      updatedConsentArray[index] = {
        ...updatedConsentArray[index],
        consentType: 'mandatory'
      };
      this.setState({
        consents: [...updatedConsentArray]
      });
    }
  };
  handleMandatoryToggle = (e, index) => {
    let mandatoryChecked = e.target.checked;
    let updatedConsentArray = [...this.state.consents];
    updatedConsentArray[index] = {
      ...updatedConsentArray[index],
      consentType: mandatoryChecked ? 'mandatory' : 'optional'
    };
    this.setState({
      consents: [...updatedConsentArray]
    });
  };
  handleOptionalRadio = (e, index) => {
    let optionalChecked = e.target.checked;
    let updatedConsentArray = [...this.state.consents];
    if (optionalChecked) {
      updatedConsentArray[index] = {
        ...updatedConsentArray[index],
        consentType: 'optional'
      };
      this.setState({
        consents: [...updatedConsentArray]
      });
    }
  };

  handleStatement = (e, index) => {
    let statementChecked = e.target.checked;
    let updatedConsentArray = [...this.state.consents];
    if (statementChecked) {
      updatedConsentArray[index] = {
        ...updatedConsentArray[index],
        consentType: 'implicit'
      };
      this.setState(
        {
          consents: [...updatedConsentArray]
        },
        this._validConsents
      );
    }
  };

  _handleFrequency = (e, index, optInType) => {
    let frequency = e;
    let updatedConsentArray = [...this.state.consents];
    updatedConsentArray[index] = {
      ...updatedConsentArray[index],
      frequency: frequency.label
    };
    document.getElementById(`frequency_error_${optInType}`) &&
      document
        .getElementById(`frequency_error_${optInType}`)
        .classList.add('cw-hidden');

    document
      .getElementById(`frequency_${optInType}`)
      .classList.contains('cw-error-focus') &&
      document
        .getElementById(`frequency_${optInType}`)
        .classList.remove('cw-error-focus');

    this.setState({
      consents: [...updatedConsentArray]
    });
  };

  _handleCancel = () => {
    this.props.setApproverEditCampaignStatus(false);
    this.props.setApproverEditCampaignStatusMessage('');
    this.props.history.push(
      '/campaign-wizard/campaign/' + this.campaignId + '/approval'
    );
  };

  _handleFrequency = (e, index, optInType) => {
    let frequency = e;
    let updatedConsentArray = [...this.state.consents];
    updatedConsentArray[index] = {
      ...updatedConsentArray[index],
      frequency: frequency.label
    };
    document.getElementById(`frequency_error_${optInType}`) &&
      document
        .getElementById(`frequency_error_${optInType}`)
        .classList.add('cw-hidden');

    document
      .getElementById(`frequency_${optInType}`)
      .classList.contains('cw-error-focus') &&
      document
        .getElementById(`frequency_${optInType}`)
        .classList.remove('cw-error-focus');

    this.setState({
      consents: [...updatedConsentArray]
    });
  };

  _handleCancel = () => {
    this.props.setApproverEditCampaignStatus(false);
    this.props.setApproverEditCampaignStatusMessage('');
    this.props.history.push(
      '/campaign-wizard/campaign/' + this.campaignId + '/approval'
    );
  };

  validateFields = () => {
    if (!this.state.consentsVersion) {
      document.getElementById('consentVersionErrorMessage') &&
        document
          .getElementById('consentVersionErrorMessage')
          .classList.remove('cw-hidden');

      document
        .getElementById(`consentsVersion`)
        .classList.contains('cw-error-focus') ||
        document
          .getElementById(`consentsVersion`)
          .classList.add('cw-error-focus');
      return false;
    }
    let ageConsentData = this.state.consents.find(
      elem => elem.optInType === 'legalAgeConsent'
    );
    let privacyConsentData = this.state.consents.find(
      elem => elem.optInType === 'privacyNotice'
    );
    let isValid = this._validConsents();
    if (!isValid) {
      this.setState({
        showAllPurposeError: true
      });
    } else {
      this.setState({
        showAllPurposeError: false
      });
    }

    if (!ageConsentData || !privacyConsentData) {
      isValid = false;
      scrollToTop();
      this.setState({ consentValidationFail: true });
      return isValid;
    }
    return isValid;
  };

  _handleSubmit = (event, saveAsDraft) => {
    if (!saveAsDraft) {
      //Put the validations code
    }
    let uti = [...this.state.consents];
    const fields = [...this.state.fields];
    this.setState({
      consents: uti
    });
    let isEdited = this.props.campaign.isEdited;
    let dataToSend = {
      uid: this.state.campaignId,
      consents: uti,
      fields: fields,
      consentsVersion: this.state.consentsVersion,
      isEdited: isEdited,
      ageOfConsent: this.state.isLegalAge
    };
    if (saveAsDraft) {
      dataToSend['saveAsDraft'] = saveAsDraft;
      axios
        .post(constant.serviceUrls.CAMPAIGN_DATA_SERVICE_URL, dataToSend)
        .then(response => {
          if (response) {
            let notificationData = {};
            notificationData.message = constant.CAMPAIGN_SAVED_AS_DRAFT_TEXT;
            notificationData.link = '';
            notificationData.type = 'warning';
            notificationData.campaign = '';

            this.props.setNotificationMessage(
              notificationData.message,
              notificationData.link,
              notificationData.type,
              notificationData.campaign
            );
            this.props.history.push('/campaign-wizard/dashboard');
          }
        });
    }
    if (!saveAsDraft && this.validateFields()) {
      this.setState({ consentValidationFail: false });
      axios
        .post(constant.serviceUrls.CAMPAIGN_DATA_SERVICE_URL, dataToSend)
        .then(response => {
          if (response) {
            if (this.status === '6') {
              this.props.setApproverEditCampaignStatus(true);
              this.props.setApproverEditCampaignStatusMessage('success');
              this.props.history.push(
                '/campaign-wizard/campaign/' +
                  this.state.campaignId +
                  '/approval'
              );
            } else
              this.props.history.push(
                '/campaign-wizard/campaign/' +
                  this.state.campaignId +
                  (constant.featureFlags.ENABLE_SINGLE_PAGE_FIELD_SELECTION
                    ? '/saas-sorting-page'
                    : '/saas-generic-details')
              );
          }
        });
    }
  };

  additionalConsentRichTextData(richTextData, index, optInType) {
    let defaultCopy = richTextData.getContent();
    let updatedConsentArray = [...this.state.consents];
    updatedConsentArray[index] = {
      ...updatedConsentArray[index],
      defaultCopy: defaultCopy
    };
    document.getElementById(`consent_error_${optInType}`) &&
      document
        .getElementById(`consent_error_${optInType}`)
        .classList.add('cw-hidden');

    document
      .getElementById(`consent_${optInType}`)
      .classList.contains('cw-error-focus') &&
      document
        .getElementById(`consent_${optInType}`)
        .classList.remove('cw-error-focus');

    this.setState({
      consents: [...updatedConsentArray]
    });
  }

  _handleInputOnBlur = event => {
    if (event.target.dataset.customError === 'true') {
      // Email Format Validtion
      let regexPattern;
      regexPattern = constant.regex[event.target.dataset.customErrorType];
      if (regexPattern.test(event.target.value)) {
        document
          .querySelector('[data-custom-msg=' + event.target.id + ']')
          .classList.add('cw-hidden');

        document
          .getElementById(`replyToEmailAdderess`)
          .classList.contains('cw-error-focus') &&
          document
            .getElementById(`replyToEmailAdderess`)
            .classList.remove('cw-error-focus');
      } else {
        if (event.target.value.trim() === '') {
          document
            .querySelector('[data-custom-msg=' + event.target.id + ']')
            .classList.add('cw-hidden');
        } else {
          document
            .querySelector('[data-custom-msg=' + event.target.id + ']')
            .classList.remove('cw-hidden');
        }
      }
    }
  };

  _handleReset = (e, index) => {
    let consents = this.state.consents;
    let consentToReset = consents[index];

    let optInType = consentToReset.optInType;
    document.getElementById(`consent_error_${optInType}`) &&
      document
        .getElementById(`consent_error_${optInType}`)
        .classList.add('cw-hidden');

    document
      .getElementById(`consent_${optInType}`)
      .classList.contains('cw-error-focus') &&
      document
        .getElementById(`consent_${optInType}`)
        .classList.remove('cw-error-focus');

    let consentTestObj =
      this.state.consentsTest && this.state.consentsTest[index]
        ? this.state.consentsTest[index]
        : null;

    if (consentToReset.tsCopy) {
      consentToReset.defaultCopy =
        consentTestObj &&
        consentTestObj.defaultCopy &&
        consentTestObj.defaultCopy.trim() === consentToReset.tsCopy &&
        consentToReset.tsCopy.trim()
          ? '' + consentTestObj.defaultCopy + ' '
          : consentToReset.tsCopy
          ? '' + consentToReset.tsCopy + ' '
          : '';
    } else {
      consentToReset.defaultCopy = consentTestObj.defaultCopy
        ? '' + consentTestObj.defaultCopy + ' '
        : ' ';
    }

    let updatedConsentArray = [...this.state.consents];
    updatedConsentArray[index] = {
      ...updatedConsentArray[index],
      ...consentToReset
    };

    let updatedConsentTestArray = [...this.state.consentsTest];
    updatedConsentTestArray[index] = {
      ...updatedConsentTestArray[index],
      ...consentToReset
    };

    this.setState({
      consents: [...updatedConsentArray],
      consentsTest: [...updatedConsentTestArray]
    });
  };

  shouldShowCiaOption = elem => {
    return this.state.consentsVersion === 'enterpriseConsentsVersion3'
      ? true
      : elem.optInType !== 'contentInterestAreas';
  };

  render() {
    let { campaign } = this.props;
    let isEdited = campaign && campaign.isEdited;
    let countrySelected =
      this.props.campaign &&
      this.props.campaign.country &&
      this.props.campaign.country.code;
    let isEpsilonMarket =
      countrySelected && epsilonMarket(countrySelected) ? true : false;
    if (this.props.asyncInProgress) {
      return <Loader />;
    } else {
      return (
        <div className="col-sm-12">
          <div className="cw-section ml-20">
            {this.status !== '6' ? (
              <BreadCrumb
                match={this.state.matchProps}
                // editCampaign={
                //   this.props.editCampaign || this.props.isCampaignEdited
                // }
                hideBreadCrumb={this.props.approverEditCampaign || isEdited}
                campaignType={
                  this.props.campaign && this.props.campaign.campaignType
                }
                websiteType={
                  this.props.campaign &&
                  this.props.campaign.websiteType &&
                  this.props.campaign.websiteType.name
                }
                formType={this.props.campaign && this.props.campaign.type}
                isEpsilonMarket={epsilonMarket(countrySelected)}
              />
            ) : null}

            <div className="cw-section--title mb-20">
              <h2 className="cw-heading--secondary mb-20">
                Consent and Compliance statements
              </h2>

              <div className="row">
                <div className="col-sm-7">
                  <div className="cw-section--content">
                    <div>
                      <Alert
                        alertType={'saasWarningAlertMessageWithIcon'}
                        textMessage={
                          constant.SAAS_MESSAGES.consentValidationMessage
                        }
                        showComponent={this.state.consentValidationFail}
                        openModal={this._openNewModal}
                        isLink={true}
                        modelText={'Need to hide age consent?'}
                      />

                      <Alert
                        alertType={'saasWarningAlertMessageWithIcon'}
                        textMessage={
                          constant.SAAS_MESSAGES.consentUnavailableMessage
                        }
                        showComponent={this.state.consentUnavailableValidation}
                      />
                    </div>
                    <div className="form-group">
                      <Alert
                        alertType={'saasWarningAlertMessage'}
                        textMessage={
                          constant.SAAS_MESSAGES.duplicateOptionsWarningInfo
                        }
                        showComponent={this.state.showErrorMessage}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-7">
                  <div className="cw-section--content">
                    <strong className="mb-20">Select consents version</strong>
                    <div className="form-group mt-20 mb-40">
                      <div id="consentsVersion">
                        <Select
                          className={'cw-select--custom cw-color--primary'}
                          name="consentsVersion"
                          value={constant.consentVersions.filter(
                            option =>
                              option.value === this.state.consentsVersion
                          )}
                          placeholder="Select option"
                          options={constant.consentVersions}
                          onChange={this._handleConsentVersion}
                        />
                      </div>
                      <span
                        id="consentVersionErrorMessage"
                        className="cw-error cw-hidden"
                        data-control="name"
                      >
                        <i className="fas fa-exclamation-triangle mr-10"></i>
                        Please select the consent version
                      </span>
                    </div>
                    <div className="form-group mb-40">
                      <Alert
                        alertType="warningMessageWithIcon"
                        textMessage={
                          constant.SAAS_MESSAGES.consentWarningMessage
                        }
                        showComponent={
                          this.state.consentsVersion &&
                          this.state.areConsentFieldsPopulated
                        }
                      />
                    </div>
                    {this.state.consentsVersion ? (
                      <div className="form-group mt-20">
                        <p className="cw-heading--saasconsent mb-20">
                          Consent Statements
                        </p>
                      </div>
                    ) : null}

                    {this.state.consents.map((elem, index) => {
                      const consentDefaultValue =
                        this.state.consentsTest &&
                        this.state.consentsTest[index] &&
                        this.state.consentsTest[index].defaultCopy
                          ? this.state.consentsTest[index].defaultCopy
                          : null;
                      const consentValue =
                        this.state.consents &&
                        this.state.consents[index] &&
                        this.state.consents[index].defaultCopy
                          ? this.state.consents[index].defaultCopy
                          : null;
                      return (
                        <div
                          className="accordion saas-form-field-accordion mb-20"
                          key={`${elem.dataType}_${index}`}
                        >
                          <ReactTooltip
                            place="right"
                            type="info"
                            multiline={true}
                            className="cw-tooltip"
                          />
                          <div
                            className="card"
                            style={{ border: 0, overflow: 'visible' }}
                          >
                            <div className="card-header  accordian-card-header">
                              <span
                                className="saas-accordion-heading cw-color--primary"
                                style={{ fontWeight: '700' }}
                              >
                                {elem.label}
                                {elem.optInType === 'contentInterestAreas' && (
                                  <img
                                    src={NewStarIcon}
                                    className="cia-new-icon"
                                    alt="new star icon"
                                    height={21}
                                    width={21}
                                  />
                                )}
                              </span>

                              <span
                                type="button"
                                className={`saas-accordion-arrow ${index}`}
                                onClick={() =>
                                  this.handleAccordionExpandCollapse(index)
                                }
                              >
                                <i
                                  className={
                                    this.state.expand === index
                                      ? 'fas fa-chevron-down transformIcon-180'
                                      : 'fas fa-chevron-down'
                                  }
                                ></i>
                              </span>
                              {elem.optInType !== 'legalAgeConsent' &&
                              elem.optInType !== 'privacyNotice' ? (
                                <span
                                  className={'saas-accordion-close'}
                                  onClick={() => {
                                    this.removeAccordion(index);
                                  }}
                                ></span>
                              ) : null}
                            </div>
                            <div
                              className={
                                this.state.expand === index
                                  ? 'collapse show'
                                  : 'collapse'
                              }
                            >
                              {elem.optInType === 'brandSMSConsent' ||
                              elem.optInType === 'unileverSMSConsent' ? (
                                <div className="form-group">
                                  <Alert
                                    alertType="saasInfoMessage"
                                    textMessage={
                                      constant.SAAS_MESSAGES
                                        .mobileNumberCollectionMessage
                                    }
                                  />
                                </div>
                              ) : null}
                              {elem.optInType !== 'legalDisclaimer' &&
                              elem.optInType !== 'privacyNotice' &&
                              elem.optInType !== 'contentInterestAreas' ? (
                                <>
                                  <div
                                    className="accordionDisplayOption mt-20 ml-1"
                                    key={`${elem.dataType}_${index}`}
                                  >
                                    <label>
                                      Display Options
                                      <span className="float-right  cw-icon cw-icon--help">
                                        <i
                                          className="fa fa-info-circle"
                                          onClick={this._openModal}
                                        ></i>
                                      </span>
                                    </label>
                                    <div
                                      className="form-check"
                                      style={{ width: '86%' }}
                                    >
                                      <div className="cols-sm-4 mb-10">
                                        <input
                                          type="radio"
                                          className="form-check-input"
                                          id={`mandatoryRadio_${index}`}
                                          name={`mandatoryRadio_${index}`}
                                          value={
                                            this.state.consents[index]
                                              .consentType === 'mandatory'
                                              ? true
                                              : false
                                          }
                                          checked={
                                            this.state.consents[index]
                                              .consentType === 'mandatory' ||
                                            this.state.consents[index]
                                              .consentType === 'optional'
                                              ? true
                                              : false
                                          }
                                          onChange={e =>
                                            this.handleMandatoryRadio(e, index)
                                          }
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor={`mandatoryRadio_${index}`}
                                        >
                                          Checkbox (Explicit consent)
                                        </label>
                                      </div>
                                      <div className="cols-sm-4 mb-10">
                                        <input
                                          type="radio"
                                          className="form-check-input"
                                          id={`statement_${index}`}
                                          name={`statement_${index}`}
                                          ref={this.statementRef}
                                          value={
                                            this.state.consents[index]
                                              .consentType === 'implicit'
                                              ? true
                                              : false
                                          }
                                          checked={
                                            this.state.consents[index]
                                              .consentType === 'implicit'
                                              ? true
                                              : false
                                          }
                                          onClick={e =>
                                            this.handleStatement(e, index)
                                          }
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor={`statement_${index}`}
                                        >
                                          Statement (Implied consents)
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  {this.state.consents[index].consentType !==
                                  'implicit' ? (
                                    <div className="form-group form-group-field-width">
                                      <div className="form-group d-flex ">
                                        <label className="switch-saas">
                                          <input
                                            type="checkbox"
                                            name={`mandateField_${index}`}
                                            checked={
                                              this.state.consents[index]
                                                .consentType === 'mandatory'
                                                ? true
                                                : false
                                            }
                                            onChange={e =>
                                              this.handleMandatoryToggle(
                                                e,
                                                index
                                              )
                                            }
                                          />
                                          <div className="slider-saas round"></div>
                                        </label>
                                        <label
                                          className="ml-20"
                                          htmlFor={`mandateField_${index}`}
                                        >
                                          Mandatory toggle
                                        </label>
                                      </div>
                                    </div>
                                  ) : (
                                    <>
                                      <div className="form-group">
                                        <Alert
                                          alertType="warningMessageWithIcon"
                                          textMessage={
                                            constant.CONSENT_STATEMENT_MESSAGE
                                          }
                                          showComponent={true}
                                        />
                                      </div>
                                      {elem.optInType === 'legalAgeConsent' ? (
                                        <div className="form-group">
                                          <Alert
                                            alertType="warningMessageWithIcon"
                                            textMessage={
                                              constant.AGE_STATEMENT_WARNING
                                            }
                                            showComponent={true}
                                          />
                                        </div>
                                      ) : null}
                                    </>
                                  )}
                                  {this.state.consents[index].consentType ===
                                  'mandatory' ? (
                                    <>
                                      <ReactTooltip
                                        place="right"
                                        type="info"
                                        multiline={true}
                                        className="cw-tooltip"
                                      />
                                      <div
                                        className="form-group"
                                        style={{
                                          margin: '0% 0 2% 1%'
                                        }}
                                      >
                                        <label
                                          htmlFor={`errorMessage_${index}`}
                                        >
                                          <span>Mandatory Error Message</span>
                                          <span
                                            className="float-right  cw-icon cw-icon--help"
                                            data-tip="Please specify the error message you would want users to see in case they forget to fill this field before proceeding
                                        to the next field or filled it in wrong format. Make sure to have this message in the language you selected for the form"
                                          >
                                            <i className="fas fa-question-circle"></i>
                                          </span>
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name={`errorMessage_${elem.optInType}`}
                                          id={`errorMessage_${elem.optInType}`}
                                          ref={`errorMessage_${elem.optInType}`}
                                          aria-describedby={`errorMessage_${elem.optInType}`}
                                          data-custom-error-type={`errorMessage_${elem.optInType}`}
                                          value={
                                            this.state.consents[index]
                                              .mandatoryMessage
                                          }
                                          onChange={e =>
                                            this._handleMandatoryError(
                                              e,
                                              index,
                                              elem.optInType
                                            )
                                          }
                                        />
                                        <span
                                          id={`consent_mandatoryMessage_${elem.optInType}`}
                                          className="cw-error cw-hidden"
                                          data-control="name"
                                        >
                                          <i className="fas fa-exclamation-triangle mr-10"></i>
                                          Please enter the error message
                                        </span>
                                      </div>
                                    </>
                                  ) : null}
                                </>
                              ) : null}
                              {elem.optInType === 'legalAgeConsent' ? (
                                <div>
                                  <div
                                    className="form-group"
                                    style={{ margin: '0% 0 2% 1%' }}
                                  >
                                    <label htmlFor="selectAge">
                                      <span>Minimum legal age of consent</span>
                                      <span
                                        className="float-right  cw-icon cw-icon--help"
                                        data-tip="We will use this to ensure anyone who selects a lower age cannot sign-up"
                                      >
                                        <i className="fa fa-question-circle"></i>
                                      </span>
                                    </label>
                                    {/* <input
                                      type="text"
                                      className="form-control"
                                      name="ageOfConsent"
                                      id="ageOfConsent"
                                      ref="ageOfConsent"
                                      aria-describedby="ageOfConsent"
                                      data-custom-error-type="ageOfConsent"
                                      value={
                                        this.state.consents &&
                                        this.state.consents[index] &&
                                        this.state.consents[index].ageOfConsent
                                          ? this.state.consents[index]
                                              .ageOfConsent
                                          : null
                                      }
                                      onChange={(e) =>
                                        this._handleInputChange(e, index)
                                      }
                                    /> */}
                                    <div id={`ageOfConsent`}>
                                      <Select
                                        className={
                                          'cw-select--custom cw-color--primary '
                                        }
                                        name={`ageOfConsent`}
                                        label={'ageOfConsent'}
                                        placeholder={this.state.isLegalAge}
                                        options={constant.LEGAL_AGE_VALUES.map(
                                          ele => {
                                            return { label: ele, value: ele };
                                          }
                                        )}
                                        onChange={e =>
                                          this._handleLegalAgeConsentInputChange(
                                            e,
                                            index,
                                            elem
                                          )
                                        }
                                        value={this.state.isLegalAge}
                                      />
                                    </div>
                                    {/* 
                                    <span
                                      id="ageErrorMessage"
                                      className="cw-error cw-hidden"
                                      data-control="name"
                                    >
                                      <i className="fas fa-exclamation-triangle mr-10"></i>
                                      Please provide the age of consent
                                    </span> */}

                                    <span
                                      id={`error_legalAgeValidation_${elem.dataType}`}
                                      className="cw-error cw-hidden"
                                      data-control="name"
                                    >
                                      <i className="fas fa-exclamation-triangle mr-10"></i>
                                      You need to be atleast{' '}
                                      {epsilonMarket(countrySelected) ? 13 : 16}{' '}
                                      years old to participate
                                    </span>
                                  </div>
                                </div>
                              ) : null}
                              {this.state.consents[index].consentType !==
                                'implicit' &&
                              elem.optInType === 'legalAgeConsent' ? (
                                <div className="form-group">
                                  <Alert
                                    alertType="warningMessageWithIcon"
                                    textMessage={constant.AGE_STATEMENT_WARNING}
                                    showComponent={true}
                                  />
                                </div>
                              ) : null}
                              {elem.optInType !== 'contentInterestAreas' && (
                                <div
                                  className="form-group"
                                  style={{ margin: '2% 0 2% 1%' }}
                                >
                                  <label
                                    htmlFor={`defaultCopy_${elem.optInType}`}
                                  >
                                    <span>Consent Statement</span>
                                  </label>
                                  <div id={`consent_${elem.optInType}`}>
                                    <RichTextEditor
                                      defaultValue={consentDefaultValue}
                                      value={consentValue}
                                      parentCallback={textData => {
                                        this.additionalConsentRichTextData(
                                          textData,
                                          index,
                                          elem.optInType
                                        );
                                      }}
                                    />
                                  </div>
                                  <span
                                    id={`consent_error_${elem.optInType}`}
                                    className="cw-error cw-hidden"
                                    data-control="name"
                                  >
                                    <i className="fas fa-exclamation-triangle mr-10"></i>
                                    Please provide the consent statement
                                  </span>
                                </div>
                              )}

                              {this.state.consents[index].consentType !==
                                'implicit' &&
                              elem.optInType !== 'additionalSubscription' &&
                              elem.optInType !== 'additionalSubscription1' &&
                              elem.optInType !== 'termsAndConditions' &&
                              elem.optInType !== 'legalDisclaimer' ? (
                                <div
                                  className="cw-form--action-cta"
                                  style={{ margin: '0 0 0 1%' }}
                                >
                                  <button
                                    type="submit"
                                    className="btn btn-primary"
                                    onClick={event =>
                                      this._handleReset(event, index)
                                    }
                                  >
                                    Reset to default
                                  </button>
                                </div>
                              ) : null}
                              {elem.optInType === 'additionalSubscription' ||
                              elem.optInType === 'additionalSubscription1' ? (
                                <>
                                  <div
                                    className="form-group"
                                    style={{
                                      margin: '0% 0 2% 1%'
                                    }}
                                  >
                                    <label htmlFor="frequency">
                                      <span>Frequency</span>
                                    </label>
                                    <Select
                                      className={'cw-select--custom'}
                                      label={'frequency'}
                                      name={`frequency_${elem.optInType}`}
                                      id={`frequency_${elem.optInType}`}
                                      ref={`frequency_${elem.optInType}`}
                                      aria-describedby={`frequency_${elem.optInType}`}
                                      placeholder={'Select option'}
                                      options={this.frequency}
                                      onChange={e =>
                                        this._handleFrequency(
                                          e,
                                          index,
                                          elem.optInType
                                        )
                                      }
                                      value={
                                        this.state.consents[index].frequency
                                          ? {
                                              label: this.state.consents[index]
                                                .frequency,
                                              value: this.state.consents[
                                                index
                                              ].frequency.toLowerCase()
                                            }
                                          : null
                                      }
                                    />
                                    <span
                                      id={`frequency_error_${elem.optInType}`}
                                      className="cw-error cw-hidden"
                                      data-control={`frequency_${elem.optInType}`}
                                    >
                                      <i className="fas fa-exclamation-triangle mr-10"></i>
                                      Please enter the frequency
                                    </span>
                                  </div>
                                  <ReactTooltip
                                    place="right"
                                    type="info"
                                    multiline={true}
                                    className="cw-tooltip"
                                  />
                                  <div
                                    className="form-group"
                                    style={{
                                      margin: '0% 0 2% 1%'
                                    }}
                                  >
                                    <label htmlFor={`serviceId_${index}`}>
                                      <span>Service ID (Optional)</span>
                                      <span
                                        className="float-right  cw-icon cw-icon--help"
                                        data-tip="Please add the service ID for this opt-in if you already have it. For e.g. If you are migrating your form from one platform to another, you many already have a service ID for your additional consent. If you do not have it, we will request it from Data Bureau"
                                      >
                                        <i className="fas fa-question-circle"></i>
                                      </span>
                                    </label>
                                    <input
                                      disabled={isEdited}
                                      type="text"
                                      className="form-control"
                                      name={`serviceId`}
                                      id={`serviceId_${elem.optInType}`}
                                      ref={`serviceId_${elem.optInType}`}
                                      aria-describedby={`serviceId_${elem.optInType}`}
                                      maxLength={25}
                                      value={
                                        this.state.consents[index].serviceId ||
                                        ''
                                      }
                                      onChange={e =>
                                        this._handleInputChange(e, index)
                                      }
                                      onBlur={this._handleInputOnBlur}
                                    />
                                  </div>
                                </>
                              ) : null}
                              {elem.optInType === 'brandEmailConsent' &&
                              !isEpsilonMarket ? (
                                <div
                                  style={{
                                    margin: '2% 0 2% 1%'
                                  }}
                                >
                                  <div className="mb-10">
                                    <p>
                                      <b>
                                        Please provide details for email
                                        communications
                                      </b>
                                    </p>
                                  </div>
                                  <div className="form-group">
                                    <label htmlFor="emailSenderName">
                                      <span>Email Senders Name</span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="emailSenderName"
                                      id="emailSenderName"
                                      ref="emailSenderName"
                                      maxLength="50"
                                      aria-describedby="emailSenderName"
                                      data-required="true"
                                      value={
                                        this.state.consents[index]
                                          .emailSenderName || ''
                                      }
                                      onChange={e =>
                                        this._handleInputChange(e, index)
                                      }
                                    />

                                    <span
                                      className="cw-error cw-hidden"
                                      data-control="emailSenderName"
                                      data-custom-msg="emailSenderName"
                                    >
                                      <i className="fas fa-exclamation-triangle mr-10"></i>
                                      Please enter the email sender's name
                                    </span>
                                  </div>

                                  <div className="form-group">
                                    <label
                                      htmlFor={`replyToEmailName_${index}`}
                                    >
                                      <span>
                                        Reply to Email Name (Optional)
                                      </span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="replyToEmailName"
                                      id="replyToEmailName"
                                      ref="replyToEmailName"
                                      maxLength="50"
                                      aria-describedby="replyToEmailName"
                                      value={
                                        this.state.consents[index]
                                          .replyToEmailName || ''
                                      }
                                      onChange={e =>
                                        this._handleInputChange(e, index)
                                      }
                                      onBlur={this._handleInputOnBlur}
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label htmlFor="replyToEmailAdderess">
                                      <span>
                                        Reply to Email Address (Optional)
                                      </span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="replyToEmailAdderess"
                                      id="replyToEmailAdderess"
                                      ref="replyToEmailAdderess"
                                      aria-describedby="replyToEmailAdderess"
                                      data-custom-error="true"
                                      data-custom-error-type="email"
                                      value={
                                        this.state.consents[index]
                                          .replyToEmailAdderess || ''
                                      }
                                      onChange={e =>
                                        this._handleInputChange(e, index)
                                      }
                                      onBlur={this._handleInputOnBlur}
                                    />
                                    <span
                                      className="cw-error cw-hidden"
                                      data-custom-msg="replyToEmailAdderess"
                                    >
                                      <i className="fas fa-exclamation-triangle mr-10"></i>
                                      Please enter valid email.
                                    </span>
                                  </div>
                                </div>
                              ) : null}
                              {elem.optInType === 'contentInterestAreas' ? (
                                <div
                                  style={{
                                    margin: '2% 0 2% 1%'
                                  }}
                                >
                                  <div className="mb-10">
                                    <Alert
                                      alertType="saasInfoMessageWithIcon"
                                      textMessage={
                                        constant.CIA_FIELD_INFO_MESSAGE
                                      }
                                    />
                                    <div className="form-group">
                                      <label htmlFor="consumerFacingLabel">
                                        <span>Consumer facing label</span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="consumerFacingLabel"
                                        id="consumerFacingLabel"
                                        ref="consumerFacingLabel"
                                        aria-describedby="consumerFacingLabel"
                                        data-custom-error="true"
                                        data-custom-error-type="email"
                                        value="Are there any of these areas you would like to hear more on from our brands"
                                        disabled
                                      />
                                      <span
                                        className="cw-error cw-hidden"
                                        data-custom-msg="consumerFacingLabel"
                                      >
                                        <i className="fas fa-exclamation-triangle mr-10"></i>
                                        Please enter valid label.
                                      </span>
                                    </div>
                                    <div className="form-group">
                                      <label>
                                        <span>Field values</span>
                                      </label>
                                      <ul className="cia-list">
                                        <li>Parenting &amp; Family</li>
                                        <li>Planet, Nature and Society</li>
                                        <li>Home &amp; Hygiene</li>
                                        <li>Food &amp; Recipes</li>
                                        <li>Beauty &amp; Styling</li>
                                        <li>
                                          Wellness, Nutrition and Active
                                          Lifestyle
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  {this.state.showAllPurposeError ? (
                    <div
                      className="form-group mt-20 ml-1"
                      style={{ fontWeight: '600' }}
                    >
                      <span
                        id={`all_purpose_error`}
                        className="cw-error"
                        data-control="name"
                      >
                        <i className="fas fa-exclamation-triangle mr-10"></i>
                        Please make sure you have entered all the mandatory
                        fields
                      </span>
                    </div>
                  ) : null}
                  {this.state.consentsVersion ? (
                    <div className="form-group" style={{ fontWeight: '700' }}>
                      <Select
                        className={'cw-select--custom cw-color--primary'}
                        name={'consentField'}
                        label={'Label'}
                        placeholder={'Add an additional consent field'}
                        options={
                          isEdited
                            ? this.optInType.filter(
                                elem =>
                                  elem.optInType !== 'additionalSubscription' &&
                                  elem.optInType !==
                                    'additionalSubscription1' &&
                                  this.shouldShowCiaOption(elem)
                              )
                            : this.optInType.filter(elem =>
                                this.shouldShowCiaOption(elem)
                              )
                        }
                        onChange={this._handleConsentField}
                        value={'Add an additional consent field'}
                      />
                    </div>
                  ) : null}
                  <Modal
                    isOpen={this.state.modalIsOpen}
                    onRequestClose={this._closeModal}
                    className="cw-modal cw-modal--questions"
                    contentLabel="Field Types"
                    style={{
                      overlay: {
                        backgroundColor: constant.EMAIL_OVERLAY_BACKGROUND
                      }
                    }}
                  >
                    <button
                      className="cw-modal--close"
                      title="Close"
                      onClick={this._closeModal}
                    >
                      <i className="fas fa-times"></i>
                    </button>
                    <h3 className="cw-heading--primary mb-10">
                      Display options
                    </h3>
                    <div className="col-sm-12">
                      <div className="row cw-bgpattern-primary">
                        <div className="col-sm-4">
                          <strong>Checkbox</strong>
                        </div>
                        <div className="col-sm-8">
                          <span>
                            Consumers will have to explicitly select consent
                            checkbox
                          </span>
                        </div>
                      </div>
                      <div className="row cw-bgpattern-primary">
                        <div className="col-sm-4">
                          <strong>Statement</strong>
                        </div>
                        <div className="col-sm-8">
                          <span>
                            Consumers will see the consent as a read only
                            statement on the form. Please note that you need to
                            copy the consent text over to the ‘Privacy policy’
                            text area. This is required for us to display all
                            legal/consents statements together in the form.
                          </span>
                          <br />
                          <div className="mt-2">
                            If you need a consent statement to be hidden but to
                            be captured true on submission, please mark it as a
                            statement and do not copy over the consent text to
                            the privacy policy. Please use this option only if
                            approved by DPOs. Some valid previously approved
                            usage of this feature includes brand who want to
                            display a single enterprise consent statement for
                            email and mobile instead of 2 separate statements.
                            In such cases, you select a both the consent fields
                            and choose to hide one, while ensuring both are
                            captured true on form submission.
                          </div>
                        </div>
                      </div>
                    </div>
                  </Modal>
                  <Modal
                    isOpen={this.state.isNewModalOpen}
                    onRequestClose={this._closeNewModal}
                    className="cw-modal cw-modal--questions"
                    contentLabel="Field Types"
                    style={{
                      overlay: {
                        backgroundColor: constant.EMAIL_OVERLAY_BACKGROUND
                      }
                    }}
                  >
                    <button
                      className="cw-modal--close"
                      title="Close"
                      onClick={this._closeNewModal}
                    >
                      <i className="fas fa-times"></i>
                    </button>
                    <div>
                      <h3 className="cw-heading--primary mb-10">
                        Hiding age consents
                      </h3>
                      <ul className="ml-3">
                        <li>
                          If you need to hide age consent from your sign-up
                          form, mark it as a ‘statement’ and do not add the
                          related copy in the privacy policy. Please use this
                          option only when absolutely necessary and agreed with
                          privacy/legal.
                        </li>
                        <li>Privacy policy cannot be hidden.</li>
                      </ul>
                    </div>
                  </Modal>

                  <Modal
                    scrollable={true}
                    isOpen={this.state.isCancelModalOpen}
                    onRequestClose={this._closeIsCancelModal}
                    className="cw-modal cw-modal--branddetail"
                    contentLabel="Campaign Wizard 2.0"
                    style={{
                      overlay: {
                        backgroundColor: constant.EMAIL_OVERLAY_BACKGROUND
                      }
                    }}
                  >
                    <CampaignCancelModel
                      closeIsCancelModal={this._closeIsCancelModal}
                      isCancelContinue={this._cancelSaasCampaign}
                    />
                  </Modal>
                  {this.status === '6' ? (
                    <div className="cw-form--action-cta">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        onClick={event => this._handleSubmit(event)}
                      >
                        Save
                      </button>
                      <a
                        href="javascript:void(0)"
                        className="cw-save-to-draft mt-40"
                        onClick={this._handleCancel}
                      >
                        Cancel
                      </a>
                    </div>
                  ) : (
                    <div className="cw-form--action-cta">
                      <button
                        type="button"
                        className="btn btn btn-outline-secondary mr-3"
                        onClick={this._handlebackButton}
                      >
                        Back
                      </button>
                      <button
                        type="submit"
                        className="btn btn-primary"
                        onClick={event => this._handleSubmit(event)}
                      >
                        Next
                      </button>
                      {this.props.campaign && this.props.campaign.isEdited ? (
                        <a
                          href="javascript:void(0)"
                          className="cw-cancel-edit mt-40"
                          onClick={event => this._openIsCancelModal(event)}
                        >
                          Cancel changes
                        </a>
                      ) : (
                        <a
                          href="javascript:void(0)"
                          className="cw-save-to-draft mt-40"
                          onClick={event => this._handleSubmit(event, true)}
                        >
                          Save and exit
                        </a>
                      )}
                    </div>
                  )}
                </div>
                <div className="col-sm-4">
                  {checkIsCIAEnabled(campaign) &&
                    this.state.consentsVersion ===
                      'enterpriseConsentsVersion3' && (
                      <div className="mt-43">
                        <Alert alertType="ciaNewUpdateInfo" />
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = state => ({
  campaign: state.consentAndCompliance.campaign,
  allConsents: state.consentAndCompliance.allConsents,
  asyncInProgress: state.consentAndCompliance.asyncInProgress,
  formConsentData: state.consentAndCompliance.formConsentData,
  campaignConfig: state.app.campaignConfig
});
export default connect(mapStateToProps, {
  setNotificationMessage,
  getCampaignDetails,
  setApproverEditCampaignStatusMessage,
  setApproverEditCampaignStatus,
  cancelCampaignChanges
})(SaasConsentCompliance);
