import styled from 'styled-components';

export const Box = styled.div`
  background: var(--color-dark);
  width: 100%;
  height: 146px;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1000px;
  margin: 0 auto;
  /* background: red; */
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-align: left;
`;

export const Row = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: auto auto minmax(185px, 1fr) minmax(185px, 1fr);
  grid-column-gap: 2rem;
  align-items: center;
  @media (max-width: 1000px) {
    grid-template-columns: auto auto minmax(180px, 1fr) minmax(180px, 1fr);
  }
`;

export const FooterLink = styled.a`
  color: #fff !important;
  font-size: 13px;
  text-decoration: none !important;

  &:hover {
    color: #fff !important;
    transition: 200ms ease-in;
    text-decoration: underline !important;
  }
`;

export const Heading = styled.p`
  font-size: 24px;
  color: #fff;
  margin-bottom: 40px;
  font-weight: bold;
`;
