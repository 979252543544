
import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import carouselForm from "images/Carousel-form-1.png";
import carouselSms from "images/Carousel-sms-1.png";
import carouselEmail from "images/Carousel-email-1.png";

function CarouselBasic() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <>
    <Carousel controls={false} activeIndex={index} onSelect={handleSelect} wrap={false} interval={5000} slide={false}>
      <Carousel.Item>
        <img
          className="d-block carousel--image"
          src={carouselForm}
          alt="First slide"
        />
        <Carousel.Caption>
          <p className='carousel--heading'>Data Acquisition</p>
          <p className='carousel--subheading'>Create sign up forms or collect master data for custom integrations and offline ingestion</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block carousel--image"
          src={carouselSms}
          alt="Second slide"
        />
        <Carousel.Caption>
          <p className='carousel--heading'>SMS Campaigns</p>
          <p className='carousel--subheading'>Brief SMS communications to leverage consumer data for improve marketing relevancy</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block carousel--image"
          src={carouselEmail}
          alt="Third slide"
        />
        <Carousel.Caption>
          <p className='carousel--heading'>Email Campaigns</p>
          <p className='carousel--subheading'>Build beautiful email campaigns to nurture ongoing and meaningful consumer relationships</p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
    </>
  );
}

export default CarouselBasic;