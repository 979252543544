import React, { useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import HistoryComponent from 'components/history';
import { fetchBrandDefaultsHistoryLogs } from 'actions/brandDefaultsHistoryLogsActions';
import { useDispatch, useSelector } from 'react-redux';

export default function BrandHistoryComponent({ control }) {
  const dispatch = useDispatch();
  const brand = useWatch({ name: 'brand', control, defaultValue: { id: '' } });

  const historyLogs = useSelector(state => state.brandDefaultsHistoryLogs);

  useEffect(() => {
    if (brand.id) {
      dispatch(fetchBrandDefaultsHistoryLogs(brand.id));
    }
  }, [brand.id]);
  return (
    <div className="mt-30">
      {historyLogs.length ? (
        <HistoryComponent isEmailBrief historyLogs={historyLogs} />
      ) : null}
    </div>
  );
}
