import { FETCH_BRAND_DEFAULTS_HISTORY_LOGS_SUCCESS } from '../actionTypes';

const initialState = [];

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_BRAND_DEFAULTS_HISTORY_LOGS_SUCCESS: {
      return action.payload;
    }
    default:
      return state;
  }
}
