import React from 'react';
import Modal from 'react-modal';
import GuidelineWrapper from '../../../atoms/GuidelineWrapper/GuidelineWrapper';
import RejectionCommentsList from './RejectionCommentsList';
import constants from 'constants/config';

const CampaignRejectionListModal = ({ isOpen, onClose }) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onClose}
    className="cw-modal cw-modal--qnadetail"
    contentLabel="Field Types"
    style={{
      overlay: {
        backgroundColor: constants.EMAIL_OVERLAY_BACKGROUND
      }
    }}
  >
    <div className="guideline-wrapper">
      <span
        className="qna-guideline-close qna-guideline-close-modal"
        onClick={onClose}
      ></span>
      <strong className="mb-3">Rejection comments</strong>
      <RejectionCommentsList />
      <div className="mr-20 mt-3">
        <button
          type="submit"
          onClick={onClose}
          className="btn btn-primary mb-10 cw-print-hide text-nowrap  mr-1 guideline-close-button"
        >
          Close
        </button>
      </div>
    </div>
  </Modal>
);

export default CampaignRejectionListModal;
