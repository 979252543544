import axios from 'axios';
import _, { orderBy } from 'lodash';
import constant from '../constants';
import { filterToQuery } from '../selectors';

export async function deleteSMSCampaignByUid(payload) {
  try {
    let response = await axios.post(
      `${constant.communications.POST_SMS_BRAND_DETAIL}/delete`,
      payload
    );
    if (response) {
      return response.data;
    }
    return {};
  } catch (e) {
    throw e;
  }
}

export async function uploadSMSCoupon(data) {
  try {
    console.log(
      'link :',
      constant.communications.POST_SMS_BRAND_DETAIL + '/couponupload'
    );
    const response = await axios.post(
      constant.communications.POST_SMS_BRAND_DETAIL + '/couponupload',
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );
    if (response) {
      return response.data;
    }
    return {};
  } catch (e) {
    console.log('getEmailBriefingCount e: ', e.message);
    throw e;
  }
}
/**
 * Download Cost Estimate
 * @param {*} payload
 * @returns
 */
export async function downloadSMSCostEstimate(uid) {
  try {
    let response = await axios.get(
      `${constant.communications.DOWNLOAD_SMS_COST_ESTIMATE}?uid=${uid}`,
      { responseType: 'blob' }
    );
    if (response) {
      return response;
    }
    return {};
  } catch (e) {
    throw e;
  }
}

/**
 * Download Purchase Order
 * @param {*} payload
 * @returns
 */
export async function downloadSMSPurchaseOrder(uid) {
  try {
    let response = await axios.get(
      `${constant.communications.DOWNLOAD_SMS_PURCHASE_ORDER}?uid=${uid}`,
      { responseType: 'blob' }
    );
    if (response) {
      return response;
    }
    return {};
  } catch (e) {
    throw e;
  }
}
