import React, { useEffect, useState, forwardRef } from 'react';
import { Input } from '../../atoms/Input';
import { FieldError } from '../../atoms/FieldError';
import { get, uniqueId, set, cloneDeep, find } from 'lodash';
import { LinkButton } from 'components/atoms/LinkButton';
import { UnstyledButton } from 'components/atoms/UnstyledButton';
import constants from '../../../constants';
import classNames from 'classnames';

const getId = () => {
  return uniqueId('field_');
};

export const CustomFormFieldArrayV3 = forwardRef(
  ({ name, type, rules, label, errors, value: fields, ...props }, ref) => {
    const errorMessages = get(errors, name);
    const hasError = !!(errors && errorMessages);
    const [fieldError, setFieldError] = useState('');
    // const [fields, setfields] = useState([
    //   {
    //     permissionGivenBy: props.user.emailId,
    //     value: '',
    //     permissionGivenAt: new Date()
    //   }
    // ]);
    let modifiedError = cloneDeep(errors);
    if (fieldError) {
      //const errorMesg = get(modifiedError, name);
      set(modifiedError, name, { message: fieldError });
    }
    const handleSetField = givenFields => {
      //setfields(givenFields);
      props.onChange(givenFields);
    };

    useEffect(() => {
      let isEdit = false;
      if (props.defaultValue) {
        let savedFields = props.defaultValue;
        handleSetField(savedFields);
        isEdit = true;
      }
      if (props.showAtleastOne && !isEdit) {
        handleSetField([{ id: getId(), value: '' }]);
      }
    }, []);

    const remove = index => {
      const tempfields = [...fields];
      tempfields.splice(index, 1);
      handleSetField(tempfields);
    };

    const validateInput = field => {
    const fieldList = fields.slice(0, fields.length-1)
      if (field.value === '') {
        setFieldError('Please enter your collaborator’s email address');
        return true;
      }
      const customValidation = constants.regex.emailAddress;
      if (customValidation && !customValidation.test(field.value)) {
        setFieldError('Please enter a valid email address');
        return true;
      }
      if(!constants.EMAIL_SENDTEST_VALID_DOMAINS.includes(field.value.split('@')[1])){
        setFieldError('Please enter a valid email. Only emails on domain unilever.com are allowed.');
        return true;
      }
      if (find(fieldList, ['value', field.value])) {
        setFieldError('This email address is already been added');
        return true;
      }
      return false;
    };

    const handleBlur = () => {
      props.onBlur();
      if (hasError) {
        props.trigger(name);
      }
    };

    const handleFocus = () => {
      if (fieldError) {
        setFieldError('');
      }
    };

    const append = data => {
      const tempfields = [...fields];
      const lastValue = tempfields[tempfields.length - 1];
      const transformedText = {
        value: lastValue.value.trim() ? lastValue.value.toLowerCase() : ''
      };

      if (!validateInput(transformedText)) {
        tempfields.push({
          permissionGivenBy: props.user.emailId,
          value: '',
          permissionGivenAt: new Date()
        });
        handleSetField(tempfields);
        setFieldError('');
      }

      //setInputValue('');
    };

    const handleChange = (index, event) => {
      const fieldToModify = fields[index];
      const tempfields = [...fields];
      tempfields.splice(index, 1, {
        id: fieldToModify.id,
        value: event.target.value
      });
      handleSetField(tempfields);
    };

    const handleInputChange = event => {
      const tempfield = [...fields];
      tempfield[tempfield.length - 1] = {
        permissionGivenBy: props.user.emailId,
        value: event.target.value,
        permissionGivenAt: new Date()
      };
      handleSetField(tempfield);
    };

    const finalFields = fields.filter((item, index) => {
      return index === fields.length - 1 ? false : true;
    });
    return (
      <div className="form-group">
        {label && <label htmlFor={props.id}>{label}</label>}

        <div className="d-flex justify-content-start align-items-center mt-10">
          <div className="w-75">
            <input
              type="text"
              name={`${name}`}
              value={get(fields, `[${fields.length - 1}].value`, '')}
              onChange={event => handleInputChange(event)}
              className={classNames('form-control', {
                'is-invalid': hasError || fieldError
              })}
              onFocus={handleFocus}
              ref={ref}
              disabled={fields.length >= 6}
              //onBlur={handleBlur}
              //hasError={hasError}
            />
          </div>
          <div>
            <button
              type="button"
              className="btn-circle btn-remove ml-10"
              onClick={() => append({ value: '' })}
              disabled={fields.length >= 6}
            >
              <i className="fas fa-plus"></i>
            </button>
          </div>
        </div>
        {/* {!hasError && fieldError && (
          <span className="cw-error" data-control={name}>
            <i className="fas fa-exclamation-triangle mr-10"></i>
            {fieldError}
          </span>
        )} */}
        <FieldError errors={modifiedError} name={`${name}`} />
        <div className="d-flex justify-content-start flex-wrap w-75 mt-10">
          {finalFields.map((field, index) => {
            return (
              <div
                className="d-flex justify-content-start align-items-center mr-20"
                key={index}
              >
                <span>{field.value}</span>
                <UnstyledButton onClick={() => remove(index)} className="ml-2">
                  <i className="fas fa-times"></i>
                </UnstyledButton>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
);
