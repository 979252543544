// Dashboard Reducer
import { GET_CAMPAIGN_HISTORY_LOGS } from '../actionTypes';

const initialState = {
	historyLogs: [],
};

export default function(state = initialState, action) {
	switch (action.type) {
		case GET_CAMPAIGN_HISTORY_LOGS: {
			return action.historyLogs;
		}
		default:
			return state;
	}
}
