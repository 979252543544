import {
    EMAIL_OPEN_SAVE_TEMPLATE,
    EMAIL_CLOSE_SAVE_TEMPLATE
  } from '../../actionTypes';
  
  const initialState = {
    isOpen: false,
    templateInfo: null,
    templateHtml: null
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
      case EMAIL_OPEN_SAVE_TEMPLATE: {
        return { ...state, ...action.payload };
      }
      case EMAIL_CLOSE_SAVE_TEMPLATE: {
        return { ...state, isOpen: action.payload.isOpen, templateInfo: null, templateHtml: null };
      }
      default:
        return state;
    }
  }
