import React, { useEffect, useState, useMemo } from 'react';
import allCampaign from '../../images/allCampaign.png';
import requesterImage from '../../images/requester.png';
import approverImage from '../../images/approver.png';
import masterDataImage from '../../images/master-data.png';
import brandMarketImage from '../../images/brand-market.png';
import marketImage from '../../images/market.png';
import brandImage from '../../images/brand.png';
import { DashboardCard } from '../organisms/DashboardCard/DashboardCard';
import { CampaignCharts } from '../organisms/CampaignCharts/CampaignCharts';
import { getDashboardMatrix } from '../../actions/dashboard';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import constant from '../../constants';
import { getAuthoringFieldData } from 'actions/saasFormAuthoring';
import ReportSectionHeading from '../atoms/ReportSectionHeading';
import FaqStats from '../molecules/FaqStats';
import FieldStatsAccordion from '../organisms/FieldStatsAccordion';
import DashStatsBox from '../organisms/DashStatsBox';
import StatsBoxTitle from '../molecules/StatsBoxTitle';
import { getChartData, getDateRange } from '../../selectors/index';
import RadioDropDown from '../RadioDropDown';
import constants from '../../constants';
import { get } from 'lodash';

const ConsumerInsight = props => {
  const dispatch = useDispatch();
  const [questionList, setQuestionList] = useState([]);
  const fetchQuestionList = async () => {
    let queUrl = constant.serviceUrls.FETCH_ALL_QUESTIONS + '?language=' + 'en';
    await axios.get(queUrl).then(response => {
      let data = response && response.data;
      setQuestionList(data);
    });
  };
  useEffect(() => {
    dispatch(getAuthoringFieldData('EN', 'Direct messaging', 'GB'));
    fetchQuestionList();
  }, []);
  let campaignMatrix = props.campaignMatrix;
  const consumerInsightDashStats = useMemo(() => {
    return [
      {
        title: 'Mobile only forms',
        count: get(campaignMatrix, 'mobile_forms.value', '51'),
        percent: `${get(campaignMatrix, 'mobile_forms.percentage', '20')}%`
      },
      {
        title: 'New enrichment questions',
        count: get(
          campaignMatrix,
          'new_data_enrichment_questions.value',
          '661'
        ),
        percent: `${get(
          campaignMatrix,
          'new_data_enrichment_questions.percentage',
          '60'
        )}%`
      },
      {
        title: 'New operational questions',
        count: get(campaignMatrix, 'new_operational_questions.value', '661'),
        percent: `${get(
          campaignMatrix,
          'new_operational_questions.percentage',
          '60'
        )}%`
      }
    ];
  }, [campaignMatrix]);

  const faqData = campaignMatrix && campaignMatrix.consumer_insights_faq;
  return (
    <div className="mt-40">
      <div className="row">
        <div className="col-sm-4 mb-30">
          <StatsBoxTitle
            title={<strong>Usage of questions</strong>}
            className="pb-20"
          />
          <FieldStatsAccordion
            data={campaignMatrix && campaignMatrix.consumerInsights}
            startingIndex={0}
          />
        </div>
        <div className="col-sm-8 mb-30 pl-2">
          <StatsBoxTitle
            title={<strong>Frequently asked questions</strong>}
            className="pb-20"
          />
          <FaqStats questions={faqData || questionList} />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 pt-60">
          <DashStatsBox list={consumerInsightDashStats} />
        </div>
      </div>
    </div>
  );
};

export default ConsumerInsight;
