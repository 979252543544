// Dashboard Reducer
import {
  UPDATE_CAMPAIGN_TYPE,
  ASYNC_IN_PROGRESS,
  GET_CAMPAIGN,
  UDPATE_CAMPAIGN_TYPE_DETAILS
} from '../actionTypes';
import constants from '../constants';

const initialState = {
  type: '',
  formType: '',
  campaignType: '',
  incentiveType: '',
  asyncInProgress: false,
  campaignTypeUpdated: false,
  campaignTypeloaded: false,
  brand: '',
  isCampaignEdited: false,
  nonGigyaSignupForm: false,
  campaign: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case UPDATE_CAMPAIGN_TYPE: {
      let type = {
        type: action.campaignTypeData.type || '',
        formType: action.campaignTypeData.formType || '',
        campaignType: action.campaignTypeData.campaignType,
        incentiveType: action.campaignTypeData.incentiveType,
        campaignTypeUpdated: true
      };
      return { ...state, ...type };
    }
    case ASYNC_IN_PROGRESS: {
      let asyncInProgress = { asyncInProgress: action.isLoading };
      return { ...state, ...asyncInProgress };
    }
    case GET_CAMPAIGN: {
      let campaignType = {
        type:
          action.campaign.type &&
          action.campaign.campaignType &&
          action.campaign.campaignType.toLowerCase() === 'direct messaging'
            ? action.campaign.type
            : '',
        formType:
          action.campaign.type &&
          action.campaign.campaignType &&
          action.campaign.campaignType.toLowerCase() === 'incentive'
            ? action.campaign.type
            : '',
        campaignType: action.campaign.campaignType || '',
        incentiveType: action.campaign.incentiveType || '',
        brand: (action.campaign.brand && action.campaign.brand.name) || '',
        campaignTypeloaded: true,
        campaignTypeUpdated: false,
        isCampaignEdited: action.campaign && action.campaign.isEdited,
        websiteType: {
          value:
            action.campaign.websiteType.code +
            '-' +
            action.campaign.websiteType._id,
          label: action.campaign.websiteType.name
        },
        isPlatformAgnostic: action.campaign.isPlatformAgnostic,
        autogenerate: action.campaign.autogenerate,
        isMasterData: action.campaign.isMasterData,
        incentivePromotionalPartner:
          action.campaign && action.campaign.incentivePromotionalPartner,
        incentivePromotionalOtherPartner: action.campaign && action.campaign.incentivePromotionalOtherPartner
      };
      // make the form platform agnostic if AEM option is diabled
      if (!constants.featureFlags.ENABLE_AEM_FORM_OPTION && !action.isEdit) {
        // campaignType.isPlatformAgnostic = true;
        campaignType.autogenerate = false;
      }
      let campaign = { campaign: action.campaign };
      return { ...state, ...campaignType, ...campaign };
    }
    case UDPATE_CAMPAIGN_TYPE_DETAILS: {
      let campaignDetails = {
        type: action.campaignDetails.type,
        formType: action.campaignDetails.formType,
        campaignType: action.campaignDetails.campaignType || '',
        incentiveType: action.campaignDetails.incentiveType || '',
        autogenerate: action.campaignDetails.autogenerate || false,
        isPlatformAgnostic: action.campaignDetails.isPlatformAgnostic || false,
        isMasterData: action.campaignDetails.isMasterData || false,
        incentivePromotionalPartner: action.campaignDetails.incentivePromotionalPartner || '',
        incentivePromotionalOtherPartner: action.campaignDetails.incentivePromotionalOtherPartner || ''
      };
      return { ...state, ...campaignDetails };
    }

    default:
      return state;
  }
}
