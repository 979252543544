import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import {
  fetchCampaignData,
  updateProfileStore,
  savePrmData
} from '../actions/prmData';
import { fetchHistoryLogs } from '../actions/history';
import {
  scrollToTop,
  textCapitalization,
  showGigyaIdScreen,
  getCampaignStatusText,
  statusBadge,
  checkCampaignLanguage
} from '../selectors/index';
import CampaignDetailsInfo from './CampaignDetailsInfo';
import { setNotificationMessage } from '../actions/dashboard';

import MasterDataQuestion from './MasterDataQuestion';
import Loader from './Loader';
import ErrorNotification from './errorNotification';
import Alert from './Alert';
import constant from '../constants';
import HistoryComponent from '../components/history';
import axios from 'axios';
class PrmData extends Component {
  constructor(props) {
    super(props);
    // TODO: Move this state into redux
    this.state = {
      dataSubmitted: false,
      showMessageAtTop: false
    };
    this.formElements = {
      url: this.props.profileStore.url || '',
      accesskey: this.props.profileStore.accesskey || '',
      secretkey: this.props.profileStore.secretkey || ''
    };
    this.notificationData = {
      message: '',
      link: '',
      type: '',
      campaign: ''
    };
    this.errorFields = [];
    this.isValid = false;
    this.campaignId = '';
    this._handleRejectButton = this._handleRejectButton.bind(this);
    this._handleApproveButton = this._handleApproveButton.bind(this);
    this._handleSubmit = this._handleSubmit.bind(this);
    this._handleChange = this._handleChange.bind(this);
    this._saveAsDraft = this._saveAsDraft.bind(this);
    this._validateField = this._validateField.bind(this);
    this._addErrorFields = this._addErrorFields.bind(this);
    this._filterErrorFields = this._filterErrorFields.bind(this);
    this._scrollToTop = this._scrollToTop.bind(this);
    this._handleCollapse = this._handleCollapse.bind(this);

    this.collapsePanel = React.createRef();
    this.collapseHandlerIcon = React.createRef();
    this.collapseHandlerText = React.createRef();

    //MasdterData Collapsible Panel
    this.collapseMasterDataPanel = React.createRef();
    this.collapseMasterDataHandlerIcon = React.createRef();
    this.collapseMasterDataHandlerText = React.createRef();
  }

  _handleCollapse(event, type) {
    event.preventDefault();
    let collapisblePanel,
      collapseHandlerIcon,
      collapseHandlerText,
      collapseHandlerTextLess,
      collapseHandlerTextMore;

    collapisblePanel = this.collapseMasterDataPanel;
    collapseHandlerIcon = this.collapseMasterDataHandlerIcon;
    collapseHandlerText = this.collapseMasterDataHandlerText;
    collapseHandlerTextLess = 'View less Master Data IDs';
    collapseHandlerTextMore = 'View more Master Data IDs';

    if (collapisblePanel.current.classList.contains('show')) {
      collapisblePanel.current.classList.remove('show');
      collapseHandlerIcon.current.classList.remove('fa-chevron-up');
      collapseHandlerIcon.current.classList.add('fa-chevron-down');
      collapseHandlerText.current.innerHTML = collapseHandlerTextMore;
    } else {
      collapisblePanel.current.classList.add('show');
      collapseHandlerText.current.innerHTML = collapseHandlerTextLess;
      collapseHandlerIcon.current.classList.remove('fa-chevron-down');
      collapseHandlerIcon.current.classList.add('fa-chevron-up');
    }
  }

  _handleChange(e) {
    this.formElements[e.target.name] = e.target.value;
    this._validateField(e.target.name);
    this.props.updateProfileStore({ [e.target.name]: e.target.value });
  }

  _validateField(field) {
    if (
      document
        .querySelector("[name='" + field + "']")
        .parentElement.classList.contains('error')
    ) {
      document
        .querySelector("[name='" + field + "']")
        .parentElement.classList.remove('error');
    }
    if (
      !document
        .querySelector("[data-control='" + field + "']")
        .classList.contains('cw-hidden')
    ) {
      document
        .querySelector("[data-control='" + field + "']")
        .classList.add('cw-hidden');
    }
  }
  _validateForm(event) {
    let formElements = this.formElements;
    for (var key in formElements) {
      if (formElements.hasOwnProperty(key)) {
        let selector = document.querySelector("[name='" + key + "']");
        if (selector) {
          if (formElements[key] !== '') {
            if (selector.parentElement.classList.contains('error')) {
              selector.parentElement.classList.remove('error');
            }
            if (document.querySelector("[data-control='" + key + "']")) {
              if (
                !document
                  .querySelector("[data-control='" + key + "']")
                  .classList.contains('cw-hidden')
              ) {
                document
                  .querySelector("[data-control='" + key + "']")
                  .classList.add('cw-hidden');
              }
            }
            this.errorFields = this._filterErrorFields(this.errorFields, key);
          } else {
            if (!selector.parentElement.classList.contains('error')) {
              selector.parentElement.classList.add('error');
            }
            if (document.querySelector("[data-control='" + key + "']")) {
              if (
                document
                  .querySelector("[data-control='" + key + "']")
                  .classList.contains('cw-hidden')
              ) {
                document
                  .querySelector("[data-control='" + key + "']")
                  .classList.remove('cw-hidden');
              }
            }
            this.errorFields = this._addErrorFields(this.errorFields, key);
          }
        }
      }
    }
    if (this.errorFields.length === 0) {
      this.isValid = true;
      this.setState({ showMessageAtTop: false });
    } else {
      this.setState({ showMessageAtTop: true });
      scrollToTop();
    }
  }

  _handleSubmit(event) {
    this._validateForm(event);

    if (this.isValid) {
      this.props
        .savePrmData(
          this.campaignId,
          this.props.profileStore,
          this.props.userName,
          this.props.userEmail
        )
        .then(response => {
          this.notificationData.message = 'has been assigned PRM keys.';
          this.notificationData.campaign = this.props.campaign.name;
          this.notificationData.type = 'success';
          this.props.setNotificationMessage(
            this.notificationData.message,
            this.notificationData.link,
            this.notificationData.type,
            this.notificationData.campaign
          );

          this.props.history.push('/campaign-wizard');
        });
    }
  }
  _saveAsDraft() {
    this.props
      .savePrmData(
        this.campaignId,
        this.props.profileStore,
        this.props.userName,
        this.props.userEmail,
        true
      )
      .then(response => {
        this.notificationData.message = 'has been saved as draft.';
        this.notificationData.campaign = this.props.campaign.name;
        this.notificationData.type = 'warning';
        this.props.setNotificationMessage(
          this.notificationData.message,
          this.notificationData.link,
          this.notificationData.type,
          this.notificationData.campaign
        );

        this.props.history.push('/campaign-wizard');
      });
  }

  _addErrorFields(array, itemToAdd) {
    return array.filter(field => field !== itemToAdd).concat([itemToAdd]);
  }
  _filterErrorFields(array, itemToRemove) {
    return array.filter(item => item !== itemToRemove);
  }

  _handleRejectButton() {
    return true;
  }

  _handleApproveButton() {
    return true;
  }

  componentDidMount() {
    this.campaignId = this.props.match.params.id;
    this.notificationData.link = '/campaign-wizard/campaign/' + this.campaignId;
    this.props.fetchCampaignData(this.campaignId);
    this.props.fetchHistoryLogs(this.campaignId);
  }

  componentWillReceiveProps(nextProps, nextState) {
    if (nextProps.dataloaded) {
      let showGigyaScreen = showGigyaIdScreen(nextProps.campaign);
      if (showGigyaScreen) {
        this.props.history.push('/campaign-wizard');
      }
    }
    if (nextProps.url || nextProps.accesskey || nextProps.secretkey) {
      this.formElements = {
        url: this.props.profileStore.url || '',
        accesskey: this.props.profileStore.accesskey || '',
        secretkey: this.props.profileStore.secretkey || ''
      };
    }
  }

  _scrollToTop() {
    window.scroll({
      top: -100,
      left: 0,
      behavior: 'smooth'
    });
  }

  _handleRefresh = () => {
    let { campaign } = this.props;
    axios
      .post(constant.serviceUrls.CAMPAIGN_INFRA_API_CALL, campaign)
      .then(response => {
        this.campaignId = this.props.match.params.id;
        this.props.fetchCampaignData(this.campaignId);
      });
  };

  render() {
    let {
      campaign,
      campaignDetails,
      dataSubmitted,
      additionalOptins,
      questionAnswerList,
      asyncInProgress
    } = this.props;

    this.formElements = {
      url: this.props.profileStore.url || '',
      accesskey: this.props.profileStore.accesskey || '',
      secretkey: this.props.profileStore.secretkey || ''
    };

    let statusClass = classNames(statusBadge(this.props.campaign));
    let getCampaignStatus = getCampaignStatusText(this.props.campaign);
    let campaignLanguage =
      campaign && campaign.language && campaign.language.name;
    let isCampaignNonEnglish = checkCampaignLanguage(campaignLanguage);
    let isCampaignIncentive =
      campaign && campaign.campaignType === 'Incentive' ? true : false;

    return (
      <>
        {asyncInProgress ? <Loader /> : null}
        <div className="cw-section cw-overview">
          <div className="colsm-12 mb-30">
            <div className="col-sm-6">
              <NavLink
                className="cw-color--primary cw-breadcrum"
                to={'/campaign-wizard'}
              >
                <i className="fas fa-angle-left"></i> Campaigns
              </NavLink>
            </div>
          </div>

          <Alert
            type={'warning'}
            alertType={'cancelcampaign'}
            campaignName={this.props.campaign && this.props.campaign.name}
            message={'has been cancelled. No action required.'}
            showComponent={
              this.props.campaign &&
              this.props.campaign.status &
                (this.props.campaign.status ===
                  constant.campaignStatus.CANCELLED)
            }
          />

          {/* Alert for failure of campaign page generation*/}
          <Alert
            alertType={'nonPublished'}
            showComponent={
              campaign.status === constant.campaignStatus.PROCESSING
            }
          />

          {!this.isValid && this.state.showMessageAtTop ? (
            <ErrorNotification />
          ) : null}
          <div className="col-sm-10 mb-30">
            <h2 className="cw-heading--secondary d-flex align-items-center mb-10">
              <span className="mr-3">Submit PRM connection details</span>
              <span className={statusClass}>{getCampaignStatus}</span>
            </h2>
            <p>Enter and submit the PRM connection details.</p>
          </div>
          {/* Campaign Details */}
          <CampaignDetailsInfo
            campaign={campaign}
            campaignDetails={campaignDetails}
            panelref={this.collapsePanel}
            collapseHandlerIconref={this.collapseHandlerIcon}
            collapseHandlerTextref={this.collapseHandlerText}
            handleRefresh={this._handleRefresh}
          />

          <div className="col-sm-12">
            <div className="cw-campaign--review-section mb-30">
              <div className="mb-20">
                <h3 className="cw-heading--primary">Master Data IDs</h3>
              </div>
              <div className="cw-campaign--review-section-content">
                <div className="cw-striped-row">
                  <div className="row">
                    <div className="col-sm-4">
                      <strong>Campaign ID</strong>
                    </div>
                    <div className="col-sm-4"></div>
                    <div className="col-sm-4">
                      <input
                        type="text"
                        className="form-control"
                        name="campaignId"
                        id="campaignId"
                        disabled="disabled"
                        data-required="true"
                        value={campaign.campaignId || ''}
                        onChange={this._handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="collapse"
            id="MasterIDCollapsed"
            ref={this.collapseMasterDataPanel}
          >
            <div className="col-sm-12">
              <div className="cw-campaign--review-section mb-30">
                <div className="mb-20">
                  <h3>Consent IDs</h3>
                </div>
                <div className="cw-campaign--review-section-content">
                  <div className="cw-striped-row">
                    <div className="row">
                      <div className="col-sm-3">
                        <strong>{this.props.brandoptin.text}</strong>
                      </div>
                      <div className="col-sm-5">
                        <p>
                          Please tick the box to receive offers and competitions
                          about (brand name ) by email.
                        </p>
                      </div>
                      <div className="col-sm-4">
                        <input
                          type="text"
                          className="form-control"
                          name={this.props.brandoptin.text}
                          id={this.props.brandoptin.text}
                          value={this.props.brandoptin.id || ''}
                          disabled={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="cw-striped-row">
                    <div className="row">
                      <div className="col-sm-3">
                        <strong>Unilever</strong>
                      </div>
                      <div className="col-sm-5">
                        <p>
                          Please tick the box to receive exciting news, offers
                          and competitions from other Unilever brands by email.
                        </p>
                      </div>
                      <div className="col-sm-4">
                        <input
                          type="text"
                          className="form-control"
                          name={this.props.unileveroptin.text}
                          id={this.props.unileveroptin.text}
                          value={this.props.unileveroptin.id || ''}
                          disabled={true}
                        />
                      </div>
                    </div>
                  </div>
                  {!isCampaignIncentive && additionalOptins.length
                    ? additionalOptins.map((optin, index) => {
                        return (
                          <Fragment key={index}>
                            <div className="cw-striped-row">
                              <div className="row">
                                <div className="col-sm-3">
                                  <strong>
                                    {'Regular communication (' +
                                      (index + 1) +
                                      ')'}
                                  </strong>
                                </div>
                                <div className="col-sm-5">
                                  <p>{optin.text}</p>
                                </div>
                                <div className="col-sm-4">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter ID"
                                    name={'additionalOptinList-' + index}
                                    id={'additionalOptinList-' + index}
                                    value={optin.id || ''}
                                    disabled={true}
                                  />
                                </div>
                              </div>
                            </div>
                            <div key={index} className="cw-striped-row">
                              <div className="row">
                                <div className="col-sm-3">
                                  <strong>
                                    {'Frequency of regular communication (' +
                                      (index + 1) +
                                      ')'}
                                  </strong>
                                </div>
                                <div className="col-sm-5">
                                  <p>
                                    {optin.frequency
                                      ? textCapitalization(optin.frequency)
                                      : ''}
                                  </p>
                                </div>
                                <div className="col-sm-4"></div>
                              </div>
                            </div>
                          </Fragment>
                        );
                      })
                    : null}
                  <div className="cw-striped-row">
                    <div className="row">
                      <div className="col-sm-3">
                        <strong>Consent channel</strong>
                      </div>
                      <div className="col-sm-5">
                        {campaignDetails.emailChannel ? 'Email' : ''}
                        {campaignDetails.smsChannel &&
                        campaignDetails.emailChannel
                          ? ','
                          : ''}
                        {campaignDetails.smsChannel ? 'SMS' : ''}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {questionAnswerList.length &&
            campaign.type === 'Sign-up with Questions & Answers' ? (
              <MasterDataQuestion
                questionAnswerList={questionAnswerList}
                campaignLanguage={campaignLanguage || ''}
                disabled={true}
                isCampaignNonEnglish={isCampaignNonEnglish}
              />
            ) : null}
          </div>
          <div className="col-sm-12">
            <div className="cw-campaign--review-section mb-30">
              <a
                className="collapse-h pt-2"
                href="#MasterIDCollapsed"
                role="button"
                aria-expanded="false"
                aria-controls="MasterIDCollapsed"
                onClick={event => this._handleCollapse(event, 'masterdata')}
              >
                <span ref={this.collapseMasterDataHandlerText}>
                  View more Master Data IDs
                </span>{' '}
                <i
                  className="fas fa-chevron-down ml-1"
                  ref={this.collapseMasterDataHandlerIcon}
                ></i>
              </a>
            </div>
          </div>
          {/* Campaign APi Details */}
          <div className="col-sm-12">
            <div className="cw-campaign--review-section mb-30">
              <div className="mb-20">
                <h3 className="cw-heading--primary">
                  <span>PRM </span>
                  connection details
                </h3>
              </div>
              <div className="cw-campaign--review-section-content mb-40">
                <div className="cw-striped-row">
                  <div className="row">
                    <div className="col-sm-4">
                      <strong>Campaign URL</strong>
                    </div>
                    <div className="col-sm-4"></div>
                    <div className="col-sm-4">
                      <input
                        type="text"
                        placeholder="Awaiting URL"
                        className="form-control"
                        name="url"
                        id="url"
                        data-required="true"
                        disabled={
                          this.props.campaign.status ===
                          constant.campaignStatus.CANCELLED
                        }
                        value={this.props.profileStore.url || ''}
                        onChange={this._handleChange}
                      />
                      <span className="cw-error cw-hidden" data-control="url">
                        <i className="fas fa-exclamation-triangle mr-10"></i>
                        Please enter Campaign Url.
                      </span>
                    </div>
                  </div>
                </div>
                <div className="cw-striped-row">
                  <div className="row">
                    <div className="col-sm-4">
                      <strong>Secret Key</strong>
                    </div>
                    <div className="col-sm-4"></div>
                    <div className="col-sm-4">
                      <input
                        type="text"
                        placeholder="Awaiting Key"
                        className="form-control"
                        name="secretkey"
                        id="secretkey"
                        data-required="true"
                        disabled={
                          this.props.campaign.status ===
                          constant.campaignStatus.CANCELLED
                        }
                        value={this.props.profileStore.secretkey || ''}
                        onChange={this._handleChange}
                      />
                      <span
                        className="cw-error cw-hidden"
                        data-control="secretkey"
                      >
                        <i className="fas fa-exclamation-triangle mr-10"></i>
                        Please enter Secretkey.
                      </span>
                    </div>
                  </div>
                </div>
                <div className="cw-striped-row">
                  <div className="row">
                    <div className="col-sm-4">
                      <strong>Access Key</strong>
                    </div>
                    <div className="col-sm-4"></div>
                    <div className="col-sm-4">
                      <input
                        type="text"
                        placeholder="Awaiting Key"
                        className="form-control"
                        name="accesskey"
                        id="accesskey"
                        data-required="true"
                        disabled={
                          this.props.campaign.status ===
                          constant.campaignStatus.CANCELLED
                        }
                        value={this.props.profileStore.accesskey || ''}
                        onChange={this._handleChange}
                      />
                      <span
                        className="cw-error cw-hidden"
                        data-control="accesskey"
                      >
                        <i className="fas fa-exclamation-triangle mr-10"></i>
                        Please enter Accesskey.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`col-sm-12 ${
                  this.props.campaign.status ===
                  constant.campaignStatus.CANCELLED
                    ? 'd-none'
                    : ''
                }`}
              >
                <div className="cw-form--action-cta">
                  <button
                    type="button"
                    className="btn btn-primary mr-3"
                    onClick={this._handleSubmit}
                  >
                    Submit
                  </button>
                  <a
                    href="#"
                    className="cw-save-to-draft mt-25"
                    onClick={this._saveAsDraft}
                  >
                    Save as draft
                  </a>
                </div>
              </div>
            </div>
          </div>
          {this.props.historyLogs.length ? (
            <HistoryComponent historyLogs={this.props.historyLogs} />
          ) : null}
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  campaign: state.prmDetails.campaign,
  campaignDetails: state.prmDetails.campaignDetails || {},
  profileStore: state.prmDetails.profileStore,
  questionAnswerList: state.masterData.questionAnswerList || [],
  additionalOptins: state.masterData.additionalOptins,
  asyncInProgress: state.prmDetails.asyncInProgress,
  dataSubmitted: state.prmDetails.dataSubmitted,
  dataUpdated: state.prmDetails.dataUpdated,
  brandoptin: state.masterData.brandoptin,
  unileveroptin: state.masterData.unileveroptin,
  isDraftState: state.prmDetails.isDraftState,
  dataloaded: state.prmDetails.dataloaded,
  historyLogs: state.historyLogs
});

export default connect(
  mapStateToProps,
  {
    fetchCampaignData,
    updateProfileStore,
    savePrmData,
    setNotificationMessage,
    fetchHistoryLogs
  }
)(PrmData);
