import React, { useEffect, useState } from 'react';
import { Input } from '../../atoms/Input';
import { FieldError } from '../../atoms/FieldError';
import { get } from 'lodash';
import classNames from 'classnames';
import ColourPicker from '../ColourPicker/ColourPicker';
import { useWatch } from 'react-hook-form';

export const ColorPickerInput = ({
  name,
  description,
  type,
  rules,
  control,
  register,
  label,
  errors,
  options,
  watchInput,
  defaultValue,
  defaultWatchValue,
  getValues,
  setValue,
  ...props
}) => {
  const errorMessages = get(errors, name);
  const hasError = !!(errors && errorMessages);

  const [selectedValue, setSelectedValue] = useState(defaultValue || []);

  const brand = useWatch({
    control,
    name: watchInput,
    defaultValue: defaultWatchValue
  });

  useEffect(() => {
    if (props.onWatch) {
      props.onWatch(brand, onChange);
    }
  }, [brand]);

  const onChange = colors => {
    setValue(name, colors);
    setSelectedValue(colors);
  };

  if (!brand) {
    return null;
  }

  return (
    <div className="form-group mt-30">
      <div className="d-flex flex-column">
        <strong>{label}</strong>
        <span className="description mb-10">{description}</span>
      </div>

      <ColourPicker value={selectedValue} name={name} onChange={onChange} />

      {!props.disableError && <FieldError errors={errors} name={name} />}
    </div>
  );
};
