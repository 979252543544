import React from 'react';
//import { components } from 'react-select';
//import AsyncCreatable from 'react-select/lib/AsyncCreatable';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { FixedSizeList as List } from 'react-window';

const MenuList = props => {
  const height = 60;
  const { options, children, maxHeight, getValue } = this.props;
  const [value] = getValue();
  const initialOffset = options.indexOf(value) * height;

  return (
    <List
      height={maxHeight}
      itemCount={children.length}
      itemSize={height}
      initialScrollOffset={initialOffset}
    >
      {({ index, style }) => <div style={style}>{children[index]}</div>}
    </List>
  );
};

const AsyncDropDown = props => {
  return (
    <AsyncCreatableSelect
      components={MenuList}
      cacheOptions={false}
      className={props.className}
      name={props.name}
      label={props.label}
      placeholder={props.placeholder}
      loadOptions={props.loadOptions}
      defaultOptions={props.defaultOptions}
      onChange={props.onChange}
      isLoading={props.isLoading}
      value={props.value}
    />
  );
};

export default AsyncDropDown;
