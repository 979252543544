import React from 'react';
import AddIcon from '../images/plus-outlined.png';

const AddFieldsButton = ({ onClick }) => {
  return (
    <button
      className="cw-color--saasheading btn btn-primary p-0 border-0 border-radius-0 cw-add-fields cw-add-fields--edit"
      onClick={onClick}
      name="button"
    >
      <img src={AddIcon} alt="Add Fields" />
      <div style={{ color: 'white', paddingLeft: 12 }}>Add Fields</div>
    </button>
  );
};

export default AddFieldsButton;
