import axios from 'axios';
import constant from '../constants';

import {
  GET_SAAS_FORM_FIELD_METADATA,
  GET_SAAS_FORM_GENERIC_METADATA,
  ASYNC_IN_PROGRESS,
  SERVICE_ERROR_OVERVIEW,
  GET_SAAS_INCENTIVE_METADATA,
  GET_SAAS_FORM_ELEMENTS_METADATA
} from '../actionTypes';

export function getAuthoringFieldData(
  language,
  campaignType,
  countryCode,
  showLoader = true
) {
  return function(dispatch) {
    if (showLoader) {
      dispatch({ type: ASYNC_IN_PROGRESS, isLoading: true });
    }
    axios
      .get(
        constant.saasServiceUrls.FORM_FIELDS_METADATA +
          '?language=' +
          language +
          `&campaignType=${campaignType}` +
          `&country=${countryCode}`
      )
      .then(res => {
        const response = res.data;
        if (response && response.length > 0) {
          const formMetaData = {
            accordionData: response,
            emailData: response,
            accordionFormElement: response
          };
          const incentiveData = {
            incentiveFields: response.filter(
              el => el.type === 'incentiveFields'
            )
          };

          dispatch({ type: GET_SAAS_FORM_FIELD_METADATA, formMetaData });
          dispatch({ type: GET_SAAS_FORM_ELEMENTS_METADATA, formMetaData });
          dispatch({ type: GET_SAAS_INCENTIVE_METADATA, incentiveData });

          if (showLoader) {
            setTimeout(() => {
              dispatch({ type: ASYNC_IN_PROGRESS, isLoading: false });
            }, 2000);
          }
        } else {
          if (showLoader) {
            dispatch({ type: ASYNC_IN_PROGRESS, isLoading: false });
          }
        }
      })
      .catch(error => {
        dispatch({ type: SERVICE_ERROR_OVERVIEW, error });
        dispatch({ type: ASYNC_IN_PROGRESS, isLoading: false });
      });
  };
}
export function getGenericFieldData(language) {
  return function(dispatch) {
    dispatch({ type: ASYNC_IN_PROGRESS, isLoading: true });
    axios
      .get(
        constant.saasServiceUrls.FORM_FIELDS_METADATA +
          '?language=' +
          language +
          '&campaignType=Direct'
      )
      .then(res => {
        const response = res.data;
        if (response && response.length > 0) {
          const formGenericData = {
            genericData: response
          };
          dispatch({ type: GET_SAAS_FORM_GENERIC_METADATA, formGenericData });
          dispatch({ type: ASYNC_IN_PROGRESS, isLoading: false });
        }else {
          dispatch({ type: ASYNC_IN_PROGRESS, isLoading: false });
        }
      })
      .catch(() => {
        dispatch({ type: ASYNC_IN_PROGRESS, isLoading: false });
      });
  };
}

export function getIncentiveFields(language) {
  return function(dispatch) {
    dispatch({ type: ASYNC_IN_PROGRESS, isLoading: true });
    axios
      .get(
        constant.saasServiceUrls.FORM_FIELDS_METADATA +
          '?language=' +
          language +
          '&campaignType=Incentive'
      )
      .then(res => {
        const response = res.data;
        if (response && response.length > 0) {
          const incentiveData = {
            incentiveFields: response.filter(
              el => el.type === 'incentiveFields'
            )
          };
          dispatch({ type: GET_SAAS_INCENTIVE_METADATA, incentiveData });
          dispatch({ type: ASYNC_IN_PROGRESS, isLoading: false });
        } else {
          dispatch({ type: ASYNC_IN_PROGRESS, isLoading: false });
        }
      })
      .catch(() => {
        dispatch({ type: ASYNC_IN_PROGRESS, isLoading: false });
      });
  };
}
