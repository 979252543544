import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { capitalize } from 'lodash';
import classNames from 'classnames';

import Loader from '../../Loader';
import { EditInput } from 'pages/BrandManagement/InlineEditInput';
import { getFormatedDate } from '../../../selectors/index';

import { getBrandDetails } from 'services/emailBriefings';

const getSortOrderClass = (elem, sort) => {
  if (elem.sort) {
    if (elem.dataType === sort.sortKey) {
      if (sort.sortBy === 'asc') {
        return 'cw-sort--asc';
      }
      return 'cw-sort--desc';
    }
  }
  return 'cw-sort--asc';
};

export default function DynamicTableV2({
  campaignConfig,
  sortClick,
  columnHeaders,
  userData,
  asyncInProgress,
  isChangeInProgress,
  panelType,
  editUser,
  deleteUser,
  className,
  sort,
  minimalWidthColumns,
  editingId,
  updateItem
}) {
  const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'
  const [sortColumn, setSortColumn] = useState(null);
  const [languageItems, setLanguageItems] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState(null);

  useEffect(() => {
    getBrandDetails().then(res => {
      if (res.languages) {
        let languages = res.languages.map(language => {
          return {
            value: language.code,
            label: language.name,
            id: language._id
          };
        });
        setLanguageItems(languages);
      }
    });
  }, []);

  const handleSort = columnName => {
    if (sortColumn === columnName) {
      // If the same column is clicked again, reverse the sorting order
      setSortOrder(prevOrder => (prevOrder === 'asc' ? 'desc' : 'asc'));
    } else {
      // If a new column is clicked, set the column and default to ascending order
      setSortColumn(columnName);
      setSortOrder('asc');
    }
  };

  const columnsHeaders = sortObj => {
    return columnHeaders.map(elm => {
      const sortOrderClass = getSortOrderClass(elm, sortObj);
      return (
        <div
          key={elm.dataType}
          className={classNames('cell', {
            //'d-flex': elm.dataType === 'businessGroup',
            'cw-sort': elm.sort, //elm.dataType === 'creationDate',
            [sortOrderClass]: elm.sort,
            actions: elm.dataType === 'actions',
            'current-sorted': elm.dataType === sortObj.sortKey,
            'minimal-width': elm.minimalWidth
          })}
          data-type={elm.dataType}
          onClick={e => {
            // handleSort(elm.dataType)
            if (elm.sort) {
              sortClick(elm.dataType);
            }
          }}
        >
          {elm.name}
        </div>
      );
    });
  };

  const dataArrMap = dataArr => {
    return (
      <ul>
        {dataArr.length
          ? dataArr.map((data, index) => {
              return (
                <li className="list-unstyled" key={index}>
                  {' '}
                  {data.name}{' '}
                </li>
              );
            })
          : ''}
      </ul>
    );
  };

  const rowCell = (value, entity, style = {}) => {
    return (
      <div
        className={classNames('cell', {
          'minimal-width': minimalWidthColumns?.includes(entity)
        })}
        style={style}
      >
        {value || ''}
      </div>
    );
  };

  const rowCellWithPill = user => {
    return (
      <div className="cell">
        <span className="badge badge-pill badge-info mb-1">
          {panelType === 'requester' || user.loginAccess ? 'Log-in access' : ''}
        </span>{' '}
        <br />
        <span className="badge badge-pill badge-info">
          {user.emailNotificationAccess ? 'Email Notification' : ''}
        </span>
      </div>
    );
  };

  const rowCellWithAccessTo = user => {
    return (
      <div className="cell">
        {user.accessTo && user.accessTo.length
          ? user.accessTo.map((data, index) => {
              return data.emailCampaign ? (
                <ul key={index}> Email</ul>
              ) : data.formCampaign ? (
                <ul key={index}> Data</ul>
              ) : (
                ''
              );
            })
          : ''}
      </div>
    );
  };

  const getCountry = item => {
    if (item.countryId && typeof item.countryId === 'object') {
      return item.countryId.name;
    }
    return capitalize(item.country);
  };

  const getBrand = item => {
    if (item.brandId && typeof item.brandId === 'object') {
      return item.brandId.name;
    }
    return capitalize(item.brand);
  };

  const renderUserDataNew = (userdata, asyncInProgress) => {
    if (asyncInProgress) {
      return (
        <div className="approverlist-table-row">
          <Loader style={{ top: '-120px' }} />
        </div>
      );
    } else {
      if (!userdata.length) {
        return (
          <div className="approverlist-table-row">
            <span className="mt-20 mb-10 ml-10">{`No data available.`}</span>
          </div>
        );
      } else {
        const presentLanguages = (brand, country) =>
          userdata
            .filter(
              item =>
                getBrand(item).toLowerCase() == brand.toLowerCase() &&
                getCountry(item).toLowerCase() === country.toLowerCase()
            )
            .map(item => item.language.languageCode?.toLowerCase());

        return userdata.map((user, index) => {
          let lastUpdatedDate = user.lastUpdatedDate
            ? getFormatedDate(user.lastUpdatedDate, false, false)
            : '';
          lastUpdatedDate = lastUpdatedDate
            ? lastUpdatedDate.split('-')[0]
            : '';

          return (
            <div
              key={`${user._id}-${user.language._id}`}
              className={`approverlist-table-row align-items-center ${
                isChangeInProgress && editingId === user.language._id
                  ? 'loading'
                  : ''
              }`}
            >
              {rowCell(getCountry(user), 'country')}
              {rowCell(getBrand(user), 'brand')}
              {rowCell(
                editingId === user.language._id ? (
                  <Select
                    className={'cw-select--custom'}
                    name={'language'}
                    label={'language'}
                    placeholder={'Please select'}
                    options={languageItems.filter(
                      option =>
                        user.language.languageCode.toLowerCase() ===
                          option?.value?.toLowerCase() ||
                        !presentLanguages(
                          getBrand(user),
                          getCountry(user)
                        ).includes(option?.value?.toLowerCase())
                    )}
                    value={selectedLanguage}
                    onChange={setSelectedLanguage}
                  />
                ) : (
                  user.language.languageName
                ),
                'language'
              )}
              {rowCell(
                <>
                  {editingId === user.language._id ? (
                    <EditInput
                      defaultValue={user.language.optOutText}
                      itemId={user.language._id}
                      editFn={editUser}
                      updateItem={updateItem}
                      selectedLanguage={selectedLanguage}
                    />
                  ) : (
                    user.language.optOutText
                  )}
                </>
              )}
              {rowCell(
                <>
                  <span
                    className={`cw-icons m-0 ${
                      editingId === user.language._id ? 'hidden' : ''
                    }`}
                  >
                    <i
                      className="far fa-edit"
                      onClick={() => {
                        editUser(user.language._id);
                        setSelectedLanguage({
                          value: user.language.languageCode.toLowerCase(),
                          label: user.language.languageName
                        });
                      }}
                    ></i>
                  </span>
                  <span className="cw-icons ml-3 trash-icon">
                    <i
                      className="far fa-trash-alt"
                      onClick={() => deleteUser(user.language._id)}
                    ></i>
                  </span>
                </>,
                'actions',
                { flex: '0 0 100px' }
              )}
            </div>
          );
        });
      }
    }
  };

  return (
    <div className={`approverlist-table ${className}`}>
      <div className="approverlist-table-row approverlist-table-head">
        {columnsHeaders(sort)}
      </div>
      <div className="approverlist-table-body">
        {renderUserDataNew(userData, asyncInProgress)}
      </div>
    </div>
  );
}
