import React, { useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link, useHistory } from 'react-router-dom';
import ImgHeaderBell from 'images/notification-bell.svg';
import ImgHeaderUser from 'images/header-user.png';
import ImgHeaderLogout from 'images/header-logout.png';
import ImgHeaderBrandManagement from 'images/user-brand-management.png';
import ImgHeaderUserManagement from 'images/user-management.png';
import ImgHeaderUserReport from 'images/user-report.png';

import Accordion from 'react-bootstrap/Accordion';
import { useMsal } from '@azure/msal-react';

import Dropdown from 'react-bootstrap/Dropdown';

import ReactTooltip from 'react-tooltip';
import constants from 'constants/config';
import { UnstyledButton } from 'components/atoms/UnstyledButton';
import { getLoggedInUser } from 'utilities/util';
import EventEmitter from 'events';
import { msalConfig } from '../../../authConfig';

const eventEmitter = new EventEmitter();

function getNameInitials(name) {
  if (name) {
    let nameList = name.split(', ');
    let initials = [];
    for (let i = nameList.length - 1; i >= 0; i--) {
      initials.push(nameList[i].charAt(0).toUpperCase());
    }
    return initials.join('');
  }
  return '';
}

function NavAccordion({ role, onNavClick }) {
  return (
    <Accordion className="user-accordion">
      <Accordion.Item eventKey="0">
        <Accordion.Header>
          <span className="user-dropdown-item">
            <img className="user-icon" src={ImgHeaderUser} alt="user-icon" />
            <span className="role-name">
              {role === 'admin' ? 'Admin' : 'ACS'}
            </span>
          </span>
        </Accordion.Header>
        <Accordion.Body>
          <Navbar>
            <Nav>
              {role === 'admin' ? (
                <>
                  <UnstyledButton
                    onClick={() => onNavClick('/campaign-wizard/user')}
                    className="nav-link"
                    hasCustomPadding={true}
                  >
                    <span className="user-dropdown-item">
                      <img
                        className="user-icon"
                        src={ImgHeaderUserManagement}
                        alt="user-management-icon"
                      />
                      <span className="role-name">User management</span>
                    </span>
                  </UnstyledButton>
                  <UnstyledButton
                    onClick={() =>
                      onNavClick('/campaign-wizard/brand-management')
                    }
                    className="nav-link"
                    hasCustomPadding={true}
                  >
                    <span className="user-dropdown-item">
                      <img
                        className="user-icon"
                        src={ImgHeaderBrandManagement}
                        alt="brand-management-icon"
                      />
                      <span className="role-name">Brand management</span>
                    </span>
                  </UnstyledButton>
                  <UnstyledButton
                    onClick={() =>
                      onNavClick('/campaign-wizard/report-mgmt-v2')
                    }
                    className="nav-link"
                    hasCustomPadding={true}
                  >
                    <span className="user-dropdown-item">
                      <img
                        className="user-icon"
                        src={ImgHeaderUserReport}
                        alt="reports-management-icon"
                      />
                      <span className="role-name">Reports</span>
                    </span>
                  </UnstyledButton>
                </>
              ) : (
                <>
                  <UnstyledButton
                    onClick={() =>
                      onNavClick('/campaign-wizard/brand-management')
                    }
                    className="nav-link"
                    hasCustomPadding={true}
                  >
                    <span className="user-dropdown-item">
                      <img
                        className="user-icon"
                        src={ImgHeaderBrandManagement}
                        alt="brand-management-icon"
                      />
                      <span className="role-name">Brand management</span>
                    </span>
                  </UnstyledButton>
                </>
              )}
            </Nav>
          </Navbar>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

function NavUserMenu({ userType }) {
  const { instance } = useMsal();
  const [showMenu, setShowMenu] = useState(false);
  const history = useHistory();
  const userDetails = getLoggedInUser(instance) || {};
  //const userDetails = JSON.parse(localStorage.getItem('userDetails')) || {};
  const userInitials = getNameInitials(userDetails.name);
  const handleLogout = () => {
    instance.logoutRedirect({
      account: userDetails,
      postLogoutRedirectUri: msalConfig.auth.postLogoutRedirectUri
    });
    // eventEmitter.emit('logoutTrack', {
    //   logEvent: 'logoutHeader'
    // });
    // localStorage.removeItem('cwtoken');
  };
  const handleLinkClick = link => {
    if (link) {
      setShowMenu(false);
      history.push(link);
    }
  };
  return (
    <div className="d-flex">
      <div className="bell-notification-container">
        <ReactTooltip
          id="header_bell_notification_rel"
          place="bottom"
          type="info"
          multiline={true}
          className="tooltip-light header_bell_notification_rel"
          closeOnEsc={true}
          closeOnScroll={true}
        />
        <Link
          to="/campaign-wizard/releasenotes"
          className="bell-notification"
          data-tip="Release updates"
          data-for="header_bell_notification_rel"
          onClick={() => {
            ReactTooltip.hide();
          }}
        >
          <img src={ImgHeaderBell} alt="Release updates" />
        </Link>
      </div>
      <div>
        <ReactTooltip
          id="header_user_initial"
          place="bottom"
          type="info"
          multiline={true}
          className="tooltip-light header_user_initial"
        />
        <Dropdown
          className="user-menu "
          autoClose="outside"
          drop="down"
          onToggle={isOpen => {
            ReactTooltip.hide();
            setShowMenu(isOpen);
          }}
          show={showMenu}
        >
          <Dropdown.Toggle
            id="dropdown-autoclose-outside avatar"
            className="avatar"
            data-tip="Profile"
            data-for="header_user_initial"
          >
            <span>{userInitials}</span>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.ItemText>
              <span className="label">Work</span>
              <span className="user-dropdown-item">
                <div className="user-dropdown-left">
                  <span className="avatar">
                    <span>{userInitials}</span>
                  </span>
                </div>
                <div className="user-dropdown-right">
                  <ReactTooltip
                    id="header_nav_email_info"
                    place="bottom"
                    type="info"
                    multiline={true}
                    className="tooltip-light header_bell_notification_rel"
                  />
                  <span className="full-name">{userDetails.name}</span>
                  <span
                    className="email"
                    data-tip={userDetails?.username?.toLowerCase()}
                    data-for="header_nav_email_info"
                  >
                    {userDetails?.username?.toLowerCase()}
                  </span>
                </div>
              </span>
              <span className="label-second">Role</span>
              {userType !== 'admin' && userType !== 'acs' ? (
                <span className="user-dropdown-item">
                  <img
                    className="user-icon"
                    src={ImgHeaderUser}
                    alt="user-icon"
                  />
                  <span className="role-name">
                    {constants.USER_ROLES_LABEL_MAP[userType] || 'NA'}
                  </span>
                </span>
              ) : null}
            </Dropdown.ItemText>
            {userType === 'admin' || userType === 'acs' ? (
              <Dropdown.ItemText className="accordion-nav">
                <NavAccordion role={userType} onNavClick={handleLinkClick} />
              </Dropdown.ItemText>
            ) : null}
            {userType === 'approver' ? (
              <Dropdown.Item
                as="button"
                onClick={() => handleLinkClick('/campaign-wizard/approvers')}
                className="accordion-nav logout"
                //hasCustomPadding={true}
              >
                <span className="user-dropdown-item pt-1 pb-1">
                  <img
                    className="user-icon"
                    style={{ marginLeft: '2px' }}
                    src={ImgHeaderUserManagement}
                    alt="user-management-icon"
                  />
                  <span className="role-name">List of approvers</span>
                </span>
              </Dropdown.Item>
            ) : null}
            <Dropdown.Item
              as="button"
              className="accordion-nav logout"
              onClick={handleLogout}
            >
              <span className="user-dropdown-item pt-1 pb-1">
                <img
                  className="user-icon-logout"
                  src={ImgHeaderLogout}
                  alt="logout-button"
                />
                <span className="role-name user-logout-label">Logout</span>
              </span>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
}

export default NavUserMenu;
