/* eslint-disable no-unused-vars */
/* eslint-disable default-case */
import React, { useState, useReducer, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import Alert from '../Alert';
import axios from 'axios';
import AsyncDropDown from '../AsyncDropDown';
import constant from '../../constants';
import BreadCrumb from '../BreadCrumb';
import QnAepsilonSubheadingText from '../QnAepsilonSubheadingText';
import {
  fetchCampaignData,
  cancelCampaignChanges
} from '../../actions/overview';
import Select from 'react-select';
import {
  setApproverEditCampaignStatus,
  setApproverEditCampaignStatusMessage,
  setApproverEditLastUpdatedScreen
} from '../../actions/app';
import {
  SAAS_ADD_QUESTION,
  ASYNC_IN_PROGRESS,
  SAAS_ADD_MULTIPLE_QUESTION,
  SAAS_ADD_SINGLE_QUESTION
} from '../../actionTypes';
import {
  getQuestions,
  getAnswers,
  getCampaignDetails
} from '../../actions/questionsAnswers';
import { scrollToTop } from 'selectors';
import CampaignCancelModel from '../CampaignCancelModel';
import Modal from 'react-modal';
import QnaGuideline from 'components/QnaGuideline';

let questionAnswer = (state, action) => {
  switch (action.type) {
    case SAAS_ADD_QUESTION:
      return { question: action.question };
    case SAAS_ADD_MULTIPLE_QUESTION:
      return { question: action.multipleQuestion };
  }
};

const questionTypes = [
  {
    answers: [
      {
        value: '',
        answerLabel: '',
        epsilonQuestionAnswerId: ''
      },
      {
        value: '',
        answerLabel: '',
        epsilonQuestionAnswerId: ''
      }
    ],
    dataType: 'questionAnswerPair',
    id: '',
    label: 'Single Choice Question',
    mandatoryToggle: false,
    mandatoryMessage: '',
    questionLabel: '',
    questionType: 'Single choice',
    toolTip: '',
    value: 'singleChoice'
  },
  {
    answers: [
      {
        value: '',
        answerLabel: '',
        epsilonQuestionAnswerId: ''
      },
      {
        value: '',
        answerLabel: '',
        epsilonQuestionAnswerId: ''
      }
    ],
    dataType: 'questionAnswerPair',
    id: '',
    label: 'Multiple Choice Question',
    mandatoryToggle: false,
    mandatoryMessage: '',
    questionLabel: '',
    questionType: 'Multiple choice',
    toolTip: '',
    value: 'multipleChoice'
  },
  {
    answers: [
      {
        value: '',
        // answerLabel: "",
        epsilonQuestionAnswerId: ''
      }
      // {
      //   value: "",
      //   answerLabel: "",
      //   epsilonQuestionAnswerId: ""
      // },
    ],
    dataType: 'questionAnswerPair',
    id: '',
    label: 'Free Text Question',
    mandatoryToggle: false,
    mandatoryMessage: '',
    questionLabel: '',
    questionType: 'Free text',
    toolTip: '',
    value: 'freeText'
  }
];

const EpsilonQnA = props => {
  const dispatch = useDispatch();
  const questions = useSelector(state => state.question);
  const [question, addQuestion] = useState([]);
  const [answersLimit, setMaxAnswerLimitOptions] = useState([]);
  const [maxAnswerLimit, setMaxAnswerLimit] = useState({
    label: 'No limit',
    value: ''
  });
  const [matchProps, setMatchProps] = useState(props.match);
  const [expand, setExpand] = useState(0);
  const [isMandatory, setMandatory] = useState(false);
  const [questionsExist, setQuestionsExist] = useState(true);
  const [campaignType, setCampaignType] = useState('');
  const [websiteType, setWebsiteType] = useState('');
  const [formType, setFormType] = useState('');
  const [showAllPurposeError, setShowAllPurposeError] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isOpenModel, setIsOpenModel] = useState(false);
  const [iConfirmQnA, setCheckbox] = useState(false);
  const [isEdited, setIsEdited] = useState('');
  let getSavedQnAFields = () => {
    axios
      .get(
        constant.serviceUrls.CAMPAIGN_DETAILS_SERVICE_URL +
          '/' +
          props.match.params.id
      )
      .then(response => {
        let { campaignDetail, campaignType, type, websiteType, isEdited } =
          response && response.data[0];
        let isClone = response && response.data[0] && response.data[0].isClone;
        setCampaignType(campaignType);
        setWebsiteType(websiteType.name);
        setFormType(type);
        setIsEdited(isEdited);
        setCheckbox(campaignDetail && campaignDetail.iConfirmQnA);
        if (!isClone) {
          setCheckbox(campaignDetail && campaignDetail.iConfirmQnA);
        } else {
          setCheckbox(false);
        }
        let fields =
          campaignDetail && campaignDetail.fields ? campaignDetail.fields : [];
        addQuestion([...fields]);
      });
  };

  useEffect(() => {
    dispatch(getCampaignDetails(props.match.params.id));
    _maxAnswersOptions();
    dispatch(
      fetchCampaignData(
        props.match.params.id,
        false,
        true,
        questionProps && questionProps.campaignType === 'Direct messaging'
          ? 'Direct'
          : questionProps && questionProps.campaignType,
        questionProps &&
          questionProps.campaign &&
          questionProps.campaign.country &&
          questionProps.campaign.country.code
      )
    );
    getSavedQnAFields();
    handleAccordionExpandCollapse(-1);
  }, [dispatch, props.match.params.id, questionProps]);

  let _handlebackButton = event => {
    event.preventDefault();
    props.history.push(
      '/campaign-wizard/campaign/' +
        props.match.params.id +
        '/saas-profile-details'
    );
  };

  let _handleCancel = () => {
    setApproverEditCampaignStatus(false);
    setApproverEditCampaignStatusMessage('');
    props.history.push(
      '/campaign-wizard/campaign/' + props.match.params.id + '/approval'
    );
  };

  let submitQna = (event, saveAsDraft) => {
    let { campaign } = questionProps;
    let campaignTypeIncentive =
      campaign && campaign.campaignType === 'Incentive';
    let isQnA =
      campaign && campaign.type === 'Sign-up with Questions & Answers';
    setQuestionsExist(true);
    let editCampaign = campaign.isEdited;
    let dataToSend = {
      uid: props.match.params.id,
      fields: [...question],
      isEdited: editCampaign,
      iConfirmQnA: iConfirmQnA,
      isClone: false
    };
    if (saveAsDraft) {
      dataToSend['saveAsDraft'] = saveAsDraft;
    }

    axios
      .post(constant.serviceUrls.CAMPAIGN_DATA_SERVICE_URL, dataToSend)
      .then(response => {
        if (response) {
          if (saveAsDraft) {
            props.history.push('/campaign-wizard/dashboard');
          } else {
            if (campaignTypeIncentive && isQnA) {
              props.history.push(
                '/campaign-wizard/campaign/' +
                  props.match.params.id +
                  '/saas-incentive-fields'
              );
            } else {
              props.history.push(
                '/campaign-wizard/campaign/' +
                  props.match.params.id +
                  '/saas-consent-compliance'
              );
            }
          }
        }
      });
  };

  let _handleSubmit = (event, saveAsDraft) => {
    let { campaign } = questionProps;
    if (saveAsDraft) {
      submitQna(event, saveAsDraft);
    } else {
      if (validateQ(question) && validateQAfields(question)) {
        submitQna(event, saveAsDraft);
      } else {
        scrollToTop();
      }
    }
  };
  let _hanldeQnASelection = selectedOption => {
    let questionList = [...question];
    setQuestionsExist(true);
    handleAccordionExpandCollapse(question.length);
    let selectedQuestionType = selectedOption.value;
    let optionSelected;
    const questionObject =
      questionProps &&
      questionProps.formMetaData &&
      questionProps.formMetaData.qnaFieldData &&
      questionProps.formMetaData.qnaFieldData.find(
        e => e.dataType === selectedQuestionType
      );

    if (selectedQuestionType === 'singleChoice') {
      optionSelected = [
        {
          answers: [
            {
              value: '',
              answerLabel: '',
              epsilonQuestionAnswerId: ''
            },
            {
              value: '',
              answerLabel: '',
              epsilonQuestionAnswerId: ''
            }
          ],
          dataType: 'questionAnswerPair',
          id: '',
          label: 'Single Choice Question',
          mandatoryToggle: false,
          mandatoryMessage: questionObject && questionObject.mandatoryMessage,
          questionLabel: '',
          questionType: 'Single choice',
          toolTip: '',
          value: 'singleChoice'
        }
      ];
    }
    if (selectedQuestionType === 'multipleChoice') {
      optionSelected = [
        {
          answers: [
            {
              value: '',
              answerLabel: '',
              epsilonQuestionAnswerId: ''
            },
            {
              value: '',
              answerLabel: '',
              epsilonQuestionAnswerId: ''
            }
          ],
          dataType: 'questionAnswerPair',
          id: '',
          label: 'Multiple Choice Question',
          mandatoryToggle: false,
          mandatoryMessage: questionObject && questionObject.mandatoryMessage,
          questionLabel: '',
          questionType: 'Multiple choice',
          toolTip: '',
          value: 'multipleChoice'
        }
      ];
    }
    if (selectedQuestionType === 'freeText') {
      optionSelected = [
        {
          answers: [
            {
              value: '',
              epsilonQuestionAnswerId: ''
            }
          ],
          englishLabel: '',
          dataType: 'questionAnswerPair',
          id: '',
          label: 'Free Text Question',
          mandatoryToggle: false,
          mandatoryMessage: questionObject && questionObject.mandatoryMessage,
          questionLabel: '',
          questionType: 'Free text',
          toolTip: '',
          value: 'freeText'
        }
      ];
    }

    addQuestion([...questionList, ...optionSelected]);
  };

  let _handleQuestionSelection = selectedOption => {
    if (iConfirmQnA) {
      setCheckbox(false);
    }
    let questionList = [...question];
    let qnaData = questionList.filter(e => e.dataType === 'questionAnswerPair');
    if (qnaData && qnaData.length > 0) {
      if (validateQ(question) && validateQAfields(question)) {
        _hanldeQnASelection(selectedOption);
      }
    } else {
      _hanldeQnASelection(selectedOption);
    }
  };

  let removeAccordion = index => {
    let updatedQuestions = [...question];
    updatedQuestions.splice(index, 1);
    let qnaData = updatedQuestions.filter(
      e => e.dataType === 'questionAnswerPair'
    );
    if (qnaData && qnaData.length === 0) {
      setExpand(0);
      setShowAllPurposeError(false);
    } else {
      setExpand(false);
    }
    addQuestion([...updatedQuestions]);
  };

  let handleAccordionExpandCollapse = index => {
    setExpand(expand === index ? false : index);
  };

  let _maxAnswersOptions = () => {
    let maxAnswers = [];
    maxAnswers.push({ value: 'No limit', label: 'No limit' });
    for (let i = 1; i <= 20; i++) {
      maxAnswers.push({ value: i, label: i });
    }
    setMaxAnswerLimitOptions(maxAnswers);
  };

  let _handleMaxAnswersAllowed = (selectedOption, index) => {
    let maxAnswerLimit = {
      value: selectedOption.value,
      label: selectedOption.label
    };
    let questionSelected = [...question];
    questionSelected[index].maxAnswerLimit = maxAnswerLimit.value;
    addQuestion([...questionSelected]);
  };

  let onLabelChange = (e, index, elem) => {
    document.getElementById(`questionLabelError_${index}`) &&
      document
        .getElementById(`questionLabelError_${index}`)
        .classList.add('cw-hidden');

    document
      .getElementById(`questionLabel_${index}`)
      .classList.contains('cw-error-focus') &&
      document
        .getElementById(`questionLabel_${index}`)
        .classList.remove('cw-error-focus');

    let { campaignLanguageCode } = questionProps;
    let enteredText = e.target.value;
    let tranlatedAQ = [...question];
    tranlatedAQ[index].questionLabel = enteredText;
    addQuestion([...tranlatedAQ]);
  };

  let handleAnswerLabel = (e, index, indexValue) => {
    let enteredText = e.target.value;
    let epsilonAnswerLabel = [...question];
    epsilonAnswerLabel[index].answers[indexValue].answerLabel = enteredText;

    document.getElementById(`answerLabelError_${index}_${indexValue}`) &&
      document
        .getElementById(`answerLabelError_${index}_${indexValue}`)
        .classList.add('cw-hidden');

    document
      .getElementById(`answerLabel_${index}_${indexValue}`)
      .classList.contains('cw-error-focus') &&
      document
        .getElementById(`answerLabel_${index}_${indexValue}`)
        .classList.remove('cw-error-focus');

    addQuestion([...epsilonAnswerLabel]);
  };

  let handleAnswerId = (e, index, indexValue) => {
    let enteredText = e.target.value;
    let epsilonAnswerLabel = [...question];
    epsilonAnswerLabel[index].answers[indexValue].value = enteredText;

    document.getElementById(`answerId_Error_${index}_${indexValue}`) &&
      document
        .getElementById(`answerId_Error_${index}_${indexValue}`)
        .classList.add('cw-hidden');

    document.getElementById(`answerId_NumError_${index}_${indexValue}`) &&
      document
        .getElementById(`answerId_NumError_${index}_${indexValue}`)
        .classList.add('cw-hidden');

    document
      .getElementById(`answerId_${index}_${indexValue}`)
      .classList.contains('cw-error-focus') &&
      document
        .getElementById(`answerId_${index}_${indexValue}`)
        .classList.remove('cw-error-focus');

    addQuestion([...epsilonAnswerLabel]);
  };

  let handleSurveyId = (e, index, indexValue) => {
    let enteredText = e.target.value;
    let surveyAnswerId = [...question];
    surveyAnswerId[index].answers[
      indexValue
    ].epsilonQuestionAnswerId = enteredText;

    document.getElementById(`surveyId_error_${index}_${indexValue}`) &&
      document
        .getElementById(`surveyId_error_${index}_${indexValue}`)
        .classList.add('cw-hidden');

    document.getElementById(`surveyId_Numerror_${index}_${indexValue}`) &&
      document
        .getElementById(`surveyId_Numerror_${index}_${indexValue}`)
        .classList.add('cw-hidden');

    document
      .getElementById(`surveyId_${index}_${indexValue}`)
      .classList.contains('cw-error-focus') &&
      document
        .getElementById(`surveyId_${index}_${indexValue}`)
        .classList.remove('cw-error-focus');

    addQuestion([...surveyAnswerId]);
  };

  let _addAnswer = (e, index) => {
    let answerNew = {
      value: '',
      answerLabel: '',
      epsilonQuestionAnswerId: ''
    };

    let questionArray = [...question];
    questionArray[index] &&
      questionArray[index].answers &&
      questionArray[index].answers.push(answerNew);
    addQuestion([...questionArray]);
  };

  let _deleteAnswer = async (index, indexValue) => {
    let questionArray = [...question];
    questionArray[index] &&
      questionArray[index].answers &&
      questionArray[index].answers.splice(indexValue, 1);
    addQuestion([...questionArray]);
  };

  let mandatoryQuestionHandler = (event, index) => {
    let isChecked = event.target.checked;
    let mandatoryQ = [...question];
    mandatoryQ[index].mandatoryToggle = isChecked;
    addQuestion([...mandatoryQ]);
    setMandatory(event.target.checked);
  };

  let handleMandatoryMessage = (e, index, elem) => {
    document.getElementById(`errorMessage_${elem.value}_${index}`) &&
      document
        .getElementById(`errorMessage_${elem.value}_${index}`)
        .classList.add('cw-hidden');

    document
      .getElementById(`errorMessage_${index}`)
      .classList.contains('cw-error-focus') &&
      document
        .getElementById(`errorMessage_${index}`)
        .classList.remove('cw-error-focus');

    let enteredText = e.target.value;
    let mandatoryQ = [...question];
    mandatoryQ[index].mandatoryMessage = mandatoryQ[index].mandatoryToggle
      ? enteredText
      : '';
    addQuestion([...mandatoryQ]);
  };

  let handleToolTip = (e, index) => {
    let enteredText = e.target.value;
    let toolTipQ = [...question];
    toolTipQ[index].toolTip = enteredText;
    addQuestion([...toolTipQ]);
  };

  let _handleQuestionId = (e, index) => {
    if (document.getElementById(`questionIdError_${index}`)) {
      document
        .getElementById(`questionIdError_${index}`)
        .classList.add('cw-hidden');
    }
    document.getElementById(`questionIdNumError_${index}`) &&
      document
        .getElementById(`questionIdNumError_${index}`)
        .classList.add('cw-hidden');

    document
      .getElementById(`questionId_${index}`)
      .classList.contains('cw-error-focus') &&
      document
        .getElementById(`questionId_${index}`)
        .classList.remove('cw-error-focus');

    let updatedQuestion = [...question];
    updatedQuestion[index].id = e.target.value;
    addQuestion([...updatedQuestion]);
  };

  let validateAnswers = (answerObj, questionIndex, isFreeTextType) => {
    const regex = /^[0-9\b]+$/;
    let isInvalid = false;

    answerObj &&
      answerObj.map((ans, index) => {
        let ansIdNumericValidation = regex.test(
          document.getElementById(`answerId_${questionIndex}_${index}`).value
        );
        let surveyIdNumericValidation = regex.test(
          document.getElementById(`surveyId_${questionIndex}_${index}`).value
        );
        if (!isFreeTextType) {
          if (
            (ans.answerLabel && ans.answerLabel === '') ||
            ans.answerLabel.trim().length === 0
          ) {
            document
              .getElementById(`answerLabelError_${questionIndex}_${index}`)
              .classList.remove('cw-hidden');

            document
              .getElementById(`answerLabel_${questionIndex}_${index}`)
              .classList.contains('cw-error-focus') ||
              document
                .getElementById(`answerLabel_${questionIndex}_${index}`)
                .classList.add('cw-error-focus');

            isInvalid = true;
          }
          if (
            document.getElementById(`answerLabel_${questionIndex}_${index}`) &&
            document
              .getElementById(`answerLabel_${questionIndex}_${index}`)
              .value.trim().length === 0
          ) {
            document
              .getElementById(`answerLabelError_${questionIndex}_${index}`)
              .classList.remove('cw-hidden');

            document
              .getElementById(`answerLabel_${questionIndex}_${index}`)
              .classList.contains('cw-error-focus') ||
              document
                .getElementById(`answerLabel_${questionIndex}_${index}`)
                .classList.add('cw-error-focus');

            isInvalid = true;
          }
        }

        if (
          document.getElementById(`answerId_${questionIndex}_${index}`) &&
          document
            .getElementById(`answerId_${questionIndex}_${index}`)
            .value.trim().length === 0
        ) {
          document
            .getElementById(`answerId_Error_${questionIndex}_${index}`)
            .classList.remove('cw-hidden');

          document
            .getElementById(`answerId_${questionIndex}_${index}`)
            .classList.contains('cw-error-focus') ||
            document
              .getElementById(`answerId_${questionIndex}_${index}`)
              .classList.add('cw-error-focus');

          isInvalid = true;
        }

        if (
          document.getElementById(`answerId_${questionIndex}_${index}`) &&
          document
            .getElementById(`answerId_${questionIndex}_${index}`)
            .value.trim().length != 0 &&
          !regex.test(
            document.getElementById(`answerId_${questionIndex}_${index}`).value
          )
        ) {
          document.getElementById(
            `answerId_NumError_${questionIndex}_${index}`
          ) &&
            document
              .getElementById(`answerId_NumError_${questionIndex}_${index}`)
              .classList.remove('cw-hidden');

          document
            .getElementById(`answerId_${questionIndex}_${index}`)
            .classList.contains('cw-error-focus') ||
            document
              .getElementById(`answerId_${questionIndex}_${index}`)
              .classList.add('cw-error-focus');

          isInvalid = true;
        }

        if (
          document.getElementById(`surveyId_${questionIndex}_${index}`) &&
          document
            .getElementById(`surveyId_${questionIndex}_${index}`)
            .value.trim().length === 0
        ) {
          document
            .getElementById(`surveyId_error_${questionIndex}_${index}`)
            .classList.remove('cw-hidden');

          document
            .getElementById(`surveyId_${questionIndex}_${index}`)
            .classList.contains('cw-error-focus') ||
            document
              .getElementById(`surveyId_${questionIndex}_${index}`)
              .classList.add('cw-error-focus');

          isInvalid = true;
        }

        if (
          document.getElementById(`surveyId_${questionIndex}_${index}`) &&
          document
            .getElementById(`surveyId_${questionIndex}_${index}`)
            .value.trim().length != 0 &&
          !regex.test(
            document.getElementById(`surveyId_${questionIndex}_${index}`).value
          )
        ) {
          document.getElementById(
            `surveyId_Numerror_${questionIndex}_${index}`
          ) &&
            document
              .getElementById(`surveyId_Numerror_${questionIndex}_${index}`)
              .classList.remove('cw-hidden');

          document
            .getElementById(`surveyId_${questionIndex}_${index}`)
            .classList.contains('cw-error-focus') ||
            document
              .getElementById(`surveyId_${questionIndex}_${index}`)
              .classList.add('cw-error-focus');

          isInvalid = true;
        }
      });
    return isInvalid;
  };

  let validateQAfields = item => {
    let isValid = true;
    const regex = /^[0-9\b]+$/;

    item.map((el, index) => {
      if (el.dataType === 'questionAnswerPair') {
        let isFreeTextType = el.questionType === 'Free text';
        let qIdNumericValidation = !regex.test(
          document.getElementById(`questionId_${index}`).value
        );

        if (
          el.mandatoryToggle &&
          (el.mandatoryMessage === undefined ||
            el.mandatoryMessage.trim().length === 0)
        ) {
          document.getElementById(`errorMessage_${el.value}_${index}`) &&
            document
              .getElementById(`errorMessage_${el.value}_${index}`)
              .classList.remove('cw-hidden');

          document
            .getElementById(`errorMessage_${index}`)
            .classList.contains('cw-error-focus') ||
            document
              .getElementById(`errorMessage_${index}`)
              .classList.add('cw-error-focus');
          setExpand(index);
          isValid = false;
        }

        if (
          (el.questionLabel !== undefined && el.questionLabel.length === 0) ||
          (document.getElementById(`questionLabel_${index}`) &&
            document.getElementById(`questionLabel_${index}`).value.trim()
              .length === 0)
        ) {
          document.getElementById(`questionLabelError_${index}`) &&
            document
              .getElementById(`questionLabelError_${index}`)
              .classList.remove('cw-hidden');

          document
            .getElementById(`questionLabel_${index}`)
            .classList.contains('cw-error-focus') ||
            document
              .getElementById(`questionLabel_${index}`)
              .classList.add('cw-error-focus');

          setExpand(index);
          isValid = false;
        }

        if (
          (el.id !== undefined && el.id.length === 0) ||
          (document.getElementById(`questionId_${index}`) &&
            document.getElementById(`questionId_${index}`).value.trim()
              .length === 0)
        ) {
          document.getElementById(`questionIdError_${index}`) &&
            document
              .getElementById(`questionIdError_${index}`)
              .classList.remove('cw-hidden');

          document
            .getElementById(`questionId_${index}`)
            .classList.contains('cw-error-focus') ||
            document
              .getElementById(`questionId_${index}`)
              .classList.add('cw-error-focus');

          setExpand(index);
          isValid = false;
        }

        if (
          document.getElementById(`questionId_${index}`) &&
          document.getElementById(`questionId_${index}`).value.trim().length !=
            0 &&
          !regex.test(document.getElementById(`questionId_${index}`).value)
        ) {
          document.getElementById(`questionIdNumError_${index}`) &&
            document
              .getElementById(`questionIdNumError_${index}`)
              .classList.remove('cw-hidden');

          document
            .getElementById(`questionId_${index}`)
            .classList.contains('cw-error-focus') ||
            document
              .getElementById(`questionId_${index}`)
              .classList.add('cw-error-focus');

          setExpand(index);
          isValid = false;
        }

        if (validateAnswers(el.answers, index, isFreeTextType)) {
          setExpand(index);
          isValid = false;
        }
        if (isValid) {
          setShowAllPurposeError(false);
        } else {
          setShowAllPurposeError(true);
        }
      }
    });

    return isValid;
  };
  let validateQ = item => {
    let isValid = false;
    item.map(el => {
      if (el.dataType === 'questionAnswerPair') {
        setQuestionsExist(true);
        isValid = true;
      } else {
        setQuestionsExist(false);
      }
    });

    return isValid;
  };

  const _openIsCancelModal = event => {
    event.preventDefault();
    setIsCancelModalOpen(true);
  };

  const _closeIsCancelModal = () => {
    setIsCancelModalOpen(false);
  };
  const _cancelSaasCampaign = event => {
    event.preventDefault();
    setIsCancelModalOpen(false);
    cancelCampaignChanges(props.match.params.id);
    props.history.push('/campaign-wizard/campaign/' + props.match.params.id);
  };

  const isOpenGuidelineModel = event => {
    event.preventDefault();
    setIsOpenModel(true);
  };

  const isCloseGuidelineModel = event => {
    event.preventDefault();
    setIsOpenModel(false);
  };

  const _handleCheckboxConfirm = event => {
    let isChecked = event.target.checked;
    if (isChecked) {
      setCheckbox(true);
    } else {
      setCheckbox(false);
    }
  };

  const questionProps = useSelector(state => ({
    approverEditCampaign: state.app.approverEditCampaign,
    questionanswerssaved: state.questionsAnswers.questionanswerssaved,
    campaignLanguage: state.questionsAnswers.campaignLanguage,
    campaignLanguageCode: state.questionsAnswers.campaignLanguageCode,
    editCampaign: state.overview.editCampaign,
    editCampaignDetails: state.app.editCampaignDetails,
    isCampaignEdited: state.questionsAnswers.isCampaignEdited,
    websiteType: state.questionsAnswers.websiteType,
    isLanguageEnglish: state.questionsAnswers.isLanguageEnglish,
    campaignType: state.questionsAnswers.campaignType,
    autogenerate: state.campaignDetails.autogenerate,
    type: state.campaignDetails.type,
    incentiveType: state.campaignDetails.incentiveType,
    campaign: state.questionsAnswers.campaign,
    fields: state.questionsAnswers.fields,
    formMetaData: state.saasFormAuthoring
  }));
  console.log('isEdited', isEdited);
  return (
    <div className="col-sm-12">
      <div className="cw-section ml-20">
        {questionProps.campaign && questionProps.campaign.status !== '6' ? (
          <BreadCrumb
            match={matchProps}
            // editCampaign={
            //   (questionProps && questionProps.editCampaign) ||
            //   (questionProps && questionProps.isCampaignEdited)
            // }
            hideBreadCrumb={
              (questionProps &&
                (questionProps.approverEditCampaign ||
                  (questionProps.campaign &&
                    questionProps.campaign.isEdited))) ||
              isEdited
            }
            campaignType={questionProps && questionProps.campaignType}
            websiteType={questionProps && questionProps.websiteType}
            formType={
              questionProps &&
              questionProps.campaign &&
              questionProps.campaign.type
            }
            isEpsilonMarket={true}
          />
        ) : null}

        <Modal
          scrollable={true}
          isOpen={isCancelModalOpen}
          onRequestClose={_closeIsCancelModal}
          className="cw-modal cw-modal--branddetail"
          contentLabel="Campaign Wizard 2.0"
          style={{
            overlay: {
              backgroundColor: constant.EMAIL_OVERLAY_BACKGROUND
            }
          }}
        >
          <CampaignCancelModel
            closeIsCancelModal={_closeIsCancelModal}
            isCancelContinue={_cancelSaasCampaign}
          />
        </Modal>
        <Modal
          scrollable={true}
          isOpen={isOpenModel}
          onRequestClose={isCloseGuidelineModel}
          className="cw-modal cw-modal--qnadetail"
          contentLabel="Campaign Wizard 2.0"
          style={{
            overlay: {
              backgroundColor: constant.EMAIL_OVERLAY_BACKGROUND
            }
          }}
        >
          <QnaGuideline closeIsEditModal={isCloseGuidelineModel} />
        </Modal>
        {/* ) : null} */}

        <div className="cw-section--title mb-20">
          <h2 className="cw-heading--secondary mb-20">
            Configure Questions and Answers
          </h2>

          <div className="row">
            <div className="col-sm-7">
              <div className="cw-section--content">
                <Alert
                  alertType={'saasWarningAlertMessage'}
                  textMessage={constant.SAAS_MESSAGES.addQuestionMessage}
                  showComponent={!questionsExist}
                />
                <p className="mt-10">
                  <QnAepsilonSubheadingText show={false} />
                </p>
                {isEdited ? (
                  <div className="form-group">
                    <Alert
                      alertType="warningMessageWithIcon"
                      textMessage={constant.SAAS_MESSAGES.editQnAInfoMsg}
                      showComponent="true"
                    />
                  </div>
                ) : null}
                <p className="mb-20">
                  Language:{' '}
                  <span className="cw-color--primary">
                    {questionProps && questionProps.campaignLanguage
                      ? questionProps.campaignLanguage
                      : ''}
                  </span>
                </p>
              </div>

              <div className="form-group">
                <p className="cw-heading--saascampaign mb-20 mt-3">
                  Add Questions
                </p>
              </div>

              <div className="form-group">
                {question &&
                  question.map((elem, index) => {
                    if (elem.dataType === 'questionAnswerPair') {
                      return (
                        <div
                          id={`accordion_${elem.value}_${index + 1}`}
                          className="accordion saas-form-field-accordion mb-20"
                          key={`question_${index + 1}`}
                        >
                          <ReactTooltip
                            place="right"
                            type="info"
                            multiline={true}
                            className="cw-tooltip"
                          />
                          <div
                            className="card"
                            style={{
                              border: 0,
                              overflow: 'visible',
                              width: '100%'
                            }}
                          >
                            <div className="card-header accordian-card-header">
                              <span
                                className="saas-accordion-heading cw-color--primary"
                                style={{ fontWeight: '700' }}
                              >
                                {question[index].questionLabel
                                  ? question[index].questionLabel.length < 70
                                    ? question[index].questionLabel
                                    : question[index].questionLabel.substring(
                                        0,
                                        70
                                      ) + '...'
                                  : elem.label}
                              </span>
                              <span
                                type="button"
                                className={`saas-accordion-arrow ${index}`}
                                onClick={() =>
                                  handleAccordionExpandCollapse(index)
                                }
                                style={{
                                  marginRight: '-8px',
                                  paddingLeft: '12px'
                                }}
                              >
                                <i
                                  className={
                                    expand === index
                                      ? 'fas fa-chevron-down transformIcon-180'
                                      : 'fas fa-chevron-down'
                                  }
                                ></i>
                              </span>
                              {!isEdited ||
                              question.filter(
                                elem => elem.dataType === 'questionAnswerPair'
                              ).length > 1 ? (
                                <span
                                  className={'saas-accordion-close'}
                                  onClick={() => {
                                    removeAccordion(index);
                                  }}
                                  style={{ right: '-4%', zIndex: '1' }}
                                ></span>
                              ) : null}
                            </div>
                            <div
                              className={
                                expand === index ? 'collapse show' : 'collapse'
                              }
                            >
                              <ReactTooltip
                                place="right"
                                type="info"
                                multiline={true}
                                className="cw-tooltip"
                              />
                              <>
                                <div className="cw-question-answer-dotted-line mt-15">
                                  <div className="cw-heading-qna mb-10">
                                    <span
                                      className="float-left  fa fa-circle mt-10"
                                      style={{ marginTop: '5px' }}
                                    ></span>
                                    <strong style={{ marginLeft: '15px' }}>
                                      Question
                                    </strong>
                                  </div>
                                  <div
                                    className="form-group cw-qna-lable-width"
                                    key={`questionLabel_${index + 1}`}
                                  >
                                    <label htmlFor={`questionLabel_${index}`}>
                                      <span>
                                        Question Label (Consumer Facing)
                                      </span>
                                      <span
                                        className="float-right  cw-icon cw-icon--help"
                                        data-tip="This is the label of the question that the consumers will see on the form"
                                      >
                                        <i className="fas fa-question-circle"></i>
                                      </span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name={`questionLabel_${index}`}
                                      id={`questionLabel_${index}`}
                                      aria-describedby={`questionLabel_${index}`}
                                      data-required="true"
                                      defaultValue={
                                        question[index].questionLabel
                                      }
                                      onChange={e =>
                                        onLabelChange(e, index, elem)
                                      }
                                    />
                                    <span
                                      id={`questionLabelError_${index}`}
                                      className="cw-error cw-hidden"
                                      data-control="name"
                                    >
                                      <i className="fas fa-exclamation-triangle mr-10"></i>
                                      Please enter Question Label
                                    </span>
                                  </div>
                                  <div className="form-group cw-qna-lable-width">
                                    <label
                                      htmlFor={`questionLabel_${index + 1}`}
                                    >
                                      <span>Question ID</span>
                                      <span
                                        className="float-right  cw-icon cw-icon--help"
                                        data-tip="Please add Question ID (master data) as provided by Epsilon in your Campaign Template (CT)"
                                      >
                                        <i className="fas fa-question-circle"></i>
                                      </span>
                                    </label>

                                    <input
                                      type="text"
                                      maxLength={16}
                                      className="form-control"
                                      name={`questionId_${index}`}
                                      id={`questionId_${index}`}
                                      aria-describedby={`questionId_${index}`}
                                      data-required="true"
                                      onChange={e =>
                                        _handleQuestionId(e, index)
                                      }
                                      defaultValue={question[index].id}
                                    />

                                    <span
                                      id={`questionIdError_${index}`}
                                      className="cw-error cw-hidden"
                                      data-control="name"
                                    >
                                      <i className="fas fa-exclamation-triangle mr-10"></i>
                                      Please enter Question ID
                                    </span>

                                    <span
                                      id={`questionIdNumError_${index}`}
                                      className="cw-error cw-hidden"
                                      data-control="name"
                                    >
                                      <i className="fas fa-exclamation-triangle mr-10"></i>
                                      Please enter a numeric value
                                    </span>
                                  </div>
                                </div>

                                <>
                                  <div className="form-group cw-question-answer-dotted-line mt-15">
                                    <div className="cw-heading-qna mb-10">
                                      <span
                                        className="float-left  fa fa-circle mt-10"
                                        style={{ marginTop: '5px' }}
                                      ></span>
                                      <strong style={{ marginLeft: '15px' }}>
                                        Answers
                                      </strong>
                                    </div>
                                    {/* <div
                                    className="cw-heading--saascampaign mt-4"
                                    style={{ marginLeft: "-3px" }}
                                  >
                                    &nbsp; Configure Answers
                                  </div> */}

                                    {question[index].answers &&
                                      question[index].answers.map(
                                        (ele, indexValue) => {
                                          return (
                                            <>
                                              <ReactTooltip
                                                place="right"
                                                type="info"
                                                multiline={true}
                                                className="cw-tooltip"
                                              />
                                              {elem.value !== 'freeText' ? (
                                                <div
                                                  className="form-group cw-qna-lable-width"
                                                  key={`answer_${indexValue +
                                                    1}`}
                                                >
                                                  <label className="mt-10">
                                                    <span className="mb-10">
                                                      Answer {indexValue + 1}{' '}
                                                      Label (Consumer Facing)
                                                    </span>
                                                    <span
                                                      className="float-right  cw-icon cw-icon--help"
                                                      data-tip="This is the label of the answer that the consumers will see on the form"
                                                    >
                                                      <i className="fas fa-question-circle"></i>
                                                    </span>
                                                  </label>
                                                  <input
                                                    type="text"
                                                    className="form-control mb-10"
                                                    name={`answerLabel_${indexValue}`}
                                                    id={`answerLabel_${index}_${indexValue}`}
                                                    aria-describedby={`answerLabel_${indexValue}`}
                                                    data-required="true"
                                                    defaultValue={
                                                      question[index].answers[
                                                        indexValue
                                                      ].answerLabel
                                                    }
                                                    onChange={e =>
                                                      handleAnswerLabel(
                                                        e,
                                                        index,
                                                        indexValue
                                                      )
                                                    }
                                                  />
                                                  {indexValue >= 2 ? (
                                                    <span
                                                      className={`saas-accordion-close epsilon-qna`}
                                                      data-index={index}
                                                      onClick={e =>
                                                        _deleteAnswer(
                                                          index,
                                                          indexValue
                                                        )
                                                      }
                                                    ></span>
                                                  ) : null}
                                                  <span
                                                    id={`answerLabelError_${index}_${indexValue}`}
                                                    className="cw-error cw-hidden"
                                                    data-control="name"
                                                  >
                                                    <i className="fas fa-exclamation-triangle mr-10"></i>
                                                    Please enter Answer Label
                                                  </span>
                                                </div>
                                              ) : null}

                                              <div
                                                className="form-group cw-qna-lable-width"
                                                key={`answerId_${indexValue +
                                                  1}`}
                                              >
                                                <label className="mt-10">
                                                  <span className="mb-10">
                                                    {elem.value !== 'freeText'
                                                      ? `Answer ${indexValue +
                                                          1} ID`
                                                      : `Answer ID`}
                                                  </span>
                                                  <span
                                                    className="float-right  cw-icon cw-icon--help"
                                                    data-tip="Please add Answer ID (master data) as provided by Epsilon in your Campaign Template (CT)"
                                                  >
                                                    <i className="fas fa-question-circle"></i>
                                                  </span>
                                                </label>
                                                <input
                                                  type="text"
                                                  maxLength={16}
                                                  className="form-control mb-10"
                                                  name={`answerId_${indexValue}`}
                                                  id={`answerId_${index}_${indexValue}`}
                                                  aria-describedby={`answerId_${indexValue}`}
                                                  data-required="true"
                                                  defaultValue={
                                                    question[index].answers[
                                                      indexValue
                                                    ].value
                                                  }
                                                  onChange={e =>
                                                    handleAnswerId(
                                                      e,
                                                      index,
                                                      indexValue
                                                    )
                                                  }
                                                />
                                                <span
                                                  id={`answerId_Error_${index}_${indexValue}`}
                                                  className="cw-error cw-hidden"
                                                  data-control="name"
                                                >
                                                  <i className="fas fa-exclamation-triangle mr-10"></i>
                                                  Please enter the Answer ID
                                                </span>
                                                <span
                                                  id={`answerId_NumError_${index}_${indexValue}`}
                                                  className="cw-error cw-hidden"
                                                  data-control="name"
                                                >
                                                  <i className="fas fa-exclamation-triangle mr-10"></i>
                                                  Please enter a numeric value
                                                </span>
                                              </div>

                                              <div
                                                className="form-group cw-qna-lable-width"
                                                key={`surveyId_${indexValue +
                                                  1}`}
                                              >
                                                <label className="mt-10">
                                                  <span className="mb-10">
                                                    {elem.value !== 'freeText'
                                                      ? `Survey Question Answer ${indexValue +
                                                          1} ID`
                                                      : `Survey Question Answer ID`}
                                                  </span>
                                                  <span
                                                    className="float-right  cw-icon cw-icon--help"
                                                    data-tip="Please add Survey Question Answer ID (master data) as provided by Epsilon in your Campaign Template (CT)"
                                                  >
                                                    <i className="fas fa-question-circle"></i>
                                                  </span>
                                                </label>
                                                <input
                                                  type="text"
                                                  maxLength={16}
                                                  className="form-control mb-10"
                                                  name={`surveyId_${indexValue}`}
                                                  id={`surveyId_${index}_${indexValue}`}
                                                  aria-describedby={`surveyId_${indexValue}`}
                                                  data-required="true"
                                                  defaultValue={
                                                    question[index].answers[
                                                      indexValue
                                                    ].epsilonQuestionAnswerId
                                                  }
                                                  onChange={e =>
                                                    handleSurveyId(
                                                      e,
                                                      index,
                                                      indexValue
                                                    )
                                                  }
                                                />
                                                <span
                                                  id={`surveyId_error_${index}_${indexValue}`}
                                                  className="cw-error cw-hidden"
                                                  data-control="name"
                                                >
                                                  <i className="fas fa-exclamation-triangle mr-10"></i>
                                                  Please enter Survey Question
                                                  Answer ID
                                                </span>
                                                <span
                                                  id={`surveyId_Numerror_${index}_${indexValue}`}
                                                  className="cw-error cw-hidden"
                                                  data-control="name"
                                                >
                                                  <i className="fas fa-exclamation-triangle mr-10"></i>
                                                  Please enter a numeric value
                                                </span>
                                              </div>
                                            </>
                                          );
                                        }
                                      )}
                                    {!isEdited && elem.value !== 'freeText' ? (
                                      <button
                                        onClick={e => _addAnswer(e, index)}
                                        className="btn btn-outline-primary"
                                        style={{ marginLeft: '4.5%' }}
                                      >
                                        Add another answer
                                      </button>
                                    ) : null}
                                    {elem.value === 'multipleChoice' ? (
                                      <>
                                        <ReactTooltip
                                          place="right"
                                          type="info"
                                          multiline={true}
                                          className="cw-tooltip"
                                        />
                                        <div
                                          className="form-group cw-hidden"
                                          style={{ margin: '1% 0 0 2%' }}
                                        >
                                          <label htmlFor="answerLimit">
                                            <span>
                                              Maximum number of answers user can
                                              select
                                            </span>
                                            <span
                                              className="float-right  cw-icon cw-icon--help"
                                              data-tip="Limit the number of answers the user can select this question"
                                            >
                                              <i className="fa fa-question-circle"></i>
                                            </span>
                                          </label>
                                          <Select
                                            className={'cw-select--custom'}
                                            name={'answerLimit'}
                                            label={'answerLimit'}
                                            placeholder={'Select option'}
                                            options={answersLimit}
                                            onChange={e =>
                                              _handleMaxAnswersAllowed(e, index)
                                            }
                                            value={
                                              question[index] &&
                                              question[index].maxAnswerLimit !==
                                                ''
                                                ? {
                                                    label:
                                                      question[index]
                                                        .maxAnswerLimit,
                                                    value:
                                                      question[index]
                                                        .maxAnswerLimit
                                                  }
                                                : {
                                                    label: 'No limit',
                                                    value: ''
                                                  }
                                            }
                                          />
                                          <span
                                            className="cw-error cw-hidden"
                                            data-control="answerLimit"
                                          >
                                            <i className="fas fa-exclamation-triangle mr-10"></i>
                                            Please select maximum answers.
                                          </span>
                                        </div>
                                      </>
                                    ) : null}
                                  </div>
                                </>
                                <div className="cw-question-answer-dotted-line">
                                  <div className="cw-heading-qna">
                                    <span
                                      className="float-left  fa fa-circle mt-10"
                                      style={{ marginTop: '5px' }}
                                    ></span>
                                    <strong style={{ marginLeft: '15px' }}>
                                      Other Configurations
                                    </strong>
                                  </div>
                                  <div
                                    className={`form-group ml-2 cw-qna-lable-width ${
                                      elem.value === 'multipleChoice'
                                        ? 'mt-3'
                                        : 'mt-2'
                                    }`}
                                  >
                                    <label htmlFor={`toolTipText_${index}`}>
                                      <span>Tooltip/Help-Text (optional)</span>
                                      <span
                                        className="float-right  cw-icon cw-icon--help"
                                        data-tip="Please specify the tooltip message you would want users to see in case they need some additional information while filling up the form. Make sure to have this message in the language you selected for the form."
                                        currentitem="false"
                                      >
                                        <i className="fas fa-question-circle"></i>
                                      </span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name={`toolTipText_${index}`}
                                      id={`toolTipText_${index}`}
                                      aria-describedby={`toolTipText_${index}`}
                                      data-required="true"
                                      value={
                                        question[index] &&
                                        question[index].toolTip
                                          ? question[index].toolTip
                                          : ''
                                      }
                                      onChange={e => handleToolTip(e, index)}
                                    />
                                  </div>
                                  <div className="form-group d-flex ml-2 cw-qna-lable-width">
                                    <label className="switch">
                                      <input
                                        type="checkbox"
                                        name="quesMandatory"
                                        checked={
                                          question[index] &&
                                          question[index].mandatoryToggle
                                            ? true
                                            : false
                                        }
                                        onChange={e =>
                                          mandatoryQuestionHandler(e, index)
                                        }
                                      />
                                      <div className="slider round"></div>
                                    </label>
                                    <label
                                      className=" ml-2"
                                      htmlFor="quesMandatory"
                                    >
                                      Make this question mandatory
                                    </label>
                                  </div>
                                  {question[index] &&
                                  question[index].mandatoryToggle ? (
                                    <>
                                      <ReactTooltip
                                        place="right"
                                        type="info"
                                        multiline={true}
                                        className="cw-tooltip"
                                      />
                                      <div className="form-group ml-2 mt-2 cw-qna-lable-width">
                                        <label
                                          htmlFor={`errorMessage_${index}`}
                                        >
                                          <span>
                                            Mandatory Field Error Message
                                          </span>
                                          <span
                                            className="float-right  cw-icon cw-icon--help"
                                            data-tip="Please specify the error message you would want the users to see in case they forget to fill this field. Make sure to have this message in the language you selected for the form."
                                            currentitem="false"
                                          >
                                            <i className="fas fa-question-circle"></i>
                                          </span>
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name={`errorMessage_${index}`}
                                          id={`errorMessage_${index}`}
                                          aria-describedby={`errorMessage_${index}`}
                                          data-required="true"
                                          value={
                                            question[index] &&
                                            question[index].mandatoryMessage
                                              ? question[index].mandatoryMessage
                                              : ''
                                          }
                                          onChange={e =>
                                            handleMandatoryMessage(
                                              e,
                                              index,
                                              elem
                                            )
                                          }
                                        />
                                        <span
                                          className="cw-error cw-hidden"
                                          id={`errorMessage_${elem.value}_${index}`}
                                        >
                                          <i className="fas fa-exclamation-triangle mr-10"></i>
                                          Please enter the error message
                                        </span>
                                      </div>
                                    </>
                                  ) : null}
                                  {false ? (
                                    <div className="form-group d-flex cw-qna-lable-width">
                                      <label className="switch">
                                        <input
                                          type="checkbox"
                                          name="isQualifying"
                                        />
                                        <div className="slider round"></div>
                                      </label>
                                      <label
                                        className=" ml-20"
                                        htmlFor="isQualifying"
                                      >
                                        Make this a Qualifying question &nbsp;
                                        <span
                                          className="float-right  cw-icon cw-icon--help"
                                          data-tip="A qualifying question is a mandatory question that needs to be answered by the end consumer and is then validated or judged in order for that entry to be valid"
                                        >
                                          <i className="fa fa-question-circle"></i>
                                        </span>
                                      </label>
                                    </div>
                                  ) : null}
                                </div>
                              </>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })}
              </div>
              {showAllPurposeError ? (
                <div className="form-group mt-20 ml-1 generic-error-message">
                  <span
                    id={`all_purpose_error`}
                    className="cw-error"
                    data-control="name"
                  >
                    <i className="fas fa-exclamation-triangle mr-10"></i>
                    Please make sure you have entered all the mandatory fields
                  </span>
                </div>
              ) : null}
              {!isEdited ? (
                <div className="form-group mt-10 mr-3 saas-profile-select mb-20">
                  <Select
                    className={'cw-select--custom cw-color--primary '}
                    name={'questionField'}
                    label={'Label'}
                    placeholder={'Add a Question'}
                    options={questionTypes}
                    onChange={_handleQuestionSelection}
                    value={'Add an additional field'}
                  />
                </div>
              ) : null}
            </div>
            {/* <div className="col-sm-4 text-center">
            <button
                    type="submit"
                    className="btn btn-primary mt-1 rounded "
                    style={{width:'75%',marginLeft: '25%'}}
                    onClick={isOpenGuidelineModel}
                  >
                    Read Guidelines
                  </button>
            </div> */}
          </div>

          {/* <div className={`form-check mb-30 ${constant.featureFlags.ENABLE_QNA_GUIDELINES_CHECKBOX?"":"cw-hidden"}`}>
            <input
              className="form-check-input"
              type="checkbox"
              value={iConfirmQnA}
              id="iconfirm"
              name="iconfirm"
              checked={iConfirmQnA}
              onChange={e=>_handleCheckboxConfirm(e)}
            />
            <label
              className="form-check-label"
              htmlFor="iconfirm"
            >
              I confirm I have not requested a PII question or a duplicate question worded differently,<br/>as indicated in the <a href="#" onClick={isOpenGuidelineModel}>guidelines</a>.
              </label>
          </div> */}
          {questionProps &&
          questionProps.campaign &&
          questionProps.campaign.status === '6' ? (
            <div className="cw-form--action-cta">
              <button
                type="submit"
                className="btn btn-primary"
                onClick={_handleSubmit}
                disabled={!iConfirmQnA}
              >
                Next
              </button>
              <a
                href="javascript:void(0)"
                className="cw-save-to-draft mt-40"
                onClick={_handleCancel}
              >
                Cancel
              </a>
            </div>
          ) : (
            <div className="cw-form--action-cta">
              <button
                type="button"
                className="btn btn btn-outline-secondary mr-3"
                onClick={_handlebackButton}
              >
                Back
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                onClick={_handleSubmit}
                //disabled={!iConfirmQnA}
              >
                Next
              </button>
              {questionProps &&
              questionProps.campaign &&
              questionProps.campaign.isEdited ? (
                <a
                  href="javascript:void(0)"
                  className="cw-cancel-edit mt-40"
                  onClick={event => _openIsCancelModal(event)}
                >
                  Cancel changes
                </a>
              ) : (
                <a
                  href="javascript:void(0)"
                  className="cw-save-to-draft mt-40"
                  style={{ width: '40%' }}
                  onClick={event => _handleSubmit(event, true)}
                >
                  Save and exit
                </a>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default EpsilonQnA;
