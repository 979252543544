import React, {useState} from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Icon } from 'semantic-ui-react';
import {getHeaderNavMenuItems} from '../../selectors';
import { closeSidebar } from 'actions/app';
import classNames from 'classnames';

const ButtonWithCaret = ({selectedIndex, onClick, id, title}) => {
    const isActive = selectedIndex === id;
    return (
        <button type='button' className={classNames("bar-item bar-btn",{
            'is-active': isActive
        })} onClick={(e) => onClick(id, e)}>{title}<span className="toggle-icon">
            {isActive ? (
              <Icon link name="chevron up" />
            ) : (
              <Icon link name="chevron down" />
            )}  
          </span>
        </button>
    )
}

const CollapsibleBox = ({selectedIndex, id, children}) => {
    const isOpen = selectedIndex === id;
    return (
        <div className={isOpen? 'show' :'hide'}>
            {children}
        </div>
    )
}

const NavButton = ({onClick, id, title, isNested}) => {
    return (
        <button type='button' className={classNames("bar-item bar-btn",{
            'is-nested': isNested
        })} onClick={(e) => onClick(e)}>{title}
        </button>
    )
}

const SideBarNav = () => {
    const [selectedIndex, setSelectedIndex] = useState(null);
    const history = useHistory();
    const { authorizedUserData } = useSelector(state => state.authorized)
    const { isSideBarOpen } = useSelector(state => state.app);
    const dispatch = useDispatch();
    const menuItems = getHeaderNavMenuItems(authorizedUserData);
    const handleClick = (givenIndex, event) => {
        event.preventDefault()
        if(selectedIndex === givenIndex){
            setSelectedIndex(null)
        } else {
            setSelectedIndex(givenIndex)    
        }           
    }
    const onNavClick = link => {
        if (link) {
          history.push(link);
          dispatch(closeSidebar())
        }
      };
  return (
    <div className={classNames("sidebar-nav",{
        'hide-sidebar': !isSideBarOpen
    })}>
        {menuItems.map((item, index) => {
            if(item.dropDownItems) {
                return (
                    <>
                        <ButtonWithCaret selectedIndex={selectedIndex} onClick={handleClick} title={item.title} id={item.id}/>
                        <CollapsibleBox selectedIndex={selectedIndex} id={item.id}>
                            {item.dropDownItems.map((dropDownItem, itemIndex) => (
                                <NavButton onClick={() => onNavClick(dropDownItem.link)} title={dropDownItem.title} key={dropDownItem.id} isNested={true}/>
                            ))}
                            
                        </CollapsibleBox>
                    </>
                )
            } else {
                return <NavButton onClick={() => onNavClick(item.link)} title={item.title} key={item.id}/>
            }
        })}
    </div>
  );
};

export default SideBarNav;
