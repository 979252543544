import React from 'react';
import * as chart from 'react-chartjs-2';

import { Charts } from '../Chart/Chart';
import { Title } from '../../atoms/Title';
import { Input } from '../../atoms/Input';
import { SelectDropDown } from 'components/atoms/SelectDropDown';
import config from './config';

export const CampaignCharts = ({
  title,
  chartType,
  chartConfig,
  dropDownConfig,
  toggleConfig,
  className
}) => {
  let chartData = config[chartType];
  if (chartConfig) {
    chartData = { ...chartData, ...chartConfig };
  }

  return (
    <>
      <div className="campaign-trends-title-wrapper mb-20">
        <Title title={title} />
        {dropDownConfig ? (
          <div className="campaign-trends-filter-dropdown">
            <SelectDropDown
              className={dropDownConfig.className}
              name={dropDownConfig.name}
              label={dropDownConfig.label}
              id={dropDownConfig.id}
              options={dropDownConfig.options}
              onChange={dropDownConfig.onChange}
              value={dropDownConfig.value}
              components={{
                IndicatorSeparator: () => null
              }}
            />
          </div>
        ) : null}
        {toggleConfig ? (
          <div className="row">
            <Title
              className="cw-chart-toggle-title"
              title={toggleConfig.title}
            />
            <label className="switch-saas ml-10">
              <Input
                type="checkbox"
                name={`showToggle`}
                checked={toggleConfig.toggleValue}
                onChange={toggleConfig.onClick}
              />
              <div className="slider-saas round"> </div>
            </label>
          </div>
        ) : null}
      </div>
      <div className={`campaign-trends-chart ${className || ''}`}>
        <Charts
          chartType={chartType}
          options={chartData.options}
          data={chartData.data}
          plugins={chartData.plugins}
        />
      </div>
    </>
  );
};
