import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import QnAListOverlay from './QnAListOverlay';
import axios from 'axios';
import constants from 'constants/config';
import {
  debounceFunction,
  getDistinctBusinessFilters
} from 'selectors/index.js';

const QuestionAnswerNewList = props => {
  const [isOpenModel, setIsOpenModel] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [businessFilters, setBusinessFilter] = useState([]);
  const [showQuestions, setQuestionsOption] = useState(true);
  const [showAnswers, setAnswersOption] = useState(false);
  let fetchQuestionList = async () => {
    let queUrl =
      constants.serviceUrls.FETCH_ALL_QUESTIONS + '?language=' + props.language;
    await axios.get(queUrl).then(response => {
      let data = response && response.data;
      setQuestions(data);
      setBusinessFilter(getDistinctBusinessFilters(data));
    });
  };
  let fetchAnswersList = async () => {
    let ansUrl =
      constants.serviceUrls.FETCH_ALL_ANSWERS + '?language=' + props.language;
    await axios.get(ansUrl).then(response => {
      let data = response && response.data;
      setAnswers(data);
    });
  };

  let searchAnswers = text => {
    axios
      .get(
        constants.serviceUrls.FETCH_ALL_ANSWERS +
          '?language=' +
          props.language +
          '&search=' +
          text
      )
      .then(response => {
        let data = response && response.data;
        setAnswers(data);
      });
  };

  let searchQuestions = (text, group) => {
    let url =
      constants.serviceUrls.FETCH_ALL_QUESTIONS +
      '?language=' +
      props.language +
      '&search=' +
      text;
    if (group && group !== '') {
      url = url + ' &group=' + encodeURIComponent(group);
    }
    axios.get(url).then(response => {
      let data = response && response.data;
      setQuestions(data);
    });
  };

  useEffect(() => {
    fetchQuestionList();
    fetchAnswersList();
    // return () => {
    //   second
    // }
  }, []);

  const closeOverlayModal = event => {
    event.preventDefault();
    fetchQuestionList();
    fetchAnswersList();
    setIsOpenModel(false);
  };
  const openQnAOverlayModal = event => {
    event.preventDefault();
    setIsOpenModel(true);
  };

  const handleQTab = event => {
    event.preventDefault();
    setQuestionsOption(true);
    setAnswersOption(false);
  };
  const handleATab = event => {
    event.preventDefault();
    setAnswersOption(true);
    setQuestionsOption(false);
  };

  const optimizedAnswerVersion = debounceFunction(searchAnswers, 600);
  const optimizedQuestionVersion = debounceFunction(searchQuestions, 600);
  return (
    <>
      <div style={{ marginLeft: '2%' }}>
        <span>
          <strong>
            Browse the {showQuestions ? 'question' : 'answer'} master list
          </strong>
        </span>
        &nbsp;{' '}
        <span style={{ color: '#007dbb' }}>{props.campaignLanguage}</span>
        <span
          className={'qna-guideline-close'}
          onClick={props.closeModal}
          style={{ marginRight: '2%' }}
        ></span>
      </div>

      <div className="ui top attached tabular menu qna-tab-overlay qna-tab-overlay-custom">
        <div
          className={`item  qna-tab-content ${
            showQuestions ? 'active qna-tab-content--active' : ''
          }`}
          onClick={handleQTab}
        >
          Questions
        </div>
        <div
          className={`item qna-tab-content ${
            !showQuestions ? 'active qna-tab-content--active' : ''
          }`}
          onClick={handleATab}
        >
          Answers
        </div>
      </div>
      <QnAListOverlay
        closeQnAModal={props.closeModal}
        questions={questions}
        answers={answers}
        businessFilters={businessFilters}
        showQuestions={showQuestions}
        searchAnswers={optimizedAnswerVersion}
        searchQuestions={optimizedQuestionVersion}
        campaignLanguage={props.campaignLanguage}
      />

      {/* </Modal> */}
    </>
  );
};

export default QuestionAnswerNewList;
