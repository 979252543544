import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import Alert from '../Alert';
import Select from 'react-select';
import { scrollToTop } from 'selectors';
import constant from '../../constants';
import BreadCrumb from '../BreadCrumb';
import axios from 'axios';
import RichTextEditor from '../richTextEditor';
import { setNotificationMessage } from '../../actions/dashboard';
import {
  setApproverEditCampaignStatus,
  setApproverEditCampaignStatusMessage,
  setApproverEditLastUpdatedScreen
} from '../../actions/app';
import { getAuthoringFieldData } from '../../actions/saasFormAuthoring';
import {
  fetchCampaignData,
  cancelCampaignChanges
} from '../../actions/overview';
import Modal from 'react-modal';
import Loader from '../Loader';
import { epsilonMarket } from '../../selectors/index';
import CampaignCancelModel from '../CampaignCancelModel';

class SaasFormProfileFieldDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      campaign: {},
      campaignId: this.props.match.params.id,
      showMessageAtTop: false,
      matchProps: props.match,
      profile: [],
      profileRichText: [],
      provinces: [{ value: '', label: '' }],
      expand: 0,
      modalIsOpen: false,
      expandEmail: true,
      mandatoryToggle: true,
      nonEmailfieldRequired: false,
      genderData: '',
      fieldLabel: '',
      toolTipText: '',
      errorMessage: '',
      regExp: '',
      isErrorMessageFilled: true,
      isEmailLabelFilled: true,
      showErrorMessage: false,
      genderErrorMessage: false,
      isGenderErrorMessageFilled: false,
      isGenderFieldLabelErrorMessage: false,
      genderFieldErrorMessage: false,
      showAllPurposeError: false,
      showHeadingError: false,
      showParagraphError: false,
      isNewModalOpen: false,
      showCommChannelValidationMessage: false,
      isCancelModalOpen: false,
      isLegalAge: epsilonMarket(
        this.props.campaign &&
          this.props.campaign.country &&
          this.props.campaign.country.code
      )
        ? 13
        : 16
    };

    this._openModal = this._openModal.bind(this);
    this._closeModal = this._closeModal.bind(this);
    this._openIsCancelModal = this._openIsCancelModal.bind(this);
    this._closeIsCancelModal = this._closeIsCancelModal.bind(this);
    this._cancelSaasCampaign = this._cancelSaasCampaign.bind(this);
    this._handleInputChange = this._handleInputChange.bind(this);
  }
  _openModal(event) {
    event.preventDefault();
    this.setState(state => {
      return { ...state, ...{ modalIsOpen: true } };
    });
  }
  _closeModal() {
    this.setState(state => {
      return { ...state, ...{ modalIsOpen: false } };
    });
  }
  canNewProfileItemsBeAdded = profileItem => {
    let isFieldValid = true;
    let isMandatoryFilled = true;
    let isLegalAgeMessageFilled = true;
    if (profileItem) {
      if (
        profileItem &&
        Object.values(profileItem.fieldLabel).filter(elem => /\S/.test(elem))
          .length === 0
      ) {
        isFieldValid = false;
        document.getElementById(`labelError_${profileItem.dataType}`) &&
          document
            .getElementById(`labelError_${profileItem.dataType}`)
            .classList.remove('cw-hidden');

        document
          .getElementById(`fieldLabel_${profileItem.dataType}`)
          .classList.contains('cw-error-focus') ||
          document
            .getElementById(`fieldLabel_${profileItem.dataType}`)
            .classList.add('cw-error-focus');
      }
      if (
        profileItem &&
        profileItem.type === 'field' &&
        profileItem.mandatoryToggle
      ) {
        if (
          profileItem &&
          Object.values(profileItem.mandatoryMessage).filter(elem =>
            /\S/.test(elem)
          ).length === 0
        ) {
          isMandatoryFilled = false;
          document.getElementById(`error_label_${profileItem.dataType}`) &&
            document
              .getElementById(`error_label_${profileItem.dataType}`)
              .classList.remove('cw-hidden');

          document
            .getElementById(`eorrrMessageText_${profileItem.dataType}`)
            .classList.contains('cw-error-focus') ||
            document
              .getElementById(`eorrrMessageText_${profileItem.dataType}`)
              .classList.add('cw-error-focus');
        }
      }

      if (
        profileItem &&
        profileItem.dataType === 'dateOfBirth' &&
        (!profileItem.validationMessage ||
          profileItem.validationMessage.trim().length === 0)
      ) {
        isLegalAgeMessageFilled = false;
        document.getElementById(
          `error_ageValidation_${profileItem.dataType}`
        ) &&
          document
            .getElementById(`error_ageValidation_${profileItem.dataType}`)
            .classList.remove('cw-hidden');

        document
          .getElementById(`ageValidation_${profileItem.dataType}`)
          .classList.contains('cw-error-focus') ||
          document
            .getElementById(`ageValidation_${profileItem.dataType}`)
            .classList.add('cw-error-focus');
      }
    }
    return isMandatoryFilled && isFieldValid && isLegalAgeMessageFilled;
  };

  _handleProfileField = selectedOption => {
    let hasNoDuplicate = this.state.profile.some(elem => {
      return (
        elem.label === selectedOption.label &&
        selectedOption.dataType !== 'heading' &&
        selectedOption.dataType !== 'paragraph'
      );
    });
    if (selectedOption && selectedOption.dataType === 'stateOrProvince') {
      selectedOption.questionType = '';
    }
    let isLabelFilled = true;
    let isErrorMessageFilled = true;

    let emailData = this.state.profile.find(elem => elem.dataType === 'email');
    let fName = this.state.profile.find(elem => elem.dataType === 'firstName');
    let middleName = this.state.profile.find(
      elem => elem.dataType === 'middleName'
    );
    let lastName = this.state.profile.find(
      elem => elem.dataType === 'lastName'
    );
    let doB = this.state.profile.find(elem => elem.dataType === 'dateOfBirth');
    let mobileData = this.state.profile.find(
      elem => elem.dataType === 'mobileNumber'
    );
    let stateOrProvinceData = this.state.profile.find(
      elem => elem.dataType === 'stateOrProvince'
    );
    let houseNumber = this.state.profile.find(
      elem => elem.dataType === 'houseNameOrNumber'
    );
    let addressData1 = this.state.profile.find(
      elem => elem.dataType === 'addressLine1'
    );
    let addressData2 = this.state.profile.find(
      elem => elem.dataType === 'addressLine2'
    );
    let city = this.state.profile.find(elem => elem.dataType === 'cityName');
    let stateOrProvince = this.state.profile.find(
      elem => elem.dataType === 'stateOrProvince'
    );
    let postalCodeData = this.state.profile.find(
      elem => elem.dataType === 'postalCode'
    );
    let countryData = this.state.profile.find(
      elem => elem.dataType === 'addressCountry'
    );
    let gender = this.state.profile.find(elem => elem.dataType === 'gender');
    let honorificPrefixData = this.state.profile.find(
      elem => elem.dataType === 'honorificPrefix'
    );
    let honorificSufixData = this.state.profile.find(
      elem => elem.dataType === 'honorificSuffix'
    );
    let headingData = this.state.profile.filter(
      elem => elem.dataType === 'heading'
    );
    let paragraphData = this.state.profile.filter(
      elem => elem.dataType === 'paragraph'
    );

    if (this.state.profile && this.state.profile.length > 0) {
      if (headingData && headingData.length > 0) {
        this.state.profile.forEach((elem, index) => {
          if (elem.dataType === 'heading') {
            if (elem.heading.trim().length === 0) {
              isLabelFilled = false;
              this.setState({ expand: index });
              document.getElementById(
                `heading_error_${elem.dataType}_${index}`
              ) &&
                document
                  .getElementById(`heading_error_${elem.dataType}_${index}`)
                  .classList.remove('cw-hidden');

              document
                .getElementById(`heading_${index}`)
                .classList.contains('cw-error-focus') ||
                document
                  .getElementById(`heading_${index}`)
                  .classList.add('cw-error-focus');
            } else {
              document.getElementById(
                `heading_error_${elem.dataType}_${index}`
              ) &&
                document
                  .getElementById(`heading_error_${elem.dataType}_${index}`)
                  .classList.add('cw-hidden');

              document
                .getElementById(`heading_${index}`)
                .classList.contains('cw-error-focus') &&
                document
                  .getElementById(`heading_${index}`)
                  .classList.remove('cw-error-focus');
            }
          }
        });
      }
      if (
        stateOrProvinceData &&
        stateOrProvinceData.questionType === 'Single choice'
      ) {
        this.state.provinces.map((stateofProvience, ansIndex) => {
          if (stateofProvience.value.trim().length === 0) {
            this.setState({
              expand: this.state.profile.findIndex(
                element => element.dataType === 'stateOrProvince'
              )
            });
            document.getElementById(`province_Error_Message_${ansIndex}`) &&
              document
                .getElementById(`province_Error_Message_${ansIndex}`)
                .classList.remove('cw-hidden');

            document
              .getElementById(`answerTranslatedLabel_${ansIndex}`)
              .classList.contains('cw-error-focus') ||
              document
                .getElementById(`answerTranslatedLabel_${ansIndex}`)
                .classList.add('cw-error-focus');

            isLabelFilled = false;
          }
        });
      }
      if (paragraphData && paragraphData.length > 0) {
        this.state.profile.forEach((elem, index) => {
          if (elem.dataType === 'paragraph') {
            if (
              elem.paragraph
                .replace(/\r?\n|\r/gm, '')
                .replace(/<[^>]+>/gm, '')
                .replace(/&nbsp;/gm, '')
                .trim().length === 0
            ) {
              isLabelFilled = false;
              document.getElementById(`paragraph_error_paragraph_${index}`) &&
                document
                  .getElementById(`paragraph_error_paragraph_${index}`)
                  .classList.remove('cw-hidden');

              document
                .getElementById(`paragraph_${index}`)
                .classList.contains('cw-error-focus') ||
                document
                  .getElementById(`paragraph_${index}`)
                  .classList.add('cw-error-focus');
            } else {
              document.getElementById(`paragraph_error_paragraph_${index}`) &&
                document
                  .getElementById(`paragraph_error_paragraph_${index}`)
                  .classList.add('cw-hidden');

              document
                .getElementById(`paragraph_${index}`)
                .classList.contains('cw-error-focus') &&
                document
                  .getElementById(`paragraph_${index}`)
                  .classList.remove('cw-error-focus');
            }
          }
        });
      }

      if (!this.canNewProfileItemsBeAdded(emailData)) {
        isLabelFilled = false;
      }
      if (!this.canNewProfileItemsBeAdded(mobileData)) {
        isLabelFilled = false;
      }
      if (!this.canNewProfileItemsBeAdded(honorificPrefixData)) {
        isLabelFilled = false;
      }
      if (!this.canNewProfileItemsBeAdded(fName)) {
        isLabelFilled = false;
      }
      if (!this.canNewProfileItemsBeAdded(middleName)) {
        isLabelFilled = false;
      }
      if (!this.canNewProfileItemsBeAdded(lastName)) {
        isLabelFilled = false;
      }
      if (!this.canNewProfileItemsBeAdded(doB)) {
        isLabelFilled = false;
      }
      if (!this.canNewProfileItemsBeAdded(houseNumber)) {
        isLabelFilled = false;
      }
      if (!this.canNewProfileItemsBeAdded(addressData1)) {
        isLabelFilled = false;
      }
      if (!this.canNewProfileItemsBeAdded(addressData2)) {
        isLabelFilled = false;
      }
      if (!this.canNewProfileItemsBeAdded(postalCodeData)) {
        isLabelFilled = false;
      }
      if (!this.canNewProfileItemsBeAdded(city)) {
        isLabelFilled = false;
      }
      if (!this.canNewProfileItemsBeAdded(stateOrProvince)) {
        isLabelFilled = false;
      }
      if (!this.canNewProfileItemsBeAdded(countryData)) {
        isLabelFilled = false;
      }
      if (!this.canNewProfileItemsBeAdded(honorificSufixData)) {
        isLabelFilled = false;
      }

      if (gender) {
        if (
          gender &&
          Object.values(gender.fieldLabel.trim()).filter(elem =>
            /\S/.test(elem)
          ).length === 0
        ) {
          isLabelFilled = false;
          document
            .getElementById(`fieldLabel_${gender.dataType}`)
            .classList.contains('cw-error-focus') ||
            document
              .getElementById(`fieldLabel_${gender.dataType}`)
              .classList.add('cw-error-focus');

          this.setState({
            isGenderFieldLabelErrorMessage: true
          });
        } else {
          document
            .getElementById(`fieldLabel_${gender.dataType}`)
            .classList.contains('cw-error-focus') &&
            document
              .getElementById(`fieldLabel_${gender.dataType}`)
              .classList.remove('cw-error-focus');

          this.setState({
            isGenderFieldLabelErrorMessage: false
          });
        }
        if (gender && gender.mandatoryToggle) {
          if (
            gender &&
            Object.values(gender.mandatoryMessage.trim()).filter(elem =>
              /\S/.test(elem)
            ).length === 0
          ) {
            isLabelFilled = false;
            this.setState({});
          }
        }
        if (gender && gender.values) {
          let seleLength = gender.values.filter(ele => ele.isChecked).length;
          let hasTranslatedValue =
            gender.values.filter(
              ele => ele.isChecked && ele.translatedValue.trim() === ''
            ).length > 0;

          if (hasTranslatedValue) {
            isLabelFilled = false;
            this.setState({
              genderFieldErrorMessage: true
            });
          }
          if (seleLength < 2) {
            isLabelFilled = false;
            this.setState({
              genderErrorMessage: true
            });
          } else {
            this.setState({
              genderErrorMessage: false
            });
          }
        }
      }
    }
    this.state.profile.forEach((elem, index) => {
      if (elem.type === 'field' && elem.mandatoryToggle) {
        if (elem.mandatoryMessage.trim().length === 0) {
          isLabelFilled = false;
          this.setState({ expand: index });
          document.getElementById(`error_label_${elem.dataType}_${index}`) &&
            document
              .getElementById(`error_label_${elem.dataType}_${index}`)
              .classList.remove('cw-hidden');

          document
            .getElementById(`eorrrMessageText_${elem.dataType}`)
            .classList.contains('cw-error-focus') ||
            document
              .getElementById(`eorrrMessageText_${elem.dataType}`)
              .classList.add('cw-error-focus');
        } else {
          document.getElementById(`error_label_${elem.dataType}_${index}`) &&
            document
              .getElementById(`error_label_${elem.dataType}_${index}`)
              .classList.add('cw-hidden');

          document
            .getElementById(`eorrrMessageText_${elem.dataType}`)
            .classList.contains('cw-error-focus') &&
            document
              .getElementById(`eorrrMessageText_${elem.dataType}`)
              .classList.remove('cw-error-focus');
        }
      }
      /*if (elem.dataType === "dateOfBirth") {
        let countrySelected =
          this.props.campaign &&
          this.props.campaign.country &&
          this.props.campaign.country.code;
        let minAge = epsilonMarket(countrySelected) ? 13 : 16;
        if (this.state.isLegalAge < minAge) {
          document.getElementById(`error_legalAgeValidation_${elem.dataType}`) &&
            document
              .getElementById(`error_legalAgeValidation_${elem.dataType}`)
              .classList.remove("cw-hidden");

          document
            .getElementById(`legalAgeValidation_${elem.dataType}`)
            .classList.contains("cw-error-focus") ||
            document
              .getElementById(`legalAgeValidation_${elem.dataType}`)
              .classList.add("cw-error-focus");
              isLabelFilled = false;
        }
      }*/
    });

    if (this.state.profile && this.state.profile.length > 0) {
      this.state.profile.forEach((elem, index) => {
        if (
          elem.dataType === 'email' ||
          elem.dataType === 'postalCode' ||
          elem.dataType === 'mobileNumber'
        ) {
          if (
            elem &&
            (!elem.validationMessage ||
              elem.validationMessage.trim().length == 0)
          ) {
            isLabelFilled = false;
            this.setState({ expand: index });
            document.getElementById(
              `validation_error_label_${elem.dataType}_${index}`
            ) &&
              document
                .getElementById(
                  `validation_error_label_${elem.dataType}_${index}`
                )
                .classList.remove('cw-hidden');

            document
              .getElementById(`formatValidation_${elem.dataType}`)
              .classList.contains('cw-error-focus') ||
              document
                .getElementById(`formatValidation_${elem.dataType}`)
                .classList.add('cw-error-focus');
          } else {
            document.getElementById(
              `validation_error_label_${elem.dataType}_${index}`
            ) &&
              document
                .getElementById(
                  `validation_error_label_${elem.dataType}_${index}`
                )
                .classList.add('cw-hidden');

            document
              .getElementById(`formatValidation_${elem.dataType}`)
              .classList.contains('cw-error-focus') &&
              document
                .getElementById(`formatValidation_${elem.dataType}`)
                .classList.remove('cw-error-focus');
          }
        }
      });
    }

    if (!hasNoDuplicate) {
      this.setState({ showErrorMessage: false });
      this.setState({ showCommChannelValidationMessage: false });
      if (isLabelFilled) {
        this.setState({
          showAllPurposeError: false
        });
        selectedOption.fieldLabel = selectedOption.translatedLabel;
        selectedOption.mandatoryToggle = false;
        if (selectedOption.dataType === 'heading') {
          selectedOption.heading = '';
          selectedOption.mandatoryToggle = false;
        }
        if (selectedOption.dataType === 'paragraph') {
          selectedOption.paragraph = '';
          selectedOption.mandatoryToggle = false;
        }
        if (selectedOption.dataType === 'email') {
          selectedOption.mandatoryToggle = true;
        }
        if (selectedOption.dataType === 'mobileNumber') {
          selectedOption.mandatoryToggle = true;
        }
        if (selectedOption && selectedOption.dataType === 'dateOfBirth') {
          const defaultAge = epsilonMarket(
            this.props.campaign &&
              this.props.campaign.country &&
              this.props.campaign.country.code
          )
            ? 13
            : 16;
          this.setState({
            isLegalAge: defaultAge
          });
          selectedOption.validationMessage = selectedOption.validationMessage
            ? selectedOption.validationMessage.replace(
                '${age}',
                defaultAge.toString()
              )
            : null;
        }

        this.state.profile.push(selectedOption);
        this.setState({
          profile: [...this.state.profile]
        });
        this.setState({ expand: this.state.profile.indexOf(selectedOption) });
        this.setState({ expandEmail: false });
      } else {
        this.setState({
          showAllPurposeError: true
        });
      }
    } else {
      this.setState({ showErrorMessage: true });
      scrollToTop();
    }
  };

  componentDidMount() {
    this.campaignId = this.props.match.params.id;
    let campaignType =
      this.props.campaign &&
      this.props.campaign.campaignType === 'Direct messaging'
        ? 'Direct'
        : this.props.campaign && this.props.campaign.campaignType;

    let countrySelected =
      this.props.campaign &&
      this.props.campaign.country &&
      this.props.campaign.country.code;
    if (epsilonMarket(countrySelected)) {
      this.props.fetchCampaignData(
        this.campaignId,
        false,
        true,
        campaignType,
        countrySelected
      );
    } else {
      this.props.fetchCampaignData(
        this.campaignId,
        false,
        true,
        campaignType,
        ''
      );
    }

    this.language = this.props.campaign.language
      ? this.props.campaign.language.code
      : '';

    this.setState(state => {
      return { ...state, ...{ campaignId: this.campaignId } };
    });

    let fields,
      fieldsItems = [];
    axios
      .get(
        constant.serviceUrls.CAMPAIGN_DETAILS_SERVICE_URL +
          '/' +
          this.state.campaignId
      )
      .then(response => {
        let campaign = response.data[0];
        this.status = campaign && campaign.status;
        fields =
          campaign && campaign.campaignDetail && campaign.campaignDetail.fields;
        if (fields.length > 0) {
          let stateOrProvinceObj = fields.find(
            elem =>
              elem.dataType === 'stateOrProvince' &&
              elem.questionType === 'Single choice'
          );
          this.setState({
            profile: fields ? fields : [],
            profileRichText: fields ? fields : [],
            provinces:
              stateOrProvinceObj && stateOrProvinceObj['answers']
                ? stateOrProvinceObj['answers']
                : [{ value: '', label: '' }],
            expand: fields.length,
            isLegalAge:
              campaign.campaignDetail && campaign.campaignDetail.ageOfConsent
          });
          this.handleAccordionExpandCollapse(-1);
        } else {
          setTimeout(() => {
            if (
              this.props &&
              this.props.formMetaData &&
              this.props.formMetaData.emailData !== undefined
            ) {
              let emailData =
                this.props &&
                this.props.formMetaData &&
                this.props.formMetaData.emailData;
              emailData[0].fieldLabel =
                emailData && emailData[0].translatedLabel;
              emailData[0].mandatoryToggle = true;
              this.state.profile.push(emailData && emailData[0]);
              this.setState({ profile: this.state.profile });
              this.handleAccordionExpandCollapse(0);
            }
          }, 2000);
        }
      });
  }

  _openIsCancelModal(event) {
    event.preventDefault();
    this.setState({
      isCancelModalOpen: true
    });
  }

  _closeIsCancelModal() {
    this.setState({
      isCancelModalOpen: false
    });
  }

  _cancelSaasCampaign(event) {
    event.preventDefault();
    this.setState({
      isCancelModalOpen: false
    });
    this.props.cancelCampaignChanges(this.campaignId);
    this.props.history.push(
      '/campaign-wizard/campaign/' + this.state.campaignId
    );
  }

  handleProvinceLabel = (e, ansIndex) => {
    let fieldLabel = e.target.value;
    let provinces = this.state.provinces;
    provinces[ansIndex] = { value: fieldLabel, label: fieldLabel };
    document.getElementById(`province_Error_Message_${ansIndex}`) &&
      document
        .getElementById(`province_Error_Message_${ansIndex}`)
        .classList.add('cw-hidden');

    document
      .getElementById(`answerTranslatedLabel_${ansIndex}`)
      .classList.contains('cw-error-focus') &&
      document
        .getElementById(`answerTranslatedLabel_${ansIndex}`)
        .classList.remove('cw-error-focus');

    this.setState({
      provinces: provinces
    });
  };
  _addProvince = e => {
    let isValid = false;
    let provinces = this.state.provinces;
    provinces.map((province, index) => {
      if (province.value.trim().length === 0) {
        document.getElementById(`province_Error_Message_${index}`) &&
          document
            .getElementById(`province_Error_Message_${index}`)
            .classList.remove('cw-hidden');

        document
          .getElementById(`answerTranslatedLabel_${index}`)
          .classList.contains('cw-error-focus') ||
          document
            .getElementById(`answerTranslatedLabel_${index}`)
            .classList.add('cw-error-focus');

        isValid = true;
      }
    });

    if (isValid) {
      return;
    }
    provinces && provinces.push({ label: '', value: '' });

    provinces.forEach((ele, anindex) => {
      ele = provinces[anindex];
    });
    this.setState({
      provinces: provinces
    });
  };

  _deleteProvince = async indexValue => {
    let provinces = this.state.provinces;
    // let updatedAnswers =
    provinces && provinces.splice(indexValue, 1);
    document.getElementById('province_Error_Message') &&
      document
        .getElementById('province_Error_Message')
        .classList.add('cw-hidden');
    provinces.forEach((ele, anindex) => {
      ele = provinces[anindex];
    });
    provinces.map((province, provIndex) => {
      if (province.value.trim().length === 0) {
        document.getElementById(`province_Error_Message_${provIndex}`) &&
          document
            .getElementById(`province_Error_Message_${provIndex}`)
            .classList.remove('cw-hidden');

        document
          .getElementById(`answerTranslatedLabel_${provIndex}`)
          .classList.contains('cw-error-focus') ||
          document
            .getElementById(`answerTranslatedLabel_${provIndex}`)
            .classList.add('cw-error-focus');
      } else {
        document
          .getElementById(`province_Error_Message_${provIndex}`)
          .classList.add('cw-hidden');

        document
          .getElementById(`answerTranslatedLabel_${provIndex}`)
          .classList.contains('cw-error-focus') &&
          document
            .getElementById(`answerTranslatedLabel_${provIndex}`)
            .classList.remove('cw-error-focus');
      }
    });
    this.setState({
      provinces: provinces
    });
  };

  _openNewModal = event => {
    event.preventDefault();
    this.setState({
      isNewModalOpen: true
    });
  };
  _closeNewModal = () => {
    this.setState({
      isNewModalOpen: false
    });
  };

  removeAccordion = index => {
    let updatedProfileArray = [...this.state.profile];
    let updateProfileTestData = [...this.state.profileRichText];
    updatedProfileArray.splice(index, 1);
    updateProfileTestData.splice(index, 1);
    this.setState({
      profile: [...updatedProfileArray],
      profileRichText: [...updatedProfileArray]
    });
  };

  _handleQuestionType = (e, index, elem) => {
    let selectedValue = e.value;
    let newProfile = [...this.state.profile];
    newProfile[index]['questionType'] = selectedValue;
    this.setState({
      profile: [...newProfile]
    });
  };

  onLabelChange = (e, index, elem) => {
    let fieldLabel = e.target.value;
    let newProfile = [...this.state.profile];

    newProfile[index] = {
      label: this.state.profile[index].label
        ? this.state.profile[index].label
        : elem.label,
      toolTipText: this.state.profile[index].toolTipText
        ? this.state.profile[index].toolTipText
        : '',
      mandatoryMessage: this.state.profile[index].mandatoryMessage
        ? this.state.profile[index].mandatoryMessage
        : '',
      fieldLabel: fieldLabel,
      mandatoryToggle: this.state.profile[index].mandatoryToggle
        ? this.state.profile[index].mandatoryToggle
        : false,
      defaultDisplayOrder: this.state.profile[index].defaultDisplayOrder,
      dataType: elem.value ? elem.value : elem.dataType,
      type: elem.type
    };
    if (this.state.profile && this.state.profile[index].label == 'Gender') {
      newProfile[index].values = elem.values;
      newProfile[index].translatedLabel = elem.fieldLabel;
    }
    if (
      this.state.profile &&
      this.state.profile[index].label == 'State or Province'
    ) {
      newProfile[index].questionType = elem.questionType;
      newProfile[index].answers = elem.answers;
    }
    if (
      this.state.profile &&
      (this.state.profile[index].label === 'Mobile Number' ||
        this.state.profile[index].label === 'Postal Code' ||
        this.state.profile[index].label === 'Date of Birth' ||
        this.state.profile[index].label === 'Email Address')
    ) {
      newProfile[index].validationMessage =
        this.state.profile && this.state.profile[index].validationMessage;
    }
    document.getElementById(`labelError_${elem.dataType}`) &&
      document
        .getElementById(`labelError_${elem.dataType}`)
        .classList.add('cw-hidden');

    document
      .getElementById(`fieldLabel_${elem.dataType}`)
      .classList.contains('cw-error-focus') &&
      document
        .getElementById(`fieldLabel_${elem.dataType}`)
        .classList.remove('cw-error-focus');

    this.setState({
      profile: [...newProfile]
    });
  };

  onToolTipChange = (e, index, elem) => {
    let toolTip = e.target.value;
    let newProfile = [...this.state.profile];

    newProfile[index] = {
      label: this.state.profile[index].label
        ? this.state.profile[index].label
        : elem.label,
      toolTipText: toolTip,
      mandatoryMessage: this.state.profile[index].mandatoryMessage
        ? this.state.profile[index].mandatoryMessage
        : '',
      fieldLabel: this.state.profile[index].fieldLabel
        ? this.state.profile[index].fieldLabel
        : '',
      mandatoryToggle: this.state.profile[index].mandatoryToggle
        ? this.state.profile[index].mandatoryToggle
        : false,
      defaultDisplayOrder: this.state.profile[index].defaultDisplayOrder,
      dataType: elem.value ? elem.value : elem.dataType,
      type: elem.type
    };
    if (this.state.profile && this.state.profile[index].label == 'Gender') {
      newProfile[index].values = elem.values;
    }
    if (
      this.state.profile &&
      this.state.profile[index].label == 'State or Province'
    ) {
      newProfile[index].questionType = elem.questionType;
      newProfile[index].answers = elem.answers;
    }
    if (
      this.state.profile &&
      (this.state.profile[index].label === 'Mobile Number' ||
        this.state.profile[index].label === 'Postal Code' ||
        this.state.profile[index].label === 'Date of Birth' ||
        this.state.profile[index].label === 'Email Address')
    ) {
      newProfile[index].validationMessage =
        this.state.profile && this.state.profile[index].validationMessage;
    }

    this.setState({
      profile: [...newProfile]
    });
  };

  onErrorMessageChange = (e, index, elem) => {
    let errorMessage = e.target.value;
    let newProfile = [...this.state.profile];
    newProfile[index] = {
      label: this.state.profile[index].label
        ? this.state.profile[index].label
        : elem.label,
      toolTipText: this.state.profile[index].toolTipText
        ? this.state.profile[index].toolTipText
        : '',
      mandatoryMessage: this.state.profile[index].mandatoryToggle
        ? errorMessage
        : '',
      mandatoryToggle: this.state.profile[index].mandatoryToggle
        ? this.state.profile[index].mandatoryToggle
        : false,
      defaultDisplayOrder: this.state.profile[index].defaultDisplayOrder,
      fieldLabel: this.state.profile[index].fieldLabel
        ? this.state.profile[index].fieldLabel
        : '',
      dataType: elem.value ? elem.value : elem.dataType,
      type: elem.type
    };
    if (this.state.profile && this.state.profile[index].label == 'Gender') {
      newProfile[index].values = elem.values;
    }
    if (
      this.state.profile &&
      this.state.profile[index].label == 'State or Province'
    ) {
      newProfile[index].questionType = elem.questionType;
      newProfile[index].answers = elem.answers;
    }
    if (
      this.state.profile &&
      (this.state.profile[index].label === 'Mobile Number' ||
        this.state.profile[index].label === 'Postal Code' ||
        this.state.profile[index].label === 'Date of Birth' ||
        this.state.profile[index].label === 'Email Address')
    ) {
      newProfile[index].validationMessage =
        this.state.profile && this.state.profile[index].validationMessage;
    }

    document.getElementById(`error_label_${elem.dataType}`) &&
      document
        .getElementById(`error_label_${elem.dataType}`)
        .classList.add('cw-hidden');

    document
      .getElementById(`eorrrMessageText_${elem.dataType}`)
      .classList.contains('cw-error-focus') &&
      document
        .getElementById(`eorrrMessageText_${elem.dataType}`)
        .classList.remove('cw-error-focus');

    if (elem.type === 'field' && elem.mandatoryToggle) {
      if (newProfile[index].mandatoryMessage.trim().length === 0) {
        this.setState({ expand: index });
      } else {
        document.getElementById(`error_label_${elem.dataType}_${index}`) &&
          document
            .getElementById(`error_label_${elem.dataType}_${index}`)
            .classList.add('cw-hidden');
      }
    }

    this.setState({
      profile: [...newProfile]
    });
  };

  onMobileRegexChange = (e, index, elem) => {
    let mobileFormatErrorMessage = e.target.value;
    document.getElementById(
      `validation_error_label_${elem.dataType}_${index}`
    ) &&
      document
        .getElementById(`validation_error_label_${elem.dataType}_${index}`)
        .classList.add('cw-hidden');

    document
      .getElementById(`formatValidation_${elem.dataType}`)
      .classList.contains('cw-error-focus') &&
      document
        .getElementById(`formatValidation_${elem.dataType}`)
        .classList.remove('cw-error-focus');

    let newProfile = [...this.state.profile];
    newProfile[index] = {
      label: this.state.profile[index].label
        ? this.state.profile[index].label
        : elem.label,
      toolTipText: this.state.profile[index].toolTipText
        ? this.state.profile[index].toolTipText
        : '',
      mandatoryMessage: this.state.profile[index].mandatoryMessage
        ? this.state.profile[index].mandatoryMessage
        : '',
      mandatoryToggle: this.state.profile[index].mandatoryToggle
        ? this.state.profile[index].mandatoryToggle
        : false,
      defaultDisplayOrder: this.state.profile[index].defaultDisplayOrder,
      fieldLabel: this.state.profile[index].fieldLabel
        ? this.state.profile[index].fieldLabel
        : '',
      validationMessage: mobileFormatErrorMessage,
      dataType: elem.value ? elem.value : elem.dataType,
      type: elem.type
    };
    if (this.state.profile && this.state.profile[index].label == 'Gender') {
      newProfile[index].values = elem.values;
    }

    this.setState({
      profile: [...newProfile]
    });
  };

  _handleInputChange(event, elem, index) {
    let newProfile = [...this.state.profile];
    const existingAge = this.state.isLegalAge;
    newProfile[index]['validationMessage'] = newProfile[index][
      'validationMessage'
    ]
      ? newProfile[index]['validationMessage'].replace(
          existingAge,
          event.value.toString()
        )
      : null;
    this.setState({
      isLegalAge: event.value,
      profile: [...newProfile]
    });

    /*let countrySelected =
    this.props.campaign &&
    this.props.campaign.country &&
    this.props.campaign.country.code;
    let minAge = epsilonMarket(countrySelected) ? 13: 16;
    if(event.value < minAge){
      document.getElementById(`error_legalAgeValidation_${elem.dataType}`) &&
      document
        .getElementById(`error_legalAgeValidation_${elem.dataType}`)
        .classList.remove("cw-hidden");
      
        document
        .getElementById(`legalAgeValidation_${elem.dataType}`)
        .classList.contains("cw-error-focus") ||
        document
          .getElementById(`legalAgeValidation_${elem.dataType}`)
          .classList.add("cw-error-focus");
    }else {
      document.getElementById(`error_legalAgeValidation_${elem.dataType}`) &&
      document
        .getElementById(`error_legalAgeValidation_${elem.dataType}`)
        .classList.add("cw-hidden");

        document
        .getElementById(`legalAgeValidation_${elem.dataType}`)
        .classList.contains("cw-error-focus") &&
        document
          .getElementById(`legalAgeValidation_${elem.dataType}`)
          .classList.remove("cw-error-focus");
    }*/
  }

  onLegalAgeValidationChange = (e, index, elem) => {
    let legalAgeValidationMessage = e.target.value;
    let newProfile = [...this.state.profile];
    document.getElementById(`error_ageValidation_${elem.dataType}`) &&
      document
        .getElementById(`error_ageValidation_${elem.dataType}`)
        .classList.add('cw-hidden');

    document
      .getElementById(`ageValidation_${elem.dataType}`)
      .classList.contains('cw-error-focus') &&
      document
        .getElementById(`ageValidation_${elem.dataType}`)
        .classList.remove('cw-error-focus');

    newProfile[index] = {
      label: this.state.profile[index].label
        ? this.state.profile[index].label
        : elem.label,
      toolTipText: this.state.profile[index].toolTipText
        ? this.state.profile[index].toolTipText
        : '',
      mandatoryMessage: this.state.profile[index].mandatoryMessage
        ? this.state.profile[index].mandatoryMessage
        : '',
      mandatoryToggle: this.state.profile[index].mandatoryToggle
        ? this.state.profile[index].mandatoryToggle
        : false,
      defaultDisplayOrder: this.state.profile[index].defaultDisplayOrder,
      fieldLabel: this.state.profile[index].fieldLabel
        ? this.state.profile[index].fieldLabel
        : '',
      validationMessage: legalAgeValidationMessage,
      dataType: elem.value ? elem.value : elem.dataType,
      type: elem.type
    };

    this.setState({
      profile: [...newProfile]
    });
  };

  onMandatoryToggleChange = (e, mandatoryToggle, index, elem) => {
    let newProfile = [...this.state.profile];

    newProfile[index] = {
      label: this.state.profile[index].label
        ? this.state.profile[index].label
        : elem.label,
      toolTipText: this.state.profile[index].toolTipText
        ? this.state.profile[index].toolTipText
        : '',
      mandatoryMessage:
        this.state.profile[index] && this.state.profile[index].mandatoryMessage
          ? this.state.profile[index].mandatoryMessage
          : '',
      mandatoryToggle: mandatoryToggle,
      defaultDisplayOrder: this.state.profile[index].defaultDisplayOrder,
      fieldLabel: this.state.profile[index].fieldLabel
        ? this.state.profile[index].fieldLabel
        : '',
      dataType: elem.value ? elem.value : elem.dataType,
      type: elem.type
    };
    if (this.state.profile && this.state.profile[index].label == 'Gender') {
      newProfile[index].values = elem.values;
    }
    if (
      this.state.profile &&
      this.state.profile[index].label == 'State or Province'
    ) {
      newProfile[index].questionType = elem.questionType;
      newProfile[index].answers = elem.answers;
    }

    if (
      this.state.profile &&
      (this.state.profile[index].label === 'Mobile Number' ||
        this.state.profile[index].label === 'Postal Code' ||
        this.state.profile[index].label === 'Date of Birth' ||
        this.state.profile[index].label === 'Email Address')
    ) {
      newProfile[index].validationMessage =
        this.state.profile && this.state.profile[index].validationMessage;
    }

    document.getElementById(`error_label_${elem.dataType}`) &&
      document
        .getElementById(`error_label_${elem.dataType}`)
        .classList.add('cw-hidden');

    this.setState({
      profile: [...newProfile]
    });
  };

  onHeadingChange = (e, index, elem) => {
    let heading = e.target.value;
    let newProfile = [...this.state.profile];
    newProfile[index] = {
      label: this.state.profile[index].label
        ? this.state.profile[index].label
        : elem.label,
      heading: heading,
      dataType: 'heading',
      type: elem.type
    };
    document.getElementById(`heading_error_${elem.dataType}_${index}`) &&
      document
        .getElementById(`heading_error_${elem.dataType}_${index}`)
        .classList.add('cw-hidden');

    document
      .getElementById(`heading_${index}`)
      .classList.contains('cw-error-focus') &&
      document
        .getElementById(`heading_${index}`)
        .classList.remove('cw-error-focus');

    this.setState({
      profile: [...newProfile]
    });
  };

  onParagraphChange = (e, index) => {
    let paragraph = e.target.value;
    let newProfile = [...this.state.profile];
    newProfile[index] = {
      label: this.state.profile[index].label
        ? this.state.profile[index].label
        : '',
      paragraph: paragraph,
      dataType: 'paragraph'
    };

    this.setState({
      profile: [...newProfile]
    });
  };

  validateProfileItems = () => {
    let isvalid = true;

    this.state.profile.forEach((elem, index) => {
      if (
        elem.dataType !== 'heading' &&
        elem.dataType !== 'paragraph' &&
        //elem.dataType !== "gender" &&
        elem.type === 'field'
      ) {
        if (
          elem.fieldLabel !== undefined &&
          elem.fieldLabel.trim().length === 0
        ) {
          isvalid = false;
          scrollToTop();
          this.setState({ expand: index });
          document.getElementById(`labelError_${elem.dataType}`) &&
            document
              .getElementById(`labelError_${elem.dataType}`)
              .classList.remove('cw-hidden');

          document
            .getElementById(`fieldLabel_${elem.dataType}`)
            .classList.contains('cw-error-focus') ||
            document
              .getElementById(`fieldLabel_${elem.dataType}`)
              .classList.add('cw-error-focus');
        }

        if (
          elem.type === 'field' &&
          elem.mandatoryToggle &&
          elem.mandatoryMessage.trim().length === 0
        ) {
          isvalid = false;
          this.setState({ expand: index });
          scrollToTop();
          document.getElementById(`error_label_${elem.dataType}`) &&
            document
              .getElementById(`error_label_${elem.dataType}`)
              .classList.remove('cw-hidden');

          document
            .getElementById(`eorrrMessageText_${elem.dataType}`)
            .classList.contains('cw-error-focus') ||
            document
              .getElementById(`eorrrMessageText_${elem.dataType}`)
              .classList.add('cw-error-focus');
        }
      }
    });
    return isvalid;
  };

  validateField = () => {
    let isvalid = true;
    this.setState({
      showCommChannelValidationMessage: false
    });

    let profileOptions = [...this.state.profile];

    let mobileData = this.state.profile.find(
      elem => elem.dataType === 'mobileNumber'
    );
    let emailData = this.state.profile.find(elem => elem.dataType === 'email');
    let stateOrProvinceData = this.state.profile.find(
      elem => elem.dataType === 'stateOrProvince'
    );
    let headingData = this.state.profile.filter(
      elem => elem.dataType === 'heading'
    );
    let paragraphData = this.state.profile.filter(
      elem => elem.dataType === 'paragraph'
    );

    if (profileOptions && profileOptions.length === 0) {
      isvalid = false;
      scrollToTop();
      this.setState({ showCommChannelValidationMessage: true });
    }

    if (profileOptions && profileOptions.length > 0) {
      // scenario 3 - only mobile avaulable validation scenario
      if (!emailData && mobileData && !mobileData.mandatoryToggle) {
        isvalid = false;
        scrollToTop();
        this.setState({
          showCommChannelValidationMessage: true
        });
      }

      //scenario 1 - none mobile or email available
      if (!mobileData && !emailData) {
        isvalid = false;
        scrollToTop();
        this.setState({
          showCommChannelValidationMessage: true
        });
      }

      //scenario 2 - only email available & no mobile
      if (!mobileData && emailData && !emailData.mandatoryToggle) {
        isvalid = false;
        scrollToTop();
        this.setState({
          showCommChannelValidationMessage: true
        });
      }

      //scenario 4 mobile & email both available
      //Please mark either email or mobile as a mandatory field
      if (
        mobileData &&
        emailData &&
        !emailData.mandatoryToggle &&
        !mobileData.mandatoryToggle
      ) {
        isvalid = false;
        scrollToTop();
        this.setState({
          showCommChannelValidationMessage: true
        });
      }

      if (!this.validateProfileItems()) {
        isvalid = false;
      }

      let genderData = profileOptions.find(ele => ele.dataType === 'gender');
      if (genderData) {
        if (genderData.fieldLabel.trim().length === 0) {
          isvalid = false;
          document
            .getElementById(`fieldLabel_${genderData.dataType}`)
            .classList.contains('cw-error-focus') ||
            document
              .getElementById(`fieldLabel_${genderData.dataType}`)
              .classList.add('cw-error-focus');

          this.setState({
            isGenderFieldLabelErrorMessage: true
          });
        } else {
          document.getElementById(`fieldLabel_${genderData.dataType}`) &&
            document
              .getElementById(`fieldLabel_${genderData.dataType}`)
              .classList.contains('cw-error-focus') &&
            document
              .getElementById(`fieldLabel_${genderData.dataType}`)
              .classList.remove('cw-error-focus');

          this.setState({
            isGenderFieldLabelErrorMessage: false
          });
        }

        let seleLength = genderData.values.filter(ele => ele.isChecked).length;
        let hasTranslatedValue =
          genderData.values.filter(
            ele => ele.isChecked && ele.translatedValue.trim() === ''
          ).length > 0;
        if (hasTranslatedValue) {
          isvalid = false;
          this.setState({
            genderFieldErrorMessage: true
          });
        }
        if (seleLength < 2) {
          isvalid = false;
          this.setState({
            genderErrorMessage: true
          });
        } else {
          this.setState({
            genderErrorMessage: false
          });
        }
      }

      this.state.profile.forEach((elem, index) => {
        //checking type === field to segragate QnA validation of mandatoryToggle
        if (elem.type === 'field') {
          if (elem.mandatoryToggle) {
            if (elem.mandatoryMessage.trim().length === 0) {
              isvalid = false;
              this.setState({ expand: index });
              document.getElementById(
                `error_label_${elem.dataType}_${index}`
              ) &&
                document
                  .getElementById(`error_label_${elem.dataType}_${index}`)
                  .classList.remove('cw-hidden');
            } else {
              document.getElementById(
                `error_label_${elem.dataType}_${index}`
              ) &&
                document
                  .getElementById(`error_label_${elem.dataType}_${index}`)
                  .classList.add('cw-hidden');
            }
          }
          if (
            elem.dataType === 'dateOfBirth' &&
            (!elem.validationMessage ||
              elem.validationMessage.trim().length === 0)
          ) {
            isvalid = false;
            document.getElementById(`error_ageValidation_${elem.dataType}`) &&
              document
                .getElementById(`error_ageValidation_${elem.dataType}`)
                .classList.remove('cw-hidden');

            document
              .getElementById(`ageValidation_${elem.dataType}`)
              .classList.contains('cw-error-focus') ||
              document
                .getElementById(`ageValidation_${elem.dataType}`)
                .classList.add('cw-error-focus');
          }

          /*if (elem.dataType === "dateOfBirth") {
            let countrySelected =
              this.props.campaign &&
              this.props.campaign.country &&
              this.props.campaign.country.code;
            let minAge = epsilonMarket(countrySelected) ? 13 : 16;
            if (this.state.isLegalAge < minAge) {
              document.getElementById(`error_legalAgeValidation_${elem.dataType}`) &&
                document
                  .getElementById(`error_legalAgeValidation_${elem.dataType}`)
                  .classList.remove("cw-hidden");

              document
                .getElementById(`legalAgeValidation_${elem.dataType}`)
                .classList.contains("cw-error-focus") ||
                document
                  .getElementById(`legalAgeValidation_${elem.dataType}`)
                  .classList.add("cw-error-focus");
              isvalid = false;
            }
          }*/
        }
      });

      if (this.state.profile && this.state.profile.length > 0) {
        this.state.profile.forEach((elem, index) => {
          if (
            elem.dataType === 'email' ||
            elem.dataType === 'postalCode' ||
            elem.dataType === 'mobileNumber'
          ) {
            if (
              elem &&
              (!elem.validationMessage ||
                elem.validationMessage.trim().length == 0)
            ) {
              isvalid = false;
              this.setState({ expand: index });
              document.getElementById(
                `validation_error_label_${elem.dataType}_${index}`
              ) &&
                document
                  .getElementById(
                    `validation_error_label_${elem.dataType}_${index}`
                  )
                  .classList.remove('cw-hidden');

              document
                .getElementById(`formatValidation_${elem.dataType}`)
                .classList.contains('cw-error-focus') ||
                document
                  .getElementById(`formatValidation_${elem.dataType}`)
                  .classList.add('cw-error-focus');
            } else {
              document.getElementById(
                `validation_error_label_${elem.dataType}_${index}`
              ) &&
                document
                  .getElementById(
                    `validation_error_label_${elem.dataType}_${index}`
                  )
                  .classList.add('cw-hidden');

              document.getElementById(`formatValidation_${elem.dataType}`) &&
                document
                  .getElementById(`formatValidation_${elem.dataType}`)
                  .classList.contains('cw-error-focus') &&
                document
                  .getElementById(`formatValidation_${elem.dataType}`)
                  .classList.remove('cw-error-focus');
            }
          }
        });
      }

      if (headingData && headingData.length > 0) {
        this.state.profile.forEach((elem, index) => {
          if (elem.dataType === 'heading') {
            if (elem.heading.trim().length === 0) {
              isvalid = false;
              this.setState({ expand: index });
              document.getElementById(
                `heading_error_${elem.dataType}_${index}`
              ) &&
                document
                  .getElementById(`heading_error_${elem.dataType}_${index}`)
                  .classList.remove('cw-hidden');

              document
                .getElementById(`heading_${index}`)
                .classList.contains('cw-error-focus') ||
                document
                  .getElementById(`heading_${index}`)
                  .classList.add('cw-error-focus');
            } else {
              document.getElementById(
                `heading_error_${elem.dataType}_${index}`
              ) &&
                document
                  .getElementById(`heading_error_${elem.dataType}_${index}`)
                  .classList.add('cw-hidden');

              document.getElementById(`heading_${index}`) &&
                document
                  .getElementById(`heading_${index}`)
                  .classList.contains('cw-error-focus') &&
                document
                  .getElementById(`heading_${index}`)
                  .classList.remove('cw-error-focus');
            }
          }
        });
      }

      if (paragraphData && paragraphData.length > 0) {
        this.state.profile.forEach((elem, index) => {
          if (elem.dataType === 'paragraph') {
            if (
              elem.paragraph
                .replace(/\r?\n|\r/gm, '')
                .replace(/<[^>]+>/gm, '')
                .replace(/&nbsp;/gm, '')
                .trim().length === 0
            ) {
              isvalid = false;
              this.setState({ expand: index });
              document.getElementById(`paragraph_error_paragraph_${index}`) &&
                document
                  .getElementById(`paragraph_error_paragraph_${index}`)
                  .classList.remove('cw-hidden');

              document
                .getElementById(`paragraph_${index}`)
                .classList.contains('cw-error-focus') ||
                document
                  .getElementById(`paragraph_${index}`)
                  .classList.add('cw-error-focus');
            } else {
              document.getElementById(`paragraph_error_paragraph_${index}`) &&
                document
                  .getElementById(`paragraph_error_paragraph_${index}`)
                  .classList.add('cw-hidden');

              document.getElementById(`paragraph_${index}`) &&
                document
                  .getElementById(`paragraph_${index}`)
                  .classList.contains('cw-error-focus') &&
                document
                  .getElementById(`paragraph_${index}`)
                  .classList.remove('cw-error-focus');
            }
          }
        });
      }
    }
    if (
      stateOrProvinceData &&
      stateOrProvinceData.questionType === 'Single choice'
    ) {
      this.state.provinces.map((stateofProvience, ansIndex) => {
        if (stateofProvience.value.trim().length === 0) {
          this.setState({
            expand: this.state.profile.findIndex(
              element => element.dataType === 'stateOrProvince'
            )
          });
          document.getElementById(`province_Error_Message_${ansIndex}`) &&
            document
              .getElementById(`province_Error_Message_${ansIndex}`)
              .classList.remove('cw-hidden');

          document
            .getElementById(`answerTranslatedLabel_${ansIndex}`)
            .classList.contains('cw-error-focus') ||
            document
              .getElementById(`answerTranslatedLabel_${ansIndex}`)
              .classList.add('cw-error-focus');

          isvalid = false;
        }
      });
    }
    if (!isvalid) {
      this.setState({
        showAllPurposeError: true
      });
    } else {
      this.setState({
        showAllPurposeError: false
      });
    }
    return isvalid;
  };

  _handlebackButton = event => {
    event.preventDefault();
    this.props.history.push(
      '/campaign-wizard/campaign/' + this.campaignId + '/details'
    );
  };
  _handleCancel = () => {
    this.props.setApproverEditCampaignStatus(false);
    this.props.setApproverEditCampaignStatusMessage('');
    this.props.setApproverEditLastUpdatedScreen('');
    this.props.history.push(
      '/campaign-wizard/campaign/' + this.campaignId + '/approval'
    );
  };

  _handleSubmit = (event, saveAsDraft) => {
    let { campaign } = this.props;
    let isDirectType = campaign && campaign.campaignType === 'Direct messaging';
    let isQnA =
      campaign && campaign.type === 'Sign-up with Questions & Answers';
    let countrySelected =
      this.props.campaign &&
      this.props.campaign.country &&
      this.props.campaign.country.code;
    let campaignTypeIncentive =
      campaign && campaign.campaignType === 'Incentive';
    let isSimpleSignUp = campaign && campaign.type === 'Simple sign-up';
    let isDirectQnA = isDirectType && isQnA ? true : false;
    let profileFields = this.state.profile.map((elem, index) => {
      if (elem.dataType === 'stateOrProvince') {
        if (elem.questionType === 'Single choice') {
          return { ...elem, answers: this.state.provinces };
        } else return { ...elem, questionType: 'Free text' };
      }
      return elem;
    });

    let consentFields = [];
    if (
      campaign &&
      campaign.campaignDetail &&
      campaign.campaignDetail.consents &&
      campaign.campaignDetail.consents.length > 0
    ) {
      consentFields = campaign.campaignDetail.consents;
      consentFields.forEach(ele => {
        if (ele && ele.optInType === 'legalAgeConsent') {
          ele.ageOfConsent = this.state.isLegalAge;
        }
      });
    }
    let editCampaign = this.props.campaign.isEdited;
    let dataToSend = {
      uid: this.campaignId,
      fields: [...profileFields],
      isEdited: editCampaign,
      ageOfConsent: this.state.isLegalAge,
      consents: consentFields
    };

    if (saveAsDraft) {
      axios
        .post(constant.serviceUrls.CAMPAIGN_DATA_SERVICE_URL, dataToSend)
        .then(response => {
          if (response) {
            let notificationData = {};
            notificationData.message = constant.CAMPAIGN_SAVED_AS_DRAFT_TEXT;
            notificationData.link = '';
            notificationData.type = 'warning';
            notificationData.campaign = '';

            this.props.setNotificationMessage(
              notificationData.message,
              notificationData.link,
              notificationData.type,
              notificationData.campaign
            );
            this.props.history.push('/campaign-wizard/dashboard');
          }
        });
    }

    if (!saveAsDraft && this.validateField()) {
      axios
        .post(constant.serviceUrls.CAMPAIGN_DATA_SERVICE_URL, dataToSend)
        .then(response => {
          if (response) {
            if (this.status === '6') {
              this.props.setApproverEditCampaignStatus(true);
              this.props.setApproverEditCampaignStatusMessage('success');
              this.props.setApproverEditLastUpdatedScreen(
                'saas-profile-details'
              );
              this.props.history.push(
                '/campaign-wizard/campaign/' +
                  this.state.campaignId +
                  '/saas-sorting-page'
              );
            } else {
              if (isDirectQnA || (campaignTypeIncentive && isQnA)) {
                this.props.history.push(
                  '/campaign-wizard/campaign/' +
                    this.state.campaignId +
                    `${
                      epsilonMarket(countrySelected)
                        ? '/saas-epsilon-qna'
                        : '/saasqna'
                    }`
                );
              } else if (campaignTypeIncentive && isSimpleSignUp) {
                this.props.history.push(
                  '/campaign-wizard/campaign/' +
                    this.state.campaignId +
                    '/saas-incentive-fields'
                );
              } else {
                this.props.history.push(
                  '/campaign-wizard/campaign/' +
                    this.state.campaignId +
                    '/saas-consent-compliance'
                );
              }
            }
          }
        });
    }
  };
  handleAccordionExpandCollapse = index => {
    this.setState(prevState => ({
      expand: prevState.expand === index ? false : index
    }));
  };

  handleEmailAccordion = () => {
    this.setState(prevState => ({
      expandEmail: !prevState.expandEmail
    }));
  };

  static getDerivedStateFromProps(props, state) {
    if (props.dataSaved) {
      let notificationData = {};
      notificationData.message = constant.CAMPAIGN_SAVED_AS_DRAFT_TEXT;
      notificationData.link = '';
      notificationData.type = 'warning';
      notificationData.campaign = '';

      props.setNotificationMessage(
        notificationData.message,
        notificationData.link,
        notificationData.type,
        notificationData.campaign
      );
      props.history.push('/campaign-wizard/dashboard');
    }
    if (props.pushFields) {
      props.history.push(
        '/campaign-wizard/campaign/' +
          state.campaignId +
          '/saas-consent-compliance'
      );
    }
    return null;
  }

  richTextData(richTextData, index, elem) {
    let paragraph = richTextData.getContent();
    let newProfile = [...this.state.profile];
    newProfile[index] = {
      label: this.state.profile[index].label
        ? this.state.profile[index].label
        : elem.label,
      paragraph: paragraph ? paragraph : '',
      dataType: 'paragraph',
      type: elem.type
    };
    if (
      paragraph
        .replace(/\r?\n|\r/gm, '')
        .replace(/<[^>]+>/gm, '')
        .replace(/&nbsp;/gm, '')
        .trim().length === 0
    ) {
      document.getElementById(`paragraph_error_paragraph_${index}`) &&
        document
          .getElementById(`paragraph_error_paragraph_${index}`)
          .classList.remove('cw-hidden');

      document
        .getElementById(`paragraph_${index}`)
        .classList.contains('cw-error-focus') ||
        document
          .getElementById(`paragraph_${index}`)
          .classList.add('cw-error-focus');
    } else {
      document.getElementById(`paragraph_error_paragraph_${index}`) &&
        document
          .getElementById(`paragraph_error_paragraph_${index}`)
          .classList.add('cw-hidden');

      document
        .getElementById(`paragraph_${index}`)
        .classList.contains('cw-error-focus') &&
        document
          .getElementById(`paragraph_${index}`)
          .classList.remove('cw-error-focus');
    }
    this.setState({
      ...this.state,
      profile: [...newProfile]
    });
  }

  genderOnchange = async (e, index, genderIndex) => {
    let newProfile = [...this.state.profile];
    newProfile[index].values[genderIndex].isChecked = e.target.checked;
    this.setState({
      ...this.state,
      profile: newProfile
    });

    let profileOptions = [...this.state.profile];
    let genderData = profileOptions.find(ele => ele.dataType === 'gender');
    if (genderData) {
      let seleLength = genderData.values.filter(ele => ele.isChecked).length;
      let seleEmpltyField = genderData.values.filter(
        ele => ele.isChecked && ele.translatedValue.trim() === ''
      );
      setTimeout(() => {
        genderData.values.forEach(el => {
          if (el.isChecked && el.translatedValue.trim() === '') {
            document
              .getElementById(`gender_${el.value}`)
              .classList.contains('cw-error-focus') ||
              document
                .getElementById(`gender_${el.value}`)
                .classList.add('cw-error-focus');
          } else {
            document.getElementById(`gender_${el.value}`) &&
              document
                .getElementById(`gender_${el.value}`)
                .classList.contains('cw-error-focus') &&
              document
                .getElementById(`gender_${el.value}`)
                .classList.remove('cw-error-focus');
          }
        });
      }, 100);

      await this.setState({
        genderFieldErrorMessage: seleEmpltyField.length > 0 ? true : false
      });
      if (seleLength < 2) {
        this.setState({
          genderErrorMessage: true
        });
      } else {
        this.setState({
          genderErrorMessage: false
        });
      }
    }
  };

  genderInputHandler = async (e, index, genderIndex) => {
    let newProfile = [...this.state.profile];
    newProfile[index].values[genderIndex].translatedValue = e.target.value;
    let profileOptions = [...this.state.profile];
    let genderData = profileOptions.find(ele => ele.dataType === 'gender');
    if (genderData) {
      let seleLength = genderData.values.filter(
        ele => ele.isChecked && ele.translatedValue.trim() === ''
      );
      genderData.values.forEach(el => {
        if (el.isChecked && el.translatedValue.trim() === '') {
          document
            .getElementById(`gender_${el.value}`)
            .classList.contains('cw-error-focus') ||
            document
              .getElementById(`gender_${el.value}`)
              .classList.add('cw-error-focus');
        } else {
          document
            .getElementById(`gender_${el.value}`)
            .classList.contains('cw-error-focus') &&
            document
              .getElementById(`gender_${el.value}`)
              .classList.remove('cw-error-focus');
        }
      });
      await this.setState({
        genderFieldErrorMessage: seleLength.length > 0 ? true : false
      });
    }
    this.setState({
      ...this.state,
      profile: newProfile
    });
  };

  isElementPresent = inputData => {
    return (
      this.state.profile.filter(elem => elem.dataType === inputData).length ===
      1
    );
  };

  renderClose() {
    if (
      this.isElementPresent('email') &&
      this.isElementPresent('mobileNumber')
    ) {
      if (document.getElementById(`close_mobileNumber`)) {
        document
          .getElementById(`close_mobileNumber`)
          .classList.remove('cw-hidden');
      }
      if (
        document.getElementById(`close_email`) &&
        document.getElementById(`close_email`).classList.contains('cw-hidden')
      ) {
        document.getElementById(`close_email`).classList.remove('cw-hidden');
      }
      if (document.getElementById(`mandatoryToggle_email`)) {
        document.getElementById(`mandatoryToggle_email`).disabled = false;
      }
      if (document.getElementById(`mandatoryToggle_mobileNumber`)) {
        document.getElementById(
          `mandatoryToggle_mobileNumber`
        ).disabled = false;
      }
    }
    if (
      this.isElementPresent('email') &&
      !this.isElementPresent('mobileNumber')
    ) {
      if (
        document.getElementById(`close_email`) &&
        !document.getElementById(`close_email`).classList.contains('cw-hidden')
      ) {
        document.getElementById(`close_email`).classList.add('cw-hidden');
      }
      if (document.getElementById(`close_mobileNumber`)) {
        document
          .getElementById(`close_mobileNumber`)
          .classList.add('cw-hidden');
      }
      if (document.getElementById(`mandatoryToggle_email`)) {
        if (document.getElementById(`mandatoryToggle_email`).checked) {
          document.getElementById(`mandatoryToggle_email`).disabled = true;
        }
      }
      if (document.getElementById(`mandatoryToggle_mobileNumber`)) {
        document.getElementById(`mandatoryToggle_mobileNumber`).disabled = true;
      }
    }
    if (
      !this.isElementPresent('email') &&
      this.isElementPresent('mobileNumber')
    ) {
      if (
        document.getElementById(`close_email`) &&
        !document.getElementById(`close_email`).classList.contains('cw-hidden')
      ) {
        document.getElementById(`close_email`).classList.add('cw-hidden');
      }
      if (
        document.getElementById(`close_mobileNumber`) &&
        !document
          .getElementById(`close_mobileNumber`)
          .classList.contains('cw-hidden')
      ) {
        document
          .getElementById(`close_mobileNumber`)
          .classList.add('cw-hidden');
      }
      if (document.getElementById(`mandatoryToggle_email`)) {
        if (document.getElementById(`mandatoryToggle_email`).checked) {
          document.getElementById(`mandatoryToggle_email`).disabled = true;
        }
      }
      if (document.getElementById(`mandatoryToggle_mobileNumber`)) {
        if (document.getElementById(`mandatoryToggle_mobileNumber`).checked) {
          document.getElementById(
            `mandatoryToggle_mobileNumber`
          ).disabled = true;
        }
      }
    }
  }

  render() {
    let editCampaign = this.props.campaign.isEdited;
    this.renderClose();
    let { campaignType, campaign } = this.props;
    let countrySelected = campaign && campaign.country && campaign.country.code;
    let stateTypeOptions = [
      {
        value: 'Free text',
        label: 'Free Text'
      },
      {
        value: 'Single choice',
        label: 'Single Choice'
      }
    ];
    return (
      <>
        {this.props.isLoading ? <Loader /> : null}
        <div className="col-sm-12">
          <ReactTooltip
            place="right"
            type="info"
            multiline={true}
            className="cw-tooltip"
          />
          <div className="cw-section ml-20">
            {this.status !== '6' ? (
              <BreadCrumb
                match={this.state.matchProps}
                // editCampaign={
                //   this.props.editCampaign || this.props.isCampaignEdited
                // }
                hideBreadCrumb={this.props.approverEditCampaign || editCampaign}
                campaignType={
                  this.props.campaign && this.props.campaign.campaignType
                }
                websiteType={
                  this.props.campaign &&
                  this.props.campaign.websiteType &&
                  this.props.campaign.websiteType.name
                }
                formType={this.props.campaign && this.props.campaign.type}
              />
            ) : null}

            <div
              className={`alert alert-danger ${
                this.state.showMessageAtTop ? ' ' : 'cw-hidden'
              } `}
              role="alert"
            >
              <strong>
                There was a problem with the page. Errors are listed below{' '}
              </strong>
            </div>

            <div className="cw-section--title mb-20">
              <h2 className="cw-heading--secondary mb-20">
                Choose profile fields
              </h2>

              <div className="row">
                <div className="col-sm-7">
                  <Alert
                    alertType={'saasWarningAlertMessage'}
                    textMessage={
                      constant.SAAS_MESSAGES.duplicateOptionsWarningInfo
                    }
                    showComponent={this.state.showErrorMessage}
                  />
                  <Alert
                    alertType={'saasWarningAlertMessage'}
                    textMessage={
                      constant.SAAS_MESSAGES.mandateCommChannelValidationMessage
                    }
                    showComponent={this.state.showCommChannelValidationMessage}
                  />
                </div>

                {!editCampaign ? (
                  <div className="col-sm-7">
                    <div className="form-group">
                      {
                        <Alert
                          alertType="saasProfileFieldsMessage"
                          textMessage={constant.SAAS_MESSAGES.personaliseFields}
                          isKnowMore={true}
                          modelText={'field properties.'}
                          optionalText={
                            constant.SAAS_MESSAGES.personaliseFieldsOptional
                          }
                          openModal={this._openNewModal}
                        />
                      }
                    </div>
                  </div>
                ) : (
                  <div className="col-sm-7">
                    <div className="form-group">
                      <Alert
                        alertType="warningMessageWithIcon"
                        textMessage={constant.SAAS_MESSAGES.editFieldsInfoMsg}
                        showComponent="true"
                      />
                    </div>
                  </div>
                )}
                <div className="col-sm-7">
                  <div className="cw-section--content">
                    {/* <div className="cw-text--quinary"> */}

                    <Modal
                      isOpen={this.state.isNewModalOpen}
                      onRequestClose={this._closeNewModal}
                      className="cw-modal cw-modal--branddetail"
                      contentLabel="field properties"
                      style={{
                        overlay: {
                          backgroundColor: constant.EMAIL_OVERLAY_BACKGROUND
                        }
                      }}
                    >
                      <Alert
                        alertType="profilePageOverlay"
                        closeModal={this._closeNewModal}
                      />
                    </Modal>
                    <Modal
                      scrollable={true}
                      isOpen={this.state.isCancelModalOpen}
                      onRequestClose={this._closeIsCancelModal}
                      className="cw-modal cw-modal--branddetail"
                      contentLabel="Campaign Wizard 2.0"
                      style={{
                        overlay: {
                          backgroundColor: constant.EMAIL_OVERLAY_BACKGROUND
                        }
                      }}
                    >
                      <CampaignCancelModel
                        closeIsCancelModal={this._closeIsCancelModal}
                        isCancelContinue={this._cancelSaasCampaign}
                        data={[]}
                      />
                    </Modal>
                    <p className="mb-20">
                      Language Selected:{' '}
                      <span className="cw-color--primary">
                        {this.props.campaign.language
                          ? this.props.campaign.language.name
                          : ''}
                      </span>
                    </p>

                    {/* Dynamic Section */}
                    {this.state.profile.map((elem, index) => {
                      if (elem.type === 'field') {
                        return (
                          <>
                            <div
                              id={`accordion_${elem.dataType}`}
                              className="accordion saas-form-field-accordion mb-20"
                              key={`${elem.dataType}_${index}`}
                            >
                              <ReactTooltip
                                place="right"
                                type="info"
                                multiline={true}
                                className="cw-tooltip"
                              />
                              <div
                                className="card"
                                style={{ border: 0, overflow: 'visible' }}
                              >
                                <div className="card-header accordian-card-header">
                                  <span
                                    className="saas-accordion-heading cw-color--primary"
                                    style={{ fontWeight: '700' }}
                                  >
                                    {elem.label}
                                  </span>
                                  <span
                                    type="button"
                                    className={`saas-accordion-arrow ${index}`}
                                    onClick={() =>
                                      this.handleAccordionExpandCollapse(index)
                                    }
                                  >
                                    <i
                                      className={
                                        this.state.expand === index
                                          ? 'fas fa-chevron-down transformIcon-180'
                                          : 'fas fa-chevron-down'
                                      }
                                    ></i>
                                  </span>

                                  {epsilonMarket(countrySelected) &&
                                  elem.dataType == 'email' ? null : (
                                    <span
                                      id={`close_${elem.dataType}`}
                                      className={'saas-accordion-close'}
                                      onClick={() => {
                                        this.removeAccordion(index);
                                      }}
                                    ></span>
                                  )}
                                </div>
                                <div
                                  className={
                                    this.state.expand === index
                                      ? 'collapse show'
                                      : 'collapse'
                                  }
                                >
                                  <ReactTooltip
                                    place="right"
                                    type="info"
                                    multiline={true}
                                    className="cw-tooltip"
                                  />
                                  {elem.label !== 'Heading' &&
                                  elem.label !== 'Paragraph' ? (
                                    <>
                                      <div
                                        className="form-group form-group-field-width"
                                        key={`${elem.dataType}_${index}`}
                                      >
                                        <label
                                          htmlFor={`fieldLabel_${elem.dataType}`}
                                        >
                                          <span>Field Label</span>
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name={`fieldLabel_${elem.dataType}`}
                                          id={`fieldLabel_${elem.dataType}`}
                                          maxLength="50"
                                          ref={`fieldLabel_${elem.dataType}`}
                                          aria-describedby={`fieldLabel_${elem.dataType}`}
                                          data-required="true"
                                          defaultValue={
                                            this.state.profile[index].fieldLabel
                                              ? this.state.profile[index]
                                                  .fieldLabel
                                              : elem.label === 'Gender'
                                              ? this.state.genderData
                                                  .translatedLabel
                                              : this.state.profile[index]
                                                  .translatedLabel
                                              ? this.state.profile[index]
                                                  .translatedLabel
                                              : ''
                                          }
                                          onChange={e =>
                                            this.onLabelChange(e, index, elem)
                                          }
                                        />

                                        {elem.dataType !== 'gender' ? (
                                          <span
                                            id={`labelError_${elem.dataType}`}
                                            className="cw-error cw-hidden"
                                            data-control="name"
                                          >
                                            <i className="fas fa-exclamation-triangle mr-10"></i>
                                            Please enter the field label
                                          </span>
                                        ) : null}

                                        {(elem.dataType === 'gender' ||
                                          elem.label === 'Gender') &&
                                        this.state
                                          .isGenderFieldLabelErrorMessage ? (
                                          <span
                                            className="cw-error"
                                            data-control="name"
                                          >
                                            <i className="fas fa-exclamation-triangle mr-10"></i>
                                            Please enter gender label
                                          </span>
                                        ) : null}
                                      </div>
                                      {elem.dataType === 'stateOrProvince' ? (
                                        <div className="form-group form-group-field-width ">
                                          <label htmlFor="state_type">
                                            <span>Field Type</span>
                                            <span className="float-right  cw-icon cw-icon--help">
                                              <i
                                                className="fa fa-info-circle"
                                                onClick={this._openModal}
                                              ></i>
                                            </span>
                                          </label>
                                          <Modal
                                            isOpen={this.state.modalIsOpen}
                                            onRequestClose={this._closeModal}
                                            className="cw-modal cw-modal--questions"
                                            contentLabel="Field Types"
                                            style={{
                                              overlay: {
                                                backgroundColor:
                                                  constant.EMAIL_OVERLAY_BACKGROUND
                                              }
                                            }}
                                          >
                                            <button
                                              className="cw-modal--close"
                                              title="Close"
                                              onClick={this._closeModal}
                                            >
                                              <i className="fas fa-times"></i>
                                            </button>
                                            <h3 className="cw-heading--primary mb-10">
                                              Field Types
                                            </h3>
                                            <p className="mb-20">
                                              Choose from 2 different field
                                              types listed below
                                            </p>
                                            <div className="col-sm-12">
                                              <div className="row cw-bgpattern-primary">
                                                <div className="col-sm-4">
                                                  <strong>Text field</strong>
                                                </div>
                                                <div className="col-sm-8">
                                                  <span>
                                                    Input text fields that
                                                    require consumers to type in
                                                    their responses
                                                  </span>
                                                </div>
                                              </div>
                                              <div className="row cw-bgpattern-primary">
                                                <div className="col-sm-4">
                                                  <strong>Single choice</strong>
                                                </div>
                                                <div className="col-sm-8">
                                                  <span>
                                                    A field with pre-defined
                                                    values presented to
                                                    consumers for selection in
                                                    the form of a dropdown or a
                                                    radio button. Consumers can
                                                    choose only one value
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </Modal>
                                          <Select
                                            className={
                                              'mb-10 cw-select--custom cw-color--primary '
                                            }
                                            name="stateType"
                                            id="stateType"
                                            ref="stateType"
                                            placeholder={'Select option'}
                                            aria-describedby="stateType"
                                            options={stateTypeOptions}
                                            onChange={e =>
                                              this._handleQuestionType(
                                                e,
                                                index,
                                                elem
                                              )
                                            }
                                            value={stateTypeOptions.filter(
                                              option =>
                                                option.value ===
                                                (elem.questionType &&
                                                elem.questionType !== undefined
                                                  ? elem.questionType
                                                  : 'Free text')
                                            )}
                                          />
                                          {elem.questionType ===
                                          'Single choice' ? (
                                            <>
                                              <label htmlFor="state_values">
                                                <span>Define values</span>
                                              </label>
                                              {this.state.provinces &&
                                                this.state.provinces.map(
                                                  (provinceObj, indexValue) => {
                                                    return (
                                                      <>
                                                        <div key={indexValue}>
                                                          <div className="form-group ">
                                                            {indexValue >= 1 ? (
                                                              <span
                                                                style={{
                                                                  right: '-4%',
                                                                  top: '10%'
                                                                }}
                                                                className="saas-accordion-close"
                                                                data-index={
                                                                  indexValue
                                                                }
                                                                onClick={e =>
                                                                  this._deleteProvince(
                                                                    indexValue
                                                                  )
                                                                }
                                                              ></span>
                                                            ) : null}
                                                            <input
                                                              type="text"
                                                              placeholder="Enter a value"
                                                              className="form-control mb-20"
                                                              name={`answerTranslatedLabel_${indexValue}`}
                                                              id={`answerTranslatedLabel_${indexValue}`}
                                                              aria-describedby={`answerTranslatedLabel_${indexValue}`}
                                                              data-required="true"
                                                              maxLength={
                                                                epsilonMarket(
                                                                  countrySelected
                                                                )
                                                                  ? '50'
                                                                  : '75'
                                                              }
                                                              value={
                                                                provinceObj &&
                                                                provinceObj.value
                                                                  ? provinceObj.value
                                                                  : ''
                                                              }
                                                              onChange={e =>
                                                                this.handleProvinceLabel(
                                                                  e,
                                                                  indexValue
                                                                )
                                                              }
                                                            />
                                                            <span
                                                              className="cw-error cw-hidden"
                                                              id={`province_Error_Message_${indexValue}`}
                                                            >
                                                              <i className="fas fa-exclamation-triangle mr-10"></i>
                                                              Please enter a
                                                              value or remove
                                                              the blank fields
                                                            </span>
                                                          </div>
                                                        </div>
                                                      </>
                                                    );
                                                  }
                                                )}
                                              <button
                                                onClick={e =>
                                                  this._addProvince(e)
                                                }
                                                className="btn btn-primary mb-10"
                                              >
                                                Add values
                                              </button>
                                            </>
                                          ) : null}
                                        </div>
                                      ) : null}

                                      {elem.dataType === 'gender' ||
                                      elem.label === 'Gender' ? (
                                        <>
                                          {elem.dataType === 'gender' ||
                                          elem.label === 'Gender' ? (
                                            <div className="form-group-field-width">
                                              Configure the gender options you
                                              want to show on your form
                                            </div>
                                          ) : null}

                                          {elem.values.map(
                                            (el, genderIndex) => {
                                              return (
                                                <div className="form-group">
                                                  <div className="row mb-1">
                                                    <div className="col-4">
                                                      <div className="form-check">
                                                        <input
                                                          className="form-check-input"
                                                          type="checkbox"
                                                          defaultChecked={
                                                            el.isChecked
                                                          }
                                                          onChange={e =>
                                                            this.genderOnchange(
                                                              e,
                                                              index,
                                                              genderIndex
                                                            )
                                                          }
                                                          name={el.value}
                                                          id={el.value}
                                                        />
                                                        <label
                                                          className="form-check-label"
                                                          htmlFor={el.value}
                                                        >
                                                          {el.value}
                                                        </label>
                                                      </div>
                                                    </div>
                                                    <div className="col-6">
                                                      {el.isChecked === true ? (
                                                        <input
                                                          type="text"
                                                          id={`gender_${el.value}`}
                                                          name={el.value}
                                                          defaultValue={
                                                            el.translatedValue
                                                          }
                                                          onChange={e =>
                                                            this.genderInputHandler(
                                                              e,
                                                              index,
                                                              genderIndex
                                                            )
                                                          }
                                                          className="form-control"
                                                        />
                                                      ) : null}
                                                    </div>
                                                  </div>
                                                </div>
                                              );
                                            }
                                          )}
                                          {this.state.genderErrorMessage ? (
                                            <span
                                              className="cw-error"
                                              data-control="name"
                                            >
                                              <i className="fas fa-exclamation-triangle mr-10"></i>
                                              Select at-least two options
                                            </span>
                                          ) : null}

                                          {this.state
                                            .genderFieldErrorMessage ? (
                                            <span
                                              className="cw-error"
                                              data-control="name"
                                            >
                                              <i className="fas fa-exclamation-triangle mr-10"></i>
                                              Please enter the label for the
                                              selected gender options
                                            </span>
                                          ) : null}
                                        </>
                                      ) : null}

                                      {elem.label === 'Gender' ? '' : null}
                                      <div className="form-group form-group-field-width">
                                        <label htmlFor={`toolTipText_${index}`}>
                                          <span>
                                            Tooltip/Help-Text (optional)
                                          </span>
                                          <span
                                            className="float-right  cw-icon cw-icon--help"
                                            data-tip="Please specify the tooltip message you would want users to see in case they need some additional information while filling up the form. Make sure to have this message in the language you selected for the form."
                                            currentitem="false"
                                          >
                                            <i className="fas fa-question-circle"></i>
                                          </span>
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name={`toolTipText_${index}`}
                                          id={`toolTipText_${index}`}
                                          ref={`toolTipText_${index}`}
                                          aria-describedby={`toolTipText_${index}`}
                                          data-required="true"
                                          value={
                                            this.state.profile[index]
                                              .toolTipText
                                          }
                                          onChange={e =>
                                            this.onToolTipChange(e, index, elem)
                                          }
                                        />
                                      </div>
                                      <div className="form-group form-group-field-width">
                                        <div className="form-group d-flex ">
                                          <label className="switch-saas">
                                            <input
                                              //   disabled={
                                              //     (elem.dataType === "email" && this.isElementPresent("email") && !this.isElementPresent("mobileNumber"))
                                              // || (elem.dataType === "mobileNumber" && !this.isElementPresent("email") && this.isElementPresent("mobileNumber"))
                                              //  }
                                              type="checkbox"
                                              name={`mandateField_${index}`}
                                              id={`mandatoryToggle_${elem.dataType}`}
                                              checked={
                                                this.state.profile[index]
                                                  .mandatoryToggle
                                              }
                                              disabled={
                                                epsilonMarket(
                                                  countrySelected
                                                ) && elem.dataType === 'email'
                                                  ? true
                                                  : false
                                              }
                                              onChange={() => {
                                                this.setState(
                                                  prevState => ({
                                                    nonEmailfieldRequired: !this
                                                      .state.profile[index]
                                                      .mandatoryToggle
                                                  }),
                                                  e => {
                                                    this.onMandatoryToggleChange(
                                                      e,
                                                      this.state
                                                        .nonEmailfieldRequired,
                                                      index,
                                                      elem
                                                    );
                                                  }
                                                );
                                              }}
                                            />
                                            <div className="slider-saas round"></div>
                                          </label>
                                          <label
                                            className="ml-20"
                                            htmlFor={`mandateField_${index}`}
                                          >
                                            Make this field mandatory
                                          </label>
                                        </div>
                                      </div>
                                      <div
                                        className={
                                          this.state.profile[index]
                                            .mandatoryToggle
                                            ? 'form-group form-group-field-width'
                                            : 'cw-hidden'
                                        }
                                      >
                                        <label
                                          htmlFor={`errorMessageText_${elem.dataType}`}
                                        >
                                          <span>
                                            Mandatory Field Error Message
                                          </span>
                                          <span
                                            className="float-right  cw-icon cw-icon--help"
                                            data-tip="Please specify the error message you would want the users to see in case they forget to fill this field. Make sure to have this message in the language you selected for the form."
                                            currentitem="false"
                                          >
                                            <i className="fas fa-question-circle"></i>
                                          </span>
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name={`errorMessageText_${elem.dataType}`}
                                          id={`eorrrMessageText_${elem.dataType}`}
                                          ref={`errorMessageText_${elem.dataType}`}
                                          aria-describedby={`errorMessageText_${elem.dataType}`}
                                          data-required="true"
                                          defaultValue={
                                            this.state.profile[index]
                                              .mandatoryMessage
                                          }
                                          onChange={e =>
                                            this.onErrorMessageChange(
                                              e,
                                              index,
                                              elem
                                            )
                                          }
                                        />
                                        <span
                                          id={`error_label_${elem.dataType}_${index}`}
                                          className="cw-error cw-hidden"
                                          data-control="name"
                                        >
                                          <i className="fas fa-exclamation-triangle mr-10"></i>
                                          Please enter the error message
                                        </span>
                                      </div>
                                    </>
                                  ) : null}
                                  {elem.label === 'Date of Birth' ? (
                                    <>
                                      <div className="form-group form-group-field-width">
                                        <label
                                          htmlFor={`legalAgeValidation_${elem.dataType}`}
                                        >
                                          <span>
                                            Minimum legal age of consent{' '}
                                          </span>
                                          <span
                                            className="float-right  cw-icon cw-icon--help"
                                            data-tip="We will use this to ensure anyone who selects a lower age cannot sign-up"
                                          >
                                            <i className="fa fa-question-circle"></i>
                                          </span>
                                        </label>
                                        <div
                                          id={`legalAgeValidation_${elem.dataType}`}
                                        >
                                          <Select
                                            className={
                                              'cw-select--custom cw-color--primary '
                                            }
                                            name={`legalAgeValidation_${elem.dataType}`}
                                            label={'Label'}
                                            placeholder={this.state.isLegalAge}
                                            options={constant.LEGAL_AGE_VALUES.map(
                                              ele => {
                                                return {
                                                  label: ele,
                                                  value: ele
                                                };
                                              }
                                            )}
                                            onChange={e =>
                                              this._handleInputChange(
                                                e,
                                                elem,
                                                index
                                              )
                                            }
                                            value={this.state.isLegalAge}
                                          />
                                        </div>
                                        <span
                                          id={`error_legalAgeValidation_${elem.dataType}`}
                                          className="cw-error cw-hidden"
                                          data-control="name"
                                        >
                                          <i className="fas fa-exclamation-triangle mr-10"></i>
                                          You need to be atleast{' '}
                                          {epsilonMarket(countrySelected)
                                            ? 13
                                            : 16}{' '}
                                          years old to participate
                                        </span>
                                      </div>
                                      <div className="form-group form-group-field-width">
                                        <label
                                          htmlFor={`formatValidation_${elem.dataType}`}
                                        >
                                          <span>
                                            Legal Age Validation Message
                                          </span>
                                          <span
                                            className="float-right  cw-icon cw-icon--help"
                                            data-tip="This the error that consumers will see if they try to select a date of birth that makes their age lower than the legal age consent."
                                            currentitem="false"
                                          >
                                            <i className="fas fa-question-circle"></i>
                                          </span>
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name={`ageValidation_${elem.dataType}`}
                                          id={`ageValidation_${elem.dataType}`}
                                          maxLength="50"
                                          ref={`ageformatValidation_${elem.dataType}`}
                                          aria-describedby={`ageValidation_${elem.dataType}`}
                                          data-required="true"
                                          value={
                                            this.state.profile[index]
                                              .validationMessage
                                          }
                                          onChange={e =>
                                            this.onLegalAgeValidationChange(
                                              e,
                                              index,
                                              elem
                                            )
                                          }
                                        />
                                        <span
                                          id={`error_ageValidation_${elem.dataType}`}
                                          className="cw-error cw-hidden"
                                          data-control="name"
                                        >
                                          <i className="fas fa-exclamation-triangle mr-10"></i>
                                          Please enter legal age validation
                                          message
                                        </span>
                                        {/* <Alert
                                        alertType={"saasInfoMessage"}
                                        textMessage={
                                          constant.SAAS_MESSAGES
                                            .legalAgeValidationInfoMessage
                                        }
                                        showComponent={true}
                                      /> */}
                                      </div>
                                    </>
                                  ) : null}
                                  {elem.label === 'Mobile Number' ||
                                  elem.label === 'Postal Code' ||
                                  elem.label === 'Email Address' ? (
                                    <div
                                      className="form-group form-group-field-width"
                                      key={`${elem.dataType}_${index}`}
                                    >
                                      <label
                                        htmlFor={`formatValidation_${elem.dataType}`}
                                      >
                                        <span>
                                          Format Validation Error Message
                                        </span>
                                        <span
                                          className="float-right  cw-icon cw-icon--help"
                                          data-tip="Please specify the error message you would want the users to see in case they have filled in a wrong format. Make sure to have this message in the language you selected for the form."
                                          currentitem="false"
                                        >
                                          <i className="fas fa-question-circle"></i>
                                        </span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name={`formatValidation_${elem.dataType}`}
                                        id={`formatValidation_${elem.dataType}`}
                                        maxLength="50"
                                        ref={`formatValidation_${elem.dataType}`}
                                        aria-describedby={`formatValidation_${elem.dataType}`}
                                        data-required="true"
                                        defaultValue={
                                          this.state.profile[index]
                                            .validationMessage
                                        }
                                        onChange={e =>
                                          this.onMobileRegexChange(
                                            e,
                                            index,
                                            elem
                                          )
                                        }
                                      />
                                      <span
                                        id={`validation_error_label_${elem.dataType}_${index}`}
                                        className="cw-error cw-hidden"
                                        data-control="name"
                                      >
                                        <i className="fas fa-exclamation-triangle mr-10"></i>
                                        Please enter the format validation error
                                        message
                                      </span>
                                    </div>
                                  ) : null}
                                  {elem.value === 'heading' ||
                                  elem.label === 'Heading' ? (
                                    <>
                                      <div
                                        className="form-group form-group-field-width"
                                        key={`${elem.dataType}_${index}`}
                                      >
                                        <label htmlFor={`heading_${index}`}>
                                          <span>Heading Text</span>
                                          <span
                                            className="float-right  cw-icon cw-icon--help"
                                            data-tip="Use this field to specify any custom headings to separate sections or fields of your form"
                                            currentitem="false"
                                          >
                                            <i className="fas fa-question-circle"></i>
                                          </span>
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name={`heading_${index}`}
                                          id={`heading_${index}`}
                                          maxLength="50"
                                          ref={`heading_${index}`}
                                          aria-describedby={`heading_${index}`}
                                          data-required="true"
                                          value={
                                            this.state.profile[index].heading ||
                                            ''
                                          }
                                          onChange={e =>
                                            this.onHeadingChange(e, index, elem)
                                          }
                                        />
                                        <span
                                          id={`heading_error_${elem.dataType}_${index}`}
                                          className="cw-error cw-hidden"
                                          data-control="name"
                                        >
                                          <i className="fas fa-exclamation-triangle mr-10"></i>
                                          Please enter the heading statement
                                        </span>
                                      </div>
                                    </>
                                  ) : null}
                                  {elem.value === 'paragraph' ||
                                  elem.label === 'Paragraph' ? (
                                    <>
                                      <div
                                        className="form-group form-group-field-width"
                                        key={`${elem.dataType}_${index}`}
                                      >
                                        <label htmlFor={`paragraph_${index}`}>
                                          <span>Paragraph Text</span>
                                          <span
                                            className="float-right  cw-icon cw-icon--help"
                                            data-tip="Use this field to specify custom paragraph text to appear between the fields on the form"
                                            currentitem="false"
                                          >
                                            <i className="fas fa-question-circle"></i>
                                          </span>
                                        </label>
                                        <div id={`paragraph_${index}`}>
                                          <RichTextEditor
                                            defaultValue={
                                              this.state.profileRichText &&
                                              this.state.profileRichText[
                                                index
                                              ] &&
                                              this.state.profileRichText[index]
                                                .paragraph
                                                ? this.state.profileRichText[
                                                    index
                                                  ].paragraph
                                                : ''
                                            }
                                            parentCallback={textData => {
                                              this.richTextData(
                                                textData,
                                                index,
                                                elem
                                              );
                                            }}
                                          />
                                        </div>
                                        <span
                                          id={`paragraph_error_${elem.dataType}_${index}`}
                                          className="cw-error cw-hidden"
                                          data-control="name"
                                        >
                                          <i className="fas fa-exclamation-triangle mr-10"></i>
                                          Please enter the paragraph statement
                                        </span>
                                      </div>
                                    </>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      }
                    })}
                    {this.state.showAllPurposeError ? (
                      <div className="form-group mt-20 ml-1 generic-error-message">
                        <span
                          id={`all_purpose_error`}
                          className="cw-error"
                          data-control="name"
                        >
                          <i className="fas fa-exclamation-triangle mr-10"></i>
                          Please make sure you have entered all the mandatory
                          fields
                        </span>
                      </div>
                    ) : null}
                    {!editCampaign ? (
                      <div className="form-group mt-20 saas-profile-select">
                        <Select
                          className={'cw-select--custom cw-color--primary '}
                          name={'consentField'}
                          label={'Label'}
                          placeholder={'Add an Additional Field'}
                          options={
                            this.props.formMetaData.accordionData === undefined
                              ? constant.profileFields
                              : this.props.formMetaData.accordionData
                          }
                          onChange={this._handleProfileField}
                          value={'Add an additional field'}
                        />
                      </div>
                    ) : null}
                    {this.status === '6' ? (
                      <div className="cw-form--action-cta">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          onClick={event => this._handleSubmit(event)}
                        >
                          Next
                        </button>
                        <a
                          href="javascript:void(0)"
                          className="cw-save-to-draft mt-40"
                          onClick={this._handleCancel}
                        >
                          Cancel
                        </a>
                      </div>
                    ) : (
                      <div className="cw-form--action-cta">
                        <button
                          type="button"
                          className="btn btn btn-outline-secondary mr-3"
                          onClick={this._handlebackButton}
                        >
                          Back
                        </button>
                        <button
                          type="submit"
                          className="btn btn-primary"
                          onClick={event => this._handleSubmit(event)}
                        >
                          Next
                        </button>

                        {campaign.isEdited ? (
                          <a
                            href="javascript:void(0)"
                            className="cw-cancel-edit mt-40"
                            onClick={event => this._openIsCancelModal(event)}
                          >
                            Cancel changes
                          </a>
                        ) : (
                          <a
                            href="javascript:void(0)"
                            className="cw-save-to-draft mt-40"
                            onClick={event => this._handleSubmit(event, true)}
                          >
                            Save and exit
                          </a>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  name: state.campaignDetails.name,
  language: state.campaignDetails.language,
  formMetaData: state.saasFormAuthoring,
  campaign: state.overview.campaign,
  isLoading: state.saasFormAuthoring.isLoading
});

export default connect(mapStateToProps, {
  getAuthoringFieldData,
  fetchCampaignData,
  setNotificationMessage,
  setApproverEditCampaignStatusMessage,
  setApproverEditCampaignStatus,
  setApproverEditLastUpdatedScreen,
  cancelCampaignChanges
})(SaasFormProfileFieldDetails);
