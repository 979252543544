import React from 'react';
import {
  getCampaignStatusText,
  getCampaignStatus,
  isAEMTemplateCampaign
} from 'selectors';
import classNames from 'classnames';
import constant from '../../../../constants';

const roleForFilter = constant.dashboardRoleFilter;

export default function Status({ authorizedUserData, campaign }) {
  const { status, isProcessed } = campaign;
  const isPublished = isProcessed ? true : false;
  const isAEMTemplate = isAEMTemplateCampaign(campaign);

  const statusClassName = classNames({
    badge: true,
    'campaign-status-badge': true,
    'badge-success':
      status === '3'
        ? isAEMTemplate
          ? isPublished
            ? true
            : false
          : true
        : false,
    'badge-warning':
      status === '6' ||
      status === '7' ||
      status === '8' ||
      (status === '3'
        ? isAEMTemplate
          ? isPublished
            ? false
            : true
          : false
        : true),
    'badge-primary': status === '1' || status === '4',
    'badge-secondary': status === '9'
  });

  return authorizedUserData.group &&
    roleForFilter.includes(authorizedUserData.group) ? (
    <span className={statusClassName}>
      {getCampaignStatusText(campaign, authorizedUserData.group)}
    </span>
  ) : (
    <span className={statusClassName}>
      {getCampaignStatus(status) === 'Completed'
        ? isAEMTemplate
          ? isPublished
            ? 'Published'
            : 'Processing'
          : getCampaignStatus(status)
        : getCampaignStatusText(campaign, authorizedUserData.group)}
    </span>
  );
}
