import React from 'react';
import { Controller } from 'react-hook-form';
import EmojiCustom from './EmojiCustom';

const EmojiCountWrapper = ({
  name,
  id,
  defaultValue,
  control,
  label,
  errors,
  maxLength,
  helpText,
  subText,
  labelTooltip
}) => {
  // const charCount = value.length
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <EmojiCustom
          {...field}
          label={label}
          id={id}
          errors={errors}
          maxLength={maxLength}
          helpText={helpText}
          defaultValue={defaultValue}
          subText={subText}
          labelTooltip={labelTooltip}
        />
      )}
    />
  );
};
export default EmojiCountWrapper;
