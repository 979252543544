import React from 'react';
import ReactTooltip from 'react-tooltip';

export default function Tooltip({
  index,
  value,
  onChange,
  qna = false,
  multipleChoice = false,
  v2 = false,
  epsilon = false
}) {
  return (
    <div
      className={`form-group ${
        qna
          ? `${epsilon ? 'ml-2' : 'ml-3'} cw-qna-lable-width ${multipleChoice ? 'mt-3' : 'mt-2'}`
          : 'form-group-field-width'
      }`}
    >
      <label htmlFor={`toolTipText_${index}`}>
        <span>Tooltip/Help-Text (optional)</span>
        {v2 && (
          <ReactTooltip
            place="right"
            id="Help-Text"
            type="info"
            multiline={true}
            className="cw-tooltip"
          />
        )}
        <span
          className="float-right  cw-icon cw-icon--help"
          {...(v2 ? { 'data-for': 'Help-Text' } : {})}
          data-tip="Please specify the tooltip message you would want users to see in case they need some additional information while filling up the form. Make sure to have this message in the language you selected for the form."
          currentitem="false"
        >
          <i className="fas fa-question-circle"></i>
        </span>
      </label>
      <input
        type="text"
        className="form-control"
        name={`toolTipText_${index}`}
        id={`toolTipText_${index}`}
        aria-describedby={`toolTipText_${index}`}
        data-required="true"
        value={value}
        onChange={onChange}
      />
    </div>
  );
}
