import { GuidelineWrapper } from 'components/atoms';
import Loader from 'components/Loader';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-modal';
import ActionBar from '../ActionBar/ActionBar';
import BeeEmailEditor from '../BeeEmailEditor';
import DesignEmail from '../DesignEmail/DesignEmail';
import { openSaveEmailTemplate } from '../../../actions/emailbriefing/saveTemplate';
import { get } from 'lodash';
import constants from '../../../constants';
import { Thumbnail } from 'pages/DesignYourEmail';
import { saveEmailTemplateAndDownload } from 'services/emailTemplateEdit';
import { addWatermarkInTemplateImage, getBlobFromBuffer } from 'selectors'
import fileDownload from 'js-file-download';
import moment from 'moment';

export default function TemplatePreview({
  template,
  thumbnail,
  emailBriefing,
  title,
  hideActionBar,
  sentTestEmailOnClick,
  onClickEdit,
  uid,
  isExportButton = false,
  refetchEmailHistoryLogs,
  handleDesignEmailClick,
  isDraft,
  ...props
}) {
  const [isMobilePreviewOpen, setIsMobilePreviewOpen] = useState(false);
  const [isDesktopPreviewOpen, setIsDesktopPreviewOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isDownloading, setIsDownloading] = useState(false);
  const { authorizedUserData } = useSelector(state => state.authorized);
  const dispatch = useDispatch();

  const beeConfig = {
    workspace: {
      stage: isMobilePreviewOpen ? 'mobile' : 'desktop'
    },
    customCss: `${constants.consentsApi.HOST}/campaign-wizard/email-editor-custom.css`
  };

  const onLoad = () => {
    setTimeout(() => setIsLoading(false), 1000);
  };

  const onClose = () => {
    setIsDesktopPreviewOpen(false);
    setIsMobilePreviewOpen(false);
    setIsLoading(true);
  };

  if (!thumbnail) {
    return <DesignEmail />;
  }

  const onSaveAsTemplate = () => {
    const templateObject = get(
      emailBriefing,
      'emailTemplateEditId.templateObject'
    );
    const templateHtml = get(emailBriefing, 'emailTemplateEditId.templateHtml');
    if (templateObject && templateHtml) {
      dispatch(openSaveEmailTemplate(templateObject, templateHtml));
      refetchEmailHistoryLogs && refetchEmailHistoryLogs();
    }
  };

  const downloadTemplateImg = async () => {
    setIsDownloading(true);
    const callback = () => {}
    const newTemplateHtml = emailBriefing.emailTemplateEditId.templateHtml;
    const data = await saveEmailTemplateAndDownload(
      emailBriefing.uid,
      JSON.stringify(emailBriefing.emailTemplateEditId.templateObject),
      newTemplateHtml,
      { user: authorizedUserData },
      callback
    );
    
    const { result : { desktopImageBlob,mobileImageBlob, campaignName,countryCode ,brandNames,variantDesktopImageArr,variantMobileImageArr} } = data
    if(variantDesktopImageArr && variantDesktopImageArr.length){
      for(const ele of variantDesktopImageArr){
        const desktopBlob =  getBlobFromBuffer(ele.imageBlob)
        fileDownload(desktopBlob,`CW_Email_${countryCode}_${brandNames}_${campaignName}_${moment().format('DDMMYYYY')}_Desktop_${constants.AB_VARIANT_MAP_IMG_DWNLOAD[ele.variant]}.jpg`)
      }
      for(const ele of variantMobileImageArr){
        const mobileBlob =  getBlobFromBuffer(ele.imageBlob)
         fileDownload(mobileBlob,`CW_Email_${countryCode}_${brandNames}_${campaignName}_${moment().format('DDMMYYYY')}_Mobile_${constants.AB_VARIANT_MAP_IMG_DWNLOAD[ele.variant]}.jpg`)
      }
      setIsDownloading(false);
    } else {
      const desktopBlob =  getBlobFromBuffer(desktopImageBlob)
      fileDownload(desktopBlob,`CW_Email_${countryCode}_${brandNames}_${campaignName}_${moment().format('DDMMYYYY')}_Desktop.jpg`)
      const mobileBlob =  getBlobFromBuffer(mobileImageBlob)
      setIsDownloading(false);
      fileDownload(mobileBlob,`CW_Email_${countryCode}_${brandNames}_${campaignName}_${moment().format('DDMMYYYY')}_Mobile.jpg`)
    }


  }

  const style = {};

  if (!hideActionBar) {
    style.position = 'relative';
    style.top = -44;
  }

  return (
    <div className="template-review" style={style}>
      <Modal
        isOpen={isMobilePreviewOpen || isDesktopPreviewOpen}
        onRequestClose={() => setIsDesktopPreviewOpen(false)}
        className="cw-modal cw-modal--email-preview"
        contentLabel="Field Types"
        style={{
          overlay: {
            backgroundColor: constants.EMAIL_OVERLAY_BACKGROUND
          }
        }}
      >
        {isLoading ? <Loader style={{ background: 'white' }} /> : null}
        <GuidelineWrapper
          isEmailCampaign
          className="template-modal-wrapper"
          onClose={onClose}
          hideCloseButton
          closeIconClass="email-preview-close-modal"
        >
          <div className="">
            {isLoading ? <Loader style={{ background: 'white' }} /> : null}
          </div>
          <div className="cross-cover"></div>
          <BeeEmailEditor
            onLoad={onLoad}
            className="mt-0"
            hideHeader
            emailBriefing={emailBriefing}
            config={beeConfig}
            template={template}
            preview
          />
        </GuidelineWrapper>
      </Modal>
      {hideActionBar ? null : (
        <ActionBar
          sentTestEmailOnClick={sentTestEmailOnClick}
          onMobilePreview={() => setIsMobilePreviewOpen(true)}
          onDesktopPreview={() => setIsDesktopPreviewOpen(true)}
          onExport={props.onExport}
          onClickEdit={onClickEdit}
          isExportButton={isExportButton}
          onSaveAsClick={onSaveAsTemplate}
          isDownloadBtn ={true}
          hideDesignCheckListBtn={true}
          downloadTemplateImg={downloadTemplateImg}
          isLoading={isDownloading}
        />
      )}
      {props.isAbTestError ? (
        <p className="error-text mb-10">
          <span>
            <i className="fas fa-exclamation-triangle mr-10"></i>
          </span>
          Missing AB test variants
        </p>
      ) : null}
      {handleDesignEmailClick ? (
        <div style={{ flex: '.3', position: 'relative' }}>
          <Thumbnail
            imageStyle={{}}
            button={
              isDraft
                ? {
                    label: 'Continue design',
                    onClick: handleDesignEmailClick
                  }
                : ''
            }
            thumbnail={thumbnail}
          />
        </div>
      ) : (
        <img
          className="template-review__image"
          src={thumbnail}
          alt="Email preview thumbnail"
        />
      )}
    </div>
  );
}
