import { GET_SMS_REVIEW_DETAIL } from '../../actionTypes';

const initialState = {
  smsBrands: [],
  smsBrandList: [],
  smsBrandDetails: [],
  brand: null,
  country: null,
  language: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SMS_REVIEW_DETAIL: {
      let smsBrandDetails = {
        smsBrandDetails: action.payload
      };
      return {
        ...state,
        ...smsBrandDetails
      };
    }
    default:
      return state;
  }
};
