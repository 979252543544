import React from "react";
const BrandDetailsMessage = (props) => {
  //let _closeNewModal = ()=> {
  // setModalState({
  //     modalState: false,
  // });
  // props.history.push('/campaign-wizard/newcampaign');
  //};
  return (
    <>
      <div className="grid-container">
        <div className="grid-item item8 mb-10">
          <h2 className="overlay_top_heading">Campaign Wizard 2.0</h2>
          <span>
            {
              // !props.destination ?
              <button
                className="cw-modal--close"
                title="Close"
                onClick={props.closeNewModal}
              >
                <i className="fas fa-times"></i>
              </button>
              // :
              // <a
              // className="cw-modal--close"
              // title="Close"
              // href={props.destination}
              //>
              //</span><i className="fas fa-times"></i>
              // </a>
            }
          </span>
        </div>
        <div className="grid-item item5">
          <span className="heading_overlay heading_overlay--h2">
            What's new
          </span>
        </div>
        <div className="grid-item item1">
          <h2 className="heading_overlay--h2 mb-2">Advanced Authoring</h2>

          <p className="text-item">
            Campaign Wizard now offers extensive control for you to construct
            your sign-up form. You can now configure all your form fields and
            also experience our inbuilt integrations to fetch approved legal
            consent copy across markets.
          </p>
        </div>
        <div className="grid-item item2">
          <h2 className="heading_overlay--h2 mb-2">Platform agnostic forms</h2>

          <p className="text-item-2">
            Preview your form output even before you submit your request. Once
            it is processed, plug our forms into your website, whether it is on
            AEM or any other platform! Our forms come with inbuilt integrations
            to send consumer data to Unilever’s consumer profile stores.
          </p>
        </div>
        <div className="grid-item item3 mt-2" style={{ textAlign: "left" }}>
          <h2 className="heading_overlay">Who can use this?</h2>
        </div>
        <div className="grid-item item4">
          <h2 className="heading_overlay--h2 mb-2">Anyone!</h2>

          <p className="text-item">
            You don’t need to be a webmaster to create forms using our easy to
            use interface that saves us all the hassle that comes with CMS
            authoring.
          </p>
        </div>
        <div className="grid-item item7">
          <h2 className="heading_overlay--h2 mb-2">All website types</h2>

          <p className="text-item-2">
            Brands that do not have AEM websites can also now use our form
            generation services.
          </p>
        </div>
        <div className="grid-item item6 mt-3">
          <h2 className="heading_overlay heading_overlay--h2">
            How to use the new features?
          </h2>
          <br />
          <ul
            className="ml-20 text-item"
            style={{ listStyleType: "square", textAlign: "start" }}
          >
            <br />
            <li>
              Choose ‘website type’ as ‘Adobe SaaS’ on the first step of your
              request, even if your website is on any other platform{" "}
            </li>
            <li>Specify all input we ask of you to construct your form</li>

            <li>Preview your form and submit your request</li>

            <li>
              We will process your request to seek your market’s approval and
              provision any master data (not applicable for US)
            </li>

            <li>
              We will notify you once your request is complete, using our
              inbuilt email automations
            </li>

            <li>
              At this stage, you will be all set to use the form we provide you.
              All you need to do is to style it and plug into your website,
              using our simple integration guide
            </li>
          </ul>
        </div>
      </div>
      <div className="grid-item item9">
        {
          //props.destination
          //? <a className="btn btn-primary btn--homepage-btn"
          //    href={props.destination} style={{width: '14%'}}>
          //</div>  Continue
          // </a>: null
        }
        {/* <button
              className="btn btn-primary btn--homepage-btn"
              title="Close"
              onClick={props.closeNewModal}
              style={{width: '14%'}}
            >
              Close
            </button> */}
      </div>

      <div className="grid-item item10"></div>
    </>
  );
};

export default BrandDetailsMessage;
