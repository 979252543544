import React, { Component } from 'react';
import { connect } from 'react-redux';
import { downloadGigyaCssFile } from '../actions/overview';
import _ from 'lodash';
import gigyaPDF from '../resources/GIG-Non-AEMImplementation-240919-1614-110.pdf';
import gigyaCSS from '../resources/gigya_css.pdf';

class GigyaProfileStore extends Component {
  constructor(props) {
    super(props);
    this._downloadCssFile = this._downloadCssFile.bind(this);
    this._handleCollapse = this._handleCollapse.bind(this);

    this.collapsePanel = React.createRef();
    this.collapseHandlerIcon = React.createRef();
    this.collapseHandlerText = React.createRef();
  }
  _handleCollapse(event) {
    event.preventDefault();

    let collapseHandlerTextLess = 'View less Gigya connection details';
    let collapseHandlerTextMore = 'View more Gigya connection details';

    if (this.collapsePanel.current.classList.contains('show')) {
      this.collapsePanel.current.classList.remove('show');
      this.collapseHandlerIcon.current.classList.remove('fa-chevron-up');
      this.collapseHandlerIcon.current.classList.add('fa-chevron-down');
      this.collapseHandlerText.current.innerHTML = collapseHandlerTextMore;
    } else {
      this.collapsePanel.current.classList.add('show');
      this.collapseHandlerText.current.innerHTML = collapseHandlerTextLess;
      this.collapseHandlerIcon.current.classList.remove('fa-chevron-down');
      this.collapseHandlerIcon.current.classList.add('fa-chevron-up');
    }
  }
  _downloadCssFile(event) {
    this.props.downloadGigyaCssFile(this.props.campaignId, event.target.name);
  }
  render() {
    let {
      campaignDetails,
      draftFlag,
      disabledFlag,
      campaign,
      status
    } = this.props;
    let isAEM =
      campaign &&
      campaign.websiteType &&
      (campaign.websiteType.name.toLowerCase() === 'adobe classic' ||
        campaign.websiteType.name.toLowerCase() === 'adobe templated')
        ? true
        : false;
    const qaApiKeyHtml = (
      <div className="cw-striped-row">
        <div className="row">
          <div className="col-sm-3">
            <strong>QA API key</strong>
          </div>
          <div
            className={
              this.props.colValClass ? this.props.colValClass : 'col-sm-5'
            }
          ></div>
          <div className="col-sm-4 text-break cw-word-wrap">
            {this.props.hideInputFields === 'true' ? (
              campaignDetails.profileStore &&
              campaignDetails.profileStore.gigyaQAProfileStoreKey &&
              campaignDetails.profileStore.gigyaQAProfileStoreKey !== '' ? (
                campaignDetails.profileStore.gigyaQAProfileStoreKey
              ) : status !== 'Cancelled' ? (
                'Awaiting'
              ) : (
                ''
              )
            ) : (
              <input
                type="text"
                className="form-control"
                name="gigyaQAProfileStoreKey"
                id="gigyaQAProfileStoreKey"
                value={
                  campaignDetails.profileStore &&
                  campaignDetails.profileStore.gigyaQAProfileStoreKey &&
                  campaignDetails.profileStore.gigyaQAProfileStoreKey !== ''
                    ? campaignDetails.profileStore.gigyaQAProfileStoreKey
                    : 'Awaiting'
                }
                disabled={disabledFlag}
                readOnly={true}
              />
            )}
          </div>
        </div>
      </div>
    );

    const productionApiKeyHtml = (
      <div className="cw-striped-row">
        <div className="row">
          <div className="col-sm-3">
            <strong>Production API key</strong>
          </div>
          <div
            className={
              this.props.colValClass ? this.props.colValClass : 'col-sm-5'
            }
          ></div>
          <div className="col-sm-4 text-break cw-word-wrap">
            {this.props.hideInputFields === 'true' ? (
              campaignDetails.profileStore &&
              campaignDetails.profileStore.gigyaProductionProfileStoreKey &&
              campaignDetails.profileStore.gigyaProductionProfileStoreKey !==
                '' ? (
                campaignDetails.profileStore.gigyaProductionProfileStoreKey
              ) : status !== 'Cancelled' ? (
                'Awaiting'
              ) : (
                ''
              )
            ) : (
              <input
                type="text"
                className="form-control"
                name="gigyaProductionProfileStoreKey"
                id="gigyaProductionProfileStoreKey"
                value={
                  campaignDetails.profileStore &&
                  campaignDetails.profileStore.gigyaProductionProfileStoreKey &&
                  campaignDetails.profileStore
                    .gigyaProductionProfileStoreKey !== ''
                    ? campaignDetails.profileStore
                        .gigyaProductionProfileStoreKey
                    : 'Awaiting'
                }
                disabled={disabledFlag}
                readOnly={true}
              />
            )}
          </div>
        </div>
      </div>
    );

    let _toggleQaProdApiSection = position => {
      if (campaign.type === 'Simple sign-up') {
        if (position === 'non-collapse') {
          return productionApiKeyHtml;
        } else {
          return '';
        }
      } else {
        if (position === 'non-collapse') {
          return qaApiKeyHtml;
        } else {
          return productionApiKeyHtml;
        }
      }
    };

    return (
      <div className="col-sm-cw-campaign--review-section mb-40">
        <div className="cw-campaign--review-section">
          <div className="mb-20">
            <h3 className="cw-heading--primary">
              <span>Gigya</span> Connection Details
            </h3>
          </div>
          <div className="cw-campaign--review-section-content">
            <div className="cw-separator mb-20">
              {_toggleQaProdApiSection('non-collapse')}

              <div
                className="collapse"
                id="gigyaDetailsCollapsed"
                ref={this.collapsePanel}
              >
                {_toggleQaProdApiSection('collapse')}
                <div className="cw-striped-row">
                  <div className="row">
                    <div className="col-sm-3">
                      <strong>Screenset name</strong>
                    </div>
                    <div
                      className={
                        this.props.colValClass
                          ? this.props.colValClass
                          : 'col-sm-5'
                      }
                    ></div>
                    <div className="col-sm-4 text-break cw-word-wrap">
                      {this.props.hideInputFields === 'true' ? (
                        campaignDetails.profileStore &&
                        campaignDetails.profileStore.gigyaScreenSetName &&
                        campaignDetails.profileStore.gigyaScreenSetName !==
                          '' ? (
                          campaignDetails.profileStore.gigyaScreenSetName
                        ) : status !== 'Cancelled' ? (
                          'Awaiting'
                        ) : (
                          ''
                        )
                      ) : (
                        <input
                          type="text"
                          className="form-control"
                          name="gigyaScreenSetName"
                          id="gigyaScreenSetName"
                          value={
                            campaignDetails.profileStore &&
                            campaignDetails.profileStore.gigyaScreenSetName &&
                            campaignDetails.profileStore.gigyaScreenSetName !==
                              ''
                              ? campaignDetails.profileStore.gigyaScreenSetName
                              : 'Awaiting'
                          }
                          disabled={disabledFlag}
                          readOnly={true}
                        />
                      )}
                    </div>
                  </div>
                </div>

                {isAEM ? (
                  <div className="cw-striped-row">
                    <div className="row">
                      <div className="col-sm-3 ">
                        <strong>Start screen name</strong>
                      </div>
                      <div
                        className={
                          this.props.colValClass
                            ? this.props.colValClass
                            : 'col-sm-5'
                        }
                      ></div>
                      <div className="col-sm-4 text-break cw-word-wrap">
                        {this.props.hideInputFields === 'true' ? (
                          campaignDetails.profileStore &&
                          campaignDetails.profileStore.gigyaStartScreenUrl &&
                          campaignDetails.profileStore.gigyaStartScreenUrl !==
                            '' ? (
                            campaignDetails.profileStore.gigyaStartScreenUrl
                          ) : status !== 'Cancelled' ? (
                            'Awaiting'
                          ) : (
                            ''
                          )
                        ) : (
                          <input
                            type="text"
                            className="form-control"
                            name="gigyaStartScreenUrl"
                            id="gigyaStartScreenUrl"
                            value={
                              campaignDetails.profileStore &&
                              campaignDetails.profileStore
                                .gigyaStartScreenUrl &&
                              campaignDetails.profileStore
                                .gigyaStartScreenUrl !== ''
                                ? campaignDetails.profileStore
                                    .gigyaStartScreenUrl
                                : 'Awaiting'
                            }
                            disabled={disabledFlag}
                            readOnly={true}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className="cw-striped-row">
                  <div className="row">
                    <div className="col-sm-3">
                      <strong>Preference centre screenset name</strong>
                    </div>
                    <div
                      className={
                        this.props.colValClass
                          ? this.props.colValClass
                          : 'col-sm-5'
                      }
                    ></div>
                    <div className="col-sm-4 text-break cw-word-wrap">
                      {this.props.hideInputFields === 'true' ? (
                        campaignDetails.profileStore &&
                        campaignDetails.profileStore
                          .gigyaPreferenceCentreScreenSetName &&
                        campaignDetails.profileStore
                          .gigyaPreferenceCentreScreenSetName !== '' ? (
                          campaignDetails.profileStore
                            .gigyaPreferenceCentreScreenSetName
                        ) : status !== 'Cancelled' ? (
                          'Awaiting'
                        ) : (
                          ''
                        )
                      ) : (
                        <input
                          type="text"
                          className="form-control"
                          name="gigyaPreferenceCentreScreenSetName"
                          id="gigyaPreferenceCentreScreenSetName"
                          value={
                            campaignDetails.profileStore &&
                            campaignDetails.profileStore
                              .gigyaPreferenceCentreScreenSetName &&
                            campaignDetails.profileStore
                              .gigyaPreferenceCentreScreenSetName !== ''
                              ? campaignDetails.profileStore
                                  .gigyaPreferenceCentreScreenSetName
                              : 'Awaiting'
                          }
                          disabled={disabledFlag}
                          readOnly={true}
                        />
                      )}
                    </div>
                  </div>
                </div>
                {!isAEM ? (
                  <div>
                    <div className="cw-striped-row">
                      <div className="row">
                        <div className="col-sm-3">
                          <strong>CSS file</strong>
                        </div>
                        <div
                          className={
                            this.props.colValClass
                              ? this.props.colValClass
                              : 'col-sm-5'
                          }
                        ></div>
                        <div className="col-sm-4 text-break cw-word-wrap">
                          <span className="file-input--path">
                            <a target="_blank" href={gigyaCSS} download>
                              gigya-screenshots_css_file.css
                            </a>
                          </span>
                          <span className="file-input--path">
                            <a target="_blank" href={gigyaPDF} download>
                              Non-AEM Implementation guide PDF
                            </a>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
              <a
                className="collapse-h pt-2"
                href="#gigyaDetailsCollapsed"
                role="button"
                aria-expanded="false"
                aria-controls="gigyaDetailsCollapsed"
                onClick={this._handleCollapse}
              >
                <span ref={this.collapseHandlerText}>
                  View more Gigya connection details
                </span>
                <i
                  className="fas fa-chevron-down ml-1"
                  ref={this.collapseHandlerIcon}
                ></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  campaign: state.overview.campaign,
  campaignDetails: state.overview.campaignDetails,
  questionAnswerList: state.overview.questionAnswerList,
  campaignStatus: state.overview.campaign.status,
  campaignId: state.overview.campaign.uid,
  serviceError: state.overview.serviceError,
  serviceErrorStatus: state.overview.serviceErrorStatus,
  apiConnectionDetails: state.overview.campaignDetails
    ? state.overview.campaignDetails.profileStore
    : '',
  asyncInProgress: state.overview.asyncInProgress
    ? state.overview.asyncInProgress
    : '',
  optins: state.overview.optins
});

export default connect(mapStateToProps, { downloadGigyaCssFile })(
  GigyaProfileStore
);
