import {
  ADD_QUESTION,
  DELETE_QUESTION,
  ADD_ANSWER,
  DELETE_ANSWER,
  ADD_QUESTION_TYPE,
  FETCH_QUESTIONS,
  FETCH_ANSWERS,
  QUESTIONS_FETCH_SUCCESS,
  ANSWERS_FETCH_SUCCESS,
  SUBMIT_QUESTIONSANSWERS_SUCCESS,
  SUBMITTING_QUESTIONSANSWERS,
  CAMPAIGN_DETAILS_FETCH_SUCCESS,
  RESET_QNA_SUBMISSION,
  EDIT_QUESTION,
  SAVE_QUESTIONSANSWERS_DATA
} from '../actionTypes';

const initialState = {
  fetchingQuestions: false,
  fetchingAnswers: false,
  fetchingCampaignDetails: false,
  questionsLoaded: false,
  campaignDetailsLoaded: false,
  selectedQuestionType: '',
  questions: [],
  answers: [],
  addedQuestions: [],
  prevAddedQuestions: [],
  selectedAnswers: [
    {
      id: '',
      text: ''
    }
  ],
  campaignLanguage: '',
  isLanguageEnglish: '',
  campaignLanguageCode: '',
  dataSubmittedToApi: false,
  submittingDataToApi: false,
  questionanswerssaved: false,
  isCampaignEdited: false,
  websiteType: '',
  campaignType: '',
  isClone: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ADD_QUESTION: {
      let addedQuestions = {
        addedQuestions: [...action.question, ...state.addedQuestions]
      };
      return { ...state, ...addedQuestions };
    }
    case ADD_QUESTION_TYPE: {
      let questionType = {
        value: action.questionType.value,
        label: action.questionType.label
      };
      let updatedQuestion = { ...state.question, ...{ type: questionType } };
      return { ...state, ...{ question: updatedQuestion } };
    }
    case ADD_ANSWER: {
      let answers = { answers: [...state.answers, action.answer] };
      return { ...state, ...answers };
    }
    case DELETE_ANSWER: {
      let answersAll = state.answers;
      let answers = {};
      if (action.index > -1) {
        answers = { answers: answersAll.filter((item, index) => index !== 1) };
      }

      return { ...state, ...answers };
    }
    case DELETE_QUESTION: {
      return {
        ...state,
        ...{
          addedQuestions: state.addedQuestions.filter(
            (item, index) => index !== action.index
          )
        }
      };
    }
    case FETCH_QUESTIONS: {
      return { ...state, ...{ fetchingQuestions: action.isLoading } };
    }

    case FETCH_ANSWERS: {
      return { ...state, ...{ fetchingAnswers: action.isLoading } };
    }

    case QUESTIONS_FETCH_SUCCESS: {
      let questions =
        action.questions &&
        action.questions.map(question => {
          return {
            value: question.id,
            label: question.text
          };
        });
      let udpatedState = {
        questions: questions,
        questionsLoaded: action.questionsLoaded
      };
      return { ...state, ...udpatedState };
    }
    case ANSWERS_FETCH_SUCCESS: {
      let answers =
        action.answers &&
        action.answers.map(question => {
          return {
            value: question.id,
            label: question.text
          };
        });

      return { ...state, ...{ answers } };
    }

    case SUBMIT_QUESTIONSANSWERS_SUCCESS: {
      return { ...state, ...{ dataSubmittedToApi: action.dataSubmission } };
    }
    case SAVE_QUESTIONSANSWERS_DATA: {
      return { ...state, ...{ questionanswerssaved: action.dataSubmission } };
    }

    case SUBMITTING_QUESTIONSANSWERS: {
      return { ...state, ...{ submittingDataToApi: action.isLoading } };
    }

    case CAMPAIGN_DETAILS_FETCH_SUCCESS: {
      return {
        ...state,
        ...{
          campaignDetailsLoaded: true,
          isCampaignEdited: action.campaign && action.campaign.isEdited,
          campaignLanguage:
            action.campaign &&
            action.campaign.language &&
            action.campaign.language.name,
          isLanguageEnglish:
            action.campaign && action.campaign.isLanguageEnglish,
          campaignLanguageCode:
            action.campaign &&
            action.campaign.language &&
            action.campaign.language.code.toUpperCase(),
          addedQuestions:
            (action.campaign &&
              action.campaign.campaignDetail &&
              action.campaign.campaignDetail.questionAnswerList) ||
            [],
          prevAddedQuestions: action.campaign.isEdited
            ? action.campaign &&
              action.campaign.campaignDetail &&
              action.campaign.campaignDetail.questionAnswerList
            : [],
          websiteType:
            (action.campaign &&
              action.campaign.websiteType &&
              action.campaign.websiteType.name) ||
            '',
          campaignType: (action.campaign && action.campaign.campaignType) || '',
          campaign: action.campaign,
          fields:
            (action.campaign &&
              action.campaign.campaignDetail &&
              action.campaign.campaignDetail.fields) ||
            [],
          isClone: action.campaign && action.campaign.isClone,
          iConfirmQnA:
            action.campaign &&
            action.campaign.campaignDetail &&
            action.campaign.campaignDetail.iConfirmQnA
        }
      };
    }

    case RESET_QNA_SUBMISSION: {
      return { ...state, ...{ dataSubmittedToApi: false } };
    }
    case EDIT_QUESTION: {
      let removalIndex = action.questionIndex.index;
      state.addedQuestions.splice(removalIndex, 1);
      let editedQuestions = {
        addedQuestions: [...action.question, ...state.addedQuestions]
      };

      return { ...state, ...editedQuestions };
    }

    default:
      return state;
  }
}
