import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  toggleEmailCrossBrand,
  toggleForceEmailType
} from '../../actions/emailbriefing/emailBrandDetails';

import CheckBoxInput from '../../components/molecules/CheckBoxInput';

export const MultiBrandCheck = () => {
  const { isCrossBrand } = useSelector(state => state.emailBrandDetails);

  const dispatch = useDispatch();

  return (
    <CheckBoxInput
      id="isCrossBrand"
      name="isCrossBrand"
      value={isCrossBrand}
      onChange={() => {
        dispatch(toggleEmailCrossBrand());
        dispatch(toggleForceEmailType(!isCrossBrand));
      }}
      label="Cross-brand email"
    />
  );
};
