import React from 'react';
const QnAepsilonSubheadingText = props => {
  return (
    <>
      Q&amp;As enable you to gain insight on your consumer preferences and needs.
      {/* needs. We have a range of approved Q&amp;As to choose from. Can't find a
      question you need? You can request new but this may take up to eight days
      to complete. */}
      <br/>
    </>
  );
};

export default QnAepsilonSubheadingText;
