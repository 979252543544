import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchUserData, logoutTracking } from 'actions/authorization';

export default ({ eventEmitter }) => {
  const history = useHistory();
  const historyRef = useRef(null);
  const dispatch = useDispatch();
  historyRef.current = history;

  useEffect(() => {
    const redirectCallback = ({ pathName = null, userDetails }) => {
      if (pathName) {
        dispatch(fetchUserData(userDetails)).then(resp => {
          console.log('redirect dispatch');
          if (historyRef.current) {
            historyRef.current.push(pathName);
          }
        });
        // if(historyRef.current){
        //   historyRef.current.push(pathName)
        // }
      }
    };
    const logoutTrackingCallback = eventName => {
      dispatch(logoutTracking(eventName));
    };
    eventEmitter.on('redirectUser', redirectCallback);
    eventEmitter.on('logoutTrack', logoutTrackingCallback);
    return () => {
      eventEmitter.removeListener('redirectUser', redirectCallback);
    };
  }, []);
  return null;
};
