import axios from 'axios';
import constant from '../constants';
import moment from 'moment';
import fileDownload from 'js-file-download';

export async function createEmailTemplateEdit(data) {
  const response = await axios.post(
    `${constant.serviceUrls.EMAIL_TEMPLATE_EDIT_URL}`,
    data
  );
  return response.data.data;
}

export async function getEmailTemplateEditByUid(uid) {
  try {
    const response = await axios.get(
      `${constant.serviceUrls.EMAIL_TEMPLATE_EDIT_URL}/${uid}`
    );
    return response.data.data;
  } catch (error) {
    throw error;
  }
}

/**
 * Save campaign template
 */
export async function saveEmailTemplate(
  uid,
  json,
  html,
  { user } = {},
  callback
) {
  try {
    const data = {
      uid,
      json: json || '',
      html: html || '',
      user
    };
    const response = await axios.post(
      `${constant.serviceUrls.EMAIL_TEMPLATE_EDIT_URL}/save`,
      data
    );
    callback();
    if (response) {
      return response.data;
    }
    return {};
  } catch (e) {
    throw e;
  }
}
/**
 * exportEmailHTML will trigger download
 * @param {*} uid
 * @returns
 */
export async function exportEmailHTML(
  uid,
  userEmail,
  userName,
  { campaignName = 'Untitled' } = {}
) {
  try {
    const response = await axios({
      url: `${
        constant.serviceUrls.EMAIL_TEMPLATE_EXPORT
      }/exporthtml/${uid}?userName=${userName ? userName : ''}&userEmail=${
        userEmail ? userEmail : ''
      }`, //your url
      method: 'GET',
      responseType: 'blob' // important
    }).then(response => {
      const currentDate = moment().format('DD-MM-YYYY');
      let name = campaignName.split(' ').join('-');
      const fileName = `Unilever_CampaignWizard_Email_${cleanUpSpecialCharacters(
        name
      )}_${uid}_${currentDate}`;
      fileDownload(response.data, `${fileName}.zip`);
    });
  } catch (e) {
    throw e;
  }
}

export async function regenerateEmailBriefingThumbnail(uid) {
  const response = await axios.get(
    `${constant.serviceUrls.EMAIL_TEMPLATE_EDIT_URL}/${uid}/regenerate-thumbnail`
  );
  return response.data;
}

/**
 * Save campaign template
 */
export async function saveEmailTemplateAndDownload(
  uid,
  json,
  html,
  { user } = {},
  callback
) {
  try {
    const data = {
      uid,
      json: json || '',
      html: html || '',
      user,
      isDownload: true
    };
    const response = await axios.post(
      `${constant.serviceUrls.EMAIL_TEMPLATE_EDIT_URL}/save`,
      data
    );
    callback();
    if (response) {
      return response.data;
    }
    return {};
  } catch (e) {
    throw e;
  }
}

function cleanUpSpecialCharacters(inputString) {
  // Array of special characters to be removed
  const specialCharacters = ['<', '>', ':', '"', '/', '\\\\', '|', '?', '*'];
  const regexPattern = new RegExp(`[${specialCharacters.join('')}]`, 'g');
  // Replace special characters with an empty string
  const cleanedString = inputString.replace(regexPattern, '');
  return cleanedString;
}
