import axios from 'axios';
import _, { orderBy } from 'lodash';
import constant from '../constants';
import { filterToQuery } from '../selectors';
const httpProtocolPrefix =
  process.env.NODE_ENV === 'development' ? 'http' : 'https';

export async function getSMSBrandMarketMappings(query = {}) {
  try {
    let response = await axios.get(constant.communications.FETCH_SMS_BRAND_DETAIL + "/list" + filterToQuery(query));
    if (response) {
      let data = response.data;
      return data;
    }
    return [];
  } catch (e) {
    throw e;
  }
}

export async function addSMSBrandMarketMappings(data, user) {
  try {
    let response = await axios.post(`${constant.communications.FETCH_SMS_BRAND_DETAIL}/add`, {
      data, user
    });
    if (response) {
      let data = response.data;
      return data;
    }
    return response;
  } catch (e) {
    throw e;
  }
}

export async function deleteSMSOptOutMessage(id) {
  try {
    let response = await axios.get(`${constant.communications.FETCH_SMS_BRAND_DETAIL}/${id}/delete`);
    if (response) {
      let data = response.data;
      return data;
    }
    return [];
  } catch (e) {
    throw e;
  }
}

export async function editSMSOptOutMessage(id, value, language, user) {
  try {
    let response = await axios.post(`${constant.communications.FETCH_SMS_BRAND_DETAIL}/${id}/edit`, {
      value, language, user
    });
    if (response) {
      let data = response.data;
      return data;
    }
    return [];
  } catch (e) {
    throw e;
  }
}

export async function getBulkUploadHistoryLogs(category) {
  try {
    let response = await axios.get(`${constant.serviceUrls.ADMIN_SETTINGS_HISTORY_LOGS}/brandManagement/?category=${category}`)
    if (response) {
      let data = response.data;
      return data;
    }
  } catch (e) {
      throw e
  }
}