import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../../components/Loader';
import BreadCrumb from '../../components/BreadCrumb';
import { FormSection } from 'components/organisms/FormSection';
import { FormError } from '../../components/molecules/FormError';
import { LinkButton } from 'components/atoms/LinkButton';
import { get } from 'lodash';
import {
  SummarySchema,
  deliveryScheduleSchema,
  audienceDefinitionSchema
} from './EmailCampaignSchema';
import {
  getEmailCampaignDetails,
  updateEmailCampaignDetails
} from 'actions/emailbriefing/emailCampaignDetails';
import { transformEmailBriefingPayload } from 'selectors';
import ProgressBar from 'components/molecules/ProgressBar';

const scrollToTop = () => {
  setTimeout(() => {
    document.getElementById('root').scrollIntoView({ behavior: 'smooth' });
  }, 10);
};

const formSectionList = [
  // {
  //   id: 'summary',
  //   title: 'Summary',
  //   formSchema: SummarySchema
  // },
  // {
  //   id: 'deliverySchedule',
  //   title: 'Delivery schedule',
  //   formSchema: deliveryScheduleSchema,
  //   sectionIcon: 'far fa-clock'
  // },
  {
    id: 'audienceDefinition',
    title: 'Audience definition',
    formSchema: audienceDefinitionSchema,
    sectionIcon: 'fas fa-users'
  }
];

const EmailCampaignDetails = props => {
  const { isLoading, uid, emailType, ...detailsData } = useSelector(
    state => state.emailCampaignDetails
  );
  const [formSectionStates, setFormSectionStates] = useState({});
  const [pageError, setPageError] = useState(false);
  const [sectionErrors, setSectionErrors] = useState({});
  const dispatch = useDispatch();
  // const compProps = { history: props.history, match: props.match };

  useEffect(() => {
    if (pageError) {
      validateAll(formSectionStates);
    }
  }, [formSectionStates]);

  useEffect(() => {
    const pathUid = props.match.params.id;
    // if (pathUid && uid !== pathUid) {
    // fetch from email details from the server
    // debugger
    dispatch(getEmailCampaignDetails(pathUid));
    // }
  }, []);

  const validateAll = (givenSectionStates, isSubmit) => {
    const list = Object.keys(givenSectionStates);
    const newErrors = { ...sectionErrors };
    let flag = false;
    list.forEach(key => {
      if (!formSectionStates[key].isValid) {
        newErrors[key] = true;
        flag = true;
      } else {
        newErrors[key] = false;
      }
    });
    setPageError(flag);
    if (flag && isSubmit) {
      scrollToTop();
    }
    setSectionErrors(newErrors);
    return flag;
  };

  const handleSectionStateChange = (id, sectionState) => {
    let temp = {};
    setFormSectionStates(prevState => {
      const newState = {
        ...prevState,
        [id]: sectionState
      };
      temp = newState;
      return newState;
    });

    // if(pageError) {
    //   validateAll(temp)
    // }
  };

  console.log('detailsData====>', detailsData);
  const handleNextClick = () => {
    if (validateAll(formSectionStates, true)) {
      return false;
    }
    const paramUid = props.match.params.id;
    const dataToSend = {
      userEmail: props.userEmail,
      userName: props.userName
    };
    formSectionList.forEach(section => {
      dataToSend[section.id] = {
        ...formSectionStates[section.id]['data']
      };
    });

    console.log('dataToSend====>', dataToSend);
    const transformedData = transformEmailBriefingPayload(
      dataToSend,
      'emailCampaignDetails'
    );

    dispatch(
      updateEmailCampaignDetails(paramUid, transformedData, (error, result) => {
        if (result) {
          console.log('Email campaign details updated!');
          const uid = props.match.params.id;
          props.history.push(
            `/campaign-wizard/emailbriefing/${uid}/email-template`
          );
        }
        if (error) {
          console.log('OnSubmit error: ', error);
        }
      })
    );
  };

  const handleSaveAndExit = () => {
    const paramUid = props.match.params.id;
    const dataToSend = {
      status: '1',
      userEmail: props.userEmail,
      userName: props.userName
    };
    formSectionList.forEach(section => {
      dataToSend[section.id] = {
        ...formSectionStates[section.id]['data']
      };
    });
    const transformedData = transformEmailBriefingPayload(
      dataToSend,
      'emailCampaignDetails'
    );

    console.log('transformedData===>', transformedData);
    dispatch(
      updateEmailCampaignDetails(paramUid, transformedData, (error, result) => {
        if (result) {
          props.history.push(`/campaign-wizard/email-dashboard`);
        }
        if (error) {
          console.log('OnSubmit error: ', error);
        }
      })
    );
  };

  return (
    <div className="col-sm-12 skypink">
      <div className="cw-section">
        <ProgressBar customClass={'cw-email-progress-custom'} pageNo={5} />
        <div className="cw-section--title mb-30">
          <h2 className="cw-heading--secondary cw-email-heading">
            Email campaign details
          </h2>
        </div>
        {pageError && (
          <div className="row">
            <div className="col-md-10">
              <FormError message="Please complete missing configurations in the highlighted sections" />
            </div>
          </div>
        )}
        <div className="row">
          {isLoading ? <Loader /> : null}
          <div className="col-md-10">
            {formSectionList.map(section => (
              <FormSection
                {...section}
                title={section.title}
                className="mb-30"
                formSchema={section.formSchema}
                key={section.title}
                onStateChange={handleSectionStateChange}
                hasError={get(sectionErrors, `${section.id}`)}
                defaultValues={detailsData[section.id]}
                emailType={emailType}
              />
            ))}

            <div>
              <button
                className="btn btn btn-outline-secondary mr-3"
                type="button"
                onClick={() => {
                  const uid = props.match.params.id;
                  props.history.push(
                    `/campaign-wizard/emailbriefing/${uid}/email-schedule`
                  );
                }}
              >
                Back
              </button>
              <button
                className="btn btn-primary"
                type="button"
                onClick={handleNextClick}
              >
                Next
              </button>
            </div>

            <LinkButton
              className="cw-formdraft-link mt-40"
              onClick={handleSaveAndExit}
            >
              Save and exit
            </LinkButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailCampaignDetails;
