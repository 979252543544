import React, { Component } from 'react';
import Optins from './Optins';
import _ from 'lodash';
import { isCampaignEdited } from '../selectors/index';

export default class RegularCommunications extends Component {
  constructor(props) {
    super(props);
    this.optinsDescription = {
      Brand:
        'Please tick the box to receive offers and competitions about (brand name ) by email.',
      Unilever:
        'Please tick the box to receive exciting news, offers and competitions from other Unilever brands by email.'
    };
  }
  render() {
    let { campaignDetails, draftFlag, disabledFlag } = this.props;
    if (
      typeof this.props.showComponent != 'undefined'
        ? this.props.showComponent
        : true
    ) {
      return (
        <div className="cw-campaign--review-section mb-40">
          <div className="cw-campaign--review-section mb-40">
            <div className="cw-campaign--review-section-header mb-3 d-flex align-items-center">
              <h3 className="cw-heading--primary">
                <span>Regular</span> Communications
              </h3>
              <button
                className={`btn btn-outline-secondary ml-auto ${
                  this.props.showEditButton ? '' : 'd-none'
                }`}
                onClick={this.props.buttonClickEvent}
              >
                Edit
              </button>
            </div>

            {campaignDetails.additionalOptinList &&
            campaignDetails.additionalOptinList.constructor === Object ? (
              <Optins
                optins={campaignDetails.additionalOptinList}
                regularCommunicationLabel={
                  this.props.regularCommunicationLabel || ''
                }
                disabledFlag={disabledFlag}
                isRegular={true}
                draftFlag={draftFlag}
                colValClass={this.props.colValClass}
                colLabelClass={this.props.colLabelClass}
                isEdited={this.props.isEdited}
                campaignEditLog={this.props.campaignEditLog}
                changeAllOptinsColor={
                  this.props.isEdited &&
                  isCampaignEdited(
                    'additionalConsentFlag',
                    this.props.campaignEditLog
                  )
                }
                showFrequency={true}
              />
            ) : campaignDetails.additionalOptinList &&
              campaignDetails.additionalConsentFlag &&
              campaignDetails.additionalOptinList.constructor === Array ? (
              campaignDetails.additionalOptinList.map((detail, detailIndex) => {
                return (
                  <Optins
                    key={detailIndex}
                    index={detailIndex}
                    isRegular={true}
                    optins={detail}
                    regularCommunicationLabel={
                      this.props.regularCommunicationLabel || ''
                    }
                    colValClass={this.props.colValClass}
                    colLabelClass={this.props.colLabelClass}
                    disabledFlag={disabledFlag}
                    draftFlag={draftFlag}
                    isEdited={this.props.isEdited}
                    campaignEditLog={this.props.campaignEditLog}
                    changeAllOptinsColor={
                      this.props.isEdited &&
                      isCampaignEdited(
                        'additionalConsentFlag',
                        this.props.campaignEditLog
                      )
                    }
                    showFrequency={true}
                  />
                );
              })
            ) : (
              <div>
                <div className="cw-striped-profile-row  ">
                  <div className="row">
                    <div className="col-sm-4">
                      <strong>Send regular communications</strong>
                    </div>
                    <div className="col-sm-8">
                      <p>No</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div
              className="cw-striped-profile-row"
              style={{ padding: '8px 4px' }}
            ></div>
          </div>
        </div>
      );
    }
    return null;
  }
}
