import axios from 'axios';
import {
	GET_CAMPAIGN,
	ASYNC_IN_PROGRESS,
	UPDATE_PROFILE_STORE,
	PRM_DATA_SUBMITTED,
	PRM_DRAFT_SUBMITTED,
} from '../actionTypes';
import constant from '../constants';

export const updateProfileStore = (profileStoreData) => ({
	type: UPDATE_PROFILE_STORE,
	profileStoreData,
});

export function fetchCampaignData(Id) {
	return function(dispatch) {
		dispatch({
			type: ASYNC_IN_PROGRESS,
			isLoading: true,
		});
		// Fetch Campaign Data
		axios.get(constant.serviceUrls.CAMPAIGN_DETAILS_SERVICE_URL + '/' + Id).then((response) => {
			let campaign = response.data[0];
			dispatch({ type: ASYNC_IN_PROGRESS, isLoading: false });
			dispatch({ type: GET_CAMPAIGN, campaign });
		});
	};
}
export function savePrmData(id, profileStoreData, userName, userEmail, isdraft) {
	return function(dispatch) {
		dispatch({
			type: ASYNC_IN_PROGRESS,
			isLoading: true,
		});
		// save prm data
		let data = {};
		data['uid'] = id;
		data['profileStore'] = profileStoreData;
		data['prmdata'] = true;
		data['prmdatadraft'] = isdraft;
		data['isPRMDetailsSubmitted'] = isdraft ? false : true;
		data['PRMSubmissionDate'] = isdraft ? null : Date.now();
		if (!isdraft) {
			data['isProcessed'] = false;
		}
		return axios({
			method: 'POST',
			url: constant.serviceUrls.CAMPAIGN_DATA_SERVICE_URL,
			data: data,
		})
			.then((response) => {
				dispatch({
					type: ASYNC_IN_PROGRESS,
					isLoading: false,
				});
			})
			.then((response) => {
				if (!isdraft) {
					let prmSubmitData = {
						uid: id,
						status: constant.campaignStatus.COMPLETED,
						userName: userName,
						userEmail: userEmail,
					};
					return axios({
						method: 'POST',
						url: constant.serviceUrls.CAMPAIGN_STATUS_UPDATE_SERVICE_URL,
						data: prmSubmitData,
					}).then((response) => {
						dispatch({
							type: ASYNC_IN_PROGRESS,
							isLoading: true,
						});
						dispatch({ type: ASYNC_IN_PROGRESS, isLoading: false });
						dispatch({
							type: PRM_DATA_SUBMITTED,
							dataSubmitted: true,
						});
						dispatch({
							type: PRM_DRAFT_SUBMITTED,
							isDraftState: false,
						});
					});
				} else {
					dispatch({
						type: ASYNC_IN_PROGRESS,
						isLoading: true,
					});
					dispatch({ type: ASYNC_IN_PROGRESS, isLoading: false });
					dispatch({
						type: PRM_DATA_SUBMITTED,
						dataSubmitted: true,
					});
					dispatch({
						type: PRM_DRAFT_SUBMITTED,
						isDraftState: true,
					});
				}
			});
	};
}
