import React from 'react';
import ReactTooltip from 'react-tooltip';
import _ from 'lodash';

import {
  EmailSummaryCard,
  EmailSummaryHeading,
  EmailSummaryText
} from '../EmailSummaryCard/EmailSummaryCard';
import { LiveSummaryCard } from './LiveSummaryCard';

import constants from 'constants/config';
import { getAudienceSendEmailTolabel, getDeliveryCondition } from 'selectors';

import CampaignIcon from '../../../images/campaign.png';
import DeliveryIcon from '../../../images/delivery.png';
import AudienceIcon from '../../../images/audience.png';
import { ReactComponent as AbTestingIcon } from '../../../images/abTestingIcon.svg';
import { ReactComponent as EmailCouponIcon } from '../../../images/email-coupon.svg';
import { ReactComponent as ResendCouponIcon } from '../../../images/resend-icon.svg';

export default function EmailSummaryCards({ className = '', emailProps }) {
  if (!emailProps.emailBriefing) return null;

  const {
    summary,
    audienceDefinition,
    brand,
    country,
    emailType,
    abTesting,
    isCrossBrand,
    emailAttributes,
    deliverySchedule,
    status,
    trackingLabels = {}
  } = emailProps && emailProps.emailBriefing;

  const couponApplied = emailAttributes?.emailCoupon?.couponApplied;
  const couponType = emailAttributes?.emailCoupon?.type;

  const { performABTests, noOfABVariants, abTestElements } = abTesting || {};
  const isABTestOnSendTime = performABTests && abTestElements === 'sendTime';

  const resendEmail =
    deliverySchedule?.sendAfter?.value &&
    deliverySchedule?.sendAfter?.value !== 'DontSend';
  const consumerCount = deliverySchedule?.consumerCount;

  const mapAbTestLabel = {
    subjectLine: 'subject',
    emailContent: 'content',
    sendTime: 'send time'
  };

  const getAudienceText = () => {
    const audienceBrand = Array.isArray(brand)
      ? brand.map(b => b.name).join(', ')
      : brand.name;
    return (
      getAudienceSendEmailTolabel({
        audienceDefinition,
        audienceBrand,
        country,
        isCrossBrand,
        summaryCard: true
      }) || 'Not specified'
    );
  };

  return (
    <>
      <div className={`d-flex mb-30 ${className}`}>
        <EmailSummaryCard
          icon={CampaignIcon}
          title="Campaign"
          showBottomText={couponApplied}
          bottomIcon={EmailCouponIcon}
          label={`Includes ${constants.EMAIL_COUPON_TYPE[couponType]}`}
          style={{ flex: 1.4 }}
        >
          <EmailSummaryHeading>
            {(summary && summary.campaignName) || 'Untitled'}
          </EmailSummaryHeading>
          <EmailSummaryText className="email-summary-card__bold">
            {constants.emailType[emailType]}
          </EmailSummaryText>
          <EmailSummaryText>
            {Array.isArray(brand)
              ? isCrossBrand
                ? 'Cross brand email'
                : brand[0].name
              : brand && brand.name}
            ,&nbsp;
            {country && country.name}
          </EmailSummaryText>
        </EmailSummaryCard>

        <EmailSummaryCard
          icon={DeliveryIcon}
          title="Delivery"
          showBottomText={resendEmail}
          bottomIcon={ResendCouponIcon}
          label={`Resend to non-openers`}
          style={{ flex: 1, marginLeft: 12 }}
        >
          {getDeliveryCondition(deliverySchedule, isABTestOnSendTime).map(text => (
            <EmailSummaryText key={text} className="one-line">{text}</EmailSummaryText>
          ))}
        </EmailSummaryCard>

        <EmailSummaryCard
          icon={AudienceIcon}
          title="Audience"
          showBottomText={abTesting && abTesting.performABTests}
          bottomIcon={AbTestingIcon}
          label={`Includes A/B test on ${
            mapAbTestLabel[abTesting?.abTestElements]
          }`}
          style={{ flex: 1, marginLeft: 12 }}
        >
          <>
            <EmailSummaryText>{getAudienceText()}</EmailSummaryText>
            <EmailSummaryText className="mt-2">
              {consumerCount && (
                <div className="consumer-count-container">
                  <span className="consumer-count">{consumerCount}</span>{' '}
                  consumers
                  <span
                    className="icon"
                    data-tip="This is the expected count of consumers to whom your email will be sent, as provided by ACS team. For actual performance metrics, refer to the Power BI reports"
                    data-for="consumerCount"
                  >
                    &nbsp;<i className="fas fa-question-circle"></i>
                  </span>
                  <ReactTooltip
                    id="consumerCount"
                    place="bottom"
                    type="info"
                    multiline={true}
                    className="cw-tooltip cw-email-tooltip"
                  />
                </div>
              )}
            </EmailSummaryText>
          </>
        </EmailSummaryCard>
      </div>

      {status === constants.EMAIL_STATUS.LIVE && (
        <LiveSummaryCard summary={summary} deliverySchedule={deliverySchedule} abTesting={abTesting} trackingLabels={trackingLabels}/>
      )}
    </>
  );
}
