import axios from 'axios';
import constant from '../constants';

let controller = new AbortController();

export async function getEmailCoreTemplates(query, pageNumber) {
  let filter =
    typeof query === 'object'
      ? '?search=' + query.text + '&' + paramsSerializer(query.filter)
      : query
      ? '?search=' + query
      : '';
  filter += `${filter ? '&' : '?'}page=${pageNumber}`;

  controller.abort();
  controller = new AbortController();
  const response = await axios.get(
    `${constant.serviceUrls.EMAIL_CORE_TEMPLATES_URL}` + filter,
    { signal: controller.signal }
  );
  return response.data;
}

export async function getLiveEmailTemplates(query, pageNumber,uid) {
  let filter =
    typeof query === 'object'
      ? '?search=' + query.text + '&' + paramsSerializer(query.filter)
      : query
      ? '?search=' + query
      : '';
   filter += `${filter ? '&' : '?'}page=${pageNumber}`;
  if(uid){
    filter += `${filter ? '&' : '?'}uid=${uid}`;
  }
  controller.abort();
  controller = new AbortController();
  const response = await axios.get(
    `${constant.serviceUrls.LIVE_EMAIL_TEMPLATES}` + filter,
    { signal: controller.signal }
  );
  return response.data;
}

function paramsSerializer(params) {
  return Object.entries(Object.assign({}, params))
    .map(([key, value]) => `${key}=${value}`)
    .join('&');
}

export async function saveEmailCoreTemplate(data) {
  try {
    const response = await axios.post(
      `${constant.serviceUrls.EMAIL_CORE_TEMPLATES_URL}/save`,
      data
    );
    if (response) {
      return response.data;
    }
    return {};
  } catch (e) {
    throw e;
  }
}

export async function deleteEmailCoreTemplate(id) {
  const response = await axios.get(
    `${constant.serviceUrls.EMAIL_CORE_TEMPLATES_URL}/${id}/delete`
  );
  return response.data;
}

export async function regenerateCoreTemplateThumbnail(id, data) {
  const response = await axios.post(
    `${constant.serviceUrls.EMAIL_CORE_TEMPLATES_URL}/${id}/regenerate-thumbnail`,
    data
  );
  return response.data;
}
