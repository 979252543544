import React from 'react';
import ReactTooltip from 'react-tooltip';

export default function MasterDataNotice({ previousMasterId }) {
  return (
    <div className="master-data-notice">
      <ReactTooltip
        id={`saas_new_master_question_translated_tooltip_${previousMasterId}`}
        place="left"
        type="info"
        multiline={true}
        className="cw-tooltip saas_new_master_tooltip master_data_notice_tooltip"
      />
      <span
        data-custom-msg={`question_${previousMasterId}_id`}
        data-tip="You are viewing a previously available master data ID for this Q/A which is no longer or not,<br />yet in the master list, as this campaign may have been created by cloning another."
        data-for={`saas_new_master_question_translated_tooltip_${previousMasterId}`}
      >
        <i className="fas fa-exclamation-triangle mr-1"></i>
        <span>{`ID ${previousMasterId} was in clone source`}</span>
      </span>
    </div>
  );
}
