import React from 'react';
import { components } from 'react-select';

import CheckBoxInput from '../CheckBoxInput';

export const OptionsWithCheckbox = ({
  getStyles,
  isFocused,
  isSelected,
  children,
  innerProps,
  isDisabled,
  ...rest
}) => {
  const onClickMultiOption = e => {
    rest.selectOption({ ...rest.data });
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <components.Option
      {...rest}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={innerProps}
      isDisabled={isDisabled}
    >
      {rest.isMulti ? (
        <div onClick={onClickMultiOption}>
          <CheckBoxInput
            id={children}
            name={rest.selectProps.name}
            label={children}
            value={isSelected}
            onChange={() => {}}
          />
        </div>
      ) : (
        children
      )}
    </components.Option>
  );
};
