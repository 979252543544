import React from 'react';
import { getGSMandUnicodeStats } from 'utilities/util';

export const SmsContentTableDisplay = ({ body, optOutText }) => {
  const contentStats = getGSMandUnicodeStats(
    optOutText ? body + ` ${optOutText}` : body
  );
  const getMessage = stats => {
    if (!stats) {
      return null;
    }
    if (stats.hasUnicode) {
      if (stats.segments > 1) {
        return (
          <p className="mt-2">
            <span className="yellow-color">
              <i className="fas fa-exclamation-circle mr-2"></i>
            </span>
            <span class="email-tabular__subLabel">
              Includes Unicode characters, {stats.segments} SMS parts
            </span>
          </p>
        );
      } else {
        return (
          <p className="mt-2">
            <span className="yellow-color">
              <i className="fas fa-exclamation-circle mr-2"></i>
            </span>
            <span class="email-tabular__subLabel">
              Includes Unicode characters, single SMS part
            </span>
          </p>
        );
      }
    } else {
      if (stats.segments > 1) {
        return (
          <p className="mt-2">
            <span className="blue-color">
              <i className="fas fa-info-circle mr-2"></i>
            </span>
            <span class="email-tabular__subLabel">
              GSM message, {stats.segments} SMS parts
            </span>
          </p>
        );
      } else {
        return (
          <p className="mt-2">
            <span className="blue-color">
              <i className="fas fa-info-circle mr-2"></i>
            </span>
            <span class="email-tabular__subLabel">
              GSM message, single SMS part
            </span>
          </p>
        );
      }
    }
  };
  return (
    <>
      <p>
        {body ? (
          <span class="pre-wrap">{body}</span>
        ) : (
          <span class="email-tabular__subLabel">
            SMS body content has not been entered
          </span>
        )}{' '}
        <br />
        {optOutText && <br />}
        {optOutText}
      </p>

      {body && getMessage(contentStats)}
    </>
  );
};
