import * as yup from 'yup';
import constants from 'constants/config';
import React, { useState } from 'react';
import { transformSMSPayloadBySchedule } from 'selectors';
import {
  smsUploadFileWithStatus,
  updateSmsCampaignStatus
} from 'actions/communications/communicationCampaignDetails';
import SmsPurchaseOrderInput from 'components/molecules/SmsPurchaseOrderInput/SmsPurchaseOrderInput';
import { SMS_ASYNC_IN_PROGRESS } from 'actionTypes';

const SmsPurchaseOrderInfoSchema = (
  dispatch,
  { defaultValues, pathUid, closeModal, setToggle, smsType ,authorizedUserData,closePoModelClick}
) => {
  const [isActive, setIsActive] = useState(false);
  return {
    formType: 'modal',
    submitBtnLabel: 'Send purchase order',
    btnClassName: 'd-flex justify-content-end mt-20',
    modelBtnSwap: true,
    fields: [
      {
        type: 'custom',
        name: 'sharePurchaseOrder',
        component: SmsPurchaseOrderInput,
        id: 'sharePurchaseOrder',
        label: 'What do you want to do',
        emailType: smsType,
        setIsActive: setIsActive,
        isActive: isActive,
        rules: yup.object({
          poNumber: yup
            .number()
            .positive('Purchase order number must be greater than 0')
            .required('Please enter your purchase order (PO) number')
            .typeError('Please enter your purchase order (PO) number'),
          poFile: yup
            .mixed()
            .required('Please upload a file')
            .typeError('Please upload a file'),
          additionalcomments: yup.string()
        })
      }
    ],
    onSubmit: (data, { user }) => {
      let dataToSend = {};
      const paramUid = pathUid;
      if (data && data?.sharePurchaseOrder) {
        const formData = new FormData();
        const fileData = data?.sharePurchaseOrder?.poFile;
        formData.append('file', fileData);
        formData.append('userEmail', user.emailId);
        formData.append('userName', user.name);
        formData.append('status', constants.SMS_STATUS.AWAITING_SETUP);
        formData.append('purchaseOrderFlow', true); // for identify file
        formData.append(
          'sharePurchaseOrder',
          JSON.stringify(data.sharePurchaseOrder)
        );
        formData.append('uid', paramUid);
        formData.append('role', authorizedUserData.group);
        const transformedData = transformSMSPayloadBySchedule(dataToSend);
        dispatch({ type: SMS_ASYNC_IN_PROGRESS, isLoading: true });
        dispatch(
          smsUploadFileWithStatus(
            formData,
            { ...transformedData, uid: paramUid },
            (error, result) => {
              dispatch({ type: SMS_ASYNC_IN_PROGRESS, isLoading: false });
              if (error) {
                console.log('OnSubmit error: ', error);
              } else {
                closeModal(true);
              }
            },
            { disableLoader: true }
          )
        );
      }
    },
    onPrevious: () => {
      setToggle(false);
      closePoModelClick(false);
    },
    previousBtnLabel: 'Cancel',
    disableFormError: true
  };
};

export default SmsPurchaseOrderInfoSchema;
