import React from 'react';
import GuidelineWrapper from './atoms/GuidelineWrapper/GuidelineWrapper';
import MasterDataGuideLines from './MasterDataGuideLines';

const MasterDataGuideline = props => {
  return (
    <GuidelineWrapper
      className="template-modal-wrapper"
      titleClassName="cw-heading--saascampaign mt-15"
      isEmailCampaign={props.isEmailCampaign}
      onClose={props.closeIsEditModal}
      title={props.title}
    >
      <div>
        <MasterDataGuideLines
          data={props.data}
          noLineBreak={false}
          campaignConfig={props.campaignConfig}
        />
        <div className="horizontal-line"></div>
      </div>
    </GuidelineWrapper>
  );
};

export default MasterDataGuideline;
