import React from 'react';
import { ErrorMessage } from '../hookform/ErrorMessage';
import classNames from 'classnames';

export const FieldError = ({ name, errors, hasNestedError, className }) => {
  return (
    <ErrorMessage
      errors={errors}
      name={name}
      render={({ message }) => (
        <span className={classNames('cw-error', className)} data-control={name}>
          <i className="fas fa-exclamation-triangle mr-10"></i>
          {hasNestedError && errors[name]?.value?.message
            ? errors[name].value.message
            : message}
        </span>
      )}
    />
  );
};