import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';
import axios from 'axios';
import Modal from 'react-modal';
import AsyncDropDown from './AsyncDropDown';
import classNames from 'classnames';
import QnANonSaasSubheadingText from './QnANonSaasSubheadingText';
import constant from '../constants';
import BreadCrumb from './BreadCrumb';
import { setNotificationMessage } from '../actions/dashboard';
import AddedQuestionsList from './AddedQuestionsList';
import QnaGuideline from 'components/QnaGuideline';
import {
  addQuestion,
  deleteQuestion,
  addAnswer,
  deleteAnswer,
  addQuestionType,
  getQuestions,
  getCampaignDetails,
  getAnswers,
  submitQuestionsAnswers,
  resetDataSubmittionState,
  removeQuestion
} from '../actions/questionsAnswers';
import Loader from './Loader';
import ErrorNotification from './errorNotification';
import {
  scrollToTop,
  checkCampaignLanguage,
  showCampaignLanguage,
  isQuestionMandatory,
  fetchQnANotificationTileData
} from '../selectors';
import {
  setApproverEditCampaignStatus,
  setApproverEditCampaignStatusMessage,
  editedCampaignDetails,
  fetchEditedCampaignLogs,
  saveEditedCampaignLogs
} from '../actions/app';
import Alert from './Alert';
import constants from 'constants/config';
import QuestionAnswerNewOverlay from './SAAS/QuestionAnswerNewOverlay';
import SummaryComponent from './SummaryComponent';

class QuestionsAnswers extends Component {
  fileUploadTypeCount = 0;
  uniqueEntryTypeCount = 0;
  qualifyingCount = 0;
  restrictQualifying = false;
  addedQualifyingQuesCount = 0;
  constructor(props) {
    super(props);
    this.state = {
      question: {
        answer: {
          value: '-1',
          label: '',
          translatedanswer: '',
          translatedvalue: '-1',

          new: false
        },
        questionType: {
          value: '',
          label: ''
        },
        question: {
          value: '',
          label: ''
        },
        maxAnswer: {
          value: '',
          label: ''
        },
        qtype: {
          value: '',
          label: ''
        },
        answers: [
          {
            value: '',
            label: '',
            new: false,
            translatedanswer: '',
            translatedvalue: '',
            translatednew: false
          },
          {
            value: '',
            label: '',
            new: false,
            translatedanswer: '',
            translatedvalue: '',
            translatednew: false
          }
        ],
        qno: '',
        id: '',
        maxAnswers: '',
        text: '',
        type: '',
        new: false,
        translatednew: false,
        translatedquestion: '',
        translatedId: '',
        mandatory: false,
        isMobile: false,
        isQualifying: false,
        restrictQualifying: false,
        isRestrictionAllowed: false,
        defaultDisplayText: '',
        placeholderText: '',
        maxFileSize: ''
      },
      answers: [
        {
          value: '',
          label: '',
          new: false
        }
      ],
      maxAnswersData: [],
      isValidQuestion: false,
      modalIsOpen: false,
      activeModal: null,
      deleteIndex: '',
      isSubmitEvent: false,
      showMessageAtTop: false,
      editQuestion: false,
      editQuestionIndex: '',
      isIconDisabled: false,
      matchProps: props.match,
      defaultAnswers: [],
      maxFileSizeLimitExceeded: false,
      qualifyingCount: 0,
      iConfirmQnA: false,
      closeGuideModal: false,
      //isClone: false,
      questionTypesSelect: [
        {
          label: 'Data enrichment',
          value: 'Data enrichment',
          id: 1
        },
        {
          label: 'Operational',
          value: 'Operational',
          id: 2
        }
      ]
    };
    this.baseState = this.state;
    this.LabelMap = {
      question: 'Question',
      questionType: 'Question Type'
    };
    this.questions = [
      { value: 'profileque', label: 'Profile' },
      { value: 'mcq', label: 'Multiple choice' },
      { value: 'scq', label: 'Single choice' },
      { value: 'freetext', label: 'Free text' }
    ];
    this.questionsPromotigo = [
      { value: 'profileque', label: 'Profile' },
      { value: 'mcq', label: 'Multiple choice' },
      { value: 'scq', label: 'Single choice' },
      { value: 'freetext', label: 'Free text' },
      { value: 'uniqueentry', label: 'Unique entry code' },
      { value: 'fileupload', label: 'File upload' }
    ];

    this._isFormValid = false;
    this.questionType = null;
    this.addedAnswers = [];
    this.errorFields = [];

    this.isAEMType =
      this.props.websiteType === 'Adobe Classic' ||
      this.props.websiteType === 'Adobe Templated' ||
      this.props.websiteType === 'Standalone';
    this.isIncentive = this.props.campaignType === 'Incentive';
    this.isPromotigoType = this.isIncentive && this.isAEMType ? true : false;

    !this.props.smsChannel
      ? (this.profileQuestionsType = [
          { value: 'Gender', label: 'Gender' },
          { value: 'Address', label: 'Address' },
          { value: 'Dob', label: 'Date of birth' },
          { value: 'Mobile', label: 'Mobile number' }
        ])
      : (this.profileQuestionsType = [
          { value: 'Gender', label: 'Gender' },
          { value: 'Address', label: 'Address' },
          { value: 'Dob', label: 'Date of birth' }
        ]);

    !this.props.smsChannel
      ? (this.profileQuestions = {
          Gender: 'What is your gender?',
          Address: 'what is your address?',
          Dob: 'What is your date of birth?',
          Mobile: 'What is your mobile number'
        })
      : (this.profileQuestions = {
          Gender: 'What is your gender?',
          Address: 'what is your address?',
          Dob: 'What is your date of birth?'
        });
    !this.props.smsChannel
      ? (this.profileAnswers = {
          Gender: 'Gender dropdown',
          Address: 'Address fields',
          Dob: 'Date picker tool',
          Mobile: 'Mobile fields'
        })
      : (this.profileAnswers = {
          Gender: 'Gender dropdown',
          Address: 'Address fields',
          Dob: 'Date picker tool'
        });

    this.quesanswerPanel = React.createRef();
    this._handleSubmit = this._handleSubmit.bind(this);
    this._handleQuestionType = this._handleQuestionType.bind(this);
    this._handleQuestionInputChange = this._handleQuestionInputChange.bind(
      this
    );
    this._handleQuestionInputOnBlur = this._handleQuestionInputOnBlur.bind(
      this
    );
    this._handleQuestion = this._handleQuestion.bind(this);
    this._handleAnswerInputChange = this._handleAnswerInputChange.bind(this);
    this._handleAnswer = this._handleAnswer.bind(this);
    this._handleAnswers = this._handleAnswers.bind(this);
    this._handleMaxAnswersAllowed = this._handleMaxAnswersAllowed.bind(this);
    this._addQuestion = this._addQuestion.bind(this);
    this._deleteQuestion = this._deleteQuestion.bind(this);
    this._addAnswer = this._addAnswer.bind(this);
    this._deleteAnswer = this._deleteAnswer.bind(this);
    this._maxAnswersOptions = this._maxAnswersOptions.bind(this);
    this._getQuestions = this._getQuestions.bind(this);
    this._openModal = this._openModal.bind(this);
    this._closeModal = this._closeModal.bind(this);
    this._validateForm = this._validateForm.bind(this);
    this._validateField = this._validateField.bind(this);
    this._handlebackButton = this._handlebackButton.bind(this);
    this._handleQuestionDeletion = this._handleQuestionDeletion.bind(this);
    this._handleCollapse = this._handleCollapse.bind(this);
    this._editQuestion = this._editQuestion.bind(this);
    this._cancelEditQuestion = this._cancelEditQuestion.bind(this);
    this._handleProfileQuestion = this._handleProfileQuestion.bind(this);
    this._cancelApproverEdit = this._cancelApproverEdit.bind(this);
    this.handleSelectData = this.handleSelectData.bind(this);
    this._showNonEnglishCampaignSubHeadingText = this._showNonEnglishCampaignSubHeadingText.bind(
      this
    );
    this.isQuesMandatory = this.isQuesMandatory.bind(this);
    this.isQuesQualifying = this.isQuesQualifying.bind(this);
    this.restrictQualifyingQuestion = this.restrictQualifyingQuestion.bind(
      this
    );
    this.isRestrictionAllowed = this.isRestrictionAllowed.bind(this);
    this._getAnswers = this._getAnswers.bind(this);
    this.handleDefaultDisplayText = this.handleDefaultDisplayText.bind(this);
    this.handleFileUploadQuestion = this.handleFileUploadQuestion.bind(this);
    this.handlePlaceholderText = this.handlePlaceholderText.bind(this);
    this.maxFileSizeValidation = this.maxFileSizeValidation.bind(this);
    this.restrictFileUploadNumber = this.restrictFileUploadNumber.bind(this);
    this._handleCheckboxConfirm = this._handleCheckboxConfirm.bind(this);
    this.isCloseGuidelineModel = this.isCloseGuidelineModel.bind(this);
    this.isOpenGuidelineModel = this.isOpenGuidelineModel.bind(this);
    this._handleUniqueEntryQuestion = this._handleUniqueEntryQuestion.bind(
      this
    );
    this.collapsePanelQ = React.createRef();
    this.collapseHandlerIconQ = React.createRef();
    this.quesanswerCancelbtn = React.createRef();
  }

  componentDidMount() {
    this.campaignId = this.props.match.params.id;
    this.props.getQuestions();
    this.props.getAnswers();
    this.props.getCampaignDetails(this.campaignId);
    this._maxAnswersOptions();
    this.restrictQualifyingQuestion();
    if (constant.featureFlags.ENABLE_QNA_GUIDELINES_CHECKBOX) {
      setTimeout(() => {
        this.getCampaignData();
      }, 1000);
    }
    setTimeout(() => {
      if (this.props.addedQuestions && this.props.addedQuestions.length) {
        this.props.addedQuestions.forEach(ele => {
          if (ele && ele.type === 'Unique entry code') {
            this.uniqueEntryTypeCount += 1;
          }
        });
      }
    }, 1000);
  }

  getCampaignData = () => {
    if (this.props.iConfirmQnA) {
      //&& !this.props.isClone) {
      this.setState({ iConfirmQnA: this.props.iConfirmQnA });
    } else {
      this.setState({ iConfirmQnA: false });
    }
  };

  componentDidUpdate(prevProps) {
    if (
      !this.props.approverEditCampaign &&
      this.props.isCampaignEdited &&
      this.props.isCampaignEdited !== prevProps.isCampaignEdited
    ) {
      this.props.fetchEditedCampaignLogs(this.campaignId);
    }
    if (
      !this.props.approverEditCampaign &&
      this.props.addedQuestions !== prevProps.addedQuestions
    ) {
      this.props.editedCampaignDetails({
        questionAnswerList: this.props.addedQuestions
      });
    }
  }

  isQuesMandatory(event) {
    if (event.target) {
      let isChecked = event.target.checked;
      this.setState(state => {
        let question = {
          ...state.question,
          ...{ mandatory: isChecked }
        };
        return { ...state, ...{ question } };
      });
    }
  }

  isQuesQualifying(event) {
    if (event.target) {
      let isChecked = event.target.checked;
      if (isChecked) {
        this.qualifyingCount = this.qualifyingCount + 1;
        this.addedQualifyingQuesCount = this.addedQualifyingQuesCount + 1;
      } else {
        this.qualifyingCount = this.qualifyingCount - 1;
        this.addedQualifyingQuesCount = this.addedQualifyingQuesCount - 1;
      }

      if (this.qualifyingCount > 5 || this.addedQualifyingQuesCount > 5) {
        this.restrictQualifying = true;
        this._scrollToTop();
      } else {
        this.restrictQualifying = false;
      }
      this.setState(state => {
        let question = {
          ...state.question,
          ...{ isQualifying: isChecked }
        };
        return { ...state, ...{ question } };
      });
    }
  }

  restrictQualifyingQuestion() {
    this.props.addedQuestions.map(elem => {
      if (elem.isQualifying) {
        this.addedQualifyingQuesCount = this.addedQualifyingQuesCount + 1;
      }
    });
    return this.addedQualifyingQuesCount;
  }

  isRestrictionAllowed(event) {
    if (event.target) {
      let isChecked = event.target.checked;
      this.setState(state => {
        let question = {
          ...state.question,
          ...{ isRestrictionAllowed: isChecked },
          ...{ maxFileSize: '' }
        };
        return { ...state, ...{ question } };
      });
    }
  }
  handleFileUploadQuestion(event) {
    let fileUploadQuestion = event.target.value;
    this.setState(state => {
      let question = {
        ...state.question,
        ...{ text: fileUploadQuestion }
      };
      return { ...state, ...{ question } };
    });
  }

  handleDefaultDisplayText(event) {
    let defaultDisplayText = event.target.value;
    this.setState(state => {
      let question = {
        ...state.question,
        ...{ defaultDisplayText: defaultDisplayText }
      };
      return { ...state, ...{ question } };
    });
  }

  handlePlaceholderText(event) {
    let placeholderText = event.target.value;
    this.setState(state => {
      let question = {
        ...state.question,
        ...{ placeholderText: placeholderText }
      };
      return { ...state, ...{ question } };
    });
  }

  maxFileSizeValidation(event) {
    const regex = /[a-zA-Z\b$&+,:;=?[\]@#|{}'<>.^*()%!-/]+$/;
    let maxFile = event.target.value;
    if (regex.test(maxFile)) {
      event.preventDefault();
      let location = maxFile.length;
      this.setState(state => {
        let question = {
          ...state.question,
          ...{ maxFileSize: maxFile.substring(0, location - 1) }
        };
        return { ...state, ...{ question } };
      });
    } else if (
      (maxFile.length > 1 && maxFile[0] === '0') ||
      maxFile[0] === '0'
    ) {
      this.setState(state => {
        let question = {
          ...state.question,
          ...{ maxFileSize: maxFile.substring(1) }
        };
        return { ...state, ...{ question } };
      });
    } else if (maxFile < 1) {
      maxFile = '';
      this.setState(state => {
        let question = {
          ...state.question,
          ...{ maxFileSize: this.state.maxFileSize }
        };
        return { ...state, ...{ question } };
      });
    } else if (maxFile > 10000) {
      this.setState(state => {
        let question = {
          ...state.question,
          ...{ maxFileSize: maxFile }
        };
        return { ...state, ...{ question } };
      });
      this.setState({ maxFileSizeLimitExceeded: true });
      this._scrollToTop();
    } else {
      this.setState(state => {
        let question = {
          ...state.question,
          ...{ maxFileSize: maxFile }
        };
        return { ...state, ...{ question } };
      });
      this.setState({ maxFileSizeLimitExceeded: false });
    }
  }
  static getDerivedStateFromProps(props, state) {
    //redirect user to dashoard if questionasnwers saved as draft
    if (props.answers) {
      state.defaultAnswers = props.answers.slice(0, 101);
    }
    if (props.questionanswerssaved) {
      let notificationData = {};
      notificationData.message = constant.CAMPAIGN_SAVED_AS_DRAFT_TEXT;
      notificationData.link = '';
      notificationData.type = 'warning';
      notificationData.campaign = '';

      props.setNotificationMessage(
        notificationData.message,
        notificationData.link,
        notificationData.type,
        notificationData.campaign
      );
      props.history.push('/campaign-wizard/dashboard');
    }

    if (props.dataSubmittedToApi) {
      props.resetDataSubmittionState(false);
      let addedQuestionsCount = props.addedQuestions.length;
      let campaignId = props.match.params.id;

      /* Redirect to Reorder when Question Count >= 2 else to review page */
      if (addedQuestionsCount < 2) {
        if (props.approverEditCampaign) {
          props.setApproverEditCampaignStatusMessage('success');
          props.history.push(
            '/campaign-wizard/campaign/' + props.match.params.id + '/approval'
          );
        } else {
          props.history.push(
            '/campaign-wizard/campaign/' + campaignId + '/review'
          );
        }
        return null;
      } else {
        props.history.push(
          '/campaign-wizard/campaign/' + campaignId + '/sorting'
        );
        return null;
      }
    }
    /* conditions to check if the question is valid or not  */
    if (state.question.qtype.value === 'mcq') {
      return {
        isValidQuestion:
          (state.question.id !== '' ||
            (state.question.id === '' && state.question.new)) &&
          state.question.answers.filter(function(el) {
            return el.label !== '';
          }).length === state.question.answers.length &&
          state.question.qtype.label !== '' &&
          state.question.text !== '' &&
          (props.isLanguageEnglish ||
            (!props.isLanguageEnglish &&
              state.question.translatedquestion.trim().length !== 0 &&
              state.question.answers.filter(function(el) {
                return (
                  el.translatedanswer && el.translatedanswer.trim().length !== 0
                );
              }).length === state.question.answers.length))
      };
    } else if (state.question.qtype.value === 'scq') {
      return {
        isValidQuestion:
          (state.question.id !== '' ||
            (state.question.id === '' && state.question.new)) &&
          state.question.answers.length &&
          state.question.answers.filter(function(el) {
            return el.label !== '';
          }).length === state.question.answers.length &&
          state.question.qtype.label !== '' &&
          state.question.text !== '' &&
          (props.isLanguageEnglish ||
            (!props.isLanguageEnglish &&
              state.question.translatedquestion.trim().length !== 0 &&
              state.question.answers.filter(function(el) {
                return (
                  el.translatedanswer && el.translatedanswer.trim().length !== 0
                );
              }).length === state.question.answers.length))
      };
    } else if (state.question.qtype.value === 'freetext') {
      return {
        isValidQuestion:
          (state.question.id !== '' ||
            (state.question.id === '' && state.question.new)) &&
          state.question.qtype.label !== '' &&
          state.question.text !== '' &&
          (props.isLanguageEnglish ||
            (!props.isLanguageEnglish &&
              state.question.translatedquestion.trim().length !== 0))
      };
    } else if (state.question.qtype.value === 'profileque') {
      return {
        isValidQuestion:
          state.question.questionType !== '' &&
          state.question.qtype.label !== '' &&
          state.question.text !== ''
      };
    } else if (state.question.qtype.value === 'uniqueentry') {
      return {
        isValidQuestion:
          state.question.questionType !== '' &&
          state.question.qtype.label !== '' &&
          state.question.text.trim().length !== 0
      };
    } else if (
      state.question.qtype.value === 'fileupload' &&
      state.question.isRestrictionAllowed
    ) {
      return {
        isValidQuestion:
          state.question.defaultDisplayText !== '' &&
          state.question.placeholderText !== '' &&
          state.question.text !== '' &&
          state.question.maxFileSize > 0 && state.question.maxFileSize < 10001
      };
    } else if (
      state.question.qtype.value === 'fileupload' &&
      !state.question.isRestrictionAllowed
    ) {
      return {
        isValidQuestion:
          state.question.defaultDisplayText !== '' &&
          state.question.placeholderText !== '' &&
          state.question.text !== ''
      };
    } else {
      return { isValidQuestion: false };
    }
  }

  _handlebackButton() {
    let previousRoute =
      this.props.campaignType === 'Incentive'
        ? this.props.websiteType === 'Standalone' && this.props.autogenerate
          ? 'authoringaccess'
          : 'details'
        : 'consentpreferences';
    this.props.history.push(
      '/campaign-wizard/campaign/' + this.campaignId + '/' + previousRoute
    );
  }
  _openModal(event) {
    event.preventDefault();
    this.setState(state => {
      return { ...state, ...{ modalIsOpen: true } };
    });
  }

  _closeModal() {
    this.setState(state => {
      return { ...state, ...{ modalIsOpen: false } };
    });
  }

  _handleQuestionDeletion(event, index) {
    event.preventDefault();
    let itemIndex = parseInt(index);

    this.setState(state => {
      return {
        ...state,
        ...{
          modalIsOpen: true,
          activeModal: 'deletionConfirmation',
          deleteIndex: itemIndex
        }
      };
    });
  }

  _scrollToTop() {
    setTimeout(() => {
      document.getElementById('root').scrollIntoView({ behavior: 'smooth' });
    }, 10);
  }

  _handleSubmit(event, saveAsDraft) {
    event.preventDefault();
    let c = 0;
    let questionsAnswers = this.props.smsChannel
      ? this.props.addedQuestions.filter(
          mobileQues => mobileQues.id !== 'Mobile'
        )
      : this.props.addedQuestions;

    if (this.props.addedQuestions.length) {
      this.props.submitQuestionsAnswers(
        this.campaignId,
        questionsAnswers,
        this.props.userName,
        this.props.userEmail,
        saveAsDraft,
        this.state.iConfirmQnA,
        this.props.isClone
      );

      if (!this.props.approverEditCampaign && this.props.isCampaignEdited) {
        this.props.saveEditedCampaignLogs(
          this.campaignId,
          this.props.editCampaignDetails
        );
      }
      document.getElementById(`qtype`).classList.contains('cw-error-focus') &&
        document.getElementById(`qtype`).classList.remove('cw-error-focus');
    } else {
      if (
        document.querySelector("[data-control='questioncnterror']") &&
        document
          .querySelector("[data-control='questioncnterror']")
          .classList.contains('cw-hidden')
      ) {
        document
          .querySelector("[data-control='questioncnterror']")
          .classList.remove('cw-hidden');
      }
    }

    document.getElementById(`qtype`).classList.contains('cw-error-focus') ||
      document.getElementById(`qtype`).classList.add('cw-error-focus');

    this.setState({ isSubmitEvent: true });
    this._scrollToTop();
  }

  _deleteQuestion(event) {
    event.preventDefault();
    this.props.deleteQuestion(this.state.deleteIndex);
    const questionType = this.props.addedQuestions[this.state.deleteIndex].type;
    if (
      questionType === 'Unique entry code' &&
      this.uniqueEntryTypeCount >= 1
    ) {
      this.uniqueEntryTypeCount = 0;
    }
    this.setState({
      answers: [
        {
          value: '',
          label: '',
          new: false
        }
      ]
    });
    this.setState(state => {
      return {
        ...state,
        ...{ modalIsOpen: false, activeModal: null, deleteIndex: '' }
      };
    });
    this.props.addedQuestions.map(ques => {
      if (ques.id === 'Mobile') {
        this.setState({ isMobile: false });
      }
    });
  }

  _addQuestion(event) {
    event.preventDefault();
    if (this.state.isValidQuestion) {
      let {
        answer,
        question,
        maxAnswer,
        qtype,
        ...questionObj
      } = this.state.question;

      if (this.state.question.id === 'Mobile') {
        this.setState({ isMobile: true });
      }
      if (qtype && qtype.value === 'freetext') {
        questionObj.answers = [];
        questionObj.answers.push(answer);
      }
      if (qtype && qtype.value === 'uniqueentry') {
        this.uniqueEntryTypeCount += 1;
      }
      if (this.state.editQuestion) {
        this.props.removeQuestion([questionObj], {
          index: this.state.editQuestionIndex
        });
        this.props.editedCampaignDetails({
          questionAnswerList: this.props.prevAddedQuestions
        });
      } else {
        this.props.addQuestion([questionObj]);
        this.props.editedCampaignDetails({
          questionAnswerList: this.props.prevAddedQuestions
        });
      }

      if (
        document.querySelector("[data-control='questioncnterror']") &&
        !document
          .querySelector("[data-control='questioncnterror']")
          .classList.contains('cw-hidden')
      ) {
        document
          .querySelector("[data-control='questioncnterror']")
          .classList.add('cw-hidden');
      }

      if (!this.quesanswerCancelbtn.current.classList.contains('cw-hidden')) {
        this.quesanswerCancelbtn.current.classList.add('cw-hidden');
      }

      if (!this.quesanswerPanel.current.classList.contains('cw-hidden')) {
        this.quesanswerPanel.current.classList.add('cw-hidden');
      }

      this.baseState.question.answers = this.baseState.question.answers.map(
        answer => {
          return { value: '', label: '' };
        }
      );

      this.baseState.question.qtype = {
        value: '',
        label: ''
      };

      this.setState(state => {
        let tempAnswers = this.state.answers;
        tempAnswers = {};
      });

      this.setState({
        answers: [
          {
            value: '',
            label: '',
            new: false
          }
        ]
      });

      this.setState(this.baseState);
      this._maxAnswersOptions();
    } else {
      this._validateForm();
    }
  }

  _validateField(field) {
    if (
      document.querySelector("[name='" + field + "']") &&
      document
        .querySelector("[name='" + field + "']")
        .parentElement.classList.contains('error')
    ) {
      document
        .querySelector("[name='" + field + "']")
        .parentElement.classList.remove('error');
    }
    if (
      document.querySelector("[data-control='" + field + "']") &&
      !document
        .querySelector("[data-control='" + field + "']")
        .classList.contains('cw-hidden')
    ) {
      document
        .querySelector("[data-control='" + field + "']")
        .classList.add('cw-hidden');
    }
  }

  _validateForm() {
    if (this.props.existingCampaign) {
      this.isValid = true;
      return false;
    }
    let formElements = {
      qtype: this.state.question.qtype.value,
      question: this.state.question.id,
      answers: this.state.question.answers
    };

    for (var key in formElements) {
      if (formElements.hasOwnProperty(key)) {
        if (document.querySelector("[name='" + key + "']")) {
          if (formElements[key]) {
            if (
              document
                .querySelector("[name='" + key + "']")
                .parentElement.classList.contains('error')
            ) {
              document
                .querySelector("[name='" + key + "']")
                .parentElement.classList.remove('error');
            }
            if (
              !document
                .querySelector("[data-control='" + key + "']")
                .classList.contains('cw-hidden')
            ) {
              document
                .querySelector("[data-control='" + key + "']")
                .classList.add('cw-hidden');
            }
            this.errorFields = this._filterErrorFields(this.errorFields, key);
          } else {
            if (
              !document
                .querySelector("[name='" + key + "']")
                .parentElement.classList.contains('error')
            ) {
              document
                .querySelector("[name='" + key + "']")
                .parentElement.classList.add('error');
            }
            if (
              document
                .querySelector("[data-control='" + key + "']")
                .classList.contains('cw-hidden')
            ) {
              document
                .querySelector("[data-control='" + key + "']")
                .classList.remove('cw-hidden');
            }
            this.errorFields = this._addErrorFields(this.errorFields, key);
          }
        } else {
          if (key === 'answers') {
            this._validateAnswers(formElements, key);
          }
        }
      }
    }

    if (this.errorFields.length === 0) {
      this.setState({ showMessageAtTop: false, isSubmitEvent: false });
    } else {
      this.setState({ showMessageAtTop: true, isSubmitEvent: false });
      scrollToTop();
    }
  }

  _validateAnswers(formElements, key) {
    if (Array.isArray(formElements[key])) {
      formElements[key].map((answer, index) => {
        if (document.querySelector('[name=answer' + index + ']')) {
          if (answer.value || (!answer.value && answer.new)) {
            if (
              document
                .querySelector('[name=answer' + index + ']')
                .parentElement.classList.contains('error')
            ) {
              document
                .querySelector('[name=answer' + index + ']')
                .parentElement.classList.remove('error');
            }
            if (
              !document
                .querySelector('[data-control=answer' + index + ']')
                .classList.contains('cw-hidden')
            ) {
              document
                .querySelector('[data-control=answer' + index + ']')
                .classList.add('cw-hidden');
            }
            this.errorFields = this._filterErrorFields(
              this.errorFields,
              answer + index
            );
          } else {
            if (
              !document
                .querySelector('[name=answer' + index + ']')
                .parentElement.classList.contains('error')
            ) {
              document
                .querySelector('[name=answer' + index + ']')
                .parentElement.classList.add('error');
            }
            if (
              document
                .querySelector('[data-control=answer' + index + ']')
                .classList.contains('cw-hidden')
            ) {
              document
                .querySelector('[data-control=answer' + index + ']')
                .classList.remove('cw-hidden');
            }
            this.errorFields = this._addErrorFields(
              this.errorFields,
              answer + index
            );
          }
        }
        return null;
      });
    }
  }

  _validateTranslatedAnswers(formElements, key) {
    if (Array.isArray(formElements[key])) {
      formElements[key].map((answer, index) => {
        if (document.querySelector('[name=translatedanswer' + index + ']')) {
          if (answer.translatedtext) {
            if (
              document
                .querySelector('[name=translatedanswer' + index + ']')
                .parentElement.classList.contains('error')
            ) {
              document
                .querySelector('[name=translatedanswer' + index + ']')
                .parentElement.classList.remove('error');
            }
            if (
              !document
                .querySelector('[data-control=translatedanswer' + index + ']')
                .classList.contains('cw-hidden')
            ) {
              document
                .querySelector('[data-control=translatedanswer' + index + ']')
                .classList.add('cw-hidden');
            }
            this.errorFields = this._filterErrorFields(
              this.errorFields,
              answer + index
            );
          } else {
            if (
              !document
                .querySelector('[name=translatedanswer' + index + ']')
                .parentElement.classList.contains('error')
            ) {
              document
                .querySelector('[name=translatedanswer' + index + ']')
                .parentElement.classList.add('error');
            }
            if (
              document
                .querySelector('[data-control=translatedanswer' + index + ']')
                .classList.contains('cw-hidden')
            ) {
              document
                .querySelector('[data-control=translatedanswer' + index + ']')
                .classList.remove('cw-hidden');
            }
            this.errorFields = this._addErrorFields(
              this.errorFields,
              answer + index
            );
          }
        }
        return null;
      });
    }
  }

  _addErrorFields(array, itemToAdd) {
    return array.filter(field => field !== itemToAdd).concat([itemToAdd]);
  }

  _filterErrorFields(array, itemToRemove) {
    return array.filter(item => item !== itemToRemove);
  }

  _addAnswer() {
    this.setState(state => {
      let answers = [...state.question.answers, { value: '', label: '' }];
      let question = {
        ...state.question,
        ...{ answers: answers, answer: { value: '', label: '' } }
      };
      return { ...state, ...{ question } };
    });
  }

  _deleteAnswer(isdisabled, event) {
    if (isdisabled) {
      return;
    }
    let itemIndex = parseInt(event.target.parentElement.dataset.index);
    let answers = [...this.state.question.answers];
    let tempAnswers = [...this.state.answers];
    answers.splice(itemIndex, 1);
    tempAnswers.splice(itemIndex, 1);
    let questionNew = { ...this.state.question, ...{ answers } };
    this.setState({
      question: questionNew,
      answers: tempAnswers
    });
  }

  _handleQuestionType(selectedOption) {
    this.restrictFileUploadNumber();
    document.getElementById(`qtype`).classList.contains('cw-error-focus') &&
      document.getElementById(`qtype`).classList.remove('cw-error-focus');
    this.setState(state => {
      let questionType = {
        value: selectedOption.value,
        label: selectedOption.label
      };
      let answers = [
        // {
        //   value: "",
        //   label: "",
        //   new: false,
        // },
        // {
        //   value: "",
        //   label: "",
        //   new: false,
        // },
      ];
      let question = {
        value: '',
        label: ''
      };
      let updatedQuestion = {
        ...state.question,
        ...{
          question: question,
          qtype: questionType,
          type: selectedOption.label,
          answers: answers,
          new: false,
          translatedquestion: '',
          translatednew: false,
          text: '',
          id: ''
        }
      };
      if (
        selectedOption.value === 'scq' ||
        selectedOption.value === 'mcq' ||
        selectedOption.value === 'freetext'
      ) {
        if (
          state.question &&
          state.question.qtype &&
          state.question.qtype.value !== selectedOption.value
        ) {
          updatedQuestion = {
            ...updatedQuestion,
            reasonForCreation: '',
            new: false
          };
        }
      }
      return {
        ...state,
        ...{ question: updatedQuestion },
        ...{ answers: answers }
      };
    });
    if (this.quesanswerPanel.current.classList.contains('cw-hidden')) {
      this.quesanswerPanel.current.classList.remove('cw-hidden');
    }
    this._validateField('qtype');
  }

  _handleQuestionInputChange(event) {
    let targetValue = event.target.value;
    this.setState(state => {
      let ques = state.question;
      let question = {
        translatedId:
          targetValue !== this.state.question.translatedquestion
            ? ''
            : ques.translatedId,
        translatedquestion: targetValue,
        translatednew: ques.translatedId ? ques.new : true
      };
      let updatedQuestion = { ...state.question, ...question };
      return { ...state, ...{ question: updatedQuestion } };
    });
    this._validateField('question');
  }

  _handleQuestionInputOnBlur(event) {
    if (event.target.dataset && event.target.dataset.required === 'true') {
      this._validateField(event.target.name);
    }
  }

  _handleQuestion(selectedOption, actionMeta) {
    this.setState(state => {
      let question = {
        id:
          actionMeta && actionMeta.action === 'create-option'
            ? ''
            : selectedOption.value,
        text: selectedOption.label,
        question: { value: selectedOption.value, label: selectedOption.label },
        new: actionMeta && actionMeta.action === 'create-option',
        translatedquestion: '',
        translatednew: false
      };
      if (state.question && state.question.reasonForCreation && question.id) {
        question = {
          ...question,
          reasonForCreation: ''
        };
      }
      if (state.question && state.question.reasonForCreation === undefined) {
        question = {
          ...question,
          reasonForCreation: ''
        };
      }
      let updatedQuestion = { ...state.question, ...question };
      return {
        ...state,
        ...{ question: JSON.parse(JSON.stringify(updatedQuestion)) }
      };
    });

    this._getTranslations('question', selectedOption.value);
    this._validateField('question');
  }

  _handleUniqueEntryQuestion(text) {
    this.setState(state => {
      let question = {
        id: '',
        text: text,
        question: { value: text, label: text },
        new: false,
        translatedId: '',
        translatedquestion: text,
        translatednew: false
      };
      let updatedQuestion = { ...state.question, ...question };
      return { ...state, ...{ question: updatedQuestion } };
    });

    this._getTranslations('question', text);
    this._validateField('question');
  }

  _handleProfileQuestion(selectedOption, actionMeta) {
    this.setState(state => {
      let question = {
        id:
          actionMeta && actionMeta.action === 'create-option'
            ? ''
            : selectedOption.value,
        text: this.profileQuestions[selectedOption.value],
        questionType: {
          value: selectedOption.value,
          label: selectedOption.label
        },
        question: {
          value: this.profileQuestions[selectedOption.value],
          label: this.profileQuestions[selectedOption.value]
        },
        new: actionMeta && actionMeta.action === 'create-option',
        answers: [
          {
            label: this.profileAnswers[selectedOption.value],
            new: false,
            value: this.profileAnswers[selectedOption.value]
          }
        ]
      };
      let updatedQuestion = { ...state.question, ...question };
      return { ...state, ...{ question: updatedQuestion } };
    });

    this._validateField('question');
  }

  _handleAnswerInputChange(index, event) {
    let inputValue = event.target.value;
    this.setState(state => {
      let answers = state.question.answers;
      let tempAnswers = state.answers;

      answers.splice(index, 1, {
        value: answers[index]['value'],
        label: answers[index]['label'],
        new: answers[index]['new'],
        translatedvalue:
          inputValue !== this.state.question.answer.translatedanswer
            ? ''
            : answers[index]['translatedvalue'],
        translatedanswer: inputValue,
        translatednew: answers[index]['translatedvalue']
          ? answers[index]['new']
          : true
      });
      tempAnswers.splice(index, 1, {
        label: answers[index]['label'],
        value: answers[index]['value'],
        new: answers[index]['new'],
        translatedvalue:
          inputValue !== this.state.question.answer.translatedanswer
            ? ''
            : answers[index]['translatedvalue'],
        translatedanswer: inputValue,
        translatednew: answers[index]['translatedvalue']
          ? answers[index]['new']
          : true
      });
      let question = { ...state.question, ...{ answers } };

      return { ...state, ...{ question }, ...{ tempAnswers } };
    });
    this._validateField('answer' + index);
  }

  _handleAnswer(selectedOption, actionMeta) {
    this.setState(state => {
      let answers = state.question.answers;
      answers.splice(selectedOption.index, 1, {
        value:
          actionMeta && actionMeta.action === 'create-option'
            ? ''
            : selectedOption.value
            ? selectedOption.value
            : '-1',
        label: selectedOption.label,
        new: actionMeta && actionMeta.action === 'create-option'
      });

      let question = { ...state.question, ...{ answers } };

      return { ...state, ...{ question } };
    });

    this._getTranslations('answer', selectedOption.value, selectedOption.index);
    this._validateField('answer' + selectedOption.index);
  }

  _handleMaxAnswersAllowed(selectedOption) {
    this.setState(state => {
      let maxAnswers = {
        maxAnswers: selectedOption.value,
        maxAnswer: { value: selectedOption.value, label: selectedOption.label }
      };
      let question = { ...state.question, ...maxAnswers };
      return { ...state, ...{ question } };
    });
    this._validateField('maxanswers');
  }

  _handleAnswers(selectedOption, actionMeta) {
    this.addedAnswers.push({
      id: '',
      text: selectedOption.label
    });
  }

  _maxAnswersOptions = () => {
    let maxAnswersData = [];
    maxAnswersData.push({ value: 'No limit', label: 'No limit' });
    for (let i = 1; i <= 20; i++) {
      maxAnswersData.push({ value: i, label: i });
    }
    this.setState({
      maxAnswersData
    });
  };

  _getQuestions(input, callback) {
    let options = [];
    axios
      .get(constant.serviceUrls.SERVICE_URL.QUESTIONS + '/?text=' + input)
      .then(response => {
        options = response.data.map(question => ({
          value: question.id,
          label: question.text
        }));
        callback(options);
      });
  }

  async _getTranslations(type, id, index = '') {
    let { campaignLanguageCode } = this.props;
    let url =
      type === 'answer'
        ? constant.serviceUrls.SERVICE_URL.GET_TRANSLATED_ANSWERS
        : constant.serviceUrls.SERVICE_URL.GET_TRANSLATED_QUESTIONS;
    let response = await axios.get(
      url + '?id=' + id + '&lang=' + campaignLanguageCode
    );
    let el = 'translated' + type + (index >= 0 ? index : '');
    if (response && response.data.length) {
      let data = response.data[0];
      if (type === 'question') {
        this.setState(state => {
          let question = {
            translatedId: data && data.id ? data.id : '',
            translatedquestion: data && data.text ? data.text : '',
            translatednew: false
          };
          let updatedQuestion = { ...state.question, ...question };
          return { ...state, ...{ question: updatedQuestion } };
        });
      } else if (type === 'answer') {
        this.setState(state => {
          let answers = state.question.answers;
          answers.splice(index, 1, {
            value: answers[index]['value'],
            label: answers[index]['label'],
            translatedvalue: data && data.id ? data.id : '',
            translatedanswer: data && data.text ? data.text : '',
            translatednew: false,
            new: answers[index]['new']
          });
          let question = { ...state.question, ...{ answers } };

          return { ...state, ...{ question } };
        });
      }
      document.getElementById(el).value = data && data.text ? data.text : '';
      document.getElementById(el).disabled = false;
    } else {
      if (document.getElementById(el)) {
        document.getElementById(el).value = '';
        document.getElementById(el).disabled = false;
      }
    }
  }

  async _getAnswers(input, callback) {
    let options = [];
    if (!input) {
      return Promise.resolve(options);
    }
    let response = await axios.get(
      constant.serviceUrls.SERVICE_URL.ANSWERS + '/?text=' + input
    );
    options = response.data.map(question => ({
      value: question.id,
      label: question.text
    }));
    return Promise.resolve(options);
  }

  _handleCollapse(event, index) {
    event.preventDefault();
    if (this[`collapsePanel${index}`].classList.contains('show')) {
      this[`collapsePanel${index}`].classList.remove('show');
      this[`collapseHandlerIcon${index}`].classList.remove('fa-chevron-up');
      this[`collapseHandlerIcon${index}`].classList.add('fa-chevron-down');
    } else {
      this[`collapsePanel${index}`].classList.add('show');
      this[`collapseHandlerIcon${index}`].classList.remove('fa-chevron-down');
      this[`collapseHandlerIcon${index}`].classList.add('fa-chevron-up');
    }
  }

  _cancelEditQuestion(event) {
    let ques = {
      answer: {
        value: '-1',
        label: '',
        new: false
      },
      question: {
        value: '',
        label: ''
      },
      maxAnswer: {
        value: '',
        label: ''
      },
      qtype: {
        value: '',
        label: ''
      },
      answers: [
        {
          value: '',
          label: '',
          new: false
        },
        {
          value: '',
          label: '',
          new: false
        }
      ],
      qno: '',
      id: '',
      maxAnswers: '',
      text: '',
      type: '',
      new: false
    };

    this.setState(state => {
      return {
        ...state,
        ...{
          question: ques,
          editQuestion: false,
          editQuestionIndex: '',
          isIconDisabled: false
        }
      };
    });
    if (!this.quesanswerPanel.current.classList.contains('cw-hidden')) {
      this.quesanswerPanel.current.classList.add('cw-hidden');
    }

    if (!this.quesanswerCancelbtn.current.classList.contains('cw-hidden')) {
      this.quesanswerCancelbtn.current.classList.add('cw-hidden');
    }

    if (this.props.addedQuestions && this.props.addedQuestions.length) {
      this.props.addedQuestions.forEach(ele => {
        if (ele && ele.type === 'Unique entry code') {
          this.uniqueEntryTypeCount += 1;
        }
      });
    }
  }

  _editQuestion(event, index, question) {
    let isIncentive = this.props.campaignType === 'Incentive';
    let isAEMType =
      this.props.websiteType === 'Adobe Classic' ||
      this.props.websiteType === 'Adobe Templated' ||
      this.props.websiteType === 'Standalone';
    let isPromotigoType = isIncentive && isAEMType;
    let qtype;
    isPromotigoType
      ? (qtype = this.questionsPromotigo.filter(ques => {
          return ques.label.toLowerCase() === question.type.toLowerCase();
        }))
      : (qtype = this.questions.filter(ques => {
          return ques.label.toLowerCase() === question.type.toLowerCase();
        }));
    question['answer'] = {
      value: '-1',
      label: '',
      new: false
    };

    if (qtype[0]['value'] === 'scq' || qtype[0]['value'] === 'mcq') {
      if (question.answers.length == 1) {
        question['answers'].push({
          value: '',
          label: '',
          new: false
        });
      }
    }
    if (qtype[0]['value'] === 'fileupload' && this.fileUploadTypeCount === 1) {
      this.fileUploadTypeCount = 0;
    }

    if (qtype[0]['value'] === 'uniqueentry' && this.uniqueEntryTypeCount >= 1) {
      this.uniqueEntryTypeCount -= 1;
    }
    question['qtype'] = qtype[0];
    question['question'] = {
      value: question.id,
      label: question.text
    };

    if (this.quesanswerCancelbtn.current.classList.contains('cw-hidden')) {
      this.quesanswerCancelbtn.current.classList.remove('cw-hidden');
    }

    if (this.quesanswerPanel.current.classList.contains('cw-hidden')) {
      this.quesanswerPanel.current.classList.remove('cw-hidden');
    }

    this.setState(state => {
      return {
        ...state,
        ...{
          answers: question.answers,
          question: question,
          editQuestion: true,
          editQuestionIndex: index,
          isIconDisabled: true
        }
      };
    });
    scrollToTop();
  }
  _cancelApproverEdit() {
    this.props.setApproverEditCampaignStatus(false);
    this.props.setApproverEditCampaignStatusMessage('');
    this.props.history.push(
      '/campaign-wizard/campaign/' + this.campaignId + '/approval'
    );
  }

  _showNonEnglishCampaignSubHeadingText = campaignLanguage => {
    return (
      <>
        All questions and answers must be written in <b>{campaignLanguage}</b>.
        <br />
        <br />
        On submitting the campaign please email your questions and answers in{' '}
        <b>English</b> to the consumer data bureau team. Email details will be
        provided on campaign submission.
      </>
    );
  };

  restrictFileUploadNumber = () => {
    this.props.addedQuestions.map(elem => {
      if (elem.type === 'File upload') {
        this.fileUploadTypeCount = this.fileUploadTypeCount + 1;
      }
    });
  };

  _handleCheckboxConfirm(event) {
    let isChecked = event.target.checked;
    if (isChecked) {
      this.setState({ iConfirmQnA: true });
    } else {
      this.setState({ iConfirmQnA: false });
    }
  }

  isOpenGuidelineModel = event => {
    event.preventDefault();
    this.setState({ closeGuideModal: true });
  };

  isCloseGuidelineModel = event => {
    event.preventDefault();
    this.setState({ closeGuideModal: false });
  };

  getNewQueCallback = (newQue, index, isNewQue) => {
    //New question will be added from question overlay
    let questionObj = { ...this.state.question };
    if (isNewQue) {
      questionObj = {
        ...questionObj,
        text: newQue.englishLabel ? newQue.englishLabel : '',
        id: newQue.id ? newQue.id : '',
        new: true,
        translatednew: newQue.istranslatedNew ? true : false,
        question: { value: newQue.id, label: newQue.englishLabel },
        translatedquestion: newQue.translatedLabel
          ? newQue.translatedLabel
          : '',
        reasonForCreation: newQue.reasonForCreation
      };
    } else {
      questionObj = {
        ...questionObj,
        text: newQue.text ? newQue.text : '',
        id: newQue.id ? newQue.id : '',
        question: { value: newQue.id, label: newQue.text },
        translatedquestion: newQue.translatedText ? newQue.translatedText : '',
        new: false,
        translatedId: newQue.translatedId ? newQue.translatedId : '',
        translatednew: newQue.istranslatedNew
          ? newQue.translatedId
            ? false
            : true
          : false,
        reasonForCreation: newQue.reasonForCreation
      };
    }
    this.setState({
      question: questionObj
    });
  };

  getNewAnsCallback = (newAns, index, isNewAns) => {
    let questionObj = { ...this.state.question };
    let selectedAns =
      questionObj.answers && questionObj.answers.length > 0
        ? questionObj.answers
        : [];
    if (isNewAns) {
      let newObj = {
        label: newAns.englishLabelAns,
        value: '',
        new: true,
        translatedvalue: '',
        translatedanswer: newAns.translatedLabelAns
          ? newAns.translatedLabelAns
          : '',
        translatednew: newAns.translatedLabelAns ? true : false
      };
      selectedAns.push(newObj);
    } else {
      for (let ele of newAns) {
        let newObje = {
          label: ele.text,
          value: ele.id,
          new: false,
          translatedanswer: ele.translatedText ? ele.translatedText : '',
          translatedvalue: ele.translatedId ? ele.translatedId : '',
          translatednew: ele.translatedText
            ? ele.translatedId
              ? false
              : true
            : false
        };
        if (selectedAns && selectedAns.length) {
          let findindex = selectedAns.findIndex(e => e.value === newObje.value);
          if (findindex > -1) {
            selectedAns[findindex] = newObje;
          } else {
            selectedAns.push(newObje);
          }
        } else {
          selectedAns.push(newObje);
        }
      }
    }
    questionObj = {
      ...questionObj,
      answers: selectedAns
    };
    this.setState({
      question: questionObj,
      answers: selectedAns
    });
  };
  handleSelectData = selectedOption => {
    document.getElementById(`ReasonForMasterdata`).classList.add('d-none');
    document
      .getElementById(`reason_for_masterdata`)
      .classList.contains('cw-error-focus') &&
      document
        .getElementById(`reason_for_masterdata`)
        .classList.remove('cw-error-focus');
    let questionObj = { ...this.state.question };
    questionObj = {
      ...questionObj,
      reasonForCreation: selectedOption.value
    };
    this.setState({
      question: questionObj
    });
    // let newQuestion = [...question];
    // newQuestion[index].reasonForCreation = selectedOption.value;
    // addQuestion(newQuestion);
  };
  disableEnter = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };
  _handleRefresh = () => {
    let { campaign } = this.props;
    axios
      .post(constants.serviceUrls.CAMPAIGN_INFRA_API_CALL, campaign)
      .then(response => {
        this.campaignId = this.props.match.params.id;
        this.props.getCampaignDetails(this.campaignId);
      });
  };

  render() {
    let isFileUploadTypeRestricted =
      this.fileUploadTypeCount >= 1 ? true : false;
    let isUniqueEntryTypeRestricted =
      this.uniqueEntryTypeCount >= 1 ? true : false;
    let isIncentive = this.props.campaignType === 'Incentive' ? true : false;
    let isSamples =
      this.props.incentiveType === 'Product samples' ? true : false;
    let isCompetitions =
      this.props.incentiveType === 'Competitions' ? true : false;
    let isAEMType =
      this.props.websiteType === 'Adobe Classic' ||
      this.props.websiteType === 'Adobe Templated' ||
      this.props.websiteType === 'Standalone';
    let isPromotigoType = isIncentive && isAEMType ? true : false;
    let isPromotigoTypeCompetition =
      isIncentive && isAEMType && isCompetitions ? true : false;

    let isPromotigoTypeSample =
      isIncentive && isAEMType && isSamples ? true : false;

    let profileQuestionsTypeCompetition = [
      { value: 'Gender', label: 'Gender' },
      { value: 'Address', label: 'Address' },
      { value: 'Dob', label: 'Date of birth' }
    ];

    let profileQuestionsTypeSample = [
      { value: 'Gender', label: 'Gender' },
      { value: 'Dob', label: 'Date of birth' }
    ];

    let {
      addedQuestions,
      answers,
      isLanguageEnglish,
      campaignType
    } = this.props;
    let nonenglishCampaign = false;
    let formclass = classNames({
      'ml-4': !isLanguageEnglish,
      'cw-subCategory': !isLanguageEnglish
    });

    let translatedField = classNames({
      'cw-hidden': isLanguageEnglish
    });

    let { question } = this.state;
    let qnaTileData = fetchQnANotificationTileData(
      null,
      this.props.addedQuestions
    );

    let answersControls = [];
    let qualifyingQuestionSection = (
      <>
        <ReactTooltip
          place="right"
          type="info"
          multiline={true}
          className="cw-toolip"
        />
        <div className="form-group d-flex ">
          <label className="switch">
            <input
              type="checkbox"
              name="isQualifying"
              checked={
                this.state.question && this.state.question.isQualifying
                  ? true
                  : false
              }
              onChange={this.isQuesQualifying}
            />
            <div className="slider round"></div>
          </label>
          <label className=" ml-20" htmlFor="isQualifying">
            Qualifying question &nbsp;
            <span
              className="float-right  cw-icon cw-icon--help"
              data-tip="You can select maximum 5 questions as Qualifying question"
            >
              <i className="fa fa-question-circle"></i>
            </span>
          </label>
        </div>
      </>
    );
    if (question.answers && question.answers.length) {
      let isDisabled = question.answers.length <= 2 ? true : false;

      answersControls = question.answers.map((answer, index) => {
        return (
          <div
            key={'add_ansswer_' + index}
            onKeyDown={e => this.disableEnter(e)}
          >
            <div className="form-group">
              <label htmlFor="name">
                <span>
                  Answer {index + 1} {!nonenglishCampaign ? '(English)' : ''}
                </span>
              </label>
              <div className="position-relative">
                <AsyncDropDown
                  className={'cw-select--custom'}
                  name={'answer' + index}
                  label={'Answer' + index}
                  placeholder={'Search for answer'}
                  loadOptions={this._getAnswers}
                  defaultOptions={this.state.defaultAnswers}
                  onChange={(val, actionMeta) => {
                    this._handleAnswer(
                      { index: index, value: val.value, label: val.label },
                      actionMeta
                    );
                  }}
                  value={
                    answer.label !== ''
                      ? {
                          label: answer.label,
                          value: answer.value
                        }
                      : null
                  }
                />
                <span
                  className="cw-error cw-hidden"
                  data-control={'answer' + index}
                >
                  <i className="fas fa-exclamation-triangle mr-10"></i>Please
                  select answer.
                </span>

                {/* {index === 0 ? (
                <span
                  className="cw-icon cw-icon--addAnswer"
                  onClick={this._addAnswer}
                >
                  <i className="fas fa-plus-circle"></i>
                </span>
              ) : null} */}
                {index !== 0 ? (
                  <span
                    className="cw-icon cw-icon--deleteAnswer"
                    data-index={index}
                    onClick={e => this._deleteAnswer(isDisabled, e)}
                  >
                    <i
                      className={`fas ${
                        isDisabled ? ' fa-disabled ' : ''
                      } fa-trash-alt`}
                    ></i>
                  </span>
                ) : null}

                {answer.new ? (
                  <span className="cw-new badge badge-warning">New</span>
                ) : null}
              </div>
            </div>
            <div className={`form-group ${translatedField}`}>
              <label htmlFor="name">
                <span>
                  Answer {index + 1}
                  {!nonenglishCampaign
                    ? ' (' + this.props.campaignLanguage + ' translation)'
                    : ''}
                </span>
              </label>
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  name={'translatedanswer' + index}
                  id={'translatedanswer' + index}
                  ref={'translatedanswer' + index}
                  label={'translatedAnswer' + index}
                  data-required="true"
                  data-index={index}
                  aria-describedby="translatedanswer"
                  placeholder={`No translation found, please enter in ${this.props.campaignLanguage} translation`}
                  value={
                    (this.state.answers && this.state.answers[index]
                      ? this.state.answers[index].translatedanswer
                      : '') || ''
                  }
                  onChange={event =>
                    this._handleAnswerInputChange(index, event)
                  }
                  onBlur={this._handleAnswerInputOnBlur}
                />

                {answer.translatednew ? (
                  <span className="cw-new badge badge-warning">New</span>
                ) : null}
              </div>
            </div>
          </div>
        );
      });
    }

    Modal.setAppElement('#root');
    let notificationclass = this.props.addedQuestions.length;

    return (
      <div className="col-sm-12">
        <ReactTooltip
          place="right"
          type="info"
          multiline={true}
          className="cw-toolip"
        />
        <div className="cw-section ml-20">
          <BreadCrumb
            match={this.state.matchProps}
            editCampaign={
              this.props.editCampaign || this.props.isCampaignEdited
            }
            hideBreadCrumb={this.props.approverEditCampaign}
            websiteType={this.props.websiteType}
            campaignType={campaignType}
          />

          <Alert
            type={'warning'}
            alertType={'isMobileSelected'}
            showComponent={
              !this.props.smsChannel &&
              this.props.showSMSChannel &&
              this.state.isMobile
                ? true
                : false
            }
          />
          {this.state.showMessageAtTop ? <ErrorNotification /> : null}
          {!this.props.addedQuestions.length && this.state.isSubmitEvent ? (
            <div className="cw-section">
              <div className="row">
                <div className="alert alert-danger col-sm-12">
                  <p>Please select at least one question.</p>
                </div>
              </div>
            </div>
          ) : null}

          {this.state.maxFileSizeLimitExceeded ? (
            <div className="cw-section">
              <div className="row">
                <div className="alert alert-danger col-sm-12">
                  <p>File size exceeding the limit of 10000 KB</p>
                </div>
              </div>
            </div>
          ) : null}

          {this.restrictQualifying || this.props.qualifyingCount > 5 ? (
            <div className="cw-section">
              <div className="row">
                <div className="alert alert-danger col-sm-12">
                  <p>
                    You can select maximum 5 questions as Qualifying question
                  </p>
                </div>
              </div>
            </div>
          ) : null}
          {question.qtype.value === 'fileupload' &&
          isFileUploadTypeRestricted ? (
            <div className="cw-section">
              <div className="row">
                <div className="alert alert-danger col-sm-12">
                  <p>Only one file upload question can be added</p>
                </div>
              </div>
            </div>
          ) : null}
          {question.qtype.value === 'uniqueentry' &&
          isUniqueEntryTypeRestricted ? (
            <div className="cw-section">
              <div className="row">
                <div className="alert alert-danger col-sm-12">
                  <p>Only one unique entry code question can be added</p>
                </div>
              </div>
            </div>
          ) : null}
          <h2 className="cw-heading--secondary mb-20">
            Configure Questions and Answers
          </h2>

          <div className="row">
            <div className="col-sm-7 cw-section--title mb-20">
              <p className="mb-30">
                <QnANonSaasSubheadingText show={true} />
              </p>
              <p className="mb-10">
                Q&A language: <b>{this.props.campaignLanguage}</b>{' '}
              </p>
              <p className="cw-heading--tertiary mb-10">Add questions</p>
              {/* <div className="alert alert-danger col-sm-12">
                <p>{constant.SAAS_MESSAGES.nonSaasPiWarning}</p>
              </div> */}
              <div className="cw-section--addQuestions cw-bg--primary mb-30">
                <form className="cw-form cw-form--bg" id="questions">
                  {/* Question Type */}
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">
                      <span>Question Type</span>
                      <span className="float-right  cw-icon cw-icon--help">
                        <i
                          className="fa fa-info-circle"
                          onClick={this._openModal}
                        ></i>
                      </span>
                    </label>

                    <div id={'qtype'}>
                      <Select
                        className={'cw-select--custom'}
                        name={'qtype'}
                        label={'Question Type'}
                        placeholder={'Select option'}
                        options={
                          isIncentive && isAEMType
                            ? this.questionsPromotigo
                            : this.questions
                        }
                        onChange={this._handleQuestionType}
                        value={
                          question.qtype && question.qtype.label !== ''
                            ? {
                                label: question.qtype.label,
                                value: question.qtype.value
                              }
                            : null
                        }
                      />
                    </div>
                    <span className="cw-error cw-hidden" data-control="qtype">
                      <i className="fas fa-exclamation-triangle mr-10"></i>
                      Please select question type.
                    </span>
                  </div>

                  <div
                    className="cw-panel--addquestion cw-hidden"
                    ref={this.quesanswerPanel}
                    onKeyDown={e => this.disableEnter(e)}
                  >
                    {/* Question */}
                    {question.qtype.value === 'profileque' ? (
                      <div className="form-group">
                        <label htmlFor="name">
                          <span>Profile question type</span>
                        </label>
                        {isPromotigoTypeCompetition ? (
                          <Select
                            className={'cw-select--custom'}
                            name={'question'}
                            label={'Question'}
                            placeholder={'Select option'}
                            options={profileQuestionsTypeCompetition}
                            onChange={this._handleProfileQuestion}
                            value={
                              question.questionType &&
                              question.questionType.label !== ''
                                ? {
                                    label: question.questionType.label,
                                    value: question.questionType.value
                                  }
                                : null
                            }
                          />
                        ) : null}
                        {isPromotigoTypeSample ? (
                          <Select
                            className={'cw-select--custom'}
                            name={'question'}
                            label={'Question'}
                            placeholder={'Select option'}
                            options={profileQuestionsTypeSample}
                            onChange={this._handleProfileQuestion}
                            value={
                              question.questionType &&
                              question.questionType.label !== ''
                                ? {
                                    label: question.questionType.label,
                                    value: question.questionType.value
                                  }
                                : null
                            }
                          />
                        ) : null}
                        {!isPromotigoTypeCompetition &&
                        !isPromotigoTypeSample ? (
                          <Select
                            className={'cw-select--custom'}
                            name={'question'}
                            label={'Question'}
                            placeholder={'Select option'}
                            options={this.profileQuestionsType}
                            onChange={this._handleProfileQuestion}
                            value={
                              question.questionType &&
                              question.questionType.label !== ''
                                ? {
                                    label: question.questionType.label,
                                    value: question.questionType.value
                                  }
                                : null
                            }
                          />
                        ) : null}

                        <span
                          className="cw-error cw-hidden"
                          data-control="question"
                        >
                          <i className="fas fa-exclamation-triangle mr-10"></i>
                          Please select question.
                        </span>
                      </div>
                    ) : null}
                    {question.qtype.value === 'mcq' ||
                    question.qtype.value === 'scq' ||
                    question.qtype.value === 'freetext' ? (
                      <>
                        {question.qtype.value === 'freetext' && (
                          <div className="custon-alert-msg custon-alert-non-saas">
                            <Alert
                              alertType="warningMessageWithIcon"
                              showComponent={true}
                              textMessage={constants.FREE_TEXT_WARNING}
                            />
                          </div>
                        )}
                        <div className="mb-20">
                          <label htmlFor="name">
                            <span>Question</span>
                          </label>
                          <QuestionAnswerNewOverlay
                            language={this.props.campaignLanguageCode}
                            campaignLanguage={this.props.campaignLanguage}
                            getNewQueCallback={this.getNewQueCallback}
                            //rootIndex = {index}
                            browseType="Questions"
                            isWebsiteTypeNonSaaS={true}
                          />{' '}
                        </div>
                      </>
                    ) : null}
                    {(question.question.label !== '' &&
                      (question.qtype.value === 'mcq' ||
                        question.qtype.value === 'scq' ||
                        question.qtype.value === 'freetext') &&
                      question.text !== '') ||
                    (question.qtype.value === 'uniqueentry' &&
                      !isUniqueEntryTypeRestricted) ? (
                      <div>
                        <div className="form-group">
                          <label htmlFor="name">
                            <span>
                              {/* Question {!isLanguageEnglish ? "(English)" : ""} */}
                              Question label
                            </span>
                          </label>
                          <div className="position-relative">
                            {question.qtype.value === 'uniqueentry' ? (
                              <>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter a question"
                                  value={
                                    question.question &&
                                    question.question.label !== ''
                                      ? question.question.label
                                      : null
                                  }
                                  onChange={e =>
                                    this._handleUniqueEntryQuestion(
                                      e.target.value
                                    )
                                  }
                                />
                              </>
                            ) : (
                              <>
                                <AsyncDropDown
                                  className={'cw-select--custom'}
                                  name={'question'}
                                  label={'Question'}
                                  placeholder={'Search for question'}
                                  minimumInput={3}
                                  loadOptions={this._getQuestions}
                                  defaultOptions={this.props.questions}
                                  onChange={this._handleQuestion}
                                  isLoading={this.props.fetchingQuestions}
                                  value={
                                    question.question &&
                                    question.question.label !== ''
                                      ? {
                                          label: question.question.label,
                                          value: question.question.value
                                        }
                                      : null
                                  }
                                />
                              </>
                            )}
                            <span
                              className="cw-error cw-hidden"
                              data-control="question"
                            >
                              <i className="fas fa-exclamation-triangle mr-10"></i>
                              Please select question.
                            </span>

                            {question.new ? (
                              <span className="cw-new badge badge-warning">
                                New
                              </span>
                            ) : null}
                          </div>
                        </div>

                        {question.qtype.value !== 'uniqueentry' ? (
                          <div
                            className={`form-group ${translatedField}`}
                            onKeyDown={e => this.disableEnter(e)}
                          >
                            <label htmlFor="name">
                              <span>
                                Question{' '}
                                {showCampaignLanguage(
                                  this.props.campaignLanguage
                                )}
                              </span>
                            </label>
                            <div className="position-relative">
                              <input
                                type="text"
                                className="form-control"
                                name="translatedquestion"
                                id="translatedquestion"
                                ref="translatedquestion"
                                data-required="true"
                                aria-describedby="translatedquestion"
                                placeholder={`No translation found, please enter in ${this.props.campaignLanguage} translation`}
                                value={
                                  (this.state.question &&
                                    this.state.question.translatedquestion) ||
                                  ''
                                }
                                onChange={this._handleQuestionInputChange}
                                onBlur={this._handleQuestionInputOnBlur}
                              />

                              <span
                                className="cw-error cw-hidden"
                                data-control="question"
                              >
                                <i className="fas fa-exclamation-triangle mr-10"></i>
                                Please add question.
                              </span>
                              {question.translatednew ? (
                                <span className="cw-new badge badge-warning">
                                  New
                                </span>
                              ) : null}
                            </div>
                          </div>
                        ) : null}
                      </div>
                    ) : null}
                    {(question.qtype.value === 'scq' ||
                      question.qtype.value === 'mcq' ||
                      question.qtype.value === 'freetext') &&
                    (question.reasonForCreation || question.new) ? (
                      <>
                        <div className="form-group mb-10">
                          <label for="questionLabel_2">
                            <span>Reason for new master data request</span>
                            <ReactTooltip
                              id="new_master"
                              place="right"
                              type="info"
                              multiline={true}
                              className="cw-toolip custom-cw-toolip"
                              style={{ width: '70% !important' }}
                            />
                            <span
                              class="float-right  cw-icon cw-icon--help"
                              data-for="new_master"
                              data-tip="Mark your question request as “enrichment” if this data will help Unilever improve its understanding of a consumer to enrich or segment audience such as consumer interests, needs, preferences, usage of products, their benefits, etc. <br/> Else, mark your question as “operational” if it intends to help ensure the campaign works or to fulfill consumer requests, such as receipts, purchase date, SKUs, etc."
                              currentitem="false"
                            >
                              <i class="fas fa-question-circle"></i>
                            </span>
                          </label>
                          <div
                            className="form-group mt-10 mr-3 w-100 saas-profile-select saas-profile-select--qna-new-design mb-20"
                            id="add-questionField"
                          >
                            <Select
                              id={`reason_for_masterdata`}
                              className={
                                'cw-select--custom light-placeholder cw-color--primary'
                              }
                              placeholder={'Reason for new request'}
                              options={this.state.questionTypesSelect}
                              onChange={e => this.handleSelectData(e)}
                              value={
                                question.reasonForCreation
                                  ? this.state.questionTypesSelect.find(
                                      e =>
                                        e.value === question.reasonForCreation
                                    )
                                  : null
                              }
                            />
                          </div>
                          <span
                            id={`ReasonForMasterdata`}
                            class="cw-error d-none"
                            data-control="name"
                          >
                            <i class="fas fa-exclamation-triangle mr-10"></i>
                            Please select reason for new master data request
                          </span>
                        </div>
                      </>
                    ) : null}
                    {question.qtype.value === 'profileque' &&
                    question.questionType.value ? (
                      <>
                        <div className="form-group">
                          <label htmlFor="name">
                            <span>Question</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="question"
                            id="question"
                            ref="Question"
                            aria-describedby="Question"
                            disabled={true}
                            value={
                              this.profileQuestions[question.questionType.value]
                            }
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="name">
                            <span>Answer</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="answer0"
                            id="Answer"
                            ref="Answer"
                            aria-describedby="Answer"
                            disabled={true}
                            value={
                              this.profileAnswers[question.questionType.value]
                            }
                          />
                        </div>
                      </>
                    ) : null}
                    {question.qtype.value === 'fileupload' ? (
                      <>
                        <div className="form-group">
                          <label htmlFor="name">
                            <span>Question</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="fileUploadQuestion"
                            id="fileUploadQuestion"
                            ref="fileUploadQuestion"
                            aria-describedby="fileUploadQuestion"
                            disabled={isFileUploadTypeRestricted}
                            value={this.state.question.text}
                            onChange={this.handleFileUploadQuestion}
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="name">
                            <span>Default display text</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="defaultDisplayText"
                            id="defaultDisplayText"
                            ref="defaultDisplayText"
                            aria-describedby="defaultDisplayText"
                            disabled={isFileUploadTypeRestricted}
                            value={this.state.question.defaultDisplayText}
                            onChange={this.handleDefaultDisplayText}
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="name">
                            <span>Placeholder text</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="placeholderText"
                            id="placeholderText"
                            ref="Answer"
                            aria-describedby="placeholderText"
                            disabled={isFileUploadTypeRestricted}
                            value={this.state.question.placeholderText}
                            onChange={this.handlePlaceholderText}
                          />
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="isRestrictionAllowed"
                            id="isRestrictionAllowed"
                            ref="isRestrictionAllowed"
                            data-required="false"
                            disabled={isFileUploadTypeRestricted}
                            checked={
                              this.state.question &&
                              this.state.question.isRestrictionAllowed
                                ? true
                                : false
                            }
                            onChange={this.isRestrictionAllowed}
                          />
                          <label
                            className="form-check-label d-block mb-20"
                            htmlFor="isRestrictionAllowed"
                          >
                            <span>Set file upload restrictions</span>
                          </label>
                        </div>
                        {this.state.question.isRestrictionAllowed ? (
                          <div className="form-group">
                            <ReactTooltip
                              place="right"
                              type="info"
                              multiline={true}
                              className="cw-toolip"
                            />
                            <label htmlFor="filesizerestriction">
                              <span>Max file size in KB</span>
                              <span
                                className="float-right  cw-icon cw-icon--help"
                                data-tip="Max limit 10000 KB"
                              >
                                <i className="fa fa-question-circle"></i>
                              </span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="filesizerestriction"
                              id="filesizerestriction"
                              ref="filesizerestriction"
                              aria-describedby="filesizerestriction"
                              disabled={isFileUploadTypeRestricted}
                              value={this.state.question.maxFileSize}
                              onChange={this.maxFileSizeValidation}
                              onKeyPress={this.maxFileSizeValidation}
                            />
                          </div>
                        ) : null}
                      </>
                    ) : null}
                    {question.qtype.value === 'mcq' ||
                    question.qtype.value === 'scq' ? (
                      <div className="mb-20">
                        <label htmlFor="name">
                          <span>
                            Answer (English) - “Please select atleast two
                            answers”
                          </span>
                        </label>
                        <QuestionAnswerNewOverlay
                          language={this.props.campaignLanguageCode}
                          campaignLanguage={this.props.campaignLanguage}
                          getNewAnsCallback={this.getNewAnsCallback}
                          //rootIndex = {index}
                          browseType="Answers"
                          isWebsiteTypeNonSaaS={true}
                        />
                      </div>
                    ) : null}
                    {question.qtype.value !== 'freetext' &&
                    question.qtype.value !== 'profileque' &&
                    question.qtype.value !== 'uniqueentry' &&
                    question.qtype.value !== 'fileupload'
                      ? answersControls
                      : null}

                    {question.qtype.value === 'mcq' ? (
                      <>
                        <ReactTooltip
                          place="right"
                          type="info"
                          multiline={true}
                          className="cw-toolip"
                        />
                        <div className="form-group">
                          <label htmlFor="name">
                            <span>
                              Maximum number of answers user can select
                            </span>
                            <span
                              className="float-right  cw-icon cw-icon--help"
                              data-tip="Limit the number of answers the user can select this question"
                            >
                              <i className="fa fa-question-circle"></i>
                            </span>
                          </label>
                          <Select
                            className={'cw-select--custom'}
                            name={'maxanswers'}
                            label={'MaxAnswers'}
                            placeholder={'Select option'}
                            options={this.state.maxAnswersData}
                            onChange={this._handleMaxAnswersAllowed}
                            value={
                              question.maxAnswer &&
                              question.maxAnswer.label !== ''
                                ? {
                                    label: question.maxAnswer.label,
                                    value: question.maxAnswer.value
                                  }
                                : {
                                    label: 'No limit',
                                    value: ''
                                  }
                            }
                          />
                          <span
                            className="cw-error cw-hidden"
                            data-control="maxanswers"
                          >
                            <i className="fas fa-exclamation-triangle mr-10"></i>
                            Please select maximum answers.
                          </span>
                        </div>
                      </>
                    ) : null}

                    <div className="form-group d-flex ">
                      <label className="switch">
                        <input
                          type="checkbox"
                          name="quesMandatory"
                          checked={
                            this.state.question && this.state.question.mandatory
                              ? true
                              : false
                          }
                          onChange={this.isQuesMandatory}
                        />
                        <div className="slider round"></div>
                      </label>
                      <label className=" ml-20" htmlFor="quesMandatory">
                        Mandatory question
                      </label>
                    </div>

                    {isPromotigoType &&
                    (question.qtype.value === 'scq' ||
                      question.qtype.value === 'freetext')
                      ? qualifyingQuestionSection
                      : null}
                    <div className="mt-4">
                      <button
                        type="button"
                        className="btn btn-outline-primary"
                        onClick={this._addQuestion}
                        disabled={
                          (question.qtype.value === 'uniqueentry' &&
                            isUniqueEntryTypeRestricted) ||
                          ((question.qtype.value === 'scq' ||
                            question.qtype.value === 'mcq' ||
                            question.qtype.value === 'freetext') &&
                            question.new &&
                            (question.reasonForCreation === '' ||
                              question.reasonForCreation === undefined)) ||
                            ((question.qtype.value === 'scq' ||
                              question.qtype.value === 'mcq') &&
                              this.state.question.answers.length < 2) ||
                          !this.state.isValidQuestion ||
                            (this.state.question.isQualifying &&
                              this.qualifyingCount > 5) ||
                            (this.state.question.isQualifying &&
                              this.addedQualifyingQuesCount > 5)
                        }
                      >
                        Add question
                      </button>

                      <a
                        href="#"
                        ref={this.quesanswerCancelbtn}
                        className="cw-ques-cancel ml-3 cw-hidden"
                        onClick={this._cancelEditQuestion}
                      >
                        Cancel edit
                      </a>
                    </div>
                  </div>
                </form>
              </div>
              <div className="cw-section--content">
                {this.props.campaignDetailsLoaded ? (
                  <div>
                    <p className="cw-heading--tertiary mb-10">
                      <span>Your questions </span>
                      <span className="js-questions--count">
                        {this.props.addedQuestions &&
                          (this.props.smsChannel
                            ? this.props.addedQuestions.filter(
                                nonMobQuestion => nonMobQuestion.id !== 'Mobile'
                              ).length
                            : this.props.addedQuestions.length)}
                      </span>
                    </p>
                    <p className="mb-30 w-80">
                      Edit or remove any questions listed below.
                      {this.props.addedQuestions &&
                      this.props.addedQuestions.length >= 2
                        ? 'You can re-order the questions on the next page'
                        : null}
                    </p>
                    <AddedQuestionsList
                      addedQuestions={
                        this.props.smsChannel
                          ? this.props.addedQuestions.filter(
                              mobileQues => mobileQues.id !== 'Mobile'
                            )
                          : this.props.addedQuestions
                      }
                      isIconDisabled={this.state.isIconDisabled}
                      editQuestionIndex={this.state.editQuestionIndex}
                      onClickEditQuestions={this._editQuestion}
                      isLanguageEnglish={isLanguageEnglish}
                      campaignLanguage={this.props.campaignLanguage}
                      onClickDeleteQuestions={this._handleQuestionDeletion}
                      isPromotigoType={isPromotigoType}
                    />
                    {qnaTileData &&
                    qnaTileData.showQnATile &&
                    qnaTileData.enrichmentQues > 0 ? (
                      <SummaryComponent
                        data={this.props.campaign}
                        isBrandSummary={false}
                        isQnASummary={true}
                        qnaTileData={qnaTileData}
                        isQnaPage={true}
                        handleRefresh={this._handleRefresh}
                      />
                    ) : null}
                    <br />
                    <ReactTooltip
                      id="IConfirmToolTip"
                      place="right"
                      type="info"
                      multiline={true}
                      className="cw-tooltip"
                    />
                    <div
                      className={`form-check mb-30 ${
                        constant.featureFlags.ENABLE_QNA_GUIDELINES_CHECKBOX
                          ? ''
                          : 'cw-hidden'
                      }`}
                      style={{ marginTop: '-1%' }}
                    >
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value={this.state.iConfirmQnA}
                        id="iconfirm"
                        name="iconfirm"
                        checked={this.state.iConfirmQnA}
                        onChange={this._handleCheckboxConfirm}
                      />
                      <label className="form-check-label" htmlFor="iconfirm">
                        <ReactTooltip
                          id="IConfirmToolTippll"
                          place="right"
                          type="info"
                          multiline={true}
                          className="cw-tooltip"
                        />
                        I confirm I have not requested a&nbsp;
                        <a
                          href="#"
                          data-for="IConfirmToolTippll"
                          data-tip={constants.PII_FIELDS_DESCRIPTION}
                        >
                          PII question
                        </a>
                        &nbsp;or a duplicate question worded differently,as
                        indicated in the{' '}
                        <a href="#" onClick={this.isOpenGuidelineModel}>
                          guidelines
                        </a>
                        .
                      </label>
                    </div>
                    <div className="cw-form--action-cta mt-40">
                      {this.props.approverEditCampaign ? (
                        this.props.addedQuestions.length >= 2 ? (
                          <button
                            type="submit"
                            className="btn btn-primary"
                            onClick={this._handleSubmit}
                            disabled={!this.state.iConfirmQnA}
                          >
                            Next
                          </button>
                        ) : (
                          <>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={this._handleSubmit}
                            >
                              Save
                            </button>
                            <a
                              href="#"
                              className="cw-save-to-draft mt-25"
                              onClick={this._cancelApproverEdit}
                            >
                              Cancel
                            </a>
                          </>
                        )
                      ) : (
                        <>
                          <button
                            type="button"
                            className="btn btn btn-outline-secondary mr-3"
                            onClick={this._handlebackButton}
                          >
                            Back
                          </button>
                          <button
                            type="submit"
                            className="btn btn-primary"
                            onClick={this._handleSubmit}
                            disabled={!this.state.iConfirmQnA}
                          >
                            Next
                          </button>

                          <a
                            href="javascript:void(0)"
                            className="cw-save-to-draft mt-40"
                            onClick={event => this._handleSubmit(event, true)}
                          >
                            Save and exit
                          </a>
                        </>
                      )}
                    </div>
                  </div>
                ) : (
                  <Loader />
                )}
              </div>
              <Modal
                isOpen={
                  this.state.modalIsOpen &&
                  this.state.activeModal === 'deletionConfirmation'
                }
                onRequestClose={this._closeModal}
                className="cw-modal cw-modal--approve"
                contentLabel="Approval"
                style={{
                  overlay: {
                    backgroundColor: constants.EMAIL_OVERLAY_BACKGROUND
                  }
                }}
              >
                <button
                  className="cw-modal--close"
                  title="Close"
                  onClick={this._closeModal}
                >
                  <i className="fas fa-times"></i>
                </button>
                <h3 className="cw-heading--primary mb-10">Confirm delete</h3>
                <p className="mb-20">
                  Confirm you want to delete this question. You will not be able
                  to undo this action
                </p>
                <div>
                  <button
                    type="submit"
                    onClick={this._closeModal}
                    className="btn btn-outline-primary mr-10"
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-primary"
                    onClick={this._deleteQuestion}
                  >
                    Confirm
                  </button>
                </div>
              </Modal>
              <Modal
                isOpen={
                  this.state.modalIsOpen &&
                  this.state.activeModal !== 'deletionConfirmation'
                }
                onRequestClose={this._closeModal}
                className="cw-modal cw-modal--questions"
                contentLabel="Question Types"
                style={{
                  overlay: {
                    backgroundColor: constants.EMAIL_OVERLAY_BACKGROUND
                  }
                }}
              >
                <button
                  className="cw-modal--close"
                  title="Close"
                  onClick={this._closeModal}
                >
                  <i className="fas fa-times"></i>
                </button>
                <h3 className="cw-heading--primary mb-10">Question Types</h3>
                <p className="mb-20">
                  {isIncentive && isAEMType
                    ? 'Choose from 6 different questions types listed below.'
                    : 'Choose from 4 different questions types listed below.'}
                </p>
                <div className="col-sm-12">
                  <div className="row cw-bgpattern-primary">
                    <div className="col-sm-4">
                      <strong>Profile</strong>
                    </div>
                    <div className="col-sm-8">
                      <span>
                        Use to capture consumer mobile, gender, date of birth
                        and address based questions.
                      </span>
                    </div>
                  </div>
                  <div className="row cw-bgpattern-primary">
                    <div className="col-sm-4">
                      <strong>Multiple Choice</strong>
                    </div>
                    <div className="col-sm-8">
                      <span>
                        Use when one or more answer can be given from a list of
                        options. Represented as checkboxes.
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4">
                      <strong>Single Choice</strong>
                    </div>
                    <div className="col-sm-8">
                      <span>
                        Use when only one answer can be given out of a list of
                        option. Represented as radio buttons or a dropdown.
                      </span>
                    </div>
                  </div>
                  <div className="row cw-bgpattern-primary">
                    <div className="col-sm-4">
                      <strong>Free text</strong>
                    </div>
                    <div className="col-sm-8">
                      <span>
                        Use when you want the consumer to repond to a question
                        with a written free text response. Represented as a free
                        text area.
                      </span>
                    </div>
                  </div>
                  {isIncentive && isAEMType ? (
                    <>
                      <div className="row cw-bgpattern-primary">
                        <div className="col-sm-4">
                          <strong>Unique Entry Code</strong>
                        </div>
                        <div className="col-sm-8">
                          <span>
                            Use to enter the unique entry code which can be
                            found near the barcode.
                          </span>
                        </div>
                      </div>
                      <div className="row cw-bgpattern-primary">
                        <div className="col-sm-4">
                          <strong>File Upload</strong>
                        </div>
                        <div className="col-sm-8">
                          <span>Use to upload a file or files</span>
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>
              </Modal>

              <Modal
                scrollable={true}
                isOpen={this.state.closeGuideModal}
                onRequestClose={this.isCloseGuidelineModel}
                className="cw-modal cw-modal--qnadetail"
                contentLabel="Campaign Wizard 2.0"
                style={{
                  overlay: {
                    backgroundColor: constants.EMAIL_OVERLAY_BACKGROUND
                  }
                }}
              >
                <QnaGuideline closeIsEditModal={this.isCloseGuidelineModel} />
              </Modal>
            </div>
            <div className="col-sm-4 offset-md-1">
              <div
                className={`col-sm-4 ${
                  constant.featureFlags.ENABLE_QNA_GUIDELINES_CHECKBOX
                    ? ''
                    : 'cw-hidden'
                }`}
                style={{
                  borderRadius: '10px',
                  marginTop: '30px',
                  maxWidth: '100%',
                  // marginLeft: 'auto',
                  padding: '0'
                }}
              >
                <button
                  type="submit"
                  className="btn btn-primary mt-1 rounded "
                  style={{ width: '85%' }}
                  onClick={this.isOpenGuidelineModel}
                >
                  Read Guidelines
                </button>
                <div className="col-sm-1"></div>
              </div>
              <div>
                <Alert
                  alertType="saasUpdateKnownmore"
                  textMessage={constant.SAAS_MESSAGES.qnaNewUpdateMessage}
                  className={true}
                  // openModal={this._openNewModal}
                  isKnowMore={true}
                  modelText={'Know more'}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  questions: state.questionsAnswers.questions,
  addedQuestions: state.questionsAnswers.addedQuestions,
  prevAddedQuestions: state.questionsAnswers.prevAddedQuestions,
  answers: state.questionsAnswers.answers,
  selectedQuestionType: state.questionsAnswers.selectedQuestionType,
  fetchingQuestions: state.questionsAnswers.fetchingQuestions,
  fetchingAnswers: state.questionsAnswers.fetchingAnswers,
  questionsLoaded: state.questionsAnswers.questionsLoaded,
  answersLoaded: state.questionsAnswers.answersLoaded,
  dataSubmittedToApi: state.questionsAnswers.dataSubmittedToApi,
  campaignDetailsLoaded: state.questionsAnswers.campaignDetailsLoaded,
  approverEditCampaign: state.app.approverEditCampaign,
  questionanswerssaved: state.questionsAnswers.questionanswerssaved,
  campaignLanguage: state.questionsAnswers.campaignLanguage,
  campaignLanguageCode: state.questionsAnswers.campaignLanguageCode,
  editCampaign: state.overview.editCampaign,
  editCampaignDetails: state.app.editCampaignDetails,
  isCampaignEdited: state.questionsAnswers.isCampaignEdited,
  websiteType: state.questionsAnswers.websiteType,
  isLanguageEnglish: state.questionsAnswers.isLanguageEnglish,
  campaignType: state.questionsAnswers.campaignType,
  smsChannel: state.campaignDetails.smsChannel,
  showSMSChannel: state.campaignDetails.showSMSChannel,
  autogenerate: state.campaignDetails.autogenerate,
  type: state.campaignDetails.type,
  incentiveType: state.campaignDetails.incentiveType,
  iConfirmQnA: state.questionsAnswers.iConfirmQnA,
  isClone: state.questionsAnswers.isClone,
  campaign: state.campaignDetails
});

export default connect(mapStateToProps, {
  addQuestion,
  addAnswer,
  deleteQuestion,
  addQuestionType,
  deleteAnswer,
  getQuestions,
  getAnswers,
  getCampaignDetails,
  submitQuestionsAnswers,
  resetDataSubmittionState,
  removeQuestion,
  setApproverEditCampaignStatus,
  setApproverEditCampaignStatusMessage,
  setNotificationMessage,
  editedCampaignDetails,
  fetchEditedCampaignLogs,
  saveEditedCampaignLogs
})(QuestionsAnswers);
