import React from 'react';
import { get } from 'lodash';

import { FormDatePicker } from '../FormDatePicker';

export const DateTimeInput = ({
  name,
  variant = '',
  currentDate,
  control,
  errors,
  defaultValue,
  setValue,
  isSms,
  disabledValidDateCheck
}) => {
  return (
    <div className='form-row row-col-padding'>
      <FormDatePicker
        name={isSms ? `${name}.details.smsDeliveryDate${variant}`:`${name}.details.emailDeliveryDate${variant}`}
        label={ isSms ? `SMS delivery date` : `Email delivery date`}
        control={control}
        errors={errors}
        dateFormat={'DD/MM/YYYY'}
        timeFormat={false}
        defaultDisabled={false}
        defaultValue={get(defaultValue, isSms ?`smsDeliveryDate${variant}` : `emailDeliveryDate${variant}`)}
        isValidDate={givenDate => {
          return disabledValidDateCheck ? true : givenDate.isSameOrAfter(currentDate, 'day');
        }}
        setValue={setValue}
        placeholder="DD/MM/YYYY"
        inputGroupClassName="datetime-width"
      />

      <FormDatePicker
        name={isSms ? `${name}.details.smsDeliveryTime${variant}` : `${name}.details.emailDeliveryTime${variant}`}
        label={isSms ? `SMS delivery time (UTC)` : "Email delivery time (UTC)"}
        control={control}
        errors={errors}
        dateFormat={false}
        defaultDisabled={false}
        timeFormat={'hh:mm a'}
        defaultValue={get(defaultValue, isSms ? `smsDeliveryTime${variant}` : `emailDeliveryTime${variant}`)}
        setValue={setValue}
        placeholder=""
        wrapperClassName="ml-3"
        inputGroupClassName="datetime-width"
      />
    </div>
  );
};
