import React from 'react';

const requestMoreInfoIcon = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 3.5H19C20.1 3.5 21 4.39 21 5.5V19.5C21 20.03 20.79 20.54 20.41 20.91C20.04 21.29 19.53 21.5 19 21.5H5C4.47 21.5 3.96 21.29 3.59 20.91C3.21 20.54 3 20.03 3 19.5V5.5C3 4.39 3.89 3.5 5 3.5ZM12.3 7.79C12.11 8 12 8.24 12 8.5C12 8.77 12.11 9 12.3 9.21C12.5 9.4 12.74 9.5 13 9.5C13.27 9.5 13.5 9.4 13.71 9.21C13.9 9 14 8.77 14 8.5C14 8.24 13.9 8 13.71 7.79C13.5 7.61 13.27 7.5 13 7.5C12.74 7.5 12.5 7.61 12.3 7.79ZM9.8 12.47C9.7 12.56 9.7 12.57 9.77 12.67L9.82 12.75L9.85 12.81C9.92 12.94 9.93 12.94 10.04 12.86C10.17 12.77 10.39 12.63 10.76 12.41C11.68 11.82 11.5 12.5 11.09 14C10.87 14.83 10.59 15.89 10.38 17.12C10.14 18.87 11.71 17.97 12.12 17.7C12.5 17.46 13.44 16.8 13.66 16.65L13.7 16.63C13.82 16.54 13.77 16.5 13.68 16.36L13.62 16.28C13.54 16.17 13.46 16.25 13.46 16.25L13.3 16.36C12.85 16.66 12.23 17.09 12.13 16.75C12.04 16.5 12.41 15.14 12.79 13.75C12.96 13.14 13.13 12.5 13.26 11.97L13.28 11.91C13.35 11.47 13.5 10.62 12.77 10.68C11.97 10.75 9.8 12.47 9.8 12.47Z"
        fill="#0074BD"
      />
    </svg>
  );
};

export default requestMoreInfoIcon;
