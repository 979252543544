import React, { useState } from 'react';
import { get, isEmpty } from 'lodash';
import classNames from 'classnames';
import moment from 'moment';
import ApproversInfoBoxModal from 'components/molecules/ApproversInfoBoxModal/ApproversInfoBoxModal';

const ApproverAssignmentInfo = ({ campaign, className, userData }) => {
  const [isInfoBoxOpen, setIsInfoBoxOpen] = useState(false);
  const { group } = userData;
  const assignedApprover = get(campaign, 'approversMailingList', {});
  const status = get(campaign, 'status', null);
  const mailingList = get(assignedApprover, 'mailingList', []);
  const nameList = mailingList.map(user => user.name).join(', ');
  const time = moment(assignedApprover.assignedOn).format('DD-MMM-YYYY');
  //const duration = moment().diff(moment(assignedApprover.assignedOn), 'days');
  //const duration = new Date().getDate() - new Date(assignedApprover.assignedOn).getDate();
  let endDate = new Date();
  let startDate = assignedApprover.assignedOn
    ? new Date(assignedApprover.assignedOn)
    : new Date();
  const duration = moment(endDate)
    .startOf('day')
    .diff(moment(startDate).startOf('day'), 'days');

  const market = get(campaign, 'country.name');
  const approvalDate = get(campaign, 'approvalDate', null);

  const durationInDays = `${duration} ${duration === 1 ? 'day' : 'days'} ago`;
  const assignmentDate = moment(assignedApprover.assignedOn).format(
    'DD-MMM-YYYY'
  );
  const durationString = duration
    ? `on ${assignmentDate}, ${durationInDays}`
    : 'today';

  const hideAssignedToInfo = () => {
    const isApproverNotAssigned = isEmpty(mailingList);
    // hide if the campaign has been rejected.
    if (status === '4') {
      return true;
    }
    if (status !== '6') {
      return isApproverNotAssigned ? true : !!approvalDate;
    } else {
      return isApproverNotAssigned;
    }
  };
  if (hideAssignedToInfo()) {
    return null;
  } else {
    return (
      <div className={classNames('approver-assigment-info mb-20', className)}>
        <div className="d-flex">
          <div className="mr-3" style={{ paddingTop: 8, paddingBottom: 8 }}>
            <i style={{ fontSize: 32 }} className="fa fa-info-circle"></i>
          </div>
          <div className={classNames('approver-assigment-info', className)}>
            <ApproversInfoBoxModal
              isOpen={isInfoBoxOpen}
              onClose={() => {
                setIsInfoBoxOpen(false);
              }}
              nameList={nameList}
              isApprovarAssignedToMarket={assignedApprover.isAssignedToMarket}
              group={group}
            />
            <p className="">
              {assignedApprover.isAssignedToMarket
                ? `Assigned for approval ${durationString}`
                : `Assigned for approval to global leads ${durationString}`}
              {'. '}
              <button
                className="inline-button"
                style={{ fontWeight: 'bold' }}
                onClick={() => setIsInfoBoxOpen(true)}
              >
                See approvers
              </button>
            </p>
          </div>
        </div>
      </div>
    );
  }
};

export default ApproverAssignmentInfo;
