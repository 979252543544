import React from 'react';
import groupIcon from '../images/groupIcon.png';
import ReactHtmlParser from 'react-html-parser';
const MasterDataGuideLines = props => {
  const { campaignConfig } = props;
  return (
    <div className="guideline">
      {props.showMasterIcon ? (
        <>
          <div style={{ color: '#E8A100' }}>
            <img src={groupIcon} alt={groupIcon} width="25" height="25"></img>
            {` This campaign includes new master data requests.`}
          </div>
          <br />
        </>
      ) : null}
      {props.data && props.data.title ? (
        <div
          className={
            props.data.titleClass
              ? props.data.titleClass
              : 'cw-heading--primary'
          }
        >
          {props.data.title}
        </div>
      ) : (
        ''
      )}
      {props.data && props.data.subTitle ? (
        <>
          <br />
          <p>{props.data.subTitle}</p>
          <br />
        </>
      ) : (
        <br />
      )}
      <ul className={`ml-20`}>
        {props.data.guideLine.map((elem, index, array) => {
          return (
            <>
              <li className={`brandidetails`} style={{ fontWeight: '' }}>
                {elem.strongText ? (
                  <strong className="masterdata-overlay mb-10">
                    {elem.strongText}
                  </strong>
                ) : null}
                {elem.text ? elem.text : null}
                {elem.subText ? (
                  <>
                    <ul className="ml-20">
                      {elem.subText.map(e => {
                        return (
                          <li
                            className="brandidetails"
                            style={{ color: '#000000 !important' }}
                          >
                            {e.strongText ? (
                              <strong className="masterdata-overlay">
                                {(e.strongText === 'Sign up ID' &&
                                  campaignConfig &&
                                  campaignConfig.LABEL_SIGN_UP_ID) ||
                                  e.strongText}
                              </strong>
                            ) : null}
                            {e.replaceText ? ReactHtmlParser(e.text) : e.text}
                            {e.isKnowMore ? (
                              <a className={'alert-knowmore-message'}>
                                {e.knowMoreText}
                              </a>
                            ) : null}
                          </li>
                        );
                      })}
                    </ul>
                  </>
                ) : null}
                {elem.isKnowMore ? (
                  <a className={'alert-knowmore-message'}>
                    {elem.knowMoreText}
                  </a>
                ) : null}
              </li>
              {index !== array.length - 1 ? <br /> : null}
            </>
          );
        })}
      </ul>
      {props.noLineBreak ? '' : <br />}
      {props.data.notes &&
        props.data.notes.map((note, index) => (
          <p key={index} className={note.className ? note.className : ''}>
            {note.text}
          </p>
        ))}
    </div>
  );
};

export default MasterDataGuideLines;
