import React, { useState } from 'react';
import { getFormatedDate } from '../../../selectors/index';
import Loader from '../../Loader';
import classNames from 'classnames';

export default function DynamicTable({
  campaignConfig,
  sortClick,
  columnHeaders,
  userData,
  asyncInProgress,
  panelType,
  editUser,
  deleteUser
}) {

  const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'
  const [sortColumn, setSortColumn] = useState(null);
  const handleSort = (columnName) => {
    if (sortColumn === columnName) {
      // If the same column is clicked again, reverse the sorting order
      setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
    } else {
      // If a new column is clicked, set the column and default to ascending order
      setSortColumn(columnName);
      setSortOrder('asc');
    }
  };

  const columnsHeaders = () => {
    return columnHeaders.map(elm => {
      return (
        <div

          className={classNames('cell', {
            //'d-flex': elm.dataType === 'businessGroup',
            'cw-sort cw-sort--asc': elm.sort, //elm.dataType === 'creationDate',
            'actions': elm.dataType === 'actions'
          })}
          data-type={elm.dataType}
          onClick={(e)=>{
           // handleSort(elm.dataType)
           if(elm.sort) {
             sortClick(e)
           }
          }}
        >
          {elm.name}
        </div>
      );
    });
  };
  const dataArrMap = dataArr => {
    return (
      <ul>
        {dataArr.length
          ? dataArr.map((data, index) => {
              return <li className='list-unstyled' key={index}> {data.name} </li>;
            })
          : ''}
      </ul>
    );
  };
  const rowCell = value => {
    return <div className="cell">{value || ''}</div>;
  };
  

  const rowCellWithPill = user => {
    return (
      <div className="cell">
        <span className="badge badge-pill badge-info mb-1">
          {panelType === 'requester' || user.loginAccess ? 'Log-in access' : ''}
        </span> <br/>
        <span className="badge badge-pill badge-info">
          {user.emailNotificationAccess ? 'Email Notification' : ''}
        </span>
      </div>
    );
  };
  const rowCellWithAccessTo = user => {
    return (
      <div className="cell">
        <ul style={{ listStyleType: 'none' }}>
          {user.accessTo && user.accessTo.length
            ? user.accessTo.map((data, index) => {
                return data.emailCampaign ? (
                  <li key={index}> Email</li>
                ) : data.formCampaign ? (
                  <li key={index}> Data</li>
                ) : data.smsCampaign ? (
                  <li key={index}> SMS</li>
                ) : (
                  ""
                )
              })
            : ""}
        </ul>
      </div>
    );
  };

  const renderUserDataNew = (userdata, asyncInProgress) => {
    if (asyncInProgress) {
      return (
        <div className="approverlist-table-row">
          <Loader />
        </div>
      );
    } else {
      if (!userdata.length) {
        return (
          <div className="approverlist-table-row">
            <span className="mt-20 mb-10 ml-10 email-summary-font">{`No ${
                panelType === 'requester' ? 'domain' : 'user'
              } added.`}</span>
          </div>
        );
      } else {
        return userdata.map((user, index) => {
          let lastUpdatedDate = user.lastUpdatedDate
            ? getFormatedDate(user.lastUpdatedDate, false, false)
            : '';
            lastUpdatedDate = lastUpdatedDate ? lastUpdatedDate.split('-')[0] : '';
          return (
            <div key={index} className="approverlist-table-row">
              <>
                {panelType === 'approver' && user.country.length? (
                  <div className="cell">{dataArrMap(user.country)}</div>
                ) : (
                  ''
                )}
                {campaignConfig &&
                campaignConfig.APPROVER_BUSINESS_GROUP_ENABLE  &&  panelType === 'approver'? (
                  user && user.businessGroup && user.businessGroup.length ? (
                    <div className="cell">{dataArrMap(user.businessGroup)}</div>
                  ) : (
                    <div className="cell"></div>
                  )
                ) : null}
              </>
              {rowCell(user.name)}
              {rowCell(user.emailId || user.domain)}
              { panelType === 'approver' || panelType === 'acs' ? rowCellWithAccessTo(user) : null}
              {rowCellWithPill(user)}
              {rowCell(lastUpdatedDate)}
              {panelType !== 'requester' && <div className="cell actions">
                {' '}
                {user.emailId ? (
                  <span className="cw-icons m-0">
                    <i
                      className="far fa-edit"
                      onClick={() => editUser(user)}
                    ></i>
                  </span>
                ) : (
                  ''
                )}
                {user.emailId ? (
                  <span className="cw-icons ml-3 trash-icon">
                    <i
                      className="far fa-trash-alt"
                      onClick={() => deleteUser(user)}
                    ></i>
                  </span>
                ) : (
                  ''
                )}
              </div>}
            </div>
          );
        });
      }
    }
  };

  return (
    <div className="approverlist-table">
      <div className="approverlist-table-row approverlist-table-head">
        {columnsHeaders()}
      </div>
      <div className="approverlist-table-body">
        {renderUserDataNew(userData, asyncInProgress)}
      </div>
    </div>
  );
}
