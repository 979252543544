import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import CarouselBasic from 'components/organisms/CarouselBasic/CarouselBasic';
//import SSOLogo from 'images/login-logo.png';
import logo from 'images/CW_logo.svg';

import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../authConfig';
import { getLoggedInUser } from 'utilities/util';

const Login = props => {
  const { instance } = useMsal();
  let activeAccount = getLoggedInUser(instance);

  useEffect(() => {
    const mainElement = document.querySelector('main');
    const mainContainer = document.querySelector('.container.desktop');
    const rootRow = mainContainer.querySelector('.row');

    mainElement.classList.add('pb-0');
    mainContainer.classList.remove('container', 'desktop');
    rootRow.classList.remove('row');

    return () => {
      mainElement.classList.remove('pb-0');
      mainContainer.classList.add('container', 'desktop');
      rootRow.classList.add('row');
    };
  }, []);

  const handleLoginRedirect = evt => {
    /**
     * When using popup and silent APIs, we recommend setting the redirectUri to a blank page or a page
     * that does not implement MSAL. Keep in mind that all redirect routes must be registered with the application
     * For more information, please follow this link: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/login-user.md#redirecturi-considerations
     */
    evt.preventDefault();
    instance.loginRedirect(loginRequest).catch(error => console.log(error));
  };
  return activeAccount ? (
    <Redirect to="/campaign-wizard" />
  ) : (
    <div className="cw--login pinkpurple loginpage min-vh-100">
      <div className="login-box--intro">
        <CarouselBasic />
      </div>
      <div className="login-box--link">
        <div className="card text-center sso-card ">
          <img className="card-img-top" src={logo} alt="Campaign wizard logo" />
          <div className="card-body">
            <h2 className="card-title">Campaign Wizard</h2>
            <h3 className="card-text">
              Welcome, start crafting your one to one campaigns today!
            </h3>
            <button
              href="#"
              className="btn btn-primary"
              onClick={handleLoginRedirect}
            >
              Unilever employees, use single sign on
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
