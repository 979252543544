import React from 'react';
import 'semantic-ui-css/components/accordion.min.css';
import BrandStyle from './BrandStyle';

const BrandEmailDefaults = () => {
  return (
    <div className="cw-section cw-overview cw-report-dashboard-wrapper ml-20">
      <div className="col-sm-12">
        <div className="d-flex align-items-center">
          <h2
            className="cw-heading--secondary d-flex align-items-center mb-10"
            style={{ flexBasis: '100%' }}
          >
            <span className="mr-3">Brand email style settings</span>
            <span className="badge badge-pill badge-dark">BETA</span>
          </h2>
        </div>
        <span>
          Please configure your brand style settings below. When you create an
          email campaign for your brand, your configured styles will
          automatically apply. You will also be able to apply your styling to
          any existing template with a click of a button.
        </span>

        <div className="col-sm-8 p-0">
          <BrandStyle />
        </div>
      </div>
    </div>
  );
};
export default BrandEmailDefaults;
