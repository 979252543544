import React from 'react';
import Card from 'components/organisms/EmailTemplateRow/Card';
import { useDispatch, useSelector } from 'react-redux';
import { deleteEmailRow, regenerateRowThumbnail } from 'actions/emailbriefing/emailCoreRaws';
import { checkIsEmailAdminOrACS, checkIfUserIsRequestorOrApprover } from 'selectors';

export const getEmailTemplateThumbail = template => {
  if (template.templateType === 'custom') {
    return `${template.thumbnail}`;
  } else {
    return template.thumbnail;
  }
};

const EmailTemplateRow = props => {
  const { type, template = {}, handleDeleteRow } = props;
  const dispatch = useDispatch();
  const onError = () => {
    const { _id } = template;
    dispatch(regenerateRowThumbnail(_id));
  };

  const { authorizedUserData } = useSelector(state => state.authorized);
  const isEmailAdminOrACS = checkIsEmailAdminOrACS(authorizedUserData);
  const isUserRequestorOrApprover = checkIfUserIsRequestorOrApprover(authorizedUserData);

  let overflowMenuOptions =[];
  
  const handleDeleteRowClick = () => {
    const { _id } = template;
    if (template && _id) {
      //dispatch(deleteEmailRow(_id, ()=>{}));
      handleDeleteRow(_id)
    }
  };

  if (isEmailAdminOrACS || isUserRequestorOrApprover) {
    overflowMenuOptions.push({
      label: 'Delete',
      onClick: handleDeleteRowClick,
      icon: 'fas fa-trash'
    });
  }

  return (
    <>
    <Card
      overflowMenuOptions={overflowMenuOptions}
      onError={onError}
      noShadow
      thumbnail={getEmailTemplateThumbail(template)}
      alt={`${type === 'tile' ? 'Tile' : 'Template'} image ${template.title}`}
      title={template.title + ' template'}
    />
        </>
  );
};

export default EmailTemplateRow;
