import axios from 'axios';
import {
	GET_CAMPAIGN,
	ASYNC_IN_PROGRESS,
	UPDATE_GIGYA_DETAILS,
	GIGYA_DETAILS_SUBMITTED,
	GIGYA_DRAFT_SUBMITTED
} from '../actionTypes';
import constant from '../constants';
import FormData from 'form-data';

export const updateDetails = details => ({
	type: UPDATE_GIGYA_DETAILS,
	details
});

export function fetchCampaignData(Id) {
	return function(dispatch) {
		dispatch({
			type: ASYNC_IN_PROGRESS,
			isLoading: true
		});

		axios.get(constant.serviceUrls.CAMPAIGN_DETAILS_SERVICE_URL + '/' + Id).then(response => {
			let campaign = response.data[0];
			dispatch({ type: ASYNC_IN_PROGRESS, isLoading: false });
			dispatch({ type: GET_CAMPAIGN, campaign });
		});
	};
}
export function saveGigyaDetails(id, profileStoreData, userName, userEmail, isdraft) {
	return function(dispatch) {
		dispatch({
			type: ASYNC_IN_PROGRESS,
			isLoading: true
		});
		let uploadedFilename;
		if (profileStoreData && profileStoreData.gigyaCssFileUrl && profileStoreData.gigyaCssFileUrl.name) {
			let data = new FormData();
			data.append('file', profileStoreData.gigyaCssFileUrl);
			uploadedFilename = profileStoreData.gigyaCssFileUrl.name;
			profileStoreData.gigyaCssFileUrl = {};
			profileStoreData.gigyaCssFileUrl['name'] = id + '.' + uploadedFilename.split('.')[1];
			axios.post(constant.serviceUrls.CAMPAIGN_DATA_SERVICE_URL + '/upload', data, {}).then(response => {
				dispatch({
					type: ASYNC_IN_PROGRESS,
					isLoading: false
				});
			});
		}

		let data = {};
		data['uid'] = id;
		data['profileStore'] = profileStoreData;
		data['gigyadata'] = true;
		data['uploadedFilename'] = uploadedFilename;
		data['gigyadatadraft'] = isdraft;
		data['isGigyaDetailsSubmitted'] = isdraft ? false : true;
		data['GigyaSubmissionDate'] = isdraft ? null : Date.now();
		if (!isdraft) {
			data['isProcessed'] = false;
		}

		return axios({
			method: 'POST',
			url: constant.serviceUrls.CAMPAIGN_DATA_SERVICE_URL,
			data: data
		})
			.then(response => {
				dispatch({
					type: ASYNC_IN_PROGRESS,
					isLoading: false
				});
			})
			.then(response => {
				if (!isdraft) {
					let gigyaSubmitData = {
						uid: id,
						status: constant.campaignStatus.COMPLETED,
						userName: userName,
						userEmail: userEmail
					};
					return axios({
						method: 'POST',
						url: constant.serviceUrls.CAMPAIGN_STATUS_UPDATE_SERVICE_URL,
						data: gigyaSubmitData
					}).then(response => {
						dispatch({
							type: ASYNC_IN_PROGRESS,
							isLoading: true
						});
						dispatch({ type: ASYNC_IN_PROGRESS, isLoading: false });
						dispatch({
							type: GIGYA_DETAILS_SUBMITTED,
							dataSubmitted: true
						});
						dispatch({
							type: GIGYA_DRAFT_SUBMITTED,
							isDraftState: false
						});
					});
				} else {
					dispatch({
						type: ASYNC_IN_PROGRESS,
						isLoading: true
					});
					dispatch({ type: ASYNC_IN_PROGRESS, isLoading: false });
					dispatch({
						type: GIGYA_DETAILS_SUBMITTED,
						dataSubmitted: true
					});
					dispatch({
						type: GIGYA_DRAFT_SUBMITTED,
						isDraftState: true
					});
				}
			});
	};
}

export function downloadGigyaCssFile(Id, file) {
	return function(dispatch) {
		dispatch({
			type: ASYNC_IN_PROGRESS,
			isLoading: true
		});

		axios.get(constant.serviceUrls.CAMPAIGN_DATA_SERVICE_URL + '/download/' + file).then(response => {
			dispatch({ type: ASYNC_IN_PROGRESS, isLoading: false });

			let fileName = file;
			if (window.navigator && window.navigator.msSaveOrOpenBlob) {
				// IE variant
				window.navigator.msSaveOrOpenBlob(
					new Blob([response.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
					}),
					fileName
				);
			} else {
				const url = window.URL.createObjectURL(
					new Blob([response.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
					})
				);
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', fileName);
				document.body.appendChild(link);
				link.click();
			}
		});
	};
}
