import React from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { get } from 'lodash';

import { FieldError } from 'components/atoms/FieldError';
import { Input } from 'components/atoms/Input';

export const TemplateAttributes = ({
  name,
  rules,
  register,
  label,
  errors,
  setValue,
  control,
  defaultValue,
  watchInput,
  ...props
}) => {
  const errorMessages = get(errors, name);

  const couponEnabled = useWatch({
    control,
    name: watchInput,
    defaultValue
  });

  return (
    <div className="form-group" aria-live="polite">
      <label htmlFor={props.id}>{label}</label>

      <div className="form-group d-flex mt-10 mb-1">
        <label className="switch-saas">
          <Controller
            control={control}
            name={`${name}.emailCoupon`}
            defaultValue={false}
            render={({ field: { onChange, value, ref, name } }) => (
              <Input
                ref={ref}
                name={name}
                checked={value}
                type="checkbox"
                id="emailCoupon"
                onChange={e => {
                  onChange(e);
                }}
              />
            )}
          />
          <div className="slider-saas round"></div>
        </label>
        <label className="ml-20" htmlFor={`emailCoupon`}>
          Template includes coupons
        </label>
      </div>

      {couponEnabled && (
        <div className="template-coupon-inputs ml-33 pt-0 cw-form-nested mb-20">
          <div className="form-check mb-10" aria-live="polite">
            <Input
              name={`${name}.emailCouponType`}
              type="radio"
              {...(register && register(`${name}.emailCouponType`))}
              id="staticCoupon"
              value="staticCoupon"
            />
            <label htmlFor="staticCoupon" className="w-50 form-check-label">
              <p>Static coupons</p>
            </label>
          </div>

          <div className="form-check mb-10" aria-live="polite">
            <Input
              name={`${name}.emailCouponType`}
              type="radio"
              {...(register && register(`${name}.emailCouponType`))}
              id="dynamicCoupon"
              value="dynamicCoupon"
            />
            <label htmlFor="dynamicCoupon" className="w-50 form-check-label">
              <p>Dynamic coupons</p>
            </label>
          </div>
          <FieldError errors={errors} name={`${name}.emailCouponType`} />
        </div>
      )}
    </div>
  );
};
