import axios from 'axios';
import {
  GET_CAMPAIGN,
  ASYNC_IN_PROGRESS,
  REDIRECT,
  SET_MASTERDATA,
  UDPATE_CAMPAIGN_MASTERDATA,
  UPDATE_ADDITIONAL_OPTINS,
  UPDATE_MD_QUESTIONS,
  UPDATE_MD_ANSWERS,
  SAVE_DRAFT,
  UPDATE_BRAND_OPTION_MASTER_DATA,
  UPDATE_UNILEVER_OPTION_MASTER_DATA,
  UPDATE_UNILEVER_ID_MASTER_DATA,
  RESET_CAMPAIGN_ID,
  SET_CAMPAIGNID
} from '../actionTypes';
import constant from '../constants';
import { masterDataCampaignStatus, showGigyaIdScreen } from '../selectors';
import { getFormPreviewUrl } from './preview';

export function fetchCampaignData(Id) {
  return function(dispatch) {
    dispatch({
      type: ASYNC_IN_PROGRESS,
      isLoading: true
    });
    // Fetch Campaign Data
    axios
      .get(constant.serviceUrls.CAMPAIGN_DETAILS_SERVICE_URL + '/' + Id)
      .then(response => {
        let campaign = response.data[0];
        let language = campaign.language && campaign.language.code;
        if (
          ((campaign.websiteType &&
            campaign.websiteType.name === 'Adobe SaaS') ||
            campaign.isPlatformAgnostic) &&
          campaign && !campaign.isMasterData
        ) {
          dispatch(getFormPreviewUrl(Id, language));
        }
        dispatch({ type: ASYNC_IN_PROGRESS, isLoading: false });
        dispatch({ type: GET_CAMPAIGN, campaign });
      })
      .catch(error => {
        dispatch({ type: REDIRECT, redirect: true });
      });
  };
}
export function saveMasterData(id, fieldsdata, userName, userEmail, draft) {
  return function(dispatch) {
    dispatch({
      type: ASYNC_IN_PROGRESS,
      isLoading: true
    });
    //profileData
    if (
      fieldsdata['url'] ||
      fieldsdata['secretKey'] ||
      fieldsdata['accessKey']
    ) {
      if (fieldsdata['url']) {
        fieldsdata['profileStore']['url'] = fieldsdata['url'];
      }
      if (fieldsdata['secretKey']) {
        fieldsdata['profileStore']['secretkey'] = fieldsdata['secretKey'];
      }
      if (fieldsdata['accessKey']) {
        fieldsdata['profileStore']['accesskey'] = fieldsdata['accessKey'];
      }
    }
    if (!draft) {
      fieldsdata['isProcessed'] = false;
    }

    return dispatch(
      saveCampaignMasterData(id, fieldsdata, userName, userEmail, draft)
    );
  };
}

export function saveCampaignMasterData(
  id,
  fieldsdata,
  userName,
  userEmail,
  draft
) {
  let data = {};
  data = {
    uid: id,
    updateddata: fieldsdata,
    iscampaignIdEdited: fieldsdata.campaignIdUpdated
  };
  return function(dispatch) {
    return axios({
      method: 'POST',
      url: constant.serviceUrls.CAMPAIGN_MASTER_DATA_UPDATE_SERVICE_URL,
      data: data
    })
      .then(response => {
        dispatch({ type: ASYNC_IN_PROGRESS, isLoading: false });

        if (draft) {
          dispatch({ type: SAVE_DRAFT, draftSaved: true });
        } else {
          dispatch({ type: SET_MASTERDATA, updated: true });
          return dispatch(
            saveCampaignStatus(id, fieldsdata, userName, userEmail)
          );
        }
      })
      .catch(error => {
        dispatch({ type: REDIRECT, redirect: true });
      });
  };
}

export function saveCampaignStatus(id, fieldsdata, userName, userEmail) {
  return function(dispatch) {
    let status = masterDataCampaignStatus(
      fieldsdata['campaignType'],
      fieldsdata['type'],
      fieldsdata['campaignDetails']['campaignDetails']['isTrackingRequired'],
      fieldsdata['campaignDetails']['websiteType']['name'],
      fieldsdata['campaignDetails']['campaignDetails']['incentiveItPartner'] || fieldsdata['incentivePromotionalPartner'],
      fieldsdata['isMasterData']
    );

    return axios({
      method: 'POST',
      url: constant.serviceUrls.CAMPAIGN_STATUS_UPDATE_SERVICE_URL,
      data: {
        uid: id,
        status: status,
        userName: userName,
        userEmail: userEmail,
        iscampaignIdEdited: fieldsdata.campaignIdUpdated
      }
    });
  };
}

export const updateCampaignDetails = campaignDetails => ({
  type: UDPATE_CAMPAIGN_MASTERDATA,
  campaignDetails
});
export const submitCampaignDetails = campaignDetails => ({
  type: UDPATE_CAMPAIGN_MASTERDATA,
  campaignDetails
});
export const updateQuestionIds = details => ({
  type: UPDATE_MD_QUESTIONS,
  details
});
export const updateAnswersIds = details => ({
  type: UPDATE_MD_ANSWERS,
  details
});
export const updateCampaignBrandOptinDetails = brandoptin => ({
  type: UPDATE_BRAND_OPTION_MASTER_DATA,
  brandoptin
});
export const updateCampaignUnileverOptinDetails = unileveroptin => ({
  type: UPDATE_UNILEVER_OPTION_MASTER_DATA,
  unileveroptin
});
export const updateAdditionalOptins = additionalOptinList => ({
  type: UPDATE_ADDITIONAL_OPTINS,
  additionalOptinList
});

export const updateCampaignUnileverIdDetails = unileverid => ({
  type: UPDATE_UNILEVER_ID_MASTER_DATA,
  unileverid
});
