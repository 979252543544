// PrmDetails Reducer
import {
	GET_CAMPAIGN,
	ASYNC_IN_PROGRESS,
	UPDATE_PROFILE_STORE,
	PRM_DATA_SUBMITTED,
	PRM_DRAFT_SUBMITTED
} from '../actionTypes';

const initialState = {
	asyncInProgress: false,
	campaign: {},
	campaignDetails: {},
	brandoptin: {},
	unileveroptin: {},
	profileStore: {},
	additionalOptins: [],
	questionAnswerList: [],
	dataSubmitted: false,
	dataUpdated: false,
	dataloaded: false
};

export default function(state = initialState, action) {
	switch (action.type) {
		case GET_CAMPAIGN: {
			let campaign = {
				campaign: action.campaign,
				campaignDetails: action.campaign.campaignDetail,
				brandoptin: (action.campaign.campaignDetail && action.campaign.campaignDetail.optins[0]) || {},
				unileveroptin: (action.campaign.campaignDetail && action.campaign.campaignDetail.optins[1]) || {},
				profileStore: (action.campaign.campaignDetail && action.campaign.campaignDetail.profileStore) || {},
				additionalOptins:
					(action.campaign.campaignDetail && action.campaign.campaignDetail.additionalOptinList) || [],
				questionAnswerList:
					(action.campaign.campaignDetail && action.campaign.campaignDetail.questionAnswerList) || [],
				dataloaded: true
			};
			return { ...state, ...campaign };
		}
		case ASYNC_IN_PROGRESS: {
			let asyncInProgress = { asyncInProgress: action.isLoading };
			return { ...state, ...asyncInProgress };
		}
		case UPDATE_PROFILE_STORE: {
			let newobj = { ...state.profileStore, ...action.profileStoreData };
			let dataUpdated = { dataUpdated: true, profileStore: newobj };
			return { ...state, ...dataUpdated };
		}
		case PRM_DATA_SUBMITTED: {
			let datasubmitted = { asyncInProgress: action.isLoading, dataSubmitted: action.dataSubmitted };
			return { ...state, ...datasubmitted };
		}
		case PRM_DRAFT_SUBMITTED: {
			let draftsubmitted = { isDraftState: action.isDraftState };
			return { ...state, ...draftsubmitted };
		}

		default:
			return state;
	}
}
