import React from 'react';
import constants from 'constants/config';
import classNames from 'classnames';

const OptOutTextMissingMessage = ({ isError }) => {
  return (
    <div
      className={classNames('d-flex alert ', {
        'alert-warning': !isError,
        'alert-danger': isError
      })}
      role="alert"
    >
      {isError ? (
        <i className="fas fa-exclamation-triangle pt-5 mr-10"></i>
      ) : (
        <i className="fas fa-exclamation-circle pt-5 mr-10"></i>
      )}
      <span>
        Opt-out text for this country, brand and language combination is not
        available in Campaign Wizard. It could be because your brand is not
        onboarded with the SMS service provider or if it is onboarded, ACS team
        may not have updated your opt-out text in CW. Please review
        <a
          href={constants.SMS_CAMPAIGNS_ONBOARDING_GUIDANCE_LINK}
          target="_blank"
          rel="noopener noreferrer"
        >
          {` onboarding guidance `}
          <i className="fas fa-external-link-alt"></i>
        </a>{' '}
        and ensure all steps are completed. You will not be able to submit your
        campaign until opt-out text is updated.
      </span>
    </div>
  );
};

export default OptOutTextMissingMessage;
