import React, { useState } from 'react';
import constant from '../constants';
import { sendTestEmail } from 'services/emailBriefings';
import { useSelector } from 'react-redux';
import { UnstyledButton } from './atoms/UnstyledButton';
import { checkEmailCampaignSendTestRights } from 'selectors';
import SendTestEmailIcon from '../images/send-test-email.png';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';

const EmailTest = ({
  uid,
  onClose,
  userEmail,
  userName,
  emailBriefing,
  refetchEmailBriefing,
  refetchEmailHistoryLogs,
  userGroup
}) => {
  const [checked, setChecked] = useState('Onlyme');
  const [addedEmail, setAddedEmail] = useState('');
  const [finalEmailList, setFinalEmailList] = useState([]);
  const [validDomainList] = useState(['unilever.com']);
  const [sentEmail, setSentEmail] = useState(false);
  const [sendEmailbtn, setSendEmailbtn] = useState(false);
  const { campaignConfig: { sendTestEmailExceptions = [] } = {} } = useSelector(
    state => state.app
  );

  const onRadioChange = e => {
    if (e.target.value === 'Onlyme') {
      setFinalEmailList([]);
      if (sendEmailbtn) {
        setSendEmailbtn(false);
      }
    } else {
      const e1 = document.getElementById(`email_invalid`);
      const e2 = document.getElementById('email_text');
      const e3 = document.getElementById('email_duplicate');
      if (
        !e1.classList.contains('cw-hidden') ||
        !e2.classList.contains('cw-hidden') ||
        !e3.classList.contains('cw-hidden')
      ) {
        setSendEmailbtn(true);
      }
    }
    setChecked(e.target.value);
  };

  let addEmail = emailList => {
    finalEmailList.push(emailList);
    setFinalEmailList([...finalEmailList]);
    document.getElementById(`email_address`).value = '';
    setAddedEmail('');
  };

  let handleEmailAddress = event => {
    let enteredAddress = event.target.value;
    document.getElementById(`email_text`) &&
      document.getElementById(`email_text`).classList.add('cw-hidden');
    document.getElementById(`email_address`) &&
      document
        .getElementById(`email_address`)
        .classList.remove('cw-error-focus');
    setAddedEmail(enteredAddress.toLowerCase());
  };

  let checkEmailExceptionList = email => {
    if (userGroup !== 'requestor') {
      return sendTestEmailExceptions.includes(email);
    }
    return false;
  };

  let addEmailAddress = (event, index) => {
    let regexPattern = constant.regex['email'];
    if (
      document.getElementById(`email_duplicate`) &&
      !document
        .getElementById(`email_duplicate`)
        .classList.contains('cw-hidden')
    ) {
      document.getElementById(`email_duplicate`) &&
        document.getElementById(`email_duplicate`).classList.add('cw-hidden');
    }
    if (
      document.getElementById(`email_address`) &&
      document.getElementById(`email_address`).value.trim().length > 0 &&
      regexPattern.test(document.getElementById(`email_address`).value)
    ) {
      //  if(document.getElementById(`email_address`).value.includes('@unilever.com')){
      let emailList = {
        email: addedEmail,
        id: parseInt(Date.now() * Math.random()),
        isNewUser: false
      };

      if (
        document.getElementById(`email_text`) &&
        !document.getElementById(`email_text`).classList.contains('cw-hidden')
      ) {
        document.getElementById(`email_text`) &&
          document.getElementById(`email_text`).classList.add('cw-hidden');
      }
      if (
        document.getElementById(`email_invalid`) &&
        !document
          .getElementById(`email_invalid`)
          .classList.contains('cw-hidden')
      ) {
        document.getElementById(`email_invalid`) &&
          document.getElementById(`email_invalid`).classList.add('cw-hidden');
      }
      if (
        document.getElementById(`email_address`) &&
        document
          .getElementById(`email_address`)
          .classList.contains('cw-error-focus')
      ) {
        document.getElementById(`email_address`) &&
          document
            .getElementById(`email_address`)
            .classList.remove('cw-error-focus');
      }
      if (finalEmailList.find(o => o.email === emailList.email) !== undefined) {
        document.getElementById(`email_duplicate`) &&
          document
            .getElementById(`email_duplicate`)
            .classList.remove('cw-hidden');
        document.getElementById(`email_address`) &&
          document
            .getElementById(`email_address`)
            .classList.add('cw-error-focus');
      } else if (emailList.email.split('@')[1] === 'unilever.com') {
        setSendEmailbtn(false);
        addEmail(emailList);
      } else if (checkEmailExceptionList(emailList.email)) {
        setSendEmailbtn(false);
        addEmail(emailList);
      } else if (validDomainList.includes(emailList.email.split('@')[1])) {
        setSendEmailbtn(false);
        addEmail(emailList);
      } else {
        setSendEmailbtn(true);
        document.getElementById(`email_invalid`) &&
          document
            .getElementById(`email_invalid`)
            .classList.remove('cw-hidden');
        document.getElementById(`email_address`) &&
          document
            .getElementById(`email_address`)
            .classList.add('cw-error-focus');
      }
    } else {
      setSendEmailbtn(true);
      if (
        document.getElementById(`email_invalid`) &&
        document.getElementById(`email_invalid`).classList.contains('cw-hidden')
      ) {
        document.getElementById(`email_invalid`) &&
          document
            .getElementById(`email_invalid`)
            .classList.remove('cw-hidden');
      }
      if (
        document.getElementById(`email_address`) &&
        !document
          .getElementById(`email_address`)
          .classList.contains('cw-error-focus')
      ) {
        document.getElementById(`email_address`) &&
          document
            .getElementById(`email_address`)
            .classList.add('cw-error-focus');
      }
    }
  };

  const checkEmailInput = givenEmail => {
    let regexPattern = constant.regex['email'];
    if (
      document.getElementById(`email_address`) &&
      document.getElementById(`email_address`).value.trim().length > 0 &&
      regexPattern.test(document.getElementById(`email_address`).value)
    ) {
      //  if(document.getElementById(`email_address`).value.includes('@unilever.com')){
      let emailList = {
        email: addedEmail,
        id: parseInt(Date.now() * Math.random()),
        isNewUser: false
      };

      if (
        document.getElementById(`email_text`) &&
        !document.getElementById(`email_text`).classList.contains('cw-hidden')
      ) {
        document.getElementById(`email_text`) &&
          document.getElementById(`email_text`).classList.add('cw-hidden');
      }

      if (
        document.getElementById(`email_invalid`) &&
        !document
          .getElementById(`email_invalid`)
          .classList.contains('cw-hidden')
      ) {
        document.getElementById(`email_invalid`) &&
          document.getElementById(`email_invalid`).classList.add('cw-hidden');
      }

      if (
        document.getElementById(`email_address`) &&
        document
          .getElementById(`email_address`)
          .classList.contains('cw-error-focus')
      ) {
        document.getElementById(`email_address`) &&
          document
            .getElementById(`email_address`)
            .classList.remove('cw-error-focus');
      }
      if (finalEmailList.find(o => o.email === emailList.email) !== undefined) {
        document.getElementById(`email_duplicate`) &&
          document
            .getElementById(`email_duplicate`)
            .classList.remove('cw-hidden');
        document.getElementById(`email_address`) &&
          document
            .getElementById(`email_address`)
            .classList.add('cw-error-focus');
        return true;
      } else if (emailList.email.split('@')[1] === 'unilever.com') {
      } else if (checkEmailExceptionList(emailList.email)) {
      } else if (validDomainList.includes(emailList.email.split('@')[1])) {
      } else {
        document.getElementById(`email_invalid`) &&
          document
            .getElementById(`email_invalid`)
            .classList.remove('cw-hidden');
        document.getElementById(`email_address`) &&
          document
            .getElementById(`email_address`)
            .classList.add('cw-error-focus');
        return true;
      }
    } else {
      if (
        document.getElementById(`email_invalid`) &&
        document.getElementById(`email_invalid`).classList.contains('cw-hidden')
      ) {
        document.getElementById(`email_invalid`) &&
          document
            .getElementById(`email_invalid`)
            .classList.remove('cw-hidden');
      }
      if (
        document.getElementById(`email_address`) &&
        !document
          .getElementById(`email_address`)
          .classList.contains('cw-error-focus')
      ) {
        document.getElementById(`email_address`) &&
          document
            .getElementById(`email_address`)
            .classList.add('cw-error-focus');
      }
      return true;
    }
  };
  const sendEmail = async () => {
    const isSendOnlyme = checked === 'Onlyme';
    let templateEmailList = isSendOnlyme ? [] : [...finalEmailList];
    if (!isSendOnlyme && addedEmail.trim().length) {
      if (addedEmail) {
        if (checkEmailInput(addedEmail)) {
          return false;
        } else {
          templateEmailList.push({
            email: addedEmail,
            id: parseInt(Date.now() * Math.random()),
            isNewUser: false
          });
        }
      }
    }
    let toEmailArr = templateEmailList.map(e => e.email);

    toEmailArr.push(userEmail);
    setSentEmail(true);
    setFinalEmailList(templateEmailList);
    sendTestEmail({
      uid: uid,
      to: toEmailArr,
      type: 'html',
      senderEmail: userEmail,
      userName
    }).then(result => {
      if (result) {
        refetchEmailBriefing && refetchEmailBriefing();
        refetchEmailHistoryLogs && refetchEmailHistoryLogs();
      }
    });
    // onClose();
  };

  let _deleteAnswer = (event, index) => {
    let currentEmailList = [...finalEmailList];
    let i = currentEmailList.findIndex(elem => elem.id === index);
    currentEmailList.splice(i, 1);
    setFinalEmailList([...currentEmailList]);
    document.getElementById('add-email').style.pointerEvents = 'auto';
  };

  const getAttempts = emailBriefingData => {
    if (userGroup === 'requestor') {
      if (emailBriefingData) {
        const attempts = emailBriefingData.sendTestEmailAttempts;
        const toolTipMesg =
          'We limit the max number of sending test emails attempts to avoid spamming. Please use this feature once you have designed your email to avoid exhausting your attempts';
        if (attempts) {
          return (
            <div>
              <ReactTooltip
                place="right"
                id="emailAttemptsTooltip"
                type="info"
                multiline={true}
                className="cw-tooltip cw-email-tooltip"
              />
              <p
                className={classNames(
                  'mt-10 text-center send-test-email__attpm-text',
                  {
                    'send-test-email__max-attempt':
                      attempts === constant.EMAIL_SEND_TEST_MAX_ATTEMPTS
                  }
                )}
              >
                {`${constant.EMAIL_SEND_TEST_MAX_ATTEMPTS - attempts} of ${
                  constant.EMAIL_SEND_TEST_MAX_ATTEMPTS
                } attempts remaining`}{' '}
                <span
                  className="cw-icon cw-icon--help cw-icon__email"
                  data-for="emailAttemptsTooltip"
                  data-tip={toolTipMesg}
                >
                  <i className="fas fa-info-circle"></i>
                </span>
              </p>
            </div>
          );
        } else {
          return (
            <div>
              <ReactTooltip
                place="right"
                id="emailAttemptsTooltip"
                type="info"
                multiline={true}
                className="cw-tooltip cw-email-tooltip"
              />
              <p className="mt-10 text-center send-test-email__attpm-text">
                {`${constant.EMAIL_SEND_TEST_MAX_ATTEMPTS} of ${constant.EMAIL_SEND_TEST_MAX_ATTEMPTS} attempts remaining`}{' '}
                <span
                  className="cw-icon cw-icon--help cw-icon__email"
                  data-for="emailAttemptsTooltip"
                  data-tip={toolTipMesg}
                >
                  <i className="fas fa-info-circle"></i>
                </span>
              </p>
            </div>
          );
        }
      }
    }
    return (
      <div>
        <ReactTooltip
          place="right"
          id="emailAttemptsTooltip"
          type="info"
          multiline={true}
          className="cw-tooltip cw-email-tooltip"
        />
        <p className="mt-10 text-center send-test-email__attpm-text">
          {`Avoid multiple attempts`}{' '}
          <span
            className="cw-icon cw-icon--help cw-icon__email"
            data-for="emailAttemptsTooltip"
            data-tip={
              'Please limit the usage of this feature to avoid spamming. Please use this feature once you have designed your email to avoid exhausting your attempts'
            }
          >
            <i className="fas fa-info-circle"></i>
          </span>
        </p>
      </div>
    );
  };

  const isSendEmailDisabled = () => {
    if (userGroup === 'requestor') {
      return (
        emailBriefing.sendTestEmailAttempts ===
        constant.EMAIL_SEND_TEST_MAX_ATTEMPTS
      );
    }
    return false;
  };

  const shouldSendTest = checkEmailCampaignSendTestRights(emailBriefing, {
    group: userGroup,
    emailId: userEmail
  });

  return (
    <div className="email-overlay-wrapper">
      <span
        className="qna-guideline-close email-overlay-close-modal"
        onClick={onClose}
      ></span>
      <div className="email-overlay-scroll">
        <div className="d-flex">
          <div className="">
            <img
              src={SendTestEmailIcon}
              alt="send test email icon"
              className="send-test-email__icon"
            />
          </div>
          <div className="ml-10">
            <div className={'cw-heading--saascampaign send-test-email__title'}>
              {'Send test email'}
            </div>
            <p className="mt-10">
              Send a copy of this email to test it works as expected
            </p>
          </div>
        </div>
        {shouldSendTest ? (
          sentEmail ? (
            <>
              <p className="mt-20">An email has been sent to</p>
              <div key={userEmail}>
                <ul style={{ listStyleType: 'none' }}>
                  <li>{userEmail}</li>
                </ul>
              </div>
              {finalEmailList.map((elem, index) => {
                return (
                  <div key={index}>
                    <ul style={{ listStyleType: 'none' }}>
                      <li>{elem.email}</li>
                    </ul>
                  </div>
                );
              })}
              <button
                type="submit"
                onClick={onClose}
                className="btn btn-primary mt-10"
              >
                Close
              </button>
            </>
          ) : (
            <>
              <div className="mt-20">
                <div className="form-group">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="Onlyme"
                      id="Onlyme"
                      value={'Onlyme'}
                      checked={checked == 'Onlyme'}
                      onChange={onRadioChange}
                    />
                    <label
                      className="form-check-label d-block mb-10"
                      htmlFor="Onlyme"
                    >
                      <span>Only me</span>
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="Onlyme"
                      id="myselfAndTwo"
                      value={'myselfAndTwo'}
                      checked={checked == 'myselfAndTwo'}
                      onChange={onRadioChange}
                    />
                    <label
                      className="form-check-label d-block mb-10"
                      htmlFor="myselfAndTwo"
                    >
                      <span>Myself and any 3 additional users</span>
                      <p className="email-text-font">
                        Only unilever.com emails are allowed
                      </p>
                    </label>
                  </div>
                  <div
                    className={`form-check mb-10 ${
                      checked == 'myselfAndTwo' ? '' : 'd-none'
                    }`}
                  >
                    <label
                      className="form-check-label ml-1"
                      htmlFor="emailList"
                    >
                      <span>Enter email address</span>
                    </label>
                    <div className="form-builder-container">
                      <div className="form-group email-test-field-width">
                        <input
                          type="text"
                          className="form-control mb-10"
                          name={`email_address`}
                          id={'email_address'}
                          aria-describedby={`email_address`}
                          data-required="true"
                          disabled={finalEmailList.length >= 3}
                          defaultValue={addedEmail}
                          onChange={e => handleEmailAddress(e, e.target.value)}
                        />

                        <span
                          id="add-email"
                          style={{
                            pointerEvents:
                              finalEmailList.length >= 3 ? 'none' : 'auto'
                          }}
                          className={'manage-editors'}
                          onClick={e => addEmailAddress()}
                        >
                          {' '}
                        </span>
                        <span
                          id={`email_text`}
                          className="cw-error cw-hidden"
                          data-control="name"
                        >
                          <div className="d-flex">
                            <span>
                              <i className="fas fa-exclamation-triangle mr-10"></i>
                            </span>
                            <span>Please add at least one email</span>
                          </div>
                        </span>
                        <span
                          id={`email_invalid`}
                          className="cw-error cw-hidden"
                          data-control="name"
                        >
                          <div className="d-flex">
                            <span>
                              <i className="fas fa-exclamation-triangle mr-10"></i>
                            </span>
                            <span>
                              Please enter a valid email. Only emails on domain
                              unilever.com are allowed.
                            </span>
                          </div>
                        </span>
                        <span
                          id={`email_duplicate`}
                          className="cw-error cw-hidden"
                          data-control="name"
                        >
                          <div className="d-flex">
                            <span>
                              <i className="fas fa-exclamation-triangle mr-10"></i>
                            </span>
                            <span>This email id is already been added</span>
                          </div>
                        </span>
                      </div>
                    </div>
                    <div className="d-flex justify-content-start flex-wrap mt-10 ml-1">
                      {finalEmailList.map((elem, index) => {
                        return (
                          <div
                            className="d-flex justify-content-start align-items-center mr-20 send-test-email__emails"
                            key={index}
                          >
                            <span>{elem.email}</span>
                            <UnstyledButton
                              onClick={e => _deleteAnswer(e, elem.id)}
                              className="ml-2"
                            >
                              <i className="fas fa-times"></i>
                            </UnstyledButton>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>

                <div className="mr-20 mt-3">
                  <button
                    type="submit"
                    onClick={sendEmail}
                    className="btn btn-primary btn-block"
                    disabled={isSendEmailDisabled() || sendEmailbtn}
                    // className="btn btn-primary mb-10 cw-print-hide text-nowrap  mr-1 guideline-close-button"
                  >
                    Send test email
                  </button>
                </div>
                {getAttempts(emailBriefing)}
              </div>
            </>
          )
        ) : (
          <>
            <p className="mt-20">
              Sorry, only requestors and collaborators of this campaign, along
              with few elevated roles like global ACS team members, can send
              this email as a test
            </p>
            <button
              type="button"
              onClick={onClose}
              className="btn btn-primary mt-10"
            >
              Close
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default EmailTest;
