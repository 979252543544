import CheckboxDropDown from '../../../../components/CheckboxDropDown';
import React from 'react';
import FilterWrapper from '../FilterWrapper/FilterWrapper';

export default function BrandFilter({
  asyncInProgress,
  value,
  onChange,
  options,
  isEmailCampaign,
  classNamePrefix,
  optionsClass,
  noMarginRight
}) {
  return (
    <FilterWrapper noMarginRight={noMarginRight}>
      <CheckboxDropDown
        isMulti={true}
        className={
          isEmailCampaign
            ? 'react-multi-select-container'
            : 'cw-dashboard-filter'
        }
        classNamePrefix={classNamePrefix}
        optionsClass={optionsClass}
        isEmailCampaign={isEmailCampaign}
        menuIsOpen={true}
        name={'brands'}
        label={'Brand'}
        hideSelectedOptions={false}
        placeholder={'Brand'}
        value={value}
        isDisabled={asyncInProgress}
        isLoading={asyncInProgress}
        onChange={onChange}
        options={options}
        isClearable={false}
      />
    </FilterWrapper>
  );
}
