import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { fetchCampaignData } from '../../actions/overview';
import ReactHtmlParser from 'react-html-parser';
import constant from '../../constants';
import ReactTooltip from 'react-tooltip';
import {
  checkCampaignLanguage,
  epsilonMarket,
  fetchQnANotificationTileData
} from '../../selectors';
import groupIcon from '../../images/groupIcon.png';
import SummaryComponent from 'components/SummaryComponent';
import constants from '../../constants';
import axios from 'axios';

class FormAuthoring extends Component {
  state = {
    campaignId: this.props.campaignId,
    expand: -1,
    consentAccordion: -1,
    questionAccordion: -1,
    incentiveAccordion: -1
  };
  componentDidMount() {
    this.campaignId = this.props.campaignId;
    let isOverviewPage = this.props.isOverviewPage
      ? this.props.isOverviewPage
      : false;
    isOverviewPage
      ? this.props.fetchCampaignData(
          this.campaignId,
          null,
          null,
          null,
          null,
          isOverviewPage
        )
      : this.props.fetchCampaignData(this.campaignId);
  }

  handleAccordionExpandCollapse = index => {
    this.setState(prevState => ({
      expand: prevState.expand === index ? false : index
    }));
  };

  handleConsentAccordion = index => {
    this.setState(prevState => ({
      consentAccordion: prevState.consentAccordion === index ? false : index
    }));
  };
  handleQuestionsAccordion = index => {
    this.setState(prevState => ({
      questionAccordion: prevState.questionAccordion === index ? false : index
    }));
  };

  handleIncentiveAccordion = index => {
    this.setState(prevState => ({
      incentiveAccordion: prevState.incentiveAccordion === index ? false : index
    }));
  };

  _handleRefresh = () => {
    let { campaign } = this.props;
    axios
      .post(constants.serviceUrls.CAMPAIGN_INFRA_API_CALL, campaign)
      .then(response => {
        this.campaignId = this.props.campaignId;
        let isOverviewPage = this.props.isOverviewPage
          ? this.props.isOverviewPage
          : false;
        isOverviewPage
          ? this.props.fetchCampaignData(
              this.campaignId,
              null,
              null,
              null,
              null,
              isOverviewPage
            )
          : this.props.fetchCampaignData(this.campaignId);
      });
  };

  render() {
    let fields =
      this.props &&
      this.props.campaignDetails &&
      this.props.campaignDetails.fields;
    let consents =
      this.props &&
      this.props.campaignDetails &&
      this.props.campaignDetails.consents;
    let messages =
      this.props &&
      this.props.campaignDetails &&
      this.props.campaignDetails.messages;

    let { campaign, campaignDetails } = this.props;
    let campaignLanguage =
      campaign && campaign.language && campaign.language.name;
    let isCampaignEnglish = checkCampaignLanguage(campaignLanguage);
    let isCampaignIncentive =
      this.props.campaign && this.props.campaign.campaignType === 'Incentive';
    let isIncentiveTypeCompetition =
      this.props.campaign &&
      this.props.campaign.incentiveType === 'Competitions';
    let isIncentiveTypeSamples =
      this.props.campaign &&
      this.props.campaign.incentiveType === 'Product samples';
    let country = campaign && campaign.country && campaign.country.code;
    let isEpsilonMarket = country && epsilonMarket(country);
    let languageAnsId =
      campaignDetails &&
      campaignDetails.epsilon &&
      campaignDetails.epsilon.languageAnsId;
    let languageAnsText =
      campaignDetails &&
      campaignDetails.epsilon &&
      campaignDetails.epsilon.languageAnsText;
    let brandOptinCCID =
      campaignDetails &&
      campaignDetails.epsilon &&
      campaignDetails.epsilon.brandOptinCCID;
    let campaignControlId =
      campaignDetails &&
      campaignDetails.epsilon &&
      campaignDetails.epsilon.campaignControlId;
    let corporateOptinCCID =
      campaignDetails &&
      campaignDetails.epsilon &&
      campaignDetails.epsilon.corporateOptinCCID;
    let dataSourceId =
      campaignDetails &&
      campaignDetails.epsilon &&
      campaignDetails.epsilon.dataSourceId;
    let languageQuestionAnsId =
      campaignDetails &&
      campaignDetails.epsilon &&
      campaignDetails.epsilon.languageQuestionAnsId;

    let { colWidth, newIconClass, accordianClass } = this.props;

    let qnaTileData = fetchQnANotificationTileData(campaignDetails);

    let newMasterData = (isNew, colWidth) => {
      return isNew ? (
        <>
          <div
            style={{ display: 'block', color: '#E9A40A', whiteSpace: 'pre' }}
            className={colWidth}
          >
            <img src={groupIcon} alt={groupIcon} width="25" height="25"></img>
            &nbsp; New Master
          </div>
        </>
      ) : null;
    };

    return (
      <div className="cw-campaign--review-section mb-40">
        <div
          className={
            !this.props.ishideQnA || isCampaignIncentive ? 'mb-40' : 'mb-20'
          }
        >
          <div className="cw-campaign--review-section-header mb-3 d-flex align-items-center">
            <h3 className="cw-heading--senary">Profile Fields</h3>
            {this.props.isEditButton ? (
              <NavLink
                className="btn btn-outline-secondary ml-auto"
                to={
                  '/campaign-wizard/campaign/' +
                  this.campaignId +
                  this.props.profilePage
                }
              >
                Edit
              </NavLink>
            ) : (
              ''
            )}
          </div>
          {campaignDetails.fields &&
            campaignDetails.fields
              .filter(elem => elem.type === 'field')
              .map((elem, index) => {
                return (
                  <>
                    <div className="mb-10">
                      <div className="accordion saas-form-field-accordion">
                        <div className="card" style={{ border: 0 }}>
                          <div
                            className={`card-header accordian-card-header ${
                              this.state.expand === index
                                ? 'card-bottom-border'
                                : 'card-no-bottom'
                            }`}
                            style={{ padding: '7px 13px' }}
                          >
                            <span
                              className="saas-accordion-heading"
                              style={{ fontWeight: '700' }}
                            >
                              {elem.label}
                            </span>
                            <span
                              type="button"
                              className={
                                this.state.expand === index
                                  ? 'saas-accordion-arrow afterTransform'
                                  : 'saas-accordion-arrow'
                              }
                              onClick={() => {
                                this.handleAccordionExpandCollapse(index);
                              }}
                            >
                              <i
                                className={
                                  this.state.expand === index
                                    ? 'fas fa-chevron-down transformProfileIcon-180'
                                    : 'fas fa-chevron-down'
                                }
                              ></i>
                            </span>
                          </div>
                          <div
                            className={
                              this.state.expand === index
                                ? 'collapse show'
                                : 'collapse'
                            }
                          >
                            <div className="cw-campaign--review-section-content">
                              <div className="cw-striped-profile-row">
                                <div className="row">
                                  <div className="col-sm-4 cw-qna-lable">
                                    {elem.label === 'Paragraph'
                                      ? 'Paragraph Text'
                                      : elem.label === 'Heading'
                                      ? 'Heading Text'
                                      : ' Field Label'}
                                  </div>
                                  <div className="col-sm-8 cw-text--ternary">
                                    {elem.label === 'Paragraph'
                                      ? ReactHtmlParser(elem.paragraph)
                                      : elem.label === 'Heading'
                                      ? elem.heading
                                      : elem.fieldLabel}
                                  </div>
                                </div>
                              </div>
                              {elem.label === 'State or Province' ? (
                                <>
                                  <div className="cw-striped-profile-row">
                                    <div className="row">
                                      <div className="col-sm-4 cw-qna-lable">
                                        Field Type
                                      </div>
                                      <div className="col-sm-8 cw-text--ternary">
                                        {elem.questionType}
                                      </div>
                                    </div>
                                  </div>
                                  {elem.questionType === 'Single choice' ? (
                                    <div className="cw-striped-profile-row">
                                      <div className="row">
                                        <div className="col-sm-4 cw-qna-lable">
                                          State Values
                                        </div>
                                        <div className="col-sm-8 cw-text--ternary">
                                          {elem.answers &&
                                          elem.answers.length > 0
                                            ? elem.answers
                                                .map(ele => ele.value)
                                                .join(', ')
                                            : null}
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}
                                </>
                              ) : null}
                              {elem.label === 'Gender' ? (
                                <div className="cw-striped-profile-row">
                                  <div className="row">
                                    <div className="col-sm-4 cw-qna-lable">
                                      Gender Values
                                    </div>
                                    <div className="col-sm-8 cw-text--ternary">
                                      {elem.values && elem.values.length > 0
                                        ? elem.values
                                            .filter(
                                              ele =>
                                                ele.isChecked &&
                                                ele.translatedValue.trim()
                                                  .length !== 0
                                            )
                                            .map(ele => ele.translatedValue)
                                            .join(', ')
                                        : null}
                                    </div>
                                  </div>
                                </div>
                              ) : null}

                              {elem.toolTipText &&
                              elem.toolTipText.trim().length > 0 ? (
                                <div className="cw-striped-profile-row">
                                  <div className="row">
                                    <div className="col-sm-4 cw-qna-lable">
                                      Tooltip/Help-Text
                                    </div>
                                    <div className="col-sm-8 cw-text--ternary">
                                      {elem.toolTipText ? elem.toolTipText : ''}
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              {elem.label !== 'Paragraph' &&
                              elem.label !== 'Heading' ? (
                                <div className="cw-striped-profile-row">
                                  <div className="row">
                                    <div className="col-sm-4 cw-qna-lable">
                                      Mandatory field
                                    </div>
                                    <div className="col-sm-8 cw-text--ternary">
                                      {elem.mandatoryToggle
                                        ? 'Mandatory'
                                        : 'Optional'}
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              {elem.mandatoryToggle ? (
                                <div className="cw-striped-profile-row">
                                  <div className="row">
                                    <div className="col-sm-4 cw-qna-lable">
                                      Mandatory Field Error Message
                                    </div>
                                    <div className="col-sm-8 cw-text--ternary">
                                      {elem.mandatoryMessage
                                        ? elem.mandatoryMessage
                                        : ''}
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              {elem.label === 'Date of Birth' ? (
                                <div className="cw-striped-profile-row">
                                  <div className="row">
                                    <div className="col-sm-4 cw-qna-lable">
                                      Legal Age Validation Message
                                    </div>
                                    <div className="col-sm-8 cw-text--ternary">
                                      {elem.validationMessage
                                        ? elem.validationMessage
                                        : ''}
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              {(elem.label === 'Email Address' ||
                                elem.label === 'Mobile Number' ||
                                elem.label === 'Postal Code') &&
                              elem.validationMessage &&
                              elem.validationMessage.trim().length > 0 ? (
                                <div className="cw-striped-profile-row">
                                  <div className="row">
                                    <div className="col-sm-4 cw-qna-lable">
                                      Format Validation Error Message
                                    </div>
                                    <div className="col-sm-8 cw-text--ternary">
                                      {elem.validationMessage
                                        ? elem.validationMessage
                                        : ''}
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
        </div>
        {/* q&a starts here */}
        {!this.props.ishideQnA ? (
          <>
            <div className={isCampaignIncentive ? 'mb-40' : 'mb-20'}>
              <div className="cw-campaign--review-section-header mb-3 d-flex align-items-center">
                <h3 className="cw-heading--senary">Question & Answers</h3>
                {this.props.isEditButton ? (
                  <NavLink
                    className="btn btn-outline-secondary ml-auto"
                    to={
                      '/campaign-wizard/campaign/' +
                      this.campaignId +
                      this.props.qnaPage
                    }
                  >
                    Edit
                  </NavLink>
                ) : (
                  ''
                )}
              </div>
              <div className="mb-20">
                {qnaTileData &&
                qnaTileData.showQnATile &&
                country !== constants.COUNTRY_US ? (
                  <SummaryComponent
                    data={this.props.campaign}
                    isBrandSummary={false}
                    isQnASummary={true}
                    qnaTileData={qnaTileData}
                    handleRefresh={this._handleRefresh}
                  />
                ) : null}
              </div>

              {!this.props.ishideQnA &&
                campaignDetails.fields &&
                campaignDetails.fields
                  .filter(elem => elem.dataType === 'questionAnswerPair')
                  .map((elem, index) => {
                    return (
                      <>
                        <div className="mb-10">
                          <ReactTooltip
                            place="right"
                            // type="warning"
                            multiline={true}
                            //className="cw-tooltip"
                          />

                          <div className="accordion saas-form-field-accordion">
                            <div className="card" style={{ border: 0 }}>
                              <div
                                className={`card-header  ${
                                  this.state.questionAccordion === index
                                    ? 'card-bottom-border'
                                    : 'card-no-bottom'
                                }`}
                                style={{ padding: '7px 13px' }}
                              >
                                <div className="row">
                                  <div
                                    className={
                                      elem.isNew ||
                                      elem.istranslatedNew ||
                                      elem.answers.find(
                                        e =>
                                          e.isNew === true ||
                                          e.istranslatedNew === true
                                      )
                                        ? accordianClass
                                        : `col-sm-11`
                                    }
                                  >
                                    <span
                                      className="saas-accordion-heading"
                                      style={{ fontWeight: '700' }}
                                    >
                                      {elem.questionLabel}
                                    </span>
                                  </div>

                                  {!epsilonMarket(country) &&
                                  (elem.isNew ||
                                    elem.istranslatedNew ||
                                    elem.answers.find(
                                      e =>
                                        e.isNew === true ||
                                        e.istranslatedNew === true
                                    )) ? (
                                    <div
                                      className={newIconClass}
                                      style={{
                                        display: 'block',
                                        color: '#E9A40A',
                                        whiteSpace: 'pre'
                                      }}
                                    >
                                      {elem.isNew ||
                                      elem.istranslatedNew ||
                                      elem.answers.find(
                                        e =>
                                          e.isNew === true ||
                                          e.istranslatedNew === true
                                      ) ? (
                                        <>
                                          <img
                                            style={{ marginLeft: '5px' }}
                                            src={groupIcon}
                                            alt={groupIcon}
                                            width="25"
                                            height="25"
                                          ></img>
                                          &nbsp; New Master
                                        </>
                                      ) : (
                                        ''
                                      )}
                                    </div>
                                  ) : null}
                                  <div className="col-sm-1">
                                    <span
                                      type="button"
                                      className={
                                        this.state.questionAccordion === index
                                          ? 'saas-accordion-arrow afterTransform'
                                          : 'saas-accordion-arrow'
                                      }
                                      onClick={() => {
                                        this.handleQuestionsAccordion(index);
                                      }}
                                    >
                                      <i
                                        className={
                                          this.state.questionAccordion === index
                                            ? 'fas fa-chevron-down transformProfileIcon-180'
                                            : 'fas fa-chevron-down'
                                        }
                                      ></i>
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div
                                className={
                                  this.state.questionAccordion === index
                                    ? 'collapse show'
                                    : 'collapse'
                                }
                              >
                                <div
                                  className="cw-campaign--review-section-content"
                                  style={{ marginTop: '10px' }}
                                >
                                  <div className="cw-question-answer-dotted-line">
                                    <div className="cw-heading-qna">
                                      <span
                                        className="float-left  fa fa-circle mt-10"
                                        style={{ marginTop: '5px' }}
                                      ></span>
                                      <strong style={{ marginLeft: '15px' }}>
                                        Question
                                      </strong>
                                    </div>
                                    <div className="cw-striped-qna-row">
                                      <div className="row">
                                        <div
                                          className={`${colWidth} cw-qna-lable`}
                                        >
                                          Question Type
                                        </div>
                                        <div
                                          className={`${
                                            epsilonMarket(country)
                                              ? `col-sm-8`
                                              : colWidth
                                          } cw-text--ternary`}
                                        >
                                          {elem.label}
                                        </div>
                                      </div>
                                    </div>
                                    {!epsilonMarket(country) ? (
                                      <div className="cw-striped-qna-row">
                                        <div className="row">
                                          <div
                                            className={`${colWidth} cw-qna-lable`}
                                          >
                                            English-Master Data
                                          </div>
                                          <div
                                            className={`${colWidth} cw-text--ternary`}
                                            style={{ lineBreak: 'anywhere' }}
                                          >
                                            {elem.englishLabel}
                                          </div>
                                          {this.props.showMasterIcon ? null : (
                                            <div
                                              className={`${colWidth} text-center cw-text--ternary`}
                                            >
                                              {elem.isNew
                                                ? elem.id
                                                  ? elem.id
                                                  : 'Awaiting'
                                                : elem.id}
                                            </div>
                                          )}
                                          {newMasterData(elem.isNew, colWidth)}
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="cw-striped-qna-row">
                                        <div className="row">
                                          <div
                                            className={`${colWidth} cw-qna-lable`}
                                          >
                                            Consumer Facing Label
                                          </div>
                                          <div
                                            className={`${
                                              epsilonMarket(country)
                                                ? `col-sm-8`
                                                : colWidth
                                            } cw-text--ternary`}
                                          >
                                            {elem.questionLabel}
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                    {!isCampaignEnglish &&
                                    !epsilonMarket(country) ? (
                                      <div className="cw-striped-qna-row">
                                        <div className="row">
                                          <div
                                            className={`${colWidth} cw-qna-lable`}
                                          >
                                            {campaignLanguage} Translation-
                                            Master Data
                                          </div>
                                          <div
                                            className={`${colWidth} cw-text--ternary`}
                                            style={{ lineBreak: 'anywhere' }}
                                          >
                                            {elem.translatedLabel}
                                          </div>
                                          {this.props.showMasterIcon ? null : (
                                            <div
                                              className={`${colWidth} text-center cw-text--ternary`}
                                            >
                                              {elem.istranslatedNew
                                                ? elem.translatedId
                                                  ? elem.translatedId
                                                  : 'Awaiting'
                                                : elem.translatedId}
                                            </div>
                                          )}
                                          {newMasterData(
                                            elem.isNew || elem.istranslatedNew,
                                            colWidth
                                          )}
                                        </div>
                                      </div>
                                    ) : null}
                                    {!epsilonMarket(country) ? (
                                      <div className="cw-striped-qna-row">
                                        <div className="row">
                                          <div
                                            className={`${colWidth} cw-qna-lable`}
                                          >
                                            Consumer Facing Label
                                          </div>
                                          <div
                                            className={`${colWidth} cw-text--ternary`}
                                          >
                                            {elem.questionLabel}
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="cw-striped-qna-row">
                                        <div className="row">
                                          <div
                                            className={`${colWidth} cw-qna-lable`}
                                          >
                                            Question ID
                                          </div>
                                          <div className="col-sm-8 cw-text--ternary">
                                            {elem.id}
                                          </div>
                                          {newMasterData(elem.isNew, colWidth)}
                                        </div>
                                      </div>
                                    )}
                                    {elem.isNew &&
                                    !epsilonMarket(country) &&
                                    elem.reasonForCreation ? (
                                      <div className="cw-striped-qna-row">
                                        <div className="row">
                                          <div
                                            className={`${colWidth} cw-qna-lable`}
                                          >
                                            Reason for new request
                                          </div>
                                          <div
                                            className={`${
                                              epsilonMarket(country)
                                                ? `col-sm-8`
                                                : colWidth
                                            } cw-text--ternary`}
                                          >
                                            {elem.reasonForCreation}
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>

                                  {elem.answers && elem.answers.length > 0 ? (
                                    <>
                                      <br />
                                      <div className="cw-question-answer-dotted-line">
                                        <div className="cw-heading-qna">
                                          <span
                                            className="float-left  fa fa-circle mt-10"
                                            style={{ marginTop: '5px' }}
                                          ></span>
                                          <strong
                                            style={{ marginLeft: '15px' }}
                                          >
                                            Answers
                                          </strong>
                                        </div>
                                        {!epsilonMarket(country) &&
                                          elem.answers.map(
                                            (answer, answerIndex) => {
                                              return (
                                                <>
                                                  <strong
                                                    style={{
                                                      marginLeft: '30px'
                                                    }}
                                                  >
                                                    Answer {answerIndex + 1}
                                                  </strong>

                                                  <div className="cw-striped-qna-row">
                                                    <div className="row">
                                                      <div
                                                        className={`${colWidth} cw-qna-lable`}
                                                      >
                                                        English-Master Data
                                                      </div>
                                                      <div
                                                        className={`${colWidth} cw-text--ternary`}
                                                        style={{
                                                          lineBreak: 'anywhere'
                                                        }}
                                                      >
                                                        {answer.englishLabel}
                                                      </div>
                                                      {this.props
                                                        .showMasterIcon ? null : (
                                                        <div
                                                          className={`${colWidth} text-center cw-text--ternary`}
                                                        >
                                                          {answer.isNew
                                                            ? answer.value
                                                              ? answer.value
                                                              : 'Awaiting'
                                                            : answer.value}
                                                        </div>
                                                      )}
                                                      {newMasterData(
                                                        answer.isNew,
                                                        colWidth
                                                      )}
                                                    </div>
                                                  </div>
                                                  {!isCampaignEnglish ? (
                                                    <div className="cw-striped-qna-row">
                                                      <div className="row">
                                                        <div
                                                          className={`${colWidth} cw-qna-lable`}
                                                        >
                                                          {campaignLanguage}{' '}
                                                          Translation- Master
                                                          Data
                                                        </div>
                                                        <div
                                                          className={`${colWidth} cw-text--ternary`}
                                                          style={{
                                                            lineBreak:
                                                              'anywhere'
                                                          }}
                                                        >
                                                          {
                                                            answer.translatedLabel
                                                          }
                                                        </div>
                                                        {this.props
                                                          .showMasterIcon ? null : (
                                                          <div
                                                            className={`${colWidth} text-center cw-text--ternary`}
                                                          >
                                                            {answer.istranslatedNew
                                                              ? answer.translatedId
                                                                ? answer.translatedId
                                                                : 'Awaiting'
                                                              : answer.translatedId}
                                                          </div>
                                                        )}
                                                        {newMasterData(
                                                          answer.isNew ||
                                                            answer.istranslatedNew,
                                                          colWidth
                                                        )}
                                                      </div>
                                                    </div>
                                                  ) : null}
                                                  <div className="cw-striped-qna-row">
                                                    <div className="row">
                                                      <div
                                                        className={`${colWidth} cw-qna-lable`}
                                                      >
                                                        Consumer Facing Label
                                                      </div>
                                                      <div
                                                        className={`${colWidth} cw-text--ternary`}
                                                      >
                                                        {answer.answerLabel}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </>
                                              );
                                            }
                                          )}
                                        {epsilonMarket(country) &&
                                          elem.answers.map(
                                            (answer, answerIndex) => {
                                              return (
                                                <>
                                                  {elem.value !== 'freeText' ? (
                                                    <div className="cw-striped-qna-row">
                                                      <div className="row">
                                                        <div
                                                          className={`${colWidth} cw-qna-lable`}
                                                        >
                                                          Consumer Facing Label
                                                        </div>
                                                        <div
                                                          className={`${
                                                            epsilonMarket(
                                                              country
                                                            )
                                                              ? `col-sm-8`
                                                              : colWidth
                                                          } cw-text--ternary`}
                                                        >
                                                          {answer.answerLabel}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  ) : null}
                                                  <div className="cw-striped-qna-row">
                                                    <div className="row">
                                                      <div
                                                        className={`${colWidth} cw-qna-lable`}
                                                      >
                                                        {elem.value !==
                                                        'freeText'
                                                          ? `Answer ${answerIndex +
                                                              1} ID`
                                                          : `Answer ID`}
                                                      </div>
                                                      <div
                                                        className={`${
                                                          epsilonMarket(country)
                                                            ? `col-sm-8`
                                                            : colWidth
                                                        } cw-text--ternary`}
                                                      >
                                                        {answer.value}
                                                      </div>
                                                      {newMasterData(
                                                        answer.isNew,
                                                        colWidth
                                                      )}
                                                    </div>
                                                  </div>
                                                  <div className="cw-striped-qna-row">
                                                    <div className="row">
                                                      <div
                                                        className={`${colWidth} cw-qna-lable`}
                                                      >
                                                        {elem.value !==
                                                        'freeText'
                                                          ? `Survey Question Answer ${answerIndex +
                                                              1} ID`
                                                          : `Survey Question Answer ID`}
                                                      </div>
                                                      <div
                                                        className={`${
                                                          epsilonMarket(country)
                                                            ? `col-sm-8`
                                                            : colWidth
                                                        } cw-text--ternary`}
                                                      >
                                                        {
                                                          answer.epsilonQuestionAnswerId
                                                        }
                                                      </div>
                                                      {newMasterData(
                                                        answer.isNew,
                                                        colWidth
                                                      )}
                                                    </div>
                                                  </div>
                                                </>
                                              );
                                            }
                                          )}

                                        {/* {elem.value==="multipleChoice" ? (
                            <div className="cw-striped-row">
                                <div className="row">
                                  <div className="col-sm-4">
                                    <strong>Maximum number of answers user can select</strong>
                                  </div>
                                  <div className="col-sm-8 cw-text--ternary">{elem.maxAnswerLimit}
                                  </div>
                              </div>
                            </div>
                            ):null} */}
                                      </div>
                                    </>
                                  ) : null}
                                  <br />
                                  <div className="cw-question-answer-dotted-line">
                                    <div className="cw-heading-qna">
                                      <span
                                        className="float-left  fa fa-circle mt-10"
                                        style={{ marginTop: '5px' }}
                                      ></span>
                                      <strong style={{ marginLeft: '15px' }}>
                                        Other Configurations
                                      </strong>
                                    </div>
                                    {elem.toolTip &&
                                    elem.toolTip.trim().length > 0 ? (
                                      <div className="cw-striped-qna-row">
                                        <div className="row">
                                          <div
                                            className={`${colWidth} cw-qna-lable`}
                                          >
                                            Tooltip/Help-Text
                                          </div>
                                          <div
                                            className={`${
                                              epsilonMarket(country)
                                                ? `col-sm-8`
                                                : colWidth
                                            } cw-text--ternary`}
                                          >
                                            {elem.toolTip}
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}
                                    <div className="cw-striped-qna-row">
                                      <div className="row">
                                        <div
                                          className={`${colWidth} cw-qna-lable`}
                                        >
                                          Mandatory field
                                        </div>
                                        <div
                                          className={`${
                                            epsilonMarket(country)
                                              ? `col-sm-8`
                                              : colWidth
                                          } cw-text--ternary`}
                                        >
                                          {elem.mandatoryToggle
                                            ? 'Mandatory'
                                            : 'Optional'}
                                        </div>
                                      </div>
                                    </div>
                                    {elem.mandatoryToggle ? (
                                      <div className="cw-striped-qna-row">
                                        <div className="row">
                                          <div
                                            className={`${colWidth} cw-qna-lable`}
                                          >
                                            Mandatory Field Error Message
                                          </div>
                                          <div
                                            className={`${
                                              epsilonMarket(country)
                                                ? `col-sm-8`
                                                : colWidth
                                            } cw-text--ternary`}
                                          >
                                            {elem.mandatoryMessage}
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
            </div>
          </>
        ) : null}
        {/* q&a ends here */}
        {isCampaignIncentive ? (
          <>
            <div className="mb-20">
              <div className="cw-campaign--review-section-header mb-3 d-flex align-items-center clearfix">
                <h3 className="cw-heading--senary">Incentive Fields</h3>
                {this.props.isEditButton ? (
                  <NavLink
                    className="btn btn-outline-secondary ml-auto"
                    to={
                      '/campaign-wizard/campaign/' +
                      this.campaignId +
                      '/saas-incentive-fields'
                    }
                  >
                    Edit
                  </NavLink>
                ) : (
                  ''
                )}
              </div>
              <div
                className="cw-striped-profile-row mb-2"
                style={{ backgroundColor: '#f9f9f9' }}
              >
                <div className="row">
                  <div className="col-sm-4">
                    <strong>Promotigo Form ID</strong>
                  </div>
                  <div className="col-sm-8 cw-text--ternary">
                    {campaign.campaignDetail &&
                    campaign.campaignDetail.promotigoFormId
                      ? campaign.campaignDetail &&
                        campaign.campaignDetail.promotigoFormId
                      : 'Awaiting'}
                  </div>
                </div>
              </div>
              {isIncentiveTypeCompetition ? (
                <div
                  className="cw-striped-profile-row mb-2"
                  style={{ backgroundColor: '#f9f9f9' }}
                >
                  <div className="row">
                    <div className="col-sm-4">
                      <strong>Instant Win/Lose</strong>
                    </div>
                    <div className="col-sm-8 cw-text--ternary">
                      {campaign.campaignDetail &&
                      campaign.campaignDetail.instantWinLose
                        ? 'Yes'
                        : 'No'}
                    </div>
                  </div>
                </div>
              ) : null}
              {campaignDetails.fields &&
                campaignDetails.fields
                  .filter(elem => elem.type === 'incentiveFields')
                  .map((elem, index) => {
                    return (
                      <div className={`mb-10`}>
                        <div className="accordion saas-form-field-accordion">
                          <div className="card" style={{ border: 0 }}>
                            <div
                              className={`card-header accordian-card-header ${
                                this.state.incentiveAccordion === index
                                  ? 'card-bottom-border'
                                  : 'card-no-bottom'
                              }`}
                              style={{ padding: '7px 13px' }}
                            >
                              <span
                                className="saas-accordion-heading"
                                style={{ fontWeight: '700' }}
                              >
                                {elem.label}
                              </span>
                              <span
                                type="button"
                                className={
                                  this.state.incentiveAccordion === index
                                    ? 'saas-accordion-arrow afterTransform'
                                    : 'saas-accordion-arrow'
                                }
                                onClick={() => {
                                  this.handleIncentiveAccordion(index);
                                }}
                              >
                                <i
                                  className={
                                    this.state.incentiveAccordion === index
                                      ? 'fas fa-chevron-down transformProfileIcon-180'
                                      : 'fas fa-chevron-down'
                                  }
                                ></i>
                              </span>
                            </div>
                            <div
                              className={
                                this.state.incentiveAccordion === index
                                  ? 'collapse show'
                                  : 'collapse'
                              }
                            >
                              <div className="cw-campaign--review-section-content">
                                <div className="cw-striped-profile-row">
                                  <div className="row">
                                    <div className="col-sm-4 cw-qna-lable">
                                      Field Label
                                    </div>
                                    <div className="col-sm-8 cw-text--ternary">
                                      {elem.fieldLabel ? elem.fieldLabel : ''}
                                    </div>
                                  </div>
                                </div>

                                {isIncentiveTypeSamples &&
                                elem.dataType === 'samplePrizeSelection' ? (
                                  <>
                                    <div className="cw-striped-profile-row">
                                      <div className="row">
                                        <div className="col-sm-4 cw-qna-lable">
                                          Samples
                                        </div>
                                        <div className="col-sm-8 cw-text--ternary">
                                          {elem.answers &&
                                          elem.answers.length > 0
                                            ? elem.answers
                                                .filter(ele => ele.label)
                                                .map(ele => ele.label)
                                                .join(', ')
                                            : 'Awaiting'}
                                        </div>
                                      </div>
                                    </div>
                                    {elem.answers && elem.answers.length > 1 ? (
                                      <div className="cw-striped-profile-row">
                                        <div className="row">
                                          <div className="col-sm-4 cw-qna-lable">
                                            Sample Selection
                                          </div>
                                          <div className="col-sm-8 cw-text--ternary">
                                            {elem.questionType ===
                                            'Single choice'
                                              ? constant.SAMPLE_OPTIONS
                                                  .singleSample
                                              : elem.questionType ===
                                                'Multiple choice'
                                              ? constant.SAMPLE_OPTIONS
                                                  .multipleSample
                                              : null}
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}
                                  </>
                                ) : null}

                                {elem.dataType === 'fileUpload' ? (
                                  <>
                                    {elem.maxFileSize ? (
                                      <div className="cw-striped-profile-row">
                                        <div className="row">
                                          <div className="col-sm-4 cw-qna-lable">
                                            Maximum Upload File Size
                                          </div>
                                          <div className="col-sm-8 cw-text--ternary">
                                            {elem.maxFileSize
                                              ? elem.maxFileSize
                                              : ''}
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}
                                    {elem.values &&
                                    elem.values
                                      .filter(ele => ele.isChecked)
                                      .map(ele => ele.value)
                                      .join(', ').length > 0 ? (
                                      <div className="cw-striped-profile-row">
                                        <div className="row">
                                          <div className="col-sm-4 cw-qna-lable">
                                            Allowed File Types
                                          </div>
                                          <div className="col-sm-8 cw-text--ternary">
                                            {elem.values &&
                                            elem.values.length > 0
                                              ? elem.values
                                                  .filter(ele => ele.isChecked)
                                                  .map(ele => ele.value)
                                                  .join(', ')
                                              : ''}
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}

                                    <div className="cw-striped-profile-row">
                                      <div className="row">
                                        <div className="col-sm-4 cw-qna-lable">
                                          Failed Upload Error Message
                                        </div>
                                        <div className="col-sm-8 cw-text--ternary">
                                          {elem.failedUploadMessage
                                            ? elem.failedUploadMessage
                                            : ''}
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ) : null}

                                {elem.toolTip &&
                                elem.toolTip.trim().length > 0 ? (
                                  <div className="cw-striped-profile-row">
                                    <div className="row">
                                      <div className="col-sm-4 cw-qna-lable">
                                        Tooltip/Help-Text
                                      </div>
                                      <div className="col-sm-8 cw-text--ternary">
                                        {elem.toolTip ? elem.toolTip : ''}
                                      </div>
                                    </div>
                                  </div>
                                ) : null}

                                <div className="cw-striped-profile-row">
                                  <div className="row">
                                    <div className="col-sm-4 cw-qna-lable">
                                      Mandatory field
                                    </div>
                                    <div className="col-sm-8 cw-text--ternary">
                                      {elem.mandatoryToggle
                                        ? 'Mandatory'
                                        : 'Optional'}
                                    </div>
                                  </div>
                                </div>

                                {elem.mandatoryToggle ? (
                                  <div className="cw-striped-profile-row">
                                    <div className="row">
                                      <div className="col-sm-4 cw-qna-lable">
                                        Mandatory Field Error Message
                                      </div>
                                      <div className="col-sm-8 cw-text--ternary">
                                        {elem.mandatoryMessage
                                          ? elem.mandatoryMessage
                                          : ''}
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                {elem.validationMessage &&
                                elem.validationMessage.trim().length > 0 ? (
                                  <div className="cw-striped-profile-row">
                                    <div className="row">
                                      <div className="col-sm-4 cw-qna-lable">
                                        Format Validation Error Message
                                      </div>
                                      <div className="col-sm-8 cw-text--ternary">
                                        {elem.validationMessage
                                          ? elem.validationMessage
                                          : ''}
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
            </div>
          </>
        ) : null}
        <br />
        <div className="mb-40">
          <div className="cw-campaign--review-section-header mb-3 d-flex align-items-center">
            <h3 className="cw-heading--senary">
              Consent and Compliance Fields
            </h3>
            {this.props.isEditButton ? (
              <NavLink
                className="btn btn-outline-secondary ml-auto"
                to={
                  '/campaign-wizard/campaign/' +
                  this.campaignId +
                  this.props.consentPage
                }
              >
                Edit
              </NavLink>
            ) : (
              ''
            )}
          </div>

          {campaignDetails.consents &&
            campaignDetails.consents.map((elem, index) => {
              return (
                <>
                  <div className="mb-10">
                    <div className="accordion saas-form-field-accordion">
                      <div className="card" style={{ border: 0 }}>
                        <div
                          className={`card-header accordian-card-header ${
                            this.state.consentAccordion === index
                              ? 'card-bottom-border'
                              : 'card-no-bottom'
                          }`}
                          style={{ padding: '7px 13px' }}
                        >
                          <span
                            className="saas-accordion-heading"
                            style={{ fontWeight: '700' }}
                          >
                            {elem.optInType === 'privacyNotice'
                              ? 'Privacy Notice'
                              : elem.label}
                          </span>
                          <span
                            type="button"
                            className={
                              this.state.consentAccordion === index
                                ? 'saas-accordion-arrow afterTransform'
                                : 'saas-accordion-arrow'
                            }
                            onClick={() => {
                              this.handleConsentAccordion(index);
                            }}
                          >
                            <i
                              className={
                                this.state.consentAccordion === index
                                  ? 'fas fa-chevron-down transformProfileIcon-180'
                                  : 'fas fa-chevron-down'
                              }
                            ></i>
                          </span>
                        </div>
                        <div
                          className={
                            this.state.consentAccordion === index
                              ? 'collapse show'
                              : 'collapse'
                          }
                        >
                          <div className="cw-campaign--review-section-content">
                            {elem.optInType !== 'privacyNotice' &&
                            elem.optInType !== 'legalDisclaimer' &&
                            elem.optInType !== 'contentInterestAreas' ? (
                              <div className="cw-striped-profile-row">
                                <div className="row">
                                  <div className="col-sm-4 cw-qna-lable">
                                    Display Options
                                  </div>
                                  <div className="col-sm-8 cw-text--ternary">
                                    {elem.consentType === 'mandatory'
                                      ? 'Mandatory Checkbox'
                                      : elem.consentType === 'optional'
                                      ? 'Non-mandatory Checkbox'
                                      : elem.consentType === 'implicit'
                                      ? 'Statement'
                                      : elem.consentType}
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {elem.optInType !== 'privacyNotice' &&
                            elem.optInType !== 'legalDisclaimer' &&
                            elem.consentType === 'mandatory' ? (
                              <div className="cw-striped-profile-row">
                                <div className="row">
                                  <div className="col-sm-4 cw-qna-lable">
                                    Mandatory Error Message
                                  </div>
                                  <div className="col-sm-8 cw-text--ternary">
                                    {elem.mandatoryMessage
                                      ? elem.mandatoryMessage
                                      : ''}
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {elem.optInType === 'legalAgeConsent' ? (
                              <div className="cw-striped-profile-row">
                                <div className="row">
                                  <div className="col-sm-4 cw-qna-lable">
                                    Minimum legal age of consent
                                  </div>
                                  <div className="col-sm-8 cw-text--ternary">
                                    {elem.ageOfConsent
                                      ? elem.ageOfConsent
                                      : '16'}
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {elem.defaultCopy && (
                              <div className="cw-striped-profile-row">
                                <div className="row">
                                  <div className="col-sm-4 cw-qna-lable">
                                    Consent Statement
                                  </div>
                                  <div className="col-sm-8 cw-text--ternary">
                                    <p>
                                      {elem.defaultCopy
                                        ? ReactHtmlParser(elem.defaultCopy)
                                        : null}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            )}
                            {elem.optInType === 'additionalSubscription' ||
                            elem.optInType === 'additionalSubscription1' ? (
                              <>
                                {elem.frequency ? (
                                  <div className="cw-striped-profile-row">
                                    <div className="row">
                                      <div className="col-sm-4 cw-qna-lable">
                                        Frequency
                                      </div>
                                      <div className="col-sm-8 cw-text--ternary">
                                        {elem.frequency}
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                {elem.serviceId ? (
                                  <div className="cw-striped-profile-row">
                                    <div className="row">
                                      <div className="col-sm-4 cw-qna-lable">
                                        Service ID
                                      </div>
                                      <div className="col-sm-8 cw-text--ternary">
                                        {elem.serviceId}
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                              </>
                            ) : null}
                            {elem.optInType === 'brandEmailConsent' &&
                            !isEpsilonMarket ? (
                              <>
                                <div className="cw-striped-profile-row">
                                  <div className="row">
                                    <div className="col-sm-4 cw-qna-lable">
                                      Email Senders Name
                                    </div>
                                    <div className="col-sm-8 cw-text--ternary">
                                      {elem.emailSenderName
                                        ? elem.emailSenderName
                                        : null}
                                    </div>
                                  </div>
                                </div>
                                {elem.replyToEmailName &&
                                elem.replyToEmailName.trim().length > 0 ? (
                                  <div className="cw-striped-profile-row">
                                    <div className="row">
                                      <div className="col-sm-4 cw-qna-lable">
                                        Reply to Email Name
                                      </div>
                                      <div className="col-sm-8 cw-text--ternary">
                                        {elem.replyToEmailName
                                          ? elem.replyToEmailName
                                          : null}
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                {elem.replyToEmailAdderess &&
                                elem.replyToEmailAdderess.trim().length > 0 ? (
                                  <div className="cw-striped-profile-row">
                                    <div className="row">
                                      <div className="col-sm-4 cw-qna-lable">
                                        Reply to Email Address
                                      </div>
                                      <div className="col-sm-8 cw-text--ternary">
                                        {elem.replyToEmailAdderess
                                          ? elem.replyToEmailAdderess
                                          : null}
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                              </>
                            ) : null}
                            {elem.optInType === 'contentInterestAreas' ? (
                              <>
                                <div className="cw-striped-profile-row">
                                  <div className="row">
                                    <div className="col-sm-4 cw-qna-lable">
                                      Note
                                    </div>
                                    <div className="col-sm-8 cw-text--ternary">
                                      Content Interest Areas will render on the
                                      sign up form as a question to consumers to
                                      understand the kind of content they are
                                      interested in. Consumers will be presented
                                      with an optional question and can choose
                                      multiple interest areas. The labels of the
                                      field and values will remain fixed as of
                                      now but will display in local language of
                                      the form
                                    </div>
                                  </div>
                                </div>
                                <div className="cw-striped-profile-row">
                                  <div className="row">
                                    <div className="col-sm-4 cw-qna-lable">
                                      Field label
                                    </div>
                                    <div className="col-sm-8 cw-text--ternary">
                                      Are there any of these areas you would
                                      like to hear more on from our brands
                                    </div>
                                  </div>
                                </div>
                                <div className="cw-striped-profile-row">
                                  <div className="row">
                                    <div className="col-sm-4 cw-qna-lable">
                                      Field values
                                    </div>
                                    <div className="col-sm-8 cw-text--ternary">
                                      <ul className="cia-list-review">
                                        <li>Parenting &amp; Family</li>
                                        <li>Planet, Nature and Society</li>
                                        <li>Home &amp; Hygiene</li>
                                        <li>Food &amp; Recipes</li>
                                        <li>Beauty &amp; Styling</li>
                                        <li>
                                          Wellness, Nutrition and Active
                                          Lifestyle
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <div className="cw-striped-profile-row">
                                  <div className="row">
                                    <div className="col-sm-4 cw-qna-lable">
                                      Mandatory
                                    </div>
                                    <div className="col-sm-8 cw-text--ternary">
                                      No
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
        </div>
        <>
          <div className="cw-campaign--review-section-header mb-3 d-flex align-items-center clearfix">
            <h3 className="cw-heading--senary">Generic Fields</h3>
            {this.props.isEditButton ? (
              <NavLink
                className="btn btn-outline-secondary ml-auto"
                to={
                  '/campaign-wizard/campaign/' +
                  this.campaignId +
                  this.props.genericPage
                }
              >
                Edit
              </NavLink>
            ) : (
              ''
            )}
          </div>
          <div className="mb-20">
            <div className="cw-striped-profile-row">
              <div className="row">
                <div className="col-sm-4">
                  <strong>Submit Button Label</strong>
                </div>
                <div className="col-sm-8 cw-text--ternary">
                  {messages && messages.submit}
                </div>
              </div>
            </div>
            <div className="cw-striped-profile-row">
              <div className="row">
                <div className="col-sm-4">
                  <strong>Submission Failure Message</strong>
                </div>
                <div className="col-sm-8 cw-text--ternary">
                  {messages && messages.submissionError}
                </div>
              </div>
            </div>
            <div className="cw-striped-profile-row">
              <div className="row">
                <div className="col-sm-4">
                  <strong>Campaign Expiry Message</strong>
                </div>
                <div className="col-sm-8 cw-text--ternary">
                  {messages && messages.campaignExpiry}
                </div>
              </div>
            </div>
            <div className="cw-striped-profile-row">
              <div className="row">
                <div className="col-sm-4 ">
                  <strong> Mandatory Fields Indicator Text</strong>
                </div>
                <div className="col-sm-8 cw-text--ternary">
                  {messages && messages.mandatoryFieldsIndicator}
                </div>
              </div>
            </div>
            <div className="cw-striped-profile-row"></div>
          </div>
        </>

        {epsilonMarket(country) ? (
          <>
            <div className="cw-campaign--review-section-header mb-3 d-flex align-items-center clearfix mt-40">
              <h3 className="cw-heading--senary">Epsilon Master Data</h3>
              {this.props.isEditButton ? (
                <NavLink
                  className="btn btn-outline-secondary ml-auto"
                  to={
                    '/campaign-wizard/campaign/' +
                    this.campaignId +
                    '/saas-epsilon-masterdata'
                  }
                >
                  Edit
                </NavLink>
              ) : (
                ''
              )}
            </div>
            <div className="mb-20">
              <div className="cw-striped-profile-row">
                <div className="row">
                  <div className="col-sm-4">
                    <strong>Data Source ID</strong>
                  </div>
                  <div className="col-sm-8 cw-text--ternary">
                    {dataSourceId}
                  </div>
                </div>
              </div>
              <div className="cw-striped-profile-row">
                <div className="row">
                  <div className="col-sm-4">
                    <strong>Campaign Control ID (CCID)</strong>
                  </div>
                  <div className="col-sm-8 cw-text--ternary">
                    {campaignControlId}
                  </div>
                </div>
              </div>
              {brandOptinCCID ? (
                <div className="cw-striped-profile-row">
                  <div className="row">
                    <div className="col-sm-4">
                      <strong>Brand Opt-in CCID</strong>
                    </div>
                    <div className="col-sm-8 cw-text--ternary">
                      {brandOptinCCID}
                    </div>
                  </div>
                </div>
              ) : null}
              {corporateOptinCCID ? (
                <div className="cw-striped-profile-row">
                  <div className="row">
                    <div className="col-sm-4">
                      <strong>Corporate Opt-in CCID</strong>
                    </div>
                    <div className="col-sm-8 cw-text--ternary">
                      {corporateOptinCCID}
                    </div>
                  </div>
                </div>
              ) : null}
              {languageQuestionAnsId ? (
                <div className="cw-striped-profile-row">
                  <div className="row">
                    <div className="col-sm-4">
                      <strong>Language Question Answer ID</strong>
                    </div>
                    <div className="col-sm-8 cw-text--ternary">
                      {languageQuestionAnsId}
                    </div>
                  </div>
                </div>
              ) : null}
              {languageAnsId ? (
                <div className="cw-striped-profile-row">
                  <div className="row">
                    <div className="col-sm-4">
                      <strong>Language Answer ID</strong>
                    </div>
                    <div className="col-sm-8 cw-text--ternary">
                      {languageAnsId}
                    </div>
                  </div>
                </div>
              ) : null}
              {languageAnsText ? (
                <div className="cw-striped-profile-row">
                  <div className="row">
                    <div className="col-sm-4">
                      <strong>Language Answer Text</strong>
                    </div>
                    <div className="col-sm-8 cw-text--ternary">
                      {languageAnsText}
                    </div>
                  </div>
                </div>
              ) : null}
              <div class="cw-striped-profile-row"></div>
            </div>
          </>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  campaign: state.overview.campaign,
  campaignDetails: state.overview.campaignDetails
});

export default connect(mapStateToProps, {
  fetchCampaignData
})(FormAuthoring);
