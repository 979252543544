import React, { useEffect ,useState} from 'react';
import { Input } from '../../atoms/Input';
import { FieldError } from '../../atoms/FieldError';
import { get } from 'lodash';
import classNames from 'classnames';

export const FormEmailRadioInput = ({
  name,
  type,
  rules,
  register,
  label,
  errors,
  options,
  defaultValue,
  getValues,
  setValue,
  ...props
}) => {
  const errorMessages = get(errors, name);
  const hasError = !!(errors && errorMessages);
  
  const [selectedValue,setSelectedValue] = useState(defaultValue)
  useEffect(() => {
    if (setValue && defaultValue) {
      setSelectedValue(defaultValue)
      setValue(name, defaultValue, { shouldTouch: true });
    }
  }, [defaultValue]);
  
  const onChange = (e)=>{
    setValue(name,e.target.value);
    setSelectedValue(e.target.value)
  }

  return (
    <>
      {options.map(
        ({
          labelClass,
          helpText,
          defaultChecked,
          imageIcon,
          isEmailView,
          description,
          ...option
        }) => (
          <div
            className={classNames('form-check mb-10 select-email-type-wrapper', {"selected":selectedValue === option.value})}
            key={option.id}
            aria-live="polite"
          >
            <Input
              name={name}
              {...option}
              // {...(register && register(name, rules))}
              onChange = {onChange}
              type={`radio`}
              defaultChecked={defaultValue === option.value}
              checked={selectedValue === option.value}
            />
           
              <label
                htmlFor={option.id}
                className={classNames('form-check-label', labelClass)}
              >
                <img src={imageIcon} alt={imageIcon} />
                <div className="d-flex flex-column">
                  <p className="font-weight-bold mb-1">{option.label}</p>
                  <div className='email-type-desc-font'>{description}</div>
                </div>
                {helpText && <p className="">{helpText}</p>}
              </label>
           
          </div>
        )
      )}
      {!props.disableError && <FieldError errors={errors} name={name} />}
    </>
  );
};
