import * as yup from 'yup';
import React from 'react';
import {
  setEmailLoader,
  updateBrandDetails
} from '../../actions/emailbriefing/emailBrandDetails';
import {
  getCountries,
  getBrandByCountry,
  getBrandDetails
} from 'services/emailBriefings';
import { _generateUUID } from 'selectors';
import { CantFindContryHelpLink } from './CantFindCountryModal';
import constants from '../../constants';
import { MultiBrandCheck } from './MultiBrandCheck';

export const getIsEdited = componentProps => {
  return (
    componentProps &&
    componentProps.history &&
    componentProps.history.location &&
    componentProps.history.location.state &&
    componentProps.history.location.state.isEdited
  );
};

const formSchema = (dispatch, componentProps, defaultValues) => {
  const isEdit = componentProps.match.params.id;
  const isEdited = getIsEdited(componentProps);
  const { emailDesign, history } = componentProps;

  const brandShape = {
    label: yup.string().required(),
    value: yup.string().required(),
    id: yup.string().required()
  };

  const onSubmit = (data, { user }, { backToReview } = {}) => {
    try {
      const brandDetails = {
        ...data,
        brand:
          componentProps.isCrossBrand || Array.isArray(data.brand)
            ? data.brand
            : [data.brand],
        isCrossBrand: componentProps.isCrossBrand,
        uid: _generateUUID(),
        requestersEmail: user.emailId
      };
      const uid = isEdit ? componentProps.match.params.id : false;
      dispatch(
        updateBrandDetails(uid, brandDetails, (error, result) => {
          if (result) {
            if (backToReview && getIsEdited(componentProps)) {
              const status = emailDesign?.emailBriefing?.status;
              if (status === constants.EMAIL_STATUS.AWAITING_FOR_APPROVAL) {
                history.push(`/campaign-wizard/emailbriefing/${uid}/approval`);
              } else if (
                status === constants.EMAIL_STATUS.DRAFT ||
                status === constants.EMAIL_STATUS.REJECTED
              ) {
                history.push(`/campaign-wizard/emailbriefing/${uid}/review`);
              }
            } else {
              isEdit
                ? componentProps.history.push(
                    `/campaign-wizard/emailbriefing/${uid ||
                      brandDetails.uid}/emailtype`
                  )
                : componentProps.history.push(
                    '/campaign-wizard/new-email-campaign-emailtype'
                  );
            }
          } else {
            console.log('onSubmit error: ', error);
          }
        })
      );
    } catch (error) {
      console.log('onSubmit error: ', error);
    }
  };

  return {
    formType: 'custombuttons',
    submitBtnLabel: isEdited ? 'Save and back to review' : 'Next',
    onSecondaryClick: onSubmit,
    onSubmitSecondary: () => {},
    isEdit: isEdited,
    secondaryBtnLabel: 'Next',
    fields: [
      {
        type: 'reactselect',
        name: 'country',
        id: 'country',
        label: 'Country',
        rules: yup
          .object()
          .shape({
            label: yup.string().required(),
            value: yup.string().required(),
            id: yup.string().required()
          })
          .required('Please select country')
          .default(undefined),
        options: [],
        defaultValue: defaultValues.country,
        defaultDisabled: true,
        isEdit,
        onLoad: (properties, setProperties) => {
          dispatch(setEmailLoader(true));
          getCountries()
            .then(response => {
              let countries = response.map(country => {
                return {
                  value: country.code,
                  label: country.name,
                  id: country._id
                };
              });
              setProperties({
                ...properties,
                options: countries,
                disabled: false
              });
              dispatch(setEmailLoader(false));
            })
            .catch(e => {
              dispatch(setEmailLoader(true));
            });
        },
        customHelpComponent: CantFindContryHelpLink,
        customHelpComponentProps: {
          setIsModelOpen: componentProps.setIsModelOpen
        }
      },
      {
        type: 'reactselect',
        name: 'brand',
        id: 'brand',
        label: 'Brand',
        rules: yup.lazy(value =>
          !Array.isArray(value)
            ? yup
                .object()
                .required('Please select brand')
                .typeError('Please select brand')
            : yup
                .array()
                .of(yup.object())
                .min(1, 'Please select brand')
                .required('Please select brand')
        ),
        options: [],
        isMulti: componentProps.isCrossBrand,
        max: 10,
        defaultDisabled: true,
        isEdit,
        defaultValue: defaultValues.brand,
        watchInput: 'country',
        defaultWatchValue: defaultValues.country,
        onWatch: (value, { options, disabled }, setData) => {
          let m_disabled = disabled;
          let m_options = options;
          if (value) {
            m_disabled = false;
            dispatch(setEmailLoader(true));
            getBrandByCountry(value.id).then(res => {
              if (res.brands) {
                let brands = res.brands.map(country => {
                  return {
                    value: country.code,
                    label: country.name,
                    id: country._id
                  };
                });
                setData({ options: brands, disabled: m_disabled });
              }
              dispatch(setEmailLoader(false));
            });
          }
        },
        customHelpComponent: MultiBrandCheck
      },
      {
        ...(componentProps.isCrossBrand
          ? {
              type: 'label',
              id: 'brandHelp',
              label:
                'Cross brand emails can be sent to the consumers signed up to at least one of the participating brands. The email should be branded as sent from Unilever instead of being sent from one brand',
              labelClassName: 'mt-n2 email-type-desc-font'
            }
          : {
              type: 'label'
            })
      },
      {
        type: 'reactselect',
        name: 'language',
        id: 'language',
        label: 'Email language',
        rules: yup
          .object()
          .shape({
            label: yup.string().required(),
            value: yup.string().required(),
            id: yup.string().required()
          })
          .required('Please select language')
          .default(undefined),
        options: [],
        defaultValue: defaultValues.language,
        defaultDisabled: true,
        watchInput: 'country',
        defaultWatchValue: defaultValues.country,
        isEdit,
        onLoad: (properties, setProperties) => {
          // if(defaultValues.language) {
          //   properties.disabled = false
          // }
          getBrandDetails().then(res => {
            if (res.languages) {
              let languages = res.languages.map(language => {
                return {
                  value: language.code,
                  label: language.name,
                  id: language._id
                };
              });
              setProperties({ ...properties, options: languages });
            }
          });
        },
        onWatch: (value, { options, disabled }, setData) => {
          let m_disabled = disabled;
          let m_options = options;
          if (value) {
            m_disabled = false;
          }
          getBrandDetails().then(res => {
            if (res.languages) {
              let languages = res.languages.map(language => {
                return {
                  value: language.code,
                  label: language.name,
                  id: language._id
                };
              });
              setData({ options: languages, disabled: m_disabled });
            }
          });
        }
      }
    ],
    onSubmit: (data, { user }) =>
      onSubmit(data, { user }, { backToReview: isEdited }),
    formErrorMessage:
      'There was a problem with the form. Errors are listed below'
  };
};

export default formSchema;
