import React from 'react';
import * as charts from 'react-chartjs-2';

function ChartData({ type, children, ...props }) {
  return React.createElement(charts[type] || charts.Bar, props, children);
}

export const Charts = ({ chartType, options, data, plugins }) => {
  return (
    <ChartData
      plugins={plugins}
      type={chartType}
      options={options}
      data={data}
    />
  );
};
