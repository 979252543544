import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import axios from 'axios';
import AsyncDropDown from '../../AsyncDropDown';
import constant from '../../../constants';
import Select from 'react-select';
import groupIcon from '../../../images/groupIcon.png';
import Tooltip from '../Tooltip/Tooltip';
import ErrorIcon from '../../atoms/ErrorIcon/ErrorIcon';

function QnAField(props) {
  const {
    elem,
    index,
    isValid,
    campaignType,
    question,
    addQuestion,
    handleAccordionExpandCollapse,
    expand,
    isEdited,
    removeAccordion
  } = props;

  const [isMandatory, setMandatory] = useState(false);
  const [isQualifying, setQualifying] = useState(false);
  const [iConfirmQnA, setCheckbox] = useState(false);
  const [answersLimit, setMaxAnswerLimitOptions] = useState([]);
  let _getQuestions = (input, callback) => {
    let options = [];
    axios
      .get(constant.serviceUrls.SERVICE_URL.QUESTIONS + '/?text=' + input)
      .then(response => {
        options = response.data.map(question => ({
          value: question.id,
          label: question.text
        }));
        callback(options);
      });
  };

  let _handleAnswer = async (selectedOption, actionMeta, indexValue, index) => {
    if (
      document.getElementById(`answerTranslationError_${index}_${indexValue}`)
    ) {
      document
        .getElementById(`answerTranslationError_${index}_${indexValue}`)
        .classList.add('cw-hidden');
    }

    if (document.getElementById(`answerLabelError_${index}_${indexValue}`)) {
      document
        .getElementById(`answerLabelError_${index}_${indexValue}`)
        .classList.add('cw-hidden');

      document
        .getElementById(`answerLabel_${index}_${indexValue}`)
        .classList.contains('cw-error-focus') &&
        document
          .getElementById(`answerLabel_${index}_${indexValue}`)
          .classList.remove('cw-error-focus');
    }

    let updatedQ = [...question];
    let answerQ = updatedQ[index].answers;
    answerQ.splice(selectedOption.index, 1, {
      value:
        actionMeta && actionMeta.action === 'create-option'
          ? ''
          : selectedOption.value
          ? selectedOption.value
          : '',
      label: selectedOption.label,
      isNew: actionMeta && actionMeta.action === 'create-option',
      englishLabel: selectedOption.label,
      // answerQ[indexValue] && answerQ[indexValue]["englishLabel"]
      //   ? answerQ[indexValue]["englishLabel"]
      //   : selectedOption.label,
      translatedId: '',
      translatedLabel: ''
    });
    if (document.getElementById(`answerDropdownError_${index}_${indexValue}`)) {
      document
        .getElementById(`answerDropdownError_${index}_${indexValue}`)
        .classList.add('cw-hidden');

      document
        .getElementById(`answerDropdown_${index}_${indexValue}`)
        .classList.contains('cw-error-focus') &&
        document
          .getElementById(`answerDropdown_${index}_${indexValue}`)
          .classList.remove('cw-error-focus');

      document.getElementById(`answerTranslatedLabel_${index}_${indexValue}`) &&
        document
          .getElementById(`answerTranslatedLabel_${index}_${indexValue}`)
          .classList.contains('cw-error-focus') &&
        document
          .getElementById(`answerTranslatedLabel_${index}_${indexValue}`)
          .classList.remove('cw-error-focus');
    }

    if (questionProps.campaignLanguageCode === 'EN') {
      answerQ[indexValue].answerLabel = selectedOption && selectedOption.label;
      if (document.getElementById(`answerLabel_${index}_${indexValue}`)) {
        document.getElementById(`answerLabel_${index}_${indexValue}`).value =
          selectedOption && selectedOption.label;
      }
    } else {
      await _getTranslations('answer', selectedOption.value, index, indexValue);
      let languageName = questionProps && questionProps.campaignLanguage;
      if (
        document.getElementById(
          `answerTranslatedLabel_${index}_${indexValue}`
        ) &&
        document
          .getElementById(`answerTranslatedLabel_${index}_${indexValue}`)
          .value.trim().length === 0
      ) {
        document.getElementById(
          `answerTranslatedLabel_${index}_${indexValue}`
        ).placeholder =
          'No translation found. Please enter in ' +
          languageName +
          ' translation.';
      }
    }
    addQuestion([...updatedQ]);
  };

  let _getTranslations = async (type, id, qIndex, ansIndex) => {
    let { campaignLanguageCode } = questionProps;
    let url =
      type === 'answer'
        ? constant.serviceUrls.SERVICE_URL.GET_TRANSLATED_ANSWERS
        : constant.serviceUrls.SERVICE_URL.GET_TRANSLATED_QUESTIONS;
    let response = await axios.get(
      url + '?id=' + id + '&lang=' + campaignLanguageCode
    );
    let el;
    if (type === 'question') {
      if (response && response.data.length >= 0) {
        let data = response.data[0];
        el = 'questionTranslatedLabel_' + qIndex;
        let fetchedQ = [...question];

        fetchedQ[qIndex].translatedId = data && data.id ? data.id : '';
        fetchedQ[qIndex].translatedLabel = data && data.text ? data.text : '';
        fetchedQ[qIndex].questionLabel = data && data.text ? data.text : '';

        addQuestion([...fetchedQ]);
        if (document.getElementById(`questionLabel_${qIndex}`)) {
          document.getElementById(`questionLabel_${qIndex}`).value =
            data && data.text ? data.text : '';
        }
        if (document.getElementById(el)) {
          document.getElementById(el).value =
            data && data.text ? data.text : '';
        }
        if (document.getElementById(el)) {
          document.getElementById(el).value = question[qIndex].translatedLabel
            ? question[qIndex].translatedLabel
            : '';
        }
      }
    }
    if (type === 'answer') {
      el = 'answerTranslatedLabel_' + qIndex + '_' + ansIndex;
      if (response && response.data.length) {
        let data = response.data[0];
        let answerQ = [...question];
        let answerUpdated = answerQ[qIndex].answers;
        answerUpdated.splice(ansIndex, 1, {
          value: id,
          label: answerUpdated[ansIndex]['label'],
          translatedId: data && data.id ? data.id : '',
          translatedLabel: data && data.text ? data.text : '',
          answerLabel: data && data.text ? data.text : '',
          englishLabel: answerUpdated[ansIndex]['label']
        });
        if (document.getElementById(`answerLabel_${qIndex}_${ansIndex}`)) {
          document.getElementById(`answerLabel_${qIndex}_${ansIndex}`).value =
            data && data.text ? data.text : '';
        }
        //answerTranslationError_2_0
        // if (document.getElementById(`answerLabel_${qIndex}_${ansIndex}`) &&
        //   document.getElementById(`answerLabel_${qIndex}_${ansIndex}`).value.trim().length === 0 ){
        //     document.getElementById(`answerLabelError_${qIndex}_${ansIndex}`) &&
        //   document.getElementById(`answerLabelError_${qIndex}_${ansIndex}`).classList.add('cw-hidden');
        // }
        if (document.getElementById(el)) {
          document.getElementById(el).value =
            data && data.text ? data.text : '';
        }

        addQuestion([...answerQ]);
      } else {
        if (document.getElementById(`answerLabel_${qIndex}_${ansIndex}`)) {
          document.getElementById(`answerLabel_${qIndex}_${ansIndex}`).value =
            '';
        }
        if (document.getElementById(el)) {
          document.getElementById(el).value = '';
        }
      }
    }
  };

  let _handleQuestion = async (selectedOption, actionMeta, index) => {
    document.getElementById(`questionDropdown_${index}`) &&
      document
        .getElementById(`questionDropdown_${index}`)
        .classList.add('cw-hidden');

    document
      .getElementById(`questionDropdown`)
      .classList.contains('cw-error-focus') &&
      document
        .getElementById(`questionDropdown`)
        .classList.remove('cw-error-focus');

    document.getElementById(`questionTranslatedLabel_${index}`) &&
      document
        .getElementById(`questionTranslatedLabel_${index}`)
        .classList.contains('cw-error-focus') &&
      document
        .getElementById(`questionTranslatedLabel_${index}`)
        .classList.remove('cw-error-focus');

    if (document.getElementById(`questionTranslationError_${index}`)) {
      document
        .getElementById(`questionTranslationError_${index}`)
        .classList.add('cw-hidden');
    }

    if (document.getElementById(`questionLabelError_${index}`)) {
      document
        .getElementById(`questionLabelError_${index}`)
        .classList.add('cw-hidden');

      document
        .getElementById(`questionLabel_${index}`)
        .classList.contains('cw-error-focus') &&
        document
          .getElementById(`questionLabel_${index}`)
          .classList.remove('cw-error-focus');
    }
    let updatedQuestion = [...question];

    updatedQuestion[index].isNew =
      actionMeta && actionMeta.action === 'create-option'
        ? selectedOption.__isNew__
        : false;
    updatedQuestion[index].id =
      selectedOption && selectedOption.value && Number(selectedOption.value)
        ? selectedOption.value
        : '';
    updatedQuestion[index].englishLabel =
      selectedOption && selectedOption.label;
    updatedQuestion[index].istranslatedNew = false;

    if (questionProps.campaignLanguageCode === 'EN') {
      updatedQuestion[index].questionLabel =
        selectedOption && selectedOption.label;
      if (document.getElementById(`questionLabel_${index}`)) {
        document.getElementById(`questionLabel_${index}`).value =
          selectedOption && selectedOption.label;
      }
    } else {
      await _getTranslations('question', selectedOption.value, index, null);
      let languageName = questionProps && questionProps.campaignLanguage;

      if (
        document.getElementById(`questionTranslatedLabel_${index}`) &&
        document.getElementById(`questionTranslatedLabel_${index}`).value.trim()
          .length === 0
      ) {
        document.getElementById(
          `questionTranslatedLabel_${index}`
        ).placeholder =
          'No translation found. Please enter in ' +
          languageName +
          ' translation.';
      }
    }

    addQuestion([...updatedQuestion]);
  };

  let onTranslationLabelChange = (e, index) => {
    document.getElementById(`questionTranslationError_${index}`) &&
      document
        .getElementById(`questionTranslationError_${index}`)
        .classList.add('cw-hidden');

    document.getElementById(`questionLabelError_${index}`) &&
      document
        .getElementById(`questionLabelError_${index}`)
        .classList.add('cw-hidden');

    document
      .getElementById(`questionLabel_${index}`)
      .classList.contains('cw-error-focus') &&
      document
        .getElementById(`questionLabel_${index}`)
        .classList.remove('cw-error-focus');

    document
      .getElementById(`questionTranslatedLabel_${index}`)
      .classList.contains('cw-error-focus') &&
      document
        .getElementById(`questionTranslatedLabel_${index}`)
        .classList.remove('cw-error-focus');

    let enteredText = e.target.value;
    let tranlatedQ = [...question];
    tranlatedQ[index].translatedLabel = enteredText;
    tranlatedQ[index].questionLabel = enteredText;
    if (enteredText.trim().length > 0) {
      tranlatedQ[index].istranslatedNew = true;
      tranlatedQ[index].translatedId = '';
    } else {
      tranlatedQ[index].istranslatedNew = false;
    }
    if (document.getElementById(`questionLabel_${index}`)) {
      document.getElementById(`questionLabel_${index}`).value = enteredText;
    }
    addQuestion([...tranlatedQ]);
  };

  let onLabelChange = (e, index, elem) => {
    document.getElementById(`questionLabelError_${index}`) &&
      document
        .getElementById(`questionLabelError_${index}`)
        .classList.add('cw-hidden');

    document
      .getElementById(`questionLabel_${index}`)
      .classList.contains('cw-error-focus') &&
      document
        .getElementById(`questionLabel_${index}`)
        .classList.remove('cw-error-focus');

    let { campaignLanguageCode } = questionProps;
    let enteredText = e.target.value;
    let tranlatedAQ = [...question];
    tranlatedAQ[index].questionLabel = enteredText;
    addQuestion([...tranlatedAQ]);
  };

  let handleTranslationAnswerLabel = (e, index, indexValue) => {
    let enteredText = e.target.value;
    let tranlatedAQ = [...question];
    tranlatedAQ[index].answers[indexValue].translatedLabel = enteredText;
    tranlatedAQ[index].answers[indexValue].answerLabel = enteredText;
    if (enteredText.trim().length > 0) {
      tranlatedAQ[index].answers[indexValue].istranslatedNew = true;
      tranlatedAQ[index].answers[indexValue].translatedId = '';
    } else {
      tranlatedAQ[index].answers[indexValue].istranslatedNew = false;
    }
    if (document.getElementById(`answerLabel_${index}_${indexValue}`)) {
      document.getElementById(
        `answerLabel_${index}_${indexValue}`
      ).value = enteredText;
    }
    document.getElementById(`answerTranslationError_${index}_${indexValue}`) &&
      document
        .getElementById(`answerTranslationError_${index}_${indexValue}`)
        .classList.add('cw-hidden');

    document.getElementById(`answerLabelError_${index}_${indexValue}`) &&
      document
        .getElementById(`answerLabelError_${index}_${indexValue}`)
        .classList.add('cw-hidden');

    document
      .getElementById(`answerLabel_${index}_${indexValue}`)
      .classList.contains('cw-error-focus') &&
      document
        .getElementById(`answerLabel_${index}_${indexValue}`)
        .classList.remove('cw-error-focus');

    document
      .getElementById(`answerTranslatedLabel_${index}_${indexValue}`)
      .classList.contains('cw-error-focus') &&
      document
        .getElementById(`answerTranslatedLabel_${index}_${indexValue}`)
        .classList.remove('cw-error-focus');
    addQuestion([...tranlatedAQ]);
  };

  let handleAnswerLabel = (e, index, indexValue) => {
    let enteredText = e.target.value;
    let tranlatedAQ = [...question];
    tranlatedAQ[index].answers[indexValue].answerLabel = enteredText;

    document.getElementById(`answerLabelError_${index}_${indexValue}`) &&
      document
        .getElementById(`answerLabelError_${index}_${indexValue}`)
        .classList.add('cw-hidden');

    document
      .getElementById(`answerLabel_${index}_${indexValue}`)
      .classList.contains('cw-error-focus') &&
      document
        .getElementById(`answerLabel_${index}_${indexValue}`)
        .classList.remove('cw-error-focus');
    addQuestion([...tranlatedAQ]);
  };

  let _getAnswers = async (input, callback) => {
    let options = [];
    if (!input) {
      return Promise.resolve(options);
    }
    let response = await axios.get(
      constant.serviceUrls.SERVICE_URL.ANSWERS + '/?text=' + input
    );
    options = response.data.map(question => ({
      value: question.id,
      label: question.text
    }));
    return Promise.resolve(options);
  };

  let _addAnswer = (e, index) => {
    let answerNew = {
      label: '',
      value: '',
      translatedLabel: '',
      translatedValue: '',
      isNew: false
    };

    let questionArray = [...question];
    questionArray[index] &&
      questionArray[index].answers &&
      questionArray[index].answers.push(answerNew);
    addQuestion([...questionArray]);
  };

  let _deleteAnswer = async (index, indexValue) => {
    let questionArray = [...question];
    questionArray[index] &&
      questionArray[index].answers &&
      questionArray[index].answers.splice(indexValue, 1);
    addQuestion([...questionArray]);
  };

  let getQualifyingQuestionsCount = question => {
    let qualifyingQuesCount = 0;
    let qualifyingQuesList =
      question && question.filter(ele => ele && ele.qualifyingToggle);
    qualifyingQuesCount = qualifyingQuesList && qualifyingQuesList.length;
    return qualifyingQuesCount;
  };

  let qualifyingQuestionHandler = (event, index) => {
    let isChecked = event.target.checked;
    let qualifyingQ = [...question];
    setQualifying(isChecked);
    let qualifyingCount = getQualifyingQuestionsCount(question);
    if (isChecked && qualifyingCount >= 5) {
      document.getElementById(`errorMessage_qualifyingToogle_${index}`) &&
        document
          .getElementById(`errorMessage_qualifyingToogle_${index}`)
          .classList.remove('cw-hidden');
      // document.getElementById('iconfirm').disabled = true;
      setCheckbox(false);
    } else {
      document
        .getElementById(`errorMessage_qualifyingToogle_${index}`)
        .classList.add('cw-hidden');
      // document.getElementById('iconfirm').disabled = false;
    }

    qualifyingQ[index].qualifyingToggle = isChecked;
    addQuestion([...qualifyingQ]);
    if (!isChecked && getQualifyingQuestionsCount(question) <= 5) {
      question.forEach((elem, index) => {
        document.getElementById(`errorMessage_qualifyingToogle_${index}`) &&
          document
            .getElementById(`errorMessage_qualifyingToogle_${index}`)
            .classList.add('cw-hidden');
      });
      // document.getElementById('iconfirm').disabled = false;
    }
  };

  let handleMandatoryMessage = (e, index, elem) => {
    document.getElementById(`errorMessage_${elem.value}_${index}`) &&
      document
        .getElementById(`errorMessage_${elem.value}_${index}`)
        .classList.add('cw-hidden');

    document
      .getElementById(`errorMessage_${index}`)
      .classList.contains('cw-error-focus') &&
      document
        .getElementById(`errorMessage_${index}`)
        .classList.remove('cw-error-focus');
    let enteredText = e.target.value;
    let mandatoryQ = [...question];
    mandatoryQ[index].mandatoryMessage = mandatoryQ[index].mandatoryToggle
      ? enteredText
      : '';
    addQuestion([...mandatoryQ]);
  };

  let handleToolTip = (e, index) => {
    let enteredText = e.target.value;
    let toolTipQ = [...question];
    toolTipQ[index].toolTip = enteredText;
    addQuestion([...toolTipQ]);
  };

  let _handleMaxAnswersAllowed = (selectedOption, index) => {
    let maxAnswerLimit = {
      value: selectedOption.value,
      label: selectedOption.label
    };
    let questionSelected = [...question];
    questionSelected[index].maxAnswerLimit = maxAnswerLimit.value;
    addQuestion([...questionSelected]);
  };

  let mandatoryQuestionHandler = (event, index) => {
    let isChecked = event.target.checked;
    let mandatoryQ = [...question];
    mandatoryQ[index].mandatoryToggle = isChecked;
    addQuestion([...mandatoryQ]);
    setMandatory(event.target.checked);
  };

  const questionProps = useSelector(state => ({
    questions: state.questionsAnswers.questions,
    addedQuestions: state.questionsAnswers.addedQuestions,
    prevAddedQuestions: state.questionsAnswers.prevAddedQuestions,
    answers: state.questionsAnswers.answers,
    selectedQuestionType: state.questionsAnswers.selectedQuestionType,
    fetchingQuestions: state.questionsAnswers.fetchingQuestions,
    fetchingAnswers: state.questionsAnswers.fetchingAnswers,
    questionsLoaded: state.questionsAnswers.questionsLoaded,
    answersLoaded: state.questionsAnswers.answersLoaded,
    dataSubmittedToApi: state.questionsAnswers.dataSubmittedToApi,
    campaignDetailsLoaded: state.questionsAnswers.campaignDetailsLoaded,
    approverEditCampaign: state.app.approverEditCampaign,
    questionanswerssaved: state.questionsAnswers.questionanswerssaved,
    campaignLanguage: state.questionsAnswers.campaignLanguage,
    campaignLanguageCode: state.questionsAnswers.campaignLanguageCode,
    editCampaign: state.overview.editCampaign,
    editCampaignDetails: state.app.editCampaignDetails,
    isCampaignEdited: state.questionsAnswers.isCampaignEdited,
    websiteType: state.questionsAnswers.websiteType,
    isLanguageEnglish: state.questionsAnswers.isLanguageEnglish,
    campaignType: state.questionsAnswers.campaignType,
    autogenerate: state.campaignDetails.autogenerate,
    type: state.campaignDetails.type,
    incentiveType: state.campaignDetails.incentiveType,
    campaign: state.questionsAnswers.campaign,
    fields: state.questionsAnswers.fields,
    formMetaData: state.saasFormAuthoring,
    campaignDetails: state.campaignDetails
  }));

  let answerIsNew =
    question[index].answers &&
    question[index].answers.map((elem, indexValue) => elem.isNew);
  let answerHasNewFlag =
    answerIsNew && answerIsNew.filter(elem => elem).length > 0;
  let answerTranslatedIsNew =
    question[index].answers &&
    question[index].answers.map((elem, indexValue) => elem.istranslatedNew);
  let answerHasNewTranslatedFlag =
    answerTranslatedIsNew &&
    answerTranslatedIsNew.filter(elem => elem).length > 0;
  if (elem.dataType === 'questionAnswerPair') {
    return (
      <div
        id={`accordion_${elem.value}_${index + 1}`}
        className="accordion saas-form-field-accordion field"
        key={`question_${index + 1}`}
      >
        <ReactTooltip
          place="right"
          type="info"
          multiline={true}
          className="cw-tooltip"
        />
        <ErrorIcon isValid={isValid} />
        <div
          className="card"
          style={{
            border: 0,
            overflow: 'visible',
            width: '99%'
          }}
        >
          <div className="card-header">
            <span
              className="saas-accordion-heading cw-color--primary"
              style={{ fontWeight: '700' }}
            >
              {question[index].questionLabel
                ? question[index].questionLabel.length < 70
                  ? question[index].questionLabel
                  : question[index].questionLabel.substring(0, 70) + '...'
                : elem.label}
            </span>
            <span
              type="button"
              className={`saas-accordion-arrow ${index}`}
              onClick={() => handleAccordionExpandCollapse(index)}
              style={{
                marginRight: '-8px',
                paddingLeft: '12px'
              }}
            >
              <i
                className={
                  expand === index
                    ? 'fas fa-chevron-down transformIcon-180'
                    : 'fas fa-chevron-down'
                }
              ></i>
            </span>
            {!isEdited ||
            question.filter(elem => elem.dataType === 'questionAnswerPair')
              .length > 1 ? (
              <span
                className={'saas-accordion-close'}
                onClick={() => {
                  removeAccordion(index);
                }}
              ></span>
            ) : null}
            {question[index].isNew ||
            question[index].istranslatedNew ||
            answerHasNewTranslatedFlag ||
            answerHasNewFlag ? (
              <span
                className={'saas_new_master_logo saas_new_master_logo_old '}
                style={{ display: 'block', color: '#E9A40A' }}
              >
                <img
                  src={groupIcon}
                  alt={groupIcon}
                  width="25"
                  height="25"
                ></img>
                &nbsp; New Master
              </span>
            ) : null}
          </div>
          <div className={expand === index ? 'collapse show' : 'collapse'}>
            <ReactTooltip
              place="right"
              type="info"
              multiline={true}
              className="cw-tooltip"
            />
            <>
              <div
                className="form-group form-group-field-width-qna"
                key={`questionDropdown_${index + 1}`}
              >
                <div className="cw-question-answer-dotted-line mt-15">
                  <div className="cw-heading-qna mb-10">
                    <span
                      className="float-left  fa fa-circle mt-10"
                      style={{ marginTop: '5px' }}
                    ></span>
                    <strong style={{ marginLeft: '15px' }}>Question</strong>
                  </div>
                  <div className="cw-qna-lable-width mb-10">
                    <label htmlFor={`questionLabel_${index + 1}`}>
                      <span>Question (English - Master Data)</span>
                      <span
                        className="float-right  cw-icon cw-icon--help"
                        data-tip="This selection is required to choose existing master data or request new. Use keywords to look for existing master data. E.g Try searching for 'hair colour'"
                      >
                        <i className="fas fa-question-circle"></i>
                      </span>
                    </label>

                    <div id={'questionDropdown'}>
                      {isEdited ? (
                        <input
                          type="text"
                          className="form-control"
                          name={'question'}
                          id={'question'}
                          disabled={true}
                          value={
                            question[index] &&
                            question[index].englishLabel !== ''
                              ? question[index].englishLabel
                              : ''
                          }
                        />
                      ) : (
                        <AsyncDropDown
                          className={'cw-select--custom'}
                          name={'question'}
                          label={'Question'}
                          placeholder={'Search for question using keywords'}
                          isDisabled={true}
                          minimumInput={3}
                          loadOptions={_getQuestions}
                          defaultOptions={questionProps.questions}
                          onChange={(e, actionMeta) =>
                            _handleQuestion(e, actionMeta, index)
                          }
                          isLoading={questionProps.fetchingQuestions}
                          value={
                            question[index] &&
                            question[index].englishLabel !== ''
                              ? {
                                  label: question[index].englishLabel,
                                  value: question[index].englishLabel
                                }
                              : null
                          }
                        />
                      )}
                    </div>

                    {/* {question[index].isNew ? (
                            <span className="cw-new badge badge-warning">
                              New
                            </span>
                          ) : null} */}

                    <span
                      id={`questionDropdown_${index}`}
                      className="cw-error cw-hidden"
                      data-control="name"
                    >
                      <i className="fas fa-exclamation-triangle mr-10"></i>
                      Please provide the question
                    </span>
                  </div>
                  {questionProps &&
                  questionProps.campaignLanguage !== 'English' ? (
                    <div
                      className="form-group cw-qna-group-field-width cw-qna-lable-width"
                      key={`translatedQ_${index + 1}`}
                    >
                      <label htmlFor={`questionTranslatedLabel_${index}`}>
                        <span>
                          Question (
                          {questionProps && questionProps.campaignLanguage}{' '}
                          translation - Master Data)
                        </span>
                        <span
                          className="float-right  cw-icon cw-icon--help"
                          data-tip="This field will auto-populate master data if existing or else will be required to provide new, in local translation."
                        >
                          <i className="fas fa-question-circle"></i>
                        </span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name={`questionTranslatedLabel_${index}`}
                        id={`questionTranslatedLabel_${index}`}
                        aria-describedby={`questionTranslatedLabel_${index}`}
                        disabled={isEdited}
                        data-required="true"
                        defaultValue={
                          question[index].translatedLabel
                            ? question[index].translatedLabel
                            : ''
                        }
                        onChange={e => onTranslationLabelChange(e, index)}
                      />
                      {/* {question[index].istranslatedNew ? (
                              <span className="cw-new badge badge-warning">
                                New
                              </span>
                            ) : null} */}
                      <span
                        id={`translationError_${index}`}
                        className="cw-error cw-hidden"
                        data-control="name"
                      >
                        <i className="fas fa-exclamation-triangle mr-10"></i>
                        No translation found. Please enter in{' '}
                        {questionProps && questionProps.campaignLanguage}{' '}
                        translation
                      </span>
                      <span
                        id={`questionTranslationError_${index}`}
                        className="cw-error cw-hidden"
                        data-control="name"
                      >
                        <i className="fas fa-exclamation-triangle mr-10"></i>
                        Please provide the translation of the question
                      </span>
                    </div>
                  ) : null}
                  <div
                    className="form-group  cw-qna-lable-width"
                    key={`questionLabel_${index + 1}`}
                  >
                    <label htmlFor={`questionLabel_${index}`}>
                      <span>Question Label (Consumer Facing)</span>
                      <span
                        className="float-right  cw-icon cw-icon--help"
                        data-tip="This is the label of the question that the consumers will see on the form"
                      >
                        <i className="fas fa-question-circle"></i>
                      </span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name={`questionLabel_${index}`}
                      id={`questionLabel_${index}`}
                      aria-describedby={`questionLabel_${index}`}
                      data-required="true"
                      defaultValue={
                        questionProps.campaignLanguageCode !== 'EN'
                          ? question[index].translatedLabel ===
                            question[index].questionLabel
                            ? question[index].translatedLabel
                            : question[index].questionLabel
                          : question[index].questionLabel ===
                            question[index].englishLabel
                          ? question[index].englishLabel
                          : question[index].questionLabel
                      }
                      onChange={e => onLabelChange(e, index, elem)}
                    />
                    <span
                      id={`questionLabelError_${index}`}
                      className="cw-error cw-hidden"
                      data-control="name"
                    >
                      <i className="fas fa-exclamation-triangle mr-10"></i>
                      Please provide the question label
                    </span>
                  </div>
                </div>
              </div>
              {elem.value !== 'freeText' ? (
                <>
                  <div className="form-group cw-question-answer-dotted-line">
                    <div className="cw-heading-qna mb-10">
                      <span
                        className="float-left  fa fa-circle mt-10"
                        style={{ marginTop: '5px' }}
                      ></span>
                      <strong style={{ marginLeft: '15px' }}>Answers</strong>
                    </div>
                    {/* <div className="cw-heading--saascampaign mt-4">
                          &nbsp;&nbsp; Configure Answers
                        </div> */}
                    {question[index].answers &&
                      question[index].answers.map((elem, indexValue) => {
                        return (
                          <>
                            <ReactTooltip
                              place="right"
                              type="info"
                              multiline={true}
                              className="cw-tooltip"
                            />
                            <div
                              className="form-group"
                              style={{ marginLeft: '3.5%' }}
                              key={`answer_${indexValue + 1}`}
                            >
                              <label
                                htmlFor={`translatedAnswerSelect_${index}`}
                              >
                                <span>
                                  <strong>
                                    &nbsp;&nbsp;Answer {indexValue + 1} (English
                                    - Master Data)
                                  </strong>
                                </span>
                                <span
                                  className="float-right  cw-icon cw-icon--help"
                                  data-tip="This selection is required to choose existing master data or request new."
                                >
                                  <i className="fas fa-question-circle"></i>
                                </span>
                              </label>
                              {/* <p className="mb-10">
                                    <strong>
                                      Answer {indexValue + 1}{" "}
                                      (English - Master Data)
                                    </strong>
                                  </p> */}
                              <div
                                style={{
                                  margin: '0 0 0 1%'
                                }}
                              >
                                <div
                                  id={`answerDropdown_${index}_${indexValue}`}
                                >
                                  {isEdited ? (
                                    <input
                                      type="text"
                                      className="form-control"
                                      name={'answer_' + indexValue}
                                      id={'answer_' + indexValue}
                                      disabled={true}
                                      value={
                                        question[index].answers &&
                                        question[index].answers[indexValue]
                                          .label !== ''
                                          ? question[index].answers[indexValue]
                                              .label
                                          : ''
                                      }
                                    />
                                  ) : (
                                    <AsyncDropDown
                                      className={'cw-select--custom'}
                                      name={'answer_' + indexValue}
                                      label={'Answer_' + indexValue}
                                      placeholder={
                                        'Search for answers using keywords'
                                      }
                                      loadOptions={_getAnswers}
                                      defaultOptions={questionProps.answers}
                                      isLoading={questionProps.fetchingAnswers}
                                      onChange={(val, actionMeta) => {
                                        _handleAnswer(
                                          {
                                            index: indexValue,
                                            value: val.value,
                                            label: val.label
                                          },
                                          actionMeta,
                                          indexValue,
                                          index
                                        );
                                      }}
                                      value={
                                        question[index].answers &&
                                        question[index].answers[indexValue]
                                          .label !== ''
                                          ? {
                                              label:
                                                question[index].answers[
                                                  indexValue
                                                ].label,
                                              value:
                                                question[index].answers[
                                                  indexValue
                                                ].value
                                            }
                                          : null
                                      }
                                    />
                                  )}
                                </div>
                                {/* {question[index].answers[
                                          indexValue
                                        ].isNew ? (
                                          <span className="cw-new badge badge-warning">
                                            New
                                          </span>
                                        ) : null} */}
                                {indexValue >= 2 ? (
                                  <span
                                    className="saas-accordion-close qna"
                                    style={{ zIndex: '1' }}
                                    data-index={index}
                                    onClick={e =>
                                      _deleteAnswer(index, indexValue)
                                    }
                                  ></span>
                                ) : null}
                                <span
                                  id={`answerDropdownError_${index}_${indexValue}`}
                                  className="cw-error cw-hidden"
                                  data-control="name"
                                >
                                  <i className="fas fa-exclamation-triangle mr-10"></i>
                                  Please provide the answer
                                </span>
                              </div>
                              {questionProps &&
                              questionProps.campaignLanguage !== 'English' ? (
                                <div
                                  className="form-group form-group-field-width"
                                  key={`translatedanswer_${indexValue + 1}`}
                                >
                                  <label className="mt-10">
                                    <span className="mb-10">
                                      Answer {indexValue + 1} {''}(
                                      {questionProps &&
                                      questionProps.campaignLanguage
                                        ? questionProps.campaignLanguage
                                        : ''}
                                      &nbsp;translation - Master Data)
                                    </span>
                                    <span
                                      className="float-right  cw-icon cw-icon--help"
                                      data-tip="This field will auto-populate master data if existing or else will be required to provide new, in local translation."
                                    >
                                      <i className="fas fa-question-circle"></i>
                                    </span>
                                    {index !== 0 ? (
                                      <span
                                        className="cw-icon cw-icon--deleteAnswer"
                                        data-index={index}
                                      >
                                        {/* <i
                                              className="saas-accordion-close qna"
                                              className={`fas ${
                                                isDisabled
                                                  ? " fa-disabled "
                                                  : ""
                                              } fa-trash-alt`}
                                            ></i> */}
                                      </span>
                                    ) : null}
                                  </label>

                                  <input
                                    type="text"
                                    className="form-control mb-10"
                                    name={`answerTranslatedLabel_${indexValue}`}
                                    id={`answerTranslatedLabel_${index}_${indexValue}`}
                                    aria-describedby={`answerTranslatedLabel_${indexValue}`}
                                    data-required="true"
                                    disabled={isEdited}
                                    defaultValue={
                                      questionProps.campaignLanguageCode !==
                                      'EN'
                                        ? question[index].answers[indexValue]
                                            .translatedLabel
                                        : ''
                                    }
                                    onChange={e =>
                                      handleTranslationAnswerLabel(
                                        e,
                                        index,
                                        indexValue
                                      )
                                    }
                                  />
                                  {/* {question[index].answers[
                                            indexValue
                                          ].istranslatedNew ? (
                                            <span className="cw-new badge badge-warning">
                                              New
                                            </span>
                                          ) : null} */}
                                  <span
                                    id={`answerTranslationError_${index}_${indexValue}`}
                                    className="cw-error cw-hidden"
                                    data-control="name"
                                  >
                                    <i className="fas fa-exclamation-triangle mr-10"></i>
                                    Please provide the translation of the answer
                                  </span>
                                </div>
                              ) : null}

                              <div
                                className="form-group form-group-field-width"
                                key={`answerLabel_${indexValue + 1}`}
                              >
                                <label className="mt-10">
                                  <span className="mb-10">
                                    Answer {indexValue + 1} Label (Consumer
                                    Facing)
                                  </span>
                                  <span
                                    className="float-right  cw-icon cw-icon--help"
                                    data-tip="This is the label of the answer that the consumers will see on the form"
                                  >
                                    <i className="fas fa-question-circle"></i>
                                  </span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control mb-10"
                                  name={`answerLabel_${indexValue}`}
                                  id={`answerLabel_${index}_${indexValue}`}
                                  aria-describedby={`answerLabel_${indexValue}`}
                                  data-required="true"
                                  defaultValue={
                                    questionProps.campaignLanguageCode !== 'EN'
                                      ? question[index].answers[indexValue]
                                          .translatedLabel ===
                                        question[index].answers[indexValue]
                                          .answerLabel
                                        ? question[index].answers[indexValue]
                                            .translatedLabel
                                        : question[index].answers[indexValue]
                                            .answerLabel
                                      : question[index].answers[indexValue]
                                          .answerLabel ===
                                        question[index].answers[indexValue]
                                          .englishLabel
                                      ? question[index].answers[indexValue]
                                          .englishLabel
                                      : question[index].answers[indexValue]
                                          .answerLabel
                                  }
                                  onChange={e =>
                                    handleAnswerLabel(e, index, indexValue)
                                  }
                                />
                                <span
                                  id={`answerLabelError_${index}_${indexValue}`}
                                  className="cw-error cw-hidden"
                                  data-control="name"
                                >
                                  <i className="fas fa-exclamation-triangle mr-10"></i>
                                  Please provide the answer label
                                </span>
                              </div>
                            </div>
                          </>
                        );
                      })}

                    {!isEdited ? (
                      <button
                        onClick={e => _addAnswer(e, index)}
                        className="btn btn-outline-primary"
                        style={{ marginLeft: '4.5%' }}
                      >
                        Add another answer
                      </button>
                    ) : null}
                    {elem.value === 'multipleChoice' ? (
                      <>
                        <ReactTooltip
                          place="right"
                          type="info"
                          multiline={true}
                          className="cw-tooltip"
                        />
                        <div
                          className="form-group cw-hidden"
                          style={{ margin: '1% 0 0 2%' }}
                        >
                          <label htmlFor="answerLimit">
                            <span>
                              Maximum number of answers user can select
                            </span>
                            <span
                              className="float-right  cw-icon cw-icon--help"
                              data-tip="Limit the number of answers the user can select this question"
                            >
                              <i className="fa fa-question-circle"></i>
                            </span>
                          </label>
                          <Select
                            className={'cw-select--custom'}
                            name={'answerLimit'}
                            label={'answerLimit'}
                            placeholder={'Select option'}
                            options={answersLimit}
                            onChange={e => _handleMaxAnswersAllowed(e, index)}
                            value={
                              question[index] &&
                              question[index].maxAnswerLimit !== ''
                                ? {
                                    label: question[index].maxAnswerLimit,
                                    value: question[index].maxAnswerLimit
                                  }
                                : {
                                    label: 'No limit',
                                    value: ''
                                  }
                            }
                          />
                          <span
                            className="cw-error cw-hidden"
                            data-control="answerLimit"
                          >
                            <i className="fas fa-exclamation-triangle mr-10"></i>
                            Please select maximum answers.
                          </span>
                        </div>
                      </>
                    ) : null}
                  </div>
                </>
              ) : (
                <>
                  <div className="form-group cw-question-answer-dotted-line">
                    <div className="cw-heading-qna mb-10">
                      <span
                        className="float-left  fa fa-circle mt-10"
                        style={{ marginTop: '5px' }}
                      ></span>
                      <strong style={{ marginLeft: '15px' }}>Answers</strong>
                    </div>
                    <div className="cw-qna-lable-width">
                      <span>
                        Answers for free text questions are entered by consumers
                      </span>
                    </div>
                  </div>
                </>
              )}
              <div className="cw-question-answer-dotted-line">
                <div className="cw-heading-qna">
                  <span
                    className="float-left  fa fa-circle mt-10"
                    style={{ marginTop: '5px' }}
                  ></span>
                  <strong style={{ marginLeft: '15px' }}>
                    Other Configurations
                  </strong>
                </div>
                <Tooltip
                  index={index}
                  value={
                    question[index] && question[index].toolTip
                      ? question[index].toolTip
                      : ''
                  }
                  onChange={e => handleToolTip(e, index)}
                  multipleChoice={elem.value === 'multipleChoice'}
                  qna
                />
                <div className="form-group d-flex ml-3 cw-qna-lable-width">
                  <label className="switch-saas">
                    <input
                      type="checkbox"
                      name="quesMandatory"
                      checked={
                        question[index] && question[index].mandatoryToggle
                          ? true
                          : false
                      }
                      onChange={e => mandatoryQuestionHandler(e, index)}
                    />
                    <div className="slider-saas round"></div>
                  </label>
                  <label className=" ml-20" htmlFor="quesMandatory">
                    Make this question mandatory
                  </label>
                </div>
                {question[index] && question[index].mandatoryToggle ? (
                  <>
                    <ReactTooltip
                      place="right"
                      type="info"
                      multiline={true}
                      className="cw-tooltip"
                    />
                    <div className="form-group ml-3 mt-2 cw-qna-lable-width">
                      <label htmlFor={`errorMessage_${index}`}>
                        <span>Mandatory Field Error Message</span>
                        <span
                          className="float-right  cw-icon cw-icon--help"
                          data-tip="Please specify the error message you would want the users to see in case they forget to fill this field. Make sure to have this message in the language you selected for the form."
                          currentitem="false"
                        >
                          <i className="fas fa-question-circle"></i>
                        </span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name={`errorMessage_${index}`}
                        id={`errorMessage_${index}`}
                        aria-describedby={`errorMessage_${index}`}
                        data-required="true"
                        value={
                          question[index] && question[index].mandatoryMessage
                            ? question[index].mandatoryMessage
                            : ''
                        }
                        onChange={e => handleMandatoryMessage(e, index, elem)}
                      />
                      <span
                        className="cw-error cw-hidden"
                        id={`errorMessage_${elem.value}_${index}`}
                      >
                        <i className="fas fa-exclamation-triangle mr-10"></i>
                        Please enter mandatory message.
                      </span>
                    </div>
                  </>
                ) : null}
                {campaignType === 'Incentive' &&
                (question[index].questionType === 'Single choice' ||
                  question[index].questionType === 'Free text') ? (
                  <div>
                    <div className="form-group d-flex cw-qna-lable-width">
                      <label className="switch-saas">
                        <input
                          type="checkbox"
                          name="isQualifying"
                          checked={
                            question[index] && question[index].qualifyingToggle
                              ? true
                              : false
                          }
                          onChange={e => qualifyingQuestionHandler(e, index)}
                        />
                        <div className="slider-saas round"></div>
                      </label>
                      <label className=" ml-20" htmlFor="isQualifying">
                        Make this a Qualifying question &nbsp;
                        {/* <span
                              className="float-right  cw-icon cw-icon--help"
                              data-tip="A qualifying question is a mandatory question that needs to be answered by the end consumer and is then validated or judged in order for that entry to be valid"
                            >
                              <i className="fa fa-question-circle"></i>
                            </span> */}
                      </label>
                    </div>
                    <span
                      className="cw-error cw-hidden ml-4"
                      id={`errorMessage_qualifyingToogle_${index}`}
                    >
                      <i className="fas fa-exclamation-triangle mr-10"></i>
                      You can mark upto 5 questions as qualifying.
                    </span>
                  </div>
                ) : null}
              </div>
            </>
          </div>
        </div>
      </div>
    );
  }
}

export default QnAField;
