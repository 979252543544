import React, { Component, Fragment } from 'react';
import groupIcon from '../images/groupIcon.png';
export default class MasterDataAnswer extends Component {
  onFieldChange(event) {
    this.props.onChange(event);
  }
  render() {
    let {
      answerList,
      disabled,
      qno,
      campaignLanguage,
      isCampaignNonEnglish
    } = this.props;
    let answers;
    let newMasterData = isNew => {
      return isNew ? (
        <>
          <div
            style={{ display: 'block', color: '#E9A40A', whiteSpace: 'pre' }}
            className="col-sm-3"
          >
            <img src={groupIcon} alt={groupIcon} width="25" height="25"></img>
            &nbsp; New Master
          </div>
        </>
      ) : null;
    };

    answers = answerList.map((answer, index) => {
      return (
        <Fragment key={'masteranswer_' + index}>
          <div className="cw-striped-qna-row">
            <div className="row">
              <div className="col-sm-3">
                <div className="cw-qna-lable">
                  <strong>
                    Answer {!isCampaignNonEnglish ? index + 1 : ''}
                  </strong>
                </div>
                <div className={`${isCampaignNonEnglish ? 'd-none' : ''}`}>
                  <p className="xsmall-label">English</p>
                </div>
              </div>
              <div className="col-sm-3">
                <p>{answer.label}</p>
              </div>
              {!this.props.showInput ? (
                <div className="col-sm-3"></div>
              ) : (
                <div className="col-sm-3">
                  <input
                    type="text"
                    className="form-control"
                    name={'answer_' + qno + '_' + index}
                    id={'answer_' + qno + '_' + index}
                    value={answer.value}
                    placeholder="Enter ID"
                    data-qno={qno + 1}
                    disabled={disabled}
                    data-ano={index + 1}
                    onChange={this.onFieldChange.bind(this)}
                  />
                  <span
                    className="cw-error cw-hidden"
                    data-control={'answer_' + qno + '_' + index}
                  >
                    <i className="fas fa-exclamation-triangle mr-10"></i>Please
                    enter Answer Id.
                  </span>
                </div>
              )}
              {newMasterData(answer.new || answer.translatednew)}
            </div>
          </div>
          {!isCampaignNonEnglish ? (
            <div className="cw-striped-qna-row">
              <div className="row">
                <div className="col-sm-3">
                  <div className="cw-qna-lable">
                    <strong>
                      Answer {!isCampaignNonEnglish ? index + 1 : ''}
                    </strong>
                  </div>
                  <div>
                    <p className="xsmall-label">{campaignLanguage}</p>
                  </div>
                </div>
                <div className="col-sm-3">
                  <p>{answer.translatedanswer}</p>
                </div>
                {!this.props.showInput ? (
                  <div className="col-sm-3"></div>
                ) : (
                  <div className="col-sm-3">
                    <input
                      type="text"
                      className="form-control"
                      name={'translatedanswer_' + qno + '_' + index}
                      id={'translatedanswer_' + qno + '_' + index}
                      value={answer.translatedvalue}
                      placeholder="Enter ID"
                      data-qno={qno + 1}
                      disabled={disabled}
                      data-ano={index + 1}
                      data-anstype="translated"
                      onChange={this.onFieldChange.bind(this)}
                    />
                    <span
                      className="cw-error cw-hidden"
                      data-control={'translatedanswer_' + qno + '_' + index}
                    >
                      <i className="fas fa-exclamation-triangle mr-10"></i>
                      Please enter Answer Id.
                    </span>
                  </div>
                )}
                {newMasterData(answer.translatednew)}
              </div>
            </div>
          ) : null}
        </Fragment>
      );
    });

    return answers;
  }
}
