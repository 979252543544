import React from 'react';
import DeleteIcon from '../../../images/times.png';

export default function AppliedFilterPill({
  item,
  keyName: fitlerKey,
  removeFilter
}) {
  return (
    <div className="cw-dashboard-filters">
      <div className="cw-dashboard-filters-label">
        {fitlerKey === 'lastUpdated' ? item && item.value : item && item.label}
      </div>
      <div
        className="cw-dashboard-filters-remove"
        onClick={() => removeFilter(item.value, fitlerKey)}
      >
        <img src={DeleteIcon} alt="Remove filter icon" />
      </div>
    </div>
  );
}
