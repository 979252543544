import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { CustomButtons } from 'pages/EmailCampaignAudience';
import { FormBuilder } from 'components/organisms/FormBuilder';
import Loader from 'components/Loader';
import ProgressBar from 'components/molecules/ProgressBar';

import formSchema from './smsTypeSchema';
import { retrieveSmsCampaignInformation } from 'actions/communications/communicationCampaignDetails.js';

import constants from '../../constants/config';

const SMSType = props => {
  const [isEdit, setEdit] = useState(false);
  const communicationCampaignDetails = useSelector(
    state => state.communicationCampaignDetails
  );
  const {dataToSend} = useSelector(
    state => state.communicationBrandDetails
  );
  console.log('brandDetails: ', dataToSend);
  const {country, brand, language} = dataToSend || {};
  const { authorizedUserData } = useSelector(state => state.authorized);

  const dispatch = useDispatch();

  const pathUid = props.match.params.id;

  useEffect(() => {
    if (pathUid) {
      setEdit(true);
      dispatch(retrieveSmsCampaignInformation(pathUid));
    }
  }, []);

  const smsBrandDetails = communicationCampaignDetails && communicationCampaignDetails.smsBrandDetails
  const smsCampaignType = communicationCampaignDetails &&  communicationCampaignDetails.smsCampaignType
  if(!smsBrandDetails) return null

  const { isLoading  } = communicationCampaignDetails
  const { uid } = smsBrandDetails

  if (!pathUid && (!country || !brand || !language)) {
  //if (!pathUid) {
    return <Redirect to={`/campaign-wizard/${constants.SMS_ROUTES.NEW_CAMPAIGN}`} />;
  }

  const compProps = {
    history: props.history,
    match: props.match,
    defaultValues: pathUid? uid !== pathUid ? {} : { smsType: smsBrandDetails.smsType } : smsCampaignType || {},
    campaignDetails: smsBrandDetails
  };

  return (
    <div className="col-sm-12 skypink">
      <div className="cw-section">
        <ProgressBar
          customClass={'cw-email-progress-custom'}
          totalPage={constants.SMS_PROGRESS_BAR_TOT_PAGE}
          pageNo={constants.SMS_JOURNEY_PAGE_NUMBERS.SMS_TYPE}
        />
        <div className="cw-section--title mb-30">
          <h2 className="cw-heading--secondary cw-email-heading">
            Select SMS type
          </h2>
        </div>
        <div className="row">
          {isLoading ? <Loader /> : null}
          <div className="col-md-8">
            <FormBuilder
              schema={formSchema(dispatch, compProps)}
              customButtonsComp={CustomButtons}
              user={authorizedUserData}
              isEdit={isEdit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SMSType;
