import {
  FETCH_EMAIL_RAW_BLOCKS_FAILURE,
  FETCH_EMAIL_RAW_BLOCKS_SUCCESS,
  FETCH_EMAIL_RAW_BLOCKS,
  DELETE_EMAIL_RAW_BLOCK_SUCCESS
} from '../../actionTypes';

const initialState = {
  isLoading: false,
  data: [],
  error: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_EMAIL_RAW_BLOCKS:
      return { ...state, isLoading: true };
    case FETCH_EMAIL_RAW_BLOCKS_SUCCESS:
      return { ...state, isLoading: false, data: action.payload };

    case FETCH_EMAIL_RAW_BLOCKS_FAILURE:
      return { ...state, isLoading: false, error: action.payload };
    
    case DELETE_EMAIL_RAW_BLOCK_SUCCESS:
       state.data.data = state.data.data.filter(
        template => template._id !== action.payload
      );
      return {
        ...state,
        isLoading: false
      };
    default:
      return state;
  }
}
