import React, { useEffect, useState } from 'react';
import { FieldError } from '../../atoms/FieldError';
import { useWatch } from 'react-hook-form';
import SingleColorPicker from '../SingleColorPicker/SingleColorPicker';
import FontSelector from 'pages/BrandEmailDefaults/FontSelector/FontSelector';

const initialState = {};

export const TitleDefaultInput = ({
  name,
  description,
  type,
  rules,
  control,
  register,
  label,
  errors,
  options,
  watchInput,
  defaultValue,
  defaultWatchValue,
  getValues,
  setValue,
  ...props
}) => {
  const [values, setValues] = useState(initialState);

  const brand = useWatch({
    control,
    name: watchInput,
    defaultValue: defaultWatchValue
  });

  useEffect(() => {
    if (props.onWatch) {
      props.onWatch(brand, onChange);
    }
  }, [brand]);

  const onChange = (property, value) => {
    setValues(values => {
      const updatedValue = { ...values, [property]: value };
      setValue(name, updatedValue);
      return updatedValue;
    });
  };

  if (!brand) {
    return null;
  }

  return (
    <div className="form-group mt-30">
      <div className="d-flex flex-column">
        <strong>Title</strong>
        <span className="description mb-10">{description}</span>
      </div>
      <FontSelector
        sampleText="I am your title"
        label="Title font"
        placeholder="Select a title font"
        property="fontFamily"
        onChange={onChange}
        value={values.fontFamily}
        values={values}
      />
      <SingleColorPicker
        className="mt-20"
        label="Title colour"
        property="color"
        onChange={onChange}
        value={values.color}
      />
      {!props.disableError && <FieldError errors={errors} name={name} />}
    </div>
  );
};
