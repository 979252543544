import React from 'react';
import Modal from 'react-modal';
import {
  getDateInDDmmmYYYY,
  getSMSReviewData,
  textCapitalization,
  getSMSDeliveryCondition
} from 'selectors';
import constants from '../../constants';
import EmailStatus from 'components/atoms/EmailStatus/EmailStatus';
import { EmailBrandDisplay } from 'components/atoms';
import Layout from 'components/organisms/Layout/Layout';
import MobileDevicePreview from 'components/organisms/MobileDevicePreview';
import { getMobilePreviewDates } from 'utilities/util';
import CampaignPreview from 'images/campaign-preview.svg';

const SmsPreviewOverlay = ({
  isOpen,
  onCloseModal,
  handleCloseForm,
  campaignName,
  brand,
  country,
  isCrossBrand,
  status,
  requestorsEmail,
  primaryButtonTitle = 'Select',
  selectedSmsForPreview: template,
  onPreviewSelect,
  hideSelectBtn,
  campaign
}) => {
  Modal.setAppElement('#root');

  const hasTimestamp = item => {
    if (item?.lastUpdatedDate) {
      return true;
    } else if (item?.lastUpdatedDate) {
      return true;
    } else {
      return false;
    }
  };

  const getTimestamp = item => {
    return getDateInDDmmmYYYY(item.lastUpdatedDate);
  };

  const brandName = Array.isArray(template?.brand)
    ? template?.brand?.map(b => b.label)
    : template?.brand?.label;

  let mobilePreviewData = getSMSReviewData(
    campaign,
    getMobilePreviewDates(campaign)
  );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCloseModal}
      className="cw-modal cw-modal--template-preview"
      contentLabel="Save row form"
      style={{
        overlay: {
          backgroundColor: constants.EMAIL_OVERLAY_BACKGROUND
        }
      }}
    >
      <div className="template-preview-overlay">
        <div>
          <button
            className={`cw-modal--close close-icon`}
            title="Close"
            onClick={handleCloseForm}
          >
            <i className="fas fa-times"></i>
          </button>
          <h3 className={`cw-heading--primary template-title mt-2 mb-10`}>
            <strong
              className="mb-1"
              style={{
                flex: 1,
                minWidth: 0,
                flexBasis: 0,
                wordBreak: 'break-word',
                lineHeight: 'normal'
              }}
            >
              {campaignName}
            </strong>
            {status ? (
              <div className="ml-2 mb-5">
                <EmailStatus small status={status} />
              </div>
            ) : null}
          </h3>
        </div>

        <div className="d-flex justify-content-between align-items-center">
          <div
            className={'campaign'}
            style={{ flex: 1, minWidth: 0, flexBasis: 0, marginTop: '5px' }}
          >
            <p className="template-category-text">{constants.SMS_TYPE_LABELS[campaign.smsType] || ''}</p>
            {template?.brand && template.country && (
              <p className="template-info-text">
                <EmailBrandDisplay
                  brand={textCapitalization(brandName)}
                  country={textCapitalization(template.country?.label)}
                  isCrossBrand={template.templateUsage === 'crossBrand'}
                  showAllBrands={true}
                />
              </p>
            )}
            {brand && country && (
              <p className="template-info-text">
                <EmailBrandDisplay
                  brand={textCapitalization(brand)}
                  country={textCapitalization(country)}
                  isCrossBrand={isCrossBrand}
                  showAllBrands={true}
                />
              </p>
            )}
          </div>
          {!hideSelectBtn && (
            <button
              className="btn btn-primary ml-2"
              style={{ width: 100 }}
              onClick={() => {
                onPreviewSelect && onPreviewSelect(template);
              }}
            >
              {primaryButtonTitle}
            </button>
          )}
        </div>

        <p className="template-info-text">
          {getSMSDeliveryCondition(campaign.deliverySchedule).map(text => (
            <div>{text}</div>
          ))}
        </p>

        <div className="mt-25 mb-15 template-preview-box">
          {mobilePreviewData.length ? (
            <MobileDevicePreview data={mobilePreviewData} campaign={campaign} isPreviewOverlay={true} />
          ) : (
            <div className="text-center">
              <img src={CampaignPreview} alt={`${campaignName} preview`} />
              <p className="mt-20 text-center preview-text">
                Preview will be available once designed
              </p>
            </div>
          )}
        </div>

        <div className="d-flex justify-content-end align-items-center">
          <div className="text-right">
            {(campaign?.requestorsEmail || requestorsEmail) && (
              <p className="template-meta-info">Requestor: {requestorsEmail}</p>
            )}
            {hasTimestamp(campaign) && (
              <p className="template-meta-info">
                Updated on: {getTimestamp(campaign)}
              </p>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SmsPreviewOverlay;
