module.exports = {
  Bar: {
    data: {
      labels: ['Lable 1', 'Lable 2', 'lable3'],
      datasets: [
        {
          data: [85, 85, 85],
          backgroundColor: ['#FF0F7D', '#00D7C4', '#FFBA00'],
          barThickness: 26
        }
      ]
    },
    options: {
      indexAxis: 'y',
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          //   offset: 20,
          stacked: true,
          display: true,
          grid: {
            display: false
          }
          //   position: "right",
        },
        x: {
          //   offset: 20,
          stacked: true,
          display: false,
          grid: {
            display: false
          },
          beginAtZero: true
        }
      },
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          // Disable the on-canvas tooltip
          callbacks: {
            title: function(context) {
              let titleArr = [];
              let titleData = context[0].label.split(' ');
              titleArr.push(titleData[0]);
              if (!titleData[1].startsWith('(')) {
                titleArr.push(titleData[1]);
              }
              return titleArr.join(' ');
            }
          }
        }
      }
    }
  },
  Line: {
    data: {
      labels: ['Lable 1', 'Lable 2', 'lable3'],
      datasets: [
        {
          label: 'Simple Signup',
          data: [50, 89, 87, 54],
          fill: false,
          borderColor: '#FF0F7D'
        },
        {
          label: 'Samples',
          data: [55, 90, 80, 35],
          fill: false,
          borderColor: '#00D7C4'
        },
        {
          label: 'Competitions',
          data: [40, 79, 77, 30],
          fill: false,
          borderColor: '#FFBA00'
        }
      ]
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          grid: {
            display: false
          },
          // offset: 20,
          ticks: {
            stepSize: 20
          }
        },
        x: {
          offset: 20,
          grid: {
            display: false
          },
          beginAtZero: false
        }
      },
      plugins: {
        legend: {
          position: 'right',
          textDirection: 'ltr',
          rtl: true,
          labels: {
            pointStyle: 'line',
            usePointStyle: true,
            textAlign: 'left'
          }
        }
      }
    }
  }
};
