import React, { Component } from 'react';
import _ from 'lodash';
import constant from '../constants';

export default class ProfileStore extends Component {
  constructor(props) {
    super(props);

    this._handleCollapse = this._handleCollapse.bind(this);

    this.collapsePanel = React.createRef();
    this.collapseHandlerIcon = React.createRef();
    this.collapseHandlerText = React.createRef();
  }

  _handleCollapse(event) {
    event.preventDefault();

    let collapseHandlerTextLess = 'View less PRM connection details';
    let collapseHandlerTextMore = 'View more PRM connection details';

    if (this.collapsePanel.current.classList.contains('show')) {
      this.collapsePanel.current.classList.remove('show');
      this.collapseHandlerIcon.current.classList.remove('fa-chevron-up');
      this.collapseHandlerIcon.current.classList.add('fa-chevron-down');
      this.collapseHandlerText.current.innerHTML = collapseHandlerTextMore;
    } else {
      this.collapsePanel.current.classList.add('show');
      this.collapseHandlerText.current.innerHTML = collapseHandlerTextLess;
      this.collapseHandlerIcon.current.classList.remove('fa-chevron-down');
      this.collapseHandlerIcon.current.classList.add('fa-chevron-up');
    }
  }
  render() {
    let {
      campaignDetails,
      draftFlag,
      disabledFlag,
      campaign,
      status,
      showkanaMsg
    } = this.props;

    return (
      <div className="cw-campaign--review-section mb-40 cw-print-show">
        <div className="cw-campaign--review-section">
          <div className="mb-20">
            <h3 className="cw-heading--primary">
              <span>PRM</span> Connection Details
            </h3>
          </div>
          {showkanaMsg ? (
            <div className="cw-striped-row ">
              <div className="row">
                <p>{constant.PRM_TITLE_TEXT}</p>
              </div>
            </div>
          ) : (
            <div className="cw-campaign--review-section-content">
              <div className="cw-separator mb-20">
                <div className="cw-striped-row ">
                  <div className="row">
                    <div className="col-sm-3">
                      <strong>Campaign URL</strong>
                    </div>
                    <div
                      className={
                        this.props.colValClass
                          ? this.props.colValClass
                          : 'col-sm-5'
                      }
                    ></div>
                    <div className="col-sm-4">
                      {this.props.hideInputFields === 'true' ? (
                        campaignDetails.profileStore &&
                        campaignDetails.profileStore.url &&
                        campaignDetails.profileStore.url !== '' ? (
                          campaignDetails.profileStore.url
                        ) : status !== 'Cancelled' ? (
                          'Awaiting'
                        ) : (
                          ''
                        )
                      ) : (
                        <input
                          type="text"
                          className="form-control"
                          name="profileStoreUrl"
                          id="profileStoreUrl"
                          value={
                            campaignDetails.profileStore &&
                            campaignDetails.profileStore.url &&
                            campaignDetails.profileStore.url !== ''
                              ? campaignDetails.profileStore.url
                              : 'Awaiting'
                          }
                          disabled={disabledFlag}
                          readOnly={true}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className="collapse"
                  id="prmDetailsCollapsed"
                  ref={this.collapsePanel}
                >
                  <div className="cw-striped-row">
                    <div className="row">
                      <div className="col-sm-3">
                        <strong>Secret Key</strong>
                      </div>
                      <div
                        className={
                          this.props.colValClass
                            ? this.props.colValClass
                            : 'col-sm-5'
                        }
                      ></div>
                      <div className="col-sm-4">
                        {this.props.hideInputFields === 'true' ? (
                          campaignDetails.profileStore &&
                          campaignDetails.profileStore.secretkey &&
                          campaignDetails.profileStore.secretkey !== '' ? (
                            campaignDetails.profileStore.secretkey
                          ) : status !== 'Cancelled' ? (
                            'Awaiting'
                          ) : (
                            ''
                          )
                        ) : (
                          <input
                            type="text"
                            className="form-control"
                            name="profileStoreSecertKey"
                            id="profileStoreSecertKey"
                            value={
                              campaignDetails.profileStore &&
                              campaignDetails.profileStore.secretkey &&
                              campaignDetails.profileStore.secretkey !== ''
                                ? campaignDetails.profileStore.secretkey
                                : 'Awaiting'
                            }
                            disabled={disabledFlag}
                            readOnly={true}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="cw-striped-row">
                    <div className="row">
                      <div className="col-sm-3">
                        <strong>Access Key</strong>
                      </div>
                      <div
                        className={
                          this.props.colValClass
                            ? this.props.colValClass
                            : 'col-sm-5'
                        }
                      ></div>
                      <div className="col-sm-4">
                        {this.props.hideInputFields === 'true' ? (
                          campaignDetails.profileStore &&
                          campaignDetails.profileStore.accesskey &&
                          campaignDetails.profileStore.accesskey !== '' ? (
                            campaignDetails.profileStore.accesskey
                          ) : status !== 'Cancelled' ? (
                            'Awaiting'
                          ) : (
                            ''
                          )
                        ) : (
                          <input
                            type="text"
                            className="form-control"
                            name="profileStoreAccessKey"
                            id="profileStoreAccessKey"
                            value={
                              campaignDetails.profileStore &&
                              campaignDetails.profileStore.accesskey &&
                              campaignDetails.profileStore.accesskey !== ''
                                ? campaignDetails.profileStore.accesskey
                                : 'Awaiting'
                            }
                            disabled={disabledFlag}
                            readOnly={true}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <a
                  className="collapse-h pt-2"
                  href="#prmDetailsCollapsed"
                  role="button"
                  aria-expanded="false"
                  aria-controls="prmDetailsCollapsed"
                  onClick={this._handleCollapse}
                >
                  <span ref={this.collapseHandlerText}>
                    View more prm connection details
                  </span>
                  <i
                    className="fas fa-chevron-down ml-1"
                    ref={this.collapseHandlerIcon}
                  ></i>
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    );

    //return null;
  }
}
