
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TimlineItem from 'components/organisms/TimelineItem/TimelineItem';
import { Accordion, Menu } from 'semantic-ui-react';
import 'semantic-ui-css/components/accordion.min.css';

function Timeline({ releaseData, handleEdit, handleDelete, isAdminUser, rerender }) {
  const [state, setState] = useState({ activeIndex: 0 });
  const [selectedQuarter, setSelectedselectedQuarter] = useState([]);

  useEffect(() => {
    if(rerender) {
      setSelectedselectedQuarter([...Array(4).keys()].map(i => `${releaseData?.[0]?.year}-${i}`))
    }
  }, [releaseData])

  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = state;
    const newIndex = activeIndex === index ? -1 : index;

    const year = titleProps.content.split("Year ")[1]

    setState({ activeIndex: newIndex });
    setSelectedselectedQuarter([...Array(4).keys()].map(i => `${year}-${i}`))
  };

  const handleQuaterClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = [...selectedQuarter];
    
    const currentIndexPosition = selectedQuarter.indexOf(index);
    if (currentIndexPosition > -1) {
      newIndex.splice(currentIndexPosition, 1);
    } else {
      newIndex.push(index);
    }

    setSelectedselectedQuarter(newIndex)
  };

  return (
    <div className="timeline">
      <Accordion vertical="true" fluid className="year-accordion">
        {releaseData.map((years, i) => (
          <Menu.Item key={'year-' + years.year} className="acc-menu-item">
            <Accordion.Title
              active={state.activeIndex === i}
              content={'Year ' + years.year}
              index={i}
              onClick={handleClick}
              className="timeline__title bold-text"
              icon={state.activeIndex === i ? 'chevron up' : 'chevron down'}
            />
            <Accordion.Content active={state.activeIndex === i} className="time-content">
            <div className="quarter-accordion">
            <Accordion vertical="true" className="sub-accordion">
            {years.quarters.map((quarter, indx) => (
              <Menu.Item key={'year-' + years.year + indx}>
                  <Accordion.Title
                    active={selectedQuarter.includes(`${years.year}-${indx}`)}
                    content={`Quarter ${quarter[0]?.quarter}, ${years.year}`}
                    index={`${years.year}-${indx}`}
                    onClick={handleQuaterClick}
                    className="timeline__title bold-text"
                    icon={selectedQuarter.includes(`${years.year}-${indx}`) ? 'chevron up' : 'chevron down'}
                  />
                  <TimlineItem
                    data={quarter}
                    activeIndex={selectedQuarter.includes(`${years.year}-${indx}`)}
                    year={years.year}
                    key={'quarter-' + years.year + '-' + indx}
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                    isAdminUser={isAdminUser}
                  />
                </Menu.Item>))
              }
              {/* <TimlineItem
                data={quarter}
                activeIndex={state.activeIndex === i}
                year={years.year}
                key={'quarter-' + years.year + '-' + indx}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
              /> */}
            {/* ))}
              {releaseData.map((years, i) => (
                <Menu.Item key={'year-' + years.year}>
                  <Accordion.Title
                    active={state.activeIndex === i}
                    content={'Year ' + years.year}
                    index={i}
                    onClick={handleClick}
                    className="timeline__title bold-text"
                    icon={state.activeIndex === i ? 'chevron up' : 'chevron down'}
                  />
                  {years.quarters.map((quarter, indx) => (
                    <TimlineItem
                      data={quarter}
                      activeIndex={state.activeIndex === i}
                      year={years.year}
                      key={'quarter-' + years.year + '-' + indx}
                      handleEdit={handleEdit}
                      handleDelete={handleDelete}
                    />
                  ))}
                </Menu.Item>
              ))} */}
            </Accordion>
            </div>
            </Accordion.Content>
            
            
            {/* {years.quarters.map((quarter, indx) => (
              
              <TimlineItem
                data={quarter}
                activeIndex={state.activeIndex === i}
                year={years.year}
                key={'quarter-' + years.year + '-' + indx}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
              />
            ))} */}
          </Menu.Item>
        ))}
      </Accordion>
    </div>
  );
}

Timeline.propTypes = {
  releaseData: PropTypes.array.isRequired
};

export default Timeline;
