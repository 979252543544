import axios from 'axios';
import {
	ASYNC_IN_PROGRESS_REQUESTER_TAB,
	DOMAIN_ALREADY_EXISTS,
	GET_DOMAIN_DATA,
	NEW_DOMAIN_ADDED,
	UPDATE_DOMAIN,
	DELETE_DOMAIN,
	DOMAIN_CANNOT_BE_DELETED,
} from '../../actionTypes';
import constant from '../../constants';

export function addNewDomain(domainData) {
	return function(dispatch) {
		dispatch({
			type: ASYNC_IN_PROGRESS_REQUESTER_TAB,
			isLoading: true,
		});
		return axios
			.post(constant.serviceUrls.REQUESTER_DOMAIN_URL, domainData)
			.then((response) => {
				if (response.status === 201) {
					dispatch({
						type: NEW_DOMAIN_ADDED,
						newDomainName: domainData['domain'],
					});
					dispatch(fetchDomainData('desc', 'creationDate'));
				}
			})
			.catch(function(error) {
				if (
					error.response.data.message &&
					error.response.data.message === constant.apiResponseText.DOMAIN_ALREADY_EXISTS
				) {
					dispatch({ type: ASYNC_IN_PROGRESS_REQUESTER_TAB, isLoading: false });
					dispatch({
						type: DOMAIN_ALREADY_EXISTS,
					});
					return constant.apiResponseText.DOMAIN_ALREADY_EXISTS;
				}
			});
	};
}

export function fetchDomainData(orderBy, sortBy,search ='') {
	return function(dispatch) {
		dispatch({
			type: ASYNC_IN_PROGRESS_REQUESTER_TAB,
			isLoading: true,
		});

		let  queryParam =
			orderBy && sortBy
				? `orderBy=${orderBy}&sortBy=${sortBy}`
				: orderBy
				? `orderBy=${orderBy}`
				: sortBy
				? `sortBy=${sortBy}`
				: '';

		queryParam = queryParam + (search ? '&search=' + search : '') 
			
		axios.get(constant.serviceUrls.REQUESTER_DOMAIN_URL + `?${queryParam}`).then((response) => {
			const domainList = response.data;
			dispatch({ type: ASYNC_IN_PROGRESS_REQUESTER_TAB, isLoading: false });
			dispatch({
				type: GET_DOMAIN_DATA,
				domainList,
			});
			return true;
		});
	};
}

export function editDomain(domain) {
	return function(dispatch) {
		dispatch({
			type: ASYNC_IN_PROGRESS_REQUESTER_TAB,
			isLoading: true,
		});

		return axios
			.post(constant.serviceUrls.REQUESTER_DOMAIN_URL, {
				id: domain.id,
				name: domain.name,
				domain: domain.domain,
			})
			.then((response) => {
				dispatch({
					type: UPDATE_DOMAIN,
					newDomainName: domain['domain'],
				});
				dispatch(fetchDomainData('desc', 'creationDate'));
			})
			.catch(function(error) {
				if (
					error.response.data.message &&
					error.response.data.message === constant.apiResponseText.DOMAIN_ALREADY_EXISTS
				) {
					dispatch({ type: ASYNC_IN_PROGRESS_REQUESTER_TAB, isLoading: false });
					dispatch({
						type: DOMAIN_ALREADY_EXISTS,
					});
					return constant.apiResponseText.DOMAIN_ALREADY_EXISTS;
				}
			});
	};
}

export function deleteDomain(domainName) {
	return function(dispatch) {
		dispatch({
			type: ASYNC_IN_PROGRESS_REQUESTER_TAB,
			isLoading: true,
		});

		return axios
			.post(constant.serviceUrls.REQUESTER_DELETE_DOMAIN_URL, {
				domain: domainName,
			})
			.then((response) => {
				if (
					response.data.message &&
					response.data.message === constant.apiResponseText.DOMAIN_DELETION_SUCCESS
				) {
					dispatch({ type: DELETE_DOMAIN, deletedDomainName: domainName });
				}
				dispatch(fetchDomainData());
			})
			.catch(function(error) {
				dispatch({ type: ASYNC_IN_PROGRESS_REQUESTER_TAB, isLoading: false });
				dispatch({
					type: DOMAIN_CANNOT_BE_DELETED,
					deletedDomainName: domainName,
				});
			});
	};
}
