import React, { useEffect, useState } from 'react';
import { FieldError } from '../../atoms/FieldError';
import { get } from 'lodash';
import { useWatch } from 'react-hook-form';
import SingleColorPicker from '../SingleColorPicker/SingleColorPicker';
import Size from 'components/atoms/Size/Size';
import FontSelector from 'pages/BrandEmailDefaults/FontSelector/FontSelector';

export const ParagraphDefaultInput = ({
  name,
  description,
  type,
  rules,
  control,
  register,
  label,
  errors,
  options,
  watchInput,
  defaultValue,
  defaultWatchValue,
  getValues,
  setValue,
  ...props
}) => {
  const errorMessages = get(errors, name);
  const hasError = !!(errors && errorMessages);

  const [values, setValues] = useState(
    defaultValue || {
      color: '',
      backgroundColor: '',
      borderColor: '',
      fontSize: 14,
      borderRadius: 4
    }
  );

  const brand = useWatch({
    control,
    name: watchInput,
    defaultValue: defaultWatchValue
  });

  useEffect(() => {
    if (props.onWatch) {
      props.onWatch(brand, onChange);
    }
  }, [brand]);

  const onChange = (property, value) => {
    setValues(values => {
      const updatedValue = { ...values, [property]: value };
      setValue(name, updatedValue);
      return updatedValue;
    });
  };

  if (!brand) {
    return null;
  }

  return (
    <div className="form-group mt-30">
      <div className="d-flex flex-column">
        <strong>Paragraph</strong>
        <span className="description mb-10">{description}</span>
      </div>
      <FontSelector
        sampleText="I am your paragraph"
        label="Paragraph font"
        placeholder="Select a paragraph font"
        property="fontFamily"
        onChange={onChange}
        value={values.fontFamily}
        values={values}
      />
      <SingleColorPicker
        className="mt-20"
        label="Paragraph colour"
        property="color"
        onChange={onChange}
        value={values.color}
      />
      <Size
        className="mt-20"
        value={values.fontSize}
        label="Text size"
        onChange={size => onChange('fontSize', size)}
      />
      {!props.disableError && <FieldError errors={errors} name={name} />}
    </div>
  );
};
