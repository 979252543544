const validator = {
	brandUrl: {
		rules: [
			{
				validation: 'regex',
				test: /https:\/\/?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/i,
				message: '',
				type: 'custom',
			},
		],
		errors: [],
		valid: false,
		state: '',
	},
	cookiePolicyUrl: {
		rules: [
			{
				validation: 'regex',
				test: /https:\/\/?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/i,
				message: '',
				type: 'custom',
			},
		],
		errors: [],
		valid: false,
		state: '',
	},
	privacyPolicyUrl: {
		rules: [
			{
				validation: 'regex',
				test: /https:\/\/?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/i,
				message: '',
				type: 'custom',
			},
		],
		errors: [],
		valid: false,
		state: '',
	},
	customCampaignId: {
		rules: [
			{
				validation: 'regex',
				test: /^(PN)[0-9]{6}$/,
				message: '',
				type: 'custom',
			},
		],
		errors: [],
		valid: false,
		state: '',
	},
	tncUrl: {
		rules: [
			{
				validation: 'regex',
				test: /https:\/\/?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/i,
				message: '',
				type: 'custom',
			},
		],
		errors: [],
		valid: false,
		state: '',
	},
	disclaimerText: {
		rules: [
			{
				validation: 'maxlength',
				test: /https:\/\/?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/i,
				message: '',
				type: 'custom',
			},
		],
		errors: [],
		valid: false,
		state: '',
	},
};

export default validator;
