import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import MenuAccordion from '../MenuAccordion/MenuAccordion';
import constant from '../../../constants';
import { getDefaultOptInTypes } from '../../../utilities/util';
import { transformUsageOfQuestiosnData } from '../../../selectors';

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function transformFieldList(list) {
  if (Array.isArray(list)) {
    return list.map(item => {
      return {
        ...item,
        percentage: getRandomInt(1, 100),
        count: getRandomInt(1, 300)
      };
    });
  }
  return list;
}

window.transformFieldList = transformFieldList;

const FieldStatsAccordion = ({ data, startingIndex }) => {
  const formMetaData = useSelector(state => state.saasFormAuthoring);
  const { campaignConfig } = useSelector(state => state.app);
  const transFormedData = useMemo(() => transformUsageOfQuestiosnData(data), [
    data
  ]);
  const accordionCloseButtonHandler = btt => {};

  const questionTypes = [
    {
      answers: [
        {
          label: '',
          value: '',
          englishLabel: '',
          translatedLabel: '',
          translatedId: '',
          isNew: false,
          istranslatedNew: false
        },
        {
          label: '',
          value: '',
          englishLabel: '',
          translatedLabel: '',
          translatedId: '',
          isNew: false,
          istranslatedNew: false
        }
      ],
      isNew: false,
      istranslatedNew: false,
      translatedLabel: '',
      englishLabel: '',
      dataType: 'questionAnswerPair',
      id: '',
      label: 'Single Choice Question',
      mandatoryToggle: false,
      mandatoryMessage: '',
      questionLabel: '',
      questionType: 'Single choice',
      toolTip: '',
      translatedId: '',
      value: 'singleChoice'
    },
    {
      answers: [
        {
          label: '',
          value: '',
          englishLabel: '',
          translatedLabel: '',
          translatedId: '',
          isNew: false,
          istranslatedNew: false
        },
        {
          label: '',
          value: '',
          englishLabel: '',
          translatedLabel: '',
          translatedId: '',
          isNew: false,
          istranslatedNew: false
        }
      ],
      isNew: false,
      istranslatedNew: false,
      translatedLabel: '',
      englishLabel: '',
      dataType: 'questionAnswerPair',
      id: '',
      label: 'Multiple Choice Question',
      mandatoryToggle: false,
      mandatoryMessage: '',
      questionLabel: '',
      questionType: 'Multiple choice',
      toolTip: '',
      translatedId: '',
      value: 'multipleChoice',
      maxAnswerLimit: 'No Limit'
    },

    {
      answers: [],
      isNew: false,
      istranslatedNew: false,
      translatedLabel: '',
      englishLabel: '',
      dataType: 'questionAnswerPair',
      id: '',
      label: 'Free Text Question',
      mandatoryToggle: false,
      mandatoryMessage: '',
      questionLabel: '',
      questionType: 'Free text',
      toolTip: '',
      translatedId: '',
      value: 'freeText'
    },
    {
      answers: [],
      isNew: false,
      istranslatedNew: false,
      translatedLabel: '',
      englishLabel: '',
      dataType: 'questionAnswerPair',
      id: '',
      label: 'Free Text Area',
      mandatoryToggle: false,
      mandatoryMessage: '',
      questionLabel: '',
      questionType: 'Free text',
      toolTip: '',
      translatedId: '',
      value: 'freeText'
    }
  ];

  const incentiveFields = [
    {
      dataType: 'entryCode',
      label: 'Unique entry code',
      type: 'incentiveFields',
      fieldLabel: '',
      mandatoryToggle: false,
      mandatoryMessage: '',
      toolTip: ''
    },
    {
      dataType: 'fileUpload',
      fieldLabel: '',
      maxFileSize: constant.INCENTIVE_UPLOAD_MAX_FILE_SIZE,
      type: 'incentiveFields',
      mandatoryToggle: false,
      mandatoryMessage: '',
      toolTip: '',
      label: 'File Upload',
      validationMessage: '',
      failedUploadMessage: '',
      values: [
        {
          key: 'jpg',
          value: 'jpg',
          isChecked: true
        },
        {
          key: 'jpeg',
          value: 'jpeg',
          isChecked: true
        },
        {
          key: 'tiff',
          value: 'tiff',
          isChecked: true
        },
        {
          key: 'tif',
          value: 'tif',
          isChecked: true
        },
        {
          key: 'png',
          value: 'png',
          isChecked: true
        },
        {
          key: 'Video (3gp, 3g2, mp4, flv, mkv, avi, mov)',
          value: '3gp, 3g2, mp4, flv, mkv, avi, mov',
          isChecked: true
        }
      ]
    }
  ];

  const sampleDetails = {
    answers: [
      {
        label: '',
        value: 'prize-0'
      }
    ],
    dataType: 'samplePrizeSelection',
    questionType: 'Single choice',
    label: 'Sample Details',
    fieldLabel: '',
    type: 'incentiveFields',
    mandatoryToggle: false,
    mandatoryMessage: '',
    toolTip: ''
  };

  const optInType = getDefaultOptInTypes(campaignConfig, {
    brand: {
      _id: '62cfd420f3164e7a274634e4',
      code: 'BH0162',
      name: 'Dove'
    },
    country: {
      _id: '62cfd40cf3164e7a2746136a',
      code: 'GB',
      name: 'United Kingdom'
    }
  });

  const getCampaignConfigAccordionList = (campaign = {}) => {
    let listArray = [
      {
        listId: '1',
        listLabel: 'Profile fields',
        // listItems: constant.profileFields
        listItems: transformFieldList(
          formMetaData.accordionData || constant.profileFields
        )
      },
      {
        listId: '2',
        listLabel: 'Form elements',
        listItems: transformFieldList(formMetaData.accordionFormElement)
      }
    ];

    listArray.push({
      listId: '3',
      listLabel: 'Custom Q&As',
      listItems: transformFieldList(questionTypes)
    });

    listArray.push({
      listId: '4',
      listLabel: 'Incentive fields',
      listItems: transformFieldList([incentiveFields, sampleDetails])
    });

    listArray.push({
      listId: '5',
      listLabel: 'Consents & compliance',
      listItems: transformFieldList(optInType)
    });
    return listArray;
  };
  return (
    <MenuAccordion
      menuItems={transFormedData || getCampaignConfigAccordionList()}
      editCampaign={false}
      clickHandler={() => {}}
      closeHandler={accordionCloseButtonHandler}
      className="custom-menu-acc"
      statsMode
      showStats
      startingIndex={startingIndex}
    />
  );
};

export default FieldStatsAccordion;
