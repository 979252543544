import React from 'react';
import groupIcon from '../images/groupIcon.png';
const BrandDetailsKnowMoreSection = props => {
  return (
    <div>
      {props.showMasterIcon ? (
        <>
          <div className="brandidetails-show-master-icon">
            <img src={groupIcon} alt={groupIcon} width="25" height="25"></img>
            {` This campaign includes new master data requests.`}
          </div>
          <br />
        </>
      ) : null}
      {props.data && props.data.title ? (
        <div
          className={
            props.data.titleClass
              ? props.data.titleClass
              : 'cw-heading--primary'
          }
        >
          {props.data.title}
        </div>
      ) : (
        ''
      )}
      {props.data && props.data.subTitle ? (
        <>
          <br />
          <p>{props.data.subTitle}</p>
        </>
      ) : (
        <br />
      )}
      <ul className={`ml-20`}>
        {props.data.guideLine.map((elem, index) => {
          return (
            <>
              <li className="brandidetails">{elem.text}</li>
            </>
          );
        })}
      </ul>
      {props.noLineBreak ? '' : <br />}
    </div>
  );
};

export default BrandDetailsKnowMoreSection;
