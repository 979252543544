import {
  FETCH_EMAIL_RAW_BLOCKS,
  FETCH_EMAIL_RAW_BLOCKS_SUCCESS,
  FETCH_EMAIL_RAW_BLOCKS_FAILURE,
  DELETE_EMAIL_RAW_BLOCK_SUCCESS
} from '../../actionTypes';
import {
  regenerateRowThumbnailService,
  getEmailRowBlockService,
  deleteEmailRowService
} from '../../services/emailCoreRaws';

export const regenerateRowThumbnail = (_id) => {
  return async function() {
    try {
      await regenerateRowThumbnailService(_id);
    } catch (error) {}
  };
};

export const getEmailRowBlock = (query, pageNumber = 1) => {
  return async function(dispatch) {
    try {
      let userQuery = query;
      dispatch({ type: FETCH_EMAIL_RAW_BLOCKS });
      const result = await getEmailRowBlockService(userQuery, pageNumber);
      dispatch({ type: FETCH_EMAIL_RAW_BLOCKS_SUCCESS, payload: result });
    } catch (error) {
      dispatch({
        type: FETCH_EMAIL_RAW_BLOCKS_FAILURE,
        payload: { message: error.message }
      });
    }
  };
};

export const deleteEmailRow = (id, callback) => {
  return async function(dispatch) {
    try {
      await deleteEmailRowService(id);
      // moved callback invocation above dispath as it was not getting invoked
      if(callback){
        callback(null);
      }
      dispatch({ type: DELETE_EMAIL_RAW_BLOCK_SUCCESS, payload: id });
    } catch (error) {
      callback(error);
    }
  };
};
