import React, { useState } from 'react';
import { get, isEmpty } from 'lodash';
import classNames from 'classnames';
import moment from 'moment';
import ApproversInfoBoxModal from 'components/molecules/ApproversInfoBoxModal/ApproversInfoBoxModal';

const EmailApproverInfo = ({ emailBriefing, className, userData = {} }) => {
  const [isInfoBoxOpen, setIsInfoBoxOpen] = useState(false);
  const { group } = userData;
  const assignedApprover = get(emailBriefing, 'approvers', {});
  const { isApprovarAssignedToMarket } = assignedApprover;
  const status = get(emailBriefing, 'status', null);
  // const mailingList = get(assignedApprover, 'approverMailList', []);
  const mailingList = isApprovarAssignedToMarket
    ? get(assignedApprover, 'approverMailList', [])
    : get(assignedApprover, 'globalLeadsActualMailList', []);
  const nameList =
    mailingList &&
    mailingList.length &&
    mailingList.map(user => (user.name ? user.name : user)).join(', ');
  let endDate = new Date();
  let startDate = assignedApprover.approvalAssignedDate
    ? new Date(assignedApprover.approvalAssignedDate)
    : new Date();
  const duration = moment(endDate)
    .startOf('day')
    .diff(moment(startDate).startOf('day'), 'days');
  const durationInDays = `${duration} ${duration === 1 ? 'day' : 'days'} ago`;
  const assignmentDate = moment(assignedApprover.approvalAssignedDate).format(
    'DD-MMM-YYYY'
  );
  const durationString = duration
    ? `on ${assignmentDate}, ${durationInDays}`
    : 'today';
  const approvalDate = get(emailBriefing, 'approvalDate', null);
  const hideAssignedToInfo = () => {
    const isApproverNotAssigned = isEmpty(mailingList);
    // hide if the campaign has been rejected.
    if (status === '5') {
      return true;
    }
    if (status !== '3') {
      return isApproverNotAssigned ? true : !!approvalDate;
    } else {
      return isApproverNotAssigned;
    }
  };
  if (hideAssignedToInfo()) {
    return null;
  } else {
    return (
      <>
        <div style={{ borderRadius: '10px' }} role="alert">
          <div className="d-flex">
            <div className="mr-3" style={{ paddingTop: 8, paddingBottom: 8 }}>
              <i style={{ fontSize: 32 }} className="fa fa-info-circle"></i>
            </div>
            <div className={classNames('approver-assigment-info', className)}>
              <ApproversInfoBoxModal
                isOpen={isInfoBoxOpen}
                onClose={() => {
                  setIsInfoBoxOpen(false);
                }}
                nameList={nameList}
                isApprovarAssignedToMarket={
                  assignedApprover.isApprovarAssignedToMarket
                }
                group={group}
              />
              <p className="">
                {assignedApprover.isApprovarAssignedToMarket
                  ? `Assigned for approval ${durationString}`
                  : `Assigned for approval to global leads ${durationString}`}
                {'. '}
                <button
                  className="inline-button"
                  style={{ fontWeight: 'bold' }}
                  onClick={() => setIsInfoBoxOpen(true)}
                >
                  See approvers
                </button>
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default EmailApproverInfo;
