import React from 'react';
import Modal from 'react-modal';

import constants from 'constants/config';
import ExternalLinkIcon from '../../../images/external-link.png';
import { ReactComponent as ApplyBrandStylesIcon } from 'images/apply-brand-styles-icon.svg';

export const ApplyBrandStyleModal = ({
  isOpen,
  brandStylesExist,
  brandStylesApplied,
  onClose,
  applyBrandStyles,
  resetBrandStyles
}) => {
  // const handleStylesAction = () => {
  //   if (brandStylesApplied) {
  //     resetBrandStyles();
  //   } else {
  //     applyBrandStyles();
  //   }

  //   setTimeout(() => {
  //     onClose();
  //   }, 500);
  // };

  return (
    <Modal
      scrollable={true}
      isOpen={isOpen}
      onRequestClose={onClose}
      className="cw-modal cw-modal--email-checklist-modal apply-styles-modal"
      contentLabel="Campaign Wizard 2.0"
      style={{
        overlay: {
          backgroundColor: constants.EMAIL_OVERLAY_BACKGROUND
        }
      }}
    >
      <div className="email-d position-relative">
        <div className="d-flex mb-20">
          <div className="email-overlay-header-box">
            <ApplyBrandStylesIcon />
          </div>
          <div className="ml-10 position-relative" style={{ flexGrow: 1 }}>
            <span className={`qna-guideline-close`} onClick={onClose}></span>
            <div className={'title cw-heading--saascampaign mt-3'}>
              Apply brand styling&nbsp;
              <span className="badge badge-pill badge-dark ml-2">BETA</span>
            </div>
          </div>
        </div>

        <div class="pb-3">
          {brandStylesExist ? (
            <>
              <p className="pb-2">
                You can apply your brand's master styling to this email. The
                email will be replaced with fonts, button styling and other
                style specifications you have defined for your brand. You can
                review your brand styling&nbsp;
                <a
                  href="/campaign-wizard/brand-style"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here
                  <div className="external-link-wrapper">
                    <img
                      src={ExternalLinkIcon}
                      alt="External Link"
                      className="external-link-icon"
                    />
                  </div>
                </a>
                .
              </p>
              <p>
                Once your brand style is applied, you will not be able to undo
                the changes.
              </p>
            </>
          ) : (
            <>
              <p>
                It looks like no brand style settings are saved for this brand
                yet.
              </p>
              <p>
                Set up your branding once and apply it to your email with a
                click of a button.
              </p>
            </>
          )}
        </div>

        <div className="mt-3">
          {brandStylesExist ? (
            <button
              type="submit"
              onClick={() => {
                applyBrandStyles();
                setTimeout(() => {
                  onClose();
                }, 500);
              }}
              className="btn btn-primary mb-10 cw-print-hide text-nowrap guideline-close-button"
            >
              Apply brand style
            </button>
          ) : (
            <a
              href="/campaign-wizard/brand-style"
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-primary mb-10 cw-print-hide text-nowrap guideline-close-button"
            >
              Setup brand styling <i class="fas fa-external-link-alt pl-1"></i>
            </a>
          )}
          {/* <button
            type="submit"
            onClick={handleStylesAction}
            className="btn btn-primary mb-10 cw-print-hide text-nowrap guideline-close-button"
          >
            {brandStylesApplied ? 'Remove brand style' : 'Apply brand style'}
          </button> */}
          <button
            type="submit"
            onClick={onClose}
            className="btn btn btn-outline-secondary mb-10 cw-print-hide text-nowrap mr-2 guideline-close-button"
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};
