import axios from 'axios';
import {
  DELETE_CAMPAIGN,
  SERVICE_ERROR_OVERVIEW,
  GET_CAMPAIGN_DETAILS_OVERVIEW,
  ASYNC_IN_PROGRESS,
  CANCEL_CAMPAIGN,
  SET_CAMPAIGN_TO_EDITED,
  RESET_CAMPAIGN_TO_EDITED,
  PUSH_STATE,
  GET_CAMPAIGN,
  CLONE_CAMPAIGN_DATA,
  PUSH_CLONED_DATA,
  POST_SUBMIT_REVIEW
} from '../actionTypes';
import constant from '../constants';
import { getFormatedDate } from '../selectors';
import { getFormPreviewUrl } from './preview';
import { getAuthoringFieldData } from './saasFormAuthoring';

export function fetchCampaignData(
  Id,
  previewAvaialable,
  isProfilePage,
  campaignType,
  countryCode,
  isOverviewPage
) {
  let preview = previewAvaialable ? previewAvaialable : false;
  return function(dispatch) {
    let getCampaignDataEndpoint =
      constant.serviceUrls.CAMPAIGN_MASTER_SERVICE_URL + '/' + Id;
    if (isOverviewPage) {
      getCampaignDataEndpoint =
        getCampaignDataEndpoint + '?overview=' + isOverviewPage;
    }
    axios
      .get(getCampaignDataEndpoint)
      .then(response => {
        let campaign = response.data[0];
        let language = campaign.language && campaign.language.code;
        if (
          ((campaign.websiteType &&
            campaign.websiteType.name === 'Adobe SaaS') ||
            campaign.isPlatformAgnostic) &&
          preview &&
          campaign.status !== '1' && //&&
          // campaign.status !== "2"
          campaign && !campaign.isMasterData
        ) {
          dispatch(getFormPreviewUrl(Id, language, isOverviewPage));
        }
        if (
          ((campaign.websiteType &&
            campaign.websiteType.name === 'Adobe SaaS') ||
            campaign.isPlatformAgnostic ||
            (campaign && campaign.isMasterData)) &&
          isProfilePage
        ) {
          const country = campaign.country && campaign.country.code;
          dispatch(
            getAuthoringFieldData(
              language,
              campaign && campaign.campaignType,
              country === 'US' ? country : '',
              false
            )
          );
        }
        dispatch({ type: GET_CAMPAIGN_DETAILS_OVERVIEW, campaign });
      })
      .catch(error => {
        dispatch({ type: ASYNC_IN_PROGRESS, isLoading: false });
        dispatch({ type: SERVICE_ERROR_OVERVIEW, error });
      });
  };
}

export function deleteCampaign(id, name) {
  return function(dispatch) {
    axios({
      method: 'POST',
      url: constant.serviceUrls.CAMPAIGN_MASTER_SERVICE_URL + '/' + id
    }).then(response => {
      dispatch({ type: DELETE_CAMPAIGN, approved: 'deleted' });
    });
  };
}

export function cancelCampaign(id, userName, userEmail) {
  return function(dispatch) {
    dispatch({
      type: ASYNC_IN_PROGRESS,
      isLoading: true
    });
    let cancelCampaign = {
      uid: id,
      status: '9',
      userName: userName,
      userEmail: userEmail
    };
    axios({
      method: 'POST',
      url: constant.serviceUrls.CAMPAIGN_STATUS_UPDATE_SERVICE_URL,
      data: cancelCampaign
    })
      .then(response => {
        axios
          .get(constant.serviceUrls.CAMPAIGN_MASTER_SERVICE_URL + '/' + id)
          .then(response => {
            let campaign = response.data[0];
            dispatch({
              type: ASYNC_IN_PROGRESS,
              isLoading: false
            });
            dispatch({ type: GET_CAMPAIGN_DETAILS_OVERVIEW, campaign });
            dispatch({ type: CANCEL_CAMPAIGN, cancelled: true });
          });
      })
      .catch(error => {
        dispatch({ type: ASYNC_IN_PROGRESS, isLoading: false });

        dispatch({ type: SERVICE_ERROR_OVERVIEW, error });
      });
  };
}

export function downloadGigyaCssFile(Id, file) {
  return function(dispatch) {
    dispatch({
      type: ASYNC_IN_PROGRESS,
      isLoading: true
    });

    axios
      .get(constant.serviceUrls.CAMPAIGN_DATA_SERVICE_URL + '/download/' + file)
      .then(response => {
        dispatch({ type: ASYNC_IN_PROGRESS, isLoading: false });

        let fileName = file;
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          // IE variant
          window.navigator.msSaveOrOpenBlob(
            new Blob([response.data], {
              type:
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            }),
            fileName
          );
        } else {
          const url = window.URL.createObjectURL(
            new Blob([response.data], {
              type:
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
        }
      });
  };
}

export function setCampaignToEdit(reset = false) {
  return function(dispatch) {
    if (reset) {
      dispatch({
        type: RESET_CAMPAIGN_TO_EDITED,
        editCampaign: false
      });
    } else {
      dispatch({
        type: SET_CAMPAIGN_TO_EDITED,
        editCampaign: true
      });
    }
  };
}
export function saasCampaignToEdit(uuid) {
  return function(dispatch) {
    axios
      .post(constant.saasServiceUrls.SAAS_EDIT_CAMPAIGN_ENDPOINT + uuid)
      .then(response => {
        if (response && response.status === 201) {
          dispatch({
            type: SET_CAMPAIGN_TO_EDITED,
            editCampaign: true
          });
          dispatch({ type: POST_SUBMIT_REVIEW, isEdited: false });
        }
      });
  };
}
export function cloneCampaign(id, newuid, userName, userEmail) {
  return function(dispatch, getState) {
    const {
      app: { campaignConfig }
    } = getState();
    return axios
      .get(constant.serviceUrls.CAMPAIGN_CLONE_URL + '/' + id)
      .then(response => {
        let campaign = response.data;
        dispatch({
          type: PUSH_STATE,
          isLoading: false,
          campaign
        });
        dispatch({ type: CLONE_CAMPAIGN_DATA, campaign });
        campaign.campaignDetail.uid = newuid;
        campaign.uid = newuid;
        campaign.isClone = true;
        campaign.campaignDetail.requestersEmail = userEmail;
        let clonedStartDate = campaign.campaignDetail.startDate;
        let clonedEndDate = campaign.campaignDetail.endDate;
        let profileStoreData = campaign.campaignDetail.profileStore;
        let formattedStartDate = getFormatedDate(clonedStartDate, false, true);

        if (profileStoreData.gigyaPreferenceCentreScreenSetName !== undefined) {
          profileStoreData.gigyaPreferenceCentreScreenSetName = 'Awaiting';
        }
        if (profileStoreData.gigyaProductionProfileStoreKey !== undefined) {
          profileStoreData.gigyaProductionProfileStoreKey = 'Awaiting';
        }
        if (profileStoreData.gigyaScreenSetName !== undefined) {
          profileStoreData.gigyaScreenSetName = 'Awaiting';
        }
        if (profileStoreData.gigyaStartScreenUrl !== undefined) {
          profileStoreData.gigyaStartScreenUrl = 'Awaiting';
        }

        campaign.campaignDetail.startDate = '';
        campaign.campaignDetail.endDate = '';

        if (!campaignConfig.showAdditionalOptins) {
          if (campaign.campaignDetail.consents) {
            campaign.campaignDetail.consents = campaign.campaignDetail.consents.filter(
              elem =>
                elem.optInType !==
                  constant.consentTypes.additionalSubscription &&
                elem.optInType !== constant.consentTypes.additionalSubscription1
            );
          }
        }

        let campaignDetails = campaign && campaign.campaignDetail;
        const { _id, ...campaignDetail } = campaignDetails;
        let dataSavedToDatabase = campaignDetail;
        axios
          .post(constant.serviceUrls.CAMPAIGN_MASTER_SERVICE_URL, campaign)
          .then(response => {
            dispatch({
              type: PUSH_CLONED_DATA,
              campaign
            });

            if (response.status === 201) {
              try {
                axios({
                  method: 'POST',
                  url: constant.serviceUrls.CAMPAIGN_DATA_SERVICE_URL,
                  data: dataSavedToDatabase
                }).then(response => {
                  fetchCampaignData(newuid, false, false);
                });
              } catch (error) {
                dispatch({
                  type: ASYNC_IN_PROGRESS,
                  isLoading: false
                });
              }
            } else {
              dispatch({
                type: ASYNC_IN_PROGRESS,
                isLoading: false
              });
            }
          });
      });
  };
}

export function cancelCampaignChanges(campaignId) {
  return function(dispatch) {
    dispatch({
      type: ASYNC_IN_PROGRESS,
      isLoading: true
    });
    // Fetch Search Filters
    axios
      .post(
        constant.saasServiceUrls.SAAS_EDIT_CAMPAIGN_ENDPOINT +
          'cancel/' +
          campaignId
      )
      .then(response => {
        dispatch({ type: ASYNC_IN_PROGRESS, isLoading: false });
      });
  };
}

export function saveEditorPermissions(
  id,
  isEveryOneEditor,
  users,
  loggedInUser,
  loggedInUserName
) {
  return function(dispatch) {
    let dataToSend = {};
    dataToSend['uid'] = id;
    dataToSend['isEveryoneEditor'] = isEveryOneEditor;
    dataToSend['users'] = users;
    dataToSend['requestSubmittedFrom'] = 'manage-editors';
    dataToSend['userEmail'] = loggedInUser;
    dataToSend['userName'] = loggedInUserName;
    axios({
      method: 'POST',
      url: constant.serviceUrls.CAMPAIGN_DATA_SERVICE_URL,
      data: dataToSend
    })
      .then(response => {
        // dispatch({ type: DELETE_CAMPAIGN, approved: "deleted" });
      })
      .catch(error => {});
  };
}
