import React from 'react';

import validIcon from 'images/valid.svg';
import errorIcon from 'images/error_icon.png';

export const MissingFieldErrors = ({
  message,
  unknownBrands,
  unknownCountries
}) => {
  const erorrs = () => {
    if (unknownBrands.length > 0 && unknownCountries.length > 0) {
      return (
        <div className="error-text mt-2">
          <div className="d-flex" style={{ gap: 6 }}>
            <img
              style={{ width: 20, height: 20 }}
              src={errorIcon}
              alt={'Invalid'}
            />
            <div>
              <span>
                There were few brands and countries which we could not find in
                Unilever brands and countries list. We have skipped those rows
                from the file. Please make sure rows with following brands and
                countries are correctly mentioned in data sheet:
              </span>
              <div>Brands: {unknownCountries.join(', ')}</div>
              <div>Countries: {unknownBrands.join(', ')}</div>
            </div>
          </div>
        </div>
      );
    } else if (unknownBrands.length > 0) {
      return (
        <div className="error-text mt-2 d-flex" style={{ gap: 6 }}>
          <img
            style={{ width: 20, height: 20 }}
            src={errorIcon}
            alt={'Invalid'}
          />
          There were few brands which we could not find in Unilever brands list.
          We have skipped those rows from the file. Please make sure following
          brands are correctly mentioned in data sheet:&nbsp;
          {unknownBrands.join(', ')}
        </div>
      );
    } else if (unknownCountries.length > 0) {
      return (
        <div className="error-text mt-2 d-flex" style={{ gap: 6 }}>
          <img
            style={{ width: 20, height: 20 }}
            src={errorIcon}
            alt={'Invalid'}
          />
          There were few countries which we could not find in Unilever countries
          list. We have skipped those rows from the file. Please make sure
          following countries are correctly mentioned in data sheet:&nbsp;
          {unknownCountries.join(', ')}
        </div>
      );
    }
  };

  return (
    <>
      <p className="success-text mt-3">
        <img
          style={{ width: 20, paddingRight: 3 }}
          src={validIcon}
          alt={'Valid'}
        />
        <span>&nbsp;{message}</span>
      </p>
      {erorrs()}
    </>
  );
};
