import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';

import {
  EmailSummaryCard,
  EmailSummaryText
} from '../EmailSummaryCard/EmailSummaryCard';
import { LinkButton } from 'components/atoms/LinkButton';
import { TrackingLabelModal } from './TrackingLabelModal';

import constants from 'constants/config';

import DeliveryLabel from '../../../images/delivery-label-icon.svg';
import externalLink from '../../../images/external-link.png';

const deliveryLabelTooltip = (
  <>
    <i
      className="fas fa-info-circle ml-1"
      data-for="value-tooltip"
      data-tip="Delivery label can be used to filter the email campaign <br /> performance metrics on the campaign tracking dashboard"
      style={{ cursor: 'pointer', fontSize: 'var(--text-base-size)' }}
    ></i>
    <ReactTooltip
      id="value-tooltip"
      place="right"
      type="info"
      multiline={true}
      className="cw-tooltip cw-email-tooltip saas_new_master_tooltip"
    />
  </>
);

export const LiveSummaryCard = ({
  summary,
  deliverySchedule,
  abTesting,
  trackingLabels
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className="mt-2 mb-3">
      <EmailSummaryCard
        icon={DeliveryLabel}
        title="Track your email performance"
      >
        <EmailSummaryText className="font-weight-bold my-3">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://app.powerbi.com/Redirect?action=OpenReport&appId=120c9b97-4133-47e9-8e38-97345453c93e&reportObjectId=b6741911-8a36-406b-ab37-325df0caf1e5&ctid=f66fae02-5d36-495b-bfe0-78a6ff9f8e6e&reportPage=ReportSection4db49a9860d12ea05402&pbi_source=appShareLink"
            aria-label="PowerBI dashboard link"
          >
            Track performance metrics of your email on the{' '}
            <strong>ACS email campaign tracking report</strong>
            <div className="external-link-wrapper">
              <img
                src={externalLink}
                alt="External Link"
                className="external-link-icon"
              />
            </div>
          </a>
        </EmailSummaryText>

        <EmailSummaryText>
          <div className="email-summary-card__title black">
            <strong>Delivery labels</strong> {deliveryLabelTooltip}
          </div>
          {typeof deliverySchedule?.deliveryLabel === 'string' ? (
            <>
              <strong>Main send: </strong>
              {deliverySchedule?.deliveryLabel}
            </>
          ) : (
            deliverySchedule?.deliveryLabel?.map(label => (
              <p key={constants.deliveryLablesToCaptionMap[label.type]}>
                <strong>
                  {constants.deliveryLablesToCaptionMap[label.type]}
                </strong>
                : {label.value}
              </p>
            ))
          )}
        </EmailSummaryText>

        <EmailSummaryText className="mt-3">
          <div className="email-summary-card__title black">
            <strong>Link tracking labels</strong>
          </div>
          <div>
            <LinkButton
              className="text-decoration-none"
              onClick={() => setIsModalOpen(true)}
            >
              <strong>View link tracking labels here</strong>
            </LinkButton>
          </div>
        </EmailSummaryText>
      </EmailSummaryCard>

      <TrackingLabelModal
        isOpen={isModalOpen}
        summary={summary}
        deliverySchedule={deliverySchedule}
        abTesting={abTesting}
        trackingLabels={trackingLabels}
        handleClose={() => setIsModalOpen(false)}
      />
    </div>
  );
};
