import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import Loader from './Loader';
import Modal from 'react-modal';
import { setModalStatus } from '../actions/app';
import { rejectCampaign } from '../actions/approval';
import ReactHtmlParser from 'react-html-parser';
import SummaryComponent from './SummaryComponent';
import {
  scrollToTop,
  getCampaignStatusText,
  statusBadge,
  checkCampaignLanguage,
  isAEMTypeCampaign,
  epsilonMarket,
  showMasterDataIcon,
  fetchQnANotificationTileData
} from '../selectors';
import FormAuthoring from './Review/FormAuthoring.js';
import FormAuthoringGrouped from './Review/FormAuthoringGrouped';
import axios from 'axios';
import { fetchCampaignData, saveMasterData } from '../actions/masterData';
import { setNotificationMessage } from '../actions/dashboard';
import { fetchHistoryLogs } from '../actions/history';
import { fetchCampaignEditLogs } from '../actions/approval';
import {
  updateCampaignDetails,
  updateAdditionalOptins,
  submitCampaignDetails,
  updateCampaignBrandOptinDetails,
  updateCampaignUnileverOptinDetails,
  updateCampaignUnileverIdDetails
} from '../actions/masterData';
import CampaignDetailsInfo from './CampaignDetailsInfo';
import constant from '../constants';
import ErrorNotification from './errorNotification';
import Alert from './Alert';
import HistoryComponent from '../components/history';
import RegularCommunications from './RegularCommunications';
import GuideLinesAccordian from './GuideLinesAccordian';
import groupIcon from '../images/groupIcon.png';
import QnAList from './Review/QnAList';
import { ReviewRowQnA } from './Review/FormAuthoringGrouped';
import MasterDataNotice from './organisms/MasterDataNotice/MasterDataNotice';
import { isNewMasterDataRequested } from './organisms/QnAFieldv2/QnAFieldv2';
import ReactTooltip from 'react-tooltip';

class MasterData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      showMessageAtTop: false,
      showRejectionError: false,
      consents: [],
      profilenQuestionfields: []
    };

    this.formElements = {
      campaignId: ''
    };
    this.notificationData = {
      message: '',
      link: '',
      type: '',
      campaign: ''
    };

    this.errorFields = [];
    this.isValid = false;
    this._updateMasterData = this._updateMasterData.bind(this);
    this._handleChange = this._handleChange.bind(this);
    this._saveAsDraft = this._saveAsDraft.bind(this);
    this._handleBrandOptinChange = this._handleBrandOptinChange.bind(this);
    this._handleCollapse = this._handleCollapse.bind(this);
    this._handleUnileverOptinChange = this._handleUnileverOptinChange.bind(
      this
    );
    this._validateQuestions = this._validateQuestions.bind(this);
    this._validateAnswers = this._validateAnswers.bind(this);
    this.scrollToTop = React.createRef();
    this.collapseMasterPanel = React.createRef();
    this.collapseHandlerIcon = React.createRef();
    this.collapseHandlerText = React.createRef();
    this._handleAdditonalOptins = this._handleAdditonalOptins.bind(this);
    this._scrollToTop = this._scrollToTop.bind(this);

    //Reject campaign
    this._handleReject = this._handleReject.bind(this);
    this._closeModal = this._closeModal.bind(this);
    this._handleOnSubmit = this._handleOnSubmit.bind(this);
    this._handleRejectChange = this._handleRejectChange.bind(this);
  }

  _handleCollapse(event) {
    event.preventDefault();
    if (this.collapseMasterPanel.current.classList.contains('show')) {
      this.collapseMasterPanel.current.classList.remove('show');
      this.collapseHandlerIcon.current.classList.remove('fa-chevron-up');
      this.collapseHandlerIcon.current.classList.add('fa-chevron-down');
      this.collapseHandlerText.current.innerHTML = 'View more Master Data IDs';
    } else {
      this.collapseMasterPanel.current.classList.add('show');
      this.collapseHandlerText.current.innerHTML = 'View less Master Data IDs';
      this.collapseHandlerIcon.current.classList.remove('fa-chevron-down');
      this.collapseHandlerIcon.current.classList.add('fa-chevron-up');
    }
  }

  componentDidMount() {
    this.campaignId = this.props.match.params.id;
    this.notificationData.link = '/campaign-wizard/campaign/' + this.campaignId;
    this.props.fetchCampaignData(this.campaignId);
    this.props.fetchHistoryLogs(this.campaignId);
  }

  componentWillReceiveProps(nextProps, nextState) {
    this.formElements['campaignId'] = nextProps.campaign.campaignId;
    let fields = this.state.fields;
    fields['campaignId'] = nextProps.campaign.campaignId;
    this.setState({ fields });

    if (nextProps.brandoptin && nextProps.brandoptin.text) {
      this.formElements[nextProps.brandoptin.text] = nextProps.brandoptin.id;
    }
    if (nextProps.additionalOptins && nextProps.additionalOptins.length) {
      nextProps.additionalOptins.forEach((optin, index) => {
        this.formElements['additionalOptinList-' + index] = optin.id;
      });
    }
    if (nextProps.unileveroptin && nextProps.unileveroptin.text) {
      this.formElements[nextProps.unileveroptin.text] =
        nextProps.unileveroptin.id;
    }
  }

  _handleChange(event) {
    let fields = this.state.fields;
    fields[event.target.name] = event.target.value;
    if (event.target.dataset.customError === 'true') {
      let regexPattern;
      regexPattern = constant.regex[event.target.dataset.customErrorType];

      if (regexPattern.test(event.target.value)) {
        document
          .querySelector('[data-custom-msg=' + event.target.name + ']')
          .classList.add('cw-hidden');

        document
          .getElementById(`${event.target.name}`)
          .classList.contains('cw-error-focus') &&
          document
            .getElementById(`${event.target.name}`)
            .classList.remove('cw-error-focus');
      } else {
        document
          .querySelector('[data-custom-msg=' + event.target.name + ']')
          .classList.remove('cw-hidden');

        document
          .getElementById(`${event.target.name}`)
          .classList.contains('cw-error-focus') ||
          document
            .getElementById(`${event.target.name}`)
            .classList.add('cw-error-focus');
      }
    } else {
      this._validateField(event);
    }

    this.setState({ fields });
    this.formElements[event.target.name] = event.target.value;

    //this._validateField(event.target.name);
    this.props.updateCampaignDetails({
      [event.target.name]: event.target.value
    });
  }

  _handleAdditonalOptins(event) {
    if (
      event.target.name &&
      event.target.name.startsWith('additionalOptinList')
    ) {
      let index = event.target.name.split('-')[1];
      let additionalOptinId = event.target.value;
      this.props.additionalOptins[index].id = additionalOptinId;
      this.props.updateAdditionalOptins(this.props.additionalOptins);
    }
    this._validateField(event);
  }

  _handleBrandOptinChange(event) {
    let fields = this.state.fields;
    fields[event.target.name] = event.target.value;

    if (event.target.dataset.customError === 'true') {
      let regexPattern;
      regexPattern = constant.regex[event.target.dataset.customErrorType];
      if (regexPattern.test(event.target.value)) {
        document
          .querySelector('[data-custom-msg=' + event.target.name + ']')
          .classList.add('cw-hidden');
      } else {
        document
          .querySelector('[data-custom-msg=' + event.target.name + ']')
          .classList.remove('cw-hidden');
      }
    }
    if (event.target.dataset && event.target.dataset.required === 'true') {
      this._validateField(event);
    }
    this.setState({ fields });
    this.formElements[event.target.name] = event.target.value;
    this.props.updateCampaignBrandOptinDetails({
      id: event.target.value
    });
  }
  _handleUnileverOptinChange(event) {
    let fields = this.state.fields;
    fields[event.target.name] = event.target.value;

    if (event.target.dataset.customError === 'true') {
      let regexPattern;
      regexPattern = constant.regex[event.target.dataset.customErrorType];
      if (regexPattern.test(event.target.value)) {
        document
          .querySelector('[data-custom-msg=' + event.target.name + ']')
          .classList.add('cw-hidden');
      } else {
        document
          .querySelector('[data-custom-msg=' + event.target.name + ']')
          .classList.remove('cw-hidden');
      }
    }
    if (event.target.dataset && event.target.dataset.required === 'true') {
      this._validateField(event);
    }

    this.setState({ fields });
    this._validateField(event);
    this.formElements[event.target.name] = event.target.value;
    this.props.updateCampaignUnileverOptinDetails({
      id: event.target.value
    });
  }

  _handleUnileverIdChange(event) {
    let fields = this.state.fields;
    fields[event.target.name] = event.target.value;

    if (event.target.dataset.customError === 'true') {
      let regexPattern;
      regexPattern = constant.regex[event.target.dataset.customErrorType];
      if (regexPattern.test(event.target.value)) {
        document
          .querySelector('[data-custom-msg=' + event.target.name + ']')
          .classList.add('cw-hidden');
      } else {
        document
          .querySelector('[data-custom-msg=' + event.target.name + ']')
          .classList.remove('cw-hidden');
      }
    }
    if (event.target.dataset && event.target.dataset.required === 'true') {
      this._validateField(event);
    }

    this.setState({ fields });
    this._validateField(event);
    this.formElements[event.target.name] = event.target.value;
    this.props.updateCampaignUnileverIdDetails({
      id: event.target.value
    });
  }

  _updateMasterData(event) {
    // Form Field validation
    let fields = this.state.fields;
    let formFields = {};
    this.setState({ fields });
    let questionAnswerList = [];
    let optins = [];
    let additionalOptinList = this.props.additionalOptins;

    optins.push(this.props.brandoptin);
    optins.push(this.props.unileveroptin);
    if (this.props.optins.length > 2) {
      optins.push(this.props.unileverid);
    }

    for (var key in this.props.questionAnswerList) {
      if (this.props.questionAnswerList.hasOwnProperty(key)) {
        questionAnswerList.push(this.props.questionAnswerList[key]);
      }
    }

    let websiteType =
      this.props &&
      this.props.campaign &&
      this.props.campaign.websiteType &&
      this.props.campaign.websiteType.name;
    if (
      websiteType === 'Adobe SaaS' ||
      this.props.campaign.isPlatformAgnostic ||
      this.props.campaign.isMasterData
    ) {
      let consents = this.state.consents;
      consents =
        consents.length === 0
          ? this.props &&
            this.props.campaignDetails &&
            this.props.campaignDetails.consents
          : consents;
      consents = consents.map(consent => {
        if (
          consent.optInType !== 'legalAgeConsent' &&
          consent.optInType !== 'legalDisclaimer' &&
          consent.optInType !== 'privacyNotice' &&
          consent.optInType !== 'termsAndConditions' &&
          consent.optInType !== 'contentInterestAreas'
        )
          consent.serviceId = document.getElementById(consent.optInType).value;
        return consent;
      });
      let profilenQuestionfields = this.state.profilenQuestionfields;
      let qnaindex = 0;
      profilenQuestionfields =
        profilenQuestionfields.length === 0
          ? this.props &&
            this.props.campaignDetails &&
            this.props.campaignDetails.fields
          : this.props.campaignDetails.fields.map((element, index) => {
              if (element.dataType === 'questionAnswerPair')
                return this.state.profilenQuestionfields[qnaindex++];
              return element;
            });
      formFields = {
        ...this.state.fields,
        consents,
        fields: profilenQuestionfields
      };
    } else {
      formFields = {
        ...this.state.fields,
        questionAnswerList,
        optins,
        additionalOptinList
      };
    }
    if (
      websiteType === 'Adobe SaaS' ||
      this.props.campaign.isPlatformAgnostic ||
      this.props.campaign.isMasterData
    ) {
      this._validateSASSForm(formFields);
    } else {
      this._validateForm(event);
    }
    if (
      this.props.campaign.type === 'Sign-up with Questions & Answers' &&
      websiteType !== 'Adobe SaaS' &&
      !this.props.campaign.isPlatformAgnostic &&
      !this.props.campaign.isMasterData
    ) {
      let campaignLanguage =
        this.props.campaign &&
        this.props.campaign.language &&
        this.props.campaign.language.name;
      this._validateQuestions(event);
      this._validateAnswers(event);
      if (!checkCampaignLanguage(campaignLanguage)) {
        this._validateTranslatedQuestions(event);
        this._validateTranslatedAnswers(event);
      }
    }

    if (this.isValid) {
      formFields = {
        ...formFields,
        campaignIdUpdated: this.props.campaignIdUpdated,
        campaignType: this.props.campaign && this.props.campaign.campaignType,
        type: this.props.campaign && this.props.campaign.type,
        isTrackingRequired:
          this.props.campaign && this.props.campaign.isTrackingRequired,
        campaignDetails: this.props.campaign,
        isMasterData: this.props.campaign && this.props.campaign.isMasterData
      };

      this.props
        .saveMasterData(
          this.props.campaign.uid,
          formFields,
          this.props.userName,
          this.props.userEmail
        )
        .then(response => {
          this.notificationData.message = 'has been assigned Master Data.';
          this.notificationData.campaign = this.props.campaign.name;
          this.notificationData.type = 'success';

          this.props.setNotificationMessage(
            this.notificationData.message,
            this.notificationData.link,
            this.notificationData.type,
            this.notificationData.campaign
          );
          //this._scrollToTop();
          this.props.history.push('/campaign-wizard/dashboard');
        });
    }
  }
  _isConsentsValid(consents) {
    let isConsentsValid = false;
    if (consents) {
      consents.map((consent, index) => {
        if (
          consent.optInType !== 'legalAgeConsent' &&
          consent.optInType !== 'legalDisclaimer' &&
          consent.optInType !== 'privacyNotice'
        ) {
          isConsentsValid = true;
        }
      });
    }
    return isConsentsValid;
  }
  _validateQuestions(event) {
    if (this.props.questionAnswerList.length) {
      for (var key in this.props.questionAnswerList) {
        if (
          this.props.questionAnswerList.hasOwnProperty(key) &&
          this.props.questionAnswerList[key]['type'] !== 'Profile' &&
          this.props.questionAnswerList[key]['type'] !== 'File upload' &&
          this.props.questionAnswerList[key]['type'] !== 'Unique entry code'
        ) {
          let quesselector = document.querySelector(
            "[name='question" + key + "']"
          );
          if (quesselector && this.props.questionAnswerList[key].id) {
            if (
              quesselector.parentElement &&
              quesselector.parentElement.classList.contains('error')
            ) {
              quesselector.parentElement.classList.remove('error');
            }
            if (
              document.querySelector("[data-control='question" + key + "']")
            ) {
              if (
                !document
                  .querySelector("[data-control='question" + key + "']")
                  .classList.contains('cw-hidden')
              ) {
                document
                  .querySelector("[data-control='question" + key + "']")
                  .classList.add('cw-hidden');

                document
                  .getElementById(`question${key}`)
                  .classList.contains('cw-error-focus') &&
                  document
                    .getElementById(`question${key}`)
                    .classList.remove('cw-error-focus');
              }
            }
            this.errorFields = this._filterErrorFields(this.errorFields, key);
          } else {
            if (
              quesselector &&
              quesselector.parentElement &&
              !quesselector.parentElement.classList.contains('error')
            ) {
              quesselector.parentElement.classList.add('error');
            }
            if (
              document.querySelector("[data-control='question" + key + "']")
            ) {
              if (
                document
                  .querySelector("[data-control='question" + key + "']")
                  .classList.contains('cw-hidden')
              ) {
                document
                  .querySelector("[data-control='question" + key + "']")
                  .classList.remove('cw-hidden');

                document.getElementById(`question${key}`) &&
                  (document
                    .getElementById(`question${key}`)
                    .classList.contains('cw-error-focus') ||
                    document
                      .getElementById(`question${key}`)
                      .classList.add('cw-error-focus'));
              }
            }
            this.errorFields = this._addErrorFields(this.errorFields, key);
          }
        }
      }
    }

    if (this.errorFields.length === 0) {
      this.isValid = true;
      this.setState({ showMessageAtTop: false });
    } else {
      this.isValid = false;
      this.setState({ showMessageAtTop: true });
      scrollToTop();
    }
  }

  _validateTranslatedQuestions(event) {
    if (this.props.questionAnswerList.length) {
      for (var key in this.props.questionAnswerList) {
        if (
          this.props.questionAnswerList.hasOwnProperty(key) &&
          this.props.questionAnswerList[key]['type'] !== 'Profile' &&
          this.props.questionAnswerList[key]['type'] !== 'File upload' &&
          this.props.questionAnswerList[key]['type'] !== 'Unique entry code'
        ) {
          let quesselector = document.querySelector(
            "[name='translatedquestion" + key + "']"
          );
          if (quesselector && this.props.questionAnswerList[key].translatedId) {
            if (
              quesselector.parentElement &&
              quesselector.parentElement.classList.contains('error')
            ) {
              quesselector.parentElement.classList.remove('error');
            }
            if (
              document.querySelector(
                "[data-control='translatedquestion" + key + "']"
              )
            ) {
              if (
                !document
                  .querySelector(
                    "[data-control='translatedquestion" + key + "']"
                  )
                  .classList.contains('cw-hidden')
              ) {
                document
                  .querySelector(
                    "[data-control='translatedquestion" + key + "']"
                  )
                  .classList.add('cw-hidden');

                document
                  .getElementById(`translatedquestion${key}`)
                  .classList.contains('cw-error-focus') &&
                  document
                    .getElementById(`translatedquestion${key}`)
                    .classList.remove('cw-error-focus');
              }
            }
            this.errorFields = this._filterErrorFields(this.errorFields, key);
          } else {
            if (
              quesselector &&
              quesselector.parentElement &&
              !quesselector.parentElement.classList.contains('error')
            ) {
              quesselector.parentElement.classList.add('error');
            }
            if (
              document.querySelector(
                "[data-control='translatedquestion" + key + "']"
              )
            ) {
              if (
                document
                  .querySelector(
                    "[data-control='translatedquestion" + key + "']"
                  )
                  .classList.contains('cw-hidden')
              ) {
                document
                  .querySelector(
                    "[data-control='translatedquestion" + key + "']"
                  )
                  .classList.remove('cw-hidden');
                document
                  .getElementById(`translatedquestion${key}`)
                  .classList.contains('cw-error-focus') ||
                  document
                    .getElementById(`translatedquestion${key}`)
                    .classList.add('cw-error-focus');
              }
            }
            this.errorFields = this._addErrorFields(this.errorFields, key);
          }
        }
      }
    }

    if (this.errorFields.length === 0) {
      this.isValid = true;
      this.setState({ showMessageAtTop: false });
    } else {
      this.isValid = false;
      this.setState({ showMessageAtTop: true });
      scrollToTop();
    }
  }

  _validateAnswers(event) {
    if (this.props.questionAnswerList.length) {
      for (var key in this.props.questionAnswerList) {
        if (
          this.props.questionAnswerList[key].answers.length &&
          this.props.questionAnswerList[key]['type'] !== 'Profile' &&
          this.props.questionAnswerList[key]['type'] !== 'File upload' &&
          this.props.questionAnswerList[key]['type'] !== 'Unique entry code'
        ) {
          for (var anskey in this.props.questionAnswerList[key].answers) {
            let ansEle = this.props.questionAnswerList[key].answers[anskey];
            let answerkey = 'answer_' + key + '_' + anskey;
            let ansselector = document.querySelector(
              "[name='" + answerkey + "']"
            );
            if (ansselector && ansEle.value) {
              if (
                ansselector.parentElement &&
                ansselector.parentElement.classList.contains('error')
              ) {
                ansselector.parentElement.classList.remove('error');
              }
              if (
                document.querySelector("[data-control='" + answerkey + "']")
              ) {
                if (
                  !document
                    .querySelector("[data-control='" + answerkey + "']")
                    .classList.contains('cw-hidden')
                ) {
                  document
                    .querySelector("[data-control='" + answerkey + "']")
                    .classList.add('cw-hidden');
                  document
                    .getElementById(`${answerkey}`)
                    .classList.contains('cw-error-focus') &&
                    document
                      .getElementById(`${answerkey}`)
                      .classList.remove('cw-error-focus');
                }
              }
              this.errorFields = this._filterErrorFields(
                this.errorFields,
                answerkey
              );
            } else {
              if (
                ansselector &&
                ansselector.parentElement &&
                !ansselector.parentElement.classList.contains('error')
              ) {
                ansselector.parentElement.classList.add('error');
              }
              if (
                document.querySelector("[data-control='" + answerkey + "']")
              ) {
                if (
                  document
                    .querySelector("[data-control='" + answerkey + "']")
                    .classList.contains('cw-hidden')
                ) {
                  document
                    .querySelector("[data-control='" + answerkey + "']")
                    .classList.remove('cw-hidden');

                  document
                    .getElementById(`${answerkey}`)
                    .classList.contains('cw-error-focus') ||
                    document
                      .getElementById(`${answerkey}`)
                      .classList.add('cw-error-focus');
                }
              }
              this.errorFields = this._addErrorFields(
                this.errorFields,
                answerkey
              );
            }
          }
        }
        // if (this.props.questionAnswerList.hasOwnProperty(key)) {
        //  let quesselector = document.querySelector("[name='question" + key + "']");
        // }
      }
    }

    if (this.errorFields.length === 0) {
      this.isValid = true;
      this.setState({ showMessageAtTop: false });
    } else {
      this.isValid = false;
      this.setState({ showMessageAtTop: true });
      scrollToTop();
    }
  }

  _validateTranslatedAnswers(event) {
    if (this.props.questionAnswerList.length) {
      for (var key in this.props.questionAnswerList) {
        if (
          this.props.questionAnswerList[key].answers.length &&
          this.props.questionAnswerList[key]['type'] !== 'Profile' &&
          this.props.questionAnswerList[key]['type'] !== 'File upload' &&
          this.props.questionAnswerList[key]['type'] !== 'Unique entry code'
        ) {
          for (var anskey in this.props.questionAnswerList[key].answers) {
            let ansEle = this.props.questionAnswerList[key].answers[anskey];
            let answerkey = 'translatedanswer_' + key + '_' + anskey;

            let ansselector = document.querySelector(
              "[name='" + answerkey + "']"
            );
            if (ansselector && ansEle.translatedvalue) {
              if (
                ansselector.parentElement &&
                ansselector.parentElement.classList.contains('error')
              ) {
                ansselector.parentElement.classList.remove('error');
              }
              if (
                document.querySelector("[data-control='" + answerkey + "']") &&
                !document
                  .querySelector("[data-control='" + answerkey + "']")
                  .classList.contains('cw-hidden')
              ) {
                document
                  .querySelector("[data-control='" + answerkey + "']")
                  .classList.add('cw-hidden');
                document
                  .getElementById(`${answerkey}`)
                  .classList.contains('cw-error-focus') &&
                  document
                    .getElementById(`${answerkey}`)
                    .classList.remove('cw-error-focus');
              }
              this.errorFields = this._filterErrorFields(
                this.errorFields,
                answerkey
              );
            } else {
              if (
                ansselector &&
                ansselector.parentElement &&
                !ansselector.parentElement.classList.contains('error')
              ) {
                ansselector.parentElement.classList.add('error');
              }
              if (
                document.querySelector("[data-control='" + answerkey + "']") &&
                document
                  .querySelector("[data-control='" + answerkey + "']")
                  .classList.contains('cw-hidden')
              ) {
                document
                  .querySelector("[data-control='" + answerkey + "']")
                  .classList.remove('cw-hidden');

                document
                  .getElementById(`${answerkey}`)
                  .classList.contains('cw-error-focus') ||
                  document
                    .getElementById(`${answerkey}`)
                    .classList.add('cw-error-focus');
              }
              this.errorFields = this._addErrorFields(
                this.errorFields,
                answerkey
              );
            }
          }
        }
        // if (this.props.questionAnswerList.hasOwnProperty(key)) {
        //  let quesselector = document.querySelector("[name='question" + key + "']");
        // }
      }
    }

    if (this.errorFields.length === 0) {
      this.isValid = true;
      this.setState({ showMessageAtTop: false });
    } else {
      this.isValid = false;
      this.setState({ showMessageAtTop: true });
      scrollToTop();
    }
  }

  _validateField(event) {
    if (event.target) {
      if (
        document
          .querySelector("[name='" + event.target.name + "']")
          .parentElement.classList.contains('error')
      ) {
        document
          .querySelector("[name='" + event.target.name + "']")
          .parentElement.classList.remove('error');
      }
      if (
        !document
          .querySelector("[data-control='" + event.target.name + "']")
          .classList.contains('cw-hidden')
      ) {
        document
          .querySelector("[data-control='" + event.target.name + "']")
          .classList.add('cw-hidden');

        document
          .getElementById(`${event.target.name}`)
          .classList.contains('cw-error-focus') &&
          document
            .getElementById(`${event.target.name}`)
            .classList.remove('cw-error-focus');
      }
    }
  }

  _validateSASSForm(formFields) {
    let regexPattern;
    regexPattern = constant.regex['campaignId'];
    if (regexPattern.test(formFields.campaignId)) {
      document
        .querySelector('[data-custom-msg=campaignId]')
        .classList.add('cw-hidden');
    } else {
      document
        .querySelector('[data-custom-msg=campaignId]')
        .classList.remove('cw-hidden');
    }

    formFields.consents
      .filter(
        consent =>
          consent.optInType !== 'legalAgeConsent' &&
          consent.optInType !== 'legalDisclaimer' &&
          consent.optInType !== 'privacyNotice' &&
          consent.optInType !== 'termsAndConditions' &&
          consent.optInType !== 'contentInterestAreas'
      )
      .map((consent, index) => {
        if (consent.optInType.startsWith('additionalSubscription')) {
          if (consent.serviceId !== undefined && consent.serviceId.length > 0) {
            document
              .querySelector('[data-control=' + consent.optInType + ']')
              .classList.add('cw-hidden');

            document
              .getElementById(`${consent.optInType}`)
              .classList.contains('cw-error-focus') &&
              document
                .getElementById(`${consent.optInType}`)
                .classList.remove('cw-error-focus');
          } else {
            document
              .querySelector('[data-control=' + consent.optInType + ']')
              .classList.remove('cw-hidden');

            document
              .getElementById(`${consent.optInType}`)
              .classList.contains('cw-error-focus') ||
              document
                .getElementById(`${consent.optInType}`)
                .classList.add('cw-error-focus');
          }
        } else {
          regexPattern = constant.regex[consent.optInType];
          if (regexPattern.test(consent.serviceId)) {
            document
              .querySelector('[data-custom-msg=' + consent.optInType + ']')
              .classList.add('cw-hidden');

            document
              .getElementById(`${consent.optInType}`)
              .classList.contains('cw-error-focus') &&
              document
                .getElementById(`${consent.optInType}`)
                .classList.remove('cw-error-focus');
          } else {
            document
              .querySelector('[data-custom-msg=' + consent.optInType + ']')
              .classList.remove('cw-hidden');

            document
              .getElementById(`${consent.optInType}`)
              .classList.contains('cw-error-focus') ||
              document
                .getElementById(`${consent.optInType}`)
                .classList.add('cw-error-focus');
          }
        }
      });

    let emptyQnAs = document.querySelectorAll("input[fieldType=qna][value='']");
    emptyQnAs.forEach(function(qnaElement) {
      document
        .querySelector(
          '[data-custom-msg=' + qnaElement.getAttribute('name') + ']'
        )
        .classList.remove('cw-hidden');

      document
        .getElementById(`${qnaElement.getAttribute('name')}`)
        .classList.contains('cw-error-focus') ||
        document
          .getElementById(`${qnaElement.getAttribute('name')}`)
          .classList.add('cw-error-focus');
    });
    if (document.querySelector('.cw-error:not(.cw-hidden)')) {
      this.setState({ showMessageAtTop: true });
      this.isValid = false;
      scrollToTop();
    } else {
      this.isValid = true;
    }
  }
  _validateForm(event) {
    let formElements = this.formElements;
    for (var key in formElements) {
      if (formElements.hasOwnProperty(key)) {
        let selector = document.querySelector("[name='" + key + "']");
        if (selector) {
          if (formElements[key] !== '') {
            if (
              key === 'campaignId' ||
              key === 'Brand' ||
              key === 'Brand_SMS' ||
              key === 'Unilever'
            ) {
              let regexPattern;

              if (key === 'Brand' || key === 'Brand_SMS') {
                regexPattern = constant.regex['brandEmailId'];
              } else if (key === 'Unilever') {
                regexPattern = constant.regex['unileverId'];
              } else {
                regexPattern = constant.regex[key];
              }

              if (!regexPattern.test(formElements[key])) {
                document
                  .querySelector('[data-custom-msg=' + key + ']')
                  .classList.remove('cw-hidden');

                this.errorFields = this._addErrorFields(this.errorFields, key);
              } else {
                document
                  .querySelector('[data-custom-msg=' + key + ']')
                  .classList.add('cw-hidden');
                this.errorFields = this._filterErrorFields(
                  this.errorFields,
                  key
                );
              }
            } else {
              if (selector.parentElement.classList.contains('error')) {
                selector.parentElement.classList.remove('error');
              }
              if (document.querySelector("[data-control='" + key + "']")) {
                if (
                  !document
                    .querySelector("[data-control='" + key + "']")
                    .classList.contains('cw-hidden')
                ) {
                  document
                    .querySelector("[data-control='" + key + "']")
                    .classList.add('cw-hidden');
                }
              }
              this.errorFields = this._filterErrorFields(this.errorFields, key);
            }
          } else {
            if (!selector.parentElement.classList.contains('error')) {
              selector.parentElement.classList.add('error');
            }

            if (document.querySelector("[data-custom-msg='" + key + "']")) {
              document
                .querySelector('[data-custom-msg=' + key + ']')
                .classList.add('cw-hidden');
            }

            if (document.querySelector("[data-control='" + key + "']")) {
              if (
                document
                  .querySelector("[data-control='" + key + "']")
                  .classList.contains('cw-hidden')
              ) {
                document
                  .querySelector("[data-control='" + key + "']")
                  .classList.remove('cw-hidden');
              }
            }
            this.errorFields = this._addErrorFields(this.errorFields, key);
          }
        }
      }
    }

    if (this.errorFields.length === 0) {
      this.isValid = true;
      this.setState({ showMessageAtTop: false });
    } else {
      this.setState({ showMessageAtTop: true });
      scrollToTop();
    }
  }
  _addErrorFields(array, itemToAdd) {
    return array.filter(field => field !== itemToAdd).concat([itemToAdd]);
  }
  _filterErrorFields(array, itemToRemove) {
    return array.filter(item => item !== itemToRemove);
  }

  _scrollToTop() {
    window.scroll({
      top: -100,
      left: 0,
      behavior: 'smooth'
    });
  }
  _handleRejectChange(e) {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.value.length > 0) {
      this.setState({ showRejectionError: false });
    } else {
      this.setState({ showRejectionError: true });
    }
  }

  _handleOnSubmit(e) {
    e.preventDefault();

    if (this.state.description && this.state.description.length > 0) {
      this.setState({ showRejectionError: false });
      let data = {
        uid: this.campaignId,
        status: constant.campaignStatus.REJECTED,
        rejectComments: this.state.description,
        userName: this.props.userName,
        userEmail: this.props.userEmail
      };

      this._closeModal();

      this.props.rejectCampaign(data).then(response => {
        this.notificationData.message = 'has been rejected.';
        this.notificationData.link =
          '/campaign-wizard/campaign/' + this.props.campaign.uid;
        this.notificationData.type = 'warning';
        this.notificationData.campaign = this.props.campaign.name;
        this.props.setNotificationMessage(
          this.notificationData.message,
          this.notificationData.link,
          this.notificationData.type,
          this.notificationData.campaign
        );
        this.props.history.push('/campaign-wizard/dashboard');
      });
    } else {
      this.setState({ showRejectionError: true });
    }
  }

  _handleReject(e) {
    e.preventDefault();
    let actionType = e.currentTarget.getAttribute('data-action');
    this.props.setModalStatus(true, actionType);
  }

  _closeModal() {
    this.props.setModalStatus(false);
  }

  _saveAsDraft(event) {
    let fields = this.state.fields;
    let formFields = {};
    let additionalOptinList = this.props.additionalOptins;
    fields['isdraft'] = true;
    this.setState({ fields });
    let questionAnswerList = [];
    for (var key in this.props.questionAnswerList) {
      if (this.props.questionAnswerList.hasOwnProperty(key)) {
        questionAnswerList.push(this.props.questionAnswerList[key]);
      }
    }

    let optins = [];
    optins.push(this.props.brandoptin);
    optins.push(this.props.unileveroptin);
    if (this.props.optins.length > 2) {
      optins.push(this.props.unileverid);
    }

    let websiteType =
      this.props &&
      this.props.campaign &&
      this.props.campaign.websiteType &&
      this.props.campaign.websiteType.name;
    if (
      websiteType === 'Adobe SaaS' ||
      this.props.campaign.isPlatformAgnostic
    ) {
      let consents = this.state.consents;
      consents =
        consents.length === 0
          ? this.props &&
            this.props.campaignDetails &&
            this.props.campaignDetails.consents
          : consents;
      consents = consents.map(consent => {
        if (
          consent.optInType !== 'legalAgeConsent' &&
          consent.optInType !== 'legalDisclaimer' &&
          consent.optInType !== 'privacyNotice' &&
          consent.optInType !== 'termsAndConditions' &&
          consent.optInType !== 'contentInterestAreas'
        )
          consent.serviceId = document.getElementById(consent.optInType).value;
        return consent;
      });
      let profilenQuestionfields = this.state.profilenQuestionfields;
      let qnaindex = 0;
      profilenQuestionfields =
        profilenQuestionfields.length === 0
          ? this.props &&
            this.props.campaignDetails &&
            this.props.campaignDetails.fields
          : this.props.campaignDetails.fields.map((element, index) => {
              if (element.dataType === 'questionAnswerPair')
                return this.state.profilenQuestionfields[qnaindex++];
              return element;
            });
      formFields = {
        ...this.state.fields,
        consents,
        fields: profilenQuestionfields
      };
    } else {
      formFields = {
        ...this.state.fields,
        questionAnswerList,
        optins,
        additionalOptinList
      };
    }
    this.props
      .saveMasterData(
        this.props.campaign.uid,
        formFields,
        this.props.userName,
        this.props.userEmail,
        true
      )
      .then(response => {
        this.notificationData.message = 'has been saved as Draft.';
        this.notificationData.campaign = this.props.campaign.name;
        this.notificationData.type = 'warning';
        this.props.setNotificationMessage(
          this.notificationData.message,
          this.notificationData.link,
          this.notificationData.type,
          this.notificationData.campaign
        );
        //this._scrollToTop();
        this.props.history.push('/campaign-wizard/dashboard');
      });
  }
  _handleUpdateConsent(event, index) {
    let consentsArray = [];
    let consents =
      this.props &&
      this.props.campaignDetails &&
      this.props.campaignDetails.consents;
    consentsArray = consents.map(consent => {
      if (consent.optInType === event.target.name) {
        consent.serviceId = event.target.value;
      }
      return consent;
    });
    this.setState({ consents: consentsArray });
    if (event.target.name.startsWith('additionalSubscription')) {
      if (event.target.value !== undefined && event.target.value.length > 0) {
        document
          .querySelector('[data-control=' + event.target.name + ']')
          .classList.add('cw-hidden');

        document
          .getElementById(`${event.target.name}`)
          .classList.contains('cw-error-focus') &&
          document
            .getElementById(`${event.target.name}`)
            .classList.remove('cw-error-focus');
      } else {
        document
          .querySelector('[data-control=' + event.target.name + ']')
          .classList.remove('cw-hidden');

        document
          .getElementById(`${event.target.name}`)
          .classList.contains('cw-error-focus') ||
          document
            .getElementById(`${event.target.name}`)
            .classList.add('cw-error-focus');
      }
    } else {
      let regexPattern = constant.regex[event.target.name];
      if (regexPattern.test(event.target.value)) {
        document
          .querySelector('[data-custom-msg=' + event.target.name + ']')
          .classList.add('cw-hidden');

        document
          .getElementById(`${event.target.name}`)
          .classList.contains('cw-error-focus') &&
          document
            .getElementById(`${event.target.name}`)
            .classList.remove('cw-error-focus');
      } else {
        document
          .querySelector('[data-custom-msg=' + event.target.name + ']')
          .classList.remove('cw-hidden');

        document
          .getElementById(`${event.target.name}`)
          .classList.contains('cw-error-focus') ||
          document
            .getElementById(`${event.target.name}`)
            .classList.add('cw-error-focus');
      }
    }
  }

  _handleUpdateQuestion(event, index, key) {
    let questionArray = [];
    let questions =
      this.props &&
      this.props.campaignDetails &&
      this.props.campaignDetails.fields;
    questionArray = questions.filter(
      elem => elem.dataType === 'questionAnswerPair'
    );
    questionArray[index][key] = event.target.value;
    this.setState({ profilenQuestionfields: questionArray });
    let regexPattern = constant.regex['qna'];
    if (regexPattern.test(event.target.value)) {
      document
        .querySelector('[data-custom-msg=' + event.target.name + ']')
        .classList.add('cw-hidden');

      document
        .getElementById(`${event.target.id}`)
        .classList.contains('cw-error-focus') &&
        document
          .getElementById(`${event.target.id}`)
          .classList.remove('cw-error-focus');
    } else {
      document
        .querySelector('[data-custom-msg=' + event.target.name + ']')
        .classList.remove('cw-hidden');

      document
        .getElementById(`${event.target.id}`)
        .classList.contains('cw-error-focus') ||
        document
          .getElementById(`${event.target.id}`)
          .classList.add('cw-error-focus');
    }
  }

  _handleUpdateAnswer(event, index, answerIndex, key) {
    let questionArray = [];
    let questions =
      this.props &&
      this.props.campaignDetails &&
      this.props.campaignDetails.fields;
    questionArray = questions.filter(
      elem => elem.dataType === 'questionAnswerPair'
    );
    questionArray[index]['answers'][answerIndex][key] = event.target.value;
    this.setState({ profilenQuestionfields: questionArray });
    let regexPattern = constant.regex['qna'];
    if (regexPattern.test(event.target.value)) {
      document
        .querySelector('[data-custom-msg=' + event.target.name + ']')
        .classList.add('cw-hidden');

      document
        .getElementById(`${event.target.name}`)
        .classList.contains('cw-error-focus') &&
        document
          .getElementById(`${event.target.name}`)
          .classList.remove('cw-error-focus');
    } else {
      document
        .querySelector('[data-custom-msg=' + event.target.name + ']')
        .classList.remove('cw-hidden');

      document
        .getElementById(`${event.target.name}`)
        .classList.contains('cw-error-focus') ||
        document
          .getElementById(`${event.target.name}`)
          .classList.add('cw-error-focus');
    }
  }

  handleAccordion = index => {
    this.setState(prevState => ({
      questionAccordion: prevState.questionAccordion === index ? false : index
    }));
  };

  _handleRefresh = () => {
    let { campaign } = this.props;
    axios
      .post(constant.serviceUrls.CAMPAIGN_INFRA_API_CALL, campaign)
      .then(response => {
        this.campaignId = this.props.match.params.id;
        this.props.fetchCampaignData(this.campaignId);
      });
  };

  render() {
    Modal.setAppElement('#root');

    let statusClassName = classNames({
      'form-control mb-40': !this.isValid,
      'form-control mb-30': this.isError
    });

    let {
      campaign,
      campaignDetails,
      asyncInProgress,
      additionalOptins,
      questionAnswerList,
      modalIsOpen,
      activeModal
    } = this.props;

    let statusClass = classNames(statusBadge(this.props.campaign));
    let getCampaignStatus = getCampaignStatusText(this.props.campaign);
    let campaignLanguage =
      campaign && campaign.language && campaign.language.name;
    let languageCode = campaign && campaign.language && campaign.language.code;
    let isCampaignNonEnglish = checkCampaignLanguage(campaignLanguage);
    let isCampaignIncentive =
      campaign && campaign.campaignType === 'Incentive' ? true : false;
    let isCampaignTypeSASS =
      (campaign &&
        campaign.websiteType &&
        campaign.websiteType.name === 'Adobe SaaS') ||
      campaign.isPlatformAgnostic ||
      campaign.isMasterData;
    const country = campaign && campaign.country && campaign.country.code;
    const isEpsilonMarket = country && epsilonMarket(country);

    let isAEMType = isAEMTypeCampaign(campaign);
    let isPromotigoType = isCampaignIncentive && isAEMType ? true : false;
    let qnaTileData = fetchQnANotificationTileData(campaignDetails);
    let isCampaignComplete =
      campaign && campaign.status && campaign.status === '3';
    let newMasterData = isNew => {
      return isNew ? (
        <>
          <div
            style={{ display: 'block', color: '#E9A40A', whiteSpace: 'pre' }}
          >
            <img src={groupIcon} alt={groupIcon} width="25" height="25"></img>
            &nbsp; New Master
          </div>
        </>
      ) : null;
    };

    let consentsHtml = consents => {
      let consenthtml = consents
        .filter(
          consent =>
            consent.optInType !== 'legalAgeConsent' &&
            consent.optInType !== 'legalDisclaimer' &&
            consent.optInType !== 'privacyNotice' &&
            consent.optInType !== 'termsAndConditions' &&
            consent.optInType !== 'additionalSubscription1' &&
            consent.optInType !== 'additionalSubscription' &&
            consent.optInType !== 'contentInterestAreas'
        )
        .map((consent, index) => {
          return (
            <div class="cw-striped-profile-row">
              <div class="row">
                <div class="col-sm-3">
                  <strong>{consent.label}</strong>
                </div>
                <div class="col-sm-4">
                  {/* {consent.defaultCopy
                    ? ReactHtmlParser(consent.defaultCopy)
                    : null} */}
                </div>
                <div class="col-sm-3">
                  <input
                    name={consent.optInType}
                    id={consent.optInType}
                    type="text"
                    class="form-control"
                    placeholder="Enter ID"
                    data-required="true"
                    data-custom-error="true"
                    data-custom-error-type={consent.optInType}
                    defaultValue={consent.serviceId}
                    onChange={event => {
                      this._handleUpdateConsent(event, index);
                    }}
                  />
                  <span
                    class="cw-error cw-hidden"
                    data-control={consent.optInType}
                  >
                    <i className="fas fa-exclamation-triangle mr-10"></i>
                    Please enter {consent.label} Id.
                  </span>
                  <span
                    className="cw-error cw-hidden"
                    data-custom-msg={consent.optInType}
                  >
                    <i className="fas fa-exclamation-triangle mr-10"></i>
                    Please enter a valid format of {consent.label} Id.
                  </span>
                </div>
                <div className="col-sm-2">
                  <div
                    className={`
                ${
                  consent.optInType === 'additionalSubscription1' ||
                  consent.optInType === 'additionalSubscription'
                    ? ''
                    : 'cw-hidden'
                }`}
                    style={{ color: '#E9A40A', whiteSpace: 'pre' }}
                  >
                    <img
                      src={groupIcon}
                      alt={groupIcon}
                      width="25"
                      height="25"
                    ></img>
                    &nbsp; New Master
                  </div>
                </div>
              </div>
            </div>
          );
        });
      return consenthtml;
    };

    let consentsSubscriptionHtml = consents => {
      let consenthtml = consents
        .filter(
          consent =>
            consent.optInType === 'additionalSubscription1' ||
            consent.optInType === 'additionalSubscription'
        )
        .map((consent, index) => {
          return (
            <div
              class={`cw-striped-profile-row cw-striped-profile-row--hide_class_${index}`}
              style={{ padding: '8px 7px' }}
            >
              <div class="row">
                <div class="col-sm-3 cw-qna-lable">
                  {consent.optInType === 'additionalSubscription'
                    ? 'Subscription 1 text'
                    : consent.optInType === 'additionalSubscription1'
                    ? 'Subscription 2 text'
                    : ''}
                </div>
                <div class="col-sm-4" style={{ lineBreak: 'anywhere' }}>
                  {consent.defaultCopy
                    ? ReactHtmlParser(consent.defaultCopy)
                    : null}
                </div>
                <div class="col-sm-3">
                  <input
                    name={consent.optInType}
                    id={consent.optInType}
                    type="text"
                    class="form-control"
                    placeholder="Enter ID"
                    data-required="true"
                    data-custom-error="true"
                    data-custom-error-type={consent.optInType}
                    defaultValue={consent.serviceId}
                    onChange={event => {
                      this._handleUpdateConsent(event, index);
                    }}
                  />
                  <span
                    class="cw-error cw-hidden"
                    data-control={consent.optInType}
                  >
                    <i className="fas fa-exclamation-triangle mr-10"></i>
                    Please enter {consent.label} Id.
                  </span>
                  <span
                    className="cw-error cw-hidden"
                    data-custom-msg={consent.optInType}
                  >
                    <i className="fas fa-exclamation-triangle mr-10"></i>
                    Please enter a valid format of {consent.label} Id.
                  </span>
                </div>
                <div className="col-sm-2">
                  <div
                    className={`
                ${
                  consent.optInType === 'additionalSubscription1' ||
                  consent.optInType === 'additionalSubscription'
                    ? ''
                    : 'cw-hidden'
                }`}
                    style={{ color: '#E9A40A', whiteSpace: 'pre' }}
                  >
                    <img
                      src={groupIcon}
                      alt={groupIcon}
                      width="25"
                      height="25"
                    ></img>
                    &nbsp; New Master
                  </div>
                </div>
              </div>
            </div>
          );
        });
      return consenthtml;
    };

    let qnaHtml = (fields, isCampaignEnglish, campaignLanguage) => {
      let questionsHtml = fields
        .filter(elem => elem.dataType === 'questionAnswerPair')
        .map((elem, index) => {
          return (
            <>
              <div className="accordion saas-form-field-accordion">
                <div className="card" style={{ border: 0 }}>
                  {/* <div className="card-header" style={{ padding: "14px 13px" }}>
                    <span className="saas-accordion-heading" style={{ fontWeight: "700" }}>{elem.questionLabel}</span>
                    <span type="button" className="saas-accordion-arrow" onClick={() => {
                            this.handleAccordion(index);
                          }}>
                      <i className={
                              this.state.questionAccordion === index
                                ? "fas fa-chevron-down transformIcon-180"
                                : "fas fa-chevron-down"}></i>
                    </span>
                  </div> */}
                  <div
                    className={`card-header ${
                      this.state.questionAccordion === index
                        ? 'card-bottom-border'
                        : 'card-no-bottom'
                    }`}
                    style={{ padding: '7px 13px', marginBottom: '10px' }}
                  >
                    <div className="row">
                      <div
                        className={
                          elem.isNew ||
                          elem.istranslatedNew ||
                          elem.answers.find(
                            e => e.isNew === true || e.istranslatedNew === true
                          )
                            ? 'col-sm-9'
                            : 'col-sm-11'
                        }
                      >
                        <span
                          className="saas-accordion-heading"
                          style={{ fontWeight: '700' }}
                        >
                          {elem.questionLabel}
                        </span>
                      </div>
                      {elem.isNew ||
                      elem.istranslatedNew ||
                      elem.answers.find(
                        e => e.isNew === true || e.istranslatedNew === true
                      ) ? (
                        <div
                          className="col-sm-2"
                          style={{
                            display: 'block',
                            color: '#E9A40A',
                            whiteSpace: 'pre'
                          }}
                        >
                          <>
                            <img
                              style={{ marginLeft: '5px' }}
                              src={groupIcon}
                              alt={groupIcon}
                              width="25"
                              height="25"
                            ></img>
                            &nbsp; New Master
                          </>
                        </div>
                      ) : (
                        ''
                      )}
                      <div className="col-sm-1">
                        <span
                          type="button"
                          className={
                            this.state.questionAccordion === index
                              ? 'saas-accordion-arrow afterTransform'
                              : 'saas-accordion-arrow'
                          }
                          onClick={() => {
                            this.handleAccordion(index);
                          }}
                        >
                          <i
                            className={
                              this.state.questionAccordion === index
                                ? 'fas fa-chevron-down transformProfileIcon-180'
                                : 'fas fa-chevron-down'
                            }
                          ></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      this.state.questionAccordion === index
                        ? 'collapse show'
                        : 'collapse'
                    }
                  >
                    <div className="cw-campaign--review-section-content">
                      {elem.qualifyingToggle ? (
                        <ReviewRowQnA
                          label="Qualifying question"
                          value="Yes"
                          colWidth="col-sm-3"
                        />
                      ) : null}
                      <br />
                      <div className="cw-question-answer-dotted-line">
                        <div className="cw-heading-qna">
                          <span
                            className="float-left  fa fa-circle mt-10"
                            style={{ marginTop: '5px' }}
                          ></span>
                          <strong style={{ marginLeft: '15px' }}>
                            Question
                          </strong>
                        </div>
                        <div className="cw-striped-qna-row">
                          <div className="row">
                            <div className="col-sm-3 cw-qna-lable">
                              Question Type
                            </div>
                            <div className="col-sm-3 cw-text--ternary">
                              {elem.label}
                            </div>
                          </div>
                        </div>
                        <div className="cw-striped-qna-row">
                          <div className="row">
                            <div className="col-sm-3 cw-qna-lable">
                              English-Master Data
                            </div>
                            <div className="col-sm-3 cw-text--ternary">
                              {elem.englishLabel}
                            </div>
                            <div className="col-sm-3 cw-text--ternary">
                              <input
                                name={`question${index}_id`}
                                id={`question${index}_id`}
                                type="text"
                                fieldType="qna"
                                class="form-control"
                                placeholder="Enter ID"
                                data-required="true"
                                data-custom-error="true"
                                data-custom-error-type={`question${index}_id`}
                                defaultValue={elem.id}
                                onChange={event => {
                                  this._handleUpdateQuestion(
                                    event,
                                    index,
                                    'id'
                                  );
                                }}
                              />
                              <span
                                className="cw-error cw-hidden"
                                data-custom-msg={`question${index}_id`}
                              >
                                <i className="fas fa-exclamation-triangle mr-10"></i>
                                Please enter a valid format of Question
                                (English-Master Data) Id.
                              </span>
                            </div>
                            <div className="col-sm-3 pl-0 pr-1">
                              {newMasterData(elem.isNew)}
                              {elem.previousMasterId && (
                                <MasterDataNotice
                                  previousMasterId={elem.previousMasterId}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        {!isCampaignEnglish ? (
                          <div className="cw-striped-qna-row">
                            <div className="row">
                              <div className="col-sm-3 cw-qna-lable">
                                {campaignLanguage} translation- Master Data
                              </div>
                              <div className="col-sm-3 cw-text--ternary">
                                {elem.translatedLabel}
                              </div>
                              <div className="col-sm-3 cw-text--ternary">
                                <input
                                  name={`question${index}_translatedId`}
                                  id={`question${index}_translatedId`}
                                  type="text"
                                  fieldType="qna"
                                  class="form-control"
                                  placeholder="Enter ID"
                                  data-required="true"
                                  data-custom-error="true"
                                  data-custom-error-type={`question${index}_translatedId`}
                                  defaultValue={elem.translatedId}
                                  onChange={event => {
                                    this._handleUpdateQuestion(
                                      event,
                                      index,
                                      'translatedId'
                                    );
                                  }}
                                />
                                <span
                                  className="cw-error cw-hidden"
                                  data-custom-msg={`question${index}_translatedId`}
                                >
                                  <i className="fas fa-exclamation-triangle mr-10"></i>
                                  Please enter a valid format of Question
                                  Translated Id.
                                </span>
                              </div>
                              <div className="col-sm-3 pl-0 pr-1">
                                {newMasterData(
                                  elem.isNew || elem.istranslatedNew
                                )}
                                {elem.previousMasterTranslatedId && (
                                  <MasterDataNotice
                                    previousMasterId={
                                      elem.previousMasterTranslatedId
                                    }
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        ) : null}

                        <div className="cw-striped-qna-row">
                          <div className="row">
                            <div className="col-sm-3 cw-qna-lable">
                              Consumer Facing Label
                            </div>
                            <div className="col-sm-3 cw-text--ternary">
                              {elem.questionLabel}
                            </div>
                          </div>
                        </div>
                        {elem.isNew && elem.reasonForCreation ? (
                          <div className="cw-striped-qna-row">
                            <div className="row">
                              <div className="col-sm-3 cw-qna-lable">
                                Reason for new request
                              </div>
                              <div className="col-sm-3 cw-text--ternary">
                                {elem.reasonForCreation}
                              </div>
                            </div>
                          </div>
                        ) : null}
                         {isEpsilonMarket ||
                      !isNewMasterDataRequested(elem)  || !elem.additionalComments ? null : (
                        <div className="cw-striped-qna-row">
                          <div className="row">
                            <div className="col-sm-3 cw-qna-lable">
                            Detailed justification for new request
                            </div>
                            <div className="col-sm-8 cw-text--ternary">
                              {elem.additionalComments || ''}
                            </div>
                          </div>
                        </div>
                      )}
                      </div>
                      {elem.answers && elem.answers.length > 0 ? (
                        <>
                          <br />
                          <div className="cw-question-answer-dotted-line">
                            <div className="cw-heading-qna">
                              <span
                                className="float-left  fa fa-circle mt-10"
                                style={{ marginTop: '5px' }}
                              ></span>
                              <strong style={{ marginLeft: '15px' }}>
                                Answers
                              </strong>
                            </div>
                            {elem.answers.map((answer, answerIndex) => {
                              return (
                                <>
                                  <span className="cw-answer-answer">
                                    <strong style={{ marginLeft: '30px' }}>
                                      Answer {answerIndex + 1}
                                    </strong>
                                  </span>
                                  <div className="cw-striped-qna-row">
                                    <div className="row">
                                      <div className="col-sm-3 cw-qna-lable">
                                        English-Master Data
                                      </div>
                                      <div className="col-sm-3 cw-text--ternary">
                                        {answer.englishLabel}
                                      </div>
                                      <div className="col-sm-3 cw-text--ternary">
                                        <input
                                          name={`question${index}_answer_${answerIndex}_value`}
                                          id={`question${index}_answer_${answerIndex}_value`}
                                          type="text"
                                          fieldType="qna"
                                          class="form-control"
                                          placeholder="Enter ID"
                                          data-required="true"
                                          data-custom-error="true"
                                          data-custom-error-type={`question${index}_answer_${answerIndex}_value`}
                                          defaultValue={answer.value}
                                          onChange={event => {
                                            this._handleUpdateAnswer(
                                              event,
                                              index,
                                              answerIndex,
                                              'value'
                                            );
                                          }}
                                        />
                                        <span
                                          className="cw-error cw-hidden"
                                          data-custom-msg={`question${index}_answer_${answerIndex}_value`}
                                        >
                                          <i className="fas fa-exclamation-triangle mr-10"></i>
                                          Please enter a valid format of Answer
                                          (English-Master Data) Id.
                                        </span>
                                      </div>
                                      <div className="col-sm-3 pl-0 pr-1">
                                        {newMasterData(answer.isNew)}
                                        {answer.previousMasterValue && (
                                          <MasterDataNotice
                                            previousMasterId={
                                              answer.previousMasterValue
                                            }
                                          />
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  {!isCampaignEnglish ? (
                                    <div className="cw-striped-qna-row">
                                      <div className="row">
                                        <div className="col-sm-3 cw-qna-lable">
                                          {campaignLanguage} Translation- Master
                                          Data
                                        </div>
                                        <div className="col-sm-3 cw-text--ternary">
                                          {answer.translatedLabel}
                                        </div>
                                        <div className="col-sm-3 cw-text--ternary">
                                          <input
                                            name={`question${index}_answer_${answerIndex}_translatedId`}
                                            id={`question${index}_answer_${answerIndex}_translatedId`}
                                            type="text"
                                            fieldType="qna"
                                            class="form-control"
                                            placeholder="Enter ID"
                                            data-required="true"
                                            data-custom-error="true"
                                            data-custom-error-type={`question${index}_answer_${answerIndex}_translatedId`}
                                            defaultValue={answer.translatedId}
                                            onChange={event => {
                                              this._handleUpdateAnswer(
                                                event,
                                                index,
                                                answerIndex,
                                                'translatedId'
                                              );
                                            }}
                                          />
                                          <span
                                            className="cw-error cw-hidden"
                                            data-custom-msg={`question${index}_answer_${answerIndex}_translatedId`}
                                          >
                                            <i className="fas fa-exclamation-triangle mr-10"></i>
                                            Please enter a valid format of
                                            Answer Translated Id.
                                          </span>
                                        </div>
                                        <div className="col-sm-3 pl-0 pr-1">
                                          {newMasterData(
                                            answer.isNew ||
                                              answer.istranslatedNew
                                          )}
                                          {answer.previousMasterTranslatedId && (
                                            <MasterDataNotice
                                              previousMasterId={
                                                answer.previousMasterTranslatedId
                                              }
                                            />
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}
                                  <div className="cw-striped-qna-row">
                                    <div className="row">
                                      <div className="col-sm-3 cw-qna-lable">
                                        Consumer Facing Label
                                      </div>
                                      <div className="col-sm-3 cw-text--ternary">
                                        {answer.label}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                          </div>
                        </>
                      ) : null}
                      {/* {elem.value==="multipleChoice" ? (
                     <div className="cw-striped-row">
                        <div className="row">
                          <div className="col-sm-4">
                            <strong>Maximum number of answers user can select</strong>
                          </div>
                          <div className="col-sm-8 cw-text--ternary">{elem.maxAnswerLimit}
                          </div>
                       </div>
                     </div>
                     ):null} */}
                      <br />
                      <div className="cw-question-answer-dotted-line">
                        <div className="cw-heading-qna">
                          <span
                            className="float-left  fa fa-circle mt-10"
                            style={{ marginTop: '5px' }}
                          ></span>
                          <strong style={{ marginLeft: '15px' }}>
                            Other Configurations
                          </strong>
                        </div>
                        {elem.toolTip && elem.toolTip.trim().length > 0 ? (
                          <div className="cw-striped-qna-row">
                            <div className="row">
                              <div className="col-sm-3 cw-qna-lable">
                                Tooltip/Help-Text
                              </div>
                              <div className="col-sm-3 cw-text--ternary">
                                {elem.toolTip}
                              </div>
                            </div>
                          </div>
                        ) : null}
                        <div className="cw-striped-qna-row">
                          <div className="row">
                            <div className="col-sm-3 cw-qna-lable">
                              Mandatory toggle
                            </div>
                            <div className="col-sm-8 cw-text--ternary">
                              {elem.mandatoryToggle ? 'Yes' : 'No'}
                            </div>
                          </div>
                        </div>
                        {elem.mandatoryToggle ? (
                          <div className="cw-striped-qna-row">
                            <div className="row">
                              <div className="col-sm-3 cw-qna-lable">
                                Mandatory Field Error Message
                              </div>
                              <div className="col-sm-8 cw-text--ternary">
                                {elem.mandatoryMessage}
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        });
      return questionsHtml;
    };

    return (
      <>
        {asyncInProgress ? <Loader /> : null}

        <div className="cw-section cw-overview mb-30" ref={this.scrollToTop}>
          <div className="mb-30">
            <div className="col-sm-6">
              <NavLink
                className="cw-color--primary cw-breadcrum"
                to={'/campaign-wizard/dashboard'}
              >
                <i className="fas fa-angle-left"></i> Campaigns
              </NavLink>
            </div>
          </div>
          <>
            <Modal
              isOpen={modalIsOpen && activeModal === 'reject'}
              onRequestClose={this._closeModal}
              className="cw-modal cw-modal--reject"
              contentLabel="Rejection Modal"
              style={{
                overlay: {
                  backgroundColor: constant.EMAIL_OVERLAY_BACKGROUND
                }
              }}
            >
              <button
                className="cw-modal--close"
                title="Close"
                onClick={this._closeModal}
              >
                <i className="fas fa-times"></i>
              </button>
              <h3 className="cw-heading--primary mb-10">Confirm rejection</h3>
              <p className="mb-20">
                Please confirm and provide a reason why you have rejected this
                campaign.
              </p>

              {/* rejection textarea */}
              <div>
                <label htmlFor="description">
                  <span>Reason for rejection</span>
                </label>
                <textarea
                  className={statusClassName}
                  name="description"
                  id="description"
                  onChange={this._handleRejectChange}
                  rows="3"
                ></textarea>
                {this.state.showRejectionError ? (
                  <span className="cw-error mb-10" data-control="description">
                    <i className="fas fa-exclamation-triangle mr-10"></i>Please
                    enter rejection reason.
                  </span>
                ) : null}
              </div>

              <div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-outline-secondary"
                    data-dismiss="modal"
                    onClick={this._closeModal}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={this._handleOnSubmit}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </Modal>
          </>
          <div className="col-10 offset-md-1 ">
            {parseInt(campaign.status) === 6 ? (
              <div className="mb-30">
                <div className="alert alert-info col-sm-12">
                  <p>
                    <strong>Campaign request waiting for approval.</strong>{' '}
                    Master data submission will be enabled once campaign is
                    approved.
                  </p>
                </div>
              </div>
            ) : null}
            {!this.isValid && this.state.showMessageAtTop ? (
              <ErrorNotification />
            ) : null}

            <Alert
              type={'warning'}
              alertType={'cancelcampaign'}
              campaignName={this.props.campaign && this.props.campaign.name}
              message={'has been cancelled. No action required.'}
              showComponent={
                this.props.campaign &&
                this.props.campaign.status &
                  (this.props.campaign.status ===
                    constant.campaignStatus.CANCELLED)
              }
            />
            {/* Alert for failure of campaign page generation*/}
            <Alert
              alertType={'nonPublished'}
              showComponent={
                campaign.status === constant.campaignStatus.PROCESSING &&
                campaign &&
                campaign.aemError &&
                campaign.aemError.code !== '404' &&
                campaign.aemError.code !== '412'
              }
            />
            <Alert
              alertType={'error404NonPublished'}
              showComponent={
                campaign.status === constant.campaignStatus.PROCESSING &&
                campaign &&
                campaign.aemError &&
                campaign.aemError.code === '404'
              }
            />
            <Alert
              alertType={'isPromotigoAC'}
              showComponent={
                campaign.status === constant.campaignStatus.PROCESSING &&
                campaign &&
                campaign.aemError &&
                campaign.aemError.code === '412'
              }
            />
          </div>
          <div className="col-10 offset-md-1  mb-20">
            <h2 className="cw-heading--secondary d-flex align-items-center mb-10 justify-content-between">
              <span className="mr-3">Submit Master Data IDs</span>
              <span
                className={`${statusClass} status_badge_reposition_masterdata`}
              >
                {getCampaignStatus}
              </span>
            </h2>
            <p>Enter and submit the Master Data IDs.</p>
          </div>
          {!constant.featureFlags.ENABLE_GUIDELINES &&
            isCampaignTypeSASS &&
            !isCampaignComplete &&
            campaign && !campaign.isMasterData && (
              <div className="d-flex justify-content-end mb-15">
                <div>
                  <a
                    className="btn btn-primary preview-form-btn"
                    href={this.props.previewUrl}
                    target={'_blank'}
                  >
                    Preview Form
                  </a>
                </div>
              </div>
            )}

          <div className="col-10 offset-md-1 ">
            {constant.featureFlags.ENABLE_GUIDELINES ? (
              <GuideLinesAccordian
                data={constant.MASTER_GUIDE_LINES}
                previewUrl={this.props.previewUrl}
                showMasterIcon={showMasterDataIcon(
                  campaign && campaign.campaign
                )}
                campaignLanguageCode={languageCode}
                campaignLanguage={campaignLanguage}
                showPreviewFormCta={
                  isCampaignTypeSASS &&
                  !isCampaignComplete &&
                  campaign && !campaign.isMasterData
                }
              />
            ) : null}
            {/* Campaign Details */}

            <div className="mb-3">
              <div className="mb-20">
                {isCampaignTypeSASS && getCampaignStatus === 'Completed' ? (
                  <SummaryComponent
                    data={this.props.campaign}
                    isBrandSummary={false}
                    cwStatus={this.props.cwStatus}
                    previewUrl={this.props.previewUrl}
                    isCampaignTypeSASS={this.props.isCampaignTypeSASS}
                    handleRefresh={this._handleRefresh}
                    campaignConfig={this.props.campaignConfig}
                  />
                ) : null}
              </div>
              <div className="mb-20">
                <SummaryComponent
                  data={this.props.campaign}
                  isBrandSummary={true}
                  cwStatus={this.props.cwStatus}
                  isCampaignTypeSASS={this.props.isCampaignTypeSASS}
                  handleRefresh={this._handleRefresh}
                  campaignConfig={this.props.campaignConfig}
                />
              </div>
            </div>
            <CampaignDetailsInfo
              campaign={campaign}
              campaignDetails={campaignDetails}
              isConsentAndCompliance={true}
              isCampaignType={true}
              isCampaignAuthoring={true}
              handleRefresh={this._handleRefresh}
              campaignConfig={this.props.campaignConfig}
            />
            {isCampaignTypeSASS &&
            !constant.featureFlags.ENABLE_SINGLE_PAGE_FIELD_SELECTION ? (
              <FormAuthoring
                isEditButton={false}
                ishideQnA={true}
                campaignId={this.props.match.params.id}
                colWidth={`col-sm-3`}
                accordianClass={`col-sm-9`}
                newIconClass={`col-sm-2`}
              ></FormAuthoring>
            ) : null}
            {isCampaignTypeSASS &&
            constant.featureFlags.ENABLE_SINGLE_PAGE_FIELD_SELECTION ? (
              <FormAuthoringGrouped
                isEditButton={false}
                ishideQnA={true}
                campaignId={this.props.match.params.id}
                colWidth={`col-sm-3`}
                accordianClass={`col-sm-9`}
                newIconClass={`col-sm-2`}
                isMasterData={campaign && campaign.isMasterData}
                campaignConfig={this.props.campaignConfig}
              ></FormAuthoringGrouped>
            ) : null}
            {!isCampaignIncentive && !isCampaignTypeSASS ? (
              <RegularCommunications
                campaign={campaign}
                campaignDetails={campaignDetails}
                colLabelClass="col-sm-4"
                colValClass="col-sm-8"
                disabledFlag={false}
                draftFlag={false}
                optins={this.props.optins}
                optinsDescription={this.state.optinsDescription}
              ></RegularCommunications>
            ) : null}
            {/* Campaign ID */}
            <div className="cw-campaign--review-section mb-40">
              <div className="cw-campaign--review-section mb-30">
                <div className="mb-20">
                  <div className="row">
                    <div className="col-sm-4">
                      <h3 className="cw-heading--primary cw-heading--primary--qna-reboot">
                        Master Data IDs
                      </h3>
                    </div>
                    <div className="col-sm-3"></div>
                    <div className="col-sm-3">
                      <div
                        className={`${
                          showMasterDataIcon(campaign && campaign.campaign)
                            ? ''
                            : additionalOptins && additionalOptins.length > 0
                            ? ''
                            : 'cw-hidden'
                        }`}
                        style={{ color: '#E9A40A', whiteSpace: 'pre' }}
                      >
                        <img
                          src={groupIcon}
                          alt={groupIcon}
                          width="25"
                          height="25"
                        ></img>
                        &nbsp; New Master
                      </div>
                    </div>
                    <div className="col-sm-2"></div>
                  </div>
                </div>
                <div className="cw-campaign--review-section-content mb-40">
                  {campaign && campaign.isMasterData && (
                    <div className="cw-striped-profile-row mb-10">
                      <div className="row mt-10">
                        <div className="col-sm-3">
                          <strong> Brand code </strong>
                        </div>
                        <div className="col-sm-4"></div>
                        <div className="col-sm-5">
                          {campaign.brand && campaign.brand.code
                            ? campaign.brand.code
                            : ''}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="cw-striped-profile-row">
                    <div className="row">
                      <div className="col-sm-3">
                        {constant.featureFlags.ENABLE_CAMPAIGN_ID_GOVERNANCE ? (
                          <strong>{`${this.props.campaignConfig.LABEL_SIGN_UP_ID}`}</strong>
                        ) : (
                          <strong>Campaign ID</strong>
                        )}
                      </div>
                      <div className="col-sm-4"></div>
                      <div className="col-sm-4">
                        <div>
                          <div className="d-flex align-items-center">
                            <span>
                              <input
                                type="text"
                                className="form-control"
                                name="campaignId"
                                id="campaignId"
                                data-required="true"
                                placeholder="Enter ID"
                                data-custom-error="true"
                                disabled={
                                  this.props.campaign.status ===
                                  constant.campaignStatus.CANCELLED
                                }
                                data-custom-error-type="campaignId"
                                value={campaign.campaignId || ''}
                                onChange={this._handleChange}
                              />
                            </span>
                            &nbsp;
                            {/* data-custom-error="true"*/}
                            <ReactTooltip
                              id="acquisitionIdToolTip"
                              place="top"
                              type="info"
                              multiline={true}
                              className="cw-toolip custom-cw-toolip"
                            />
                            <span
                              className="float-right  cw-icon cw-icon--help"
                              data-tip={`Campaign IDs are now known as ${this.props.campaignConfig.LABEL_SIGN_UP_ID}s to better explain the purpose of the field to uniquely identify the  initiative through which a consumer signed up`}
                              currentitem="false"
                              data-for="acquisitionIdToolTip"
                            >
                              <i className="fas fa-question-circle"></i>
                            </span>
                          </div>

                          <span
                            className="cw-error cw-hidden"
                            data-control="campaignId"
                          >
                            <i className="fas fa-exclamation-triangle mr-10"></i>
                            {`Please enter ${this.props.campaignConfig.LABEL_SIGN_UP_ID}.`}
                          </span>
                          <span
                            className="cw-error cw-hidden"
                            data-custom-msg="campaignId"
                          >
                            <i className="fas fa-exclamation-triangle mr-10"></i>
                            {`Please enter a valid ${this.props.campaignConfig.LABEL_SIGN_UP_ID} (example CN123456 or
                          PN123456)`}
                          </span>
                        </div>
                      </div>
                      <div className="col-sm-1"></div>
                    </div>
                  </div>

                  {!isCampaignTypeSASS ||
                  (isCampaignTypeSASS &&
                    (this._isConsentsValid(campaignDetails.consents) ||
                      (campaign.type === constant.CAMPAIGN_QA_TYPE &&
                        campaignDetails.fields.filter(
                          elem => elem.dataType === 'questionAnswerPair'
                        ).length))) ? (
                    <div>
                      <div
                        className="collapse show"
                        id="masterDataCollapsed"
                        // ref={this.collapseMasterPanel}
                      >
                        <div>
                          {!isCampaignTypeSASS ||
                          (isCampaignTypeSASS &&
                            this._isConsentsValid(campaignDetails.consents)) ? (
                            <>
                              {/* <div class="mb-20">
                              <p class="cw-heading--saascampaign mt-20">
                                Consent IDs
                              </p>
                            </div> */}

                              {!isCampaignTypeSASS ? (
                                <div className="cw-campaign--review-section-content">
                                  <div className="cw-striped-profile-row">
                                    <div className="row">
                                      <div className="col-sm-3">
                                        <strong>
                                          {!campaignDetails.emailChannel &&
                                          campaignDetails.smsChannel
                                            ? 'Brand (SMS)'
                                            : 'Brand (email)'}
                                        </strong>
                                      </div>
                                      <div className="col-sm-4">
                                        {campaignDetails.emailChannel &&
                                        campaignDetails.smsChannel ? (
                                          <p>
                                            Please tick the box to receive
                                            offers and competitions about (brand
                                            name ) by email.
                                          </p>
                                        ) : campaignDetails.emailChannel &&
                                          !campaignDetails.smsChannel ? (
                                          <p>
                                            Please tick the box to receive
                                            offers and competitions about (brand
                                            name ) by email.
                                          </p>
                                        ) : !campaignDetails.emailChannel &&
                                          campaignDetails.smsChannel ? (
                                          <p>
                                            Please tick the box to receive
                                            offers and competitions about (brand
                                            name ) by SMS.
                                          </p>
                                        ) : (
                                          <p>
                                            Please tick the box to receive
                                            offers and competitions about (brand
                                            name ) by email.
                                          </p>
                                        )}
                                      </div>
                                      <div className="col-sm-3">
                                        <input
                                          type="text"
                                          className="form-control"
                                          name={this.props.brandoptin.text}
                                          id={this.props.brandoptin.text}
                                          value={this.props.brandoptin.id || ''}
                                          placeholder="Enter ID"
                                          data-required="true"
                                          data-custom-error="true"
                                          data-custom-error-type="brandEmailId"
                                          disabled={
                                            this.props.campaign.status ===
                                            constant.campaignStatus.CANCELLED
                                          }
                                          onChange={
                                            this._handleBrandOptinChange
                                          }
                                        />
                                        <span
                                          className="cw-error cw-hidden"
                                          data-control={
                                            this.props.brandoptin.text
                                          }
                                        >
                                          <i className="fas fa-exclamation-triangle mr-10"></i>
                                          Please enter
                                          {' ' + this.props.brandoptin.text} Id.
                                        </span>
                                        <span
                                          className="cw-error cw-hidden"
                                          data-custom-msg={
                                            this.props.brandoptin.text
                                          }
                                        >
                                          <i className="fas fa-exclamation-triangle mr-10"></i>
                                          Please enter a valid format{' '}
                                          {' ' + this.props.brandoptin.text} Id.
                                        </span>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="cw-striped-profile-row">
                                    <div className="row">
                                      <div className="col-sm-3">
                                        <strong>
                                          {campaignDetails.emailChannel &&
                                          campaignDetails.smsChannel
                                            ? 'Brand (SMS)'
                                            : this.props.unileveroptin.text}
                                        </strong>
                                      </div>
                                      <div className="col-sm-4">
                                        {campaignDetails.emailChannel &&
                                        campaignDetails.smsChannel ? (
                                          <p>
                                            Please tick the box to receive
                                            offers and competitions about (brand
                                            name ) by SMS.
                                          </p>
                                        ) : campaignDetails.emailChannel &&
                                          !campaignDetails.smsChannel ? (
                                          <p>
                                            Please tick the box to receive
                                            exciting news, offers and
                                            competitions from other Unilever
                                            brands by email.
                                          </p>
                                        ) : !campaignDetails.emailChannel &&
                                          campaignDetails.smsChannel ? (
                                          <p>
                                            Please tick the box to receive
                                            exciting news, offers and
                                            competitions from other Unilever
                                            brands by email.
                                          </p>
                                        ) : (
                                          <p>
                                            Please tick the box to receive
                                            offers and competitions about (brand
                                            name ) by SMS.
                                          </p>
                                        )}
                                      </div>
                                      <div className="col-sm-3">
                                        <input
                                          type="text"
                                          className="form-control"
                                          name={this.props.unileveroptin.text}
                                          id={this.props.unileveroptin.text}
                                          value={
                                            this.props.unileveroptin.id || ''
                                          }
                                          placeholder="Enter ID"
                                          data-required="true"
                                          data-custom-error="true"
                                          data-custom-error-type={
                                            campaignDetails.emailChannel &&
                                            !campaignDetails.smsChannel
                                              ? 'unileverId'
                                              : 'brandEmailId'
                                          }
                                          disabled={
                                            this.props.campaign.status ===
                                            constant.campaignStatus.CANCELLED
                                          }
                                          onChange={
                                            this._handleUnileverOptinChange
                                          }
                                        />
                                        <span
                                          className="cw-error cw-hidden"
                                          data-control={
                                            this.props.unileveroptin.text
                                          }
                                        >
                                          <i className="fas fa-exclamation-triangle mr-10"></i>
                                          Please enter
                                          {' ' +
                                            this.props.unileveroptin.id}{' '}
                                          Id.
                                        </span>
                                        <span
                                          className="cw-error cw-hidden"
                                          data-custom-msg={
                                            this.props.unileveroptin.text
                                          }
                                        >
                                          <i className="fas fa-exclamation-triangle mr-10"></i>
                                          Please enter a valid format{' '}
                                          {' ' + this.props.unileveroptin.text}{' '}
                                          Id.
                                        </span>
                                      </div>
                                    </div>
                                    <div className="col-sm-2"></div>
                                  </div>

                                  {campaignDetails.emailChannel &&
                                  campaignDetails.smsChannel ? (
                                    <div className="cw-striped-profile-row">
                                      <div className="row">
                                        <div className="col-sm-3">
                                          <strong>
                                            {this.props.unileverid.text !==
                                            undefined
                                              ? this.props.unileverid.text
                                              : ''}
                                          </strong>
                                        </div>
                                        <div className="col-sm-4">
                                          <p>
                                            Please tick the box to receive
                                            exciting news, offers and
                                            competitions from other Unilever
                                            brands by email.
                                          </p>
                                        </div>
                                        <div className="col-sm-3">
                                          <input
                                            type="text"
                                            className="form-control"
                                            name={this.props.unileverid.text}
                                            id={this.props.unileverid.text}
                                            value={
                                              this.props.unileverid.id || ''
                                            }
                                            placeholder="Enter ID"
                                            data-required="true"
                                            data-custom-error="true"
                                            data-custom-error-type="unileverId"
                                            disabled={
                                              this.props.campaign.status ===
                                              constant.campaignStatus.CANCELLED
                                            }
                                            onChange={
                                              this._handleUnileverIdChange
                                            }
                                          />
                                          <span
                                            className="cw-error cw-hidden"
                                            data-control={
                                              this.props.unileverid.text
                                            }
                                          >
                                            <i className="fas fa-exclamation-triangle mr-10"></i>
                                            Please enter
                                            {' ' +
                                              this.props.unileverid.text}{' '}
                                            Id.
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}
                                  {!isCampaignIncentive &&
                                  additionalOptins.length ? (
                                    <div className="cw-striped-profile-row">
                                      <div className="row">
                                        <div className="col-sm-3">
                                          <strong>
                                            Additional Subscriptions
                                          </strong>
                                        </div>
                                        <div className="col-sm-3"></div>
                                        <div className="col-sm-3"></div>
                                      </div>
                                    </div>
                                  ) : null}
                                  {!isCampaignIncentive &&
                                  additionalOptins.length
                                    ? additionalOptins.map((optin, index) => {
                                        return (
                                          <Fragment key={index}>
                                            <div
                                              className={`cw-striped-profile-row cw-striped-profile-row--hide_class_${index} mb-2`}
                                            >
                                              <div className="row">
                                                <div className="col-sm-3 cw-qna-lable">
                                                  {'Subscription  ' +
                                                    (index + 1) +
                                                    ' text'}
                                                </div>
                                                <div className="col-sm-4">
                                                  <p>{optin.text}</p>
                                                </div>
                                                <div className="col-sm-3">
                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter ID"
                                                    name={
                                                      'additionalOptinList-' +
                                                      index
                                                    }
                                                    id={
                                                      'additionalOptinList-' +
                                                      index
                                                    }
                                                    value={optin.id || ''}
                                                    disabled={
                                                      this.props.campaign
                                                        .status ===
                                                      constant.campaignStatus
                                                        .CANCELLED
                                                    }
                                                    onChange={
                                                      this
                                                        ._handleAdditonalOptins
                                                    }
                                                  />
                                                  <span
                                                    className="cw-error cw-hidden"
                                                    data-control={
                                                      'additionalOptinList-' +
                                                      index
                                                    }
                                                  >
                                                    <i className="fas fa-exclamation-triangle mr-10"></i>
                                                    Please enter
                                                    {' Subscription ' +
                                                      (index + 1)}{' '}
                                                    text Id.
                                                  </span>
                                                </div>
                                                <div className="col-sm-2">
                                                  <div
                                                    style={{
                                                      color: '#E9A40A',
                                                      whiteSpace: 'pre'
                                                    }}
                                                  >
                                                    <img
                                                      src={groupIcon}
                                                      alt={groupIcon}
                                                      width="25"
                                                      height="25"
                                                    ></img>
                                                    &nbsp; New Master
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </Fragment>
                                        );
                                      })
                                    : null}
                                </div>
                              ) : (
                                <div className="cw-campaign--review-section-content">
                                  {consentsHtml(campaignDetails.consents)}
                                  {campaignDetails.consents.filter(
                                    consent =>
                                      consent.optInType ===
                                        'additionalSubscription1' ||
                                      consent.optInType ===
                                        'additionalSubscription'
                                  ).length ? (
                                    <div
                                      className="cw-striped-profile-row"
                                      style={{ padding: '8px 4px' }}
                                    >
                                      <div className="row">
                                        <div className="col-sm-4">
                                          <strong
                                            style={{ paddingLeft: '6px' }}
                                          >
                                            Additional Subscriptions
                                          </strong>
                                        </div>
                                      </div>
                                      {consentsSubscriptionHtml(
                                        campaignDetails.consents
                                      )}
                                    </div>
                                  ) : null}
                                  <div
                                    className="cw-striped-profile-row"
                                    style={{ padding: '8px 4px' }}
                                  ></div>
                                </div>
                              )}
                            </>
                          ) : null}
                          {campaign.type ===
                            'Sign-up with Questions & Answers' &&
                          campaignDetails.fields.filter(
                            elem => elem.dataType === 'questionAnswerPair'
                          ).length ? (
                            <>
                              <div class="mb-20">
                                <p class="cw-heading--saascampaign mt-20">
                                  Question and Answers
                                </p>
                              </div>
                              <div className="mb-20">
                                {qnaTileData && qnaTileData.showQnATile ? (
                                  <SummaryComponent
                                    data={this.props.campaign}
                                    isBrandSummary={false}
                                    isQnASummary={true}
                                    qnaTileData={qnaTileData}
                                    handleRefresh={this._handleRefresh}
                                    campaignConfig={this.props.campaignConfig}
                                  />
                                ) : null}
                              </div>
                              <div className="cw-campaign--review-section-content">
                                {qnaHtml(
                                  campaignDetails.fields,
                                  isCampaignNonEnglish,
                                  campaignLanguage
                                )}
                              </div>
                            </>
                          ) : null}
                        </div>
                      </div>

                      {/* <a
                      className="collapse-h pt-2"
                      href="#masterDataCollapsed"
                      role="button"
                      aria-expanded="false"
                      aria-controls="masterDataCollapsed"
                     // onClick={this._handleCollapse}
                    >
                      <span ref={this.collapseHandlerText}>
                        View more Master Data IDs
                      </span>{' '}
                      <i
                        className="fas fa-chevron-down ml-1"
                        ref={this.collapseHandlerIcon}
                      ></i>
                    </a> */}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            {questionAnswerList.length &&
            campaign.type === 'Sign-up with Questions & Answers' &&
            !isCampaignTypeSASS ? (
              <>
                {/* <MasterDataQuestion
              questionAnswerList={questionAnswerList}
              campaignLanguage={
                (campaign.language && campaign.language.name) || ""
              }
              disabled={
                this.props.campaign.status === constant.campaignStatus.CANCELLED
              }
              fielddValidation={this._validateField}
              isCampaignNonEnglish={isCampaignNonEnglish}
              isPromotigoType={isPromotigoType}
            /> */}
                <QnAList
                  campaignLanguage={campaignLanguage}
                  handleQuestionsAnswers={this._handleQuestionsAnswers}
                  isCampaignNonEnglish={isCampaignNonEnglish}
                  campaignId={this.campaignId}
                  qnaList={questionAnswerList}
                  showQnaList={
                    campaign.type === 'Sign-up with Questions & Answers' &&
                    !isCampaignTypeSASS
                  }
                  isPromotigoType={isPromotigoType}
                  colWidth={`col-sm-3`}
                  showAwaitingQid={false}
                  isEditButtonShow={false}
                  disabled={
                    this.props.campaign.status ===
                    constant.campaignStatus.CANCELLED
                  }
                  fielddValidation={this._validateField}
                  showTextBox={true}
                  accordianClass={`col-sm-9`}
                  newIconClass={`col-sm-2`}
                  campaign={campaign}
                  campaignDetails={campaignDetails}
                />
              </>
            ) : null}

            <div
              className={`col-sm-12 ${
                this.props.campaign.status === constant.campaignStatus.CANCELLED
                  ? 'd-none'
                  : ''
              }`}
            >
              <div className="cw-form--action-cta">
                <button
                  type="button"
                  className="btn btn btn-outline-secondary mr-3"
                  data-action="reject"
                  onClick={this._handleReject}
                >
                  Reject
                </button>
                <button
                  type="button"
                  className="btn btn-primary mr-3"
                  onClick={this._updateMasterData}
                >
                  Submit
                </button>
                <a
                  href="#"
                  className="cw-save-to-draft mt-25"
                  onClick={this._saveAsDraft}
                >
                  Save as draft
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-10 offset-md-1 ">
          <div className="overview-page-history">
            {this.props.historyLogs.length ? (
              <HistoryComponent historyLogs={this.props.historyLogs} />
            ) : null}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  campaign: state.masterData,
  campaignDetails: state.masterData.campaignDetail || {},
  questionAnswerList: state.masterData.questionAnswerList || [],
  additionalOptins: state.masterData.additionalOptins,
  asyncInProgress: state.masterData.asyncInProgress,
  updated: state.masterData.updated,
  draftSaved: state.masterData.draftSaved,
  brandoptin: state.masterData.brandoptin,
  unileveroptin: state.masterData.unileveroptin,
  unileverid: state.masterData.unileverid,
  optins: state.masterData.optins,
  historyLogs: state.historyLogs,
  campaignEditLog: state.approval.campaignEditLog,
  campaignIdUpdated: state.masterData.campaignIdUpdated,
  newcampaignId: state.masterData.newcampaignId,
  isTrackingRequired: state.masterData.isTrackingRequired,
  modalIsOpen: state.app.modalIsOpen,
  activeModal: state.app.activeModal,
  previewUrl: state.preview.previewUrl,
  campaignConfig: state.app.campaignConfig
});

export default connect(mapStateToProps, {
  rejectCampaign,
  setModalStatus,
  fetchCampaignData,
  saveMasterData,
  updateCampaignDetails,
  submitCampaignDetails,
  updateCampaignBrandOptinDetails,
  updateCampaignUnileverOptinDetails,
  updateCampaignUnileverIdDetails,
  updateAdditionalOptins,
  setNotificationMessage,
  fetchHistoryLogs,
  fetchCampaignEditLogs
})(MasterData);
