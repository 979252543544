import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Modal from 'react-modal';
import Select from 'react-select';
import {
  getCampaignDetails,
  updateCampaignDetails,
  submitCampaignDetails,
  updateAdditionalOptins,
  resetSubmissionState
} from '../actions/campaignDetails';
import ErrorNotification from './errorNotification';
import { scrollToTop } from '../selectors';
import {
  setApproverEditCampaignStatus,
  setApproverEditCampaignStatusMessage,
  editedCampaignDetails,
  fetchEditedCampaignLogs,
  saveEditedCampaignLogs
} from '../actions/app';
import Loader from './Loader';

import BreadCrumb from './BreadCrumb';
import { setNotificationMessage } from '../actions/dashboard';
import constant from '../constants';
import ModalWindow from './ModalWindow';
import standardPreference from '../images/regular-communications/standard-preference-centre.png';
import additionalPreference from '../images/regular-communications/additonal-consent-preference.png';

const brandConsentDescription = `When the consumer consents, the brand is permitted to send brand related communications. If the
consumer removes their consent, via the preference centre then the brand can no longer send communications.`;
const unileverConsentDescription = `Unilever consent complete a cross marketing within the divisional space.`;
const extraConsentDescription = `Extra consent is an additional subscription that is attached to the brand consent.
It should be used as a single communication topic which is not just brand communication (which is covered by brand 
  consent). This consent permits consumers to control the Unilever communication received.`;
const emailPreferenceDescription = (
  <>
    <span>
      Example of the standard brand and Unilever communications in preference
      centre. Highlighted in{' '}
    </span>
    <span className="cw-color-red">red</span>
    <span>.</span>
  </>
);
const additionalPreferenceDescription = (
  <>
    <span>
      Example of additional consent in preference centre. Highlighted in{' '}
    </span>
    <span className="cw-color-red">red</span>
    <span>.</span>
  </>
);
class ConsentPreferences extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false,
      consentModalIsOpen: false,
      showMessageAtTop: false,
      matchProps: props.match,
      campaignId: '',
      openBrandConsentModal: false,
      openUnileverConsentModal: false,
      openExtraConsentModal: false,
      openEmailPreferenceModal: false,
      openAdditionalPreferenceModal: false
    };

    this.valid = true;

    this.options = [
      { value: 'daily', label: 'Daily' },
      { value: 'weekly', label: 'Weekly' },
      { value: 'monthly', label: 'Monthly' },
      { value: 'annually', label: 'Annually' }
    ];

    this.addmoreoption = React.createRef();

    this._handleInputChange = this._handleInputChange.bind(this);
    this._handleAdditonalOptins = this._handleAdditonalOptins.bind(this);
    this._addConsent = this._addConsent.bind(this);
    this._deleteConsent = this._deleteConsent.bind(this);
    this._handleSubmit = this._handleSubmit.bind(this);
    this._handlebackButton = this._handlebackButton.bind(this);
    this._openModal = this._openModal.bind(this);
    this._closeModal = this._closeModal.bind(this);
    this._closeConsentModal = this._closeConsentModal.bind(this);
    this._openConsentModal = this._openConsentModal.bind(this);
    this._handleAdditonalOptinsFrequency = this._handleAdditonalOptinsFrequency.bind(
      this
    );
    this._cancelApproverEdit = this._cancelApproverEdit.bind(this);
    this._validateForm = this._validateForm.bind(this);
    this._validateField = this._validateField.bind(this);
  }

  componentDidMount() {
    // load images for modal windows in advance
    const imagesToBePreloaded = [standardPreference, additionalPreference];
    imagesToBePreloaded.forEach(image => {
      new Image().src = image;
    });

    this.campaignId = this.props.match.params.id;

    this.setState(state => {
      return { ...state, ...{ campaignId: this.campaignId } };
    });
    this.props.getCampaignDetails(this.campaignId);
    scrollToTop();
  }

  componentDidUpdate(prevProps) {
    if (
      !this.props.approverEditCampaign &&
      this.props.isCampaignEdited &&
      this.props.isCampaignEdited !== prevProps.isCampaignEdited
    ) {
      this.props.fetchEditedCampaignLogs(this.campaignId);
    }
    if (this.props.campaignType) {
      let isCampaignIncentive =
        this.props.campaignType === 'Incentive' ? true : false;
      if (isCampaignIncentive) {
        this.props.history.push('/campaign-wizard/dashboard');
      }
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.campaignType) {
      let isCampaignIncentive =
        props.campaignType === 'Incentive' ? true : false;
      if (isCampaignIncentive) {
        props.history.push('/campaign-wizard/dashboard');
      }
    }

    if (props.pushstate) {
      props.resetSubmissionState(false);

      let nextRoute = '';
      if (props.approverEditCampaign) {
        props.setApproverEditCampaignStatusMessage('success');
        nextRoute = 'approval';
      } else {
        nextRoute =
          props.type === 'Simple sign-up' ? 'review' : 'questionsanswers';
      }

      props.history.push(
        '/campaign-wizard/campaign/' + props.match.params.id + '/' + nextRoute
      );
    }

    /* redirect to dashboard once consent details saved as draft */
    if (props.dataSaved) {
      let notificationData = {};
      notificationData.message = constant.CAMPAIGN_SAVED_AS_DRAFT_TEXT;
      notificationData.link = '';
      notificationData.type = 'warning';
      notificationData.campaign = '';

      props.setNotificationMessage(
        notificationData.message,
        notificationData.link,
        notificationData.type,
        notificationData.campaign
      );

      props.history.push('/campaign-wizard/dashboard');
    }
    return null;
  }

  _openModal(event) {
    event.preventDefault();
    this.setState(state => {
      return { ...state, ...{ modalIsOpen: true } };
    });
  }

  _openConsentModal(event) {
    event.preventDefault();
    this.setState(state => {
      return { ...state, ...{ consentModalIsOpen: true } };
    });
  }

  _closeModal() {
    this.setState(state => {
      return { ...state, ...{ modalIsOpen: false } };
    });
  }

  _closeConsentModal() {
    this.setState(state => {
      return { ...state, ...{ consentModalIsOpen: false } };
    });
  }

  _toggleOptions(index) {
    if (
      document
        .querySelector("div[id='additionalOptinListFrequency_" + index + "']")
        .classList.contains('cw-hidden')
    ) {
      document
        .querySelector("div[id='additionalOptinListFrequency_" + index + "']")
        .classList.remove('cw-hidden');
    } else {
      document
        .querySelector("div[id='additionalOptinListFrequency_" + index + "']")
        .classList.add('cw-hidden');
    }
  }

  _handleInputChange(event) {
    let detailsToUpdate = {
      [event.target.name]:
        event.target.type === 'checkbox'
          ? event.target.checked
          : event.target.value === 'true'
          ? true
          : false
    };
    this.props.updateCampaignDetails(detailsToUpdate);
    this.props.editedCampaignDetails(detailsToUpdate);
  }

  _addConsent(event) {
    if (this.props.additionalOptinList.length < 2) {
      let additionalOptin = { text: '', id: '' };
      this.props.additionalOptinList.push(additionalOptin);
      this.props.updateAdditionalOptins(this.props.additionalOptinList);
      if (
        this.addmoreoption.current.parentElement.classList.contains('cw-hidden')
      ) {
        this.addmoreoption.current.parentElement.classList.remove('cw-hidden');
      }
    } else {
      if (
        !this.addmoreoption.current.parentElement.classList.contains(
          'cw-hidden'
        )
      ) {
        this.addmoreoption.current.parentElement.classList.add('cw-hidden');
      }
    }
  }

  _deleteConsent(event) {
    let itemIndex = parseInt(event.target.parentElement.dataset.index);
    this.props.additionalOptinList.splice(itemIndex);
    this.props.updateAdditionalOptins(this.props.additionalOptinList);
    this.props.editedCampaignDetails({
      additionalOptinList: this.props.additionalOptinList
    });
  }

  _handleAdditonalOptins(event) {
    if (
      event.target.name &&
      event.target.name.startsWith('additionalOptinList')
    ) {
      let index = event.target.name.split('-')[1];
      let additionalOptin = { text: event.target.value, id: '' };
      if (!event.target.value) {
        this.props.additionalOptinList.splice(index);
      }
      if (typeof this.props.additionalOptinList[index] !== 'undefined') {
        this.props.additionalOptinList[index]['text'] = event.target.value;
      } else {
        this.props.additionalOptinList[index] = additionalOptin;
      }

      this.props.updateAdditionalOptins(this.props.additionalOptinList);
      this.props.editedCampaignDetails({
        additionalOptinList: this.props.additionalOptinList
      });
    }
  }

  _handleAdditonalOptinsFrequency(selectedOption, index) {
    let indexValue = index.name.split('-')[1];
    if (!selectedOption) {
      this.props.additionalOptinList.splice(indexValue);
    }
    this._validateField(indexValue);
    this.props.additionalOptinList[indexValue]['frequency'] =
      selectedOption.value;
    this.props.updateAdditionalOptins(this.props.additionalOptinList);
  }

  _validateField(index) {
    if (
      !document
        .querySelector(
          "[data-control='additionalOptinListFrequency-" + index + "']"
        )
        .classList.contains('cw-hidden')
    ) {
      document
        .querySelector(
          "[data-control='additionalOptinListFrequency-" + index + "']"
        )
        .classList.add('cw-hidden');
    }
  }

  _validateForm(event) {
    event.preventDefault();
    if (
      this.props.additionalOptinList.length &&
      this.props.additionalConsentFlag
    ) {
      this.props.additionalOptinList.forEach((optin, index) => {
        if (
          this.props.additionalOptinList[index].text &&
          (this.props.additionalOptinList[index].frequency === 'undefined' ||
            !this.props.additionalOptinList[index].frequency)
        ) {
          if (
            document.querySelector(
              "[data-control='additionalOptinListFrequency-" + index + "']"
            )
          ) {
            if (
              document
                .querySelector(
                  "[data-control='additionalOptinListFrequency-" + index + "']"
                )
                .classList.contains('cw-hidden')
            ) {
              document
                .querySelector(
                  "[data-control='additionalOptinListFrequency-" + index + "']"
                )
                .classList.remove('cw-hidden');
            }
          }
          this.valid = false;
          this.setState({ showMessageAtTop: true });
        } else {
          this.valid = true;
          this.setState({ showMessageAtTop: false });
          scrollToTop();
        }
      });
    }
  }

  _handleSubmit(event, saveAsDraft) {
    event.preventDefault();

    let updatedFormData = {};

    if (!saveAsDraft) {
      this._validateForm(event);
    } else {
      this.isValid = true;
    }
    if (this.valid) {
      updatedFormData[
        'additionalConsentFlag'
      ] = this.props.additionalConsentFlag;
      updatedFormData['additionalOptinList'] = this.props.additionalConsentFlag
        ? this.props.additionalOptinList
        : [];
      updatedFormData['emailChannel'] = this.props.emailChannel;
      updatedFormData['smsChannel'] = this.props.smsChannel;
      updatedFormData.uid = this.campaignId;
      updatedFormData.lastUpdatedScreen = 'consentpreferences';
      this.props.submitCampaignDetails(
        updatedFormData,
        saveAsDraft,
        this.props.userName,
        this.props.userEmail
      );

      if (!this.props.approverEditCampaign && this.props.isCampaignEdited) {
        this.props.saveEditedCampaignLogs(
          this.campaignId,
          this.props.editCampaignDetails
        );
      }
    }
  }

  _handlebackButton(event) {
    event.preventDefault();
    const prevRoute =
      this.props.websiteType === 'Standalone' && this.props.autogenerate
        ? '/authoringaccess'
        : '/details';
    this.props.history.push(
      '/campaign-wizard/campaign/' + this.campaignId + prevRoute
    );
  }

  _cancelApproverEdit() {
    this.props.setApproverEditCampaignStatus(false);
    this.props.setApproverEditCampaignStatusMessage('');
    this.props.history.push(
      '/campaign-wizard/campaign/' + this.campaignId + '/approval'
    );
  }

  render() {
    Modal.setAppElement('#root');
    let additionalConsentClass = classNames({
      'ml-4': true,
      'cw-subCategory': true,
      'mb-20': true,
      'cw-hidden': !this.props.additionalConsentFlag
    });

    let addMoreOptionClass = classNames({
      'ml-10': true,
      'mb-25': true,
      'cw-hidden': this.props.additionalOptinList.length >= 2 ? true : false
    });
    let isCampaignIncentive =
      this.props.campaignType === 'Incentive' ? true : false;
    return (
      <div className="col-sm-12">
        {isCampaignIncentive ? (
          <Loader />
        ) : (
          <div className="cw-section ml-20">
            <div className="row">
              <div className="col-sm-9">
                <div className="cw-section--content">
                  {!isCampaignIncentive ? (
                    <BreadCrumb
                      match={this.state.matchProps}
                      editCampaign={
                        this.props.editCampaign || this.props.isCampaignEdited
                      }
                      hideBreadCrumb={this.props.approverEditCampaign}
                      websiteType={this.props.websiteType}
                      campaignType={this.props.campaignType}
                    />
                  ) : null}

                  {!this.isValid && this.state.showMessageAtTop ? (
                    <ErrorNotification />
                  ) : null}
                  <div className="cw-section--title mb-20">
                    <h2 className="cw-heading--secondary mb-20">
                      {'Do you want to send regular communications?'}
                    </h2>
                  </div>
                  <div className="cw-section--title mb-30">
                    <p className="mb-20">
                      All sign up forms contain first name, surname, contact
                      information (email, mobile number)
                      <a
                        className="cw-color--primary"
                        href="#brandConsent"
                        onClick={this.toggleBrandConsentModal}
                      >
                        {' '}
                        brand consent
                      </a>{' '}
                      and{' '}
                      <a
                        className="cw-color--primary"
                        href="#unileverConsent"
                        onClick={this.toggleUnileverConsentModal}
                      >
                        Unilever consent
                      </a>
                      . Consumers can alter consent in the{' '}
                      <a
                        className="cw-color--primary"
                        href="#emailPreference"
                        onClick={this.toggleEmailPreferenceModal}
                      >
                        email preference centre
                      </a>
                      .
                    </p>

                    <p className="mb-20">
                      Add a regular communication if you want create an{' '}
                      <a
                        className="cw-color--primary"
                        href="#extraConsent"
                        onClick={this.toggleExtraConsentModal}
                      >
                        extra consent channel
                      </a>{' '}
                      for the consumer. This{' '}
                      <a
                        className="cw-color--primary"
                        href="#additionalPreference"
                        onClick={this.toggleAdditionalPreferenceModal}
                      >
                        additional consent preference
                      </a>{' '}
                      will appear alongside the brand and Unilever consent.
                    </p>

                    <p className="mb-20">
                      Example: an ongoing weekly or monthly communication on a
                      selected topic that is not brand related (e.g. monthly
                      meal planner).
                    </p>

                    <p className="bold-text">
                      Only choose yes if an extra consent channel is required.
                    </p>
                  </div>
                  <form className="cw-form" onSubmit={this._handleSubmit}>
                    {/* Campaign additional optins constent */}
                    <div className="cw-form--legend mb-40">
                      <div className="form-check form-check--container mb-10">
                        <input
                          type="radio"
                          className="form-check-input"
                          id="additionalConsentFlag"
                          ref="additionalConsentFlag"
                          name="additionalConsentFlag"
                          value="true"
                          checked={this.props.additionalConsentFlag || ''}
                          onClick={this._handleInputChange}
                          onChange={this._handleInputChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="additionalConsentFlag"
                        >
                          <p className="cw-heading--tenary mb-5">Yes</p>
                        </label>
                      </div>
                      <div className={additionalConsentClass}>
                        {this.props.additionalOptinList.map((optins, index) => {
                          let addHiddenClass = index !== 0 ? '' : 'cw-hidden';

                          return (
                            <div key={index} className="form-group ml-10">
                              <div className="mb-10">
                                <label htmlFor={'additionalOptinList-' + index}>
                                  <span>
                                    {'Name of regular communication (' +
                                      (index + 1) +
                                      ')'}
                                  </span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name={'additionalOptinList-' + index}
                                  id={'additionalOptinList-' + index}
                                  ref={'additionalOptinList-' + index}
                                  aria-describedby={
                                    'additionalOptinList-' + index
                                  }
                                  placeholder="eg Ladies Football News (appears in preference center)"
                                  onChange={this._handleAdditonalOptins}
                                  value={optins.text || ''}
                                />

                                <span
                                  className={
                                    'cw-icon cw-icon--deleteAnswer ' +
                                    addHiddenClass
                                  }
                                  data-index={index}
                                  onClick={this._deleteConsent}
                                >
                                  <i className="fas fa-trash-alt"></i>
                                </span>
                              </div>

                              <div>
                                <label htmlFor={'additionalOptinList-' + index}>
                                  <span>
                                    {'Frequency of communication (' +
                                      (index + 1) +
                                      ')'}
                                  </span>
                                </label>
                                <Select
                                  className={'cw-select--custom'}
                                  name={'additionalOptinListFrequency-' + index}
                                  label={'frequency'}
                                  placeholder={'Select option'}
                                  options={this.options}
                                  onChange={(selectedOption, index) =>
                                    this._handleAdditonalOptinsFrequency(
                                      selectedOption,
                                      index
                                    )
                                  }
                                  value={
                                    optins.frequency
                                      ? {
                                          label:
                                            optins.frequency
                                              .charAt(0)
                                              .toUpperCase() +
                                            optins.frequency.slice(1),
                                          value: optins.frequency
                                        }
                                      : null
                                  }
                                />
                                <span
                                  className="cw-error cw-hidden"
                                  data-control={
                                    'additionalOptinListFrequency-' + index
                                  }
                                >
                                  <i className="fas fa-exclamation-triangle mr-10"></i>
                                  Please enter frequency for this opt-in.
                                </span>

                                <span
                                  className={
                                    'cw-icon cw-icon--deleteAnswer ' +
                                    addHiddenClass
                                  }
                                  data-index={index}
                                  onClick={this._deleteConsent}
                                >
                                  <i className="fas fa-trash-alt"></i>
                                </span>
                              </div>
                            </div>
                          );
                        })}
                        <span className={addMoreOptionClass}>
                          <a
                            href="#guideToWritingConsent"
                            ref={this.addmoreoption}
                            onClick={this._addConsent}
                          >
                            <i
                              value="addAdditionalConsent"
                              className="fas fa-plus-circle"
                            ></i>{' '}
                            Add additional regular communication
                          </a>
                        </span>
                      </div>

                      <div className="form-check">
                        <input
                          type="radio"
                          className="form-check-input"
                          id="additionalConsentFlag-no"
                          name="additionalConsentFlag"
                          value="false"
                          onChange={this._handleInputChange}
                          checked={!this.props.additionalConsentFlag}
                          onClick={this._handleInputChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="additionalConsentFlag-no"
                        >
                          <p className="cw-heading--tenary mb-5">No</p>
                        </label>
                      </div>
                    </div>
                    <div className="cw-section--title mb-20 cw-hidden">
                      <p className="cw-heading--tertiary">
                        Select consent channel
                      </p>
                    </div>

                    <div className="cw-section--title mb-30 cw-hidden">
                      <p>
                        Choose your method of marketing communication . This
                        channel applies to both brand and Unilever and
                        additional consent.
                      </p>
                    </div>
                    <div className="form-check cw-hidden">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value={this.props.emailChannel ? 'true' : 'false'}
                        id="emailChannel"
                        ref="emailChannel"
                        name="emailChannel"
                        checked={this.props.emailChannel || ''}
                        onChange={this._handleInputChange}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="emailChannel"
                      >
                        Email
                      </label>
                    </div>
                    <div className="form-check mb-50 cw-hidden">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value={this.props.smsChannel ? 'true' : 'false'}
                        id="smsChannel"
                        ref="smsChannel"
                        name="smsChannel"
                        checked={this.props.smsChannel || ''}
                        onChange={this._handleInputChange}
                      />
                      <label className="form-check-label" htmlFor="smsChannel">
                        SMS
                      </label>
                    </div>
                    <div className="cw-form--action-cta mt-50">
                      {this.props.approverEditCampaign ? (
                        <>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={this._handleSubmit}
                          >
                            Save
                          </button>
                          <a
                            href="#"
                            className="cw-save-to-draft mt-25"
                            onClick={this._cancelApproverEdit}
                          >
                            Cancel
                          </a>
                        </>
                      ) : (
                        <>
                          <button
                            type="button"
                            className="btn btn btn-outline-secondary mr-3"
                            onClick={this._handlebackButton}
                          >
                            Back
                          </button>
                          <button
                            type="submit"
                            className="btn btn-primary"
                            onClick={this._handleSubmit}
                          >
                            Next
                          </button>
                          <a
                            href="javascript:void(0)"
                            className="cw-save-to-draft mt-40"
                            onClick={event => this._handleSubmit(event, true)}
                          >
                            Save and exit
                          </a>
                        </>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <Modal
              isOpen={this.state.modalIsOpen}
              onRequestClose={this._closeModal}
              className="cw-modal cw-modal--questions"
              contentLabel="Question Types"
              style={{
                overlay: {
                  backgroundColor: constant.EMAIL_OVERLAY_BACKGROUND
                }
              }}
            >
              <button
                className="cw-modal--close"
                title="Close"
                onClick={this._closeModal}
              >
                <i className="fas fa-times"></i>
              </button>
              <h3 className="cw-heading--primary mb-10">
                Brand and Unilever consent opt-ins
              </h3>
              <div className="col-sm-12">
                <div className="row cw-bgpattern-primary">
                  <div className="col-sm-4">
                    <strong>Brand opt in</strong>
                  </div>
                  <div className="col-sm-8">
                    <span>
                      Please tick the box to receive exciting news, offers and
                      competitions about (brand name) by email.
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-4">
                    <strong>Unilever opt in</strong>
                  </div>
                  <div className="col-sm-8">
                    <span>
                      Please tick the box to receive exciting news, offers and
                      competitions from other Unilever brands by email.
                    </span>
                  </div>
                </div>
              </div>
            </Modal>

            <Modal
              isOpen={this.state.consentModalIsOpen}
              onRequestClose={this._closeConsentModal}
              className="cw-modal cw-modal--questions"
              contentLabel="Question Types"
              style={{
                overlay: {
                  backgroundColor: constant.EMAIL_OVERLAY_BACKGROUND
                }
              }}
            >
              <button
                className="cw-modal--close"
                title="Close"
                onClick={this._closeConsentModal}
              >
                <i className="fas fa-times"></i>
              </button>
              <h3 className="cw-heading--primary mb-10">
                How to a write consent opt-ins
              </h3>
              <div className="mt-15">
                <strong>Clear Affirmative action</strong>
                <ul className="ml-20">
                  <li className="ml-20 cw-consent-modal-list">
                    <span className="cw-consent-modal-text">
                      Make sure you ask people to actively opt-in
                    </span>
                  </li>
                  <li className="ml-20 cw-consent-modal-list">
                    <span className="cw-consent-modal-text">
                      Don't use opt-out boxes, pre-ticked boxes or default
                      settings
                    </span>
                  </li>
                </ul>
              </div>
              <div className="mt-15">
                <strong>It must be clearly distinguishable</strong>
                <ul className="ml-20">
                  <li className="ml-20 cw-consent-modal-list">
                    <span className="cw-consent-modal-text">
                      Make sure to use plain language
                    </span>
                  </li>
                  <li className="ml-20 cw-consent-modal-list">
                    <span className="cw-consent-modal-text">
                      Keep your consent request separate from other terms and
                      conditions
                    </span>
                  </li>
                </ul>
              </div>
              <div className="mt-15">
                <strong>It must be informed</strong>
                <ul className="ml-20">
                  <li className="ml-20 cw-consent-modal-list">
                    <span className="cw-consent-modal-text">
                      Include the name of the Unilever organisation collecting
                      the personal data
                    </span>
                  </li>
                  <li className="ml-20 cw-consent-modal-list">
                    <span className="cw-consent-modal-text">
                      Explain why you want the data and what you will do with
                      it.
                    </span>
                  </li>
                  <li className="ml-20 cw-consent-modal-list">
                    <span className="cw-consent-modal-text">
                      Only collect data that you need
                    </span>
                  </li>
                </ul>
              </div>
              <div className="mt-15">
                <strong>It must be easily withdrawn</strong>
                <ul className="ml-20">
                  <li className="ml-20 cw-consent-modal-list">
                    <span className="cw-consent-modal-text">
                      Make it as easy for consumers to withdraw consent as it
                      was to give it (e.g. include an unsubscribe buttonin your
                      emails)
                    </span>
                  </li>
                  <li className="ml-20 cw-consent-modal-list">
                    <span className="cw-consent-modal-text">
                      {' '}
                      Make sure that your database records that an individual
                      has unsubscribed
                    </span>
                  </li>
                </ul>
              </div>
            </Modal>
            <ModalWindow
              className="w-44"
              isOpen={this.state.openBrandConsentModal}
              onRequestClose={this.toggleBrandConsentModal}
              heading={'Brand consent'}
              description={brandConsentDescription}
            />
            <ModalWindow
              className="w-44"
              isOpen={this.state.openUnileverConsentModal}
              onRequestClose={this.toggleUnileverConsentModal}
              heading={'Unilever consent'}
              description={unileverConsentDescription}
            />
            <ModalWindow
              className="w-44"
              isOpen={this.state.openExtraConsentModal}
              onRequestClose={this.toggleExtraConsentModal}
              heading={'Extra consent'}
              description={extraConsentDescription}
            />
            <ModalWindow
              className="w-44"
              isOpen={this.state.openEmailPreferenceModal}
              onRequestClose={this.toggleEmailPreferenceModal}
              heading={'Standard preference centre'}
              description={emailPreferenceDescription}
            >
              <img
                src={standardPreference}
                width="100%"
                alt="ticked checkboxes showing user consent for receiving emails from Brand, Unilever brands and services"
              />
            </ModalWindow>
            <ModalWindow
              className="w-44"
              isOpen={this.state.openAdditionalPreferenceModal}
              onRequestClose={this.toggleAdditionalPreferenceModal}
              heading={'Additional preference centre'}
              description={additionalPreferenceDescription}
            >
              <img
                src={additionalPreference}
                width="100%"
                alt="ticked checkbox showing user consent to receive regular communications from Brand"
              />
            </ModalWindow>
          </div>
        )}
      </div>
    );
  }

  toggleBrandConsentModal = () => {
    this.setState({ openBrandConsentModal: !this.state.openBrandConsentModal });
  };

  toggleUnileverConsentModal = () => {
    this.setState({
      openUnileverConsentModal: !this.state.openUnileverConsentModal
    });
  };

  toggleExtraConsentModal = () => {
    this.setState({ openExtraConsentModal: !this.state.openExtraConsentModal });
  };

  toggleEmailPreferenceModal = () => {
    this.setState({
      openEmailPreferenceModal: !this.state.openEmailPreferenceModal
    });
  };

  toggleAdditionalPreferenceModal = () => {
    this.setState({
      openAdditionalPreferenceModal: !this.state.openAdditionalPreferenceModal
    });
  };
}

const mapStateToProps = state => ({
  additionalOptinList: state.campaignDetails.additionalOptinList,
  additionalConsentFlag: state.campaignDetails.additionalConsentFlag,
  type: state.campaignDetails.type,
  emailChannel: state.campaignDetails.emailChannel,
  smsChannel: state.campaignDetails.smsChannel,
  approverEditCampaign: state.app.approverEditCampaign,
  pushstate: state.campaignDetails.pushstate,
  dataSaved: state.campaignDetails.dataSaved,
  websiteType:
    state.brandDetails.websiteType && state.brandDetails.websiteType.label,
  editCampaign: state.overview.editCampaign,
  editCampaignDetails: state.app.editCampaignDetails,
  isCampaignEdited: state.campaignDetails.isCampaignEdited,
  websiteType: state.campaignDetails.websiteType,
  campaignType: state.campaignDetails.campaignType,
  campaignLoaded: state.campaignDetails.campaignLoaded,
  isTrackingRequired: state.campaignDetails.isTrackingRequired,
  autogenerate: state.campaignDetails.autogenerate
});

export default connect(mapStateToProps, {
  getCampaignDetails,
  updateCampaignDetails,
  submitCampaignDetails,
  updateAdditionalOptins,
  resetSubmissionState,
  setNotificationMessage,
  setApproverEditCampaignStatus,
  setApproverEditCampaignStatusMessage,
  editedCampaignDetails,
  fetchEditedCampaignLogs,
  saveEditedCampaignLogs
})(ConsentPreferences);
