import React, { forwardRef, useEffect, useState } from 'react';
import classNames from 'classnames';
import { FieldError } from '../../atoms/FieldError';
import { get } from 'lodash';
import { FormTextArea } from '../FormTextArea';

export const EmailRequestInfoTextInput = ({
  name,
  rules,
  register,
  label,
  errors,
  setValue,
  control,
  defaultValue,
  emailType,
  ...props
}) => {
  const errorMessages = get(errors, name);
  const hasError = !!(errors && errorMessages);

  return (
    <div className="form-group " aria-live="polite">
      <label htmlFor={props.id}>{label}</label>
      <div>
        <FormTextArea
          errors={errors}
          name={name}
          className={classNames(`form-control email-request-info-text-area w-100`, {'is-invalid': hasError}) }
          setValue={setValue}
          register={register}
          rows={'5'}
          placeholder="Please describe your issue here"
          defaultValue={defaultValue}
        />
      </div>
    </div>
  );
};
