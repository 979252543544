import axios from 'axios';
import {
  QASORT_GET_CAMPAIGN,
  QASORT_ASYNC_IN_PROGRESS,
  UDPATE_QASORT_DETAILS,
  SUBMITTING_QASORT,
  QASORT_SUCCESS,
  RESET_QNA_SORT_SUBMISSION,
  SAVE_QUESTIONSANSWERS_DATA
} from '../actionTypes';
import constant from '../constants';

export const resetDataSubmittionState = reset => ({
  type: RESET_QNA_SORT_SUBMISSION,
  reset
});

export function fetchCampaignData(Id) {
  return function(dispatch) {
    dispatch({
      type: QASORT_ASYNC_IN_PROGRESS,
      isLoading: true
    });
    // Fetch Campaign Data
    axios
      .get(constant.serviceUrls.CAMPAIGN_DETAILS_SERVICE_URL + '/' + Id)
      .then(response => {
        let campaign = response.data[0];
        dispatch({ type: QASORT_ASYNC_IN_PROGRESS, isLoading: false });
        dispatch({ type: QASORT_GET_CAMPAIGN, campaign });
      });
  };
}

export const updateQuestionDetails = questionDetails => ({
  type: UDPATE_QASORT_DETAILS,
  questionDetails
});

export function submitQuestionsAnswers(campaignId, payload, saveAsDraft) {
  return function(dispatch) {
    dispatch({
      type: QASORT_ASYNC_IN_PROGRESS,
      isLoading: true
    });

    axios
      .post(constant.serviceUrls.CAMPAIGN_DATA_SERVICE_URL, {
        uid: campaignId,
        lastUpdatedScreen: 'questionsanswerssorting',
        questionAnswerList: payload.questionAnswerList,
        questionDisplay: payload.questionDisplay,
        saveAsDraft: saveAsDraft ? true : false
      })
      .then(response => {
        dispatch({ type: QASORT_ASYNC_IN_PROGRESS, isLoading: false });
        if (saveAsDraft) {
          dispatch({
            type: SAVE_QUESTIONSANSWERS_DATA,
            dataSubmission: true
          });
        } else {
          dispatch({
            type: QASORT_SUCCESS,
            dataSubmission: true
          });
        }
      });
  };
}
