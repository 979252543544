import React, { Component } from 'react';
import { connect } from 'react-redux';
import HistoryComponent from '../history';
import { getSmsOverview } from '../../actions/communications/communicationOverview';
import { fetchHistoryLogs } from '../../actions/history';
import { openFeedbackForm } from '../../actions/feedbackForm';
import NotFound from 'components/NotFound';
import SMSSummaryCards from 'components/organisms/EmailSummaryCards/SMSSummaryCards';
import CommunicationsOverviewTables from 'components/organisms/CommunicationsOverviewTables';
import Toolbar from './Toolbar';
import print_icon from '../../images/print_icon.png';
import feedback_icon from '../../images/feedback_icon.png';
import Layout from 'components/organisms/Layout/Layout';
import PageHeader from 'components/organisms/PageHeader/PageHeader';
import MobileDevicePreview from 'components/organisms/MobileDevicePreview';
import { getSMSReviewData } from 'selectors';
import EmailApproverInfo from 'components/organisms/EmailApproverInfo';
import EmailGuidelineText from 'components/organisms/EmailGuidelineText/EmailApproverGuideline';
import Modal from 'react-modal';
import EmailGuideline from 'components/EmailGuideline';
import constants from 'constants/config';
import BackAndNext from 'components/organisms/BackAndNext/BackAndNext';
import EmailRejectModel from 'components/organisms/EmailRejectModel';
import { updateSmsCampaignStatus } from 'actions/communications/communicationCampaignDetails';
import Loader from 'components/Loader';

class CommunicationApproval extends Component {
  state = {
    uid: this.props.match.params.id,
    aprGuideModel: false,
    rejectModelOpen: false
  };
  componentDidMount() {
    this.props.fetchHistoryLogs(this.state.uid);
    this.props.getSmsOverview(this.state.uid);
  }

  continueCampaign = () => {
    this.props.history.push(
      '/campaign-wizard/communications/' +
        this.state.uid +
        '/communicationbrandpage'
    );
  };

  handleOnOpenFeedback = () => {
    this.props.openFeedbackForm({
      campaignUid: this.props.match.params.id,
      userId: this.props.authorizedUserData._id,
      defaultCampaignRequest: 'SMS',
      showCampaignRequest: false
    });
  };

  isOpenAprGuidelineModel = () => {
    this.setState({ aprGuideModel: true });
  };

  isCloseAprGuidelineModel = () => {
    this.setState({ aprGuideModel: false });
  };

  handleRejectClick = () => {
    this.setState({ rejectModelOpen: true });
  };

  closeRejectModelClick = () => {
    this.setState({ rejectModelOpen: false });
  };

  handleOnSubmit = rejectedComment => {
    const paramUid = this.props.match.params.id;
    const dataToSend = {
      uid: paramUid,
      status: '5', // Rejected
      userEmail: this.props.userEmail,
      userName: this.props.userName,
      rejectComments: rejectedComment,
      role: this.props.authorizedUserData.group
    };
    this.props.updateSmsCampaignStatus(dataToSend, (error, result) => {
      if (result) {
        this.props.history.push(`/campaign-wizard/smsdashboard`);
      }
    });
  };

  handleApprovalClick = () => {
    const paramUid = this.props.match.params.id;
    const dataToSend = {
      uid: paramUid,
      status: constants.SMS_STATUS.AWAITING_COST_ESTIMATE,
      userEmail: this.props.userEmail,
      userName: this.props.userName
    };

    this.props.updateSmsCampaignStatus(dataToSend, (error, result) => {
      if (result) {
        const uid = this.props.match.params.id;
        this.props.history.push(`/campaign-wizard/communications/${uid}`);
      }
    });
  };

  render() {
    const { name, status } = this.props.smsDetailsOverview;

    let isDraft = status === '1' ? true : false;

    let mobilePreviewData = getSMSReviewData(this.props.smsDetailsOverview);

    if (this.props.smsServiceError === 404) {
      return <NotFound />;
    } else if (this.props.isLoading) {
      return <Loader />;
    } else if (this.props.smsServiceError === null) {
      return (
        <>
          <div className="skypink ml-5 mr-5">
            <div className="cw-section cw-overview">
              <div className="draft_button_section_main">
                <PageHeader
                  heading={name || 'Untitled'}
                  status={status}
                  subHeading={
                    isDraft ? 'Continue creating your campaign' : undefined
                  }
                  review
                />
                <div className="toolbar-scroll-fix cw-print-hide">
                  {!isDraft ? (
                    <Toolbar>
                      <Toolbar.Item
                        icon={print_icon}
                        name="Print"
                        onClick={() => window.print()}
                      />
                      <Toolbar.Item
                        icon={feedback_icon}
                        name="Feedback"
                        onClick={() => this.handleOnOpenFeedback()}
                      />
                    </Toolbar>
                  ) : null}
                </div>
                <div className="mb-10">
                  <EmailApproverInfo
                    emailBriefing={this.props && this.props.smsDetailsOverview}
                    userData={this.props.authorizedUserData}
                  />
                </div>
                <div className="mb-50">
                  <EmailGuidelineText
                    isOpenGuidelineModel={this.isOpenAprGuidelineModel}
                    titleText={`Please review and approve the SMS campaign using these `}
                    guideLineName={`approval guidelines`}
                  />
                </div>
              </div>

              <Layout
                className="mt-30"
                sidebar={
                  <MobileDevicePreview
                    className="mobile-preview-overview"
                    data={mobilePreviewData}
                    campaign={this.props.smsDetailsOverview}
                    alignHeight={true}
                  />
                }
              >
                <SMSSummaryCards smsCampaign={this.props.smsDetailsOverview} />
                <CommunicationsOverviewTables
                  editable={false}
                  smsCampaign={this.props.smsDetailsOverview}
                />
                <div className="mb-20">
                  <BackAndNext
                    backTitle="Reject"
                    onClickBack={this.handleRejectClick}
                    onClickNext={this.handleApprovalClick}
                    nextTitle="Approve"
                  />
                </div>
                <div className="overview-page-history mt-20">
                  {this.props.historyLogs && this.props.historyLogs.length ? (
                    <HistoryComponent
                      historyLogs={this.props.historyLogs}
                      isEmailBrief={true}
                    />
                  ) : null}
                </div>
              </Layout>
            </div>
            <EmailRejectModel
              modalIsOpen={this.state.rejectModelOpen}
              closeModal={this.closeRejectModelClick}
              handleOnSubmit={this.handleOnSubmit}
            />
            <Modal
              scrollable={true}
              isOpen={this.state.aprGuideModel}
              onRequestClose={this.isCloseAprGuidelineModel}
              className="cw-modal cw-modal--qnadetail"
              contentLabel="Campaign Wizard 2.0"
              style={{
                overlay: {
                  backgroundColor: constants.EMAIL_OVERLAY_BACKGROUND
                }
              }}
            >
              <EmailGuideline
                closeIsEditModal={this.isCloseAprGuidelineModel}
                data={constants.SMS_APPROVER_GUIDE_LINES}
                isEmailCampaign={true}
              />
            </Modal>
          </div>
        </>
      );
    }
  }
}

const mapStateToProps = state => ({
  smsDetailsOverview: state.communicationOverview.smsDetailsOverview,
  isLoading: state.communicationOverview.isLoading,
  historyLogs: state.historyLogs,
  smsServiceError: state.communicationOverview.smsServiceError
});

export default connect(mapStateToProps, {
  getSmsOverview,
  fetchHistoryLogs,
  openFeedbackForm,
  updateSmsCampaignStatus
})(CommunicationApproval);
