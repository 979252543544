import { getDateRange } from '../selectors';
import constant from '../constants';

export const lastUpdatedData = [
  {
    value: getDateRange('today'),
    label: 'Today'
  },
  {
    value: getDateRange('last 7 days'),
    label: 'Last 7 days'
  },
  {
    value: getDateRange('last 14 days'),
    label: 'Last 14 days'
  },
  {
    value: getDateRange('last 30 days'),
    label: 'Last 30 days'
  },
  {
    value: getDateRange('last 60 days'),
    label: 'Last 60 days'
  },
  {
    value: getDateRange('last 90 days'),
    label: 'Last 90 days'
  },
  {
    value: getDateRange('last month'),
    label: 'Last calendar month'
  },
  {
    value: getDateRange('this year'),
    label: 'This year'
  },
  {
    value: getDateRange('past year'),
    label: 'Last calendar year'
  },
  {
    value: 'All time',
    label: 'All time'
  }
];

export const campaignTypes = [
  {
    value: 'Direct messaging',
    incentiveType: null,
    label: 'Simple sign up'
  },
  {
    value: 'Incentive samples',
    incentiveType: 'Product samples',
    label: 'Incentive samples'
  },
  {
    value: 'Incentive competitions',
    incentiveType: 'Competitions',
    label: 'Incentive competitions'
  }
];
// if (constant.featureFlags.ENABLE_MASTER_DATA_ONLY_CAMPAIGN) {
//   campaignTypes.push({
//     value: 'masterdataonly',
//     incentiveType: null,
//     label: 'Master data only '
//   });
// }
