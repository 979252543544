import {
  GET_PREVIEW_URL,
  GET_PREVIEW_ERROR_PAGE,
  PREVIEW_ASYNC_IN_PROGRESS
} from '../actionTypes';

const initialState = {
  asyncInProgress: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case PREVIEW_ASYNC_IN_PROGRESS: {
      let isLoading = { isLoading: action.isLoading };
      return { ...state, ...isLoading };
    }
    case GET_PREVIEW_URL: {
      let previewUrl = {
        previewUrl: action.previewUrl
      };
      return { ...state, ...previewUrl };
    }
    case GET_PREVIEW_ERROR_PAGE: {
      let previewErrorUrl = {
        previewUrl: action.errorPageUrl,
        isLoading: false
      };
      return { ...state, ...previewErrorUrl };
    }
    default:
      return state;
  }
}
