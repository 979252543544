import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { useSelector, useDispatch } from 'react-redux';
import SatisfactoryRatingsInput from './SatisfactoryRatingsInput/index';
import { closeFeedbackForm, submitFeedback } from 'actions/feedbackForm';
import constants from 'constants/config';
const hasErrors = errors => {
  return Object.keys(errors).length > 0;
};

const FeedbackForm = ({ onCloseModal }) => {
  const {
    isOpen,
    campaignUid,
    userId,
    isSubmitting,
    defaultCampaignRequest,
    showCampaignRequest
  } = useSelector(state => state.feedbackForm);
  const [feedback, setFeedback] = useState({
    commentType: 'Suggestion',
    campaignUid,
    feedbackProvider: userId
  });
  const [feedbackErrors, setFeedbackErrors] = useState({});
  const [showCampaignRequestType, setShowCampaignRequestType] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const feedbackObj = {
      ...feedback,
      campaignUid,
      feedbackProvider: userId
    };
    if (defaultCampaignRequest) {
      if (defaultCampaignRequest === 'form') {
        feedbackObj.campaignRequestType = 'Data campaigns (sign up)';
      } else if (defaultCampaignRequest === 'email') {
        feedbackObj.campaignRequestType = 'Email campaigns';
      } else {
        feedbackObj.campaignRequestType = 'SMS campaigns';
      }
    }
    setFeedback(feedbackObj);
  }, [campaignUid, userId, defaultCampaignRequest]);

  useEffect(() => {
    if (showCampaignRequest === undefined) {
      setShowCampaignRequestType(true);
    } else {
      setShowCampaignRequestType(showCampaignRequest);
    }
  }, [showCampaignRequest]);

  const handleValidation = (field, givenValue) => {
    let formErrors = {};
    const validation = {
      campaignRequestType: {
        testFunc: value => (!value ? true : false),
        message: 'This field is mandatory'
      },
      ratings: {
        testFunc: value => (!value ? true : false),
        message: 'This field is mandatory'
      },
      commentType: {
        testFunc: value => (!value ? true : false),
        message: 'This field is mandatory'
      },
      comments: {
        testFunc: value => (!value || !value.trim() ? true : false),
        message: 'Please enter your comments'
      }
    };
    if(field && field === 'comments' && givenValue && givenValue.trim().length){
      const { ['comments']: ommited, ...remainingFormErrors } = formErrors;
      setFeedbackErrors(remainingFormErrors);
    } else if (field && field !== 'comments') {
      formErrors = { ...feedbackErrors };
      const valueToCheck =
        typeof givenValue === 'string' ? givenValue : feedback[field];
    
      if (validation[field].testFunc(givenValue || valueToCheck)) {
        formErrors[field] = validation[field].message;
        setFeedbackErrors(formErrors);
        return hasErrors(formErrors);
      } else {
        if(field !== 'comments' && field ==='ratings' && givenValue >'3'){
          const { ['comments']: ommited, ...remainingFormErrors } = formErrors;
          setFeedbackErrors(remainingFormErrors);
        }else {
          const { [field]: ommited, ...remainingFormErrors } = formErrors;
          setFeedbackErrors(remainingFormErrors);
          return hasErrors(formErrors);
        }
      }
    } else {
      Object.keys(validation).forEach(key => {
        if (key !== 'comments' && validation[key].testFunc(feedback[key])) {
          formErrors[key] = validation[key].message;
        }
        const commentKey = 'comments'
        if(key !== 'comments' &&key ==='ratings' && feedback.ratings <='3' && (!feedback[commentKey] || feedback[commentKey].trim().length ===0)){
          document
            .getElementById(`comments`)
            .classList.contains("cw-error-focus") ||
            document.getElementById(`${commentKey}`).classList.add("cw-error-focus");
            formErrors[commentKey] = validation[commentKey].message;
        } else {
          key !== 'comments' && key ==='ratings' && document
          .getElementById(`${commentKey}`) &&
          document
          .getElementById(`${commentKey}`)
          .classList.contains("cw-error-focus") &&
          document.getElementById(`${commentKey}`).classList.remove("cw-error-focus");
        }
      });
      setFeedbackErrors(formErrors);
      return hasErrors(formErrors);
    }
  };

  const handleOnChange = event => {
    if (event.target) {
      setFeedback({
        ...feedback,
        [event.target.name]: event.target.value
      });
      const commentKey = 'comments'
      const field = event.target.name;
      const fieldValue = event.target.value
      if(field !== 'comments' && field ==='ratings' && fieldValue >'3'){
        field !== 'comments' && field ==='ratings' && document
        .getElementById(`${commentKey}`) &&
        document
        .getElementById(`${commentKey}`)
        .classList.contains("cw-error-focus") &&
        document.getElementById(`${commentKey}`).classList.remove("cw-error-focus"); 
      } 
      handleValidation(event.target.name, event.target.value);
    }
  };

  const handleBlur = event => {
    if (event.target) {
      handleValidation(event.target.name);
    }
  };

  const handleSubmit = event => {
    event.preventDefault();
    if (handleValidation()) {
      return false;
    }
    dispatch(
      submitFeedback(feedback, (error, result) => {
        if (error) {
          setFeedbackErrors({
            formError: 'Oops! Something went wrong. Please try again later.'
          });
        } else {
          setFeedback({
            ...feedback,
            comments: undefined,
            ratings: undefined,
            commentType: 'Suggestion'
          });
        }
      })
    );
  };

  const handleCloseForm = () => {
    setFeedbackErrors({});
    setFeedback({
      comments: undefined,
      ratings: undefined,
      commentType: 'Suggestion',
      campaignUid: undefined,
      feedbackProvider: undefined
    });
    setShowCampaignRequestType(true);
    dispatch(closeFeedbackForm());
  };

  Modal.setAppElement('#root');

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCloseModal}
      className="cw-modal cw-modal--feedbackform"
      contentLabel="CW Feedback form"
      style={{
        overlay: {
          backgroundColor: constants.EMAIL_OVERLAY_BACKGROUND
        }
      }}
    >
      <div>
        <button
          className="cw-modal--close"
          title="Close"
          onClick={handleCloseForm}
        >
          <i className="fas fa-times"></i>
        </button>
        <h2 className="cw-heading--primary mb-20">
          <strong>Feedback form</strong>
        </h2>
        <form id="feedbackForm" onSubmit={handleSubmit}>
          <div>
            {hasErrors(feedbackErrors) && (
              <div className="alert alert-danger" role="alert">
                <strong>Please fix the errors to proceed.</strong>
              </div>
            )}

            <div className="mb-10">
              <p className="mb-10">
                We would love to hear about your experience using Campaign
                Wizard
              </p>
            </div>
            <div className="form-group mb-20">
              <label>Rate your experience</label>
              <SatisfactoryRatingsInput
                name="ratings"
                onChange={handleOnChange}
                value={feedback.ratings}
                onBlur={handleBlur}
              />
              {feedbackErrors.ratings && (
                <span className="cw-error" data-control="ratings">
                  <i className="fas fa-exclamation-triangle"></i>{' '}
                  {feedbackErrors.ratings}
                </span>
              )}
            </div>
            <div className="form-group">
              <label>
                Please provide comments so we can action on your input
              </label>

              <label
                className={`btn btn-outline-primary ${feedback.commentType ===
                  'Suggestion' && 'cw-btn-active'}`}
              >
                <input
                  type="radio"
                  name="commentType"
                  id="suggestion"
                  value="Suggestion"
                  autoComplete="off"
                  checked={feedback.commentType === 'Suggestion'}
                  onChange={handleOnChange}
                />{' '}
                Suggestion
              </label>
              <label
                className={`btn btn-outline-primary ${feedback.commentType ===
                  'Compliment' && 'cw-btn-active'} ml-20`}
              >
                <input
                  type="radio"
                  name="commentType"
                  id="compliment"
                  value="Compliment"
                  autoComplete="off"
                  checked={feedback.commentType === 'Compliment'}
                  onChange={handleOnChange}
                />{' '}
                Compliment
              </label>
              <label
                className={`btn btn-outline-primary ${feedback.commentType ===
                  'Complaint' && 'cw-btn-active'} ml-20`}
              >
                <input
                  type="radio"
                  name="commentType"
                  id="complaint"
                  value="Complaint"
                  autoComplete="off"
                  checked={feedback.commentType === 'Complaint'}
                  onChange={handleOnChange}
                />{' '}
                Complaint
              </label>
              <label
                className={`btn btn-outline-primary ${feedback.commentType ===
                  'New feature request' && 'cw-btn-active'} ml-20`}
              >
                <input
                  type="radio"
                  name="commentType"
                  id="newFeatureRequest"
                  value="New feature request"
                  autoComplete="off"
                  checked={feedback.commentType === 'New feature request'}
                  onChange={handleOnChange}
                />{' '}
                New request
              </label>
            </div>
            <div className="form-group">
              <textarea
                className="form-control"
                name="comments"
                id="comments"
                rows="3"
                maxLength="500"
                data-required="true"
                onChange={handleOnChange}
                value={feedback.comments || ''}
                onBlur={handleBlur}
              ></textarea>
              {feedbackErrors.comments && (
                <span className="cw-error" data-control="comments">
                  <i className="fas fa-exclamation-triangle mr-10"></i>{' '}
                  {feedbackErrors.comments}
                </span>
              )}
            </div>
            {showCampaignRequestType && (
              <div className="form-group mb-20">
                <label>What are you sharing the feedback for?</label>
                <div className="form-check">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="signUpFormRequests"
                    name="campaignRequestType"
                    value={'Data campaigns (sign up)'}
                    checked={
                      feedback.campaignRequestType ===
                      'Data campaigns (sign up)'
                    }
                    onChange={handleOnChange}
                    onBlur={handleBlur}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="signUpFormRequests"
                  >
                    Data campaigns &#40;sign up&#41;
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="smsCampaignRequests"
                    name="campaignRequestType"
                    value={'SMS campaigns'}
                    checked={feedback.campaignRequestType === 'SMS campaigns'}
                    onChange={handleOnChange}
                    onBlur={handleBlur}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="smsCampaignRequests"
                  >
                    SMS campaigns
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="emailCampaigns"
                    name="campaignRequestType"
                    value={'Email campaigns'}
                    checked={feedback.campaignRequestType === 'Email campaigns'}
                    onChange={handleOnChange}
                    onBlur={handleBlur}
                  />
                  <label className="form-check-label" htmlFor="emailCampaigns">
                    Email campaigns
                  </label>
                </div>
                {feedbackErrors.campaignRequestType && (
                  <span className="cw-error" data-control="campaignRequestType">
                    {feedbackErrors.campaignRequestType}
                  </span>
                )}
              </div>
            )}
          </div>
          <br />
          <div className="mr-20">
            <button
              type="button"
              onClick={handleCloseForm}
              className="btn btn-outline-primary mb-10 cw-print-hide mr-3"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-primary mb-10 cw-print-hide text-nowrap"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <span>
                  <i className="fa fa-spinner fa-spin" /> Loading
                </span>
              ) : (
                'Submit'
              )}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default FeedbackForm;
