import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import Alert from '../Alert';
import constant from '../../constants';
import BreadCrumb from '../BreadCrumb';
import axios from 'axios';
import { fetchCampaignData } from '../../actions/overview';
import { setNotificationMessage } from '../../actions/dashboard';
import {
  setApproverEditCampaignStatus,
  setApproverEditCampaignStatusMessage
} from '../../actions/app';
import { epsilonMarket } from '../../selectors';
import { getGenericFieldData } from '../../actions/saasFormAuthoring';
import CampaignCancelModel from '../CampaignCancelModel';
import { cancelCampaignChanges } from '../../actions/overview';
import Modal from 'react-modal';

class SaasGenericFormField extends Component {
  constructor(props) {
    super(props);
    this.boolean = {
      true: true,
      false: false
    };
    this.state = {
      submitCtaLabel: '',
      failureMsg: '',
      expiryMsg: '',
      mandatoryInd: '',
      campaignId: this.props.match.params.id,
      matchProps: this.props.match,
      isCancelModalOpen: false
    };
    this._openIsCancelModal = this._openIsCancelModal.bind(this);
    this._closeIsCancelModal = this._closeIsCancelModal.bind(this);
    this._cancelSaasCampaign = this._cancelSaasCampaign.bind(this);
  }

  componentDidMount() {
    this.campaignId = this.props.match.params.id;
    //this.props.getAuthoringFieldData();
    this.language = this.props.campaign.language
      ? this.props.campaign.language.code
      : '';
    this.props.getGenericFieldData(this.language);
    this.props.fetchCampaignData(this.campaignId);
    this.setState(state => {
      return { ...state, ...{ campaignId: this.campaignId } };
    });

    axios
      .get(
        constant.serviceUrls.CAMPAIGN_DETAILS_SERVICE_URL +
          '/' +
          this.state.campaignId
      )
      .then(response => {
        let campaign = response.data[0];
        this.status = campaign && campaign.status;
        let messages =
          campaign &&
          campaign.campaignDetail &&
          campaign.campaignDetail.messages;

        if (messages) {
          this.setState({
            submitCtaLabel:
              messages && messages.submit ? messages && messages.submit : '',
            failureMsg:
              messages && messages.submissionError
                ? messages && messages.submissionError
                : '',
            expiryMsg:
              messages && messages.campaignExpiry
                ? messages && messages.campaignExpiry
                : '',
            mandatoryInd:
              messages && messages.mandatoryFieldsIndicator
                ? messages && messages.mandatoryFieldsIndicator
                : ''
          });
        } else {
          if (
            this.props &&
            this.props.formGenericData !== undefined &&
            this.props.formGenericData &&
            this.props.formGenericData.genericData
          ) {
            this.setState({
              submitCtaLabel:
                this.props.formGenericData &&
                this.props.formGenericData.genericData &&
                this.props.formGenericData.genericData[0].translatedLabel,
              failureMsg:
                this.props.formGenericData &&
                this.props.formGenericData.genericData &&
                this.props.formGenericData.genericData[1].translatedLabel,
              expiryMsg:
                this.props.formGenericData &&
                this.props.formGenericData.genericData &&
                this.props.formGenericData.genericData[2].translatedLabel,
              mandatoryInd:
                this.props.formGenericData &&
                this.props.formGenericData.genericData &&
                this.props.formGenericData.genericData[3] &&
                this.props.formGenericData.genericData[3].translatedLabel
            });
          } else {
            this.setState({
              submitCtaLabel: constant.messagesFields[0].translatedLabel,
              failureMsg: constant.messagesFields[1].translatedLabel,
              expiryMsg: constant.messagesFields[2].translatedLabel,
              mandatoryInd: constant.messagesFields[3].translatedLabel
            });
          }
        }
      });
  }

  _openIsCancelModal(event) {
    event.preventDefault();
    this.setState({
      isCancelModalOpen: true
    });
  }

  _closeIsCancelModal() {
    this.setState({
      isCancelModalOpen: false
    });
  }

  _cancelSaasCampaign(event) {
    event.preventDefault();
    this.setState({
      isCancelModalOpen: false
    });
    this.props.cancelCampaignChanges(this.state.campaignId);
    this.props.history.push(
      '/campaign-wizard/campaign/' + this.state.campaignId
    );
  }

  _handlebackButton = event => {
    event.preventDefault();
    this.props.history.push(
      '/campaign-wizard/campaign/' +
        this.state.campaignId +
        '/saas-consent-compliance'
    );
  };
  _validateSubmitCtaLabel = () => {
    if (document.getElementById('submitCtaLabel').value.trim().length === 0) {
      if (
        document
          .querySelector("[data-control='submitCtaLabel']")
          .classList.contains('cw-hidden')
      ) {
        document
          .querySelector("[data-control='submitCtaLabel']")
          .classList.remove('cw-hidden');

        document
          .getElementById(`submitCtaLabel`)
          .classList.contains('cw-error-focus') ||
          document
            .getElementById(`submitCtaLabel`)
            .classList.add('cw-error-focus');
      }
    } else {
      if (
        !document
          .querySelector("[data-control='submitCtaLabel']")
          .classList.contains('cw-hidden')
      ) {
        document
          .querySelector("[data-control='submitCtaLabel']")
          .classList.add('cw-hidden');

        document
          .getElementById(`submitCtaLabel`)
          .classList.contains('cw-error-focus') &&
          document
            .getElementById(`submitCtaLabel`)
            .classList.add('cw-error-focus');
      }
    }
  };
  _validateFailureMsg = () => {
    if (document.getElementById('failureMsg').value.trim().length === 0) {
      if (
        document
          .querySelector("[data-control='failureMsg']")
          .classList.contains('cw-hidden')
      ) {
        document
          .querySelector("[data-control='failureMsg']")
          .classList.remove('cw-hidden');

        document
          .getElementById(`failureMsg`)
          .classList.contains('cw-error-focus') ||
          document.getElementById(`failureMsg`).classList.add('cw-error-focus');
      }
    } else {
      if (
        !document
          .querySelector("[data-control='failureMsg']")
          .classList.contains('cw-hidden')
      ) {
        document
          .querySelector("[data-control='failureMsg']")
          .classList.add('cw-hidden');

        document
          .getElementById(`failureMsg`)
          .classList.contains('cw-error-focus') &&
          document
            .getElementById(`failureMsg`)
            .classList.remove('cw-error-focus');
      }
    }
  };
  _validateExpiryMsg = () => {
    if (document.getElementById('expiryMsg').value.trim().length === 0) {
      if (
        document
          .querySelector("[data-control='expiryMsg']")
          .classList.contains('cw-hidden')
      ) {
        document
          .querySelector("[data-control='expiryMsg']")
          .classList.remove('cw-hidden');

        document
          .getElementById(`expiryMsg`)
          .classList.contains('cw-error-focus') ||
          document.getElementById(`expiryMsg`).classList.add('cw-error-focus');
      }
    } else {
      if (
        !document
          .querySelector("[data-control='expiryMsg']")
          .classList.contains('cw-hidden')
      ) {
        document
          .querySelector("[data-control='expiryMsg']")
          .classList.add('cw-hidden');

        document
          .getElementById(`expiryMsg`)
          .classList.contains('cw-error-focus') &&
          document
            .getElementById(`expiryMsg`)
            .classList.remove('cw-error-focus');
      }
    }
  };

  _validateMandatoryIndText = () => {
    if (document.getElementById('mandatoryInd').value.trim().length === 0) {
      if (
        document
          .querySelector("[data-control='mandatoryInd']")
          .classList.contains('cw-hidden')
      ) {
        document
          .querySelector("[data-control='mandatoryInd']")
          .classList.remove('cw-hidden');

        document
          .getElementById(`mandatoryInd`)
          .classList.contains('cw-error-focus') ||
          document
            .getElementById(`mandatoryInd`)
            .classList.add('cw-error-focus');
      }
    } else {
      if (
        !document
          .querySelector("[data-control='mandatoryInd']")
          .classList.contains('cw-hidden')
      ) {
        document
          .querySelector("[data-control='mandatoryInd']")
          .classList.add('cw-hidden');

        document
          .getElementById(`mandatoryInd`)
          .classList.contains('cw-error-focus') &&
          document
            .getElementById(`mandatoryInd`)
            .classList.remove('cw-error-focus');
      }
    }
  };
  _handleSubmitCtaLabel = event => {
    let ctaLabel = event.target.value;
    this.setState({ submitCtaLabel: ctaLabel }, () => {
      this._validateSubmitCtaLabel();
    });
  };
  _handleFailureMsg = event => {
    let failMsg = event.target.value;
    this.setState({ failureMsg: failMsg }, () => {
      this._validateFailureMsg();
    });
  };
  _handleExpiryMsg = event => {
    let expMsg = event.target.value;
    this.setState({ expiryMsg: expMsg }, () => {
      this._validateExpiryMsg();
    });
  };

  _handleMandatoryIndText = event => {
    let mandatoryIndText = event.target.value;
    this.setState({ mandatoryInd: mandatoryIndText }, () => {
      this._validateMandatoryIndText();
    });
  };

  _handleCancel = () => {
    this.props.setApproverEditCampaignStatus(false);
    this.props.setApproverEditCampaignStatusMessage('');
    this.props.history.push(
      '/campaign-wizard/campaign/' + this.campaignId + '/approval'
    );
  };
  _handleSubmit = async (event, saveAsDraft) => {
    let { campaign } = this.props;
    event.preventDefault();

    if (!saveAsDraft) {
      this._validateSubmitCtaLabel();
      this._validateFailureMsg();
      this._validateExpiryMsg();
      this._validateMandatoryIndText();
    } else {
      this.isValid = true;
    }
    if (document.querySelector('.cw-error:not(.cw-hidden)') === null) {
      let updatedFormData = {};
      let messagesData = {};
      messagesData['submit'] = this.state.submitCtaLabel
        ? this.state.submitCtaLabel.trim()
        : this.props.formGenericData &&
          this.props.formGenericData.genericData &&
          this.props.formGenericData.genericData[0].translatedLabel;
      messagesData['submissionError'] = this.state.failureMsg
        ? this.state.failureMsg.trim()
        : this.props.formGenericData &&
          this.props.formGenericData.genericData &&
          this.props.formGenericData.genericData[1].translatedLabel;
      messagesData['campaignExpiry'] = this.state.expiryMsg
        ? this.state.expiryMsg.trim()
        : this.props.formGenericData &&
          this.props.formGenericData.genericData &&
          this.props.formGenericData.genericData[2].translatedLabel;
      messagesData['mandatoryFieldsIndicator'] = this.state.mandatoryInd
        ? this.state.mandatoryInd.trim()
        : this.props.formGenericData.genericData &&
          this.props.formGenericData.genericData[3].translatedLabel;
      updatedFormData['uid'] = this.state.campaignId;
      updatedFormData['messages'] = messagesData;
      updatedFormData['saveAsDraft'] = saveAsDraft;
      if (!saveAsDraft) {
        updatedFormData['isEdited'] = this.props.campaign.isEdited;
      }
      axios
        .post(constant.serviceUrls.CAMPAIGN_DATA_SERVICE_URL, updatedFormData)
        .then(response => {
          if (response) {
            if (saveAsDraft) {
              let notificationData = {};
              notificationData.message = constant.CAMPAIGN_SAVED_AS_DRAFT_TEXT;
              notificationData.link = '';
              notificationData.type = 'warning';
              notificationData.campaign = '';

              this.props.setNotificationMessage(
                notificationData.message,
                notificationData.link,
                notificationData.type,
                notificationData.campaign
              );
              this.props.history.push('/campaign-wizard/dashboard');
            } else {
              if (this.status === '6') {
                this.props.setApproverEditCampaignStatus(true);
                this.props.setApproverEditCampaignStatusMessage('success');
                this.props.history.push(
                  '/campaign-wizard/campaign/' +
                    this.state.campaignId +
                    '/approval'
                );
              } else {
                this.props.history.push(
                  '/campaign-wizard/campaign/' +
                    this.state.campaignId +
                    '/saas-sorting-page'
                );
              }
            }
          }
        });
    }
  };

  render() {
    let { campaignType, campaign } = this.props;
    let isEdited = campaign && campaign.isEdited;
    let countrySelected = campaign.country && campaign.country.code;
    return (
      <div className="col-sm-12">
        <ReactTooltip
          place="right"
          type="info"
          multiline={true}
          className="cw-tooltip"
        />
        <Modal
          scrollable={true}
          isOpen={this.state.isCancelModalOpen}
          onRequestClose={this._closeIsCancelModal}
          className="cw-modal cw-modal--branddetail"
          contentLabel="Campaign Wizard 2.0"
          style={{
            overlay: {
              backgroundColor: constant.EMAIL_OVERLAY_BACKGROUND
            }
          }}
        >
          <CampaignCancelModel
            closeIsCancelModal={this._closeIsCancelModal}
            isCancelContinue={this._cancelSaasCampaign}
          />
        </Modal>
        <div className="cw-section ml-20">
          {this.status !== '6' ? (
            <BreadCrumb
              match={this.state.matchProps}
              // editCampaign={
              //   this.props.editCampaign || this.props.isCampaignEdited
              // }
              hideBreadCrumb={this.props.approverEditCampaign || isEdited}
              campaignType={
                this.props.campaign && this.props.campaign.campaignType
              }
              websiteType={
                this.props.campaign &&
                this.props.campaign.websiteType &&
                this.props.campaign.websiteType.name
              }
              formType={this.props.campaign && this.props.campaign.type}
              isEpsilonMarket={epsilonMarket(countrySelected)}
            />
          ) : null}

          <div className="cw-section--title mb-20">
            <h2 className="cw-heading--secondary mb-20">
              Configure generic form fields
            </h2>

            <div className="row">
              <div className="col-sm-8">
                <div className="cw-section--title mb-20">
                  {/* <div className="cw-text--quinary"> */}
                  <div className="form-group" style={{ width: '85%' }}>
                    <Alert
                      alertType="saasUpdateMessage"
                      textMessage={constant.SAAS_MESSAGES.genericFieldsInfo}
                    />
                  </div>
                  <p className="cw-heading--saascampaign">
                    Submission Experience
                  </p>
                </div>

                <form
                  className="cw-form"
                  style={{ width: '85%' }}
                  onSubmit={this._handleSubmit}
                >
                  <div className="form-group">
                    <label htmlFor="submitCtaLabel">
                      <span>Submit Button Label</span>
                      <span
                        className="float-right  cw-icon cw-icon--help"
                        data-tip="Creating a call to action will allow users to click through. Make sure this label is welcoming for your users who fill up the form."
                      >
                        <i className="fas fa-question-circle"></i>
                      </span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="submitCtaLabel"
                      id="submitCtaLabel"
                      maxLength="50"
                      ref="submitCtaLabel"
                      aria-describedby="submitsubmitCtaLabel"
                      data-required="true"
                      value={this.state.submitCtaLabel}
                      onChange={this._handleSubmitCtaLabel}
                    />
                    <span
                      className="cw-error cw-hidden"
                      data-control="submitCtaLabel"
                    >
                      <i className="fas fa-exclamation-triangle mr-10"></i>
                      Please enter the submit button label.
                    </span>
                  </div>
                  <div className="form-group">
                    <label htmlFor="failureMsg">
                      <span>Submission Failure Message</span>
                      <span
                        className="float-right  cw-icon cw-icon--help"
                        data-tip="Please specify the failure message you would want users to see if the form fails to submit due to technical issue."
                      >
                        <i className="fas fa-question-circle"></i>
                      </span>
                    </label>
                    <textarea
                      className="form-control"
                      name="failureMsg"
                      id="failureMsg"
                      ref="failureMsg"
                      rows="3"
                      maxLength="500"
                      aria-describedby="submissionfailureMsg"
                      data-required="true"
                      value={this.state.failureMsg}
                      onChange={this._handleFailureMsg}
                    ></textarea>
                    <span
                      className="cw-error cw-hidden"
                      data-control="failureMsg"
                    >
                      <i className="fas fa-exclamation-triangle mr-10"></i>
                      Please enter the submission failure message.
                    </span>
                  </div>
                  <div className="form-group">
                    <label htmlFor="expiryMsg">
                      <span>Campaign Expiry Message</span>
                      <span
                        className="float-right  cw-icon cw-icon--help"
                        data-tip="Please specify the expiry message you would want the users to see when they try to access an expired campaign."
                      >
                        <i className="fas fa-question-circle"></i>
                      </span>
                    </label>
                    <textarea
                      className="form-control"
                      name="expiryMsg"
                      id="expiryMsg"
                      ref="expiryMsg"
                      rows="3"
                      maxLength="500"
                      aria-describedby="campaignExpiryMsg"
                      data-required="true"
                      value={this.state.expiryMsg}
                      onChange={this._handleExpiryMsg}
                    ></textarea>
                    <span
                      className="cw-error cw-hidden"
                      data-control="expiryMsg"
                    >
                      <i className="fas fa-exclamation-triangle mr-10"></i>
                      Please enter the campaign expiry message.
                    </span>
                  </div>
                  <div className="form-group mb-40">
                    <label htmlFor="mandatoryInd">
                      <span>Mandatory Fields Indicator Text</span>
                      <span
                        className="float-right  cw-icon cw-icon--help"
                        data-tip="Please specify the mandatory fields indicator text to be displayed in the form, informing the user about the mandatory fields."
                      >
                        <i className="fas fa-question-circle"></i>
                      </span>
                    </label>
                    <textarea
                      className="form-control"
                      name="mandatoryInd"
                      id="mandatoryInd"
                      ref="mandatoryInd"
                      rows="3"
                      maxLength="500"
                      aria-describedby="MandatoryIndText"
                      data-required="true"
                      value={this.state.mandatoryInd}
                      onChange={this._handleMandatoryIndText}
                    ></textarea>
                    <span
                      className="cw-error cw-hidden"
                      data-control="mandatoryInd"
                    >
                      <i className="fas fa-exclamation-triangle mr-10"></i>
                      Please enter the mandatory fields indicator text.
                    </span>
                  </div>
                  {this.status === '6' ? (
                    <div className="cw-form--action-cta">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        onClick={event => this._handleSubmit(event)}
                      >
                        Save
                      </button>
                      <a
                        href="javascript:void(0)"
                        className="cw-save-to-draft mt-40"
                        onClick={this._handleCancel}
                      >
                        Cancel
                      </a>
                    </div>
                  ) : (
                    <div className="cw-form--action-cta">
                      <button
                        type="button"
                        className="btn btn btn-outline-secondary mr-3"
                        onClick={this._handlebackButton}
                      >
                        Back
                      </button>
                      <button
                        type="submit"
                        className="btn btn-primary"
                        onClick={this._handleSubmit}
                      >
                        Next
                      </button>
                      {campaign && campaign.isEdited ? (
                        <a
                          href="javascript:void(0)"
                          className="cw-cancel-edit mt-40"
                          onClick={event => this._openIsCancelModal(event)}
                        >
                          Cancel changes
                        </a>
                      ) : (
                        <a
                          href="javascript:void(0)"
                          className="cw-save-to-draft mt-40"
                          style={{ width: '40%' }}
                          onClick={event => this._handleSubmit(event, true)}
                        >
                          Save and exit
                        </a>
                      )}
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  name: state.campaignDetails.name,
  language: state.campaignDetails.language,
  campaign: state.overview.campaign,
  formGenericData: state.saasFormAuthoring
});

export default connect(mapStateToProps, {
  fetchCampaignData,
  setNotificationMessage,
  setApproverEditCampaignStatusMessage,
  setApproverEditCampaignStatus,
  getGenericFieldData,
  cancelCampaignChanges
})(SaasGenericFormField);
