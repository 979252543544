import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

const getNotificationDisplayComponent = (messageComp, campaignName, link) => {
  switch (messageComp){
    case 'emailCampaignDraft': 
    return <p>Your campaign <Link to={link} className="email-dash-notification-link">{campaignName}</Link> has been saved as draft.</p>

    default : return null
  }
}

const NotificationMessage = props => {
  return (
    <div
      className={`alert alert-${props.type ? props.type : ''}${props.onClose? ' alert-dismissible':''}${
        props.showcomponent ? '' : ' d-none'
      }`}
      role="alert"
    >
      {props.notificationMessageComp? getNotificationDisplayComponent(props.notificationMessageComp, props.campaignname, props.link) : <p>
        {props.link ? (
          <a
            href={props.link}
            className={`${props.type ? 'alert-' + props.type : ''}`}
          >
            <strong>
              <u>{props.campaignname}</u>
            </strong>
          </a>
        ) : null}
        {props.campaignname && !props.link ? (
          <strong>{props.campaignname}</strong>
        ) : null}

        {props.link || props.campaignname ? (
          props.message ? (
            ' ' + props.message
          ) : (
            ''
          )
        ) : props.message ? (
          <strong>{props.message}</strong>
        ) : (
          ''
        )}
      </p>}
      {props.onClose && (
        <button type="button" className="close alert-close-btn" aria-label="Close" onClick={props.onClose}>
          <span aria-hidden="true">&times;</span>
        </button>
      )}
         
    </div>
  );
};

export default NotificationMessage;
export {getNotificationDisplayComponent}
