import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIdleTimer } from 'react-idle-timer';
import { useMsal } from '@azure/msal-react';
import AppRouter from './routers/AppRouter';
import { getCampaignConfig, routineTask } from './actions/app';
import { getLoggedInUser } from 'utilities/util';
import { logoutUserState } from 'actions/authorization';
import constants from 'constants/config';
import './App.css';
import { msalConfig } from './authConfig';

export default function App({ eventEmitter }) {
  const dispatch = useDispatch();
  const [userIsIdle, setUserIsIdle] = useState(false);
  const { instance } = useMsal();

  const onIdle = () => {
    // logout user
    if (getLoggedInUser(instance)) {
      setUserIsIdle(true);
    }
  };

  const onActive = () => {};

  const onAction = event => {};

  const onMessage = payload => {
    if (payload === 'triggerLogout') {
      dispatch(logoutUserState());
    }
  };

  const { isLastActiveTab, message } = useIdleTimer({
    onIdle,
    onActive,
    onAction,
    onMessage,
    timeout: constants.USER_AUTOLOGOUT_TIMEOUT,
    throttle: 500,
    crossTab: true,
    leaderElection: true,
    syncTimers: 200
  });

  useEffect(() => {
    dispatch(routineTask());
    dispatch(getCampaignConfig());
  }, []);

  const lastActiveTab =
    isLastActiveTab() === null ? 'loading' : isLastActiveTab();

  useEffect(() => {
    if (userIsIdle && lastActiveTab) {
      instance.logoutRedirect({
        account: instance.getActiveAccount(),
        postLogoutRedirectUri: msalConfig.auth.postLogoutRedirectUri
      });
      message('triggerLogout');
    }
  }, [userIsIdle, lastActiveTab]);

  return <AppRouter eventEmitter={eventEmitter} />;
}
