import React from 'react';
import { SelectInput } from '../../atoms/SelectInput';
import { FieldError } from '../../atoms/FieldError';
import { get } from 'lodash';

export const FormSelect = ({
  name,
  rules,
  register,
  label,
  errors,
  ...props
}) => {
  const errorMessages = get(errors, name);
  const hasError = !!(errors && errorMessages);

  return (
    <div className="form-group" aria-live="polite">
      <label htmlFor={props.id}>{label}</label>
      <SelectInput
        name={name}
        {...props}
        {...(register && register(name, rules))}
        hasError={hasError}
      />
      <FieldError errors={errors} name={name} />
    </div>
  );
};
