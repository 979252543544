import {
  EMAIL_OPEN_SAVE_TEMPLATE,
  EMAIL_CLOSE_SAVE_TEMPLATE
} from '../../actionTypes';

export const openSaveEmailTemplate = (templateInfo, templateHtml) =>  ({
  type: EMAIL_OPEN_SAVE_TEMPLATE,
  payload: {isOpen: true, templateInfo, templateHtml}
})

export const closeSaveEmailTemplate = () => ({
  type: EMAIL_CLOSE_SAVE_TEMPLATE,
  payload: { isOpen: false }
});
