import React from 'react';

const ErrorMessage = ({ id, message }) => (
  <span id={id} className="cw-error cw-hidden" data-control="name">
    <i className="fas fa-exclamation-triangle mr-10"></i>
    {message}
  </span>
);

export default ErrorMessage;
