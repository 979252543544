import React from 'react';

const downloadIcon = () => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="25" rx="4" ry="4" fill="#0074BD" />
      <g transform="translate(3 3.5)">
        <path
          d="M15.75 9.75V13.5C15.75 14.1904 15.4871 14.8527 15.0126 15.3271C14.5381 15.8016 13.8757 16.0645 13.1853 16.0645H4.81471C4.12435 16.0645 3.46193 15.8016 2.98745 15.3271C2.51298 14.8527 2.25 14.1904 2.25 13.5V9.75C2.25 9.41848 2.3817 9.10054 2.61612 8.86612C2.85054 8.6317 3.16848 8.5 3.5 8.5C3.83152 8.5 4.14946 8.6317 4.38388 8.86612C4.6183 9.10054 4.75 9.41848 4.75 9.75V12.75H13.25V9.75C13.25 9.41848 13.3817 9.10054 13.6161 8.86612C13.8505 8.6317 14.1685 8.5 14.5 8.5C14.8315 8.5 15.1495 8.6317 15.3839 8.86612C15.6183 9.10054 15.75 9.41848 15.75 9.75ZM8.25 10.5C8.34305 10.5931 8.45194 10.6639 8.57156 10.7083C8.69117 10.7527 8.81942 10.7698 8.94743 10.7583C9.07544 10.7467 9.1998 10.7069 9.31104 10.6417C9.42228 10.5765 9.51833 10.4878 9.59261 10.3824L12.2176 7.75736C12.352 7.62292 12.437 7.44821 12.4526 7.25923C12.4682 7.07025 12.4136 6.88316 12.2996 6.73576C12.1855 6.58836 12.0184 6.4914 11.8338 6.46658C11.6492 6.44176 11.4645 6.49057 11.3224 6.60191L9.75 8.09251V2.25C9.75 2.05109 9.671 1.86032 9.53033 1.71967C9.38968 1.57902 9.19891 1.5 9 1.5C8.80109 1.5 8.61032 1.57902 8.46967 1.71967C8.32902 1.86032 8.25 2.05109 8.25 2.25V8.09251L6.67757 6.60191C6.5355 6.49057 6.35081 6.44176 6.16618 6.46658C5.9816 6.4914 5.81453 6.58836 5.70044 6.73576C5.58634 6.88316 5.53174 7.07025 5.54737 7.25923C5.56301 7.44821 5.64805 7.62292 5.78243 7.75736L8.40739 10.3824C8.48167 10.4878 8.57772 10.5765 8.68896 10.6417C8.8002 10.7069 8.92456 10.7467 9.05257 10.7583C9.18058 10.7698 9.30883 10.7527 9.42844 10.7083C9.54806 10.6639 9.65695 10.5931 9.75 10.5Z"
          fill="#fff"
        />
      </g>
    </svg>
  );
};

export default downloadIcon;
