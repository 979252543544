import {
	FETCH_EXISTING_EMAIL_LIST,
	GET_AUTHOR_ACCESS_EMAILS_BY_CAMPAIGN_ID,
	AUTHORING_PUSH_STATE,
	AUTHORING_DATA_SAVED,
	RESET_AUTHORING_FORM_SUBMISSION,
	AUTHORING_INPROGRESS,
	AUTHORING_CAMPAIGN_DETAILS,
} from '../actionTypes';

const initialState = {
	existingEmailList: [],
	campaignAccessEmails: [],
	campaignType: '',
	type: '',
	dataSaved: false,
	pushstate: false,
	asyncInProgress: false,
};

export default function(state = initialState, action) {
	switch (action.type) {
		case FETCH_EXISTING_EMAIL_LIST: {
			const userData = {
				existingEmailList: action.existingEmailList || [],
			};
			return { ...state, ...userData };
		}
		case GET_AUTHOR_ACCESS_EMAILS_BY_CAMPAIGN_ID: {
			return { ...state, campaignAccessEmails: action.campaignAccessEmails };
		}
		case AUTHORING_PUSH_STATE: {
			let pushstate = { pushstate: action.pushstate };
			return { ...state, ...pushstate };
		}
		case AUTHORING_DATA_SAVED: {
			let savedata = { dataSaved: action.dataSaved };
			return { ...state, ...savedata };
		}
		case RESET_AUTHORING_FORM_SUBMISSION: {
			return { ...state, ...{ pushstate: false } };
		}
		case AUTHORING_INPROGRESS: {
			let asyncInProgress = { asyncInProgress: action.isLoading };
			return { ...state, ...asyncInProgress };
		}
		case AUTHORING_CAMPAIGN_DETAILS: {
			let campaignDetails = { campaignType: action.campaignType, type: action.campaigntype };
			return { ...state, ...campaignDetails };
		}
		default:
			return state;
	}
}
