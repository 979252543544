import React, { useEffect, useState, useMemo } from 'react';
import allCampaign from '../../images/allCampaign.png';
import requesterImage from '../../images/requester.png';
import approverImage from '../../images/approver.png';
import masterDataImage from '../../images/master-data.png';
import brandMarketImage from '../../images/brand-market.png';
import marketImage from '../../images/market.png';
import brandImage from '../../images/brand.png';
import { DashboardCard } from '../organisms/DashboardCard/DashboardCard';
import { CampaignCharts } from '../organisms/CampaignCharts/CampaignCharts';
import { getDashboardMatrix } from '../../actions/dashboard';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import constant from '../../constants';
import { getAuthoringFieldData } from 'actions/saasFormAuthoring';
import ReportSectionHeading from '../atoms/ReportSectionHeading';
import FaqStats from '../molecules/FaqStats';
import FieldStatsAccordion from '../organisms/FieldStatsAccordion';
import DashStatsBox from '../organisms/DashStatsBox';
import StatsBoxTitle from '../molecules/StatsBoxTitle';
import { getChartData, getDateRange } from '../../selectors/index';
import RadioDropDown from '../RadioDropDown';
import constants from '../../constants';
import { get } from 'lodash';
import moment from 'moment';
import ChartSummary from '../organisms/ChartSummary';
const dropDownOptions = [
  { label: 'Monthly', value: 'monthly' },
  { label: 'Weekly', value: 'weekly' },
  { label: 'Quarterly', value: 'quarter' },
  { label: 'Yearly', value: 'annual' }
];

var _startDate = null;

var brandSummaryCard = [
  {
    title: 'Brand market combinations',
    value: 0,
    percentage: '...',
    className: 'cw-dashboard-summary-card-brand-market'
  },
  {
    title: 'Markets',
    value: 0,
    percentage: '...',
    className: 'cw-dashboard-summary-card-markets'
  },
  {
    title: 'Brands',
    value: 0,
    percentage: '...',
    className: 'cw-dashboard-summary-card-brands'
  },
  {
    title: 'Languages',
    value: 0,
    percentage: '...',
    className: 'cw-dashboard-summary-card-languages'
  }
];

const BrandSummary = props => {
  const [topBrandCheck, setTopBrandCheck] = useState(false);
  const [topMarketCheck, setTopMarketCheck] = useState(false);

  let campaignMatrix = props.campaignMatrix;

  // Brand Summary - Unique Brand and Market
  if (campaignMatrix && campaignMatrix.uniqueBrandMarkets) {
    brandSummaryCard[0].value = campaignMatrix.uniqueBrandMarkets.value;
    brandSummaryCard[0].percentage =
      Math.ceil(campaignMatrix.uniqueBrandMarkets.percentage) + ' %';
  }

  // Brand Summary - Unique Markets
  if (campaignMatrix && campaignMatrix.uniqueMarkets) {
    brandSummaryCard[1].value = campaignMatrix.uniqueMarkets.value;
    brandSummaryCard[1].percentage =
      Math.ceil(campaignMatrix.uniqueMarkets.percentage) + ' %';
  }

  // Brand Summary - Unique Brands
  if (campaignMatrix && campaignMatrix.uniqueBrands) {
    brandSummaryCard[2].value = campaignMatrix.uniqueBrands.value;
    brandSummaryCard[2].percentage =
      Math.ceil(campaignMatrix.uniqueBrands.percentage) + ' %';
  }

  // Unique Languages
  if (campaignMatrix && campaignMatrix.uniqueLanguages) {
    brandSummaryCard[3].value = campaignMatrix.uniqueLanguages.value;
    brandSummaryCard[3].percentage =
      Math.ceil(campaignMatrix.uniqueLanguages.percentage) + '%';
  }

  const handleTopBrandCheckbox = () => {
    setTopBrandCheck(!topBrandCheck);
  };

  const handleTopMarketCheckbox = () => {
    setTopMarketCheck(!topMarketCheck);
  };

  const lowestUsageSummaryData = [
    {
      title: <strong>Lowest usage markets</strong>,
      className: 'pl-3',
      data: []
    },
    {
      title: <strong>Lowest usage brands</strong>,
      className: 'pr-3',
      data: []
    }
  ];

  const topUsageSummaryData = [
    {
      title: <strong>Top markets</strong>,
      data: [],
      className: 'pl-3',
      checkBoxConfig: {
        title: 'Show brands',
        onChange: handleTopBrandCheckbox,
        value: topBrandCheck
      }
    },
    {
      title: <strong>Top brands</strong>,
      data: [],
      className: 'pr-3',
      checkBoxConfig: {
        title: 'Show markets',
        onChange: handleTopMarketCheckbox,
        value: topMarketCheck
      }
    }
  ];

  // Brand Summary - Lowest Usage Brands
  lowestUsageSummaryData[1].data = [];
  if (campaignMatrix && campaignMatrix.lowestBrands) {
    var totalLowBrand = 0;
    var lowBrandPercentage = 0.0;
    for (var index in campaignMatrix.lowestBrands) {
      lowestUsageSummaryData[1].data.push({
        title: campaignMatrix.lowestBrands[index].name,
        value: campaignMatrix.lowestBrands[index].count,
        percentage:
          Math.ceil(campaignMatrix.lowestBrands[index].percentage) + '%'
      });
      totalLowBrand += campaignMatrix.lowestBrands[index].count;
      lowBrandPercentage += campaignMatrix.lowestBrands[index].percentage;
    }
    lowestUsageSummaryData[1].total = {
      title: 'Total',
      value: totalLowBrand,
      percentage: ''
    };
  }

  // Brand Summary - Lowest Usage Markets
  lowestUsageSummaryData[0].data = [];
  if (campaignMatrix && campaignMatrix.lowestMarkets) {
    var totalLowMarket = 0;
    var lowMarketPercentage = 0.0;
    for (var index in campaignMatrix.lowestMarkets) {
      lowestUsageSummaryData[0].data.push({
        title: campaignMatrix.lowestMarkets[index].name,
        value: campaignMatrix.lowestMarkets[index].count,
        percentage:
          Math.ceil(campaignMatrix.lowestMarkets[index].percentage) + '%'
      });
      totalLowMarket += campaignMatrix.lowestMarkets[index].count;
      lowMarketPercentage += campaignMatrix.lowestMarkets[index].percentage;
    }
    lowestUsageSummaryData[0].total = {
      title: 'Total',
      value: totalLowMarket,
      percentage: ''
    };
  }

  // Brand Summary - Top Markets
  topUsageSummaryData[0].data = [];
  if (campaignMatrix && campaignMatrix.topMarkets) {
    var totalBrands = 0;
    var topBrandPercentage = 0.0;
    for (var index in campaignMatrix.topMarkets) {
      var topBrandsOfMarket = [];

      for (var j in campaignMatrix.topMarkets[index].topBrands) {
        topBrandsOfMarket.push({
          title: campaignMatrix.topMarkets[index].topBrands[j].name,
          value: campaignMatrix.topMarkets[index].topBrands[j].count,
          percentage:
            Math.ceil(
              campaignMatrix.topMarkets[index].topBrands[j].percentage
            ) + '%'
        });
      }

      topUsageSummaryData[0].data.push({
        title: campaignMatrix.topMarkets[index].name,
        value: campaignMatrix.topMarkets[index].count,
        percentage:
          Math.ceil(campaignMatrix.topMarkets[index].percentage) + '%',
        data: topBrandsOfMarket
      });

      totalBrands += campaignMatrix.topMarkets[index].count;
      topBrandPercentage += campaignMatrix.topMarkets[index].percentage;
    }
    topUsageSummaryData[0].total = {
      title: 'Total',
      value: totalBrands
      //percentage: Math.ceil(topBrandPercentage) + '%'
    };
  }

  // Brand Summary - Top Brands
  topUsageSummaryData[1].data = [];
  if (campaignMatrix && campaignMatrix.topBrands) {
    var totalMarkets = 0;
    var topMarketPercentage = 0.0;
    for (var index in campaignMatrix.topBrands) {
      var topMarketsOfBrand = [];
      for (var j in campaignMatrix.topBrands[index].topMarkets) {
        topMarketsOfBrand.push({
          title: campaignMatrix.topBrands[index].topMarkets[j].name,
          value: campaignMatrix.topBrands[index].topMarkets[j].count,
          percentage:
            Math.ceil(
              campaignMatrix.topBrands[index].topMarkets[j].percentage
            ) + '%'
        });
      }
      topUsageSummaryData[1].data.push({
        title: campaignMatrix.topBrands[index].name,
        value: campaignMatrix.topBrands[index].count,
        percentage: Math.ceil(campaignMatrix.topBrands[index].percentage) + '%',
        data: topMarketsOfBrand
      });
      totalMarkets += campaignMatrix.topBrands[index].count;
      topMarketPercentage += campaignMatrix.topBrands[index].percentage;
    }
    topUsageSummaryData[1].total = {
      title: 'Total',
      value: totalMarkets
      //percentage: Math.ceil(topMarketPercentage) + '%'
    };
  }

  return (
    <div className="mt-40">
      <div className="row justify-content-between">
        <DashboardCard
          data={brandSummaryCard}
          colClass={`col-sm-3 pl-0 pr-0`}
        />
      </div>
      <div className="row mt-40 d-flex justify-content-between">
        <ChartSummary data={topUsageSummaryData} className="col-sm-6" />
      </div>
      <div className="row mt-40 d-flex justify-content-between">
        <ChartSummary data={lowestUsageSummaryData} className="col-sm-6" />
      </div>
    </div>
  );
};

export default BrandSummary;
