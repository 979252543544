import React, { useEffect, useState, useMemo } from 'react';
import allCampaign from '../../images/allCampaign.png';
import requesterImage from '../../images/requester.png';
import approverImage from '../../images/approver.png';
import masterDataImage from '../../images/master-data.png';
import brandMarketImage from '../../images/brand-market.png';
import marketImage from '../../images/market.png';
import brandImage from '../../images/brand.png';
import { DashboardCard } from '../organisms/DashboardCard/DashboardCard';
import { CampaignCharts } from '../organisms/CampaignCharts/CampaignCharts';
import { getDashboardMatrix } from '../../actions/dashboard';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import constant from '../../constants';
import { getAuthoringFieldData } from 'actions/saasFormAuthoring';
import ReportSectionHeading from '../atoms/ReportSectionHeading';
import FaqStats from '../molecules/FaqStats';
import FieldStatsAccordion from '../organisms/FieldStatsAccordion';
import DashStatsBox from '../organisms/DashStatsBox';
import StatsBoxTitle from '../molecules/StatsBoxTitle';
import { getChartData, getDateRange } from '../../selectors/index';
import RadioDropDown from '../RadioDropDown';
import constants from '../../constants';
import { get } from 'lodash';
import moment from 'moment';
import ChartSummary from '../organisms/ChartSummary';
//import { ScriptableContext } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const dropDownOptions = [
  { label: 'Monthly', value: 'monthly' },
  { label: 'Weekly', value: 'weekly' },
  { label: 'Quarterly', value: 'quarter' },
  { label: 'Yearly', value: 'annual' }
];

var _startDate = null;

const BarChartColorMap = {
  'simple-sign-up': '#FF0F7D',
  samples: '#00D7C4',
  competitions: '#FFBA00'
};
const BarChartLightColorQnaMap = {
  'simple-sign-up': '#FFB1D5',
  samples: '#9BD7D3',
  competitions: '#FFF6B4'
};
const BarChartColorQnaMap = {
  'simple-sign-up': '#80415e',
  samples: '#3f6e69',
  competitions: '#73643d'
};

// setColourGradient = (canvas , color) => {
// const ctx = canvas.getContext('2d');
// const gradient = ctx.createLinearGraident(0, 0, 100, 100);
// gradient.addColorStop(0, color);
// gradient.addColorStop(0.1, rgba(255, 255, 255, 255));
// }

const CampaignSummary = props => {
  const dispatch = useDispatch();
  const [campaignTrendsOption, setCampaignTrendsOption] = useState(
    dropDownOptions[0]
  );

  const handleCampaignTrendsDropDown = selectedOption => {
    setCampaignTrendsOption(selectedOption);
  };

  const [showToggle, setShowToggle] = useState(false);
  const toggleBtnClick = () => {
    setShowToggle(!showToggle);
  };

  const dropDownConfig = {
    className: 'cw-report-dashboard-dropdown-compact',
    name: 'campaignTrendsDropDown',
    label: 'campaignTrendsDropDown',
    id: 'campaignTrendsDropDown',
    options: dropDownOptions,
    onChange: handleCampaignTrendsDropDown,
    value: campaignTrendsOption
  };

  let campaignMatrix = props.campaignMatrix;
  //Campaign Trends Data set
  if (campaignMatrix && campaignMatrix.campaign_trends) {
    // Form Type: Simple Signup
    var simpleSignUpChartdata = getChartData(
      campaignMatrix.campaign_trends['simple-sign-up'],
      campaignTrendsOption.value
    );
    // Form Type: Samples
    var samplenUpChartdata = getChartData(
      campaignMatrix.campaign_trends['samples'],
      campaignTrendsOption.value
    );
    // Form Type: Competitions
    var competitionsChartdata = getChartData(
      campaignMatrix.campaign_trends['competitions'],
      campaignTrendsOption.value
    );
  }

  const totalCampaign = [
    {
      title: 'Total Campaigns',
      imageIcon2: allCampaign,
      value:
        (campaignMatrix &&
          campaignMatrix.total_count &&
          campaignMatrix.total_count.value) ||
        0,
      className: 'cw-dashboard-summary-card-rings'
    }
  ];

  let barChartLabels = [];
  let barChartCounts = [];
  let barChartslableWithPerArr = [];
  let barChartsDataArr = [];
  let barChartDataQnaToggleArr = [];
  let barChartsQnaDataArr = [];
  let barChartColor = [];
  let barChartQnaColor = [];
  let maxValue = 1;
  if (
    campaignMatrix &&
    campaignMatrix.campaign_types &&
    campaignMatrix.campaign_types.value &&
    campaignMatrix.campaign_types.value.length
  ) {
    for (let data of campaignMatrix.campaign_types.value) {
      if (data.value > maxValue) {
        maxValue = data.value;
      }
      barChartslableWithPerArr.push(
        `${data.label} (${data.value || 0} | ${data.percentage || 0}%)`
      );
      barChartLabels.push(`${data.label}`);
      barChartCounts.push(`${data.value || 0} | ${data.percentage || 0}%`);
      barChartColor.push(BarChartColorMap[data.name]);
      barChartQnaColor.push(BarChartLightColorQnaMap[data.name]);
      if (data.value) {
        barChartsDataArr.push(data.value || 0);
        barChartDataQnaToggleArr.push(data.value - data.qnaCampaignCount || 0);
        barChartsQnaDataArr.push(data.qnaCampaignCount || 0);
      }
    }
  }

  const customToolTip = {
    id: 'campaignTypeTooltip',
    afterDraw(chart) {
      const { ctx, chartArea, tooltip } = chart;
      console.log(tooltip);
    }
  };

  const CampaignTypeChartConfig = {
    data: {
      labels: barChartLabels,
      datasets: []
    },
    plugins: [ChartDataLabels],
    options: {
      layout: {
        padding: {
          left: 0,
          right: 100,
          top: 0,
          bottom: 0
        }
      },
      indexAxis: 'y',
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          stacked: true,
          display: true,
          grid: {
            display: false
          }
        },
        x: {
          stacked: true,
          display: false,
          grid: {
            display: false
          },
          beginAtZero: true
        }
      },
      plugins: {
        customToolTip,
        legend: {
          display: false
        },
        tooltip: {
          // external: externalTooltipHandler,
          // enabled: false,
          // position: 'nearest'
          // displayColors: false,
          // backgroundColor: "#ff0000",
          callback: {
            title: function(context) {
              let titleArr = [];
              let titleData = barChartslableWithPerArr[
                context[0].dataIndex
              ].split(' ');
              titleArr.push(titleData[0]);
              if (!titleData[1].startsWith('(')) {
                titleArr.push(titleData[1]);
              }
              return titleArr.join(' ');
            },
            backgroundColor: '#227799'
          }
        }
      }
    }
  };

  if (showToggle) {
    CampaignTypeChartConfig.data.datasets = [
      {
        label: 'No Q&A',
        data: barChartDataQnaToggleArr,
        backgroundColor: barChartQnaColor,
        barThickness: 13,
        pointRadius: 50,
        borderRadius: 5,
        datalabels: {
          display: false,
          color: 'black',
          align: 'right'
        }
      },
      {
        label: 'Q&A',
        data: barChartsQnaDataArr,
        backgroundColor: barChartColor,
        pointRadius: 50,
        showLine: false,
        pointStyle: 'line',
        barThickness: 13,
        borderRadius: 5,
        datalabels: {
          display: true,
          color: 'black',
          align: 'right',
          anchor: 'end',
          formatter: function(value, context) {
            return barChartCounts[context.dataIndex];
          }
        }
      }
    ];
  } else {
    CampaignTypeChartConfig.data.datasets = [
      {
        label: '',
        data: barChartsDataArr,
        backgroundColor: barChartColor,
        barThickness: 13,
        pointRadius: 50,
        borderRadius: 5,
        datalabels: {
          display: true,
          color: 'black',
          align: 'right',
          anchor: 'end',
          formatter: function(value, context) {
            return barChartCounts[context.dataIndex];
          }
        }
      }
    ];
  }

  const CampaignTrendsConfig = {
    data: {
      labels: simpleSignUpChartdata && simpleSignUpChartdata.labels,
      datasets: [
        {
          label: 'Simple Signup',
          data: simpleSignUpChartdata && simpleSignUpChartdata.dataSet,
          fill: false,
          borderColor: '#FF0F7D',
          // backgroundColor: ({chart: {ctx}}) => {
          //   const bg = ctx.createLinearGradient(0, 0, 0, 100);
          //   bg.addColorStop(0, "#FF0F7Dff");
          //   bg.addColorStop(0.5, "#ffffffff");
          //   return bg;
          // },
          tension: 0.3
        },
        {
          label: 'Samples',
          data: samplenUpChartdata && samplenUpChartdata.dataSet,
          fill: false,
          borderColor: '#00D7C4',
          tension: 0.3
        },
        {
          label: 'Competitions',
          data: competitionsChartdata && competitionsChartdata.dataSet,
          fill: false,
          borderColor: '#FFBA00',
          tension: 0.3
        }
      ]
    },
    options: {
      borderWidth: 1.5,
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          grid: {
            display: false
          },
          ticks: {
            stepSize: 20
          }
        },
        x: {
          offset: 20,
          grid: {
            display: false
          },
          beginAtZero: false
        }
      },
      plugins: {
        legend: {
          position: 'right',
          textDirection: 'ltr',
          rtl: true,
          labels: {
            pointStyle: 'line',
            usePointStyle: true,
            textAlign: 'left'
          }
        },
        tooltip: {
          callbacks: {
            title: function(context) {
              if (campaignTrendsOption.value == 'weekly') {
                let m = moment(context[0].label, 'DD MMM YYYY');
                if (m) {
                  if (_startDate && _startDate > m) {
                    return (
                      _startDate.format('DD MMM YYYY') +
                      ' - ' +
                      m.day(6).format('DD MMM YYYY')
                    );
                  }
                  return (
                    context[0].label + ' - ' + m.day(6).format('DD MMM YYYY')
                  );
                }
              }
              return context[0].label;
            }
          }
        }
      }
    }
  };

  const toggleConfig = {
    toggleValue: showToggle,
    onClick: toggleBtnClick,
    title: 'Show Q&A usage'
  };

  const migrationList = [
    {
      title: 'Migration campaigns',
      count:
        (campaignMatrix &&
          campaignMatrix.migration_campaigns &&
          campaignMatrix.migration_campaigns.value) ||
        0,
      percent: `${Math.ceil(
        campaignMatrix &&
          campaignMatrix.migration_campaigns &&
          campaignMatrix.migration_campaigns.percentage
      ) || 0} %`
    },
    {
      title: 'Campaigns with Q&As',
      count:
        (campaignMatrix &&
          campaignMatrix.campaigns_with_qna &&
          campaignMatrix.campaigns_with_qna.value) ||
        0,
      percent: `${Math.ceil(
        campaignMatrix &&
          campaignMatrix.campaigns_with_qna &&
          campaignMatrix.campaigns_with_qna.percentage
      ) || 0} %`
    },
    {
      title: 'Incentive Campaigns',
      count:
        (campaignMatrix &&
          campaignMatrix.incentive_campaigns &&
          campaignMatrix.incentive_campaigns.value) ||
        0,
      percent: `${Math.ceil(
        campaignMatrix &&
          campaignMatrix.incentive_campaigns &&
          campaignMatrix.incentive_campaigns.percentage
      ) || 0} %`
    }
  ];

  return (
    <div className="mt-40">
      <div className="row justify-content-between">
        <DashboardCard data={totalCampaign} colClass={`col-sm-3`} />
        <div className="col-sm-8">
          <div className="campaign-trends">
            <CampaignCharts
              title={<strong>Campaign types</strong>}
              className={'campaign-type-chart'}
              chartType={'Bar'}
              chartConfig={CampaignTypeChartConfig}
              toggleConfig={toggleConfig}
            />
          </div>
        </div>
      </div>

      <div className="campaign-trends mt-20">
        <CampaignCharts
          title={<strong>Campaigns fulfilled</strong>}
          className={'campaign-trends-chart-height'}
          chartType={'Line'}
          chartConfig={CampaignTrendsConfig}
          dropDownConfig={dropDownConfig}
        />
        {campaignTrendsOption.value === 'weekly' ? (
          <div className="cw-chart-footnote-wrapper">
            {' '}
            <span className="cw-chart-footnote">
              Dates on x-axis are calendar week start dates
            </span>{' '}
          </div>
        ) : null}
      </div>

      <div className="row">
        <div className="col-md-12 pt-60">
          <DashStatsBox list={migrationList} />
        </div>
      </div>
    </div>
  );

  // EXTERNAL TOOL
};

const getOrCreateTooltip = chart => {
  let tooltipEl = chart.canvas.parentNode.querySelector('div');

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
    tooltipEl.style.borderRadius = '3px';
    tooltipEl.style.color = 'white';
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.transform = 'translate(-50%, 0)';
    tooltipEl.style.transition = 'all .1s ease';

    const table = document.createElement('table');
    table.style.margin = '0px';

    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

const externalTooltipHandler = context => {
  console.log('>>context', context);
  // Tooltip Element
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set Text
  if (tooltip.body) {
    const titleLines = tooltip.title || [];
    const bodyLines = tooltip.body.map(b => b.lines);
    const tableHead = document.createElement('thead');

    titleLines.forEach(title => {
      const tr = document.createElement('tr');
      tr.style.borderWidth = 0;

      const th = document.createElement('th');
      th.style.borderWidth = 0;
      const text = document.createTextNode(title);

      th.appendChild(text);
      tr.appendChild(th);
      tableHead.appendChild(tr);
    });

    const tableBody = document.createElement('tbody');
    bodyLines.forEach((body, i) => {
      const colors = tooltip.labelColors[i];

      const span = document.createElement('span');
      span.style.background = colors.backgroundColor;
      span.style.borderColor = colors.borderColor;
      span.style.borderWidth = '2px';
      span.style.marginRight = '10px';
      span.style.height = '10px';
      span.style.width = '10px';
      span.style.display = 'inline-block';

      const tr = document.createElement('tr');
      tr.style.backgroundColor = 'inherit';
      tr.style.borderWidth = 0;

      const td = document.createElement('td');
      td.style.borderWidth = 0;

      const text = document.createTextNode(body);

      td.appendChild(span);
      td.appendChild(text);
      tr.appendChild(td);
      tableBody.appendChild(tr);
    });

    const tableRoot = tooltipEl.querySelector('table');

    // Remove old children
    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove();
    }

    // Add new children
    tableRoot.appendChild(tableHead);
    tableRoot.appendChild(tableBody);
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

  // Display, position, and set styles for font
  tooltipEl.style.backgroundColor = '#ff0000';
  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = positionX + tooltip.caretX + 'px';
  tooltipEl.style.top = positionY + tooltip.caretY + 'px';
  tooltipEl.style.font = tooltip.options.bodyFont.string;
  tooltipEl.style.padding =
    tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
};

export default CampaignSummary;
