/**
 * Combine all module export
 */
let _ = require('lodash');

// Load app configuration
module.exports = _.merge(
	require(__dirname + '/config.js'),
	require(__dirname +
		'/' +
		(process.env.$NODE_ENV || process.env.NODE_ENV.trim() || process.env.NODE_ENV || 'development') +
		'.js') || {}
);
