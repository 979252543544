import {
  FETCH_BRAND_DEFAULTS,
  FETCH_BRAND_DEFAULTS_SUCCESS,
  FETCH_BRAND_DEFAULTS_FAILURE,
  SAVE_BRAND_DEFAULTS,
  SAVE_BRAND_DEFAULTS_SUCCESS,
  SAVE_BRAND_DEFAULTS_FAILURE
} from '../actionTypes';
import {
  fetchBrandDefaults as fetchBrandDefaultsService,
  saveBrandDefaultsService
} from 'services/brandDefaultsService';

export const fetchBrandDefaults = brand => {
  return async function(dispatch) {
    try {
      dispatch({ type: FETCH_BRAND_DEFAULTS });
      const brandDefaults = await fetchBrandDefaultsService(brand);

      dispatch({
        type: FETCH_BRAND_DEFAULTS_SUCCESS,
        payload: brandDefaults
      });
    } catch (error) {
      dispatch({ type: FETCH_BRAND_DEFAULTS_FAILURE, payload: error });
    }
  };
};

export const saveBrandDefaults = (data, callback) => {
  return async function(dispatch) {
    try {
      dispatch({ type: SAVE_BRAND_DEFAULTS });
      const brandDefaults = await saveBrandDefaultsService(data);
      callback && callback(data.brand);
      dispatch({
        type: SAVE_BRAND_DEFAULTS_SUCCESS,
        payload: brandDefaults
      });
    } catch (error) {
      dispatch({ type: SAVE_BRAND_DEFAULTS_FAILURE, payload: error });
    }
  };
};
