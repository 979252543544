import React, {
  forwardRef,
  useState,
  useRef,
} from 'react';
import { FieldError } from '../../atoms/FieldError';
import { get } from 'lodash';
import classNames from 'classnames';
import { Mention, MentionsInput } from 'react-mentions'
import { LinkButton } from 'components/atoms/LinkButton';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import ReactTooltip from 'react-tooltip';

const defaultStyle =  {
  control: {
  },

  '&multiLine': {
    control: {
      fontFamily: 'monospace',
      minHeight: 63,
    },
    highlighter: {
      padding: 9,
      border: '1px solid transparent',
    },
  },

  '&singleLine': {
    display: 'block',
    flex: '1 1 auto',
    width: '1%',

    highlighter: {
      padding: '0.375rem 0.75rem',
      paddingRight: '54px',
    },
    input: {
      display: 'block',
      padding: '0.375rem 0.75rem',
      paddingRight: '54px',
      fontSize: '1rem',
      fontWeight: '400',
      lineHeight: '1.5',
      color: '#495057',
      backgroundColor: 'transparent',
      backgroundClip: 'padding-box',
      border: '1px solid #ced4da',
      borderRadius: '0.25rem 0px 0px 0.25rem',
      transition: 'border-color .15s',
      position: 'absolute',
      top: '0px',
      left: '0px'
    },
  },

  suggestions: {
    list: {
      backgroundColor: 'white',
      border: '1px solid rgba(0,0,0,0.15)',
      fontSize: 14,
    },
    item: {
      padding: '5px 15px',
      borderBottom: '1px solid rgba(0,0,0,0.15)',
      '&focused': {
        backgroundColor: '#cee4e5',
      },
    },
  },
}

const transformText = (givenText) => {
  return givenText.replace(/First Name/g, "[First Name]")
}

const findAllFirstNameTags = (str) => {
  const regexp = /First Name/g;
  let match;
  let newList = [];
  while ((match = regexp.exec(str)) !== null) {
    newList.push([match.index,regexp.lastIndex])
  }
  return newList;
}

const checkIfWord = (cursorPos, list) => {
  let flag = false;
  for(let i=0 ; i<list.length; i++) {
    if(cursorPos > list[i][0] && cursorPos < list[i][1]){
      flag = true;
    }
  }
  return flag
}

const EmojiCustom = forwardRef(
  (
    { onChange, name, value, maxLength, label, helpText, errors, defaultValue, subText, labelTooltip },
    ref
  ) => {
    const inputRef = useRef(null);
    const errorMessages = get(errors, name);
    const hasError = !!(errors && errorMessages);
    const [showEmojis, setShowEmojis] = useState(false);
    
    const addEmoji = e => {
      let sym = e.unified.split('-');
      let codesArray = [];
      sym.forEach(el => codesArray.push('0x' + el));
      let emoji = String.fromCodePoint(...codesArray);

      const cursor = inputRef.current.selectionStart;
      const cursorEnd = inputRef.current.selectionEnd;
      const value = inputRef.current.value;
      
      const matchList = findAllFirstNameTags(inputRef.current?.value)
      if(checkIfWord(cursor, matchList)){
        return null;
      }
      
      const text = value.slice(0, cursor) + emoji + value.slice(cursorEnd);
      if(text.length > parseInt(maxLength)){
        return null;
      }
      onChange(transformText(text));

      //Codes added for the new cursor
      const newCursor = (cursor) + emoji.length;
      setTimeout(
        () => inputRef.current.setSelectionRange(newCursor, newCursor),
        10
      );
    };
    const handleTextLink = e =>{
      const cursor = inputRef.current.selectionStart;
      const cursorEnd = inputRef.current.selectionEnd;
      const value = inputRef.current.value;
      const str = 'First Name '

      const matchList = findAllFirstNameTags(inputRef.current?.value)
      if(checkIfWord(cursor, matchList)){
        return null;
      }

      const text = value.slice(0, cursor) + str + value.slice(cursorEnd);
      if(text && text.length > parseInt(maxLength)){
        return null;
      }
      onChange(transformText(text));
      //Codes added for the new cursor
      const newCursor = cursor + str.length;
      setTimeout(() => {
          inputRef.current.setSelectionRange(newCursor, newCursor)
          inputRef.current.focus();
        },10);
    }

    const charCountAdjust = (value.match(/\[First Name\]/g) || []).length * 2
    
    const handleChange = (event, newValue, newPlainTextValue, mentions) => {
      if(newValue.length <= (parseInt(maxLength) + charCountAdjust)){
        onChange(newValue);
      }
    };

    

    return (
      <div
        className={classNames('form-group emoji-custom-input')}
      >
        <label htmlFor={name}>{label}
        {labelTooltip && <>
          <span
            className="icon"
            style={{cursor: 'pointer'}}
            data-tip={labelTooltip}
            data-for={label}
          >
            &nbsp;<i className="fas fa-question-circle"></i>
          </span>
          <ReactTooltip
            id={label}
            place="right"
            type="info"
            multiline={true}
            className="cw-tooltip cw-email-tooltip"
          />
        </>}
        {subText && 
          <span className="email-label-subtext d-block">
            {subText}
          </span>}
        </label>
        <div className="input-group">
          <MentionsInput
            singleLine
            value={value}
            name={name}
            onChange={handleChange}
            className={classNames(
              'react-mentions-email',
              {
                'is-invalid': hasError
              }
            )}
            placeholder={"Add placeholder text using '@'"}
            style={defaultStyle}
            a11ySuggestionsListLabel={'Suggested mentions'}
            inputRef={e => {
              ref(e);
              inputRef.current = e;
            }}
          >
            <Mention
              data={[{ id: 'First Name', display: 'First Name' }]}
              trigger="@"
              markup="[__id__]"
              displayTransform={(id, display) => `${display}`}
              style={{
                backgroundColor: '#cee4e5',
              }}
              appendSpaceOnAdd
              // onAdd={(id, display, startPos, endPos) => {
              //   console.log('endPos: ', endPos);
              //   console.log('startPos: ', startPos);
              // }}
            />
          </MentionsInput>

          <div className="input-group-append">
            <span className="position-absolute text-secondary emoji-custom-input__count">
              {value.length - charCountAdjust}/{maxLength}
            </span>
            <span
              className={classNames('input-group-text cw-icon cw-icon--cal')}
              id="cw-icon--cal"
              onClick={() => {
                setShowEmojis(!showEmojis);
              }}
            >
              <i className="far fa-smile"></i>
            </span>
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <LinkButton
            className="emoji-custom-input__textlink"
            onClick={handleTextLink}
          >
            {'Insert first name'}
          </LinkButton>
          <div className="emoji-custom-input__helparea">
            <div className="emoji-custom-input__helptext">{helpText}</div>
          </div>
        </div>
        <div>
          <FieldError
            errors={errors}
            name={name}
            className="emoji-custom-input__error-mesg"
          />
        </div>
        {showEmojis && (
          <div className="emoji-custom-input__picker">
            <Picker
              data={data}
              onEmojiSelect={addEmoji}
              onClickOutside={() => {
                inputRef.current.focus();
                setShowEmojis(false);
              }}
            />
          </div>
        )}
      </div>
    );
  }
);
export default EmojiCustom;
