import React, { Component } from 'react';
import Modal from 'react-modal';
import constants from 'constants/config';
export default class ModalWindow extends Component {
  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        onRequestClose={this.props.onRequestClose}
        className={`cw-modal ${this.props.className}`}
        contentLabel={this.props.contentLabel}
        style={{
          overlay: {
            backgroundColor: constants.EMAIL_OVERLAY_BACKGROUND
          }
        }}
      >
        <button
          className="cw-modal--close"
          title="Close"
          onClick={this.props.onRequestClose}
        >
          <i className="fas fa-times"></i>
        </button>
        <h3 className="cw-heading--primary mb-10">{this.props.heading}</h3>
        <p className="mb-20">{this.props.description}</p>
        {this.props.children}
      </Modal>
    );
  }
}
