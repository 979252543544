import React from 'react';
import Nav from 'react-bootstrap/Nav';
import { Link, useHistory } from 'react-router-dom';
import { NavItem, Dropdown, NavLink } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { resetEmailType } from 'actions/emailbriefing/emailType';
import { setNotificationMessage } from 'actions/dashboard';
import { resetEmailBrandDetails } from 'actions/emailbriefing/emailBrandDetails';
import { resetFormBrandDetails } from 'actions/brandDetails';
import { resetSmsCampaignDetails } from 'actions/communications/communicationCampaignDetails';
import { resetSmsBrandDetails } from 'actions/communications/communicationBrandDetails.js';
import ExternalLinkIcon from '../../../images/external-link.png';
const HeaderNavMenu = ({ menuItems }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const onNavClick = (link, id) => {
    if(link && id == 'EmailTutorials'){
      window.open(link,'_blank')
    } else if (link) {
      history.push(link);
      if (id === 'newEmailCampaign') {
        dispatch(resetEmailType());
        dispatch(resetEmailBrandDetails());
      }
      if (id === 'emailDashboard') {
        dispatch(setNotificationMessage('', undefined, ''));
      }

      if (id === 'newCampaign') {
        dispatch(resetFormBrandDetails());
      }

      if (id === 'newSmsCampaign') {
        dispatch(resetSmsBrandDetails());
        dispatch(resetSmsCampaignDetails());
      }

      if (id === 'smsDashboard') {
        dispatch(setNotificationMessage('', undefined, ''));
      }
    }
  };
  return (
    <div className="header-nav">
      <Nav>
        {menuItems.map((item, index) => {
          if (item.dropDownItems) {
            return (
              <Dropdown as={NavItem} key={item.id}>
                <Dropdown.Toggle as={NavLink} className="header-dropdown-title">
                  {item.title}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {item.dropDownItems.map((dropDownItem, itemIndex) => {
                    if (dropDownItem && dropDownItem.showLinkIcon) {
                      return (
                        <Dropdown.Item
                        as="button"
                        className="header-dropdown-item"
                        key={dropDownItem.id}
                        onClick={() =>
                          onNavClick(dropDownItem.link, dropDownItem.id)
                        }
                        >
                          {dropDownItem.title}
                          <img
                            src={ExternalLinkIcon}
                            alt="External Link"
                            className="icon external-link-icon"
                          />
                        </Dropdown.Item>
                      );
                    } else {
                      return (
                        <Dropdown.Item
                          as="button"
                          className="header-dropdown-item"
                          onClick={() =>
                            onNavClick(dropDownItem.link, dropDownItem.id)
                          }
                          key={dropDownItem.id}
                        >
                          {dropDownItem.title}
                        </Dropdown.Item>
                      );
                    }
                  })}
                </Dropdown.Menu>
              </Dropdown>
            );
          } else {
            return (
              <Nav.Item>
                <Link to={item.link}>{item.title}</Link>
              </Nav.Item>
            );
          }
        })}
      </Nav>
    </div>
  );
};

export default HeaderNavMenu;
