import {
  FETCH_EMAIL_BRIEFINGS,
  FETCH_EMAIL_BRIEFINGS_SUCCESS,
  FETCH_EMAIL_BRIEFINGS_FAILURE,
  SET_EMAIL_BRIEFINGS_COUNT
} from '../../actionTypes';
import { getEmailBriefingList, getEmailBriefingCount } from 'services/emailBriefings';

export const fetchEmailBriefingList = (filters = {}) => {
  return async function(dispatch) {
    try {
      dispatch({ type: FETCH_EMAIL_BRIEFINGS });
      const emailBriefings = await getEmailBriefingList(filters);

      dispatch({
        type: FETCH_EMAIL_BRIEFINGS_SUCCESS,
        payload: emailBriefings
      });
    } catch (error) {
      dispatch({ type: FETCH_EMAIL_BRIEFINGS_FAILURE, payload: error });
    }
  };
};

export const fetchEmailBriefingCount = (filters = {}) => {
  return async function(dispatch) {
    try {
      // dispatch({ type: FETCH_EMAIL_BRIEFINGS });
      const result = await getEmailBriefingCount(filters);

      dispatch({
        type: SET_EMAIL_BRIEFINGS_COUNT,
        payload: result.data
      });
    } catch (error) {
      //dispatch({ type: FETCH_EMAIL_BRIEFINGS_FAILURE, payload: error });
    }
  };
};