import axios from 'axios';
import constant from '../constants';
import { transformToURLQueryString } from 'selectors';

import {
  OPEN_FEEDBACK_FORM,
  CLOSE_FEEDBACK_FORM,
  TOGGLE_FEEDBACK_FORM_SUBMISSION,
  FETCH_FEEDBACKS,
  SET_FEEDBACKS
} from '../actionTypes';

export const openFeedbackForm = payload => {
  return {
    type: OPEN_FEEDBACK_FORM,
    payload
  };
};

export const closeFeedbackForm = () => ({
  type: CLOSE_FEEDBACK_FORM
});

export const toggleFeedbackFormSubmission = payload => ({
  type: TOGGLE_FEEDBACK_FORM_SUBMISSION,
  payload
});

export const submitFeedback = (feedbackData, callback) => {
  return function(dispatch) {
    dispatch(toggleFeedbackFormSubmission(true));
    axios
      .post(constant.serviceUrls.FEEDBACKS_URL, feedbackData)
      .then(response => {
        dispatch(toggleFeedbackFormSubmission(false));
        callback(null, true);
        dispatch(closeFeedbackForm());
      })
      .catch(error => {
        callback(error, null);
        dispatch(toggleFeedbackFormSubmission(false));
      });
  };
};

export const setFeedbacksInStore = payload => ({
  type: SET_FEEDBACKS,
  payload
});

export const fetchFeedbacks = (options, callback = () => {}) => {
  return function(dispatch) {
    const url =
      constant.serviceUrls.FEEDBACKS_URL +
      '?' +
      transformToURLQueryString(options);
    axios
      .get(url)
      .then(response => {
        dispatch(setFeedbacksInStore(response.data));
        callback(null, true);
      })
      .catch(error => {
        callback(error, null);
      });
  };
};
