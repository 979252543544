import constants from 'constants/config';
import React from 'react';
import BrandDetailsKnowMoreSection from './BrandDetailsKnowMoreSection';

const BrandDetailsKnowMore = props => {
  return (
    <div className="brandidetails-header">
      <h2 className="brandidetails-h2">Form widgets</h2>
      <span
        className={'qna-guideline-close brand-details-close-modal'}
        onClick={props.closeIsEditModal}
      ></span>

      <div>
        <div className="qna-guideline-scroll mt-4">
          <BrandDetailsKnowMoreSection
            data={constants.BRAND_DETAIL_SECTION_1}
            qnaIndex={true}
          />
          <div className="horizontal-line"></div>
          <BrandDetailsKnowMoreSection
            data={constants.BRAND_DETAIL_SECTION_2}
            qnaIndex={true}
          />
          <div className="horizontal-line"></div>
          <BrandDetailsKnowMoreSection
            data={constants.BRAND_DETAIL_SECTION_3}
            qnaIndex={true}
          />
          <div className="horizontal-line"></div>
        </div>
      </div>

      <div className="mr-20 mt-3">
        <button
          type="submit"
          onClick={props.closeIsEditModal}
          className="btn btn-primary mb-10 cw-print-hide text-nowrap  mr-1 brandidetails-close-btn"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default BrandDetailsKnowMore;
