import React from 'react';

export const ReportsInformationModal = ({ content }) => {
  return (
    <>
      <h3 className="cw-heading--primary mb-10">Reports summary</h3>
      <div className="col-sm-12">
        {content.map((item, i) => (
          <div
            className={`row ${i % 2 ? 'cw-bgpattern-primary' : ''}`}
            key={`report-info-item-${i}`}
          >
            <div className="col-sm-4">
              <strong>{item.label}</strong>
            </div>
            <div className="col-sm-8">
              <span>{item.toolTipData}</span>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
