export default [
  {
    name: 'Arial',
    fontFamily: "Arial, 'Helvetica Neue', Helvetica, sans-serif"
  },
  {
    name: 'Courier',
    fontFamily:
      "'Courier New', Courier, 'Lucida Sans Typewriter', 'Lucida Typewriter', monospace"
  },
  {
    name: 'Georgia',
    fontFamily: "Georgia, Times, 'Times New Roman', serif"
  },
  {
    name: 'Helvetica',
    fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif"
  },
  {
    name: 'Lucida Sans',
    fontFamily:
      "'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Geneva, Verdana, sans-serif"
  },
  {
    name: 'Tahoma',
    fontFamily: 'Tahoma, Verdana, Segoe, sans-serif'
  },
  {
    name: 'Times New Roman',
    fontFamily:
      "TimesNewRoman, 'Times New Roman', Times, Beskerville, Georgia, serif"
  },
  {
    name: 'Trebuchet MS',
    fontFamily:
      "'Trebuchet MS', 'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', Tahoma, sans-serif"
  },
  {
    name: 'Verdana',
    fontFamily: 'Verdana, Geneva, sans-serif'
  },
  {
    name: 'ヒラギノ角ゴ Pro W3',
    fontFamily:
      'ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro,Osaka, メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, sans-serif'
  },
  {
    name: 'メイリオ',
    fontFamily:
      'メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro,Osaka, sans-serif'
  },
  {
    name: 'Bitter',
    fontFamily: "'Bitter', Georgia, Times, 'Times New Roman', serif",
    url: 'https://fonts.googleapis.com/css?family=Bitter'
  },
  {
    name: 'Droid Serif',
    fontFamily: "'Droid Serif', Georgia, Times, 'Times New Roman', serif",
    url: 'https://fonts.googleapis.com/css?family=Droid+Serif'
  },
  {
    name: 'Lato',
    fontFamily: "'Lato', Tahoma, Verdana, Segoe, sans-serif",
    url: 'https://fonts.googleapis.com/css?family=Lato'
  },
  {
    name: 'Open Sans',
    fontFamily: "'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
    url: 'https://fonts.googleapis.com/css?family=Open+Sans'
  },
  {
    name: 'Roboto',
    fontFamily: "'Roboto', Tahoma, Verdana, Segoe, sans-serif",
    url: 'https://fonts.googleapis.com/css?family=Roboto'
  },
  {
    name: 'Source Sans Pro',
    fontFamily: "'Source Sans Pro', Tahoma, Verdana, Segoe, sans-serif",
    url: 'https://fonts.googleapis.com/css?family=Source+Sans+Pro'
  },
  {
    name: 'Montserrat',
    fontFamily:
      "'Montserrat', 'Trebuchet MS', 'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', Tahoma, sans-serif",
    url: 'https://fonts.googleapis.com/css?family=Montserrat'
  },
  {
    name: 'Ubuntu',
    fontFamily: "'Ubuntu', Tahoma, Verdana, Segoe, sans-serif",
    url: 'https://fonts.googleapis.com/css?family=Ubuntu'
  }
];
