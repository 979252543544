import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { isEmpty, get } from 'lodash';

import Layout from 'components/organisms/Layout/Layout';
import PageHeader from 'components/organisms/PageHeader/PageHeader';
import ProgressBar from 'components/molecules/ProgressBar';
import BackAndNext from 'components/organisms/BackAndNext/BackAndNext';
import SMSSummaryCards from 'components/organisms/EmailSummaryCards/SMSSummaryCards';
import CommunicationsOverviewTables from 'components/organisms/CommunicationsOverviewTables';
import MobileDevicePreview from 'components/organisms/MobileDevicePreview';
import { FormError } from 'components/molecules/FormError';
import Loader from 'components/Loader';
import { Feedback, validateFeedbackForm } from './Feedback';

import { getSmsOverview } from 'actions/communications/communicationOverview';
import { submitFeedback } from 'actions/feedbackForm';

import { getMobilePreviewDates } from 'utilities/util';
import { validateSMSCampaign } from './utilities';

import constants from '../../constants';

import { scrollToTop, getSMSReviewData } from 'selectors';
import * as yup from 'yup';
import SMSContentAlert from './SMSContentAlert';

const SMSReview = props => {
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [feedback, setFeedback] = useState({});
  const [errors, setErrors] = useState(false);
  const [requestInfoAnswer, setRequestInfoAnswer] = useState({});
  const campaign = useSelector(
    state => state.communicationOverview.smsDetailsOverview
  );
  const { isLoading } = useSelector(state => state.communicationOverview);

  const { authorizedUserData } = useSelector(state => state.authorized);
  const { feedbacks: userSubmittedFeebacks } = useSelector(
    state => state.feedbackForm
  );

  useEffect(() => {
    const pathUid = props.match.params.id;

    if (pathUid) {
      dispatch(getSmsOverview(pathUid));
    }
  }, []);

  const dispatch = useDispatch();

  let mobilePreviewData = getSMSReviewData(
    campaign,
    getMobilePreviewDates(campaign)
  );

  const shouldShowFeedbackSection = isEmpty(userSubmittedFeebacks);

  const validateRequestorInfoAnswer = async smsDetails => {
    const { requestInfoAnswerText } = smsDetails;
    const requestorInfoSubmitSchema = yup.object({
      requestInfoAnswerText: yup.string().required()
    });

    return await requestorInfoSubmitSchema.isValid({
      requestInfoAnswerText: requestInfoAnswerText
    });
  };

  const isError =
    errors.summary ||
    errors.campaignAttributes ||
    errors.deliverySchedule ||
    errors.audienceDefinition ||
    // errors.emailCollaborators ||
    // errors.emailABTesting ||
    errors.feedbackError ||
    errors.designSMS ||
    errors.dynamicCouponError ||
    errors.feedbackCommentError ||
    errors.requestAnswererror;

  const handleNextClick = async () => {
    const errors = await validateSMSCampaign(campaign);
    const {
      summary,
      campaignAttributes,
      deliverySchedule,
      audienceDefinition,
      designSMS,
      dynamicCouponError
      // emailCollaborators,
    } = errors;

    const feedbackError = await validateFeedbackForm(
      feedback,
      shouldShowFeedbackSection
    );

    const feedbackCommentErr =
      feedback.rw_ratings <= '3' &&
      (!feedback.rw_comments || feedback.rw_comments.trim().length == 0)
        ? true
        : false;

    let requestInfoError = true;
    if (campaign && campaign.requestInfoText) {
      requestInfoError = await validateRequestorInfoAnswer(requestInfoAnswer);
    }

    if (
      summary ||
      campaignAttributes ||
      deliverySchedule ||
      audienceDefinition ||
      // emailCollaborators ||
      !feedbackError ||
      designSMS ||
      dynamicCouponError ||
      feedbackCommentErr ||
      dynamicCouponError ||
      !requestInfoError
    ) {
      setErrors({
        ...errors,
        feedbackError: !feedbackError,
        feedbackCommentError: feedbackCommentErr,
        campaignAttributes: dynamicCouponError ? true : campaignAttributes,
        requestAnswererror: !requestInfoError
      });
      scrollToTop();
      return;
    }

    const paramUid = props.match.params.id;
    let campaignAdditionalInfoData = {};

    if (additionalInfo.trim()) {
      campaignAdditionalInfoData.uid = paramUid;
      campaignAdditionalInfoData.additionalInfo = additionalInfo;

      await axios.post(
        constants.communications.POST_SMS_CAMPAIGN_ADDITIONAL_INFO_DETAILS,
        campaignAdditionalInfoData
      );
    }

    if (shouldShowFeedbackSection) {
      let feedbackData = {
        ratings: feedback.rw_ratings,
        campaignRequestType: 'SMS campaigns',
        commentType: get(feedback, 'rw_commentType.value'),
        comments: feedback.rw_comments,
        campaignUid: paramUid,
        feedbackProvider: authorizedUserData._id
      };

      dispatch(
        submitFeedback(feedbackData, (error, result) => {
          if (error) {
            console.log(error);
          } else {
            console.log(result);
          }
        })
      );
    }

    let dataToSend = {
      status: constants.featureFlags.ENABLE_SMS_APPROVAL_JOURNEY ? '3' : '2',
      uid: paramUid,
      userEmail: authorizedUserData.emailId,
      userName: authorizedUserData.name,
      requestorsEmail: authorizedUserData.emailId
    };

    if (campaign && campaign.requestInfoText) {
      dataToSend = {
        ...dataToSend,
        requestInfoAnswerText:
          requestInfoAnswer && requestInfoAnswer.requestInfoAnswerText
      };
    }

    await axios.post(
      constants.communications.UPDATE_SMS_CAMPAIGN_STATUS,
      dataToSend
    );
    props.history.push(
      `${constants.SMS_BASE_ROUTE}/${paramUid}/${constants.SMS_ROUTES.CONFIRMATION_PAGE}`
    );
  };

  const handleRequestInfoAnswer = event => {
    let newRequestInfoAnswer = {
      ...requestInfoAnswer,
      requestInfoAnswerText: event.target.value
    };
    setErrors({
      ...errors,
      requestAnswererror: false
    });
    setRequestInfoAnswer(newRequestInfoAnswer);
  };

  if (isLoading) return <Loader />;

  return (
    <div className="skypink ml-5 mr-5">
      <div className="cw-section">
        <ProgressBar
          customClass={'cw-email-progress-custom'}
          totalPage={constants.SMS_PROGRESS_BAR_TOT_PAGE}
          pageNo={constants.SMS_JOURNEY_PAGE_NUMBERS.REVIEW}
        />
        <PageHeader heading="Review and submit campaign" />

        <div className="d-flex">
            <div
            style={{
              flex: 1,
              marginRight: 40,
              minWidth: 0,
              flexDirection: 'column',
              display: 'flex'
            }}
          >
            {isError && (
              <div>
                <FormError
                  className="mb-15"
                  message={constants.EMAIL_REVIEW_ERROR_MESSAGE}
                  isNotBold
                />
              </div>
            )}

            <div className="mb-20">
              <SMSContentAlert smsCampaign={campaign} />
            </div>
          </div>
          <div style={{width: '280px'}}>

          </div>
        </div>

        <Layout
          className=""
          sidebar={
            <MobileDevicePreview
              className="mobile-preview-overview"
              data={mobilePreviewData}
              campaign={campaign}
              alignHeight={true}
            />
          }
        >
          <SMSSummaryCards smsCampaign={campaign} />
          <CommunicationsOverviewTables
            review
            editable
            smsCampaign={campaign}
            errors={errors}
            handleRequestInfoAnswer={handleRequestInfoAnswer}
            requestInfoAnswerFlag={true}
          />

          {shouldShowFeedbackSection && (
            <Feedback
              feedback={feedback}
              setFeedback={setFeedback}
              errors={errors}
              setErrors={setErrors}
            />
          )}

          <BackAndNext
            onClickBack={() => {
              const uid = props.match.params.id;
              props.history.push(
                `${constants.SMS_BASE_ROUTE}/${uid}/${constants.SMS_ROUTES.DESIGN_SMS}`
              );
            }}
            onClickNext={handleNextClick}
            nextTitle="Submit"
          />
        </Layout>
      </div>
    </div>
  );
};

export default SMSReview;
