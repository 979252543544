import React from 'react';
import AppliedFilterPill from 'components/atoms/AppliedFilterPill/AppliedFilterPill';
import constants from 'constants/config';

export default class AppliedFilters extends React.Component {
  getFiltersList() {
    const { filters: unorderedfilters, removeFilter } = this.props;
    const orderedFilters = {};
    let filters;

    Object.keys(unorderedfilters)
      .sort()
      .forEach(function(key) {
        orderedFilters[key] = unorderedfilters[key];
      });
    if (Object.keys(orderedFilters).length > 0) {
      filters = Object.keys(orderedFilters).map((keyName, i) => {
        if (Array.isArray(orderedFilters[keyName])) {
          return orderedFilters[keyName].map((item, indx) => {
            return (
              <AppliedFilterPill
                key={`${keyName}-${indx}`}
                item={item}
                keyName={keyName}
                defaultLastUpdatedValue={this.defaultLastUpdatedValue}
                removeFilter={removeFilter}
                orderedFilters={orderedFilters}
              />
            );
          });
        } else if (typeof orderedFilters[keyName] === 'object') {
          return (
            <AppliedFilterPill
              key={keyName}
              item={orderedFilters[keyName]}
              keyName={keyName}
              orderedFilters={orderedFilters}
              removeFilter={removeFilter}
            />
          );
        } else if(typeof orderedFilters[keyName] === 'boolean'){
          return orderedFilters[keyName]? (
            <AppliedFilterPill
              key={keyName}
              item={{label: constants.EMAIL_DASHBOARD_FILTER_MAP[keyName]}}
              keyName={keyName}
              orderedFilters={orderedFilters}
              removeFilter={removeFilter}
            />
            ) : null;
        } else {
          return '';
        }
      });
      return filters;
    }
  }

  render() {
    const { filtersCnt, _clearAllFilters, className='' } = this.props;

    return (
      <div className={`row mt-2 ${className}`}>
        <div className="col-sm-10 justify-content-between align-items-center">
          {this.getFiltersList()}
          {filtersCnt >= 2 ? (
            <div className="cw-dashboard-filters clearall">
              <div
                className="cw-dashboard-filters-label clearalllabel"
                onClick={_clearAllFilters}
              >
                Clear all
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
