import React, { useEffect } from 'react';
import { Input } from '../../atoms/Input';
import { FieldError } from '../../atoms/FieldError';
import { get } from 'lodash';
import classNames from 'classnames';

export const FormCheckBoxGroupInput = ({
  name,
  type,
  rules,
  register,
  label,
  errors,
  options,
  defaultValue,
  ...props
}) => {
  const errorMessages = get(errors, name);
  const hasError = !!(errors && errorMessages);

  useEffect(() => {
    if (props.setValue && defaultValue) {
      props.setValue(name, defaultValue, { shouldTouch: true });
    }
  }, [defaultValue]);

  return (
    <div className="form-group">
      <label htmlFor={props.id}>{label}</label>
      <div
        className={classNames({
          'd-flex': props.stackHorizontal,
          'mb-10': props.stackHorizontal
        })}
      >
        {options.map(
          ({ labelClass, helpText, defaultChecked, richlabel, ...option }) => {
            const { label: LabelJSX } = option;

            return (
              <div
                className={classNames('form-check mb-10', {
                  'mr-10': props.stackHorizontal
                })}
                key={option.id}
                aria-live="polite"
              >
                <Input
                  name={name}
                  {...option}
                  {...(register && register(name, rules))}
                  type={'checkbox'}
                  defaultChecked={defaultValue === option.value}
                />
                {richlabel ? (
                  <LabelJSX />
                ) : (
                  <label htmlFor={option.id} className="form-check-label">
                    <span className={labelClass}>{option.label}</span>
                    {helpText && <p className="">{helpText}</p>}
                  </label>
                )}
              </div>
            );
          }
        )}
      </div>
      {!props.disableError && <FieldError errors={errors} name={name} />}
    </div>
  );
};
