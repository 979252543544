import React, { useState, useEffect } from 'react';
import Alert from 'components/Alert';
import { useDispatch, useSelector } from 'react-redux';
import StatusTimeline from 'components/status-timeline';
import Loader from '../../components/Loader';
import { openFeedbackForm } from '../../actions/feedbackForm';
import { fetchHistoryLogs } from 'actions/history';
import { getEmailBriefing } from 'actions/emailbriefing/emailDesign';
import { getEmailCampaignStatusSteps } from 'selectors';
import { isEmpty } from 'lodash';

const EmailSubmit = props => {
  const [loading, setLoading] = useState(false);
  const emailProps = useSelector(state => ({
    historyLogs: state.historyLogs,
    emailBriefing: state.emailDesign.emailBriefing
  }));
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    const uid = props.match.params.id;
    dispatch(fetchHistoryLogs(uid));
    dispatch(getEmailBriefing(uid));
  }, []);

  useEffect(() => {
    setLoading(true);
    if (emailProps.emailBriefing && emailProps.historyLogs) {
      setLoading(false);
    }
  }, [emailProps]);

  const _handleOnOpenFeedback = event => {
    event.preventDefault();
    dispatch(
      openFeedbackForm({
        campaignUid: props.match.params.id,
        userId: props.authorizedUserData._id,
        defaultCampaignRequest: 'email',
        showCampaignRequest: false
      })
    );
  };

  const _showCampaignOverview = event => {
    event.preventDefault();
    props.history.push(
      '/campaign-wizard/emailbriefing/' + props.match.params.id
    );
  };

  const isDataAvailable = () => {
    return !isEmpty(emailProps.emailBriefing) && emailProps.historyLogs;
  };
  return (
    <div className="col-sm-12 skypink">
      <div className="cw-section">
        {loading ? (
          <Loader />
        ) : (
          <div>
            <div className="cw-section--title mb-4 mt-10">
              <br />
              <h2 className="cw-heading--secondary cw-email-heading">
                Campaign submitted
              </h2>
            </div>
            <div className="row">
              <div className="col-sm-8">
                <p className="mb-10">
                Great job! You are one step closer to reaching consumers' inbox!
                </p>
                <p className="mb-10">
                We now have the HTML of your email and will shortly begin the execution setup in ACS
                </p>
                <p className="mb-10">
                  <strong>What&#39;s next</strong>
                </p>
                <ul className="email-submit-page-ul">
                  <li>
                    Your campaign will go through a series of steps as indicated
                    below
                  </li>
                  <li>
                    We will keep you aware of the progress through automated
                    email notifications at key stages
                  </li>
                  <li>
                    You can also track all campaign requests and gain
                    inspiration on the{' '}
                    <a href="/campaign-wizard/email-dashboard">
                      <strong>campaigns dashboard</strong>
                    </a>
                  </li>
                </ul>
                {isDataAvailable() && (
                  <StatusTimeline
                    steps={getEmailCampaignStatusSteps(
                      emailProps.emailBriefing,
                      emailProps.historyLogs
                    )}
                    className="email-status-timeline"
                  />
                )}
              </div>
              <div className="col-sm-4 mb-4">
                <div className="btn-box-ml-auto mb-10">
                  <button
                    className="btn btn-primary mb-10"
                    onClick={_showCampaignOverview}
                  >
                    View campaign details
                  </button>
                </div>
                <Alert
                  alertType="feedbackFormMesg"
                  onFeedbackClick={_handleOnOpenFeedback}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EmailSubmit;
