import axios from 'axios';
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { msalConfig } from '../authConfig';
import { promises } from 'fs-extra';

export default () => {
  axios.interceptors.request.use(
    async config => {
      if ('post' === config.method && !config.disableLastUpdatedScreen) {
        let result = /[^/]*$/.exec(window.location.pathname)[0];
        if (config.data) {
          config.data.lastUpdatedScreen = result;
        }
      }
      // Append header to all the calls
      try {
        // JWT token
        const tokenResponse = localStorage.getItem('cwtoken');
        const cwApiKey = localStorage.getItem('cwApiKey');
        if (tokenResponse) {
          config.headers['Authorization'] = `Bearer ${tokenResponse}`;
        }
        if (cwApiKey) {
          config.headers['ApiKey'] = `${cwApiKey}`;
        }
        // const tokenResponse = await getAuthToken();
        // if (tokenResponse) {
        //   axios.defaults.headers.common[
        //     'Authorization'
        //   ] = `Bearer ${tokenResponse.idToken}`;

        //   config.headers['Authorization'] = `Bearer ${tokenResponse.idToken}`;
        // }
      } catch (error) {
        console.log('Token ERROR 4002 - ', error);
      }
      return config;
    },
    error => {
      // Do something with request error
      return Promise.reject(error);
    }
  );
};

const getAuthToken = async () => {
  const request = {
    scopes: ['User.Read']
  };
  if (window.msalInstance.getActiveAccount()) {
    return await window.msalInstance.acquireTokenSilent(request);
  }
};
