import * as yup from 'yup';
import { BeeRowTypeInput } from 'components/molecules/BeeRowTypeInput';
import FormWarningsInput from 'components/molecules/FormWarningsInput';
import constants from '../../constants';
import { SaveDisplayConditionInput } from 'components/SaveDisplayConditionInput';
import { get } from 'lodash';

const getDisplayCondition = selectedVariant => {
  switch (selectedVariant) {
    case 'variantA':
      return {
        id: 'variantA',
        type: 'A/B test',
        label: 'A/B test - Variant A',
        description: '',
        before: '{{#if VariantA}}',
        after: '{{/if}}'
      };
    case 'variantB':
      return {
        id: 'variantB',
        type: 'A/B test',
        label: 'A/B test - Variant B',
        description: '',
        before: '{{#if VariantB}}',
        after: '{{/if}}'
      };
    case 'variantC':
      return {
        id: 'variantC',
        type: 'A/B test',
        label: 'A/B test - Variant C',
        description: '',
        before: '{{#if VariantC}}',
        after: '{{/if}}'
      };
    default:
      return null;
  }
};

const hasVariantC = emailBrief => {
  const noOfVariants = get(emailBrief, 'abTesting.noOfABVariants', null);
  return noOfVariants && noOfVariants === '3' ? true : false;
};

export default (
  handleFormClose,
  handleSubmit,
  emailBriefing,
  savedDisplayCondition,
  assignedVariants
) => {
  const isEdit = get({}, '_id') ? true : false;
  return {
    formType: 'custombuttons',
    submitBtnLabel: isEdit ? 'Save as new' : 'Save and close',
    previousBtnLabel: 'Cancel',
    secondaryBtnLabel: 'Update existing',
    disableFormError: true,
    fields: [
      {
        type: 'custom',
        component: SaveDisplayConditionInput,
        name: 'variant',
        id: 'variant',
        label: 'Variant',
        showVariantC: hasVariantC(emailBriefing),
        savedDisplayCondition,
        assignedVariants,
        rules: yup
          .string()
          .required('Please select variant')
          .nullable()
      }
    ],
    onSubmit: (data, { user, setFormErrorMessage }) => {
      const displayCondition = getDisplayCondition(data.variant);
      handleSubmit(displayCondition, {setFormErrorMessage, savedDisplayCondition});
    },
    onPrevious: () => {
      handleFormClose();
    },
    onSecondaryClick: isEdit
      ? (data, { user, setFormErrorMessage }) => {
          const displayCondition = getDisplayCondition(data.variant);
          handleSubmit(displayCondition, {setFormErrorMessage, savedDisplayCondition, action: 'update'});
        }
      : null,
    isEdit: isEdit
  };
};
