import React, { useState, useEffect } from 'react';
import { get, isEmpty } from 'lodash';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { FormBuilder } from '../FormBuilder';
import { FormError } from '../../molecules/FormError';
import {
  firstAttemptSchema,
  followUpSchema,
  firstAndFollowUpSchema
} from './saveSubjectAndPreheaderSchema';
import { updateEmailBriefing } from 'services/emailBriefings';
import { _generateUUID } from 'selectors';
import { DONT_SEND } from 'components/molecules/EmailScheduleInput';
import { CustomButtons } from 'pages/EmailCampaignAudience';

const SaveSubjectAndPreheader = ({
  emailBriefing,
  onClose,
  isInFlyout = true,
  onSuccess,
  history,
  onPrevious,
  onSaveAndExit,
  authorizedUserData,
  formTriggerCallback
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [formData, setFormData] = useState({});
  const [firstAttemptError, setFirstAttemptError] = useState(true);
  const [followUpError, setFollowUpError] = useState(true);
  const [hasFormError, setHasFormError] = useState(true);
  const [firstLoad, setFirstLoad] = useState(true);
  const [formErrorMessage, setFormErrorMessage] = useState('');
  const [key1, setkey1] = useState(_generateUUID());
  const [key2, setkey2] = useState(_generateUUID());

  useEffect(() => {
    const firstAttempt = get(emailBriefing, 'firstAttempt', {});
    const followUp = get(emailBriefing, 'followUp', {});
    let flags = { firstAttempt: false, followUp: false };
    if (firstAttempt.subject && firstAttempt.preheader) {
      setFirstAttemptError(false);
      flags.firstAttempt = true;
    }
    if (followUp.subject && followUp.preheader) {
      setFollowUpError(false);
      flags.followUp = true;
    }
    if (flags.firstAttempt && flags.followUp) {
      setFirstLoad(false);
    }
    const someThings = {
      firstAttempt: {
        ...firstAttempt,
        subject: decodeURIComponent(firstAttempt.subject || ''),
        subjectVariantB: decodeURIComponent(firstAttempt.subjectVariantB || ''),
        subjectVariantC: decodeURIComponent(firstAttempt.subjectVariantC || '')
      },
      followUp: {
        ...followUp,
        subject: decodeURIComponent(followUp.subject || ''),
        subjectVariantB: decodeURIComponent(followUp.subjectVariantB || ''),
        subjectVariantC: decodeURIComponent(followUp.subjectVariantC || '')
      }
    };
    setFormData(someThings);
    setkey1(_generateUUID());
    setkey2(_generateUUID());
    return () => {};
  }, [emailBriefing]);

  const sendAfter = get(
    emailBriefing,
    'deliverySchedule.sendAfter.value',
    false
  );

  const isFollowUpSchema = sendAfter !== DONT_SEND;
  const onSubmit = (data, type) => {
    setFirstLoad(false);
    if (type === 'firstAttempt') {
      const formDataObj = { ...formData, firstAttempt: data };
      setFormData(formDataObj);
      setFirstAttemptError(false);
      //checkFormError(false, followUpError);
      if (followUpError) {
        // stay here
      } else {
        // submit the form
        window.alert('Final submit');
        const uid = get(emailBriefing, 'uid', null);
        if (uid) {
          updateEmailBriefing(uid, formDataObj).then(result => {
            if (result) {
              onClose();
            }
          });
        }
      }
    } else {
      const formDataObj = { ...formData, followUp: data };
      setFormData(formDataObj);
      setFollowUpError(false);
      //checkFormError(firstAttemptError, false);
      if (firstAttemptError) {
        // stay here
      } else {
        // submit the form
        window.alert('Final submit');
        //const payload = {...data};
        const uid = get(emailBriefing, 'uid', null);
        if (uid) {
          updateEmailBriefing(uid, formDataObj).then(result => {
            if (result) {
              onClose();
            }
          });
        }
      }
    }
    //checkFormError()
  };
  const handleFormExit = ({ data, fieldSchema }, type) => {
    setFirstLoad(false);
    if (type === 'firstAttempt') {
      setFormData({ ...formData, firstAttempt: data });
      fieldSchema.isValid(data).then(isDataValid => {
        setFirstAttemptError(!isDataValid);
        //checkFormError(!isDataValid, followUpError);
      });
    } else {
      setFormData({ ...formData, followUp: data });
      fieldSchema.isValid(data).then(isDataValid => {
        setFollowUpError(!isDataValid);
        //checkFormError(firstAttemptError, !isDataValid);
      });
    }
  };

  const handleFirstAttemptSubmit = (
    data,
    { isSaveAndExit } = {},
    { backToReview } = {}
  ) => {
    const uid = get(emailBriefing, 'uid', null);
    const status = get(emailBriefing, 'status', null);

    const payload = {
      firstAttempt: { subject: data.subject,
        subjectVariantB: data.subjectVariantB,
        subjectVariantC: data.subjectVariantC,
        preheader: data.preheader 
      },
      $unset: { followUp: '' },
      userName: data.userName,
      userEmail: data.userEmail
    };
    if (isSaveAndExit) {
      payload.status = status === '8' ? '8' : status;
    }
    if (uid) {
      updateEmailBriefing(uid, payload).then(result => {
        if (result) {
          isInFlyout
            ? onClose()
            : isSaveAndExit
            ? onSaveAndExit()
            : onSuccess({ backToReview });
        }
      });
    }
  };

  const handleFirstAndFollowUpSubmit = (
    data,
    { isSaveAndExit } = {},
    { backToReview } = {}
  ) => {
    const uid = get(emailBriefing, 'uid', null);
    const status = get(emailBriefing, 'status', null);

    const payload = {
      firstAttempt: {
        subject: data.firstAttemptSubject,
        subjectVariantB: data.subjectVariantB,
        subjectVariantC: data.subjectVariantC,
        preheader: data.firstAttemptPreheader
      },
      followUp: {
        subject: data.followUpSubject,
        subjectVariantB: data.followUpSubjectB,
        subjectVariantC: data.followUpSubjectC,
        preheader: data.followUpPreheader
      },
      userName: data.userName,
      userEmail: data.userEmail
    };
    if (isSaveAndExit) {
      payload.status = status === '8' ? '8' : status;
    }
    console.log('payload: ', payload);

    if (uid) {
      updateEmailBriefing(uid, payload).then(result => {
        if (result) {
          isInFlyout
            ? onClose()
            : isSaveAndExit
            ? onSaveAndExit()
            : onSuccess({ backToReview });
        }
      });
    }
  };

  const checkFormError = (firstAttemptErr, followUpErr) => {
    if (firstAttemptErr && !followUpErr) {
      setHasFormError(true);
      setFormErrorMessage('First attempts section has some missing configs');
    } else if (!firstAttemptErr && followUpErr) {
      setHasFormError(true);
      setFormErrorMessage('Follow up section has some missing configs');
    } else if (followUpErr && firstAttemptErr) {
      setHasFormError(true);
      setFormErrorMessage(
        'First attempts section and follow up section have some missing configs'
      );
    } else {
      setHasFormError(false);
      setFormErrorMessage('');
    }
  };
  const check1 = !firstLoad && (firstAttemptError || followUpError);
  const check2 = () => {
    if (firstAttemptError && !followUpError) {
      return 'First attempts section has some missing configs';
    } else if (!firstAttemptError && followUpError) {
      return 'Follow up section has some missing configs';
    } else if (followUpError && firstAttemptError) {
      return 'First attempts section and follow up section have some missing configs';
    } else {
      return '';
    }
  };

  const checkIfSubmitted = type => {
    return !isEmpty(formData[type]);
  };

  const checkAll = () => {
    if (
      formData.firstAttempt?.subject &&
      formData.firstAttempt?.preheader &&
      formData.followUp?.subject &&
      formData.followUp?.preheader
    ) {
      return false;
    }
    return true;
  };

  const isEmailBriefingData = !isEmpty(emailBriefing);

  return isEmailBriefingData? isFollowUpSchema ? (
    <div className={isInFlyout ? 'flyout-content' : ''}>
      <FormBuilder
        schema={firstAndFollowUpSchema(
          handleFirstAndFollowUpSubmit,
          formData,
          () => {},
          {
            isInFlyout,
            onPrevious,
            history,
            onDraft: values =>
              handleFirstAndFollowUpSubmit(values, { isSaveAndExit: true }),
            emailBriefing
          }
        )}
        key={key1}
        customButtonsComp={CustomButtons}
        user={authorizedUserData}
        remoteSubmit={formTriggerCallback}
      />
    </div>
  ) : (
    <>
    <FormBuilder
      schema={firstAttemptSchema(handleFirstAttemptSubmit, formData, () => {}, {
        isInFlyout,
        history,
        onPrevious,
        onDraft: values =>
          handleFirstAttemptSubmit(values, { isSaveAndExit: true }),
          emailBriefing
      })}
      key={key1}
      customButtonsComp={CustomButtons}
      user={authorizedUserData}
      remoteSubmit={formTriggerCallback}
    />
    </>
  ): null;
};

export default SaveSubjectAndPreheader;
