import axios from 'axios';
import constant from '../constants';

import {
  POST_SUBMIT_REVIEW,
  GET_REVIEW_CAMPAIGN,
  ASYNC_IN_PROGRESS,
  UDPATE_CAMPAIGN_DETAILS_REVIEW,
  SERVICE_ERROR_REVIEW
} from '../actionTypes';
import { epsilonMarket } from '../selectors/index';

import { getFormPreviewUrl } from './preview';
import { saveCampaignMasterData } from './masterData';

export const updateCampaignDetails = campaignDetails => ({
  type: UDPATE_CAMPAIGN_DETAILS_REVIEW,
  campaignDetails
});

export function getReviewCampaignDetails(campignId) {
  return function(dispatch) {
    axios
      .get(constant.serviceUrls.CAMPAIGN_DETAILS_SERVICE_URL + '/' + campignId)
      .then(response => {
        let campaign = response.data[0];
        let language = campaign.language && campaign.language.code;
        if (
          (campaign.isPlatformAgnostic ||
            (campaign && campaign.websiteType.name === 'Adobe SaaS')) &&
          campaign && !campaign.isMasterData
        ) {
          dispatch(getFormPreviewUrl(campignId, language));
        }
        dispatch({ type: ASYNC_IN_PROGRESS, isLoading: false });
        dispatch({ type: GET_REVIEW_CAMPAIGN, campaign });
      })
      .catch(error => {
        dispatch({ type: SERVICE_ERROR_REVIEW, error });
      });
  };
}

export function submitReviewData(
  Id,
  userName,
  userEmail,
  editCampaign,
  editCampaignDetails,
  country,
  isSaasEdited,
  editComments
) {
  return function(dispatch) {
    dispatch({
      type: ASYNC_IN_PROGRESS,
      isLoading: true
    });

    // Fetch Campaign Data
    let reviewSubmitData = {
      uid: Id,
      status: epsilonMarket(country) || isSaasEdited ? '3' : '6',
      userName: userName,
      userEmail: userEmail,
      editComments: editComments,
      lastUpdatedScreen: 'review',
      isEdited: isSaasEdited
    };
    if (editCampaign) {
      reviewSubmitData = {
        ...reviewSubmitData,
        campaignLog: editCampaignDetails
      };
    }

    // if(isSaasEdited){

    // }
    console.log('reviewSubmitData', reviewSubmitData);

    axios({
      method: 'POST',
      url: constant.serviceUrls.CAMPAIGN_STATUS_UPDATE_SERVICE_URL,
      data: reviewSubmitData
    })
      .then(response => {
        dispatch({ type: ASYNC_IN_PROGRESS, isLoading: false });
        dispatch({
          type: POST_SUBMIT_REVIEW,
          isSubmitted: true,
          isEdited: isSaasEdited
        });
      })
      .catch(error => {
        dispatch({ type: ASYNC_IN_PROGRESS, isLoading: false });
        dispatch({ type: SERVICE_ERROR_REVIEW, error });
      });
  };
}

export function submitCampaignDetails(data, userName, userEmail) {
  const campaignDetails = {
    ...data,
    userName: userName,
    userEmail: userEmail
  };
  return function(dispatch) {
    axios
      .post(constant.serviceUrls.CAMPAIGN_DATA_SERVICE_URL, campaignDetails)
      .then(response => {
        dispatch({ type: ASYNC_IN_PROGRESS, isLoading: false });
      });
  };
}
