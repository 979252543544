import { get } from 'lodash';

import { SMSCustomCriteriaLabel } from 'components/molecules/SendEmailToInput';

export const audienceOverviewDisplay = (audienceDefinition, campaignConfig) => {
  const campaignIds = [];
  const customCriteria = [];

  if (
    audienceDefinition?.sendSMSTo?.type?.value ===
    'consumersLinkedToCampaignIds'
  ) {
    let value = audienceDefinition?.sendSMSTo?.details?.campaignIds;

    if (value && value.length && typeof value[0] === 'object') {
      value = value.map(campaignIdObject => campaignIdObject.value);
    }
    campaignIds.push({
      label: `${campaignConfig.LABEL_SIGN_UP_ID}(s)`,
      value: value.join(', '),
      tooltip: `Campaign IDs are now known as ${campaignConfig.LABEL_SIGN_UP_ID}s to better explain <br /> the purpose of the field to uniquely identify the initiative <br /> through which a consumer signed up`
    });
  }

  if (audienceDefinition?.sendSMSTo?.type?.value === 'customCriteria') {
    const {
      ageRange,
      audienceFileName,
      gender,
      last,
      unit,
      pastSMSEngagementCriteria,
      customCriteriaList,
      criteriaCampaignIds
    } = get(audienceDefinition, 'sendSMSTo.details', {});
    if (customCriteriaList && customCriteriaList.length) {
      customCriteria.push({
        label: 'Custom criteria',
        value: customCriteriaList
          .map(g => SMSCustomCriteriaLabel(campaignConfig)[g])
          .join(', ')
      });
    }
    if (criteriaCampaignIds && criteriaCampaignIds.length) {
      customCriteria.push({
        label: `${campaignConfig.LABEL_SIGN_UP_ID}(s)`,
        value: criteriaCampaignIds.map(campaign => campaign.value).join(', '),
        tooltip: `Campaign IDs are now known as ${campaignConfig.LABEL_SIGN_UP_ID}s to better explain <br /> the purpose of the field to uniquely identify the initiative <br /> through which a consumer signed up`
      });
    }
    if (customCriteriaList && customCriteriaList.includes('standardProfile')) {
      if (gender && gender.length) {
        customCriteria.push({
          label: 'Gender',
          value: gender.map(g => g.label).join(', ')
        });
      } else {
        customCriteria.push({
          label: 'Gender',
          value: 'All genders'
        });
      }
    }

    if (pastSMSEngagementCriteria) {
      customCriteria.push({
        label: 'Past SMS engagement',
        value: pastSMSEngagementCriteria.label
      });
    }
    if (last && unit) {
      customCriteria.push({
        label: 'Duration',
        value: `Last ${last.label} ${unit.label}`
      });
    }
    if (audienceFileName) {
      customCriteria.push({
        label: 'Audience name from ACT',
        value: audienceFileName
      });
    }
    if (ageRange && ageRange.length) {
      customCriteria.push({
        label: 'Age range',
        value: ageRange.join(' - ')
      });
    }
    customCriteria.push({
      label: 'Custom criteria details',
      value: audienceDefinition?.sendSMSTo?.details?.customCriteriaDetails
    });
  }

  return {
    campaignIds,
    customCriteria
  };
};
