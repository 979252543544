import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash'
import Loader from '../../components/Loader';
import ProgressBar from 'components/molecules/ProgressBar';
import { getEmailCampaignDetails } from 'actions/emailbriefing/emailCampaignDetails';
import PageHeader from 'components/organisms/PageHeader/PageHeader';
import SaveSubjectAndPreheader from 'components/organisms/BeeEmailEditor/saveSubjectAndPreheader';
import { getIsEdited } from 'pages/NewEmailCampaign/brandDetailsformSchema';
import constants from '../../constants';
import { setNotificationMessage } from 'actions/dashboard';

const EmailProperties = props => {
  const { history, match } = props;
  const { isLoading } = useSelector(state => state.emailCampaignDetails);
  const { authorizedUserData } = useSelector(state => state.authorized);

  const dispatch = useDispatch();

  useEffect(() => {
    const pathUid = props.match.params.id;
    if (pathUid) {
      dispatch(getEmailCampaignDetails(pathUid));
    }
  }, []);

  const { emailBriefing } = useSelector(state => state.emailDesign);

  const handlePrevious = () => {
    const uid = match.params.id;
    history.push(`/campaign-wizard/emailbriefing/${uid}/email-audience`);
  };

  const isEditJourney = getIsEdited({ history: history });

  const handleSuccess = ({ backToReview } = {}) => {
    const uid = match.params.id;
    if (backToReview && isEditJourney) {
      history.push(`/campaign-wizard/emailbriefing/${uid}/review`);
    } else {
      history.push(`/campaign-wizard/emailbriefing/${uid}/design-your-email`);
    }
  };

  const handleSaveAndExit = () => {
    let campaignName = get(emailBriefing,'summary.campaignName');
    const uid = match.params.id;
        dispatch(
          setNotificationMessage(
            constants.CAMPAIGN_SAVED_AS_DRAFT_TEXT,
            `/campaign-wizard/emailbriefing/${uid}`,
            'success',
            campaignName,
            'emailCampaignDraft'
          )
        );
    history.push('/campaign-wizard/email-dashboard');
  };

  return (
    <div className="col-sm-12 skypink">
      <div className="cw-section">
        <ProgressBar customClass={'cw-email-progress-custom'} pageNo={constants.EMAIL_JOURNEY_PAGE_NUMBERS.EMAIL_PROPERTIES} />
        <PageHeader heading="Email properties" form />
        <div className="row">
          {isLoading ? <Loader /> : null}
          <div className="col-md-8">
            <SaveSubjectAndPreheader
              isInFlyout={false}
              history={history}
              onSaveAndExit={handleSaveAndExit}
              onPrevious={handlePrevious}
              onSuccess={handleSuccess}
              emailBriefing={emailBriefing}
              authorizedUserData={authorizedUserData}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailProperties;
