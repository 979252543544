import React, { useEffect, useState } from 'react';
import { fetchHistoryLogs } from 'actions/history';
import { useDispatch, useSelector } from 'react-redux';
import HistoryComponent from 'components/history';
import { getEmailBriefing } from 'actions/emailbriefing/emailDesign';
import Toolbar from 'components/common/toolbar';
import Layout from 'components/organisms/Layout/Layout';
import PageHeader from 'components/organisms/PageHeader/PageHeader';
import { openFeedbackForm } from 'actions/feedbackForm';
import constants from 'constants/config';
import TemplatePreview from 'components/organisms/TemplatePreview/TemplatePreview';
import EmailTest from 'components/EmailTest';
import Modal from 'react-modal';
import EmailSummaryCards from 'components/organisms/EmailSummaryCards/EmailSummaryCards';
import EmailOverviewTables from 'components/organisms/EmailOverviewTables/EmailOverviewTables';
import { exportEmailHTML } from 'services/emailTemplateEdit';
import {
  deleteEmailCampaignDetails,
  updateEmailCampaignDetails
} from 'actions/emailbriefing/emailCampaignDetails';
import { setNotificationMessage } from 'actions/dashboard';
import Alert from 'components/Alert';
import BackAndNext from 'components/organisms/BackAndNext/BackAndNext';
import EmailScheduleModel from 'components/organisms/EmailScheduleModel';
import EmailLiveModel from 'components/organisms/EmailLiveModel';
import TemplatePreviewOverlay from 'pages/EmailCampaignTemplate/templatePreviewOverlay';
import ABTestResultsOverlay from './abTestResultsOverlay';
import EmailGuideline from 'components/EmailGuideline';
import EmailGuidelineText from 'components/organisms/EmailGuidelineText/EmailApproverGuideline';
import WinningVariantInfo from 'components/organisms/WinningVariantInfo';
import { get } from 'lodash';
import { checkIsEmailAdminOrACS } from 'selectors';
import NotificationMessage from 'components/Messages';
import EmailRequestInfoModel from 'components/organisms/EmailRequestInfoModel';
import Loader from 'components/Loader';

const EmailExecution = props => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalSchduleOpen, setModalScheduleOpen] = useState(false);
  const [modalLiveOpen, setModalLiveOpen] = useState(false);
  const [isTemplatePreviewOpen, setIsTemplatePreviewOpen] = useState(false);
  const [selectedTemplateForPreview, setSelectedTemplateForPreview] = useState(
    {}
  );
  const [acsGuideModel, setAcsGuideModel] = useState(false);
  const [alertMessage, setAlertMessage] = useState("")
  const [alertType, setAlertType] = useState("")
  const [isAbTestResultOverlayOpen, setIsAbTestResultOverlayOpen] = useState(false);
  const [requestModelInfoOpen, setRequestModelInfoOpen] = useState(false);
  const { history, match } = props;
  const dispatch = useDispatch();
  useEffect(() => {
    const uid = props.match.params.id;
    dispatch(fetchHistoryLogs(uid));
    dispatch(getEmailBriefing(uid));
  }, []);

  const emailProps = useSelector(state => ({
    historyLogs: state.historyLogs,
    emailBriefing: state.emailDesign.emailBriefing,
    isLoading: state.emailDesign.isLoading,
    campaignConfig: state.app.campaignConfig
  }));
  const { authorizedUserData } = useSelector(state => state.authorized);

  if (!emailProps.emailBriefing) return null;

  const { summary, status, emailTemplateEditId, rejectComments ,requestInfoAnswerText,requestInfoText,nmiAssignedToRequestor} = emailProps && emailProps.emailBriefing;
  const { id: uid } = match.params;
  const campaignName = (summary && summary.campaignName) || 'Untitled';
  const { isLoading } = emailProps;
  const handleOnOpenFeedback = () => {
    dispatch(
      openFeedbackForm({
        campaignUid: props.match.params.id,
        userId: props.authorizedUserData._id,
        defaultCampaignRequest: 'email',
        showCampaignRequest: false
      })
    );
  };

  const openTestEmailModel = () => {
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };
  /**
   * Export button click handler
   */
  const onExportHandler = () => {
    const res = exportEmailHTML(
      props.match.params.id,
      props.userEmail,
      props.userName,
      { campaignName }
    );
  };

  const deleteEmailBriefing = () => {
    dispatch(
      deleteEmailCampaignDetails(uid, () => {
        dispatch(
          setNotificationMessage({
            message: `${campaignName} has been deleted.`,
            type: 'warning'
          })
        );
        history.push(`/campaign-wizard/email-dashboard`);
      })
    );
  };

  const continueCampaign = () => {
    history.push(`/campaign-wizard/emailbriefing/${uid}/branddetails`);
  };

  const handleMarkScheduleClick = () => {
    setModalScheduleOpen(true);
  };

  const closeScheduleModelClick = ({ showAlertMessage = false } = {}) => {
    setModalScheduleOpen(false);
    if (showAlertMessage) {
      setAlertMessage(
        constants.EMAIL_CAMPAIGN_APPROVAL_MESG.acsScheduled.message
      );
      setAlertType(constants.EMAIL_CAMPAIGN_APPROVAL_MESG.acsScheduled.type);
      window.scroll({
        top: -100,
        left: 0,
        behavior: 'smooth'
      });
    }
  };
  const handleMarkLiveClick = () => {
    setModalLiveOpen(true);
  };
  const closeLiveModelClick = () => {
    setModalLiveOpen(false);
    history.push(`/campaign-wizard/emailbriefing/${uid}`);
  };

  const closeLiveModelOnClick = () => {
    setModalLiveOpen(false);
  };

  const isOpenAcsGuidelineModel = () => {
    setAcsGuideModel(true);
  };

  const isCloseAcsGuidelineModel = () => {
    setAcsGuideModel(false);
  };

  const isDraft = String(status) === '1' || String(status) === '5' ||  (String(status) === '8' &&  nmiAssignedToRequestor == undefined) || (String(status) === '8' &&  nmiAssignedToRequestor === true);
  const isAwaitingSetup =
    String(status) === constants.EMAIL_STATUS.AWAITING_SETUP || (String(status) === '8' && nmiAssignedToRequestor === false);

  const isEmailAdminOrACS = checkIsEmailAdminOrACS(authorizedUserData);

  const onClickEdit = () => {
    history.push({
      pathname: `/campaign-wizard/emailbriefing/${uid}/email-editor`,
      state: {
        isEdited: true
      }
    });
  };

  const editProps = {};
  if (isAwaitingSetup && isEmailAdminOrACS) {
    editProps.onClickEdit = onClickEdit;
  }

  const handleTemplatePreview = template => {
    setIsTemplatePreviewOpen(true);
    setSelectedTemplateForPreview(template);
  };

  const handleCloseTemplatePreview = () => {
    setIsTemplatePreviewOpen(false);
    setSelectedTemplateForPreview({});
  };

  const refetchEmailBriefing = () => {
    const uid = props.match.params.id;
    // if (!emailBriefing || emailBriefing.uid !== uid) {
    dispatch(getEmailBriefing(uid, {disableLoader : true}));
  };

  const handleAbTestResultSubmit = (data, { user, setFormErrorMessage }) => {
    const paramUid = match.params.id;
    const dataToSend = {
      ...data,
      userEmail: user.emailId,
      userName: user.name
    };

    dispatch(
      updateEmailCampaignDetails(paramUid, dataToSend, (error, result) => {
        if (result) {
          setIsAbTestResultOverlayOpen(false);
        }
        if (error) {
          setFormErrorMessage(
            'Someting went wrong will saving AB test results. Please try again later'
          );
        }
      })
    );
  };

  const handleRequestInfoClick = () => {
    setRequestModelInfoOpen(true);
  };

  const closeRequestInfoModelClick = (redirect) => {
    setRequestModelInfoOpen(false);
    if(redirect) {
      history.push(`/campaign-wizard/emailbriefing/${uid}`);
    }
  };

  const showEnterABTestResult = constants.EMAIL_STATUS.LIVE === status && get(emailProps.emailBriefing, "abTesting.performABTests")
  const winningVariant = constants.EMAIL_STATUS.LIVE === status && get(emailProps.emailBriefing, "winningVariant", '')

  return isLoading? <Loader /> : (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="cw-modal cw-modal--test-email-model"
        contentLabel="Field Types"
        style={{
          overlay: {
            backgroundColor: constants.EMAIL_OVERLAY_BACKGROUND
          }
        }}
      >
        <EmailTest
          onClose={closeModal}
          userEmail={props.userEmail}
          uid={props.match.params.id}
          userName={props.userName}
          userGroup={props.authorizedUserData.group}
          emailBriefing={emailProps.emailBriefing}
          refetchEmailBriefing={refetchEmailBriefing}
        />
      </Modal>
      <EmailScheduleModel
        modalIsOpen={modalSchduleOpen}
        closeModal={closeScheduleModelClick}
        emailBriefing={emailProps && emailProps.emailBriefing}
        pathUid={uid}
      />
       <EmailRequestInfoModel
        modalIsOpen={requestModelInfoOpen}
        closeModal={closeRequestInfoModelClick}
        emailBriefing={emailProps && emailProps.emailBriefing}
        pathUid={uid}
      />
      <EmailLiveModel
        modalIsOpen={modalLiveOpen}
        closeModal={closeLiveModelClick}
        closeModelLiveClick={closeLiveModelOnClick}
        emailBriefing={emailProps && emailProps.emailBriefing}
        pathUid={uid}
      />
      <Modal
        scrollable={true}
        isOpen={acsGuideModel}
        onRequestClose={isCloseAcsGuidelineModel}
        className="cw-modal cw-modal--qnadetail"
        contentLabel="Campaign Wizard 2.0"
        style={{
          overlay: {
            backgroundColor: constants.EMAIL_OVERLAY_BACKGROUND
          },
          content: {
            maxHeight: '90vh'
          }
        }}
      >
        <EmailGuideline
          closeIsEditModal={isCloseAcsGuidelineModel}
          data={constants.EMAIL_ACS_GUIDE_LINES}
          isEmailCampaign={true}
        />
      </Modal>
      <div className="skypink ml-5 mr-5">
        <div className="cw-section cw-overview">
          <div className="draft_button_section_main">
            <Alert
              type={'warning'}
              message={rejectComments ? rejectComments : ''}
              alertType={'rejectcomment'}
              showComponent={status === '5'}
            />
            
            <Alert
              type={'warning'}
              className={'mt-4'}
              message={requestInfoText ? requestInfoText : ''}
              alertType={'requestMoreInfo'}
              showComponent={status === '8'}
              title={(nmiAssignedToRequestor == undefined || nmiAssignedToRequestor) ?`ACS team has requested more from requestor  ` :  `ACS team is awaiting more information internally - there is no next step on requestor `}
            /> 

             <Alert
              type={'warning'}
              className={'mb-5'}
              message={requestInfoText ? requestInfoText : ''}
              alertType={'requestMoreInfo'}
              showComponent={status === '4' && requestInfoText}
              title={`ACS team requested more information`}
             /> 
             
             <Alert
              type={'warning'}
              message={requestInfoAnswerText ? requestInfoAnswerText : ''}
              alertType={'requestMoreInfo'}
              showComponent={status === '4' && requestInfoAnswerText}
              title={`Requestor has provided more information`}
             /> 
            <NotificationMessage
              showcomponent={alertMessage}
              message={alertMessage}
              type={alertType}
              link={''}
              campaignname={''}
              onClose={() => {
                setAlertMessage('');
                setAlertType('');
              }}
            />
            <PageHeader
              heading={(summary && summary.campaignName) || 'Untitled'}
              status={status}
              subHeading={
                isDraft ? 'Continue creating your campaign' : undefined
              }
              review
            />
            <div className="row">
              <div className={`col-12 cw-print-hide`}>
                <div className="toolbar-scroll-fix">
                  <Toolbar
                    showPrint={true}
                    showFeedbackLink={
                      constants.featureFlags.ENABLE_FEEDBACK_FORM
                    }
                    // onDelete={deleteEmailBriefing}
                    onOpenFeedback={handleOnOpenFeedback}
                    showMarkAsScheduled={
                      constants.EMAIL_STATUS.AWAITING_SETUP === status || ( constants.EMAIL_STATUS.AWAITING_INFO === status && nmiAssignedToRequestor === false)
                    }
                    markAsScheduledOnClick={handleMarkScheduleClick}
                    showMarkAsLive={constants.EMAIL_STATUS.SCHEDULED === status}
                    markAsLiveOnClick={handleMarkLiveClick}
                    showEnterABTestResult={showEnterABTestResult}
                    enterABTestResultOnClick={() => {setIsAbTestResultOverlayOpen(true);}}
                    winningVariant={winningVariant}
                    showRequestMoreInfo ={constants.EMAIL_STATUS.AWAITING_SETUP === status || ( constants.EMAIL_STATUS.AWAITING_INFO === status && nmiAssignedToRequestor === false)}
                    onOpenRequestInfo ={handleRequestInfoClick}
                  />
                </div>
              </div>
              <br />
            </div>
            <div className="ml-2 mb-30">
              <EmailGuidelineText
                isOpenGuidelineModel={isOpenAcsGuidelineModel}
                titleText={`Please setup the email campaign in ACS using these `}
                guideLineName={`setup guidelines`}
              />
            </div>
          </div>
          {isDraft ? (
            <div className="mb-30 d-flex justify-content-end">
              <span className="btn-group-vertical px-10">
                <button
                  className="btn btn-primary mr-3 rounded "
                  onClick={continueCampaign}
                >
                  Continue Campaign
                </button>
              </span>
            </div>
          ) : null}
          <Layout
            sidebar={
              <div className="winning-variant-wrapper">
                <TemplatePreview
                  template={
                    emailTemplateEditId && emailTemplateEditId.templateObject
                  }
                  hideActionBar={false}
                  emailBriefing={emailProps.emailBriefing}
                  thumbnail={
                    emailTemplateEditId && emailTemplateEditId.thumbnail
                  }
                  title={emailTemplateEditId && emailTemplateEditId.title}
                  sentTestEmailOnClick={openTestEmailModel}
                  {...editProps}
                  isExportButton={true}
                  onExport={onExportHandler}
                />
                <WinningVariantInfo
                  className="mt-10"
                  emailBriefing={emailProps.emailBriefing}
                />
              </div>
            }
          >
            <EmailSummaryCards emailProps={emailProps} />
            <EmailOverviewTables
              emailProps={emailProps}
              handleTemplatePreview={handleTemplatePreview}
            />
            {constants.EMAIL_STATUS.SCHEDULED === status ? (
              <BackAndNext
                backTitle=""
                onClickBack={''}
                onClickNext={handleMarkLiveClick}
                nextTitle={'Mark as Live'}
              />
            ) : (
              ''
            )}

            {constants.EMAIL_STATUS.AWAITING_SETUP === status || ( constants.EMAIL_STATUS.AWAITING_INFO === status && nmiAssignedToRequestor === false)? (
              <div className="mb-20">
                <BackAndNext
                  backTitle="Mark as scheduled"
                  onClickBack={handleMarkScheduleClick}
                  nextTitle="Request more info"
                  onClickNext={handleRequestInfoClick}
                  invertStyles={true}
                />
              </div>
            ) : (
              ''
            )}

            <div className="overview-page-history mt-20">
              {emailProps.historyLogs && emailProps.historyLogs.length ? (
                <HistoryComponent
                  historyLogs={emailProps.historyLogs}
                  isEmailBrief={true}
                />
              ) : null}
            </div>
          </Layout>
        </div>
      </div>
      <TemplatePreviewOverlay
        isOpen={isTemplatePreviewOpen}
        selectedTemplateForPreview={selectedTemplateForPreview}
        handleCloseForm={handleCloseTemplatePreview}
        hideSelectBtn
      />
      <ABTestResultsOverlay
        isOpen={isAbTestResultOverlayOpen}
        winningVariant={winningVariant}
        handleCloseForm={() => {
          setIsAbTestResultOverlayOpen(false);
        }}
        handleSubmit={handleAbTestResultSubmit}
        user={authorizedUserData}
        emailBriefing={emailProps.emailBriefing}
      />
    </>
  );
};

export default EmailExecution;
