import {
  EMAIL_ASYNC_IN_PROGRESS,
  RESET_EMAIL_BRAND_DETAILS_PAGE,
  SET_EMAIL_BRAND_DETAILS,
  EMAIL_CROSS_BRAND,
  FORCE_EMAIL_Type
} from '../../actionTypes';

const initialState = {
  isLoading: false,
  country: null,
  brand: null,
  language: null,
  uid: null,
  isUpdated: false,
  isCrossBrand: false,
  forceEmailType: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case EMAIL_ASYNC_IN_PROGRESS: {
      return { ...state, isLoading: action.isLoading };
    }

    case SET_EMAIL_BRAND_DETAILS: {
      let brandDetails = action.brandDetails;
      return { ...state, ...brandDetails };
    }

    case EMAIL_CROSS_BRAND: {
      return { ...state, isCrossBrand: !state.isCrossBrand };
    }

    case FORCE_EMAIL_Type: {
      return { ...state, forceEmailType: action.forceEmailType };
    }

    case RESET_EMAIL_BRAND_DETAILS_PAGE: {
      return initialState;
    }

    default:
      return state;
  }
}
