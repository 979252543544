import {
  FETCH_BRAND_DEFAULTS_HISTORY_LOGS,
  FETCH_BRAND_DEFAULTS_HISTORY_LOGS_SUCCESS,
  FETCH_BRAND_DEFAULTS_HISTORY_LOGS_FAILURE
} from '../actionTypes';
import { fetchBrandDefaultsHistoryLogs as fetchBrandDefaultsHistoryLogsService } from 'services/brandDefaultsHistoryLogsService';

export const fetchBrandDefaultsHistoryLogs = brand => {
  return async function(dispatch) {
    try {
      dispatch({ type: FETCH_BRAND_DEFAULTS_HISTORY_LOGS });
      const brandDefaultsHistoryLogs = await fetchBrandDefaultsHistoryLogsService(
        brand
      );

      dispatch({
        type: FETCH_BRAND_DEFAULTS_HISTORY_LOGS_SUCCESS,
        payload: brandDefaultsHistoryLogs
      });
    } catch (error) {
      dispatch({
        type: FETCH_BRAND_DEFAULTS_HISTORY_LOGS_FAILURE,
        payload: error
      });
    }
  };
};
