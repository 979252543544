import * as yup from 'yup';
import { transformEmailBriefingData } from 'selectors';
import { BeeRowTypeFilter } from 'components/molecules/BeeRowTypeInput';
import constants from '../../constants';
import { get } from 'lodash';
export default (
  dispatch,
  handleFormClose,
  handleSubmit,
  emailBriefing = {},
  { countries, brands, customFilters }
) => {
  const countiesList = countries
    ? countries.map(elem => ({
        id: elem._id,
        label: elem.name,
        value: elem.code
      }))
    : [];
  const brandsList = brands
    ? brands.map(elem => ({ id: elem._id, label: elem.name, value: elem.code }))
    : [];

  const transformedData = transformEmailBriefingData(
    emailBriefing,
    'branddetails'
  );
  return {
    formType: 'modal',
    submitBtnLabel: 'Find',
    previousBtnLabel: 'Cancel',
    disableFormError: true,
    fields: [
      {
        type: 'reactselect',
        name: 'rowCategory',
        id: 'rowCategory',
        label: 'Tile category',
        placeholder: 'Please select',
        defaultValue: get(customFilters, 'rowCategory', []),
        options: constants.EMAIL_SAVE_ROW_CATEGORIES,
        isMulti: true 
      },
      {
        type: 'custom',
        name: 'rowType',
        component: BeeRowTypeFilter,
        id: 'rowType',
        label: 'Tile context',
        defaultValue: customFilters,
        countryOptions: [{ id: '', value: '', label: 'Any' }, ...countiesList],
        brandOptions: [{ id: '', value: '', label: 'Any' }, ...brandsList],
        emailBriefing
      }
    ],
    onSubmit: (data, { user }) => {
      handleSubmit({ ...data });
    },
    onPrevious: () => {
      handleFormClose();
    }
  };
};
