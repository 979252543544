import React, { forwardRef } from 'react';
import classNames from 'classnames';

export const SelectInput = forwardRef(
  (
    { id, name, type, label, placeholder, options, hasError, ...props },
    ref
  ) => {
    return (
      <select
        className={classNames('form-control', { 'is-invalid': hasError })}
        id={id}
        ref={ref}
        name={name}
        type={type}
        aria-label={label}
        placeholder={placeholder}
        {...props}
      >
        {options &&
          options.map(item => (
            <option key={item.value} value={item.value}>
              {item.label}
            </option>
          ))}
      </select>
    );
  }
);
