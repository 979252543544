import React from 'react';
import Modal from 'react-modal';
import BackAndNext from 'components/organisms/BackAndNext/BackAndNext';
import constants from 'constants/config';

export default function ConfirmChangeTemplateModal({
  isModalOpen,
  closeModal,
  onSelect,
  handleResume
}) {
  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      className="cw-modal cw-modal--confirm-change"
      contentLabel="Approval"
      style={{
        overlay: {
          backgroundColor: constants.EMAIL_OVERLAY_BACKGROUND,
        }
      }}
    >
      <button className="cw-modal--close" title="Close" onClick={closeModal}>
        <i className="fas fa-times"></i>
      </button>
      <p className="mb-10" style={{ color: 'var(--color-primary)' }}>
        Changing your template
      </p>
      <p className="mb-40" style={{ fontSize: 14 }}>
        You are changing your template selection and it may cause you to loose
        any changes previously made to your email body design.
      </p>
      <BackAndNext
        nextTitle="Change template"
        onClickNext={onSelect}
        backTitle="Resume current"
        onClickBack={handleResume}
        invertStyles
      />
    </Modal>
  );
}
