import React, { useEffect, useState } from 'react';
import SearchIcon from '../images/find.png';
import FilterIcon from '../images/filters.png';
import Autocomplete from 'react-autocomplete';
import ReactTooltip from 'react-tooltip';
import { UnstyledButton } from './atoms/UnstyledButton';

const SmsAdvanceSearch = props => {
  const { placeholder, searchEvent, email, className,toggleFilters = () => {}} = props;
  const [selectedItem, setSelectedItem] = useState(null);
  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  const onChange = e => {
    setQuery(e.target.value);
    setSelectedItem(null);
    // searchEvent(e.target.value);
  };

  const onSelect = (val, item) => {
    setQuery(val);
    setSelectedItem(item);
  };

  const handleSearch = () => {
    if (email) {
      searchEvent(query);
    } else if (selectedItem || query) {
      if (selectedItem) {
        const { campaignId = '', name = '' } = selectedItem;
        searchEvent([campaignId, name]);
      } else {
        searchEvent(['', '', query]);
      }
    }
  };

  const getItemValue = item => item.label;

  useEffect(() => {
    props.value !== undefined && setQuery(props.value);
  }, [props.value]);

  return (
    <div className={`cw-listing d-flex flex-grow-1 align-items-center ${className}`}>
      <ReactTooltip
        id="filterIconTooltip"
        place="right"
        type="info"
        className="cw-toolip custom-cw-toolip w-auto"
      />
      <span
        data-tip="Filters"
        data-for="filterIconTooltip"
      >
        <UnstyledButton className="filter-icon filter-icon-btn" onClick={toggleFilters}><i className="fas fa-filter"></i></UnstyledButton>
      </span>
      
      <div className="d-flex flex-grow-1 align-items-center">
        <Autocomplete
          inputProps={{
            id: 'campaignIdAutocomplete',
            className: 'search-input cross',
            placeholder:
              placeholder ||
              `Search using campaign name'
              }`,
            onKeyPress: e => {
              if (e.key === 'Enter' && query) {
                handleSearch();
              }
            }
          }}
          wrapperProps={{
            className: 'search-input-wrapper'
          }}
          autoHighlight={false}
          getItemValue={getItemValue}
          items={suggestions}
          value={query || props.value}
          onChange={onChange}
          onSelect={onSelect}
          renderMenu={SuggestionMenu}
          renderInput={props => (
            <div className="input-clear">
              <input {...props} />
              {query.trim().length > 0 && (
                <span
                  className="input-clear__cross"
                  onClick={() => {
                    onChange({ target: { value: '' } });
                  }}
                >
                  <i className="fas fa-times"></i>
                </span>
              )}
            </div>
          )}
          renderItem={SuggestionItem}
        />
        <button className="search-button" onClick={handleSearch}>
          <img src={SearchIcon} alt="Search" />
        </button>
      </div>
    </div>
  );
};

const SuggestionItem = (item, isHighlighted) => {
  return (
    <div
      className={`suggestion-item  ${isHighlighted ? 'item-highlighted' : ''}`}
      key={item._id}
    >
      <p>{item.label}</p>
      <p>{item.campaignId}</p>
    </div>
  );
};

const SuggestionMenu = children => {
  return children && children.length ? (
    <div className="menu">{children}</div>
  ) : (
    <></>
  );
};

export default SmsAdvanceSearch;
