import React from 'react';
import Modal from 'react-modal';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Icon } from 'semantic-ui-react';

import { TabPanel } from 'pages/ReportingDashboard/ReportingDashboard';
import { FormBuilder } from 'components/organisms/FormBuilder';
import generateImagePathSchema from './GenerateImagePathSchema';
import constants from 'constants/config';

const GenerateImagePathModal = ({
  isOpen,
  handleCloseForm,
  type,
  copyToClipboard,
  isCopyUrl,
  currentImage,
  handleSubmit
}) => {
  Modal.setAppElement('#root');

  const [activeTab, setActiveTab] = React.useState(0);

  const tabStyle = { fontFamily: 'Open Sans', textTransform: 'none' };

  const handleChange = (e, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleCloseForm}
      className="cw-modal cw-modal--filePicker-info"
      contentLabel="Save row form"
      style={{
        overlay: {
          backgroundColor: constants.EMAIL_OVERLAY_BACKGROUND
        }
      }}
    >
      <div className="email-overlay-wrapper image-guideline">
        <div className="cw-heading--saascampaign send-test-email__title mb-20">
          Generate image path
          <span
            className="qna-guideline-close email-overlay-close-modal"
            onClick={handleCloseForm}
          ></span>
        </div>

        <Tabs
          value={activeTab}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          disableRipple
          style={{ borderBottom: '1px solid #aaa', marginBottom: 20 }}
        >
          <Tab disableRipple style={tabStyle} label="Enter image details" />
          <Tab disableRipple style={tabStyle} label="Image guidelines" />
        </Tabs>

        <TabPanel value={activeTab} index={0}>
          <FormBuilder
            schema={generateImagePathSchema(
              handleSubmit,
              handleCloseForm,
              currentImage
            )}
            isInModal
          />
        </TabPanel>

        <TabPanel value={activeTab} index={1}>
          <div className="d-flex mb-20 ml-10">
            <div className="scrollable email-overlay-scroll">
              <p>
                Campaign Wizard accepts images uploaded on TAB i.e. Unilever's asset bank only.
              </p>
              <div className="ml-20 mr-20">
                <ul className="mt-20">
                  <li>
                    <strong>Step 1</strong> :&nbsp;
                    <a
                      href="https://theassetbank.unilever.com/en-us/create?tab3094231=UPLOAD+%26+EDIT+METADATA"
                      target="_blank"
                    >
                      Upload your image <Icon name="external" />
                    </a>
                    &nbsp;to TAB or find an existing image. Ensure it is marked
                    public with attribute 'Share to website' as 'Yes'
                  </li>
                  <li>
                    <strong>Step 2</strong> : Copy the asset ID of the public
                    image from TAB (E.g. 1282798)
                  </li>
                  <li>
                    <strong>Step 3</strong> : While creating your email in
                    Campaign Wizard, click on the image block to view image
                    properties in the right side panel. Click on 'Specify image'
                    button to enter TAB ID, image extension ( .jpg, .png, etc.)
                    Note that .png is recommended for transparent images and
                    .jpg for images with backgrounds
                  </li>
                  <li>
                    <strong>Step 4</strong> : Click on generate URL for Campaign
                    Wizard to auto-populate the image URL in the expected
                    format. The URL would be in the given format&nbsp;
                    <b>
                      https://assets.unileversolutions.com/v1/TAB_ID.extension
                    </b>
                  </li>
                </ul>
              </div>
              <p className="mt-10">Examples</p>
              <div className="ml-20 mr-20">
                <ul className="mt-10">
                  <li>
                    Default:&nbsp;
                    <b>https://assets.unileversolutions.com/v1/1282798.png</b>
                  </li>
                  <li>
                    Resized&nbsp;
                    <b>
                      https://assets.unileversolutions.com/v1/1282798.png?im=Resize=(200)
                    </b>
                  </li>
                </ul>
              </div>
              <p className="mt-10 mb-10">
                To use any other asset transformation parameters, refer to
                this&nbsp;
                <a
                  target={'_blank'}
                  href="https://ce-platform.atlassian.net/wiki/spaces/CPDF/pages/798065380/TAB+Integration+Asset+Pipeline"
                >
                  documentation <Icon name="external" />
                </a>
              </p>
              <p className="mb-20">
                To learn more about TAB, visit&nbsp;
                <a
                  target={'_blank'}
                  href="https://theassetbank.unilever.com/en-us/Training?tab3094175=User+Guides"
                >
                  The Asset Bank <Icon name="external" />
                </a>
              </p>
            </div>
          </div>
        </TabPanel>
      </div>
    </Modal>
  );
};

export default GenerateImagePathModal;
