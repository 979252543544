import {
  GET_CAMPAIGN_DETAILS_CONSENTS,
  GET_ALL_CONSENTS_FOR_LOCALE,
  REMOVE_ALL_CONSENTS_FOR_LOCALE,
  CONSENTS_ASYNC_IN_PROGRESS,
  GET_CONSENTS_FOR_SELECTED_VERSION,
  GET_SAAS_CONSENT_METADATA
} from "../actionTypes";

const initialState = {
  asyncInProgress: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_CAMPAIGN_DETAILS_CONSENTS: {
      let campaign = { campaign: action.campaign };
      return { ...state, ...campaign };
    }
    case GET_ALL_CONSENTS_FOR_LOCALE: {
      let allConsents = { allConsents: action.allConsents };
      return { ...state, ...allConsents };
    }
    case REMOVE_ALL_CONSENTS_FOR_LOCALE: {
      return { ...state, allConsents: undefined };
    }
    case CONSENTS_ASYNC_IN_PROGRESS: {
      let asyncInProgress = { asyncInProgress: action.isLoading };
      return { ...state, ...asyncInProgress };
    }
    case GET_CONSENTS_FOR_SELECTED_VERSION: {
      let selectedConsents = { selectedConsents: action.defaultList };
      return { ...state, ...selectedConsents };
    }
    
    case GET_SAAS_CONSENT_METADATA: {
      let formConsentData = {
        formConsentData: action.formConsentData.filter(
          el => el.type === 'consentMessage'
        )
      };
      return { ...state, ...formConsentData };
    }
    default:
      return state;
  }
}
