import React, { Component } from 'react';
import Loader from './Loader';
import { connect } from 'react-redux';
import fileDownload from 'js-file-download';
import {
  getFormatedDate,
  getUserCountryList,
  getCampaignStatusText
} from '../selectors/index';
import CheckboxDropDown from './CheckboxDropDown';
import { getBrandCountries } from '../actions/dashboard';
import axios from 'axios';
import * as XLSX from 'xlsx';
import moment from 'moment';
import constant from '../constants';
import {
  fetchApproverFilterData,
  fetchUserData
} from '../actions/user-management';

class TabPanelContent extends Component {
  constructor(props) {
    super(props);
    this.uploadExcelRef = React.createRef();
    this.state = {
      selectedCountry: [],
      selectedCampaignFilter: [],
      filterUserData: [],
      selectedCountryArr: [],
      file: null,
      searchText :''
    };
    this.renderUserData = this.renderUserData.bind(this);
    this._getCountries = this._getCountries.bind(this);
    this._handleDropDownValue = this._handleDropDownValue.bind(this);
    this._handleCampaignDropDownValue = this._handleCampaignDropDownValue.bind(
      this
    );
    this._handleSearch = this._handleSearch.bind(
      this
    );
  }

  componentDidMount() {
    this._setRoleBaseFilters();
    this.props.getBrandCountries();
  }

  _setRoleBaseFilters() {
    if (
      this.props.authorizedUserData &&
      this.roleForFilter.includes(this.props.authorizedUserData.group)
    ) {
      if (
        this.props.authorizedUserData &&
        this.props.authorizedUserData.country &&
        this.props.authorizedUserData.country.length
      ) {
        this.country = getUserCountryList(
          this.props.authorizedUserData.country
        );
        this.setState({ brandcountry: this.country });
      }
    }
  }

  _getCountries() {
    let { countries } = this.props;
    if (Object.keys(countries).length > 0) {
      return countries.map(function(country) {
        return { value: country.code + '-' + country._id, label: country.name };
      });
    }
  }

  getUserDataBasedOnFilter(selectedCountryData, selectedCampaignData) {
    const filter = {
      selectedCountry: selectedCountryData
        ? selectedCountryData
        : this.state.selectedCountryArr,
      selectedCampaign: selectedCampaignData
        ? selectedCampaignData
        : this.state.selectedCampaignFilter
    };
    if (
      filter &&
      filter.selectedCountry.length === 0 &&
      filter.selectedCampaign.length === 0
    ) {
      this.props.fetchUserData('approver','','','',this.state.searchText);
    } else {
      this.props.fetchApproverFilterData('approver', filter,this.state.searchText);
    }
  }

  _handleCampaignDropDownValue(selectedOption, action) {
    this.getUserDataBasedOnFilter('', selectedOption);
    if (selectedOption && selectedOption.length === 0) {
      this.setState({
        selectedCampaignFilter: selectedOption
      });
    } else {
      this.setState({
        selectedCampaignFilter: selectedOption
      });
    }
  }

  _handleDropDownValue(selectedOption, action) {
    let selectedCoutryData = [];
    if (selectedOption && selectedOption.length) {
      for (const element of selectedOption) {
        let countryId =
          element && element['value'] ? element['value'].split('-')[1] : null;
        if (countryId) {
          selectedCoutryData.push(countryId);
        }
      }
    }
    this.getUserDataBasedOnFilter([...new Set(selectedCoutryData)], '');
    if (selectedOption && selectedOption.length === 0) {
      this.setState({
        selectedCountry: selectedOption,
        selectedCountryArr: selectedCoutryData
      });
    } else {
      this.setState({
        selectedCountry: selectedOption,
        selectedCountryArr: selectedCoutryData
      });
    }
  }

  _handleSearch(event) {
    let searchText = event.target.value;
    const filter = {
      selectedCountry: this.state.selectedCountryArr,
      selectedCampaign: this.state.selectedCampaignFilter
    };
    this.setState({searchText : event.target.value});
    if (
      filter &&
      filter.selectedCountry.length === 0 &&
      filter.selectedCampaign.length === 0
    ) {
      this.props.fetchUserData('approver','','','',searchText);
    } else {
      this.props.fetchApproverFilterData('approver', filter,searchText);
    }
  }

  renderUserData(userdata, asyncInProgress) {
    const { campaignConfig } = this.props;
    if (asyncInProgress) {
      return (
        <tr>
          <td>
            <Loader />
          </td>
        </tr>
      );
    } else {
      if (!userdata.length) {
        return (
          <tr>
            <td>
              <span>{`No ${
                this.props.panelType === 'requester' ? 'domain' : 'user'
              } added.`}</span>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        );
      } else {
        return userdata.map((user, index) => {
          let creationDate = user.creationDate
            ? getFormatedDate(user.creationDate, false, false)
            : '';
          creationDate = creationDate ? creationDate.split('-')[0] : '';

          return (
            <tr key={index}>
              {this.props.panelType === 'approver' ? (
                <>
                  <td>
                    {user.country.length
                      ? user.country.map((data, index) => {
                          return <ul key={index}> {data.name} </ul>;
                        })
                      : ''}
                  </td>
                  {campaignConfig &&
                  campaignConfig.APPROVER_BUSINESS_GROUP_ENABLE ? (
                    <td>
                      {user && user.businessGroup && user.businessGroup.length
                        ? user.businessGroup.map((data, index) => {
                            return <ul key={index}> {data.name} </ul>;
                          })
                        : ''}
                    </td>
                  ) : null}
                </>
              ) : null}
              <th scope="row">{user.name || ''}</th>
              <td>
                <div className="content-email-column">
                  {user.emailId || user.domain}
                </div>
              </td>
              {this.props.panelType === 'approver' ||
              this.props.panelType === 'acs' ? (
                <td>
                  {user.accessTo && user.accessTo.length
                    ? user.accessTo.map((data, index) => {
                        return data.emailCampaign ? (
                          <ul key={index}> Email</ul>
                        ) : data.formCampaign ? (
                          <ul key={index}> Data</ul>
                        ) : (
                          ''
                        );
                      })
                    : ''}
                </td>
              ) : null}
              <td>
                <span className="badge badge-pill badge-info">
                  {this.props.panelType === 'requester' || user.loginAccess
                    ? 'Log-in access'
                    : ''}
                </span>
                <br />
                <span className="badge badge-pill badge-info">
                  {user.emailNotificationAccess ? 'Email Notification' : ''}
                </span>
              </td>
              <td>{creationDate}</td>
              <td className="approver-action">
                {user.emailId ? (
                  <span className="cw-icons">
                    <i
                      className="far fa-edit"
                      onClick={() =>
                        this.props.editUser(
                          this.props.panelType,
                          'editUser',
                          user,
                          this.state.selectedCountryArr,
                          this.state.selectedCampaignFilter,
                          user.userRole
                        )
                      }
                    ></i>
                  </span>
                ) : (
                  ''
                )}
                {user.emailId ? (
                  <span className="cw-icons">
                    <i
                      className="far fa-trash-alt"
                      onClick={() =>
                        this.props.deleteUser(
                          this.props.panelType,
                          user.emailId || user.domain,
                          this.state.selectedCountryArr,
                          this.state.selectedCampaignFilter,
                          user.businessGroup,
                          user.country
                        )
                      }
                    ></i>
                  </span>
                ) : (
                  ''
                )}
              </td>
            </tr>
          );
        });
      }
    }
  }

  downloadExcel = () => {
    let endDate = new Date();
    let startDate = endDate.setMonth(endDate.getMonth() - 3);
    const excleStartDateFormat = moment(startDate).format('DDMMMYYYY');

    let endNewDate = new Date();
    const excleEndDateFormat = moment(endNewDate).format('DDMMMYYYY');

    startDate = startDate && moment(startDate).format('DD/MM/YYYY');
    endNewDate = endNewDate && moment(endNewDate).format('DD/MM/YYYY');

    let qnaUrl = `${
      constant.serviceUrls.QNA_CAMPAIGN_DETAILS
    }?startDate=${encodeURIComponent(startDate)}&&endDate=${encodeURIComponent(
      endNewDate
    )}&&excleStartDateFormat=${excleStartDateFormat}&&excleEndDateFormat=${excleEndDateFormat}&&action=export`;

    axios({ method: 'get', url: qnaUrl, responseType: 'blob' })
    .then(response => {
      let fileName =
        response.headers['content-disposition']?.split('filename=')[1] ||
        `file_${new Date().getTime()}`;
      fileDownload(response.data, fileName);
    })
    .catch(err => {
      console.log('download report error: ', err);
    });
  };

  downloadApprovers = () => {
    let endDate = new Date();
    let startDate = endDate.setMonth(endDate.getMonth() - 3);
    const excleStartDateFormat = startDate
      ? moment(startDate).format('DDMMMYYYY')
      : '';
    const excleEndDateFormat = endDate
      ? moment(endDate).format('DDMMMYYYY')
      : '';

    startDate = '';
    endDate = '';

    let allCwApproverListUrl = `${constant.serviceUrls.CAMPAIGN_GET_USER_URL}/export/approver?group=approver&&startDate=${startDate}&&endDate=${endDate}&sortBy=lastUpdatedDate&orderBy=desc`;
    axios.get(allCwApproverListUrl).then(response => {
      const approverList = response && response.data;
      let approverJson = [];
      if (approverList && approverList.length) {
        for (let approver of approverList) {
          if (approver && approver.country && approver.country.length) {
            for (const country of approver.country) {
              let newApprovar = {
                Country: country.name,
                ['Business group']: approver.allBuFlag ? 'All' :
                  ((approver.businessGroup &&
                    approver.businessGroup[0] &&
                    approver.businessGroup[0].name) ||
                  'Unavailable'),
                'Approver name': approver.name || 'Unavailable',
                'Approver email': approver.emailId || 'Unavailable',
                'Status' :approver && approver.status =='1' ? 'Active' : (approver.status =='0' ? 'Inactive' : 'Unavailable')
              };
              approverJson.push(newApprovar);
            }
          }
        }
      }
      approverJson.sort((p, q) => (p.Country > q.Country ? 1 : -1));
      const worksheet = XLSX.utils.json_to_sheet(approverJson);

      XLSX.utils.sheet_add_aoa(
        worksheet,
        [['Exported on', new moment().format('DD MMM YYYY')]],
        { origin: 'A' + (approverJson.length + 3) }
      );

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Approver List');
      XLSX.writeFile(workbook, `CW_Approvers_All_time.xlsx`);
    });
  };

  handleFileChange = async event => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const formData = new FormData();
      formData.append('file', selectedFile);

      try {
        const response = await axios.post(
          constant.serviceUrls.APPROVER_BULK_UPLOAD,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        );

        console.log('File uploaded successfully', response.data);
      } catch (error) {
        console.error('Error uploading file', error);
      }
    } else {
      alert('Please select an Excel file before submitting.');
    }
  };

  handleFileClick = (e) => {
    e.target.value = ''
  }

  render() {
    let { asyncInProgress, campaignConfig } = this.props;
    let userData =
      this.props.panelType === 'requester'
        ? this.props.domainData
        : this.props.userData;

    const addNewButtonTitle =
      this.props.panelType === 'requester' ? 'New Domain' : 'New User';
    return (
      <div>
        <div className="mb-10">
          <div className="d-flex justify-content-between">
            <div className="d-flex">
              <button
                type="button"
                className="btn btn-outline-primary mr-10"
                onClick={() =>
                  this.props.modalClick(
                    this.props.panelType,
                    'addUser',
                    null,
                    this.state.selectedCountryArr,
                    this.state.selectedCampaignFilter
                  )
                }
              >
                {addNewButtonTitle}
              </button>
              {this.props.panelType === 'approver' ? (
                <div className="d-flex">
                  <div className="mr-10">
                  <input
                      className="form-control content-approver-search"
                      type="text"
                      name="search"
                      placeholder='Search by name/email'
                      onChange={this._handleSearch}
                    />
                  </div>
                  <div className="mr-10">
                    <div className="form-group mb-0">
                      <CheckboxDropDown
                        isMulti={true}
                        className={'cw-dashboard-filter country-search'}
                        menuIsOpen={false}
                        name={'brandcountry'}
                        label={'Country'}
                        hideSelectedOptions={false}
                        placeholder={'Country'}
                        isDisabled={asyncInProgress}
                        isLoading={asyncInProgress}
                        value={this.state.brandcountry}
                        onChange={this._handleDropDownValue}
                        options={this._getCountries()}
                        isClearable={false}
                      />
                    </div>
                  </div>
                  <div className="mr-10">
                    <div className="form-group mb-0">
                      <CheckboxDropDown
                        isMulti={true}
                        className={'cw-dashboard-filter cw-filter-fix-width'}
                        menuIsOpen={false}
                        name={'campaigngroup'}
                        label={'Campaign group'}
                        hideSelectedOptions={false}
                        placeholder={'Campaign group'}
                        isDisabled={asyncInProgress}
                        isLoading={asyncInProgress}
                        value={this.state.selectedCampaignFilter}
                        onChange={this._handleCampaignDropDownValue}
                        options={[
                          {
                            label: 'Data campaign',
                            value: 'formCampaign'
                          },
                          {
                            label: 'Email campaign',
                            value: 'emailCampaign'
                          }
                        ]}
                        isClearable={false}
                      />
                    </div>
                  </div>
                </div>
              ) : null}

              {this.props.panelType === 'admin' ? (
                <button
                  className="btn btn-outline-primary release-notes-btn mr-10"
                  onClick={() =>
                    this.props.modalClick(
                      this.props.panelType,
                      'addReleaseNote'
                    )
                  }
                >
                  Add Release Update
                </button>
              ) : null}
              {this.props.panelType === 'admin' ? (
                <button
                  className="btn btn-outline-primary release-notes-btn"
                  onClick={this.downloadExcel}
                >
                  Download Excel
                </button>
              ) : null}
            </div>
            <div className="d-flex">
              {this.props.panelType === 'approver' ? (
                <div>
                  <input
                    type="file"
                    ref={this.uploadExcelRef}
                    id="excelFileInput"
                    accept=".xlsx"
                    onChange={this.handleFileChange}
                    onClick={this.handleFileClick}
                    className='d-none'
                  />
                  <button
                    type="button"
                    className="btn btn-outline-primary ml-10"
                    onClick={() => {
                      this.uploadExcelRef.current.click()
                    }}
                  >
                    Upload approvers
                  </button>
                </div>
              ) : null}
              {this.props.panelType === 'approver' ? (
                <button
                  type="button"
                  className="btn btn-outline-primary ml-10"
                  onClick={() => this.downloadApprovers()}
                >
                  Export
                </button>
              ) : null}
            </div>
          </div>
        </div>

        <div>
          <div className="cw-listing--content">
            {userData &&
            userData.length === 0 &&
            this.props.userData.length != 0 &&
            this.props.panelType === 'approver' ? (
              <>
                <span>
                  No approvers are available on record for the selected markets.
                  The approval requests for these markets are sent to Global 121
                </span>
              </>
            ) : (
              <>
                <table className="table table-striped mb-40">
                  <thead>
                    <tr>
                      {this.props.panelType === 'approver' ? (
                        <>
                          <th scope="col">
                            <span  className="cw-sort cw-sort--asc cell "
                              data-type="country"
                      onClick={e =>
                        this.props.sortClick(
                          e,
                          this.props.panelType,
                          this.state.selectedCountryArr,
                          this.state.selectedCampaignFilter,
                          this.state.searchText
                        )
                      }>Country</span>
                          </th>
                          {campaignConfig &&
                          campaignConfig.APPROVER_BUSINESS_GROUP_ENABLE ? (
                            <th scope="col">
                              <span
                                className="cw-sort cw-sort--asc cell"
                                data-type="businessGroup"
                                onClick={e =>
                                  this.props.sortClick(
                                    e,
                                    this.props.panelType,
                                    this.state.selectedCountryArr,
                                    this.state.selectedCampaignFilter,
                                    this.state.searchText
                                  )
                                }
                              >
                                Business group
                              </span>
                            </th>
                          ) : null}
                        </>
                      ) : null}
                      <th
                        scope="col"
                        data-type="name"
                        onClick={this.sortDashboard}
                      >
                        <span
                          className="cw-sort cw-sort--asc cell "
                          data-type="name"
                          onClick={e =>
                            this.props.sortClick(
                              e,
                              this.props.panelType,
                              this.state.selectedCountryArr,
                              this.state.selectedCampaignFilter,
                              this.state.searchText
                            )
                          }
                        >
                          Name
                        </span>
                      </th>

                      <th scope="col">
                        <span
                          className=""
                          data-type="emailId"
                          onClick={e =>
                            this.props.sortClick(
                              e,
                              this.props.panelType,
                              this.state.selectedCountryArr,
                              this.state.selectedCampaignFilter,
                              this.state.searchText
                            )
                          }
                        >
                          {`${
                            this.props.panelType === 'requester'
                              ? 'Domain'
                              : 'Email'
                          }`}
                        </span>
                      </th>
                      {this.props.panelType === 'approver' ||
                      this.props.panelType === 'acs' ? (
                        <th scope="col">
                          <span className="">Module</span>
                        </th>
                      ) : null}

                      <th scope="col">
                        <span className="">Permissions</span>
                      </th>
                      <th scope="col">
                        <span
                          className="cw-sort cw-sort--asc"
                          data-type="creationDate"
                          onClick={e =>
                            this.props.sortClick(
                              e,
                              this.props.panelType,
                              this.state.selectedCountryArr,
                              this.state.selectedCampaignFilter,
                              this.state.searchText
                            )
                          }
                        >
                          Date added
                        </span>
                      </th>

                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.renderUserData(userData, this.props.asyncInProgress)}
                  </tbody>
                </table>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  // authorizedUserData: state.authorized.authorizedUserData,
  showLoader: state.dashboard.showLoader,
  countries: state.dashboard.countries,
  userData: state.user.userData || {},
  campaignConfig: state.app.campaignConfig
});

export default connect(mapStateToProps, {
  fetchApproverFilterData,
  fetchUserData,
  getBrandCountries
})(TabPanelContent);
