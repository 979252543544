import React from 'react';

import GuideLinesInfo from 'components/GuideLineInfo';

import constants from 'constants/config';

export const SMSBodyGuidelines = () => {
  return (
    <GuideLinesInfo
      data={constants.SMS_DESIGN_GUIDE_LINES}
      liClassName="unordered-list-item"
      noUlLineBreak={true}
      noSubTitleLineBreak={true}
      defaultCollapse={true}
    />
  );
};
