import React, { useEffect } from 'react';
import Alert from 'components/Alert';
import { useDispatch, useSelector } from 'react-redux';
import StatusTimeline from 'components/status-timeline';
import Loader from '../../components/Loader';
import { openFeedbackForm } from '../../actions/feedbackForm';
import constants from 'constants/config';
import { fetchHistoryLogs } from 'actions/history';
import { getSmsOverview } from 'actions/communications/communicationOverview';
import { getSMSCampaignStatusSteps } from 'selectors';
import { isEmpty } from 'lodash';

const CommunicationSubmit = props => {
  const campaign = useSelector(
    state => state.communicationOverview.smsDetailsOverview
  );
  const isLoading = useSelector(state => state.communicationOverview.isLoading);

  const historyLogs = useSelector(state => state.historyLogs);

  const { authorizedUserData } = useSelector(state => state.authorized);

  useEffect(() => {
    const pathUid = props.match.params.id;

    if (pathUid) {
      dispatch(getSmsOverview(pathUid));
      dispatch(fetchHistoryLogs(pathUid));
    }
  }, []);

  const dispatch = useDispatch();

  const _handleOnOpenFeedback = event => {
    event.preventDefault();
    dispatch(
      openFeedbackForm({
        campaignUid: props.match.params.id,
        userId: authorizedUserData._id,
        defaultCampaignRequest: 'SMS',
        showCampaignRequest: false
      })
    );
  };

  const _showCampaignOverview = event => {
    event.preventDefault();
    props.history.push(`${constants.SMS_BASE_ROUTE}/${props.match.params.id}`);
  };

  const isDataAvailable = () => {
    return !isEmpty(campaign) && !isEmpty(historyLogs);
  };

  return (
    <div className="col-sm-12 skypink">
      <div className="cw-section">
        <div className="row min-height-60">
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <div className="col-sm-12 cw-section--title mb-4 mt-10">
                <br />
                <h2 className="cw-heading--secondary cw-email-heading">
                  Campaign submitted
                </h2>
              </div>
              <div className="col-sm-8">
                <p className="mb-10">
                  Your SMS campaign request has been sent to your market
                  approvers. Once approved, it will be assigned to the global
                  121 ACS team, who will then share the estimated costs and
                  audience count with you. Once you receive the cost estimate
                  for this campaign, you will have to create a purchase order in
                  Coupa and upload it to your Campaign Wizard request. Global
                  ACS team will then initiate setting up your campaign
                </p>
                <p className="mb-10">
                  <strong>What&#39;s next</strong>
                </p>
                <ul className="email-submit-page-ul">
                  <li>
                    Your campaign will go through a series of steps as indicated
                    below
                  </li>
                  <li>
                    We will keep you aware of the progress through automated
                    email notifications at key stages
                  </li>
                  <li>
                    You can also track all campaign requests and gain
                    inspiration on the{' '}
                    <a
                      href={`/campaign-wizard/${constants.SMS_ROUTES.DASHBOARD}`}
                    >
                      <strong>campaigns dashboard</strong>
                    </a>
                  </li>
                </ul>
                {isDataAvailable() && (
                  <StatusTimeline
                    steps={getSMSCampaignStatusSteps(campaign, historyLogs)}
                    className="email-status-timeline"
                  />
                )}
              </div>
              <div className="col-sm-4 mb-4">
                <div className="btn-box-ml-auto mb-10">
                  <button
                    className="btn btn-primary mb-10"
                    onClick={_showCampaignOverview}
                  >
                    View campaign details
                  </button>
                </div>
                <Alert
                  alertType="feedbackFormMesg"
                  onFeedbackClick={_handleOnOpenFeedback}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CommunicationSubmit;
