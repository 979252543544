// Approval Reducer
import {
	GET_CAMPAIGN,
	ASYNC_IN_PROGRESS,
	APPROVE_CAMPAIGN,
	REJECT_CAMPAIGN,
	REDIRECT,
	SET_APPROVAL_ERROR,
	FETCH_CAMPIGNEDITLOGS_IN_PROGRESS,
	GET_CAMPAIGN_EDITLOGS,
} from '../actionTypes';

const initialState = {
	asyncInProgress: false,
	campaign: {},
	campaignDetails: {},
	questionAnswerList: [],
	approved: '',
	redirect: false,
	dataUpdated: false,
	approvalErrorMessage: null,
	approvalMsg: null,
	campaignEditLog: {},
};

export default function(state = initialState, action) {
	switch (action.type) {
		case GET_CAMPAIGN: {
			//let campaigns = [...state.campaigns, ...action.campaigns];
			let campaign = {
				campaign: action.campaign,
				campaignDetails: action.campaign.campaignDetail,
				questionAnswerList:
					(action.campaign.campaignDetail && action.campaign.campaignDetail.questionAnswerList) || [],
			};
			let dataUpdated = action.dataUpdated;
			return { ...state, ...campaign, ...dataUpdated };
		}
		case FETCH_CAMPIGNEDITLOGS_IN_PROGRESS:
		case ASYNC_IN_PROGRESS: {
			let asyncInProgress = { asyncInProgress: action.isLoading };
			return { ...state, ...asyncInProgress };
		}
		case APPROVE_CAMPAIGN: {
			let approved = action.approved;
			let approvalMsg = action.approvalMsg;
			let asyncInProgress = {
				asyncInProgress: action.isLoading,
				approved: approved,
				approvalMsg: approvalMsg,
			};
			let dataUpdated = action.dataUpdated;

			return { ...state, ...asyncInProgress, ...dataUpdated };
		}
		case REJECT_CAMPAIGN: {
			let approved = action.approved;
			let asyncInProgress = {
				asyncInProgress: action.isLoading,
				approved: approved,
			};
			let dataUpdated = action.dataUpdated;

			return { ...state, ...asyncInProgress, ...dataUpdated };
		}
		case REDIRECT: {
			let redirect = { redirect: action.redirect };
			return { ...state, ...redirect };
		}
		case SET_APPROVAL_ERROR: {
			let approvalErrorMessage = { approvalErrorMessage: action.error };
			return { ...state, ...approvalErrorMessage };
		}
		case GET_CAMPAIGN_EDITLOGS: {
			let campaignEditLog = { campaignEditLog: action.campaignEditLogs };
			return { ...state, ...campaignEditLog };
		}
		default:
			return state;
	}
}
