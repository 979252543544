import React, { Fragment, useState } from 'react';
import {
  isQuestionMandatory,
  isQuesQualifying,
  isRestrictionAllowed,
  fetchQnANotificationTileData
} from '../../selectors/index';
import { useDispatch, useSelector } from 'react-redux';
import { updateQuestionIds, updateAnswersIds } from '../../actions/masterData';
import groupIcon from '../../images/groupIcon.png';
import constant from '../../constants';
import SummaryComponent from 'components/SummaryComponent';

const { questionTypes } = constant;

let newMasterData = (isNew, colWidth) => {
  return isNew ? (
    <>
      <div
        style={{ display: 'block', color: '#E9A40A', whiteSpace: 'pre' }}
        className={{ colWidth }}
      >
        <img src={groupIcon} alt={groupIcon} width="25" height="25"></img>
        &nbsp; New Master
      </div>
    </>
  ) : null;
};

const AnswerList = props => {
  const {
    answers,
    qno,
    qtype,
    isCampaignNonEnglish,
    campaignLanguage,
    colWidth,
    showAwaitingQid,
    disabled,
    showTextBox
  } = props;

  const dispatch = useDispatch();
  let validateQuesAns = event => {
    props.fielddValidation(event);
  };
  let _handleAnswer = event => {
    validateQuesAns(event);
    dispatch(
      updateAnswersIds({
        id: event.target.value,
        qno: event.target.dataset.qno,
        ano: event.target.dataset.ano,
        anstype: event.currentTarget.dataset.anstype
      })
    );
  };
  return answers && answers.length > 0 ? (
    <div className="cw-question-answer-dotted-line mb-10">
      <div className="cw-heading-qna">
        <span
          className="float-left  fa fa-circle mt-10"
          style={{ marginTop: '5px' }}
        ></span>
        <strong style={{ marginLeft: '12px' }}>Answers</strong>
      </div>
      {answers.length &&
        answers.map((answer, answerIndex) => {
          return (
            <Fragment key={answerIndex}>
              <div className="cw-striped-qna-row">
                <div className="row">
                  <div className={colWidth}>
                    <div className="cw-qna-lable">
                      <strong>
                        Answer {!isCampaignNonEnglish ? answerIndex + 1 : ''}
                      </strong>
                    </div>
                    <div className={`${isCampaignNonEnglish ? 'd-none' : ''}`}>
                      <p className="xsmall-label">English</p>
                    </div>
                  </div>
                  <div className={colWidth}>
                    <p>{answer.label}</p>
                  </div>
                  {showTextBox ? (
                    <>
                      {!props.showInput ? (
                        <div className={colWidth}></div>
                      ) : (
                        <div className={colWidth}>
                          <input
                            type="text"
                            className="form-control"
                            name={'answer_' + qno + '_' + answerIndex}
                            id={'answer_' + qno + '_' + answerIndex}
                            value={answer.value}
                            placeholder="Enter ID"
                            data-qno={qno + 1 || ''}
                            disabled={disabled}
                            data-ano={answerIndex + 1 || ''}
                            onChange={_handleAnswer}
                          />
                          <span
                            className="cw-error cw-hidden"
                            data-control={'answer_' + qno + '_' + answerIndex}
                          >
                            <i className="fas fa-exclamation-triangle mr-10"></i>
                            Please enter Answer Id.
                          </span>
                        </div>
                      )}
                    </>
                  ) : null}
                  {showAwaitingQid ? (
                    <div className={colWidth + ' text-center'}>
                      {answer.value && answer.value !== '-1'
                        ? answer.value
                        : qtype === questionTypes.FREE_TEXT
                        ? '-1'
                        : 'Awaiting'}
                    </div>
                  ) : (
                    ''
                  )}
                  {newMasterData(answer.new)}
                </div>
              </div>
              {!isCampaignNonEnglish ? (
                <div className="cw-striped-qna-row">
                  <div className="row">
                    <div className={colWidth}>
                      <div className="cw-qna-lable">
                        <strong>
                          Answer {!isCampaignNonEnglish ? answerIndex + 1 : ''}
                        </strong>
                      </div>
                      <div
                        className={`${isCampaignNonEnglish ? 'd-none' : ''}`}
                      >
                        <p className="xsmall-label">{campaignLanguage}</p>
                      </div>
                    </div>
                    <div className={colWidth}>
                      <p>{answer.translatedanswer}</p>
                    </div>
                    {showTextBox ? (
                      <>
                        {!props.showInput ? (
                          <div className={colWidth}></div>
                        ) : (
                          <div className={colWidth}>
                            <input
                              type="text"
                              className="form-control"
                              name={
                                'translatedanswer_' + qno + '_' + answerIndex
                              }
                              id={'translatedanswer_' + qno + '_' + answerIndex}
                              value={answer.translatedvalue}
                              placeholder="Enter ID"
                              data-qno={qno + 1}
                              disabled={disabled}
                              data-ano={answerIndex + 1}
                              data-anstype="translated"
                              onChange={_handleAnswer}
                            />
                            <span
                              className="cw-error cw-hidden"
                              data-control={
                                'translatedanswer_' + qno + '_' + answerIndex
                              }
                            >
                              <i className="fas fa-exclamation-triangle mr-10"></i>
                              Please enter Answer Id.
                            </span>
                          </div>
                        )}
                      </>
                    ) : null}
                    {showAwaitingQid ? (
                      <div className={colWidth + ' text-center'}>
                        {answer.translatedvalue &&
                        answer.translatedvalue !== '-1'
                          ? answer.translatedvalue
                          : qtype === questionTypes.FREE_TEXT
                          ? '-1'
                          : 'Awaiting'}
                      </div>
                    ) : (
                      ''
                    )}
                    {newMasterData(answer.translatednew)}
                  </div>
                </div>
              ) : null}
            </Fragment>
          );
        })}
    </div>
  ) : null;
};

const QAndAItems = props => {
  const {
    qnaList,
    isCampaignNonEnglish,
    campaignLanguage,
    isPromotigoType,
    colWidth,
    showAwaitingQid,
    disabled,
    showTextBox,
    accordianClass,
    newIconClass,
    isOtherLang,
    parentComponent
  } = props;
  const dispatch = useDispatch();
  const [questionAccordion, setQuestionAccordion] = useState(-1);
  let handleAccordionExpandCollapse = index => {
    setQuestionAccordion(questionAccordion === index ? false : index);
  };

  let validateQuesAns = event => {
    props.fielddValidation(event);
  };

  let _handleChange = event => {
    validateQuesAns(event);
    dispatch(
      updateQuestionIds({
        id: event.target.value,
        qno: event.currentTarget.dataset.qno,
        qtype: event.currentTarget.dataset.qtype
      })
    );
  };
  return (
    qnaList &&
    qnaList.length > 0 &&
    qnaList.map((question, questionIndex) => {
      return (
        <>
          <div className="mb-10">
            <div className="accordion saas-form-field-accordion">
              <div className="card" style={{ border: 0 }}>
                <div
                  className={`card-header ${
                    questionAccordion === questionIndex
                      ? 'card-bottom-border'
                      : 'card-no-bottom'
                  }`}
                  style={{ padding: '7px 13px' }}
                >
                  <div className="row">
                    <div
                      className={
                        question.new ||
                        question.translatednew ||
                        question.answers.find(e => e.new || e.translatednew)
                          ? accordianClass
                          : 'col-sm-11'
                      }
                    >
                      <span
                        className="saas-accordion-heading"
                        style={{ fontWeight: '700' }}
                      >
                        {question.text}
                      </span>
                    </div>
                    {question.new ||
                    question.translatednew ||
                    question.answers.find(e => e.new || e.translatednew) ? (
                      <div
                        className={newIconClass}
                        style={{
                          display: 'block',
                          color: '#E9A40A',
                          whiteSpace: 'pre'
                        }}
                      >
                        <>
                          <img
                            style={{ marginLeft: '-12px' }}
                            src={groupIcon}
                            alt={groupIcon}
                            width="25"
                            height="25"
                          ></img>
                          &nbsp; New Master
                        </>
                      </div>
                    ) : (
                      ''
                    )}
                    <div className="col-sm-1">
                      <span
                        type="button"
                        className={
                          questionAccordion === questionIndex
                            ? 'saas-accordion-arrow afterTransform'
                            : 'saas-accordion-arrow'
                        }
                        onClick={() => {
                          handleAccordionExpandCollapse(questionIndex);
                        }}
                      >
                        <i
                          className={
                            questionAccordion === questionIndex
                              ? 'fas fa-chevron-down transformProfileIcon-180'
                              : 'fas fa-chevron-down'
                          }
                        ></i>
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    questionAccordion === questionIndex
                      ? 'collapse show'
                      : 'collapse'
                  }
                >
                  <div style={{ marginTop: '7px' }}>
                    <div
                      key={questionIndex}
                      className="cw-campaign--review-section-content"
                    >
                      <div className="cw-question-answer-dotted-line">
                        <div className="cw-heading-qna">
                          <span
                            className="float-left  fa fa-circle mt-10"
                            style={{ marginTop: '5px' }}
                          ></span>
                          <strong style={{ marginLeft: '12px' }}>
                            Question
                          </strong>
                        </div>
                        <div className="cw-separator mb-10">
                          <div className="cw-striped-qna-row">
                            <div className="row">
                              <div className={colWidth}>
                                <div className="cw-qna-lable">
                                  <strong>Question </strong>
                                </div>
                                <div
                                  className={`${
                                    isCampaignNonEnglish ||
                                    question.type === 'Profile'
                                      ? 'd-none'
                                      : ''
                                  }`}
                                >
                                  <p className="xsmall-label">
                                    {!isCampaignNonEnglish &&
                                    question.type === 'Unique entry code'
                                      ? campaignLanguage
                                      : 'English'}
                                  </p>
                                </div>
                              </div>
                              <div className={colWidth}>
                                <p>{question.text}</p>
                              </div>
                              {showTextBox ? (
                                <>
                                  {question.type !== 'Profile' &&
                                  question.type !== 'File upload' &&
                                  question.type !== 'Unique entry code' ? (
                                    <div className={colWidth}>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name={'question' + questionIndex}
                                        id={'question' + questionIndex}
                                        placeholder="Enter ID"
                                        data-qno={questionIndex + 1 || ''}
                                        disabled={disabled}
                                        value={question.id || ''}
                                        onChange={_handleChange}
                                      />
                                      <span
                                        className="cw-error cw-hidden"
                                        data-control={
                                          'question' + questionIndex
                                        }
                                      >
                                        <i className="fas fa-exclamation-triangle mr-10"></i>
                                        Please enter Question Id.
                                      </span>
                                    </div>
                                  ) : (
                                    <div className={colWidth}></div>
                                  )}
                                </>
                              ) : null}
                              {showAwaitingQid ? (
                                <div className={colWidth + ' text-center'}>
                                  {question.type !== 'File upload' &&
                                  question.type !== 'Unique entry code'
                                    ? question.id || 'Awaiting'
                                    : ''}
                                </div>
                              ) : (
                                ''
                              )}
                              {newMasterData(question.new, colWidth)}
                            </div>
                          </div>
                          {!isCampaignNonEnglish &&
                          question.type !== 'Profile' &&
                          question.type !== 'Unique entry code' ? (
                            <div className="cw-striped-qna-row">
                              <div className="row">
                                <div className={colWidth}>
                                  <div className="cw-qna-lable">
                                    <strong>Question</strong>
                                  </div>
                                  <div
                                    className={`${
                                      isCampaignNonEnglish ? 'd-none' : ''
                                    }`}
                                  >
                                    <p className="xsmall-label">
                                      {campaignLanguage}
                                    </p>
                                  </div>
                                </div>
                                <div className={colWidth}>
                                  <p>{question.translatedquestion}</p>
                                </div>
                                {showTextBox ? (
                                  <>
                                    {question.type !== 'Profile' &&
                                    question.type !== 'File upload' &&
                                    question.type !== 'Unique entry code' ? (
                                      <div className={colWidth}>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name={
                                            'translatedquestion' + questionIndex
                                          }
                                          id={
                                            'translatedquestion' + questionIndex
                                          }
                                          placeholder="Enter ID"
                                          data-qno={questionIndex + 1 || ''}
                                          disabled={disabled}
                                          value={question.translatedId || ''}
                                          onChange={_handleChange}
                                          data-qtype={'translated'}
                                        />
                                        <span
                                          className="cw-error cw-hidden"
                                          data-control={
                                            'translatedquestion' + questionIndex
                                          }
                                        >
                                          <i className="fas fa-exclamation-triangle mr-10"></i>
                                          Please enter Question Id.
                                        </span>
                                      </div>
                                    ) : (
                                      <div className={colWidth}></div>
                                    )}
                                  </>
                                ) : (
                                  ''
                                )}
                                {showAwaitingQid ? (
                                  <div className={colWidth + ' text-center'}>
                                    {question.type !== 'File upload' &&
                                    question.type !== 'Unique entry code'
                                      ? question.translatedId || 'Awaiting'
                                      : ''}
                                  </div>
                                ) : (
                                  ''
                                )}
                                {newMasterData(
                                  question.translatednew,
                                  colWidth
                                )}
                              </div>
                            </div>
                          ) : null}
                          <div className="cw-striped-qna-row">
                            <div className="row">
                              <div className={`${colWidth} cw-qna-lable`}>
                                Question Type
                              </div>
                              <div className={colWidth}>
                                <p>{question.type}</p>
                              </div>
                              <div className={colWidth}></div>
                            </div>
                          </div>
                          {question.new && question.reasonForCreation ? (
                            <div className="cw-striped-qna-row">
                              <div className="row">
                                <div className={`${colWidth} cw-qna-lable`}>
                                  Reason for new request
                                </div>
                                <div className={colWidth}>
                                  {question.reasonForCreation}
                                </div>
                                <div className={colWidth}></div>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {question.type !== 'Profile' &&
                      question.type !== 'File upload' &&
                      question.type !== 'Unique entry code' ? (
                        <AnswerList
                          isCampaignNonEnglish={isCampaignNonEnglish}
                          campaignLanguage={campaignLanguage}
                          answers={question.answers}
                          qno={questionIndex}
                          qtype={question.type}
                          colWidth={colWidth}
                          showAwaitingQid={showAwaitingQid}
                          disabled={disabled}
                          showInput={
                            question.type !== 'Profile' &&
                            question.type !== 'File upload' &&
                            question.type !== 'Unique entry code'
                              ? true
                              : false
                          }
                          fielddValidation={props.fielddValidation}
                          showTextBox={showTextBox}
                        />
                      ) : null}

                      <div className="cw-question-answer-dotted-line">
                        <div className="cw-heading-qna">
                          <span
                            className="float-left  fa fa-circle mt-10"
                            style={{ marginTop: '5px' }}
                          ></span>
                          <strong style={{ marginLeft: '12px' }}>
                            Other Configurations
                          </strong>
                        </div>

                        <div className="cw-striped-qna-row">
                          <div className="row">
                            <div className={`${colWidth} cw-qna-lable`}>
                              Mandatory field
                            </div>
                            <div className={colWidth}>
                              <p>{isQuestionMandatory(question.mandatory)}</p>
                            </div>
                            <div className={colWidth}></div>
                          </div>
                        </div>
                        {isPromotigoType &&
                        (question.type === 'Single choice' ||
                          question.type === 'Free text') ? (
                          <div className="cw-striped-qna-row">
                            <div className="row">
                              <div className={`${colWidth} cw-qna-lable`}>
                                Qualifying
                              </div>
                              <div className={colWidth}>
                                <p>{isQuesQualifying(question.isQualifying)}</p>
                              </div>
                              <div className={colWidth}></div>
                            </div>
                          </div>
                        ) : null}
                        {question.type === 'File upload' ? (
                          <>
                            {' '}
                            <div className="cw-striped-qna-row">
                              <div className="row">
                                <div className={`${colWidth} cw-qna-lable`}>
                                  Placeholder Text
                                </div>
                                <div className={colWidth}>
                                  <p>{question.placeholderText}</p>
                                </div>
                                <div className={colWidth}></div>
                              </div>
                            </div>
                            <div className="cw-striped-qna-row">
                              <div className="row">
                                <div className={`${colWidth} cw-qna-lable`}>
                                  Default Display Text
                                </div>
                                <div className={colWidth}>
                                  <p>{question.defaultDisplayText}</p>
                                </div>
                                <div className={colWidth}></div>
                              </div>
                            </div>
                            <div className="cw-striped-qna-row">
                              <div className="row">
                                <div className={`${colWidth} cw-qna-lable`}>
                                  Upload Restrictions
                                </div>
                                <div className={colWidth}>
                                  <p>
                                    {isRestrictionAllowed(
                                      question.isRestrictionAllowed
                                    )}
                                  </p>
                                </div>
                                <div className={colWidth}></div>
                              </div>
                            </div>
                            {question.isRestrictionAllowed ? (
                              <div className="cw-striped-qna-row">
                                <div className="row">
                                  <div className={`${colWidth} cw-qna-lable`}>
                                    File Size
                                  </div>
                                  <div className={colWidth}>
                                    <p>{question.maxFileSize} KB</p>
                                  </div>
                                  <div className={colWidth}></div>
                                </div>
                              </div>
                            ) : null}
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    })
  );
};
const QnAList = props => {
  let {
    qnaList,
    showQnaList,
    campaignId,
    isCampaignNonEnglish,
    campaignLanguage,
    campaign,
    isPromotigoType,
    colWidth,
    showAwaitingQid,
    isEditButtonShow,
    disabled,
    fielddValidation,
    showTextBox,
    isHeadingQnAs,
    status,
    accordianClass,
    newIconClass,
    isOtherLang,
    campaignDetails
  } = props;

  let qnaTileData = fetchQnANotificationTileData(
    null,
    campaignDetails &&
      campaignDetails.questionAnswerList &&
      campaignDetails.questionAnswerList.length
      ? campaignDetails.questionAnswerList
      : []
  );

  return (
    <div
      className={`cw-campaign--review-section mb-20 ${
        !showQnaList ? 'd-none' : ''
      }`}
    >
      <div className="cw-campaign--review-section-header mb-3 d-flex align-items-center clearfix">
        {isHeadingQnAs ? (
          <h3 className={status === 'Draft' ? 'cw-heading--primary' : ''}>
            {status === 'Draft'
              ? 'Questions & Answers'
              : "Questions & Answers ID's"}
          </h3>
        ) : (
          <h3 className="cw-heading--senary">Questions &amp; answers</h3>
        )}
        {isEditButtonShow ? (
          <button
            className="btn btn-outline-secondary ml-auto"
            onClick={props.handleQuestionsAnswers}
          >
            Edit
          </button>
        ) : (
          ''
        )}
      </div>

      <div className="cw-campaign--review-section-content mb-4">
        <div className="mb-20">
          {qnaTileData && qnaTileData.showQnATile ? (
            <SummaryComponent
              data={campaign}
              isBrandSummary={false}
              isQnASummary={true}
              qnaTileData={qnaTileData}
            />
          ) : null}
        </div>
        <QAndAItems
          campaignLanguage={campaignLanguage}
          isCampaignNonEnglish={isCampaignNonEnglish}
          qnaList={qnaList}
          isPromotigoType={isPromotigoType}
          colWidth={colWidth}
          showAwaitingQid={showAwaitingQid}
          disabled={disabled}
          fielddValidation={fielddValidation}
          showTextBox={showTextBox}
          accordianClass={accordianClass}
          newIconClass={newIconClass}
          isOtherLang={isOtherLang}
        />
      </div>
    </div>
  );
};
export default QnAList;
