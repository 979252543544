import React from 'react';
import ReactTooltip from 'react-tooltip';

export const FormLabel = ({ label, ...props }) => {
  return (
    <div className="form-group" aria-live="polite">
      <label htmlFor={props.id} className={props.className}>
        {label}
      </label>
      {props.descriptionText && <p>{props.descriptionText}
      {props.toolTipText && (
        <span>
          <ReactTooltip
            id="value-tooltip"
            place="right"
            type="info"
            multiline={true}
            className="cw-tooltip cw-email-tooltip"
          />
          <i
            className="fas fa-info-circle ml-1"
            data-for="value-tooltip"
            data-tip={props.toolTipText}
          ></i>
        </span>
      )}
      </p>}
      
    </div>
  );
};
