import React from 'react';
import Modal from 'react-modal';
import constants from 'constants/config';

export default function ApproversInfoBoxModal({
  isOpen,
  onClose,
  nameList,
  isApprovarAssignedToMarket,
  group
}) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="cw-modal cw-modal--questions"
      contentLabel="Field Types"
      style={{
        overlay: {
          backgroundColor: constants.EMAIL_OVERLAY_BACKGROUND
        }
      }}
    >
      <button className="cw-modal--close" title="Close" onClick={onClose}>
        <i className="fas fa-times"></i>
      </button>
      <h3 className="cw-heading--primary mb-10">Your approvers</h3>
      <div>
        <p>
          {isApprovarAssignedToMarket
            ? 'Your campaign needs to be approved by one of the following approvers.'
            : 'Your campaign needs to be approved by global leads as we do not have approvers on record for your market.'}
        </p>
        <br />
        <p>{nameList}</p>
        <br />
        <p className="email-type-desc-font">
          The complete{' '}
          <a
            href={
              group === 'admin'
                ? constants.APPROVER_LIST_ADMIN_PAGE
                : constants.APPROVER_LIST_PAGE
            }
            target="_blank"
          >
            {` list of approvers `}
            <i className="fas fa-external-link-alt"></i>
          </a>{' '}
          for each country and business group is available for review. Please
          raise a{' '}
          <a href={constants.KANA_PAGE} target="_blank">
            {` Kana ticket `}
            <i className="fas fa-external-link-alt"></i>
          </a>{' '}
          to request any changes to your approvers list. Please note that any
          approver changes will be reflected on your future campaigns.
        </p>
      </div>
    </Modal>
  );
}
