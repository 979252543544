import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormBuilder } from '../../components/organisms/FormBuilder';
import Loader from '../../components/Loader';
import SmsPurchaseOrderInfoSchema from './smsPurchaseOrderInfoSchema';

const SmsPurchaseOrderInfo = ({
  pathUid,
  closeModal,
  setToggle,
  smsDetails,
  toggle,
  closePoModelClick
}) => {
  const { isLoading, uid, deliverySchedule, status, smsType,requestInfoText } = smsDetails;
  const { authorizedUserData } = useSelector(state => state.authorized);
  const dispatch = useDispatch();
  const compProps = {
    pathUid,
    closeModal,
    setToggle,
    defaultValues: deliverySchedule || {},
    smsType,
    status,
    requestInfoText,
    toggle,
    authorizedUserData,
    closePoModelClick : closePoModelClick
  };
  return (
    <div>
      {isLoading ? <Loader /> : null}
      <FormBuilder
        schema={SmsPurchaseOrderInfoSchema(dispatch, compProps)}
        user={authorizedUserData}
      />
    </div>
  );
};

export default SmsPurchaseOrderInfo;
