import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import CampaignRejectionListRow from './CampaignRejectionListRow';
import { fetchRejectedCampaignHistoryLogs } from '../../../../actions/history';
import constant from '../../../../constants';

const RejectionCommentsList = ({ list, authorizedUserData }) => {
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    axios
      .get(
        constant.serviceUrls.CAMPAIGN_HISTORY_LOGS_URL +
          '?event=Campaign rejected&campaignStatus=3'
      )
      .then(response => {
        setLoading(false);
        setLogs(response.data.filter(c => c.campaign.status == '3'));
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  const thStyle = {
    padding: 15,
    color: 'var(--color-grey-number)'
  };

  const trStyle = {};

  return loading ? (
    <span>loading...</span>
  ) : (
    <>
      <div
        style={{
          marginTop: '8px',
          maxHeight: '60vh',
          minWidth: '50vw',
          maxWidth: '60vw',
          overflowY: 'auto',
          overflowX: 'visible'
        }}
      >
        <table style={{ borderSpacing: '20px 30px', marginRight: '10px' }}>
          <thead>
            <tr className="rejection-list--row">
              <th style={{ width: '30%', color: 'var(--color-grey-number)' }}>
                Campaign
              </th>
              <th style={thStyle}>Rejection</th>
              <th style={thStyle}>Comments</th>
            </tr>
          </thead>
          <tbody>
            {logs.map(log => (
              <CampaignRejectionListRow
                key={log._id}
                log={log}
                authorizedUserData={authorizedUserData}
              />
            ))}
          </tbody>
        </table>
      </div>

      <div style={{ marginTop: '16px', fontSize: '14px' }}>
        Showing {logs.length == 1 ? '1 rejection' : logs.length + ' rejections'}
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  authorizedUserData: state.authorized.authorizedUserData,
  //   showLoader: state.dashboard.showLoader,
  //   countries: state.dashboard.countries,
  //   brands: state.dashboard.brands,
  list: state.dashboard.campaigns
  //   asyncInProgress: state.dashboard.asyncInProgress,
  //   totalCount: state.dashboard.totalCount,
  //   recordShowStart: state.dashboard.recordShowStart,
  //   recordShowto: state.dashboard.recordShowto,
  //   pageNumber: state.dashboard.pageNumber,
  //   limit: state.dashboard.limit,
  //   deletedCampaign: state.app.deletedCampaign,
  //   serviceError: state.dashboard.serviceError,
  //   serviceErrorStatus: state.dashboard.serviceErrorStatus,
  //   notificationMessage: state.dashboard.notificationMessage,
  //   notificationLink: state.dashboard.notificationLink,
  //   notificationType: state.dashboard.notificationType,
  //   notificationCampaign: state.dashboard.notificationCampaign,
  //   campaign: state.overview.campaign,
  //   campaignDetails: state.overview.campaignDetails,
  //   autogenerate: state.dashboard.autogenerate,
  //   websiteTypes: state.dashboard.websiteTypes
});

export default connect(mapStateToProps, { fetchRejectedCampaignHistoryLogs })(
  RejectionCommentsList
);
