import React from 'react';
import { NavLink } from 'react-router-dom';
const RequestComponent = props => {
  //   let _openNewModal =(event)=> {
  //     event.preventDefault();
  //     setModalState({
  //         modalState: true,
  //     });
  //   }
  return (
    <div
      className={`cw-section-primary cw-section--request-section ${
        props.showComponent ? '' : 'cw-hidden'
      }`}
    >
      <div className="row">
        <div className="col-sm-6" style={{ fontSize: '20px' }}>
          <strong>{props.requestType}</strong>
        </div>
      </div>
      <div className="row mt-15">
        <div className="col-sm-12 home-page-custom-height">
          <p style={props.textStyle}>{props.overview}</p>
        </div>
      </div>
      <div className="cw-btn-home-wrap">
        <div className={`mb-20 ${props.showPrimaryButton ? '' : 'cw-hidden'}`}>
          {
            //props.buttonTitle1 === 'Create a new Form Request'
            //?
            //<a className="btn btn-primary btn--homepage-btn"
            //     onClick={_openNewModal}>
            //</div>    {props.buttonTitle1}
            //</a>:
            <a
              className="btn btn-primary btn--homepage-btn"
              href={props.destinationURL1}
            >
              {props.buttonTitle1}
            </a>
          }
        </div>
        <div
          className={`mt-20 ${props.showSecondaryButton ? '' : 'cw-hidden'} `}
        >
          <NavLink
            className="btn btn-outline-primary btn--homepage-secondary"
            to={props.destinationURL2}
          >
            {props.buttonTitle2}
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default RequestComponent;
