import React from 'react';

const requestMoreInfoIconTransparent = () => {
  return (
    <svg
      width="44"
      height="39"
      viewBox="0 0 44 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        y="0.5"
        width="44"
        height="38"
        rx="5"
        fill="url(#paint0_linear_4814_31074)"
      />
      <g clip-path="url(#clip0_4814_31074)">
        <path
          d="M14.3333 9.5H30.6667C31.95 9.5 33 10.5383 33 11.8333V28.1667C33 28.785 32.755 29.38 32.3117 29.8117C31.88 30.255 31.285 30.5 30.6667 30.5H14.3333C13.715 30.5 13.12 30.255 12.6883 29.8117C12.245 29.38 12 28.785 12 28.1667V11.8333C12 10.5383 13.0383 9.5 14.3333 9.5ZM22.85 14.505C22.6283 14.75 22.5 15.03 22.5 15.3333C22.5 15.6483 22.6283 15.9167 22.85 16.1617C23.0833 16.3833 23.3633 16.5 23.6667 16.5C23.9817 16.5 24.25 16.3833 24.495 16.1617C24.7167 15.9167 24.8333 15.6483 24.8333 15.3333C24.8333 15.03 24.7167 14.75 24.495 14.505C24.25 14.295 23.9817 14.1667 23.6667 14.1667C23.3633 14.1667 23.0833 14.295 22.85 14.505ZM19.9333 19.965C19.8167 20.07 19.8167 20.0817 19.8983 20.1983L19.9567 20.2917L19.9917 20.3617C20.0733 20.5133 20.085 20.5133 20.2133 20.42C20.365 20.315 20.6217 20.1517 21.0533 19.895C22.1267 19.2067 21.9167 20 21.4383 21.75C21.1817 22.7183 20.855 23.955 20.61 25.39C20.33 27.4317 22.1617 26.3817 22.64 26.0667C23.0833 25.7867 24.18 25.0167 24.4367 24.8417L24.4833 24.8183C24.6233 24.7133 24.565 24.6667 24.46 24.5033L24.39 24.41C24.2967 24.2817 24.2033 24.375 24.2033 24.375L24.0167 24.5033C23.4917 24.8533 22.7683 25.355 22.6517 24.9583C22.5467 24.6667 22.9783 23.08 23.4217 21.4583C23.62 20.7467 23.8183 20 23.97 19.3817L23.9933 19.3117C24.075 18.7983 24.25 17.8067 23.3983 17.8767C22.465 17.9583 19.9333 19.965 19.9333 19.965Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_4814_31074"
          x1="22"
          y1="0.5"
          x2="22"
          y2="38.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D93EB5" />
          <stop offset="1" stop-color="#F51781" />
        </linearGradient>
        <clipPath id="clip0_4814_31074">
          <rect
            width="28"
            height="28"
            fill="white"
            transform="translate(8 5.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default requestMoreInfoIconTransparent;
