import React, { Fragment } from 'react';
import Select, { components } from 'react-select';
//import { css, cx } from 'emotion/css';
import { FixedSizeList as List } from 'react-window';

const Option = props => {
  const { selectProps } = props;

  return (
    <div>
      <components.Option
        {...props}
        className={props.label !== 'Cross Brand' && props.optionsClass}
      >
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={selectProps.onChange}
        />
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <i className="fas fa-sort"></i>
    </components.DropdownIndicator>
  );
};

const CustomValueContainer = ({ children, ...props }) => {
  const { getValue, hasValue, selectProps } = props;
  const nbValues = getValue().length;
  const isCrossBrand = getValue().find(
    option => option.value === 'isCrossBrand'
  );

  if (hasValue) {
    if (nbValues >= 2) {
      if (isCrossBrand) {
        return (
          <components.ValueContainer {...props}>
            <div className="custom-tag-heading">{selectProps.label}</div>
            <div className="custom-tag-value">{`Cross brand + ${nbValues -
              1} selected`}</div>
            {children[1]}
          </components.ValueContainer>
        );
      }

      return (
        <components.ValueContainer {...props}>
          <div className="custom-tag-heading">{selectProps.label}</div>
          <div className="custom-tag-value">{nbValues + ' selected '}</div>
          {children[1]}
        </components.ValueContainer>
      );
    } else if (nbValues === 1) {
      return (
        <components.ValueContainer {...props}>
          <div className="custom-tag-heading">{selectProps.label}</div>
          <div className="custom-tag-value">{selectProps.value[0].label}</div>
          {children[1]}
        </components.ValueContainer>
      );
    }
  } else {
    return (
      <components.ValueContainer {...props}>
        {children}
      </components.ValueContainer>
    );
  }
};

const Menu = props => {
  return (
    <>
      <components.Menu {...props}>{props.children}</components.Menu>
    </>
  );
};

const MenuList = props => {
  const { options, children, maxHeight, getValue } = props;
  const height = 65;
  const [value] = getValue();
  const initialOffset = options.indexOf(value) * height;

  if (!props.isEmailCampaign && options.length > 4) {
    return (
      <List
        height={maxHeight}
        itemCount={children.length}
        itemSize={height}
        initialScrollOffset={initialOffset}
      >
        {({ index, style }) => <div style={style}>{children[index]}</div>}
      </List>
    );
  } else {
    return (
      <components.MenuList {...props}>{props.children}</components.MenuList>
    );
  }
};

const CheckboxDropDown = props => {
  let groupedOptions = props.options;

  if (props.name === 'brands') {
    const crossBrandOption = props?.options?.find(
      option => option.value === 'isCrossBrand'
    );
    const restOptions = props?.options?.filter(
      option => option.value !== 'isCrossBrand'
    );

    const isCrossBrandSelected = Array.isArray(props?.value)
      ? props?.value?.find(v => v.value === 'isCrossBrand')
      : props?.value === 'isCrossBrand';

    groupedOptions = props.options
      ? isCrossBrandSelected
        ? [
            crossBrandOption,
            {
              label: 'Select brands to refine further',
              options: restOptions
            }
          ]
        : props.options
      : [];
  }

  return (
    <Select
      components={{
        Option: innerProps => (
          <Option {...innerProps} optionsClass={props.optionsClass} />
        ),
        DropdownIndicator,
        ValueContainer: CustomValueContainer,
        IndicatorSeparator: null,
        Menu,
        MenuList: innerProps => (
          <MenuList {...innerProps} isEmailCampaign={props.isEmailCampaign} />
        )
      }}
      isMulti={props.isMulti}
      className={props.className}
      classNamePrefix={props.classNamePrefix}
      name={props.name}
      label={props.label}
      hideSelectedOptions={props.hideSelectedOptions}
      placeholder={props.placeholder}
      isDisabled={props.isDisabled}
      isLoading={props.isLoading}
      value={props.value}
      onChange={props.onChange}
      options={groupedOptions}
      isClearable={props.isClearable}
      closeMenuOnSelect={props.closeMenuOnSelect}
      blurInputOnSelect={props.blurInputOnSelect}
      defaultMenuIsOpen={props.defaultMenuIsOpen}
    />
  );
};

export default CheckboxDropDown;
