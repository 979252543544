import * as yup from 'yup';

import { EmailFileSelector } from 'components/organisms/EmailFileSelector';
import constants from '../../constants';

export default (handleSubmit, handleCloseForm, defaultValues) => {
  return {
    formType: 'modal',
    submitBtnLabel: 'Generate URL',
    previousBtnLabel: 'Cancel',
    fields: [
      {
        type: 'custom',
        name: 'fileSelector',
        id: 'fileSelector',
        component: EmailFileSelector,
        defaultValue: {
          ...defaultValues,
          extn: defaultValues.extn
            ? {
                value: defaultValues.extn,
                label: constants.FILE_TYPES.find(
                  type => type.value === defaultValues.extn
                )?.label
              }
            : null
        },
        rules: yup.object({
          assetID: yup
            .string()
            .required('Asset ID is required')
            .matches(/^[0-9]*$/g, 'Please enter valid Asset ID'),
          fileType: yup
            .object({
              label: yup.string(),
              value: yup.string()
            })
            .required('File type is required')
            .default(undefined)
        })
      }
    ],
    onSubmit: data => {
      const { assetID, fileType } = data.fileSelector;

      let url = `${constants.TAB_BASE_URL}${assetID}.${fileType.value}`;

      console.log(url);

      return handleSubmit(url);
    },
    onPrevious: () => {
      handleCloseForm();
    }
  };
};
