import React from 'react';
import ReactTooltip from 'react-tooltip';
import ErrorIcon from '../../atoms/ErrorIcon/ErrorIcon';

export default function HeadingField({
  elem,
  index,
  onHeadingChange,
  profile,
  errors,
  isValid,
  removeAccordion,
  handleAccordionExpandCollapse,
  expand
}) {
  return (
    <div
      id={`accordion_${elem.dataType}`}
      className="accordion saas-form-field-accordion field"
      key={`${elem.dataType}_${index}`}
    >
      <ReactTooltip
        place="right"
        type="info"
        multiline={true}
        className="cw-tooltip"
      />
      <ErrorIcon isValid={isValid} />
      <div className="card" style={{ border: 0, overflow: 'visible' }}>
        <div className="card-header">
          <span
            className="saas-accordion-heading cw-color--primary"
            style={{ fontWeight: '700' }}
          >
            {profile[index].heading
              ? profile[index].heading.length < 70
                ? profile[index].heading
                : profile[index].heading.substring(0, 70) + '...'
              : elem.label}
          </span>
          <span
            type="button"
            className={`saas-accordion-arrow ${index}`}
            onClick={() => handleAccordionExpandCollapse(index)}
          >
            <i
              className={
                expand === index
                  ? 'fas fa-chevron-down transformIcon-180'
                  : 'fas fa-chevron-down'
              }
            ></i>
          </span>
          <span
            id={`close_${elem.dataType}`}
            className={'saas-accordion-close'}
            onClick={() => {
              removeAccordion(index);
            }}
          ></span>
        </div>
        <div className={expand === index ? 'collapse show' : 'collapse'}>
          <ReactTooltip
            place="right"
            type="info"
            multiline={true}
            className="cw-tooltip"
          />
          <div
            className="form-group form-group-field-width"
            key={`${elem.dataType}_${index}`}
          >
            <label htmlFor={`heading_${index}`}>
              <span>Heading Text</span>
              <span
                className="float-right  cw-icon cw-icon--help"
                data-tip="Use this field to specify any custom headings to separate sections or fields of your form"
                currentitem="false"
              >
                <i className="fas fa-question-circle"></i>
              </span>
            </label>
            <input
              type="text"
              className={`form-control ${
                errors[`heading_error_${elem.dataType}_${index}`]
                  ? 'cw-error-focus'
                  : ''
              }`}
              name={`heading_${index}`}
              id={`heading_${index}`}
              maxLength="50"
              aria-describedby={`heading_${index}`}
              data-required="true"
              value={profile[index].heading || ''}
              onChange={e => onHeadingChange(e, index, elem)}
            />
            <span
              id={`heading_error_${elem.dataType}_${index}`}
              className={`cw-error ${
                errors[`heading_error_${elem.dataType}_${index}`]
                  ? ''
                  : 'cw-hidden'
              }`}
              data-control="name"
            >
              <i className="fas fa-exclamation-triangle mr-10"></i>
              Please enter the heading statement
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
