import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { TabPanel } from 'pages/ReportingDashboard/ReportingDashboard';
import EmailGuideline from 'components/EmailGuideline';
import constants from 'constants/config';

export default function EmailDesignChecklist({ onClose }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (e, newValue) => {
    setValue(newValue);
  };

  const tabStyle = { fontFamily: 'Open Sans', textTransform: 'none' };

  return (
    <div className="email-d">
      <div className="title cw-heading--saascampaign m-3 position-relative">
        Email design checklist
        <span
          className={`qna-guideline-close qna-guideline-close-modal`}
          onClick={onClose}
        ></span>
      </div>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="false"
        disableRipple
        style={{ borderBottom: '1px solid #aaa' }}
      >
        <Tab disableRipple style={tabStyle} label="Email sections" />
        <Tab disableRipple style={tabStyle} label="Content checklist" />
        <Tab disableRipple style={tabStyle} label="Testing checklist" />
      </Tabs>
      <TabPanel value={value} index={0}>
        <EmailGuideline
          listStyleType="disc"
          noMargin
          hideCloseButton
          isEmailCampaign
          data={constants.EMAIL_SECTIONS}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <EmailGuideline
          listStyleType="disc"
          noMargin
          hideCloseButton
          isEmailCampaign
          data={constants.EMAIL_CONTENT_CHECKLIST}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <EmailGuideline
          noMargin
          listStyleType="disc"
          hideCloseButton
          isEmailCampaign
          data={constants.EMAIL_TESTING_CHECKLIST}
        />
      </TabPanel>
      <div className="mr-20 mt-3">
        <button
          type="submit"
          onClick={onClose}
          className="btn btn-primary mb-10 cw-print-hide text-nowrap  mr-1 guideline-close-button"
        >
          Close
        </button>
      </div>
    </div>
  );
}
