import React from 'react';
import Alert from './Alert';

const CampaignEditModel = props => {
  const {campaign } = props
  return (
    <div>
      <button
        className="cw-modal--close"
        title="Close"
        onClick={props.closeIsEditModal}
      >
        <i className="fas fa-times"></i>
      </button>
      <h2 className="cw-heading--primary mb-20">
        <strong>Edit Guidelines</strong>
      </h2>
      {props.campaignType &&
      props.campaignType === 'Incentive' &&
      campaign &&
      campaign.incentivePromotionalPartner &&
      campaign.incentivePromotionalPartner === 'promotigo'  ? (
        <Alert
          alertType="warningMessageWithIcon"
          textMessage={`Please ensure that all specifications mentioned in your Campaign Wizard request match with the details you provide in Promotigo at all times, failing which your form may not work correctly.`}
          showComponent={true}
        />
      ) : null}

      <div style={{ 'overflow-y': 'auto', height: '35vh' }}>
        <p className="branddetails_items_list mb-10">
          You are trying to edit a completed campaign. Before you edit, you must
          read these guidelines to understand the elements of a campaign that
          can be changed after it has been processed by Campaign Wizard. If you
          need to make any edits that are not allowed on completed campaigns,
          you must create a new campaign or clone an existing.
        </p>
        {props.data.map(ele => {
          return (
            <>
              <h6 className="mr-10">
                <strong>{ele.heading}</strong>
              </h6>
              {ele.alertText ? (
                <Alert
                  alertType="warningMessageWithIcon"
                  textMessage={ele.alertText}
                />
              ) : null}
              <p className="branddetails_items_list mb-10">
                <ul className="ml-20">
                  {ele.values.map(valEle => {
                    return <li>{valEle}</li>;
                  })}
                </ul>
              </p>
            </>
          );
        })}
        <p className="branddetails_items_list mb-10">
          Please note that any edits made to the form will be immediately
          reflected on the live form
        </p>
      </div>
      <br />
      <div className="mr-20">
        <button
          style={{ float: 'right' }}
          type="submit"
          onClick={props.isEditContinue}
          className="btn btn-primary btn-custom-width mb-10 cw-print-hide text-nowrap  mr-1"
        >
          Continue to Edit
        </button>
        <button
          style={{ float: 'right' }}
          type="submit"
          onClick={props.closeIsEditModal}
          className="btn btn-outline-primary btn-custom-width mb-10 cw-print-hide mr-1"
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default CampaignEditModel;
