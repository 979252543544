import { SET_NOTIFICATION_MESSAGE } from '../../actionTypes';

const initialState = {
  message: '',
  type: '',
  link: '',
  campaign: ''
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_NOTIFICATION_MESSAGE: {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
}
