import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';
import axios from 'axios';
import * as XLSX from 'xlsx';
import moment from 'moment';
import { isEmpty, sortBy } from 'lodash';
import classNames from 'classnames';

import Loader from 'components/Loader';
import DynamicTableV2 from 'components/organisms/DynamicTable/DynamicTableV2';
import BrandFilter from 'components/organisms/Filters/BrandFilter/BrandFilter';
import CountryFilter from 'components/organisms/Filters/CountryFilter/CountryFilter';
import HistoryComponent from 'components/history';
import { AddOptOutTextModal } from './AddOptOutTextModal';
import { DeleteConfirmationModal } from './DeleteConfirmationModal';
import { BulkActionButtons } from './BulkActionButtons';
import { MissingFieldErrors } from './MissingFieldErrors'

import {
  getSMSBrandMarketMappings,
  addSMSBrandMarketMappings,
  deleteSMSOptOutMessage,
  editSMSOptOutMessage,
  getBulkUploadHistoryLogs
} from 'services/smsBrandMapping';

import { getBrandCountries, getBrands } from 'actions/dashboard';
import { getBrandDetails } from 'actions/brandDetails';

import constants from 'constants/config';

import errorIcon from 'images/error_icon.png';

const serviceOptions = [
  {
    value: 'signUpForms',
    label: 'Sign up forms'
  },
  {
    value: 'email',
    label: 'Email'
  },
  {
    value: 'sms',
    label: 'SMS'
  }
];
const smsBrandManagementCols = [
  { name: 'Country', dataType: 'country', sort: true, minimalWidth: true },
  { name: 'Brand', dataType: 'brand', sort: true, minimalWidth: true },
  { name: 'Language', dataType: 'labguage', sort: false, minimalWidth: true },
  { name: 'Opt-out text', dataType: 'optOutMessage', sort: false },
  { name: '', dataType: 'actions', sort: false }
];

export const getQueryObject = obj => {
  const query = {};
  if (obj?.brandCountry?.length) {
    query.countryIds = obj.brandCountry.map(item => item.value).join(',');
  }
  if (obj?.brands?.length) {
    query.brands = encodeURIComponent(obj.brands.map(item => item.label.toLowerCase()).join(','));
    query.brandIds = obj.brands.map(item => item.value).join(',');
  }
  if (obj?.sort?.sortKey) {
    query.sortKey = obj?.sort?.sortKey;
    query.sortBy = obj?.sort?.sortBy;
  }
  if (obj?.languageCodes?.length) {
    query.languageCodes = obj.languageCodes.map(item => item.value).join(',');
  }
  return query;
};
const BrandManagement = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [brandCountry, setBrandCountry] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [smsBrandMarketData, setSmsBrandMarketData] = useState([]);
  const [sort, setSort] = useState({ sortKey: 'country', sortBy: 'asc' });
  const [editingId, setEditingId] = useState(null);
  const [deletingId, setDeletingId] = useState(null);
  const [isChangeInProgress, setIsChangeInProgress] = useState(false)
  const [isAddOptOutTextModalOpen, setIsAddOptOutTextModalOpen] = useState(
    false
  );
  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] = useState(
    false
  );
  const [uploadSuccess, setUploadSuccess] = useState('');
  const [uploadMissingFields, setUploadMissingFields] = useState({ unknownBrands: [], unknownCountries: [] })
  const [uploadError, setUploadError] = useState('');
  const [logs, setLogs] = useState([])

  const dashboardObj = useSelector(state => state.dashboard);
  const { authorizedUserData } = useSelector(state => state.authorized);
  const { campaignConfig } = useSelector(state => state.app);

  const { brands, countries } = dashboardObj;
  const user = {
    userEmail: authorizedUserData.emailId,
    userName: authorizedUserData.name
  }

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBrandCountries());
    dispatch(getBrands());
    dispatch(getBrandDetails());
  }, []);

  useEffect(() => {
    if(selectedService?.value === 'sms') {
      getBulkUploadHistoryLogs('smsBrandManagement').then(data => setLogs(data))
    }
  }, [selectedService]);

  const fetchSMSbrandMappings = query => {
    setIsDataLoading(true);
    getSMSBrandMarketMappings(query)
      .then(data => {
        setIsDataLoading(false);
        setSmsBrandMarketData(data);
      })
      .catch(err => {
        setIsDataLoading(false);
        console.log('getSMSBrandMarketMappings error ', err);
      });
  };

  const resetFilters = () => {
    setBrandCountry([]);
    setBrandList([]);
    setSort({ sortKey: 'country', sortBy: 'asc' });
  };

  const handleServiceChange = selectedOption => {
    setSelectedService(selectedOption);
    resetFilters();
    if (selectedOption && selectedOption.value === 'sms') {
      fetchSMSbrandMappings(getQueryObject({ sort: sort }));
    }
  };

  const isSmsSelected = givenOption => {
    if (givenOption && ['signUpForms', 'email'].includes(givenOption.value)) {
      return false;
    }
    return true;
  };

  const getCountries = () => {
    if (Object.keys(countries).length > 0) {
      return countries.map(function(country) {
        return { value: country._id, label: country.name, code: country.code };
      });
    }
    return [];
  };
  const getBrandsList = () => {
    if (Object.keys(brands).length > 0) {
      return brands.map(function(brand) {
        return { value: brand._id, label: brand.name, code: brand.code };
      });
    }
    return [];
  };

  const handleDropDownValue = (selectedValue, action) => {
    const optionName = action && action.name;
    if (optionName === 'brandcountry') {
      setBrandCountry(selectedValue);
      fetchSMSbrandMappings(
        getQueryObject({
          brandCountry: selectedValue,
          brands: brandList,
          sort: sort
        })
      );
    }
    if (optionName === 'brands') {
      setBrandList(selectedValue);
      fetchSMSbrandMappings(
        getQueryObject({
          brandCountry: brandCountry,
          brands: selectedValue,
          sort: sort
        })
      );
    }
  };

  const handleSort = dataType => {
    const sortObj = {};
    if (dataType === sort.sortKey) {
      sortObj.sortKey = dataType;
      sortObj.sortBy = sort.sortBy === 'asc' ? 'desc' : 'asc';
    } else {
      sortObj.sortKey = dataType;
      sortObj.sortBy = 'asc';
    }
    setSort(sortObj);
    fetchSMSbrandMappings(
      getQueryObject({
        brandCountry: brandCountry,
        brands: brandList,
        sort: sortObj
      })
    );
  };

  const addItem = async data => {
    try {
      setIsDataLoading(true);

      await addSMSBrandMarketMappings({
        ...data,
        optOutKeyword: data.optOutTextInput.keyword,
        optOutText: data.optOutTextInput.text
      }, user);

      setSort({ sortKey: 'language.updatedAt', sortBy: 'desc' })

      const updatedData = await getSMSBrandMarketMappings(
        getQueryObject({
          brandCountry,
          brands: brandList,
          sort: { sortKey: 'language.updatedAt', sortBy: 'desc' }
        })
      );
      setSmsBrandMarketData(updatedData);

      setIsAddOptOutTextModalOpen(false);
    } catch (err) {
      console.log(err);
    } finally {
      setIsDataLoading(false);
    }
  };

  const deleteItem = async () => {
    try {
      setIsChangeInProgress(true);

      await deleteSMSOptOutMessage(deletingId);

      let updatedList = smsBrandMarketData.filter(item => item.language._id != deletingId)
      setSmsBrandMarketData(updatedList);

      setIsDeleteConfirmModalOpen(false);
    } catch (err) {
      console.log(err);
    } finally {
      setIsChangeInProgress(false);
    }
  };

  const updateItem = async (value, language) => {
    try {
      setIsChangeInProgress(true);

      const response = await editSMSOptOutMessage(editingId, value, language, user);

      let updatedList = smsBrandMarketData.map(item => {
          if (item.language._id == editingId){
            return response
          }
          return item
      });
      setSmsBrandMarketData(updatedList);

      setEditingId(null);
    } catch (err) {
      console.log(err);
    } finally {
      setIsChangeInProgress(false);
    }
  };

  const uploadBulkData = async event => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const formData = new FormData();
      formData.append('file', selectedFile);
      formData.append('userEmail', user.userEmail)
      formData.append('userName', user.userName)

      try {
        setIsDataLoading(true);
        setUploadError('')
        setUploadSuccess('')

        const response = await axios.post(
          constants.serviceUrls.OPT_OUT_BULK_UPLOAD,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        );

        setUploadSuccess(response.data.message)
        setUploadMissingFields({
          unknownBrands: [...new Set(response.data.unknownBrands)],
          unknownCountries: [...new Set(response.data.unknownCountries)]
        })
        
        setSort({ sortKey: 'language.updatedAt', sortBy: 'desc' })
        const updatedData = await getSMSBrandMarketMappings(getQueryObject({
          brandCountry,
          brands: brandList,
          sort: { sortKey: 'language.updatedAt', sortBy: 'desc' }
        }));
        
        getBulkUploadHistoryLogs('smsBrandManagement').then(data => setLogs(data))
        setSmsBrandMarketData(updatedData);
      } catch (error) {
        setUploadError(error?.response?.data?.message);
        console.error('Error uploading file', error.response);
      } finally {
        setIsDataLoading(false);
      }
    } else {
      setUploadError('Please select an Excel file before submitting.');
    }
  };

  const downloadData = async () => {
    const data = await getSMSBrandMarketMappings();
    const sortedData = data.map(item => ({
      ...item,
      sortField: `${item.countryId.code}_${item.brandId.name.toLowerCase()}_${item.language.languageCode.toUpperCase()}`
    })).sort((a,b) => {
      if(a.sortField < b.sortField) {
        return -1
      } else if(a.sortField > b.sortField) {
        return 1
      }
      return 0
    })

    let list = [];
    if (sortedData && sortedData.length) {
      for (let item of sortedData) {
        let newRow = {
          Market_Brand_Language: `${item.countryId.code}_${
            item.brandId.code
          }_${item.language.languageCode.toUpperCase()}`,
          'Country Code': item.countryId.code,
          'Brand Code': item.brandId.code,
          'Brand Name': item.brandId.name,
          Country: item.countryId.name,
          'Language Code': item.language.languageCode.toUpperCase(),
          Language: item.language.languageName,
          'Opt Out Keyword': item.language.optOutKeyword.toUpperCase(),
          'Opt Out Text': item.language.optOutText
        };
        list.push(newRow);
      }
    }

    const worksheet = XLSX.utils.json_to_sheet(list);

    XLSX.utils.sheet_add_aoa(
      worksheet,
      [['Exported on', new moment().format('DD MMM YYYY')]],
      { origin: 'A' + (list.length + 3) }
    );

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Master Data');
    XLSX.writeFile(workbook, `SMSMasterData.xlsx`);
  };

  return (
    <div className="col-sm-12 skypink">
      <div className="cw-section">
        {/* <BreadCrumb match={props.match} emailBriefing /> */}
        <div className="cw-section--title mb-30">
          <h2 className="cw-heading--secondary cw-email-heading">
            Brand management
          </h2>
          <p>Configure brand-country combinations enabled to use CW services</p>
        </div>

        <div className="row align-items-center">
          {isLoading ? <Loader /> : null}
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="service">Select service</label>
              <Select
                className={classNames('cw-select--custom')}
                classNamePrefix="react-select"
                options={serviceOptions}
                name="service"
                id="service"
                value={selectedService}
                placeholder={'Select service'}
                hideSelectedOptions={false}
                onChange={handleServiceChange}
              />
            </div>
          </div>

          {selectedService?.value === 'sms' && (
            <div className="col-md-6 mt-3">
              <button
                className="btn btn-outline-primary float-right"
                onClick={() => setIsAddOptOutTextModalOpen(true)}
              >
                Add opt out text
              </button>
            </div>
          )}
        </div>

        {!isEmpty(selectedService) ? (
          isSmsSelected(selectedService) ? (
            <>
              <div className="row">
                <div className="d-flex align-items-center col-sm-6">
                  {/* Country dropdown */}
                  <CountryFilter
                    asyncInProgress={false}
                    value={brandCountry}
                    onChange={handleDropDownValue}
                    options={getCountries()}
                    classNamePrefix="react-multi-select"
                    isEmailCampaign={false}
                  />

                  {/* Brand Filter */}
                  <BrandFilter
                    asyncInProgress={false}
                    value={brandList}
                    onChange={handleDropDownValue}
                    options={getBrandsList()}
                    classNamePrefix="react-multi-select"
                    isEmailCampaign={false}
                    noMarginRight={true}
                  />
                </div>
                <div className="d-flex justify-content-end align-items-center col-sm-6">
                  <BulkActionButtons
                    upload={uploadBulkData}
                    download={downloadData}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  {uploadError && (
                    <div className="error-text mt-3 d-flex" style={{ gap: 6 }}>
                    <img
                      style={{ width: 20, height: 20 }}
                      src={errorIcon}
                      alt={'Invalid'}
                    />
                    {uploadError}
                  </div>
                  )}
                  {uploadSuccess && (
                    <MissingFieldErrors
                      message={uploadSuccess}
                      unknownBrands={uploadMissingFields.unknownBrands}
                      unknownCountries={uploadMissingFields.unknownCountries}
                    />
                  )}
                  <DynamicTableV2
                    campaignConfig={campaignConfig}
                    sortClick={handleSort}
                    columnHeaders={smsBrandManagementCols}
                    asyncInProgress={isDataLoading}
                    isChangeInProgress={isChangeInProgress}
                    userData={smsBrandMarketData}
                    minimalWidthColumns={['brand', 'country', 'language']}
                    panelType={'smsBrandManagement'}
                    editUser={id => {
                      setEditingId(id);
                    }}
                    deleteUser={id => {
                      setDeletingId(id);
                      setIsDeleteConfirmModalOpen(true);
                    }}
                    className="brand-management-table mt-20"
                    businessGroup={() => {
                      return null;
                    }}
                    sort={sort}
                    editingId={editingId}
                    updateItem={updateItem}
                  />
                </div>
              </div>

              {logs && !isDataLoading && (
                <div className='mt-20'>
                  <HistoryComponent historyLogs={logs} isEmailBrief={true} />
                </div>
              )}
            </>
          ) : (
            <div className="row">
              <div className="col-md-12">
                <p>
                  All active brand and country combinations in master Brand
                  Positioning &#40;BPOS&#41; are enabled to use sign up forms
                </p>
              </div>
            </div>
          )
        ) : null}
      </div>

      <AddOptOutTextModal
        isOpen={isAddOptOutTextModalOpen}
        addItem={addItem}
        onClose={() => setIsAddOptOutTextModalOpen(false)}
      />

      <DeleteConfirmationModal
        isOpen={isDeleteConfirmModalOpen}
        onClose={() => setIsDeleteConfirmModalOpen(false)}
        deleteItem={deleteItem}
      />
    </div>
  );
};

export default BrandManagement;
