import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { CustomButtons } from 'pages/EmailCampaignAudience';
import { FormBuilder } from 'components/organisms/FormBuilder';
import Loader from 'components/Loader';
import ProgressBar from 'components/molecules/ProgressBar';
import formSchema from './smsScheduleSchema';
import constants from '../../constants/config';
import { retrieveSmsCampaignInformation } from 'actions/communications/communicationCampaignDetails.js';

const SMSSchedule = props => {
  const [isEdit, setEdit] = useState(false);
  const communicationCampaignDetails = useSelector(
    state => state.communicationCampaignDetails
  );
  const { authorizedUserData } = useSelector(state => state.authorized);
  const { country, brand, language } = useSelector(
    state => state.communicationCampaignDetails
  );
  const dispatch = useDispatch();
  const pathUid = props.match.params.id;
  useEffect(() => {
    if (pathUid) {
      setEdit(true);
      // fetch from email details from the server
      dispatch(retrieveSmsCampaignInformation(pathUid));
    }
  }, []);

  const smsBrandDetails =
    communicationCampaignDetails &&
    communicationCampaignDetails.smsBrandDetails;

  if (!smsBrandDetails) return null;
  const { isLoading } = communicationCampaignDetails;
  const { uid, deliverySchedule, smsType } = smsBrandDetails;

  if (!pathUid) {
    return <Redirect to="new-email-campaign" />;
  }

  const compProps = {
    history: props.history,
    match: props.match,
    defaultValues: deliverySchedule || {},
    smsType: smsType,
    campaignDetails: smsBrandDetails
  };

  const isSMSDataEmpty = isEmpty(smsBrandDetails);

  return (
    <div className="col-sm-12 skypink">
      <div className="cw-section">
        <ProgressBar
          customClass={'cw-email-progress-custom'}
          totalPage={constants.SMS_PROGRESS_BAR_TOT_PAGE}
          pageNo={constants.SMS_JOURNEY_PAGE_NUMBERS.SCHEDULE}
        />
        <div className="cw-section--title mb-30">
          <h2 className="cw-heading--secondary cw-email-heading">
            SMS schedule
          </h2>
        </div>
        <div className="row">
          {isLoading ? <Loader /> : null}
          <div className="col-md-8">
            {!isLoading && !isSMSDataEmpty && (
              <FormBuilder
                schema={formSchema(dispatch, compProps)}
                customButtonsComp={CustomButtons}
                user={authorizedUserData}
                isEdit={isEdit}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SMSSchedule;
