import React from 'react';
import {
  DisplayEmailTriggerInput,
  sentAfterEmail
} from 'components/molecules/EmailTriggerInput';
import { emailCustomCriteriaLabel } from 'components/molecules/SendEmailToInput';
import { compact, get } from 'lodash';
import constants from 'constants/config';
import { DONT_SEND } from 'components/molecules/EmailScheduleInput';
import { LinkButton } from 'components/atoms/LinkButton';
import {
  getAudienceSendEmailTolabel,
  userHasCouponCodePermission
} from 'selectors';
import WinningVariantIndicator from 'components/atoms/WinningVariantIndicator';
import { couponDisplay, CouponCodeDisplay } from './CouponCodeDisplay';
import moment from 'moment';

function getSubjectLinesData(
  abTesting,
  info = {},
  winningVariant,
  isFollowUp = false
) {
  const isABTestOnSubjectLine = abTesting.abTestElements === 'subjectLine';
  if (abTesting.performABTests && isABTestOnSubjectLine) {
    let line1,
      line2,
      line3 = null;
    let line4 = isFollowUp ? (
      <p className="email-label-subtext">
        The follow up subject line will be based on whether variant A or B wins
        in the AB test
      </p>
    ) : null;

    if (
      isABTestOnSubjectLine &&
      winningVariant &&
      winningVariant === 'variantA'
    ) {
      line1 = (
        <>
          <p>{`Variant A | ${decodeURIComponent(info.subject || '')}`}</p>{' '}
          <WinningVariantIndicator variant={winningVariant} />
        </>
      );
    } else {
      line1 = <p>{`Variant A | ${decodeURIComponent(info.subject || '')}`}</p>;
    }

    if (
      isABTestOnSubjectLine &&
      winningVariant &&
      winningVariant === 'variantB'
    ) {
      line2 = (
        <>
          <p>{`Variant B | ${decodeURIComponent(
            info.subjectVariantB || ''
          )}`}</p>{' '}
          <WinningVariantIndicator variant={winningVariant} />
        </>
      );
    } else {
      line2 = (
        <p>{`Variant B | ${decodeURIComponent(info.subjectVariantB || '')}`}</p>
      );
    }

    if (isABTestOnSubjectLine && abTesting.noOfABVariants === '3') {
      if (winningVariant && winningVariant === 'variantC') {
        line3 = (
          <>
            <p>{`Variant C | ${decodeURIComponent(
              info.subjectVariantC || ''
            )}`}</p>{' '}
            <WinningVariantIndicator variant={winningVariant} />
          </>
        );
      } else {
        line3 = (
          <p>{`Variant C | ${decodeURIComponent(
            info.subjectVariantC || ''
          )}`}</p>
        );
      }
      line4 = isFollowUp ? (
        <p className="email-label-subtext">
          The follow up subject line will be based on whether variant A, B or C
          wins in the AB test
        </p>
      ) : null;
    }

    return (
      <>
        {line1}
        {line2}
        {line3}
        {line4}
      </>
    );
  } else {
    return decodeURIComponent((info && info.subject) || '');
  }
}

export function getTabularData(emailProps, review, currentUser, parentData) {
  const {
    brand,
    country,
    language,
    emailType,
    status,
    summary,
    deliverySchedule,
    audienceDefinition,
    firstAttempt,
    followUp,
    requestersEmail,
    emailTemplateEditId: { coreTemplate, isLiveEmailTemplate } = {},
    emailCollaborators = {},
    isCrossBrand,
    abTesting = {},
    winningVariant,
    emailAttributes,
    couponFileTrack = {},
    submittedDate
  } = emailProps && emailProps.emailBriefing;

  const isLive = status === constants.EMAIL_STATUS.LIVE;
  const isFixedDateAndTime =
    deliverySchedule?.emailTrigger?.type === 'fixedDateAndtime';

  const couponApplied = emailAttributes?.emailCoupon?.couponApplied;
  const couponType = emailAttributes?.emailCoupon?.type;
  const isCouponCodeVisible = userHasCouponCodePermission(
    requestersEmail,
    currentUser,
    emailCollaborators
  );

  const brandDisplay = Array.isArray(brand)
    ? isCrossBrand
      ? `Cross brand email for ${brand.map(b => (b ? b.name : '')).join(', ')}`
      : brand[0].name
    : brand.name;

  const emalSecondaryTheme =
    (summary &&
      summary?.emailContentSecondaryTheme
        ?.map(theme => theme.label)
        .join(', ')) ||
    '';

  const emailCollaboratorsData = () => {
    let collabUserData = [
      {
        label: 'Collaborators permissions',
        value:
          constants.EMAIL_COLLABORATORS_PERMISSION_TYPE[
            emailCollaborators.type
          ],
        mustShow: true
      }
    ];
    if (emailCollaborators.type === 'requestorAndSelectedUsers') {
      collabUserData.push({
        label: 'Collaborators',
        value: compact(emailCollaborators.collaborators
          .map(item => item.value))
          .join(', ')
      });
    }
    return collabUserData;
  };

  const brandSummary = [
    {
      label: 'Country',
      value: country && country.name
    },
    {
      label: 'Brand',
      value: brandDisplay
    },
    {
      label: 'Email language',
      value: language && language.name
    }
  ];

  const campaignType = [
    {
      label: 'Email type',
      mustShow: true,
      value: constants.emailType[emailType]
    }
  ];

  let campaignSummary = [
    {
      label: 'Campaign name',
      mustShow: true,
      value: summary && summary.campaignName
    },
    {
      label: 'Description',
      mustShow: true,
      value: summary && summary.campaignDescription
    },
    {
      label: 'Content theme',
      mustShow: true,
      value:
        summary && summary.emailContentTheme && summary.emailContentTheme.label
    },
    {
      label: 'Secondary theme',
      mustShow: true,
      value: emalSecondaryTheme
    },
    {
      ...(!review && {
        label: 'Brand',
        value: brandDisplay
      })
    },
    {
      ...(!review && {
        label: 'Country',
        value: country && country.name
      })
    },
    {
      label: 'Email language',
      value: language && language.name
    },
    {
      label: 'Additional information',
      value: summary && summary.additionalInformation
    },
    {
      label: 'Requestor',
      value: requestersEmail
    },
    {
      ...(submittedDate && {
        label: 'Requested on',
        value: submittedDate ? moment(submittedDate).format('DD MMM YYYY') : '',
        mustShow: true
      })
    },

    ...emailCollaboratorsData()
  ];

  if (review) {
    campaignSummary = campaignSummary.filter(
      item => item.label !== 'Email language'
    );
  }

  const emailABTesting = [
    {
      label: 'A/B test',
      value: abTesting && abTesting.performABTests ? 'Yes' : 'No'
    }
  ];
  if (abTesting && abTesting.performABTests) {
    emailABTesting.push({
      label: 'A/B test elements',
      value:
        constants.EMAIL_AB_TESTING_CONSTANTS[abTesting.abTestElements] || '',
      mustShow: true
    });
    emailABTesting.push({
      label: 'AB test split',
      value: constants.EMAIL_AB_TESTING_CONSTANTS[abTesting.abTestSplits] || '',
      mustShow: true
    });
    if (
      constants.EMAIL_AB_TEST_DURATION_CHECK_LIST.includes(
        abTesting.abTestSplits
      )
    ) {
      emailABTesting.push({
        label: 'Test duration',
        value: abTesting.testDuration ? abTesting.testDuration.label : '',
        mustShow: true
      });
    }
    emailABTesting.push({
      label: 'Win criteria',
      value: constants.EMAIL_AB_TESTING_CONSTANTS[abTesting.winCriteria] || '',
      mustShow: true
    });
  }

  const campaignAttributes = [
    {
      label: 'Coupons',
      value: couponDisplay(couponApplied, couponType)
    },
    {
      ...(couponApplied && {
        label: 'Coupon codes',
        value: (
          <CouponCodeDisplay
            emailAttributes={emailAttributes}
            userPermitted={isCouponCodeVisible}
            couponFileTrack={couponFileTrack}
          />
        )
      })
    },
    ...emailABTesting
  ];

  let emailTrigger =
    deliverySchedule && deliverySchedule.emailTrigger
      ? DisplayEmailTriggerInput(
          deliverySchedule.emailTrigger,
          abTesting,
          winningVariant
        )
      : [
          {
            label: 'Email trigger',
            mustShow: true,
            value: ''
          }
        ];

  let sendAfterArr = deliverySchedule
    ? sentAfterEmail(deliverySchedule)
    : [
        {
          label: 'Send follow up',
          mustShow: true,
          value: ''
        }
      ];

  const deliveryScheduleData = [
    ...emailTrigger,
    ...sendAfterArr,
    {
      label: 'Additional information',
      value: deliverySchedule && deliverySchedule.additionalInformation
    }
  ];

  let campaignIds = [];
  if (
    audienceDefinition &&
    audienceDefinition.sendEmailTo &&
    audienceDefinition.sendEmailTo.type &&
    audienceDefinition.sendEmailTo.type.value === 'consumersLinkedToCampaignIds'
  ) {
    let value =
      audienceDefinition &&
      audienceDefinition.sendEmailTo &&
      audienceDefinition.sendEmailTo.details &&
      audienceDefinition.sendEmailTo.details.campaignIds &&
      audienceDefinition.sendEmailTo.details.campaignIds;
    if (value && value.length && typeof value[0] === 'object') {
      value = value.map(campaignIdObject => campaignIdObject.value);
    }
    campaignIds.push({
      label: `${emailProps.campaignConfig.LABEL_SIGN_UP_ID}(s)`,
      value: value.join(', '),
      tooltip: `Campaign IDs are now known as ${emailProps.campaignConfig.LABEL_SIGN_UP_ID}s to better explain <br /> the purpose of the field to uniquely identify the initiative <br /> through which a consumer signed up`
    });
  }

  let customCriteria = [];
  if (
    audienceDefinition &&
    audienceDefinition.sendEmailTo &&
    audienceDefinition.sendEmailTo.type &&
    audienceDefinition.sendEmailTo.type.value === 'customCriteria'
  ) {
    const {
      ageRange,
      audienceFileName,
      gender,
      last,
      unit,
      pastEmailEngagementCriteria,
      customCriteriaList,
      criteriaCampaignIds
    } = get(audienceDefinition, 'sendEmailTo.details', {});
    if (customCriteriaList && customCriteriaList.length) {
      customCriteria.push({
        label: 'Custom criteria',
        value: customCriteriaList
          .map(g => emailCustomCriteriaLabel(emailProps.campaignConfig)[g])
          .join(', ')
      });
    }
    if (criteriaCampaignIds && criteriaCampaignIds.length) {
      customCriteria.push({
        label: `${emailProps.campaignConfig.LABEL_SIGN_UP_ID}(s)`,
        value: criteriaCampaignIds.map(campaign => campaign.value).join(', '),
        tooltip: `Campaign IDs are now known as ${emailProps.campaignConfig.LABEL_SIGN_UP_ID}s to better explain <br /> the purpose of the field to uniquely identify the initiative <br /> through which a consumer signed up`
      });
    }
    if (customCriteriaList && customCriteriaList.includes('standardProfile')) {
      if (gender && gender.length) {
        customCriteria.push({
          label: 'Gender',
          value: gender.map(g => g.label).join(', ')
        });
      } else {
        customCriteria.push({
          label: 'Gender',
          value: 'All genders'
        });
      }
    }

    if (pastEmailEngagementCriteria) {
      customCriteria.push({
        label: 'Past email engagement',
        value: pastEmailEngagementCriteria.label
      });
    }
    if (last && unit) {
      customCriteria.push({
        label: 'Duration',
        value: `Last ${last.label} ${unit.label}`
      });
    }
    if (audienceFileName) {
      customCriteria.push({
        label: 'Audience name from ACT',
        value: audienceFileName
      });
    }
    if (ageRange && ageRange.length) {
      customCriteria.push({
        label: 'Age range',
        value: ageRange.join(' - ')
      });
    }
    customCriteria.push({
      label: 'Custom criteria details',
      value:
        audienceDefinition &&
        audienceDefinition.sendEmailTo &&
        audienceDefinition.sendEmailTo.details &&
        audienceDefinition.sendEmailTo.details.customCriteriaDetails
    });
  }

  emailProps.emailBriefing.audienceBrand = Array.isArray(brand)
    ? brand.map(b => b.name).join(', ')
    : brand.name;

  const audienceData = [
    {
      label: 'Send email to',
      mustShow: true,
      value: getAudienceSendEmailTolabel(emailProps && emailProps.emailBriefing)
    },
    ...campaignIds,
    ...customCriteria,
    {
      label: 'Additional information',
      value: audienceDefinition && audienceDefinition.additionalInformation
    },
    {
      label: 'Audience count',
      value: isFixedDateAndTime
        ? deliverySchedule?.consumerCount
          ? deliverySchedule?.consumerCount
          : 'ACS execution team will update the expected audience count when they setup the campaign'
        : 'Not available for emails with dynamic triggers'
    }
  ];

  const emailProperties = [
    {
      label: 'Sender name',
      value: 'As configured in ACS (email execution engine)'
    },
    {
      label: 'Sender email',
      value: 'As configured in ACS (email execution engine)'
    }
  ];

  const isDontSend = deliverySchedule?.sendAfter?.value === DONT_SEND;

  const emailPropertiesSections = [];
  if (isDontSend) {
    const emailPropertiesFirstAttempt = [];
    emailProperties.push({
      label: 'Subject',
      subLabel: ' (Main send)',
      value: getSubjectLinesData(abTesting, firstAttempt, winningVariant),
      mustShow: true
    });
    emailProperties.push({
      label: 'Preheader',
      subLabel: ' (Main send)',
      value: firstAttempt && firstAttempt.preheader,
      mustShow: true
    });
    // emailPropertiesSections.push({ title: 'First attempt', properties: emailPropertiesFirstAttempt })
  } else {
    const emailPropertiesFirstAttempt = [];
    emailProperties.push({
      label: 'Subject',
      subLabel: ' (Main send)',
      value: getSubjectLinesData(abTesting, firstAttempt, winningVariant),
      mustShow: true
    });
    emailProperties.push({
      label: 'Preheader',
      subLabel: ' (Main send)',
      value: firstAttempt && firstAttempt.preheader,
      mustShow: true
    });
    const emailPropertiesFollowUp = [];
    emailProperties.push({
      label: 'Subject',
      subLabel: ' (Follow up)',
      value: getSubjectLinesData(abTesting, followUp, winningVariant, true),
      mustShow: true
    });
    emailProperties.push({
      label: 'Preheader',
      subLabel: ' (Follow up)',
      value: followUp && followUp.preheader,
      mustShow: true
    });
    // emailPropertiesSections.push({ title: 'First attempt', properties: emailPropertiesFirstAttempt })
    // emailPropertiesSections.push({ title: 'Follow up', properties: emailPropertiesFollowUp })
  }

  const emailDesignReferences = handleTemplatePreview => {
    const isBlackTemplate =
      coreTemplate &&
      coreTemplate.name &&
      coreTemplate.name.trim() === 'blank-template';

    return [
      {
        label: 'Template name',
        value: isBlackTemplate ? (
          'Blank template'
        ) : isLiveEmailTemplate ? (
          `Existing email "${parentData.name}" from ${parentData.brand}, ${parentData.country}`
        ) : coreTemplate ? (
          <LinkButton
            onClick={() => {
              handleTemplatePreview && handleTemplatePreview(coreTemplate);
            }}
          >
            {coreTemplate.title}
          </LinkButton>
        ) : (
          'The core template has been deleted'
        )
      }
    ];
  };

  return {
    brandSummary,
    campaignType,
    campaignSummary,
    campaignAttributes,
    deliveryScheduleData,
    audienceData,
    emailProperties,
    emailPropertiesSections,
    emailDesignReferences
  };
}
