import React, { Fragment, Component, useR } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import Modal from 'react-modal';
import Loader from '../../components/Loader';
import CheckBoxInput from 'components/molecules/CheckBoxInput';
import constant from '../../constants';
import classNames from 'classnames';
import Alert from '../../components/Alert';
import {
  fetchSmsBrandDetails,
  fetchSmsBrandList,
  updateSmsDetails,
  getSmsCampaignInformation,
  submitSmsBrandDetails,
  getBrandCountries,
  getBrandByCountry,
} from '../../actions/communications/communicationBrandDetails.js';
import { getSmsCampaignBrandsInformation } from 'actions/communications/communicationCampaignDetails';
import { getBrandDetails } from 'services/emailBriefings';
import { textCapitalization, _generateUUID, SMSSubmitRedirectURL } from '../../selectors/index';
import axios from 'axios';
import constants from '../../constants';
import ProgressBar from 'components/molecules/ProgressBar';
import { getIsEdited } from 'pages/NewEmailCampaign/brandDetailsformSchema';
import {get} from 'lodash';
import ErrorNotification from 'components/errorNotification';
import OnboardingGuidanceSmsModal from './OnboardingGuidanceSmsCamp';
import OptOutTextMissingMessage from './OptOutTextMissingMessage';
import NewUpdateAlert from 'components/molecules/NewUpdateAlert';

class CommunicationBrandDetails extends Component {
  state = {
    uuid: this.props.match.params.id,
    isModalOpen: false,
    englishSelected: false,
    otherSelected: false,
    country: {},
    language: '',
    languageId: null,
    languageCode: null,
    brand: {},
    otherLanguage: null,
    languages:[],
    showMessageAtTop:false,
    POSCheck: false,
    isEdited: false,
    status: 0,
    showOnboardingWarning: false,
    isOptOutLoading: false
  };

  componentDidMount() {
    this.props.fetchSmsBrandDetails({bpos: this.props?.appConfig?.SMS_JOURNEY_BPOS});
    this.props.getBrandCountries();
    this.fetchLanguages();
    // if (this.props.language === null) {
    //   this.props.updateSmsDetails({
    //     language: constant.SMS_CAMPAIGN_DEFAULT_LANGUAGE
    //   });
    //   this.setState({
    //     language: constant.SMS_CAMPAIGN_DEFAULT_LANGUAGE
    //   });
    // }
    const isEdited = getIsEdited({ history: this.props.history });
    this.setState({ isEdited })
  }

  componentDidUpdate(prevProps) {
    if (
      (prevProps.countries !== this.props.countries)
    ) {
      if (this.state.uuid !== undefined) {
        this.setValuesToFieldOnPageLoad(this.state.uuid);
      } else {
        this.setValuesToFieldOnPageLoadNewCampaign();
      }
    }
  }
  setValuesToFieldOnPageLoad = uid => {
    console.log('setValuesToFieldOnPageLoad: ');
    let brandDetaillURL = constant.communications.POST_SMS_BRAND_DETAIL;
    axios.get(brandDetaillURL + '/' + uid).then(res => {
      let response = res && res.data;
      this.setState({ status: response.status })

      const campaignCountry = this.props.countries.find(e=> e.name.toLowerCase() === response.country)
      let countryData = {
        value: response.country,
        label: textCapitalization(response.country),
        _id : campaignCountry._id
      };
      let brandData = {
        value: response.brand,
        label: textCapitalization(response.brand)
      };
      this.setState({
        country: countryData,
        brand: brandData
      }, () => {
        console.log("=====================1=====================");
      });
      this.setState({ POSCheck: response.brandPOSFlag }, () => {
        console.log("=====================2=====================");
      })
      this._handleCountries(countryData, {dontFetchOptOut: true});
      this._updateBrandState(brandData);
      const givenLanguage = this.state.languages.find(e => e.label === response.language)
      console.log("=====================3=====================");
      if (response.language !== 'English') {
        this.setState({
          otherLanguage: givenLanguage?.label || response.language,
          language: givenLanguage?.label || response.language,
          languageId : givenLanguage?.id || null,
          languageCode: givenLanguage?.value || null
        },() => {
          console.log("=====================4=====================");
          this._getOptOutText();
        });
      }
      if (response.language === 'English') {
        this.setState({
          otherLanguage: null,
          language: givenLanguage?.label || response.language,
          languageId : givenLanguage?.id || null,
          languageCode: givenLanguage?.value || null
        },() => {
          console.log("=====================4-2=====================");
          this._getOptOutText();
        });
      }
    });
  };

  setValuesToFieldOnPageLoadNewCampaign = () => {   
      
      //this.setState({ status: 200 })
      const prevSavedData = get(this.props, "smsCampaignDetails.dataToSend", {});
      if(prevSavedData.country && prevSavedData.brand && prevSavedData.language){
        //const campaignCountry = this.props.countries.find(e=> e.name.toLowerCase() === prevSavedData.country)
        let countryData = {
          value: prevSavedData.country,
          label: textCapitalization(prevSavedData.country),
          _id : prevSavedData.countryId
        };
        
        let brandData = {
          value: prevSavedData.brand,
          label: textCapitalization(prevSavedData.brand),
          _id:  prevSavedData.brandId
        };
        this.setState({
          country: countryData,
          brand: brandData
        });
        this.setState({ POSCheck: prevSavedData.brandPOSFlag })
        this._handleCountries(countryData, {dontFetchOptOut: true});
        this._updateBrandState(brandData);
        const givenLanguage = this.state.languages.find(e => e.label === prevSavedData.language)
          this.setState({
            otherLanguage: prevSavedData.language,
            language: prevSavedData.language,
            languageId: prevSavedData.languageId,
            languageCode: givenLanguage?.value || null
        }, () => {
          console.log("=====================5=====================");
          this._getOptOutText();
        })
    }
  };

  _updateBrandState = (brandData)=>{
    this.props.updateSmsDetails({
      brand: brandData
    });
    this.setState({
      brand: brandData
    });
    this._validateBrandField(brandData.value);
  }

  _scrollToTop() {
    setTimeout(() => {
      document.getElementById('root').scrollIntoView({ behavior: 'smooth' });
    }, 10);
  }
  _handleBrands = selectedOption => {
    const {country , smsBrandList}  = this.props
    
    this.props.updateSmsDetails({
      brand: selectedOption
    });
    this.setState({
      brand: selectedOption
    }, () => {
      console.log("=====================6 brand=====================");
      this._getOptOutText();
    });
    this._validateBrandField(selectedOption.value);
  };

  _handleCountries = (selectedOption, {dontFetchOptOut} = {}) => {
    this.props.fetchSmsBrandList(selectedOption.value);
    if(selectedOption._id){
      this.props.getBrandByCountry(selectedOption._id);
    }
    this.props.updateSmsDetails({ country: selectedOption });
    this.setState({
      country: selectedOption,
      showMessageAtTop  : false,
      brand: {}
    }, () => {
      console.log("=====================7 country=====================");
      if(!dontFetchOptOut){
        this._getOptOutText();
      }
      
    });
    this.props.updateSmsDetails({ brand: null });
    this._validateCountryField(selectedOption.label.toLowerCase());
  };

  _getCountries = () => {
    let { smsCountries, countries } = this.props;
    if (smsCountries.length > 0) {
      return smsCountries.map(country => {
        if(typeof country === 'object'){
          return {
            value: country.name,
            label: textCapitalization(country.name),
            _id: country._id
          };
        }
        const campaignCountry = countries.find(e=> e.name.toLowerCase() === country) 
        return {
          value: country,
          label: textCapitalization(country),
          _id: campaignCountry?._id
        }
        
      });
    }
  };

  _getBposCountries() {
    let { countries } = this.props;
    if (Object.keys(countries).length > 0) {
      return countries.map(function(country) {
        return { value: country.name.trim().toLowerCase() , label: country.name ,_id : country._id};
      });
    }
  }

  _getBrands = () => {
    let { smsBrandList } = this.props;
    if (smsBrandList.length > 0) {
      return smsBrandList.map(brand => {
        return {
          value: brand.brand,
          label: textCapitalization(brand.brand),
          _id: brand.brandId
        };
      });
    }
  };


  _getBposBrands() {
    let { brands } = this.props;
    if (Object.keys(brands).length > 0) {
      return brands.map(function(brand) {
        return { value: brand.name.trim().toLowerCase(), label: brand.name ,_id : brand._id };
      });
    }
  }

  _getOptOutText = () => {
    console.log('going _getOptOutText ');
    let country = this.state.country && this.state.country.label;
    console.log('_getOptOutText country: ', country);
    let brand = this.state.brand && this.state.brand.label;
    console.log('_getOptOutText brand: ', brand);
    if(country && brand && this.state.language && this.state.languageCode){
      this.setState({isOptOutLoading: true});
      let languageData = [{_id: this.state.languageId, name: this.state.language, code: this.state.languageCode}]
      console.log('_getOptOutText languageData: ', languageData);
      this.props.getSmsCampaignBrandsInformation(country,brand.toLowerCase(), languageData, (error, result) => {
        console.log('getSmsCampaignBrandsInformation result: ', result);
        this.setState({isOptOutLoading: false});
        if(error){
          console.log("error: ", error.message);
        } else {
          if(!result){
            this.setState({showOnboardingWarning: true});
          } else {
            this.setState({showOnboardingWarning: false});
          }
        }
      })
    }
  }

  _openModal = event => {
    event.preventDefault();
    this.setState({
      isModalOpen: true
    });
  };

  _closeModal = () => {
    this.setState({
      isModalOpen: false
    });
  };

  onEnglishRadioClick = event => {
    this.props.updateSmsDetails({
      language: constant.SMS_CAMPAIGN_DEFAULT_LANGUAGE
    });
    this.setState({
      otherLanguage: null,
      language: constant.SMS_CAMPAIGN_DEFAULT_LANGUAGE
    });
    document.getElementById('otherlanguage').value = null;
    if (!this.refs.languageError.classList.contains('cw-hidden')) {
      this.refs.languageError.classList.add('cw-hidden');
    }
  };

  onOtherRadioClick = event => {
    this.props.updateSmsDetails({ language: null });
    this.setState({
      language: null,
      otherLanguage: ''
    });
  };

  handleOtherLanguage = event => {
    let languageEntered = event.target.value.trim();
    if (languageEntered.length > 0) {
      this.setState({
        otherLanguage: languageEntered,
        language: languageEntered
      });

      if (!this.refs.languageError.classList.contains('cw-hidden')) {
        this.refs.languageError.classList.add('cw-hidden');
      }
    }
    if (
      event.key === 'Backspace' ||
      (event.key === 'Delete' && languageEntered.length > 0)
    ) {
      if (!this.refs.languageError.classList.contains('cw-hidden')) {
        this.refs.languageError.classList.add('cw-hidden');
      }
    } //event.key === 'Backspace' || (event.key === 'Delete' &&
  };

  // _getLanguages() {
  //   let { languages } = this.props;
  //   if (Object.keys(languages).length > 0) {
  //     return languages.map(function(brand) {
  //       return { value: brand.code + '-' + brand._id, label: brand.name };
  //     });
  //   }
  // }

  handleLanguageSelect = (item) => {
    this.setState({language: item.label, languageId: item.id , languageCode: item.value,  showMessageAtTop  : false}, () => {
      if (Object.keys(this.state.language).length > 0) {
        this.refs.languageError.classList.add('cw-hidden');
        document
        .getElementById(`language`)
        .classList.contains('cw-error-focus') &&
        document.getElementById(`language`).classList.remove('cw-error-focus');
        this._getOptOutText();
      }
    })
  }

  _validateCountryField = country => {
    let countryValue = country && country.value;
    if (countryValue !== null) {
      if (!this.refs.countryError.classList.contains('cw-hidden')) {
        this.refs.countryError.classList.add('cw-hidden');
        document
        .getElementById(`country`)
        .classList.contains('cw-error-focus') &&
        document.getElementById(`country`).classList.remove('cw-error-focus');
      }
    }
  };

  _validateBrandField = brand => {
    let brandValue = brand && brand.value;
    if (brandValue !== null) {
      if (!this.refs.brandError.classList.contains('cw-hidden')) {
        this.refs.brandError.classList.add('cw-hidden');
        document
        .getElementById(`brand`)
        .classList.contains('cw-error-focus') &&
        document.getElementById(`brand`).classList.remove('cw-error-focus');
      }
    }
  };

  validateFormSubmit = () => {
    let isValid = true;
    if (
      Object.keys(this.state.country).length === 0 &&
      this.state.country.constructor === Object
    ) {
      if (this.refs.countryError.classList.contains('cw-hidden')) {
        this.refs.countryError.classList.remove('cw-hidden');
      }
      document
      .getElementById(`country`)
      .classList.contains('cw-error-focus') ||
      document.getElementById(`country`).classList.add('cw-error-focus');
     
      isValid = false;
    }
    if (
      Object.keys(this.state.brand).length === 0 &&
      this.state.brand.constructor === Object
    ) {
      if (this.refs.brandError.classList.contains('cw-hidden')) {
        this.refs.brandError.classList.remove('cw-hidden');
      }
      document
      .getElementById(`brand`)
      .classList.contains('cw-error-focus') ||
      document.getElementById(`brand`).classList.add('cw-error-focus');
      isValid = false;
    }
    if (this.state.language !== constant.SMS_CAMPAIGN_DEFAULT_LANGUAGE) {
      if (
        this.state.language === null ||
        this.state.language === '' ||
        (this.state.language && this.state.language.length < 1) ||
        (Object.keys(this.state.language).length === 0 && this.state.language.constructor === Object)
      ) {
        if (this.refs.languageError.classList.contains('cw-hidden')) {
          this.refs.languageError.classList.remove('cw-hidden');
        }
        document
        .getElementById(`language`)
        .classList.contains('cw-error-focus') ||
        document.getElementById(`language`).classList.add('cw-error-focus');
        isValid = false;
      }
    }
    return isValid;
  };

  _handleSubmit = (event, backToReview) => {
    event.preventDefault();
    let uuid;
    // if (this.props.match.params.id === undefined) {
    //   uuid = _generateUUID();
    // }
    if (this.props.match.params.id !== undefined) {
      uuid = this.props.match.params.id;
    }
    if (this.validateFormSubmit()) {
      this.setState({showMessageAtTop : false})
      this.props.submitSmsBrandDetails(
        uuid,
        this.state.brand,
        this.state.country,
        this.state.language,
        this.props.userName,
        this.props.userEmail,
        this.state.languageId,
        this.state.POSCheck,
        (error, result) => {
          if (result) {
              if(uuid){
                this.props.history.push(SMSSubmitRedirectURL({
                  backToReview: this.state.isEdited && backToReview,
                  status: this.state.status,
                  uid: uuid,
                  nextPage: `${constants.SMS_ROUTES.SMS_TYPE}`
                }))
              } else {
                this.props.history.push(`/campaign-wizard/${constant.SMS_ROUTES.NEW_SMS_TYPE}`);
              }
              
            // this.props.history.push(`${constant.SMS_BASE_ROUTE}/${uuid}/${constant.SMS_ROUTES.SMS_TYPE}`);
          } else {
            this._scrollToTop();
          }
        }
      );
      this._scrollToTop();

      // setTimeout(() => {
      //   console.log("same one: ", this.props.response);
        
      // }, 1500);
    } else {
      this.setState({showMessageAtTop : true})
      this._scrollToTop();
    }
  };

  _countryOptions() {
    return this._getBposCountries()
  }

  _brandOptions() {
    return this._getBposBrands()
  }

  _togglePOSCheck = () => {
    this.setState(prev => ({ 
      ...prev, POSCheck: !prev.POSCheck,
      country: {},
      brand: {},
      language: {},
      languageId: null,
      englishSelected: false,
      showMessageAtTop: false
    }))
  }
    
  fetchLanguages = () => {
    getBrandDetails().then(res => {
      if (res.languages) {
        let languages = res.languages.map(language => {
          return {
            value: language.code,
            label: language.name,
            id: language._id
          };
        });
        this.setState({ languages: languages }, () => {
          console.log("language callback")
          if (this.state.uuid !== undefined) {
            this.setValuesToFieldOnPageLoad(this.state.uuid);
          } else {
            this.setValuesToFieldOnPageLoadNewCampaign();
          }
        });
      }
    });
  }

  render() {
    let englishLanguage = constant.SMS_CAMPAIGN_DEFAULT_LANGUAGE;
    let hideOtherLanguage = classNames({
      'cw-hidden': !(this.state.language !== englishLanguage)
    });
    return (
      <>
        {this.props.isLoading || this.state.isOptOutLoading ? <Loader /> : null}
        <div className="col-sm-12 skypink email-campaign-brand-selection">
          <div className="cw-section" ref={this.screenTop}>
            {this.state.showMessageAtTop ? <ErrorNotification /> : null}
            <strong>
              <Alert
                alertType={'nonEditableBrandDetails'}
                type={'danger'}
                message={constant.SYSTEM_ERROR_MESSAGE}
                showComponent={
                  (this.props.systemError &&
                    this.props.systemError.response === undefined) ||
                  (this.props.systemError &&
                    this.props.systemError.response !== 201)
                }
              />
            </strong>

            <ProgressBar
              customClass={'cw-email-progress-custom'}
              pageNo={constants.SMS_JOURNEY_PAGE_NUMBERS.NEW_CAMPAIGN}
              totalPage={constants.SMS_PROGRESS_BAR_TOT_PAGE}
            />

            <div className="cw-section--title mb-30">
              <h2 className="cw-heading--secondary cw-email-heading">
                Enter Brand Details
              </h2>
              <p>
                <a
                  href="#modal"
                  onClick={this._openModal}
                  title="Onboarding guidance for SMS campaigns"
                >
                  Onboarding guidance for SMS campaigns
                </a>
              </p>
            </div>
            <div className="row">
              <div className="col-sm-8">
                <div className="cw-section--content">
                  <form
                    method="GET"
                    action=""
                    className="cw-form"
                    onSubmit={this._handleSubmit}
                  >
                    <div className="form-group">
                      <label htmlFor="country">Country</label>
                      <Select
                        className={'cw-select--custom'}
                        name={'country'}
                        label={'Country'}
                        placeholder={'Select option'}
                        ref="inputCountry"
                        id={'country'}
                        options={this._countryOptions()}
                        onChange={e => this._handleCountries(e)}
                        value={
                          this.state.country && this.state.country.value
                            ? {
                                label: this.state.country.label,
                                value: this.state.country.value,
                                _id: this.state.country._id
                              }
                            : null
                        }
                      />
                      <span
                        className="cw-error cw-hidden"
                        data-control="country"
                        ref="countryError"
                      >
                        <i className="fas fa-exclamation-triangle mr-10"></i>
                        Please select country.
                      </span>
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Brand Name</label>
                      <Select
                        className={'cw-select--custom'}
                        name={'brand'}
                        label={'Brand'}
                        id={'brand'}
                        placeholder={'Select option'}
                        isDisabled={
                          Object.keys(this.state.country).length === 0 &&
                          this.state.country.constructor === Object
                        }
                        ref="inputBrand"
                        options={this._brandOptions()}
                        onChange={e => this._handleBrands(e)}
                        value={
                          this.state.brand && this.state.brand.value
                            ? {
                                label: this.state.brand.label,
                                value: this.state.brand.value
                              }
                            : null
                        }
                      />
                      <span
                        className="cw-error cw-hidden"
                        ref="brandError"
                        data-control="brand"
                      >
                        <i className="fas fa-exclamation-triangle mr-10"></i>
                        Please select brand.
                      </span>
                    </div>

                    <div className="form-group">
                      <label htmlFor="language">Language</label>
                      <Select
                        className={'cw-select--custom'}
                        name={'language'}
                        label={'Language'}
                        id={'language'}
                        placeholder={'Select option'}
                        options={this.state.languages}
                        onChange={this.handleLanguageSelect}
                        isDisabled={
                          !this.props.country ||
                          this.props.editCampaign ||
                          (Object.keys(this.state.country).length === 0 &&
                            this.state.country.constructor === Object)
                        }
                        isLoading={this.props.isLoading}
                        value={
                          Object.keys(this.state.language).length > 0 &&
                          this.state.languages.find(
                            item => item.label === this.state.language
                          )
                        }
                      />
                      <span
                        className="cw-error cw-hidden"
                        id={'language'}
                        data-control="language"
                        ref="languageError"
                      >
                        <i className="fas fa-exclamation-triangle mr-10"></i>
                        Please select language.
                      </span>
                    </div>

                    {this.state.showOnboardingWarning && (
                      <OptOutTextMissingMessage />
                    )}

                    <div>
                      <button
                        type="submit"
                        className="btn btn-primary"
                        // disabled={this.state.showMessageAtTop}
                      >
                        Next
                      </button>
                      {this.state.isEdited && (
                        <button
                          class="cw-link-btn cw-formdraft-link mt-20"
                          onClick={e => this._handleSubmit(e, true)}
                        >
                          Save and back to review
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-md-4">
            <NewUpdateAlert
              className="mt-30"
              title="SMS marketing is now inhouse!"
              content={
                <>
                  <p>
                    Empower yourself with complete control over your SMS campaigns using our new and improved SMS capabilities.
                  </p>
                  <p>
                  Here's how it works: Simply submit your campaign, and our dedicated market approvers will swiftly review and approve it. Once approved, our Global ACS team will provide you with a cost estimate, ensuring transparency and budget control. Then, create your Purchase Order in Coupa and upload it to Campaign Wizard, and watch as our global ACS team spring into action, executing your campaign with precision and efficiency.
                  </p>
                </>
              }
            />
          </div>
            </div>
            <OnboardingGuidanceSmsModal
              isModalOpen={this.state.isModalOpen}
              closeModal={this._closeModal}
            />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  smsBrands: state.communicationBrandDetails.smsBrands,
  smsCountries: state.communicationBrandDetails.smsCountries,
  smsBrandList: state.communicationBrandDetails.smsBrandList,
  country: state.communicationBrandDetails.country,
  brand: state.communicationBrandDetails.brand,
  englishSelected: state.communicationBrandDetails.englishSelected,
  otherSelected: state.communicationBrandDetails.otherSelected,
  otherLanguage: state.communicationBrandDetails.otherLanguage,
  optOutText: state.communicationBrandDetails.optOutText,
  language: state.communicationBrandDetails.language,
  uuid: state.communicationBrandDetails.uuid,
  smsCampaignDetails: state.communicationBrandDetails,
  isLoading: state.communicationBrandDetails.isLoading,
  systemError: state.communicationBrandDetails.systemError,
  response: state.communicationBrandDetails.response,
  brands: state.communicationBrandDetails.brands,
  countries: state.communicationBrandDetails.countries,
  appConfig: state.app.campaignConfig,
});

export default connect(mapStateToProps, {
  fetchSmsBrandDetails,
  fetchSmsBrandList,
  updateSmsDetails,
  getSmsCampaignInformation,
  submitSmsBrandDetails,
  getBrandCountries,
  getBrandByCountry,
  getSmsCampaignBrandsInformation,
})(CommunicationBrandDetails);
