import Alert from 'components/Alert';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import constant from '../../constants';
import ReactTooltip from 'react-tooltip';
import Select from 'react-select';
import {
  fetchCampaignData,
  cancelCampaignChanges
} from '../../actions/overview';
import { scrollToTop } from 'selectors';
import axios from 'axios';
import {
  setApproverEditCampaignStatus,
  setApproverEditCampaignStatusMessage,
  setApproverEditLastUpdatedScreen
} from '../../actions/app';
import { getIncentiveFields } from '../../actions/saasFormAuthoring';
import BreadCrumb from '../BreadCrumb';
import CampaignCancelModel from '../CampaignCancelModel';
import Modal from 'react-modal';
let incentiveFieldData = null;

class SaasIncentive extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expand: 0,
      campaignId: this.props.match.params.id,
      incentiveFields: [],
      matchProps: props.match,
      isMandatory: false,
      showErrorMessage: false,
      fileUploadError: false,
      instantWinLose: false,
      showAllPurposeError: false,
      isModalOpen: false,
      isCancelModalOpen: false
    };
    this._openModal = this._openModal.bind(this);
    this._closeModal = this._closeModal.bind(this);
    this._openIsCancelModal = this._openIsCancelModal.bind(this);
    this._closeIsCancelModal = this._closeIsCancelModal.bind(this);
    this._cancelSaasCampaign = this._cancelSaasCampaign.bind(this);
  }
  _openModal(event) {
    event.preventDefault();
    this.setState({
      isModalOpen: true
    });
  }

  _closeModal() {
    this.setState({
      isModalOpen: false
    });
  }

  _openIsCancelModal(event) {
    event.preventDefault();
    this.setState({
      isCancelModalOpen: true
    });
  }

  _closeIsCancelModal() {
    this.setState({
      isCancelModalOpen: false
    });
  }

  _cancelSaasCampaign(event) {
    event.preventDefault();
    this.setState({
      isCancelModalOpen: false
    });
    this.props.cancelCampaignChanges(this.campaignId);
    this.props.history.push('/campaign-wizard/campaign/' + this.campaignId);
  }

  componentDidMount() {
    this.campaignId = this.props.match.params.id;
    this.props.fetchCampaignData(this.campaignId, false, true);
    axios
      .get(
        constant.serviceUrls.CAMPAIGN_DETAILS_SERVICE_URL +
          '/' +
          this.props.match.params.id
      )
      .then(response => {
        let campaign = response.data[0];
        let language = campaign.language
          ? this.props.campaign.language.code
          : '';
        this.props.getIncentiveFields(language);
        let fields =
          campaign && campaign.campaignDetail && campaign.campaignDetail.fields;
        let instantWinLoseFlag =
          campaign &&
          campaign.campaignDetail &&
          campaign.campaignDetail.instantWinLose;

        if (instantWinLoseFlag) {
          this.setState({
            instantWinLose: instantWinLoseFlag
          });
        }
        if (fields && fields.length > 0) {
          this._handleAccordionExpandCollapse(-1);

          let isCompititionSample =
            campaign && campaign.incentiveType === 'Competitions';
          let isProductSample =
            campaign && campaign.incentiveType === 'Product samples';
          if (isCompititionSample) {
            fields = fields.filter(
              ele => ele.dataType !== 'samplePrizeSelection'
            );
          }
          if (isProductSample) {
            fields = fields.filter(ele => ele.dataType !== 'competitionDetail');
          }

          // for legacy video option I have added
          if (fields && fields.length > 0) {
            fields.forEach(ele => {
              if (ele.dataType === 'fileUpload') {
                if (ele.values && ele.values.length) {
                  let findVideoIndex = ele.values.findIndex(
                    v => v.key === constant.INCENTIVE_VIDEO_OPTION_KEY
                  );
                  if (findVideoIndex === -1) {
                    let newValues = [...ele.values];
                    newValues.push({
                      key: constant.INCENTIVE_VIDEO_OPTION_KEY,
                      value: constant.INCENTIVE_VIDEO_OPTION_VALUE,
                      isChecked: true
                    });
                    ele.values = newValues;
                  }
                }
              }
            });
          }

          this.setState({
            incentiveFields: fields
          });
        }
      });
  }

  handleIncentiveSelection = selectedOption => {
    let field = {};
    if (selectedOption.dataType === 'entryCode') {
      field = {
        dataType: 'entryCode',
        label: 'Entry Code',
        type: 'incentiveFields',
        fieldLabel: '',
        mandatoryToggle: false,
        mandatoryMessage: '',
        toolTip: ''
      };
    } else if (selectedOption.dataType === 'samplePrizeSelection') {
      field = {
        answers: [
          {
            label: '',
            value: 'prize-0'
          }
        ],
        dataType: 'samplePrizeSelection',
        questionType: 'Single choice',
        label: 'Sample Details',
        fieldLabel: '',
        type: 'incentiveFields',
        mandatoryToggle: false,
        mandatoryMessage: '',
        toolTip: ''
      };
    } else if (selectedOption.dataType === 'fileUpload') {
      field = {
        dataType: 'fileUpload',
        fieldLabel: '',
        maxFileSize: constant.INCENTIVE_UPLOAD_MAX_FILE_SIZE,
        type: 'incentiveFields',
        mandatoryToggle: false,
        mandatoryMessage: '',
        toolTip: '',
        label: 'File Upload',
        validationMessage: '',
        failedUploadMessage: '',
        values: [
          {
            key: 'jpg',
            value: 'jpg',
            isChecked: true
          },
          {
            key: 'jpeg',
            value: 'jpeg',
            isChecked: true
          },
          {
            key: 'tiff',
            value: 'tiff',
            isChecked: true
          },
          {
            key: 'tif',
            value: 'tif',
            isChecked: true
          },
          {
            key: 'png',
            value: 'png',
            isChecked: true
          },
          {
            key: 'Video (3gp, 3g2, mp4, flv, mkv, avi, mov)',
            value: '3gp, 3g2, mp4, flv, mkv, avi, mov',
            isChecked: true
          }
        ]
      };
    }

    return field;
  };

  _handleIncentiveField = selectedOption => {
    selectedOption = this.handleIncentiveSelection(selectedOption);

    // entryCode and samplePrizeSelectionValidation only one time able to add
    let isDuplicate = this.state.incentiveFields.some(elem => {
      return (
        elem.dataType === selectedOption.dataType &&
        (elem.dataType === 'entryCode' ||
          elem.dataType === 'samplePrizeSelection' ||
          elem.dataType === 'competitionDetail')
      );
    });
    let fileUpdateArr = this.state.incentiveFields.filter(
      elem =>
        selectedOption.dataType === 'fileUpload' &&
        elem.dataType === 'fileUpload'
    );

    let fileUploadData =
      this.props.incentiveFields &&
      this.props.incentiveFields.length > 0 &&
      this.props.incentiveFields.filter(e => e.dataType === 'fileUpload');

    selectedOption.fieldLabel =
      this.props.incentiveFields &&
      this.props.incentiveFields.length > 0 &&
      this.props.incentiveFields.filter(
        e => e.dataType === selectedOption.dataType
      )[0].translatedLabel;

    selectedOption.mandatoryMessage =
      this.props.incentiveFields &&
      this.props.incentiveFields.length > 0 &&
      this.props.incentiveFields.filter(
        e => e.dataType === selectedOption.dataType
      )[0].mandatoryMessage;

    if (selectedOption.dataType === 'fileUpload') {
      selectedOption.validationMessage =
        fileUploadData &&
        fileUploadData.length > 0 &&
        fileUploadData[0].validationMessage;
      selectedOption.failedUploadMessage =
        fileUploadData &&
        fileUploadData.length > 0 &&
        fileUploadData[0].otherErrorMessage;
    }

    if (isDuplicate) {
      this.setState({ showErrorMessage: true, fileUploadError: false });
      scrollToTop();
    } else {
      // 3 time fileupload can able to do then need to give error message
      if (fileUpdateArr && fileUpdateArr.length === 3) {
        this.setState({ showErrorMessage: true, fileUploadError: true });
        scrollToTop();
      } else {
        if (this.validateIncentiveField()) {
          this.state.incentiveFields.push(selectedOption);
          this.setState({
            expand: this.state.incentiveFields.indexOf(selectedOption),
            showErrorMessage: false,
            fileUploadError: false,
            incentiveFields: this.state.incentiveFields
          });
        }
      }
    }
  };

  onChangeRadio = (e, index) => {
    let radioValue = e.target.value;
    let newIncentiveFields = [...this.state.incentiveFields];
    newIncentiveFields[index].questionType = radioValue;
    this.setState({
      incentiveFields: [...newIncentiveFields]
    });
  };

  _removeAccordion = index => {
    let updatedFields = [...this.state.incentiveFields];
    updatedFields.splice(index, 1);
    const incentiveFields = updatedFields.filter(
      ele => ele.type === 'incentiveFields'
    );
    if (incentiveFields && incentiveFields.length === 0) {
      this.setState({
        showAllPurposeError: false
      });
    }
    this.setState({
      incentiveFields: [...updatedFields]
    });
  };

  _handleAccordionExpandCollapse = index => {
    this.setState(prevState => ({
      expand: prevState.expand === index ? false : index
    }));
  };

  _addAnswer = (e, index) => {
    let isValid = false;
    let newIncentiveFields = [...this.state.incentiveFields];
    let answerArr =
      newIncentiveFields[index] && newIncentiveFields[index].answers
        ? newIncentiveFields[index].answers
        : [];
    let answerNew = {
      label: '',
      value: `prize-${answerArr.length}`
    };
    answerArr.map((answer, ansIndex) => {
      if (answer && answer.label.trim().length === 0) {
        document.getElementById(`answer_Error_Message_${index}_${ansIndex}`) &&
          document
            .getElementById(`answer_Error_Message_${index}_${ansIndex}`)
            .classList.remove('cw-hidden');

        document
          .getElementById(`answerTranslatedLabel_${index}_${ansIndex}`)
          .classList.contains('cw-error-focus') ||
          document
            .getElementById(`answerTranslatedLabel_${index}_${ansIndex}`)
            .classList.add('cw-error-focus');

        isValid = true;
      }
    });

    if (isValid) {
      return;
    }
    if (
      newIncentiveFields[index] &&
      newIncentiveFields[index].answers &&
      newIncentiveFields[index].answers.length === 12
    ) {
      document.getElementById(`answer_Error_Message_${index}`) &&
        document
          .getElementById(`answer_Error_Message_${index}`)
          .classList.remove('cw-hidden');
    } else {
      newIncentiveFields[index] &&
        newIncentiveFields[index].answers &&
        newIncentiveFields[index].answers.push(answerNew);

      newIncentiveFields[index].answers.forEach((ele, anindex) => {
        ele.label = newIncentiveFields[index].answers[anindex].label;
        ele.value = `prize-${anindex}`;
      });
      this.setState({
        incentiveFields: [...newIncentiveFields]
      });
    }
  };

  handleAnswerLabel = (e, index, ansIndex) => {
    let fieldLabel = e.target.value;
    let newIncentiveFields = [...this.state.incentiveFields];
    newIncentiveFields[index].answers[ansIndex].label = fieldLabel;
    document.getElementById(`answer_Error_Message_${index}_${ansIndex}`) &&
      document
        .getElementById(`answer_Error_Message_${index}_${ansIndex}`)
        .classList.add('cw-hidden');

    document
      .getElementById(`answerTranslatedLabel_${index}_${ansIndex}`)
      .classList.contains('cw-error-focus') &&
      document
        .getElementById(`answerTranslatedLabel_${index}_${ansIndex}`)
        .classList.remove('cw-error-focus');

    this.setState({
      incentiveFields: [...newIncentiveFields]
    });
  };

  _deleteAnswer = (index, indexValue) => {
    let sampleArray = [...this.state.incentiveFields];
    // let updatedAnswers =
    sampleArray[index] &&
      sampleArray[index].answers &&
      sampleArray[index].answers.splice(indexValue, 1);

    if (
      sampleArray &&
      sampleArray[index] &&
      sampleArray[index].answers &&
      sampleArray[index].answers.length !== 12
    ) {
      document.getElementById(`answer_Error_Message_${index}`) &&
        document
          .getElementById(`answer_Error_Message_${index}`)
          .classList.add('cw-hidden');
    }

    sampleArray[index].answers.forEach((ele, anindex) => {
      ele.label = sampleArray[index].answers[anindex].label;
      ele.value = `prize-${anindex}`;
    });

    sampleArray[index].answers.map((answer, ansIndex) => {
      if (answer && answer.label.trim().length === 0) {
        document.getElementById(`answer_Error_Message_${index}_${ansIndex}`) &&
          document
            .getElementById(`answer_Error_Message_${index}_${ansIndex}`)
            .classList.remove('cw-hidden');

        document
          .getElementById(`answerTranslatedLabel_${index}_${ansIndex}`)
          .classList.contains('cw-error-focus') ||
          document
            .getElementById(`answerTranslatedLabel_${index}_${ansIndex}`)
            .classList.add('cw-error-focus');
      } else {
        document
          .getElementById(`answer_Error_Message_${index}_${ansIndex}`)
          .classList.add('cw-hidden');

        document
          .getElementById(`answerTranslatedLabel_${index}_${ansIndex}`)
          .classList.contains('cw-error-focus') &&
          document
            .getElementById(`answerTranslatedLabel_${index}_${ansIndex}`)
            .classList.remove('cw-error-focus');
      }
    });

    this.setState({
      incentiveFields: [...sampleArray]
    });
  };

  _handleToolTip = (e, index) => {
    let enteredText = e.target.value;
    let newIncentiveFields = [...this.state.incentiveFields];
    newIncentiveFields[index].toolTip = enteredText;
    this.setState({
      incentiveFields: [...newIncentiveFields]
    });
  };

  _mandatoryQuestionHandler = (event, index) => {
    let isChecked = event.target.checked;
    let newIncentiveFields = [...this.state.incentiveFields];
    newIncentiveFields[index].mandatoryToggle = isChecked;
    this.setState({
      incentiveFields: [...newIncentiveFields]
    });
  };

  _handleMandatoryMessage = (e, index, elem) => {
    let enteredText = e.target.value;
    let newIncentiveFields = [...this.state.incentiveFields];

    document.getElementById(`mandatory_Error_Message_${index}`) &&
      document
        .getElementById(`mandatory_Error_Message_${index}`)
        .classList.add('cw-hidden');

    document
      .getElementById(`errorMessage_${index}`)
      .classList.contains('cw-error-focus') &&
      document
        .getElementById(`errorMessage_${index}`)
        .classList.remove('cw-error-focus');

    newIncentiveFields[index].mandatoryMessage = newIncentiveFields[index]
      .mandatoryToggle
      ? enteredText
      : '';
    this.setState({
      incentiveFields: [...newIncentiveFields]
    });
  };

  _handleValidationMessage = (e, index, elem) => {
    let enteredText = e.target.value;
    let newIncentiveFields = [...this.state.incentiveFields];
    document.getElementById(`validation_Error_Message_${index}`) &&
      document
        .getElementById(`validation_Error_Message_${index}`)
        .classList.add('cw-hidden');

    document
      .getElementById(`validationMessage_${index}`)
      .classList.contains('cw-error-focus') &&
      document
        .getElementById(`validationMessage_${index}`)
        .classList.remove('cw-error-focus');

    newIncentiveFields[index].validationMessage = enteredText;

    this.setState({
      incentiveFields: [...newIncentiveFields]
    });
  };

  _handleFileUploadMessage = (e, index, elem) => {
    let enteredText = e.target.value;
    let newIncentiveFields = [...this.state.incentiveFields];
    document.getElementById(`file_Upload_Error_Message_${index}`) &&
      document
        .getElementById(`file_Upload_Error_Message_${index}`)
        .classList.add('cw-hidden');

    document
      .getElementById(`fileUploadMessage_${index}`)
      .classList.contains('cw-error-focus') &&
      document
        .getElementById(`fileUploadMessage_${index}`)
        .classList.remove('cw-error-focus');

    newIncentiveFields[index].failedUploadMessage = enteredText;

    this.setState({
      incentiveFields: [...newIncentiveFields]
    });
  };

  _handleImageOnchange = (e, index, imgIndex) => {
    let newIncentiveFields = [...this.state.incentiveFields];
    newIncentiveFields[index].values[imgIndex].isChecked = e.target.checked;
    this.setState({
      incentiveFields: [...newIncentiveFields]
    });
  };

  validateIncentiveField = () => {
    let isValid = true;
    // if incentive field array is blank then also allow next
    let filterIncentiveField = this.state.incentiveFields.filter(
      elem => elem.type === 'incentiveFields'
    );
    if (filterIncentiveField && filterIncentiveField.length === 0) {
      return isValid;
    }
    this.state.incentiveFields.map((elem, index) => {
      if (elem.type === 'incentiveFields') {
        if (
          elem &&
          elem.dataType !== 'competitionDetail' &&
          Object.values(elem.fieldLabel).filter(elem => /\S/.test(elem))
            .length === 0
        ) {
          isValid = false;
          this.setState({ expand: index });
          document.getElementById(`labelError_${index}`) &&
            document
              .getElementById(`labelError_${index}`)
              .classList.remove('cw-hidden');

          document.getElementById(`field_label_${index}`) &&
            document
              .getElementById(`field_label_${index}`)
              .classList.add('cw-error-focus');
        }

        if (elem.mandatoryToggle) {
          if (elem.mandatoryMessage.trim().length === 0) {
            isValid = false;
            this.setState({ expand: index });
            document.getElementById(`mandatory_Error_Message_${index}`) &&
              document
                .getElementById(`mandatory_Error_Message_${index}`)
                .classList.remove('cw-hidden');

            document
              .getElementById(`errorMessage_${index}`)
              .classList.contains('cw-error-focus') ||
              document
                .getElementById(`errorMessage_${index}`)
                .classList.add('cw-error-focus');
          } else {
            document.getElementById(`mandatory_Error_Message_${index}`) &&
              document
                .getElementById(`mandatory_Error_Message_${index}`)
                .classList.add('cw-hidden');

            document
              .getElementById(`errorMessage_${index}`)
              .classList.contains('cw-error-focus') &&
              document
                .getElementById(`errorMessage_${index}`)
                .classList.remove('cw-error-focus');
          }
        }

        if (
          elem &&
          elem.dataType === 'fileUpload' &&
          (!elem.validationMessage || elem.validationMessage.trim().length == 0)
        ) {
          isValid = false;
          this.setState({ expand: index });
          document.getElementById(`validation_Error_Message_${index}`) &&
            document
              .getElementById(`validation_Error_Message_${index}`)
              .classList.remove('cw-hidden');

          document
            .getElementById(`validationMessage_${index}`)
            .classList.contains('cw-error-focus') ||
            document
              .getElementById(`validationMessage_${index}`)
              .classList.add('cw-error-focus');
        }

        if (
          elem &&
          elem.dataType === 'fileUpload' &&
          (!elem.failedUploadMessage ||
            elem.failedUploadMessage.trim().length == 0)
        ) {
          isValid = false;
          this.setState({ expand: index });
          document.getElementById(`file_Upload_Error_Message_${index}`) &&
            document
              .getElementById(`file_Upload_Error_Message_${index}`)
              .classList.remove('cw-hidden');

          document
            .getElementById(`fileUploadMessage_${index}`)
            .classList.contains('cw-error-focus') ||
            document
              .getElementById(`fileUploadMessage_${index}`)
              .classList.add('cw-error-focus');
        }

        if (
          elem &&
          elem.dataType === 'fileUpload' &&
          (!elem.maxFileSize || elem.maxFileSize.trim().length == 0)
        ) {
          isValid = false;
          this.setState({ expand: index });
          document.getElementById(`file_size_${index}`) &&
            document
              .getElementById(`file_size_${index}`)
              .classList.remove('cw-hidden');

          document
            .getElementById(`fileSize_${index}`)
            .classList.contains('cw-error-focus') ||
            document
              .getElementById(`fileSize_${index}`)
              .classList.add('cw-error-focus');
        }

        if (
          elem &&
          elem.dataType === 'fileUpload' &&
          elem.maxFileSize > parseInt(constant.INCENTIVE_UPLOAD_MAX_FILE_SIZE)
        ) {
          isValid = false;
          this.setState({ expand: index });
          document.getElementById(`max_file_size_${index}`) &&
            document
              .getElementById(`max_file_size_${index}`)
              .classList.remove('cw-hidden');
        }

        if (elem && elem.answers && elem.answers.length > 0) {
          elem.answers.map((answer, ansIndex) => {
            if (answer && answer.label.trim().length === 0) {
              document.getElementById(
                `answer_Error_Message_${index}_${ansIndex}`
              ) &&
                document
                  .getElementById(`answer_Error_Message_${index}_${ansIndex}`)
                  .classList.remove('cw-hidden');

              document
                .getElementById(`answerTranslatedLabel_${index}_${ansIndex}`)
                .classList.contains('cw-error-focus') ||
                document
                  .getElementById(`answerTranslatedLabel_${index}_${ansIndex}`)
                  .classList.add('cw-error-focus');

              isValid = false;
            }
          });
          this.setState({ expand: index });
        }
        if (elem && elem.answers && elem.answers.length === 12) {
          document.getElementById(`answer_Error_Message_${index}`) &&
            document
              .getElementById(`answer_Error_Message_${index}`)
              .classList.add('cw-hidden');
        }
      }
    });

    if (!isValid) {
      this.setState({
        showAllPurposeError: true
      });
    } else {
      this.setState({
        showAllPurposeError: false
      });
    }
    return isValid;
  };

  submitIncentiveField = (event, saveAsDraft) => {
    let { campaign } = this.props;
    let dataToSend = {
      uid: this.props.match.params.id,
      instantWinLose: this.state.instantWinLose,
      fields: [...this.state.incentiveFields],
      isEdited: campaign.isEdited
    };
    if (saveAsDraft) {
      dataToSend['saveAsDraft'] = saveAsDraft;
    }

    axios
      .post(constant.serviceUrls.CAMPAIGN_DATA_SERVICE_URL, dataToSend)
      .then(response => {
        if (response) {
          if (saveAsDraft) {
            this.props.history.push('/campaign-wizard/dashboard');
          } else {
            if (campaign.status === '6') {
              this.props.setApproverEditCampaignStatus(true);
              this.props.setApproverEditCampaignStatusMessage('success');
              this.props.setApproverEditLastUpdatedScreen(
                'saas-incentive-fields'
              );
              this.props.history.push(
                '/campaign-wizard/campaign/' +
                  this.props.match.params.id +
                  '/saas-sorting-page'
              );
            } else {
              this.props.history.push(
                '/campaign-wizard/campaign/' +
                  this.props.match.params.id +
                  '/saas-consent-compliance'
              );
            }
          }
        }
      });
  };

  _handleSubmit = (event, saveAsDraft) => {
    if (saveAsDraft) {
      this.submitIncentiveField(event, saveAsDraft);
    } else {
      if (this.validateIncentiveField()) {
        this.submitIncentiveField(event, saveAsDraft);
      } else {
        scrollToTop();
      }
    }
  };

  _handleCancel = () => {
    setApproverEditCampaignStatus(false);
    setApproverEditCampaignStatusMessage('');
    this.props.setApproverEditLastUpdatedScreen('');
    this.props.history.push(
      '/campaign-wizard/campaign/' + this.props.match.params.id + '/approval'
    );
  };

  _handlebackButton = event => {
    event.preventDefault();
    let { campaign } = this.props;
    let campaignTypeIncentive =
      campaign && campaign.campaignType === 'Incentive';
    let isQnA =
      campaign && campaign.type === 'Sign-up with Questions & Answers';
    let isSimpleSignUp = campaign && campaign.type === 'Simple sign-up';
    if (campaignTypeIncentive && isQnA) {
      this.props.history.push(
        '/campaign-wizard/campaign/' + this.props.match.params.id + '/saasqna'
      );
    } else if (campaignTypeIncentive && isSimpleSignUp) {
      this.props.history.push(
        '/campaign-wizard/campaign/' +
          this.props.match.params.id +
          '/saas-profile-details'
      );
    }
  };

  onLabelChange = (e, index, elem) => {
    let fieldLabel = e.target.value;
    let newIncentiveFields = [...this.state.incentiveFields];
    newIncentiveFields[index].fieldLabel = fieldLabel;
    document.getElementById(`labelError_${index}`) &&
      document.getElementById(`labelError_${index}`).classList.add('cw-hidden');

    document
      .getElementById(`field_label_${index}`)
      .classList.contains('cw-error-focus') &&
      document
        .getElementById(`field_label_${index}`)
        .classList.remove('cw-error-focus');

    this.setState({
      incentiveFields: [...newIncentiveFields]
    });
  };

  winLooseOnchange = e => {
    this.setState({
      instantWinLose: e.target.checked
    });
  };

  onFileSizeChange = (e, index, elem) => {
    let fileSize = e.target.value;
    const regex = /^[0-9\b]+$/;
    if (fileSize > parseInt(constant.INCENTIVE_UPLOAD_MAX_FILE_SIZE)) {
      let newIncentiveFields = [...this.state.incentiveFields];
      newIncentiveFields[index].maxFileSize = fileSize;

      document.getElementById(`max_file_size_${index}`) &&
        document
          .getElementById(`max_file_size_${index}`)
          .classList.remove('cw-hidden');
      this.setState({
        incentiveFields: [...newIncentiveFields]
      });
    } else if (fileSize !== '0' && (fileSize === '' || regex.test(fileSize))) {
      let newIncentiveFields = [...this.state.incentiveFields];
      newIncentiveFields[index].maxFileSize = fileSize;

      document.getElementById(`file_size_${index}`) &&
        document
          .getElementById(`file_size_${index}`)
          .classList.add('cw-hidden');

      document.getElementById(`max_file_size_${index}`) &&
        document
          .getElementById(`max_file_size_${index}`)
          .classList.add('cw-hidden');

      document
        .getElementById(`fileSize_${index}`)
        .classList.contains('cw-error-focus') &&
        document
          .getElementById(`fileSize_${index}`)
          .classList.remove('cw-error-focus');

      this.setState({
        incentiveFields: [...newIncentiveFields]
      });
    }
  };

  render() {
    let { campaign } = this.props;

    let isEdited = campaign && campaign.isEdited;
    let isProductSample =
      campaign && campaign.incentiveType === 'Product samples';
    if (isProductSample) {
      incentiveFieldData = [
        ...constant.incentiveFields,
        ...constant.sampleDetails
      ];
    } else {
      incentiveFieldData = [...constant.incentiveFields];
    }
    Modal.setAppElement('#root');
    return (
      <div className="col-sm-12">
        <ReactTooltip
          place="right"
          type="info"
          multiline={true}
          className="cw-tooltip"
        />
        <Modal
          isOpen={this.state.isModalOpen}
          onRequestClose={this._closeModal}
          className="cw-modal cw-modal--branddetail"
          contentLabel="Can't find your brand?"
          style={{
            overlay: {
              backgroundColor: constant.EMAIL_OVERLAY_BACKGROUND
            }
          }}
        >
          <button
            className="cw-modal--close"
            title="Close"
            onClick={this._closeModal}
          >
            <i className="fas fa-times"></i>
          </button>

          <p className="mb-10">
            {`We recommend you use this field to let your users know the valid
            file types and size upfront, based on your selection. For example,
            add a message "You can only upload jpg and png files of upto
            ${constant.INCENTIVE_UPLOAD_MAX_FILE_SIZE}KB`}
            .
          </p>
        </Modal>

        <Modal
          scrollable={true}
          isOpen={this.state.isCancelModalOpen}
          onRequestClose={this._closeIsCancelModal}
          className="cw-modal cw-modal--branddetail"
          contentLabel="Campaign Wizard 2.0"
          style={{
            overlay: {
              backgroundColor: constant.EMAIL_OVERLAY_BACKGROUND
            }
          }}
        >
          <CampaignCancelModel
            closeIsCancelModal={this._closeIsCancelModal}
            isCancelContinue={this._cancelSaasCampaign}
          />
        </Modal>
        <div className="cw-section">
          {this.status !== '6' ? (
            <BreadCrumb
              match={this.state.matchProps}
              // editCampaign={
              //   this.props.editCampaign || this.props.isCampaignEdited
              // }
              hideBreadCrumb={this.props.approverEditCampaign || isEdited}
              campaignType={
                this.props.campaign && this.props.campaign.campaignType
              }
              websiteType={
                this.props.campaign &&
                this.props.campaign.websiteType &&
                this.props.campaign.websiteType.name
              }
              formType={this.props.campaign && this.props.campaign.type}
            />
          ) : null}
          <div className="cw-section--title mb-20">
            <h2 className="cw-heading--secondary mb-20">
              Configure Incentive fields for the form
            </h2>

            <div className="row">
              <div className="col-sm-7">
                <div className="cw-section--content">
                  <div className="form-group">
                    <Alert
                      alertType={'saasWarningAlertMessage'}
                      textMessage={
                        this.state.fileUploadError
                          ? constant.SAAS_MESSAGES.fileUploadWarningInfo
                          : constant.SAAS_MESSAGES.duplicateOptionsWarningInfo
                      }
                      showComponent={this.state.showErrorMessage}
                    />
                    <Alert
                      alertType={'warningMessageWithIcon'}
                      textMessage={constant.SAAS_MESSAGES.incentiveEditMessage}
                      showComponent={isEdited}
                    />
                    <Alert
                      alertType="saasUpdateMessage"
                      textMessage={constant.SAAS_MESSAGES.incentiveInfoMessage}
                    />
                    <p className="mt-10">{constant.INCENTIVE_HEADER_MESSAGE}</p>
                  </div>
                  <p className="mb-20">
                    Language Selected:{' '}
                    <span className="cw-color--primary">
                      {this.props.campaign.language
                        ? this.props.campaign.language.name
                        : ''}
                    </span>
                  </p>
                  {/* <div className="form-group">
                                        <p className="cw-heading--saascampaign mb-20">Configure Sample Information</p>
                                    </div> */}

                  {this.state.incentiveFields &&
                    this.state.incentiveFields.map((elem, index) => {
                      if (elem.type === 'incentiveFields') {
                        return (
                          <div
                            id={`accordion_${elem.value}_${index + 1}`}
                            className="accordion saas-form-field-accordion mb-20"
                            key={`${elem.label}_${index + 1}`}
                          >
                            <ReactTooltip
                              place="right"
                              type="info"
                              multiline={true}
                              className="cw-tooltip"
                            />
                            <div
                              className="card"
                              style={{ border: 0, overflow: 'visible' }}
                            >
                              <div className="card-header  accordian-card-header">
                                <span
                                  className="saas-accordion-heading cw-color--primary"
                                  style={{ fontWeight: '700' }}
                                >
                                  {elem.label}
                                </span>
                                <span
                                  type="button"
                                  className={`saas-accordion-arrow ${index}`}
                                  onClick={() =>
                                    this._handleAccordionExpandCollapse(index)
                                  }
                                >
                                  <i
                                    className={
                                      this.state.expand === index
                                        ? 'fas fa-chevron-down transformIcon-180'
                                        : 'fas fa-chevron-down'
                                    }
                                  ></i>
                                </span>
                                <span
                                  className={'saas-accordion-close'}
                                  onClick={() => {
                                    this._removeAccordion(index);
                                  }}
                                  style={{ right: '-4%' }}
                                ></span>
                              </div>
                              <div
                                className={
                                  this.state.expand === index
                                    ? 'collapse show'
                                    : 'collapse'
                                }
                              >
                                <ReactTooltip
                                  place="right"
                                  type="info"
                                  multiline={true}
                                  className="cw-tooltip"
                                />
                                <>
                                  {elem.label !== 'Competition Details' ? (
                                    <div
                                      className="form-group form-group-field-width"
                                      key={`${elem.dataType}_${index}`}
                                    >
                                      <label
                                        htmlFor={`entry_code_field_label_${index}`}
                                      >
                                        <span>Field Label</span>
                                        {elem.label === 'Entry Code' ? (
                                          <span
                                            className="float-right  cw-icon cw-icon--help"
                                            data-tip="Use to enter the unique entry code which can be found near the barcode."
                                            currentitem="false"
                                          >
                                            <i className="fas fa-question-circle"></i>
                                          </span>
                                        ) : null}
                                      </label>

                                      <input
                                        type="text"
                                        className="form-control"
                                        name={`field_label_${index}`}
                                        id={`field_label_${index}`}
                                        aria-describedby={`field_label_${index}`}
                                        data-required="true"
                                        defaultValue={
                                          this.state.incentiveFields[index]
                                            .fieldLabel
                                            ? this.state.incentiveFields[index]
                                                .fieldLabel
                                            : ''
                                        }
                                        onChange={e =>
                                          this.onLabelChange(e, index, elem)
                                        }
                                      />
                                      <span
                                        id={`labelError_${index}`}
                                        className="cw-error cw-hidden"
                                        data-control="name"
                                      >
                                        <i className="fas fa-exclamation-triangle mr-10"></i>
                                        Please enter the field label
                                      </span>
                                    </div>
                                  ) : null}
                                  {elem.label === 'File Upload' ? (
                                    <>
                                      <div className="form-group form-group-field-width">
                                        <label>
                                          <span>
                                            {`Max file size (Upto ${constant.INCENTIVE_UPLOAD_MAX_FILE_SIZE} Kb allowed)`}
                                          </span>
                                          <span
                                            className="float-right  cw-icon cw-icon--help"
                                            data-tip={`This is the max size of each file that consumers will be allowed to upload. If they try to add a bigger file, they will see an error`}
                                            currentitem="false"
                                          >
                                            <i className="fas fa-question-circle"></i>
                                          </span>
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name={`fileSize_${index}`}
                                          id={`fileSize_${index}`}
                                          aria-describedby={`fileSize_${index}`}
                                          data-required="true"
                                          value={
                                            this.state.incentiveFields[index]
                                              .maxFileSize
                                              ? this.state.incentiveFields[
                                                  index
                                                ].maxFileSize
                                              : ''
                                          }
                                          onChange={e =>
                                            this.onFileSizeChange(
                                              e,
                                              index,
                                              elem
                                            )
                                          }
                                        />
                                        <span
                                          id={`file_size_${index}`}
                                          className="cw-error cw-hidden"
                                          data-control="name"
                                        >
                                          <i className="fas fa-exclamation-triangle mr-10"></i>
                                          Please specify the file size
                                        </span>

                                        <span
                                          id={`max_file_size_${index}`}
                                          className="cw-error cw-hidden"
                                          data-control="name"
                                        >
                                          <i className="fas fa-exclamation-triangle mr-10"></i>
                                          {`File size cannot exceed ${constant.INCENTIVE_UPLOAD_MAX_FILE_SIZE} kb`}
                                        </span>
                                      </div>
                                      <div className="form-group form-group-field-width">
                                        <label>
                                          <span>
                                            Allowed file types (optional)
                                          </span>
                                        </label>
                                        {elem.values.map((el, imageIndex) => {
                                          return (
                                            <div className="form-group">
                                              <div className="row mb-1">
                                                <div className="col-9">
                                                  <div className="form-check">
                                                    <input
                                                      className="form-check-input"
                                                      type="checkbox"
                                                      defaultChecked={
                                                        el.isChecked
                                                      }
                                                      onChange={e =>
                                                        this._handleImageOnchange(
                                                          e,
                                                          index,
                                                          imageIndex
                                                        )
                                                      }
                                                      name={`file_upload_${index}_${imageIndex}`}
                                                      id={`file_upload_${index}_${imageIndex}`}
                                                    />
                                                    <label
                                                      className="form-check-label"
                                                      htmlFor={`file_upload_${index}_${imageIndex}`}
                                                    >
                                                      {el.key}
                                                    </label>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </>
                                  ) : null}
                                  {elem.label === 'Sample Details' ? (
                                    <>
                                      <div className="form-group form-group-field-width">
                                        <label>
                                          <span>Sample Details</span>
                                        </label>
                                      </div>
                                      {this.state.incentiveFields[index]
                                        .answers &&
                                        this.state.incentiveFields[
                                          index
                                        ].answers.map((elem, indexValue) => {
                                          return (
                                            <>
                                              <div
                                                className="form-group form-group-field-width"
                                                key={`${
                                                  elem.value
                                                }_${indexValue + 1}`}
                                              >
                                                <div
                                                  className="form-group"
                                                  key={`${
                                                    elem.value
                                                  }_${indexValue + 3}`}
                                                >
                                                  {indexValue >= 1 ? (
                                                    <span
                                                      className="saas-accordion-close incentive"
                                                      data-index={index}
                                                      onClick={e =>
                                                        this._deleteAnswer(
                                                          index,
                                                          indexValue
                                                        )
                                                      }
                                                    ></span>
                                                  ) : null}

                                                  <input
                                                    type="text"
                                                    placeholder="Please enter the sample details"
                                                    className="form-control mb-20"
                                                    name={`answerTranslatedLabel_${indexValue}`}
                                                    id={`answerTranslatedLabel_${index}_${indexValue}`}
                                                    aria-describedby={`answerTranslatedLabel_${indexValue}`}
                                                    data-required="true"
                                                    value={
                                                      this.state
                                                        .incentiveFields[
                                                        index
                                                      ] &&
                                                      this.state
                                                        .incentiveFields[index]
                                                        .answers[indexValue]
                                                        ? this.state
                                                            .incentiveFields[
                                                            index
                                                          ].answers[indexValue]
                                                            .label
                                                        : ''
                                                    }
                                                    onChange={e =>
                                                      this.handleAnswerLabel(
                                                        e,
                                                        index,
                                                        indexValue
                                                      )
                                                    }
                                                  />
                                                  <span
                                                    className="cw-error cw-hidden"
                                                    id={`answer_Error_Message_${index}_${indexValue}`}
                                                  >
                                                    <i className="fas fa-exclamation-triangle mr-10"></i>
                                                    Please enter the sample
                                                    details or remove the empty
                                                    fields. At-least one sample
                                                    should be added
                                                  </span>
                                                </div>
                                              </div>
                                            </>
                                          );
                                        })}
                                      {!isEdited ? (
                                        <button
                                          onClick={e =>
                                            this._addAnswer(e, index)
                                          }
                                          className="btn btn-outline-primary"
                                          style={{ margin: '1% 0 1% 1%' }}
                                        >
                                          Add another sample
                                        </button>
                                      ) : null}
                                      <span
                                        className="form-group form-group-field-width cw-error cw-hidden"
                                        id={`answer_Error_Message_${index}`}
                                      >
                                        <i className="fas fa-exclamation-triangle mr-10"></i>
                                        Maximum 12 samples can be added
                                      </span>
                                      {this.state.incentiveFields[index]
                                        .answers &&
                                      this.state.incentiveFields[index].answers
                                        .length > 1 ? (
                                        <>
                                          <div
                                            className="form-group form-group-field-width"
                                            key={`${elem.dataType}_${index}`}
                                          >
                                            <label
                                              htmlFor={`entry_code_field_label_${index}`}
                                            >
                                              <span>Sample Selection </span>
                                            </label>
                                            <div className="form-check mb-10">
                                              <div className="form-check">
                                                <input
                                                  type="radio"
                                                  className="form-check-input"
                                                  id="singleSampleFlag_yes"
                                                  name="singleSampleFlag"
                                                  ref="singleSampleFlag"
                                                  value={'Single choice'}
                                                  checked={
                                                    this.state.incentiveFields[
                                                      index
                                                    ] &&
                                                    this.state.incentiveFields[
                                                      index
                                                    ].questionType ===
                                                      'Single choice'
                                                      ? true
                                                      : false
                                                  }
                                                  onClick={e =>
                                                    this.onChangeRadio(e, index)
                                                  }
                                                />
                                                <label
                                                  className="form-check-label"
                                                  htmlFor="singleSampleFlag_yes"
                                                >
                                                  <p className="form-check-label d-block mr-2">
                                                    Allow users to select only
                                                    one sample
                                                  </p>
                                                </label>
                                              </div>
                                            </div>
                                            <div className="form-check mb-10">
                                              <div className="form-check">
                                                <input
                                                  type="radio"
                                                  className="form-check-input"
                                                  id="singleSampleFlag_no"
                                                  name="multiSampleFlag"
                                                  ref="multiSampleFlag"
                                                  value={'Multiple choice'}
                                                  checked={
                                                    this.state.incentiveFields[
                                                      index
                                                    ] &&
                                                    this.state.incentiveFields[
                                                      index
                                                    ].questionType ===
                                                      'Multiple choice'
                                                      ? true
                                                      : false
                                                  }
                                                  onClick={e =>
                                                    this.onChangeRadio(e, index)
                                                  }
                                                />
                                                <label
                                                  className="form-check-label"
                                                  htmlFor="singleSampleFlag_no"
                                                >
                                                  <p className="form-check-label d-block mb-5">
                                                    Allow users to select more
                                                    than one sample
                                                  </p>
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      ) : null}
                                    </>
                                  ) : null}
                                  {elem.label !== 'Competition Details' ? (
                                    <>
                                      <div className="form-group form-group-field-width">
                                        <label htmlFor={`toolTipText_${index}`}>
                                          <span>
                                            {' '}
                                            Tooltip/Help-Text (optional)
                                          </span>
                                          {elem.label === 'File Upload' ? (
                                            <span class="ml-10">
                                              <a
                                                href="#modal"
                                                onClick={this._openModal}
                                              >
                                                Important Note!
                                              </a>
                                            </span>
                                          ) : null}
                                          <span
                                            className="float-right  cw-icon cw-icon--help"
                                            data-tip="Please specify the tooltip message you would want users to see in case they need some additional information while filling up the form. Make sure to have this message in the language you selected for the form."
                                            currentitem="false"
                                          >
                                            <i className="fas fa-question-circle"></i>
                                          </span>
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name={`toolTipText_${index}`}
                                          id={`toolTipText_${index}`}
                                          aria-describedby={`toolTipText_${index}`}
                                          data-required="true"
                                          value={
                                            this.state.incentiveFields[index] &&
                                            this.state.incentiveFields[index]
                                              .toolTip
                                              ? this.state.incentiveFields[
                                                  index
                                                ].toolTip
                                              : ''
                                          }
                                          onChange={e =>
                                            this._handleToolTip(e, index)
                                          }
                                        />
                                      </div>

                                      <div className="form-group d-flex form-group-field-width">
                                        <label className="switch">
                                          <input
                                            type="checkbox"
                                            name="quesMandatory"
                                            checked={
                                              this.state.incentiveFields[
                                                index
                                              ] &&
                                              this.state.incentiveFields[index]
                                                .mandatoryToggle
                                                ? true
                                                : false
                                            }
                                            onChange={e =>
                                              this._mandatoryQuestionHandler(
                                                e,
                                                index
                                              )
                                            }
                                          />
                                          <div className="slider round"></div>
                                        </label>
                                        <label
                                          className=" ml-20"
                                          htmlFor="quesMandatory"
                                        >
                                          Make this field mandatory
                                        </label>
                                      </div>
                                    </>
                                  ) : null}
                                  {this.state.incentiveFields[index] &&
                                  this.state.incentiveFields[index]
                                    .mandatoryToggle ? (
                                    <>
                                      <ReactTooltip
                                        place="right"
                                        type="info"
                                        multiline={true}
                                        className="cw-tooltip"
                                      />
                                      <div className="form-group form-group-field-width">
                                        <label
                                          htmlFor={`errorMessage_${index}`}
                                        >
                                          <span>
                                            Mandatory Field Error Message
                                          </span>
                                          <span
                                            className="float-right  cw-icon cw-icon--help"
                                            data-tip="Please specify the error message you would want the users to see in case they forget to fill this field. Make sure to have this message in the language you selected for the form."
                                            currentitem="false"
                                          >
                                            <i className="fas fa-question-circle"></i>
                                          </span>
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name={`errorMessage_${index}`}
                                          id={`errorMessage_${index}`}
                                          aria-describedby={`errorMessage_${index}`}
                                          data-required="true"
                                          value={
                                            this.state.incentiveFields[index] &&
                                            this.state.incentiveFields[index]
                                              .mandatoryMessage
                                              ? this.state.incentiveFields[
                                                  index
                                                ].mandatoryMessage
                                              : ''
                                          }
                                          onChange={e =>
                                            this._handleMandatoryMessage(
                                              e,
                                              index,
                                              elem
                                            )
                                          }
                                        />
                                        <span
                                          className="cw-error cw-hidden"
                                          id={`mandatory_Error_Message_${index}`}
                                        >
                                          <i className="fas fa-exclamation-triangle mr-10"></i>
                                          Please enter the error message
                                        </span>
                                      </div>
                                    </>
                                  ) : null}
                                  {elem.label === 'File Upload' ? (
                                    <>
                                      <div className="form-group form-group-field-width">
                                        <label
                                          htmlFor={`validationMessage_${index}`}
                                        >
                                          <span>
                                            Format Validation Error Message
                                          </span>
                                          <span
                                            className="float-right  cw-icon cw-icon--help"
                                            data-tip="Please enter the error message to be displayed if a user tries to upload a file with incorrect file format or size"
                                            currentitem="false"
                                          >
                                            <i className="fas fa-question-circle"></i>
                                          </span>
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name={`validationMessage_${index}`}
                                          id={`validationMessage_${index}`}
                                          aria-describedby={`validationMessage_${index}`}
                                          data-required="true"
                                          value={
                                            this.state.incentiveFields[index] &&
                                            this.state.incentiveFields[index]
                                              .validationMessage
                                              ? this.state.incentiveFields[
                                                  index
                                                ].validationMessage
                                              : ''
                                          }
                                          onChange={e =>
                                            this._handleValidationMessage(
                                              e,
                                              index,
                                              elem
                                            )
                                          }
                                        />
                                        <span
                                          className="cw-error cw-hidden"
                                          id={`validation_Error_Message_${index}`}
                                        >
                                          <i className="fas fa-exclamation-triangle mr-10"></i>
                                          Please enter the format validation
                                          error message
                                        </span>
                                      </div>
                                      <div className="form-group form-group-field-width">
                                        <label
                                          htmlFor={`fileUploadMessage_${index}`}
                                        >
                                          <span>
                                            Failed Upload Error Message{' '}
                                          </span>
                                          <span
                                            className="float-right  cw-icon cw-icon--help"
                                            data-tip="Please specify the error message you would want the users to see in case the file upload has failed due to a technical issue. We recommend the message to be in the language you selected for your form."
                                            currentitem="false"
                                          >
                                            <i className="fas fa-question-circle"></i>
                                          </span>
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name={`fileUploadMessage_${index}`}
                                          id={`fileUploadMessage_${index}`}
                                          aria-describedby={`fileUploadMessage_${index}`}
                                          data-required="true"
                                          value={
                                            this.state.incentiveFields[index] &&
                                            this.state.incentiveFields[index]
                                              .failedUploadMessage
                                              ? this.state.incentiveFields[
                                                  index
                                                ].failedUploadMessage
                                              : ''
                                          }
                                          onChange={e =>
                                            this._handleFileUploadMessage(
                                              e,
                                              index,
                                              elem
                                            )
                                          }
                                        />
                                        <span
                                          className="cw-error cw-hidden"
                                          id={`file_Upload_Error_Message_${index}`}
                                        >
                                          <i className="fas fa-exclamation-triangle mr-10"></i>
                                          Please enter the error message for a
                                          failed upload
                                        </span>
                                      </div>
                                    </>
                                  ) : null}
                                </>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })}
                  {this.state.showAllPurposeError ? (
                    <div className="form-group mt-20 ml-1 generic-error-message">
                      <span
                        id={`all_purpose_error`}
                        className="cw-error"
                        data-control="name"
                      >
                        <i className="fas fa-exclamation-triangle mr-10"></i>
                        Please make sure you have entered all the mandatory
                        fields
                      </span>
                    </div>
                  ) : null}
                  <div
                    className={`form-group mt-20 mb-40 saas-profile-select ${
                      isEdited ? 'cw-hidden' : ''
                    }`}
                  >
                    <Select
                      className={'cw-select--custom cw-color--primary'}
                      name="incentiveField"
                      // value={this.state.incentiveField}
                      placeholder="Add an Incentive Field"
                      options={incentiveFieldData}
                      onChange={this._handleIncentiveField}
                      value={'Add an additional field'}
                    />
                  </div>

                  {!isProductSample ? (
                    <>
                      <div className="form-group">
                        <label htmlFor={`instantWinLose`}>
                          <strong style={{ fontSize: '18px' }}>
                            Instant Win/Lose
                          </strong>
                          <span
                            className="float-right  cw-icon cw-icon--help"
                            data-tip="Check this checkbox if you would like the users to be displayed with a win or lose page instead of a thankyou page on submission of the form"
                            currentitem="false"
                          >
                            <i className="fas fa-question-circle"></i>
                          </span>
                        </label>
                        <div className="row mb-1">
                          <div className="col-6">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={this.state.instantWinLose}
                                value={
                                  this.state.instantWinLose
                                    ? this.state.instantWinLose
                                    : false
                                }
                                onChange={e => this.winLooseOnchange(e)}
                                name={`compititionDetails_`}
                                id={`compititionDetails_`}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`compititionDetails_`}
                              >
                                Include instant win/lose in the form
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row mb-1">
                          <div className="col-11">
                            <div className="form-check">
                              <label>
                                Please note that the redirection to win or lose
                                pages needs to be configured in platforms and
                                not in Campaign Wizard
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}
                  {campaign.status === '6' ? (
                    <div className="cw-form--action-cta">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        onClick={this._handleSubmit}
                      >
                        Next
                      </button>
                      <a
                        href="javascript:void(0)"
                        className="cw-save-to-draft mt-40"
                        onClick={this._handleCancel}
                      >
                        Cancel
                      </a>
                    </div>
                  ) : (
                    <div className="cw-form--action-cta">
                      <button
                        type="button"
                        className="btn btn btn-outline-secondary mr-3"
                        onClick={this._handlebackButton}
                      >
                        Back
                      </button>
                      <button
                        type="submit"
                        className="btn btn-primary"
                        onClick={this._handleSubmit}
                      >
                        Next
                      </button>
                      {campaign.isEdited ? (
                        <a
                          href="javascript:void(0)"
                          className="cw-cancel-edit mt-40"
                          onClick={event => this._openIsCancelModal(event)}
                        >
                          Cancel changes
                        </a>
                      ) : (
                        <a
                          href="javascript:void(0)"
                          className="cw-save-to-draft mt-40"
                          style={{ width: '40%' }}
                          onClick={event => this._handleSubmit(event, true)}
                        >
                          Save and exit
                        </a>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  campaign: state.overview.campaign,
  incentiveFields: state.saasFormAuthoring.incentiveFields
});
export default connect(mapStateToProps, {
  setApproverEditCampaignStatusMessage,
  setApproverEditCampaignStatus,
  setApproverEditLastUpdatedScreen,
  fetchCampaignData,
  getIncentiveFields,
  cancelCampaignChanges
})(SaasIncentive);
