import axios from 'axios';
import {
	FETCH_EXISTING_EMAIL_LIST,
	AUTHORING_PUSH_STATE,
	AUTHORING_DATA_SAVED,
	GET_AUTHOR_ACCESS_EMAILS_BY_CAMPAIGN_ID,
	RESET_AUTHORING_FORM_SUBMISSION,
	AUTHORING_INPROGRESS,
	AUTHORING_CAMPAIGN_DETAILS,
} from '../actionTypes';
import constant from '../constants';

export const resetSubmissionState = (reset) => ({
	type: RESET_AUTHORING_FORM_SUBMISSION,
	reset,
});

export const processing = (status) => ({
	type: AUTHORING_INPROGRESS,
	isLoading: status,
});

export function fetchExistingEmailList() {
	return function(dispatch) {
		axios.get(constant.serviceUrls.CAMPAIGN_AUTHOR_ACCESS).then((response) => {
			dispatch({
				type: FETCH_EXISTING_EMAIL_LIST,
				existingEmailList: response.data.users,
			});
		});
	};
}

export function isLDAPUser(emailID) {
	return function(dispatch) {
		return axios
			.get(constant.serviceUrls.VALIDATE_CAMPAIGN_AUTHOR_ACCESS + `?user=${emailID}`)
			.then((response) => {
				return response.data.code === 200;
			})
			.catch(function(error) {
				return false;
			});
	};
}

export function submitAuthorAccessEmails(authorAccessDetails, saveAsDraft, userName, userEmail) {
	return function(dispatch) {
		authorAccessDetails['saveAsDraft'] = saveAsDraft ? true : false;
		authorAccessDetails['userName'] = userName;
		authorAccessDetails['userEmail'] = userEmail;
		if (!userEmail && saveAsDraft) {
			dispatch({ type: AUTHORING_DATA_SAVED, dataSaved: true });
		} else {
			axios.post(constant.serviceUrls.CAMPAIGN_DATA_SERVICE_URL, authorAccessDetails).then((response) => {
				if (!saveAsDraft) {
					dispatch({ type: AUTHORING_PUSH_STATE, pushstate: true });
				} else {
					dispatch({ type: AUTHORING_DATA_SAVED, dataSaved: true });
				}
			});
		}
	};
}

export function getAuthorAccessEmailsByCampaignID(campaignID) {
	return function(dispatch) {
		axios.get(constant.serviceUrls.CAMPAIGN_DETAILS_SERVICE_URL + '/' + campaignID).then((response) => {
			const campaignAccessEmails = response.data[0].campaignDetail.users;
			const campaignType = response.data[0].campaignType;
			const type = response.data[0].type;
			dispatch({ type: AUTHORING_CAMPAIGN_DETAILS, campaignType, campaigntype: type });
			dispatch({ type: GET_AUTHOR_ACCESS_EMAILS_BY_CAMPAIGN_ID, campaignAccessEmails });
		});
	};
}
