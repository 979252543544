import React, { forwardRef, useEffect, useState } from 'react';
import { FieldError } from '../../atoms/FieldError';
import { get } from 'lodash';
import { FormTextArea } from '../FormTextArea';

const EmailScheduleRadioInput = forwardRef(({ name, value, onChange }, ref) => {
  const handleOnChange = e => {
    onChange(e.target.value);
  };
  return (
    <div className="d-flex flex-wrap">
      <div className="form-check">
        <input
          name={`${name}`}
          type="radio"
          id="schduleYes"
          value="schduleYes"
          checked={value === 'schduleYes'}
          onChange={handleOnChange}
        />
        <label htmlFor="schduleYes">Yes</label>
      </div>
      <div className="form-check">
        <input
          name={`${name}`}
          id="schduleNo"
          value="schduleNo"
          type="radio"
          checked={value === 'schduleNo'}
          onChange={handleOnChange}
        />
        <label htmlFor="schduleNo">No</label>
      </div>
    </div>
  );
});

export const EmailSchduleRadioInput = ({
  name,
  rules,
  register,
  label,
  errors,
  setValue,
  control,
  defaultValue,
  emailType,
  ...props
}) => {
  const errorMessages = get(errors, name);
  const hasError = !!(errors && errorMessages);

  return (
    <div className="form-group " aria-live="polite">
      <label htmlFor={props.id}>{label}</label>
      <div>
        {/* <Controller
          name={`${name}.type`}
          control={control}
          defaultValue={get(defaultValue, `${name}.type`)}
          render={({ field }) => <EmailScheduleRadioInput {...field} />}
        /> */}
        <FormTextArea
          errors={errors}
          name={name}
          setValue={setValue}
          register={register}
          placeholder={'Please explain the reason'}
          defaultValue={defaultValue}
        />
      </div>
    </div>
  );
};
