import React, { Component, Fragment } from 'react';
import Select, { components } from 'react-select';
//import { css, cx } from 'emotion';

const Option = props => {
  return (
    <div>
      {' '}
      <components.Option {...props}>
        {' '}
        <input
          type="radio"
          checked={props.isSelected}
          onChange={props.selectProps.onChange}
        />{' '}
        <label>{props.label}</label>{' '}
      </components.Option>{' '}
    </div>
  );
};
const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <i className="fas fa-sort"></i>
    </components.DropdownIndicator>
  );
};

const ValueContainer = ({ children, ...props }) => {
  const { getValue, hasValue, selectProps } = props;

  if (hasValue) {
    return (
      <components.ValueContainer {...props}>
        <div className="custom-tag-heading">{selectProps.label}</div>

        <div className="custom-tag-value">
          {selectProps.name === 'lastUpdated'
            ? selectProps.value.value
            : selectProps.value.label}
        </div>
        {children[1]}
      </components.ValueContainer>
    );
  } else {
    return (
      <components.ValueContainer {...props}>
        {children}
      </components.ValueContainer>
    );
  }
};

const RadioDropDown = props => {
  return (
    <Select
      components={{
        Option,
        DropdownIndicator,
        ValueContainer,
        IndicatorSeparator: null
      }}
      className={props.className}
      classNamePrefix={props.classNamePrefix}
      name={props.name}
      label={props.label}
      hideSelectedOptions={props.hideSelectedOptions}
      placeholder={props.placeholder}
      isDisabled={props.isDisabled}
      isLoading={props.isLoading}
      value={props.value}
      onChange={props.onChange}
      options={props.options}
      isClearable={props.isClearable}
    />
  );
};

export default RadioDropDown;
