import classNames from 'classnames';
import { FieldError } from 'components/atoms/FieldError';
import { get } from 'lodash';
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';

export default React.forwardRef(
  ({ name, errors, onChange, id, label, setError, clearErrors }, ref) => {
    const maxSize = 10 * 1024 * 1024; // 10MB in bytes
    const [file, setFile] = useState(null);
    const errorMessages = get(errors, name);
    const hasError = !!(errors && errorMessages);

    const onDrop = useCallback((acceptedFiles, fileRejections) => {
      const file = acceptedFiles[0];
      if (file) {
        if (file.size <= maxSize && file.type === 'application/pdf') {
          clearErrors(name);
          setFile(file);
          onChange(file);
        } else {
          setError(name, {
            type: 'file-invalid-type',
            message: 'File should be of type PDF.'
          });
        }
      }
      fileRejections &&
        fileRejections.length &&
        fileRejections.forEach(file => {
          file.errors.forEach(err => {
            if (err.code === 'file-too-large') {
              setError(name, {
                type: 'file-too-large',
                message: 'Please upload a PDF file within 10MB.'
              });
            }

            if (err.code === 'file-invalid-type') {
              setError(name, {
                type: 'file-invalid-type',
                message: 'File should be of type PDF'
              });
            }
          });
        });
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
      onDrop,
      accept: 'application/pdf',
      multiple: false,
      maxSize: maxSize
    });

    const removeFile = () => {
      setFile(null);
      onChange(null);
      clearErrors(name);
    };
    return (
      <>
        <div className="file-upload-zone-wrap">
          <label htmlFor={id}>{label}</label>
          <div
            className={classNames('coupon-file-upload', { isError: hasError })}
            id={id}
            {...getRootProps()}
          >
            <div className="drop-help-text">
              <span className="file-upload-icon">
                <i className="fas fa-upload"></i>
              </span>
              <input {...getInputProps()} />
              <p>Only PDF files of up to 10 MB are allowed</p>
            </div>
          </div>
          {/* {error && <div className="error">{error}</div>} */}
          {file && (
            <div className="file-upload-preview">
              <span>File uploaded: {file.name}</span>
              <span className="ml-10">
                <i
                  className="fas fa-times"
                  style={{ cursor: 'pointer' }}
                  onClick={removeFile}
                ></i>
              </span>
            </div>
          )}
          <FieldError errors={errors} name={name} />
        </div>
      </>
    );
  }
);
