import * as yup from 'yup';
import constants from 'constants/config';
import { getYupSchema } from 'selectors';
import { submitSmsCampaignDetailsV2 } from 'actions/communications/communicationCampaignDetails.js';
import { get } from 'lodash';
import { transformSMSPayloadByDesign, SMSSubmitRedirectURL } from 'selectors';
import FormUpdateTrack from 'components/organisms/FormUpdateTrack';
import { SMSURLInput } from 'components/molecules/SMSURLInput';
import { EnhancedTextArea } from 'components/molecules/EnhancedTextArea';
import { SMSBodyGuidelines } from './SMSBodyGuidelines';

const designSmsSchema = (
  dispatch,
  {
    defaultValues,
    match,
    history,
    smsType,
    onFormValueChange,
    optOutText,
    authorizedUserData,
    insertingURLMain,
    insertingURLFollowup,
    setInsertingURLMain,
    setInsertingURLFollowup
  }
) => {
  const isEdited =
    history &&
    history.location &&
    history.location.state &&
    history.location.state.isEdited;

  const { status } = defaultValues || {};
  const sendAfter = get(
    defaultValues,
    'deliverySchedule.sendAfter.value',
    false
  );

  const isCouponApplied = get(
    defaultValues,
    'smsAttributes.smsCoupon.couponApplied',
    false
  );
  const couponType = get(defaultValues, 'smsAttributes.smsCoupon.type', '');
  const isDynamicCoupon = isCouponApplied && couponType === 'dynamicCoupon';

  const isFollowUpSchema = sendAfter !== 'DontSend';

  const onSubmit = (data, { user }, { backToReview } = {}) => {
    const paramUid = match.params.id;

    const dataToSend = {
      uid: paramUid,
      userEmail: user.emailId,
      userName: user.name,
      details: data,
      isFollowUp: isFollowUpSchema,
      changesDescription:
        data && data.changesDescription ? data.changesDescription : null,
      role: user && user.group,
      status
    };

    if (!insertingURLMain) {
      delete data.URLMain;
    }

    if (!insertingURLFollowup) {
      delete data.URLFollowup;
    }

    const transformedData = transformSMSPayloadByDesign(dataToSend);

    dispatch(
      submitSmsCampaignDetailsV2({ ...transformedData }, (error, result) => {
        if (error) {
          console.log('OnSubmit error: ', error);
        } else {
          if (
            isEdited &&
            (status === constants.SMS_STATUS.AWAITING_SETUP ||
              status === constants.SMS_STATUS.AWAITING_INFO)
          ) {
            history.push(
              `${constants.SMS_BASE_ROUTE}/${paramUid}/${constants.SMS_ROUTES.EXECUTION}`
            );
          } else {
            history.push(
              SMSSubmitRedirectURL({
                backToReview: isEdited && backToReview,
                status,
                uid: paramUid,
                nextPage: `${constants.SMS_ROUTES.REVIEW}`,
                userGroup: authorizedUserData.group
              })
            );
          }
        }
      })
    );
  };
  const firstOptOutText = optOutText;
  const secondOptOutText = optOutText;

  const getFollowUpField = isFollowUp => {
    return isFollowUp
      ? [
          {
            type: 'label',
            label: 'SMS - follow up',
            id: 'followUpLabel',
            labelClassName: 'font-weight-bold mt-30 mb-0'
          },
          {
            type: 'custom',
            component: EnhancedTextArea,
            flow: 'sms',
            isDynamicCoupon,
            placeholderTooltipText:
              'These placeholders will be replaced by actual dynamic values when this SMS is sent by ACS',
            name: 'smsContentFollowUp',
            id: 'smsContentFollowUp',
            label: 'SMS body content',
            defaultValue: get(
              defaultValues,
              'communicationDetail.smsDetails.[1].smsContent',
              ''
            ),
            optOutTextLength: secondOptOutText ? secondOptOutText.length : 0,
            optOutText: secondOptOutText ? secondOptOutText : '',
            insertingURLFollowup,
            setInsertingURLFollowup,
            customHelpComponent: SMSBodyGuidelines,
            rules: yup
              .string()
              .trim()
              .required('Please enter body content'),
            detectUnicodeContent:
              constants.featureFlags.ENABLE_SMS_UNICODE_DETECTION
          },
          {
            ...(insertingURLFollowup
              ? {
                  type: 'custom',
                  component: SMSURLInput,
                  name: 'URLFollowup',
                  id: 'URLFollowup',
                  defaultValue: get(
                    defaultValues,
                    'communicationDetail.smsDetails.[1].URL.[0].fullURL',
                    ''
                  ),
                  insertingURLFollowup,
                  setInsertingURLFollowup,
                  rules: yup
                    .object({
                      fullURL: yup
                        .string()
                        .required(
                          'Please enter the URL for your SMS body content'
                        )
                        .test('valid-url', 'Please enter valid URL', value => {
                          const URLRegex = new RegExp(constants.regex.smsBodyURL);
                          const isValidFullURL = URLRegex.test(value);

                          if (!isValidFullURL) {
                            return false;
                          }
                          return true;
                        })
                    })
                    .nullable()
                }
              : {
                  type: 'label'
                })
          },
          {
            type: 'text',
            name: 'optOutTextFollowUp',
            id: 'optOutTextFollowUp',
            label: 'SMS opt out text',
            maxLength: '50',
            defaultValue: optOutText,
            rules: yup
              .string()
              .trim()
              .required('Please enter opt out text'),
            readOnly: true
          }
        ]
      : [];
  };

  const getChangesDescriptionField = () => {
    return isEdited &&
      (status === constants.SMS_STATUS.AWAITING_SETUP ||
        status === constants.SMS_STATUS.AWAITING_INFO)
      ? [
          {
            type: 'textarea',
            name: 'changesDescription',
            id: 'changesDescription',
            label: 'Please describe what changed in SMS',
            placeholder: '',
            // defaultValue: get(
            //   defaultValues,
            //   'communicationDetail.changesDescription',
            //   ''
            // ),
            rules: yup
              .string()
              .trim()
              .required('Please describe what changed in SMS')
          }
        ]
      : [];
  };

  const getLabelField = isFollowUp => {
    return isFollowUp
      ? [
          {
            type: 'label',
            label: 'SMS - main send',
            id: 'mainSendLabel',
            labelClassName: 'font-weight-bold mb-0'
          }
        ]
      : [];
  };
  return {
    formType: 'custombuttons',
    submitBtnLabel:
      isEdited &&
      (status === constants.SMS_STATUS.AWAITING_SETUP ||
        status === constants.SMS_STATUS.AWAITING_INFO)
        ? ' '
        : isEdited
        ? 'Save and back to review'
        : 'Next',
    onSecondaryClick: onSubmit,
    onSubmitSecondary: () => {},
    isEdit: isEdited,
    secondaryBtnLabel:
      isEdited &&
      (status === constants.SMS_STATUS.AWAITING_SETUP ||
        status === constants.SMS_STATUS.AWAITING_INFO)
        ? 'Submit'
        : 'Next',
    fields: [
      ...getLabelField(isFollowUpSchema),
      {
        type: 'custom',
        component: EnhancedTextArea,
        flow: 'sms',
        isDynamicCoupon,
        placeholderTooltipText:
          'These placeholders will be replaced by actual dynamic values when this SMS is sent by ACS',
        name: 'smsContent',
        id: 'smsContent',
        label: 'SMS body content',
        defaultValue: get(
          defaultValues,
          'communicationDetail.smsDetails.[0].smsContent',
          ''
        ),
        smsDetails :  get(
          defaultValues,
          'communicationDetail.smsDetails',
          ''
        ),
        optOutTextLength: firstOptOutText ? firstOptOutText.length : 0,
        optOutText: firstOptOutText ? firstOptOutText : '',
        insertingURLMain,
        setInsertingURLMain,
        customHelpComponent: SMSBodyGuidelines,
        rules: yup
          .string()
          .trim()
          .required('Please enter body content'),
        detectUnicodeContent:
          constants.featureFlags.ENABLE_SMS_UNICODE_DETECTION,
        watch: 'URLMain'
      },
      {
        ...(insertingURLMain
          ? {
              type: 'custom',
              component: SMSURLInput,
              name: 'URLMain',
              id: 'URLMain',
              defaultValue: {
                fullURL: get(
                  defaultValues,
                  'communicationDetail.smsDetails.[0].URL.[0].fullURL',
                  ''
                ),
                shortURL: get(
                  defaultValues,
                  'communicationDetail.smsDetails.[0].URL.[0].shortURL',
                  ''
                )
              },
              status,
              insertingURLMain,
              setInsertingURLMain,
              rules: yup
                .object({
                  fullURL: yup
                    .string()
                    .required('Please enter the URL for your SMS body content')
                    .test('valid-url', 'Please enter valid URL', value => {
                      const URLRegex = new RegExp(constants.regex.smsBodyURL);
                      const isValidFullURL = URLRegex.test(value);

                      if (!isValidFullURL) {
                        return false;
                      }
                      return true;
                    }),
                  shortURL:
                    status == 4
                      ? yup
                          .string()
                          .required('Please enter shortened URL')
                          .test(
                            'valid-url',
                            'Please enter valid URL',
                            value => {
                              const URLRegex = new RegExp(constants.regex.smsBodyURL);
                              const isValidShortURL = URLRegex.test(value);

                              if (!isValidShortURL) {
                                return false;
                              }
                              return true;
                            }
                          )
                      : yup.string().optional()
                })
                .nullable()
            }
          : {
              type: 'label'
            })
      },
      {
        type: 'text',
        name: 'optOutText',
        id: 'optOutText',
        label: 'SMS opt out text',
        maxLength: '50',
        defaultValue: optOutText,
        rules: yup
          .string()
          .trim()
          .required('Please enter opt out text'),
        readOnly: true
      },
      ...getFollowUpField(isFollowUpSchema),
      {
        type: 'custom',
        component: FormUpdateTrack,
        name: 'formTrack',
        watch: isFollowUpSchema
          ? [
              'smsContent',
              'optOutText',
              'smsContentFollowUp',
              'optOutTextFollowUp',
              'URLMain'
            ]
          : ['smsContent', 'optOutText','URLMain'],
        onFormValueChange: onFormValueChange,
        defaultValue: isFollowUpSchema
          ? [
              get(
                defaultValues,
                'communicationDetail.smsDetails.[0].smsContent',
                ''
              ),
              get(
                defaultValues,
                'communicationDetail.smsDetails.[0].optOutText',
                optOutText
              ),
              get(
                defaultValues,
                'communicationDetail.smsDetails.[1].smsContent',
                ''
              ),
              get(
                defaultValues,
                'communicationDetail.smsDetails.[1].optOutText',
                optOutText
              )
            ]
          : [
              get(
                defaultValues,
                'communicationDetail.smsDetails.[0].smsContent',
                ''
              ),
              get(
                defaultValues,
                'communicationDetail.smsDetails.[0].optOutText',
                optOutText
              )
            ]
      },
      {
        type: 'textarea',
        name: 'additionalInformation',
        id: 'additionalInformation',
        label: 'Additional information',
        placeholder: 'Optional',
        isOptional: true,
        defaultValue: get(
          defaultValues,
          'communicationDetail.additionalInformation',
          ''
        ),
        rules: yup
          .string()
          .trim()
          .max(300, 'Max 300 characters are allowed'),
        maxLength: '300'
      },
      ...getChangesDescriptionField()
    ],
    onSubmit: (data, { user }) =>
      onSubmit(data, { user }, { backToReview: isEdited }),
    onDraft: (data, validationSchema, { user }) => {
      const paramUid = match.params.id;
      if (
        isEdited &&
        (status === constants.SMS_STATUS.AWAITING_SETUP ||
          status === constants.SMS_STATUS.AWAITING_INFO)
      ) {
        history.push(
          `${constants.SMS_BASE_ROUTE}/${paramUid}/${constants.SMS_ROUTES.EXECUTION}`
        );
      } else {
        const dataToSend = {
          uid: paramUid,
          userEmail: user.emailId,
          userName: user.name,
          details: data,
          isFollowUp: isFollowUpSchema,
          status: '1'
        };

        const transformedData = transformSMSPayloadByDesign(dataToSend);

        dispatch(
          submitSmsCampaignDetailsV2(
            { ...transformedData },
            (error, result) => {
              if (error) {
                console.log('OnSubmit error: ', error);
              } else {
                history.push(`/campaign-wizard/smsdashboard`);
              }
            },
            { isSaveAsDraft: true }
          )
        );
      }
    },
    getSchema() {
      if (this.fields) {
        return getYupSchema(this.fields);
      }
      return null;
    },
    onPrevious: () => {
      const uid = match.params.id;
      if (
        isEdited &&
        (status === constants.SMS_STATUS.AWAITING_SETUP ||
          status === constants.SMS_STATUS.AWAITING_INFO)
      ) {
        history.push(
          `${constants.SMS_BASE_ROUTE}/${uid}/${constants.SMS_ROUTES.EXECUTION}`
        );
      } else {
        history.push(
          `${constants.SMS_BASE_ROUTE}/${uid}/${constants.SMS_ROUTES.AUDIENCE}`
        );
      }
    },
    previousBtnLabel:
      isEdited &&
      (status === constants.SMS_STATUS.AWAITING_SETUP ||
        status === constants.SMS_STATUS.AWAITING_INFO)
        ? 'Cancel'
        : 'Back',
    draftText:
      isEdited &&
      (status === constants.SMS_STATUS.AWAITING_SETUP ||
        status === constants.SMS_STATUS.AWAITING_INFO)
        ? ' '
        : 'Save and exit',
    formErrorMessage:
      'There was a problem with the form. Errors are listed below'
  };
};

export default designSmsSchema;
