import React, { useState } from 'react';
import Modal from 'react-modal';
import constants from 'constants/config';

const EmailRejectModel = ({ modalIsOpen, closeModal, handleOnSubmit }) => {
  const [text, setText] = useState('');
  const [errorComment, setErrorComment] = useState(false);
  const handleChange = e => {
    setText(e.target.value);
  };
  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="cw-modal cw-modal--reject"
        contentLabel="Rejection Modal"
        style={{
          overlay: {
            backgroundColor: constants.EMAIL_OVERLAY_BACKGROUND
          }
        }}
      >
        <button className="cw-modal--close" title="Close" onClick={closeModal}>
          <i className="fas fa-times"></i>
        </button>
        <h3 className="cw-heading--primary mb-10">Confirm rejection</h3>
        <p className="mb-20">
          Please confirm and provide a reason why you have rejected this
          campaign.
        </p>

        {/* rejection textarea */}
        <div>
          <label htmlFor="description">
            <span>Reason for rejection</span>
          </label>
          <textarea
            className={'form-control mb-30'}
            name="description"
            id="description"
            onChange={handleChange}
            rows="3"
          ></textarea>
          {errorComment ? (
            <span className="cw-error mb-10">
              <i className="fas fa-exclamation-triangle mr-10"></i>Please enter
              rejection reason.
            </span>
          ) : (
            ''
          )}
        </div>

        <div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-outline-secondary"
              data-dismiss="modal"
              onClick={closeModal}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                if(text && text.trim().length){
                  setErrorComment(false)
                  handleOnSubmit(text);
                } else {
                  setErrorComment(true);
                }
              }}
            >
              Confirm
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default EmailRejectModel;
