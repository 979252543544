import React from 'react';
import classNames from 'classnames';

const ReportSectionHeading = ({ title, className, titleClass }) => {
  return (
    <div className={classNames('d-flex align-items-center', className)}>
      <h3 className={classNames('report-heading-title', titleClass)}>
        {title}
      </h3>
      <div className="report-heading-line"></div>
    </div>
  );
};

export default ReportSectionHeading;
