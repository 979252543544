import React from 'react';
import { useWatch } from 'react-hook-form';

const FormUpdateTrack = ({
  watch,
  control,
  onFormValueChange,
  defaultValue
}) => {
  const values = useWatch({
    control,
    name: watch,
    defaultValue
  });
  if (onFormValueChange) {
    onFormValueChange(values, defaultValue);
  }

  return null;
};

export default FormUpdateTrack;
