import React, { useState } from 'react';
import Select from 'react-select';
import * as yup from 'yup';

import SatisfactoryRatingsInput from 'components/SatisfactoryRatingsInput';

import constants from 'constants/config';
import classNames from 'classnames';

export const Feedback = ({ feedback, setFeedback, errors, setErrors }) => {
  const handleFeedbackInputChange = event => {
    if (event.target.name === 'rw_ratings') {
      setErrors({ ...errors, feedbackError: false, feedbackCommentError: false });
    }
    if(event.target.name === 'rw_comments' && event.target.value.trim().length){
      setErrors({ ...errors, feedbackCommentError: false });
    }
    let newFeedback = {
      ...feedback,
      [event.target.name]: event.target.value
    };
    setFeedback(newFeedback);
  };

  const handleFeedbackCommentType = value => {
    let newFeedback = {
      ...feedback,
      rw_commentType: value
    };
    setFeedback(newFeedback);
  };

  return (
    <div className="cw-campaign--review-section mb-10">
      <div className="cw-campaign--review-section-header mb-3 d-flex align-items-center">
        <h3 className="email-tabular__heading">Rate your experience</h3>
      </div>

      <div className="form-group mb-20">
        <label>Please rate your experience using Campaign Wizard</label>
        <SatisfactoryRatingsInput
          name="rw_ratings"
          onChange={handleFeedbackInputChange}
          value={feedback.rw_ratings}
          onBlur={() => {}}
          idPrefix="rw_"
        />
        {errors.feedbackError && (
          <span id="ratingsFieldMessage" className="cw-error">
            <i className="fas fa-exclamation-triangle mr-10"></i>
            Please rate your experience
          </span>
        )}
      </div>

      <div className="form-group mb-20"></div>

      <div className="form-group">
        <label>
          Please provide comments so we can action on your input
          &#40;Optional&#41;
        </label>
      </div>
      <div className="form-group">
        <label>Comment type</label>
        <Select
          className={'cw-select--custom'}
          name={'rw_commentType'}
          label={'rw_commentType'}
          placeholder={'Please select'}
          options={constants.SMS_FEEDBACKOPTIONS}
          onChange={handleFeedbackCommentType}
          value={feedback.rw_commentType}
        />
      </div>

      <div className="form-group">
        <textarea
          className={classNames({
            'form-control': true,
            'cw-error-focus': errors.feedbackCommentError,
          })}
          
          name="rw_comments"
          id="rw_comments"
          rows="3"
          maxLength="500"
          data-required="true"
          placeholder="Please provide your suggestions, complaints, compliments or new feature requests"
          onChange={handleFeedbackInputChange}
          value={feedback.rw_comments || ''}
          onBlur={() => {}}
        ></textarea>
         {errors.feedbackCommentError && (
          <span id="ratingsFieldMessage" className="cw-error">
            <i className="fas fa-exclamation-triangle mr-10"></i>
            Please enter your comments
          </span>
        )}
      </div>
    </div>
  );
};

export const validateFeedbackForm = async (
  feedbackData,
  shouldShowFeedbackSection
) => {
  const feedbackSchema = yup.object({
    rw_ratings: yup.string().required(),
    rw_comments: yup.string()
  });
  if (shouldShowFeedbackSection) {
    return await feedbackSchema.isValid(feedbackData);
  } else {
    return true;
  }
};
