import React, { forwardRef } from 'react';
import { FieldError } from '../../atoms/FieldError';
import { get } from 'lodash';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';

const CharCountTextarea = forwardRef(
  (
    { onChange, name, value, maxLength, label, helpText, errors, rows = '3', labelTooltip },
    ref
  ) => {
    // const charCount = value.length
    const errorMessages = get(errors, name);
    const hasError = !!(errors && errorMessages);
    return (
      <div className="form-group charcount-textarea">
        <label htmlFor={name}>
          {label}
          {labelTooltip && <>
            <span
              className="icon"
              style={{cursor: 'pointer'}}
              data-tip={labelTooltip}
              data-for={label}
            >
              &nbsp;<i className="fas fa-question-circle"></i>
            </span>
            <ReactTooltip
              id={label}
              place="right"
              type="info"
              multiline={true}
              className="cw-tooltip cw-email-tooltip"
            />
          </>}
        </label>
        <div className="position-relative">
          <textarea
            className={classNames('form-control pr-5', {
              'is-invalid': hasError
            })}
            type="text"
            name={name}
            id={name}
            onChange={event => onChange(event.target.value)}
            value={value}
            ref={ref}
            maxLength={maxLength}
            rows={rows}
          ></textarea>
          <span className="position-absolute text-secondary charcount-textarea__count">
            {value.length}/{maxLength}
          </span>
        </div>
        <div className="charcount-textarea__helparea">
          <div>
            <FieldError
              errors={errors}
              name={name}
              className="charcount-textarea__error-mesg"
            />
          </div>
          <div className="charcount-textarea__helptext">{helpText}</div>
        </div>
        {/* <div className="charcount-textarea__helptext">{helpText}</div>
        <FieldError errors={errors} name={name} /> */}
      </div>
    );
  }
);
export default CharCountTextarea;
