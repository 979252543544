import {
  GET_SMS_CAMPAIGNS_LIST,
  FETCHING_SMS_CAMPAIGNS
} from '../../actionTypes';

const initialState = {
  asyncInProgress: false,
  totalCount: '',
  campaigns: [],
  pageNumber: 1,
  limit: 50,
  recordShowStart: 1,
  recordShowto: 50,
  serviceError: null,
  serviceErrorStatus: null,
  showLoader: true,
  notificationMessage: '',
  notificationLink: '',
  notificationType: '',
  notificationCampaign: '',
  countries: [],
  brands: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCHING_SMS_CAMPAIGNS: {
      return { ...state, showLoader: action.showLoader };
    }
    case GET_SMS_CAMPAIGNS_LIST: {
      let campaigns = { campaigns: action.campaigns };
      let totalCount = { totalCount: action.totalCount };
      let pageNumber = { pageNumber: parseInt(action.pageNumber) };
      let recordShowStart = { recordShowStart: action.recordShowStart };
      let recordShowto = { recordShowto: action.recordShowto };
      let showloader = { showLoader: false };
      return {
        ...state,
        ...campaigns,
        ...totalCount,
        ...pageNumber,
        ...recordShowStart,
        ...recordShowto,
        ...showloader
      };
    }
    default:
      return state;
  }
}
