// Masterdata Reducer
import {
	QASORT_GET_CAMPAIGN,
	QASORT_ASYNC_IN_PROGRESS,
	UDPATE_QASORT_DETAILS,
	SUBMITTING_QASORT,
	QASORT_SUCCESS,
	RESET_QNA_SORT_SUBMISSION,
	SAVE_QUESTIONSANSWERS_DATA,
} from '../actionTypes';

const initialState = {
	asyncInProgress: false,
	campaign: {},
	campaignDetails: {},
	questionDisplay: 'single',
	questionAnswerList: [],
	questionLoaded: false,
	sortingdataSubmittedToApi: false,
	questionanswerssaved: false,
	isCampaignEdited: false,
	websiteType: '',
};

export default function(state = initialState, action) {
	switch (action.type) {
		case QASORT_GET_CAMPAIGN: {
			let campaignSections = {
				campaign: action.campaign,
				campaignDetails: action.campaign.campaignDetail,
				questionAnswerList:
					(action.campaign.campaignDetail && action.campaign.campaignDetail.questionAnswerList) || [],
				questionDisplay:
					(action.campaign.campaignDetail && action.campaign.campaignDetail.questionDisplay) || 'single',
				questionLoaded: true,
				isCampaignEdited: action.campaign && action.campaign.isEdited,
				websiteType: (action.campaign && action.campaign.websiteType && action.campaign.websiteType.name) || '',
			};

			return { ...state, ...campaignSections };
		}
		case QASORT_ASYNC_IN_PROGRESS: {
			let asyncInProgress = { asyncInProgress: action.isLoading };
			return { ...state, ...asyncInProgress };
		}
		case UDPATE_QASORT_DETAILS: {
			let questionDetails = action.questionDetails;
			return { ...state, ...questionDetails };
		}
		case QASORT_SUCCESS: {
			return {
				...state,
				...{ sortingdataSubmittedToApi: action.dataSubmission },
			};
		}
		case SAVE_QUESTIONSANSWERS_DATA: {
			return { ...state, ...{ questionanswerssaved: action.dataSubmission } };
		}
		case RESET_QNA_SORT_SUBMISSION: {
			return { ...state, ...{ sortingdataSubmittedToApi: false } };
		}
		default:
			return state;
	}
}
