import React from 'react';

export default function BackAndNext({
  onClickBack,
  onClickNext,
  invertStyles = false,
  backTitle = 'Back',
  nextTitle = 'Next',
  nextDisabled
}) {
  return (
    <div>
      {backTitle ? (
        <button
          className={`btn ${
            invertStyles ? 'btn-primary' : 'btn-outline-secondary'
          } mr-3`}
          type="button"
          onClick={onClickBack}
        >
          {backTitle}
        </button>
      ) : (
        ''
      )}
      <button
        className={`btn ${
          invertStyles ? 'btn-outline-secondary' : 'btn-primary'
        }`}
        type="button"
        disabled={nextDisabled}
        onClick={onClickNext}
      >
        {nextTitle}
      </button>
    </div>
  );
}
