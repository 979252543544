import React, { useState } from 'react';
import classNames from 'classnames';
import { textCapitalization } from '../../selectors/index';
import { getFormatedDate } from '../../selectors';
import SmsPreviewOverlay from './SmsPreviewOverlay';
import previewImg from '../../images/dashboard-list-preview.svg';
import { useHistory } from 'react-router';
import constant from '../../constants';
import { useSelector } from 'react-redux';
import {get} from 'lodash'

export default function CommunicationDashboardRow({ campaign = {}, index }) {
  const {
    brand,
    brandId,
    name,
    uid,
    lastUpdatedDate,
    country,
    countryId,
    status,
    communicationDetail,
    requestorsEmail,
    deliverySchedule ={},
    shareCostEstimate ={}
  } = campaign;

  const consumerCount = deliverySchedule?.consumerCount;
  const audienceCount = shareCostEstimate?.audienceCount
  const [isSmsPreviewOpen, setSmsPreviewOpen] = useState(false);
  const [selectedSmsForPreview, setSelectedSmsForPreview] = useState(
    {}
  );
  const { authorizedUserData } = useSelector(state => state.authorized);
  const history = useHistory();

  const expectedVolume  = consumerCount || audienceCount || (communicationDetail && communicationDetail.expectedVolume);

  const lastUpdateDate = getFormatedDate(lastUpdatedDate, false, false).split(
    '-'
  )[0];

  const statusClassName = classNames({
    'cw-print-hide': true,
    badge: true,
    'campaign-status-badge': true,
    'badge-success': String(status) === '2' || String(status) === '7',
    'badge-primary': String(status) === '1',
    'badge-warning': String(status) === '3',
    'badge-email-awaiting-setup':String(status) === '4' || String(status) === '9',
    'badge-email-schedule': String(status) === '6',
    'badge-email-reject': String(status) === '5',
    'badge-email-awaiting-info': String(status) === '8',
    'badge-email-awaiting-purchase_order': String(status) === '10'
  });

  const getSmsRedirectLink = () => {
    const role = get(authorizedUserData, 'group', 'requestor')
    if (['admin', 'approver'].includes(role) &&
      status === constant.SMS_STATUS.AWAITING_FOR_APPROVAL
    ) {
      return `/campaign-wizard/communications/${uid}/approval`;
    } else if (['admin', 'acs'].includes(role) &&
    (status === constant.SMS_STATUS.AWAITING_SETUP || status === constant.SMS_STATUS.SCHEDULED || status === constant.SMS_STATUS.AWAITING_COST_ESTIMATE)
    ) {
      return `/campaign-wizard/communications/${uid}/execution`;
    } else {
        return `/campaign-wizard/communications/${uid}`;
    }
  };

  const onSelectClick = e => {
    e.preventDefault();
    history.push(getSmsRedirectLink());
  };

  const getSMSBriefingLink = () => {
      return `/campaign-wizard/communications/${uid}`;
  };

  const handleCloseSmsPreview = () => {
    setSmsPreviewOpen(false);
    setSelectedSmsForPreview({});
  };
  return (
    <>
    <tr key={index}>
      <td>
        <a
          href={getSmsRedirectLink()}
          aria-label={name}
          className="one-line dark-text"
          onClick={e => {
            onSelectClick(e);
          }}
        >
          {name || 'Untitled'}
        </a>
      </td>
      <td className="max-width-column">
        <div className="d-flex flex-column ">
          <span className="dark-text one-line">
            {brandId?.name || textCapitalization(brand) || ''}
          </span>
          <span className="light-text">{countryId?.name || textCapitalization(country) || ''}</span>
        </div>
      </td>
      <td className="dark-text center one-line max-width-column">
        {expectedVolume || ''}
      </td>
      <td className="dark-text center">{lastUpdateDate}</td>
      <td className="center">
        <span className={statusClassName}>
          {constant.emailStatus[status]}
        </span>
      </td>
      <td className="center">
          <button
            className="inline-button template-btn"
            onClick={() => {
              setSmsPreviewOpen(true);
              setSelectedSmsForPreview(uid);
            }}
          >
            <img src={previewImg} alt="preview" className="preview-icon" />
          </button>
        </td>
    </tr>

    <SmsPreviewOverlay
        isOpen={isSmsPreviewOpen}
        onPreviewSelect={() => {
          history.push(getSMSBriefingLink());
        }}
        selectedSmsForPreview={selectedSmsForPreview}
        handleCloseForm={handleCloseSmsPreview}
        primaryButtonTitle="View"
        campaignName={name || 'Untitled'}
        requestorsEmail={requestorsEmail}
        brand={brand}
        status={status}
        country={country}
        campaignUpdatedAt={lastUpdatedDate}
        campaign ={campaign}
      />
    </>
  );
}
