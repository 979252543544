import React, { useState, useEffect } from 'react';
import { FormDataFields } from 'components/molecules/FormDataFields';
import { FormBuilder } from '../FormBuilder';
import classNames from 'classnames';

const extractData = formSchema => {
  const dataObj = {};
  if (formSchema) {
    formSchema.fields.forEach(field => {
      dataObj[field.name] = '';
    });
  }
  return dataObj;
};

const getFormFieldValue = data => {
  if (typeof data === 'object') {
    return data.label || data.value || data;
  }
  return data;
};

const getFieldsData = (formSchema, formData) => {
  const dataList = [];
  if (formSchema) {
    formSchema.fields.forEach(field => {
      if (field.type === 'custom') {
        const displayDataList = field.displayComponent(formData[field.name]);
        if (Array.isArray(displayDataList)) {
          dataList.push(...field.displayComponent(formData[field.name]));
        } else {
          dataList.push(field.displayComponent(formData[field.name]));
        }
      } else {
        dataList.push({
          label: field.label,
          value: getFormFieldValue(formData[field.name])
        });
      }
    });
  }
  return dataList;
};

const FormSection = ({
  id,
  title,
  formSchema,
  className,
  defaultValues,
  emailType,
  ...props
}) => {
  const [isEdit, setIsEdit] = useState(props.isEdit || false);
  const [isValid, setIsValid] = useState(false);
  const [formData, setFormData] = useState({});

  useEffect(() => {
    if (props.onStateChange) {
      const schema = formSchemaObj.getSchema();
      if (schema) {
        schema.isValid(formData).then(isDataValid => {
          props.onStateChange(id, { isValid: isDataValid, data: formData });
        });
      }
    }
  }, []);

  useEffect(() => {
    if (props.onStateChange) {
      props.onStateChange(id, { isValid, data: formData });
    }
  }, [isValid, formData]);

  useEffect(() => {
    if (defaultValues) {
      setFormData({ ...defaultValues });
      const schema = formSchemaObj.getSchema();
      if (schema) {
        // run validation on data returned from db
        schema.isValid(defaultValues).then(isDataValid => {
          setIsValid(isDataValid);
          // props.onStateChange(id, {isValid: isDataValid, data:formData})
        });
      }
    }
  }, [defaultValues]);

  const handleOnSubmit = (data, { formState }) => {
    console.log('FormSection submit data: ', data);
    // console.log('handleOnSubmit formState: ', formState);
    setFormData({ ...data });
    setIsValid(true);
    setIsEdit(false);
  };

  const handleFormStateChange = formState => {
    // if (formState.isSubmitted && formState.isValid) {
    //   setIsValid(formState.isValid);
    //   // setIsEdit(false);
    // }
  };

  const handleOnDraft = (values, schema, formState) => {
    setFormData({ ...values });
    schema.isValid(values).then(isDataValid => {
      setIsValid(isDataValid);
    });
    setIsEdit(false);
  };

  const formSchemaObj = formSchema(
    handleOnSubmit,
    formData,
    handleFormStateChange,
    handleOnDraft,
    emailType
  );

  return (
    <div
      className={classNames('cw-eb-form-section', className, {
        'section-has-error': props.hasError
      })}
    >
      <div className="row">
        <div className="col-md-12">
          <div className="d-flex justify-content-between align-items-center form-section-header">
            <div className="form-section-title d-flex align-items-center">
              {props.sectionIcon ? (
                <span className="form-section-icon">
                  <i className={props.sectionIcon}></i>
                </span>
              ) : (
                <img
                  className="form-section-img"
                  src="/campaign-wizard/form-section-icon.svg"
                  alt="icon for form section"
                />
              )}
              <h3 className="cw-heading--senary section-title">{title}</h3>
            </div>
            <div className="edit-box">
              {!isEdit && (
                <button
                  className="edit-btn"
                  onClick={() => {
                    setIsEdit(true);
                  }}
                >
                  {/* <img
                    className="edit-icon"
                    src="/campaign-wizard/edit-pencil.svg"
                    alt="icon for form section"
                  /> */}
                  <i className="fas fa-pen"></i>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      {isEdit ? (
        <div className="form-section-content">
          <FormBuilder schema={formSchemaObj} />
        </div>
      ) : (
        <>
          <div className="row">
            <div className="col-md-12">
              <FormDataFields
                className="form-section-content"
                fields={getFieldsData(formSchemaObj, formData)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="d-flex justify-content-end form-section-footer">
                <div className="status">
                  {isValid ? (
                    <i className="fas fa-check-circle status-valid"></i>
                  ) : (
                    <i className="fas fa-exclamation-triangle status-notvalid"></i>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export { FormSection };
