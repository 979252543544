import * as yup from 'yup';
import constants from 'constants/config';
import {
  getEmailCampaignDetails,
  updateEmailCampaignDetails
} from 'actions/emailbriefing/emailCampaignDetails';
import { getYupSchema, transformEmailBriefingPayload } from 'selectors';
import moment from 'moment';
import { get } from 'lodash';
import { EmailAttributes } from 'components/organisms/EmailAttributes';
import { EmailABTestingInput } from 'components/molecules/EmailABTestingInput';

const checkIfCleanUpRequired = (mainData, defaultData) => {
  const result = { cleanABTest: false, isVariantChange: false };
  const isAbTestConfigured = get(defaultData, 'isAbTestConfigured');
  const oldValue = get(defaultData, 'abTesting.performABTests');
  const newValue = get(mainData, 'abTesting.performABTests');
  const oldSplit = get(defaultData, 'abTesting.abTestSplits');
  const newSplit = get(mainData, 'abTesting.abTestSplits');
  const oldElements = get(defaultData, 'abTesting.abTestElements');
  const newElements = get(mainData, 'abTesting.abTestElements');
  if (isAbTestConfigured && oldValue === true && newValue === false) {
    result.cleanABTest = true;
  }
  if (isAbTestConfigured && oldElements !== newElements) {
    result.cleanABTest = true;
  }
  if (
    oldSplit === '3-variants-5-5-5-85' &&
    newSplit !== '3-variants-5-5-5-85'
  ) {
    result.isVariantChange = true;
  }
  return result;
};

const campaignAttributesSchema = (
  dispatch,
  { defaultValues, match, history, emailType, toggleShowDynamicCouponAlert }
) => {
  const isEdited =
    history &&
    history.location &&
    history.location.state &&
    history.location.state.isEdited;

  const onSubmit = (data, { user }, { backToReview } = {}) => {
    const paramUid = match.params.id;
    const {
      couponCode,
      kanaTicketNumber,
      emailCoupon,
      emailCouponType,
      couponFileUpload,
      shareCouponFileViaKana
    } = data.emailAttributes;

    const dataToSend = {
      ...data,
      userEmail: user.emailId,
      userName: user.name
    };

    dataToSend.emailAttributes = emailCoupon
      ? {
          emailCoupon: {
            couponApplied: emailCoupon,
            type: emailCouponType,
            code: emailCouponType === 'staticCoupon' ? couponCode : '',
            kanaTicketNumber:
              emailCouponType === 'dynamicCoupon' ? kanaTicketNumber : '',
            couponFileUpload: emailCouponType === 'dynamicCoupon' ? couponFileUpload : '',
            shareCouponFileViaKana: emailCouponType === 'dynamicCoupon' ? shareCouponFileViaKana : false,
          }
        }
      : {};

    const transformedData = transformEmailBriefingPayload(
      dataToSend,
      'abTesting'
    );
    
    const checks = checkIfCleanUpRequired(transformedData, defaultValues);
    if (checks.cleanABTest) {
      transformedData.cleanABTest = true;
    }
    if (checks.isVariantChange) {
      transformedData.isVariantChange = true;
    }
    console.log({ transformedData });

    dispatch(
      updateEmailCampaignDetails(paramUid, transformedData, (error, result) => {
        if (result) {
          const uid = match.params.id;
          const status = defaultValues?.status;
          if (isEdited && backToReview) {
            if (status === constants.EMAIL_STATUS.AWAITING_FOR_APPROVAL) {
              history.push(`/campaign-wizard/emailbriefing/${uid}/approval`);
            } else if (
              status === constants.EMAIL_STATUS.DRAFT ||
              status === constants.EMAIL_STATUS.REJECTED
            ) {
              history.push(`/campaign-wizard/emailbriefing/${uid}/review`);
            }
          } else {
            history.push(
              `/campaign-wizard/emailbriefing/${uid}/email-schedule`
            );
          }
        }
        if (error) {
          console.log('OnSubmit error: ', error);
        }
      })
    );
  };

  return {
    formType: 'custombuttons',
    submitBtnLabel: isEdited ? 'Save and back to review' : 'Next',
    onSecondaryClick: onSubmit,
    onSubmitSecondary: () => {},
    isEdit: isEdited,
    secondaryBtnLabel: 'Next',
    fields: [
      {
        type: 'custom',
        name: 'emailAttributes',
        id: 'emailAttributes',
        label: 'Coupons',
        component: EmailAttributes,
        defaultValue: defaultValues,
        rules: yup.object({
          emailCoupon: yup.bool(),
          emailCouponType: yup
            .string()
            .when('emailCoupon', {
              is: data => !!data,
              then: schema => schema.required('Please select coupon type')
            })
            .nullable(),
          couponCode: yup
            .string()
            .when(
              ['emailCouponType', 'emailCoupon'],
              (type, applied, schema) => {
                if (applied && type === 'staticCoupon') {
                  return schema
                    .required('Please provide a coupon code')
                    .max(50, 'Coupon code cannot be longer than 50 characters');
                }
                return schema;
              }
            )
            .nullable(),
          kanaTicketNumber: yup
            .string()
            .when(
              ['emailCouponType', 'emailCoupon', 'shareCouponFileViaKana'],
              (type, applied, isShareViaKana, schema) => {
                if (applied && type === 'dynamicCoupon' && isShareViaKana) {
                  return schema
                    .matches(
                      /^$|^CS\d{7}$/,
                      "Please enter a valid Kana ticket in format 'CS1234567'"
                    )
                    .nullable()
                    .max(
                      9,
                      "Please enter a valid Kana ticket in format 'CS1234567'"
                    );
                }
                return schema;
              }
            )
            .nullable()
        }),
        toggleShowDynamicCouponAlert
      },
      {
        type: 'custom',
        name: 'abTesting',
        label: 'A/B or multivariate tests',
        component: EmailABTestingInput,
        id: 'abTesting',
        defaultValue: defaultValues,
        rules: yup.object({
          performABTests: yup.bool(),
          abTestElements: yup
            .string()
            .when('performABTests', {
              is: data => !!data,
              then: schema =>
                schema.required('Please specify A/B test elements')
            })
            .nullable(),
          abTestSplits: yup
            .string()
            .when('performABTests', {
              is: data => !!data,
              then: schema => schema.required('Please specify A/B test split')
            })
            .nullable(),
          winCriteria: yup
            .string()
            .when('performABTests', {
              is: data => !!data,
              then: schema => schema.required('Please specify win criteria')
            })
            .nullable(),
          testDuration: yup
            .object()
            .shape({
              label: yup.string().required(),
              value: yup.string().required()
            })
            .when('abTestSplits', {
              is: data =>
                constants.EMAIL_AB_TEST_DURATION_CHECK_LIST.includes(data),
              then: schema => schema.required('Please select test duration')
            })
            .default(undefined)
        })
      }
    ],
    onSubmit: (data, { user }) =>
      onSubmit(data, { user }, { backToReview: isEdited }),
    onDraft: (data, validationSchema, { user }) => {
      const paramUid = match.params.id;
      const {
        couponCode,
        kanaTicketNumber,
        emailCoupon,
        emailCouponType,
        couponFileUpload,
        shareCouponFileViaKana
      } = data.emailAttributes;

      const dataToSend = {
        userEmail: user.emailId,
        userName: user.name,
        ...data,
        status: '1'
      };

      dataToSend.emailAttributes = emailCoupon
        ? {
            emailCoupon: {
              couponApplied: emailCoupon,
              type: emailCouponType,
              code: emailCouponType === 'staticCoupon' ? couponCode : '',
              kanaTicketNumber:
                emailCouponType === 'dynamicCoupon' ? kanaTicketNumber : '',
                couponFileUpload: emailCouponType === 'dynamicCoupon' ? couponFileUpload : '',
                shareCouponFileViaKana: emailCouponType === 'dynamicCoupon' ? shareCouponFileViaKana : false,
            }
          }
        : {};

      const transformedData = transformEmailBriefingPayload(
        dataToSend,
        'abTesting'
      );
      const checks = checkIfCleanUpRequired(transformedData, defaultValues);
      if (checks.cleanABTest) {
        transformedData.cleanABTest = true;
      }
      if (checks.isVariantChange) {
        transformedData.isVariantChange = true;
      }
      console.log('transformedData: ', transformedData);
      dispatch(
        updateEmailCampaignDetails(
          paramUid,
          transformedData,
          (error, result) => {
            if (result) {
              const uid = match.params.id;
              history.push(`/campaign-wizard/email-dashboard`);
            }
            if (error) {
              console.log('OnSubmit error: ', error);
            }
          },
          { isSaveAsDraft: true }
        )
      );
    },
    getSchema() {
      if (this.fields) {
        return getYupSchema(this.fields);
      }
      return null;
    },
    onPrevious: () => {
      const uid = match.params.id;
      history.push(`/campaign-wizard/emailbriefing/${uid}/email-summary`);
    },
    previousBtnLabel: 'Back',
    draftText: 'Save and exit',
    formErrorMessage:
      'There was a problem with the form. Errors are listed below'
  };
};

export default campaignAttributesSchema;
