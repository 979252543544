import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import Modal from 'react-modal';
import ReactHtmlParser from 'react-html-parser';
import _ from 'lodash';
import FormAuthoring from './Review/FormAuthoring';
import FormAuthoringGrouped from './Review/FormAuthoringGrouped';
import SummaryComponent from './SummaryComponent';
import ReactTooltip from 'react-tooltip';
import axios from 'axios';
//import constants from "../constants/config";
import Toolbar from './common/toolbar';
import {
  fetchCampaignData,
  deleteCampaign,
  cancelCampaign,
  setCampaignToEdit,
  cloneCampaign,
  saasCampaignToEdit
  //resubmitCamapign,
} from '../actions/overview';
import { fetchHistoryLogs } from '../actions/history';
import { setDeletedCampaign } from '../actions/app';
import { openFeedbackForm } from '../actions/feedbackForm';
import {
  getCampaignStatus,
  showGigyaIdScreen,
  isAEMTemplateCampaign,
  getCampaignStatusText,
  isQuestionMandatory,
  isQuesQualifying,
  showCampaignEditButton,
  checkCampaignLanguage,
  _generateUUID,
  isAEMTypeCampaign,
  checkMarketSupportedForDoubleOptin,
  isGigyaKeySubmitted,
  epsilonMarket,
  showEditFormForSaas,
  hasAEMFlow,
  isUnbundledConsentAndMarketCheck
} from '../selectors';
import Loader from '../components/Loader';
import ProfileStore from './ProfileStore';
import GigyaProfileStore from './GigyaProfileStore';
import Optins from './Optins';
import QuestionAnswerList from './QuestionAnswerList';
import RegularCommunications from './RegularCommunications';
import CampaignDetailsInfo from './CampaignDetailsInfo';
import { getFormatedDate, getFormatedDateNew } from '../selectors/index';
import { setNotificationMessage } from '../actions/dashboard';
import ServiceError from './ServiceError';
import NotFound from './NotFound';
import constant from '../constants';
import Alert from './Alert';
import HistoryComponent from '../components/history';
import ModalWindow from './ModalWindow';
import constants from 'constants/config';
import CampaignEditModel from './CampaignEditModel';
import overview from 'reducers/overview';
import EditPermissionsSection from './EditPermissionsSection';
import groupIcon from '../images/groupIcon.png';
import QnAList from './Review/QnAList';
import refresh from '../images/refresh.png';
import ApproverAssignmentInfo from './organisms/ApproverAssignmentInfo';
import CampaignMasterDataEditModel from './CampaignMasterDataEditModel';
import MasterDataSummaryComponent from './MasterDataSummaryComponent';

class Overview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      activeModal: '',
      isEditModalOpen: false
    };
    this._openModal = this._openModal.bind(this);
    this._openRestrictCloneModal = this._openRestrictCloneModal.bind(this);
    this._closeModal = this._closeModal.bind(this);
    this._openIsEditModal = this._openIsEditModal.bind(this);
    this._closeIsEditModal = this._closeIsEditModal.bind(this);

    this._saveOverviewDetail = this._saveOverviewDetail.bind(this);
    this._handleInputChange = this._handleInputChange.bind(this);
    this._cancelCampaign = this._cancelCampaign.bind(this);

    this.collapsePanel = React.createRef();
    this.collapseHandlerIcon = React.createRef();
    this.collapseHandlerText = React.createRef();
    this.optinsDescription = {
      Brand:
        'Please tick the box to receive offers and competitions about (brand name ) by email.',
      Brand_SMS:
        'Please tick the box to receive offers and competitions about (brand name ) by SMS.',
      Unilever:
        'Please tick the box to receive exciting news, offers and competitions from other Unilever brands by email.'
    };
    //MasterData Collapsible Panel
    this.collapseMasterDataPanel = React.createRef();
    this.collapseMasterDataHandlerIcon = React.createRef();
    this.collapseMasterDataHandlerText = React.createRef();
    this._handleCollapse = this._handleCollapse.bind(this);

    this._editCampaign = this._editCampaign.bind(this);
    this._editSaasCampaign = this._editSaasCampaign.bind(this);
    this._cloneCampaign = this._cloneCampaign.bind(this);
    this._cloneSaasCampaign = this._cloneSaasCampaign.bind(this);
    this._redirectToManageEditors = this._redirectToManageEditors.bind(this);
    this.notificationData = {
      message: '',
      link: '',
      type: ''
    };
  }

  componentDidMount() {
    let previewAvaialable = true;
    let isOverviewPage = true;
    this.campaignId = this.props.match.params.id;
    this.props.fetchCampaignData(
      this.campaignId,
      previewAvaialable,
      undefined,
      undefined,
      undefined,
      isOverviewPage
    );
    this.props.fetchHistoryLogs(this.campaignId);
  }

  _handleRefresh = () => {
    let { campaign } = this.props;
    axios
      .post(constant.serviceUrls.CAMPAIGN_INFRA_API_CALL, campaign)
      .then(response => {
        let previewAvaialable = false;
        let isOverviewPage = true;
        this.campaignId = this.props.match.params.id;
        this.props.fetchCampaignData(
          this.campaignId,
          previewAvaialable,
          undefined,
          undefined,
          undefined,
          isOverviewPage
        );
      });
  };

  _isConsentsValid(consents) {
    let isConsentsValid = false;
    if (consents) {
      consents.map((consent, index) => {
        if (
          consent.optInType !== 'legalAgeConsent' &&
          consent.optInType !== 'legalDisclaimer' &&
          consent.optInType !== 'privacyNotice'
        ) {
          isConsentsValid = true;
        }
      });
    }
    return isConsentsValid;
  }

  _handleCollapse(event) {
    event.preventDefault();
    let collapisblePanel,
      collapseHandlerIcon,
      collapseHandlerText,
      collapseHandlerTextLess,
      collapseHandlerTextMore;

    collapisblePanel = this.collapseMasterDataPanel;
    collapseHandlerIcon = this.collapseMasterDataHandlerIcon;
    collapseHandlerText = this.collapseMasterDataHandlerText;
    collapseHandlerTextLess = 'View less Master Data IDs';
    collapseHandlerTextMore = 'View more Master Data IDs';
    if (collapisblePanel.current.classList.contains('show')) {
      collapisblePanel.current.classList.remove('show');
      collapseHandlerIcon.current.classList.remove('fa-chevron-up');
      collapseHandlerIcon.current.classList.add('fa-chevron-down');
      collapseHandlerText.current.innerHTML = collapseHandlerTextMore;
    } else {
      collapisblePanel.current.classList.add('show');
      collapseHandlerText.current.innerHTML = collapseHandlerTextLess;
      collapseHandlerIcon.current.classList.remove('fa-chevron-down');
      collapseHandlerIcon.current.classList.add('fa-chevron-up');
    }
  }

  _handleInputChange(event) {
    event.preventDefault();
  }

  _openModal(event) {
    event.preventDefault();
    let modaltype = event.target.getAttribute('dataset-modaltype');
    this.setState({
      modalIsOpen: true,
      activeModal: modaltype ? modaltype : 'editCampaign'
    });
  }

  _openRestrictCloneModal() {
    this.setState({
      modalIsOpen: true,
      activeModal: 'restrictCloneAem'
    });
  }

  _openRestrictUnbundledEditModal() {
    this.setState({
      modalIsOpen: true,
      activeModal: 'unBundledRestrict'
    });
  }

  _openIsEditModal(event) {
    event.preventDefault();
    const { campaign, campaignDetails } = this.props;
    const countryCode = campaign.country && campaign.country.code;
    const isUnbundledMarket = isUnbundledConsentAndMarketCheck(
      this.props.campaignConfig,
      countryCode
    );
    if (
      isUnbundledMarket &&
      campaignDetails &&
      campaignDetails.isUnBundledConsent === false
    ) {
      this._openRestrictUnbundledEditModal();
      return;
    } else {
      this.setState({
        isEditModalOpen: true
      });
    }
  }

  _openPromotigo(uid) {
    this.props.history.push(
      '/campaign-wizard/campaign/' + uid + '/promotigo-info'
    );
  }

  _closeModal() {
    this.setState({ modalIsOpen: false, activeModal: '' });
  }

  _closeIsEditModal() {
    this.setState({
      isEditModalOpen: false
    });
  }

  _saveOverviewDetail(id, name) {
    this.props.deleteCampaign(id, name);
    this.props.setDeletedCampaign(name);
    this.props.history.push('/campaign-wizard/dashboard');
    return false;
  }
  _continueCampaign(uid) {
    let route = this.props.campaignDetails.lastUpdatedScreen;
    this.props.history.push(
      '/campaign-wizard/campaign/' + uid + '/branddetails'
    );
    return false;
  }

  _editCampaign(event) {
    event.preventDefault();
    this.props.setCampaignToEdit();
    this.props.history.push(
      '/campaign-wizard/campaign/' + this.campaignId + '/branddetails'
    );
  }

  _editSaasCampaign(event) {
    event.preventDefault();
    this.props.saasCampaignToEdit(this.campaignId);
    this.props.history.push({
      pathname: '/campaign-wizard/campaign/' + this.campaignId + '/details',
      state: { isEdited: true }
    });
  }

  _cancelCampaign(id) {
    this.props.cancelCampaign(id, this.props.userName, this.props.userEmail);
    this.setState({ modalIsOpen: false, activeModal: '' });
  }

  _cloneCampaign() {
    let hasAemTypeFlow =
      isAEMTypeCampaign(this.props.campaign) || hasAEMFlow(this.props.campaign);
    if (hasAemTypeFlow && !constant.featureFlags.ENABLE_AEM_FORM_OPTION) {
      this._openRestrictCloneModal();
      return;
    }
    let path = window.location.pathname;
    let uids1 = path.substring(26);
    let uid = uids1.substring(0, 8);
    let uuid = _generateUUID();
    this.props.cloneCampaign(
      uid,
      uuid,
      this.props.userName,
      this.props.userEmail
    );
    this.notificationData.message = 'has been created.';
    this.notificationData.type = 'warning';
    this.notificationData.campaign = 'Copy of ' + this.props.campaign.name;
    this.notificationData.link = '/campaign-wizard/campaign/' + uuid;
    this.props.setNotificationMessage(
      this.notificationData.message,
      this.notificationData.link,
      this.notificationData.type,
      this.notificationData.campaign
    );
    this.props.history.push('/campaign-wizard/dashboard');

    // setTimeout(() => {
    //   window.location.href = `/campaign-wizard/campaign/${uuid}`;
    // }, 1000);
  }

  _cloneSaasCampaign() {
    let path = window.location.pathname;
    let uids1 = path.substring(26);
    let uid = uids1.substring(0, 8);
    let uuid = _generateUUID();
    this.props.cloneCampaign(
      uid,
      uuid,
      this.props.userName,
      this.props.userEmail
    );
    this.notificationData.message = 'has been created.';
    this.notificationData.type = 'warning';
    this.notificationData.campaign = 'Copy of ' + this.props.campaign.name;
    this.notificationData.link = '/campaign-wizard/campaign/' + uuid;
    this.props.setNotificationMessage(
      this.notificationData.message,
      this.notificationData.link,
      this.notificationData.type,
      this.notificationData.campaign
    );
    this.props.history.push('/campaign-wizard/dashboard');

    // setTimeout(() => {
    //   window.location.href = `/campaign-wizard/campaign/${uuid}`;
    // }, 1000);
  }

  _redirectToManageEditors() {
    this.campaignId = this.props.match.params.id;
    this.props.history.push(
      `/campaign-wizard/campaign/${this.campaignId}/manage-editors`
    );
  }

  _handleResubmit(event) {
    event.preventDefault();
    let uid = this.props.match.params.id;
    let campaign = {
      uid: this.props.match.params.id,
      status: '3'
    };
    axios
      .post(constant.serviceUrls.CAMPAIGN_STATUS_UPDATE_SERVICE_URL, campaign)
      .then(response => {
        //Fetching campaign detials again to get the updated status as per the re-submit call
        this.props.fetchCampaignData(uid, true);
      });
  }
  handleAccordion = index => {
    this.setState(prevState => ({
      questionAccordion: prevState.questionAccordion === index ? false : index
    }));
  };

  handleOnOpenFeedback = () => {
    this.props.openFeedbackForm({
      campaignUid: this.props.campaign.uid,
      userId: this.props.authorizedUserData._id,
      defaultCampaignRequest: 'form',
      showCampaignRequest: false
    });
  };

  isPreviousMasterIdsPresent = campaignDetail => {
    const { fields } = campaignDetail;
    return fields
      ? fields.some(f => {
          if (f.dataType === 'questionAnswerPair') {
            return (
              constant.PREVIOUS_MASTER_KEYS.some(key => !!f[key]) ||
              f.answers.some(a =>
                constant.PREVIOUS_MASTER_KEYS.some(key => !!a[key])
              )
            );
          }
          return false;
        })
      : false;
  };

  render() {
    let { isClone, incentiveType } = this.props.campaign;
    let marketCode =
      this.props.campaign &&
      this.props.campaign.country &&
      this.props.campaign.country.code;
    let allowedMarketsForDoubleOptin = constant.allowedMarketsForDoubleOptin;
    let isGigyakey = isGigyaKeySubmitted(this.props.campaign);
    let isMarketSupported = checkMarketSupportedForDoubleOptin(
      marketCode,
      allowedMarketsForDoubleOptin
    );

    let isEpsilonMarket = false;
    if (this.props.campaign && this.props.campaign.country) {
      isEpsilonMarket = epsilonMarket(this.props.campaign.country.code);
    }

    let divStyle = {
      backgroundColor: '#f9f9f9'
    };
    let newMasterData = isNew => {
      return isNew ? (
        <>
          <div
            style={{ display: 'block', color: '#E9A40A', whiteSpace: 'pre' }}
            className="col-sm-3"
          >
            <img src={groupIcon} alt={groupIcon} width="25" height="25"></img>
            &nbsp; New Master
          </div>
        </>
      ) : null;
    };

    Modal.setAppElement('#root');
    const {
      campaign,
      campaignDetails,
      questionAnswerList,
      campaignStatus,
      isProcessed,
      campaignId,
      asyncInProgress,
      isLoading
    } = this.props;
    if (!(campaign && campaign.uid)) {
      return null;
    }

    let autogenerateSignupInAEM = campaign.websiteType
      ? campaign.websiteType.name === 'Standalone' ||
        campaign.websiteType.name === 'Adobe Classic' ||
        campaign.websiteType.name === 'Adobe Templated'
      : false;
    let hasInstantWinLose =
      campaign &&
      campaign.campaignType === 'Incentive' &&
      campaign.incentiveType === 'Competitions' &&
      campaign.campaignDetail &&
      campaign.campaignDetail.instantWinLose !== undefined &&
      campaign.campaignDetail.instantWinLose
        ? true
        : false;
    let isSimpleSignUp = campaign && campaign.type === 'Simple sign-up';
    let isTrackingRequired =
      campaign &&
      campaign.campaignDetail &&
      campaign.campaignDetail.isTrackingRequired;
    let isTrackingRequiredForSimpleSignUp =
      isTrackingRequired && isSimpleSignUp;
    let cwStatus = getCampaignStatus(campaign.status);
    let campaignStatusCode = campaign && campaign.status;
    let isPublished = campaign && campaign.isProcessed ? true : false;
    let isAEMTemplate = isAEMTemplateCampaign(campaign);
    let showGigyaId = showGigyaIdScreen(campaign);
    let getcampaignStatus = getCampaignStatusText(
      campaign,
      this.props.userrole
    );
    let campaignLanguage =
      campaign && campaign.language && campaign.language.name;
    let isCampaignNonEnglish = checkCampaignLanguage(campaignLanguage);

    let isCampaignIncentive =
      (campaign &&
        campaign.campaignType === 'Incentive' &&
        ((campaign.campaignDetail &&
          campaign.campaignDetail.incentiveItPartner === 'promotigo') ||
          campaign.incentivePromotionalPartner === 'promotigo')) ||
      (campaign.campaignType === 'Incentive' &&
        !(campaign && campaign.isMasterData))
        ? true
        : false;

    let isAEMType = isAEMTypeCampaign(campaign);
    let isPromotigoType = isAEMType && isCampaignIncentive ? true : false;
    let adobeClassicImplementationLink =
      constant.serviceUrls.CONFLUENCE_ADOBE_CLASSIC_IMPLEMENTATION;
    let adobeTemplatedImplementationLink =
      constant.serviceUrls.CONFLUENCE_ADOBE_TRANSLATED_IMPLEMENTATION;
    let otherPlatformImplementationLink =
      constant.serviceUrls.CONFLUENCE_OTHER_PLATFORM_IMPLEMENTATION;
    let campaignJsonOutputLink =
      constant.serviceUrls.CAMPAIGN_JSON_OUTPUT_URL + '/' + campaignId;
    let jsonURL = constant.serviceUrls.CAMPAIGN_JSON_URL;
    let isCampaignTypeSASS =
      (campaign &&
        campaign.websiteType &&
        campaign.websiteType.name === 'Adobe SaaS') ||
      campaign.isPlatformAgnostic ||
      campaign.isMasterData;

    let isCampaignTypeAT =
      campaign &&
      campaign.websiteType &&
      campaign.websiteType.name === 'Adobe Templated'
        ? true
        : false;

    let isCampaignInfraEnabled =
      campaign && campaign.status && campaign.status === '1'
        ? false
        : campaignDetails &&
          campaignDetails.campaignInfraDetails &&
          campaignDetails.campaignInfraDetails.isCampaignInfraSetupDone
        ? true
        : false;
    let dynamicMarginTop = {
      marginTop: '0'
    };
    if (
      isCampaignTypeSASS &&
      showEditFormForSaas(this.props) &&
      (campaign.status === constants.campaignStatus.COMPLETED ||
        campaign.status !== constants.campaignStatus.DRAFT)
    ) {
      dynamicMarginTop.marginTop = '1%';
    }

    let enableEditForSAAS = constant.featureFlags.EDIT_FOR_SAAS;
    let jsonOutput = (
      <div className="col-sm-12 " style={{ marginLeft: '-1%' }}>
        <div className="cw-campaign--review-section mb-30">
          <div className="mb-20">
            <h3 className="cw-heading--primary">JSON for the campaign</h3>
            <p>
              This output will be consumed by third party system to generate a
              form in web platform.
            </p>
          </div>
          <div className="cw-striped-row" style={divStyle}>
            <div className="row">
              <div className="col-sm-4">
                <strong>Json output</strong>
              </div>
              <div className="col-sm-8">
                <span>
                  <a
                    href={campaignJsonOutputLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {constant.ctaToLink.campaignJSONLinkText} &nbsp;
                    <i className="fas fa-external-link-alt"></i>
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
    let statusClassName = classNames({
      'mt-3': true,
      badge: true,
      'badge-success':
        campaignStatus === '3'
          ? isAEMTemplate
            ? isPublished
              ? true
              : false
            : true
          : false,
      'badge-warning':
        campaignStatus === '2' ||
        campaignStatus === '6' ||
        campaignStatus === '7' ||
        campaignStatus === '8' ||
        campaignStatus === '12' ||
        (campaignStatus === '3'
          ? isAEMTemplate
            ? isPublished
              ? false
              : true
            : false
          : true),
      'badge-primary': campaignStatus === '1' || campaignStatus === '4',
      'badge-secondary': campaignStatus === '9',
      'cw-print-hide': true
    });

    let isAutogenerate = campaign && campaign.autogenerate ? true : false;
    let disabledFlag = campaign.status === '2' ? true : false;
    let draftFlag =
      campaign.status === '1' || campaign.status === '4' ? true : false;
    let qAndAItems = [];
    let answerList = (answers, questionId) => {
      let answerItems = [];
      if (answers && answers.length) {
        answers.forEach((answer, answersIndex) => {
          if (answer.label) {
            answerItems.push(
              <div key={answersIndex} className="cw-striped-row">
                <div className="row">
                  <div className="col-sm-3">
                    <strong>Answer</strong>
                  </div>
                  <div className="col-sm-4">
                    <p>{answer.label}</p>
                  </div>
                  {cwStatus === 'Completed' ? (
                    <div className="col-sm-4">{answer.value || '-1'}</div>
                  ) : null}
                </div>
              </div>
            );
          }
        });
      }
      return answerItems;
    };

    if (campaign && Object.keys(campaign).length > 0) {
      // eslint-disable-next-line no-lone-blocks
      if (questionAnswerList.length) {
        questionAnswerList.forEach((question, questionIndex) => {
          qAndAItems.push(
            <div
              key={questionIndex}
              className="cw-campaign--review-section-content"
            >
              <div className="cw-separator mb-20">
                <div className="cw-striped-row">
                  <div className="row">
                    <div className="col-sm-3">
                      <strong>Question</strong>
                    </div>
                    <div className="col-sm-4">
                      <p>{question.text}</p>
                    </div>
                    {cwStatus === 'Completed' ? (
                      <div className="col-sm-4 text-center">
                        {question.id || 'Awaiting'}
                      </div>
                    ) : null}
                  </div>
                </div>
                {question.type !== 'Profile' &&
                question.type !== 'Unique entry code' &&
                question.type !== 'File upload'
                  ? answerList(question.answers, questionIndex)
                  : null}
                <div className="cw-striped-row">
                  <div className="row">
                    <div className="col-sm-3">
                      <strong>Type</strong>
                    </div>
                    <div className="col-sm-5">
                      <p>{question.type}</p>
                    </div>
                    <div className="col-sm-4"></div>
                  </div>
                </div>
                <div className="cw-striped-row">
                  <div className="row">
                    <div className="col-sm-3">
                      <strong>Mandatory </strong>
                    </div>
                    <div className="col-sm-5">
                      <p>{isQuestionMandatory(question.mandatory)}</p>
                    </div>
                    <div className="col-sm-4"></div>
                  </div>
                </div>
                <div className="cw-striped-row">
                  <div className="row">
                    <div className="col-sm-3">
                      <strong>Qualifying</strong>
                    </div>
                    <div className="col-sm-5">
                      <p>{isQuesQualifying(question.isQualifying)}</p>
                    </div>
                    <div className="col-sm-4"></div>
                  </div>
                </div>
              </div>
            </div>
          );
        });
      }
    }

    let qnaHtml = (fields, isCampaignEnglish, campaignLanguage) => {
      let questionsHtml = fields
        .filter(elem => elem.dataType === 'questionAnswerPair')
        .map((elem, index) => {
          return (
            <>
              <div className="mb-10">
                <div className="accordion saas-form-field-accordion">
                  <div className="card" style={{ border: 0 }}>
                    <div
                      className={`card-header ${
                        this.state.questionAccordion === index
                          ? 'card-bottom-border'
                          : 'card-no-bottom'
                      }`}
                      style={{ padding: '7px 13px', marginBottom: '10px' }}
                    >
                      <div className="row">
                        <div className="col-sm-9">
                          <span
                            className="saas-accordion-heading"
                            style={{ fontWeight: '700' }}
                          >
                            {elem.questionLabel}
                          </span>
                        </div>

                        <div
                          className="col-sm-2"
                          style={{
                            display: 'block',
                            color: '#E9A40A',
                            whiteSpace: 'pre'
                          }}
                        >
                          {elem.isNew ||
                          elem.istranslatedNew ||
                          elem.answers.find(
                            e => e.isNew === true || e.istranslatedNew === true
                          ) ? (
                            <>
                              <img
                                style={{ marginLeft: '5px' }}
                                src={groupIcon}
                                alt={groupIcon}
                                width="25"
                                height="25"
                              ></img>
                              &nbsp; New Master
                            </>
                          ) : (
                            ''
                          )}
                        </div>
                        <div className="col-sm-1">
                          <span
                            type="button"
                            className={
                              this.state.questionAccordion === index
                                ? 'saas-accordion-arrow afterTransform'
                                : 'saas-accordion-arrow'
                            }
                            onClick={() => {
                              this.handleAccordion(index);
                            }}
                          >
                            <i
                              className={
                                this.state.questionAccordion === index
                                  ? 'fas fa-chevron-down transformProfileIcon-180'
                                  : 'fas fa-chevron-down'
                              }
                            ></i>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        this.state.questionAccordion === index
                          ? 'collapse show'
                          : 'collapse'
                      }
                    >
                      <div className="cw-campaign--review-section-content">
                        <div className="cw-question-answer-dotted-line">
                          <div className="cw-heading-qna">
                            <span
                              className="float-left  fa fa-circle mt-10"
                              style={{ marginTop: '5px' }}
                            ></span>
                            <strong style={{ marginLeft: '15px' }}>
                              Question
                            </strong>
                          </div>
                          <div className="cw-striped-qna-row">
                            <div className="row">
                              <div className="col-sm-3 cw-qna-lable">
                                Question Type
                              </div>
                              <div
                                className="col-sm-3"
                                style={{ lineBreak: 'anywhere' }}
                              >
                                {elem.label}
                              </div>
                            </div>
                          </div>
                          <div className="cw-striped-qna-row">
                            <div className="row">
                              <div className="col-sm-3 cw-qna-lable">
                                English-Master Data
                              </div>
                              <div
                                className="col-sm-3"
                                style={{ lineBreak: 'anywhere' }}
                              >
                                {elem.englishLabel}
                              </div>
                              <div className="col-sm-3">
                                {elem.id
                                  ? elem.id
                                  : cwStatus !== 'Cancelled'
                                  ? 'Awaiting'
                                  : ''}
                              </div>
                              {newMasterData(elem.isNew)}
                            </div>
                          </div>
                          {!isCampaignEnglish ? (
                            <div className="cw-striped-qna-row">
                              <div className="row">
                                <div className="col-sm-3 cw-qna-lable">
                                  {campaignLanguage} translation- Master Data
                                </div>
                                <div
                                  className="col-sm-3 cw-qna-lable"
                                  style={{ lineBreak: 'anywhere' }}
                                >
                                  {elem.translatedLabel}
                                </div>
                                <div className="col-sm-3 ">
                                  {elem.translatedId
                                    ? elem.translatedId
                                    : cwStatus !== 'Cancelled'
                                    ? 'Awaiting'
                                    : ''}
                                </div>
                                {newMasterData(elem.isNew)}
                              </div>
                            </div>
                          ) : null}
                          <div className="cw-striped-qna-row">
                            <div className="row">
                              <div className="col-sm-3 cw-qna-lable">
                                Consumer Facing Label
                              </div>
                              <div
                                className="col-sm-3"
                                style={{ lineBreak: 'anywhere' }}
                              >
                                {elem.questionLabel}
                              </div>
                            </div>
                          </div>
                        </div>
                        {elem.answers && elem.answers.length > 0 ? (
                          <>
                            <br />
                            <div className="cw-question-answer-dotted-line">
                              <div className="cw-heading-qna">
                                <span
                                  className="float-left  fa fa-circle mt-10"
                                  style={{ marginTop: '5px' }}
                                ></span>
                                <strong style={{ marginLeft: '15px' }}>
                                  Answer
                                </strong>
                              </div>
                              {elem.answers.map((answer, answerIndex) => {
                                return (
                                  <>
                                    <strong style={{ marginLeft: '30px' }}>
                                      Answer {answerIndex + 1}
                                    </strong>

                                    <div className="cw-striped-qna-row">
                                      <div className="row">
                                        <div className="col-sm-3 cw-qna-lable">
                                          English-Master Data
                                        </div>
                                        <div
                                          className="col-sm-3 cw-qna-lable"
                                          style={{ lineBreak: 'anywhere' }}
                                        >
                                          {answer.englishLabel}
                                        </div>
                                        <div className="col-sm-3">
                                          {answer.value
                                            ? answer.value
                                            : cwStatus !== 'Cancelled'
                                            ? 'Awaiting'
                                            : ''}
                                        </div>
                                        {newMasterData(answer.isNew)}
                                      </div>
                                    </div>
                                    {!isCampaignEnglish ? (
                                      <div className="cw-striped-qna-row">
                                        <div className="row">
                                          <div className="col-sm-3 cw-qna-lable">
                                            {campaignLanguage} Translation-
                                            Master Data
                                          </div>
                                          <div
                                            className="col-sm-3 cw-qna-lable"
                                            style={{ lineBreak: 'anywhere' }}
                                          >
                                            {answer.translatedLabel}
                                          </div>
                                          <div className="col-sm-3">
                                            {answer.translatedId
                                              ? answer.translatedId
                                              : cwStatus !== 'Cancelled'
                                              ? 'Awaiting'
                                              : ''}
                                          </div>
                                          {newMasterData(
                                            answer.isNew ||
                                              answer.istranslatedNew
                                          )}
                                        </div>
                                      </div>
                                    ) : null}
                                    <div className="cw-striped-qna-row">
                                      <div className="row">
                                        <div className="col-sm-3 cw-qna-lable">
                                          Consumer Facing Label
                                        </div>
                                        <div
                                          className="col-sm-3"
                                          style={{ lineBreak: 'anywhere' }}
                                        >
                                          {answer.answerLabel}
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                          </>
                        ) : null}

                        {/*elem.value==="multipleChoice" ? (
                     <div className="cw-striped-row">
                        <div className="row">
                          <div className="col-sm-4">
                            <strong>Maximum number of answers user can select</strong>
                          </div>
                          <div className="col-sm-8 cw-text--ternary">{elem.maxAnswerLimit}
                          </div>
                       </div>
                     </div>
                     ):null*/}
                        <br />
                        <div className="cw-question-answer-dotted-line">
                          <div className="cw-heading-qna">
                            <span
                              className="float-left  fa fa-circle mt-10"
                              style={{ marginTop: '5px' }}
                            ></span>
                            <strong style={{ marginLeft: '15px' }}>
                              Other Configurations
                            </strong>
                          </div>

                          {elem.toolTip && elem.toolTip.trim().length > 0 ? (
                            <div className="cw-striped-qna-row">
                              <div className="row">
                                <div className="col-sm-3 cw-qna-lable">
                                  Tooltip/Help-Text
                                </div>
                                <div
                                  className="col-sm-3"
                                  style={{ lineBreak: 'anywhere' }}
                                >
                                  {elem.toolTip}
                                </div>
                              </div>
                            </div>
                          ) : null}
                          <div className="cw-striped-qna-row">
                            <div className="row">
                              <div className="col-sm-3 cw-qna-lable">
                                Mandatory field
                              </div>
                              <div className="col-sm-5">
                                {elem.mandatoryToggle
                                  ? 'Mandatory'
                                  : 'Optional'}
                              </div>
                            </div>
                          </div>
                          {elem.mandatoryToggle ? (
                            <div className="cw-striped-qna-row">
                              <div className="row">
                                <div className="col-sm-3 cw-qna-lable">
                                  Mandatory Field Error Message
                                </div>
                                <div
                                  className="col-sm-5"
                                  style={{ lineBreak: 'anywhere' }}
                                >
                                  {elem.mandatoryMessage}
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        });
      return questionsHtml;
    };

    let consentsHtml = consents => {
      let consenthtml = '';
      if (consents && consents.length > 0) {
        consenthtml = consents
          .filter(
            consent =>
              consent.optInType !== 'legalAgeConsent' &&
              consent.optInType !== 'legalDisclaimer' &&
              consent.optInType !== 'privacyNotice' &&
              consent.optInType !== 'termsAndConditions' &&
              consent.optInType !== 'additionalSubscription1' &&
              consent.optInType !== 'additionalSubscription' &&
              consent.optInType !== 'contentInterestAreas'
          )
          .map((consent, index) => {
            return (
              <div className="cw-striped-profile-row">
                <div className="row">
                  <div className="col-sm-3">
                    <strong>{consent.label}</strong>
                  </div>
                  <div className="col-sm-4">
                    {/* {consent.defaultCopy
                      ? ReactHtmlParser(consent.defaultCopy)
                      : null} */}
                  </div>
                  <div className="col-sm-3">
                    {consent.serviceId
                      ? consent.serviceId
                      : cwStatus !== 'Cancelled'
                      ? 'Awaiting'
                      : ''}
                  </div>
                  <div className="col-sm-2"></div>
                </div>
              </div>
            );
          });
      }

      return consenthtml;
    };

    let consentsSubscriptionHtml = consents => {
      let consenthtml = '';
      if (consents && consents.length > 0) {
        consenthtml = consents
          .filter(
            consent =>
              consent.optInType === 'additionalSubscription1' ||
              consent.optInType === 'additionalSubscription'
          )
          .map((consent, index) => {
            return (
              <div
                class={`cw-striped-profile-row cw-striped-profile-row--hide_class_${index}`}
                style={{ padding: '8px 7px' }}
              >
                <div class="row">
                  <div class="col-sm-3">
                    <strong>
                      {consent.optInType === 'additionalSubscription'
                        ? 'Subscription 1 text'
                        : consent.optInType === 'additionalSubscription1'
                        ? 'Subscription 2 text'
                        : ''}
                    </strong>
                  </div>
                  <div class="col-sm-4">
                    {consent.defaultCopy
                      ? ReactHtmlParser(consent.defaultCopy)
                      : null}
                  </div>
                  <div class="col-sm-3 text-break">
                    {consent.serviceId
                      ? consent.serviceId
                      : cwStatus !== 'Cancelled'
                      ? 'Awaiting'
                      : ''}
                  </div>
                  <div className="col-sm-2">
                    <div
                      className={`
                ${
                  consent.optInType === 'additionalSubscription1' ||
                  consent.optInType === 'additionalSubscription'
                    ? ''
                    : 'cw-hidden'
                }`}
                      style={{ color: '#E9A40A', whiteSpace: 'pre' }}
                    >
                      <img
                        src={groupIcon}
                        alt={groupIcon}
                        width="25"
                        height="25"
                      ></img>
                      &nbsp; New Master
                    </div>
                  </div>
                </div>
              </div>
            );
          });
      }

      return consenthtml;
    };

    if (asyncInProgress) {
      return <Loader />;
    } else if (isLoading) {
      return <Loader />;
    } else if (
      this.props.serviceError &&
      (this.props.serviceErrorStatus === 500 ||
        this.props.serviceErrorStatus === 401)
    ) {
      return <ServiceError />;
    } else if (
      this.props.serviceError &&
      this.props.serviceErrorStatus === 404
    ) {
      return <NotFound />;
    } else {
      // let status = getCampaignStatus(campaignStatus);
      return (
        <>
          <div className="cw-section cw-overview">
            <div className="col-sm-12 mb-30">
              <div className="col-sm-6" style={{ marginLeft: '-2.5%' }}>
                <NavLink
                  className="cw-color--primary cw-breadcrum cw-print-hide"
                  to={'/campaign-wizard/dashboard'}
                >
                  <i className="fas fa-angle-left"></i> Campaigns
                </NavLink>
              </div>
            </div>
            <div className={`col-sm-10 offset-md-1`}>
              {/* */}
              <Alert
                type={'warning'}
                message={
                  this.props.campaign && this.props.campaign.rejectComments
                }
                alertType={'rejectcomment'}
                showComponent={
                  this.props.campaign &&
                  campaignStatus &&
                  (campaignStatus ===
                    constant.campaignStatus.REJECTED.toString() ||
                    campaignStatus ===
                      constant.campaignStatus.DRAFT.toString() ||
                    campaignStatus ===
                      constant.campaignStatus.WAITING_APPROVAL.toString()) &&
                  this.props.campaign.rejectComments
                }
              />
              <Alert
                textMessage={
                  'Some of the questions or answers master data IDs' +
                  ' have been removed as they are no longer or not yet' +
                  ' available in the master list. IDs will be reassigned' +
                  ' by consumer data team.'
                }
                showComponent={
                  campaignStatus === constant.campaignStatus.DRAFT &&
                  this.isPreviousMasterIdsPresent(campaignDetails)
                }
                alertType="warningMessageWithIcon"
              />

              {/*Cancellation message for cancelled campaigns */}
              <Alert
                type={'warning'}
                alertType={'cancelcampaign'}
                campaignName={this.props.campaign && this.props.campaign.name}
                message={'has been cancelled.'}
                showComponent={this.props.cancelled}
              />
              {/* Alert for failure of campaign page generation*/}
              <Alert
                alertType={'nonPublished'}
                showComponent={
                  campaign.status === constant.campaignStatus.PROCESSING &&
                  campaign &&
                  campaign.aemError &&
                  campaign.aemError.code !== '404' &&
                  campaign.aemError.code !== '412'
                }
              />
              <Alert
                alertType={'error404NonPublished'}
                showComponent={
                  campaign.status === constant.campaignStatus.PROCESSING &&
                  campaign &&
                  campaign.aemError &&
                  campaign.aemError.code === '404'
                }
              />
              <Alert
                alertType={'isPromotigoAC'}
                showComponent={
                  campaign.status === constant.campaignStatus.PROCESSING &&
                  campaign &&
                  campaign.aemError &&
                  campaign.aemError.code === '412'
                }
              />
              <Alert
                alertType={'formWidgetErrorMessgae'}
                showComponent={
                  campaign.status === constant.campaignStatus.PROCESSING &&
                  campaign &&
                  campaign.formSubmissionError &&
                  campaign.formSubmissionError.code
                }
                errorMessage={constants.FORM_SUBMISSION_ERROR}
              />
            </div>
            {/* <Alert
              type={"warning"}
              message={`${campaign.name} created`}
              alertType={"clonedCampaignMessage"}
              showComponent={isClone && draftFlag}
            /> */}
            <div className="draft_button_section_main">
              <div className={`col-sm-10 offset-md-1`}>
                <h2 className="cw-heading--secondary  mb-10 cw-print-hide">
                  <span className="cw-print-hide campaign-break-word">
                    {campaign.name || 'Untitled'}
                  </span>
                  <span
                    className={`${statusClassName} status_badge_reposition_overview`}
                  >
                    {getcampaignStatus}
                  </span>
                </h2>
                <ApproverAssignmentInfo
                  campaign={campaign}
                  userData={this.props.authorizedUserData}
                />
                {draftFlag ? <p>Continue creating your campaign</p> : null}
                <div className="row">
                  <div
                    className={
                      cwStatus == 'Completed'
                        ? `col-12 cw-print-hide`
                        : `col-9 cw-print-hide`
                    }
                    style={{ zIndex: this.state.isEditModalOpen ? '0' : '' }}
                  >
                    <div className="toolbar-scroll-fix overview-toolbar">
                      <Toolbar
                        showPrint={campaign.status === '3'}
                        showPreview={
                          isCampaignTypeSASS &&
                          cwStatus !== 'Draft' &&
                          cwStatus !== 'Cancelled' &&
                          campaignStatusCode !== '2' &&
                          campaign &&
                          !campaign.isMasterData
                        }
                        previewHandler={this.props.previewUrl}
                        showCloneSAAS={
                          cwStatus === 'Completed' && isCampaignTypeSASS
                        }
                        saasCloneHandler={this._cloneSaasCampaign}
                        showCloneNonSAAS={
                          cwStatus !== 'Draft' && !isCampaignTypeSASS
                        }
                        nonSaasCloneHandler={this._cloneCampaign}
                        showEditSAAS={
                          isCampaignTypeSASS && campaign.status === '3'
                        }
                        saasEditHandler={this._openIsEditModal}
                        showEditNonSAAS={showCampaignEditButton(campaign)}
                        nonSaasEditHandler={this._openModal}
                        showPermission={
                          isCampaignTypeSASS &&
                          showEditFormForSaas(this.props) &&
                          campaign.status !== constants.campaignStatus.DRAFT &&
                          campaign.status !==
                            constants.campaignStatus.REJECTED &&
                          campaign &&
                          !campaign.isMasterData
                        }
                        permissionsHandler={this._redirectToManageEditors}
                        showFeedbackLink={
                          constants.featureFlags.ENABLE_FEEDBACK_FORM
                        }
                        onOpenFeedback={this.handleOnOpenFeedback}
                      />
                    </div>
                  </div>
                  {isCampaignTypeSASS &&
                    !draftFlag &&
                    cwStatus !== 'Completed' &&
                    campaign &&
                    !campaign.isMasterData && (
                      <div className="col-sm-3 cw-print-hide text-right">
                        <a
                          className="btn btn-primary preview-form-btn"
                          href={this.props.previewUrl}
                          target={'_blank'}
                        >
                          Preview Form
                        </a>
                      </div>
                    )}
                </div>
                <Alert
                  alertType={'saasEditedMessageInfo'}
                  showComponent={
                    false
                    // this.props.campaign && this.props.campaign.lastEditDate
                    //   ? true
                    //   : false
                  }
                  lastUpdatedDate={
                    this.props.campaign && this.props.campaign.lastEditDate
                      ? getFormatedDateNew(
                          this.props.campaign &&
                            this.props.campaign.lastEditDate
                        )
                      : ''
                  }
                />
                {/* {draftFlag ? <p>Continue creating your campaign</p> : null} */}
                {draftFlag ? null : cwStatus === 'Completed' &&
                  !isCampaignTypeSASS ? (
                  isAEMTemplate ? (
                    isPublished ? (
                      <>
                        <p>
                          Your campaign has now been generated on AEM. Use the
                          AEM Campaign links to finalise the campaign prior to
                          Publishing. Review the campaign for translations,
                          mandated fields, postal code validation formatting
                          (regex) for postcode fields and add styling tweaks if
                          required.
                        </p>
                        {isGigyakey ? (
                          <>
                            <br />
                            <p>
                              Note: All Simple sign up campaigns (without
                              tracking) should use Gigya screensets <br />
                              and will be provided with gigya keys. Campaign
                              Wizard will only provide a pdf output. <br />
                              Whereas signup with Q&A campaigns forms will be
                              built in AEM automatically if the
                              <br />
                              Website Type is Adobe Classic or Adobe Templated
                              or Standalone.
                            </p>{' '}
                          </>
                        ) : null}
                      </>
                    ) : (
                      <p>
                        This campaign either awaiting IDs or approval. We will
                        contact you when the campaign set-up is complete.
                      </p>
                    )
                  ) : (
                    <>
                      <p
                        className={`${
                          showCampaignEditButton(campaign)
                            ? 'cw-print-hide'
                            : ' d-none'
                        }`}
                      >
                        Review and edit your campaign.
                      </p>
                      {isGigyakey ? (
                        <>
                          <br />
                          <p className="cw-print-show">
                            Note: All Simple sign up campaigns (without
                            tracking) should use Gigya screensets <br />
                            and will be provided with gigya keys. Campaign
                            Wizard will only provide a pdf output. <br />
                            Whereas signup with Q&A campaigns forms will be
                            built in AEM automatically if the
                            <br />
                            Website Type is Adobe Classic or Adobe Templated or
                            Standalone.
                          </p>{' '}
                        </>
                      ) : null}
                      <br />
                      {/* <div className="cw-campaign--review-section mb-30 cw-print-show">
                        <div className="mb-20">
                          <h3 className="cw-heading--primary">
                            Confluence Links for Next Steps
                          </h3>
                          <p>
                            We advice you to follow the next steps mentioned in
                            the links provided
                          </p>
                        </div>
                        <div className="cw-campaign--review-section-content">
                          <div className="cw-striped-row">
                            <div className="row">
                              <div className="col-sm-4">
                                <strong>
                                  Campaign Form Auto-Generation: Other Platforms
                                  (other than AC &amp; AT)
                                </strong>
                              </div>
                              <div className="col-sm-8 text-break">
                                <span>
                                  <a
                                    href={otherPlatformImplementationLink}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {constant.ctaToLink.otherPlatformLinkText} &nbsp;
                                    <i className="fas fa-external-link-alt"></i>
                                  </a>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {jsonOutput} */}
                    </>
                  )
                ) : cwStatus === 'Cancelled' ? (
                  <p className="mb-30">
                    This campaign has been cancelled and will not be processed.
                  </p>
                ) : cwStatus !== 'Completed' ? (
                  <p>
                    This campaign either awaiting IDs or approval. We will
                    contact you when the campaign set-up is complete.
                  </p>
                ) : (
                  ''
                )}
                <div className={`text-right draft_button_section`}>
                  <span
                    className={`${
                      draftFlag ? 'btn-group-vertical px-10' : 'd-none'
                    }`}
                  >
                    <button
                      type="submit"
                      className="btn btn-primary mr-3 rounded "
                      onClick={this._continueCampaign.bind(this, campaign.uid)}
                    >
                      Continue Campaign
                    </button>
                    <button
                      type="submit"
                      onClick={this._openModal}
                      dataset-modaltype={'delete'}
                      title="Confirm Delete"
                      className="btn btn-outline-primary rounded"
                    >
                      Delete Campaign
                    </button>
                  </span>
                  {/* <span
                  className={`${
                    showCampaignEditButton(campaign) && !campaign.isPlatformAgnostic ? "" : " d-none"
                  }`}
                >
                  <button
                    type="submit"
                    className="btn btn-primary btn-custom-width mb-10  cw-print-hide"
                    onClick={this._openModal}
                    dataset-modaltype={"editCampaign"}
                  >
                    Edit campaign
                  </button>
                </span> */}
                  <span
                    className={`${
                      campaign.status === '2' && isCampaignTypeSASS
                        ? ''
                        : ' d-none'
                    }`}
                  >
                    <button
                      type="submit"
                      onClick={event => this._handleResubmit(event)}
                      className="btn btn-primary  rounded"
                    >
                      Re-submit
                    </button>
                  </span>
                  {/* <span className={`${campaign.status === "3" ? "" : " d-none"}`}>
                  <button
                    type="submit"
                    onClick={() => window.print()}
                    className="btn btn-outline-primary btn-custom-width mb-10 cw-print-hide"
                  >
                    Print campaign
                  </button>
                </span> */}

                  {/* <span
                  className={`${
                    isCampaignTypeSASS &&
                    showEditFormForSaas(this.props) &&
                    campaign.status === "3"
                      ? ""
                      : " d-none"
                  }`}
                >
                  <button
                    type="submit"
                    onClick={this._openIsEditModal}
                    className="btn btn-primary btn-custom-width mb-10 cw-print-hide"
                  >
                    Edit form
                  </button>
                </span> */}
                  <Modal
                    scrollable={true}
                    isOpen={this.state.isEditModalOpen}
                    onRequestClose={this._closeIsEditModal}
                    className="cw-modal cw-modal--branddetail"
                    contentLabel="Campaign Wizard 2.0"
                    style={{
                      overlay: {
                        backgroundColor: constants.EMAIL_OVERLAY_BACKGROUND
                      }
                    }}
                  >
                    {campaign && campaign.isMasterData ? (
                      <CampaignMasterDataEditModel
                        closeIsEditModal={this._closeIsEditModal}
                        isEditContinue={this._editSaasCampaign}
                        data={constants.EDIT_MASTERDATA_MESSAGE}
                        campaignType={campaign.campaignType}
                        campaign={campaign}
                      />
                    ) : (
                      <CampaignEditModel
                        closeIsEditModal={this._closeIsEditModal}
                        isEditContinue={this._editSaasCampaign}
                        data={constants.SAAS_EDIT_MESSAGES}
                        campaignType={campaign.campaignType}
                        campaign={campaign}
                      />
                    )}
                  </Modal>

                  {/* <span
                  className={`${
                    cwStatus === "Draft" || isCampaignTypeSASS ? "d-none" : ""
                  }`}
                >
                  <button
                    type="submit"
                    onClick={this._cloneCampaign}
                    className="btn btn-primary btn-custom-width mb-10 cw-print-hide"
                  >
                    Clone campaign
                  </button>
                </span> */}
                  {/* <span
                  className={`${
                    cwStatus === "Completed" && isCampaignTypeSASS
                      ? ""
                      : "d-none"
                  }`}
                >
                  <button
                    type="submit"
                    onClick={this._cloneSaasCampaign}
                    className="btn btn-primary btn-custom-width mb-10 cw-print-hide"
                  >
                    Clone campaign
                  </button>
                </span> */}

                  {/* <span
                  className={`${
                    isCampaignTypeSASS &&
                    showEditFormForSaas(this.props) &&
                    campaign.status != constants.campaignStatus.DRAFT
                      ? ""
                      : "d-none"
                  }`}
                >
                  <button
                    type="submit"
                    onClick={this._redirectToManageEditors}
                    className="btn btn-outline-primary btn-custom-width mb-10 cw-print-hide"
                  >
                    Manage Editors
                  </button>
                </span> */}

                  <span
                    className={`${
                      cwStatus === 'Awaiting Promotigo ID'
                        ? 'btn-group-vertical px-10'
                        : 'd-none'
                    }`}
                  >
                    {this.props.authorizedUserData &&
                    this.props.authorizedUserData.group &&
                    this.props.authorizedUserData.group === 'requestor' &&
                    campaign.status === '12' ? (
                      <button
                        type="submit"
                        onClick={this._openPromotigo.bind(this, campaign.uid)}
                        title="Add Form ID campaign"
                        className="btn btn-primary mr-3 rounded"
                      >
                        Add Form ID
                      </button>
                    ) : (
                      ''
                    )}
                    <br />
                    {this.props.authorizedUserData &&
                    this.props.authorizedUserData.group &&
                    this.props.authorizedUserData.group === 'requestor' &&
                    !isCampaignTypeSASS &&
                    (cwStatus !== 'Completed' || cwStatus !== 'Published') ? (
                      <button
                        type="submit"
                        onClick={this._openModal}
                        dataset-modaltype={'cancel'}
                        title="Cancel campaign"
                        className="btn btn-outline-primary  cw-print-hide"
                      >
                        Cancel campaign
                      </button>
                    ) : (
                      ''
                    )}
                  </span>

                  <span
                    className={`${
                      cwStatus === 'Processing'
                        ? 'btn-group-vertical px-10'
                        : 'd-none'
                    }`}
                  >
                    {this.props.authorizedUserData &&
                    this.props.authorizedUserData.group &&
                    this.props.authorizedUserData.group === 'requestor' &&
                    !isCampaignTypeSASS &&
                    (cwStatus !== 'Completed' || cwStatus !== 'Published') ? (
                      <button
                        type="submit"
                        onClick={this._openModal}
                        dataset-modaltype={'cancel'}
                        title="Cancel campaign"
                        className="btn btn-primary mr-3 rounded"
                      >
                        Cancel campaign
                      </button>
                    ) : (
                      ''
                    )}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-10 offset-md-1">
              {cwStatus === 'Draft' && !isCampaignTypeSASS ? (
                <div className="cw-campaign--review-section mb-40">
                  <div
                    className="cw-campaign--review-section mb-30"
                    // style={{ marginLeft: '-20px' }}
                  >
                    <SummaryComponent
                      data={this.props.campaign}
                      isBrandSummary={true}
                      test={getcampaignStatus}
                      handleRefresh={this._handleRefresh}
                      campaignConfig={this.props.campaignConfig}
                    />
                  </div>
                  {/* <div className="cw-campaign--review-section mb-30">
                  <div className="mb-20">
                    <h3 className="cw-heading--primary">Campaign Type</h3>
                  </div>
                  <div className="cw-campaign--review-section-content">
                    <div className="cw-striped-row">
                      <div className="row">
                        <div className="col-sm-4">
                          <strong>Campaign type</strong>
                        </div>
                        <div className="col-sm-8">
                          {campaign && campaign.campaignType
                            ? campaign.campaignType + " campaign"
                            : ""}
                        </div>
                      </div>
                    </div>
                    {campaign && campaign.campaignType === "Incentive" ? (
                      <div className="cw-striped-row">
                        <div className="row">
                          <div className="col-sm-4">
                            <strong>Incentive</strong>
                          </div>
                          <div className="col-sm-8">
                            {campaign && campaign.incentiveType
                              ? campaign.incentiveType
                              : ""}
                          </div>
                        </div>
                      </div>
                    ) : null}
                    <div className="cw-striped-row">
                      <div className="row">
                        <div className="col-sm-4">
                          <strong>Form type</strong>
                        </div>
                        <div className="col-sm-8">
                          {campaign && campaign.type ? campaign.type : ""}
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                  <CampaignDetailsInfo
                    isCampaignTypeSASS={isCampaignTypeSASS}
                    previewUrl={this.props.previewUrl}
                    cwStatus={cwStatus}
                    campaign={campaign}
                    campaignDetails={campaignDetails}
                    panelref={this.collapsePanel}
                    collapseHandlerIconref={this.collapseHandlerIcon}
                    collapseHandlerTextref={this.collapseHandlerText}
                    isConsentAndCompliance={true}
                    isCampaignType={false}
                    isCampaignAuthoring={true}
                    userrole={this.props.userrole}
                    isOverviewPage={true}
                    handleRefresh={this._handleRefresh}
                    campaignConfig={this.props.campaignConfig}
                  />
                  {/* <div className="cw-campaign--review-section mb-30">
                  <div className="mb-20">
                    <h3 className="cw-heading--primary">Campaign Details </h3>
                  </div>
                  <div className="cw-campaign--review-section-content">
                    <div className="cw-striped-row">
                      <div className="row">
                        <div className="col-sm-4">
                          <strong>Campaign name</strong>
                        </div>
                        <div className="col-sm-8">
                          {campaign && campaign.name}
                        </div>
                      </div>
                    </div>
                    <div className="cw-striped-row">
                      <div className="row">
                        <div className="col-sm-4">
                          <strong>Campaign description</strong>
                        </div>
                        <div className="col-sm-8">
                          {campaignDetails && campaignDetails.description}
                        </div>
                      </div>
                    </div>
                    <div
                      className={`cw-striped-row ${campaignDetails && campaignDetails.brandUrl
                        ? ""
                        : "d-none"
                        }`}
                    >
                      <div className="row">
                        <div className="col-sm-4">
                          <strong>Brand Url</strong>
                        </div>
                        <div className="col-sm-8">
                          {campaignDetails && campaignDetails.brandUrl
                            ? campaignDetails.brandUrl
                            : ""}
                        </div>
                      </div>
                    </div>
                    <div className="cw-striped-row">
                      <div className="row">
                        <div className="col-sm-4">
                          <strong>Brand Cookie policy</strong>
                        </div>
                        <div className="col-sm-8">
                          {campaignDetails.cookiePolicyUrl}
                        </div>
                      </div>
                    </div>
                    <div className="cw-striped-row">
                      <div className="row">
                        <div className="col-sm-4">
                          <strong>Brand Privacy policy</strong>
                        </div>
                        <div className="col-sm-8">
                          {campaignDetails && campaignDetails.privacyPolicyUrl}
                        </div>
                      </div>
                    </div>
                    <div className="cw-striped-row">
                      <div className="row">
                        <div className="col-sm-4">
                          <strong>Campaign T&Cs</strong>
                        </div>
                        <div className="col-sm-8">
                          {campaignDetails && campaignDetails.tncUrl}
                        </div>
                      </div>
                    </div>
                    <div className="cw-striped-row">
                      <div className="row">
                        <div className="col-sm-4">
                          <strong>Minimum legal age of consent</strong>
                        </div>
                        <div className="col-sm-8">
                          {campaignDetails && campaignDetails.ageOfConsent}
                        </div>
                      </div>
                    </div>

                    {isMarketSupported ? (
                      <div className="cw-striped-row">
                        <div className="row">
                          <div className="col-sm-4">
                            <strong>Double Opt-in</strong>
                          </div>
                          <div className="col-sm-8">
                            {campaignDetails && campaignDetails.doubleOptinReq
                              ? "Yes"
                              : "No"}
                          </div>
                        </div>
                      </div>
                    ) : null}
                    <div className="cw-striped-row">
                      <div className="row">
                        <div className="col-sm-4">
                          <strong>Disclaimer text</strong>
                        </div>
                        <div className="col-sm-8">
                          {campaignDetails && campaignDetails.disclaimerText}
                        </div>
                      </div>
                    </div>
                    <div className="cw-striped-row">
                      <div className="row">
                        <div className="col-sm-4">
                          <strong>Requester's email</strong>
                        </div>
                        <div className="col-sm-8">
                          {campaignDetails && campaignDetails.requestersEmail}
                        </div>
                      </div>
                    </div>

                    <div className="cw-striped-row">
                      <div className="row">
                        <div className="col-sm-4">
                          <strong>Start date</strong>
                        </div>
                        <div className="col-sm-8">
                          {campaignDetails && campaignDetails.startDate
                            ? getFormatedDate(campaignDetails.startDate) +
                            " UTC"
                            : ""}
                        </div>
                      </div>
                    </div>
                    <div className="cw-striped-row">
                      <div className="row">
                        <div className="col-sm-4">
                          <strong>End date</strong>
                        </div>
                        <div className="col-sm-8">
                          {campaignDetails && campaignDetails.isAlwaysOn
                            ? "No end date"
                            : campaignDetails && campaignDetails.endDate
                              ? getFormatedDate(campaignDetails.endDate) + " UTC"
                              : null}
                        </div>
                      </div>
                    </div>

                    <div className="cw-striped-row">
                      <div className="row">
                        <div className="col-sm-4">
                          <strong>Unique Campaign ID?</strong>
                        </div>
                        <div className="col-sm-8 cw-text--quinary">
                          {campaignDetails.isCustomTrackingId !== undefined
                            ? campaignDetails &&
                              campaignDetails.isCustomTrackingId
                              ? "Reuse existing provided by brand"
                              : campaignDetails.isTrackingRequired !== undefined
                                ? campaignDetails &&
                                  campaignDetails.isTrackingRequired
                                  ? "Yes"
                                  : "No"
                                : ""
                            : campaignDetails.isTrackingRequired !== undefined
                              ? campaignDetails &&
                                campaignDetails.isTrackingRequired
                                ? "Yes"
                                : "No"
                              : ""}
                        </div>
                      </div>
                    </div>
                    {campaignDetails.isCustomTrackingId !== undefined &&
                      campaignDetails.isCustomTrackingId ? (
                      <div className="cw-striped-row">
                        <div className="row">
                          <div className="col-sm-4">
                            <strong>Campaign ID</strong>
                          </div>
                          <div className="col-sm-8 cw-text--quinary">
                            {this.props.campaign.campaignId
                              ? this.props.campaign.campaignId
                              : "Awaiting"}
                          </div>
                        </div>
                      </div>
                    ) : null}

                    <div className="cw-striped-row">
                      <div className="row">
                        <div className="col-sm-4">
                          <strong>DPC/PRA reference </strong>
                        </div>
                        <div className="col-sm-8">
                          {campaignDetails && campaignDetails.dpiaReferenceNo}
                        </div>
                      </div>
                    </div>

                    {campaign.campaignType === "Incentive" &&
                      isAEMType &&
                      isClone ? (
                      <>
                        <div className="cw-striped-row">
                          <div className="row">
                            <div className="col-sm-4">
                              <strong>Promotigo Form ID</strong>
                            </div>
                            <div className="col-sm-8">
                              {campaignDetails &&
                                campaignDetails.promotigoFormId
                                ? campaignDetails.promotigoFormId
                                : "Awaiting"}
                            </div>
                          </div>
                        </div>

                        {incentiveType === "Competitions" ? (
                          <div className="cw-striped-row">
                            <div className="row">
                              <div className="col-sm-4">
                                <strong>Instant Win/Lose</strong>
                              </div>
                              <div className="col-sm-8">
                                {campaignDetails &&
                                  campaignDetails.instantWinLose
                                  ? "Yes"
                                  : "No"}
                              </div>
                            </div>
                          </div>
                        ) : null}

                        {incentiveType === "Product samples" ? (
                          <div className="cw-striped-row">
                            <div className="row">
                              <div className="col-sm-4">
                                <strong>Samples</strong>
                              </div>
                              <div className="col-sm-8">
                                {campaignDetails &&
                                  campaignDetails.samples &&
                                  campaignDetails.samples.length > 0 &&
                                  (campaign.status === "3" ||
                                    campaign.status === "12" ||
                                    campaign.status === "2" ||
                                    (campaign.status === "1" && isClone))
                                  ? (campaignDetails.samples + ",").substring(
                                    0,
                                    (campaignDetails.samples + ",").length - 1
                                  )
                                  : "Awaiting"}
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </>
                    ) : null}

                    <div
                      className={`cw-striped-row ${campaign &&
                        campaign.websiteType &&
                        campaign.websiteType.name &&
                        campaign.websiteType.name === "Standalone"
                        ? ""
                        : "d-none"
                        }`}
                    >
                      <div className="row">
                        <div className="col-sm-4">
                          <strong>Campaign authoring access</strong>
                        </div>
                        <div className="col-sm-8">
                          {campaignDetails.users.length &&
                            campaignDetails.users
                              .map((data) => data.email)
                              .join("\n")}
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                </div>
              ) : (
                <>
                  <div className="mb-3">
                    {isCampaignTypeSASS &&
                    cwStatus === 'Completed' &&
                    campaign &&
                    !campaign.isMasterData ? (
                      <SummaryComponent
                        data={this.props.campaign}
                        isBrandSummary={false}
                        cwStatus={this.props.cwStatus}
                        previewUrl={this.props.previewUrl}
                        isCampaignTypeSASS={this.props.isCampaignTypeSASS}
                        handleRefresh={this._handleRefresh}
                        campaignConfig={this.props.campaignConfig}
                      />
                    ) : null}

                    {cwStatus === 'Completed' &&
                    campaign &&
                    campaign.isMasterData ? (
                      <MasterDataSummaryComponent
                        data={this.props.campaign}
                        isBrandSummary={false}
                        cwStatus={this.props.cwStatus}
                        previewUrl={this.props.previewUrl}
                        isCampaignTypeSASS={this.props.isCampaignTypeSASS}
                        handleRefresh={this._handleRefresh}
                        campaignConfig={this.props.campaignConfig}
                      />
                    ) : null}

                    <SummaryComponent
                      data={this.props.campaign}
                      isBrandSummary={true}
                      cwStatus={this.props.cwStatus}
                      isCampaignTypeSASS={this.props.isCampaignTypeSASS}
                      handleRefresh={this._handleRefresh}
                      campaignConfig={this.props.campaignConfig}
                    />
                  </div>
                  {!isCampaignTypeSASS &&
                  getcampaignStatus === 'Completed' &&
                  !isPublished ? (
                    <>
                      <div className="cw-campaign--review-section mb-40">
                        <div className="cw-campaign--review-section-header mb-3 d-flex align-items-center">
                          <h3 className="cw-heading--senary">Form Summary</h3>
                        </div>
                        <div className="cw-striped-profile-row">
                          <div className="row">
                            <div className="col-sm-4">
                              <strong>Campaign JSON</strong>
                            </div>
                            <div className="col-sm-8">
                              <span>
                                <a
                                  href={campaignJsonOutputLink}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {constant.ctaToLink.campaignJSONLinkText}{' '}
                                  &nbsp;
                                  <i className="fas fa-external-link-alt"></i>
                                </a>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="cw-striped-profile-row">
                          <div className="row">
                            <div className="col-sm-4">
                              <strong>
                                Next steps other platforms (other than AC &amp;
                                AT)
                              </strong>
                            </div>
                            <div className="col-sm-8 text-break">
                              <span>
                                <a
                                  href={otherPlatformImplementationLink}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {
                                    constant.ctaToLink
                                      .nextStepOtherPlatformLinkText
                                  }{' '}
                                  &nbsp;
                                  <i className="fas fa-external-link-alt"></i>
                                </a>
                              </span>
                            </div>
                          </div>
                        </div>
                        {getcampaignStatus === 'Completed' ||
                        (isCampaignTypeAT && isPublished) ? (
                          <div>
                            <ReactTooltip
                              place="right"
                              id="infraStatusText"
                              type="info"
                              multiline={true}
                              className="cw-tooltip custom-cw-toolip"
                            />
                            {campaign &&
                            campaign.websiteType &&
                            campaign.websiteType.name !== 'Adobe Classic' &&
                            campaign.websiteType.name !== 'Standalone' &&
                            campaign.campaignDetail.campaignProductionUrl ? (
                              <div className="cw-striped-profile-row">
                                <div className="row">
                                  <div className="col-sm-4">
                                    <strong>Infra Status</strong>
                                  </div>
                                  <div className="col-sm-8 text-break">
                                    <span>
                                      {!isCampaignInfraEnabled ? (
                                        <span>
                                          <span style={{ color: 'red' }}>
                                            Disabled
                                          </span>
                                          <a
                                            href={constants.KANA_URL}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            &nbsp; Raise Kana &nbsp;
                                            <i className="fas fa-external-link-alt"></i>
                                          </a>
                                        </span>
                                      ) : (
                                        'Enabled'
                                      )}
                                      <span
                                        className="cw-icon cw-icon--help"
                                        data-for="infraStatusText"
                                        data-tip={
                                          isCampaignInfraEnabled
                                            ? isCampaignTypeSASS
                                              ? constants.SAAS_ENABLED_INFRA_STATUS_TOOLTIP_TEXT
                                              : constants.NONSAAS_ENABLED_INFRA_STATUS_TOOLTIP_TEXT
                                            : constants.DISABLED_INFRA_STATUS_TOOLTIP_TEXT
                                        }
                                      >
                                        &nbsp;
                                        <i className="fas fa-question-circle"></i>
                                        <ReactTooltip
                                          id={'refresh-status'}
                                          place="right"
                                          type="info"
                                          multiline={true}
                                          className="cw-tooltip custom-cw-toolip"
                                        />
                                        <img
                                          data-tip={'Refresh status'}
                                          style={{ cursor: 'pointer' }}
                                          data-for="refresh-status"
                                          src={refresh}
                                          onClick={this._handleRefresh}
                                          className="refresh-icon"
                                          alt="refresh"
                                          width={20}
                                        />
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ) : null}

                            {campaignDetails &&
                            campaignDetails.campaignInfraDetails &&
                            campaignDetails.campaignInfraDetails
                              .isCampaignInfraSetupDone ? (
                              <div>
                                <div className="cw-striped-profile-row">
                                  <div className="row">
                                    <div className="col-sm-4">
                                      <strong>Prod V3 API endpoint</strong>
                                    </div>
                                    <ReactTooltip
                                      place="right"
                                      id="infraV3ApiEndpoint"
                                      type="info"
                                      multiline={true}
                                      className="cw-tooltip custom-cw-toolip"
                                    />
                                    <div className="col-sm-8 text-break">
                                      <span>
                                        {campaignDetails &&
                                          campaignDetails.campaignInfraDetails &&
                                          campaignDetails.campaignInfraDetails
                                            .v3ApiEndpoint}
                                        <span
                                          className="cw-icon cw-icon--help"
                                          data-for="infraV3ApiEndpoint"
                                          data-tip={
                                            isCampaignTypeSASS
                                              ? constants.SAAS_V3_ENDPOINT_TOOLTIP_TEXT
                                              : constants.NONSAAS_V3_ENDPOINT_TOOLTIP_TEXT
                                          }
                                        >
                                          &nbsp;
                                          <i className="fas fa-question-circle"></i>
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="cw-striped-profile-row">
                                  <div className="row">
                                    <div className="col-sm-4">
                                      <strong>Prod V3 API key</strong>
                                    </div>
                                    <ReactTooltip
                                      place="right"
                                      id="infraV3ApiKey"
                                      type="info"
                                      multiline={true}
                                      className="cw-tooltip custom-cw-toolip"
                                    />
                                    <div className="col-sm-8 text-break">
                                      <span>
                                        {campaignDetails &&
                                          campaignDetails.campaignInfraDetails &&
                                          campaignDetails.campaignInfraDetails
                                            .xApiKey}
                                        <span
                                          className="cw-icon cw-icon--help"
                                          data-for="infraV3ApiKey"
                                          data-tip={
                                            isCampaignTypeSASS
                                              ? constants.SAAS_V3_API_KEY_TOOLTIP_TEXT
                                              : constants.NONSAAS_V3_API_KEY_TOOLTIP_TEXT
                                          }
                                        >
                                          &nbsp;
                                          <i className="fas fa-question-circle"></i>
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </>
                  ) : (
                    ''
                  )}
                  {isAEMTemplate && isPublished ? (
                    <>
                      <div className="col-sm-12" style={{ marginLeft: '-1%' }}>
                        <div className="cw-campaign--review-section mb-30">
                          <div className="mb-20">
                            <h3 className="cw-heading--primary">
                              Form Summary
                            </h3>
                          </div>
                          <div className="cw-campaign--review-section-content">
                            <div className={`cw-striped-profile-row`}>
                              <div className="row">
                                <div className="col-sm-4">
                                  <strong>AEM form</strong>
                                </div>
                                <div className="col-sm-8 text-break">
                                  {campaign &&
                                  campaign.authorPages &&
                                  campaign.authorPages.campaignAuthorPage ? (
                                    <span>
                                      <a
                                        href={
                                          campaign.authorPages
                                            .campaignAuthorPage
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {
                                          constant.ctaToLink
                                            .aemCampaignFormLinkText
                                        }{' '}
                                        &nbsp;
                                        <i className="fas fa-external-link-alt"></i>
                                      </a>
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="cw-striped-profile-row">
                              <div className="row">
                                <div className="col-sm-4">
                                  <strong>AEM 404</strong>
                                </div>
                                <div className="col-sm-8 text-break">
                                  {campaign &&
                                  campaign.authorPages &&
                                  campaign.authorPages.campaignErrorPage &&
                                  (campaign.authorPages.campaignErrorPage.includes(
                                    'http'
                                  ) ||
                                    campaign.authorPages.campaignErrorPage.includes(
                                      'https'
                                    )) ? (
                                    <a
                                      href={
                                        campaign.authorPages.campaignErrorPage
                                      }
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {constant.ctaToLink.aem404LinkText} &nbsp;
                                      <i className="fas fa-external-link-alt"></i>
                                    </a>
                                  ) : (
                                    campaign.authorPages &&
                                    campaign.authorPages.campaignErrorPage
                                  )}
                                </div>
                              </div>
                            </div>

                            <div
                              className={`cw-striped-profile-row ${
                                hasInstantWinLose ? '' : 'd-none'
                              }`}
                            >
                              <div className="row">
                                <div className="col-sm-4">
                                  <strong>Instant Win Page</strong>
                                </div>
                                <div className="col-sm-8 text-break">
                                  {campaign &&
                                  campaign.authorPages &&
                                  campaign.authorPages
                                    .campaigninstantWinPage ? (
                                    <>
                                      <a
                                        href={
                                          campaign.authorPages
                                            .campaigninstantWinPage
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {
                                          constant.ctaToLink
                                            .instantWinPageLinkText
                                        }{' '}
                                        &nbsp;
                                        <i className="fas fa-external-link-alt"></i>
                                      </a>
                                    </>
                                  ) : (
                                    ''
                                  )}
                                </div>
                              </div>
                            </div>
                            <div
                              className={`cw-striped-profile-row ${
                                hasInstantWinLose ? '' : 'd-none'
                              }`}
                            >
                              <div className="row">
                                <div className="col-sm-4">
                                  <strong>Instant Lose Page</strong>
                                </div>
                                <div className="col-sm-8 text-break">
                                  {campaign &&
                                  campaign.authorPages &&
                                  campaign.authorPages
                                    .campaigninstantLosePage ? (
                                    <>
                                      <a
                                        href={
                                          campaign.authorPages
                                            .campaigninstantLosePage
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {
                                          constant.ctaToLink
                                            .instantLosePageLinkText
                                        }{' '}
                                        &nbsp;
                                        <i className="fas fa-external-link-alt"></i>
                                      </a>
                                    </>
                                  ) : (
                                    ''
                                  )}
                                </div>
                              </div>
                            </div>
                            <div
                              className={`cw-striped-profile-row ${
                                campaign &&
                                campaign.authorPages &&
                                campaign.authorPages.campaignResultPage &&
                                !hasInstantWinLose
                                  ? ''
                                  : 'd-none'
                              }`}
                            >
                              <div className="row">
                                <div className="col-sm-4">
                                  <strong> Thank you page</strong>
                                </div>
                                <div className="col-sm-8 text-break">
                                  {campaign &&
                                  campaign.authorPages &&
                                  campaign.authorPages.campaignResultPage ? (
                                    <>
                                      <a
                                        href={
                                          campaign.authorPages
                                            .campaignResultPage
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {constant.ctaToLink.thankYouLinkText}{' '}
                                        &nbsp;
                                        <i className="fas fa-external-link-alt"></i>
                                      </a>
                                    </>
                                  ) : (
                                    ''
                                  )}
                                </div>
                              </div>
                            </div>
                            <div
                              className={`cw-striped-profile-row ${
                                campaign && campaign.prefCentrePage
                                  ? ''
                                  : 'd-none'
                              }`}
                            >
                              <div className="row">
                                <div className="col-sm-4">
                                  <strong>Preference center</strong>
                                </div>
                                <div className="col-sm-8 text-break">
                                  {campaign && campaign.prefCentrePage ? (
                                    <>
                                      <a
                                        href={campaign.prefCentrePage}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {campaign.prefCentrePage}{' '}
                                        <i className="fas fa-external-link-alt"></i>
                                      </a>
                                    </>
                                  ) : (
                                    ''
                                  )}
                                </div>
                              </div>
                            </div>

                            <div
                              className={`cw-striped-profile-row ${
                                campaign &&
                                campaign.campaignPublishPage &&
                                campaign.websiteType &&
                                campaign.websiteType.name.toLowerCase() ===
                                  'adobe classic'
                                  ? ''
                                  : 'd-none'
                              }`}
                            >
                              <div className="row">
                                <div className="col-sm-4">
                                  <strong> Publish campaign URL</strong>
                                </div>
                                <div className="col-sm-8 text-break">
                                  {campaign && campaign.campaignPublishPage ? (
                                    <>
                                      <a
                                        href={campaign.campaignPublishPage}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {campaign.campaignPublishPage}
                                        <i className="fas fa-external-link-alt"></i>
                                      </a>
                                    </>
                                  ) : (
                                    ''
                                  )}
                                </div>
                              </div>
                            </div>
                            <div
                              className={`cw-striped-profile-row ${
                                campaign &&
                                campaign.publishPassword &&
                                campaign.websiteType &&
                                campaign.websiteType.name.toLowerCase() ===
                                  'adobe classic'
                                  ? ''
                                  : 'd-none'
                              }`}
                            >
                              <div className="row">
                                <div className="col-sm-4">
                                  <strong>
                                    Password for published campaign
                                  </strong>
                                </div>
                                <div className="col-sm-8 text-break">
                                  {campaign && campaign.publishPassword ? (
                                    <>
                                      <a
                                        href={campaign.publishPassword}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {campaign.publishPassword}
                                        <i className="fas fa-external-link-alt"></i>
                                      </a>
                                    </>
                                  ) : (
                                    ''
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="cw-striped-profile-row">
                              <div className="row">
                                <div className="col-sm-4">
                                  <strong>Campaign JSON</strong>
                                </div>
                                <div className="col-sm-8">
                                  <span>
                                    <a
                                      href={campaignJsonOutputLink}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {constant.ctaToLink.campaignJSONLinkText}{' '}
                                      &nbsp;
                                      <i className="fas fa-external-link-alt"></i>
                                    </a>
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="cw-striped-profile-row">
                              <div className="row">
                                <div className="col-sm-4">
                                  <strong> Next steps</strong>
                                </div>
                                <div className="col-sm-8 text-break">
                                  <span>
                                    <a
                                      href={adobeClassicImplementationLink}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {constant.ctaToLink.ACNextStepsLinkText}{' '}
                                      &nbsp;
                                      <i className="fas fa-external-link-alt"></i>
                                    </a>
                                    {/* </div>
                            </div>
                          </div>
                          <div className="cw-striped-row">
                            <div className="row">
                              <div className="col-sm-4">
                                <strong>
                                Next steps Adobe Template
                                </strong>
                              </div>
                              <div className="col-sm-8 text-break"> */}
                                    , &nbsp;
                                    <a
                                      href={adobeTemplatedImplementationLink}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {constant.ctaToLink.ATNextStepsLinkText}{' '}
                                      &nbsp;
                                      <i className="fas fa-external-link-alt"></i>
                                    </a>
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div>
                              <ReactTooltip
                                place="right"
                                type="info"
                                multiline={true}
                                className={'cw-tooltip custom-cw-toolip'}
                              />
                              {isCampaignTypeAT &&
                              campaign.campaignDetail.campaignProductionUrl ? (
                                <div>
                                  <div className="cw-striped-profile-row">
                                    <div className="row">
                                      <div className="col-sm-4">
                                        <strong>Infra Status</strong>
                                      </div>
                                      <div className="col-sm-8 text-break">
                                        <span>
                                          <span>
                                            {!isCampaignInfraEnabled ? (
                                              <span>
                                                <span style={{ color: 'red' }}>
                                                  Disabled
                                                </span>
                                                <a
                                                  href={constants.KANA_URL}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                >
                                                  &nbsp; Raise Kana &nbsp;
                                                  <i className="fas fa-external-link-alt"></i>
                                                </a>
                                              </span>
                                            ) : (
                                              <span> Enabled</span>
                                            )}
                                          </span>
                                          <span className="cw-icon cw-icon--help">
                                            &nbsp;
                                            <i
                                              data-tip={
                                                isCampaignInfraEnabled
                                                  ? constants.NONSAAS_ENABLED_INFRA_STATUS_TOOLTIP_TEXT
                                                  : constants.DISABLED_INFRA_STATUS_TOOLTIP_TEXT
                                              }
                                              data-for="refresh-help-text"
                                              className="fas fa-question-circle"
                                            ></i>
                                            <ReactTooltip
                                              id={'refresh-help-text'}
                                              place="right"
                                              type="info"
                                              multiline={true}
                                              className="cw-tooltip custom-cw-toolip"
                                            />
                                            <ReactTooltip
                                              id={'refresh-status'}
                                              place="right"
                                              type="info"
                                              multiline={true}
                                              className="cw-tooltip custom-cw-toolip"
                                            />
                                            <img
                                              data-tip={'Refresh status'}
                                              style={{ cursor: 'pointer' }}
                                              data-for="refresh-status"
                                              src={refresh}
                                              onClick={this._handleRefresh}
                                              className="refresh-icon"
                                              alt="refresh"
                                              width={20}
                                            />
                                          </span>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  {campaignDetails &&
                                  campaignDetails.campaignInfraDetails &&
                                  campaignDetails.campaignInfraDetails
                                    .isCampaignInfraSetupDone ? (
                                    <div>
                                      <div className="cw-striped-profile-row">
                                        <div className="row">
                                          <div className="col-sm-4">
                                            <strong>
                                              Prod V3 API endpoint
                                            </strong>
                                          </div>
                                          <div className="col-sm-8 text-break">
                                            <span>
                                              {campaignDetails &&
                                                campaignDetails.campaignInfraDetails &&
                                                campaignDetails
                                                  .campaignInfraDetails
                                                  .v3ApiEndpoint}
                                              <span
                                                className="cw-icon cw-icon--help"
                                                data-tip={
                                                  isCampaignTypeSASS
                                                    ? constants.SAAS_V3_ENDPOINT_TOOLTIP_TEXT
                                                    : constants.NONSAAS_V3_ENDPOINT_TOOLTIP_TEXT
                                                }
                                              >
                                                &nbsp;
                                                <i className="fas fa-question-circle"></i>
                                              </span>
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="cw-striped-profile-row">
                                        <div className="row">
                                          <div className="col-sm-4">
                                            <strong>Prod V3 API key</strong>
                                          </div>
                                          <div className="col-sm-8 text-break">
                                            <span>
                                              {campaignDetails &&
                                                campaignDetails.campaignInfraDetails &&
                                                campaignDetails
                                                  .campaignInfraDetails.xApiKey}
                                              <span
                                                className="cw-icon cw-icon--help"
                                                data-tip={
                                                  isCampaignTypeSASS
                                                    ? constants.SAAS_V3_API_KEY_TOOLTIP_TEXT
                                                    : constants.NONSAAS_V3_API_KEY_TOOLTIP_TEXT
                                                }
                                              >
                                                &nbsp;
                                                <i className="fas fa-question-circle"></i>
                                              </span>
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              ) : null}
                            </div>

                            <div
                              className={`cw-striped-profile-row ${
                                campaign &&
                                campaign.publishPassword &&
                                campaign.websiteType &&
                                campaign.websiteType.name.toLowerCase() ===
                                  'adobe classic'
                                  ? ''
                                  : 'd-none'
                              }`}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}
                  <CampaignDetailsInfo
                    isCampaignTypeSASS={isCampaignTypeSASS}
                    previewUrl={this.props.previewUrl}
                    cwStatus={cwStatus}
                    campaign={campaign}
                    campaignDetails={campaignDetails}
                    panelref={this.collapsePanel}
                    collapseHandlerIconref={this.collapseHandlerIcon}
                    collapseHandlerTextref={this.collapseHandlerText}
                    isConsentAndCompliance={true}
                    isCampaignType={false}
                    isCampaignAuthoring={true}
                    isOverviewPage={true}
                    handleRefresh={this._handleRefresh}
                    campaignConfig={this.props.campaignConfig}
                  />
                </>
              )}

              {isCampaignTypeSASS &&
              constant.featureFlags.ENABLE_SINGLE_PAGE_FIELD_SELECTION ? (
                <FormAuthoringGrouped
                  ishideQnA={
                    campaign.type !== 'Sign-up with Questions & Answers'
                  }
                  isEditButton={false}
                  campaignId={this.props.match.params.id}
                  isOverviewPage={true}
                  showMasterIcon={false}
                  colWidth={`col-sm-3`}
                  accordianClass={`col-sm-9`}
                  newIconClass={`col-sm-2`}
                  isMasterData={campaign && campaign.isMasterData}
                  campaignConfig={this.props.campaignConfig}
                ></FormAuthoringGrouped>
              ) : null}
              {isCampaignTypeSASS &&
              !constant.featureFlags.ENABLE_SINGLE_PAGE_FIELD_SELECTION ? (
                <FormAuthoring
                  ishideQnA={
                    campaign.type !== 'Sign-up with Questions & Answers'
                  }
                  isEditButton={false}
                  campaignId={this.props.match.params.id}
                  isOverviewPage={true}
                  showMasterIcon={false}
                  colWidth={`col-sm-3`}
                  accordianClass={`col-sm-9`}
                  newIconClass={`col-sm-2`}
                ></FormAuthoring>
              ) : null}

              {!isCampaignIncentive && !isCampaignTypeSASS ? (
                <RegularCommunications
                  campaign={campaign}
                  campaignDetails={campaignDetails}
                  mainDivClass="col-sm-8"
                  colLabelClass="col-sm-4"
                  colValClass="col-sm-8"
                  disabledFlag={false}
                  regularCommunicationLabel="Regular Communication"
                  draftFlag={false}
                  optins={this.props.optins}
                  showComponent={cwStatus === 'Draft'}
                  optinsDescription={this.state.optinsDescription}
                ></RegularCommunications>
              ) : null}

              {cwStatus !== 'Draft' && !isEpsilonMarket ? (
                <div className="cw-campaign--review-section mb-40">
                  <div className="cw-campaign--review-section mb-30">
                    <div className="mb-20">
                      <h3 className="cw-heading--primary">Master Data IDs </h3>
                    </div>
                    <div
                      className={`cw-campaign--review-section-content ${
                        _.isEmpty(campaignId) && draftFlag ? 'd-none' : ''
                      }`}
                    >
                      {campaign && campaign.isMasterData && (
                        <div className="cw-striped-profile-row">
                          <div className="row">
                            <div className="col-sm-3">
                              <strong> Brand code </strong>
                            </div>
                            <div className="col-sm-4"></div>
                            <div className="col-sm-5">
                              {campaign.brand && campaign.brand.code
                                ? campaign.brand.code
                                : ''}
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="cw-striped-profile-row">
                        <div className="row">
                          <div className="col-sm-3">
                            {constant.featureFlags
                              .ENABLE_CAMPAIGN_ID_GOVERNANCE ? (
                              <strong>{`${this.props.campaignConfig.LABEL_SIGN_UP_ID}`}</strong>
                            ) : (
                              <strong> Campaign ID </strong>
                            )}
                          </div>
                          <div
                            className={`col-sm-${
                              isCampaignTypeSASS ? '4' : '5'
                            }`}
                          ></div>
                          <div className="col-sm-4">
                            <div>
                              <span>
                                {campaign.campaignId
                                  ? campaign.campaignId
                                  : cwStatus !== 'Cancelled'
                                  ? 'Awaiting'
                                  : ''}{' '}
                              </span>
                              {campaign.campaignDetail &&
                              campaign.campaignDetail.isCustomTrackingId ? (
                                <span>
                                  (Existing ID provided by requestor) &nbsp;
                                </span>
                              ) : null}

                              <ReactTooltip
                                id="acquisitionIdToolTipOverView"
                                place="top"
                                type="info"
                                multiline={true}
                                className="cw-toolip custom-cw-toolip"
                              />
                              <span
                                className="cw-icon cw-icon--help"
                                data-tip={`Campaign IDs are now known as ${this.props.campaignConfig.LABEL_SIGN_UP_ID}s to better explain the purpose of the field to uniquely identify the  initiative through which a consumer signed up`}
                                currentitem="false"
                                data-for="acquisitionIdToolTipOverView"
                              >
                                <i className="fas fa-question-circle"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      {isCampaignIncentive && isCampaignTypeSASS && (
                        <div className="cw-striped-profile-row">
                          <div className="row">
                            <div className="col-sm-3">
                              <strong> Promotigo Form ID </strong>
                            </div>
                            <div className="col-sm-4"></div>
                            <div className="col-sm-5">
                              {campaign.campaignDetail &&
                              campaign.campaignDetail.promotigoFormId
                                ? campaign.campaignDetail &&
                                  campaign.campaignDetail.promotigoFormId
                                : 'Awaiting'}
                            </div>
                          </div>
                        </div>
                      )}
                      {/*Optins */}
                      {!isCampaignTypeSASS ||
                      (isCampaignTypeSASS &&
                        (this._isConsentsValid(campaignDetails.consents) ||
                          (campaign.type === constant.CAMPAIGN_QA_TYPE &&
                            campaignDetails.fields.filter(
                              elem => elem.dataType === 'questionAnswerPair'
                            ).length))) ? (
                        <div>
                          <div
                            className="collapse show"
                            id="MasterIDCollapsed"
                            ref={this.collapseMasterDataPanel}
                          >
                            <div className="cw-campaign--review-section">
                              {!isCampaignTypeSASS ||
                              (isCampaignTypeSASS &&
                                this._isConsentsValid(
                                  campaignDetails.consents
                                )) ? (
                                <>
                                  {!isCampaignTypeSASS &&
                                  cwStatus != 'Draft' &&
                                  this.props.optins.length ? (
                                    <>
                                      <div className="cw-campaign--review-section-content">
                                        {this.props.optins.map(
                                          (optins, optinindex) => {
                                            return (
                                              <div
                                                className="cw-striped-profile-row"
                                                key={optinindex}
                                              >
                                                <div className="row">
                                                  <div className="col-sm-3">
                                                    <strong>
                                                      {optins.text ===
                                                      'Brand_SMS'
                                                        ? 'Brand (SMS)'
                                                        : optins.text ===
                                                          'Brand'
                                                        ? 'Brand (email)'
                                                        : optins.text}
                                                    </strong>
                                                  </div>
                                                  <div className="col-sm-5">
                                                    <p>
                                                      {
                                                        this.optinsDescription[
                                                          optins.text
                                                        ]
                                                      }
                                                    </p>
                                                  </div>
                                                  <div className="col-sm-3">
                                                    {optins.id
                                                      ? optins.id
                                                      : cwStatus !== 'Cancelled'
                                                      ? 'Awaiting'
                                                      : ''}
                                                  </div>
                                                </div>
                                              </div>
                                            );
                                          }
                                        )}
                                      </div>
                                      {!isCampaignIncentive &&
                                      campaignDetails.additionalOptinList &&
                                      campaignDetails.additionalOptinList
                                        .length > 0 ? (
                                        <div className="cw-campaign--review-section-content">
                                          {campaignDetails.additionalOptinList &&
                                          campaignDetails.additionalOptinList
                                            .constructor === Object ? (
                                            <Optins
                                              optins={
                                                campaignDetails.additionalOptinList
                                              }
                                              disabledFlag={disabledFlag}
                                              // colValClass="col-sm-4"
                                              hideInputFields="true"
                                              draftFlag={draftFlag}
                                              showFrequency={false}
                                            ></Optins>
                                          ) : campaignDetails.additionalOptinList &&
                                            campaignDetails.additionalOptinList
                                              .constructor === Array ? (
                                            campaignDetails.additionalOptinList.map(
                                              (detail, detailIndex) => {
                                                return (
                                                  <Optins
                                                    key={detailIndex}
                                                    regularCommunicationLabel="Regular Communication"
                                                    index={detailIndex}
                                                    //  colValClass="col-sm-4"
                                                    hideInputFields="true"
                                                    optins={detail}
                                                    disabledFlag={disabledFlag}
                                                    draftFlag={draftFlag}
                                                    showFrequency={false}
                                                  ></Optins>
                                                );
                                              }
                                            )
                                          ) : null}
                                        </div>
                                      ) : null}
                                    </>
                                  ) : (
                                    // <div className="cw-campaign--review-section-content">
                                    //   {consentsHtml(
                                    //     this.props.campaignDetails.consents
                                    //   )}
                                    // </div>

                                    <div className="cw-campaign--review-section-content">
                                      {consentsHtml(
                                        this.props.campaignDetails.consents
                                      )}
                                      {consentsSubscriptionHtml(
                                        this.props.campaignDetails.consents
                                      ) &&
                                      consentsSubscriptionHtml(
                                        this.props.campaignDetails.consents
                                      ).length > 0 ? (
                                        <div
                                          className="cw-striped-profile-row"
                                          style={{ padding: '8px 4px' }}
                                        >
                                          <div className="row">
                                            <div className="col-sm-4">
                                              <strong
                                                style={{ paddingLeft: '6px' }}
                                              >
                                                Additional Subscriptions
                                              </strong>
                                            </div>
                                          </div>
                                          {consentsSubscriptionHtml(
                                            this.props.campaignDetails.consents
                                          )}
                                        </div>
                                      ) : null}
                                      <div
                                        className="cw-striped-profile-row"
                                        style={{ padding: '8px 4px' }}
                                      ></div>
                                    </div>
                                  )}
                                </>
                              ) : null}
                              {/*campaign.type ===
                              "Sign-up with Questions & Answers" &&
                              campaignDetails.fields.filter(
                                (elem) => elem.dataType === "questionAnswerPair"
                              ).length ? (
                              <>
                                <div className="mb-20">
                                  <p className="cw-heading--saascampaign mt-20">
                                    Question and Answers
                                  </p>
                                </div>
                                <div className="cw-campaign--review-section-content">
                                  {qnaHtml(
                                    campaignDetails.fields,
                                    isCampaignNonEnglish,
                                    campaignLanguage
                                  )}
                                </div>
                              </>
                            ) : null*/}
                            </div>
                          </div>
                          {/*cwStatus !== "Draft" ? (
                          <div className="cw-campaign--review-section mb-30">
                            <a
                              className="collapse-h pt-2"
                              href="javascript:void(0)"
                              role="button"
                              aria-expanded="false"
                              aria-controls="MasterIDCollapsed"
                              onClick={this._handleCollapse}
                            >
                              <span ref={this.collapseMasterDataHandlerText}>
                                View more Master Data IDs
                              </span>{" "}
                              <i
                                className="fas fa-chevron-down ml-1"
                                ref={this.collapseMasterDataHandlerIcon}
                              ></i>
                            </a>
                          </div>
                        ) : null*/}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              ) : null}
              {/* <QuestionAnswerList
              status={cwStatus}
              qnaList={questionAnswerList}
              showQnaList={
                questionAnswerList.length &&
                campaign.type === "Sign-up with Questions & Answers" &&
                !isCampaignTypeSASS
              }
              isCampaignNonEnglish={isCampaignNonEnglish}
              campaignLanguage={campaignLanguage}
              isPromotigoType={isPromotigoType}
            /> */}
              <QnAList
                campaignLanguage={campaignLanguage}
                handleQuestionsAnswers={this._handleQuestionsAnswers}
                isCampaignNonEnglish={isCampaignNonEnglish}
                campaignId={campaignId}
                qnaList={questionAnswerList}
                showQnaList={
                  campaign.type === 'Sign-up with Questions & Answers' &&
                  !isCampaignTypeSASS
                }
                isPromotigoType={isPromotigoType}
                colWidth={`col-sm-3`}
                showAwaitingQid={true}
                isEditButtonShow={false}
                showTextBox={false}
                status={cwStatus}
                isHeadingQnAs={true}
                isMdOverviewScreen={true}
                accordianClass={`col-sm-9`}
                newIconClass={`col-sm-2`}
                isOtherLang={true}
                campaign={campaign}
                campaignDetails={campaignDetails}
              />

              {/* Additional Information */}
              {cwStatus === 'Draft' &&
              !isCampaignTypeSASS &&
              campaignDetails &&
              campaignDetails.additionalInfo ? (
                <div className="cw-campaign--review-section mb-30">
                  <div className="mb-20">
                    <h3 className="cw-heading--primary">
                      Additonal information
                    </h3>
                  </div>
                  <div className="cw-campaign--review-section-content">
                    <div className="cw-striped-row">
                      <div className="row">
                        <div className="col-sm-4">
                          <strong>Additonal information</strong>
                        </div>
                        <div className="col-sm-8">
                          {campaignDetails && campaignDetails.additionalInfo}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {!isCampaignTypeSASS ? (
                cwStatus !== 'Draft' &&
                !showGigyaId &&
                campaignDetails.profileStore &&
                campaignDetails.profileStore.constructor === Object ? (
                  <ProfileStore
                    campaign={campaign}
                    campaignDetails={campaignDetails}
                    disabledFlag={disabledFlag}
                    colValClass="col-sm-4"
                    hideInputFields="true"
                    draftFlag={draftFlag}
                    status={cwStatus}
                    showkanaMsg={true}
                  ></ProfileStore>
                ) : cwStatus !== 'Draft' &&
                  !showGigyaId &&
                  campaignDetails.profileStore &&
                  campaignDetails.profileStore.constructor === Array ? (
                  campaignDetails.map((detail, detailIndex) => {
                    return (
                      <ProfileStore
                        key={detailIndex}
                        campaign={campaign}
                        campaignDetails={detail}
                        colValClass="col-sm-4"
                        hideInputFields="true"
                        disabledFlag={disabledFlag}
                        draftFlag={draftFlag}
                        status={cwStatus}
                        showkanaMsg={true}
                      ></ProfileStore>
                    );
                  })
                ) : null
              ) : null}
              {cwStatus !== 'Draft' &&
              showGigyaId &&
              !isAEMTemplate &&
              campaign &&
              !isCampaignTypeSASS &&
              campaign.campaignType !== 'Incentive' &&
              isGigyakey ? (
                <GigyaProfileStore
                  campaign={campaign}
                  hideInputFields="true"
                  // colValClass="col-sm-4"
                  campaignDetails={campaignDetails}
                  disabledFlag={disabledFlag}
                  draftFlag={draftFlag}
                  status={cwStatus}
                ></GigyaProfileStore>
              ) : null}

              {/* Added with isClone Conditional rendering of Gigya & Master data - Begins */}

              {isCampaignTypeSASS && campaign && !campaign.isMasterData ? (
                <EditPermissionsSection data={campaignDetails} />
              ) : null}
              <Modal
                isOpen={
                  this.state.modalIsOpen && this.state.activeModal === 'delete'
                }
                onRequestClose={this._closeModal}
                // style={customStyles}
                className="cw-modal"
                contentLabel="Confirm Delete"
                style={{
                  overlay: {
                    backgroundColor: constants.EMAIL_OVERLAY_BACKGROUND
                  }
                }}
              >
                <button
                  className="cw-modal--close"
                  title="Close"
                  onClick={this._closeModal}
                >
                  <i className="fas fa-times"></i>
                </button>
                <h3 className="cw-heading--primary mb-10">Confirm Delete</h3>
                <p className="mb-20">
                  Confirm you want to delete this campaign. All data will be
                  lost.
                </p>
                <div>
                  <button
                    type="submit"
                    onClick={this._closeModal}
                    className="btn btn-outline-primary mr-10"
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-primary"
                    onClick={this._saveOverviewDetail.bind(
                      this,
                      campaign.uid,
                      campaign.name
                    )}
                  >
                    Confirm
                  </button>
                </div>
              </Modal>
              <Modal
                isOpen={
                  this.state.modalIsOpen && this.state.activeModal === 'cancel'
                }
                onRequestClose={this._closeModal}
                // style={customStyles}
                className="cw-modal cw-modal--cancel"
                contentLabel="Confirm cancellation"
                style={{
                  overlay: {
                    backgroundColor: constants.EMAIL_OVERLAY_BACKGROUND
                  }
                }}
              >
                <button
                  className="cw-modal--close"
                  title="Close"
                  onClick={this._closeModal}
                >
                  <i className="fas fa-times"></i>
                </button>
                <h3 className="cw-heading--primary mb-10">
                  Confirm cancellation
                </h3>
                <p className="mb-20">
                  You won't be able to restart this campaign once it has been
                  cancelled.
                </p>
                <div>
                  <button
                    type="submit"
                    onClick={this._closeModal}
                    className="btn btn-outline-primary mr-10"
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-primary"
                    onClick={this._cancelCampaign.bind(this, campaign.uid)}
                  >
                    Confirm
                  </button>
                </div>
              </Modal>
              <ModalWindow
                className="cw-modal cw-modal--cancel"
                isOpen={
                  this.state.modalIsOpen &&
                  this.state.activeModal === 'editCampaign'
                }
                onRequestClose={this._closeModal}
                heading={'Warning'}
                description={
                  "By editing this campaign a new form will be created. When you're ready please ensure you deactivate and remove the old form in AEM."
                }
              >
                <div className="d-flex justify-content-between align-items-center">
                  <button
                    type="submit"
                    onClick={this._closeModal}
                    className="btn btn-outline-primary mr-10"
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-primary"
                    onClick={this._editCampaign}
                  >
                    Confirm
                  </button>
                </div>
              </ModalWindow>
              <Modal
                isOpen={
                  this.state.modalIsOpen &&
                  this.state.activeModal === 'restrictCloneAem'
                }
                onRequestClose={this._closeModal}
                // style={customStyles}
                className="cw-modal cw-modal--cancel"
                contentLabel="Clone functionality is not available"
                style={{
                  overlay: {
                    backgroundColor: constants.EMAIL_OVERLAY_BACKGROUND
                  }
                }}
              >
                <button
                  className="cw-modal--close"
                  title="Close"
                  onClick={this._closeModal}
                >
                  <i className="fas fa-times"></i>
                </button>
                <h3 className="cw-heading--primary mb-10">
                  Clone functionality is not available
                </h3>
                <p className="mb-20">
                  This is a campaign with an AEM (Adobe Experience Manager)
                  form, which are no longer supported to be created via Campaign
                  Wizard. Please use the new form widgets as this is the revised
                  easy to use global solution for sign up
                </p>
              </Modal>
              <Modal
                isOpen={
                  this.state.modalIsOpen &&
                  this.state.activeModal === 'unBundledRestrict'
                }
                onRequestClose={this._closeModal}
                // style={customStyles}
                className="cw-modal cw-modal--cancel"
                contentLabel="Clone functionality is not available"
                style={{
                  overlay: {
                    backgroundColor: constants.EMAIL_OVERLAY_BACKGROUND
                  }
                }}
              >
                <button
                  className="cw-modal--close"
                  title="Close"
                  onClick={this._closeModal}
                >
                  <i className="fas fa-times"></i>
                </button>
                <p className="mb-20">
                  You cannot edit the form as the consent configuration for this
                  market have changed. Please create a new form
                </p>
              </Modal>
            </div>
          </div>
          <div className="col-10 offset-md-1">
            <div className="overview-page-history">
              {this.props.historyLogs.length ? (
                <HistoryComponent historyLogs={this.props.historyLogs} />
              ) : null}
            </div>
          </div>
        </>
      );
    }
  }
}
const mapStateToProps = state => ({
  campaign: state.overview.campaign,
  campaignDetails: state.overview.campaignDetails,
  questionAnswerList: state.overview.questionAnswerList,
  campaignStatus: state.overview.campaign.status,
  campaignId: state.overview.campaign.uid,
  serviceError: state.overview.serviceError,
  serviceErrorStatus: state.overview.serviceErrorStatus,
  apiConnectionDetails: state.overview.campaignDetails
    ? state.overview.campaignDetails.profileStore
    : '',
  asyncInProgress: state.overview.asyncInProgress
    ? state.overview.asyncInProgress
    : '',
  optins: state.overview.optins,
  cancelled: state.overview.cancelled,
  historyLogs: state.historyLogs,
  campaignAccessEmails: state.overview.campaignAccessEmails,
  isClone: state.overview.isClone,
  previewUrl: state.preview.previewUrl,
  isLoading: state.preview.isLoading ? state.preview.isLoading : '',
  campaignConfig: state.app.campaignConfig
});
export default connect(mapStateToProps, {
  fetchCampaignData,
  deleteCampaign,
  setDeletedCampaign,
  cancelCampaign,
  fetchHistoryLogs,
  setCampaignToEdit,
  cloneCampaign,
  setNotificationMessage,
  saasCampaignToEdit,
  openFeedbackForm
})(Overview);
