import axios from 'axios';
import {
  SERVICE_ERROR_DASHBOARD,
  FETCHING_SMS_CAMPAIGNS,
  GET_SMS_CAMPAIGNS_LIST
} from '../../actionTypes';
import constant from '../../constants';
import _ from 'lodash';

export function fetchSMSCampaignsList(
  page = 1,
  perPage = 100,
  sortKey = 'lastUpdatedDate',
  sortBy = 'desc',
  body = {}
) {
  return function(dispatch) {
    dispatch({
      type: FETCHING_SMS_CAMPAIGNS,
      showLoader: true
    });
    axios({
      method: 'POST',
      url:
        constant.communications.SMS_CAMPAIGN_LIST_SERVICE_URL +
        'list' +
        '?page=' +
        page +
        '&perPage=' +
        perPage +
        '&sortKey=' +
        sortKey +
        '&sortBy=' +
        sortBy,
      data: body,
      disableLastUpdatedScreen: true
    })
      .then(response => {
        let campaigns = response.data.campaigns;
        let totalCount = response.data.totalrecords;
        let pageNumber = response.data.page;
        let recordShowStart = response.data.recordShowStart;
        let recordShowto = response.data.recordShowto;
        dispatch({
          type: GET_SMS_CAMPAIGNS_LIST,
          campaigns,
          totalCount,
          pageNumber,
          recordShowStart,
          recordShowto
        });
      })
      .catch(error => {
        dispatch({ type: SERVICE_ERROR_DASHBOARD, error });
      });
  };
}
