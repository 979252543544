import React from 'react';

export const TestsInProgressBody = () => (
  <div className="text-center runtest--init">
    <div
      className="spinner-border cw-color--primary runtest--loader"
      role="status"
    >
      <span className="sr-only">Loading...</span>
    </div>
    <div className="runtest--initmsg">
      <div className="runtest--init-msg1">Testing initiated</div>
      <div className="runtest--init-msg2">
        Sit tight! We are checking if your email has errors
      </div>
    </div>
  </div>
);

export const TestsInProgressActions = ({ closeModal }) => (
  <div className="d-flex justify-content-center mb-10">
    <button
      className="btn btn btn btn-primary"
      onClick={() => closeModal(false)}
    >
      Close
    </button>
  </div>
);
