import React, { useState } from "react";

import validIcon from 'images/valid.svg';

export const EditInput = ({
    defaultValue,
    itemId,
    editFn,
    updateItem,
    selectedLanguage,
  }) => {
    const [value, setValue] = useState(defaultValue);
    const [submitted, setSubmitted] = useState(false)
  
    return (
      <div className="d-flex align-items-center">
        <div className={`w-100 ${submitted && !value ? 'mt-40' : ''}`}>
          <input
            className={`form-control ${submitted && !value ? 'cw-error-focus' : ''}`}
            value={value}
            maxLength={100}
            onChange={e => setValue(e.target.value)}
          />
          {submitted && !value && (
            <p className="error-text my-2">Please enter opt-out text</p>
          )}
        </div>
        <button
          className="inline-actions btn position-relative pr-2 pl-2"
          onClick={() => {
            setSubmitted(true);
            if (value && selectedLanguage?.value) {
              updateItem(value, selectedLanguage);
            }
          }}
        >
          <img
            style={{ width: 20 }}
            src={validIcon}
            alt={'Valid'}
            aria-label="Save"
          />
        </button>
        <button
          className="inline-actions btn position-relative pr-2 pl-0"
          onClick={() => {
            editFn(null);
          }}
        >
          <span
            className={'saas-accordion-close mt-1'}
            style={{ position: 'static' }}
            aria-label="Cancel"
          ></span>
        </button>
      </div>
    );
  };