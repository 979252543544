import React from 'react';

export default function Layout({ children, sidebar, className = 'mt-50' }) {
  return (
    <div className={`d-flex ${className}`}>
      <div
        style={{
          flex: 1,
          marginRight: 40,
          minWidth: 0,
          flexDirection: 'column',
          display: 'flex'
        }}
      >
        {children}
      </div>
      {sidebar}
    </div>
  );
}
