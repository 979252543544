import React, { forwardRef } from 'react';
import Select from 'react-select';
export const SelectDropDown = forwardRef(
  ({ className, name, label, id, options, onChange, value, ...props }, ref) => {
    return (
      <Select
        className={className || ''}
        name={name || ''}
        label={label || ''}
        id={id || ''}
        options={options || ''}
        onChange={onChange || ''}
        value={value || ''}
        {...props}
      />
    );
  }
);
