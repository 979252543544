import React, { Component } from 'react';
import RadioDropDown from './RadioDropDown';
import {
  getDateRange,
  getCampaignStatusText,
  getFieldValue
} from '../selectors/index';
import axios from 'axios';
import * as XLSX from 'xlsx';
import moment from 'moment';
import fileDownload from 'js-file-download';
import constant from '../constants';
import Modal from 'react-modal';
import ReportDashboard from './ReportDashboard';
import { ReportsInformationModal } from './ReportsInformationModal'

const downloadFile = url => {
  axios({ method: 'get', url: url, responseType: 'blob' })
    .then(response => {
      let fileName =
        response.headers['content-disposition']?.split('filename=')[1] ||
        `file_${new Date().getTime()}`;
      fileDownload(response.data, fileName);
    })
    .catch(err => {
      console.log('download report error: ', err);
    });
};

export default class ReportTabPanelContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      activeModal: null
    };
    this.lastUpdatedData = [
      {
        value: getDateRange('today'),
        label: 'Today'
      },
      {
        value: getDateRange('last 7 days'),
        label: 'Last 7 days'
      },
      {
        value: getDateRange('last 14 days'),
        label: 'Last 14 days'
      },
      {
        value: getDateRange('last 30 days'),
        label: 'Last 30 days'
      },
      {
        value: getDateRange('last 60 days'),
        label: 'Last 60 days'
      },
      {
        value: getDateRange('last 90 days'),
        label: 'Last 90 days'
      },
      {
        value: getDateRange('last month'),
        label: 'Last calendar month'
      },
      {
        value: getDateRange('this year'),
        label: 'This year'
      },
      {
        value: getDateRange('past year'),
        label: 'Last calendar year'
      },
      {
        value: 'All time',
        label: 'All time'
      }
    ];
    this._handleDropDownValue = this._handleDropDownValue.bind(this);
    this._openModal = this._openModal.bind(this);
    this._closeModal = this._closeModal.bind(this);
  }

  componentDidMount() {
    this._setDefaultLastUpdatedFilter();
  }

  _handleDropDownValue(selectedOption, action) {
    let optionName = action && action.name;
    this.setState(state => {
      let optionvalue = {};
      optionvalue[optionName] = selectedOption;
      return { ...state, ...optionvalue };
    });
  }

  _setDefaultLastUpdatedFilter() {
    //setting default last updated filter to last 30 days
    let getcurentMonthDateRange = getDateRange('last 90 days');
    this.defaultLastUpdatedValue = getcurentMonthDateRange;
    this.setState({
      lastUpdated: {
        value: getcurentMonthDateRange,
        label: 'Last 90 days'
      }
    });
  }

  newQnaEnglishDownload = (startDate, endDate) => {
    const excleStartDateFormat = startDate
      ? moment(startDate).format('DDMMMYYYY')
      : '';
    const excleEndDateFormat = endDate
      ? moment(endDate).format('DDMMMYYYY')
      : '';

    startDate = startDate && moment(startDate).format('DD/MM/YYYY');
    endDate = endDate && moment(endDate).format('DD/MM/YYYY');

    let qnaUrl = `${
      constant.serviceUrls.QNA_CAMPAIGN_DETAILS
    }?startDate=${encodeURIComponent(startDate)}&&endDate=${encodeURIComponent(
      endDate
    )}&&excleStartDateFormat=${excleStartDateFormat}&&excleEndDateFormat=${excleEndDateFormat}&&action=export`;
    downloadFile(qnaUrl);
  };

  getAllCwData = (startDate, endDate) => {
    const excleStartDateFormat = startDate
      ? moment(startDate).format('DDMMMYYYY')
      : '';
    //  let endDate  = startDate.setMonth(startDate.getMonth() - 3);
    const excleEndDateFormat = endDate
      ? moment(endDate).format('DDMMMYYYY')
      : '';

    startDate = startDate && moment(startDate).format('DD/MM/YYYY');
    endDate = endDate && moment(endDate).format('DD/MM/YYYY');
    let allCwUrl = `${
      constant.serviceUrls.CAMPAIGN_JSON_OUTPUT_URL
    }?startDate=${encodeURIComponent(startDate)}&&endDate=${encodeURIComponent(
      endDate
    )}&&excleStartDateFormat=${excleStartDateFormat}&&excleEndDateFormat=${excleEndDateFormat}&&action=export`;
    downloadFile(allCwUrl);
  };

  getFormFieldReport = async (startDate, endDate) => {
    const excleStartDateFormat = startDate
      ? moment(startDate).format('DDMMMYYYY')
      : '';
    //  let endDate  = startDate.setMonth(startDate.getMonth() - 3);
    const excleEndDateFormat = endDate
      ? moment(endDate).format('DDMMMYYYY')
      : '';

    startDate = startDate && moment(startDate).format('DD/MM/YYYY');
    endDate = endDate && moment(endDate).format('DD/MM/YYYY');
    let allCwUrl = `${
      constant.serviceUrls.CAMPAIGN_JSON_OUTPUT_URL
    }?startDate=${encodeURIComponent(startDate)}&&endDate=${encodeURIComponent(
      endDate
    )}&&excleStartDateFormat=${excleStartDateFormat}&&excleEndDateFormat=${excleEndDateFormat}&&action=export&reportType=formFieldReport`;
   // window.open(allCwUrl, '_blank');
   downloadFile(allCwUrl)
  //  const response = await axios({
  //   url: allCwUrl,
  //   method: 'GET',
  //   responseType: 'blob' // important
  //   })
  //   let fileName = `CW_form_fields_report`;
  //   if(excleStartDateFormat && excleEndDateFormat){
  //     fileName = `${fileName}_${excleStartDateFormat}_${excleEndDateFormat}`
  //   }else {
  //     fileName = `${fileName}_All_time`
  //   }
  //   await fileDownload(response.data, `${fileName}.xlsx`);
  
  };

  getCwApproverList = (startDate, endDate) => {
    const excleStartDateFormat = startDate
      ? moment(startDate).format('DDMMMYYYY')
      : '';
    //  let endDate  = startDate.setMonth(startDate.getMonth() - 3);
    const excleEndDateFormat = endDate
      ? moment(endDate).format('DDMMMYYYY')
      : '';

    startDate = startDate ? moment(startDate).format('DD/MM/YYYY') : '';

    endDate = endDate ? moment(endDate).format('DD/MM/YYYY') : '';

    let allCwApproverListUrl = `${constant.serviceUrls.CAMPAIGN_GET_USER_URL}/export/approver?group=approver&&startDate=${startDate}&&endDate=${endDate}&sortBy=lastUpdatedDate&orderBy=desc`;
    axios.get(allCwApproverListUrl).then(response => {
      const approverList = response && response.data;
      let approverJson = [];
      if (approverList && approverList.length) {
        for (let approver of approverList) {
          if (approver && approver.country && approver.country.length) {
            for (const country of approver.country) {
              let newApprovar = {
                Country: country.name,
                ['Business group']: approver.allBuFlag ? 'All' :
                  ((approver.businessGroup &&
                    approver.businessGroup[0] &&
                    approver.businessGroup[0].name) ||
                  'Unavailable'),
                'Approver name': approver.name || 'Unavailable',
                'Approver email': approver.emailId || 'Unavailable',
                'Status' :approver && approver.status =='1' ? 'Active' : (approver.status =='0' ? 'Inactive' : 'Unavailable')
              };
              approverJson.push(newApprovar);
            }
          } 
        }
      }
      approverJson.sort((p, q) => (p.Country > q.Country ? 1 : -1));
      const worksheet = XLSX.utils.json_to_sheet(approverJson);

      XLSX.utils.sheet_add_aoa(
        worksheet,
        [['Exported on', new moment().format('DD MMM YYYY')]],
        { origin: 'A' + (approverJson.length + 3) }
      );

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Approver List');
      if (excleStartDateFormat && excleEndDateFormat) {
        XLSX.writeFile(
          workbook,
          `CW_Approvers_${excleStartDateFormat}-${excleEndDateFormat}.xlsx`
        );
      } else {
        XLSX.writeFile(workbook, `CW_Approvers_All_time.xlsx`);
      }
    });
  };

  getFeedbackDownload = (startDate, endDate) => {
    const excleStartDateFormat = startDate
      ? moment(startDate).format('DDMMMYYYY')
      : '';
    //  let endDate  = startDate.setMonth(startDate.getMonth() - 3);
    const excleEndDateFormat = endDate
      ? moment(endDate).format('DDMMMYYYY')
      : '';

    startDate = startDate ? moment(startDate).format('DD/MM/YYYY') : '';
    endDate = endDate ? moment(endDate).format('DD/MM/YYYY') : '';
    let feedbackUrl = `${
      constant.serviceUrls.FEEDBACKS_URL
    }?startDate=${encodeURIComponent(startDate)}&endDate=${encodeURIComponent(
      endDate
    )}&&excleStartDateFormat=${excleStartDateFormat}&&excleEndDateFormat=${excleEndDateFormat}&&action=export`;
    downloadFile(feedbackUrl);
  };

  getMajorEnrichmentQuestions = (startDate, endDate) => {
        const excleStartDateFormat = startDate
        ? moment(startDate).format('DDMMMYYYY')
        : '';
      const excleEndDateFormat = endDate
        ? moment(endDate).format('DDMMMYYYY')
        : '';

      startDate = startDate && moment(startDate).format('DD/MM/YYYY');
      endDate = endDate && moment(endDate).format('DD/MM/YYYY');

      let qnaUrl = `${
        constant.serviceUrls.MAJOR_ENRICHMENT_REPORT
      }?startDate=${encodeURIComponent(startDate)}&&endDate=${encodeURIComponent(
        endDate
      )}&&excleStartDateFormat=${excleStartDateFormat}&&excleEndDateFormat=${excleEndDateFormat}&&action=export`;
      //window.open(qnaUrl, '_blank');
      downloadFile(qnaUrl);
  }

  downloadExcel = () => {
    let startDate = '';
    let endDate = '';
    if (
      this.state.lastUpdated &&
      this.state.lastUpdated['value'] !== 'All time'
    ) {
      let dateArr =
        this.state.lastUpdated &&
        this.state.lastUpdated['value'] &&
        this.state.lastUpdated['value'].split('-');
      startDate =
        dateArr && dateArr.length
          ? new Date(moment(dateArr[0].trim(), 'DD/MM/YY').format('YYYY-MM-DD'))
          : '';
      endDate =
        dateArr && dateArr.length
          ? new Date(moment(dateArr[1].trim(), 'DD/MM/YY').format('YYYY-MM-DD'))
          : '';
    }
    if (
      this.state.reportFilter &&
      this.state.reportFilter['value'] === 'new_qna_english'
    ) {
      this.newQnaEnglishDownload(startDate, endDate);
    } else if (
      this.state.reportFilter &&
      this.state.reportFilter['value'] === 'all_cw_data'
    ) {
      this.getAllCwData(startDate, endDate);
    } else if (
      this.state.reportFilter &&
      this.state.reportFilter['value'] === 'cw_feedback'
    ) {
      this.getFeedbackDownload(startDate, endDate);
    } else if (
      this.state.reportFilter &&
      this.state.reportFilter['value'] === 'cw_approvers'
    ) {
      this.getCwApproverList(startDate, endDate);
    } else if (
      this.state.reportFilter &&
      this.state.reportFilter['value'] === 'cw_form_fields_report'
    ) {
      this.getFormFieldReport(startDate, endDate);
    } else if(
      this.state.reportFilter &&
      this.state.reportFilter['value'] === 'cw_major_enrichment_questions'
    ) {
      this.getMajorEnrichmentQuestions(startDate, endDate);
    }

    
  };

  _openModal(event) {
    event.preventDefault();
    this.setState(state => {
      return { ...state, ...{ modalIsOpen: true } };
    });
  }

  _closeModal() {
    this.setState(state => {
      return { ...state, ...{ modalIsOpen: false } };
    });
  }

  filteredCriteria = constant.REPORT_DOWNLOAD_CRITERIA.filter(item => (item.allowedRoles.includes(this.props.role)))

  render() {
    let { asyncInProgress } = this.props;
    return (
      <>
        <div className="mt-20">
          {this.props.panelType === 'dashboard' ? (
            <>
              <ReportDashboard />
            </>
          ) : null}

          {this.props.panelType === 'reports' ? (
            <>
              <div className="row mb-20">
                <div className="col-sm-6">
                  <h2 className="cw-heading--secondary d-flex align-items-center mb-10">
                    {/* <span className="mr-3"></span> */}
                    <div className="cw-heading--primary">
                      Select report filter criteria
                    </div>
                  </h2>
                </div>
              </div>
              <div>
                <div className="d-flex align-items-center">
                  <div className="mr-10">
                    <div className="form-group mb-0">
                      <RadioDropDown
                        isMulti={false}
                        className={
                          'cw-dashboard-reports-filter report-updated-filter'
                        }
                        name={'lastUpdated'}
                        label={'Last Updated'}
                        hideSelectedOptions={false}
                        placeholder={'Last Updated'}
                        isDisabled={asyncInProgress}
                        isLoading={asyncInProgress}
                        value={this.state.lastUpdated}
                        onChange={this._handleDropDownValue}
                        options={this.lastUpdatedData}
                        isClearable={false}
                      />
                    </div>
                  </div>
                  <div className="form-group mb-0">
                    <RadioDropDown
                      isMulti={false}
                      style={{ paddingTop: '0px' }}
                      className={
                        'cw-dashboard-reports-filter-single report-updated-filter pt-0 reports-download-options'
                      }
                      name={'reportFilter'}
                      label={'Select a report'}
                      hideSelectedOptions={false}
                      placeholder={'Please select'}
                      isDisabled={asyncInProgress}
                      isLoading={asyncInProgress}
                      value={this.state.reportFilter}
                      onChange={this._handleDropDownValue}
                      options={this.filteredCriteria}
                      isClearable={false}
                    />
                  </div>
                  <div className="ml-10">
                    <span
                      className="cw-icon cw-icon--help"
                      data-for="reportTooltip"
                    >
                      <i
                        className="fa fa-info-circle"
                        onClick={this._openModal}
                      ></i>
                    </span>
                  </div>
                </div>
                {this.state.reportFilter &&
                this.state.reportFilter.toolTipData ? (
                  <p className="report-text-display mt-10">
                    {this.state.reportFilter.toolTipData}
                  </p>
                ) : null}
                <div className="d-flex align-items-center">
                  <button
                    className="btn btn-outline-primary release-notes-btn mt-20 mr-10"
                    onClick={this.downloadExcel}
                    disabled={this.state.reportFilter ? false : true}
                  >
                    Download Excel
                  </button>
                </div>
              </div>
            </>
          ) : null}
        </div>
        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this._closeModal}
          className="cw-modal cw-modal--questions"
          contentLabel="Approval"
          style={{
            overlay: {
              backgroundColor: constant.EMAIL_OVERLAY_BACKGROUND
            }
          }}
        >
          <button
            className="cw-modal--close"
            title="Close"
            onClick={this._closeModal}
          >
            <i className="fas fa-times"></i>
          </button>
          <ReportsInformationModal content={this.filteredCriteria}/>
        </Modal>
      </>
    );
  }
}
