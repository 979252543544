import * as yup from 'yup';
import { omit } from 'lodash';
import constants from 'constants/config';
import React from 'react';
import { transformSMSPayloadBySchedule } from 'selectors';
import moment from 'moment';
import { EmailDeliveryAndLabelInput } from 'components/molecules/EmailDeliveryAndLabelInput';
import { get } from 'lodash';
import ReactTooltip from 'react-tooltip';
import { EmailSchduleRadioInput } from 'components/molecules/EmailSchduleRadioInput';
import { setNotificationMessage } from 'actions/dashboard';
import { EmailScheduleTests } from './EmailScheduleTests';
import { SMSShortURLInput } from './SMSShortURLInput';
import { updateSmsCampaignStatus } from 'actions/communications/communicationCampaignDetails';

const dateTimeSchema = isABTestOnSendTime => {
  if (isABTestOnSendTime) {
    return {
      smsDeliveryDateVariantA: yup
        .string()
        .test(
          'isDateStringParsable',
          'Please enter a valid date',
          dateString => {
            return (
              moment(dateString, 'DD/MM/YYYY', true).isValid() ||
              moment(
                String(dateString).slice(0, 33),
                'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ',
                true
              ).isValid()
            );
          }
        )
        .required('Please enter SMS delivery date for variant A'),
      smsDeliveryDateVariantB: yup
        .string()
        .test(
          'isDateStringParsable',
          'Please enter a valid date',
          dateString => {
            return (
              moment(dateString, 'DD/MM/YYYY', true).isValid() ||
              moment(
                String(dateString).slice(0, 33),
                'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ',
                true
              ).isValid()
            );
          }
        )
        .required('Please enter SMS delivery date for variant B'),
      smsDeliveryTimeVariantA: yup
        .string()
        .trim()
        .test(
          'isDateStringParsable',
          'Please enter a valid time',
          dateString => {
            return moment(dateString).isValid();
          }
        )
        .required('Please enter SMS delivery time for variant A'),
      smsDeliveryTimeVariantB: yup
        .string()
        .trim()
        .test(
          'isDateStringParsable',
          'Please enter a valid time',
          dateString => {
            return moment(dateString).isValid();
          }
        )
        .required('Please enter SMS delivery time for variant B')
    };
  } else {
    return {
      smsDeliveryDate: yup
        .string()
        .test(
          'isDateStringParsable',
          'Please enter a valid date',
          dateString => {
            return (
              moment(dateString, 'DD/MM/YYYY', true).isValid() ||
              moment(
                String(dateString).slice(0, 33),
                'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ',
                true
              ).isValid()
            );
          }
        )
        .required('Please enter SMS delivery date'),
      smsDeliveryTime: yup
        .string()
        .trim()
        .test(
          'isDateStringParsable',
          'Please enter a valid time',
          dateString => {
            return moment(dateString).isValid();
          }
        )
        .required('Please enter SMS delivery time')
    };
  }
};

export const LableText = () => {
  return (
    <>
      <ReactTooltip
        id="updateLable_tooltip"
        place="right"
        type="info"
        multiline={true}
        className="cw-tooltip cw-email-tooltip"
      />
      <span
        className={'cw-icon--help'}
        style={{
          display: 'block',
          whiteSpace: 'pre',
          cursor: 'pointer'
        }}
        data-tip="This is the label requestors can use to view performance metrics of their SMS in Power BI trackers"
        data-for="updateLable_tooltip"
      >
        {' '}
        <i className="fa fa-question-circle"></i>
      </span>
    </>
  );
};

const SmsCampaignSheduleSchema = (
  dispatch,
  {
    defaultValues,
    pathUid,
    closeModal,
    smsType,
    abTesting,
    setSuccess,
    status,
    isCrossBrand,
    nmiAssignedToRequestor,
    communicationDetail,
    shareCostEstimate
  }
) => {
  const defaultType = get(defaultValues, 'smsTrigger.type');

  const hasMainURL = get(
    communicationDetail,
    'smsDetails.[0].URL.[0].fullURL',
    ''
  );

  const hasFollowupURL = get(
    communicationDetail,
    'smsDetails.[1].URL.[0].fullURL',
    ''
  );

  const hasURL = hasMainURL || hasFollowupURL;
  let testsToPerform = isCrossBrand ? 4 : 3;
  if (hasURL) {
    testsToPerform += 1;
  }

  const { performABTests, noOfABVariants, abTestElements } = abTesting || {};
  const isABTestOnSendTime = performABTests && abTestElements === 'sendTime';
  return {
    formType: 'modal',
    submitBtnLabel:
      constants.SMS_STATUS.AWAITING_SETUP === status ||
      (constants.SMS_STATUS.AWAITING_INFO === status &&
        nmiAssignedToRequestor === false)
        ? 'Mark as scheduled'
        : 'Mark as Live',
    fields: [
      {
        type: 'custom',
        name: 'smsTrigger',
        labelInputName: 'deliveryLabel',
        component: EmailDeliveryAndLabelInput,
        customComponent: LableText,
        id: 'smsTrigger',
        label: 'SMS trigger',
        dateFormat: constants.FORMAT_DATE_DD_MM_YY,
        timeFormat: true,
        defaultValue: {
          ...defaultValues.smsTrigger,
          sendAfter: defaultValues.sendAfter,
          deliveryLabel: defaultValues.deliveryLabel
        },
        isSms: true,
        markAsSchedule: true,
        emailType: smsType,
        abTesting,
        rules: yup
          .object({
            details: yup
              .object()
              .when('type', {
                is: 'fixedDateAndtime',
                then: yup.object(dateTimeSchema(isABTestOnSendTime))
              })
              .when('type', {
                is: 'dynamicTrigger',
                then: yup.object({
                  triggerConditionStartDate: yup
                    .string()
                    .test(
                      'isDateStringParsable',
                      'Please enter start date',
                      dateString => {
                        return moment(dateString).isValid();
                      }
                    )
                    .required('Please enter start date'),
                  triggerConditionEndDate: yup
                    .string()
                    .when('triggerConditionAlwaysOn', {
                      is: false,
                      then: schema =>
                        schema
                          .test(
                            'isDateStringParsable',
                            'Please enter end date',
                            dateString => {
                              return moment(dateString).isValid();
                            }
                          )
                          .test(
                            'isNotGreaterThenStartDate',
                            'End date can not be before Start date',
                            (dateString, context) => {
                              return moment(dateString, [
                                'DD/MM/YYYY',
                                moment.ISO_8601,
                                'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ'
                              ]).isSameOrAfter(
                                moment(
                                  context.parent.triggerConditionStartDate,
                                  [
                                    'DD/MM/YYYY',
                                    moment.ISO_8601,
                                    'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ'
                                  ]
                                ),
                                'day'
                              );
                            }
                          )
                          .required('Please enter end date'),
                      otherwise: schema => schema.notRequired()
                    })
                })
              }),
            deliveryLabel: yup.object({
              mainLabel: yup
                .string()
                .typeError('Please enter update delivery label')
                .when([], {
                  is: () => !abTesting || !abTesting.performABTests,
                  then: yup
                    .string()
                    .required('Please enter update delivery label'),
                  otherwise: yup.string().nullable()
                })
                .trim()
                .max(100, 'Max 100 characters are allowed')
                .nullable(),
              ABVariantA: yup
                .string()
                .typeError('Please enter delivery label for variant A')
                .when([], {
                  is: () => abTesting && abTesting.performABTests,
                  then: yup
                    .string()
                    .required('Please enter delivery label for variant A'),
                  otherwise: yup.string().nullable()
                })
                .trim()
                .max(100, 'Max 100 characters are allowed')
                .nullable(),
              ABVariantB: yup
                .string()
                .typeError('Please enter delivery label for variant B')
                .when([], {
                  is: () =>
                    abTesting &&
                    abTesting.performABTests &&
                    abTesting.noOfABVariants >= 2,
                  then: yup
                    .string()
                    .required('Please enter delivery label for variant B'),
                  otherwise: yup.string().nullable()
                })
                .trim()
                .max(100, 'Max 100 characters are allowed')
                .nullable(),
              ABVariantC: yup
                .string()
                .typeError('Please enter delivery label for variant C')
                .when([], {
                  is: () =>
                    abTesting &&
                    abTesting.performABTests &&
                    abTesting.noOfABVariants == 3,
                  then: yup
                    .string()
                    .required('Please enter delivery label for variant C'),
                  otherwise: yup.string().nullable()
                })
                .trim()
                .max(100, 'Max 100 characters are allowed')
                .nullable(),
              followUpLabel: yup
                .string()
                .typeError('Please enter delivery label for follow up')
                .when([], {
                  is: () =>
                    get(defaultValues, 'sendAfter.value') !== 'DontSend',
                  then: yup
                    .string()
                    .required('Please enter delivery label for follow up'),
                  otherwise: yup.string().nullable()
                })
                .trim()
                .max(100, 'Max 100 characters are allowed')
                .nullable()
            })
          })
          .required('Please select email trigger')
          .default({ type: undefined, details: undefined })
      },
      {
        type: 'label',
        id: 'sendTimeHelp',
        label: get(defaultValues, 'smsTrigger.details.optimizeSendTime')
          ? 'Requires send time optimization'
          : '',
        labelClassName: 'mt-n2 email-type-desc-font'
      },
      {
        type: 'custom',
        component: SMSShortURLInput,
        name: 'shortURL',
        id: 'shortURL',
        label: '',
        rules: yup.object({
          mainSend: hasMainURL
            ? yup
                .string()
                .required(
                  'Please enter the short Bitly URL you have created from the link in the SMS body'
                ).test(
                  'valid-url',
                  'Please enter valid URL',
                  value => {
                    const URLRegex = new RegExp(constants.regex.smsBodyURL);
                    const isValidShortURL = URLRegex.test(value);

                    if (!isValidShortURL) {
                      return false;
                    }
                    return true;
                  }
                )
            : yup.string().optional(),
          followup: hasFollowupURL
            ? yup
                .string()
                .required(
                  'Please enter the short Bitly URL you have created from the link in the SMS body'
                )
            : yup.string().optional()
        }),
        communicationDetail
      },
      {
        type: 'number',
        name: 'consumerCount',
        id: 'consumerCount',
        label: 'Audience count',
        placeholder: '',
        isOptional: true,
        defaultValue:
          defaultValues.consumerCount || shareCostEstimate?.audienceCount,
        disabled: defaultType === 'dynamicTrigger',
        rules:
          defaultType === 'dynamicTrigger'
            ? yup.number().optional()
            : yup
                .number()
                .typeError('Please enter audience count')
                .required('The audience count is required!')
                .test(
                  'Is positive?',
                  'Audience count must be greater than 0',
                  value => Number(value) > 0
                )
      },
      {
        type: 'label',
        label:
          defaultType === 'dynamicTrigger'
            ? 'Not available for SMS with dynamic triggers'
            : '',
        id: 'AudienceCountInfo',
        labelClassName: 'mt-n2 email-type-desc-font'
      },
      {
        type: 'custom',
        name: 'scheduleTextArea',
        component: EmailSchduleRadioInput,
        id: 'scheduleTextArea',
        label:
          'Was any change made to SMS body content outside of Campaign Wizard ?',
        defaultValue: defaultValues.scheduleTextArea,
        emailType: smsType,
        rules: yup
          .string()
          .test(
            'words count',
            'Max 300 words allowed',
            value => !value || (value && value.split(' ').length <= 300)
          )
      },
      {
        type: 'custom',
        name: 'smsScheduleTests',
        id: 'smsScheduleTests',
        label: 'Testing confirmations',
        component: EmailScheduleTests,
        showEmailScheduleTests:
          constants.SMS_STATUS.AWAITING_SETUP === status ||
          (constants.SMS_STATUS.AWAITING_INFO === status &&
            nmiAssignedToRequestor === false),
        defaultValue: [],
        isCrossBrand,
        hasURL,
        rules: yup
          .array()
          .test(
            'all checked',
            'Please confirm that the tests have been performed',
            value => {
              if (constants.SMS_STATUS.SCHEDULED === status) {
                return true;
              }

              return Array.isArray(value) && value.length === testsToPerform;
            }
          )
          .typeError('Please confirm that the tests have been performed')
      }
    ],
    onSubmit: (data, { user }) => {
      if (data) {
        data.deliveryLabel = { ...data.smsTrigger.deliveryLabel };
        data.smsTrigger = omit(data.smsTrigger, ['deliveryLabel']);

        if (data.smsTrigger && data.smsTrigger.details) {
          data.smsTrigger.details = {
            ...get(defaultValues, 'smsTrigger.details'),
            ...data.smsTrigger.details,
            optimizeSendTime: get(
              defaultValues,
              'smsTrigger.details.optimizeSendTime' // check this
            )
          };
        }

        data.deliveryLabel = Object.entries(data.deliveryLabel).map(
          ([key, value]) => {
            return {
              type: key,
              value
            };
          }
        );
      }
      const paramUid = pathUid;
      const { shortURL, ...restFields } = data;
      const dataToSend = {
        userEmail: user.emailId,
        userName: user.name,
        deliverySchedule: { ...defaultValues, ...restFields },
        shortURL,
        status:
          constants.SMS_STATUS.AWAITING_SETUP === status ||
          (constants.SMS_STATUS.AWAITING_INFO === status &&
            nmiAssignedToRequestor === false)
            ? constants.SMS_STATUS.SCHEDULED
            : constants.SMS_STATUS.LIVE
      };

      const transformedData = transformSMSPayloadBySchedule(dataToSend);
      dispatch(
        updateSmsCampaignStatus(
          { ...transformedData, uid: paramUid },
          (error, result) => {
            if (error) {
              console.log('OnSubmit error: ', error);
            } else {
              if (dataToSend.status === constants.SMS_STATUS.LIVE) {
                dispatch(
                  setNotificationMessage(
                    constants.EMAIL_CAMPAIGN_APPROVAL_MESG.acsLive.message,
                    undefined,
                    constants.EMAIL_CAMPAIGN_APPROVAL_MESG.acsLive.type
                  )
                );
              }
              setSuccess(true);
            }
          },
          { disableLoader: true }
        )
      );
    },
    onPrevious: () => {
      closeModal();
    },
    previousBtnLabel: 'Cancel',
    disableFormError: true
  };
};

export default SmsCampaignSheduleSchema;
