import React, { Component, useCallback } from 'react';
import { connect } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import Alert from '../Alert';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import BreadCrumb from '../BreadCrumb';
import {
  fetchCampaignData,
  updateProfileFields,
  updateGenderValues,
  updateProvinceValues
} from '../../actions/profileFieldsSorting';
import {
  setApproverEditCampaignStatus,
  setApproverEditCampaignStatusMessage,
  setApproverEditLastUpdatedScreen
} from '../../actions/app';
import constant from '../../constants';
import arrayMove from 'array-move';
import axios from 'axios';
import { setNotificationMessage } from '../../actions/dashboard';
import { epsilonMarket } from '../../selectors/index';
import CampaignCancelModel from '../CampaignCancelModel';
import { cancelCampaignChanges } from '../../actions/overview';
import Modal from 'react-modal';

const SortableItem = SortableElement(
  ({
    field,
    sortIndex,
    handleCollapse,
    collapsableIcon,
    collapsablePanel,
    state,
    currentObj,
    onSortEnd
  }) => {
    const collpaseEv = useCallback(() => {
      handleCollapse(sortIndex);
    }, [handleCollapse, sortIndex]);

    return (
      <li className="list-group-item p-0 sortable-item">
        <div
          className="cw-questions--listitem form-check mb-5"
          data-item={sortIndex}
          key={sortIndex}
        >
          <div className="cw-striped-row">
            <div className="row">
              <div className="col-sm-12">
                <div className="row">
                  <div
                    className="accordion saas-form-field-accordion"
                    style={{
                      margin: '0 0 0 1%',
                      width: '90%',
                      paddingRight: 15
                    }}
                  >
                    <div className="card" style={{ border: 0 }}>
                      <div
                        className="card-header-dnd"
                        style={{ backgroundColor: '#F9F9F9' }}
                      >
                        <span className="text-secondary pl-13 mr-20">
                          <i className="fas fa-align-justify cw-color--primary"></i>
                        </span>
                        <strong>
                          <span className="saas-accordion-heading cw-color--primary">
                            <p>
                              {field.dataType === 'paragraph'
                                ? field.label
                                : field.dataType === 'heading'
                                ? field.label + ' (' + field.heading + ')'
                                : field.dataType === 'questionAnswerPair'
                                ? field.questionLabel
                                : field.fieldLabel}
                            </p>
                          </span>
                        </strong>
                        <br />
                      </div>
                    </div>
                  </div>
                  {field.dataType === 'gender' ||
                  field.dataType === 'paragraph' ||
                  (field.dataType === 'samplePrizeSelection' &&
                    field.answers.length > 1) ||
                  (field.dataType === 'questionAnswerPair' &&
                    field.questionType === 'Single choice') ||
                  (field.dataType === 'questionAnswerPair' &&
                    field.questionType === 'Multiple choice') ||
                  (field.dataType === 'stateOrProvince' &&
                    field.questionType === 'Single choice') ? (
                    <span
                      type="button"
                      className="saas-accordion-arrow"
                      href="#fieldInfoCollapsed"
                      role="button"
                      aria-expanded="false"
                      aria-controls="fieldInfoCollapsed"
                      onClick={collpaseEv}
                    >
                      <i
                        className={
                          state.expand === sortIndex
                            ? 'fas fa-chevron-down transformIcon-180'
                            : 'fas fa-chevron-down'
                        }
                        ref={node => {
                          collapsableIcon[sortIndex] = node;
                        }}
                      ></i>
                    </span>
                  ) : null}
                </div>
              </div>
              <div
                className={`collapse `}
                id="fieldInfoCollapsed"
                ref={node => {
                  collapsablePanel[sortIndex] = node;
                }}
                style={{ margin: '0 0 0 0.5%', width: '99%' }}
              >
                <div className="cw-campaign--review-section-content">
                  {field.label === 'Paragraph' ? (
                    <div className="cw-striped-row sorting-child-container">
                      <div
                        className="row"
                        style={{
                          margin: '0 0 0 4%'
                        }}
                      >
                        <div className="col-sm-4">
                          <strong>
                            {
                              //field.label === "Paragraph" ?
                              'Paragraph Text'
                              // : field.label === "Heading"
                              // ? "Heading Text"
                              // : " Field Label"
                            }
                          </strong>
                        </div>
                        <div className="col-sm-8 cw-text--ternary">
                          {//field.label === "Paragraph" ?
                          ReactHtmlParser(field.paragraph)
                          // : field.label === "Heading"
                          // ? field.heading
                          // : field.dataType === "questionAnswerPair"
                          // ? field.questionLabel
                          // : field.fieldLabel
                          }
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {field.label === 'Gender' ? (
                    <div className="cw-striped-row sorting-child-container">
                      <div className="row" style={{ margin: '0 0 0 4%' }}>
                        <div className="col-sm-4">
                          <strong>Gender Values</strong>
                        </div>
                        <div className="col-sm-12 cw-text--ternary">
                          <GenderValuesSortableList
                            distance={1}
                            items={
                              currentObj.state.values
                                ? currentObj.state.values
                                : currentObj.props.genderValues
                            }
                            onSortEnd={currentObj.onSortEndForGenderValues}
                            state={state}
                            currentObj={currentObj}
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {field.label === 'State or Province' &&
                  field.questionType === 'Single choice' ? (
                    <div className="cw-striped-row sorting-child-container">
                      <div className="row" style={{ margin: '0 0 0 4%' }}>
                        <div className="col-sm-4">
                          <strong>State Values</strong>
                        </div>
                        <div className="col-sm-12 cw-text--ternary">
                          <ProvinceValuesSortableList
                            distance={1}
                            items={
                              currentObj.state.provinces
                                ? currentObj.state.provinces
                                : currentObj.props.provinceValues
                            }
                            onSortEnd={currentObj.onSortEndForProvinceValues}
                            state={state}
                            currentObj={currentObj}
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/*field.dataType === "questionAnswerPair" &&
                  field.questionType ? (
                    <div className="cw-striped-row">
                      <div className="row" style={{ margin: "0 0 0 4%" }}>
                        <div className="col-sm-4">
                          <strong>Question Type</strong>
                        </div>
                        <div className="col-sm-8 cw-text--ternary">
                          {field.questionType}
                        </div>
                      </div>
                    </div>
                  ) : null} */}
                  {/* {field.dataType === "samplePrizeSelection" &&
                  field.answers &&
                  field.answers.length > 1 ? (
                    <div className="cw-striped-row">
                      <div className="row" style={{ margin: "0 0 0 4%" }}>
                        <div className="col-sm-4">
                          <strong>Sample Selection</strong>
                        </div>
                        <div className="col-sm-8 cw-text--ternary">
                          {field.questionType === "Single choice"
                            ? constant.SAMPLE_OPTIONS.singleSample
                            : field.questionType === "Multiple choice"
                            ? constant.SAMPLE_OPTIONS.multipleSample
                            : null}
                        </div>
                      </div>
                    </div>
                  ) : null} */}
                  {/* {field.maxAnswerLimit ? (
                    <div className="cw-striped-row">
                      <div className="row" style={{ margin: "0 0 0 4%" }}>
                        <div className="col-sm-4">
                          <strong>Maximum number of answers allowed</strong>
                        </div>
                        <div className="col-sm-8 cw-text--ternary">
                          {field.maxAnswerLimit ? field.maxAnswerLimit : ""}
                        </div>
                      </div>
                    </div>
                  ) : null} */}
                  {/* {field.toolTipText || field.toolTip ? (
                    <div className="cw-striped-row">
                      <div className="row" style={{ margin: "0 0 0 4%" }}>
                        <div className="col-sm-4">
                          <strong>Tooltip</strong>
                        </div>
                        <div className="col-sm-8 cw-text--ternary">
                          {field.toolTipText
                            ? field.toolTipText
                            : field.toolTip
                            ? field.toolTip
                            : ""}
                        </div>
                      </div>
                    </div>
                  ) : null} */}
                  {/* {field.dataType === "fileUpload" ? (
                    <div className="cw-striped-row">
                      <div className="row" style={{ margin: "0 0 0 4%" }}>
                        <div className="col-sm-4">
                          <strong>
                            Maximum upload file size allowed in KB
                          </strong>
                        </div>
                        <div className="col-sm-8 cw-text--ternary">
                          {field.maxFileSize ? field.maxFileSize : ""}
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {field.dataType === "fileUpload" &&
                  field.values &&
                  field.values.filter((e) => e.isChecked).length > 0 ? (
                    <div className="cw-striped-row">
                      <div className="row" style={{ margin: "0 0 0 4%" }}>
                        <div className="col-sm-4">
                          <strong>Allowed file types</strong>
                        </div>
                        <div className="col-sm-8 cw-text--ternary">
                          {field.values
                            .filter((e) => e.isChecked)
                            .map((ele) => ele.value)
                            .join(", ")}
                        </div>
                      </div>
                    </div>
                  ) : null} */}
                  {/* {field.label !== "Paragraph" && field.label !== "Heading" ? (
                    <div className="cw-striped-row">
                      <div className="row" style={{ margin: "0 0 0 4%" }}>
                        <div className="col-sm-4">
                          <strong>Mandatory Toggle</strong>
                        </div>
                        <div className="col-sm-8 cw-text--ternary">
                          {field.mandatoryToggle ? "Yes" : "No"}
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {field.mandatoryToggle ? (
                    <div className="cw-striped-row">
                      <div className="row" style={{ margin: "0 0 0 4%" }}>
                        <div className="col-sm-4">
                          <strong>Mandatory Field Error Message</strong>
                        </div>
                        <div className="col-sm-8 cw-text--ternary">
                          {field.mandatoryMessage ? field.mandatoryMessage : ""}
                        </div>
                      </div>
                    </div>
                  ) : null} */}
                  {/* {field.label === "Date of Birth" ? (
                    <div className="cw-striped-row">
                      <div className="row" style={{ margin: "0 0 0 4%" }}>
                        <div className="col-sm-4">
                          <strong>Legal Age Validation Message</strong>
                        </div>
                        <div className="col-sm-8 cw-text--ternary">
                          {field.validationMessage
                            ? field.validationMessage
                            : ""}
                        </div>
                      </div>
                    </div>
                  ) : null} */}
                  {/* {(field.label === "Email Address" ||
                    field.label === "Mobile Number" ||
                    field.label === "Postal Code" ||
                    field.dataType === "fileUpload") &&
                  field.validationMessage ? (
                    <div className="cw-striped-row">
                      <div className="row" style={{ margin: "0 0 0 4%" }}>
                        <div className="col-sm-4">
                          <strong>Format Validation Error Message</strong>
                        </div>
                        <div className="col-sm-8 cw-text--ternary">
                          {field.validationMessage
                            ? field.validationMessage
                            : ""}
                        </div>
                      </div>
                    </div>
                  ) : null} */}
                  {/* {field.dataType === "fileUpload" ? (
                    <div className="cw-striped-row">
                      <div className="row" style={{ margin: "0 0 0 4%" }}>
                        <div className="col-sm-4">
                          <strong>Failed Upload Error Message</strong>
                        </div>
                        <div className="col-sm-8 cw-text--ternary">
                          {field.failedUploadMessage
                            ? field.failedUploadMessage
                            : ""}
                        </div>
                      </div>
                    </div>
                  ) : null} */}
                  {(field.dataType === 'questionAnswerPair' ||
                    field.dataType === 'samplePrizeSelection') &&
                  field.answers &&
                  field.answers.length > 0 ? (
                    <div className="cw-striped-row sorting-child-container">
                      <div className="row" style={{ margin: '0 0 0 4%' }}>
                        <div className="col-sm-4">
                          {field.dataType === 'questionAnswerPair' ? (
                            <strong>Answers</strong>
                          ) : (
                            <strong>Samples</strong>
                          )}
                        </div>
                        <div className="col-sm-12 cw-text--ternary">
                          {
                            <AnswersSortableList
                              distance={1}
                              answers={field.answers}
                              onSortEnd={onSortEnd.bind(this, {
                                sortIndex
                              })}
                              state={state}
                              currentObj={currentObj}
                            />
                          }
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
    );
  }
);

const SortableList = SortableContainer(
  ({
    items,
    handleCollapse,
    collapsableIcon,
    collapsablePanel,
    state,
    currentObj,
    onSortEndForAnswers
  }) => {
    return (
      <ul className="list-group">
        {items.map((value, index) => (
          <SortableItem
            key={`item-${index}`}
            index={index}
            field={value}
            sortIndex={index}
            handleCollapse={handleCollapse}
            collapsableIcon={collapsableIcon}
            collapsablePanel={collapsablePanel}
            state={state}
            currentObj={currentObj}
            onSortEnd={onSortEndForAnswers}
          />
        ))}
      </ul>
    );
  }
);

const GenderValuesSortableList = SortableContainer(
  ({ items, state, currentObj }) => {
    return (
      <ul className="list-group">
        {items
          .filter(ele => ele.isChecked)
          .map((value, index) => (
            <SortableGenderValues
              key={`item-${index}`}
              index={index}
              field={value}
              sortIndex={index}
              state={state}
            />
          ))}
      </ul>
    );
  }
);

const ProvinceValuesSortableList = SortableContainer(
  ({ items, state, currentObj }) => {
    return (
      <ul className="list-group">
        {items.map((value, index) => (
          <SortableProvinceValues
            key={`item-${index}`}
            index={index}
            field={value}
            sortIndex={index}
            state={state}
          />
        ))}
      </ul>
    );
  }
);
const SortableProvinceValues = SortableElement(({ field, sortIndex }) => {
  return (
    <li className="list-group-item p-0 sortable-item">
      <div
        className="cw-questions--listitem "
        data-item={sortIndex}
        key={sortIndex}
      >
        <div className="cw-striped-row">
          <div className="row">
            <div className="col-sm-12">
              {/* <div className="row"> */}
              <div className="card" style={{ border: 0 }}>
                <div
                  className="card-header-dnd"
                  style={{ backgroundColor: '#F9F9F9' }}
                >
                  <span className="text-secondary mr-20">
                    <i className="fas fa-align-justify cw-color--primary"></i>
                  </span>
                  <strong>
                    <span className="saas-accordion-heading cw-color--primary">
                      <p>{field.value}</p>
                    </span>
                  </strong>
                  <br />
                </div>
              </div>
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    </li>
  );
});

const SortableGenderValues = SortableElement(({ field, sortIndex }) => {
  return (
    <li className="list-group-item p-0 sortable-item">
      <div
        className="cw-questions--listitem "
        data-item={sortIndex}
        key={sortIndex}
      >
        <div className="cw-striped-row">
          <div className="row">
            <div className="col-sm-12">
              {/* <div className="row"> */}
              <div className="card" style={{ border: 0 }}>
                <div
                  className="card-header-dnd"
                  style={{ backgroundColor: '#F9F9F9' }}
                >
                  <span className="text-secondary mr-20">
                    <i className="fas fa-align-justify cw-color--primary"></i>
                  </span>
                  <strong>
                    <span className="saas-accordion-heading cw-color--primary">
                      <p>{field.translatedValue}</p>
                    </span>
                  </strong>
                  <br />
                </div>
              </div>
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    </li>
  );
});

const AnswersSortableList = SortableContainer(
  ({ answers, state, currentObj }) => {
    return (
      <ul className="list-group">
        {answers.map((value, index) => (
          <SortableAnswers
            key={`item-${index}`}
            index={index}
            field={value}
            sortIndex={index}
            state={state}
          />
        ))}
      </ul>
    );
  }
);

const SortableAnswers = SortableElement(({ field, sortIndex }) => {
  return (
    <li className="list-group-item p-0 sortable-item">
      <div
        className="cw-questions--listitem "
        data-item={sortIndex}
        key={sortIndex}
      >
        <div className="cw-striped-row">
          {/* <div className="row"> */}
          <div className="col-sm-12">
            <div className="row">
              <div className="card" style={{ border: 0 }}>
                <div
                  className="card-header-dnd"
                  style={{ backgroundColor: '#F9F9F9' }}
                >
                  <span className="text-secondary mr-20">
                    <i className="fas fa-align-justify cw-color--primary"></i>
                  </span>
                  {field.answerLabel ? (
                    <strong>
                      {field.answerLabel && field.answerLabel.length > 50 ? (
                        <span className="saas-accordion-heading cw-color--primary">
                          <p>{field.answerLabel}</p>
                        </span>
                      ) : (
                        <span className="saas-accordion-heading cw-color--primary">
                          {field.answerLabel ? field.answerLabel : ''}
                        </span>
                      )}
                    </strong>
                  ) : (
                    <strong>
                      {field.label && field.label.length > 50 ? (
                        <span className="saas-accordion-heading cw-color--primary">
                          <p>{field.label}</p>
                        </span>
                      ) : (
                        <span className="saas-accordion-heading cw-color--primary">
                          {field.label ? field.label : ''}
                        </span>
                      )}
                    </strong>
                  )}

                  <br />
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
        </div>
      </div>
    </li>
  );
});

const optinsNote = (
  <>
    <strong>Note:&nbsp;</strong>
    The optins you have authored will be displayed in a default order at the
    bottom of your form.
  </>
);

class ProfileFieldsSorting extends Component {
  state = {
    matchProps: this.props.match,
    expand: -1,
    questionInContext: {},
    isCancelModalOpen: false
  };
  constructor(props) {
    super(props);
    this.campaignId = this.props.match.params.id;
    this.handleCollapse = this.handleCollapse.bind(this);
    this.onSortEnd = this.onSortEnd.bind(this);
    this.onSortEndForGenderValues = this.onSortEndForGenderValues.bind(this);
    this.onSortEndForProvinceValues = this.onSortEndForProvinceValues.bind(
      this
    );
    this._handleOnSelectChange = this._handleOnSelectChange.bind(this);
    this.collapsableIcon = [];
    this.collapsablePanel = [];

    this._openIsCancelModal = this._openIsCancelModal.bind(this);
    this._closeIsCancelModal = this._closeIsCancelModal.bind(this);
    this._cancelSaasCampaign = this._cancelSaasCampaign.bind(this);
  }

  _openIsCancelModal(event) {
    event.preventDefault();
    this.setState({
      isCancelModalOpen: true
    });
  }

  _closeIsCancelModal() {
    this.setState({
      isCancelModalOpen: false
    });
  }

  _cancelSaasCampaign(event) {
    event.preventDefault();
    this.setState({
      isCancelModalOpen: false
    });
    this.props.cancelCampaignChanges(this.campaignId);
    this.props.history.push('/campaign-wizard/campaign/' + this.campaignId);
  }

  _handlebackButton = event => {
    event.preventDefault();
    let { campaign } = this.props;
    let status = campaign && campaign.status;
    if (status === '6') {
      if (
        this.props &&
        this.props.lastUpdatedScreen &&
        (this.props.lastUpdatedScreen === 'saas-profile-details' ||
          this.props.lastUpdatedScreen === 'saas-incentive-fields')
      ) {
        this.props.history.push(
          '/campaign-wizard/campaign/' +
            this.campaignId +
            '/' +
            this.props.lastUpdatedScreen
        );
      } else {
        this.props.history.push(
          '/campaign-wizard/campaign/' +
            this.campaignId +
            (constant.featureFlags.ENABLE_SINGLE_PAGE_FIELD_SELECTION
              ? '/configure-form-fields'
              : '/saasqna')
        );
      }
    } else {
      this.props.history.push(
        '/campaign-wizard/campaign/' +
          this.campaignId +
          (constant.featureFlags.ENABLE_SINGLE_PAGE_FIELD_SELECTION
            ? '/configure-form-fields'
            : '/saas-generic-details')
      );
    }
  };

  _handleCancel = () => {
    this.props.setApproverEditCampaignStatus(false);
    this.props.setApproverEditCampaignStatusMessage('');
    this.props.setApproverEditLastUpdatedScreen('');
    this.props.history.push(
      '/campaign-wizard/campaign/' + this.campaignId + '/approval'
    );
  };

  _handleSubmit = async (event, isSaveAsDraft) => {
    let { campaign } = this.props;
    let country = campaign.country && campaign.country.code;
    let status = campaign && campaign.status;
    let sortedFields = this.state.fields
      ? this.state.fields
      : this.props.fields;
    event.preventDefault();
    if (this.state.values && this.state.values.length > 0) {
      let genderObj =
        this.props.fields &&
        this.props.fields.length > 0 &&
        this.props.fields.filter(e => e.dataType === 'gender');

      let genderValuesUnChecked =
        genderObj &&
        genderObj[0].values &&
        genderObj[0].values.filter(elem => !elem.isChecked);

      sortedFields.forEach((elem, index) => {
        if (elem.dataType === 'gender') {
          elem.values = this.state.values;
          genderValuesUnChecked.forEach(e => elem.values.push(e));
          sortedFields[index] = elem;
        }
      });
    }
    if (this.state.provinces && this.state.provinces.length > 0) {
      let provinceObj =
        this.props.fields &&
        this.props.fields.length > 0 &&
        this.props.fields.filter(e => e.dataType === 'stateOrProvince');

      let provinceValues = provinceObj && provinceObj[0].answers;

      sortedFields.forEach((elem, index) => {
        if (elem.dataType === 'stateOrProvince') {
          elem.answers = this.state.provinces;
          sortedFields[index] = elem;
        }
      });
    }
    let updatedData = {
      uid: this.campaignId,
      fields: sortedFields,
      isEdited: campaign.isEdited,
      userName: this.props.userName,
      userEmail: this.props.userEmail
    };
    axios
      .post(constant.serviceUrls.CAMPAIGN_DATA_SERVICE_URL, updatedData)
      .then(response => {
        if (isSaveAsDraft) {
          let notificationData = {};
          notificationData.message = constant.CAMPAIGN_SAVED_AS_DRAFT_TEXT;
          notificationData.link = '';
          notificationData.type = 'warning';
          notificationData.campaign = '';

          this.props.setNotificationMessage(
            notificationData.message,
            notificationData.link,
            notificationData.type,
            notificationData.campaign
          );
          this.props.history.push('/campaign-wizard/dashboard');
        } else {
          if (status === '6') {
            this.props.setApproverEditCampaignStatus(true);
            this.props.setApproverEditCampaignStatusMessage('success');
            this.props.setApproverEditLastUpdatedScreen('');
            this.props.history.push(
              '/campaign-wizard/campaign/' + this.campaignId + '/approval'
            );
          } else {
            this.props.history.push(
              '/campaign-wizard/campaign/' +
                this.campaignId +
                `${
                  epsilonMarket(country)
                    ? `/saas-epsilon-masterdata`
                    : `/review`
                }`
            );
          }
        }
      });
  };

  componentDidMount() {
    this.props.fetchCampaignData(this.campaignId);
  }

  handleCollapse(index) {
    if (this.collapsableIcon[index] && this.collapsablePanel[index]) {
      if (this.collapsablePanel[index].classList.contains('show')) {
        this.collapsablePanel[index].classList.remove('show');
        this.collapsableIcon[index].classList.remove('fa-chevron-up');
        this.collapsableIcon[index].classList.add('fa-chevron-down');
      } else {
        this.collapsablePanel[index].classList.add('show');
        this.collapsableIcon[index].classList.remove('fa-chevron-down');
        this.collapsableIcon[index].classList.add('fa-chevron-up');
      }
    }
  }

  _handleOnSelectChange(selectedOption, event) {
    this.props.updateProfileFields({ [event.name]: selectedOption.value });
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(
      ({ fields }) => ({
        fields: arrayMove(
          this.state.fields ? this.state.fields : this.props.fields,
          oldIndex,
          newIndex
        )
      }),
      () => {
        this.props.updateProfileFields(this.state.fields);
      }
    );
  };

  onSortEndForGenderValues = ({ oldIndex, newIndex }) => {
    let genderValues = this.props.genderValues;
    this.setState(
      ({ values }) => ({
        values: arrayMove(
          this.state.values ? this.state.values : genderValues,
          oldIndex,
          newIndex
        )
      }),
      () => {
        this.props.updateGenderValues(this.state.values);
      }
    );
  };
  onSortEndForProvinceValues = ({ oldIndex, newIndex }) => {
    let provinceValues = this.props.provinceValues;
    this.setState(
      ({ provinces }) => ({
        provinces: arrayMove(
          this.state.provinces ? this.state.provinces : provinceValues,
          oldIndex,
          newIndex
        )
      }),
      () => {
        this.props.updateProvinceValues(this.state.provinces);
      }
    );
  };

  onSortEndForAnswers = (fieldIndex, { oldIndex, newIndex }) => {
    const fields = this.state.fields ? this.state.fields : this.props.fields;
    const question = fields[fieldIndex.sortIndex];

    let sortedAnswers = arrayMove(question.answers, oldIndex, newIndex);
    fields[fieldIndex.sortIndex].answers = sortedAnswers;

    this.setState({
      fields: fields
    });
  };

  render() {
    let { campaign, campaignDetails } = this.props;
    let status = campaign && campaign.status;
    let countrySelected = campaign.country && campaign.country.code;
    return (
      <>
        <div className="col-sm-12">
          <div className="cw-section ml-20">
            {status !== '6' ? (
              <BreadCrumb
                match={this.state.matchProps}
                hideBreadCrumb={campaign && campaign.isEdited}
                campaignType={
                  this.props.campaign && this.props.campaign.campaignType
                }
                websiteType={
                  this.props.campaign &&
                  this.props.campaign.websiteType &&
                  this.props.campaign.websiteType.name
                }
                formType={this.props.campaign && this.props.campaign.type}
                isEpsilonMarket={epsilonMarket(countrySelected)}
              />
            ) : null}

            <div className="row mb-0">
              <div className="col-sm-6">
                <h2 className="cw-heading--secondary mb-3">
                  Choose field sort order
                </h2>
              </div>
            </div>
          </div>
        </div>

        <div className="cw-section ml-20">
          <div className="col-sm-7">
            <div className="cw-section--content">
              <div className="cw-campaign--review">
                <div className="cw-campaign--review-section mb-0">
                  {/* <Alert
                    alertType="saasUpdateMessage"
                    textMessage={constant.SAAS_MESSAGES.saasSortingMessage}
                  /> */}
                  <Modal
                    scrollable={true}
                    isOpen={this.state.isCancelModalOpen}
                    onRequestClose={this._closeIsCancelModal}
                    className="cw-modal cw-modal--branddetail"
                    contentLabel="Campaign Wizard 2.0"
                    style={{
                      overlay: {
                        backgroundColor: constant.EMAIL_OVERLAY_BACKGROUND
                      }
                    }}
                  >
                    <CampaignCancelModel
                      closeIsCancelModal={this._closeIsCancelModal}
                      isCancelContinue={this._cancelSaasCampaign}
                    />
                  </Modal>

                  <p> Please drag and drop to reorder your form fields.</p>

                  <>
                    <br />
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="cw-section--content">
                          <div className="form-group">
                            <SortableList
                              distance={1}
                              items={
                                this.state.fields
                                  ? this.state.fields
                                  : this.props.fields
                              }
                              onSortEnd={this.onSortEnd}
                              onSortEndForAnswers={this.onSortEndForAnswers.bind(
                                this
                              )}
                              handleCollapse={this.handleCollapse}
                              collapsableIcon={this.collapsableIcon}
                              collapsablePanel={this.collapsablePanel}
                              state={this.state}
                              currentObj={this}
                            />{' '}
                          </div>
                          <p className="mb-30">{optinsNote}</p>
                          <div className="cw-form--action-cta">
                            <button
                              type="button"
                              className="btn btn btn-outline-secondary mr-3"
                              onClick={this._handlebackButton}
                            >
                              Back
                            </button>
                            <button
                              type="submit"
                              className="btn btn-primary"
                              onClick={this._handleSubmit}
                            >
                              {status === '6' ? 'Save' : 'Next'}
                            </button>
                            {status === '6' ? (
                              <a
                                href="javascript:void(0)"
                                className="cw-save-to-draft mt-40"
                                style={{ width: '40%' }}
                                onClick={this._handleCancel}
                              >
                                Cancel
                              </a>
                            ) : campaign && campaign.isEdited ? (
                              <a
                                href="javascript:void(0)"
                                className="cw-cancel-edit mt-40"
                                onClick={event =>
                                  this._openIsCancelModal(event)
                                }
                              >
                                Cancel changes
                              </a>
                            ) : (
                              <a
                                href="javascript:void(0)"
                                className="cw-save-to-draft mt-40"
                                style={{ width: '40%' }}
                                onClick={event =>
                                  this._handleSubmit(event, true)
                                }
                              >
                                Save and exit
                              </a>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = state => ({
  campaign: state.profileFieldSorting.campaign,
  campaignDetails: state.profileFieldSorting.campaignDetails || {},
  fields: state.profileFieldSorting.fields || [],
  asyncInProgress: state.profileFieldSorting.asyncInProgress,
  genderValues: state.profileFieldSorting.genderValues,
  provinceValues: state.profileFieldSorting.provinceValues,
  lastUpdatedScreen: state.app.lastUpdatedScreen
});
export default connect(mapStateToProps, {
  fetchCampaignData,
  updateProfileFields,
  setNotificationMessage,
  setApproverEditCampaignStatus,
  setApproverEditCampaignStatusMessage,
  setApproverEditLastUpdatedScreen,
  updateGenderValues,
  updateProvinceValues,
  cancelCampaignChanges
})(ProfileFieldsSorting);
