import {
  EMAIL_ASYNC_IN_PROGRESS,
  UPDATE_EMAIL_BRAND_DETAILS,
  SET_EMAIL_BRAND_DETAILS,
  RESET_EMAIL_BRAND_DETAILS_PAGE,
  EMAIL_CROSS_BRAND,
  FORCE_EMAIL_Type
} from '../../actionTypes';

import {
  createEmailBriefing,
  getEmailBriefingbyUid,
  updateEmailBriefing
} from 'services/emailBriefings';
import { transformEmailBriefingData } from 'selectors';
import { updateEmailBriefingsStore } from './emailDesign';

export const setBrandDetails = brandDetails => ({
  type: SET_EMAIL_BRAND_DETAILS,
  brandDetails
});

export const setEmailLoader = payload => ({
  type: EMAIL_ASYNC_IN_PROGRESS,
  isLoading: payload
});

export const resetEmailBrandDetails = () => ({
  type: RESET_EMAIL_BRAND_DETAILS_PAGE
});

export const toggleEmailCrossBrand = () => ({
  type: EMAIL_CROSS_BRAND
});

export const toggleForceEmailType = forceEmailType => ({
  type: FORCE_EMAIL_Type,
  forceEmailType
});

export const updateBrandDetails = (uid, data, callback) => {
  return async function(dispatch) {
    const { brand, country, language } = data;

    let dataToSend = {
      brand: Array.isArray(brand) ? brand.map(d => d.id) : brand.id,
      country: country.id,
      language: language.id,
      isCrossBrand: data.isCrossBrand
    };

    try {
      dispatch(setEmailLoader(true));
      if (uid) {
        await updateEmailBriefing(uid, dataToSend);
      }
      dispatch(setBrandDetails(uid ? { ...data, uid } : data));
      callback(null, true);
      const emailBriefingNewStore = {
        brand: data.brand.map(d => ({
          _id: d.id,
          code: d.value,
          name: d.label
        })),
        country: {
          _id: data.country.id,
          code: data.country.value,
          name: data.country.label
        },
        language: {
          _id: data.language.id,
          code: data.language.value,
          name: data.language.label
        },
        isCrossBrand: data.isCrossBrand
      };
      if (uid) {
        emailBriefingNewStore.uid = uid;
      }
      dispatch(updateEmailBriefingsStore(emailBriefingNewStore));
    } catch (err) {
      callback(err, null);
    } finally {
      dispatch(setEmailLoader(false));
    }
  };
};

export const getBrandDetails = uid => {
  return function(dispatch) {
    dispatch(setEmailLoader(true));
    getEmailBriefingbyUid(uid)
      .then(result => {
        let transformedData = transformEmailBriefingData(
          result.data,
          'branddetails'
        );
        dispatch(setEmailLoader(false));
        dispatch(setBrandDetails(transformedData));
      })
      .catch(error => {
        dispatch(setEmailLoader(false));
        console.log('getBrandDetails error :', error);
      });
  };
};
