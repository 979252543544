import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAuthorAccessEmailsByCampaignID } from '../../actions/campaignAuthorAccess';

class AuthorAccessReview extends Component {
  render() {
    return this.props.campaignAccessEmails &&
      this.props.campaignAccessEmails.length !== 0 ? (
      <div className="cw-campaign--review-section mb-60">
        <div className="cw-campaign--review-section-header mb-3 d-flex align-items-center clearfix">
          <h3 className="cw-heading--senary">Campaign authoring access</h3>
          <button
            className="btn btn-outline-secondary ml-auto"
            onClick={this.navigateToCampaignAuthoringAccess}
          >
            Edit
          </button>
        </div>
        {this.props.campaignAccessEmails.map((item, index) => (
          <div className="cw-striped-profile-row" key={item.id}>
            <div className="row">
              <div className="col-sm-4">
                <strong>{`Email ${index + 1}`}</strong>
              </div>
              <div className="col-sm-7">{item.email}</div>
            </div>
          </div>
        ))}
        <div class="cw-striped-profile-row"></div>
      </div>
    ) : null;
  }

  componentDidMount() {
    this.props.getAuthorAccessEmailsByCampaignID(this.props.campaignID);
  }

  navigateToCampaignAuthoringAccess = () => {
    this.props.history.push(
      '/campaign-wizard/campaign/' + this.props.campaignID + '/authoringaccess'
    );
  };
}

const mapStateToProps = state => ({
  campaignAccessEmails: state.campaignAuthorAccess.campaignAccessEmails
});

export default connect(
  mapStateToProps,
  {
    getAuthorAccessEmailsByCampaignID
  }
)(AuthorAccessReview);
