import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ReactTooltip from 'react-tooltip';
import { TabPanel } from 'pages/ReportingDashboard/ReportingDashboard';
import EmailGuideline from 'components/EmailGuideline';
import constants from 'constants/config';

export default function ABTestingGuidelines({ onClose }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (e, newValue) => {
    setValue(newValue);
  };

  const tabStyle = { fontFamily: 'Open Sans', textTransform: 'none' };

  return (
    <div className="email-d">
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        disableRipple
        style={{ borderBottom: '1px solid #aaa' }}
      >
        <Tab disableRipple style={tabStyle} label="Configure test variants" />
        <Tab disableRipple style={tabStyle} label="Preview test variants" />
        <Tab disableRipple style={tabStyle} label="Find test results" />
        <Tab disableRipple style={tabStyle} label="AB test overview" />
      </Tabs>
      <TabPanel value={value} index={0}>
        <ol className="email-design-checklist-nested">
          <li className="email-design-checklist-nested__item">
            <span className="item-title">Configuring variant A</span>
            <div style={{ margin: '0 2%' }}>
              <ul
                className={`ml-20 qna_guide_ul`}
                style={{ listStyleType: 'disc', marginLeft: 20 }}
              >
                <li
                  className={`qna_guide_list unordered-list-item`}
                  style={{ listStyleType: 'disc', paddingLeft: 0 }}
                >
                  Click on a&nbsp;
                  <button
                    className="btn btn-primary-link"
                    data-tip="To select a tile, hover over an empty area on the extreme right or left corner of a content row within your editor canvas until you locate a boundary with label 'tile'. Click to select the tile"
                    data-for="tile"
                  >
                    tile
                  </button>
                  &nbsp;to select it
                <ReactTooltip
                  id="tile"
                  place="bottom"
                  type="info"
                  multiline={true}
                  className="cw-tooltip cw-email-tooltip"
                />
                </li>
                <li
                  className={`qna_guide_list unordered-list-item`}
                  style={{ listStyleType: 'disc', paddingLeft: 0 }}
                >
                  When you select a tile, its properties panel will open on the
                  right
                </li>
                <li
                  className={`qna_guide_list unordered-list-item`}
                  style={{ listStyleType: 'disc', paddingLeft: 0 }}
                >
                  Within properties panel, locate and click on the&nbsp;
                  <strong>Configure AB test button</strong>
                </li>
                <li
                  className={`qna_guide_list unordered-list-item`}
                  style={{ listStyleType: 'disc', paddingLeft: 0 }}
                >
                  Mark the tile as variant A and save
                </li>
              </ul>
            </div>
          </li>
          <li className="email-design-checklist-nested__item">
            <span className="item-title">Configuring other variants</span>
            <div style={{ margin: '0 2%' }}>
              <ul
                className={`ml-20 qna_guide_ul`}
                style={{ listStyleType: 'disc', marginLeft: 20 }}
              >
                <li
                  className={`qna_guide_list unordered-list-item`}
                  style={{ listStyleType: 'disc', paddingLeft: 0 }}
                >
                  Select the tile you have configured as Variant A
                </li>
                <li
                  className={`qna_guide_list unordered-list-item`}
                  style={{ listStyleType: 'disc', paddingLeft: 0 }}
                >
                  Duplicate the tile using the copy button available in the tile
                  menu within the design canvas
                </li>
                <li
                  className={`qna_guide_list unordered-list-item`}
                  style={{ listStyleType: 'disc', paddingLeft: 0 }}
                >
                  Change the content of the newly copied tile
                </li>
                <li
                  className={`qna_guide_list unordered-list-item`}
                  style={{ listStyleType: 'disc', paddingLeft: 0 }}
                >
                  Select the newly copied tile to see its properties panel on
                  the right
                </li>
                <li
                  className={`qna_guide_list unordered-list-item`}
                  style={{ listStyleType: 'disc', paddingLeft: 0 }}
                >
                  Within properties panel, locate and click on the&nbsp;
                  <strong>Configure AB test button</strong>
                </li>
                <li
                  className={`qna_guide_list unordered-list-item`}
                  style={{ listStyleType: 'disc', paddingLeft: 0 }}
                >
                  Mark the tile as variant B and save
                </li>
                <li
                  className={`qna_guide_list unordered-list-item`}
                  style={{ listStyleType: 'disc', paddingLeft: 0 }}
                >
                  Repeat this process if you have chosen to configure the 3rd
                  variant as well
                </li>
              </ul>
            </div>
          </li>
        </ol>
        <div style={{ margin: '0 2%' }}>
          <div className="horizontal-line"></div>
        </div>
        {/* <EmailGuideline
          listStyleType="disc"
          noMargin
          hideCloseButton
          isEmailCampaign
          data={constants.EMAIL_SECTIONS}
        /> */}
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ol className="email-design-checklist-nested">
          <li className="email-design-checklist-nested__item">
            <span className="item-title">Previewing all email variants</span>
            <EmailGuideline
              listStyleType="disc"
              listStyleTypeColor="black"
              liClassName="li-item-color"
              noMargin
              isEmailCampaign
              hideCloseButton
              hideHorizontalLine
              data={constants.EMAIL_AB_TEST_PREVIEW_GUIDELINE}
              noUlLineBreak
              noSubTitleLineBreak
            />
          </li>
        </ol>
        <div style={{ margin: '0 2%' }}>
          <div className="horizontal-line"></div>
        </div>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ol className="email-design-checklist-nested">
          <li className="email-design-checklist-nested__item">
            <span className="item-title">Learning test results</span>
            <EmailGuideline
              listStyleType="disc"
              listStyleTypeColor="black"
              liClassName="li-item-color"
              noMargin
              isEmailCampaign
              hideCloseButton
              hideHorizontalLine
              data={constants.EMAIL_AB_TEST_RESULT_GUIDELINE}
              noUlLineBreak
              noSubTitleLineBreak
            />
          </li>
        </ol>
        <div style={{ margin: '0 2%' }}>
          <div className="horizontal-line"></div>
        </div>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <ol className="email-design-checklist-nested">
          <li className="email-design-checklist-nested__item">
            <span className="item-title">Understanding AB tests</span>
            <ol className="email-design-checklist-nested margin-top-none">
              <li className="email-design-checklist-nested__item">
                AB tests are experiments that let you send 2 or 3 variations of
                the same email to different audience segments and measure the
                impact of variables on you campaign performance
              </li>
              <li className="email-design-checklist-nested__item">
                You can test on only one variable at a time, either subject line
                or email content
              </li>
              <li className="email-design-checklist-nested__item">
                You can set your test splits in the following manner
                <EmailGuideline
                  listStyleType="disc"
                  listStyleTypeColor="black"
                  liClassName="li-item-color"
                  noMargin
                  isEmailCampaign
                  hideCloseButton
                  hideHorizontalLine
                  data={constants.EMAIL_AB_TEST_TEST_OVERVIEW_GUIDELINE}
                  noUlLineBreak
                  noSubTitleLineBreak
                />
              </li>
              <li className="email-design-checklist-nested__item">
                The winning variant can be determined using performance metrics like open rate and click through rate
              </li>
            </ol>
          </li>
        </ol>
        <div style={{ margin: '0 2%' }}>
          <div className="horizontal-line"></div>
        </div>
      </TabPanel>
      <div className="mr-20 mt-3">
        <button
          type="submit"
          onClick={onClose}
          className="btn btn-block btn-outline-secondary mb-10 cw-print-hide text-nowrap"
        >
          Close
        </button>
      </div>
    </div>
  );
}
