import React, { Component } from 'react';
import { connect } from 'react-redux';

class CommunicationsConfirmation extends Component {
  componentDidMount() {
    this.smsCampaignId = this.props.match.params.id;
  }

  componentWillUnmount() {
    this.props.history.goForward();
  }

  _handleSubmitButton = () => {
    this.props.history.push(
      '/campaign-wizard/communications/' + this.smsCampaignId
    );
  };
  render() {
    return (
      <div className="col-sm-12">
        <div className="cw-section">
          <div className="row">
            <div className="col-sm-8 cw-section--title mb-30">
              <h2 className="cw-heading--secondary mb-3">
                SMS Campaign Submitted
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-8">
              <div className="cw-section--content">
                <div className="cw-campaign--review">
                  {/* Confirmation Details - Start */}
                  <div className="cw-campaign--review-section mb-40">
                    <div className="cw-campaign--review-section-content">
                      <div className="mb-20">
                        <strong className="mb-10">What happens next?</strong>
                      </div>
                      <div className="cw-text--quinary">
                        <p className="mb-20">
                          Your SMS campaign request has been sent to the 121
                          global agency and they will reach out to you shortly.
                        </p>
                        <p className="mb-20">
                          Once you receive an invoice for this request, you can
                          raise a purchase order in coupa.
                        </p>
                        <p className="mb-20">
                          When the purchase order is created, ACS will receive
                          the request in an email and your SMS campaign creation
                          will be initiated.
                        </p>
                        <strong>
                          <p className="mb-20">SMS Campaign Reference ID : {this.props.match.params.id}</p>
                        </strong>
                        <p className="mb-20">
                          Track the progress of your campaign from the <a href={'/campaign-wizard/smsdashboard'}>SMS campaign dashboard</a>
                        </p>
                        <button
                          type="submit"
                          className="btn btn-primary"
                          onClick={this._handleSubmitButton}
                        >
                          View Campaign Details
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* Confirmation Details - End */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

export default connect(
  mapStateToProps,
  {}
)(CommunicationsConfirmation);
