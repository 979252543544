import React from 'react';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';

import { FormBuilder } from 'components/organisms/FormBuilder';

import { formSchema } from './AddOptOutTextSchema';
import constants from 'constants/config';

export const AddOptOutTextModal = ({ isOpen, addItem, onClose }) => {
  const dispatch = useDispatch();

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="cw-modal cw-modal--newuser"
      style={{
        overlay: {
          backgroundColor: constants.EMAIL_OVERLAY_BACKGROUND
        },
        content: {
          maxHeight: '90vh'
        }
      }}
    >
      <button className="cw-modal--close" title="Close" onClick={onClose}>
        <i className="fas fa-times"></i>
      </button>

      <h3 className="cw-heading--primary mb-10">Add opt out text</h3>

      <FormBuilder
        schema={formSchema(
          dispatch,
          {
            addItem,
            onClose
          },
          {}
        )}
      />
    </Modal>
  );
};
