import React from 'react';

import MissingImage from '../../../images/template-missing-image.png';
import MissingLink from '../../../images/template-missing-link.png';
import MissingABTest from '../../../images/template-missing-abtest.png';
import MissingCoupon from '../../../images/template-missing-coupon.png';
import Check from '../../../images/bi_check.png';
import CheckBig from '../../../images/bi_check-bold.png';


export const SuccessScreenBody = () => (
  <div className="runtest--success mt-4">
    <div className="runtest--success-splash">
      <div className="text-center runtest--init">
        <div
          className="cw-color--primary runtest--error-icon"
          role="status"
        >
          <img src={CheckBig} alt="Success info" />
          <span className="sr-only">Loading errors...</span>
        </div>
        <div className="runtest--initmsg">
          <p>Testing completed successfully</p>
        </div>
      </div>
    </div>

    <div className='runtest--success-list'>
      <p>Great! We could not find any errors.</p>

      <div className="success-items mt-3">
        <div className="success-item mb-4 d-flex justify-content-between align-items-center">
          <div>
            <img src={MissingImage} />
            <span className="heading ml-3">Missing Images</span>
          </div>
          <img src={Check} />
        </div>

        <div className="success-item mb-4 d-flex justify-content-between align-items-center">
          <div>
            <img src={MissingLink} />
            <span className="heading ml-3">Missing Urls</span>
          </div>
          <img src={Check} />
        </div>

        <div className="success-item mb-4 d-flex justify-content-between align-items-center">
          <div>
            <img src={MissingABTest} />
            <span className="heading ml-3">A/B tests</span>
          </div>
          <img src={Check} />
        </div>

        <div className="success-item mb-2 d-flex justify-content-between align-items-center">
          <div>
            <img src={MissingCoupon} />
            <span className="heading ml-3">Coupons</span>
          </div>
          <img src={Check} />
        </div>
      </div>

      <p className="mt-5 mb-4 font-italic template-tests-note">
        Our automated tests still cannot check if you have added privacy policy,
        cookie policy links and all the required legal information or not, so
        please ensure you are using our footer rows as reference as well as global
        and local legal guidance.<br/>
        Please note that after you submit your campaign request, ACS team will run more 
        detailed tests using Email on Acid to checking rendering across email clients and accessibility compliance.
      </p>
    </div>
    
  </div>
);

export const SuccessScreenActions = ({ closeModal }) => (
  <div className="d-flex justify-content-center">
    <button
      className="btn btn btn btn-primary"
      onClick={() => closeModal(false)}
    >
      Close
    </button>
  </div>
);
