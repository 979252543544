import react from 'react';
import constant from './constants';

const ldaproutesConfig = [
  {
    path: '/campaign-wizard/dashboard',
    label: 'Campaigns',
    uid: 1,
    parent: 0
  },
  {
    path: '/campaign-wizard/newcampaign',
    label: 'Campaigns',
    uid: 2,
    parent: 1
  },
  {
    path: '/campaign-wizard/user',
    label: 'Campaigns',
    uid: 3,
    parent: 0
  },
  //new added config
  {
    path: '/campaign-wizard/brand-mgmt',
    label: 'Campaigns',
    uid: 3,
    parent: 0
  },
  {
    path: '/campaign-wizard/campaign/:id',
    label: 'Campaigns',
    uid: 4,
    parent: 0
  },
  {
    path: '/campaign-wizard/campaign/:id/branddetails',
    label: 'Brand details',
    uid: 5,
    parent: 1
  },
  {
    path: '/campaign-wizard/campaign/:id/type',
    label: 'Campaign type',
    uid: 6,
    parent: 5
  },
  {
    path: '/campaign-wizard/campaign/:id/details',
    label: 'Campaign details',
    uid: 7,
    parent: 6
  },

  {
    path: '/campaign-wizard/campaign/:id/authoringaccess',
    label: 'Campaign authoring access',
    uid: 8,
    parent: 7
  },

  {
    path: '/campaign-wizard/campaign/:id/consentpreferences',
    label: 'Regular communications',
    uid: 9,
    parent: 8
  },
  {
    path: '/campaign-wizard/campaign/:id/questionsanswers',
    label: 'Questions & answers',
    uid: 10,
    parent: 9
  },
  {
    path: '/campaign-wizard/campaign/:id/sorting',
    label: 'Question layout',
    uid: 11,
    parent: 10
  },
  {
    path: '/campaign-wizard/campaign/:id/details',
    label: 'Campaign details',
    uid: 12,
    parent: 6
  },
  {
    path: '/campaign-wizard/campaign/:id/saas-profile-details',
    label: 'Profile fields',
    uid: 13,
    parent: 12
  },
  {
    path: '/campaign-wizard/campaign/:id/saas-consent-compliance',
    label: 'Legal/Consents',
    uid: 14,
    parent: 13
  },
  {
    path: '/campaign-wizard/campaign/:id/saas-generic-details',
    label: 'Generic details',
    uid: 15,
    parent: 14
  },
  {
    path: '/campaign-wizard/campaign/:id/saasqna',
    label: 'Q & A',
    uid: 16,
    parent: 15
  },
  {
    path: '/campaign-wizard/campaign/:id/saas-sorting-page',
    label: 'Sorting',
    uid: 16,
    parent: 15
  }
];

const routesConfig = [
  {
    path: '/campaign-wizard/dashboard',
    label: 'Campaigns',
    uid: 1,
    parent: 0
  },
  {
    path: '/campaign-wizard/newcampaign',
    label: 'Campaigns',
    uid: 2,
    parent: 1
  },
  {
    path: '/campaign-wizard/user',
    label: 'Campaigns',
    uid: 3,
    parent: 0
  },
  //new added config
  {
    path: '/campaign-wizard/brand-mgmt',
    label: 'Campaigns',
    uid: 3,
    parent: 0
  },
  {
    path: '/campaign-wizard/campaign/:id',
    label: 'Campaigns',
    uid: 4,
    parent: 0
  },
  {
    path: '/campaign-wizard/campaign/:id/branddetails',
    label: 'Brand details',
    uid: 5,
    parent: 1
  },
  {
    path: '/campaign-wizard/campaign/:id/type',
    label: 'Campaign type',
    uid: 6,
    parent: 5
  },
  {
    path: '/campaign-wizard/campaign/:id/details',
    label: 'Campaign details',
    uid: 7,
    parent: 6
  },
  {
    path: '/campaign-wizard/campaign/:id/consentpreferences',
    label: 'Regular communications',
    uid: 8,
    parent: 7
  },
  {
    path: '/campaign-wizard/campaign/:id/questionsanswers',
    label: 'Questions & answers',
    uid: 9,
    parent: 8
  },
  {
    path: '/campaign-wizard/campaign/:id/sorting',
    label: 'Question layout',
    uid: 10,
    parent: 9
  },
  {
    path: '/campaign-wizard/campaign/:id/details',
    label: 'Campaign details',
    uid: 11,
    parent: 6
  },
  {
    path: '/campaign-wizard/campaign/:id/saas-profile-details',
    label: 'Profile fields',
    uid: 12,
    parent: 11
  },
  {
    path: '/campaign-wizard/campaign/:id/configure-form-fields',
    label: 'Configure fields',
    uid: 18,
    parent: 11
  },
  {
    path: '/campaign-wizard/campaign/:id/saas-consent-compliance',
    label: 'Legal/Consents',
    uid: 13,
    parent: constant.featureFlags.ENABLE_SINGLE_PAGE_FIELD_SELECTION ? 18 : 12
  },
  {
    path: '/campaign-wizard/campaign/:id/saas-generic-details',
    label: 'Generic details',
    uid: 14,
    parent: 13
  },
  {
    path: '/campaign-wizard/campaign/:id/saasqna',
    label: 'Q & A',
    uid: 15,
    parent: 14
  },
  {
    path: '/campaign-wizard/campaign/:id/saas-sorting-page',
    label: 'Sorting',
    uid: 15,
    parent: constant.featureFlags.ENABLE_SINGLE_PAGE_FIELD_SELECTION ? 13 : 14
  },
  {
    path: '/campaign-wizard/campaign/:id/saas-epsilon-masterdata',
    label: 'Epsilon master data',
    uid: 16,
    parent: 15
  },
  {
    path: '/campaign-wizard/releasenotes',
    label: 'Release updates',
    uid: 17,
    parent: 1,
    showCurrent: true
  },
  {
    path: '/campaign-wizard/consumerdataenrichment',
    label: 'Consumer data enrichment',
    uid: 18,
    parent: 1,
    showCurrent: true
  }
];

const qnARoutingConfig = [
  {
    path: '/campaign-wizard/dashboard',
    label: 'Campaigns',
    uid: 1,
    parent: 0
  },
  {
    path: '/campaign-wizard/newcampaign',
    label: 'Campaigns',
    uid: 2,
    parent: 1
  },
  {
    path: '/campaign-wizard/user',
    label: 'Campaigns',
    uid: 3,
    parent: 0
  },
  //new added config
  {
    path: '/campaign-wizard/brand-mgmt',
    label: 'Campaigns',
    uid: 3,
    parent: 0
  },
  {
    path: '/campaign-wizard/campaign/:id',
    label: 'Campaigns',
    uid: 4,
    parent: 0
  },
  {
    path: '/campaign-wizard/campaign/:id/branddetails',
    label: 'Brand details',
    uid: 5,
    parent: 1
  },
  {
    path: '/campaign-wizard/campaign/:id/type',
    label: 'Campaign type',
    uid: 6,
    parent: 5
  },
  {
    path: '/campaign-wizard/campaign/:id/details',
    label: 'Campaign details',
    uid: 7,
    parent: 6
  },
  {
    path: '/campaign-wizard/campaign/:id/saas-profile-details',
    label: 'Profile fields',
    uid: 8,
    parent: 7
  },
  {
    path: '/campaign-wizard/campaign/:id/saasqna',
    label: 'Q & A',
    uid: 9,
    parent: 8
  },
  {
    path: '/campaign-wizard/campaign/:id/saas-consent-compliance',
    label: 'Legal/Consents',
    uid: 10,
    parent: constant.featureFlags.ENABLE_SINGLE_PAGE_FIELD_SELECTION ? 13 : 9
  },
  {
    path: '/campaign-wizard/campaign/:id/saas-generic-details',
    label: 'Generic details',
    uid: 11,
    parent: 10
  },
  {
    path: '/campaign-wizard/campaign/:id/saas-sorting-page',
    label: 'Sorting',
    uid: 12,
    parent: constant.featureFlags.ENABLE_SINGLE_PAGE_FIELD_SELECTION ? 10 : 11
  },
  {
    path: '/campaign-wizard/campaign/:id/configure-form-fields',
    label: 'Configure fields',
    uid: 13,
    parent: 7
  }
];

const incentiveSimpleRoutingConfig = [
  {
    path: '/campaign-wizard/dashboard',
    label: 'Campaigns',
    uid: 1,
    parent: 0
  },
  {
    path: '/campaign-wizard/newcampaign',
    label: 'Campaigns',
    uid: 2,
    parent: 1
  },
  {
    path: '/campaign-wizard/user',
    label: 'Campaigns',
    uid: 3,
    parent: 0
  },
  {
    path: '/campaign-wizard/brand-mgmt',
    label: 'Campaigns',
    uid: 3,
    parent: 0
  },
  {
    path: '/campaign-wizard/campaign/:id',
    label: 'Campaigns',
    uid: 4,
    parent: 0
  },
  {
    path: '/campaign-wizard/campaign/:id/branddetails',
    label: 'Brand details',
    uid: 5,
    parent: 1
  },
  {
    path: '/campaign-wizard/campaign/:id/type',
    label: 'Campaign type',
    uid: 6,
    parent: 5
  },
  {
    path: '/campaign-wizard/campaign/:id/details',
    label: 'Campaign details',
    uid: 7,
    parent: 6
  },
  {
    path: '/campaign-wizard/campaign/:id/saas-profile-details',
    label: 'Profile fields',
    uid: 8,
    parent: 7
  },
  {
    path: '/campaign-wizard/campaign/:id/configure-form-fields',
    label: 'Configure fields',
    uid: 13,
    parent: 7
  },
  {
    path: '/campaign-wizard/campaign/:id/saas-incentive-fields',
    label: 'Campaign Incentive details',
    uid: 9,
    parent: 8
  },
  {
    path: '/campaign-wizard/campaign/:id/saas-consent-compliance',
    label: 'Legal/Consents',
    uid: 10,
    parent: constant.featureFlags.ENABLE_SINGLE_PAGE_FIELD_SELECTION ? 13 : 9
  },
  {
    path: '/campaign-wizard/campaign/:id/saas-generic-details',
    label: 'Generic details',
    uid: 11,
    parent: 10
  },
  {
    path: '/campaign-wizard/campaign/:id/saas-sorting-page',
    label: 'Sorting',
    uid: 12,
    parent: constant.featureFlags.ENABLE_SINGLE_PAGE_FIELD_SELECTION ? 10 : 11
  }
];

const incentiveQnARoutingConfig = [
  {
    path: '/campaign-wizard/dashboard',
    label: 'Campaigns',
    uid: 1,
    parent: 0
  },
  {
    path: '/campaign-wizard/newcampaign',
    label: 'Campaigns',
    uid: 2,
    parent: 1
  },
  {
    path: '/campaign-wizard/user',
    label: 'Campaigns',
    uid: 3,
    parent: 0
  },
  {
    path: '/campaign-wizard/brand-mgmt',
    label: 'Campaigns',
    uid: 3,
    parent: 0
  },
  {
    path: '/campaign-wizard/campaign/:id',
    label: 'Campaigns',
    uid: 4,
    parent: 0
  },
  {
    path: '/campaign-wizard/campaign/:id/branddetails',
    label: 'Brand details',
    uid: 5,
    parent: 1
  },
  {
    path: '/campaign-wizard/campaign/:id/type',
    label: 'Campaign type',
    uid: 6,
    parent: 5
  },
  {
    path: '/campaign-wizard/campaign/:id/details',
    label: 'Campaign details',
    uid: 7,
    parent: 6
  },
  {
    path: '/campaign-wizard/campaign/:id/saas-profile-details',
    label: 'Profile fields',
    uid: 8,
    parent: 7
  },
  {
    path: '/campaign-wizard/campaign/:id/configure-form-fields',
    label: 'Configure fields',
    uid: 14,
    parent: 7
  },
  {
    path: '/campaign-wizard/campaign/:id/saasqna',
    label: 'Q & A',
    uid: 9,
    parent: 8
  },
  {
    path: '/campaign-wizard/campaign/:id/saas-incentive-fields',
    label: 'Campaign Incentive details',
    uid: 10,
    parent: 9
  },
  {
    path: '/campaign-wizard/campaign/:id/saas-consent-compliance',
    label: 'Legal/Consents',
    uid: 11,
    parent: constant.featureFlags.ENABLE_SINGLE_PAGE_FIELD_SELECTION ? 14 : 10
  },
  {
    path: '/campaign-wizard/campaign/:id/saas-generic-details',
    label: 'Generic details',
    uid: 12,
    parent: 11
  },
  {
    path: '/campaign-wizard/campaign/:id/saas-sorting-page',
    label: 'Sorting',
    uid: 13,
    parent: constant.featureFlags.ENABLE_SINGLE_PAGE_FIELD_SELECTION ? 11 : 12
  }
];

const editCampaignRoutesConfig = [
  {
    path: '/campaign-wizard/dashboard',
    label: 'Campaigns',
    uid: 1,
    parent: 0
  },

  {
    path: '/campaign-wizard/newcampaign',
    label: 'Campaigns',
    uid: 2,
    parent: 1
  },
  {
    path: '/campaign-wizard/user',
    label: 'Campaigns',
    uid: 3,
    parent: 0
  },
  //new added config
  {
    path: '/campaign-wizard/brand-mgmt',
    label: 'Campaigns',
    uid: 3,
    parent: 0
  },
  {
    path: '/campaign-wizard/campaign/:id',
    label: 'Campaign Overview',
    uid: 4,
    parent: 1
  },
  {
    path: '/campaign-wizard/campaign/:id/branddetails',
    label: 'Brand details',
    uid: 5,
    parent: 4
  },
  {
    path: '/campaign-wizard/campaign/:id/type',
    label: 'Campaign type',
    uid: 6,
    parent: 5
  },
  {
    path: '/campaign-wizard/campaign/:id/details',
    label: 'Campaign details',
    uid: 7,
    parent: 6
  },

  {
    path: '/campaign-wizard/campaign/:id/consentpreferences',
    label: 'Regular communications',
    uid: 8,
    parent: 7
  },
  {
    path: '/campaign-wizard/campaign/:id/questionsanswers',
    label: 'Questions & answers',
    uid: 9,
    parent: 8
  },
  {
    path: '/campaign-wizard/campaign/:id/sorting',
    label: 'Question layout',
    uid: 10,
    parent: 9
  },
  {
    path: '/campaign-wizard/campaign/:id/details',
    label: 'Campaign details',
    uid: 11,
    parent: 6
  },
  {
    path: '/campaign-wizard/campaign/:id/saas-profile-details',
    label: 'Profile fields',
    uid: 12,
    parent: 11
  },
  {
    path: '/campaign-wizard/campaign/:id/saas-consent-compliance',
    label: 'Legal/Consents',
    uid: 13,
    parent: 12
  },
  {
    path: '/campaign-wizard/campaign/:id/saas-generic-details',
    label: 'Generic details',
    uid: 14,
    parent: 13
  },
  {
    path: '/campaign-wizard/campaign/:id/saasqna',
    label: 'Q & A',
    uid: 15,
    parent: 14
  },
  {
    path: '/campaign-wizard/campaign/:id/saas-sorting-page',
    label: 'Sorting',
    uid: 15,
    parent: 14
  }
];

const incentiveRoutesConfig = [
  {
    path: '/campaign-wizard/dashboard',
    label: 'Campaigns',
    uid: 1,
    parent: 0
  },
  {
    path: '/campaign-wizard/newcampaign',
    label: 'Campaigns',
    uid: 2,
    parent: 1
  },
  {
    path: '/campaign-wizard/user',
    label: 'Campaigns',
    uid: 3,
    parent: 0
  },
  //new added config
  {
    path: '/campaign-wizard/brand-mgmt',
    label: 'Campaigns',
    uid: 3,
    parent: 0
  },
  {
    path: '/campaign-wizard/campaign/:id',
    label: 'Campaigns',
    uid: 4,
    parent: 0
  },
  {
    path: '/campaign-wizard/campaign/:id/branddetails',
    label: 'Brand details',
    uid: 5,
    parent: 1
  },
  {
    path: '/campaign-wizard/campaign/:id/type',
    label: 'Campaign type',
    uid: 6,
    parent: 5
  },
  {
    path: '/campaign-wizard/campaign/:id/details',
    label: 'Campaign details',
    uid: 7,
    parent: 6
  },
  {
    path: '/campaign-wizard/campaign/:id/questionsanswers',
    label: 'Questions & answers',
    uid: 8,
    parent: 7
  },
  {
    path: '/campaign-wizard/campaign/:id/sorting',
    label: 'Question layout',
    uid: 9,
    parent: 8
  },
  {
    path: '/campaign-wizard/campaign/:id/details',
    label: 'Campaign details',
    uid: 10,
    parent: 6
  },
  {
    path: '/campaign-wizard/campaign/:id/saas-profile-details',
    label: 'Profile fields',
    uid: 11,
    parent: 10
  },
  {
    path: '/campaign-wizard/campaign/:id/saas-consent-compliance',
    label: 'Legal/Consents',
    uid: 12,
    parent: 11
  },
  {
    path: '/campaign-wizard/campaign/:id/saas-generic-details',
    label: 'Generic details',
    uid: 13,
    parent: 12
  },
  {
    path: '/campaign-wizard/campaign/:id/saasqna',
    label: 'Q & A',
    uid: 14,
    parent: 13
  },
  {
    path: '/campaign-wizard/campaign/:id/saas-sorting-page',
    label: 'Sorting',
    uid: 14,
    parent: 13
  }
];

const incentiveLdaproutesConfig = [
  {
    path: '/campaign-wizard/dashboard',
    label: 'Campaigns',
    uid: 1,
    parent: 0
  },
  {
    path: '/campaign-wizard/newcampaign',
    label: 'Campaigns',
    uid: 2,
    parent: 1
  },
  {
    path: '/campaign-wizard/user',
    label: 'Campaigns',
    uid: 3,
    parent: 0
  },
  //new added config
  {
    path: '/campaign-wizard/brand-mgmt',
    label: 'Campaigns',
    uid: 3,
    parent: 0
  },
  {
    path: '/campaign-wizard/campaign/:id',
    label: 'Campaigns',
    uid: 4,
    parent: 0
  },
  {
    path: '/campaign-wizard/campaign/:id/branddetails',
    label: 'Brand details',
    uid: 5,
    parent: 1
  },
  {
    path: '/campaign-wizard/campaign/:id/type',
    label: 'Campaign type',
    uid: 6,
    parent: 5
  },
  {
    path: '/campaign-wizard/campaign/:id/details',
    label: 'Campaign details',
    uid: 7,
    parent: 6
  },
  {
    path: '/campaign-wizard/campaign/:id/authoringaccess',
    label: 'Campaign authoring access',
    uid: 8,
    parent: 7
  },
  {
    path: '/campaign-wizard/campaign/:id/questionsanswers',
    label: 'Questions & answers',
    uid: 9,
    parent: 8
  },
  {
    path: '/campaign-wizard/campaign/:id/sorting',
    label: 'Question layout',
    uid: 10,
    parent: 9
  },
  {
    path: '/campaign-wizard/campaign/:id/details',
    label: 'Campaign details',
    uid: 11,
    parent: 6
  },
  {
    path: '/campaign-wizard/campaign/:id/saas-profile-details',
    label: 'Profile fields',
    uid: 12,
    parent: 11
  },
  {
    path: '/campaign-wizard/campaign/:id/saas-consent-compliance',
    label: 'Legal/Consents',
    uid: 13,
    parent: 12
  },
  {
    path: '/campaign-wizard/campaign/:id/saas-generic-details',
    label: 'Generic details',
    uid: 14,
    parent: 13
  },
  {
    path: '/campaign-wizard/campaign/:id/saasqna',
    label: 'Q & A',
    uid: 15,
    parent: 14
  },
  {
    path: '/campaign-wizard/campaign/:id/saas-sorting-page',
    label: 'Sorting',
    uid: 15,
    parent: 14
  }
];

const epsilonRoute = [
  {
    path: '/campaign-wizard/dashboard',
    label: 'Campaigns',
    uid: 1,
    parent: 0
  },
  {
    path: '/campaign-wizard/newcampaign',
    label: 'Campaigns',
    uid: 2,
    parent: 1
  },
  {
    path: '/campaign-wizard/user',
    label: 'Campaigns',
    uid: 3,
    parent: 0
  },
  //new added config
  {
    path: '/campaign-wizard/brand-mgmt',
    label: 'Campaigns',
    uid: 3,
    parent: 0
  },
  {
    path: '/campaign-wizard/campaign/:id',
    label: 'Campaigns',
    uid: 4,
    parent: 0
  },
  {
    path: '/campaign-wizard/campaign/:id/branddetails',
    label: 'Brand details',
    uid: 5,
    parent: 1
  },
  {
    path: '/campaign-wizard/campaign/:id/type',
    label: 'Campaign type',
    uid: 6,
    parent: 5
  },
  {
    path: '/campaign-wizard/campaign/:id/details',
    label: 'Campaign details',
    uid: 7,
    parent: 6
  },
  {
    path: '/campaign-wizard/campaign/:id/saas-profile-details',
    label: 'Profile fields',
    uid: 8,
    parent: 7
  },
  {
    path: '/campaign-wizard/campaign/:id/saas-consent-compliance',
    label: 'Legal/Consents',
    uid: 9,
    parent: constant.featureFlags.ENABLE_SINGLE_PAGE_FIELD_SELECTION ? 13 : 8
  },
  {
    path: '/campaign-wizard/campaign/:id/saas-generic-details',
    label: 'Generic details',
    uid: 10,
    parent: 9
  },
  {
    path: '/campaign-wizard/campaign/:id/saas-sorting-page',
    label: 'Sorting',
    uid: 11,
    parent: constant.featureFlags.ENABLE_SINGLE_PAGE_FIELD_SELECTION ? 9 : 10
  },
  {
    path: '/campaign-wizard/campaign/:id/saas-epsilon-masterdata',
    label: 'Epsilon master data',
    uid: 12,
    parent: 11
  },
  {
    path: '/campaign-wizard/campaign/:id/configure-form-fields',
    label: 'Configure fields',
    uid: 13,
    parent: 7
  }
];

const epsilonQARoute = [
  {
    path: '/campaign-wizard/dashboard',
    label: 'Campaigns',
    uid: 1,
    parent: 0
  },
  {
    path: '/campaign-wizard/newcampaign',
    label: 'Campaigns',
    uid: 2,
    parent: 1
  },
  {
    path: '/campaign-wizard/user',
    label: 'Campaigns',
    uid: 3,
    parent: 0
  },
  //new added config
  {
    path: '/campaign-wizard/brand-mgmt',
    label: 'Campaigns',
    uid: 3,
    parent: 0
  },
  {
    path: '/campaign-wizard/campaign/:id',
    label: 'Campaigns',
    uid: 4,
    parent: 0
  },
  {
    path: '/campaign-wizard/campaign/:id/branddetails',
    label: 'Brand details',
    uid: 5,
    parent: 1
  },
  {
    path: '/campaign-wizard/campaign/:id/type',
    label: 'Campaign type',
    uid: 6,
    parent: 5
  },
  {
    path: '/campaign-wizard/campaign/:id/details',
    label: 'Campaign details',
    uid: 7,
    parent: 6
  },
  {
    path: '/campaign-wizard/campaign/:id/saas-profile-details',
    label: 'Profile fields',
    uid: 8,
    parent: 7
  },
  {
    path: '/campaign-wizard/campaign/:id/saas-epsilon-qna',
    label: 'Q & A',
    uid: 9,
    parent: 8
  },
  {
    path: '/campaign-wizard/campaign/:id/saas-consent-compliance',
    label: 'Legal/Consents',
    uid: 10,
    parent: constant.featureFlags.ENABLE_SINGLE_PAGE_FIELD_SELECTION ? 14 : 9
  },
  {
    path: '/campaign-wizard/campaign/:id/saas-generic-details',
    label: 'Generic details',
    uid: 11,
    parent: 10
  },
  {
    path: '/campaign-wizard/campaign/:id/saas-sorting-page',
    label: 'Sorting',
    uid: 12,
    parent: constant.featureFlags.ENABLE_SINGLE_PAGE_FIELD_SELECTION ? 10 : 11
  },
  {
    path: '/campaign-wizard/campaign/:id/saas-epsilon-masterdata',
    label: 'Epsilon master data',
    uid: 13,
    parent: 12
  },
  {
    path: '/campaign-wizard/campaign/:id/configure-form-fields',
    label: 'Configure fields',
    uid: 14,
    parent: 7
  }
];

// email briefing routes
const emailBriefingRoutes = [
  {
    path: '/campaign-wizard',
    label: 'Campaigns',
    uid: 1,
    parent: 0
  },
  {
    path: '/campaign-wizard/new-email-campaign',
    label: 'Brand details',
    uid: 2,
    parent: 1
  },
  {
    path: '/campaign-wizard/emailbriefing/:id/emailtype',
    label: 'Email type',
    uid: 3,
    parent: 4
  },
  {
    path: '/campaign-wizard/emailbriefing/:id/branddetails',
    label: 'Brand details',
    uid: 4,
    parent: 1
  },
  {
    path: '/campaign-wizard/emailbriefing/:id/email-campaign-details',
    label: 'Email campaign details',
    uid: 5,
    parent: 3
  },
  {
    path: '/campaign-wizard/emailbriefing/:id/email-template',
    label: 'Select email template',
    uid: 6,
    parent: 5
  },
  {
    path: '/campaign-wizard/emailbriefing/:id/email-editor',
    label: 'Email editor',
    uid: 7,
    parent: 6
  }
];

export {
  routesConfig,
  editCampaignRoutesConfig,
  ldaproutesConfig,
  incentiveRoutesConfig,
  incentiveLdaproutesConfig,
  qnARoutingConfig,
  incentiveSimpleRoutingConfig,
  incentiveQnARoutingConfig,
  epsilonRoute,
  epsilonQARoute,
  emailBriefingRoutes
};
