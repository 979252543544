import React from 'react';
import classNames from 'classnames';
import { ReactComponent as WinningVariantIcon } from '../../../images/winning_variant_icon.svg';
import constants from 'constants/config';

const WinningVariantIndicator = ({ variant }) => {
  return (
    <div className="d-flex align-items-center">
      <WinningVariantIcon />
      <span className="winning-variant-text">{`Winning test ${constants.EMAIL_ABTEST_VARIANT_MAP[variant]}`}</span>
    </div>
  );
};

export default WinningVariantIndicator;
