import {
	DOMAIN_ALREADY_EXISTS,
	GET_DOMAIN_DATA,
	NEW_DOMAIN_ADDED,
	UPDATE_DOMAIN,
	DELETE_DOMAIN,
	DOMAIN_CANNOT_BE_DELETED,
	ASYNC_IN_PROGRESS_REQUESTER_TAB,
} from '../../actionTypes';

const initialState = {
	asyncInProgress: false,
	domainExists: false,
	domainData: [],
	isNewDomainAdded: false,
	newDomainName: undefined,
	isDomainUpdated: false,
	isDomainDeleted: false,
	deletedDomainName: undefined,
	isDomainCannotDeleted: false,
};

export default function(state = initialState, action) {
	switch (action.type) {
		case ASYNC_IN_PROGRESS_REQUESTER_TAB: {
			return {
				...state,
				asyncInProgress: action.isLoading,
			};
		}
		case DOMAIN_ALREADY_EXISTS: {
			return { ...state, domainExists: true };
		}
		case GET_DOMAIN_DATA: {
			return { ...state, domainData: action.domainList || [] };
		}
		case NEW_DOMAIN_ADDED: {
			return {
				...state,
				newDomainName: action.newDomainName,
				isDomainDeleted: false,
				isDomainUpdated: false,
				isNewDomainAdded: true,
				isDomainCannotDeleted: false,
				domainExists: false,
			};
		}
		case UPDATE_DOMAIN: {
			return {
				...state,
				newDomainName: action.newDomainName,
				isDomainDeleted: false,
				isDomainUpdated: true,
				isNewDomainAdded: false,
				isDomainCannotDeleted: false,
				domainExists: false,
			};
		}
		case DELETE_DOMAIN: {
			return {
				...state,
				deletedDomainName: action.deletedDomainName,
				isDomainDeleted: true,
				isDomainUpdated: false,
				isNewDomainAdded: false,
				isDomainCannotDeleted: false,
				domainExists: false,
			};
		}
		case DOMAIN_CANNOT_BE_DELETED: {
			return {
				...state,
				deletedDomainName: action.deletedDomainName,
				isDomainDeleted: false,
				isDomainUpdated: false,
				isNewDomainAdded: false,
				isDomainCannotDeleted: true,
				domainExists: false,
			};
		}
		default:
			return state;
	}
}
