import React, { useEffect, useState } from 'react';
import { fetchHistoryLogs } from 'actions/history';
import { useDispatch, useSelector } from 'react-redux';
import HistoryComponent from 'components/history';
import { getEmailBriefing } from 'actions/emailbriefing/emailDesign';
import Toolbar from 'components/common/toolbar';
import Layout from 'components/organisms/Layout/Layout';
import PageHeader from 'components/organisms/PageHeader/PageHeader';
import { openFeedbackForm } from 'actions/feedbackForm';
import constants from 'constants/config';
import TemplatePreview from 'components/organisms/TemplatePreview/TemplatePreview';
import EmailTest from 'components/EmailTest';
import Modal from 'react-modal';
import EmailSummaryCards from 'components/organisms/EmailSummaryCards/EmailSummaryCards';
import EmailOverviewTables from 'components/organisms/EmailOverviewTables/EmailOverviewTables';
import { exportEmailHTML } from 'services/emailTemplateEdit';
import { deleteEmailCampaignDetails } from 'actions/emailbriefing/emailCampaignDetails';
import { setNotificationMessage } from 'actions/dashboard';
import { flushSelectedTemplate } from 'actions/emailbriefing/selectedTemplate';
import TemplatePreviewOverlay from 'pages/EmailCampaignTemplate/templatePreviewOverlay';
import Alert from 'components/Alert';
import EmailApproverInfo from 'components/organisms/EmailApproverInfo';
import DesignEmail from 'components/organisms/DesignEmail/DesignEmail';
import WinningVariantInfo from 'components/organisms/WinningVariantInfo';
import { checkEmailCampaignEditRights } from 'selectors';
import NotificationMessage from 'components/Messages';
import _ from 'lodash';
import { LinkButton } from 'components/atoms/LinkButton';
import { DeleteConfirmationModal } from './DeleteConfirmationModal';
import Loader from 'components/Loader';

const EmailOverview = props => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isTemplatePreviewOpen, setIsTemplatePreviewOpen] = useState(false);
  const [selectedTemplateForPreview, setSelectedTemplateForPreview] = useState(
    {}
  );
  const [
    isDeleteConfirmationModalOpen,
    setIsDeleteConfirmationModalOpen
  ] = useState(false);

  const { history, match } = props;
  const dispatch = useDispatch();
  useEffect(() => {
    const uid = props.match.params.id;
    dispatch(fetchHistoryLogs(uid));
    dispatch(getEmailBriefing(uid));
  }, []);

  const emailProps = useSelector(state => ({
    historyLogs: state.historyLogs,
    emailBriefing: state.emailDesign.emailBriefing,
    isLoading: state.emailDesign.isLoading,
    campaignConfig: state.app.campaignConfig,
    authorizedUserData: state.authorized.authorizedUserData
  }));

  const { notificationMessage, notificationType } = useSelector(
    state => state.dashboard
  );

  if (!emailProps.emailBriefing) return null;

  const {
    summary,
    status,
    emailTemplateEditId,
    rejectComments,
    requestInfoText,
    nmiAssignedToRequestor
  } = emailProps && emailProps.emailBriefing;
  const { id: uid } = match.params;
  const campaignName = (summary && summary.campaignName) || 'Untitled';
  const { isLoading } = emailProps;

  const handleOnOpenFeedback = () => {
    dispatch(
      openFeedbackForm({
        campaignUid: props.match.params.id,
        userId: props.authorizedUserData._id,
        defaultCampaignRequest: 'email',
        showCampaignRequest: false
      })
    );
  };

  const openTestEmailModel = () => {
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };
  /**
   * Export button click handler
   */
  const onExportHandler = async () => {
    const res = await exportEmailHTML(
      props.match.params.id,
      props.userEmail,
      props.userName,
      { campaignName }
    );
    const uid = props.match.params.id;
    dispatch(fetchHistoryLogs(uid));
  };

  const deleteEmailBriefing = () => {
    dispatch(
      deleteEmailCampaignDetails(uid, () => {
        dispatch(
          setNotificationMessage(
            `${campaignName} has been deleted.`,
            undefined,
            'warning'
          )
        );
        dispatch(flushSelectedTemplate());
        history.push(`/campaign-wizard/email-dashboard`);
      })
    );
  };

  const continueCampaign = () => {
    history.push({
      pathname: `/campaign-wizard/emailbriefing/${uid}/branddetails`
    });
  };

  const isDraft =
    String(status) === '1' ||
    String(status) === '5' ||
    (nmiAssignedToRequestor === undefined &&
      String(status) === constants.EMAIL_STATUS.AWAITING_INFO) ||
    (String(status) === '8' && nmiAssignedToRequestor === true);
  const isRejected =
    String(status) === constants.EMAIL_STATUS.REJECTED ||
    (nmiAssignedToRequestor === undefined &&
      String(status) === constants.EMAIL_STATUS.AWAITING_INFO) ||
    (String(status) === constants.EMAIL_STATUS.AWAITING_INFO &&
      nmiAssignedToRequestor === true);
  const handleTemplatePreview = template => {
    setIsTemplatePreviewOpen(true);
    setSelectedTemplateForPreview(template);
  };

  const handleCloseTemplatePreview = () => {
    setIsTemplatePreviewOpen(false);
    setSelectedTemplateForPreview({});
  };

  const refetchEmailBriefing = () => {
    const uid = props.match.params.id;
    // if (!emailBriefing || emailBriefing.uid !== uid) {
    dispatch(getEmailBriefing(uid, { disableLoader: true }));
  };

  const refetchEmailHistoryLogs = () => {
    const uid = props.match.params.id;
    dispatch(fetchHistoryLogs(uid));
  };

  const templateExists = !_.isEmpty(
    emailProps?.emailBriefing?.emailTemplateEditId
  );

  const redirect = lastUpdatedScreen => {
    let path = `/campaign-wizard/emailbriefing/${uid}/`;

    if (
      ['new-email-campaign-email-summary', 'email-collaborators'].includes(
        lastUpdatedScreen
      )
    ) {
      path += 'email-summary';
      history.push(path);
    } else if (
      ['email-abtesting', 'campaign-attributes'].includes(lastUpdatedScreen)
    ) {
      path += 'email-attributes';
      history.push(path);
    } else {
      history.push(path + lastUpdatedScreen);
    }
  };

  const resumeWhereYouLeft = () => {
    const uid = match.params.id;
    const lastUpdatedScreen = emailProps?.emailBriefing?.lastUpdatedScreen;
    const isCampaignRejected =
      emailProps?.emailBriefing?.status === constants.EMAIL_STATUS.REJECTED;
    if (lastUpdatedScreen && !isCampaignRejected) {
      redirect(lastUpdatedScreen);
    } else {
      history.push(`/campaign-wizard/emailbriefing/${uid}/branddetails`);
    }
  };

  const goToFirstPage = () => {
    const uid = match.params.id;
    history.push(`/campaign-wizard/emailbriefing/${uid}/branddetails`);
  };

  const handleDesignEmailClick = () => {
    const uid = match.params.id;
    history.push(
      `/campaign-wizard/emailbriefing/${uid}/${
        templateExists ? 'email-editor' : 'email-template'
      }`
    );
  };

  const userHasEditRights = checkEmailCampaignEditRights(
    emailProps.emailBriefing,
    props.authorizedUserData
  );

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="cw-modal cw-modal--test-email-model"
        contentLabel="Field Types"
        style={{
          overlay: {
            backgroundColor: constants.EMAIL_OVERLAY_BACKGROUND
          }
        }}
      >
        <EmailTest
          onClose={closeModal}
          userEmail={props.userEmail}
          uid={props.match.params.id}
          userName={props.userName}
          userGroup={props.authorizedUserData.group}
          emailBriefing={emailProps.emailBriefing}
          refetchEmailBriefing={refetchEmailBriefing}
          refetchEmailHistoryLogs={refetchEmailHistoryLogs}
        />
      </Modal>

      <DeleteConfirmationModal
        isOpen={isDeleteConfirmationModalOpen}
        closeModal={setIsDeleteConfirmationModalOpen}
        deleteCampaign={deleteEmailBriefing}
        allowDelete={isDraft && userHasEditRights}
      />

      <div className="skypink ml-5 mr-5">
        <div className="cw-section cw-overview">
          <div className="draft_button_section_main">
            <Alert
              type={'warning'}
              message={rejectComments ? rejectComments : ''}
              alertType={'rejectcomment'}
              showComponent={status === '5'}
            />
            <Alert
              type={'warning'}
              className={'mt-4'}
              message={requestInfoText ? requestInfoText : ''}
              alertType={'requestMoreInfo'}
              showComponent={status === '8'}
              title={
                nmiAssignedToRequestor == undefined || nmiAssignedToRequestor
                  ? `ACS team has requested more from requestor  `
                  : `ACS team is awaiting more information internally - there is no next step on requestor `
              }
            />
            <NotificationMessage
              showcomponent={
                status !== constants.EMAIL_STATUS.DRAFT && notificationMessage
              }
              message={notificationMessage}
              type={notificationType}
              link={''}
              campaignname={''}
              onClose={() => {
                dispatch(setNotificationMessage('', undefined, ''));
              }}
            />
            <PageHeader
              heading={(summary && summary.campaignName) || 'Untitled'}
              status={status}
              subHeading={
                isDraft ? 'Continue creating your campaign' : undefined
              }
              review
            />
            <div className="toolbar-scroll-fix cw-print-hide">
              <Toolbar
                showPrint={!isDraft}
                showFeedbackLink={constants.featureFlags.ENABLE_FEEDBACK_FORM}
                style={{ marginBottom: 10 }}
                onDelete={() => setIsDeleteConfirmationModalOpen(true)}
                onOpenFeedback={handleOnOpenFeedback}
                isDraft={isDraft}
                userHasEditRights={userHasEditRights}
              />
            </div>
          </div>
          {console.log('emailProps', emailProps)}
          {status === constants.EMAIL_STATUS.AWAITING_FOR_APPROVAL ? (
            <div className="mb-30">
              <EmailApproverInfo
                emailBriefing={emailProps && emailProps.emailBriefing}
                userData={emailProps && emailProps.authorizedUserData}
              />
            </div>
          ) : null}
          {isRejected && userHasEditRights && (
            <div className="d-flex">
              <div className="continue-section-empty"></div>
              <div className="continue-camp-btn-container">
                <button
                  className="btn btn-primary w-100"
                  onClick={goToFirstPage}
                >
                  Continue
                </button>
              </div>
            </div>
          )}
          {isDraft && !isRejected ? (
            userHasEditRights ? (
              <div className="d-flex">
                <div className="continue-section-empty"></div>
                <div className="continue-camp-btn-container">
                  <div className="d-flex flex-column">
                    <button
                      className="btn btn-primary resume-email-camp"
                      onClick={resumeWhereYouLeft}
                    >
                      Resume where you left
                    </button>
                    <button
                      className="btn btn-outline-secondary mt-10"
                      onClick={goToFirstPage}
                    >
                      Start over
                    </button>
                  </div>
                </div>
              </div>
            ) : null
          ) : null}
          <Layout
            className="mt-20"
            sidebar={
              emailProps.emailBriefing.emailTemplateEditId?.thumbnail ? (
                <div className="winning-variant-wrapper">
                  <TemplatePreview
                    template={
                      emailTemplateEditId && emailTemplateEditId.templateObject
                    }
                    hideActionBar={isDraft}
                    emailBriefing={emailProps.emailBriefing}
                    thumbnail={
                      emailTemplateEditId && emailTemplateEditId.thumbnail
                    }
                    title={emailTemplateEditId && emailTemplateEditId.title}
                    sentTestEmailOnClick={openTestEmailModel}
                    isExportButton={true}
                    onExport={onExportHandler}
                    refetchEmailHistoryLogs={refetchEmailHistoryLogs}
                    handleDesignEmailClick={handleDesignEmailClick}
                    isDraft={false}
                  />
                  <WinningVariantInfo
                    className="mt-10"
                    emailBriefing={emailProps.emailBriefing}
                  />
                </div>
              ) : (
                <DesignEmail
                  buttonLabel={false ? 'Design email' : ''}
                  onClick={handleDesignEmailClick}
                />
              )
            }
          >
            <EmailSummaryCards emailProps={emailProps} />
            <EmailOverviewTables
              editable={false}
              emailProps={emailProps}
              handleTemplatePreview={handleTemplatePreview}
            />
            <div className="overview-page-history mt-20">
              {emailProps.historyLogs && emailProps.historyLogs.length ? (
                <HistoryComponent
                  historyLogs={emailProps.historyLogs}
                  isEmailBrief={true}
                />
              ) : null}
            </div>
          </Layout>
        </div>
      </div>
      <TemplatePreviewOverlay
        isOpen={isTemplatePreviewOpen}
        selectedTemplateForPreview={selectedTemplateForPreview}
        handleCloseForm={handleCloseTemplatePreview}
        hideSelectBtn
      />
    </>
  );
};

export default EmailOverview;
