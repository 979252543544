import React from 'react';
import { useState } from 'react';
import Select from 'react-select';
const AddNewQuestion = props => {
  const [addNewQuestions, setNewQuestions] = useState(false);
  const [masterDataQue, setMasterDataQue] = useState({
    isNew: true,
    istranslatedNew: true,
    translatedLabel: '',
    englishLabel: '',
    dataType: 'questionAnswerPair',
    id: '',
    label: 'Free Text Question',
    mandatoryToggle: false,
    mandatoryMessage: '',
    questionLabel: '',
    questionType: 'Free text',
    toolTip: '',
    translatedId: '',
    value: 'freeText',
    reasonForCreation: ''
  });

  const questionTypes = [
    {
      label: 'Data enrichment',
      value: 'Data enrichment',
      id: 1
    },
    {
      label: 'Operational',
      value: 'Operational',
      id: 2
    }
  ];
  const handleSelectData = (e, selectedOption) => {
    document.getElementById('ReasonForMasterdata').classList.add('d-none');
    setMasterDataQue({
      ...masterDataQue,
      reasonForCreation: selectedOption.value
    });
  };
  const handleChangeInput = e => {
    document.getElementById(`${e.target.name}`).classList.add('d-none');
    setMasterDataQue({ ...masterDataQue, [e.target.name]: e.target.value });
  };
  const handleSubmit = () => {
    let isValid = true;
    if (masterDataQue && masterDataQue.reasonForCreation.trim().length === 0) {
      document.getElementById('ReasonForMasterdata').classList.remove('d-none');
      isValid = false;
    } else {
      document.getElementById('ReasonForMasterdata').classList.add('d-none');
    }
    if (masterDataQue && masterDataQue.englishLabel.trim().length === 0) {
      document.getElementById('englishLabel').classList.remove('d-none');
      isValid = false;
    } else {
      document.getElementById('englishLabel').classList.add('d-none');
    }
    if (masterDataQue && masterDataQue.translatedLabel.trim().length === 0) {
      document.getElementById('translatedLabel').classList.remove('d-none');
      isValid = false;
    } else {
      document.getElementById('translatedLabel').classList.add('d-none');
    }
    if (isValid) {
      props.getNewQueCallback(masterDataQue);
    }
  };
  return (
    <>
      {addNewQuestions ? (
        <div style={{ margin: '2%', marginBottom: '0' }}>
          <div className="request-new-modal-header">
            <span
              className="modal-header-back"
              onClick={() => setNewQuestions(false)}
            >
              <i class="fas fa-arrow-left"></i>
              <span>Back to master list</span>
            </span>
            <span
              className={'qna-guideline-close'}
              onClick={props.CloseAddNewQuestionModal}
              style={{ right: '-4%' }}
            ></span>
          </div>

          <div className="add-new-question-body">
            <h4>Request new question</h4>
            <div className="note-question">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 30 30"
                fill="none"
              >
                <path
                  d="M27 0H3C1.35 0 0.015 1.35 0.015 3L0 30L6 24H27C28.65 24 30 22.65 30 21V3C30 1.35 28.65 0 27 0ZM16.5 13.5H13.5V4.5H16.5V13.5ZM16.5 19.5H13.5V16.5H16.5V19.5Z"
                  fill="#C71F1F"
                  fill-opacity="0.9"
                />
              </svg>
              &nbsp; Important Note:
            </div>
            <div className="addnew-question-text">
              <h6>Can’t find the question you are looking for?</h6>
              <p>
                You can request new. but there will be longer than usual
                turnaround time due to tighter data governance rules. Ensure you
                have read our guidelines and are not requesting master data that
                is duplicate or relates to a PII field.
              </p>
            </div>
          </div>
          <div className="form-controler mb-50">
            <div className="form-group">
              <input
                value={masterDataQue.englishLabel}
                type="text"
                name="englishLabel"
                className="form-control"
                data-required="true"
                placeholder="Enter new question (English)"
                onChange={handleChangeInput}
              />
              <div className="sufix">
                <i class="fas fa-database"></i> New Master Data
              </div>
            </div>
            <span id="englishLabel" class="cw-error d-none" data-control="name">
              <i class="fas fa-exclamation-triangle mr-10"></i>
              Please enter a short question name
            </span>
            <div className="form-group">
              <input
                value={masterDataQue.translatedLabel}
                type="text"
                name="translatedLabel"
                className="form-control"
                data-required="true"
                placeholder="Enter new question (Translated)"
                onChange={handleChangeInput}
              />
              <div className="sufix">
                <i class="fas fa-database"></i> New Master Data
              </div>
            </div>
            <span
              id="translatedLabel"
              class="cw-error d-none"
              data-control="name"
            >
              <i class="fas fa-exclamation-triangle mr-10"></i>
              Please enter a short translated question name
            </span>
            <div className="mb-50">
              <div
                className="form-group mt-10 mr-3 saas-profile-select saas-profile-select--qna-new-design mb-20"
                id="add-questionField"
              >
                <Select
                  className={'cw-select--custom cw-color--primary '}
                  placeholder={'Reason for new master data request'}
                  options={questionTypes}
                  onChange={handleSelectData}
                  value={questionTypes.find(
                    e => e.value == masterDataQue.reasonForCreation
                  )}
                />
                <span
                  className="float-right  cw-icon cw-icon--help"
                  data-tip="Please specify the tooltip message you would want users to see in case they need some additional information while filling up the form. Make sure to have this message in the language you selected for the form."
                  currentitem="false"
                >
                  <i className="fas fa-question-circle"></i>
                </span>
              </div>

              <span
                id="ReasonForMasterdata"
                class="cw-error d-none"
                data-control="name"
              >
                <i class="fas fa-exclamation-triangle mr-10"></i>
                Please select reason for new master data request
              </span>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="request-new-modal"
          style={{ margin: '2%', marginBottom: '0' }}
        >
          <div className="request-new-modal-header">
            <span className="modal-header-back">
              <span>
                Select and add from the master list of questions &nbsp;&nbsp;
                <a href="#">{props.campaignLanguage}</a>
              </span>
            </span>
            <span
              className={'qna-guideline-close'}
              onClick={props.CloseAddNewQuestionModal}
              style={{ right: '-4%' }}
            ></span>
          </div>
          <div className="form-controler mb-10 p-0">
            <div className="form-group">
              <div className="langtext-wrap">
                <input
                  value={masterDataQue.englishLabel}
                  type="text"
                  name="englishLabel"
                  className="form-control"
                  data-required="true"
                  placeholder="Search using keywords"
                  onChange={handleChangeInput}
                />
                <div className="search-sufix">
                  <i class="fas fa-search"></i>
                </div>
              </div>
              <div className="sufix">
                <a
                  className="new-question-answer-btn"
                  onClick={() => setNewQuestions(true)}
                >
                  Request new question
                </a>
              </div>
            </div>
          </div>
          <div className="custom-free-text">
            <div class="form-group mt-10 mb-5 generic-error-message">
              <span id="note" class="cw-error" data-control="name">
                No results found matching your search criteria
              </span>
            </div>
            <p className="mb-15">
              You can search using other related keywords or look for an
              alternate question that fullfils your purpose.{' '}
            </p>
            <h6>
              <b>If you still can’t find the question you are looking for,</b>{' '}
              you can request new{' '}
              <a
                className="new-question-answer-btn"
                onClick={() => setNewQuestions(true)}
              >
                <u>here</u>
              </a>{' '}
              but there will be longer than usual turnaround time due to tighter
              data governance rules. Ensure you have read our guidelines and are
              not requesting master data that is duplicate or relates to a PII
              field.
            </h6>
          </div>
        </div>
      )}
      <div className="devider-top">
        <button
          style={{ borderRadius: '8px' }}
          type="submit"
          onClick={handleSubmit}
          disabled={!addNewQuestions}
          className="btn btn-primary mb-10 cw-print-hide text-nowrap  mr-1"
        >
          Add
        </button>
      </div>
    </>
  );
};

export default AddNewQuestion;
