import React from 'react';

export default function Color({ color, onClick, onRemove }) {
  const colorDisplay = (
    <>
      <div className="brand-color-title mt-1">{color?.name || ''}</div>
      <div className="brand-color-title mt-1">{color?.code || color}</div>
    </>
  );

  return (
    <div className="brand-color" onClick={onClick}>
      <div
        style={{
          background: color?.code || color
        }}
        className="brand-color-sample"
      ></div>
      <div className="brand-color-title mt-1">{colorDisplay}</div>
      <i
        className="fa fa-times brand-color-delete"
        onClick={e => {
          e.stopPropagation();
          onRemove(color);
        }}
      />
    </div>
  );
}
