import React from "react";
const CampaignCancelModel = (props) => {
    return (
        <div >
            <button
                className="cw-modal--close"
                title="Close"
                onClick={props.closeIsCancelModal}
            >
                <i className="fas fa-times"></i>
            </button>
            <h2 className="cw-heading--primary mb-20">
                <strong>Cancel changes</strong>
            </h2>
            <div>
            <p className="branddetails_items_list mb-10">
            All edits made to your form briefing will be lost. Are you sure you want to cancel?         
            </p>
            </div>
            <br />
            <div className="mr-20">
                <button style={{ float: 'right' }}
                    type="submit"
                    onClick={props.isCancelContinue}
                    className="btn btn-primary btn-custom-width mb-10 cw-print-hide text-nowrap  mr-1"
                >
                    Yes
                </button>
                <button style={{ float: 'right' }}
                    type="submit"
                    onClick={props.closeIsCancelModal}
                    className="btn btn-outline-primary btn-custom-width mb-10 cw-print-hide mr-1"
                >
                    No
                </button>
            </div>

        </div>
    )
}

export default CampaignCancelModel;
