import {
  SET_MODAL_STATUS,
  SET_CAMPAIGN_RECORD_ID,
  SET_DELETED_CAMPAIGN,
  APPROVAL_EDIT_CAMPAIGN,
  SET_APPROVAL_CAMPAIGN_UPDATION_SUCCESS,
  RESET_APP_PROPS,
  SET_EDITED_CAMPAIGNS_DATA,
  SET_APPROVAL_LAST_UPDATED_SCREEN,
  GET_CAMPAIGN_CONFIG,
  GET_ROUTINE_CONFIG,
  TOGGLE_SIDEBAR,
  CLOSE_SIDEBAR
} from '../actionTypes';
import axios from 'axios';
import constant from '../constants';

export const setCampaignId = id => ({ type: SET_CAMPAIGN_RECORD_ID, id });
export const setDeletedCampaign = deletedCampaign => ({
  type: SET_DELETED_CAMPAIGN,
  deletedCampaign
});

export const resetAppProps = reset => ({
  type: RESET_APP_PROPS,
  reset
});

export const setApproverEditCampaignStatus = approverEditCampaign => ({
  type: APPROVAL_EDIT_CAMPAIGN,
  approverEditCampaign
});
export const setApproverEditCampaignStatusMessage = approverCampaignUpdated => ({
  type: SET_APPROVAL_CAMPAIGN_UPDATION_SUCCESS,
  approverCampaignUpdated
});

export const setApproverEditLastUpdatedScreen = lastUpdatedScreen => {
  return function(dispatch) {
    dispatch({
      type: SET_APPROVAL_LAST_UPDATED_SCREEN,
      lastUpdatedScreen
    });
  };
};

export const editedCampaignDetails = campaignDetails => ({
  type: SET_EDITED_CAMPAIGNS_DATA,
  campaignDetails
});

export function fetchEditedCampaignLogs(campignId) {
  return function(dispatch) {
    axios
      .get(
        constant.serviceUrls.SERVICE_URL.CAMPAIGN_EDIT_LOGS_URL +
          '/' +
          campignId
      )
      .then(response => {
        let campaignDetails =
          response.data[0] && response.data[0].updatedFields;

        dispatch({ type: SET_EDITED_CAMPAIGNS_DATA, campaignDetails });
      });
  };
}

export function saveEditedCampaignLogs(campignId, logData) {
  return function(dispatch) {
    let campaignEditData = { uid: campignId, campaignLog: logData };
    axios.post(
      constant.serviceUrls.SERVICE_URL.CAMPAIGN_EDIT_LOGS_URL,
      campaignEditData
    );
  };
}

export function setModalStatus(status, activeModal) {
  return function(dispatch) {
    dispatch({
      type: SET_MODAL_STATUS,
      modalIsOpen: status,
      activeModal: activeModal || ''
    });
  };
}

export function getCampaignConfig() {
  return async function(dispatch) {
    const response = await axios.get(constant.serviceUrls.CAMPAIGN_CONFIG_URL);
    let campaignConfig = response.data.data;

    dispatch({ type: GET_CAMPAIGN_CONFIG, campaignConfig });
  };
}

/**
 * On application load we are getting API key
 * @returns
 */
export function routineTask() {
  return async function(dispatch) {
    const routine = await axios.post(constant.serviceUrls.CAMPAIGN_ROUTINE_URL);
    const routineResponse = routine.data.t;
    localStorage.setItem('cwApiKey', routineResponse);
    dispatch({ type: GET_ROUTINE_CONFIG, routineResponse });
  };
}

export function toggleSidebar(){
  return {
    type: TOGGLE_SIDEBAR,
  }
}

export function closeSidebar(){
  return {
    type: CLOSE_SIDEBAR,
  }
} 
