import axios from 'axios';
import constant from '../constants';

import {
  GET_CAMPAIGN,
  ASYNC_IN_PROGRESS,
  UDPATE_CAMPAIGN_DETAILS,
  PUSH_STATE,
  RESET_FORM_SUBMISSION,
  UPDATE_ADDITIONAL_OPTINS,
  SAVE_CAMPAIGN_DATA,
  GET_CAMPAIGN_COOKIE_PRIVACY_URL,
  REMOVE_ALL_CONSENTS_FOR_LOCALE
} from '../actionTypes';

export const updateCampaignDetails = campaignDetails => ({
  type: UDPATE_CAMPAIGN_DETAILS,
  campaignDetails
});
export const resetSubmissionState = reset => ({
  type: RESET_FORM_SUBMISSION,
  reset
});

export const updateAdditionalOptins = additionalOptinList => ({
  type: UPDATE_ADDITIONAL_OPTINS,
  additionalOptinList
});

export function submitCampaignDetails(
  campaignDetails,
  saveAsDraft,
  userName,
  userEmail,
  isEdited,
  editedCampaignLogs
) {
  return function(dispatch) {
    campaignDetails['saveAsDraft'] = saveAsDraft ? true : false;
    campaignDetails['userName'] = userName;
    campaignDetails['userEmail'] = userEmail;
    campaignDetails['isEdited'] = isEdited;
    campaignDetails['campaignLog'] = editedCampaignLogs;
    axios
      .post(constant.serviceUrls.CAMPAIGN_DATA_SERVICE_URL, campaignDetails)
      .then(response => {
        dispatch({ type: ASYNC_IN_PROGRESS, isLoading: false });
        if (!saveAsDraft) {
          dispatch({ type: PUSH_STATE, pushstate: true });
        } else {
          dispatch({ type: SAVE_CAMPAIGN_DATA, dataSaved: true });
        }
      });
  };
}

export function getCampaignDetails(campignId) {
  return function(dispatch) {
    axios
      .get(constant.serviceUrls.CAMPAIGN_DETAILS_SERVICE_URL + '/' + campignId)
      .then(response => {
        let campaign = response.data[0];
        let countryCode = campaign && campaign.country && campaign.country.code;
        let languaugeCode =
          campaign && campaign.language && campaign.language.code;
        let websiteType =
          campaign && campaign.websiteType && campaign.websiteType.name;
        if (websiteType !== 'Adobe SaaS') {
          dispatch(getCampaignCookiePrivacyUrl(countryCode, languaugeCode));
        }
        dispatch({ type: ASYNC_IN_PROGRESS, isLoading: false });
        dispatch({ type: GET_CAMPAIGN, campaign, campaignLoaded: true });
      });
  };
}

export function getCampaignCookiePrivacyUrl(countryCode, languageCode) {
  return function(dispatch) {
    axios
      .get(
        constant.serviceUrls.SERVICE_URL.COOKIE_PRIVACY_URL +
          '?country=' +
          countryCode +
          '&lang=' +
          languageCode
      )
      .then(response => {
        let urlData = response.data;
        dispatch({ type: ASYNC_IN_PROGRESS, isLoading: false });
        dispatch({ type: GET_CAMPAIGN_COOKIE_PRIVACY_URL, urlData });
      });
  };
}

export function validateCampignName(campaignName, campaignId) {
  return function(dispatch) {
    return axios
      .get(
        constant.serviceUrls.VALIDATE_CAMPAIGN_NAME_URL +
          '?name=' +
          encodeURIComponent(campaignName) +
          '&uid=' +
          campaignId
      )
      .then(response => {
        return response.data && response.data.valid;
      })
      .catch(err => {
        return false;
      });
  };
}

export function removeConsentDataFromCampaign(uid) {
  return async function(dispatch) {
    try {
      dispatch({ type: REMOVE_ALL_CONSENTS_FOR_LOCALE });
      await axios.post(
        `${constant.serviceUrls.CAMPAIGN_DATA_SERVICE_URL}/removeconsents/${uid}`,
        { consentsVersion: '', consents: [] }
      );
    } catch (error) {}
  };
}
