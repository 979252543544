import React, { forwardRef, useState } from 'react';
import DateTimeField from 'react-datetime';
import classNames from 'classnames';
// import constants from 'constants';

export const DatePicker = forwardRef(
  (
    {
      name,
      id,
      className,
      placeholder,
      onChange,
      dateFormat,
      timeFormat,
      defaultValue,
      isValidDate,
      value,
      hasError,
      disabled,
      inputGroupClassName
    },
    ref
  ) => {
    return (
      <DateTimeField
        inputProps={{
          name: name,
          id: id,
          disabled,
          className: classNames(
            'form-control',
            {
              'is-invalid': hasError
            },
            className
          ),
          'aria-describedby': name,
          placeholder: placeholder,
          'aria-label': placeholder,
          'data-required': 'true',
        }}
        dateFormat={dateFormat}
        timeFormat={timeFormat}
        utc={true}
        isValidDate={isValidDate || undefined}
        value={value}
        onChange={v => {
          onChange(v);
        }}
        onkeyDown="return false"
        renderInput={(prop, openCalendar) => (
          <div className={classNames("input-group date", inputGroupClassName)} id={id}>
            <input {...prop} autoComplete="off" ref={ref} />
            <div className="input-group-append">
              <span
                className={classNames('input-group-text cw-icon cw-icon--cal', {
                  'cursor-not-allowed': disabled
                })}
                id="cw-icon--cal"
                onClick={!disabled ? openCalendar : undefined}
              >
                <i className="fas fa-calendar-alt"></i>
              </span>
            </div>
          </div>
        )}
      />
    );
  }
);
