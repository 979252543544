import React from 'react';
import classNames from 'classnames'

const StatusTimeline = ({className, ...props}) => {
  return (
    <div className={classNames("status-progress-wrapper mt-20",className)}>
      <ul className="status-progress">
        {props.steps.map(step => (
          <li className={classNames(`steps-item ${step.status}`,{
            "step-height": step.subTitle? false : true
          })} key={step.id}>
            <div className="step-down-arrow"></div>
            <strong>{step.title}</strong>
            {step.subTitle && <span className="status-progress__sub-title">{step.subTitle}</span>}
            <div className={classNames("insidesubtitle",{
              "step-line-height": step.subTitle? false : true
            })}>
              <span>{step.completedAt}</span>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default StatusTimeline;
