import React from 'react';
import { FormBuilder } from '../../components/organisms/FormBuilder';
import { Controller } from 'react-hook-form';
import * as yup from 'yup';
import EmojiCountWrapper from 'components/molecules/CharCountInput/EmojiCountWrapper';
import CheckboxDropDown from 'components/CheckboxDropDown';
import { get } from 'lodash';

const TempComp = ({name, control, label, defaultValue}) => {
  return <div className="mb-10"><Controller
  name={`${name}`}
  control={control}
  defaultValue={get(defaultValue, 'gender')}
  render={({ field }) => (
    <CheckboxDropDown
      {...field}
      isMulti={true}
      className={'react-multi-select-container'}
      isEmailCampaign={true}
      label={label}
      hideSelectedOptions={false}
      placeholder={'Please select gender'}
      classNamePrefix="react-multi-select"
      options={[
        {
          label: 'Male',
          value: 'male'
        },
        {
          label: 'Female',
          value: 'female'
        },
        {
          label: 'Not Applicable',
          value: 'Not Applicable'
        },
        {
          label: 'Other',
          value: 'other'
        },
        {
          label: 'Unknown',
          value: 'Unknown'
        }
      ]}
      isClearable={false}
    />
  )}
/></div>
}

const TempComp2 = ({name, control, label, defaultValue}) => {
  return <Controller
  name={`${name}`}
  control={control}
  defaultValue={get(defaultValue, 'gender')}
  render={({ field }) => (
    <CheckboxDropDown
      {...field}
      isMulti={true}
      className={'cw-dashboard-filter'}
      isEmailCampaign={true}
      label={label}
      hideSelectedOptions={false}
      placeholder={'Please select gender'}
      //classNamePrefix="react-multi-select"
      options={[
        {
          label: 'Male',
          value: 'male'
        },
        {
          label: 'Female',
          value: 'female'
        },
        {
          label: 'Not Applicable',
          value: 'Not Applicable'
        },
        {
          label: 'Other',
          value: 'other'
        },
        {
          label: 'Unknown',
          value: 'Unknown'
        }
      ]}
      isClearable={false}
    />
  )}
/>
}

const formSchema = {
  title: 'Sample form',
  formType: 'simple',
  previousBtnLabel: 'Previous',
  nextBtnLabel: 'Next',
  fields: [
    {
      type: 'text',
      name: 'firstName',
      id: 'firstName',
      label: 'First Name',
      placeholder: 'Your first name',
      defaultValue: 'Harshank',
      rules: yup.string().required('This field Is required!!!')
    },
    {
      type: 'text',
      name: 'lastName',
      id: 'lastName',
      label: 'Last Name',
      defaultValue: 'Kahar',
      rules: yup.string().required('This field Is required!!!')
    },
    {
      type: 'textarea',
      name: 'comments',
      id: 'comments',
      label: 'Comments',
      rows: '5',
      rules: yup.string().required('This field Is required!!!')
    },
    {
      type: 'select',
      name: 'country',
      id: 'country',
      label: 'Country',
      rules: yup.string().required('This field Is required!!!'),
      defaultValue: 'us',
      options: [
        { value: '', label: 'None' },
        { value: 'us', label: 'United States' },
        {
          value: 'uk',
          label: 'United kingdom'
        }
      ]
    },
    {
      type: 'reactselect',
      name: 'websiteType2',
      id: 'websiteType2',
      label: 'Website Type react Select',
      rules: yup.string().required('This field Is required!!!'),
      defaultValue: {
        value: 'Adobe, Saas',
        label: 'Adobe Saas',
        country: 'us'
      },
      options: [
        { value: 'Adobe, Saas', label: 'Adobe Saas', country: 'us' },
        {
          value: 'Adobe Templated',
          label: 'Adobe Templated',
          country: 'uk'
        },
        {
          value: 'Adobe Classic',
          label: 'Adobe Classic',
          country: 'uk'
        },
        {
          value: 'Standalone',
          label: 'Standalone',
          country: 'us'
        },
        {
          value: 'Other',
          label: 'Other',
          country: 'us'
        }
      ],
      watchInput: 'country',
      onWatch: (value, { options, disabled }) => {
        let m_disabled = disabled;
        let m_options = options;
        if (value) {
          m_disabled = false;
          m_options = options.filter(i => i.country === value);
        }
        return { options: m_options, disabled: m_disabled };
      }
    },
    {
      type: 'label',
      id: 'CampaignTypeField',
      label: 'Sample Label'
    },
    {
      type: 'radio',
      name: 'CampaignType',
      helpText: 'A simple signup form',
      options: [
        {
          id: 'simpleSignUp',
          value: 'simpleSignUp',
          label: 'Simple Signup',
          helpText: 'A simple signup form'
        },
        {
          id: 'simpleSignUpWithQnA',
          value: 'simpleSignUpWithQnA',
          label: 'Simple Signup with QnA',
          labelClass: 'cw-heading--tenary',
          helpText: 'A simple signup form with Questions and Answers'
        },
        {
          id: 'incentive',
          value: 'incentive',
          label: 'Incentive Signup with QnA'
        }
      ],
      rules: yup.string().required('This field Is required!!!')
    },
    {
      type: 'custom',
      name: 'subject',
      component: EmojiCountWrapper,
      id: 'subject',
      label: 'Subject',
      defaultValue: '',
      maxLength: '60',
      helpText: '41 characters, 7 words recommended',
      rules: yup
        .string()
        .required('Please enter subject line')
        .nullable()
    },
    {
      type: 'custom',
      name: 'gender',
      component: TempComp,
      id: 'gender',
      label: 'Gender',
      defaultValue: {},
    },
    {
      type: 'custom',
      name: 'gender2',
      component: TempComp2,
      id: 'gender2',
      label: 'Gender2',
      defaultValue: {},
    }
  ],
  onSubmit: data => {
    window.alert(JSON.stringify(data, null, 2));
  }
};



export const FormBuilderPoc = () => {
  return (
    <div className="col-sm-12 skypink">
      <div className="cw-section">
        <div className="row">
          <div className="col-md-8">
            <FormBuilder schema={formSchema} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormBuilderPoc;
