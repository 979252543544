import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormBuilder } from '../../components/organisms/FormBuilder';
import Loader from '../../components/Loader';
import CampaignRequestInfoSchema from './emailCampaignRequestInfoSchema';

const EmailCampaignRequestInfo = ({
  pathUid,
  closeModal,
  setToggle,
  emailBriefing,
  toggle
}) => {
  const { isLoading, uid, deliverySchedule, status, emailType, isCrossBrand,requestInfoText } = emailBriefing;
  const { authorizedUserData } = useSelector(state => state.authorized);
  const dispatch = useDispatch();
  const compProps = {
    pathUid,
    closeModal,
    setToggle,
    defaultValues: deliverySchedule || {},
    emailType,
    status,
    isCrossBrand,
    requestInfoText,
    toggle
  };
  return (
    <div>
      {isLoading ? <Loader /> : null}
      <FormBuilder
        schema={CampaignRequestInfoSchema(dispatch, compProps)}
        user={authorizedUserData}
      />
    </div>
  );
};

export default EmailCampaignRequestInfo;
