import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormBuilder } from '../../components/organisms/FormBuilder';
import Loader from '../../components/Loader';
import BreadCrumb from '../../components/BreadCrumb';
import NewUpdateAlert from 'components/molecules/NewUpdateAlert';
import formSchema from './brandDetailsformSchema';
import CantFindCountryModal from './CantFindCountryModal';
import { getBrandDetails } from 'actions/emailbriefing/emailBrandDetails';
import ProgressBar from 'components/molecules/ProgressBar';
import classNames from 'classnames';
import { LinkButton } from 'components/atoms/LinkButton';
import { getEmailCampaignDetails } from 'actions/emailbriefing/emailCampaignDetails';
import constants from 'constants/config';

export const CustomButtons = ({
  isSubmitting,
  onPrevious,
  previousBtnLabel,
  submitBtnLabel,
  onSecondaryClick,
  onSubmitSecondary,
  secondaryBtnLabel,
  isEdit
}) => {
  return isEdit ? (
    <div className="mt-30 mb-20">
      <div>
        {onSecondaryClick && (
          <button
            className="btn btn-primary mr-3"
            type="button"
            onClick={() => {
              onSubmitSecondary();
            }}
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Loading....' : secondaryBtnLabel || 'Secondary'}
          </button>
        )}
      </div>
      <div className="mt-10">
        <LinkButton
          className="cw-formdraft-link"
          type="submit"
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Loading....' : submitBtnLabel || 'Submit'}
        </LinkButton>
      </div>
    </div>
  ) : (
    <div className="mt-30 mb-20">
      <button className="btn btn-primary" type="submit" disabled={isSubmitting}>
        {isSubmitting ? 'Loading....' : submitBtnLabel || 'Submit'}
      </button>
    </div>
  );
};

const NewEmailCampaign = props => {
  const {
    isLoading,
    country,
    brand,
    language,
    uid,
    isCrossBrand
  } = useSelector(state => state.emailBrandDetails);
  const { authorizedUserData } = useSelector(state => state.authorized);
  const emailDesign = useSelector(state => state.emailDesign);
  const [isModalOpen, setIsModelOpen] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const pathUid = props.match.params.id;
    console.log('pathUid: ', pathUid);
    if (pathUid) {
      setEdit(true);
    }
    if (pathUid && uid !== pathUid) {
      // fetch from email details from the server
      dispatch(getBrandDetails(pathUid));
      dispatch(getEmailCampaignDetails(pathUid));
    }
  }, []);

  return (
    <div className="col-sm-12 skypink email-campaign-brand-selection">
      <div className="cw-section">
        <ProgressBar customClass={'cw-email-progress-custom'} pageNo={constants.EMAIL_JOURNEY_PAGE_NUMBERS.NEW_CAMPAIGN} />
        <div className="cw-section--title mb-30">
          <h2 className="cw-heading--secondary cw-email-heading">
            Enter Brand Details
          </h2>
        </div>
        <div className="row">
          {isLoading ? <Loader /> : null}
          <div className="col-md-8">
            <FormBuilder
              schema={formSchema(
                dispatch,
                {
                  history: props.history,
                  match: props.match,
                  isEdit,
                  isCrossBrand,
                  emailDesign,
                  setIsModelOpen
                },
                { country, brand, language }
              )}
              user={authorizedUserData}
              isEdit={isEdit}
              customButtonsComp={CustomButtons}
            />
          </div>
          <div className="col-md-4">
            <NewUpdateAlert
              className="mt-30"
              title="Introducing email builder!"
              content={
                <>
                  <p>
                    Say goodbye to the frustration of clunky interfaces and
                    limited design options.
                  </p>
                  <p>
                    {' '}
                    With our user-friendly platform, you can easily create
                    stunning emails that capture your brand's essence and engage
                    your audience. Whether you're a seasoned email marketer or
                    just starting out, our product makes it simple to create
                    effective campaigns that convert.
                  </p>
                  <p>Give it a go, now!</p>
                </>
              }
            />
          </div>
        </div>
      </div>
      <CantFindCountryModal
        isModalOpen={isModalOpen}
        onClose={() => {
          setIsModelOpen(false);
        }}
      />
    </div>
  );
};

export default NewEmailCampaign;
