import React from 'react';
import DashStats from 'components/molecules/DashStats';
import classNames from 'classnames';

const DashStatsBox = ({ list, direction, className }) => {
  return (
    <div
      className={classNames(
        'd-flex align-item-center justify-content-around',
        {
          'flex-column': direction === 'column'
        },
        className
      )}
    >
      {list.map((item, index) => {
        return (
          <DashStats
            key={index}
            title={item.title}
            count={item.count}
            percent={item.percent}
          />
        );
      })}
    </div>
  );
};

export default DashStatsBox;
