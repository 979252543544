import React from 'react';
import { FieldError } from '../../atoms/FieldError';
import { get } from 'lodash';
import { useWatch } from 'react-hook-form';
import { FormReactSelect } from '../FormReactSelect';
import { DONT_SEND } from '../EmailScheduleInput';

const options = [
  {
    label: "Don't send",
    value: DONT_SEND
  },
  {
    label: '3 days',
    value: '3'
  },
  {
    label: '4 days',
    value: '4'
  },
  {
    label: '5 days',
    value: '5'
  },
  {
    label: '6 days',
    value: '6'
  },
  {
    label: '7 days',
    value: '7'
  }
];

const SendAfterField = ({
  control,
  watchInput,
  errors,
  name,
  setValue,
  defaultValue,
  sendAfterFieldLabel
}) => {
  const sendAfter = useWatch({
    control,
    name: 'sendAfter',
    defaultValue: 5
  });
  const errorMessages = get(errors, name);
  const hasError = !!(errors && errorMessages);

  return (
    <div className="pt-0 mt-10">
      <FormReactSelect
        id="sendAfter"
        name={`sendAfter`}
        label={sendAfterFieldLabel ? sendAfterFieldLabel : 'How many days after the 1st send should we send your follow up email to non-openers?'}
        placeholder={'Please select send after'}
        control={control}
        errors={errors}
        defaultValue={get(defaultValue, 'sendAfter', options[3])}
        options={options}
        setValue={setValue}
      />
    </div>
  );
};

export const SendFollowUpEmailInput = ({
  name,
  rules,
  register,
  label,
  errors,
  setValue,
  control,
  defaultValue,
  mainSmsLabel,
  subSmsLabel,
  sendAfterFieldLabel,
  ...props
}) => {
  const errorMessages = get(errors, name);
  const hasError = !!(errors && errorMessages);

  return (
    <div className="form-group" aria-live="polite">
      <div className="send-follow-up-container">
        <p><strong>
         {mainSmsLabel ? mainSmsLabel : `Do you want to send a follow up email?`}
        </strong>
        </p>
        <span className="email-sendafter-font">
           {subSmsLabel ? subSmsLabel :`We will setup your campaign to automatically trigger a follow up email to consumers who do not open your email in the 1st attempt. The email body content of the follow up will remain the same as the original email. To encourage non-openers to engage, you will be required to enter a slightly different subject line for your follow up in the later sections of this briefing.`}
        </span>
        <SendAfterField
          control={control}
          watchInput={`${name}`}
          errors={errors}
          name={`sendAfter`}
          setValue={setValue}
          defaultValue={defaultValue}
          sendAfterFieldLabel={sendAfterFieldLabel}
        />
      </div>
      <FieldError errors={errors} name={`${name}.type`} />
    </div>
  );
};
