import React from 'react';
import ReactTooltip from 'react-tooltip';
import constants from 'constants/config';

export default function QualifyingQuestionToggle({
  disabled,
  checked,
  qualifyingQuestionHandler,
  index,
  elem
}) {
  return (
    <div>
      <div
        className="form-group d-flex form-group-field-width"
        style={{ marginLeft: '0 !important' }}
      >
        <label className="switch-saas">
          <input
            type="checkbox"
            name="isQualifying"
            disabled={disabled}
            checked={checked}
            onChange={e => qualifyingQuestionHandler(e, index, elem)}
          />
          <div className="slider-saas round"></div>
        </label>
        <label className=" ml-20" htmlFor="isQualifying">
          Make this a Qualifying question &nbsp;
          <ReactTooltip
            id="saas_qualifying_toggle_tooltip"
            place="right"
            type="info"
            multiline={true}
            className="cw-tooltip"
          />
          <span
            className="float-right  cw-icon cw-icon--help"
            data-for="saas_qualifying_toggle_tooltip"
            data-tip={constants.QUALIFYING_TOGGLE_TOOLTIP}
          >
            <i className="fa fa-info-circle"></i>
          </span>
        </label>
      </div>
      <span
        className="cw-error cw-hidden ml-4"
        id={`errorMessage_qualifyingToogle_${index}`}
      >
        <i className="fas fa-exclamation-triangle mr-10"></i>
        You can mark upto 5 questions as qualifying.
      </span>
    </div>
  );
}
