import React, { useState, useEffect } from 'react';
import constants from 'constants/config';

const CharecterCountAlert = ({ stats }) => {
  const [mesg, setMesg] = useState(null);
  const [showMesg, setShowMesg] = useState(false);

  useEffect(() => {
    if (stats.hasUnicode) {
      if (stats.segments > 1) {
        setMesg(
          <div>
          <p><strong>Your SMS has a Unicode character and exceeds max length!</strong></p>
          <p>When a Unicode character is present, your max length per SMS is reduced from {constants.SMS_GSM_CHARACTERS_LIMIT} to {constants.SMS_UNICODE_CHARACTER_LIMIT} characters. This is further reduced to {stats.segmentLimit} characters when you exceed max length. Your message now has '{stats.segments}' parts with a reduced limit of 67 characters each. You will be charged for {stats.segments} messages per consumer.</p>
          </div>
        );
      } else {
        setMesg(
          <div>
          <p><strong>Your SMS has a Unicode character.</strong></p>
          <p>When a Unicode character is present, your max length per SMS is reduced from {constants.SMS_GSM_CHARACTERS_LIMIT} to {constants.SMS_UNICODE_CHARACTER_LIMIT} characters.</p>
          </div>
        );
      }
      setShowMesg(true);
    } else {
      if (stats.segments > 1) {
        setMesg(
          <div>
          <p><strong>Your SMS exceeds max length!</strong></p>
          <p>When your GSM message exceeds {constants.SMS_GSM_CHARACTERS_LIMIT} characters, max length per SMS is reduced to {stats.segmentLimit} characters. Your message now has '{stats.segments}' parts with a reduced limit of {stats.segmentLimit} characters each. You will be charged for {stats.segments} messages per consumer. </p>
          </div>
        );
        setShowMesg(true);
      } else {
        setShowMesg(false);
      }
    }
  }, [stats]);

  return showMesg ? (
    <div className={`alert alert-warning d-flex mt-10`} role="alert">
      <i className="fas fa-exclamation-circle pt-5 mr-10"></i>
      {mesg}
    </div>
  ) : null;
};

export default CharecterCountAlert;
