import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Accordion } from 'semantic-ui-react';
import 'semantic-ui-css/components/accordion.min.css';
import 'semantic-ui-css/components/icon.min.css';
import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';
import { UnstyledButton } from 'components/atoms/UnstyledButton';
import { ReactComponent as EditIcon } from '../../../images/edit-icon.svg';
import { ReactComponent as DeleteIcon } from '../../../images/delete-icon.svg';
// import { ReactComponent as DownArrow } from '../../../images/down-arrow.svg';
import { get } from 'lodash';

const campraignTypeMap = {
  dataAcquisition: 'Sign up requests',
  emailCampaigns: 'Email campaigns',
  smsCampaigns: 'SMS campaigns',
  general: 'General'
};

/**
 * @usage
 * <TimlineItem time={time} text={text} />
 */
const transformReleaseNote = (note = {}) => {
  return {
    ...note,
    descriptionText: note.description,
    featureSummaryText: note.title,
    releasedateText: moment(note.releaseDate)
  };
};

function TimelineItem({
  data,
  activeIndex,
  year,
  handleEdit,
  handleDelete,
  isAdminUser
}) {
  // const [isCollapsed, setIsCollapsed] = useState(true);

  // const getDescription = description => {
  //   if (description.length > 400) {
  //     return isCollapsed ? (
  //       <>
  //         {ReactHtmlParser(description.slice(0, 400))}{' '}
  //         <UnstyledButton className="read-more-link" onClick={() => setIsCollapsed(false)}>
  //           Read more &nbsp;<DownArrow />
  //         </UnstyledButton>
  //       </>
  //     ) : (
  //       <>
  //         {ReactHtmlParser(description)}{' '}
  //         <UnstyledButton className="read-more-link" onClick={() => setIsCollapsed(true)}>
  //           Read less &nbsp;<DownArrow style={{ transform: 'rotate(180deg)' }} />
  //         </UnstyledButton>
  //       </>
  //     );
  //   }

  //   return ReactHtmlParser(description);
  // };

  const releaseItems = (
    <ul className="timeline-item">
      <li>
        {data && data.length && data[0]._id ? (
          data.map((item, i) => (
            <div key={i}>
              <section
                className={
                  i === 0 ||
                  (i > 0 &&
                    moment(data[i].releaseDate).format('MMM') !==
                      moment(data[i - 1].releaseDate).format('MMM'))
                    ? 'timeline-item__section month'
                    : 'timeline-item__section'
                }
              >
                {i === 0 ||
                (i > 0 &&
                  moment(data[i].releaseDate).format('MMM') !==
                    moment(data[i - 1].releaseDate).format('MMM')) ? (
                  <div className="timeline-item__month">
                    {moment(item.releaseDate).format('MMMM')}
                  </div>
                ) : null}

                <div className="d-flex justify-content-between release-note-bar">
                  <div className="d-flex">
                    {get(item, 'featureCategory.value') && (
                      <span className="badge badge-pill badge-dark">
                        {campraignTypeMap[get(item, 'featureCategory.value', '')]}
                      </span>
                    )}
                  </div>
                </div>

                <div className="d-flex justify-content-between">
                  <div className="timeline-item__title">
                    {item.title}
                    {get(item, 'status.value', '') === 'comingSoon' && (
                      <span className="badge badge-pill warning ml-2">
                        Coming soon
                      </span>
                    )}
                  </div>
                  <div className="d-flex">
                    {isAdminUser && (
                      <>
                        <UnstyledButton
                          onClick={() => handleEdit(transformReleaseNote(item))}
                        >
                          <EditIcon />
                        </UnstyledButton>
                        <UnstyledButton
                          className="ml-10"
                          onClick={() =>
                            handleDelete(transformReleaseNote(item))
                          }
                        >
                          <DeleteIcon />
                        </UnstyledButton>
                      </>
                    )}
                  </div>
                </div>

                <div className="timeline-item__desc">
                  {ReactHtmlParser(item.description)}
                </div>
              </section>
            </div>
          ))
        ) : (
          <div>
            <section className="timeline-item__section">
              <div className="timeline-item__desc">
                No release updates or announcements found for this time period.
              </div>
            </section>
          </div>
        )}
      </li>
    </ul>
  );

  return <Accordion.Content active={activeIndex} content={releaseItems} />;
}

TimelineItem.defaultProps = {};

TimelineItem.propTypes = {
  data: PropTypes.array.isRequired
};

export default TimelineItem;
