import {
  GET_USER_DATA,
  NEW_USER_DATA_ADDED,
  UPDATE_USER_DATA,
  DELETE_USER,
  USER_CANNOT_DELETED,
  TAB_CHANGE_USER,
  ASYNC_IN_PROGRESS_USER_MANAGEMENT,
  USER_EMAIL_ALREADY_EXISTS,
  GET_APPROVER_COUNTRIES,
  APPROVER_COUNTRY_LOADED_SUCCESS,
  UPDATE_APPROVER_DETAILS,
  USER_APPROVER_EMAIL_VALIDATION,
  GET_BUSINESS_GROUP_LIST,
  UPDATE_BUSINESS_GROUP_DETAILS
} from '../../actionTypes';

const initialState = {
  asyncInProgress: false,
  userData: [],
  masterData: {},
  acsData: {},
  gigyaData: {},
  newUserAdded: false,
  userDataUpdated: false,
  userDeleted: false,
  deletedUsername: '',
  addedUsername: undefined,
  usercannotdeleted: false,
  deletionStatusCheck: false,
  emailAlreadyUse: false,
  countriesLoaded: false,
  countries: [],
  country: [],
  requestersDetails: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_USER_DATA: {
      let userData = {
        userData: action.userList || []
      };
      return { ...state, ...userData };
    }
    case ASYNC_IN_PROGRESS_USER_MANAGEMENT: {
      let asyncInProgress = { asyncInProgress: action.isLoading };
      return { ...state, ...asyncInProgress };
    }
    case NEW_USER_DATA_ADDED: {
      let userdataupdated = {
        newUserAdded: action.newUserAdded,
        addedUsername: action.addedUsername,
        userDataUpdated: action.userDataUpdated,
        userDeleted: action.userDeleted,
        deletedUsername: action.deletedUsername,
        usercannotdeleted: action.usercannotdeleted,
        emailAlreadyUse: action.emailAlreadyUse,
        country: []
      };

      return { ...state, ...userdataupdated };
    }
    case UPDATE_USER_DATA: {
      let userdataupdated = {
        userDataUpdated: action.userDataUpdated,
        addedUsername: action.addedUsername,
        newUserAdded: action.newUserAdded,
        userDeleted: action.userDeleted,
        deletedUsername: action.deletedUsername,
        usercannotdeleted: action.usercannotdeleted,
        emailAlreadyUse: action.emailAlreadyUse,
        country: []
      };

      return { ...state, ...userdataupdated };
    }
    case DELETE_USER: {
      let userdatadeleted = {
        userDeleted: action.userDeleted,
        deletedUsername: action.deletedUsername,
        userDataUpdated: action.userDataUpdated,
        addedUsername: action.addedUsername,
        newUserAdded: action.newUserAdded,
        usercannotdeleted: action.usercannotdeleted,
        emailAlreadyUse: action.emailAlreadyUse
      };
      return { ...state, ...userdatadeleted };
    }
    case USER_CANNOT_DELETED: {
      let userdatadeleted = {
        usercannotdeleted: action.usercannotdeleted,
        userDeleted: action.userDeleted,
        deletedUsername: action.deletedUsername,
        userDataUpdated: action.userDataUpdated,
        addedUsername: action.addedUsername,
        newUserAdded: action.newUserAdded,
        deletionStatusCheck: action.deletionStatusCheck,
        emailAlreadyUse: action.emailAlreadyUse
      };
      return { ...state, ...userdatadeleted };
    }
    case TAB_CHANGE_USER: {
      let removemsgs = {
        usercannotdeleted: action.usercannotdeleted,
        userDeleted: action.userDeleted,
        deletedUsername: action.deletedUsername,
        userDataUpdated: action.userDataUpdated,
        addedUsername: action.addedUsername,
        newUserAdded: action.newUserAdded,
        deletionStatusCheck: action.deletionStatusCheck,
        emailAlreadyUse: action.emailAlreadyUse
      };
      return { ...state, ...removemsgs };
    }
    case USER_EMAIL_ALREADY_EXISTS: {
      let emailAlreadyUse = {
        emailAlreadyUse: action.emailAlreadyUse
      };
      return { ...state, ...emailAlreadyUse };
    }
    case APPROVER_COUNTRY_LOADED_SUCCESS: {
      return {
        ...state,
        ...{
          countriesLoaded: action.payload
        }
      };
    }
    case GET_APPROVER_COUNTRIES: {
      let countries = {
        countries: action.countryDetailsSorted
      };

      return {
        ...state,
        ...countries
      };
    }
    case UPDATE_APPROVER_DETAILS: {
      let approverDetails = action.approverDetails;
      return { ...state, ...approverDetails };
    }

    case USER_APPROVER_EMAIL_VALIDATION: {
      let emailApproverValidation = {
        emailApproverValidation: action.emailApproverValidation
      };
      return { ...state, ...emailApproverValidation };
    }

    case GET_BUSINESS_GROUP_LIST: {
      let businessGroup = {
        businessGroup: action.businessGroupListSorted
      };

      return {
        ...state,
        ...businessGroup
      };
    }

    case UPDATE_BUSINESS_GROUP_DETAILS: {
      let selectedBusinessGroup = action.selectedBusinessGroup;
      return { ...state, selectedBusinessGroup };
    }
    default:
      return state;
  }
}
