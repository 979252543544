import * as yup from 'yup';

import { EmailImageSelector } from 'components/organisms/EmailImageSelector';
import constants from '../../constants';

export default (handleSubmit, handleCloseForm, defaultValues) => {
  return {
    formType: 'modal',
    submitBtnLabel: 'Generate URL',
    previousBtnLabel: 'Cancel',
    fields: [
      {
        type: 'custom',
        name: 'imageSelector',
        id: 'imageSelector',
        component: EmailImageSelector,
        defaultValue: {
          ...defaultValues,
          extn: defaultValues.extn
            ? {
                value: defaultValues.extn,
                label: constants.IMAGE_TYPES.find(
                  type => type.value === defaultValues.extn
                )?.label
              }
            : null
        },
        rules: yup.object({
          assetID: yup
            .string()
            .required('Asset ID is required')
            .matches(/^[0-9]*$/g, 'Please enter valid Asset ID'),
          fileType: yup
            .object({
              label: yup.string(),
              value: yup.string()
            })
            .required('File type is required')
            .default(undefined),
          resizeWidth: yup
            .number('Width must be a number')
            .typeError('Width must be a number')
            .nullable()
            .notRequired()
            .when('resizeHeight', {
              is: data => !!data,
              then: yup
                .number('Width must be a number')
                .required('Width is mandatory when height is specified')
                .typeError('Width is mandatory when height is specified')
            })
            .transform((_, val) => (val !== '' ? Number(val) : null)),
          resizeHeight: yup
            .number('Height must be a number')
            .typeError('Height must be a number')
            .nullable()
            .notRequired()
            .transform((_, val) => (val !== '' ? Number(val) : null))
        })
      }
    ],
    onSubmit: data => {
      const {
        assetID,
        fileType,
        resizeWidth,
        resizeHeight
      } = data.imageSelector;
      const isTransform = resizeWidth;

      let url = `${constants.TAB_BASE_URL}${assetID}.${fileType.value}`;
      url = isTransform ? url + '?im=' : url;

      if (resizeWidth) {
        url += `Resize=(${resizeWidth},${
          resizeHeight ? resizeHeight : resizeWidth
        })`;
      }

      console.log(url);

      return handleSubmit(url);
    },
    onPrevious: () => {
      handleCloseForm();
    }
  };
};
