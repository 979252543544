import {
  GET_SAAS_FORM_FIELD_METADATA,
  GET_SAAS_FORM_GENERIC_METADATA,
  ASYNC_IN_PROGRESS,
  GET_SAAS_INCENTIVE_METADATA,
  GET_SAAS_FORM_ELEMENTS_METADATA
} from '../actionTypes';

const initialState = {
  formMetaData: {},
  formGenericData: {},
  isLoading: false,
  accordionFormElement: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_SAAS_FORM_FIELD_METADATA: {
      let formMetaData = {
        accordionData: action.formMetaData.accordionData.filter(
          el =>
            el.type !== 'message' &&
            // && el.dataType !== 'email'
            el.type === 'field'
        ),
        qnaFieldData: action.formMetaData.accordionData.filter(
          el => el.type !== 'message' && el.type === 'questionAnswerPair'
        ),
        // this section will be later removed as we will bring the email in dropdown
        emailData: action.formMetaData.emailData.filter(
          el => el.dataType === 'email'
        )
      };

      return { ...state, ...formMetaData };
    }
    case ASYNC_IN_PROGRESS: {
      let isLoading = {
        isLoading: action.isLoading
      };

      return { ...state, ...isLoading };
    }

    case GET_SAAS_FORM_GENERIC_METADATA: {
      let formGenericData = {
        genericData: action.formGenericData.genericData.filter(
          el => el.type === 'message' || el.dataType === 'submit'
        ),
        submitData: action.formGenericData.genericData.find(el => el.dataType === 'submit')
      };
      return { ...state, ...formGenericData };
    }

    case GET_SAAS_FORM_ELEMENTS_METADATA: {
      let accordionFormElement = {
        accordionFormElement: action.formMetaData.accordionFormElement.filter(
          el => el.type === 'formElement'
        )
      };
      return { ...state, ...accordionFormElement };
    }

    case GET_SAAS_INCENTIVE_METADATA: {
      let incentiveFields = {
        incentiveFields: action.incentiveData.incentiveFields
      };
      return { ...state, ...incentiveFields };
    }

    default:
      return state;
  }
}
