import React, { useEffect, useState } from 'react';
import { FieldError } from '../../atoms/FieldError';
import { useWatch } from 'react-hook-form';
import SingleColorPicker from '../SingleColorPicker/SingleColorPicker';

export const TextLinksDefaultInput = ({
  name,
  description,
  type,
  rules,
  control,
  register,
  label,
  errors,
  options,
  watchInput,
  defaultValue,
  defaultWatchValue,
  getValues,
  setValue,
  ...props
}) => {
  const [values, setValues] = useState(
    defaultValue || {
      color: '',
      backgroundColor: '',
      borderColor: '',
      fontSize: 14,
      borderRadius: 4
    }
  );

  const brand = useWatch({
    control,
    name: watchInput,
    defaultValue: defaultWatchValue
  });

  useEffect(() => {
    if (props.onWatch) {
      props.onWatch(brand, onChange);
    }
  }, [brand]);

  const onChange = (property, value) => {
    setValues(values => {
      const updatedValue = { ...values, [property]: value };
      setValue(name, updatedValue);
      return updatedValue;
    });
  };

  if (!brand) {
    return null;
  }

  return (
    <div className="form-group mt-30">
      <div className="d-flex flex-column">
        <strong>Text links</strong>
        <span className="description mb-10">{description}</span>
      </div>
      <div className="d-flex">
        <div className="d-flex" style={{ alignItems: 'flex-start' }}>
          <SingleColorPicker
            label="Link text colour"
            property="color"
            value={values.color}
            onChange={onChange}
          />
        </div>
        <div
          className="ml-20"
          style={{
            color: values.color,
            marginTop: 5
          }}
        >
          I am your text link
        </div>
      </div>
    </div>
  );
};
