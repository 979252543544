import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormBuilder } from 'components/organisms/FormBuilder';
import brandStyleFormSchema from './brandStyleFormSchema';
import { getBrands } from 'actions/dashboard';
import { fetchBrandDefaultsHistoryLogs } from 'actions/brandDefaultsHistoryLogsActions';

export default function BrandStyle() {
  const [showSavedAlert, setShowSavedAlert] = useState(false);
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();

  const { authorizedUserData } = useSelector(state => state.authorized);

  // const handleFilterChange = selectedBrand => {
  //   setErrors({ ...errors, brandColours: '' });
  //   setBrandFilter(selectedBrand);
  // };

  const compProps = {
    defaultValues: { brand: '' },
    onSave: brandId => {
      setShowSavedAlert(true);
      dispatch(fetchBrandDefaultsHistoryLogs(brandId));
      setTimeout(() => setShowSavedAlert(false), 1000);
    }
  };

  useEffect(() => {
    dispatch(getBrands());
  }, []);

  return (
    <div className="mt-2" style={{ position: 'relative' }}>
      <div style={{ marginTop: '24px', marginBottom: '24px' }}>
        {errors.brandColours ? (
          <span className="cw-error" data-control="brand">
            <i className="fas fa-exclamation-triangle mr-10"></i>
            {errors.brandColours}
          </span>
        ) : null}
      </div>
      <FormBuilder
        schema={brandStyleFormSchema(dispatch, compProps)}
        user={authorizedUserData}
      />

      <div
        style={{ color: 'var(--color-primary)' }}
        className={`mt-10 ${showSavedAlert ? 'fadeIn' : 'fadeOut'}`}
      >
        Saved!
      </div>
    </div>
  );
}
