import React, { useEffect, useState, useMemo } from 'react';
import allCampaign from '../images/allCampaign.png';
import requesterImage from '../images/requester.png';
import approverImage from '../images/approver.png';
import masterDataImage from '../images/master-data.png';
import brandMarketImage from '../images/brand-market.png';
import marketImage from '../images/market.png';
import brandImage from '../images/brand.png';
import { DashboardCard } from './organisms/DashboardCard/DashboardCard';
import { CampaignCharts } from './organisms/CampaignCharts/CampaignCharts';
import { getDashboardMatrix } from '../actions/dashboard';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import constant from '../constants';
import { getAuthoringFieldData } from 'actions/saasFormAuthoring';
import ReportSectionHeading from './atoms/ReportSectionHeading';
import FaqStats from './molecules/FaqStats';
import FieldStatsAccordion from './organisms/FieldStatsAccordion';
import DashStatsBox from './organisms/DashStatsBox';
import StatsBoxTitle from './molecules/StatsBoxTitle';
import { getChartData, getDateRange } from '../selectors/index';
import RadioDropDown from './RadioDropDown';
import constants from '../constants';
import { get } from 'lodash';
import moment from 'moment';
import ChartSummary from './organisms/ChartSummary';
import CampaignRejectionList from './organisms/CampaignRejectionList/CampaignRejectionList';

const dropDownOptions = [
  { label: 'Monthly', value: 'monthly' },
  { label: 'Weekly', value: 'weekly' },
  { label: 'Quarterly', value: 'quarter' },
  { label: 'Yearly', value: 'annual' }
];

var _startDate = null;

const BarChartColorMap = {
  'simple-sign-up': '#FF0F7D',
  samples: '#00D7C4',
  competitions: '#FFBA00'
};

const BarChartColorQnaMap = {
  'simple-sign-up': '#80415e',
  samples: '#3f6e69',
  coimageIconmpetitions: '#73643d'
};

var userSummaryCard = [
  {
    title: 'Requestors',
    imageIcon: requesterImage,
    value: 0,
    className: 'all-campaign'
  },
  {
    title: 'Approvers',
    imageIcon: approverImage,
    value: 0,
    className: 'all-campaign'
  },
  {
    title: 'Data Bureau',
    imageIcon: masterDataImage,
    value: 0,
    className: 'all-campaign'
  }
];

var brandSummaryCard = [
  {
    title: 'Unique brands - markets',
    imageIcon: brandMarketImage,
    value: 0,
    percentage: '...',
    className: 'all-campaign'
  },
  {
    title: 'Markets',
    imageIcon: marketImage,
    value: 0,
    percentage: '...',
    className: 'all-campaign'
  },
  {
    title: 'Brands',
    imageIcon: brandImage,
    value: 0,
    percentage: '...',
    className: 'all-campaign'
  }
];

const ReportDashboard = props => {
  const dispatch = useDispatch();
  const [campaignTrendsOption, setCampaignTrendsOption] = useState(
    dropDownOptions[0]
  );

  const [infraStatusOption, setInfraStatusOption] = useState(
    dropDownOptions[0]
  );

  let getcurentMonthDateRange = getDateRange('this year');
  const [lastUpdatedData, setLastUpdatedData] = useState(
    constants.lastUpdatedData
  );

  const [lastUpdated, setLastUpdated] = useState({
    value: getcurentMonthDateRange,
    label: 'This year'
  });

  const [topBrandCheck, setTopBrandCheck] = useState(false);
  const [topMarketCheck, setTopMarketCheck] = useState(false);

  const handleLastUpdatefilter = selectedOption => {
    setLastUpdated(selectedOption);
    dashboardApiCall(selectedOption);
  };

  const [questionList, setQuestionList] = useState([]);
  const handleCampaignTrendsDropDown = selectedOption => {
    setCampaignTrendsOption(selectedOption);
  };

  const handleInfraStatusDropDown = selectedOption => {
    setInfraStatusOption(selectedOption);
  };

  const [showToggle, setShowToggle] = useState(false);
  const toggleBtnClick = () => {
    setShowToggle(!showToggle);
  };

  const fetchQuestionList = async () => {
    let queUrl = constant.serviceUrls.FETCH_ALL_QUESTIONS + '?language=' + 'en';
    await axios.get(queUrl).then(response => {
      let data = response && response.data;
      setQuestionList(data);
    });
  };

  const dashboardApiCall = lastUpdatedDate => {
    let startDate = '';
    let endDate = '';
    if (lastUpdatedDate && lastUpdatedDate['value'] !== 'All time') {
      let dateArr =
        lastUpdatedDate &&
        lastUpdatedDate['value'] &&
        lastUpdatedDate['value'].split('-');
      startDate =
        dateArr && dateArr.length
          ? moment(dateArr[0].trim(), 'DD/MM/YY').format('DD-MM-YYYY')
          : '';
      _startDate =
        startDate == '' ? null : moment(dateArr[0].trim(), 'DD/MM/YY');
      endDate =
        dateArr && dateArr.length
          ? moment(dateArr[1].trim(), 'DD/MM/YY').format('DD-MM-YYYY')
          : '';
    } else {
      _startDate = null;
    }
    dispatch(getDashboardMatrix(startDate, endDate));
  };

  useEffect(() => {
    dashboardApiCall(lastUpdated);
    dispatch(getAuthoringFieldData('EN', 'Direct messaging', 'GB'));
    fetchQuestionList();
  }, []);

  const dropDownConfig = {
    className: 'cw-dashboard-filter report-dashboard-filter',
    name: 'campaignTrendsDropDown',
    label: 'campaignTrendsDropDown',
    id: 'campaignTrendsDropDown',
    options: dropDownOptions,
    onChange: handleCampaignTrendsDropDown,
    value: campaignTrendsOption
  };

  const infraStatusDropDownConfig = {
    className: 'cw-dashboard-filter report-dashboard-filter',
    name: 'campaignTrendsDropDown',
    label: 'campaignTrendsDropDown',
    id: 'campaignTrendsDropDown',
    options: dropDownOptions,
    onChange: handleInfraStatusDropDown,
    value: infraStatusOption
  };

  const dashboardProps = useSelector(state => ({
    campaignMatrix: state.dashboard.campaignMatrix
  }));

  let { campaignMatrix } = dashboardProps;
  //Campaign Trends Data set
  if (campaignMatrix && campaignMatrix.campaign_trends) {
    // Form Type: Simple Signup
    var simpleSignUpChartdata = getChartData(
      campaignMatrix.campaign_trends['simple-sign-up'],
      campaignTrendsOption.value
    );
    // Form Type: Samples
    var samplenUpChartdata = getChartData(
      campaignMatrix.campaign_trends['samples'],
      campaignTrendsOption.value
    );
    // Form Type: Competitions
    var competitionsChartdata = getChartData(
      campaignMatrix.campaign_trends['competitions'],
      campaignTrendsOption.value
    );
  }

  // Brand Summary - Unique Brand and Market
  if (campaignMatrix && campaignMatrix.uniqueBrandMarkets) {
    brandSummaryCard[0].value = campaignMatrix.uniqueBrandMarkets.value;
    brandSummaryCard[0].percentage =
      campaignMatrix.uniqueBrandMarkets.percentage + ' %';
  }

  // Brand Summary - Unique Markets
  if (campaignMatrix && campaignMatrix.uniqueMarkets) {
    brandSummaryCard[1].value = campaignMatrix.uniqueMarkets.value;
    brandSummaryCard[1].percentage =
      campaignMatrix.uniqueMarkets.percentage + ' %';
  }

  // Brand Summary - Unique Brands
  if (campaignMatrix && campaignMatrix.uniqueBrands) {
    brandSummaryCard[2].value = campaignMatrix.uniqueBrands.value;
    brandSummaryCard[2].percentage =
      campaignMatrix.uniqueBrands.percentage + ' %';
  }

  const handleTopBrandCheckbox = () => {
    setTopBrandCheck(!topBrandCheck);
  };

  const handleTopMarketCheckbox = () => {
    setTopMarketCheck(!topMarketCheck);
  };

  const topMarketsBrandsData = [
    {
      title: 'Top markets',
      data: [],
      checkBoxConfig: {
        title: 'Show brands',
        onChange: handleTopBrandCheckbox,
        value: topBrandCheck
      }
    },
    {
      title: 'Top Brands',
      data: []
    }
  ];

  const lowestUsageSummaryData = [
    {
      title: 'Lowest usage markets',
      data: []
    },
    {
      title: 'Lowest usage brands',
      data: []
    }
  ];

  const topUsageSummaryData = [
    {
      title: 'Top markets',
      data: [],
      checkBoxConfig: {
        title: 'Show brands',
        onChange: handleTopBrandCheckbox,
        value: topBrandCheck
      }
    },
    {
      title: 'Top brands',
      data: [],
      checkBoxConfig: {
        title: 'Show markets',
        onChange: handleTopMarketCheckbox,
        value: topMarketCheck
      }
    }
  ];

  const infraStatusMap = [
    {
      title: 'Infra and security setup',
      data: []
    }
  ];
  const infraStatusTrend = {
    data: {
      labels: [],
      datasets: []
    }
  };

  // Brand Summary - Lowest Usage Brands
  lowestUsageSummaryData[1].data = [];
  if (campaignMatrix && campaignMatrix.lowestBrands) {
    for (var index in campaignMatrix.lowestBrands) {
      lowestUsageSummaryData[1].data.push({
        title: campaignMatrix.lowestBrands[index].name,
        value: campaignMatrix.lowestBrands[index].count,
        percentage: campaignMatrix.lowestBrands[index].percentage + '%'
      });
    }
  }

  // Brand Summary - Lowest Usage Markets
  lowestUsageSummaryData[0].data = [];
  if (campaignMatrix && campaignMatrix.lowestMarkets) {
    for (var index in campaignMatrix.lowestMarkets) {
      lowestUsageSummaryData[0].data.push({
        title: campaignMatrix.lowestMarkets[index].name,
        value: campaignMatrix.lowestMarkets[index].count,
        percentage: campaignMatrix.lowestMarkets[index].percentage + '%'
      });
    }
  }

  // Brand Summary - Top Markets
  topUsageSummaryData[0].data = [];
  if (campaignMatrix && campaignMatrix.topMarkets) {
    for (var index in campaignMatrix.topMarkets) {
      var topBrandsOfMarket = [];
      for (var j in campaignMatrix.topMarkets[index].topBrands) {
        topBrandsOfMarket.push({
          title: campaignMatrix.topMarkets[index].topBrands[j].name,
          value: campaignMatrix.topMarkets[index].topBrands[j].count,
          percentage:
            campaignMatrix.topMarkets[index].topBrands[j].percentage + '%'
        });
      }

      topUsageSummaryData[0].data.push({
        title: campaignMatrix.topMarkets[index].name,
        value: campaignMatrix.topMarkets[index].count,
        percentage: campaignMatrix.topMarkets[index].percentage + '%',
        data: topBrandsOfMarket
      });
    }
  }

  // Brand Summary - Top Brands
  topUsageSummaryData[1].data = [];
  if (campaignMatrix && campaignMatrix.topBrands) {
    for (var index in campaignMatrix.topBrands) {
      var topMarketsOfBrand = [];
      for (var j in campaignMatrix.topBrands[index].topMarkets) {
        topMarketsOfBrand.push({
          title: campaignMatrix.topBrands[index].topMarkets[j].name,
          value: campaignMatrix.topBrands[index].topMarkets[j].count,
          percentage:
            campaignMatrix.topBrands[index].topMarkets[j].percentage + '%'
        });
      }
      topUsageSummaryData[1].data.push({
        title: campaignMatrix.topBrands[index].name,
        value: campaignMatrix.topBrands[index].count,
        percentage: campaignMatrix.topBrands[index].percentage + '%',
        data: topMarketsOfBrand
      });
    }
  }

  // User Summary
  if (campaignMatrix && campaignMatrix.userSummary) {
    //Requestors
    userSummaryCard[0].value = campaignMatrix.userSummary.requestors
      ? campaignMatrix.userSummary.requestors.value
      : 0;

    //approvers
    userSummaryCard[1].value = campaignMatrix.userSummary.approvers
      ? campaignMatrix.userSummary.approvers.value
      : 0;

    // Mater data assigners
    userSummaryCard[2].value = campaignMatrix.userSummary.masterDataAppovers
      ? campaignMatrix.userSummary.masterDataAppovers.value
      : 0;
  }

  // Infra Setup
  if (campaignMatrix && campaignMatrix.infraStatus) {
    infraStatusMap[0].data = [
      {
        title: 'Success',
        value: campaignMatrix.infraStatus.success.value || 0,
        percentage: (campaignMatrix.infraStatus.success.percentage || 0) + '%'
      },
      {
        title: 'Failure',
        value: campaignMatrix.infraStatus.failure.value || 0,
        percentage: (campaignMatrix.infraStatus.failure.percentage || 0) + '%'
      }
    ];
    infraStatusMap[0].total = {
      title: 'Total',
      value:
        (campaignMatrix.infraStatus.success.value || 0) +
        (campaignMatrix.infraStatus.failure.value || 0),
      percentage: ''
    };

    // Chart Data setup
    var infraStatusSuccessChartdata = getChartData(
      campaignMatrix.infraStatus.success.trend,
      infraStatusOption.value
    );
    var infraStatusFailureChartdata = getChartData(
      campaignMatrix.infraStatus.failure.trend,
      infraStatusOption.value
    );

    infraStatusTrend.data = {
      labels: infraStatusSuccessChartdata.labels,
      datasets: [
        {
          label: 'Infra setup success',
          backgroundColor: '#278325',
          data: infraStatusSuccessChartdata.dataSet,
          barThickness: 26,
          borderRadius: 5
        },
        {
          label: 'Infra setup failure',
          backgroundColor: '#AD2A0D',
          data: infraStatusFailureChartdata.dataSet,
          barThickness: 26,
          borderRadius: 5
        }
      ]
    };

    infraStatusTrend.options = {
      indexAxis: 'x',
      responsive: true,
      maintainAspectRatio: true,
      scales: {
        y: {
          //   offset: 20,
          stacked: true,
          display: true,
          grid: {
            display: false
          }
          //   position: "right",
        },
        x: {
          //   offset: 20,
          stacked: true,
          display: true,
          grid: {
            display: false
          },
          beginAtZero: true
        }
      },
      plugins: {
        legend: {
          display: true,
          position: 'right',
          labels: {
            boxWidth: 10
          }
        },
        tooltip: {
          intersect: false,
          callbacks: {
            title: function(context) {
              if (infraStatusOption.value == 'weekly') {
                let m = moment(context[0].label, 'DD MMM YYYY');
                if (m) {
                  if (_startDate && _startDate > m) {
                    return (
                      _startDate.format('DD MMM YYYY') +
                      ' - ' +
                      m.day(6).format('DD MMM YYYY')
                    );
                  }
                  return (
                    context[0].label + ' - ' + m.day(6).format('DD MMM YYYY')
                  );
                }
              }
              return context[0].label;
            }
          }
        }
      }
    };
  }

  const totalCampaign = [
    {
      title: 'Total Campaigns',
      imageIcon: allCampaign,
      value:
        (campaignMatrix &&
          campaignMatrix.total_count &&
          campaignMatrix.total_count.value) ||
        0,
      className: 'all-campaign'
    }
  ];

  let barChartslableArr = [];
  let barChartslableWithPerArr = [];
  let barChartsDataArr = [];
  let barChartDataQnaToggleArr = [];
  let barChartsQnaDataArr = [];
  let barChartColor = [];
  let barChartQnaColor = [];
  if (
    campaignMatrix &&
    campaignMatrix.campaign_types &&
    campaignMatrix.campaign_types.value &&
    campaignMatrix.campaign_types.value.length
  ) {
    for (let data of campaignMatrix.campaign_types.value) {
      barChartslableWithPerArr.push(
        `${data.label} (${data.value || 0} | ${data.percentage || 0}%)`
      );
      barChartColor.push(BarChartColorMap[data.name]);
      barChartQnaColor.push(BarChartColorQnaMap[data.name]);
      if (data.value) {
        barChartsDataArr.push(data.value || 0);
        barChartDataQnaToggleArr.push(data.value - data.qnaCampaignCount || 0);
        barChartsQnaDataArr.push(data.qnaCampaignCount || 0);
      }
    }
  }

  const consumerInsightDashStats = useMemo(() => {
    return [
      {
        title: 'Mobile only forms',
        count: get(campaignMatrix, 'mobile_forms.value', '51'),
        percent: `${get(campaignMatrix, 'mobile_forms.percentage', '20')}%`
      },
      {
        title: 'New enrichment questions',
        count: get(
          campaignMatrix,
          'new_data_enrichment_questions.value',
          '661'
        ),
        percent: `${get(
          campaignMatrix,
          'new_data_enrichment_questions.percentage',
          '60'
        )}%`
      },
      {
        title: 'New operational questions',
        count: get(campaignMatrix, 'new_operational_questions.value', '661'),
        percent: `${get(
          campaignMatrix,
          'new_operational_questions.percentage',
          '60'
        )}%`
      }
    ];
  }, [campaignMatrix]);

  const CampaignTypeChartConfig = {
    data: {
      labels: barChartslableWithPerArr,
      datasets: [
        {
          label: '',
          data: showToggle ? barChartDataQnaToggleArr : barChartsDataArr,
          backgroundColor: barChartColor,
          barThickness: 26
        },
        {
          label: 'Q&A',
          data: showToggle ? barChartsQnaDataArr : [],
          backgroundColor: barChartQnaColor,
          pointRadius: 50,
          showLine: true,
          pointStyle: 'line',
          barThickness: 26
        }
      ]
    }
  };

  const CampaignTrendsConfig = {
    data: {
      labels: simpleSignUpChartdata && simpleSignUpChartdata.labels,
      datasets: [
        {
          label: 'Simple Signup',
          data: simpleSignUpChartdata && simpleSignUpChartdata.dataSet,
          fill: false,
          borderColor: '#FF0F7D'
        },
        {
          label: 'Samples',
          data: samplenUpChartdata && samplenUpChartdata.dataSet,
          fill: false,
          borderColor: '#00D7C4'
        },
        {
          label: 'Competitions',
          data: competitionsChartdata && competitionsChartdata.dataSet,
          fill: false,
          borderColor: '#FFBA00'
        }
      ]
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          grid: {
            display: false
          },
          // offset: 20,
          ticks: {
            stepSize: 20
          }
        },
        x: {
          offset: 20,
          grid: {
            display: false
          },
          beginAtZero: false
        }
      },
      plugins: {
        legend: {
          position: 'right',
          textDirection: 'ltr',
          rtl: true,
          labels: {
            pointStyle: 'line',
            usePointStyle: true,
            textAlign: 'left'
          }
        },
        tooltip: {
          callbacks: {
            title: function(context) {
              if (campaignTrendsOption.value == 'weekly') {
                let m = moment(context[0].label, 'DD MMM YYYY');
                if (m) {
                  if (_startDate && _startDate > m) {
                    return (
                      _startDate.format('DD MMM YYYY') +
                      ' - ' +
                      m.day(6).format('DD MMM YYYY')
                    );
                  }
                  return (
                    context[0].label + ' - ' + m.day(6).format('DD MMM YYYY')
                  );
                }
              }
              return context[0].label;
            }
          }
        }
      }
    }
  };

  const toggleConfig = {
    toggleValue: showToggle,
    onClick: toggleBtnClick,
    title: 'Campaigns with QnA'
  };

  const migrationList = [
    {
      title: 'Migration campaigns',
      count:
        (campaignMatrix &&
          campaignMatrix.migration_campaigns &&
          campaignMatrix.migration_campaigns.value) ||
        0,
      percent: `${(campaignMatrix &&
        campaignMatrix.migration_campaigns &&
        campaignMatrix.migration_campaigns.percentage) ||
        0} %`
    },
    {
      title: 'Campaigns with Q&As',
      count:
        (campaignMatrix &&
          campaignMatrix.campaigns_with_qna &&
          campaignMatrix.campaigns_with_qna.value) ||
        0,
      percent: `${(campaignMatrix &&
        campaignMatrix.campaigns_with_qna &&
        campaignMatrix.campaigns_with_qna.percentage) ||
        0} %`
    },
    {
      title: 'Incentive Campaigns',
      count:
        (campaignMatrix &&
          campaignMatrix.incentive_campaigns &&
          campaignMatrix.incentive_campaigns.value) ||
        0,
      percent: `${(campaignMatrix &&
        campaignMatrix.incentive_campaigns &&
        campaignMatrix.incentive_campaigns.percentage) ||
        0} %`
    }
  ];

  const faqData = campaignMatrix && campaignMatrix.consumer_insights_faq;

  return (
    <div className="mt-20">
      <div className="d-flex align-items-center">
        <div className="mr-10">
          <div className="form-group mb-0">
            <RadioDropDown
              isMulti={false}
              className={'cw-dashboard-filter report-updated-filter'}
              name={'lastUpdated'}
              label={'Date of completion'}
              hideSelectedOptions={false}
              placeholder={'Date of completion'}
              value={lastUpdated}
              onChange={handleLastUpdatefilter}
              options={lastUpdatedData}
              isClearable={false}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <ReportSectionHeading
            title="Campaign summary"
            className="mb-30 mt-20"
          />
        </div>
      </div>
      <div className="row justify-content-between">
        <DashboardCard data={totalCampaign} colClass={`col-sm-3`} />
        <div className="col-sm-8">
          <div className="campaign-trends">
            <CampaignCharts
              title={'Campaign Type'}
              className={'campaign-type-chart'}
              chartType={'Bar'}
              chartConfig={CampaignTypeChartConfig}
              // dropDownConfig={dropDownConfig}
              toggleConfig={toggleConfig}
            />
          </div>
        </div>
      </div>

      <div className="campaign-trends mt-20">
        <CampaignCharts
          title={'Campaign trends'}
          className={'campaign-trends-chart-height'}
          chartType={'Line'}
          chartConfig={CampaignTrendsConfig}
          dropDownConfig={dropDownConfig}
        />
      </div>

      <div className="row">
        <div className="col-md-12 pt-60">
          <DashStatsBox list={migrationList} />
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <ReportSectionHeading title="Brand summary" className="mb-30 mt-50" />
        </div>
      </div>
      <div className="row summary-padding">
        <DashboardCard data={brandSummaryCard} colClass={`col-sm-4`} />
      </div>
      <div className="row mt-40 d-flex justify-content-between">
        <ChartSummary data={topUsageSummaryData} className="col-sm-6" />
      </div>
      <div className="row mt-40 d-flex justify-content-between">
        <ChartSummary data={lowestUsageSummaryData} className="col-sm-6" />
      </div>

      <div className="row">
        <div className="col-sm-12">
          <ReportSectionHeading
            title="Consumer insight"
            className="mb-30 mt-50"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-4 mb-30">
          <StatsBoxTitle title="Usage of questions" className="pb-20" />
          <FieldStatsAccordion
            data={campaignMatrix && campaignMatrix.consumerInsights}
          />
        </div>
        <div className="col-sm-8 mb-30 pr-30 pl-30">
          <StatsBoxTitle title="Frequently asked questions" className="pb-20" />
          <FaqStats questions={faqData || questionList} />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 pt-60">
          <DashStatsBox list={consumerInsightDashStats} />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <ReportSectionHeading title="User summary" className="mb-30 mt-130" />
        </div>
      </div>
      <div className="row summary-padding">
        <DashboardCard data={userSummaryCard} colClass={`col-sm-4`} />
      </div>

      {/** PERFORMACNE CHART STARTS */}
      <div className="row pt-60">
        <div className="col-md-12">
          <ReportSectionHeading
            title="Performance and health"
            className="mb-30 mt-20"
            titleClass="dashboard-info-security-title"
          />
        </div>
      </div>
      <div className="row justify-content-betweenss">
        <div className="col-sm-4 dashboard-info-security">
          <ChartSummary
            data={infraStatusMap}
            titleClass={'mb-60'}
            className={'chart-summary-wrapper-no-padding'}
          />
        </div>
        <div className="col-sm-8 mt-20">
          <CampaignCharts
            title={' '}
            className={'ml-50'}
            chartType={'Bar'}
            chartConfig={infraStatusTrend}
            dropDownConfig={infraStatusDropDownConfig}
          />
        </div>
      </div>
      {/** PERFORMACNE CHART ENDS */}
      <div className="row">
        <CampaignRejectionList />
      </div>
    </div>
  );
};

export default ReportDashboard;
