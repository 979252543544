import constants from 'constants/config';
import React, { useState, useEffect } from 'react';
//import Alert from './Alert';
import GuideLines from '../GuideLines';
import axios from 'axios';
import Loader from 'components/Loader';
import arrowImage from '../../images/arrowImage.png';
import search from '../../images/search.png';

const QnAListEnrichment = props => {
  const [showQLoading, setShowQLoading] = useState(true);
  const [showALoading, setShowALoading] = useState(true);

  const {
    searchQuestionsQuery,
    setSearchQuestionsQuery,
    searchAnswersQuery,
    setSearchAnswersQuery,
    selectedBusinessFilters,
    setSelectedBusinessFilters
  } = props;

  useEffect(() => {
    setShowQLoading(true);
    setTimeout(() => {
      setShowQLoading(false);
    }, 1500);
  }, []);

  useEffect(() => {
    setShowALoading(true);
    setTimeout(() => {
      setShowALoading(false);
    }, 1500);
  }, []);

  return (
    <>
      <div style={{ margin: '2%' }}>
        <div>
          <div>
            {props.showQuestions ? (
              <>
                {showQLoading ? <Loader /> : null}
                <div class="fake-input">
                  {/* <h4 className="cw-heading--qnafilters mb-3 mr-2" > Filter by business group</h4>   */}
                  <div>
                    {props.businessFilters &&
                      props.businessFilters.length > 0 &&
                      props.businessFilters.map((elem, index) => {
                        return (
                          <span>
                            <button
                              type="submit"
                              onClick={() =>
                                setSelectedBusinessFilters(
                                  elem !== selectedBusinessFilters ? elem : ''
                                )
                              }
                              className={
                                'btn filter-buttons mb-10 cw-print-hide text-nowrap  mr-1 ' +
                                (selectedBusinessFilters &&
                                selectedBusinessFilters === elem
                                  ? 'active'
                                  : '')
                              }
                            >
                              {elem}
                            </button>
                          </span>
                        );
                      })}
                  </div>
                  <input
                    type="search"
                    className="form-control mb-2"
                    name={'question'}
                    id={'question'}
                    placeholder="Search using keywords or master IDs"
                    style={{
                      width: '65%',
                      height: '2em',
                      marginLeft: '0.16%'
                    }}
                    value={searchQuestionsQuery}
                    onChange={e => setSearchQuestionsQuery(e.target.value)}
                  />
                </div>
                <div className="qna-guideline-scroll-browsable">
                  <div className="mb-10 cw-color--saasheading d-none">
                    <strong>Available master list</strong>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <img src={arrowImage} alt={arrowImage} />
                  </div>
                  <ul>
                    {props.questions.map((elem, index) => {
                      return (
                        <>
                          <li
                            key={index}
                            className="mb-1"
                            style={{ listStyle: 'none', fontSize: 14 }}
                          >
                            {elem.text}
                            <br />

                            {props.campaignLanguage !== 'English' ? (
                              <p style={{ fontSize: '12px' }}>
                                <span style={{ color: '#007dbb' }}>
                                  {props.campaignLanguage}:
                                </span>{' '}
                                <span>
                                  {elem.translatedText !== undefined
                                    ? elem.translatedText
                                    : 'No translation'}
                                </span>
                              </p>
                            ) : null}
                          </li>
                          <div className="horizontal-line horizontal-line-browse--li mb-1"></div>
                        </>
                      );
                    })}
                    {props.questions && props.questions.length === 0 ? (
                      <div class="custom-free-text">
                        <div class="form-group mt-10 mb-5 generic-error-message">
                          <span id="note" class="cw-error" data-control="name">
                            No results found matching your search criteria
                          </span>
                        </div>
                        <p class="mb-15">
                          You can search using other related keywords or look
                          for an alternate question that fulfills your purpose
                          from our curated master list with readily available
                          master data IDs
                        </p>
                      </div>
                    ) : null}
                  </ul>
                </div>
              </>
            ) : (
              <>
                {showALoading ? <Loader /> : null}

                <div class="fake-input">
                  <input
                    type="search"
                    className="form-control mb-10"
                    name={'answers'}
                    id={'answers'}
                    placeholder="Search using keywords or master IDs"
                    style={{
                      width: '65%',
                      height: '2em',
                      marginLeft: '0.16%'
                    }}
                    value={searchAnswersQuery}
                    onChange={e => setSearchAnswersQuery(e.target.value)}
                  />
                </div>
                <div className="answer-overlay-scroll-browsable">
                  <div className="mb-10 cw-color--saasheading d-none">
                    <strong>Available master list</strong>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <img src={arrowImage} alt={arrowImage} />
                  </div>
                  <ul>
                    {props.answers.map((elem, index) => {
                      return (
                        <>
                          <li
                            key={index}
                            className="mb-1"
                            style={{
                              fontWeight: '',
                              listStyle: 'none',
                              fontSize: 14
                            }}
                          >
                            {elem.text}
                            {props.campaignLanguage !== 'English' ? (
                              <p style={{ fontSize: '12px' }}>
                                <span style={{ color: '#007dbb' }}>
                                  {props.campaignLanguage}:
                                </span>{' '}
                                <span>
                                  {elem.translatedText !== undefined
                                    ? elem.translatedText
                                    : 'No translation'}
                                </span>
                              </p>
                            ) : null}
                          </li>
                          <div className="horizontal-line horizontal-line-browse--li mb-1"></div>
                        </>
                      );
                    })}
                  </ul>
                  {props.answers && props.answers.length === 0 ? (
                    <div class="custom-free-text">
                      <div class="form-group mt-10 mb-5 generic-error-message">
                        <span id="note" class="cw-error" data-control="name">
                          No results found matching your search criteria
                        </span>
                      </div>
                      <p class="mb-15">
                        You can search using other related keywords or look for
                        an alternate answer that fulfills your purpose from our
                        curated master list with readily available master data
                        IDs.
                      </p>
                    </div>
                  ) : null}
                </div>
              </>
            )}

            {/* <div className="horizontal-line"></div> */}
          </div>
        </div>
        {/* <div className="">
          <div className="mt-3 horizontal-line mb-10"></div>
          <button
            style={{ float: "right", borderRadius: "8px",margin:0 }}
            type="submit"
            onClick={props.closeQnAModal}
            className="btn btn-primary mb-10 cw-print-hide text-nowrap  mr-1"
          >
            Close
          </button>
        </div> */}
      </div>
    </>
  );
};

export default QnAListEnrichment;
