import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	isLDAPUser,
	fetchExistingEmailList,
	getAuthorAccessEmailsByCampaignID,
	submitAuthorAccessEmails,
	resetSubmissionState,
	processing,
} from '../../actions/campaignAuthorAccess';
import { scrollToTop } from '../../selectors';
import BreadCrumb from './../BreadCrumb';
import { setNotificationMessage } from '../../actions/dashboard';
import constant from '../../constants';
import EmailAddressField from './EmailAddressField';
import Loader from '../Loader';

class CampaignAuthoringAccess extends Component {
	constructor(props) {
		super(props);

		this.state = {
			matchProps: props.match,
			finalEmailList: [
				{
					id: new Date().getTime().toString(36),
					email: undefined,
					isNewUser: false,
				},
			],
			isFormSubmitted: false,
			isNextDisabled: true,
			isformValid: false,
		};
		this.formValid = false;
		this._isMounted = false;

		this._handleSubmit = this._handleSubmit.bind(this);
		this._handlebackButton = this._handlebackButton.bind(this);
		this._validateForm = this._validateForm.bind(this);
	}

	componentDidMount() {
		this._isMounted = true;
		this.props.fetchExistingEmailList();
		this.campaignId = this.props.match.params.id;
		this.props.getAuthorAccessEmailsByCampaignID(this.campaignId);
		scrollToTop();
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.campaignAccessEmails !== this.props.campaignAccessEmails &&
			this.props.campaignAccessEmails.length > 0
		) {
			this.props.campaignAccessEmails &&
				this.setState({
					finalEmailList: this.props.campaignAccessEmails,
				});
		}
	}

	static getDerivedStateFromProps(props, state) {
		if (props.pushstate) {
			props.resetSubmissionState(false);
			//const nextRoute = 'consentpreferences';
			const nextRoute =
				props.campaignType === 'Incentive'
					? props.type === 'Simple sign-up'
						? 'review'
						: 'questionsanswers'
					: 'consentpreferences';
			props.history.push('/campaign-wizard/campaign/' + props.match.params.id + '/' + nextRoute);
		}

		/* redirect to dashboard once consent details saved as draft */
		if (props.dataSaved) {
			let notificationData = {};
			notificationData.message = constant.CAMPAIGN_SAVED_AS_DRAFT_TEXT;
			notificationData.link = '';
			notificationData.type = 'warning';
			notificationData.campaign = '';

			props.setNotificationMessage(
				notificationData.message,
				notificationData.link,
				notificationData.type,
				notificationData.campaign
			);

			props.history.push('/campaign-wizard/dashboard');
		}
		return null;
	}
	_showHideErrorMessages(key, msg, show) {
		if (document.querySelector('[data-msg=' + key + ']')) {
			document.querySelector('[data-msg=' + key + ']').classList.add('cw-hidden');
			document.querySelector('[data-msg=' + key + ']').classList.add('d-none');
			document.querySelector('[data-msg-txt=' + key + ']').classList.add('cw-hidden');
		}
		if (document.querySelector('[data-custom-msg=' + key + ']')) {
			if (show) {
				document.querySelector('[data-custom-txt=' + key + ']').innerHTML = msg;
				document.querySelector('[data-custom-msg=' + key + ']').classList.remove('cw-hidden');
			} else {
				document.querySelector('[data-custom-txt=' + key + ']').innerHTML = '';
				document.querySelector('[data-custom-msg=' + key + ']').classList.add('cw-hidden');
			}
		}
	}

	async _validateData(email, index, datacount) {
		const regex = constant.regex['email'];
		let key = `userEmailAddress_${index + 1}`;
		let msg = '';

		if (email) {
			if (this.props.existingEmailList.includes(email)) {
				this._showHideErrorMessages(key, '', false);
				return true;
			} else if (regex.test(email)) {
				let isLDAPUser = await this.props.isLDAPUser(email);
				msg = constant.USER_NOT_IN_GROUP_ERROR;
				if (isLDAPUser) {
					this._showHideErrorMessages(key, msg, false);
					return true;
				} else {
					this._showHideErrorMessages(key, msg, true);
					return false;
				}
			}
		} else {
			msg = datacount === 1 ? constant.MANDATORY_AUTHORING_ERROR_MSG : constant.EMPTY_AUTHORING_EMAIL_ERROR;
			this._showHideErrorMessages(key, msg, true);
			return false;
		}
	}

	async _validateForm(event) {
		let emailsAdded = this.state.finalEmailList;
		if (emailsAdded.length) {
			let emailCount = emailsAdded.length;
			this.props.processing(true);
			let formElementsValitionPromise = emailsAdded.map(async (data, index) => {
				return await this._validateData(data.email, index, emailCount);
			});
			let formElementsValidated = await Promise.all(formElementsValitionPromise);
			let isformValid = formElementsValidated.every((value) => value === true);
			this.formValid = isformValid;
			this.setState({ isformValid: isformValid });
			this.props.processing(false);
		}
	}

	async _handleSubmit(event, saveAsDraft = false) {
		event.preventDefault();
		event.stopPropagation();
		this.setState({ isFormSubmitted: true });
		if (!saveAsDraft) {
			await this._validateForm(event);
		} else {
			this.formValid = true;
		}

		//this.setState({ isFormSubmitted: false });
		if (this.formValid) {
			this.formValid = false;
			let updatedFormData = {};
			if (saveAsDraft || !this.state.finalEmailList.some((item) => item.email === undefined)) {
				updatedFormData.uid = this.campaignId;
				updatedFormData.lastUpdatedScreen = 'authoringaccess';
				updatedFormData.users = this.state.finalEmailList;
				this.props.submitAuthorAccessEmails(
					updatedFormData,
					saveAsDraft,
					this.props.userName,
					this.props.userEmail
				);
			}
		} else {
			this.setState({ isFormSubmitted: false });
		}
	}

	_handlebackButton(event) {
		event.preventDefault();
		this.props.history.push('/campaign-wizard/campaign/' + this.campaignId + '/details');
	}

	render() {
		let websiteType = 'Standalone';
		let { asyncInProgress } = this.props;

		return (
			<div className='col-sm-12'>
				<div className='cw-section'>
					<div className='row'>
						{asyncInProgress ? <Loader /> : null}
						<div className='col-sm-9'>
							<div className='cw-section--content'>
								<BreadCrumb
									match={this.state.matchProps}
									hideBreadCrumb={this.props.approverEditCampaign}
									websiteType={websiteType}
								/>

								<div className='cw-section--title mb-20'>
									<h2 className='cw-heading--secondary mb-20'>{'Campaign authoring access'}</h2>
								</div>
								<div className='cw-section--title mb-30'>
									<p className='mb-20'>
										Add the email addresses of the front end agency or web masters who’ll be
										authoring the campaign on AEM.
									</p>

									<p className='mb-20'>
										<b>
											Please ensure all email addresses have been set up in Adobe Expeirence
											Manager (LDAP) first.
										</b>
									</p>

									<p className='mb-20'>
										Once the campaign has been approved and published on AEM, the team member will
										be provided access to the campaign folder.
									</p>

									<p className='mb-20'>Add up to 10 email addresses.</p>
								</div>
								{this.state.finalEmailList.map((item, index) => (
									<EmailAddressField
										key={item.id}
										existingEmailList={this.props.existingEmailList}
										count={index + 1}
										saveValidEmail={this.saveValidEmail}
										addOrDelete={this.addOrDelete}
										isFormSubmitted={this.state.isFormSubmitted}
										email={item.email}
										isNewUser={item.isNewUser}
										isLDAPUser={this.props.isLDAPUser}
										isMounted={this._isMounted}
									/>
								))}
								<form className='cw-form' onSubmit={this._handleSubmit}>
									<div className='cw-form--action-cta mt-50'>
										<button
											type='button'
											className='btn btn btn-outline-secondary mr-3'
											onClick={this._handlebackButton}>
											Back
										</button>
										<button
											type='submit'
											className='btn btn-primary'
											disabled={this.state.isFormSubmitted}
											onClick={this._handleSubmit}>
											Next
										</button>
										<a
											href='javascript:void(0)'
											className='cw-save-to-draft mt-40'
											onClick={(event) => this._handleSubmit(event, true)}>
											Save and exit
										</a>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	addOrDelete = (count) => {
		let newEmailList = this.state.finalEmailList;
		if (count === 1) {
			newEmailList.length !== 10 &&
				newEmailList.push({
					id: new Date().getTime().toString(36),
					isNewUser: false,
					email: undefined,
				});
			this.setState({ isNextDisabled: true });
		} else {
			newEmailList = this.state.finalEmailList.filter((item, index) => index !== count - 1);
		}
		this.setState({
			finalEmailList: newEmailList,
			isNextDisabled: newEmailList.some((item) => item.email === undefined),
		});
	};

	saveValidEmail = (count, email) => {
		this.setState({
			finalEmailList: this.state.finalEmailList.map((item, index) => {
				return index === count - 1
					? {
							...item,
							email,
							isNewUser: !this.props.existingEmailList.includes(email),
					  }
					: item;
			}),
			isNextDisabled: this.state.finalEmailList.some((item, index) => {
				if (index === count - 1) {
					return email === undefined;
				} else {
					return item.email === undefined;
				}
			}),
		});
	};
}

const mapStateToProps = (state) => ({
	type: state.campaignAuthorAccess.type,
	campaignType: state.campaignAuthorAccess.campaignType,
	approverEditCampaign: state.app.approverEditCampaign,
	pushstate: state.campaignAuthorAccess.pushstate,
	dataSaved: state.campaignAuthorAccess.dataSaved,
	existingEmailList: state.campaignAuthorAccess.existingEmailList,
	campaignAccessEmails: state.campaignAuthorAccess.campaignAccessEmails,
	asyncInProgress: state.campaignAuthorAccess.asyncInProgress,
});

export default connect(
	mapStateToProps,
	{
		isLDAPUser,
		processing,
		resetSubmissionState,
		getAuthorAccessEmailsByCampaignID,
		submitAuthorAccessEmails,
		setNotificationMessage,
		fetchExistingEmailList,
	}
)(CampaignAuthoringAccess);
