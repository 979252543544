import React from 'react';
import { Link } from 'react-router-dom';
import constant from '../constants';
const NotFound = props => {
	return (
		<div className='col-sm-12'>
			<div className='cw-section'>
				<h2 className='cw-heading--secondary mb-30'>{!props.isGigyaDecommissioned ?"404":<div></div>}</h2>
				<p className='alert alert-danger mb-40'>
					<strong>{!props.isGigyaDecommissioned 
					? "We couldn't find this page."
					: constant.GIGYA_DECOMMISSION_MESSAGE
				}</strong>
					<Link className='ml-2' to='/campaign-wizard'>
						Go to Homepage.
					</Link>
				</p>
			</div>
		</div>
	);
};

export default NotFound;
