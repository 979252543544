import React from 'react';
import Modal from 'react-modal';
import constants from 'constants/config';

const ABTestingVariantValidation = ({
  isOpen,
  onCloseModal,
  handleCloseModal,
  variantValidationError
}) => {
  Modal.setAppElement('#root');
  const { testType, error } = variantValidationError;
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCloseModal}
      className="cw-modal cw-modal--filePicker-info"
      contentLabel="Save row form"
      style={{
        overlay: {
          backgroundColor: constants.EMAIL_OVERLAY_BACKGROUND
        }
      }}
    >
      <div className="email-overlay-wrapper image-guideline">
        <span
          className="qna-guideline-close email-overlay-close-modal"
          onClick={handleCloseModal}
        ></span>
        <div>
          <div className="d-flex mb-20">
            <div className="ml-10">
              <div
                className={
                  'cw-heading--saascampaign send-test-email__title mb-20'
                }
              >
                Design errors
              </div>
              <div className="scrollable email-overlay-scroll">
                <div className="d-flex">
                <span className="cw-error">
                  <i className="fas fa-exclamation-triangle mr-10"></i>
                </span>
                <span className="cw-error">
                  {`Your email campaign includes an ${testType} test on email content. You have not yet configured ${error}`}
                </span>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-row-reverse">
            <button className="btn btn-primary" onClick={handleCloseModal}>Close</button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ABTestingVariantValidation;
