import React from 'react';
import {
  getCampaignStatus,
  isAEMTemplateCampaign,
  getCampaignStatusText
} from '../../../selectors';
import classNames from 'classnames';

export default function Status({
  authorizedUserData,
  roleForFilter,
  campaign,
  status
}) {
  const isPublished = campaign && campaign.isProcessed ? true : false;
  const isAEMTemplate = isAEMTemplateCampaign(campaign);

  const statusClassName = classNames({
    badge: true,
    'campaign-status-badge': true,
    'badge-success':
      status === '3'
        ? isAEMTemplate
          ? isPublished
            ? true
            : false
          : true
        : false,
    'badge-warning':
      status === '6' ||
      status === '7' ||
      status === '8' ||
      (status === '3'
        ? isAEMTemplate
          ? isPublished
            ? false
            : true
          : false
        : true),
    'badge-primary': status === '1' || status === '4',
    'badge-secondary': status === '9'
  });

  if (
    authorizedUserData.group &&
    roleForFilter.includes(authorizedUserData.group)
  ) {
    return (
      <span className={statusClassName}>
        {getCampaignStatusText(campaign, authorizedUserData.group)}
      </span>
    );
  } else {
    return (
      <span className={statusClassName}>
        {getCampaignStatus(status) === 'Completed'
          ? isAEMTemplate
            ? isPublished
              ? 'Published'
              : 'Processing'
            : getCampaignStatus(status)
          : getCampaignStatusText(campaign, authorizedUserData.group)}
      </span>
    );
  }
}
