import React from 'react';
import GuidelineWrapper from './atoms/GuidelineWrapper/GuidelineWrapper';
import GuideLines from './GuideLines';

const EmailCustomAudienceGuildline = props => {
  return (
    <GuidelineWrapper
      isEmailCampaign={props.isEmailCampaign}
      onClose={props.closeIsEditModal}
      title={props.title}
    >
      <div className="email-overlay-scroll__content">
        <GuideLines data={props.data} qnaIndex={true} noLineBreak={true} />
      </div>
      <div className="horizontal-line"></div>
    </GuidelineWrapper>
  );
};

export default EmailCustomAudienceGuildline;
