import axios from 'axios';
import {
  SERVICE_ERROR_OVERVIEW,
  GET_CAMPAIGN_HISTORY_LOGS,
  FETCH_REJECTED_CAMPAIGN_HISTORY_LOGS
} from '../actionTypes';
import constant from '../constants';

export function fetchHistoryLogs(campaignID) {
  return function(dispatch) {
    axios
      .get(constant.serviceUrls.CAMPAIGN_HISTORY_LOGS_URL + '/' + campaignID)
      .then(response => {
        const historyLogs = response.data;
        dispatch({ type: GET_CAMPAIGN_HISTORY_LOGS, historyLogs });
      })
      .catch(error => {
        dispatch({ type: SERVICE_ERROR_OVERVIEW, error });
      });
  };
}

export function fetchRejectedCampaignHistoryLogs() {
  return function(dispatch) {
    axios
      .get(
        constant.serviceUrls.CAMPAIGN_HISTORY_LOGS_URL +
          '?event=Campaign rejected'
      )
      .then(response => {
        const historyLogs = response.data;
        dispatch({ type: FETCH_REJECTED_CAMPAIGN_HISTORY_LOGS, historyLogs });
      })
      .catch(error => {
        dispatch({ type: SERVICE_ERROR_OVERVIEW, error });
      });
  };
}
