import React from 'react';
import { get } from 'lodash';

import { EmailSchduleInput } from 'components/molecules/EmailScheduleInput';
import { DeliveryLabelInputs } from 'components/molecules/DeliveryLabelInputs';

export const EmailDeliveryAndLabelInput = ({
  name,
  rules,
  register,
  label,
  labelClass,
  errors,
  setValue,
  control,
  defaultValue,
  labelInputName,
  customComponent,
  emailType,
  abTesting,
  isSms,
  markAsSchedule
}) => {
  const { performABTests, noOfABVariants, abTestElements } = abTesting || {};
  const isABTestOnSendTime = performABTests && abTestElements === 'sendTime';
  const isFollowUp = get(defaultValue, 'sendAfter.value') !== 'DontSend';

  if (
    !performABTests ||
    (performABTests && !isABTestOnSendTime) ||
    (performABTests &&
      isABTestOnSendTime &&
      defaultValue.type == 'dynamicTrigger')
  ) {
    return (
      <>
        <EmailSchduleInput
          name={name}
          rules={rules}
          register={register}
          label={label}
          errors={errors}
          setValue={setValue}
          control={control}
          defaultValue={defaultValue}
          emailType={emailType}
          isSms={isSms}
          markAsSchedule={markAsSchedule}
        />

        <DeliveryLabelInputs
          control={control}
          errors={errors}
          name={`${name}.${labelInputName}`}
          register={register}
          setValue={setValue}
          labelClass={labelClass}
          defaultValue={defaultValue}
          customComponent={customComponent}
          abTesting={abTesting}
          isSms={isSms}
          markAsSchedule={markAsSchedule}
        />
      </>
    );
  }

  if (performABTests && isABTestOnSendTime) {
    return (
      <>
        <strong>A/B test - Variant A</strong>
        <EmailSchduleInput
          name={name}
          rules={rules}
          register={register}
          label={label}
          errors={errors}
          setValue={setValue}
          control={control}
          defaultValue={defaultValue}
          emailType={emailType}
          variant="VariantA"
        />

        <DeliveryLabelInputs
          control={control}
          errors={errors}
          name={`${name}.${labelInputName}`}
          register={register}
          setValue={setValue}
          labelClass={labelClass}
          defaultValue={defaultValue}
          customComponent={customComponent}
          abTesting={abTesting}
          variant="VariantA"
          isSms={isSms}
        />

        <strong>A/B test - Variant B</strong>
        <EmailSchduleInput
          name={name}
          rules={rules}
          register={register}
          label={label}
          errors={errors}
          setValue={setValue}
          control={control}
          defaultValue={defaultValue}
          emailType={emailType}
          variant="VariantB"
        />

        <DeliveryLabelInputs
          control={control}
          errors={errors}
          name={`${name}.${labelInputName}`}
          register={register}
          setValue={setValue}
          labelClass={labelClass}
          defaultValue={defaultValue}
          customComponent={customComponent}
          variant="VariantB"
          isSms={isSms}
        />

        {isFollowUp && <DeliveryLabelInputs
          control={control}
          errors={errors}
          name={`${name}.${labelInputName}`}
          register={register}
          setValue={setValue}
          labelClass={labelClass}
          defaultValue={defaultValue}
          customComponent={customComponent}
          abTesting={abTesting}
          variantFollowUp
          isSms={isSms}
        />}
      </>
    );
  }
};
