import React, { useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';

function usePrevious(value) {
	const ref = useRef();
	useEffect(() => {
		ref.current = value;
	}, [value]);

	return ref.current;
}
const ScrollToTop = props => {
	const currentLocation = props.location.pathname;
	const previousLocation = usePrevious({ currentLocation });
	useEffect(() => {
		if (currentLocation !== previousLocation) {
			window.scrollTo(0, 0);
		}
	});

	return null;
};

export default withRouter(ScrollToTop);
